import { CloseIcon } from 'images';
import './index.scss';
import { InputWrapper } from 'components/Input/InputWrapper';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { STORAGE, patternNoFirstSpacing } from 'utils';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deactivePostByAdmin, deletePostByAdmin } from 'store/actions/admin';
import { PopupConfirmFan } from 'components';

const PopupConfirm = ({
  title,
  handleClosePopup,
  subTitle,
  type = 'deactive',
  handleSuccess,
}) => {
  const { t } = useTranslation();

  const { id } = useParams();

  const dispatch = useDispatch();

  const {
    register,
    watch,
    formState: { errors },
  } = useForm({ mode: 'all' });

  const handleTextarea = (e) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
  };

  const reasonContent = type !== 'no-reason' ? watch('reason', '') : 'active';
  const token = localStorage.getItem(STORAGE.USER_ACCESS_TOKEN);

  const handleClickOk = () => {
    if (type === 'delete-post') {
      dispatch(deletePostByAdmin({ id, reasonContent, token }, handleSuccess));
    } else dispatch(deactivePostByAdmin({ id, reasonContent, token }, handleSuccess));
  };

  return (
    <>
      {type === 'no-reason' ? (
        <PopupConfirmFan
          isVisiblePopup={false}
          iconNote={true}
          colorSvg='#FFBF00'
          fillColor={true}
          text={t('PostDetail.Popup.are_you_sure_you_want_to_activate_this_post')}
          className='popup-content sell-package-page'>
          <button
            onClick={() => {
              handleClosePopup();
              handleClickOk();
            }}
            className='btn btn-bg-red text-white'>
            {t('PostDetail.Popup.yes')}
          </button>
          <div
            className='btn cancel'
            onClick={() => {
              handleClosePopup();
            }}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      ) : (
        <div className={`popup post-sale-performer`}>
          <div className='popup-content sale-performer popup-admin-handle-post'>
            <>
              <div className='title'>{title}</div>
              <CloseIcon className='close-icon' onClick={() => handleClosePopup()} />
              <div
                className='content'
                style={{ padding: '24px', paddingBottom: '8px', display: 'block' }}>
                <p style={{ fontSize: '14px', fontWeight: '400' }}>{subTitle}</p>
                {type !== 'no-reason' && (
                  <>
                    <p style={{ fontSize: '14px', fontWeight: '400' }}>
                      {t('Creator.Message.text')}
                      <span className='fansite-form-input__required'>*</span>
                    </p>
                    <InputWrapper
                      id='fsite-content'
                      label=''
                      required={true}
                      className={`text-post`}
                      error={errors?.reason ? errors?.reason?.message : ''}>
                      <textarea
                        {...register('reason', {
                          required: t('ValidateMsg.itemRequired'),
                          maxLength: {
                            value: 500,
                            message: t('ValidateMsg.CONTENT_IS_TOO_LONG'),
                          },
                          validate: {
                            noFirstSpacing: (value) =>
                              (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                              t('ValidateMsg.itemRequired'),
                          },
                          onChange: (e) => handleTextarea(e),
                        })}
                        style={{
                          width: '100%',
                          borderRadius: '6px',
                          borderColor: '#E5E6EB',
                          padding: '10px 15px',
                          minHeight: '103px',
                        }}
                        placeholder={t('Creator.Message.placeholderTextarea')}
                        onKeyDown={(e) => handleTextarea(e)}
                        maxLength={500}
                      />
                    </InputWrapper>
                  </>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  borderTop: '1px solid #F0F0F0',
                  padding: '20px 15px',
                }}>
                <div className=''>
                  <button
                    disabled={type !== 'no-reason' ? !reasonContent.length : false}
                    onClick={() => {
                      handleClosePopup();
                      handleClickOk();
                    }}
                    className='btn btn-bg-red text-white'>
                    {t('Common.send')}
                  </button>
                </div>
                <div className=''>
                  <button
                    onClick={() => {
                      handleClosePopup();
                    }}
                    className='btn'
                    style={{
                      border: '1px solid #F0F0F0',
                      marginLeft: '10px',
                      minWidth: '119px',
                    }}>
                    {t('Common.buttonCancel')}
                  </button>
                </div>
              </div>
            </>
          </div>
        </div>
      )}
    </>
  );
};

export default PopupConfirm;
