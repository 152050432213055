import Icon from '@ant-design/icons';

const TwitterProfileIcon = (props) => (
  <Icon
    component={() => (
      <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.2033 1.5625H17.9599L11.9374 8.66788L19.0224 18.3367H13.4749L9.12992 12.4726L4.15826 18.3367H1.39992L7.84159 10.7367L1.04492 1.5625H6.73326L10.6608 6.9225L15.2033 1.5625ZM14.2358 16.6335H15.7633L5.90326 3.17626H4.26409L14.2358 16.6335Z" fill="#1A1A1A" />
      </svg>
    )}
    {...props}
  />
);

export default TwitterProfileIcon;
