import Icon from '@ant-design/icons';

const NoLiveArchive = (props) => (
  <Icon
    component={() => (
      <svg
        width='120'
        height='120'
        viewBox='0 0 120 120'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M101.25 33.75H18.75C16.6789 33.75 15 35.4289 15 37.5V93.75C15 95.8211 16.6789 97.5 18.75 97.5H101.25C103.321 97.5 105 95.8211 105 93.75V37.5C105 35.4289 103.321 33.75 101.25 33.75Z'
          stroke='#FCECEF'
          strokeWidth='7'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M37.5 11.25L60 33.75L82.5 11.25'
          stroke='#FCECEF'
          strokeWidth='7'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default NoLiveArchive;
