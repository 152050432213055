/* eslint-disable react-hooks/exhaustive-deps */
import { ImgCustom, LayoutAffiliator, PopupCopyLink, PopupLoading } from 'components';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getListBannerDetailAffiliator } from 'store/actions/affiliator';
import { linkS3, onErrImage } from 'utils/utils';
import { downloadS3Img } from 'utils/download';
import './index.scss';
const BannerDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { banner_id } = useParams();
  const { loading, detail_banner } = useSelector((state) => state.affiliator);
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const [checkApi, setCheckApi] = useState(false);
  const {t} = useTranslation();
  useEffect(() => {
    dispatch(
      getListBannerDetailAffiliator(
        { banner_id },
        () => {
          setCheckApi(true);
        },
        () => {
          setCheckApi(false);
          navigate('/*');
        }
      )
    );
  }, []);

  return (
    <>
      {popupCopyLink && (
        <PopupCopyLink
          isVisiblePopup={() => setPopupCopyLink(false)}
          link={detail_banner.banner_url}
          router={-1}
        />
      )}
      {checkApi && (
        <LayoutAffiliator titlePage={t('Affiliator.AF005.bannerDetailScreen')}>
          <div className={`view-affiliator--banner-detail`}>
            {loading && <PopupLoading className={'popup-loading'} />}
            <div className='banner-detail-wrapper'>
              <ImgCustom
                src={`${detail_banner?.banner_image}`}
                alt=''
                onError={(e) => onErrImage(e, false)}
              />
              <div className='d-flex'>
                <div className='name'>{t('Affiliator.AF005.bannerTitle')}</div>
                <div className='value'>{detail_banner?.title}</div>
              </div>
              <div className='d-flex'>
                <div className='name'>{t('Affiliator.AF005.postPeriod')}</div>
                <div className='value'>
                  {moment.utc(detail_banner.start_time).local().format('YYYY/MM/DD')} ~
                  {moment.utc(detail_banner.end_time).local().format('YYYY/MM/DD')}
                </div>
              </div>
              <div className='d-flex'>
                <div className='name'>{t('Affiliator.AF005.affiliateDisplay')}</div>
                <div className='value'>
                  {detail_banner?.show_affiliator === 1 ? 'ON' : 'OFF'}
                </div>
              </div>
              <div className='d-flex'>
                <div className='name'>{t('Affiliator.AF005.referralURL')}</div>
                <div className='value'>{detail_banner?.banner_url}</div>
              </div>
              <div className='d-flex btn-wrapper'>
                <button className='btn btn-share' onClick={() => setPopupCopyLink(true)}>
                  {t('Popup.share')}
                </button>
                <button
                  className='btn btn-download'
                  onClick={() => downloadS3Img(detail_banner)}>
                  {t('Affiliator.AF005.download')}
                </button>
              </div>
            </div>
          </div>
        </LayoutAffiliator>
      )}
    </>
  );
};

export default BannerDetail;
