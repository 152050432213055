import HeaderListIdolV2 from './HeaderListIdolV2';
import { CloseIcon } from 'images';
import { useState } from 'react';
import AccountSidebar from 'components/Account/AccountSidebar';
import { isAdmin, isAgency, setOverflowTablet } from 'utils';
import { useSelector } from 'react-redux';
import { MenuSidebarCustom } from 'components';
import { useEffect } from 'react';

const HeaderMobileV2 = ({ titlePage }) => {
  const [isVisibleMenu, setIsVisbleMenu] = useState(false);
  const { dataUser } = useSelector((state) => state.users);
  const widthScreen = window.innerWidth;
  
  useEffect(() => {
    if (isVisibleMenu) {
      document.getElementById('root').style.overflow = 'hidden';
      document.getElementById('root').style.position = 'fixed';
      document.getElementById('root').style.height = '100%';
      document.getElementById('root').style.width = '100vw';
    } else {
      // document.getElementById('root').style.overflow = 'auto';
      setOverflowTablet();
      document.getElementById('root').style.width = 'auto';
      document.getElementById('root').style.position = 'revert';
    }
  }, [isVisibleMenu]);

  return (
    <>
      <>
        {widthScreen < 1024 && (
          <>
            <HeaderListIdolV2 />
            <div className='header__mobile_creator d-flex margin-top-20 justify-content-between align-items-center'>
              {/* <div className='nav__btn'>
                  <MenuIcon onClick={() => setIsVisbleMenu(true)} />
                </div>
                {titlePage && 
                  <div
                    className='header__mobile_creator__screen-title d-flex justify-content-center align-items-center w-100'
                    style={{ fontWeight: 500, fontSize: 20, paddingLeft: "-20px" }}>
                    {titlePage}
                  </div>
                } */}
            </div>
          </>
        )}
        {isVisibleMenu && (
          <div className='box-modal'>
            <div className='menu-mobile'>
              <AccountSidebar
                setIsVisbleMenu={setIsVisbleMenu}
                isAdmin={isAdmin}
                isAgency={isAgency}
                user={dataUser}>
                <CloseIcon
                  style={{ cursor: 'pointer', zIndex: 10 }}
                  className='close-drawer'
                  onClick={() => setIsVisbleMenu(false)}
                />
              </AccountSidebar>
              <MenuSidebarCustom />
            </div>
            <div className='gray-screen' onClick={() => setIsVisbleMenu(false)}></div>
          </div>
        )}
      </>
    </>
  );
};

export default HeaderMobileV2;
