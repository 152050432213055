import { Navigate, Route, Routes } from 'react-router-dom';
import { STORAGE, isAdmin, isAgency, isProdEnable, isProdEnableLiveStream } from 'utils';
import { ADMIN_ROUTES, AGENCY_ROUTES, USER_NOT_APPROVED_ROUTES, USER_ROUTES } from 'routes';
import './index.scss';
import NewFansiteLayout from 'components/Layout/v2/Layout';
import Storage from 'utils/storage';
import { useMemo } from 'react';
import CreatorHomePage from 'pages/creator/layout/home-page';
import SignIn from 'pages/sign-in';
import VerifyAge from 'pages/check-age';

export default function V2Layout() {
  const statusUser = Storage.get(STORAGE.USER_STATUS);
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);

  const isNotApproved = useMemo(() => {
    return statusUser ? +statusUser !== 1 : false;
  }, [statusUser]);

  const renderRoute = (list) => {
    return (
      <>
        {list.map((route) => {
          if (!route?.isProdPageEnable && !route?.isProdEnableLiveStream) {
            if (isAuth && route?.statusAccept) {
              if (route?.statusAccept?.includes(+statusUser)) {
                return <Route key={route.path} {...route} />;
              }
            } else {
              return <Route key={route.path} {...route} />;
            }
          } else if (
            (route?.isProdPageEnable && +isProdEnable === 1) ||
            (route?.isProdEnableLiveStream && +isProdEnableLiveStream === 1)
          ) {
            return <Route key={route.path} {...route} />;
          }
        })}
      </>
    );
  };

  return (
    <NewFansiteLayout>
      <Routes>
        {isAdmin
          ? ADMIN_ROUTES.map((route) => <Route key={route.path} {...route} />)
          : isAgency
          ? AGENCY_ROUTES.map((route) => <Route key={route.path} {...route} />)
          : isNotApproved
          ? renderRoute(USER_NOT_APPROVED_ROUTES)
          : renderRoute(USER_ROUTES)}
        <Route
          path='/'
          element={
            JSON.parse(Storage.get(STORAGE.VERIFY_AGE)) && isAuth ? (
              <CreatorHomePage />
            ) : JSON.parse(Storage.get(STORAGE.VERIFY_AGE)) && !isAuth ? (
              <SignIn />
            ) : (
              <VerifyAge />
            )
          }
        />
        <Route path='*' element={<Navigate to='/not-found' />} />
      </Routes>
    </NewFansiteLayout>
  );
}
