import { AvatarInfor, ButtonBookmark, ListImages, ReadMoreLess, ViewFullImage } from 'components';
import useUserRole from 'hooks/useUserRole';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { postBookMarkDetail } from 'store/actions/myPage';
import { StyledPostDetail } from './styles';

const ContentNormalType3 = ({ detailPost = {}, checkDisableWithType3And4 }) => {
  const dispatch = useDispatch();
  const [dataViewFull, setDataViewFull] = useState({});
  const { isIdol } = useUserRole();
  const { t } = useTranslation();
  const handlePostBookMark = () => {
    dispatch(postBookMarkDetail(detailPost?.id));
  };

  return (
    <>
      {dataViewFull?.image?.url && (
        <ViewFullImage dataViewFull={dataViewFull} close={() => setDataViewFull({})} />
      )}
      {detailPost?.medias?.length === 0 && (
        <ButtonBookmark post={detailPost} onClick={handlePostBookMark} />
      )}

      <AvatarInfor
        className='avatar-info-detail'
        images={detailPost?.user?.avatar}
        name={detailPost?.user?.account_name || detailPost?.user?.username}
        content1={`${t('Common.id')}: ${detailPost?.user?.account_id}`}
      />

      <div className='content'>
        <div className='text'>
          <ReadMoreLess content={detailPost?.content} />
        </div>
      </div>
      <StyledPostDetail.Wrapper>
        {checkDisableWithType3And4 && (
          <StyledPostDetail.WrapperBlur>
            {t('DetailCard.notYetFollow')}
          </StyledPostDetail.WrapperBlur>
        )}
        {detailPost?.medias?.length > 0 && (
          <>
            {(() => {
              const data = detailPost?.medias
                ?.filter((item) => item?.type === 1)
                .filter((item) => item.public_flag === 1);
              return (
                data.length > 0 && (
                  <>
                    <ListImages
                      images={data}
                      number_bookmark={detailPost?.number_bookmark}
                      id_post={detailPost?.id}
                      bookmark_status={detailPost?.user_bookmark_status}
                      post_type={detailPost?.type}
                      is_idol={isIdol}
                      isPostDetail
                      onClickImage={setDataViewFull}
                    />
                  </>
                )
              );
            })()}
          </>
        )}
      </StyledPostDetail.Wrapper>
    </>
  );
};

export default ContentNormalType3;
