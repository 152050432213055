import Icon from '@ant-design/icons';

const HiddenIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='14'
        height='10'
        fill='none'
        viewBox='0 0 14 10'
      >
        <path
          fill='#B1B6BB'
          d='M7 1.08C3.318 1.08.333 5.04.333 5.04S3.318 9.003 7 9.003c3.681 0 6.666-3.961 6.666-3.961S10.681 1.08 7 1.08zm0 7.053A3.092 3.092 0 116.99 1.95 3.092 3.092 0 017 8.133z'
        ></path>
        <path
          fill='#B1B6BB'
          d='M7 6.638a1.597 1.597 0 100-3.195 1.597 1.597 0 000 3.195z'
        ></path>
        <path
          fill='#B1B6BB'
          d='M2.51.564a.44.44 0 01.62 0L11.49 8.9a.436.436 0 01-.31.747.44.44 0 01-.311-.128l-8.36-8.337a.437.437 0 010-.618z'
        ></path>
      </svg>
    )}
    {...props}
  />
);

export default HiddenIcon;
