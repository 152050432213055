import {
  ListImages,
  ReadMoreLess,
  VideoDetail,
  ViewFullImage,
  PopupUserBuyPostF021,
  ButtonBookmark,
} from 'components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { convertToCurrentTime, formatCoin } from 'utils/utils';
import { postBookMarkDetail } from 'store/actions/myPage';

const ContentSale = ({ detailPost = {}, dataProfile = {}, sellBy }) => {
  const [dataViewFull, setDataViewFull] = useState({});
  const dispatch = useDispatch();
  const { t } = useTranslation();
  useEffect(() => {
    detailPost &&
      detailPost?.medias?.map((item) => item?.type === 4 && setThumbnail(item?.url));
  }, [detailPost]);
  const [thumbnail, setThumbnail] = useState();

  const handlePostBookMark = () => {
    dispatch(postBookMarkDetail(detailPost?.id));
  };

  return (
    <div className='px-16 mt-3 content-sale'>
      {dataViewFull?.image?.url && (
        <ViewFullImage dataViewFull={dataViewFull} close={() => setDataViewFull({})} />
      )}

      {detailPost?.title && (
        <div className='bold mb-4 text-center display-linebreak'>
          <ReadMoreLess content={detailPost?.title} />
        </div>
      )}

      {detailPost?.medias?.map(
        (item) =>
          item.type === 3 && (
            <div className='bookmark-wrapper'>
              <ButtonBookmark post={detailPost} onClick={handlePostBookMark} />
              <VideoDetail
                className='video-detail trailer_video fan'
                link={item.url}
                background_video={thumbnail}
              />
            </div>
          )
      )}

      {(() => {
        const data = detailPost?.medias
          ?.filter((item) => item?.type === 1)
          .filter((item) => item.public_flag === 1);
        return (
          data.length > 0 && (
            <ListImages
              images={data}
              className='custom-fan'
              is_idol={true}
              noClick={false}
              isPostDetail={true}
              onClickImage={setDataViewFull}
            />
          )
        );
      })()}

      <div className='content-body'>
        {detailPost?.content && (
          <div className='mb-4'>
            <div className='bold mb-2'>{t('PostDetail.productDesc')}</div>
            <div className='text'>
              <ReadMoreLess content={detailPost?.content} />
            </div>
          </div>
        )}

        {detailPost?.packages?.length > 0 && (
          <div className='mb-4'>
            <div className='bold mb-2'>{t('PostDetail.planContent')}</div>
            <div className='text'>
              {detailPost.all_package ? t('Common.allPlan') : detailPost.package_names}
            </div>
          </div>
        )}

        {/* {detailPost?.number_part && (
          <div className='mb-4'>
            <div className='bold mb-2'>{t('PostDetail.partNumber')}</div>
            <div className='text'>{detailPost.number_part || ''}</div>
          </div>
        )} */}

        {!!detailPost?.enable_schedule && (
          <div className='mb-4'>
            <div className='bold mb-2'>{t('PostDetail.salesPeriod')}</div>
            <div className='text'>
              {convertToCurrentTime(
                detailPost?.public_time || detailPost.created_at,
                'YYYY/MM/DD HH:mm:ss'
              )}
              {detailPost?.unpublic_time &&
                ' ～' + convertToCurrentTime(detailPost?.unpublic_time, 'YYYY/MM/DD HH:mm:ss')}
              {t('PostDetail.to')}
            </div>
          </div>
        )}

        <div className='mb-4'>
          <div className='bold mb-2'>{t('PostDetail.purchasePremiumTicket')}</div>
          <div className='text'>
            {detailPost.number_premium_ticket !== null
              ? t('PostDetail.premiumTicketTarget')
              : t('PostDetail.notPremiumTicketTarget')}
          </div>
        </div>

        {detailPost?.number_premium_ticket !== null && (
          <div className='mb-4'>
            <div className='bold mb-2'>{t('PostDetail.numberPremiumTicket')}</div>
            <div className='text'>{detailPost.number_premium_ticket + t('Common.sheet')}</div>
          </div>
        )}
      </div>
      <div className='buy-content'>
        <div className='_left'>
          <div className='bold'>{t('Popup.sellingPrice')}</div>
          <div className='_point'>
            {formatCoin(detailPost?.price)} {t('Common.pt')}
          </div>
        </div>
        <div className='_right'>
          <PopupUserBuyPostF021
            data_post={detailPost}
            data_profile={dataProfile}
            sellBy={sellBy}
          />
        </div>
      </div>
    </div>
  );
};

export default ContentSale;
