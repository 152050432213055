import Icon from '@ant-design/icons';

const CircleCheckIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={22}
        height={22}
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M14.7812 8.9375L9.73955 13.75L7.21875 11.3438'
          stroke='#52C41A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11 19.25C15.5563 19.25 19.25 15.5563 19.25 11C19.25 6.44365 15.5563 2.75 11 2.75C6.44365 2.75 2.75 6.44365 2.75 11C2.75 15.5563 6.44365 19.25 11 19.25Z'
          stroke='#52C41A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default CircleCheckIcon;
