import moment from 'moment';
import { useState, useEffect } from 'react';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ImgCustom, Tooltip2 } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import NoNotification from 'images/NoNotification.svg';

import { getIDNotiAdmin, userGetListNotification } from 'store/actions/notification';
import { LOGO_SHARE_GRAM, getTitleByTypeAdmin, isMappable } from 'utils';
import { useCallback } from 'react';

const TYPE_MANAGEMENT = 1;
const Management = ({ itemsPerPage, notifiRead, handleClickNotify, setFirstLoading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { listNotifyManager, idNoti2, initPageNoti } = useSelector((state) => state.notification);
  const [page, setPage] = useState(idNoti2 && initPageNoti ? +initPageNoti : 1);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const fetchNext = () => {
    funCallApi(page + 1);
    setPage(page + 1);
  }
  const funCallApi = useCallback(
    (page) => {
      setIsLoadingMore(true);
      dispatch(
        userGetListNotification(
          { type: TYPE_MANAGEMENT, page: page, limit: itemsPerPage },
          (_response) => {
            setIsLoadingMore(false);
          }
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page]
  );


  useEffect(() => {
    if (idNoti2) {
      const idPost = document.getElementById(idNoti2.id);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idNoti2]);

  useEffect(() => {
    if(initPageNoti < 1) {
      const top = document.getElementById('app_cocofans_0');
      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
      setFirstLoading(true);
      setIsLoading(true);
      dispatch(
        userGetListNotification(
          { type: TYPE_MANAGEMENT, page: page, limit: itemsPerPage },
          (_response) => {
            setIsLoading(false);
            setFirstLoading(false);
          }
        )
      );
    }
  }, []);

  // useEffect(() => {
  //   if (page === 1) return;

  //   setIsLoadingMore(true);
  //   dispatch(
  //     userGetListNotification(
  //       { type: TYPE_MANAGEMENT, page: page, limit: itemsPerPage },
  //       (_response) => {
  //         setIsLoadingMore(false);
  //       }
  //     )
  //   );
  // }, [page]);

  const language = localStorage.getItem('i18nextLng');

  return (
    <>
      <div className='list'>
        <InfiniteLoad
          key={TYPE_MANAGEMENT}
          loading={isLoadingMore}
          data={listNotifyManager}
          fetchNext={fetchNext}>
          {isLoading && !isLoadingMore ? (
            <div>
              <ShimmerPostDetails />
            </div>
          ) : (
            <>
              {isMappable(listNotifyManager)
                ? listNotifyManager.map((item, index) => {
                    const getDataFromContent = () => {
                      if (item?.content) {
                        try {
                          return JSON.parse(item?.content);
                        } catch (error) {
                          return {};
                        }
                      }
                      return {};
                    };

                    const data = getDataFromContent();
                    const origiTitie = () => {
                      if (item?.content_type === 30 || item?.content_type === 31) {
                        if (language === 'en') {
                          return item?.origin_title_en;
                        } else return item?.origin_title;
                      } else {
                        if (item?.origin_title) {
                          return item?.origin_title;
                        } else return item?.title;
                      }
                    };

                    return (
                      <div key={index} id={item?.id} className='item'>
                        <ImgCustom
                          src={LOGO_SHARE_GRAM}
                          alt='avatar image'
                          screen={'avatar_notification_list_60_60'}
                          className={'logo-coco-fan'}
                        />
                        <div
                          className={`${
                            item?.is_read !== 0 || notifiRead.includes(item?.id)
                              ? '--isRead'
                              : ''
                          } `}>
                          <div
                            className={`d-flex justify-content-between w-100`}
                            onClick={() => {
                              if (!notifiRead.includes(item?.id)) {
                                handleClickNotify(item)
                              }
                            }}>
                            <div className='name fw-bold'>Sharegram</div>
                            <div className='date'>
                              <div className='date'>
                                {item?.start_time &&
                                  moment
                                    .utc(item?.start_time)
                                    .local()
                                    .format('YYYY/MM/DD HH:mm')}
                              </div>
                            </div>
                          </div>
                          <div className={`content mt-2 fw-normal`}>
                            <Tooltip2
                              className={'custom-text creator'}
                              onClick={() => {
                                if (!notifiRead.includes(item?.id)) {
                                  handleClickNotify(item)
                                }
                              }}
                              text={getTitleByTypeAdmin(
                                +item?.title,
                                origiTitie(),
                                data?.bonus
                              )}>
                              {getTitleByTypeAdmin(
                                +item?.title,
                                origiTitie(),
                                data?.bonus
                              )}
                            </Tooltip2>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : !isLoading && (
                    <div className='no-result'>
                      <img
                        src={NoNotification}
                        alt='No Notification'
                        width={100}
                        height={100}
                      />
                      {t('Creator.NotificationList.noNotifications')}
                    </div>
                  )}
            </>
          )}
        </InfiniteLoad>
      </div>
    </>
  );
};
export default Management;
