import { IconTypePost, LayoutCreatorRight, ListImages } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import useLoading from 'hooks/useLoading';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  fanViewed,
  fanViewedPosts,
  handleResetFanViewed,
  handleResetFanViewPost
} from 'store/actions/users';
import { LIST_TAB_BROWSER_HISTORY } from 'utils/utils';
import '../styles.scss';

export default function Index() {
  const dispatch = useDispatch();
  const { listFanViewedPosts, listFanViewed, loading } = useSelector((state) => state.users);
  const [tab, setTab] = useState(0);
  const [pageView, setPageView] = useState(1);
  const [pagePost, setPagePost] = useState(1);
  const isLoading = useLoading();
  const { t } = useTranslation();
  const [loadingCustom, setLoadingCustom] = useState(false);
  const loadingFunc = () => {
    setLoadingCustom(true);
    setTimeout(() => {
      setLoadingCustom(false);
    }, 1000);
  };
  const fetchNextView = () => {
    setPageView(pageView + 1);
  };
  const fetchNextPost = () => {
    setPagePost(pagePost + 1);
  };

  useEffect(() => {
    if (tab === 0) {
      dispatch(fanViewed(pageView, 20));
    } else {
      dispatch(fanViewedPosts(pagePost, 20));
    }
  }, [pageView, pagePost, tab]);

  const handleChangeTab = (id) => {
    if (tab !== id) {
      setTab(id);
      id === 0 ? setPageView(1) : setPagePost(1);
      loadingFunc();
      id === 0 ? dispatch(handleResetFanViewPost()) : dispatch(handleResetFanViewed());
    }
  };

  const handleLeftIcon = (item) => {
    if (item.type === 1 && item?.medias?.length > 0) {
      return item?.medias?.[0]?.type === 2 ? (
        <IconTypePost type='video' />
      ) : (
        <IconTypePost type='image' />
      );
    }
  };

  return (
    <LayoutCreatorRight titlePage={t('BrowsingHistory.title')}>
      <div className='list-page browse-history'>
        <div className='body'>
          <div className='button-container'>
            {LIST_TAB_BROWSER_HISTORY.map((v, i) => (
              <div
                key={i}
                className={tab === v.id ? `--active` : `--default`}
                onClick={() => handleChangeTab(v.id)}>
                {v.text}
              </div>
            ))}
          </div>
          {tab === 0 ? (
            <>
              {listFanViewed?.length > 0 ? (
                <InfiniteLoad loading={loading} data={listFanViewed} fetchNext={fetchNextView}>
                  <div className='grid'>
                    {listFanViewed.map((item, index) => (
                      <div key={index} className='post' style={{ cursor: 'pointer' }}>
                        <div className='type'>{handleLeftIcon(item)}</div>
                        <ListImages
                          images={item?.medias}
                          number_bookmark={item?.number_bookmark}
                          id_post={item?.id}
                          bookmark_status={item?.user_bookmark_status}
                          post_type={item?.type}
                          isMyPage
                          show_img_demo
                          show_1_img
                          play_video={false}
                        />
                      </div>
                    ))}
                  </div>
                </InfiniteLoad>
              ) : (
                <div className='no-result'>{t('BrowsingHistory.listNull')}</div>
              )}
            </>
          ) : (
            <>
              {listFanViewedPosts?.length > 0 ? (
                <InfiniteLoad
                  loading={loading}
                  data={listFanViewedPosts}
                  fetchNext={fetchNextPost}>
                  <div className='grid'>
                    {listFanViewedPosts.map((item, index) => (
                      <div key={index} className='post' style={{ cursor: 'pointer' }}>
                        <div className='type'>
                          <div className='type'>{handleLeftIcon(item)}</div>
                        </div>
                        <ListImages
                          images={item?.medias}
                          number_bookmark={item?.number_bookmark}
                          id_post={item?.id}
                          bookmark_status={item?.user_bookmark_status}
                          post_type={item?.type}
                          isMyPage
                          show_img_demo
                          show_1_img
                          play_video={false}
                        />
                      </div>
                    ))}
                  </div>
                </InfiniteLoad>
              ) : (
                <div className='no-result'>{t('BrowsingHistory.listNull')}</div>
              )}
            </>
          )}
        </div>
      </div>
    </LayoutCreatorRight>
  );
}
