import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getDetailRecording,
  resetActionDataPopupRecor,
  updateDetailRecording,
} from 'store/actions/creator';
import { formatCoin, formatNumber, linkS3, linkS3VideoM3U8 } from 'utils/utils';
import { PopupGeneral, PopupLoading, ToggleButton } from 'components';
import './index.scss';
import { ProfileIcon } from 'images';
import { convertToCurrentTime, linkS3Video, decryptPath, LOGO_THUMBNAIL_COCOFAN } from 'utils';
import VideoCustomDetail from 'pages/creator/layout/recording/VideoCustomDetail';
import LoadingIconV2 from 'images/LoadingIconV2';
import { updateDetailRecordingV2 } from 'store/actions/usersNew';

const PopupRecordingDetailMyPage = ({
  classNameCustom = '',
  closeModal,
  item,
  isVisible,
  setIsVisibleDel,
}) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.creator);
  const { t } = useTranslation();
  const { dataUser, dataInfoOther } = useSelector((state) => state.users);

  // const isShowRecording = useMemo(() => {
  //   if (item?.is_show === 1 || item?.is_show === 0) {
  //     if (item?.is_show === 1) return true;
  //     else return false;
  //   }
  // }, [recordingDetail?.is_show]);

  const handleShowRecording = (key, data) => {
    dispatch(updateDetailRecordingV2(key, data));
  };

  const imgLink =
    decryptPath(item?.img_background) !== 'NULL'
      ? `${item?.img_background}`
      : LOGO_THUMBNAIL_COCOFAN;

  return (
    <>
      {loading && (
        <div className='layer-spinner'>
          <LoadingIconV2 className='spinner-icon' />
        </div>
      )}
      <>
        <PopupGeneral
          className={`popup-recording-detail ${classNameCustom}`}
          generalClass='popup-fan-detail-view'
          closeModal={() => {
            closeModal(false);
            dispatch(resetActionDataPopupRecor());
          }}
          title={item?.live_title}>
          <div className='mt-3'>
            <VideoCustomDetail
              src={`${linkS3VideoM3U8}${decryptPath(item?.recording) + '#t=0.1'}`}
              thumbnail={decryptPath(imgLink)}
              className='record-detail'
              isLiveArchive
              handleNavigate={() => {}}
            />
          </div>
          <div className='gap-4 gap-sm-5 mt-3'>
            <div className='d-flex gap-2 align-items-center view-number'>
              <ProfileIcon className='profile-icon' />
              <p className='mb-0 cls-text fw-normal'>
                {formatCoin(item?.views_count ? item?.views_count : 0)}
              </p>
            </div>
            <div className='wrap--text'>
              <p className='cls-title-2nd cls-date fw-normal'>
                {convertToCurrentTime(item?.live_date, 'YYYY/MM/DD HH:mm')}
              </p>
            </div>
          </div>
          {/* {dataUser?.id === dataInfoOther?.id && (
            <div className='btn-wrapper'>
              <div className={`button btn-add-livestream`}>
                {t('Popup.showRecording')}
                <div className='toggle'>
                  <div>
                    <ToggleButton
                      id='plan_notification'
                      checked={item?.is_show === 1 ? true : false}
                      onChange={(e) => {
                        let data = 1;
                        if (e) {
                          data = 1;
                        } else {
                          data = 0;
                        }
                        handleShowRecording(item?.id, {
                          is_show: data,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )} */}
        </PopupGeneral>
      </>
    </>
  );
};

export default PopupRecordingDetailMyPage;
