import { useEffect, useState } from 'react';

import { formatCoin, itemPerPages } from '../../utils/utils';
// import InfiniteLoad from 'components/Paging/InfiniteLoad';
import InfiniteLoadingLive from 'components/Paging/InfiniteLoadingLive';

import { useDispatch, useSelector } from 'react-redux';
import { getTopDonate, resetDataTopDonate } from 'store/actions/lives';

import lodash from 'lodash';
import Loading from '../Paging/Loading';

import { AVATAR_DEFAULT } from 'utils/constant';
import { useTranslation } from 'react-i18next';
import { ImgCustom, Tooltip2 } from '..';
import Top1Icon from 'images/rank/Top1Icon';
import Top2Icon from 'images/rank/Top2Icon';
import Top3Icon from 'images/rank/Top3Icon';
import CoinRankIcon from 'images/rank/CoinRank';
import { WrapperItemLiveRankFan } from './fanRanking';

const C092PopupFanRanking = ({ liveKey, page, isMobile = false }) => {
  let { loadingTopDonate, dataTopDonate } = useSelector((state) => state.lives);
  const { t } = useTranslation();
  const [pageTopDonate, setPageTopDonate] = useState(page);
  const itemPerPage = itemPerPages;
  const dispatch = useDispatch();
  const fetchNext = () => {
    setPageTopDonate(pageTopDonate + 1);
  };

  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingInfinite, setLoadingInfinite] = useState(false);

  useEffect(() => {
    if (loadingTopDonate) {
      if (pageTopDonate === 1) {
        setLoadingPage(true);
        setLoadingInfinite(false);
      } else {
        setLoadingPage(false);
        setLoadingInfinite(true);
      }
    } else {
      setLoadingPage(false);
      setLoadingInfinite(false);
    }
  }, [loadingTopDonate, pageTopDonate]);

  useEffect(() => {
    return () => dispatch(resetDataTopDonate());
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(getTopDonate(liveKey, pageTopDonate, itemPerPage));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTopDonate]);

  if (dataTopDonate.length > 0) {
    //const result = lodash.reverse(lodash.uniqBy(lodash.reverse([...dataTopDonate]), 'user_id'));
    dataTopDonate = lodash.uniqBy(dataTopDonate, 'user_id');
  }

  return (
    <>
      <InfiniteLoadingLive
        loading={loadingInfinite}
        data={dataTopDonate}
        style={{
          maxHeight: `${isMobile ? '505px' : '706px'}`,
        }}
        scrollableTarget='ranking-live-fan'
        fetchNext={fetchNext}>
        {loadingPage ? <Loading /> : null}
        {!loadingTopDonate && !dataTopDonate.length && (
          <p
            className=''
            style={{
              fontWeight: 'normal',
              padding: '50px',
              textAlign: 'center',
              color: '#282727',
            }}>
            {t('Popup.noRanking')}
          </p>
        )}
        <WrapperItemLiveRankFan>
          {dataTopDonate.length > 0 &&
            dataTopDonate.map((item, index) => (
              <div className='item' key={index}>
                {isMobile ? (
                  <>
                    <div className='info-user mobile'>
                      <span className='ranking'>
                        {item?.rank === 1 && <Top1Icon />}
                        {item?.rank === 2 && <Top2Icon />}
                        {item?.rank === 3 && <Top3Icon />}
                        {item?.rank > 3 && item?.rank}
                      </span>
                      <ImgCustom
                        src={item?.avatar === null ? AVATAR_DEFAULT : `${item?.avatar}`}
                        alt=''
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = AVATAR_DEFAULT;
                        }}
                        className='avatar'
                      />
                      <div>
                        <div className='name' style={{ overflow: 'unset' }}>
                          <Tooltip2
                            text={item?.account_name}
                            className={`ranking-name-tooltip-wrapper`}>
                            {item?.account_name}
                          </Tooltip2>
                        </div>
                        <div className='box-coin'>
                          <CoinRankIcon />
                          <span className='number-coin'>{formatCoin(item?.points)}</span>
                          <span className='text-coin'>{t('Common.point')}</span>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='info-user'>
                      <span className='ranking'>
                        {item?.rank === 1 && <Top1Icon />}
                        {item?.rank === 2 && <Top2Icon />}
                        {item?.rank === 3 && <Top3Icon />}
                        {item?.rank > 3 && item?.rank}
                      </span>
                      <ImgCustom
                        src={item?.avatar === null ? AVATAR_DEFAULT : `${item?.avatar}`}
                        alt=''
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = AVATAR_DEFAULT;
                        }}
                        className='avatar'
                      />
                      <span className='name'>
                        <Tooltip2
                          text={item?.account_name}
                          className={`ranking-name-tooltip-wrapper`}>
                          {item?.account_name}
                        </Tooltip2>
                      </span>
                    </div>
                    <div className='box-coin'>
                      <CoinRankIcon />
                      <span className='number-coin'>{formatCoin(item?.points)}</span>
                      <span className='text-coin'>{t('Common.point')}</span>
                    </div>
                  </>
                )}
              </div>
            ))}
        </WrapperItemLiveRankFan>
      </InfiniteLoadingLive>
    </>
  );
};

export default C092PopupFanRanking;
