import Icon from '@ant-design/icons';

const DeleteRecoreIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={30}
        height={30}
        viewBox='0 0 30 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx={15} cy={15} r={15} fill='white' />
        <path
          d='M21.875 9.375L8.125 9.37501'
          stroke='#DC143C'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.125 13.125V18.125'
          stroke='#DC143C'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.875 13.125V18.125'
          stroke='#DC143C'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M20.625 9.375V21.25C20.625 21.4158 20.5592 21.5747 20.4419 21.6919C20.3247 21.8092 20.1658 21.875 20 21.875H10C9.83424 21.875 9.67527 21.8092 9.55806 21.6919C9.44085 21.5747 9.375 21.4158 9.375 21.25V9.375'
          stroke='#DC143C'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18.125 9.375V8.125C18.125 7.79348 17.9933 7.47554 17.7589 7.24112C17.5245 7.0067 17.2065 6.875 16.875 6.875H13.125C12.7935 6.875 12.4755 7.0067 12.2411 7.24112C12.0067 7.47554 11.875 7.79348 11.875 8.125V9.375'
          stroke='#DC143C'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default DeleteRecoreIcon;
