import { ImgCustom } from 'components';
import { newPathUser } from 'constants/layout/constant';
import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const AvatarInfo = ({ item, handleFollowCreator, userId, setIdAndNavigate }) => {
  const { t } = useTranslation();

  const [isHover, setIsHover] = useState(false);
  const { dataUser } = useSelector((state) => state.users);

  const navigateUser = () => {
    setIdAndNavigate(item?.id, `${newPathUser}${item?.account_id}`);
  };
  return (
    <div className='info-user'>
      <div className='info-user-avatar'>
        <div className='avatar' onClick={navigateUser}>
          <ImgCustom
            isPlaceholderAvatar
            className='img-avatar'
            screen='recommend_top_56_56'
            src={item?.avatar}
          />
        </div>
        <div className='name'>
          <div className='account-name' onClick={navigateUser}>
            {item?.account_name}
          </div>
          <div className='account_id'>{item?.account_id}</div>
        </div>
      </div>
      <div className='info-user-follow'>
        {dataUser?.id !== item?.user_id && (
          <div
            className={`btn-followOrUnFollow ${
              item?.is_followed === 1 ? 'following' : 'no-follow'
            } ${isMobile ? '' : 'hover-color'}`}
            onMouseOver={() => {
              if (item?.is_followed === 1 && item?.id) {
                !isMobile && setIsHover(true);
              }
            }}
            onMouseLeave={() => {
              if (item?.is_followed === 1) {
                setIsHover(false);
              }
            }}
            onClick={() => handleFollowCreator(item)}>
            {item?.is_followed === 1
              ? isHover
                ? t('Creator.FollowList.unfollow')
                : t('Creator.FollowList.following')
              : t('Creator.FollowList.followUs')}
          </div>
        )}
      </div>
    </div>
  );
};
export default AvatarInfo;
