import { CheckboxWrapper } from './style';

const Checkbox = ({
  className = '',
  type = 'checkbox',
  disabled,
  checked,
  text = '',
  value,
  key = '',
  defaultChecked,
  register,
  direction = 'column',
  style,
  required,
  ...props
}) => {
  return (
    <CheckboxWrapper
      className={`label-checkbox ${className} ${type} ${disabled && 'disable'} ${direction}`}
      style={style}>
      <input
        type={type}
        value={value}
        checked={checked}
        disabled={disabled}
        defaultChecked={defaultChecked}
        {...register}
        {...props}
      />
      <span className={`checkmark ${type} `} />
      <span className={`text ${disabled && 'disable'}`}>{text}</span>
      {required && <span className='required'>*</span>}
    </CheckboxWrapper>
  );
};

export default Checkbox;
