import React from 'react';
import { useTranslation } from 'react-i18next';
import ReactReadMoreReadLess from 'react-read-more-read-less';
import './index.scss';

const ReadMoreLess = ({
  content = '',
  readMoreText,
  readLessText,
  charLimit = 100,
  classNameButton,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <>
      {content && (
        <div className='content-current display-linebreak' {...props}>
          <ReactReadMoreReadLess
            ellipsis=''
            charLimit={charLimit}
            readMoreText={readMoreText || `${t('ReadMoreLess.moreText')}`}
            //readLessText={readLessText || `${t('ReadMoreLess.lessText')}`}
            readLessText={''}
            readMoreClassName={`read-more button-readmore ${classNameButton}`}
            //readLessClassName={`read-more button-readmore ${classNameButton}`}
            >
            {content?.toString()}
          </ReactReadMoreReadLess>
        </div>
      )}
    </>
  );
};

export default ReadMoreLess;
