export const CONTENT_EN = [
  {
    title: 'Introduction: ',
    content:
      'Although this Website is not based in the United States, we voluntarily comply with the requirements of 18 U.S.C. §§2257, 2257A and/or 28 C.F.R. §75 (“Section 2257”) in as far as we can under English Law.',
  },
  {
    title: 'Compliant Content: ',
    content:
      'All models, actors, actresses, and other persons that appear in any visual portrayal of actual or simulated sexually explicit conduct appearing on, or otherwise contained in, this Website were over the age of eighteen (18) years at the time the visual image was produced. Records required for all depictions of actual or simulated sexually explicit conduct by Title 18 U.S.C. §2257 and 28 C.F.R. §75, et seq., are on file with the appropriate Records Custodian.',
  },
  {
    title: 'Exempt Content: ',
    content:
      'All other visual depictions displayed on this Website are exempt from the provision of Section 2257 because: 1) they do not portray conduct as specifically listed in 18 U.S.C §2256 (2)(A) (i) through (iv); 2) they do not portray conduct as specifically listed in 18 U.S.C. §2257A produced after March 19, 2009; 3) they do not portray conduct listed in 18 U.S.C. §2256(2)(A)(v) produced after March 19, 2009; 4) the visual depictions were created prior to July 3, 1995; or, 5) this Company does not act as a “producer” with respect to the dissemination of such exempt images as that term is defined in 28 C.F.R. §75.1(c).',
  },
];

export const CONTENT_JP = [
  {
    title: 'はじめに: ',
    content:
      'この Web サイトは米国に拠点を置いていませんが、当社は自主的に 18 U.S.C. の要件に準拠しています。 §§2257、2257A および/または 28 C.F.R.英国法に基づいて可能な限り、§75 (「第 2257 条」) に準拠します。',
  },
  {
    title: '準拠コンテンツ: ',
    content:
      '本 Web サイトに登場する、または本 Web サイトに含まれる、実際のまたは模擬的な性的行為の視覚的描写に登場するすべてのモデル、俳優、女優、その他の人物は、本 Web サイトの公開時点で 18 歳以上でした。ビジュアルイメージを制作しました。合衆国法典第 18 条により、実際のまたは模擬的な性的行為のすべての描写に記録が必要です。 §2257 および 28 C.F.R. §75 以降は、適切な記録管理者にファイルされています。',
  },
  {
    title: '適用除外コンテンツ: ',
    content:
      'この Web サイトに表示されるその他すべての視覚的描写は、次の理由により第 2257 条の規定から免除されます。1) それらは、18 U.S.C §2256 (2)(A) (i) から (iv) に具体的にリストされている行為を描写していない。 2) 合衆国法典第 18 条に具体的に記載されているような行為は描写されていません。 §2257A 2009 年 3 月 19 日以降に作成。 3) これらは、合衆国法典第 18 条に記載されている行為を描写していません。 §2256(2)(A)(v) 2009 年 3 月 19 日以降に作成。 4) 視覚的描写は 1995 年 7 月 3 日より前に作成されたもの。または、5) 当社は、その用語が 28 C.F.R. で定義されている免除画像の配布に関して「プロデューサー」としての役割を果たしていません。 §75.1(c)。',
  },
];
