import { getEnv } from 'configs/env';
import { configParamsGet, configParamsPUT, updateStatus } from 'utils/constant';
import { callApi } from 'utils/request';
import { TYPES } from './index';

const ENV = getEnv('REACT_APP_API_SERVER');
const API = {
  getInforBankAccount: () => `${ENV}/fan/bank-account`,
  putInforBankAccount: () => `${ENV}/fan/bank-account`,
};
const getInforBankAccount = () => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_INFOR_BANK_ACCOUNT_REQUEST));
    callApi(
      API.getInforBankAccount(),
      configParamsGet,
      () => null,
      async (reponse) => {
        await dispatch(
          updateStatus(TYPES.GET_INFOR_BANK_ACCOUNT_SUCCESS, {
            data: reponse.data,
          })
        );
      },
      async (error) => {
        await dispatch(
          updateStatus(TYPES.GET_INFOR_BANK_ACCOUNT_FAILED, { error: error.errors })
        );
      }
    );
  };
};

const putInforBankAccount = (data) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.PUT_INFOR_BANK_ACCOUNT_REQUEST));
    callApi(
      API.putInforBankAccount(),
      configParamsPUT(data),
      () => null,
      async (reponse) => {
        await dispatch(
          updateStatus(TYPES.PUT_INFOR_BANK_ACCOUNT_SUCCESS, {
            data: reponse.data,
          })
        );
      },
      async (error) => {
        await dispatch(
          updateStatus(TYPES.PUT_INFOR_BANK_ACCOUNT_FAILED, { error: error.errors })
        );
      }
    );
  };
};

const resetActionBankAccount = () => (dispatch) => {
  dispatch(updateStatus('RESET_ACTION_BANK_ACCOUNT'));
};

export { getInforBankAccount, putInforBankAccount, resetActionBankAccount };
