/* eslint-disable react-hooks/exhaustive-deps */
import { ReactCalendar } from 'components';
import useCalendar from 'hooks/useCalendar';
import { CalendarIconSelect } from 'images';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOutsideClickCustom from '../../hooks/useOutsideClickCustom';
import './index.scss';

const FORMAT = 'YYYY/MM';
const PickMonthCalendar = ({ onEmitDate, label, customPositionCalendar = 'bottom' }) => {
  const {
    date: from_date,
    toggleCalendar: toggleStartDate,
    onChangeDate: onChangeStartDate,
    isVisibleCalendar: isVisibleStartDate,
    checkOldDate: checkOldDateStart,
  } = useCalendar({ format: FORMAT });

  const {
    date: to_date,
    toggleCalendar: toggleEndDate,
    onChangeDate: onChangeEndDate,
    isVisibleCalendar: isVisibleEndDate,
    checkOldDate: checkOldDateEnd,
  } = useCalendar({ format: FORMAT });

  const datePickerRef1 = useRef();
  const datePickerRef2 = useRef();
  const { t } = useTranslation();

  useOutsideClickCustom((e) => {
    if (isVisibleStartDate) {
      const { className } = e?.target;
      if (
        e?.target !== datePickerRef1?.current &&
        className !== '' &&
        !className.includes('react-calendar')
      ) {
        toggleStartDate();
      }
    }
  });
  useOutsideClickCustom((e) => {
    if (isVisibleEndDate) {
      const { className } = e?.target;
      if (
        e?.target !== datePickerRef2?.current &&
        className !== '' &&
        !className.includes('react-calendar')
      ) {
        toggleEndDate();
      }
    }
  });

  const [isShowToDate, setIsShowDate] = useState(false);
  const [autoToDate, setAutoToDate] = useState();
  const [minFromDate, setMinFromDate] = useState();

  const handleMaxDate = () => {
    const fromDate = moment(from_date, 'YYYY/MM').add(11, 'M').toDate();
    return fromDate.getTime();
  };

  const handleMinDate = () => {
    const toDate = moment(
      moment(to_date, 'YYYY/MM').subtract(11, 'M').format('YYYY/MM'),
      'YYYY/MM'
    ).format('YYYYMM');
    return toDate;
  };

  const handleStartDate = useCallback(() => {
    if (from_date) {
      const setToDateAdd12 = moment(from_date, 'YYYY/MM').add(11, 'M').format('YYYY/MM');
      const currentMonth = moment().format('YYYY/MM');
      setAutoToDate(handleMaxDate() > new Date().getTime() ? currentMonth : setToDateAdd12);
      const formatFromDate = moment(from_date, 'YYYY/MM').format('YYYYMM');
      const formatToDate = moment(
        handleMaxDate() > new Date().getTime() ? currentMonth : setToDateAdd12,
        'YYYY/MM'
      ).format('YYYYMM');
      onEmitDate({
        from_date: formatFromDate,
        to_date: formatToDate,
      });
      setIsShowDate(false);
      setMinFromDate();
    }
  }, [from_date, checkOldDateStart]);

  useEffect(() => {
    handleStartDate();
  }, [handleStartDate]);

  const handleEndDate = useCallback(() => {
    if (from_date && to_date) {
      const formatFromDate = moment(from_date, 'YYYY/MM').format('YYYYMM');
      const formatToDate = moment(to_date, 'YYYY/MM').format('YYYYMM');
      onEmitDate({ from_date: formatFromDate, to_date: formatToDate });
      setAutoToDate(to_date);
      setIsShowDate(true);
      setMinFromDate();
    } else if (!from_date && to_date) {
      const formatToDate = moment(to_date, 'YYYY/MM').format('YYYYMM');
      onEmitDate({ from_date: handleMinDate(), to_date: formatToDate });
      setAutoToDate(to_date);
      setIsShowDate(true);
      setMinFromDate(moment(handleMinDate(), 'YYYYMM').format('YYYY/MM'));
    }
  }, [to_date, checkOldDateEnd]);

  useEffect(() => {
    handleEndDate();
  }, [handleEndDate]);

  return (
    <div className='pick-month py-3'>
      <p className='pick-month-label mb-1'>{label}</p>
      <p className='pick-month-des'>{t('PickMonthCalendar.year')}</p>
      <div className='pick-month-input'>
        <div className='pick-month-input__value cursor-pointer' onClick={toggleStartDate}>
          <span>{t('Common.start')}</span>
          <span className='text-dark px-2'>{minFromDate ? minFromDate : from_date}</span>
        </div>
        <svg
          width='12'
          height='6'
          viewBox='0 0 12 6'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M11.6422 4.31563L9.07969 1.06563C9.03296 1.00629 8.97339 0.958321 8.90547 0.925306C8.83755 0.892291 8.76302 0.875092 8.6875 0.875H7.675C7.57031 0.875 7.5125 0.995312 7.57656 1.07812L9.83125 3.9375H0.375C0.30625 3.9375 0.25 3.99375 0.25 4.0625V5C0.25 5.06875 0.30625 5.125 0.375 5.125H11.2484C11.6672 5.125 11.9 4.64375 11.6422 4.31563Z'
            fill='#B1B6BB'
          />
        </svg>
        &nbsp;
        <div className='pick-month-input__value cursor-pointer' onClick={toggleEndDate}>
          <span>{t('Common.end')}</span>
          <span className='text-dark px-2'>{isShowToDate ? to_date : autoToDate}</span>
        </div>
        <CalendarIconSelect
          color={(isVisibleStartDate || isVisibleEndDate) && '#DC143C'}
          className='icon'
          onClick={toggleEndDate}
        />
        {isVisibleStartDate && (
          <ReactCalendar
            formatMMM={true}
            onChange={onChangeStartDate}
            onClickMonth={onChangeStartDate}
            onClickYear={onChangeStartDate}
            customPosition={`${customPositionCalendar === 'top' ? 'top-left' : 'bottom-left'}`}
            view='year'
            inputRef={datePickerRef1}
            maxDate={new Date()}
          />
        )}
        {isVisibleEndDate && (
          <ReactCalendar
            formatMMM={true}
            onChange={onChangeEndDate}
            onClickMonth={onChangeEndDate}
            onClickYear={onChangeEndDate}
            view='year'
            maxDate={
              from_date
                ? handleMaxDate() > new Date().getTime()
                  ? new Date()
                  : new Date(handleMaxDate(from_date))
                : new Date()
            }
            minDate={new Date(moment(from_date, 'YYYY/MM').format('YYYY-MM-DD'))}
            customPosition={`${
              customPositionCalendar === 'top' ? 'top-right' : 'bottom-right'
            }`}
            className={`${customPositionCalendar === 'top' ? 'top-calendar' : ''}`}
            inputRef={datePickerRef2}
          />
        )}
      </div>
    </div>
  );
};

export default PickMonthCalendar;
