import {
  ImgCustom,
  PaginationTable,
  PopupConfirmFan,
  ReactCalendar,
  TableNoBorderRadius,
} from 'components';
import useCalendar from 'hooks/useCalendar';
import useOutsideClickCustom from 'hooks/useOutsideClickCustom';
import { CalendarIcon } from 'images';
import { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getLimitPaymentAffilicate,
  getListRevenueSalesHistory,
  getListRevenueSumary,
} from 'store/actions/affiliator';
import { postUserWithdrawRequest } from 'store/actions/saleConfirmation';
import { getUserInfo } from 'store/actions/users';
import { formatCSV_YYYYMMDD } from 'utils/constant';
import { convertToCurrentTime, formatCoin, linkS3 } from 'utils/utils';
import './index.scss';
const SalesHistory = ({ tabActive }) => {
  const navigate = useNavigate();
  const { data: dataProfile } = useSelector((state) => state.users);
  const [page, setPage] = useState(1);
  const [valueInputSearch, setValueInputSearch] = useState();
  const [dataListSalesHistory, setDataListSalesHistory] = useState([]);
  const [dataListSalesHistoryCSV, setDataListSalesHistoryCSV] = useState([]);
  const [pagination, setPagination] = useState({});
  const dispatch = useDispatch();
  const [searchCount, setSearchCount] = useState(0);
  const [numberAmount, setNumberAmount] = useState();
  const [revenueMonthCurrent, setRevenueMonthCurrent] = useState(0);
  const [isVisiblePopupWithdrawSuccess, setIsVisiblePopupWithdrawSuccess] = useState(false);
  const [minPrice, setMinPrice] = useState(0);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (tabActive === t('Common.sales_history')) {
      dispatch(
        getLimitPaymentAffilicate(({ data }) => data && setMinPrice(data.min_price || 0))
      );
      dispatch(getUserInfo());
      dispatch(
        getListRevenueSumary(({ data }) => setRevenueMonthCurrent(data.revenue_month_current))
      );
    }
  }, [tabActive]);

  useEffect(() => {
    tabActive === t('Common.sales_history') &&
      dispatch(
        getListRevenueSalesHistory(
          { from_date, to_date, full_name: valueInputSearch, page, limit: 20 },
          ({ data, pagination }) => {
            setPagination(pagination);
            setDataListSalesHistory(data);
          }
        )
      );
  }, [page, searchCount, tabActive]);

  useEffect(() => {
    tabActive === t('Common.sales_history') &&
      dispatch(
        getListRevenueSalesHistory(
          { from_date, to_date, full_name: valueInputSearch },
          (data) => {
            setDataListSalesHistoryCSV(
              data.map((row) => ({
                ...row,
                updated_at: `${
                  formatCSV_YYYYMMDD +
                  convertToCurrentTime(row.updated_at, 'YYYY/MM/DD HH:mm:ss')
                }`,
                profit_point: `${row.profit_point} ${t(
                  'Common.yen'
                )}`,
              }))
            );
          }
        )
      );
  }, [searchCount, tabActive]);

  //START REACT CALENDAR
  const {
    date: from_date,
    toggleCalendar: toggleCalendar1,
    onChangeDate: onChangeDate1,
    isVisibleCalendar: isVisibleCalendar1,
  } = useCalendar();
  const {
    date: to_date,
    toggleCalendar: toggleCalendar2,
    onChangeDate: onChangeDate2,
    isVisibleCalendar: isVisibleCalendar2,
  } = useCalendar();

  const datePickerRef1 = useRef();
  const datePickerRef2 = useRef();

  useOutsideClickCustom((e) => {
    if (isVisibleCalendar1) {
      const { className } = e?.target;
      if (
        e?.target !== datePickerRef1?.current &&
        className !== '' &&
        !className.includes('react-calendar')
      ) {
        toggleCalendar1();
      }
    }
  });
  useOutsideClickCustom((e) => {
    if (isVisibleCalendar2) {
      const { className } = e?.target;
      if (
        e?.target !== datePickerRef2?.current &&
        className !== '' &&
        !className.includes('react-calendar')
      ) {
        toggleCalendar2();
      }
    }
  });

  const renderTable = () => {
    return dataListSalesHistory?.length > 0 ? (
      dataListSalesHistory.map((item, id) => (
        <tr key={id}>
          <td className='td-date'>
            {convertToCurrentTime(item.updated_at, 'YYYY/MM/DD HH:mm:ss')}
          </td>
          <td className='td-name'>
            <ImgCustom
              src={`${item?.avatar}`}
              alt=''
              style={{ width: 44, height: 44, marginRight: 12 }}
            />
            {item?.full_name}
          </td>
          <td className='td-price'>
            {formatCoin(item.profit_point)}
            {i18n.language === 'en' && <span style={{ marginLeft: 3 }} />}
            {t('Common.yen')}
          </td>
        </tr>
      ))
    ) : (
      <div
        style={{
          width: '100%',
          height: `50vh`,
          color: '#B1B6BB',
        }}
        className='d-flex justify-content-center align-items-center'>
        {valueInputSearch && searchCount > 0
          ? t('Common.listNull')
          : `${t('Common.searchNoResult')}`}
      </div>
    );
  };

  const headersCSV = [
    { label: `${t('Common.created_at')}`, key: 'updated_at' },
    { label: `${t('Common.account')}`, key: 'full_name' },
    { label: `${t('Sales.amount_of_money')}`, key: 'profit_point' },
  ];
  const date = new Date();
  const nameFileCSV = `${t('Affiliator.AF004.earnings')}_${date
    ?.toISOString()
    ?.split('T')?.[0]
    ?.split('-')
    ?.join('')}${date?.toTimeString()?.split(' ')?.[0]?.split(':')?.join('')}`;

  return (
    <>
      {isVisiblePopupWithdrawSuccess && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopupWithdrawSuccess(e)}
          text={t('Popup.withdrawMoneySuccess')}>
          <div className='ok btn' onClick={() => navigate(0)}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}
      <div className='sales-history'>
        <div className='text-money withdrawable-amount'>
          <span>{t('Affiliator.AF004.withdrawable_amount')}</span>
          <span>
            {formatCoin(dataProfile?.affiliate_point)}
            {i18n.language === 'en' && <span style={{ marginLeft: 3 }} />}
            {t('Common.yen')}
          </span>
        </div>
        <div className='text-money total-sales-of-month'>
          <span>{t('Affiliator.AF004.totalSalesForTheCurrentMonth')}</span>
          <span>
            {formatCoin(revenueMonthCurrent)}
            {i18n.language === 'en' && <span style={{ marginLeft: 3 }} />}
            {t('Common.yen')}
          </span>
        </div>
        <div className='without-money wrapper'>
          <CurrencyFormat
            thousandSeparator={true}
            placeholder={t('Affiliator.AF004.enterAmount')}
            className='fsite-input without-money-input'
            decimalSeparator={false}
            decimalScale={0}
            onValueChange={(values) => {
              const { floatValue } = values;
              setNumberAmount(floatValue);
            }}
          />
          <button
            className={`without-money-button btn ${
              numberAmount > (dataProfile?.affiliate_point || 0) ||
              numberAmount < minPrice ||
              !numberAmount
                ? 'disabled'
                : 'active'
            }`}
            onClick={() => {
              dispatch(
                postUserWithdrawRequest({ amount: numberAmount }, () =>
                  setIsVisiblePopupWithdrawSuccess(true)
                )
              );
            }}>
            {t('Affiliator.AF004.drawer')}
          </button>
        </div>
        {numberAmount && numberAmount > (dataProfile?.affiliate_point || 0) ? (
          <p className='text-danger font-sm'>{t('Sales.withdrawal_amount_exceeds')}</p>
        ) : (
          ''
        )}
        {numberAmount && numberAmount < minPrice && numberAmount ? (
          <p className='text-danger font-sm'>
            {t('Sales.pointAdminSetting', { point: formatCoin(minPrice) })}
          </p>
        ) : (
          ''
        )}
        <div className='search'>
          <div className='title'>{t('Common.sales_history')}</div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'space-between',
            }}>
            <div className='content-left'>
              <div className='date-wrapper'>
                <label>{t('Common.created_at')}</label>
                <div className='date from-date'>
                  <input
                    onClick={toggleCalendar1}
                    value={from_date}
                    readOnly
                    className='fsite-input'
                  />
                  <CalendarIcon className='calendar-icon' onClick={toggleCalendar1} />
                  {isVisibleCalendar1 && (
                    <ReactCalendar
                      onChange={onChangeDate1}
                      maxDate={to_date && new Date(to_date)}
                      className='react-calendar-AF004'
                    />
                  )}
                </div>
                <div className='date to-date'>
                  <input
                    onClick={toggleCalendar2}
                    value={to_date}
                    readOnly
                    className='fsite-input'
                  />
                  <CalendarIcon className='calendar-icon' onClick={toggleCalendar2} />
                  {isVisibleCalendar2 && (
                    <ReactCalendar
                      onChange={onChangeDate2}
                      minDate={from_date && new Date(from_date)}
                      className='react-calendar-AF004'
                    />
                  )}
                </div>
              </div>
              <div className='account-wrapper'>
                <label>{t('Common.account')}</label>
                <input
                  className='fsite-input'
                  placeholder={t('Affiliator.AF004.placeHolderSearch')}
                  onChange={(e) => setValueInputSearch(e.target.value)}
                />
                <button
                  className={`btn button-search active`}
                  onClick={() => setSearchCount(searchCount + 1)}>
                  {t('Search.title')}
                </button>
              </div>
            </div>
            <div className='content-right'>
              <CSVLink
                data={dataListSalesHistoryCSV || []}
                headers={headersCSV}
                className='btn btn-export-csv d-middle'
                filename={nameFileCSV}>
                {t('Common.csv')}
              </CSVLink>
            </div>
          </div>
          <TableNoBorderRadius
            className='table-sales-history'
            pagination={() => (
              <div
                className={`pagination-bar ${dataListSalesHistory?.length === 0 && 'd-none'}`}>
                <PaginationTable
                  itemsPerPage={20}
                  total={pagination?.total}
                  currentPageTable={(e) => setPage(e)}
                />
              </div>
            )}>
            <thead>
              <tr>
                <th className='td-date'>{t('Common.created_at')}</th>
                <th className='td-name'>{t('Common.account')}</th>
                <th className='td-price'>{t('Affiliator.AF004.type')}</th>
              </tr>
            </thead>
            <tbody>{renderTable()}</tbody>
          </TableNoBorderRadius>
        </div>
      </div>
    </>
  );
};

export default SalesHistory;
