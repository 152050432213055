import React, { useState } from 'react';
import './styles.scss';
import Avt from 'images/AvatarDefault.svg';
import { formatToDateTimeString, linkS3 } from 'utils/utils';
import { ArrowDownIcon } from 'images';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ImgCustom } from '../../components';

export default function EventItem({ key, item }) {
  const [toggleShowMore, setToggleShowMore] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const handleMovePage = (item) => {
    if (!item.livestream) {
      navigate(`/post/${item.id}`);
    } else {
      item.check_live
        ? navigate(`/lives/${item?.livestream?.live_key}`)
        : navigate(`/lives/list/live`);
    }
  };
  const widthItem = document.querySelector(
    '.calendar-page.fan .event-container .event-time'
  )?.offsetWidth;

  return (
    <>
      {item.length > 0 && item.length === 1 ? (
        <>
          <div
            className={`item`}
            key={key}
            style={{ '--widthItemItemCalendarFan': `${widthItem}px` }}>
            <ImgCustom
              src={item[0]?.user?.avatar ? `${item[0]?.user?.avatar}` : Avt}
              alt='avt'
              onClick={() => handleMovePage(item[0])}
            />
            <div onClick={() => handleMovePage(item[0])}>
              <div className='name'>
                <label
                  className={`text ${item[0].check_live ? 'isStreaming' : 'noStreaming'}`}>
                  {item[0]?.user?.account_name || 'Name creator'}
                </label>
                {item[0]?.livestream && item[0].check_live && (
                  <label className='live'>{t('Common.live')}</label>
                )}
              </div>
              <div style={{ display: 'flex' }}>
                <label className={`content`}>
                  {!item[0]?.livestream ? t('Common.post') : t('Common.liveStreamingList')}
                  {` - `}
                  {item[0]?.title}
                </label>
                <label className='time'>
                  {'  •  '} {formatToDateTimeString(new Date(item[0]?.time_start_event))}
                </label>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <ArrowDownIcon
            className={`icon-show-more ${toggleShowMore && 'active'}`}
            onClick={() => setToggleShowMore(!toggleShowMore)}
          />
          {item.slice(0, toggleShowMore ? item?.length : 1).map((x) => (
            <div
              className={`item`}
              key={key}
              style={{ '--widthItemItemCalendarFan': `${widthItem}px` }}>
              <ImgCustom
                src={x?.user?.avatar ? `${x?.user?.avatar}` : Avt}
                alt='avt'
                onClick={() => handleMovePage(x)}
              />
              <div onClick={() => handleMovePage(x)}>
                <div className='name'>
                  <label className={`text ${x.check_live ? 'isStreaming' : 'noStreaming'}`}>
                    {x?.user?.account_name || 'Name creator'}
                  </label>
                  {x?.livestream && x?.check_live && (
                    <label className='live'>{t('Common.live')}</label>
                  )}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <label className={`content`}>
                    {!x.livestream ? t('Common.post') : t('Common.liveStreamingList')}
                    {` - `}
                    {x.title}
                  </label>
                  <label className='time'>
                    {'  •  '} {formatToDateTimeString(new Date(x?.time_start_event))}
                  </label>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
}
