
// import { create } from 'hooks/useFireStore';
// import { serverTimestamp } from 'firebase/firestore';


import { insertData, updateData } from 'hooks/useRealTimeDB';
import { onValue, ref} from 'firebase/database';

import { serverTimestamp } from 'firebase/database';
import { v4 as uuidv4 } from 'uuid';

import moment from 'moment';
import { getTopOneDonate } from 'store/actions/lives';
import { DBRealTime } from '../../../firebase/config';

import Ranking1 from 'images/ranking1.png';

// const collectionName = 'lives-message';
const generateId = (total) => {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const length = total;
    const charactersLength = characters.length;
    for (let i = 0; i < length; i += 1) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const messageSystemWhenUserTopRanking = (data = {}, liveKey = '' ) => {


  const params = {
    key: liveKey,
    type: '2', 
    name: (data?.account_name || 'SYSTEM'),
    avatar: (data?.avatar || 'null'),
    id : 0,
    creator : 0,
    message: `top1Ranking`,
    is_block: 0,
    icon : 'Stamp_RanKing1',
    createdAt: serverTimestamp(),
  };
  
  const dateNow = Date.now();
  const uid = uuidv4(); 
  let key = 'u'+ 0  + 'x'+ dateNow + '-gen'+ generateId(10) + '-rgen' + generateId(10) + uid + '-ml'+moment().milliseconds(); 

  
  return insertData(`/chat/${liveKey}/${key}`, params);

}

export default function handleSendCoins(type = 0, liveKey = '', coins = 0, user, 
stampsIcon = '', stampsGift = '', posX = 0, posY = 0) {
    if (user && liveKey !== '') {   

      const params = {
        key: liveKey,
        type: type, // 0 la message binh thuong ko gui kem point,
        name: user?.data?.account_name,
        avatar: user?.data?.avatar || 'null',
        message: coins,
        icon : stampsIcon,
        createdAt: serverTimestamp(),
      }
      const dateNow = Date.now();
      const uid = uuidv4(); 
      let key = 'u'+ user?.data?.id  + 'x'+ dateNow + '-gen'+ generateId(10) + '-rgen' + generateId(10) + uid + '-ml'+moment().milliseconds();        
      insertData(`/chat/${liveKey}/${key}`, params).then(() => {
        
       getTopOneDonate(liveKey, 1, 1, function(data) {
          if (data && data[0]) {
            onValue(
              ref(DBRealTime, `/topranking/${liveKey}`),
              (snapshot) => {
                if (snapshot.val() !== null) {
                  if (snapshot.val().id !== data[0].user_id) {
                    
                    updateData(`/topranking/${liveKey}`, {
                      rank: 1,
                      id : data[0].user_id,
                      fullname: data[0].account_name
                    }).then(() => {
                      return messageSystemWhenUserTopRanking(data[0], liveKey);
                    })
                    
                  } 
                } else {
         
                  insertData(`/topranking/${liveKey}`, {
                    rank: 1,
                    id : data[0].user_id,
                    fullname: data[0].account_name
                  }).then(() => {
                      return messageSystemWhenUserTopRanking(data[0], liveKey);
                  })
                }
              },
              {
                onlyOnce: true,
              }
            )
          } 
        
        });
 
      });

      //* STAMPS ICONS 

      const paramsStamp = {
        status : 1,
        coins,
        stampIcon: stampsIcon, 
        stampGiff:stampsGift,
        x: posX,
        y: posY,
        createdAt: serverTimestamp(),
        key
      } 

      insertData(`/stamps/${liveKey}/${key}`, paramsStamp).then(() => {
        // setTimeout(() => {  
        //   return updateData(`/stamps/${liveKey}/${key}`, {status: 0}).then(() => {
        //    });
        // }, 2000);
      });

        // create(collectionName, {
        //     key : liveKey,
        //     type: type, // 0 la message binh thuong ko gui kem point,
        //     name: user?.data?.full_name, 
        //     avatar: user?.data?.avatar,
        //     message: coins,
        //     createdAt: serverTimestamp()
        // })
    } else {
      return window.location.reload();
    }
}

