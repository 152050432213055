import { LayoutFan, UserItem } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import useLoading from 'hooks/useLoading';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { creatorGetListMyFan } from 'store/actions/users';
import { isMappable } from 'utils/utils';
import '../styles.scss';

export default function Index() {
  const dispatch = useDispatch();
  const isLoading = useLoading();
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const { listFans = [], loading, pagination } = useSelector((state) => state.users);
  const fetchNext = () => {
    const pages = pagination?.total / pagination?.per_page;
    if (pages > page) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    dispatch(creatorGetListMyFan(page, 20));
  }, [page]);

  return (
    <>
      <LayoutFan titleHeader={t('Follower.title')} loading={isLoading}>
        <div className='list-page'>
          <div className='body'>
            {isMappable(listFans) ? (
              <div className='list'>
                <InfiniteLoad loading={loading} data={listFans} fetchNext={fetchNext}>
                  {listFans.map((item, index) => (
                    <UserItem key={index} item={item} />
                  ))}
                </InfiniteLoad>
              </div>
            ) : (
              <div className='no-result'>{t('Follower.listNull')}</div>
            )}
          </div>
        </div>
      </LayoutFan>
    </>
  );
}
