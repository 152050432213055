import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Chart from './components/chart';
import Table from './components/table';
import { getChartUserReactions } from 'store/actions/chartRepot';
const UserReactions = ({ date }) => {
  const { from_date, to_date } = date;
  const dispatch = useDispatch();
  const { chartUserReactions } = useSelector((state) => state.chartReport);
  useEffect(() => {
    dispatch(getChartUserReactions({ from_date, to_date }));
  }, [from_date, to_date]);

  return (
    <>
      <Chart data={chartUserReactions || []} />
      <Table columns={chartUserReactions} />
    </>
  );
};
export default UserReactions;
