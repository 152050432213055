import { ImgCustom, LayoutCreatorRight, Tooltip2 } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { newPathUser } from 'constants/layout/constant';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  userGetBadgeNotification,
  userGetDetailNotification,
  userGetListNotification,
} from 'store/actions/notification';
import {
  convertToCurrentTime,
  getTitleByType,
  getTitleByTypeAdmin,
  linkS3,
  LIST_TAB_NOTIFICATION,
  onErrImage,
} from 'utils/utils';
import useLoading from '../../hooks/useLoading';
import Avt from '../../images/AvatarDefault.svg';
import { LOGO_COCO_FANS, STORAGE } from '../../utils/constant';
import { isMappable } from '../../utils/utils';
import './styles.scss';
import Storage from 'utils/storage';

export default function Index() {
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const navigate = useNavigate();
  const [tab, setTab] = useState(1);
  const itemsPerPage = 30;
  const isLoading = useLoading();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [list, setList] = useState([]);
  const [unreadNumber, setUnreadNumber] = useState(null);
  const { listNotifyManager, listNotifyFan, loading } = useSelector(
    (state) => state.notification
  );
  const [notifiRead, setNotifiRead] = useState([]);
  const [loadingCustom, setLoadingCustom] = useState(false);
  const loadingFunc = () => {
    setLoadingCustom(true);
    setTimeout(() => {
      setLoadingCustom(false);
    }, 1500);
  };
  const { t } = useTranslation();
  useEffect(() => {
    setList(tab === 1 ? listNotifyManager : listNotifyFan);
  }, [tab, loading]);

  const fetchNext = () => setPage(page + 1);

  useEffect(() => {
    setList(tab === 1 ? listNotifyManager : listNotifyFan);
  }, [tab, loading]);

  useEffect(() => {
    dispatch(userGetListNotification({ type: tab, page: page, limit: itemsPerPage }));
  }, [page, tab]);

  useEffect(() => {
    if(isAuth) {
      dispatch(userGetBadgeNotification(handleSetDataUnread));
    }
  }, []);

  const handleSetDataUnread = ({ success, data }) => success && setUnreadNumber(data);

  const handleNavigate = (notify) => {
    if (tab === 1) {
      if (notify?.banner_id) {
        notify?.title === '12' && navigate(`/affiliator/banners/${notify?.banner_id}`);
      } else if (notify?.title === '20' || notify?.title === '21') {
        navigate(`/purchase`);
      } else if (notify?.title !== '14' && !notify?.banner_id) {
        notify?.created_by_admin === 1
          ? navigate(`/notification/${notify?.id}`)
          : navigate(`/message/${notify?.admin_id}`);
      }
    } else if ([8].includes(notify.content_type)) {
      navigate(`/message/${notify?.user_id}`);
    } else if ([9, 19].includes(notify.content_type)) {
      navigate(`/post/${notify?.post_id}`);
    } else if ([20].includes(notify.content_type)) {
      navigate('/purchase');
    } else if ([2].includes(notify.content_type)) {
      navigate(`${newPathUser}${notify?.user?.account_id}`);
    }
  };

  const onSeen = async (notify) => {
    dispatch(
      userGetDetailNotification(notify?.id, () => {
        setNotifiRead([...notifiRead, notify?.id]);
        isAuth && dispatch(userGetBadgeNotification(handleSetDataUnread));
        handleNavigate(notify);
      })
    );
  };

  const handleClickNotify = (notify) => {
    !notify?.is_read ? onSeen(notify) : handleNavigate(notify);
  };

  return (
    <LayoutCreatorRight titlePage={t('Notification.title')} loading={loadingCustom || isLoading}>
      <div className='notification-page'>
        <div className='tab-container'>
          {LIST_TAB_NOTIFICATION.map((v, i) => (
            <div
              key={i}
              className={`item ${tab === i + 1 ? ` active ` : ``}`}
              onClick={() => {
                loadingFunc();
                if (tab !== i + 1) {
                  setTab(i + 1);
                  setPage(1);
                  window.scrollTo(0, 0);
                }
              }}>
              {`${v.label} (${t('Common.unread')}：${
                unreadNumber?.[v.type] > 99 ? '99+' : unreadNumber?.[v.type] || 0
              }${t('Common.piece')})`}
            </div>
          ))}
        </div>
        <div className='body'>
          <div className='list'>
            <InfiniteLoad loading={loading} data={list} fetchNext={fetchNext}>
              {isMappable(list) ? (
                list.map((item, index) => (
                  <div key={index} className='item' onClick={() => handleClickNotify(item)}>
                    <ImgCustom
                      src={
                        tab === 1
                          ? LOGO_COCO_FANS
                          : item?.user?.avatar
                          ? `${item?.user?.avatar}`
                          : Avt
                      }
                      alt='avt'
                      className={`${tab === 1 ? 'logo-coco-fan' : 'logo-fan'}`}
                      onError={(e) => onErrImage(e, true)}
                    />
                    <div
                      className={`${
                        item?.is_read !== 0 || notifiRead.includes(item?.id) ? '--isRead' : ''
                      }`}>
                      <div className={`d-flex justify-content-between w-100`}>
                        <div className='name fw-bold'>
                          {tab === 1
                            ? `Sharegram`
                            : item?.user?.account_name || item?.user?.full_name}
                        </div>
                        <div className='date'>
                          {item?.start_time && convertToCurrentTime(item?.start_time)}
                        </div>
                      </div>
                      <div className={`content mt-2 fw-normal`}>
                        <Tooltip2
                          className={'custom-text fan'}
                          text={
                            tab === 1
                              ? getTitleByTypeAdmin(+item?.title, item?.title)
                              : getTitleByType(
                                  item?.content_type,
                                  item?.user?.account_name || item?.user?.full_name,
                                  false
                                )
                          }>
                          {tab === 1
                            ? getTitleByTypeAdmin(+item?.title, item?.title)
                            : getTitleByType(
                                item?.content_type,
                                item?.user?.account_name || item?.user?.full_name,
                                false
                              )}
                        </Tooltip2>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className='no-result'>{t('Notification.listNull')}</div>
              )}
            </InfiniteLoad>
          </div>
        </div>
      </div>
    </LayoutCreatorRight>
  );
}
