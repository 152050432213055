import { TrashIcon } from 'images';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import ThreePointIconV2 from 'images/ThreePointIconV2';

const PopupDeleteMessage = ({ id, index, setShowPopupConfirm, setMessageDelete }) => {
  const [isVisiblePopupDelete, setIsVisiblePopupDelete] = useState(false);
  const { t } = useTranslation();

  return (
    <div className='box-icon-more' style={{ paddingRight: '24px' }}>
      <ThreePointIconV2
        onClick={() => {
          setMessageDelete({});
          setIsVisiblePopupDelete(!isVisiblePopupDelete);
        }}
        onBlur={() => {
          setTimeout(() => {
            setIsVisiblePopupDelete(false);
          }, 300);
        }}
      />
      <div className='wrapper-popup'></div>
      <div className='pop-up' style={{ visibility: isVisiblePopupDelete ? '' : 'hidden' }}>
        <div
          className='item-delete'
          onClick={() => {
            setMessageDelete({ id, index });
            setShowPopupConfirm(true);
            setIsVisiblePopupDelete(!isVisiblePopupDelete);
          }}>
          <TrashIcon />
          <span className='text'>{t('Common.buttonDelete')}</span>
        </div>
      </div>
    </div>
  );
};

export default PopupDeleteMessage;
