import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { TwitterIcon, LineIcon } from 'images';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import { useTranslation } from 'react-i18next';

const PopupSharePost = ({ data, isVisiblePopup, ...props }) => {
  useLockBodyScroll();

  const textRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const { t } = useTranslation();
  const copyLinkFunc = () => {
    navigator.clipboard.writeText(data.link);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1500);
  };
  const handleShowPopup = (e) => {
    //isVisiblePopup(-1);
    e.preventDefault();
    isVisiblePopup(null);
  };
  const decodeUrl = () => {
    return encodeURI(data.link);
  };
  const shareTwitter = () => {
    let urlTwiter = [];
    if (data.title) {
      urlTwiter.push(`text=${data.title}`);
    }
    if (data.link) {
      urlTwiter.push(`url=${decodeUrl()}`);
    }
    const url = `https://twitter.com/intent/tweet?${urlTwiter.join('&')}`;
    window.open(url, 'share', 'left=100,top=100,width=600,height=320,toolbar=0');
    return false;
  };

  const shareLine = () => {
    let urlLine = [];
    if (data.title) {
      urlLine.push(`text=${data.title}`);
    }
    if (data.link) {
      urlLine.push(`url=${decodeUrl()}`);
    }
    const url = `https://social-plugins.line.me/lineit/share?${urlLine.join('&')}`;
    window.open(url, 'share', 'left=100,top=100,width=600,height=320,toolbar=0');
    return false;
  };
  return (
    <div className='popup popup-share-post'>
      <div className='popup-content'>
        <div className='popup-header'>
          <div className='title'>{t('Popup.share')}</div>
          {/* <CloseIcon className='close-icon' onClick={handleShowPopup} /> */}
          <svg
            style={{ cursor: "pointer" }}
            className='close-icon' onClick={handleShowPopup}
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            fill='none'
            viewBox='0 0 18 18'>
            <path
              stroke='currentColor'
              strokeLinecap='round'
              strokeWidth='1.5'
              d='M4.5 13.875l9.711-9.378M4.5 4.5l9.711 9.378'></path>
          </svg>
        </div>
        <hr className='mt-0' style={{ color: '#E5E6EB', opacity: "1" }} />
        <div className='sub-title'>{t('Popup.shareNow')}</div>
        <div className='social-button'>
          <button className='btn btn-twitter me-2' onClick={shareTwitter}>
            <TwitterIcon />
          </button>
          <button className='btn btn-line' onClick={() => shareLine()}>
            <LineIcon />
          </button>
        </div>
        <div className='sub-title'>{t('Popup.copyLink')}</div>
        <div style={{ padding: '0 24px' }}>
          <div className='copy-link-box'>
            <span
              //style={{ whiteSpace: 'normal', wordBreak: 'break-all' }}
              className='text'
              ref={textRef}>
              {data.link}
            </span>
            <button className={'btn-copy-link'} onClick={copyLinkFunc}>
              {t('Popup.copy')}
            </button>
            {/* <CopyLinkIcon style={{ marginRight: "10px" }} onClick={copyLinkFunc} /> */}
            {copySuccess && <div className='copy-success'>{t('Popup.messageCopyLink')}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupSharePost;
