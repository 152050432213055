import RouteSuspense from 'components/RouteSuspense';
import SubscriptionList from 'pages/creator/layout//subscription';
import SubscriptionCreate from 'pages/creator/layout/subscription/new/create';
import EditSubscription from 'pages/creator/layout/subscription/new/edit';
import ListSurvey from 'pages/creator/layout//survey';
import EditSurveyScreen from 'pages/creator/layout//survey/edit';
import PostSurveyScreen from 'pages/creator/layout//survey/post';
import BlockPage from 'pages/creator/layout/block';
import Calendar from 'pages/creator/layout/calendar';
import CreateLiveStream from 'pages/creator/layout/create-livestream';
import FollowPage from 'pages/creator/layout/follow';
import FollowerPage from 'pages/creator/layout/follower';
import HelpPage from 'pages/creator/layout/help/';
import PostHelpCreator from 'pages/creator/layout/help/post';
import CreatorHomePage from 'pages/creator/layout/home-page';
import ListBuyer from 'pages/creator/layout/list-buyer';
import ListLivestream from 'pages/creator/layout/list-livestream';
import ListRankingBuyer from 'pages/creator/layout/list-ranking-buyer';
import ListSubscriber from 'pages/creator/layout/list-subscriber';
import Livestream from 'pages/creator/layout/livestream';
import ListMessage from 'pages/creator/layout/message';
import MessageQuestionnaire from 'pages/creator/layout/message-questionnaire';
import CreateMessage from 'pages/creator/layout/message/create';
import Message from 'pages/creator/layout/message/detail';
import Notification from 'pages/creator/layout/notification';
import NotificationDetail from 'pages/creator/layout/notification/detail';
import PlanList from 'pages/creator/layout/plan-list';
import EditPostContent from 'pages/creator/layout/post-article/edit-post-content';
import EditPostNormal from 'pages/creator/layout/post-article/edit-post-normal';
import PostContent from 'pages/creator/layout/post-article/post-content';
import PostContentDetail from 'pages/creator/layout/post-article/post-content-detail';
import PostContentDetailMode2 from 'pages/creator/layout/post-article/post-content-detail-mode2';
import PostNormal from 'pages/creator/layout/post-article/post-normal';
import PostReport from 'pages/creator/layout/post-report';
import PremiumTicketCreate from 'pages/creator/layout/subscription/premium/created';
import PremiumTicketEdit from 'pages/creator/layout/subscription/premium/edit';
import CreatorProfile from 'pages/creator/layout/profile';
import ProfileInfo from 'pages/creator/layout/profile-info';
import CreatorUpdateEmail from 'pages/creator/layout/profile-update-email';
import ReportFunctionPage from 'pages/creator/layout/report-function';
import ReviewLivestream from 'pages/creator/layout/review-livestream';
import SaleConfirmation from 'pages/creator/layout/sale-confirmation';
import ProfileUpdate from 'pages/creator/layout/profile-update';
import PostShort from 'pages/creator/layout/post-article/post-short';
import CreatorTimeLine from 'pages/creator/layout/timeline';
import Timeline from 'pages/timeline';
import Recording from 'pages/creator/layout/recording';
import ListPurchaseScreen from 'pages/purchase';
import LikeListScreen from 'pages/bookmark/like';
import ListPostBuyed from 'pages/list-post-buyed';
import BrowseHistoryListScreen from 'pages/bookmark/browse-history';
import ViewOnTimeLine from 'pages/favorite/ViewOnTimeLine/ViewOnTimeLine';
import FavoriteListPost from 'pages/favorite/ListPost';
import SettingNotificationScreen from 'pages/notification/setting';
import PostReportV2 from 'pages/post-report';
import SubscribeListScreen from 'pages/bookmark/subscribe';
import NotificationScreen from 'pages/notification';
import PaymentScreen from 'pages/purchase/payment';
import ListLiveScreen from 'pages/lives/List';
import DetailShortVideo from 'pages/detail-shortvideo';
import SurveyDetailScreen from 'pages/survey/detail';
import ChangePassword from 'pages/user/changePassword';
import ConfirmNewEmail from 'pages/user/confirmNewEmail';
import PostContentSaleDetail from 'pages/creator/layout/post-article/post-content-sale';
import SearchPage from 'pages/creator/layout/search-page';
import PaymentVendoPage from 'pages/purchase/payment-vendo';
import HashtagPosts from 'pages/favorite/ListPost/HashtagPosts';
import WithDraw from 'pages/with-draw';
import ProfileContract from 'pages/creator/layout/profile-contract';
import PopupContracts from 'pages/creator/layout/profile-contract/popup/contracts';
import RankingPage from 'pages/ranking';
import CardManagementPage from 'pages/card-management';
import { CreateAndUpdateCard } from 'pages/card-management/createAndUpdateCard';
import { STATUS_ACCEPT } from 'utils';
import { CreateAndUpdateCardVendoPayment } from 'pages/purchase/payment-vendo/createAndUpdateCard';
import PaymentTiger from 'pages/purchase/payment-tiger';
import CommentHideFromPostPage from 'pages/comment-hide-post';

export const USER_ROUTES = [
  {
    path: '/',
    element: <CreatorHomePage />,
  },
  {
    path: '/:account_id',
    element: (
      <RouteSuspense>
        <CreatorHomePage />
      </RouteSuspense>
    ),
  },
  {
    path: '/:creator_id/plan-list',
    element: (
      <RouteSuspense>
        <PlanList />
      </RouteSuspense>
    ),
  },
  {
    path: '/live/create',
    element: (
      <RouteSuspense>
        <CreateLiveStream />
      </RouteSuspense>
    ),
    isProdEnableLiveStream: true
  },
  {
    path: '/live/create/:id/:liveKey',
    element: (
      <RouteSuspense>
        <CreateLiveStream />
      </RouteSuspense>
    ),
    isProdEnableLiveStream: true
  },
  {
    path: '/live/list-livestream',
    element: (
      <RouteSuspense>
        <ListLivestream />
      </RouteSuspense>
    ),
    isProdEnableLiveStream: true
  },
  {
    path: '/live/:id/:liveKey',
    key: '/live/livestream',
    element: (
      <RouteSuspense>
        <Livestream />
      </RouteSuspense>
    ),
    isProdEnableLiveStream: true
  },
  {
    path: '/live/review/:id/:liveKey',
    element: (
      <RouteSuspense>
        <ReviewLivestream />
      </RouteSuspense>
    ),
    isProdEnableLiveStream: true
  },
  {
    path: '/profile-settings',
    element: (
      <RouteSuspense>
        <CreatorProfile />
      </RouteSuspense>
    ),
  },
  {
    path: '/profile/info',
    element: (
      <RouteSuspense>
        <ProfileInfo />
      </RouteSuspense>
    ),
  },
  {
    path: '/profile/update',
    element: (
      <RouteSuspense>
        <ProfileUpdate />
      </RouteSuspense>
    ),
  },
  {
    path: '/profile/registration-of-performer',
    element: (
      <RouteSuspense>
        <ProfileContract />
      </RouteSuspense>
    ),
  },
  {
    path: '/profile/registration-of-performer/create',
    element: (
      <RouteSuspense>
        <PopupContracts isShowProfile/>
      </RouteSuspense>
    ),
  },
  {
    path: '/profile/registration-of-performer/:id',
    element: (
      <RouteSuspense>
        <PopupContracts isShowProfile/>
      </RouteSuspense>
    ),
  },
  {
    path: '/profile/change-email/:token',
    element: (
      <RouteSuspense>
        <CreatorUpdateEmail />
      </RouteSuspense>
    ),
  },

  {
    path: '/follow-list/:account_id',
    element: (
      <RouteSuspense>
        <FollowPage />
      </RouteSuspense>
    ),
  },
  {
    path: '/follower-list/:account_id',
    element: (
      <RouteSuspense>
        <FollowerPage />
      </RouteSuspense>
    ),
  },
  {
    path: '/block',
    element: (
      <RouteSuspense>
        <BlockPage />
      </RouteSuspense>
    ),
  },
  {
    path: '/subscriber',
    element: (
      <RouteSuspense>
        <ListSubscriber />
      </RouteSuspense>
    ),
  },
  {
    path: '/buyer',
    element: (
      <RouteSuspense>
        <ListBuyer />
      </RouteSuspense>
    ),
  },
  {
    path: '/ranking-buyer',
    element: (
      <RouteSuspense>
        <ListRankingBuyer />
      </RouteSuspense>
    ),
  },
  {
    path: '/comment-hide/:postId',
    element: (
      <RouteSuspense>
        <CommentHideFromPostPage />
      </RouteSuspense>
    ),
  },
  // {
  //   path: '/search',
  //   element: (
  //     <RouteSuspense>
  //       <CreatorSearch />
  //     </RouteSuspense>
  //   ),
  // },
  {
    path: '/profile/subscription',
    element: (
      <RouteSuspense>
        <SubscriptionList />
      </RouteSuspense>
    ),
  },
  {
    path: '/profile/subscription/create',
    element: (
      <RouteSuspense>
        <SubscriptionCreate />
      </RouteSuspense>
    ),
  },
  {
    path: '/profile/subscription/update/:id',
    element: (
      <RouteSuspense>
        <EditSubscription />
      </RouteSuspense>
    ),
  },
  {
    path: '/profile/premium-ticket/create',
    element: (
      <RouteSuspense>
        <PremiumTicketCreate />
      </RouteSuspense>
    ),
  },
  {
    path: '/profile/premium-ticket/update/:id',
    element: (
      <RouteSuspense>
        <PremiumTicketEdit />
      </RouteSuspense>
    ),
  },
  {
    path: '/post-normal/:id',
    element: (
      <RouteSuspense>
        {/* <PostNormalDetail /> */}
        <PostContentDetail />
      </RouteSuspense>
    ),
  },
  {
    path: '/post/:id',
    element: (
      <RouteSuspense>
        <PostContentDetail />
      </RouteSuspense>
    ),
  },
  {
    path: '/post-sale/:id',
    element: (
      <RouteSuspense>
        <PostContentSaleDetail />
      </RouteSuspense>
    ),
  },
  {
    path: '/post-content-mode-2/:id',
    element: (
      <RouteSuspense>
        <PostContentDetailMode2 />
      </RouteSuspense>
    ),
  },
  {
    path: '/help',
    element: (
      <RouteSuspense>
        <HelpPage />
      </RouteSuspense>
    ),
  },
  {
    path: '/post-help',
    element: (
      <RouteSuspense>
        <PostHelpCreator />
      </RouteSuspense>
    ),
  },
  {
    path: '/surveys',
    element: (
      <RouteSuspense>
        <ListSurvey />
      </RouteSuspense>
    ),
  },
  {
    path: '/ranking',
    element: (
      <RouteSuspense>
        <RankingPage />
      </RouteSuspense>
    ),
    statusAccept: STATUS_ACCEPT
  },
  {
    path: '/card-management',
    element: (
      <RouteSuspense>
        <CardManagementPage />
      </RouteSuspense>
    ),
    statusAccept: STATUS_ACCEPT,
    isProdPageEnable: true
  },
  {
    path: '/create/card',
    element: (
      <RouteSuspense>
        <CreateAndUpdateCard />
      </RouteSuspense>
    ),
    statusAccept: STATUS_ACCEPT,
    isProdPageEnable: true
  },
  {
    path: '/card/:id',
    element: (
      <RouteSuspense>
        <CreateAndUpdateCard isEdit/>
      </RouteSuspense>
    ),
    statusAccept: STATUS_ACCEPT,
    isProdPageEnable: true
  },
  {
    path: '/create-surveys',
    element: (
      <RouteSuspense>
        <PostSurveyScreen />
      </RouteSuspense>
    ),
  },
  {
    path: '/surveys/:id',
    element: (
      <RouteSuspense>
        <MessageQuestionnaire />
      </RouteSuspense>
    ),
  },
  {
    path: '/survey/:id',
    element: (
      <RouteSuspense>
        <SurveyDetailScreen />
      </RouteSuspense>
    ),
  },
  {
    path: '/surveys/edit/:id',
    element: (
      <RouteSuspense>
        <EditSurveyScreen />
      </RouteSuspense>
    ),
  },
  {
    path: '/post-content/create',
    element: (
      <RouteSuspense>
        <PostContent />
      </RouteSuspense>
    ),
  },
  {
    path: '/post-sale/update/:id',
    element: (
      <RouteSuspense>
        <EditPostContent />
      </RouteSuspense>
    ),
  },
  {
    path: '/post-normal/create',
    element: (
      <RouteSuspense>
        <PostNormal />
      </RouteSuspense>
    ),
  },
  {
    path: '/report',
    element: (
      <RouteSuspense>
        <ReportFunctionPage />
      </RouteSuspense>
    ),
  },
  {
    path: '/post/update/:id',
    element: (
      <RouteSuspense>
        <EditPostNormal />
      </RouteSuspense>
    ),
  },
  {
    path: '/message',
    element: (
      <RouteSuspense>
        <ListMessage />
      </RouteSuspense>
    ),
  },
  {
    path: '/message/:id',
    element: (
      <RouteSuspense>
        <Message />
      </RouteSuspense>
    ),
  },
  {
    path: '/message/create',
    element: (
      <RouteSuspense>
        <CreateMessage />
      </RouteSuspense>
    ),
  },
  {
    path: '/calendar',
    element: (
      <RouteSuspense>
        <Calendar />
      </RouteSuspense>
    ),
  },

  {
    path: '/search',
    element: (
      <RouteSuspense>
        <SearchPage />
      </RouteSuspense>
    ),
  },

  {
    path: '/profile/notification',
    element: (
      <RouteSuspense>
        <Notification />
      </RouteSuspense>
    ),
  },

  {
    path: '/profile/recording',
    element: (
      <RouteSuspense>
        <Recording />
      </RouteSuspense>
    ),
    isProdEnableLiveStream: true
  },

  {
    path: '/user/profile/notification/:id',
    element: (
      <RouteSuspense>
        <NotificationDetail />
      </RouteSuspense>
    ),
  },
  {
    path: '/sale-confirmation',
    element: (
      <RouteSuspense>
        <SaleConfirmation />
      </RouteSuspense>
    ),
  },

  {
    path: '/report',
    element: (
      <RouteSuspense>
        <PostReportV2 />
      </RouteSuspense>
    ),
  },
  // {
  //   path: '/setting/language',
  //   element: (
  //     <RouteSuspense>
  //       <SettingLanguage />
  //     </RouteSuspense>
  //   ),
  // },
  {
    path: '/setting/with-draw',
    element: (
      <RouteSuspense>
        <WithDraw />
      </RouteSuspense>
    ),
  },
  {
    path: '/post-short/create',
    element: (
      <RouteSuspense>
        <PostShort />
      </RouteSuspense>
    ),
  },
  {
    path: '/post/update/:id',
    element: (
      <RouteSuspense>
        {/* <EditPostShort /> */}
        <EditPostNormal />
      </RouteSuspense>
    ),
  },
  {
    path: '/post-short/:id',
    element: (
      <RouteSuspense>
        {/* <PostShortDetail /> */}
        <PostContentDetail />
      </RouteSuspense>
    ),
  },
  {
    path: '/list-short-video',
    element: (
      <RouteSuspense>
        <CreatorTimeLine />
      </RouteSuspense>
    ),
  },
  {
    path: '/timeline',
    element: (
      <RouteSuspense>
        <Timeline isCreator />
      </RouteSuspense>
    ),
  },
  {
    path: '/purchase',
    element: (
      <RouteSuspense>
        <ListPurchaseScreen />
      </RouteSuspense>
    ),
    isProdPageEnable: true
  },
  {
    path: '/like-list',
    element: (
      <RouteSuspense>
        <LikeListScreen />
      </RouteSuspense>
    ),
  },
  {
    path: '/list-post-buyed',
    element: (
      <RouteSuspense>
        <ListPostBuyed />
      </RouteSuspense>
    ),
  },
  {
    path: '/browse-history-list',
    element: (
      <RouteSuspense>
        <BrowseHistoryListScreen />
      </RouteSuspense>
    ),
  },
  {
    path: '/favorite/view-on-timeline',
    element: (
      <RouteSuspense>
        <ViewOnTimeLine />
      </RouteSuspense>
    ),
  },
  {
    path: '/favorite/list-post',
    element: (
      <RouteSuspense>
        <FavoriteListPost />
      </RouteSuspense>
    ),
  },
  {
    path: '/notification/setting',
    element: (
      <RouteSuspense>
        <SettingNotificationScreen />
      </RouteSuspense>
    ),
  },
  {
    path: '/post-report',
    element: (
      <RouteSuspense>
        <PostReportV2 />
      </RouteSuspense>
    ),
  },
  {
    path: '/subscribe-list',
    element: (
      <RouteSuspense>
        <SubscribeListScreen />
      </RouteSuspense>
    ),
  },
  {
    path: '/notification',
    element: (
      <RouteSuspense>
        <NotificationScreen />
      </RouteSuspense>
    ),
  },
  {
    path: '/payment',
    element: (
      <RouteSuspense>
        <PaymentScreen />
      </RouteSuspense>
    ),
    isProdPageEnable: true
  },
  {
    path: '/payment/checkout',
    element: (
      <RouteSuspense>
        <PaymentVendoPage />
      </RouteSuspense>
    ),
    isProdPageEnable: true
  },
  {
    path: '/payment/tigerpay',
    element: (
      <RouteSuspense>
        <PaymentTiger />
      </RouteSuspense>
    ),
    isProdPageEnable: true
  },
  {
    path: '/payment/vendo/create/card',
    element: (
      <RouteSuspense>
        <CreateAndUpdateCardVendoPayment />
      </RouteSuspense>
    ),
    isProdPageEnable: true
  },
  {
    path: '/lives/list/live',
    element: (
      <RouteSuspense>
        <ListLiveScreen />
      </RouteSuspense>
    ),
    isProdEnableLiveStream: true
  },

  {
    path: '/hashtag-posts',
    element: (
      <RouteSuspense>
        <HashtagPosts />
      </RouteSuspense>
    ),
  },
  {
    path: '/short-video-detail/:id',
    element: <DetailShortVideo />,
  },
  {
    path: 'change-password',
    element: <ChangePassword />,
  },
  {
    path: '/confirm-email/:token',
    element: <ConfirmNewEmail />,
  },
];

export const USER_NOT_APPROVED_ROUTES = [
  {
    path: '/',
    element: <CreatorHomePage />,
  },
  {
    path: '/:account_id',
    element: (
      <RouteSuspense>
        <CreatorHomePage />
      </RouteSuspense>
    ),
  },
  {
    path: '/comment-hide/:postId',
    element: (
      <RouteSuspense>
        <CommentHideFromPostPage />
      </RouteSuspense>
    ),
  },
  {
    path: '/:creator_id/plan-list',
    element: (
      <RouteSuspense>
        <PlanList />
      </RouteSuspense>
    ),
  },
  {
    path: '/payment/checkout',
    element: (
      <RouteSuspense>
        <PaymentVendoPage />
      </RouteSuspense>
    ),
  },
  {
    path: '/payment/tigerpay',
    element: (
      <RouteSuspense>
        <PaymentTiger />
      </RouteSuspense>
    ),
    isProdPageEnable: true
  },
  {
    path: '/payment/vendo/create/card',
    element: (
      <RouteSuspense>
        <CreateAndUpdateCardVendoPayment />
      </RouteSuspense>
    ),
    isProdPageEnable: true
  },
  {
    path: '/ranking',
    element: (
      <RouteSuspense>
        <RankingPage />
      </RouteSuspense>
    ),
    statusAccept: STATUS_ACCEPT
  },
  {
    path: '/profile-settings',
    element: (
      <RouteSuspense>
        <CreatorProfile />
      </RouteSuspense>
    ),
  },
  {
    path: '/profile/info',
    element: (
      <RouteSuspense>
        <ProfileInfo />
      </RouteSuspense>
    ),
  },
  {
    path: '/profile/update',
    element: (
      <RouteSuspense>
        <ProfileUpdate />
      </RouteSuspense>
    ),
  },
  {
    path: '/profile/change-email/:token',
    element: (
      <RouteSuspense>
        <CreatorUpdateEmail />
      </RouteSuspense>
    ),
  },

  {
    path: '/follow-list/:account_id',
    element: (
      <RouteSuspense>
        <FollowPage />
      </RouteSuspense>
    ),
  },
  {
    path: '/follower-list/:account_id',
    element: (
      <RouteSuspense>
        <FollowerPage />
      </RouteSuspense>
    ),
  },
  {
    path: '/block',
    element: (
      <RouteSuspense>
        <BlockPage />
      </RouteSuspense>
    ),
  },
  {
    path: '/profile/subscription/create',
    element: (
      <RouteSuspense>
        <SubscriptionCreate />
      </RouteSuspense>
    ),
  },
  {
    path: '/profile/subscription/update/:id',
    element: (
      <RouteSuspense>
        <EditSubscription />
      </RouteSuspense>
    ),
  },
  {
    path: '/profile/premium-ticket/create',
    element: (
      <RouteSuspense>
        <PremiumTicketCreate />
      </RouteSuspense>
    ),
  },
  {
    path: '/profile/premium-ticket/update/:id',
    element: (
      <RouteSuspense>
        <PremiumTicketEdit />
      </RouteSuspense>
    ),
  },
  {
    path: '/post-normal/:id',
    element: (
      <RouteSuspense>
        {/* <PostNormalDetail /> */}
        <PostContentDetail />
      </RouteSuspense>
    ),
  },
  {
    path: '/post/:id',
    element: (
      <RouteSuspense>
        <PostContentDetail />
      </RouteSuspense>
    ),
  },
  {
    path: '/post-sale/:id',
    element: (
      <RouteSuspense>
        <PostContentSaleDetail />
      </RouteSuspense>
    ),
  },
  {
    path: '/post-content-mode-2/:id',
    element: (
      <RouteSuspense>
        <PostContentDetailMode2 />
      </RouteSuspense>
    ),
  },
  {
    path: '/help',
    element: (
      <RouteSuspense>
        <HelpPage />
      </RouteSuspense>
    ),
  },
  {
    path: '/post-help',
    element: (
      <RouteSuspense>
        <PostHelpCreator />
      </RouteSuspense>
    ),
  },
  {
    path: '/surveys',
    element: (
      <RouteSuspense>
        <ListSurvey />
      </RouteSuspense>
    ),
  },
  {
    path: '/surveys/:id',
    element: (
      <RouteSuspense>
        <MessageQuestionnaire />
      </RouteSuspense>
    ),
  },
  {
    path: '/survey/:id',
    element: (
      <RouteSuspense>
        <SurveyDetailScreen />
      </RouteSuspense>
    ),
  },
  {
    path: '/post-sale/update/:id',
    element: (
      <RouteSuspense>
        <EditPostContent />
      </RouteSuspense>
    ),
  },
  {
    path: '/post-normal/create',
    element: (
      <RouteSuspense>
        <PostNormal />
      </RouteSuspense>
    ),
  },
  {
    path: '/post/update/:id',
    element: (
      <RouteSuspense>
        <EditPostNormal />
      </RouteSuspense>
    ),
  },
  {
    path: '/message',
    element: (
      <RouteSuspense>
        <ListMessage />
      </RouteSuspense>
    ),
  },
  {
    path: '/message/:id',
    element: (
      <RouteSuspense>
        <Message />
      </RouteSuspense>
    ),
  },
  {
    path: '/message/create',
    element: (
      <RouteSuspense>
        <CreateMessage />
      </RouteSuspense>
    ),
  },
  {
    path: '/calendar',
    element: (
      <RouteSuspense>
        <Calendar />
      </RouteSuspense>
    ),
  },

  {
    path: '/search',
    element: (
      <RouteSuspense>
        <SearchPage />
      </RouteSuspense>
    ),
  },

  {
    path: '/profile/notification',
    element: (
      <RouteSuspense>
        <Notification />
      </RouteSuspense>
    ),
  },
  {
    path: '/user/profile/notification/:id',
    element: (
      <RouteSuspense>
        <NotificationDetail />
      </RouteSuspense>
    ),
  },
  {
    path: '/setting/with-draw',
    element: (
      <RouteSuspense>
        <WithDraw />
      </RouteSuspense>
    ),
  },
  {
    path: '/report',
    element: (
      <RouteSuspense>
        <PostReport />
      </RouteSuspense>
    ),
  },
  // {
  //   path: '/setting/language',
  //   element: (
  //     <RouteSuspense>
  //       <SettingLanguage />
  //     </RouteSuspense>
  //   ),
  // },
  {
    path: '/post-short/create',
    element: (
      <RouteSuspense>
        <PostShort />
      </RouteSuspense>
    ),
  },
  {
    path: '/post/update/:id',
    element: (
      <RouteSuspense>
        {/* <EditPostShort /> */}
        <EditPostNormal />
      </RouteSuspense>
    ),
  },
  {
    path: '/post-short/:id',
    element: (
      <RouteSuspense>
        {/* <PostShortDetail /> */}
        <PostContentDetail />
      </RouteSuspense>
    ),
  },
  {
    path: '/list-short-video',
    element: (
      <RouteSuspense>
        <CreatorTimeLine />
      </RouteSuspense>
    ),
  },
  {
    path: '/timeline',
    element: (
      <RouteSuspense>
        <Timeline isCreator />
      </RouteSuspense>
    ),
  },
  {
    path: '/card-management',
    element: (
      <RouteSuspense>
        <CardManagementPage />
      </RouteSuspense>
    ),
    statusAccept: STATUS_ACCEPT,
    isProdPageEnable: true
  },
  {
    path: '/create/card',
    element: (
      <RouteSuspense>
        <CreateAndUpdateCard />
      </RouteSuspense>
    ),
    statusAccept: STATUS_ACCEPT,
    isProdPageEnable: true
  },
  {
    path: '/card/:id',
    element: (
      <RouteSuspense>
        <CreateAndUpdateCard isEdit/>
      </RouteSuspense>
    ),
    statusAccept: STATUS_ACCEPT,
    isProdPageEnable: true
  },
  {
    path: '/purchase',
    element: (
      <RouteSuspense>
        <ListPurchaseScreen />
      </RouteSuspense>
    ),
  },
  {
    path: '/like-list',
    element: (
      <RouteSuspense>
        <LikeListScreen />
      </RouteSuspense>
    ),
  },
  {
    path: '/list-post-buyed',
    element: (
      <RouteSuspense>
        <ListPostBuyed />
      </RouteSuspense>
    ),
  },
  {
    path: '/browse-history-list',
    element: (
      <RouteSuspense>
        <BrowseHistoryListScreen />
      </RouteSuspense>
    ),
  },
  {
    path: '/favorite/view-on-timeline',
    element: (
      <RouteSuspense>
        <ViewOnTimeLine />
      </RouteSuspense>
    ),
  },
  {
    path: '/favorite/list-post',
    element: (
      <RouteSuspense>
        <FavoriteListPost />
      </RouteSuspense>
    ),
  },
  {
    path: '/notification/setting',
    element: (
      <RouteSuspense>
        <SettingNotificationScreen />
      </RouteSuspense>
    ),
  },
  {
    path: '/post-report',
    element: (
      <RouteSuspense>
        <PostReportV2 />
      </RouteSuspense>
    ),
  },
  {
    path: '/subscribe-list',
    element: (
      <RouteSuspense>
        <SubscribeListScreen />
      </RouteSuspense>
    ),
  },
  {
    path: '/notification',
    element: (
      <RouteSuspense>
        <NotificationScreen />
      </RouteSuspense>
    ),
  },
  {
    path: '/payment',
    element: (
      <RouteSuspense>
        <PaymentScreen />
      </RouteSuspense>
    ),
  },
  {
    path: '/lives/list/live',
    element: (
      <RouteSuspense>
        <ListLiveScreen />
      </RouteSuspense>
    ),
    isProdEnableLiveStream: true
  },
  {
    path: '/hashtag-posts',
    element: (
      <RouteSuspense>
        <HashtagPosts />
      </RouteSuspense>
    ),
  },
  {
    path: '/short-video-detail/:id',
    element: <DetailShortVideo />,
  },
  {
    path: 'change-password',
    element: <ChangePassword />,
  },
  {
    path: '/confirm-email/:token',
    element: <ConfirmNewEmail />,
  },
];
