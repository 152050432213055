import { useEffect } from 'react';

const useOutsideClickCustom = (callback) => {
  const handleClick = (e) => {
    callback(e);
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  });
};

export default useOutsideClickCustom;
