import Icon from '@ant-design/icons';

const FlagIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M4.2915 1.66699V18.3337'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.2915 3.33301H13.6248C15.8748 3.33301 16.3748 4.58301 14.7915 6.16634L13.7915 7.16634C13.1248 7.83301 13.1248 8.91634 13.7915 9.49967L14.7915 10.4997C16.3748 12.083 15.7915 13.333 13.6248 13.333H4.2915'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default FlagIcon;
