import Icon from '@ant-design/icons';

const MuteIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={88}
        height={88}
        viewBox='0 0 88 88'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g filter='url(#filter0_d_2021_71200)'>
          <rect x={24} y={20} width={40} height={40} rx={20} fill='white' fillOpacity='0.3' />
          <path
            d='M46.1497 31.9384C46.0446 31.8871 45.9272 31.8663 45.8109 31.8783C45.6946 31.8904 45.584 31.9349 45.4917 32.0068L40.0352 36.25H36.5C36.1686 36.2503 35.8508 36.3821 35.6165 36.6164C35.3821 36.8508 35.2503 37.1685 35.25 37.5V42.5C35.2503 42.8314 35.3821 43.1491 35.6165 43.3835C35.8508 43.6178 36.1686 43.7496 36.5 43.75H40.0352L45.4917 47.9931C45.584 48.065 45.6946 48.1095 45.811 48.1216C45.9273 48.1337 46.0448 48.1128 46.1498 48.0614C46.2549 48.01 46.3435 47.9301 46.4054 47.8309C46.4673 47.7316 46.5001 47.617 46.5 47.5V32.5C46.5001 32.3829 46.4673 32.2683 46.4053 32.169C46.3434 32.0697 46.2548 31.9898 46.1497 31.9384Z'
            fill='white'
          />
          <path
            d='M51.7592 39.9995L53.1923 38.5664C53.3093 38.4491 53.375 38.2902 53.3749 38.1246C53.3748 37.959 53.3089 37.8002 53.1918 37.683C53.0747 37.5659 52.9159 37.5001 52.7503 37.5C52.5846 37.4999 52.4258 37.5656 52.3085 37.6826L50.8754 39.1157L49.4423 37.6826C49.3251 37.5656 49.1662 37.4999 49.0005 37.5C48.8349 37.5001 48.6761 37.5659 48.559 37.683C48.4419 37.8002 48.376 37.959 48.3759 38.1246C48.3759 38.2902 48.4415 38.4491 48.5585 38.5664L49.9916 39.9995L48.5585 41.4326C48.5004 41.4906 48.4542 41.5595 48.4228 41.6353C48.3913 41.7112 48.375 41.7925 48.375 41.8746C48.375 41.9567 48.3911 42.0381 48.4225 42.1139C48.4539 42.1898 48.5 42.2588 48.558 42.3168C48.6161 42.3749 48.6851 42.421 48.7609 42.4524C48.8368 42.4838 48.9181 42.4999 49.0003 42.4999C49.0824 42.4998 49.1637 42.4836 49.2395 42.4521C49.3154 42.4206 49.3843 42.3745 49.4423 42.3164L50.8754 40.8833L52.3085 42.3164C52.4258 42.4333 52.5846 42.499 52.7503 42.4989C52.9159 42.4988 53.0747 42.433 53.1918 42.3159C53.3089 42.1988 53.3748 42.04 53.3749 41.8743C53.375 41.7087 53.3093 41.5498 53.1923 41.4326L51.7592 39.9995Z'
            fill='white'
          />
        </g>
        <defs>
          <filter
            id='filter0_d_2021_71200'
            x={0}
            y={0}
            width={88}
            height={88}
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'>
            <feFlood floodOpacity={0} result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy={4} />
            <feGaussianBlur stdDeviation={12} />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0' />
            <feBlend
              mode='multiply'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_2021_71200'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_2021_71200'
              result='shape'
            />
          </filter>
        </defs>
      </svg>
    )}
    {...props}
  />
);

export default MuteIcon;
