import React, { useState, useEffect } from "react";

const AutoExpire = ({ children }) => {
  const [isExpired, setIsExpired] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => { setIsExpired(true) }, 1000);
    return () => clearTimeout(timer);
  }, []);

  if (isExpired) {
    return null;
  }

  return (
    <> {children} </>
  )
};

export default AutoExpire;