import {
  ButtonBookmark,
  ImgCustom,
  ListImages,
  PopupShowVideo,
  ReadMoreLess,
  VideoDetail,
  ViewFullImage,
} from 'components';
import { FileZipUploadIcon } from 'images';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { convertToCurrentTime, decryptPath, linkS3Video, linkS3VideoM3U8 } from 'utils/utils';
import { postBookMarkDetail } from 'store/actions/myPage';
import VideoCustomDetail from 'pages/creator/layout/recording/VideoCustomDetail';

const ContentSaleMode2 = ({ detailPost = {} }) => {
  const [dataViewFull, setDataViewFull] = useState({});
  const [thumbnail, setThumbnail] = useState();
  // useDisabledF12();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    detailPost &&
      detailPost?.medias?.map((item) => item?.type === 4 && setThumbnail(item?.url));
  }, [detailPost]);

  const handleDownloadFile = (item) => {
    const a = document.createElement('a');
    const linkDownLoad = `${decryptPath(item.url)}`;
    a.href = linkDownLoad;
    a.download = linkDownLoad;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  const handlePostBookMark = () => {
    dispatch(postBookMarkDetail(detailPost?.id));
  };
  return (
    <div className='px-16 mt-3 content-sale'>
      {dataViewFull?.image?.url && (
        <ViewFullImage dataViewFull={dataViewFull} close={() => setDataViewFull({})} />
      )}
      {detailPost?.title && (
        <div className='bold mb-4 text-center display-linebreak'>
          <ReadMoreLess content={detailPost?.title} />
        </div>
      )}

      {detailPost?.medias?.map(
        (item, index) =>
          (item.type === 2 || item.type === 5) && (
            <div className='bookmark-wrapper' key={index}>
              <ButtonBookmark post={detailPost} onClick={handlePostBookMark} />
              {item.type === 5 ? (
                <div
                  className='image-thumbnail'
                  style={{ position: 'relative' }}
                  onClick={() => handleDownloadFile(item)}>
                  <ImgCustom className='image-thumbnail' src={thumbnail} alt=''></ImgCustom>
                  <div className='file_zip'>
                    <FileZipUploadIcon className='file-zip-anticon' />
                    <div className='file_name'>{item.file_name}</div>
                  </div>
                </div>
              ) : item?.url?.indexOf('.m3u8') !== -1 ? (
                <VideoCustomDetail
                  className='video-detail main_video img-content-recod'
                  src={`${linkS3VideoM3U8}${decryptPath(item.url) + '#t=0.1'}`}
                  thumbnail={`${thumbnail}`}
                  style={{ marginTop: 0 }}
                  isDetailPost
                  handleNavigate={() => {}}
                />
              ) : (
                <VideoDetail
                  className='video-detail main_video'
                  style={{ marginTop: 0 }}
                  link={item.url}
                  background_video={thumbnail}
                />
              )}
            </div>
          )
      )}

      <PopupShowVideo
        item={detailPost?.medias?.filter((item) => item.type === 3)}
        thumbnail={thumbnail}
        // classNameCustom='C021'
      />

      {(() => {
        const data = detailPost?.medias
          ?.filter((item) => item?.type === 1)
          .filter((item) => item.public_flag === 1);
        return (
          data.length > 0 && (
            <ListImages
              images={data}
              className='custom-fan'
              is_idol={true}
              noClick={false}
              isPostDetail={true}
              onClickImage={setDataViewFull}
            />
          )
        );
      })()}

      <div className='content-body'>
        {detailPost?.content && (
          <div className='mb-4'>
            <div className='bold mb-2'>{t('PostDetail.productDesc')}</div>
            <div className='text'>
              <ReadMoreLess content={detailPost?.content} />
            </div>
          </div>
        )}

        {detailPost?.packages?.length > 0 && (
          <div className='mb-4'>
            <div className='bold mb-2'>{t('PostDetail.planContent')}</div>
            <div className='text'>
              {detailPost.all_package ? t('Common.allPlan') : detailPost.package_names}
            </div>
          </div>
        )}

        {detailPost?.number_part && (
          <div className='mb-4'>
            <div className='bold mb-2'>{t('PostDetail.partNumber')}</div>
            <div className='text'>{detailPost.number_part || ''}</div>
          </div>
        )}

        {!!detailPost?.enable_schedule && (
          <div className='mb-4'>
            <div className='bold mb-2'>{t('PostDetail.salesPeriod')}</div>
            <div className='text'>
              {convertToCurrentTime(
                detailPost?.public_time || detailPost.created_at,
                'YYYY/MM/DD HH:mm:ss'
              )}
              {detailPost?.unpublic_time &&
                ' ～' + convertToCurrentTime(detailPost?.unpublic_time, 'YYYY/MM/DD HH:mm:ss')}
              {t('PostDetail.to')}
            </div>
          </div>
        )}
        <div className='mb-4'>
          <div className='bold mb-2'>{t('PostDetail.purchasePremiumTicket')}</div>
          <div className='text'>
            {detailPost.number_premium_ticket !== null
              ? t('PostDetail.premiumTicketTarget')
              : t('PostDetail.notPremiumTicketTarget')}
          </div>
        </div>

        {detailPost?.number_premium_ticket !== null && (
          <div className='mb-4'>
            <div className='bold mb-2'>{t('PostDetail.numberPremiumTicket')}</div>
            <div className='text'>{detailPost.number_premium_ticket + t('Common.sheet')}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContentSaleMode2;
