import { useTranslation } from 'react-i18next';
import { AVATAR_DEFAULT, RANKING_1, RANKING_2, RANKING_3 } from 'utils/constant';
import { ImgCustom } from '..';
import useUserRole from '../../hooks/useUserRole';
import { formatCoin, linkS3 } from '../../utils/utils';
import './FanRankingItem.scss';
import { MiniCoin, Rank1, Rank2, Rank3 } from 'images';
import DefaultAvatar from 'images/DefaultAvatar.svg';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const FanRankingItem = ({ item = {}, className = '', handleClick }) => {
  const { isIdol } = useUserRole();
  const { t, i18n } = useTranslation();
  const { idRankingBuy } = useSelector((state) => state.users);
  useEffect(() => {
    if (idRankingBuy) {
      const idPost = document.getElementById(idRankingBuy.id);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idRankingBuy]);

  return (
    <div
      id={item?.rank}
      className={`ranking-item ${className} ${isIdol ? 'isIdol' : ''}`}
      onClick={() => handleClick(item?.account_id)}>
      <div className={`ranking ${i18n.language === 'en' ? 'en' : ''}`}>
        {+item.rank === 1 ? (
          <Rank1 className='rank-icon' />
        ) : +item.rank === 2 ? (
          <Rank2 className='rank-icon' />
        ) : +item.rank === 3 ? (
          <Rank3 className='rank-icon' />
        ) : (
          `${t('Acount.FanRankingItem.rankingItem')} ${item.rank}`
        )}
      </div>
      <div className='ranking-item-info'>
        <ImgCustom
          className='people-avatar'
          src={item.avatar ? `${item.avatar}` : DefaultAvatar}
          alt='avatar'
          screen={item.avatar ? 'recommend_top_56_56' : ''}
        />
        <div className='people-info'>
          <div className='name'>
            {item.account_name || item.full_name || t('Creator.accountNoName')}
          </div>
          <div className='people-points'>
            <MiniCoin className='icon' />
            <div className='points'>{formatCoin(item?.price)}</div>
            <div className='text'>{t('Purchase.point')}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FanRankingItem;
