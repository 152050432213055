import { getEnv } from 'configs/env';
import { callApi } from 'utils/request';
import Storage from 'utils/storage';
import { TYPES } from '.';
import {
  configParamsGet,
  configParamsPostJSON,
  configParamsPostNoBody,
  configParamsDELETE,
  ENV,
  updateStatus,
  configParamsPUT,
} from 'utils/constant';
import { queryString } from 'utils/utils';
import i18n from 'translate/i18n';
import { pushNotify } from 'components/Notification';
// UI F002-C030
const API = {
  getListPost: `${ENV}/posts`,
  getListCreator: (id) => `${ENV}/creators/${id}`,
  getDetailCreator: (id) => `${ENV}/users/${id}`,
  getAnotherPostDetailCreator: (data) => {
    return `${ENV}/users/${data?.id}/posts?${data?.type && `type=${data?.type}`}${
      data?.page ? `&page=${data?.page}` : ''
    }${data?.limit ? `&limit=${data?.limit}` : ''}${
      data?.keyword ? `&keyword=${data?.keyword}` : ''
    }`;
  },
  getPostFanViewCreator: (id) => `${ENV}/fan/${id}/posts`,
  getLiveStream: (account_id) => `${ENV}/livestreams?account_id=${account_id}&status=3`,
  postLikePost: (id) => `${ENV}/posts/${id}/likes`,
  getListPostCreator: (data) =>
    `${ENV}/users/${data?.id}/posts?${data?.type && `type=${data?.type}`}${
      data?.page ? `&page=${data?.page}` : ''
    }${data?.limit ? `&limit=${data?.limit}` : ''}${
      data?.keyword ? `&keyword=${data?.keyword}` : ''
    }`,
  getNoticationCreator: `${ENV}/user/notification/badge`,
  postBookMarkF017: (id) => `${ENV}/fan/${id}/bookmark`,
  listHighlightC017_C020: (account_id) => `${ENV}/users/${account_id}/highlights?limit=30`,
  getListRecordingCreator: (params) => `${ENV}/live-archives?${queryString(params)}`,
  getListPostShortVideo: (params) => `${ENV}/short-videos?${queryString(params)}`,
};

const getListCreator = () => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_CREATOR_REQUEST));
    callApi(
      API.getListCreator,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_CREATOR_SUCCESS, {
            res: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_CREATOR_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};
const getListPost = () => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIST_POST_CREATOR_REQUEST));
    callApi(
      API.getListPost,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.LIST_POST_CREATOR_SUCCESS, {
            res: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIST_POST_CREATOR_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};
const getDetailCreator = (account_id, handleSuccess) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.DETAIL_CREATOR_REQUEST));
    callApi(
      API.getDetailCreator(account_id),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.DETAIL_CREATOR_SUCCESS, {
            res: response,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.DETAIL_CREATOR_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      null
    );
  };
};
const getPostFanViewCreator = (id) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.POSTS_CREATOR_REQUEST));
    callApi(
      API.getPostFanViewCreator(id),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.POSTS_CREATOR_SUCCESS, {
            res: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.POSTS_CREATOR_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      null
    );
  };
};
const getLiveStream = (account_id) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIVE_STREAM_CREATOR_REQUEST));
    callApi(
      API.getLiveStream(account_id),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.LIVE_STREAM_CREATOR_SUCCESS, {
            res: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIVE_STREAM_CREATOR_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      null
    );
  };
};
const postLikeLive = (id, data) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.POST_LIKE_CREATOR_REQUEST));
    callApi(
      API.postLikePost(id),
      configParamsPostJSON(data),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.POST_LIKE_CREATOR_SUCCESS, response));
      },
      (error) => {
        dispatch(updateStatus(TYPES.POST_LIKE_CREATOR_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      null
    );
  };
};
const getListPostCreator = (data, isSelf, handleSuccess, handleFailed) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIST_POSTS_CREATOR_REQUEST));
    callApi(
      isSelf === true
        ? API.getListPostCreator({ ...data })
        : isSelf === false
        ? API.getAnotherPostDetailCreator({ ...data })
        : '',
      configParamsGet,
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.LIST_POSTS_CREATOR_SUCCESS, { res: response }));
        handleSuccess && handleSuccess();
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIST_POSTS_CREATOR_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        handleFailed && handleFailed();
      }
    );
  };
};

const getListPostShortVideo = (data, handleSuccess, handleFailed) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIST_POSTS_SHORT_VIDEO_REQUEST));
    callApi(
      API.getListPostShortVideo({ ...data }),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.LIST_POSTS_SHORT_VIDEO_SUCCESS, { res: response }));
        handleSuccess && handleSuccess();
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIST_POSTS_SHORT_VIDEO_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        handleFailed && handleFailed();
      }
    );
  };
};

const getListRecordingCreator = (params, handleSuccess, handleFailed) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIST_RECORDING_REQUEST));
    callApi(
      API.getListRecordingCreator(params),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.LIST_RECORDING_SUCCESS, { res: response }));
        handleSuccess && handleSuccess();
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIST_RECORDING_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        handleFailed && handleFailed();
      }
    );
  };
};

const setIdScrollBackRecording = (id, cb) => {
  return (dispatch) => {
    dispatch(
      updateStatus('SET_ID_SCROLL_BACK_RECORDING', {
        data: {
          id,
        },
      })
    );
    cb && cb();
  };
};

const clearIdScrollBackRecording = (id, cb) => {
  return (dispatch) => {
    dispatch(
      updateStatus('CLEAR_ID_SCROLL_BACK_RECORDING', {
        data: {
          id,
        },
      })
    );
    cb && cb();
  };
};

const getDetailRecording = (liveKey) => {
  const url = `${ENV}/live-archives/${liveKey}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_DETAIL_RECORDING_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_DETAIL_RECORDING_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_DETAIL_RECORDING_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const updateDetailRecording = (liveKey, data) => {
  const url = `${ENV}/live-archives/${liveKey}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.UPDATE_DETAIL_RECORDING_REQUEST));
    callApi(
      url,
      configParamsPUT(data),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.UPDATE_DETAIL_RECORDING_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.UPDATE_DETAIL_RECORDING_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const deleteRecording = (liveKey, action, handleDeleteSuccess, handleFailed) => {
  const url = `${ENV}/live-archives/${liveKey}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.DELETE_RECORDING_REQUEST));
    callApi(
      url,
      configParamsDELETE,
      null,
      async (response) => {
        // const msg =
        //   i18n.t(`ValidateMsg.${response?.message}`) !== undefined
        //     ? i18n.t(`ValidateMsg.DELETE_RECORDINGLIVE_SUCCESS`)
        //     : i18n.t(`ValidateMsg.DELETE_RECORDINGLIVE_SUCCESS`);
        // pushNotify('success', msg);
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
        await dispatch(
          updateStatus(TYPES.DELETE_RECORDING_SUCCESS, {
            data: response.data,
            liveKey: liveKey,
          })
        );
        action && (await dispatch(action));
        handleDeleteSuccess && handleDeleteSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_RECORDING_FAILED, { error: error.errors }));
        handleFailed && handleFailed();
      }
    );
  };
};

const fanFollowCreator = (id, handleSuccess) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/users/${id}/following`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.FAN_FOLLOW_CREATOR_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FAN_FOLLOW_CREATOR_SUCCESS, {
            data: response.data,
          })
        );
        handleSuccess && handleSuccess(response.data);
      },
      (error) => {
        dispatch(updateStatus(TYPES.FAN_FOLLOW_CREATOR_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};
const reportCreator = () => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.REPORT_CREATOR_REQUEST));
    callApi(
      API.getListCreator,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.REPORT_CREATOR_SUCCESS, {
            res: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.REPORT_CREATOR_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getNoticationCreator = () => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_NOTIFICATIONS_CREATOR_REQUEST));
    callApi(
      API.getNoticationCreator,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_NOTIFICATIONS_CREATOR_SUCCESS, {
            res: response,
          })
        );
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_NOTIFICATIONS_CREATOR_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const postBookMarkF017 = (id) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.POST_BOOKMARK_F017_REQUEST));
    callApi(
      API.postBookMarkF017(id),
      configParamsPostNoBody,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.POST_BOOKMARK_F017_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.POST_BOOKMARK_F017_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const listHighlightC017_C020 = (account_id, handleSuccess) => {
  return () => {
    callApi(
      API.listHighlightC017_C020(account_id),
      configParamsGet,
      null,
      (response) => {
        handleSuccess && handleSuccess(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  };
};

const resetActionCreatorHomePage = () => (dispatch) => {
  dispatch(updateStatus('RESET_ACTION_CREATOR_PAGE'));
};

const resetActionDataPopupRecor = () => (dispatch) => {
  dispatch(updateStatus('RESET_ACTION_DATA_POPUP_RECOR'));
};

const resetListPostShortVideo = () => (dispatch) => {
  dispatch(updateStatus('RESET_LIST_POST_SHORT_VIDEO'));
};

export {
  getListCreator,
  getListPost,
  getDetailCreator,
  getPostFanViewCreator,
  getLiveStream,
  postLikeLive,
  getListPostCreator,
  resetActionCreatorHomePage,
  resetActionDataPopupRecor,
  fanFollowCreator,
  reportCreator,
  getNoticationCreator,
  postBookMarkF017,
  listHighlightC017_C020,
  getListRecordingCreator,
  getDetailRecording,
  deleteRecording,
  getListPostShortVideo,
  updateDetailRecording,
  resetListPostShortVideo,
  setIdScrollBackRecording,
  clearIdScrollBackRecording,
};
