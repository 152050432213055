export default function FailIcon(props) {
  return (
    <svg
      width={80}
      height={80}
      viewBox='0 0 80 80'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M75.7456 46.6247L75.7223 46.6363C72.9801 48.7393 71.8183 52.3178 72.7944 55.6294L72.8059 55.6527C74.3628 60.9161 70.5054 66.2258 65.0211 66.3653H64.998C61.5355 66.4583 58.4912 68.6658 57.3409 71.9308V71.9424C55.505 77.1244 49.2542 79.1577 44.7344 76.0321C41.9278 74.1157 38.2176 74.0157 35.2648 76.0321H35.2533C30.7336 79.146 24.4825 77.1243 22.6583 71.9307C22.0952 70.3371 21.0618 68.9519 19.6946 67.9581C18.3275 66.9644 16.691 66.4089 15.0014 66.3652H14.9781C9.49404 66.2257 5.63639 60.916 7.19342 55.6525L7.20498 55.6293C8.18076 52.3177 7.01889 48.7391 4.27701 46.6361L4.25373 46.6246C-0.103457 43.2782 -0.103457 36.7252 4.25373 33.3789L4.27701 33.3674C7.01889 31.2644 8.18076 27.6857 7.19342 24.3743V24.351C5.62467 19.0877 9.49389 13.7777 14.9781 13.6383H15.0014C18.4522 13.5453 21.5079 11.3377 22.6583 8.07285V8.06129C24.4823 2.87925 30.7336 0.845974 35.2533 3.9716H35.2648C38.1114 5.93519 41.8759 5.93519 44.7344 3.9716C49.2997 0.818943 55.5158 2.91035 57.3409 8.06129V8.07285C58.4912 11.3261 61.5353 13.5455 64.998 13.6383H65.0211C70.5053 13.7777 74.3628 19.0877 72.8059 24.351L72.7944 24.3743C71.8183 27.6857 72.9801 31.2644 75.7223 33.3674L75.7456 33.3789C80.1028 36.7252 80.1028 43.2783 75.7456 46.6247Z'
        fill='#E5E6EB'
      />
      <path
        d='M39.9997 61.642C51.953 61.642 61.643 51.952 61.643 39.9987C61.643 28.0455 51.953 18.3555 39.9997 18.3555C28.0465 18.3555 18.3564 28.0455 18.3564 39.9987C18.3564 51.952 28.0465 61.642 39.9997 61.642Z'
        fill='#DC143C'
      />
      <path
        opacity='0.1'
        d='M56.6177 26.1456C52.8741 23.0659 48.0845 21.2148 42.8633 21.2148C30.9102 21.2148 21.2148 30.9102 21.2148 42.8633C21.2148 48.0845 23.0659 52.8741 26.1455 56.6177C21.3891 52.65 18.3594 46.6817 18.3594 39.9995C18.3594 28.0463 28.0464 18.3594 39.9995 18.3594C46.6817 18.3594 52.65 21.3891 56.6177 26.1456Z'
        fill='#1A1A1A'
      />
      <path
        d='M50 32L32 50'
        stroke='white'
        strokeWidth={6}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M50 50L32 32'
        stroke='white'
        strokeWidth={6}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
