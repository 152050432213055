import { LayerProgress, LoadingProgress } from "../style";


const ProgressBarRunTimeShortVideo = ({ play, duration = 100, progress = 0 }) => {
  return (
    <LayerProgress>
      <LoadingProgress
        progress={(progress / duration) * 100 || 0}
        play={play}
        time={duration}>
        <div className='progress-bar' />
      </LoadingProgress>
    </LayerProgress>
  );
};

export default ProgressBarRunTimeShortVideo;
