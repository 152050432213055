import Icon from '@ant-design/icons';

const ListIconV2 = (props) => (
  <Icon
    component={() => (
      <svg
        width='21'
        height='20'
        viewBox='0 0 21 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6.25 11.25C7.97589 11.25 9.375 9.85089 9.375 8.125C9.375 6.39911 7.97589 5 6.25 5C4.52411 5 3.125 6.39911 3.125 8.125C3.125 9.85089 4.52411 11.25 6.25 11.25Z'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.875 6.25H19.375'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.875 10H19.375'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.75 13.75H19.375'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M1.4082 15C1.68563 13.9266 2.3118 12.9759 3.18833 12.2971C4.06485 11.6183 5.14206 11.25 6.25068 11.25C7.3593 11.25 8.43651 11.6183 9.31304 12.2971C10.1896 12.9759 10.8157 13.9266 11.0932 15'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default ListIconV2;
