import {
  configParamsGet,
  configParamsPostJSON,
  configParamsPostNoBody,
  configParamsPUT,
  ENV,
  updateStatus,
} from '../../utils/constant';
import { callApi } from '../../utils/request';
import Storage from '../../utils/storage';
import { TYPES } from './index';
import { getEnv } from 'configs/env';
import { queryString } from 'utils/utils';
import { pushNotify } from 'components/Notification';
import i18n from 'translate/i18n';

const API = {
  getUserInfoOthers: (id) => `${ENV}/users/${id}`,
  getListPostCreator: (data) =>
    `${ENV}/users/${data?.id}/posts?${data?.type && `type=${data?.type}`}${
      data?.page ? `&page=${data?.page}` : ''
    }${data?.limit ? `&limit=${data?.limit}` : ''}${
      data?.keyword ? `&keyword=${data?.keyword}` : ''
    }${data?.hashtag_id ? `&hashtag_id=${data?.hashtag_id}` : ''}
    ${data?.sub_title ? `&sub_title=${data?.sub_title}` : ''}
    ${data?.only_media ? `&only_media=${data?.only_media}` : ''}
    `,
  getAnotherPostDetailCreator: (data) => {
    return `${ENV}/users/${data?.id}/posts?${data?.type && `type=${data?.type}`}${
      data?.page ? `&page=${data?.page}` : ''
    }${data?.limit ? `&limit=${data?.limit}` : ''}${
      data?.keyword ? `&keyword=${data?.keyword}` : ''
    }${data?.hashtag_id ? `&hashtag_id=${data?.hashtag_id}` : ''}
    ${data?.sub_title ? `&sub_title=${data?.sub_title}` : ''}
    ${data?.only_media ? `&only_media=${data?.only_media}` : ''}
    `;
  },
  postLikePost: (id) => `${ENV}/posts/${id}/likes`,
  postNormalBuyPackage: (package_id) => `${ENV}/packages/${package_id}/buy`,
  postRetweet: (id) => `${ENV}/posts/${id}/share`,
  ghimPost: (id) => `${ENV}/pinned/${id}`,
};

const getListPostCreator = (data, isSelf, handleSuccess, handleFailed, otherToken) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIST_POSTS_CREATOR_REQUEST));
    const token = Storage.get('USER_ACCESS_TOKEN');
    const configParams = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${otherToken || token}`,
      },
    };
    callApi(
      isSelf === true
        ? API.getListPostCreator({ ...data })
        : isSelf === false
        ? API.getAnotherPostDetailCreator({ ...data })
        : '',
      configParams,
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.LIST_POSTS_CREATOR_SUCCESS, { res: response }));
        handleSuccess && handleSuccess();
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIST_POSTS_CREATOR_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        handleFailed && handleFailed();
        window.location.href = `/not-found`;
      }
    );
  };
};

const postCommentDetail = (id, data, handleSuccess, postDetailMeta, callGetDetail = true) => {
  const formData = new FormData();
  formData.append('content', data.content);
  const url = `${getEnv('REACT_APP_API_SERVER')}/posts/${id}/comments`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.POST_COMMENT_DETAILS_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.POST_COMMENT_DETAILS_SUCCESS, {
            data: response.data,
            metaPost: postDetailMeta,
          })
        );
        handleSuccess && handleSuccess(response);
        callGetDetail && dispatch(getCommentDetail(id));
      },
      (error) => {
        dispatch(updateStatus(TYPES.POST_COMMENT_DETAILS_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getCommentDetail = (post_id, page = 1, limit = 10) => {
  const url = `${getEnv(
    'REACT_APP_API_SERVER'
  )}/posts/${post_id}/comments?page=${page}&limit=${limit}`;
  const configParams = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_COMMENT_DETAILS_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_COMMENT_DETAILS_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_COMMENT_DETAILS_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getMetaCommentOPG = (id, postId, commentId, parentId) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/post-meta/${id}`;
  const configParams = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_META_COMMENT_OPG_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_META_COMMENT_OPG_SUCCESS, {
            data: {
              ...response?.data,
              url,
            },
            commentId,
            postId,
            parentId,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_META_COMMENT_OPG_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const postLikeLive = (post) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.POST_LIKE_CREATOR_SUCCESS, post));
    callApi(
      API.postLikePost(post.id),
      configParamsPostJSON(post.id),
      null,
      null,
      (error) => {
        dispatch(updateStatus(TYPES.POST_LIKE_CREATOR_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      },
      dispatch,
      null
    );
  };
};

const searchSaleContentAction = (
  keyword = '',
  page = 1,
  limit = 10,
  handleSuccess,
  handleFailed
) => {
  const url = `${ENV}/searches?keyword=${encodeURIComponent(
    keyword
  )}&limit=${limit}&page=${page}&type=sales`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.SEARCH_SALE_CONTENT_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.SEARCH_SALE_CONTENT_SUCCESS, {
            data: response.data,
            pagination: response.pagination,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.SEARCH_SALE_CONTENT_FAILED, { error: error.errors }));
        handleFailed && handleFailed(error);
      }
    );
  };
};

const searchPostAction = (keyword = '', page = 1, limit = 12, handleSuccess, handleFailed) => {
  const url = `${ENV}/searches?keyword=${encodeURIComponent(
    keyword
  )}&limit=${limit}&page=${page}&type=post`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.SEARCH_POST_TIMELINE_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.SEARCH_POST_TIMELINE_SUCCESS, {
            data: response.data,
            pagination: response.pagination,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.SEARCH_POST_TIMELINE_FAILED, { error: error.errors }));
        handleFailed && handleFailed(error);
      }
    );
  };
};

const postRetweet = (id, data, handleSuccess, handleFailed) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.POST_RETWEET_REQUEST_REQUEST));
    callApi(
      API.postRetweet(id),
      configParamsPostJSON(data),
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.POST_RETWEET_REQUEST_SUCCESS, response));
        handleSuccess && handleSuccess();
      },
      (error) => {
        dispatch(updateStatus(TYPES.POST_RETWEET_REQUEST_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        handleFailed && handleFailed();
      },
      dispatch,
      null
    );
  };
};

const ghimPostMypage = (id, handleSuccess, handleFailed) => {
  const configParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GHIM_POST_MYPAGE_REQUEST));
    callApi(
      API.ghimPost(id),
      configParams,
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GHIM_POST_MYPAGE_SUCCESS, response));
        handleSuccess && handleSuccess();
        const top = document.getElementById('pin-post');
        top && top.scrollIntoView({ top: 0, left: 0, behavior: 'smooth', block: 'start' });
        if (response.data.is_pinned === 0) {
          pushNotify('success', i18n.t('Popup.postUnPin'));
        } else {
          pushNotify('success', i18n.t('Popup.postPin'));
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.GHIM_POST_MYPAGE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        handleFailed && handleFailed();
      },
      dispatch,
      null
    );
  };
};

const likeCommentDetail = (id, onSuccess = () => {}) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}/likes`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIKE_COMMENT_DETAIL_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.LIKE_COMMENT_DETAIL_SUCCESS, {
            data: response.data,
          })
        );
        onSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIKE_COMMENT_DETAIL_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const updateLikeCommentDetail = (commentId, data, onSuccess = () => {}) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${commentId}/likes`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.BEFORE_LIKE_COMMENT_DETAIL_SUCCESS, { data: data }));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.LIKE_COMMENT_DETAIL_SUCCESS, {
            data: response.data,
          })
        );
        onSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIKE_COMMENT_DETAIL_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const creatorGetPostDetail = (id, type, handleSuccess, otherToken, topPage) => {
  // const url = `${getEnv('REACT_APP_API_SERVER')}/creator/posts/${id}${
  //   is_my_post ? '?is_my_post=1' : ''
  // }`;
  const url = `${getEnv('REACT_APP_API_SERVER')}/posts/${id}?${type && `type=${type}`}`;
  const token = Storage.get('USER_ACCESS_TOKEN');

  const configParams = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${otherToken ?? token}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_GET_POST_DETAILS_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CREATOR_GET_POST_DETAILS_SUCCESS, {
            data: response,
          })
        );
        topPage && topPage();
        handleSuccess && handleSuccess(response.data);
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATOR_GET_POST_DETAILS_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        if (!otherToken) {
          window.location.href = `/not-found`;
        }
      }
    );
  };
};

const likePost = (id) => {
  // TODO
  const url = `${getEnv('REACT_APP_API_SERVER')}/posts/${id}/likes`;
  const configParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIKE_POST_DETAILS_REQUEST));
    callApi(url, configParams, null, null, (error) => {
      dispatch(updateStatus(TYPES.LIKE_POST_DETAILS_FAILED, { err: error.errors }));
      dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
    });
  };
};

const postLikeDetail = (id) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/posts/${id}/likes`;
  const configParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIKE_POST_DETAILS_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.LIKE_POST_DETAILS_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIKE_POST_DETAILS_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};
const updatePostLikeDetail = (postDetail, noCallAPI) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/posts/${postDetail?.id}/likes`;
  const configParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.UPDATE_LIKE_POST_DETAILS_REQUEST, postDetail));
    if (noCallAPI) return;

    callApi(
      url,
      configParams,
      null,
      async () => {},
      (error) => {
        dispatch(updateStatus(TYPES.LIKE_POST_DETAILS_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const updatePostMyPageWhenUpdate = (postDetail, isPostSale = false) => {
  return (dispatch) => {
    dispatch(
      updateStatus('UPDATE_POST_MYPAGE_WHEN_UPDATE_POST', {
        postDetail,
        isPostSale,
      })
    );
  };
};

const updateHiddenLastComment = (idPost, idHidden, value) => {
  return (dispatch) => {
    dispatch(
      updateStatus('UPDATE_HIDDEN_LAST_COMMENT_MYPAPGE', {
        idPost,
        idHidden,
        value,
      })
    );
  };
};

const bookmarkPost = (id) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/posts/${id}/bookmarks`;
  const configParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.BOOK_MARK_DETAILS_REQUEST));
    callApi(url, configParams, null, null, (error) => {
      dispatch(updateStatus(TYPES.BOOK_MARK_DETAILS_FAILED, { err: error.errors }));
      dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
    });
  };
};

const postBookMarkDetail = (id) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/posts/${id}/bookmarks`;
  const configParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.BOOK_MARK_DETAILS_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.BOOK_MARK_DETAILS_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.BOOK_MARK_DETAILS_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getListPostTimeLine = (data, handleSuccess, handleFailed) => {
  const API = { getListPostTimeLine: (params) => `${ENV}/timelines?${queryString(params)}` };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIST_POSTS_TIME_LINE_REQUEST));
    callApi(
      API.getListPostTimeLine({ ...data }),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.LIST_POSTS_TIME_LINE_SUCCESS, { res: response }));
        handleSuccess && handleSuccess();
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIST_POSTS_TIME_LINE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        handleFailed && handleFailed();
      }
    );
  };
};

const postLike = (id, handleSuccess) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/posts/${id}/likes`;
  const configParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIKE_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.LIKE_SUCCESS, {
            data: response.data,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIKE_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const postBookMark = (post) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/posts/${post.id}/bookmarks`;
  const configParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.BOOK_MARKS_SUCCESS, post));
    callApi(url, configParams, null, null, (error) => {
      dispatch(updateStatus(TYPES.BOOK_MARKS_FAILED, { err: error.errors }));
      dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
    });
  };
};

const creatorDeleteArticle = (post_id, handleSuccess, item) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/posts/post/${post_id}`;
  const configParams = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_DELETE_ARTICLE_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CREATOR_DELETE_ARTICLE_SUCCESS, {
            data: {
              data: response.data,
              payload: post_id,
              item: item,
            },
          })
        );
        handleSuccess && handleSuccess(response.data);
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATOR_DELETE_ARTICLE_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const creatorDeleteRecore = (post_id, handleSuccess) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/live-archives/${post_id}`;
  const configParams = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_DELETE_ARTICLES_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CREATOR_DELETE_ARTICLES_SUCCESS, {
            data: {
              data: response.data,
              payload: post_id,
            },
          })
        );
        handleSuccess && handleSuccess(response.data);
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATOR_DELETE_ARTICLES_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const fanFollowCreator = (id, handleSuccess) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/users/${id}/following`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.FAN_FOLLOW_CREATOR_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FAN_FOLLOW_CREATOR_SUCCESS, {
            data: {
              data: response.data,
              payload: id,
            },
          })
        );
        handleSuccess && handleSuccess(response.data);
      },
      (error) => {
        dispatch(updateStatus(TYPES.FAN_FOLLOW_CREATOR_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const hiddenPostMypage = (id, handleSuccess) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/hided/${id}`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.HIDDEN_POST_MYPAGE_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.HIDDEN_POST_MYPAGE_SUCCESS, {
            data: {
              data: response.data,
              payload: id,
            },
          })
        );
        handleSuccess && handleSuccess(response.data);
        if (response.data.is_hided === 0) {
          pushNotify('success', i18n.t('Popup.postDisplayed'));
        } else {
          pushNotify('success', i18n.t('Popup.postHidden'));
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.HIDDEN_POST_MYPAGE_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const deleteCommentDetail = (id, post_id, handleSuccess, render) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}`;
  const configParams = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.DELETE_COMMENT_DETAILS_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.DELETE_COMMENT_DETAILS_SUCCESS, {
            data: {
              data: response.data,
              payload: id,
              postId: post_id,
            },
          })
        );
        render && render();
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_COMMENT_DETAILS_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const putEditComment = ({ id, data, onSuccess = () => {}, onFailed = () => {} }) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}`;
  const configParams = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.UPDATE_COMMENT_POST_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.UPDATE_COMMENT_POST_SUCCESS, {
            data: response,
          })
        );
        onSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.UPDATE_COMMENT_POST_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        onFailed(error);
      }
    );
  };
};

const postRepListCommentDetail = ({ id, data, onSuccess = () => {}, onFailed = () => {} }) => {
  const formData = new FormData();
  formData.append('content', data.content);
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}/comments`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATE_COMMENT_POST_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CREATE_COMMENT_POST_SUCCESS, {
            data: response,
          })
        );
        onSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATE_COMMENT_POST_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        onFailed(error);
      }
    );
  };
};

const deleteReplyCommentDetail = (id, idParent, handleSuccess, render) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}`;
  const configParams = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.DELETE_REPLY_COMMENT_DETAILS_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.DELETE_REPLY_COMMENT_DETAILS_SUCCESS, {
            data: {
              data: response.data,
              payload: id,
              idParent,
            },
          })
        );
        render && render();
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.DELETE_REPLY_COMMENT_DETAILS_FAILED, { err: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const listHighlightTimeLine = (id, handleSuccess, handleFailed) => {
  const API = {
    listHighlightTimeLine: (id) => `${ENV}/users/${id}/highlights`,
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIST_HIGH_LIGHT_REQUEST));
    callApi(
      API.listHighlightTimeLine(id),
      //`${ENV}/followings/highlights`,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.LIST_HIGH_LIGHT_SUCCESS, { res: response.data }));
        handleSuccess && handleSuccess(response.data);
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIST_HIGH_LIGHT_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        handleFailed && handleFailed();
      }
    );
  };
};

const list3ImgMypage = (id, handleSuccess, handleFailed) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIST_3_IMG_MY_PAGE_REQUEST));
    callApi(
      `${ENV}/users/${id}/user-images`,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.LIST_3_IMG_MY_PAGE_SUCCESS, { res: response.data }));
        handleSuccess && handleSuccess();
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIST_3_IMG_MY_PAGE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        handleFailed && handleFailed();
      }
    );
  };
};

const postFollowUser = (id) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/users/${id}/following`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.FOLLOW_USER_COMMENT_DETAILS_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FOLLOW_USER_COMMENT_DETAILS_SUCCESS, {
            data: {
              data: response.data,
              payload: id,
            },
          })
        );
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.FOLLOW_USER_COMMENT_DETAILS_FAILED, { err: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const postFollowCommentDetail = (
  id,
  onRequest = () => {},
  onFinally = () => {},
  isUseRedux = true
) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/users/${id}/following`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    onRequest();
    isUseRedux && dispatch(updateStatus(TYPES.FOLLOW_USER_COMMENT_DETAILS_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        isUseRedux &&
          (await dispatch(
            updateStatus(TYPES.FOLLOW_USER_COMMENT_DETAILS_SUCCESS, {
              data: {
                data: response.data,
                payload: id,
              },
            })
          ));
        onFinally();
      },
      (error) => {
        onFinally();
        if (isUseRedux) {
          dispatch(
            updateStatus(TYPES.FOLLOW_USER_COMMENT_DETAILS_FAILED, { err: error.errors })
          );
          dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        }
      }
    );
  };
};
const postFollowCommentDetailNoApi = (data, id) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.FAN_FOLLOW_CREATOR_REQUEST));
    dispatch(
      updateStatus(TYPES.FAN_FOLLOW_CREATOR_SUCCESS, {
        data: {
          data,
          payload: id,
        },
      })
    );
  };
};

const postFollowUserNoApi = (data, id) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.FOLLOW_USER_COMMENT_DETAILS_REQUEST));
    updateStatus(TYPES.FOLLOW_USER_COMMENT_DETAILS_SUCCESS, {
      data: {
        data,
        payload: id,
      },
    });
  };
};
const followOrUnFollowUserNoApi = (data, id) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.FOLLOW_OR_UN_FOLLOW_USER_REQUEST));
    updateStatus(TYPES.FOLLOW_OR_UN_FOLLOW_USER_SUCCESS, {
      data,
      id: id,
    });
  };
};
const likeReplyCommentDetail = (id) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}/likes`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIKE_REPLY_COMMENT_DETAILS_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.LIKE_REPLY_COMMENT_DETAILS_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIKE_REPLY_COMMENT_DETAILS_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const updateLikeReplyComment = (commentId, comment) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${commentId}/likes`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIKE_REPLY_COMMENT_NOW_REQUEST, comment));
    callApi(
      url,
      configParams,
      null,
      async (response) => {},
      (error) => {
        dispatch(updateStatus(TYPES.LIKE_REPLY_COMMENT_DETAIL_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const postFollowReplyCommentDetail = (id) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/users/${id}/following`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.FOLLOW_USER_REPLY_COMMENT_DETAILS_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FOLLOW_USER_REPLY_COMMENT_DETAILS_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.FOLLOW_USER_REPLY_COMMENT_DETAILS_FAILED, { err: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const postRepCommentDetail = (id, data, dataMeta) => {
  const formData = new FormData();
  formData.append('content', data.content);
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}/comments`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.POST_REPLY_COMMENT_DETAILS_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.POST_REPLY_COMMENT_DETAILS_SUCCESS, {
            data: response.data,
            dataMeta,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.POST_REPLY_COMMENT_DETAILS_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getReplyCommentDetail = (comment_id, page = 1, limit = 5) => {
  const url = `${getEnv(
    'REACT_APP_API_SERVER'
  )}/comments/${comment_id}/comments?page=${page}&limit=${limit}`;
  const configParams = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_REPLY_COMMENT_DETAILS_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_REPLY_COMMENT_DETAILS_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_REPLY_COMMENT_DETAILS_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const editCommentDetail = (id, data, handleSuccess, dataMeta) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}`;
  const configParams = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.EDIT_COMMENT_DETAILS_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.EDIT_COMMENT_DETAILS_SUCCESS, {
            data: response.data,
            dataMeta,
          })
        );
        handleSuccess && handleSuccess();
      },
      (error) => {
        dispatch(updateStatus(TYPES.EDIT_COMMENT_DETAILS_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const editCommentDetailNew = (id, handleSuccess) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}?filter_words=false`;
  const configParams = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.EDIT_COMMENT_DETAILS_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        // await dispatch(
        //   updateStatus(TYPES.EDIT_COMMENT_DETAILS_SUCCESS, {
        //     data: response.data,
        //   })
        // );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.EDIT_COMMENT_DETAILS_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const editReplyCommentDetail = (id, data, dataMeta) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/comments/${id}`;
  const configParams = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.EDIT_REPLY_COMMENT_DETAILS_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.EDIT_REPLY_COMMENT_DETAILS_SUCCESS, {
            data: response.data,
            dataMeta,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.EDIT_REPLY_COMMENT_DETAILS_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const postNormalBuyPackage = (package_id, handleSuccess, handleError, item) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.POST_NORMAL_BUY_PACKAGES_REQUEST));
    callApi(
      API.postNormalBuyPackage(package_id),
      configParamsPostNoBody,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.POST_NORMAL_BUY_PACKAGES_SUCCESS, {
            //res: response,
            res: {
              res: response,
              payload: package_id,
              item: item,
            },
          })
        );
        pushNotify(
          'success',
          i18n.t('Popup.messageSubscriptionSuccess'),
          'update_c92_success'
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleError && handleError(error.errors);
        dispatch(updateStatus(TYPES.POST_NORMAL_BUY_PACKAGES_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const postNormalPaymentBuyPackageSuccess = (package_id, handleSuccess) => {
  return (dispatch) => {
    dispatch(
      updateStatus(TYPES.POST_NORMAL_BUY_PACKAGES_SUCCESS, {
        res: {
          payload: package_id,
        },
      })
    );
    handleSuccess && handleSuccess();
  };
};

const updateDetailRecordingV2 = (liveKey, data, handleSuccess) => {
  const url = `${ENV}/live-archives/${liveKey}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.UPDATE_DETAIL_RECORDING_V2_REQUEST));
    callApi(
      url,
      configParamsPUT(data),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.UPDATE_DETAIL_RECORDING_V2_SUCCESS, {
            data: {
              data: response.data,
              payload: liveKey,
            },
          })
        );
        handleSuccess && handleSuccess();
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.UPDATE_DETAIL_RECORDING_V2_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getUserInfoOthers = (id, handleSuccess) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_USER_INFO_OTHERS_REQUEST));
    callApi(
      API.getUserInfoOthers(id),
      configParamsGet,
      null,
      async (response) => {
        //response.success && checkNavigateByRole(isIdol, response?.data?.status);
        await dispatch(
          updateStatus(TYPES.GET_USER_INFO_OTHERS_SUCCESS, {
            data: response,
          })
        );
        handleSuccess && handleSuccess(response);
        //window.scrollTo(0, 0);
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_USER_INFO_OTHERS_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        window.location.href = `/not-found`;
      }
    );
  };
};

const updateBlockInfoOther = (id, value) => {
  return (dispatch) => {
    dispatch(
      updateStatus('UPDATE_BLOCK_INFO_OTHER_USER', {
        id,
        value,
      })
    );
  };
};

const getListBookmark = (page = 1, limit = 10, handleSuccess, handleFail) => {
  const url = `${ENV}/bookmarks?limit=${limit}&page=${page}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIST_BOOKMARK_TIMELINE_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.LIST_BOOKMARK_TIMELINE_SUCCESS, {
            data: response.data,
            pagination: response.pagination,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleFail();
        dispatch(updateStatus(TYPES.LIST_BOOKMARK_TIMELINE_FAILED, { error: error.errors }));
      }
    );
  };
};

const collapseReplyCommentDetail = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.COLLAPSE_REPLY_COMMENT_DETAILS_SUCCESS, payload));
  };
};

const resetActionUserNew = () => (dispatch) => {
  dispatch(updateStatus('RESET_ACTION_USER_NEW_PAGE'));
};

const resetDetailPost = () => (dispatch) => {
  dispatch(updateStatus(TYPES.RESET_DETAIL_POST_REQUEST));
  dispatch(updateStatus(TYPES.RESET_DETAIL_POST_SUCCESS));
};

const resetCommentDetail = () => (dispatch) => {
  dispatch(updateStatus('RESET_COMMENT_DETAIL'));
};

const resetPopupPost = () => (dispatch) => {
  dispatch(updateStatus('RESET_POPUP_LOCK_POST'));
};

const resetPopupPostShortVideo = () => (dispatch) => {
  dispatch(updateStatus('RESET_POST_SHORT_VIDEO'));
};

const resetPostSearch = () => (dispatch) => {
  dispatch(updateStatus('RESET_POST_SEARCH'));
};

const getIDTimeline = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('ID_TIMELINE', payload));
  };
};

const getIDKYC = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('ID_KYC', payload));
  };
};

const getIDSearchPost = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('ID_POST_SEARCH', payload));
  };
};

const resetIDSearchPost = () => (dispatch) => {
  dispatch(updateStatus('RESET_ID_SEARCH_POST'));
};

const getIDSearchSale = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('ID_POST_SEARCH_SALE', payload));
  };
};

const resetIDSearchSale = () => (dispatch) => {
  dispatch(updateStatus('RESET_ID_SEARCH_SALE'));
};

const getIDSearchVideo = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('ID_POST_SEARCH_VIDEO', payload));
  };
};

const resetIDSearchVideo = () => (dispatch) => {
  dispatch(updateStatus('RESET_ID_SEARCH_VIDEO'));
};

const resetIDTimeline = () => (dispatch) => {
  dispatch(updateStatus('RESET_ID_TIMELINE'));
};

const getIDHomePage = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('ID_HOMEPAGE', payload));
  };
};

const resetIDHomePage = () => (dispatch) => {
  dispatch(updateStatus('RESET_ID_HOMEPAGE'));
};

const resetDataInfoOther = () => (dispatch) => {
  dispatch(updateStatus('RESET_DATA_INFO_OTHER'));
};

const getIDFavor = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('ID_FAVOR', payload));
  };
};

const resetDataFavor = () => (dispatch) => {
  dispatch(updateStatus('RESET_ID_FAVOR'));
};

const getIDCMTTimeline = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('ID_CMT_TIMELINE', payload));
  };
};

const resetgetIDCMTTimeline = () => (dispatch) => {
  dispatch(updateStatus('RESET_ID_CMT_TIMELINE'));
};

const getIDCMTHomepage = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('ID_CMT_HOMEPAGE', payload));
  };
};

const resetgetIDCMTHomepage = () => (dispatch) => {
  dispatch(updateStatus('RESET_ID_CMT_HOMEPAGE'));
};

const getIDCMTBookmark = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('ID_CMT_BOOKMARK', payload));
  };
};

const resetgetIDCMTBookmark = () => (dispatch) => {
  dispatch(updateStatus('RESET_ID_CMT_BOOKMARK'));
};

const setIdScrollBackRecommend = (id, pathUrl, typeTopList, cb) => {
  return (dispatch) => {
    dispatch(
      updateStatus('SET_ID_SCROLL_BACK_RECOMMEND', {
        data: {
          id,
          pathUrl,
          typeTopList,
        },
      })
    );
    cb && cb();
  };
};

const getTabTimeline = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('TAB_TIMELINE', payload));
  };
};

const resetTabTimeline = () => (dispatch) => {
  dispatch(updateStatus('RESET_TAB_TIMELINE'));
};

const checkIsShortMypage = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('CHECK_POPUP_PAGE_SHORT', payload));
  };
};

export const getPageShort = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('SET_PAGE_SHORT', payload));
  };
};

export {
  getListPostCreator,
  postCommentDetail,
  getCommentDetail,
  postLikeLive,
  likeCommentDetail,
  postLikeDetail,
  likePost,
  creatorGetPostDetail,
  resetDetailPost,
  getListPostTimeLine,
  resetActionUserNew,
  postLike,
  postBookMark,
  postBookMarkDetail,
  bookmarkPost,
  creatorDeleteArticle,
  fanFollowCreator,
  deleteCommentDetail,
  listHighlightTimeLine,
  resetCommentDetail,
  postFollowCommentDetail,
  deleteReplyCommentDetail,
  likeReplyCommentDetail,
  postFollowReplyCommentDetail,
  postRepCommentDetail,
  getReplyCommentDetail,
  collapseReplyCommentDetail,
  editReplyCommentDetail,
  list3ImgMypage,
  editCommentDetail,
  postFollowUser,
  postNormalBuyPackage,
  resetPopupPost,
  resetPopupPostShortVideo,
  updateDetailRecordingV2,
  creatorDeleteRecore,
  postRetweet,
  editCommentDetailNew,
  updateLikeCommentDetail,
  updateLikeReplyComment,
  updatePostLikeDetail,
  searchSaleContentAction,
  searchPostAction,
  resetPostSearch,
  hiddenPostMypage,
  postNormalPaymentBuyPackageSuccess,
  ghimPostMypage,
  getMetaCommentOPG,
  postFollowCommentDetailNoApi,
  getUserInfoOthers,
  postFollowUserNoApi,
  followOrUnFollowUserNoApi,
  getIDTimeline,
  resetIDTimeline,
  getIDHomePage,
  resetIDHomePage,
  resetDataInfoOther,
  getIDSearchPost,
  resetIDSearchPost,
  getIDSearchSale,
  resetIDSearchSale,
  getIDSearchVideo,
  resetIDSearchVideo,
  getListBookmark,
  getIDFavor,
  resetDataFavor,
  getIDCMTTimeline,
  resetgetIDCMTTimeline,
  getIDCMTHomepage,
  resetgetIDCMTHomepage,
  getIDCMTBookmark,
  resetgetIDCMTBookmark,
  setIdScrollBackRecommend,
  getTabTimeline,
  resetTabTimeline,
  checkIsShortMypage,
  getIDKYC,
  putEditComment,
  postRepListCommentDetail,
  updatePostMyPageWhenUpdate,
  updateHiddenLastComment,
  updateBlockInfoOther,
};
