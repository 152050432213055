import Icon from '@ant-design/icons';

const LogoutIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M15.016 7.3895V6.4565C15.016 4.4215 13.366 2.7715 11.331 2.7715H6.456C4.422 2.7715 2.772 4.4215 2.772 6.4565V17.5865C2.772 19.6215 4.422 21.2715 6.456 21.2715H11.341C13.37 21.2715 15.016 19.6265 15.016 17.5975V16.6545'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M21.8095 12.0214H9.7685'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18.8812 9.1063L21.8092 12.0213L18.8812 14.9373'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default LogoutIcon;
