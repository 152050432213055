/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from 'store/actions';

const initialState = {
  status: null,
  loading: false,
  error: null,
  pagination: {},
  listBanner: [],
  detail_banner: {},
};

//C141
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_LIST_BANNER_AFFILIATOR_REQUEST:
    case TYPES.GET_LIST_BANNER_DETAIL_AFFILIATOR_REQUEST:
    case TYPES.GET_LIST_REVENUE_SALES_HISTORY_REQUEST:
    case TYPES.GET_LIST_REVENUE_SUMARY_REQUEST:
    case TYPES.GET_LIST_REVENUE_SALES_HISTORY_CSV_REQUEST:
    case TYPES.GET_LIST_REVENUE_PAYMENT_HISTORY_REQUEST:
    case TYPES.GET_LIST_REVENUE_PAYMENT_HISTORY_MONTH_REQUEST:
    case TYPES.GET_LIMIT_PAYMENT_AFFILICATE_REQUEST:
      return {
        ...state,
        loading: true,
        status: action.type,
      };
    case TYPES.GET_LIST_BANNER_AFFILIATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        pagination: action?.data.data?.pagination,
        listBanner: action?.data.data.data,
      };
    case TYPES.GET_LIST_BANNER_DETAIL_AFFILIATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        detail_banner: action?.data.data.data,
      };
    case TYPES.GET_LIST_REVENUE_SUMARY_SUCCESS:
    case TYPES.GET_LIST_REVENUE_SALES_HISTORY_CSV_SUCCESS:
    case TYPES.GET_LIST_REVENUE_SALES_HISTORY_SUCCESS:
    case TYPES.GET_LIST_REVENUE_PAYMENT_HISTORY_SUCCESS:
    case TYPES.GET_LIST_REVENUE_PAYMENT_HISTORY_MONTH_SUCCESS:
    case TYPES.GET_LIMIT_PAYMENT_AFFILICATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case TYPES.GET_LIST_BANNER_AFFILIATOR_FAILED:
    case TYPES.GET_LIST_BANNER_DETAIL_AFFILIATOR_FAILED:
    case TYPES.GET_LIST_REVENUE_SALES_HISTORY_FAILED:
    case TYPES.GET_LIST_REVENUE_SUMARY_FAILED:
    case TYPES.GET_LIST_REVENUE_SALES_HISTORY_CSV_FAILED:
    case TYPES.GET_LIST_REVENUE_PAYMENT_HISTORY_FAILED:
    case TYPES.GET_LIST_REVENUE_PAYMENT_HISTORY_MONTH_FAILED:
    case TYPES.GET_LIMIT_PAYMENT_AFFILICATE_FAILED:
      return {
        ...state,
        loading: false,
        status: action.type,
      };

    default:
      return state;
  }
};
