/* eslint-disable import/no-anonymous-default-export */

import _ from 'lodash';
import { TYPES } from 'store/actions';

const initialState = {
  status: null,
  loading: false,
  error: null,
  pagination: {},
  listFeeds: [],
  listCategoryFeedsBack: [],
  dataFeedBack: {},
  listCategoryReport: [],
  dataReport: {},
  isLoadingFeedback: false,
  idScrollBackHelp: null,
};

//C141
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_LIST_FEEDBACK_REQUEST:
      return {
        ...state,
        isLoadingFeedback: true,
      };
    case TYPES.POST_FEEDBACK_REQUEST:
    case TYPES.GET_LIST_FFEDBACK_CATEGORY_REQUEST:
    case TYPES.GET_REPORT_CATEGORIES_REQUEST:
    case TYPES.POST_USER_REPORT_REQUEST:
      return {
        ...state,
        status: action.type,
        loading: true,
      };

    case TYPES.GET_LIST_FEEDBACK_SUCCESS:
      const _listFeeds = _.chain(action?.data?.data?.data)
        .groupBy('name')
        .map((value, key) => ({ name: key, question: value }))
        .value();

      return {
        ...state,
        loading: false,
        isLoadingFeedback: false,
        listFeeds: _listFeeds,
        idScrollBackHelp: action.data?.lan,
      };
    case TYPES.GET_LIST_FFEDBACK_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        listCategoryFeedsBack: action?.data?.data,
      };
    case TYPES.POST_FEEDBACK_SUCCESS:
      return {
        ...state,
        status: true,
        loading: false,
        dataFeedBack: action?.data?.data,
      };
    case TYPES.GET_REPORT_CATEGORIES_SUCCESS:
      return {
        ...state,
        status: true,
        loading: false,
        listCategoryReport: action?.data?.data,
      };
    case TYPES.POST_USER_REPORT_SUCCESS:
      return {
        ...state,
        status: true,
        loading: false,
      };

    case TYPES.GET_LIST_FEEDBACK_FAILED:
      return {
        ...state,
        loading: false,
        isLoadingFeedback: false,
      };
    case TYPES.POST_FEEDBACK_FAILED:
    case TYPES.GET_LIST_FFEDBACK_CATEGORY_FAILED:
    case TYPES.GET_REPORT_CATEGORIES_FAILED:
    case TYPES.POST_USER_REPORT_FAILED:
      return {
        ...state,
        status: false,
        loading: false,
      };
    case 'RESET_ACTION_FEEDBACK':
      return {
        ...state,
        pagination: {},
        listFeeds: [],
        listCategoryFeedsBack: [],
        dataFeedBack: {},
        listCategoryReport: [],
        dataReport: {},
        statusReport: null,
        idScrollBackHelp: null,
      };
    case 'SET_ID_SCROLL_BACK_HELP':
      return {
        ...state,
        idScrollBackHelp: action.data?.data?.id || null,
      };
    case 'RESET_STATUS_FEEDBACK':
      return {
        ...state,
        status: false,
      };
    default:
      return state;
  }
};
