import { configParamsGet, ENV } from 'utils/constant';
import { callApi } from 'utils/request';
import { queryString } from '../../utils';
const API = {
  getListCocoRecommend: () => `${ENV}/recommendations?type=users`,
  getListPostContentRecommend: () => `${ENV}/recommendations?type=post`,
  getListTopDonate: (params) => `${ENV}/livestreams/top-donate?${queryString(params)}`,
};

const getListCocoRecommend = (handleSuccess, handleError) => {
  return () => {
    callApi(
      API.getListCocoRecommend(),
      configParamsGet,
      null,
      async (response) => {
        handleSuccess && handleSuccess(response.data);
      },
      (error) => {
        handleError && handleError(error.errors);
      }
    );
  };
};

const getListPostContentRecommend = (handleSuccess, handleError) => {
  return () => {
    callApi(
      API.getListPostContentRecommend(),
      configParamsGet,
      null,
      async (response) => {
        handleSuccess && handleSuccess(response.data);
      },
      (error) => {
        handleError && handleError(error.errors);
      }
    );
  };
};

const getListTopDonate = (params, handleSuccess, handleError) => {
  return () => {
    callApi(
      API.getListTopDonate(params),
      configParamsGet,
      null,
      async (response) => {
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleError && handleError(error.errors);
      }
    );
  };
};

export { getListCocoRecommend, getListPostContentRecommend, getListTopDonate };
