import { useEffect, useRef, useState } from 'react';
import { SECOND_TOTAL_TO_LOAD_IMAGE_SHORT_VIDEO } from 'utils';
import { ImgCustom } from 'components';
import { StyledTimeline } from './styled';
import { useMemo } from 'react';
import { LayerProgress } from '../style';
export const Card = ({ data, isPlay, toggleValueVideo, isIntersecting, isMiniUrl }) => {
  const [run, setRun] = useState(isPlay || false);
  const [index, setIndex] = useState(0);
  const [percent, setPercent] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const refCard = useRef();
  const intervalRef = useRef(null);
  const timeToLoadOneImage = useMemo(() => {
    if (data && data?.length > 0) {
      return (SECOND_TOTAL_TO_LOAD_IMAGE_SHORT_VIDEO / data?.length) * 1000;
    }
    return SECOND_TOTAL_TO_LOAD_IMAGE_SHORT_VIDEO * 1000;
  }, [data]);
  useEffect(() => {
    if (run) {
      const totalIncrements = timeToLoadOneImage / 10;
      const increment = 100 / totalIncrements;
      intervalRef.current = setInterval(() => {
        if (!isPaused && percent >= 100) {
          if (index >= data?.length - 1) {
            setIndex(0);
          } else {
            setIndex((prev) => (prev = prev + 1));
          }
          setPercent(0);
        } else {
          if (!isPaused) {
            setPercent((prevPercent) => prevPercent + increment);
          }
        }
      }, timeToLoadOneImage / totalIncrements);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [run, timeToLoadOneImage, index, percent, data]);

  useEffect(() => {
    setRun(isPlay);
  }, [isPlay]);

  useEffect(() => {
    if (!isIntersecting) {
      setPercent(0);
      setIndex(0);
    }
  }, [isIntersecting]);

  return (
    <StyledTimeline.Card ref={refCard}>
      <StyledTimeline.Global className='slideshow'>
        <div className='slideshowSlider'>
          <LayerProgress>
            <StyledTimeline.LoadingProcess time={timeToLoadOneImage}>
              {data.map((dt, i) => (
                <StyledTimeline.ProcessBar key={i}>
                  <div className='back-ground-process'>
                    <div
                      className='process-item-run'
                      style={{
                        height: '4px',
                        backgroundColor: 'white',
                        width: `${i === index ? percent : index > i ? 100 : 0}%`,
                      }}></div>
                  </div>
                </StyledTimeline.ProcessBar>
              ))}
            </StyledTimeline.LoadingProcess>
          </LayerProgress>
          {data.map((dt, i) => (
            <>
              <div
                className={`slide ${i === index ? 'show' : 'hide'}`}
                key={i}
                onClick={() => {
                  toggleValueVideo()
                }}>
                <ImgCustom screen={isMiniUrl ? 'mini_32_auto_pc' :'timeline_auto_375_pc'} avatarErr={false} src={dt?.url} />
              </div>
            </>
          ))}
        </div>
      </StyledTimeline.Global>
    </StyledTimeline.Card>
  );
};
