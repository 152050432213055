import Icon from '@ant-design/icons';

const SendChatIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={24}
        height={24}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M20.9481 10.692L5.11231 1.82403C4.84586 1.67483 4.54022 1.61051 4.23623 1.63966C3.93225 1.66882 3.64441 1.79005 3.41117 1.98717C3.17793 2.18429 3.01041 2.4479 2.931 2.74278C2.85159 3.03765 2.86407 3.34973 2.96677 3.63732L5.68588 11.2508H12.7502C12.9491 11.2508 13.1399 11.3299 13.2805 11.4705C13.4212 11.6112 13.5002 11.8019 13.5002 12.0008C13.5002 12.1998 13.4212 12.3905 13.2805 12.5312C13.1399 12.6718 12.9491 12.7508 12.7502 12.7508H5.68588L2.96677 20.3644C2.88638 20.5907 2.86137 20.833 2.89384 21.0709C2.9263 21.3089 3.01529 21.5356 3.15336 21.7321C3.29143 21.9287 3.47456 22.0892 3.68742 22.2005C3.90029 22.3117 4.13669 22.3703 4.37686 22.3714C4.63459 22.3709 4.88787 22.3042 5.1124 22.1777L20.948 13.3097C21.1806 13.1794 21.3744 12.9894 21.5092 12.7594C21.644 12.5294 21.7151 12.2675 21.7151 12.0009C21.7151 11.7342 21.6441 11.4724 21.5092 11.2423C21.3744 11.0123 21.1807 10.8223 20.9481 10.692Z'
          fill='currentColor'
        />
      </svg>
    )}
    {...props}
  />
);

export default SendChatIcon;
