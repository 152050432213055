import React from 'react';

import { IconFieldChanged } from 'images';
import { FieldChangedStyle } from './styles';

const FieldChanged = ({ height, width, borderRadius, bottom, top }) => {
  return (
    <FieldChangedStyle
      height={height}
      width={width}
      borderRadius={borderRadius}
      bottom={bottom}
      top={top}>
      <IconFieldChanged />
    </FieldChangedStyle>
  );
};

export default FieldChanged;
