import { PlusOutlined } from '@ant-design/icons';
import { PopupConfirmFan } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import NoCardsIcon from 'images/NoCardsIcon';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { getCards } from 'store/actions/card';
import { CardItem } from './cardItem';
import './index.scss';

const PopupCardManagementPage = ({
  setIsShowPopupListCard,
  setIsShowPopupCreateAndUpdateCard,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const LIMIT_PAGE = 20;

  const [isShowPopupCanNotUpdate, setShowPopupCanNotUpdate] = useState({
    isShow: false,
    message: '',
    typeCard: null,
  });
  const [cards, setCards] = useState([]);

  const isHaveDataCard = useMemo(() => {
    return !!cards?.length;
  }, [cards]);

  const fetchNext = () => {
    setIsLoading(true);
    setPage(page + 1);
  };

  const getCardsSuccess = (rs) => {
    if (rs?.data) {
      setCards(cards?.concat(rs?.data));
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getCards(
        {
          page,
          limit: LIMIT_PAGE,
        },
        getCardsSuccess
      )
    );
  }, [dispatch, page]);

  const renderButtonAdd = () => {
    return (
      <div className='button-add-card'>
        <button
          onClick={() => {
            setIsShowPopupListCard(false);
            setIsShowPopupCreateAndUpdateCard(true);
          }}
          className='btn btn-outline-danger  d-flex align-items-center'>
          <PlusOutlined />
          <span className='ms-2'>{t('Creator.cardManagement.createNew')}</span>
        </button>
      </div>
    );
  };

  const renderNoData = () => {
    return (
      !isLoading && (
        <div className='no-data'>
          <div>
            <NoCardsIcon />
          </div>
          <div className='text'>{t('Creator.cardManagement.noData')}</div>
          {renderButtonAdd()}
        </div>
      )
    );
  };

  return (
    <>
      <div className='popup-card-management-layout'>
        {isShowPopupCanNotUpdate?.isShow && (
          <PopupConfirmFan
            colorSvg='#FF9F43'
            iconNote
            text={t(`${isShowPopupCanNotUpdate?.message}`, {
              typeCard: isShowPopupCanNotUpdate?.typeCard,
            })}>
            <div
              className='ok btn'
              onClick={() => {
                setShowPopupCanNotUpdate({
                  isShow: false,
                  message: '',
                  typeCard: null,
                });
              }}>
              {t('Common.Ok')}
            </div>
          </PopupConfirmFan>
        )}
        <div className='card-management-container'>
          {isLoading && !isHaveDataCard ? (
            <div className='shimmer-loading'>
              <ShimmerPostDetails hasImage imageType='circular' title />
            </div>
          ) : (
            <>
              {isHaveDataCard ? (
                <>
                  <div className='head-title-button-add'>
                    <div className='head-title-list'>{t('Plan.listCard')}</div>
                    {renderButtonAdd()}
                  </div>
                  <InfiniteLoad loading={isLoading} data={cards || []} fetchNext={fetchNext}>
                    <div className='list-card'>
                      {cards?.map((item) => (
                        <CardItem key={item?.id} item={item} />
                      ))}
                    </div>
                  </InfiniteLoad>
                </>
              ) : (
                renderNoData()
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PopupCardManagementPage;
