import { css } from 'styled-components';

const fontWeight = {
  1: '400',
  2: '500',
  3: '600',
  4: '700',
  5: '900',
  bold: 'bold',
};
const fontSize = {
  1: '12px',
  2: '14px',
  3: '16px',
  4: '18px',
};
const color = {
  1: '#FFFFFF',
  2: '#282727',
  3: '#EEEEEE',
  4: '#B1B6BB',
  5: '#28C76F',
  6: '#F2F2F2',
  7: '#1A1A1A',
  8: '#DC143C',
  9: '#1480FF',
  10: '#666565',
};
const border = {
  1: `1px solid ${color[4]}`,
  none: 'none'
};

const borderC = {
  1: `1px solid ${color[8]}`,
  none: 'none'
};
const padding = {
  1: '6px 8.5px',
  2: '5px'
};

const FlexCenter = css`
  ${({ dfc }) =>
    dfc &&
    ` display: flex;
      align-items: center;
      justify-content: space-evenly;
    `}
`;
const OverflowText = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const PropsText = css`
  ${({ fw }) => fw && `font-weight: ${fontWeight[fw]};`}
  ${({ fs }) => fs && `font-size: ${fontSize[fs]};`}
  ${({ cl }) => cl && `color: ${color[cl]};`}
  ${({ bdr }) => bdr && `border-right: ${border[bdr]};`}
  ${({ pdr }) => pdr && `padding-right: ${pdr};`}
  ${({ pd }) => pd && `padding: ${pd};`}
  ${({ trl }) => trl && `translate: ${trl};`}
  ${({ maw }) => maw && `max-width: ${maw};`}
  ${({ ovftext }) => ovftext && OverflowText}
  ${({ w }) => w && `width: ${w};`}

`;
const PropsDiv = css`
  display: ${({ display }) => display || 'block'};
  ${({ algc }) => algc && `align-items: center;`}
  ${({ gap }) => gap && `gap: ${gap};`}
  ${({ mg }) => mg && `margin: ${mg};`}
  ${({ h }) => h && `height: ${h};`}
  ${({ mh }) => mh && `min-height: ${mh};`}
  ${({ w }) => w && `width: ${w};`}
  ${({ mw }) => mw && `min-width: ${mw};`}
  ${({ pd }) => pd && `padding: ${pd};`}
  ${({ bgc }) => bgc && `background-color: ${color[bgc]};`}
  ${({ ovftext }) => ovftext && OverflowText}
  @media screen and (max-width: 575px) {
      ${({dl575}) => `display: ${dl575}`}
    }
`;
const PropsButton = css`
  height: ${({ h = '32px' }) => h};
  outline: none;
  ${({ pd }) => pd && `padding: ${padding[pd]};`}
  ${({ bgc }) => bgc && `background-color: ${color[bgc]};`}
  ${({ bdr }) => bdr && `border-radius: ${bdr};`}
  ${({ fw }) => fw && `font-weight: ${fontWeight[fw]};`}
  ${({ fs }) => fs && `font-size: ${fontSize[fs]};`}
  ${({ cl }) => cl && `color: ${color[cl]};`}
  ${({ w }) => w && `width: ${w};`}
  ${({ mw }) => mw && `min-width: ${mw};`}
  ${({ maw }) => maw && `max-width: ${maw};`}
  ${({ bd }) => bd && `border: ${border[bd]};`}
  ${({ bdc }) => bdc && `border: ${borderC[bdc]};`}
  ${({ mg }) => mg && `margin: ${mg};`}
  ${({ mh }) => mh && `min-height: ${mh};`}
  ${({ ovftext }) => ovftext && OverflowText}
  @media screen and (max-width: 1023px) {
    ${({ mw }) => mw && `width: ${mw};`}
    ${({ mmg }) => mmg && `margin: ${mmg};`}
  }
`;

export const propsStyled = {
  fontWeight,
  fontSize,
  color,
  PropsText,
  PropsDiv,
  PropsButton,
  FlexCenter,
};
