import React from 'react';
import { TableCustom } from 'components';
import { convertTextMonthEN, formatCoin } from 'utils';
import { useTranslation } from 'react-i18next';

const Table = ({ columns }) => {
  const { t, i18n } = useTranslation();
  return (
    <TableCustom className='general'>
      <thead>
        <tr>
          <th></th>
          {columns.map((item, index) => (
            <th key={index}>
              {i18n.language === 'jp' ? (
                <>{+item.month + `${t('Common.month')}`}</>
              ) : (
                convertTextMonthEN(+item.month)
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>{t('Report.number_of_followers')}</th>
          {columns.map((item) => (
            <td key={item.month}>{formatCoin(item.number_follow)}</td>
          ))}
        </tr>
        <tr>
          <th>{t('Report.number_content')}</th>
          {columns.map((item) => (
            <td key={item.month}>{formatCoin(item.number_content)}</td>
          ))}
        </tr>
        <tr>
          <th>{t('Report.number_like')}</th>
          {columns.map((item) => (
            <td key={item.month}>{formatCoin(item.number_like)}</td>
          ))}
        </tr>
        <tr>
          <th>{t('Report.number_bookmark')}</th>
          {columns.map((item) => (
            <td key={item.month}>{formatCoin(item.number_bookmark)}</td>
          ))}
        </tr>
      </tbody>
    </TableCustom>
  );
};

export default Table;
