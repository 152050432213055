import styled from 'styled-components';

const CheckboxWrapper = styled.label`
  width: max-content;
  padding-left: 25px !important;
  display: inline-block;
  align-items: flex-start !important;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  &.disable {
    cursor: not-allowed;
  }
  &.column + &.column {
    margin-top: 8px;
  }

  &.horizontal + &.horizontal {
    margin-left: 25px;
  }

  .text {
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #1a1a1a;
    &.disable {
      color: #b1b6bb;
    }
  }
  .required {
    color: #dc143c;
  }
  input {
    opacity: 0;
    position: absolute;
    width: 16.67px !important;
    height: 16.67px !important;
    left: 0;
    margin: 0;
    top: 3px;
    cursor: pointer;
  }
  .checkmark {
    display: inline-block;
    margin-top: 3px;
    width: 16.67px;
    height: 16.67px;
    background: $color-01;
    position: absolute;
    left: 0;
    top: 0;
    border: 1.5px solid #e5e6eb;
    padding: 0;
    border-radius: 3px;
    box-sizing: border-box;
    &.radio {
      border-radius: 50%;
    }
  }
  input:checked + .checkmark {
    background-color: #dc143c !important;
    border: none !important;
  }

  input:checked + .checkmark:after {
    content: '';
    position: absolute;
    border-left: 2.5px solid $color-01;
    border-bottom: 2.5px solid $color-01;
    border-radius: 0.5px;
    height: 5px;
    width: 9px;
    top: 43%;
    left: 51.5%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  input[disabled] + .checkmark {
    border: 1px solid #b1b6bb;
    cursor: not-allowed;
  }

  input[disabled]:checked + .checkmark {
    background-color: #dc143c !important;
  }
`;

export { CheckboxWrapper };
