import { ImgCustom, PopupGeneral } from 'components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  acceptedImageTypes,
  failImgInvalid,
  failImgThan10Mb,
  getPresignedUrlS3,
  linkS3,
  setFileNameS3,
  TEN_MB,
  uploadS3ByPresigned,
  UPLOAD_CAMERA_RED,
} from 'utils';
import { CloseIcon } from '../../images';
import { creatorEditTimeLineArticle, creatorGetPostDetail } from '../../store/actions/myPage';
import { InputWrapper } from '../Input/InputWrapper';

const PopupChoiceImgHighlightC156 = ({
  classNameCustom,
  old_thumbnail,
  title,
  detailPost,
  loading,
}) => {
  const [isVisiblePopUp, setIsVisiblePopUp] = useState(false);

  const { t } = useTranslation();
  const [thumbnailUpload, setThumbnailUpload] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isVisiblePopUp) {
      const checkVideo = document.querySelector('.video-react-video');
      checkVideo && checkVideo.pause();
      old_thumbnail && setThumbnailUpload(old_thumbnail.url);
    }
  }, [isVisiblePopUp]);

  const handleInputImg = (e) => {
    let sizeImgBiggerTenMb = false;
    let checkImg = true;
    if (e.target.files[0].size > TEN_MB) sizeImgBiggerTenMb = true;
    if (!acceptedImageTypes.includes(e.target.files[0]['type'])) checkImg = false;
    if (e.target.files[0] && checkImg && !sizeImgBiggerTenMb) {
      setThumbnailUpload({
        files: URL.createObjectURL(e.target.files[0]),
        img: e.target.files[0],
      });
    } else {
      sizeImgBiggerTenMb && failImgThan10Mb();
      !checkImg && failImgInvalid();
    }
  };

  const handleRemoveImg = () => {
    setThumbnailUpload();
  };

  const inputImage = () => {
    return (
      <div className={`inputFile`}>
        <div className='btn-upload'>
          <ImgCustom src={UPLOAD_CAMERA_RED} />
          <span>{t('Creator.PostArticle.imageUpload')}</span>
        </div>
        <input
          type='file'
          accept={acceptedImageTypes.map((item) => item).join(',')}
          onChange={handleInputImg}
          onClick={(e) => (e.target.value = null)}
        />
      </div>
    );
  };
  const isDisabledButton = old_thumbnail
    ? old_thumbnail && !thumbnailUpload
    : !thumbnailUpload;
  return (
    <>
      <InputWrapper id='fsite-submit' className={`button-submit highlight`}>
        <button
          onClick={() => {
            setIsVisiblePopUp(true);
          }}>
          {t('Creator.PostArticle.postShort.buttonHighlight')}
        </button>
      </InputWrapper>
      {isVisiblePopUp && (
        <PopupGeneral
          title={title}
          className={`popup-choice-img-highlight ${classNameCustom}`}
          closeModal={() => {
            setIsVisiblePopUp(false);
            setThumbnailUpload();
          }}>
          {!thumbnailUpload ? (
            <div className='content'>
              {inputImage()}
              <div className='note'>{t('Popup.choiceImgHighlightC156.note')}</div>
            </div>
          ) : (
            <div className={`img-highlight`}>
              <>
                <ImgCustom
                  src={
                    typeof thumbnailUpload === 'string'
                      ? `${thumbnailUpload}`
                      : thumbnailUpload?.files
                  }
                />
                <CloseIcon onClick={() => handleRemoveImg()} className='close-img' />{' '}
              </>
            </div>
          )}
          <button
            className={`btn button-submit ${isDisabledButton && 'disabled'}`}
            disabled={isDisabledButton}
            onClick={async () => {
              loading(true);
              let thumbnail_pre_signed;
              let thumbnail;
              getPresignedUrlS3(
                'image',
                `uploads/output/images`,
                setFileNameS3(thumbnailUpload.img.name)
              )
                .then((resUrl) => {
                  thumbnail_pre_signed = resUrl.data.pre_signed;
                  thumbnail = resUrl.data.file_path;
                })
                .then(() => {
                  uploadS3ByPresigned(thumbnail_pre_signed, thumbnailUpload.img)
                    .then(() => {
                      dispatch(
                        creatorEditTimeLineArticle(
                          detailPost?.id,
                          {
                            type: detailPost?.type,
                            thumbnail,
                          },
                          (e) => {
                            console.log(e);
                            setIsVisiblePopUp(false);
                            loading(false);
                            dispatch(creatorGetPostDetail(detailPost?.id, true));
                          },
                          (e) => {
                            console.log(e);
                            setIsVisiblePopUp(false);
                            loading(false);
                          }
                        )
                      );
                    })
                    .catch(() => loading(false));
                })
                .catch((_error) => loading(false));
            }}>
            {t('Common.Ok')}
          </button>
        </PopupGeneral>
      )}
    </>
  );
};

export default PopupChoiceImgHighlightC156;
