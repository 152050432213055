import useLockBodyScroll from 'hooks/useLockBodyScroll';
import useUserRole from 'hooks/useUserRole';
import HeaderListIdolV2  from 'components/HeaderV2/HeaderListIdolV2';
import SuccessRegisterIcon from 'images/SuccessRegisterIcon';
import { useTranslation } from 'react-i18next';

const PopupRegisterTWSuccessFan = ({ children }) => {
  const { t } = useTranslation();
  useLockBodyScroll();
  const { isIdol } = useUserRole();
  return (
    <>
      <HeaderListIdolV2/>
      <div className='popup register-success-fan' style={{ backgroundColor: "transparent"}}>
        <div className='popup-content'>
          <div className='content'>
            <SuccessRegisterIcon />
            <div className='text success-register-text'>
              {isIdol === false
                ? `${t('Popup.verificationEmail')}`
                : `${t('Popup.successfullyCreateAccount')}`}
            </div>
            <div className={`box-button ${isIdol === true ? 'w-100' : ''}`}>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupRegisterTWSuccessFan;
