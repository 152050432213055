import React, { useEffect, useMemo, useRef, useState /* useRef */ } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { getEnv } from 'configs/env';

import { useDispatch, useSelector } from 'react-redux';
import { fanBuyPackages, updateChangeStatusEndLive } from 'store/actions/lives';

import { isMobile } from 'react-device-detect';

import { onValue, ref } from 'firebase/database';
import { DBRealTime } from '../../../../firebase/config';

import { InputChat } from 'components/LiveStream/Chat/InputChat';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { ImgCustom, PopupConfirmFan } from 'components';

import { Loading } from 'components/LiveStream/Loading';

import './VideoPlayer.scss';

import Camera from 'images/Camera.png';
import CameraDisabled from 'images/CameraDisabled.png';
import MicroPhone from 'images/MicroPhone.png';
import MicroPhoneDisabled from 'images/MicroPhoneDisabled.png';
// import ChangeCamera from 'images/ChangeCamera.png';
import Pause from 'images/Pause.png';
import Play from 'images/Play.png';
import Logout from 'images/Logout.png';

import MinimumScreen from 'images/MinimumScreen.png';
import Copy from 'images/Copy.png';
// import { withOrientationChange } from 'react-device-detect'

import { PopupDenyFanView } from 'components/Popup/fanVewLives';

import { formatCoin } from 'utils/utils';
import { realTimeLives } from 'hooks/useRealTimeDB';

import platform from 'platform';
import useDevice from 'hooks/useDevice';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import { newPathUser } from 'constants/layout/constant';
import { LockIcon } from 'images';

const appID = getEnv('REACT_APP_AGORA_APP_ID');
// create Agora client

const client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });

export const VideoPlayerTest = (props) => {
  // const client = useMemo(
  //   () => AgoraRTC.createClient({ mode: 'live', codec: 'vp8' }),
  // []);
  const {
    liveKey,
    type,
    host,
    view,
    showButton,
    allowView,
    messageAllow,
    checkHorizontalScreen,
    // isMobileScreenHorizontal,
    allowComment,
    creatorIsFollow,
    remainPoint, //* SO POINTS DANG CO CUA FANS
  } = props;

  let { videoConfig } = props;

  if (videoConfig === undefined) {
    videoConfig = {
      width: 640,
      height: 480,
    };
  }

  const localTracks = {
    videoTrack: null,
    audioTrack: null,
  };

  // let remoteUsers = {};

  // Agora client options
  const options = {
    appid: appID,
    channel: liveKey,
    uid: null,
    token: null,
    role: host ? 'host' : 'audience', // host or audience
    audienceLatency: 1,
  };

  //const [userTracks, setUserTracks] = useState({});

  const [local, setLocal] = useState({
    videoTrack: null,
    audioTrack: null,
  });

  const stateCtx = useSelector((state) => state.lives);

  const [uid, setUID] = useState(0);
  const [remoteUsers, setRemoteUsers] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [muteVideo, setMuteVideo] = useState(false);
  const [muteAudio, setMuteAudio] = useState(false);
  const [activePlay, setActivePlay] = useState(false);
  const [popupConfirmFan, setPopupConfirmFan] = useState(false);

  const [autoPlayAudioFail, setAutoPlayAudioFail] = useState(null);

  const dispatch = useDispatch();
  const loadingChangeStatus = useSelector((state) => state.lives.loadingChangeStatus);

  //* Check fans call api buy packages success
  const isFanBuySuccess = useSelector((state) => state.lives.buyPackageSuccess);
  const isCallAPIBuyPackage = useSelector((state) => state.lives.loading);

  //const [checkJoinRoom, setCheckJoinRoom] = useState(false);

  const [fanUser, setFanUser] = useState({});
  const { t } = useTranslation();

  // Create the query list.
  const mediaQueryList = window.matchMedia('(orientation: portrait)');
  // const [isOrientation, setIsOrientation] = useState(null);
  // let configVideo = {
  //   width  : mediaQueryList.matches ? 375 : 667,
  //   height : mediaQueryList.matches ? 667 : 375
  // };

  useEffect(() => {
    join();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  AgoraRTC.onAutoplayFailed = () => {
    if (autoPlayAudioFail) return;
    return setAutoPlayAudioFail(true);
  };

  //* END HANDLE EVENT CREATOR ROTATE DEVICE
  useEffect(() => {
    if (!host) {
      const handleAutoPlayAudioFail = () => {
        if (!autoPlayAudioFail) return;
        setAutoPlayAudioFail(false);
      };
      document.body.addEventListener('click', handleAutoPlayAudioFail);
      return () => document.body.removeEventListener('click', handleAutoPlayAudioFail);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoPlayAudioFail]);

  const startLiveWithScreen = async () => {
    localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack();

    if (isMobile) {
      localTracks.videoTrack = await AgoraRTC.createCameraVideoTrack({
        cameraId: '',
        encoderConfig: {
          width: parseInt(videoConfig.width),
          height: parseInt(videoConfig.height),
        },
        optimizationMode:
          platform?.name === 'Chrome' || platform?.name === 'Chrome Mobile' ? 'motion' : '',
      });

      // await localTracks.videoTrack.setEncoderConfiguration(
      //   {
      //     width: 375,
      //     // Specify a value range and an ideal value
      //     height: 640,
      //     frameRate: { max: 15, min: 5 },
      //     bitrateMin: 600, bitrateMax: 1000,
      //   }
      // );
    } else {
      //alert('DV');
      localTracks.videoTrack = await AgoraRTC.createCameraVideoTrack({
        // optimizationMode: "motion",
        optimizationMode:
          platform?.name === 'Chrome' || platform?.name === 'Chrome Mobile' ? 'motion' : '',
        cameraId: '',
        encoderConfig: {
          width: 640,
          // Specify a value range and an ideal value
          height: 480,
          frameRate: 15,
          bitrateMin: 600,
          bitrateMax: 1200,
        },
      });
    }

    setLocal(localTracks);

    // play local video track
    await localTracks.videoTrack.play('local-player', { fit: 'contain' });

    // publish local tracks to channel
    await client.publish(Object.values(localTracks));
    console.log('publish success', localTracks);

    setIsLoading(false);
  };

  // const changeLayoutVideo = async () => {
  //   // console.log("DUC-changeLayoutVideo ", local.videoTrack);
  //   if (isMobile) {
  //     alert('T');
  //     local.videoTrack && await local.videoTrack.setEncoderConfiguration(
  //       {
  //            width: 375,
  //           // Specify a value range and an ideal value
  //           height: 667,
  //           frameRate: { max: 15, min: 5 },
  //           bitrateMin: 600, bitrateMax: 1000,
  //       },
  //     );
  //   }

  // }

  const updateUserViewLiveStream = () => {
    if (stateCtx.userInfo !== null) {
      const userInfo = {
        id: stateCtx.userInfo.auth_id,
        avatar: stateCtx.userInfo.avatar,
        account_name: stateCtx.userInfo.account_name,
        comment: allowComment,
        creator_follow: creatorIsFollow,
      };
      return realTimeLives(`/lives/${liveKey}/${userInfo.id}`, liveKey, userInfo);
    }
  };

  const join = async () => {
    setIsLoading(true);
    if (options.role === 'host') {
      await client.setClientRole('host');

      [options.uid, localTracks.audioTrack, localTracks.videoTrack] = await Promise.all([
        // join the channel
        await client.join(
          options.appid,
          options.channel,
          options.token || null,
          options.uid || null
        ),
        // create local tracks, using microphone and camera
        await AgoraRTC.createMicrophoneAudioTrack(),
        await AgoraRTC.createCameraVideoTrack({
          encoderConfig: {
            width: 667,
            height: 375,
          },
          // optimizationMode: (platform?.name === 'Chrome' || platform?.name === 'Chrome Mobile') ? 'motion' : '',
        }),
      ]);

      await localTracks.videoTrack.play('local-player', { fit: 'contain' });
      await client.publish(Object.values(localTracks));
      setLocal(localTracks);
    } else {
      await client.setClientRole('audience', { level: 1 });
      await client.join(
        options.appid,
        options.channel,
        options.token || null,
        options.uid || null
      );
      //  updateUserViewLiveStream();
    }

    client.on('user-published', handleUserPublished);
    client.on('user-unpublished', handleUserUnpublished);

    console.log('publish success');
    setIsLoading(false);
  };

  //* HANDLE EVENT CREATOR ROTATE DEVICE

  const handleOrientationChange = async (evt) => {
    // alert(mediaQueryList.matches);
    // return window.location.reload();
    // handleStopLive();
    // join();
    // console.log("DDDXXX: ", local);
    // alert(local.videoTrack);

    // const realWidth = Math.round((window.screen.width * window.devicePixelRatio) / 2);
    // const realHeight = Math.round((window.screen.height * window.devicePixelRatio) / 2);
    if (evt.matches) {
      if (local.videoTrack) {
        // alert('DP: ' + local.videoTrack + '-rw:' + realWidth + '-rh:' + realHeight);

        return await local.videoTrack.setEncoderConfiguration({
          width: 375,
          height: 667,
          frameRate: 24,
        });
      }
    } else {
      if (local.videoTrack) {
        // alert('Land: ' + local.videoTrack + '-rw:' + realWidth + '-rh:' + realHeight);
        return await local.videoTrack.setEncoderConfiguration({
          width: 667,
          height: 375,
          frameRate: 24,
        });
      }
    }
  };

  useEffect(() => {
    if (host && isMobile) {
      handleOrientationChange(mediaQueryList);
      mediaQueryList.addEventListener('change', handleOrientationChange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaQueryList]);

  // const join = async () => {

  //   setIsLoading(true);

  //   if (options.role === 'audience') {
  //     client.setClientRole(options.role, { level: 1 });
  //     // add event listener to play remote tracks when remote user publishs.
  //     client.on('user-published', handleUserPublished);
  //     client.on('user-unpublished', handleUserUnpublished);
  //     // client.on('connection-state-change', function(event){
  //     //   console.log("DUC-EVT: ", event);
  //     // })

  //     // TODOS: HANDLE UPDATE USER VIEW LIVESTREAM
  //     updateUserViewLiveStream();

  //   } else {
  //     client.setClientRole('host');
  //   }

  //   // join the channel
  //   await client.join(
  //       options.appid,
  //       options.channel,
  //       options.token || null,
  //       options.uid || null
  //   );

  //   if (host) {
  //     return startLiveWithScreen();
  //   }

  // };

  const subscribe = async (user, mediaType) => {
    const uid = user.uid;
    setUID(uid);
    // subscribe to a remote user
    await client.subscribe(user, mediaType);
    console.log('subscribe success');
    setFanUser(user);

    if (mediaType === 'video') {
      user.videoTrack.play(`player-${uid}`, { fit: 'contain' });
    }
    if (mediaType === 'audio') {
      if (allowView) {
        user.audioTrack.play();
      } else {
        user.audioTrack.stop();
      }
    }

    // setUserTracks(user);
  };

  const fanRef = useRef();
  const fanViewRef = useRef();

  const handleUserPublished = (user, mediaType) => {
    const id = user.uid;
    remoteUsers[id] = user;
    setRemoteUsers(remoteUsers);
    subscribe(user, mediaType);

    setIsLoading(false);
  };

  const handleUserUnpublished = (user, mediaType) => {
    console.log('DUC-UserUnpublished: ', isLoading);

    if (mediaType === 'video') {
      setIsLoading(true);
      const id = user.uid;
      console.log('DUC-XOA: ', remoteUsers, fanRef);
      setRemoteUsers(delete remoteUsers[id]);

      // $(`#player-wrapper-${id}`).remove();
    }
  };

  //TODO : 16-4-2022 ACTION

  const toggleVideo = async () => {
    const newValue = !muteVideo;
    setMuteVideo(newValue);
    if (newValue) {
      await local.videoTrack.setMuted(true);
    } else {
      await local.videoTrack.setMuted(false);
    }
  };

  const toggleAudio = async () => {
    const newValue = !muteAudio;
    setMuteAudio(newValue);
    if (newValue) {
      await local.audioTrack.setMuted(true);
    } else {
      await local.audioTrack.setMuted(false);
    }
    setMuteAudio(newValue);
  };

  const handlePlayOrPauseVideo = async () => {
    const newValue = !activePlay;
    setActivePlay(newValue);
    if (!newValue) {
      await local.videoTrack.setMuted(false);
      await local.audioTrack.setMuted(false);
      setMuteVideo(false);
      setMuteAudio(false);
    } else {
      await local.videoTrack.setMuted(true);
      await local.audioTrack.setMuted(true);
      setMuteVideo(true);
      setMuteAudio(true);
    }
  };

  const confirmStopLive = () => {
    setPopupConfirmFan(!popupConfirmFan);
  };

  const handleStopLive = async () => {
    for (const trackName in local) {
      const track = local[trackName];
      if (track) {
        track.stop();
        track.close();
        await client.unpublish(track);
        // setAutoPlayAudioFail(false);
        local[trackName] = undefined;
        localTracks[trackName] = undefined;
      }
    }
    // remove remote users and player views
    setRemoteUsers({}); // remoteUsers = {};
    await client.leave();
  };

  const handleRotateDevice = async () => {
    for (const trackName in local) {
      const track = local[trackName];
      if (track) {
        track.stop();
        track.close();
        // setAutoPlayAudioFail(false);
        local[trackName] = undefined;
        localTracks[trackName] = undefined;
      }
    }
    // remove remote users and player views
    setRemoteUsers({}); // remoteUsers = {};
    await client.leave();
  };

  // useEffect(() => {
  //   window.onpopstate = () => {
  //     handleStopLive();
  //   };
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  //TODO: 16-4-2022 END ACTION

  const isDevice = useDevice();

  //TODO: 15-4-2022 HANDLE FULLSCREEN
  const handle = useFullScreenHandle();
  //TODO: END HANDLE FULLSCREEN

  const [showPopupFanWhenLiveEnd, setShowPopupFanWhenLiveEnd] = useState(false);

  useMemo(() => {
    //! HANDLE EVENT LIVE END ALL HOST IN LIVE OUT SCREEN
    onValue(
      ref(DBRealTime, `/lives/${liveKey}/config`),
      (snapshot) => {
        if (snapshot.val() !== null) {
          const statusLive = snapshot.val().status;

          if (statusLive && statusLive === 5) {
            if (host) {
              return (window.location.href = `${newPathUser}live/list-livestream`);
            } else {
              setShowPopupFanWhenLiveEnd(true);
              setIsLoading(false);
              setTimeout(() => {
                return (window.location.href = '/lives/list/live');
              }, 5000);
            }
          }
        } //! END if (snapshot.val() !== null)
      },
      {
        onlyOnce: false,
      }
    );
    //! END HANDLE EVENT LIVE END ALL HOST IN LIVE
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isEnoughPoints, setIsEnoughPoints] = useState(null);

  //* CHECK FAN BUY PACKAGES RELOAD PAGE
  // useEffect(() => {
  //   if (!host) {
  //     console.log("isFanBuySuccess: ", isFanBuySuccess);

  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isFanBuySuccess])

  //* FEE CONVERT POINTS TO YEN

  const feePointToYen = 0.1; //* 10%
  const convertShowPrice = (price) => {
    const priceYen = parseInt(price) + parseInt(price) * feePointToYen;
    return (
      <>
        1{t('Creator.Home.month')}/
        <CurrencyFormat
          thousandSeparator={true}
          value={price}
          className=''
          displayType='text'
        />
        {t('Common.pt')}
        {/* (
          税込
          <CurrencyFormat
            thousandSeparator={true}
            value={priceYen}
            className=''
            displayType='text'
          />
          円
        ) */}
      </>
    );
  };

  return (
    <>
      {isLoading && !showPopupFanWhenLiveEnd && allowView !== null && allowView && (
        <div className='loading-video-live'>
          <Loading />
        </div>
      )}

      {host && (
        <>
          <FullScreen
            handle={handle}
            className={type === 'review' ? 'sc-creator-rv-screen' : ''}>
            <div
              id='local-player'
              className={` ${
                isMobile ? 'sc-creator-views-mobile' : ''
              } sc-creator-views current-view`}
              style={{ height: '100%', width: '100%' }}></div>
          </FullScreen>
        </>
      )}

      {host && view === 'creator' && popupConfirmFan && (
        <div>
          <PopupConfirmFan
            className='creator-confirm-close-live'
            colorSvg='#FF9F43'
            text={t('LiveStream.V5.messageEndLive')}>
            {!loadingChangeStatus ? (
              <>
                <div className='btn cancel' onClick={() => setPopupConfirmFan(false)}>
                  {t('Common.buttonCancel')}
                </div>
                <div
                  className={`btn ok`}
                  onClick={() => {
                    dispatch(updateChangeStatusEndLive(liveKey));
                  }}>
                  OK
                </div>
              </>
            ) : (
              <>
                <div className='btn cancel'> {t('Common.buttonCancel')} </div>
                <div className={`btn ok`}>
                  <div
                    className='spinner-border m-auto'
                    role='status'
                    style={{ width: '15px', height: '15px' }}></div>
                </div>
              </>
            )}
          </PopupConfirmFan>
        </div>
      )}

      {/* BUTTON ACTION SCREEN CREATOR REVIEW  */}
      {!isLoading && host && view === 'creator' && showButton === 'screen-review' && (
        <div className='box-button d-flex' style={{ zIndex: 100 }}>
          <span className='bg-white rounded-circle d-middle' onClick={toggleVideo}>
            <ImgCustom src={!muteVideo ? Camera : CameraDisabled} alt='' />
          </span>
          <span className='bg-white rounded-circle d-middle' onClick={toggleAudio}>
            <ImgCustom src={!muteAudio ? MicroPhone : MicroPhoneDisabled} alt='' />
          </span>
        </div>
      )}
      {/* END BUTTON ACTION SCREEN CREATOR REVIEW  */}

      {/* BUTTON ACTION SCREEN CREATOR LIVESTREAM   */}

      {!isLoading && host && view === 'creator' && showButton === 'screen-creator-live' && (
        <>
          <div
            className={`${
              isDevice
                ? checkHorizontalScreen !== 'landscape'
                  ? 'group-service-vertical'
                  : 'group-service-horizontal'
                : 'group-service-vertical'
            } group-service`}>
            <InputChat allowComment={true} denyCharacter={[]} />
            {
              <>
                <div
                  className={`box-button-lstream d-flex ${
                    isDevice
                      ? checkHorizontalScreen !== 'landscape'
                        ? 'box-button-vertical'
                        : 'box-button-horizontal'
                      : 'box-button-pc'
                  } `}
                  style={{ zIndex: 100 }}>
                  <span className='bg-white rounded-circle d-middle'>
                    <ImgCustom
                      src={Copy}
                      alt=''
                      onClick={(event) => {
                        navigator.clipboard.writeText(
                          `${getEnv('REACT_APP_FAN_URL')}/lives/${liveKey}`
                        );
                      }}
                    />
                  </span>
                  <span className='bg-white rounded-circle d-middle' onClick={toggleVideo}>
                    <ImgCustom src={muteVideo ? CameraDisabled : Camera} alt='' />
                  </span>
                  <span className='bg-white rounded-circle d-middle' onClick={toggleAudio}>
                    <ImgCustom src={muteAudio ? MicroPhoneDisabled : MicroPhone} alt='' />
                  </span>
                  <span
                    className='bg-white rounded-circle d-middle'
                    onClick={handlePlayOrPauseVideo}>
                    <ImgCustom src={activePlay ? Play : Pause} alt='' />
                  </span>
                  <span className='bg-white rounded-circle d-middle' onClick={handle.enter}>
                    <ImgCustom src={MinimumScreen} alt='' />
                  </span>
                  <span className='bg-white rounded-circle d-middle' onClick={confirmStopLive}>
                    <ImgCustom src={Logout} alt='' />
                  </span>
                </div>
              </>
            }
          </div>
        </>
      )}
      {/* END BUTTON ACTION SCREEN CREATOR LIVESTREAM   */}

      {!host && !allowView && (
        <>
          {isEnoughPoints !== null && !isEnoughPoints && (
            <PopupConfirmFan
              isVisiblePopup={() => setIsEnoughPoints(null)}
              colorSvg='#FF9F43'
              text={t('Popup.NotEnoughPoints')}>
              <div className='btn cancel' onClick={() => setIsEnoughPoints(null)}>
                {t('Common.buttonCancel')}
              </div>
              <div
                className='ok btn'
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  return (window.location.href = `${newPathUser}purchase`);
                }}>
                OK
              </div>
            </PopupConfirmFan>
          )}
          <PopupDenyFanView>
            <LockIcon
              className='live-icon-lock'
              style={{ height: 'auto', maxHeight: '32px', width: 'auto' }}
              alt=''
            />
            {messageAllow !== null && messageAllow.package !== null && (
              <span className='live-title-lock'> {messageAllow.package} </span>
            )}
            <span className='live-deslink-lock'>
              {messageAllow !== null && messageAllow.package !== null
                ? convertShowPrice(messageAllow.message)
                : messageAllow.message}{' '}
            </span>
            <button
              className='live-btn-back-lock'
              disabled={isCallAPIBuyPackage}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (messageAllow !== null && messageAllow.package !== null) {
                  const currentPoints = parseInt(remainPoint);

                  if (currentPoints >= parseInt(messageAllow.message)) {
                    //* SHOW CONFIRM HOI MUON MUA HAY KHONG
                    setIsEnoughPoints(null);
                    // CALL API FAN MUA PACKAGES CUA CREATOR
                    return dispatch(fanBuyPackages(parseInt(messageAllow.packageId), liveKey));
                  } else {
                    //* SHOW POPUP KO DU POINTS
                    setIsEnoughPoints(false);
                  }
                } else {
                  return (window.location.href = '/lives/list/live');
                }
              }}>
              {isCallAPIBuyPackage ? (
                <span
                  className='spinner-border m-auto'
                  style={{ width: '2rem', height: '2rem' }}
                  role='status'></span>
              ) : (
                `${t('LiveStream.V5.membership')}`
              )}
            </button>
          </PopupDenyFanView>
        </>
      )}

      {/* SHOW POPUP WHEN CREATOR END LIVE FOR FAN  */}
      {showPopupFanWhenLiveEnd && (
        <PopupConfirmFan
          isVisiblePopup={() => {}}
          colorSvg='#FF9F43'
          text={t('LiveStream.V5.livehasEnded')}>
          <div
            className='ok btn'
            onClick={(event) => {
              event.preventDefault();
              return (window.location.href = '/lives/list/live');
            }}>
            OK
          </div>
        </PopupConfirmFan>
      )}
      {/* END SHOW POPUP WHEN CREATE END LIVE FOR FAN */}

      {!host && !isLoading && (
        <>
          {allowView && (
            <div
              ref={fanViewRef}
              id={`player-${uid}`}
              style={{ height: '100%', width: '100%' }}
              className={`lsv-fan current-view`}>
              {autoPlayAudioFail && (
                <div
                  ref={fanRef}
                  style={{
                    position: 'absolute',
                    zIndex: '2',
                    top: '0%',
                    display: 'flex',
                    width: '70%',
                    justifyContent: 'left',
                  }}>
                  <button
                    className='btn btn-follow'
                    style={{ backgroundColor: '#B1B6BB' }}
                    onClick={(e) => {
                      e.preventDefault();
                      setAutoPlayAudioFail(false);
                    }}>
                    {' '}
                    Click me to resume the audio/video{' '}
                  </button>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};
