import { HeaderListIdolV2, PopupConfirmFan, PopupLoading } from 'components';
import FooterListIdolV2 from 'components/FooterV2/FooterListIdolV2';
import { InputWrapper } from 'components/FormLogin';
import { HiddenV2Icon, EyeV2Icon, SuccessRegisterMailIcon } from 'images';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { checkToken, resetPassword } from 'store/actions/users';
import HomeQuickViewImageSigin from 'components/HomeQuickViewImageSigin';
import { isEmptyObject, patternNoFirstSpacing } from '../../utils/utils';
import IdolDemo1 from 'images/img-slice-n1.png';
import IdolDemo2 from 'images/img-slice-n2.png';
import IdolDemo3 from 'images/img-slice-n3.png';

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    clearErrors,
  } = useForm({
    mode: 'all',
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [hiddenConfirmPassword, setHiddenConfirmPassword] = useState(true);
  const password = useRef({});
  const confirmPassword = useRef({});
  const watchPassword = watch('password', '');
  const watchConfirmPassword = watch('password_confirmation', '');
  password.current = watch('password', '');
  confirmPassword.current = watch('password_confirmation', '');
  const { t } = useTranslation();
  const {
    status,
    success,
    checkToken: check,
    loading: isLoading,
  } = useSelector((state) => state.users);
  const isDisabledButton =
    watchConfirmPassword?.length < 8 ||
    watchPassword?.length < 8 ||
    watchConfirmPassword?.length > 16 ||
    watchPassword?.length > 16 ||
    !watchPassword ||
    !watchConfirmPassword ||
    watchPassword !== watchConfirmPassword ||
    isLoading ||
    check !== 'TOKEN_VALID' ||
    !isEmptyObject(errors);

  useEffect(() => {
    dispatch(checkToken(token));
  }, []);

  const onSubmit = async (data) => {
    try {
      await dispatch(resetPassword(data, token));
    } catch (err) {
      console.log(err);
    }
  };

  const initialImages = [
    {
      url: IdolDemo1,
    },
    {
      url: IdolDemo2,
    },
    {
      url: IdolDemo3,
    },
  ];

  return (
    <>
      {success && (
        <PopupConfirmFan text={t('Common.changPasswordSuccess')} colorSvg='#28c76f'>
          <div
            className='ok btn'
            onClick={() => {
              navigate('/sign-in');
            }}>
            {t('Common.goToLogin')}
          </div>
        </PopupConfirmFan>
      )}
      {isLoading ? (
        <PopupLoading className={'popup-loading'} />
      ) : (
        <div
          className='block-div-signin reset-pass'
          style={{ backgroundColor: 'transparent' }}>
          <HeaderListIdolV2 />
          {check === 'TOKEN_VALID' ? (
            <div className='container-div'>
              <div
                className='container-left'
                style={{ marginBottom: '38px', marginTop: '66px' }}>
                <HomeQuickViewImageSigin className='mt-24' initialImages={initialImages} />
              </div>
              <div className='container-login'>
                <div className='login-form change-password'>
                  <form className='form' onSubmit={(e) => e.preventDefault()}>
                    <div className='d-center title'>{t('ForgotPassword.title')}</div>
                    {isLoading && <PopupLoading className={'popup-loading'} />}
                    <>
                      <InputWrapper
                        id='fsite-password'
                        label={t('Common.newPassword')}
                        required={true}
                        error={errors?.password ? errors?.password?.message : ''}>
                        <div className='password'>
                          <input
                            className='block-div-signin__active'
                            type={hiddenPassword ? `password` : `text`}
                            autocomplete='off'
                            placeholder={t('ValidateMsg.newPasswordRequired')}
                            {...register('password', {
                              required: t('ValidateMsg.newPasswordRequired'),
                              minLength: {
                                value: 8,
                                message: t('ValidateMsg.minPassword'),
                              },
                              validate: {
                                noFirstSpacing: (value) =>
                                  (value &&
                                    patternNoFirstSpacing.test(value.toString().trim())) ||
                                  t('ValidateMsg.newPasswordRequired'),
                                checkPass: (value) =>
                                  value !== confirmPassword.current && confirmPassword.current
                                    ? t('ValidateMsg.passwordNewNoMatchRePasswordNew')
                                    : null,
                              },
                              onChange: (e) => {
                                const { value } = e.target;
                                setValue('password', value?.replace(/\s/g, ''));
                                !(
                                  !patternNoFirstSpacing.test(value.toString().trim()) ||
                                  !value === confirmPassword.current
                                ) && clearErrors(['password_confirmation', 'password']);
                              },
                            })}
                          />
                          {hiddenPassword ? (
                            <EyeV2Icon onClick={() => setHiddenPassword(false)} />
                          ) : (
                            <HiddenV2Icon onClick={() => setHiddenPassword(true)} />
                          )}
                        </div>
                      </InputWrapper>
                      <InputWrapper
                        id='fsite-password'
                        label={t('ForgotPassword.newPassword')}
                        required={true}
                        error={
                          errors?.password_confirmation
                            ? errors?.password_confirmation?.message
                            : ''
                        }>
                        <div className='password'>
                          <input
                            className='block-div-signin__active'
                            type={hiddenConfirmPassword ? `password` : `text`}
                            placeholder={t('ValidateMsg.reNewPasswordRequired')}
                            {...register('password_confirmation', {
                              required: t('ValidateMsg.reNewPasswordRequired'),
                              minLength: {
                                value: 8,
                                message: t('ValidateMsg.minPassword'),
                              },
                              validate: {
                                noFirstSpacing: (value) =>
                                  (value &&
                                    patternNoFirstSpacing.test(value.toString().trim())) ||
                                  t('ValidateMsg.reNewPasswordRequired'),

                                checkPass: (value) =>
                                  value !== password.current && password.current
                                    ? t('ValidateMsg.passwordNewNoMatchRePasswordNew')
                                    : null,
                              },
                              onChange: (e) => {
                                const { value } = e.target;
                                setValue('password_confirmation', value?.replace(/\s/g, ''));
                                !(
                                  !patternNoFirstSpacing.test(value.toString().trim()) ||
                                  !value === password.current
                                ) && clearErrors(['password_confirmation', 'password']);
                              },
                            })}
                          />
                          {hiddenConfirmPassword ? (
                            <EyeV2Icon onClick={() => setHiddenConfirmPassword(false)} />
                          ) : (
                            <HiddenV2Icon onClick={() => setHiddenConfirmPassword(true)} />
                          )}
                        </div>
                      </InputWrapper>
                      <button
                        className={`btn ${isDisabledButton ? ` disabled` : ` active`}`}
                        type='submit'
                        disabled={isDisabledButton}
                        onClick={handleSubmit(onSubmit)}>
                        {t('Common.buttonChange')}
                      </button>
                    </>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <div className='container-div'>
              <div className='container-login'>
                <div className='login-form'>
                  <div
                    className='popup register-success-fan verify-email'
                    style={{ background: 'transparent' }}>
                    <div className='popup-content'>
                      <>
                        <div
                          className='content'
                          style={{
                            background: 'transparent',
                            boxShadow: 'none',
                          }}>
                          <SuccessRegisterMailIcon />
                          <div
                            className='text success-register-text text-center'
                            style={{ margin: '22px 0 32px 0' }}>
                            {t('Popup.URLValidate')}
                          </div>
                          <div className={`box-button`}>
                            <Link className='btn btn-outline-danger-v2' to={'/sign-up'}>
                              {t('Popup.newRegistrationPage')}
                            </Link>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <FooterListIdolV2 />
        </div>
      )}
    </>
  );
};

export default ResetPassword;
