import { LayoutCreatorRight } from 'components';
import withCreatorRole from 'hocs/withCreatorRole';
import useRedirect from 'hooks/useRedirect';
import { ClearInputIcon, SearchIcon } from 'images';
import 'pages/search/styles.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUserInfo,
  resetIDSearchUser,
  searchHashtagAction,
  searchShortVideoAction,
  searchTimeline,
  searchUserAction,
} from 'store/actions/users';
import './index.scss';
import { SearchShortVideoPage } from 'components/DetailCard/SearchShortVideoPage';
import SearchUser from './search-user';
import { isMobile, isTablet } from 'react-device-detect';
import {
  resetCommentDetail,
  resetIDSearchPost,
  resetIDSearchSale,
  searchPostAction,
  searchSaleContentAction,
  resetIDSearchVideo,
} from 'store/actions/usersNew';
import SearchSaleContent from './search-sale-content';
import SearchPost from './search-post';
import useDebounce from 'hooks/useDebouce';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { newPathUser } from 'constants/layout/constant';
import { getQueryParams } from 'utils';
import { useGetIdFromRecommendUser } from 'hooks/v2/useGetIdFromRecommendUser';
import { Helmet } from 'react-helmet';

const SearchPage = () => {
  const dispatch = useDispatch();
  const error = useSelector((state) => state.search.error);
  const {
    idSearchPost,
    idSearchSale,
    idSearchVideo,
    initPageSearchPost,
    initPageSeachSale,
    initPageSeachVideo,
  } = useSelector((state) => state.usersNew);
  const { idSearchUser, initPageSeachUser } = useSelector((state) => state.users);
  useRedirect(error);
  const [loadingCustom, setLoadingCustom] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [flgSearch, setFlgSearch] = useState(false);
  const tab = searchParams.get('tab');
  const keyword = searchParams.get('keyword');
  const [input, setInput] = useState(
    keyword === 'null' || keyword === null
      ? ''
      : keyword
      ? keyword
      : idSearchPost?.keyword ||
        idSearchSale?.keyword ||
        idSearchUser?.keyword ||
        idSearchVideo?.keyword ||
        ''
  );
  const widthScreen = window.innerWidth;
  const [tabActive, setTabActive] = useState(
    tab ? Number(tab) : idSearchSale?.tab || idSearchUser?.tab || idSearchVideo?.tab || 1
  );
  const debounce = useDebounce(input, 600);
  const itemsPerPage = 10;
  const [firstLoading, setFirstLoading] = useState(false);
  const [page, setPage] = useState(1);

  const [pagePost, setPagePost] = useState(
    idSearchPost && initPageSearchPost ? +initPageSearchPost : 1
  );
  const [pageSale, setPageSale] = useState(
    idSearchSale && initPageSeachSale ? +initPageSeachSale : 1
  );
  const [pageUser, setPageUser] = useState(
    idSearchUser && initPageSeachUser ? +initPageSeachUser : 1
  );
  const [pageShort, setPageShort] = useState(
    idSearchVideo && initPageSeachVideo ? +initPageSeachVideo : 1
  );
  // useEffect(() => {
  //   if (tab) {
  //   } else {
  //     navigate(`${newPathUser}search?tab=${1}&keyword=${keyword}`, { replace: true });
  //   }
  // }, [tab]);

  const onSubmit = (e) => {
    const keyCode = e.key;
    const value = e.target.value;
    if (!!input?.trim() && keyCode === 'Enter') {
      if (tabActive === 1) {
        setLoadingCustom(true);
        dispatch(
          searchPostAction(
            input,
            page,
            itemsPerPage,
            () => {
              setLoadingCustom(false);
            },
            () => {
              setLoadingCustom(false);
            }
          )
        );
      }
      if (tabActive === 2) {
        setLoadingCustom(true);
        dispatch(
          searchSaleContentAction(
            input,
            page,
            itemsPerPage,
            () => {
              setLoadingCustom(false);
            },
            () => {
              setLoadingCustom(false);
            }
          )
        );
      }
      if (tabActive === 3) {
        setLoadingCustom(true);
        dispatch(
          searchUserAction(
            input,
            page,
            itemsPerPage,
            () => {
              setLoadingCustom(false);
            },
            () => {
              setLoadingCustom(false);
            }
          )
        );
      }
      if (tabActive === 4) {
        setLoadingCustom(true);
        dispatch(
          searchShortVideoAction(
            input,
            page,
            isMobile ? 5 : 10,
            () => {
              setLoadingCustom(false);
            },
            () => {
              setLoadingCustom(false);
            }
          )
        );
      }
      // if (tabActive === 4) {
      //   setLoadingCustom(true);
      //   dispatch(
      //     searchHashtagAction(
      //       input,
      //       page,
      //       itemsPerPage,
      //       () => {
      //         setLoadingCustom(false);
      //       },
      //       () => {
      //         setLoadingCustom(false);
      //       }
      //     )
      //   );
      // }
    }
  };

  // useEffect(() => {
  //   setCheckFirstTimeSearch1(true);
  // }, []);
  const location = useLocation();
  useEffect(() => {
    if (getQueryParams(location.search, 'tab')?.includes(4)) {
      setTabActive(4);
    } else if (getQueryParams(location.search, 'tab')?.includes(3)) {
      setTabActive(3);
    } else if (getQueryParams(location.search, 'tab')?.includes(2)) {
      setTabActive(2);
    } else {
      setTabActive(1);
    }
  }, [location.state, location, location.search]);

  useEffect(() => {
    return () => {
      dispatch(resetCommentDetail());
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };
  const { idFromRecommendUser, clearIdScrollBackRecommend } = useGetIdFromRecommendUser();
  useEffect(() => {
    if (widthScreen < 991 && !idFromRecommendUser) {
      const timer = setTimeout(() => {
        if (input !== '') {
          handleSearch();
        }
      }, 800);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [input, idFromRecommendUser]);

  const handleInputChange = (e) => {
    if (widthScreen < 991) {
      clearIdScrollBackRecommend()
      setInput(e);
    }
  };

  const handleSearch = () => {
    navigate(`${newPathUser}search?tab=${tabActive}&keyword=${input}`, {
      replace: true,
    });
    dispatch(resetIDSearchVideo());
    dispatch(resetIDSearchSale());
    dispatch(resetIDSearchPost());
    dispatch(resetIDSearchUser());
    setPagePost(1);
    setPageSale(1);
    setPageUser(1);
    setPageShort(1);
    setFlgSearch(true);
    if (tabActive === 1) {
      setLoadingCustom(true);
      dispatch(
        searchPostAction(
          input,
          page,
          12,
          () => {
            setLoadingCustom(false);
          },
          () => {
            setLoadingCustom(false);
          }
        )
      );
    }
    if (tabActive === 2) {
      setLoadingCustom(true);
      dispatch(
        searchSaleContentAction(
          input,
          page,
          itemsPerPage,
          () => {
            setLoadingCustom(false);
          },
          () => {
            setLoadingCustom(false);
          }
        )
      );
    }
    if (tabActive === 3) {
      setLoadingCustom(true);
      dispatch(
        searchUserAction(
          input,
          page,
          itemsPerPage,
          () => {
            setLoadingCustom(false);
          },
          () => {
            setLoadingCustom(false);
          }
        )
      );
    }
    if (tabActive === 4) {
      setLoadingCustom(true);
      dispatch(
        searchShortVideoAction(
          input,
          page,
          isMobile ? 5 : 10,
          () => {
            setLoadingCustom(false);
          },
          () => {
            setLoadingCustom(false);
          }
        )
      );
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Sharegram</title>
        <meta name="keywords" content="sharegram, シェアグラム, 検索, search, share-gram, share-gram.com" />
        <link rel='canonical' href='https://share-gram.com/search' />
      </Helmet>
      <LayoutCreatorRight noHeader titlePage={t('Common.search')} className='search-page'>
        <form onSubmit={(e) => e.preventDefault()} className={` from-search-page`}>
          <div className='form-item'>
            <div className='icon-container'>
              <SearchIcon className='search-icon' />
              {!!input?.trim() && (
                <ClearInputIcon
                  className='clear-icon'
                  onClick={() => {
                    navigate(`${newPathUser}search?tab=${tabActive}&keyword=${null}`, {
                      replace: true,
                    });
                    setInput('');
                    setFlgSearch(false);
                    if (widthScreen < 991) {
                      const top = document.getElementById('app_cocofans_0');
                      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
                      clearIdScrollBackRecommend()
                      navigate(`${newPathUser}search?tab=${tabActive}&keyword=${null}`, {
                        replace: true,
                      });
                      dispatch(resetIDSearchVideo());
                      dispatch(resetIDSearchSale());
                      dispatch(resetIDSearchPost());
                      dispatch(resetIDSearchUser());
                      setPagePost(1);
                      setPageSale(1);
                      setPageUser(1);
                      setPageShort(1);
                      setFlgSearch(true);
                      if (tabActive === 1) {
                        setLoadingCustom(true);
                        dispatch(
                          searchPostAction(
                            '',
                            page,
                            12,
                            () => {
                              setLoadingCustom(false);
                            },
                            () => {
                              setLoadingCustom(false);
                            }
                          )
                        );
                      }
                      if (tabActive === 2) {
                        setLoadingCustom(true);
                        dispatch(
                          searchSaleContentAction(
                            '',
                            page,
                            itemsPerPage,
                            () => {
                              setLoadingCustom(false);
                            },
                            () => {
                              setLoadingCustom(false);
                            }
                          )
                        );
                      }
                      if (tabActive === 3) {
                        setLoadingCustom(true);
                        dispatch(
                          searchUserAction(
                            '',
                            page,
                            itemsPerPage,
                            () => {
                              setLoadingCustom(false);
                            },
                            () => {
                              setLoadingCustom(false);
                            }
                          )
                        );
                      }
                      if (tabActive === 4) {
                        setLoadingCustom(true);
                        dispatch(
                          searchShortVideoAction(
                            '',
                            page,
                            isMobile ? 5 : 10,
                            () => {
                              setLoadingCustom(false);
                            },
                            () => {
                              setLoadingCustom(false);
                            }
                          )
                        );
                      }
                    }
                  }}
                />
              )}
            </div>
            <input
              className={`${!!input ? 'is_input' : ''} input-field`}
              id='id-input-search'
              type='text'
              value={input}
              placeholder={t('Search.placeholderKeyword')}
              onKeyDown={handleKeyDown}
              onChange={(e) => {
                if (widthScreen < 991) {
                  handleInputChange(e.target.value);
                }
                setInput(e.target.value);
              }}
              onKeyPress={(e) => onSubmit(e)}
            />
          </div>
          <div
            className='button-search'
            onClick={() => {
              navigate(`${newPathUser}search?tab=${tabActive}&keyword=${input}`, {
                replace: true,
              });
              dispatch(resetIDSearchVideo());
              dispatch(resetIDSearchSale());
              dispatch(resetIDSearchPost());
              dispatch(resetIDSearchUser());
              setPagePost(1);
              setPageSale(1);
              setPageUser(1);
              setPageShort(1);
              setFlgSearch(true);
              if (tabActive === 1) {
                setLoadingCustom(true);
                dispatch(
                  searchPostAction(
                    input,
                    page,
                    12,
                    () => {
                      setLoadingCustom(false);
                    },
                    () => {
                      setLoadingCustom(false);
                    }
                  )
                );
              }
              if (tabActive === 2) {
                setLoadingCustom(true);
                dispatch(
                  searchSaleContentAction(
                    input,
                    page,
                    itemsPerPage,
                    () => {
                      setLoadingCustom(false);
                    },
                    () => {
                      setLoadingCustom(false);
                    }
                  )
                );
              }
              if (tabActive === 3) {
                setLoadingCustom(true);
                dispatch(
                  searchUserAction(
                    input,
                    page,
                    itemsPerPage,
                    () => {
                      setLoadingCustom(false);
                    },
                    () => {
                      setLoadingCustom(false);
                    }
                  )
                );
              }
              if (tabActive === 4) {
                setLoadingCustom(true);
                dispatch(
                  searchShortVideoAction(
                    input,
                    page,
                    isMobile ? 5 : 10,
                    () => {
                      setLoadingCustom(false);
                    },
                    () => {
                      setLoadingCustom(false);
                    }
                  )
                );
              }
            }}>
            {t('Search.title')}
          </div>
        </form>
        <div className='tabs-search'>
          <div
            className={`tab-item ${tabActive === 1 ? 'active' : ''}`}
            style={{
              cursor: `${firstLoading ? 'default' : 'pointer'}`,
              pointerEvents: `${tabActive === 1 ? 'none' : 'auto'}`,
            }}
            disabled={firstLoading}
            onClick={() => {
              if (!firstLoading) {
                navigate(`${newPathUser}search?tab=${1}&keyword=${keyword}`);
                dispatch(resetIDSearchVideo());
                dispatch(resetIDSearchUser());
                dispatch(resetIDSearchSale());
                tabActive !== 1 && setTabActive(1);
              }
            }}>
            {t('PostDetail.title.type1')}
          </div>
          <div
            className={`tab-item ${tabActive === 2 ? 'active' : ''}`}
            style={{
              cursor: `${firstLoading ? 'default' : 'pointer'}`,
              pointerEvents: `${tabActive === 2 ? 'none' : 'auto'}`,
            }}
            disabled={firstLoading}
            onClick={() => {
              if (!firstLoading) {
                navigate(`${newPathUser}search?tab=${2}&keyword=${keyword}`);
                dispatch(resetIDSearchVideo());
                dispatch(resetIDSearchUser());
                dispatch(resetIDSearchPost());
                tabActive !== 2 && setTabActive(2);
              }
            }}>
            {t('HomePageContent.saleContentType2')}
          </div>
          <div
            className={`tab-item ${tabActive === 3 ? 'active' : ''}`}
            style={{
              cursor: `${firstLoading ? 'default' : 'pointer'}`,
              pointerEvents: `${tabActive === 3 ? 'none' : 'auto'}`,
            }}
            disabled={firstLoading}
            onClick={() => {
              if (!firstLoading) {
                navigate(`${newPathUser}search?tab=${3}&keyword=${keyword}`);
                dispatch(resetIDSearchVideo());
                dispatch(resetIDSearchSale());
                dispatch(resetIDSearchPost());
                tabActive !== 3 && setTabActive(3);
              }
            }}>
            {t('FooterTabs.creator')}
          </div>
          <div
            className={`tab-item ${tabActive === 4 ? 'active' : ''}`}
            style={{
              cursor: `${firstLoading ? 'default' : 'pointer'}`,
              pointerEvents: `${tabActive === 4 ? 'none' : 'auto'}`,
            }}
            disabled={firstLoading}
            onClick={() => {
              if (!firstLoading) {
                navigate(`${newPathUser}search?tab=${4}&keyword=${keyword}`);
                dispatch(resetIDSearchUser());
                dispatch(resetIDSearchSale());
                dispatch(resetIDSearchPost());
                tabActive !== 4 && setTabActive(4);
              }
            }}>
            {t('FooterTabs.short')}
          </div>
        </div>
        {tabActive === 1 && (
          <>
            <SearchPost
              flgSearch={flgSearch}
              page={pagePost}
              setPagePost={setPagePost}
              keyword={input}
              loadingClickSearch={loadingCustom}
              setFirstLoading={setFirstLoading}
            />
          </>
        )}
        {tabActive === 2 && (
          <>
            <SearchSaleContent
              flgSearch={flgSearch}
              page={pageSale}
              setPage={setPageSale}
              keyword={input}
              loadingClickSearch={loadingCustom}
              setFirstLoading={setFirstLoading}
            />
          </>
        )}
        {tabActive === 3 && (
          <>
            <SearchUser
              flgSearch={flgSearch}
              page={pageUser}
              setPage={setPageUser}
              keyword={input}
              loadingClickSearch={loadingCustom}
              setFirstLoading={setFirstLoading}
            />
          </>
        )}
        {tabActive === 4 && (
          <>
            <SearchShortVideoPage
              flgSearch={flgSearch}
              page={pageShort}
              setPage={setPageShort}
              keyword={input}
              loadingClickSearch={loadingCustom}
              setFirstLoading={setFirstLoading}
            />
          </>
        )}
        {/* {tabActive === 4 && (
          <>
            <SearchHashtag keyword={input} loadingClickSearch={loadingCustom} />
          </>
        )} */}
      </LayoutCreatorRight>
    </React.Fragment>
  );
};

export default withCreatorRole(SearchPage);
