import {
  ImgCustom,
  LayoutFan,
  ListComment,
  PopupConfirmFan,
  PopupLockPostNormal,
  PopupSharePost,
  Textarea,
  ViewFullImage,
} from 'components';
import { InputWrapper } from 'components/FormLogin';
import Loading from 'components/Paging/Loading.jsx';
import { getEnv } from 'configs/env';
import useRedirect from 'hooks/useRedirect';
import {
  ChatIcon,
  CircleCheckIcon,
  FlagIcon,
  HeartActiveIcon,
  HeartIcon,
  SendChatIcon,
  ShareIcon,
} from 'images';
import AvatarDemo from 'images/Avatar.png';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  deleteCommentDetail,
  deleteReplyCommentDetail,
  getPostDetail,
  postCommentDetail,
  postFollowCommentDetail,
  postFollowReplyCommentDetail,
  postLikeDetail,
} from 'store/actions/myPage.js';
import { getUserInfo } from 'store/actions/users.js';
import { linkS3 } from 'utils/utils';
import { getDetailCreator } from '../../../store/actions/creator';
import '../myPage.scss';
import ContentNormal from './ContentNormal';
import ContentNormalType3 from './ContentNormalType3';
import ContentSale from './ContentSale';
import ContentSaleMode2 from './ContentSaleMode2';
import ContentShortType4 from './ContentShortType4';
import './styles.scss';
import { updateLikeReplyComment, updateLikeCommentDetail, updatePostLikeDetail } from 'store/actions/usersNew';

export default function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { detailPost, loading, loadingAction, loadingPostDetail, statusPostComment } =
    useSelector((state) => state.myPage);
  const dataProfile = useSelector((state) => state.users.data);
  const { check_buy_post, loadingBuyPost } = useSelector((state) => state.sellPost);
  const [checkSendChat, setCheckSendChat] = useState(null);
  const [valueComment, setValueComment] = useState('');
  const [showComment, setShowComment] = useState([-1]);
  const [popupDeleteComment, setPopupDeleteComment] = useState(null);
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const [dataViewFull, setDataViewFull] = useState({});
  const [sellBy, setSellBy] = useState();
  // toggle comment
  const handleToggleComment = (id) => {
    const check = showComment.find((elem) => elem === id);
    check
      ? setShowComment(showComment.filter((item) => item !== id))
      : setShowComment(showComment.concat(id));
  };

  // copy link url
  const handleShowPopup = (e) => {
    setPopupCopyLink(e);
  };

  const handleTextarea = (e, id, height) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    setValueComment(e.target.value);
    setCheckSendChat(id);
    if (e.target.value?.toString().trim() === '') {
      e.target.style.height = height;
      e.target.style.padding = '9px  30px  9px 12px';
      setCheckSendChat(null);
    }
  };

  // esc func
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setCheckSendChat(null);
      setValueComment('');
    }
  }, []);

  // start func esc
  useEffect(() => {
    if (statusPostComment) {
      setCheckSendChat(null);
      setValueComment('');
      const textareaId = document.getElementById('textarea-post-comment');
      if (textareaId) textareaId.style.height = '38px';
    }
    document.addEventListener('keydown', escFunction);
    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction, statusPostComment]);

  // get userInfo
  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(getPostDetail(id));
  }, [id]);

  const error = useSelector((state) => state.myPage.error);
  useRedirect(error);

  //api like post
  const handleLikePost = (post) => {
    dispatch(updatePostLikeDetail(post));
  };

  const handleSharePost = (dataPC) => {
    setPopupCopyLink(dataPC);
  };

  const handleLikeComment = (data) => {
    const { commentId, type, dataPost } = data;
    type === 'comment'
      ? dispatch(updateLikeCommentDetail(commentId, dataPost))
      : dispatch(updateLikeReplyComment(commentId, dataPost));
  };

  // api post/edit comment
  const handlePostComment = (id, parent_id) => {
    const data = {
      parent_id: parent_id,
      content: valueComment,
    };
    if (valueComment !== '') {
      dispatch(postCommentDetail(id, data));
      // setValueComment('');
    }
  };

  const handleDeleteComment = () => {
    const { id, type, idParent } = popupDeleteComment;
    type === 'comment'
      ? dispatch(deleteCommentDetail(id))
      : dispatch(deleteReplyCommentDetail(id, idParent));
    setPopupDeleteComment(false);
  };

  // FOLLOW USER IN COMMENT
  const handleFollowUserComment = (data) => {
    const { id, type } = data;
    type === 'comment'
      ? dispatch(postFollowCommentDetail(id))
      : dispatch(postFollowReplyCommentDetail(id));
  };

  useEffect(() => {
    setTimeout(() => {
      setCheckSendChat(null);
    }, 500);
  }, [loading]);
  const { t } = useTranslation();

  const checkDisableWithType3And4 =
    detailPost?.type === 3 || detailPost?.type === 4
      ? detailPost?.follow_flag !== 1
        ? false
        : detailPost?.user_follow_status === 1
        ? false
        : true
      : false;

  return (
    <>
      {detailPost && (
        <LayoutFan
          titleHeader={
            detailPost?.type === 1
              ? t('PostDetail.title.type1')
              : detailPost?.type === 2
              ? t('PostDetail.title.type2')
              : detailPost?.type === 3
              ? t('PostDetail.title.type3')
              : t('PostDetail.title.type4')
          }
          loading={loadingBuyPost}
          page={detailPost?.type === 2 && 'page-F021'}>
          {dataViewFull?.image?.url && (
            <ViewFullImage dataViewFull={dataViewFull} close={() => setDataViewFull({})} />
          )}
          {popupCopyLink !== null && (
            <PopupSharePost isVisiblePopup={handleShowPopup} data={popupCopyLink || {}} />
          )}
          {popupDeleteComment && (
            <PopupConfirmFan
              isVisiblePopup={handleShowPopup}
              colorSvg='#FF9F43'
              text={t('Popup.confirmDeleteComment')}
              className='popup-delete'>
              <div className='ok btn' onClick={() => handleDeleteComment()}>
                {t('Common.Ok')}
              </div>
              <div className='btn cancel' onClick={() => setPopupDeleteComment(false)}>
                {t('Common.buttonCancel')}
              </div>
            </PopupConfirmFan>
          )}
          {check_buy_post === 'BUY_SUCCESS' ? (
            <>
              <div className='bg-white my-page'>
                <div className='my-page-item sell-post-success'>
                  <CircleCheckIcon className='check-icon' />
                  <div className='text-confirm'>
                    {+sellBy === 1 || sellBy === '1'
                      ? t('Popup.buyPostSuccess1', { code: detailPost?.code })
                      : t('Popup.buyPostTicketSuccess', { code: detailPost?.code })}
                  </div>
                  <div className='button btn-reload' onClick={() => navigate(0)}>
                    {t('PostDetail.buttonReload')}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {loadingPostDetail ? (
                <Loading />
              ) : (
                detailPost?.user && (
                  <>
                    <div className='bg-white my-page my-page-detail'>
                      <div className='my-page-item'>
                        <PopupLockPostNormal detailPost={detailPost} post_id={detailPost.id} />
                        {/* post normal */}
                        {detailPost.type === 1 && <ContentNormal detailPost={detailPost} />}
                        {/* post sale */}
                        {detailPost.type === 2 && (
                          <>
                            {detailPost?.unlock_package && detailPost?.unlock_media ? (
                              <ContentSaleMode2 detailPost={detailPost} />
                            ) : (
                              <ContentSale
                                detailPost={detailPost}
                                dataProfile={dataProfile}
                                sellBy={(e) => setSellBy(e)}
                              />
                            )}
                          </>
                        )}
                        {/* post normal type 3 */}
                        {detailPost.type === 3 && (
                          <ContentNormalType3
                            detailPost={detailPost}
                            checkDisableWithType3And4={checkDisableWithType3And4}
                          />
                        )}
                        {/* post short type 4 */}
                        {detailPost.type === 4 && (
                          <ContentShortType4
                            detailPost={detailPost}
                            checkDisableWithType3And4={checkDisableWithType3And4}
                          />
                        )}
                        <div
                          className='interactive'
                          style={{
                            pointerEvents: checkDisableWithType3And4 ? 'none' : 'auto',
                            color: checkDisableWithType3And4 ? '#B1B6BB' : '#787880',
                          }}>
                          <div className='left'>
                            <div className={`item ${loadingAction && 'disabled'}`}>
                              {detailPost?.user_like_status === 1 ? (
                                <>
                                  <HeartActiveIcon
                                    className={`like-icon active`}
                                    onClick={() => handleLikePost(detailPost)}
                                  />
                                </>
                              ) : (
                                <>
                                  <HeartIcon
                                    className={`like-icon}`}
                                    onClick={() => handleLikePost(detailPost)}
                                  />
                                </>
                              )}
                              <span className='number'>{detailPost?.number_like}</span>
                            </div>
                            <div className={`item ${loadingAction && 'disabled'}`}>
                              <ChatIcon
                                className='chat-icon'
                                onClick={() => handleToggleComment(detailPost?.id)}
                              />
                              <span className='number'>{detailPost?.number_comment}</span>
                            </div>
                            <div className={`item ${loadingAction && 'disabled'}`}>
                              <ShareIcon
                                className={`share-icon ${loadingAction && 'disabled'}`}
                                onClick={() => {
                                  handleSharePost({
                                    link: `${getEnv('REACT_APP_FAN_URL')}/post/${
                                      detailPost?.id
                                    }`,
                                    title: detailPost.title,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <FlagIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              navigate('/post-report', {
                                state: { post_id: id },
                              })
                            }
                          />
                        </div>
                        {!!detailPost?.enable_comment && !checkDisableWithType3And4 && (
                          <div className={`comment`}>
                            <ImgCustom
                              src={dataProfile?.avatar ? `${dataProfile?.avatar}` : AvatarDemo}
                            />
                            <form onSubmit={(e) => e.preventDefault()}>
                              <InputWrapper id='fsite-comment' className='form-item'>
                                <Textarea
                                  onChange={(e) => handleTextarea(e, detailPost?.id, '38px')}
                                  placeholder={t('comment.formAddRepComment.placeholder')}
                                  onKeyDown={(e) => handleTextarea(e, detailPost?.id, '38px')}
                                  value={valueComment}
                                  maxLength='500'
                                  id='textarea-post-comment'
                                  overflowHidden
                                />
                                <SendChatIcon
                                  className={`${valueComment !== '' && 'active'}`}
                                  onClick={() => {
                                    console.log(valueComment);
                                    valueComment !== '' &&
                                      handlePostComment(detailPost?.id, 0, 'post');
                                  }}
                                  type='button'
                                />
                              </InputWrapper>
                            </form>
                          </div>
                        )}
                        {loading && checkSendChat === detailPost?.id && (
                          <Loading className='loading-comment' />
                        )}
                        {!checkDisableWithType3And4 && (
                          <ListComment
                            dataPost={detailPost}
                            handleDeleteComment={(e) => {
                              setPopupDeleteComment(e);
                            }}
                            handleLikeComment={handleLikeComment}
                            dataProfile={dataProfile}
                            handleFollowUserComment={handleFollowUserComment}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )
              )}
            </>
          )}
        </LayoutFan>
      )}
    </>
  );
}
