import { PopupLoading, PopupSharePost } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getListPostShortVideo,
  resetActionCreatorHomePage,
  resetListPostShortVideo,
} from 'store/actions/creator';
import { postFollowUser, resetCommentDetail } from 'store/actions/usersNew';
import './index.scss';
import PopupViewFullMess from './comment/popupCommemt';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { LockScreen } from './style';
import { isMobile, isSafari } from 'react-device-detect';
import Storage from 'utils/storage';
import ItemImage from './ListImgTimeline';
import ItemVideo from './VideoPlayerShort/ItemVideo';
import { logUserProfile } from 'store/actions/myPage';
import { STORAGE } from 'utils';
import { CloseIcon } from 'images';

export const TimelineWrapperContext = createContext();

const Timeline = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [listShort, setListShort] = useState([]);
  const [indexPlay, setIndexPlay] = useState(0);
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const [isShowPopup, setShowPopup] = useState({
    index: 0,
    status: false,
    dataItem: {},
  });
  const { pagination, listPostShortVideo, loading } = useSelector((state) => state.creator);
  const { dataUser } = useSelector((state) => state.users);
  const { isLoadingFollowCreate, commentDetail } = useSelector((state) => state.usersNew);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [firstLoading, setFirstLoading] = useState(false);
  const [isReloadPage, setIsReloadPage] = useState(Storage.get('reload-short'));
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);

  useEffect(() => {
    dispatch(resetActionCreatorHomePage());

    return () => {
      Storage.set('reload-short', 0);
      dispatch(resetListPostShortVideo());
    };
  }, []);

  useEffect(() => {
    if (commentDetail && commentDetail?.length > 0 && !isShowPopup?.status) {
      dispatch(resetCommentDetail());
    }
  }, [isShowPopup?.status, commentDetail, dispatch]);

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);
  const alertUser = (e) => {
    Storage.set('reload-short', 1);
  };
  useEffect(() => {
    if (listPostShortVideo && listPostShortVideo?.length > 0) {
      const listChange = listPostShortVideo?.map((item, index) => {
        let newItem = item;
        const itemHave = listShort?.find((e) => e?.id === item?.id) || null;
        if (itemHave) {
          newItem = itemHave;
        }
        if (index === indexPlay) {
          return {
            ...newItem,
            isFullContent: false,
            isPlay: item?.is_followed === 0 && item?.follow_flag === 1 ? false : true,
            isMute: isSafari && isMobile ? true : +isReloadPage === 1,
            //type: newItem?.medias && newItem?.medias[0]?.type,
            duration:
              newItem?.medias && newItem?.medias[0]?.type === 2
                ? newItem?.medias[0]?.duration
                : 0,
          };
        }
        return {
          ...newItem,
          isFullContent: false,
          isPlay: false,
          isMute: true,
          //type: newItem?.medias && newItem?.medias[0]?.type,
          duration:
            newItem?.medias && newItem?.medias[0]?.type === 2
              ? newItem?.medias[0]?.duration
              : 0,
        };
      });
      setListShort(listChange);
    }
  }, [listPostShortVideo, isReloadPage]);

  const updateList = useCallback(
    (indexItem) => {
      setIndexPlay(indexItem);
      if (indexItem === listShort?.length - 1) {
        setPage((prevPage) => prevPage + 1);
      }
      if (listShort && listShort?.length > 0) {
        const listChange = listShort?.map((item, index) => {
          if (index === indexItem) {
            return {
              ...item,
              isPlay: item?.is_followed === 0 && item?.follow_flag === 1 ? false : true,
              isMute: isSafari && isMobile ? true : +isReloadPage === 1,
              //type: item?.medias && item?.medias[0]?.type,
            };
          }
          return {
            ...item,
            isPlay: false,
            isMute: true,
            //type: item?.medias && item?.medias[0]?.type,
          };
        });
        setListShort(listChange);
      }
    },
    [listShort, page]
  );

  const funCallApiListPostShort = useCallback(() => {
    page === 1 && setFirstLoading(true); // page = 1
    dispatch(
      getListPostShortVideo(
        {
          page: page,
          limit: isMobile ? 2 : 4,
        },
        () => setFirstLoading(false),
        () => setFirstLoading(false)
      )
    );
  }, [page]);
  useEffect(() => {
    funCallApiListPostShort();
  }, [funCallApiListPostShort]);

  const fetchNext = () => {
    const pages = pagination?.total / pagination?.per_page;
    if (pages > page) {
      setPage(page + 1);
    }
  };

  const handleFollowCreator = (id) => {
    dispatch(postFollowUser(id));
  };

  const toggleValueVideo = useCallback(
    (index, name = 'isPlay', value) => {
      const listClone = JSON.parse(JSON.stringify(listShort)) || [];
      if (listClone?.length > 0) {
        if (name === 'is_liked') {
          listClone[index][name] = value || !listClone[index][name];
          if (value === 1) {
            listClone[index]['number_like'] = listClone[index]['number_like'] + 1;
          } else {
            listClone[index]['number_like'] =
              listClone[index]['number_like'] > 0 ? listClone[index]['number_like'] - 1 : 0;
          }
        } else if (name === 'number_comment') {
          listClone[index]['number_comment'] =
            listClone[index]['number_comment'] + (value || 1);
        } else {
          listClone[index][name] = value || !listClone[index][name];
        }
      }
      setListShort(listClone);
    },
    [listShort]
  );

  const toggleFollowAllUser = useCallback(
    (index, userId, playValue = true, followValue = 1) => {
      const listClone = JSON.parse(JSON.stringify(listShort)) || [];
      if (listClone?.length > 0) {
        const newList = listClone?.map((item, indexItem) => {
          if (index === indexItem) {
            return {
              ...item,
              isPlay: playValue,
              is_followed: followValue,
            };
          } else if (item?.user_id === userId) {
            return {
              ...item,
              isPlay: false,
              is_followed: followValue,
            };
          }
          return item;
        });
        setListShort(newList);
      }
    },
    [listShort]
  );

  const toggleFollowAndPlay = useCallback(
    (index, userId) => {
      toggleFollowAllUser(index, userId, true, 1);
    },
    [toggleFollowAllUser]
  );

  const renderItemVideo = (item, index) => {
    const { medias } = item;
    if (medias) {
      if (medias[0]?.type === 2) {
        return (
          <ItemVideo
            item={item}
            index={index}
            indexPlay={indexPlay}
            updateList={updateList}
            toggleValueVideo={toggleValueVideo}
            setShowPopup={setShowPopup}
            handleFollowCreator={handleFollowCreator}
            toggleFollowAllUser={toggleFollowAllUser}
            setPopupCopyLink={setPopupCopyLink}
            isShowPopup={isShowPopup}
          />
        );
      } else {
        return (
          <ItemImage
            item={item}
            index={index}
            indexPlay={indexPlay}
            updateList={updateList}
            toggleValueVideo={toggleValueVideo}
            medias={medias}
            setShowPopup={setShowPopup}
            handleFollowCreator={handleFollowCreator}
            toggleFollowAllUser={toggleFollowAllUser}
            setPopupCopyLink={setPopupCopyLink}
            isShowPopup={isShowPopup}
            dataUser={dataUser}
          />
        );
      }
    }
  };

  return (
    <div>
      {firstLoading && (
        <div className='shimmer-bg'>
          <ShimmerPostDetails hasImage imageType='circular' title />
        </div>
      )}
      {isShowPopup?.status && (
        <PopupViewFullMess
          className='popup-full-message-short'
          index={isShowPopup?.index}
          toggleValueVideo={toggleValueVideo}
          item={isShowPopup?.dataItem}
          toggleFollowAllUser={toggleFollowAllUser}
          close={() => {
            dispatch(resetCommentDetail());
            setShowPopup({
              ...isShowPopup,
              status: false,
              dataItem: {},
            });
          }}
        />
      )}
      {popupCopyLink && (
        <PopupSharePost
          isVisiblePopup={() => setPopupCopyLink(false)}
          data={popupCopyLink || {}}
        />
      )}
      {!firstLoading && (
        <div className={`wrapper-short ${listShort?.length > 0 ? "" : "empty-data"}`}>
          {isLoadingFollowCreate && <PopupLoading className={'popup-loading'} />}
          {listShort?.length > 0 ? (
            <InfiniteLoad data={listShort} fetchNext={fetchNext}>
              <div className='page-list-short-video c154' id='short-video-list-id'>
                {listShort.map((item, index) => (
                  <div
                    key={item?.id}
                    className={`page-list-short-video-item ${isMobile ? 'isMobile' : ''}`}>
                    {item?.is_followed === 0 && item?.follow_flag === 1 && (
                      <div style={item?.medias?.length === 0 ? { paddingBottom: '35px' } : {}}>
                        <LockScreen>
                          <div className='screen-blur'></div>
                          <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
                          <button
                            onClick={(e) => {
                              if (!isAuth) {
                                dispatch(logUserProfile(item));
                              } else {
                                e.stopPropagation();
                                toggleFollowAndPlay(index, item?.user_id);
                                handleFollowCreator(item?.user_id);
                              }
                            }}>
                            {t('Common.follow')}
                          </button>
                        </LockScreen>
                      </div>
                    )}
                    {renderItemVideo(item, index)}
                  </div>
                ))}
              </div>
            </InfiniteLoad>
          ) : (
            <>
              <div className='empty-box'>
                <div className='empty-box-icon'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='100'
                    height='100'
                    viewBox='0 0 100 100'
                    fill='none'>
                    <path
                      d='M9.375 23.4375H59.375C62.6902 23.4375 65.8696 24.7545 68.2138 27.0987C70.558 29.4429 71.875 32.6223 71.875 35.9375V73.4375C71.875 74.2663 71.5458 75.0612 70.9597 75.6472C70.3737 76.2333 69.5788 76.5625 68.75 76.5625H18.75C15.4348 76.5625 12.2554 75.2455 9.91117 72.9013C7.56696 70.5571 6.25 67.3777 6.25 64.0625V26.5625C6.25 25.7337 6.57924 24.9388 7.16529 24.3528C7.75134 23.7667 8.5462 23.4375 9.375 23.4375V23.4375Z'
                      stroke='#FCECEF'
                      strokeWidth='4'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M71.875 43.75L93.75 31.25V68.75L71.875 56.25'
                      stroke='#FCECEF'
                      strokeWidth='4'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
                <div>{t('MenuSiderbar.creator.listShortVideoEmpty')}</div>
                <div className='empty-box-btn' onClick={() => navigate("/")}>
                  {t('Common.backToHome')}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Timeline;
