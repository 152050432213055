import {
  LayoutCreatorRight,
  PopupAlert,
  PopupBlockUser,
  PopupConfirmFan,
  PopupLoading,
} from 'components';
import DragNDrop from 'components/DragNDrop';
import { InputWrapper } from 'components/Input/InputWrapper';
import { newPathUser } from 'constants/layout/constant';
import { CREATOR_STATUS_ENUM, CREATOR_STATUS_ENUM_COLOR } from 'constants/shared';
import withCreatorRole from 'hocs/withCreatorRole';
import { ArrowDownIcon, ArrowLeftIcon, CalendarIcon } from 'images';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import {
  editCreatorProfileIndividual,
  getListCountry,
  getUserInfo,
  getUserInfoMe,
  handleChangeEmailStep1,
  requestApprove,
  requestLeave,
} from 'store/actions/users';
import {
  customStyles,
  GENDER,
  getPresignedUrlS3,
  isEmptyObject,
  MONEY_TYPE,
  setFileNameS3,
  TEN_MB,
  acceptedImageTypes,
  patternNoFirstSpacing,
  CustomOption,
  acceptedFileDOCTypes,
  FIVE_MB,
  uploadS3ByPresigned,
  setFileNameS3Contract,
  acceptedFilePDFContractTypes,
} from 'utils';
import '../../sign-up/index.scss';
import './index.scss';
import ProfileBackgroundImage from 'components/Profile-background-image';
import ProfileAvatar from 'components/Profile-avatar';
import NavbarUserProfile from 'components/NavbarEditProfile';
import { LIST_COUNTRY_EN, LIST_COUNTRY_JA } from 'constants/shared/country';
import withCreatorProfilePage, { useCreatorProfile } from 'context/CreatorProfileProvider';
import { isMobile, isTablet } from 'react-device-detect';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import {
  getUserInfoOthers,
  resetDataInfoOther,
  resetIDHomePage,
} from 'store/actions/usersNew';
import axios from 'axios';
import { getEnv } from 'configs/env';

function ProfileInfo() {
  const checkTextJP = /^[一-龯ぁ-んァ-ヾー々ｧ-ﾝ　 ]+$/gu;
  const checkTextJPKana = /^[ァ-ンー－　 ｧ-ﾝｰ－]+$/gu;
  const [isChanging, setIsChanging] = useState(false);
  const changingFormRef = useRef({});
  const [isLoading, setIsLoading] = useState(false);
  let dataS3Update = {};
  const navigate = useNavigate();
  const location = useLocation();
  const [showPopupSuccess, setShowPopupSuccess] = useState(false);
  const [showPopupLeave, setShowPopupLeave] = useState(false);
  const [showPopupApproveSuccess, setShowPopupApproveSuccess] = useState(false);
  const [showPopupChangeEmailSuccess, setShowPopupChangeEmailSuccess] = useState(false);
  const [typeRequestApprove, setTypeRequestApprove] = useState();
  const { t, i18n } = useTranslation();
  const [focusGender, setFocusGender] = useState(false);
  const [avatarTmp, setAvatarTmp] = useState();
  const [bgTmp, setBgTmp] = useState();
  const [errorBG, setErrorBG] = useState('');
  const [errorAvar, setErrorAvar] = useState('');
  const [focusNation, setFocusNation] = useState(false);
  const [focusDeposit, setFocusDeposit] = useState(false);
  const [lableCT, setLableCT] = useState({});
  const [loaddingDown, setLoaddingDown] = useState(false);
  const kanaOne = useRef({});
  const kanaTwo = useRef({});
  const ref = useRef();
  const {
    loading,
    data: userProfile,
    listCountryLoading,
    creatorUpdateProfileFailed,
    creatorUpdateProfileSuccess,
    requestApproveSuccess,
    changeEmailStep1,
    dataUser,
    listCountries,
  } = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm({
    mode: 'all',
  });

  useEffect(() => {
    dispatch(getUserInfo());
  }, []);

  useEffect(() => {
    creatorUpdateProfileFailed && setIsLoading(false);
  }, [creatorUpdateProfileFailed]);

  useEffect(() => {
    if (location?.state?.redirectBlockRequest) {
      const top = document.getElementById('block-request-information');
      top && top.scrollIntoView({ top: 0, left: 0, block: 'center' });
    }
  }, [location?.state?.redirectBlockRequest]);

  useEffect(() => {
    creatorUpdateProfileSuccess && setShowPopupSuccess(true);
    setIsLoading(false);
  }, [creatorUpdateProfileSuccess]);

  useEffect(() => {
    requestApproveSuccess ? setShowPopupApproveSuccess(true) : setIsLoading(false);
    setIsLoading(false);
  }, [requestApproveSuccess]);

  useEffect(() => {
    changeEmailStep1 && setShowPopupChangeEmailSuccess(true);
  }, [changeEmailStep1]);

  useEffect(() => {
    if (userProfile && listCountries) {
      setLableCT({
        label: listCountries?.find((e) => e.id === userProfile?.country_id)?.country_name,
        value: userProfile?.country_id,
      });
    }
  }, [userProfile, listCountries, i18n.language]);

  useEffect(() => {
    if (userProfile) {
      setValue('country_id', userProfile?.country_id);
      setValue('country_name', userProfile.country_name);
      setValue('avatar', userProfile.avatar);
      setValue('bg_img', userProfile.bg_img);
      setValue('last_name', userProfile?.last_name);
      setValue('first_name', userProfile?.first_name);
      setValue('last_name_kana', userProfile?.last_name_kana);
      setValue('first_name_kana', userProfile?.first_name_kana);
      setValue('identify_img_front', userProfile?.identify_img_front);
      setValue('contract', userProfile?.contract);
      setValue('identify_img_back', userProfile?.identify_img_back);
      setValue('bank_code', userProfile?.bank_account?.bank_code || '');
      setValue('branch_code', userProfile?.bank_account?.branch_code || '');
      setValue('type', userProfile?.bank_account?.type || null);
      setValue('account_number', userProfile?.bank_account?.account_number || '');
      setValue('account_name', userProfile?.bank_account?.account_name || '');
      setValue('account_address', userProfile?.bank_account?.account_address || '');
      setValue('swift_code', userProfile?.bank_account?.swift_code || '');
      setValue('branch_name', userProfile?.bank_account?.branch_name || '');
      setValue('branch_address', userProfile?.bank_account?.branch_address || '');
      setValue('bank_name', userProfile?.bank_account?.bank_name || '');
      setValue('name_romaji', userProfile?.name_romaji || '');
      setValue('name_japanese', userProfile?.name_japanese || '');
      setValue('birthday', userProfile?.birthday || '');
      setValue('gender', userProfile?.gender);
      let phone = userProfile?.phone || '';

      const cleaned = ('' + phone).replace(/\D/g, '');
      const match10 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);

      if (match10) {
        phone = match10[1] + '-' + match10[2] + '-' + match10[3];
      }

      const match11 = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
      if (match11) {
        phone = match11[1] + '-' + match11[2] + '-' + match11[3];
      }

      const match12 = cleaned.match(/^(\d{4})(\d{4})(\d{4})$/);
      if (match12) {
        phone = match12[1] + '-' + match12[2] + '-' + match12[3];
      }
      setValue('phone', phone || '');

      let phoneAccount = userProfile?.bank_account?.account_phone || '';

      const cleaneds = ('' + phoneAccount).replace(/\D/g, '');
      const match10s = cleaneds.match(/^(\d{2})(\d{4})(\d{4})$/);

      if (match10s) {
        phoneAccount = match10s[1] + '-' + match10s[2] + '-' + match10s[3];
      }

      const match11s = cleaneds.match(/^(\d{3})(\d{4})(\d{4})$/);
      if (match11s) {
        phoneAccount = match11s[1] + '-' + match11s[2] + '-' + match11s[3];
      }

      const match12s = cleaneds.match(/^(\d{4})(\d{4})(\d{4})$/);
      if (match12s) {
        phoneAccount = match12s[1] + '-' + match12s[2] + '-' + match12s[3];
      }
      setValue('account_phone', phoneAccount || '');
    }
  }, [userProfile]);

  const watchFrontSideCard = watch('identify_img_front', undefined);
  const watchBackSideCard = watch('identify_img_back', undefined);
  const watchContract = watch('contract', undefined);
  const watchCountryId = watch('country_id');
  //const watchBankAddress = watch('branch_code');
  // const watchPhone = watch('phone');
  const watchBankBranch = watch('branch_code');
  const watchBankType = watch('type');
  const watchCover = watch('bg_img', undefined);
  const watchAvatar = watch('avatar', undefined);
  const watchBankLastName = watch('last_name');
  const watchBankFirstName = watch('first_name');
  const watchBankLastKanaName = watch('last_name_kana');
  const watchBankFirstKanaName = watch('first_name_kana');

  const watchBankName = watch('bank_name');
  const watchCode = watch('swift_code');
  const watchBankCode = watch('bank_code');
  const watchBranchName = watch('branch_name');
  const watchBranchAddress = watch('branch_address');
  const watchAddress = watch('account_address');
  const watchPhone = watch('account_phone');
  const watchBankAccountNumber = watch('account_number');
  const watchBankAccountName = watch('account_name');

  kanaOne.current = watch('last_name', '');
  kanaTwo.current = watch('first_name', '');
  // const watchPhone = watch('phone', '');
  const disableButton =
    !watchFrontSideCard ||
    //!watchBackSideCard ||
    !watchCountryId ||
    //!watchBankAddress ||
    !watchBankBranch ||
    // !watchBankType ||
    !watchBankAccountNumber ||
    !watchBankAccountName ||
    !watchBankLastName ||
    !watchBankFirstName ||
    !watchBankName ||
    !watchCode ||
    !watchBankCode ||
    !watchBranchName ||
    !watchBranchAddress ||
    !watchAddress ||
    !watchPhone ||
    !watchBankAccountName ||
    // !watchBankLastKanaName ||
    // !watchBankFirstKanaName ||
    !isEmptyObject(errors);

  const customStyleReactSelect = {
    ...customStyles,
    dropdownIndicator: (base) => ({
      ...base,
      display: 'block',
    }),
    singleValue: (provided, state) => {
      const color = state.isDisabled ? '#BEBEBE' : '#1A1A1A';
      return {
        ...provided,
        fontWeight: 500,
        fontSize: 14,
        position: 'absolute',
        padding: '2px 20px 2px 0',
        color: color,
      };
    },
    control: (base, state) => {
      return {
        ...base,
        width: '100%',
        svg: {
          color: '#666565',
          transform: state.menuIsOpen ? 'rotate(180deg)' : '',
        },
        borderRadius: '6px',
        boxShadow: 'none',
        borderColor: state.isFocused ? '#1480FF' : '#E5E6EB',
      };
    },
    option: (base, state) => {
      return {
        ...base,
        position: 'relative',
        backgroundColor: state.isSelected ? '#FFDBDB' : 'transparent',
        color: '#1A1A1A',
        '&.content-right': {
          color: '#DC143C',
        },
        svg: {
          color: '#DC143C',
          display: state.isSelected ? 'block' : 'none',
        },
        fontWeight: state.isSelected ? 500 : 400,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 19px 12px 16px',
        gap: '5px',
      };
    },
  };

  const optionListContent = useMemo(() => {
    return (
      listCountries?.map((item) => ({
        ...item,
        value: item?.id,
        label: item?.country_name,
      })) || []
    );
  }, [listCountries]);

  useEffect(() => {
    const language = i18n.language === 'jp' ? 'ja' : i18n.language;
    dispatch(getListCountry(language));
  }, [i18n.language]);

  const WithDropdownIndicator = () => {
    return <ArrowLeftIcon className={`${focusGender ? 'focus' : 'no-focus'}`} />;
  };

  const CREATOR_STATUS_ENUM_NEW = {
    1: i18n.t('User.status1'),
    2: i18n.t('User.status2'),
    3: i18n.t('User.status3'),
    4: i18n.t('User.status4'),
    5: i18n.t('User.status5'),
    7: i18n.t('User.status7'),
  };
  const toggleChangingForm = async () => {
    clearErrors();
    setIsChanging((prevState) => {
      return !prevState;
    });
  };
  const onCustomValidate = (values) => {
    return new Promise((resolve, reject) => {
      let isError = false;
      if (!values?.identify_img_front) {
        setError('identify_img_front', {
          type: 'required',
          message: t('ValidateMsg.required'),
        });
        isError = true;
      } else if (!values?.contract && userProfile?.status !== 1 && userProfile?.status !== 3) {
        setError('contract', {
          type: 'required',
          message: t('ValidateMsg.required'),
        });
        isError = true;
      } else if (!values?.bank_code) {
        setError('bank_code', {
          type: 'required',
          message: t('ValidateMsg.required'),
        });
        isError = true;
      } else if (!values?.branch_code) {
        setError('branch_code', {
          type: 'required',
          message: t('ValidateMsg.required'),
        });
        isError = true;
      } else if (!values?.account_number) {
        setError('account_number', {
          type: 'required',
          message: t('ValidateMsg.required'),
        });
        isError = true;
      } else if (!values?.account_name) {
        setError('account_name', {
          type: 'required',
          message: t('ValidateMsg.required'),
        });
        isError = true;
      } else if (!values?.last_name) {
        setError('last_name', {
          type: 'required',
          message: t('ValidateMsg.required'),
        });
        isError = true;
      } else if (!values?.first_name) {
        setError('first_name', {
          type: 'required',
          message: t('ValidateMsg.required'),
        });
        isError = true;
        // } else if (!values?.last_name_kana) {
        //   setError('last_name_kana', {
        //     type: 'required',
        //     message: t('ValidateMsg.required'),
        //   });
        //   isError = true;
        // } else if (!values?.first_name_kana) {
        //   setError('first_name_kana', {
        //     type: 'required',
        //     message: t('ValidateMsg.required'),
        //   });
        //   isError = true;
        // } else if (!values?.type) {
        //   setError('type', {
        //     type: 'required',
        //     message: t('ValidateMsg.required'),
        //   });
        //   isError = true;
      }
      // } else if (!values?.phone) {
      //   setError('phone', {
      //     type: 'required',
      //     message: t('ValidateMsg.required'),
      //   });
      //   isError = true;
      // }
      return isError === true ? reject(values) : resolve(values);
    });
  };
  const onSubmit = async (data) => {
    const avatarImage = avatarTmp || '';
    const coverImage = bgTmp || '';
    const result = await onCustomValidate(data);
    data = {
      ...data,
      type: data?.type ? data?.type : 1,
      bank_name: data?.bank_name.trim(),
      branch_name: data?.branch_name.trim(),
      branch_address: data?.branch_address.trim(),
      account_name: data?.account_name.trim(),
      account_address: data?.account_address.trim(),
      step: 2,
      phone: ('' + data?.phone).replace(/\D/g, ''),
      account_phone: ('' + data?.account_phone).replace(/\D/g, ''),
    };
    // if (watchPhone === userProfile?.phone) {
    //   delete data.phone
    // }
    if (result) {
      await setIsLoading(true);
      if (typeof watchFrontSideCard !== 'string') {
        const resultFront = await getPresignedUrlS3(
          'image',
          'uploads/output/images/identifier',
          setFileNameS3(watchFrontSideCard.name)
        );
        if (resultFront?.data) {
          dataS3Update = {
            ...dataS3Update,
            front_card: resultFront?.data?.file_path,
            front_card_pre_signed: resultFront?.data?.pre_signed,
            front_card_file: watchFrontSideCard,
          };
        }
      }
      if (
        watchBackSideCard !== undefined &&
        typeof watchBackSideCard !== 'string' &&
        watchBackSideCard !== null
      ) {
        const resultBack = await getPresignedUrlS3(
          'image',
          'uploads/output/images/identifier',
          setFileNameS3(watchBackSideCard.name)
        );
        if (resultBack?.data) {
          dataS3Update = {
            ...dataS3Update,
            back_card: resultBack?.data?.file_path,
            back_card_pre_signed: resultBack?.data?.pre_signed,
            back_card_file: watchBackSideCard,
          };
        }
      }

      // contract
      if (
        watchContract !== undefined &&
        typeof watchContract !== 'string' &&
        watchContract !== null
      ) {
        const resultContract = await getPresignedUrlS3(
          'docx',
          'contracts',
          setFileNameS3Contract(watchContract.name, userProfile?.account_id)
        );
        if (resultContract?.data) {
          dataS3Update = {
            ...dataS3Update,
            contract: resultContract?.data?.file_path,
            contract_pre_signed: resultContract?.data?.pre_signed,
            contract_file: watchContract,
          };
        }
      }

      //if (watchAvatar) {
      if (avatarImage) {
        const resultAvatar = await getPresignedUrlS3(
          'image',
          'uploads/output/images/creator/avatar',
          setFileNameS3(avatarImage.name)
        );
        if (resultAvatar?.data) {
          dataS3Update = {
            ...dataS3Update,
            avatar: resultAvatar?.data?.file_path,
            avatar_pre_signed: resultAvatar?.data?.pre_signed,
            avatar_file: avatarImage,
          };
        }
      }
      //}

      //if (watchCover) {
      if (coverImage) {
        const resultCover = await getPresignedUrlS3(
          'image',
          'uploads/output/images/creator/cover',
          setFileNameS3(coverImage.name)
        );
        if (resultCover?.data) {
          dataS3Update = {
            ...dataS3Update,
            bg_img: resultCover?.data?.file_path,
            bg_img_pre_signed: resultCover?.data?.pre_signed,
            bg_img_file: coverImage,
          };
        }
      }
      //}

      const {
        avatar,
        avatar_pre_signed,
        avatar_file,
        bg_img,
        bg_img_pre_signed,
        bg_img_file,
        front_card,
        front_card_pre_signed,
        front_card_file,
        back_card,
        back_card_pre_signed,
        back_card_file,
        contract,
        contract_pre_signed,
        contract_file,
      } = dataS3Update;

      if (avatar) {
        await uploadS3ByPresigned(avatar_pre_signed, avatar_file);
      }
      if (bg_img) {
        await uploadS3ByPresigned(bg_img_pre_signed, bg_img_file);
      }

      if (front_card) {
        await uploadS3ByPresigned(front_card_pre_signed, front_card_file);
      }

      if (back_card) {
        await uploadS3ByPresigned(back_card_pre_signed, back_card_file);
      }

      if (contract) {
        await uploadS3ByPresigned(contract_pre_signed, contract_file);
      }

      const contractTmp =
        dataS3Update.contract === undefined
          ? watchContract?.replace(/^.*\/contracts\//, 'contracts/')
          : dataS3Update.contract;
      if (!front_card && !back_card) {
        const avatarBG = {
          avatar: avatarTmp === null ? null : dataS3Update.avatar,
          bg_img: bgTmp === null ? null : dataS3Update.bg_img,
          contract: contractTmp,
        };
        await dispatch(editCreatorProfileIndividual({ ...data, ...avatarBG }));
      }
      if (front_card && !back_card) {
        const frontUpload = {
          identify_img_front: dataS3Update.front_card,
          contract: contractTmp,
          avatar: avatarTmp === null ? null : dataS3Update.avatar,
          bg_img: bgTmp === null ? null : dataS3Update.bg_img,
        };
        await dispatch(editCreatorProfileIndividual({ ...data, ...frontUpload }));
      }
      if (!front_card && back_card) {
        const backUpload = {
          identify_img_back: dataS3Update.back_card,
          contract: contractTmp,
          avatar: avatarTmp === null ? null : dataS3Update.avatar,
          bg_img: bgTmp === null ? null : dataS3Update.bg_img,
        };
        await dispatch(editCreatorProfileIndividual({ ...data, ...backUpload }));
      }
      if (front_card && back_card) {
        const cardUpload = {
          identify_img_front: dataS3Update.front_card,
          identify_img_back: dataS3Update.back_card,
          contract: contractTmp,
          avatar: avatarTmp === null ? null : dataS3Update.avatar,
          bg_img: bgTmp === null ? null : dataS3Update.bg_img,
        };
        await dispatch(editCreatorProfileIndividual({ ...data, ...cardUpload }));
      }
    }
  };

  const handleDownload = () => {
    setLoaddingDown(true);
    const url = `${getEnv('REACT_APP_CONTRACT_TEMPLATE')}`;
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {},
    })
      .then((response) => {
        let tmp = response?.config?.url;
        setLoaddingDown(false);
        document.querySelector('#root').style.overflow = 'auto';
        const url = window.URL.createObjectURL(response.data);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = url;
        a.download = tmp?.split('/').pop();
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        window.location.href = `/not-found`;
        throw Error(err);
      })
      .finally(() => {});
  };

  const MONEY_TYPE_NEW = [
    { value: 1, label: i18n.t('User.inforBank.ordinaryDeposit') },
    { value: 2, label: i18n.t('User.inforBank.currentAccount') },
  ];

  const handleBack = (status) => {
    if (location?.state?.redirectBlockRequest) {
      navigate(-1);
    } else {
      navigate(`${newPathUser}${userProfile?.account_id}`, {
        state: { pageProfile: 'changEmail' },
      });
      if (userProfile?.status === 3 || userProfile?.status === 4) {
        setShowPopupSuccess(false);
        setShowPopupApproveSuccess(false);
        setShowPopupLeave(false);
        setShowPopupChangeEmailSuccess(false);
      } else {
        navigate(`${newPathUser}${userProfile?.account_id}`, {
          state: { pageProfile: 'changEmail' },
        });
        setShowPopupSuccess(false);
        setShowPopupApproveSuccess(false);
        setShowPopupLeave(false);
        setShowPopupChangeEmailSuccess(false);
      }
      dispatch(getUserInfo());
    }
  };

  const handleChangeEmail = () => {
    dispatch(handleChangeEmailStep1());
  };

  const handleRequestLeave = () => {
    dispatch(requestLeave());
  };

  const listCountriesToSelectlabel = useMemo(() => {
    const listCountriesRaw = i18n.language === 'jp' ? LIST_COUNTRY_JA : LIST_COUNTRY_EN || [];
    return (
      listCountriesRaw.map((country) => ({
        label: country.country_name,
        value: country.id,
      })) || []
    );
  }, [i18n.language]);

  useEffect(() => {
    if (watchCountryId) {
      setValue('country_id', watchCountryId, {
        shouldTouch: true,
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [i18n.language]);

  const onCustomValidateAprrove = (values) => {
    return new Promise((resolve, reject) => {
      let isError = false;
      if (!values && userProfile?.status !== 1 && userProfile?.status !== 3) {
        setError('contract', {
          type: 'required',
          message: t('ValidateMsg.required'),
        });
        isError = true;
      }

      return isError === true ? reject(values) : resolve(values);
    });
  };

  const handleRequestApprove = async (type) => {
    setTypeRequestApprove(type);
    const result = await onCustomValidateAprrove(watchContract);
    if (result) {
      let dataApprove = {
        first_request: type === 1 ? 1 : 0,
        last_name: watchBankLastName,
        first_name: watchBankFirstName,
        last_name_kana: watchBankLastKanaName,
        first_name_kana: watchBankFirstKanaName,
      };
      await setIsLoading(true);
      if (typeof watchFrontSideCard !== 'string') {
        const resultFront = await getPresignedUrlS3(
          'image',
          'uploads/output/images/identifier',
          setFileNameS3(watchFrontSideCard.name)
        );
        if (resultFront?.data) {
          dataS3Update = {
            ...dataS3Update,
            front_card: resultFront?.data?.file_path,
            front_card_pre_signed: resultFront?.data?.pre_signed,
            front_card_file: watchFrontSideCard,
          };
        }
      }

      if (
        watchBackSideCard !== null &&
        watchBackSideCard !== undefined &&
        typeof watchBackSideCard !== 'string'
      ) {
        const resultBack = await getPresignedUrlS3(
          'image',
          'uploads/output/images/identifier',
          setFileNameS3(watchBackSideCard.name)
        );
        if (resultBack?.data) {
          dataS3Update = {
            ...dataS3Update,
            back_card: resultBack?.data?.file_path,
            back_card_pre_signed: resultBack?.data?.pre_signed,
            back_card_file: watchBackSideCard,
          };
        }
      }

      if (
        watchContract !== undefined &&
        typeof watchContract !== 'string' &&
        watchContract !== null
      ) {
        const resultContract = await getPresignedUrlS3(
          'docx',
          'contracts',
          setFileNameS3Contract(watchContract.name, userProfile?.account_id)
        );
        if (resultContract?.data) {
          dataS3Update = {
            ...dataS3Update,
            contract: resultContract?.data?.file_path,
            contract_pre_signed: resultContract?.data?.pre_signed,
            contract_file: watchContract,
          };
        }
      }

      const {
        front_card,
        front_card_pre_signed,
        front_card_file,
        back_card,
        back_card_pre_signed,
        back_card_file,
        contract,
        contract_pre_signed,
        contract_file,
      } = dataS3Update;

      if (front_card) {
        await uploadS3ByPresigned(front_card_pre_signed, front_card_file);
      }

      if (back_card) {
        await uploadS3ByPresigned(back_card_pre_signed, back_card_file);
      }

      if (contract) {
        await uploadS3ByPresigned(contract_pre_signed, contract_file);
      }
      const contractTmp =
        dataS3Update.contract === undefined
          ? watchContract?.replace(/^.*\/contracts\//, 'contracts/')
          : dataS3Update.contract;
      if (!front_card && !back_card) {
        dataApprove = {
          ...dataApprove,
          identify_name: 'abc',
          identify_img_front: userProfile?.identify_img_front,
          identify_img_back: userProfile?.identify_img_back,
          contract: contractTmp,
        };
        await dispatch(requestApprove(dataApprove));
      }
      if (front_card && !back_card) {
        dataApprove = {
          ...dataApprove,
          identify_name: 'abc',
          identify_img_front: dataS3Update.front_card,
          identify_img_back: userProfile?.identify_img_back,
          contract: contractTmp,
        };
        await dispatch(requestApprove(dataApprove));
      }
      if (!front_card && back_card) {
        dataApprove = {
          ...dataApprove,
          identify_name: 'abc',
          identify_img_front: userProfile?.identify_img_front,
          identify_img_back: dataS3Update.back_card,
          contract: contractTmp,
        };
        await dispatch(requestApprove(dataApprove));
      }
      if (front_card && back_card) {
        dataApprove = {
          ...dataApprove,
          identify_name: 'abc',
          identify_img_front: dataS3Update.front_card,
          identify_img_back: dataS3Update.back_card,
          contract: contractTmp,
        };
        await dispatch(requestApprove(dataApprove));
      }
    }
  };

  const convertToUppercase = () => {
    var input = document.getElementById('inputText');
    input.value = input.value.toUpperCase();
  };

  return (
    <React.Fragment>
      {/* {dataUser?.status === 2 && <PopupBlockUser />} */}
      {/* {(loading || isLoading) && <PopupLoading className={'popup-loading'} />} */}
      {loaddingDown && <PopupLoading className={'popup-loading'} />}
      {showPopupSuccess && (
        <PopupAlert
          header=''
          content={t('Popup.saved')}
          isVisiblePopup={() => setShowPopupSuccess(false)}>
          <button
            className='btn btn-danger'
            onClick={() => {
              if (location?.state?.redirectBlockRequest) {
                return navigate(-1);
              } else {
                dispatch(getUserInfoOthers(userProfile?.account_id));
                dispatch(getUserInfoMe());
                dispatch(resetIDHomePage());
                dispatch(resetDataInfoOther());
                return navigate(`${newPathUser}`);
              }
            }}>
            {t('Common.Ok')}
          </button>
        </PopupAlert>
      )}

      {showPopupLeave && (
        <PopupAlert
          header=''
          hiddenIcon
          content={t('Popup.requestLeaveConfirm')}
          isVisiblePopup={() => setShowPopupLeave(false)}>
          <button className='btn btn-danger' onClick={() => handleRequestLeave()}>
            {t('Common.buttonYes')}
          </button>
          <button className='btn border cancel' onClick={() => setShowPopupLeave(false)}>
            {t('Common.buttonNo')}
          </button>
        </PopupAlert>
      )}

      {showPopupApproveSuccess && (
        <PopupAlert
          header=''
          content={typeRequestApprove === 1 ? t('Popup.reviewRequest') : t('Popup.saved')}
          isVisiblePopup={() => setShowPopupApproveSuccess(false)}>
          <button className='btn btn-danger' onClick={() => handleBack()}>
            {t('Common.Ok')}
          </button>
        </PopupAlert>
      )}

      {showPopupChangeEmailSuccess && (
        <PopupAlert
          header=''
          //hiddenIcon
          content={t('Popup.changeMessToMail')}
          isVisiblePopup={() => setShowPopupChangeEmailSuccess(false)}>
          <button className='btn btn-danger' onClick={() => handleBack()}>
            {t('Common.Ok')}
          </button>
        </PopupAlert>
      )}

      {isLoading && <PopupLoading />}
      <LayoutCreatorRight
        titlePage={t('ProfileSetting.titleProfileInfo')}
        cb={() => {
          if (location?.state?.redirectBlockRequest) {
            return navigate(-1);
          } else {
            return navigate(`${newPathUser}`);
          }
        }}
        className='profile-info'>
        <div style={{ position: 'absolute', top: 0, left: 0 }} ref={ref} />
        <div className='position-relative bg-white creator-profile-info'>
          <ProfileBackgroundImage
            setBgTmp={setBgTmp}
            name='bg_img'
            maxSize={TEN_MB}
            isSelected={watchCover}
            defaultValue={userProfile?.bg_img || ''}
            setErrorBG={setErrorBG}
          />
          <div className={`${isMobile ? 'px-3' : 'px-4'}`}>
            <ProfileAvatar
              setAvatarTmp={setAvatarTmp}
              name='avatar'
              maxSize={TEN_MB}
              isSelected={watchAvatar}
              defaultValue={userProfile?.avatar || ''}
              setErrorAvar={setErrorAvar}
            />
          </div>
          <div className={`${isMobile ? 'px-3 pt-1' : 'px-4'}`}>
            <NavbarUserProfile
              defaultActive={2}
              resetPage={() => {
                //setPage(1);
                //loadingFunc();
              }}
              className='navbar-edit-profile'
            />
          </div>
          {loading && !isLoading ? (
            <div style={{ padding: '0px 16px' }}>
              <ShimmerPostDetails className='shimmer-loading' />
            </div>
          ) : (
            <>
              <form
                className=''
                encType='multipart/form-data'
                onSubmit={(e) => e.preventDefault()}>
                {isChanging === false ? (
                  <React.Fragment>
                    <div className=''>
                      <div className={`${isMobile ? 'px-3' : 'px-4'}`}>
                        <InputWrapper
                          className='infoPersonal'
                          label={t('ProfileSetting.personalInfor')}></InputWrapper>
                      </div>
                      <div className={`row ${isMobile ? 'px-3' : 'px-4'}`}>
                        <div className='col-12'>
                          <div className='block-field'>
                            <div className='box-field'>
                              <InputWrapper label={t('Common.birthday')}>
                                <div className='birthday'>
                                  <input
                                    {...register('birthday')}
                                    readOnly
                                    style={{ cursor: 'pointer' }}
                                    disabled
                                    className='fsite-input bg-grey'
                                  />
                                  <CalendarIcon />
                                </div>
                              </InputWrapper>
                            </div>
                            <div className='box-field'>
                              <InputWrapper
                                label={t('Common.gender')}
                                error={errors?.gender ? errors?.gender?.message : ''}>
                                <div className='gender'>
                                  <Controller
                                    name='gender'
                                    control={control}
                                    rules={{ required: t('ValidateMsg.genderRequired') }}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        options={GENDER}
                                        onFocus={() => setFocusGender(true)}
                                        onBlur={() => setFocusGender(false)}
                                        isSearchable={false}
                                        onChange={(val) => {
                                          field.onChange(val);
                                          setFocusGender(false);
                                        }}
                                        value={GENDER.find((c) => c.value === field.value)}
                                        placeholder={t('ValidateMsg.genderRequired')}
                                        styles={customStyleReactSelect}
                                        components={{
                                          Option: CustomOption,
                                          DropdownIndicator: () => <ArrowLeftIcon />,
                                        }}
                                        isDisabled
                                      />
                                    )}
                                  />
                                </div>
                              </InputWrapper>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`${isMobile ? 'px-3' : 'px-4'}`}>
                        <InputWrapper
                          id='fsite-email'
                          label={t('Common.email')}
                          error={errors?.email ? errors?.email?.message : ''}>
                          <input
                            placeholder={t('ValidateMsg.emailRequired')}
                            value={userProfile?.email}
                            className='fsite-input bg-grey'
                            disabled
                            readOnly
                          />
                          {userProfile?.status === 1 && (
                            <div className='text-start'>
                              <button
                                type='button'
                                className='btn text-white font-weight-500 mt-3 px-4 btn-email'
                                onClick={() => handleChangeEmail()}>
                                {t('User.butonChangeEmail')}
                              </button>
                            </div>
                          )}
                        </InputWrapper>
                      </div>
                      <div className={`row ${isMobile ? 'px-3' : 'px-4'}`}>
                        <div className='col-12'>
                          <div className='block-field'>
                            <div className='box-field'>
                              <InputWrapper
                                id='fsite-phone-number'
                                label={t('Common.phone')}
                                //required={true}
                                error={errors?.phone ? errors?.phone?.message : ''}>
                                <input
                                  type='text'
                                  className='fsite-input'
                                  placeholder='0XX-XXXX-XXXX・0X-XXXX-XXXX・0120-XXXX-XXXX'
                                  maxLength={14}
                                  {...register('phone', {
                                    //required: t('ValidateMsg.PHONE_IS_REQUIRED'),
                                    validate: (value) => {
                                      const cleaned = ('' + value).replace(/\D/g, '');
                                      const match10 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
                                      const match11 = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
                                      const match12 = cleaned.match(/^(\d{4})(\d{4})(\d{4})$/);

                                      if (value) {
                                        if (
                                          (!match10 && !match11 && !match12) ||
                                          (match12 && match12[1] !== '0120') ||
                                          cleaned.substring(0, 1) !== '0'
                                        ) {
                                          return 'ValidateMsg.C002_VALIDATE_PHONE_FORMAT';
                                        }
                                      }
                                    },
                                    onChange: (e) => {
                                      const value = e.target.value;
                                      const cleaned = ('' + value).replace(/\D/g, '');
                                      const match10 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);

                                      if (match10) {
                                        e.target.value =
                                          match10[1] + '-' + match10[2] + '-' + match10[3];
                                      }

                                      const match11 = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
                                      if (match11) {
                                        e.target.value =
                                          match11[1] + '-' + match11[2] + '-' + match11[3];
                                      }

                                      const match12 = cleaned.match(/^(\d{4})(\d{4})(\d{4})$/);
                                      if (match12) {
                                        if (match12[1] === '0120') {
                                          e.target.value =
                                            '0120-' + match12[2] + '-' + match12[3];
                                        } else {
                                          e.target.value =
                                            match12[1] + '-' + match12[2] + '-' + match12[3];
                                        }
                                      }
                                    },
                                  })}
                                />
                              </InputWrapper>
                            </div>
                            <div className='box-field'>
                              <InputWrapper
                                id='fsite-nation'
                                label={t('Common.country')}
                                error={errors?.country_id ? errors?.country_id?.message : ''}>
                                <div className='gender'>
                                  <Controller
                                    name='country_id'
                                    control={control}
                                    rules={{ required: t('ValidateMsg.required') }}
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        ref={field.ref}
                                        //options={mapCountriesToSelectlabel(listCountries)}
                                        value={optionListContent?.find(
                                          (e) => e.value === field?.value
                                        )}
                                        options={optionListContent}
                                        isLoading={listCountryLoading}
                                        onFocus={() => setFocusNation(true)}
                                        onBlur={() => setFocusNation(false)}
                                        onClick={() => setFocusNation(false)}
                                        onChange={(val) => {
                                          field.onChange(val.id);
                                          setFocusNation(false);
                                          setValue('country_name', val.label);
                                        }}
                                        placeholder={t('SignUp.placeHolderCountry')}
                                        className='nation-zindex'
                                        defaultValue={lableCT}
                                        styles={customStyleReactSelect}
                                        components={{
                                          Option: CustomOption,
                                          DropdownIndicator: () => <ArrowLeftIcon />,
                                        }}
                                        noOptionsMessage={() => t('Common.searchNoResult')}
                                      />
                                    )}
                                  />
                                  {/* <ArrowLeftIcon className={`${focusNation ? 'focus1' : 'no-focus1'}`} /> */}
                                </div>
                              </InputWrapper>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        id='block-request-information'
                        style={{
                          background: '#F2F3F5',
                          padding: `${isMobile ? '24px 16px' : '24px'}`,
                        }}>
                        <div className='row'>
                          <div
                            className='col-12'
                            style={{ padding: `${isMobile && '0 5px'}` }}>
                            <div className='block-field'>
                              <div className='box-field'>
                                <InputWrapper
                                  id='fsite-last_name'
                                  label={t('Common.lastName')}
                                  className='custom-input'
                                  required={true}
                                  error={errors?.last_name ? errors?.last_name?.message : ''}>
                                  <input
                                    {...register('last_name', {
                                      required: 'ValidateMsg.SURNAME_IS_REQUIRED',
                                      maxLength: {
                                        value: 20,
                                        message:
                                          'ValidateMsg.PROFILE_VALIDATE_SURNAME_MAXSIZE',
                                      },
                                      pattern: {
                                        value: /^[\p{L}\p{M}\s]+$/gu,
                                        message: 'ValidateMsg.PROFILE_VALIDATE_SURNAME_FORMAT',
                                      },
                                      validate: {
                                        noFirstSpacing: (value) =>
                                          (value &&
                                            patternNoFirstSpacing.test(
                                              value.toString().trim()
                                            )) ||
                                          'ValidateMsg.SURNAME_IS_REQUIRED',
                                      },
                                    })}
                                    onInput={(e) => {
                                      if (
                                        checkTextJP.test(e.target.value.trim()) &&
                                        !watchBankLastKanaName
                                      ) {
                                        setError('last_name_kana', {
                                          type: 'required',
                                          message: 'ValidateMsg.SURNAME_KANA_IS_REQUIRED',
                                        });
                                      } else {
                                        clearErrors('last_name_kana');
                                      }
                                    }}
                                    // onBlur={(e) => {
                                    //   if (checkTextJP.test(e.target.value.trim())) {
                                    //     setError('last_name_kana', {
                                    //       type: 'required',
                                    //       message: t(
                                    //         'ValidateMsg.C002_VALIDATE_NAME_JAPAN_FORMAT'
                                    //       ),
                                    //     });
                                    //   } else {
                                    //     clearErrors('last_name_kana');
                                    //   }
                                    // }}
                                    placeholder={t('Common.lastNamePlace')}
                                  />
                                </InputWrapper>
                              </div>
                              <div className='box-field'>
                                <InputWrapper
                                  id='fsite-japan-name'
                                  label={t('Common.firstName')}
                                  required={true}
                                  className='custom-input'
                                  error={
                                    errors?.first_name ? errors?.first_name?.message : ''
                                  }>
                                  <input
                                    {...register('first_name', {
                                      required: 'ValidateMsg.NAME_IS_REQUIRED',
                                      maxLength: {
                                        value: 20,
                                        message: 'ValidateMsg.PROFILE_VALIDATE_NAME_MAXSIZE',
                                      },
                                      pattern: {
                                        value: /^[\p{L}\p{M}\s]+$/gu,
                                        message: 'ValidateMsg.PROFILE_VALIDATE_NAME_FORMAT',
                                      },
                                      validate: {
                                        noFirstSpacing: (value) =>
                                          (value &&
                                            patternNoFirstSpacing.test(
                                              value.toString().trim()
                                            )) ||
                                          'ValidateMsg.NAME_IS_REQUIRED',
                                      },
                                    })}
                                    onInput={(e) => {
                                      if (
                                        checkTextJP.test(e.target.value.trim()) &&
                                        !watchBankFirstKanaName
                                      ) {
                                        setError('first_name_kana', {
                                          type: 'required',
                                          message: 'ValidateMsg.NAME_KANA_IS_REQUIRED',
                                        });
                                      } else {
                                        clearErrors('first_name_kana');
                                      }
                                    }}
                                    // onBlur={(e) => {
                                    //   if (checkTextJP.test(e.target.value.trim())) {
                                    //     setError('first_name_kana', {
                                    //       type: 'required',
                                    //       message: t(
                                    //         'ValidateMsg.C002_VALIDATE_NAME_JAPAN_FORMAT'
                                    //       ),
                                    //     });
                                    //   } else {
                                    //     clearErrors('first_name_kana');
                                    //   }
                                    // }}
                                    placeholder={t('Common.firstNamePlace')}
                                  />
                                </InputWrapper>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div
                            className='col-12'
                            style={{ padding: `${isMobile && '0 5px'}` }}>
                            <div className='block-field'>
                              <div className='box-field'>
                                <InputWrapper
                                  id='fsite-japan-name'
                                  label={t('Common.lastNameKana')}
                                  className='custom-input'
                                  error={
                                    errors?.last_name_kana
                                      ? errors?.last_name_kana?.message
                                      : ''
                                  }>
                                  <input
                                    {...register('last_name_kana', {
                                      maxLength: {
                                        value: 20,
                                        message:
                                          'ValidateMsg.PROFILE_VALIDATE_SURNAME_KANA_MAXSIZE',
                                      },
                                      pattern: {
                                        value: checkTextJPKana,
                                        message: 'ValidateMsg.SURNAME_KANA_IS_REQUIRED',
                                      },
                                    })}
                                    onBlur={(e) => {
                                      if (
                                        checkTextJP.test(watchBankLastName) &&
                                        !checkTextJPKana.test(e.target.value.trim())
                                      ) {
                                        setError('last_name_kana', {
                                          type: 'required',
                                          message: 'ValidateMsg.SURNAME_KANA_IS_REQUIRED',
                                        });
                                      } else {
                                        //clearErrors('last_name_kana');
                                      }
                                    }}
                                    onInput={(e) => {
                                      if (
                                        checkTextJP.test(watchBankLastName) &&
                                        !checkTextJPKana.test(e.target.value.trim())
                                      ) {
                                        setError('last_name_kana', {
                                          type: 'required',
                                          message: 'ValidateMsg.SURNAME_KANA_IS_REQUIRED',
                                        });
                                      } else {
                                        //clearErrors('last_name_kana');
                                      }
                                    }}
                                    placeholder={t('Common.lastNameKanaPlace')}
                                  />
                                </InputWrapper>
                              </div>
                              <div className='box-field'>
                                <InputWrapper
                                  id='fsite-first_name_kana'
                                  label={t('Common.firstNameKana')}
                                  className='custom-input'
                                  error={
                                    errors?.first_name_kana
                                      ? errors?.first_name_kana?.message
                                      : ''
                                  }>
                                  <input
                                    {...register('first_name_kana', {
                                      maxLength: {
                                        value: 20,
                                        message: t(
                                          'ValidateMsg.PROFILE_VALIDATE_NAME_KANA_MAXSIZE'
                                        ),
                                      },
                                      pattern: {
                                        value: checkTextJPKana,
                                        message: t('ValidateMsg.NAME_KANA_IS_REQUIRED'),
                                      },
                                    })}
                                    onBlur={(e) => {
                                      if (
                                        checkTextJP.test(watchBankFirstName) &&
                                        !checkTextJPKana.test(e.target.value.trim())
                                      ) {
                                        setError('first_name_kana', {
                                          type: 'required',
                                          message: 'ValidateMsg.NAME_KANA_IS_REQUIRED',
                                        });
                                      } else {
                                        //clearErrors('first_name_kana');
                                      }
                                    }}
                                    onInput={(e) => {
                                      if (
                                        checkTextJP.test(watchBankFirstName) &&
                                        !checkTextJPKana.test(e.target.value.trim())
                                      ) {
                                        setError('first_name_kana', {
                                          type: 'required',
                                          message: 'ValidateMsg.NAME_KANA_IS_REQUIRED',
                                        });
                                      } else {
                                        //clearErrors('first_name_kana');
                                      }
                                    }}
                                    placeholder={t('Common.firstNameKanaPlace')}
                                  />
                                </InputWrapper>
                              </div>
                            </div>
                          </div>
                        </div>
                        <>
                          <InputWrapper
                            id='fsite-identify-card'
                            label={t('SignUp.identification')}
                            required={true}
                            status={
                              userProfile?.status === 1 ||
                              userProfile?.status === 3 ||
                              userProfile?.status === 4
                            }
                            error={
                              errors?.identify_img_front
                                ? errors?.identify_img_front.message
                                : errors?.identify_img_back
                                ? errors?.identify_img_back.message
                                : ''
                            }>
                            <div className='row'>
                              <div className='col-sm-12 col-md-6'>
                                <DragNDrop
                                  isCMND
                                  widthBlue={`${isMobile ? 378 : 327}`}
                                  heightBlue={241}
                                  is_required={true}
                                  label={t('SignUp.surfaceSide')}
                                  name='identify_img_front'
                                  setValue={setValue}
                                  register={register}
                                  setError={setError}
                                  clearErrors={clearErrors}
                                  maxSize={TEN_MB}
                                  isSelected={watchFrontSideCard}
                                  //defaultValue={userProfile?.identify_img_front}
                                  accept={acceptedImageTypes.map((item) => item).join(',')}
                                />
                              </div>
                              <div className='col-sm-12 col-md-6'>
                                <DragNDrop
                                  widthBlue={`${isMobile ? 378 : 327}`}
                                  heightBlue={241}
                                  //is_required={true}
                                  label={t('SignUp.backSide')}
                                  name='identify_img_back'
                                  setValue={setValue}
                                  register={register}
                                  setError={setError}
                                  clearErrors={clearErrors}
                                  maxSize={TEN_MB}
                                  isSelected={watchBackSideCard}
                                  //defaultValue={userProfile?.identify_img_back}
                                  accept={acceptedImageTypes.map((item) => item).join(',')}
                                />
                              </div>
                            </div>
                          </InputWrapper>
                          <div style={{ color: '#666565', marginBottom: "25px" }}>
                            {t('ProfileSetting.descriptionIMG')}
                          </div>
                          <InputWrapper
                            contract
                            handleDownload={() => handleDownload()}
                            className='group-title'
                            label={t('ProfileSetting.contractPro')}
                            //status={contractOrigin?.contract_file}
                            required
                            error={errors?.contract ? errors?.contract?.message : ''}>
                            <p style={{ color: "#1a1a1a", textAlign: 'center' }}>{t('ProfileSetting.contractDes')}</p>
                            <DragNDrop
                              widthBlue={`${isMobile ? 378 : 327}`}
                              heightBlue={241}
                              label={t('SignUp.supportDOC')}
                              name='contract'
                              setValue={setValue}
                              setError={setError}
                              clearErrors={clearErrors}
                              maxSize={TEN_MB}
                              isPdf={true}
                              isContract={true}
                              isUpload={false}
                              accept={acceptedFilePDFContractTypes.map((item) => item).join(',')}
                            />
                          </InputWrapper>
                          {userProfile?.status === 7 && (
                            <InputWrapper>
                              <div className='d-flex justify-content-between align-items-center'>
                                <div className=''></div>
                                <button
                                  disabled={
                                    userProfile?.status === 1 ||
                                    !watchFrontSideCard ||
                                    !watchContract ||
                                    // !watchBackSideCard ||
                                    isLoading ||
                                    errors?.identify_img_front ||
                                    errors?.contract ||
                                    errors?.identify_img_back ||
                                    errors?.last_name ||
                                    errors?.first_name ||
                                    errors?.last_name_kana ||
                                    errors?.first_name_kana ||
                                    !watchBankLastName ||
                                    !watchBankFirstName
                                  }
                                  type='button'
                                  className='btn text-white font-weight-500 float-right px-4 btn-review'
                                  onClick={() => handleRequestApprove(1)}>
                                  {t('ProfileSetting.requestReview')}
                                </button>
                              </div>
                            </InputWrapper>
                          )}
                        </>
                      </div>
                      <div className={`pt-3 ${isMobile ? 'px-3' : 'px-4'}`}>
                        <InputWrapper label={t('ProfileSetting.examinationStatus')}>
                          <div className='d-flex justify-content-between align-items-center'>
                            <div
                              className={`detail-btn btn ${
                                CREATOR_STATUS_ENUM_COLOR[userProfile?.status]
                              }-status`}>
                              {CREATOR_STATUS_ENUM_NEW[userProfile?.status]}
                            </div>
                            {userProfile?.status === 4 && (
                              <button
                                type='button'
                                className='btn bg-red text-white font-weight-500 float-right px-4'
                                disabled={!watchFrontSideCard || isLoading}
                                onClick={() => handleRequestApprove(2)}>
                                {t('ProfileSetting.requestButton')}
                              </button>
                            )}
                          </div>
                        </InputWrapper>
                      </div>
                      <div className={`infoPersonal pt-3 ${isMobile ? 'px-3' : 'px-4'}`}>
                        <InputWrapper
                          required
                          label={t('ProfileSetting.transferInfo')}></InputWrapper>
                      </div>

                      <div className={`row ${isMobile ? 'px-3' : 'px-4'}`}>
                        <div className='col-12'>
                          <div className='block-field'>
                            <div className='box-field'>
                              <InputWrapper
                                id='fsite-bank_name'
                                label={t('User.inforBank.bankName')}
                                error={errors?.bank_name ? errors?.bank_name?.message : ''}>
                                <input
                                  type='text'
                                  className='fsite-input'
                                  placeholder={t('User.inforBank.bankName')}
                                  name='bank_name'
                                  maxLength={100}
                                  {...register('bank_name', {
                                    required: 'ValidateMsg.bankNameNewRequired',
                                    pattern: {
                                      value: /^[a-zA-Z0-9\x20.,'-]+$/,
                                      message: 'ProfileSetting.bankNameVali',
                                    },
                                  })}
                                />
                              </InputWrapper>
                            </div>
                            <div className='box-field'>
                              <InputWrapper
                                id='fsite-nation'
                                label={t('User.inforBank.depositType')}
                                error={errors?.type ? errors?.type?.message : ''}>
                                <div className='gender'>
                                  <Controller
                                    name='type'
                                    control={control}
                                    render={({ field }) => (
                                      <Select
                                        options={MONEY_TYPE_NEW}
                                        onChange={(val) => {
                                          field.onChange(val.value);
                                          setFocusDeposit(false);
                                          setValue('type', val.value);
                                        }}
                                        value={MONEY_TYPE_NEW?.find(
                                          (e) => e.value === field.value
                                        )}
                                        onFocus={() => setFocusDeposit(true)}
                                        onBlur={() => setFocusDeposit(false)}
                                        onClick={() => setFocusDeposit(false)}
                                        defaultValue={{
                                          label:
                                            MONEY_TYPE_NEW.find(
                                              (option) =>
                                                option.value ===
                                                userProfile?.bank_account?.type
                                            )?.label || MONEY_TYPE_NEW[0].label,
                                          value:
                                            userProfile?.bank_account?.type ||
                                            MONEY_TYPE_NEW[0].value,
                                        }}
                                        placeholder={t('ProfileSetting.messageDepositType')}
                                        styles={customStyleReactSelect}
                                        components={{
                                          Option: CustomOption,
                                          DropdownIndicator: () => <ArrowLeftIcon />,
                                        }}
                                        noOptionsMessage={() => t('Common.searchNoResult')}
                                      />
                                    )}
                                  />
                                </div>
                              </InputWrapper>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={`row ${isMobile ? 'px-3' : 'px-4'}`}>
                        <div className='col-12'>
                          <div className='block-field'>
                            <div className='box-field'>
                              <InputWrapper
                                id='fsite-swift_code'
                                label={t('User.inforBank.swiftBank')}
                                error={errors?.swift_code ? errors?.swift_code?.message : ''}>
                                <input
                                  type='text'
                                  className='fsite-input'
                                  placeholder='BOTKJPJTXXX'
                                  name='swift_code'
                                  maxLength={11}
                                  {...register('swift_code', {
                                    required: 'ValidateMsg.swiftBankRequired',
                                    pattern: {
                                      value: /^[a-zA-Z0-9]+$/,
                                      message: 'ProfileSetting.swiftBankValid',
                                    },
                                    onChange: (e) => {
                                      const val = e.target.value;
                                      setValue('swift_code', val.toUpperCase());
                                    },
                                  })}
                                />
                              </InputWrapper>
                            </div>
                            <div className='box-field'>
                              <InputWrapper
                                id='fsite-bank-code'
                                label={t('User.inforBank.bankCode')}
                                error={errors?.bank_code ? errors?.bank_code?.message : ''}>
                                <input
                                  type='text'
                                  className='fsite-input'
                                  placeholder='0000'
                                  name='bank_code'
                                  maxLength={4}
                                  {...register('bank_code', {
                                    required: 'ValidateMsg.bankNameRequired',
                                    pattern: {
                                      value: /^[0-9]{4}$/,
                                      message: 'ProfileSetting.placeHolderbankName',
                                    },
                                  })}
                                />
                              </InputWrapper>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={`row ${isMobile ? 'px-3' : 'px-4'}`}>
                        <div className='col-12'>
                          <div className='block-field'>
                            <div className='box-field'>
                              <InputWrapper
                                id='fsite-bank-branch_name'
                                label={t('User.inforBank.branchName')}
                                error={
                                  errors?.branch_name ? errors?.branch_name?.message : ''
                                }>
                                <input
                                  type='text'
                                  className='fsite-input'
                                  placeholder={t('User.inforBank.branchName')}
                                  maxLength={100}
                                  name='branch_name'
                                  {...register('branch_name', {
                                    required: 'ValidateMsg.branchNameRequired',
                                    pattern: {
                                      value: /^[a-zA-Z0-9\x20.,'-]+$/,
                                      message: 'ProfileSetting.branchNameVali',
                                    },
                                  })}
                                />
                              </InputWrapper>
                            </div>
                            <div className='box-field'>
                              <InputWrapper
                                id='fsite-bank-branch_code'
                                label={t('User.inforBank.branchNumber')}
                                error={
                                  errors?.branch_code ? errors?.branch_code?.message : ''
                                }>
                                <input
                                  type='text'
                                  className='fsite-input'
                                  placeholder='000'
                                  maxLength={3}
                                  name='branch_code'
                                  {...register('branch_code', {
                                    required: 'ValidateMsg.bankBranchRequired',
                                    pattern: {
                                      value: /^[0-9]{3}$/,
                                      message: 'ProfileSetting.placeHolderbankBranch',
                                    },
                                  })}
                                />
                              </InputWrapper>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={`row ${isMobile ? 'px-3' : 'px-4'}`}>
                        <div className='col-12'>
                          <div className='block-field'>
                            <div className='box-field'>
                              <InputWrapper
                                id='fsite-branch_address'
                                label={t('User.inforBank.branchAddress')}
                                error={
                                  errors?.branch_address ? errors?.branch_address?.message : ''
                                }>
                                <input
                                  type='text'
                                  className='fsite-input'
                                  placeholder={t('User.inforBank.branchAddress')}
                                  name='branch_address'
                                  maxLength={255}
                                  {...register('branch_address', {
                                    required: 'ValidateMsg.branchAddressRequired',
                                    pattern: {
                                      value: /^[a-zA-Z0-9\x20/".,'()\[\]-]+$/,
                                      message: 'ProfileSetting.branchAddressVali',
                                    },
                                  })}
                                />
                              </InputWrapper>
                            </div>
                            <div className='box-field'></div>
                          </div>
                        </div>
                      </div>

                      <div className={`row ${isMobile ? 'px-3' : 'px-4'}`}>
                        <div className='col-12'>
                          <div className='block-field'>
                            <div className='box-field'>
                              <InputWrapper
                                id='fsite-account_number'
                                label={t('User.inforBank.accountNumber')}
                                error={
                                  errors?.account_number ? errors?.account_number?.message : ''
                                }>
                                <input
                                  type='text'
                                  className='fsite-input'
                                  placeholder={t('User.inforBank.accountNumber')}
                                  name='account_number'
                                  maxLength={45}
                                  {...register('account_number', {
                                    required: 'ValidateMsg.accountNumberRequired',
                                    pattern: {
                                      value: /^[0-9]+$/,
                                      message: 'ProfileSetting.correctAccountNumber',
                                    },
                                  })}
                                />
                              </InputWrapper>
                            </div>
                            <div className='box-field'>
                              <InputWrapper
                                id='fsite-account_name'
                                label={t('User.inforBank.accountName')}
                                error={
                                  errors?.account_name ? errors?.account_name?.message : ''
                                }>
                                <input
                                  type='text'
                                  className='fsite-input'
                                  placeholder={t('User.inforBank.accountName')}
                                  name='account_name'
                                  maxLength={45}
                                  {...register('account_name', {
                                    required: 'ValidateMsg.accountNameRequired',
                                    pattern: {
                                      value: /^[A-Za-z\x20]+$/gu,
                                      message: 'ProfileSetting.correctAccountName',
                                    },
                                  })}
                                  // onBlur={(e) => {
                                  //   setValue('account_name', e.target.value.trim());
                                  //   if (!e.target.value.trim()) {
                                  //     setError('account_name', {
                                  //       type: 'required',
                                  //       message: 'ValidateMsg.accountNameRequired',
                                  //     });
                                  //   }
                                  // }}
                                />
                              </InputWrapper>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`row ${isMobile ? 'px-3' : 'px-4'}`}>
                        <div className='col-12'>
                          <div className='block-field'>
                            <div className='box-field'>
                              <InputWrapper
                                id='fsite-account_address'
                                label={t('User.inforBank.accountAddress')}
                                error={
                                  errors?.account_address
                                    ? errors?.account_address?.message
                                    : ''
                                }>
                                <input
                                  type='text'
                                  className='fsite-input'
                                  placeholder={t('User.inforBank.accountAddress')}
                                  name='account_address'
                                  maxLength={255}
                                  {...register('account_address', {
                                    required: 'ValidateMsg.bankAdressRequired',
                                    pattern: {
                                      value: /^[a-zA-Z0-9\x20/".,'()\[\]-]+$/,
                                      message: 'ProfileSetting.accountAddressVali',
                                    },
                                  })}
                                />
                              </InputWrapper>
                            </div>
                            <div className='box-field'>
                              <InputWrapper
                                id='fsite-bank-account_phone'
                                label={t('User.inforBank.accountPhone')}
                                error={
                                  errors?.account_phone ? errors?.account_phone?.message : ''
                                }>
                                <input
                                  type='text'
                                  className='fsite-input'
                                  placeholder='0XX-XXXX-XXXX・0X-XXXX-XXXX・0120-XXXX-XXXX'
                                  maxLength={14}
                                  name='account_phone'
                                  {...register('account_phone', {
                                    required: 'ValidateMsg.phoneRequired',
                                    validate: (value) => {
                                      const cleaned = ('' + value).replace(/\D/g, '');
                                      const match10 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
                                      const match11 = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
                                      const match12 = cleaned.match(/^(\d{4})(\d{4})(\d{4})$/);

                                      if (
                                        (!match10 && !match11 && !match12) ||
                                        (match12 && match12[1] !== '0120') ||
                                        cleaned.substring(0, 1) !== '0'
                                      ) {
                                        return t('ValidateMsg.C002_VALIDATE_PHONE_FORMAT');
                                      }
                                    },
                                    onChange: (e) => {
                                      const value = e.target.value;
                                      const cleaned = ('' + value).replace(/\D/g, '');
                                      const match10 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);

                                      if (match10) {
                                        e.target.value =
                                          match10[1] + '-' + match10[2] + '-' + match10[3];
                                      }

                                      const match11 = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
                                      if (match11) {
                                        e.target.value =
                                          match11[1] + '-' + match11[2] + '-' + match11[3];
                                      }

                                      const match12 = cleaned.match(/^(\d{4})(\d{4})(\d{4})$/);
                                      if (match12) {
                                        if (match12[1] === '0120') {
                                          e.target.value =
                                            '0120-' + match12[2] + '-' + match12[3];
                                        } else {
                                          e.target.value =
                                            match12[1] + '-' + match12[2] + '-' + match12[3];
                                        }
                                      }
                                    },
                                  })}
                                />
                              </InputWrapper>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`${isMobile ? 'px-3 ps-bottom-moblie' : 'px-4 ps-bottom'} ${
                          isTablet && 'ps-bottom-tablet'
                        }`}>
                        <InputWrapper>
                          <div className='row wrapper__btn-comfirm'>
                            <div className='col-12 col-sm-6 col-md-6 mb-3 btn_keep'>
                              <button
                                className={`btn bg-red w-100 font-weight-700 text-white app-btn ${
                                  !isMobile && 'mr-top'
                                }`}
                                type='submit'
                                disabled={
                                  isLoading ||
                                  disableButton ||
                                  errorBG !== '' ||
                                  errorAvar !== ''
                                }
                                onClick={handleSubmit(onSubmit)}>
                                {t('Common.keep')}
                              </button>
                            </div>
                            <div className='col-12 col-sm-6 col-md-6 mb-3 btn_return'>
                              <button
                                // disabled={
                                //   userProfile?.status === 3 || userProfile?.status === 4
                                // }
                                className={`btn w-100 font-weight-700 border app-btn bg-white bg-while ${
                                  !isMobile && 'mr-top'
                                }`}
                                onClick={() => handleBack()}>
                                {t('Common.return')}
                              </button>
                            </div>
                          </div>
                        </InputWrapper>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div
                      className='p-4 creator-request-leave'
                      ref={changingFormRef}
                      style={{ minHeight: 'calc(100vh - 135px)' }}>
                      <p className='mb-0'> {t('User.requestLeave.text1')}</p>
                      <ul>
                        <li>{t('Creator.User.requestLeave.text2')}</li>
                        <li>{t('Creator.User.requestLeave.text3')}</li>
                        <li>{t('Creator.User.requestLeave.text4')}</li>
                        <li>{t('Creator.User.requestLeave.text5')}</li>
                      </ul>

                      <p className='text-center text-confirm'>
                        {t('Creator.User.requestLeave.text6')}
                      </p>
                      <InputWrapper>
                        <div className='row'>
                          <div className='col-12 col-sm-6 col-md-6 mb-3'>
                            <button
                              className='btn bg-red w-100 font-weight-700 text-white app-btn'
                              type='button'
                              disabled={loading}
                              onClick={() => setShowPopupLeave(true)}>
                              {t('Common.buttonLeave')}
                            </button>
                          </div>
                          <div className='col-12 col-sm-6 col-md-6 mb-3'>
                            <button
                              className='btn w-100 font-weight-700 border app-btn bg-white bg-while'
                              onClick={toggleChangingForm}>
                              {t('Common.buttonCancel')}
                            </button>
                          </div>
                        </div>
                      </InputWrapper>
                    </div>
                  </React.Fragment>
                )}
              </form>
            </>
          )}
        </div>
      </LayoutCreatorRight>
    </React.Fragment>
  );
}

export default withCreatorRole(withCreatorProfilePage(ProfileInfo));
