import Icon from '@ant-design/icons';

const AddSurveyIconV2 = (props) => (
  <Icon
    component={() => (
      <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M8.25 8.25H13.75'
          stroke='currentColor'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.25 11H13.75'
          stroke='currentColor'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.25 13.75H11'
          stroke='currentColor'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.4652 18.5625H4.125C3.94266 18.5625 3.7678 18.4901 3.63886 18.3611C3.50993 18.2322 3.4375 18.0573 3.4375 17.875V4.125C3.4375 3.94266 3.50993 3.7678 3.63886 3.63886C3.7678 3.50993 3.94266 3.4375 4.125 3.4375H17.875C18.0573 3.4375 18.2322 3.50993 18.3611 3.63886C18.4901 3.7678 18.5625 3.94266 18.5625 4.125V13.4652C18.5625 13.5555 18.5447 13.6449 18.5102 13.7283C18.4756 13.8117 18.425 13.8875 18.3611 13.9514L13.9514 18.3611C13.8875 18.425 13.8117 18.4756 13.7283 18.5102C13.6449 18.5447 13.5555 18.5625 13.4652 18.5625V18.5625Z'
          stroke='currentColor'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18.5004 13.75H13.75V18.5006'
          stroke='currentColor'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default AddSurveyIconV2;
