import Icon from '@ant-design/icons';

const CoinRankIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={21}
        height={20}
        viewBox='0 0 21 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M10.0007 20C4.48471 20 0 15.5156 0 10C0 4.48438 4.48471 0 10.0007 0C15.5168 0 20.0015 4.48438 20.0015 10C20.0015 15.5156 15.5168 20 10.0007 20ZM10.0007 1.17188C5.1332 1.17188 1.17196 5.13281 1.17196 10C1.17196 14.8672 5.1332 18.8281 10.0007 18.8281C14.8683 18.8281 18.8295 14.8672 18.8295 10C18.8295 5.13281 14.8683 1.17188 10.0007 1.17188Z'
          fill='#EFAA33'
        />
        <path
          d='M10.0025 17.6572C5.77955 17.6572 2.3457 14.2236 2.3457 10.001C2.3457 5.77832 5.77955 2.34473 10.0025 2.34473C14.2255 2.34473 17.6593 5.77832 17.6593 10.001C17.6593 14.2236 14.2255 17.6572 10.0025 17.6572ZM10.0025 3.5166C6.42804 3.5166 3.51767 6.42676 3.51767 10.001C3.51767 13.5752 6.42804 16.4854 10.0025 16.4854C13.577 16.4854 16.4874 13.5752 16.4874 10.001C16.4874 6.42676 13.577 3.5166 10.0025 3.5166Z'
          fill='#EFAA33'
        />
        <path
          d='M12.8609 12.9922H7.12997C6.86823 12.9922 6.63774 12.8164 6.56742 12.5664L5.48531 8.74609C5.42671 8.54297 5.48531 8.32422 5.63376 8.17578C5.78221 8.02734 6.00098 7.96875 6.20412 8.02344L7.97769 8.50781L9.54421 6.48437C9.64969 6.33594 9.82157 6.25391 10.0013 6.25391C10.181 6.25391 10.3529 6.33594 10.4662 6.48047L12.0327 8.50391L13.8062 8.01953C14.0094 7.96484 14.2282 8.02344 14.3766 8.17187C14.5251 8.32422 14.5797 8.54297 14.5251 8.74609L13.4312 12.5664C13.3531 12.8164 13.1226 12.9922 12.8609 12.9922ZM7.57141 11.8203H12.4194L13.107 9.42578L11.9624 9.73828C11.7319 9.80078 11.4897 9.71875 11.3451 9.53125L10.0013 7.79687L8.66133 9.53125C8.51679 9.71875 8.27068 9.80078 8.0441 9.73828L6.89557 9.42187L7.57141 11.8203Z'
          fill='#EFAA33'
        />
      </svg>
    )}
    {...props}
  />
);

export default CoinRankIcon;
