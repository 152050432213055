import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Step1Tiger from './step1';
import { WrapperLayoutTigerStyled } from './styled';
import { useLocation, useNavigate } from 'react-router-dom';
import Step2Tiger from './step2';
import { pushNotify } from 'components/Notification';
import { useDispatch } from 'react-redux';
import { applyVoucher, resetAllPayment } from 'store/actions/payment';

const PaymentTiger = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);

  const [dataVendo] = useState(location?.state?.dataVendo || null);
  const [dataVoucher, setDataVoucher] = useState(null);
  const [searchVoucher, setSearchVoucher] = useState('');

  useEffect(() => {
    return () => {
      dispatch(resetAllPayment());
    };
  }, []);

  const priceVoucher = useMemo(() => {
    if (dataVoucher?.bonus && dataVendo?.point) {
      return (+dataVoucher?.bonus * +dataVendo?.point) / 100;
    }
    return 0;
  }, [dataVoucher?.bonus, dataVendo?.point]);

  const renderTitlePage = useMemo(() => {
    if (step === 1 || !step) {
      return `Purchase.payment.titlePaymentDetail`;
    } else {
      return `Purchase.payment.title`;
    }
  }, [step]);

  const handleCheckApplyVoucher = (data) => {
    if (data?.code) {
      setDataVoucher(data);
      setSearchVoucher('');
      return pushNotify('success', `${t('Purchase.payment.vendo.checkVoucherSuccess')}`);
    } else {
      return pushNotify('error', `${t(data?.message)}`);
    }
  };

  const handleApplyVoucher = () => {
    if (searchVoucher?.trim()) {
      dispatch(applyVoucher(searchVoucher?.trim(), handleCheckApplyVoucher));
    }
  };
  return (
    <>
      <WrapperLayoutTigerStyled
        titlePage={t(renderTitlePage)}
        cb={() => {
          return navigate(-1, {
            state: { ...location.state },
          });
        }}
        className='payment-tiger-header'>
        <div className='wrapper-tiger-payment'>
          {step === 1 || !step ? (
            <Step1Tiger
              dataVendo={dataVendo}
              dataVoucher={dataVoucher}
              searchVoucher={searchVoucher}
              priceVoucher={priceVoucher}
              setDataVoucher={setDataVoucher}
              setSearchVoucher={setSearchVoucher}
              setStep={setStep}
              handleApplyVoucher={handleApplyVoucher}
            />
          ) : (
            <Step2Tiger
              priceVoucher={priceVoucher}
              setStep={setStep}
              dataVoucher={dataVoucher}
              codePayment={location?.state?.codePayment}
            />
          )}
        </div>
      </WrapperLayoutTigerStyled>
    </>
  );
};

export default PaymentTiger;
