export default function SurveyIconAnswered(props) {
  return (
    <svg
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <rect
        x='1'
        y='1'
        width='58'
        height='58'
        rx='4'
        fill='#F7FFF7'
        stroke='#52C41A'
        strokeWidth='2'
      />
      <path
        d='M40.164 18.3236H37.1785V16.988C37.1785 16.5559 36.7071 16.3595 36.275 16.3595H33.9574C33.4074 14.7882 32.0325 14.0025 30.4612 14.0025C28.9072 13.9441 27.4937 14.897 26.965 16.3595H24.6867C24.2545 16.3595 23.8224 16.5559 23.8224 16.988V18.3236H20.8369C19.0677 18.3425 17.6203 19.7381 17.5371 21.5055V44.0144C17.5371 45.7429 19.1084 46.9999 20.8369 46.9999H40.164C41.8924 46.9999 43.4637 45.7429 43.4637 44.0144V21.5056C43.3806 19.7381 41.9332 18.3425 40.164 18.3236ZM25.3937 17.9308H27.5542C27.9314 17.8848 28.2343 17.5979 28.3006 17.2237C28.5333 16.2106 29.4221 15.4834 30.4612 15.456C31.4907 15.4872 32.3661 16.2167 32.5825 17.2237C32.6529 17.6108 32.9758 17.9014 33.3681 17.9308H35.6073V21.0734H25.3937V17.9308ZM41.8924 44.0145C41.8924 44.8787 41.0282 45.4287 40.164 45.4287H20.8369C19.9726 45.4287 19.1084 44.8787 19.1084 44.0145V21.5056C19.1886 20.606 19.9339 19.9115 20.8369 19.895H23.8224V21.8984C23.8639 22.3386 24.2451 22.6678 24.6866 22.6448H36.2749C36.7246 22.6694 37.1177 22.3447 37.1784 21.8984V19.8949H40.1639C41.0668 19.9115 41.8122 20.6059 41.8923 21.5055V44.0145H41.8924Z'
        fill='#52C41A'
      />
      <path
        d='M27.4755 31.5652C27.1808 31.2546 26.6917 31.2371 26.3756 31.526L23.8614 33.9222L22.8008 32.8223C22.5062 32.5117 22.017 32.4942 21.7009 32.783C21.3966 33.1018 21.3966 33.6034 21.7009 33.9222L23.3114 35.5721C23.4508 35.7282 23.6522 35.8145 23.8614 35.8078C24.0686 35.8049 24.2663 35.7201 24.4113 35.5721L27.4754 32.6652C27.7791 32.3865 27.7994 31.9144 27.5207 31.6107C27.5064 31.5948 27.4913 31.5797 27.4755 31.5652Z'
        fill='#52C41A'
      />
      <path
        d='M38.7503 33.25H29.7153C29.2814 33.25 28.9297 33.6017 28.9297 34.0357C28.9297 34.4696 29.2814 34.8213 29.7153 34.8213H38.7503C39.1843 34.8213 39.536 34.4696 39.536 34.0357C39.536 33.6017 39.1843 33.25 38.7503 33.25Z'
        fill='#52C41A'
      />
      <path
        d='M27.4755 25.2762C27.1808 24.9656 26.6917 24.9481 26.3756 25.2369L23.8614 27.6332L22.8008 26.5332C22.5062 26.2226 22.017 26.2051 21.7009 26.494C21.3966 26.8128 21.3966 27.3143 21.7009 27.6332L23.3114 29.283C23.4508 29.4391 23.6522 29.5254 23.8614 29.5188C24.0686 29.5158 24.2663 29.4311 24.4113 29.283L27.4754 26.3761C27.7791 26.0975 27.7994 25.6253 27.5207 25.3216C27.5064 25.3057 27.4913 25.2906 27.4755 25.2762Z'
        fill='#52C41A'
      />
      <path
        d='M38.7503 26.9688H29.7153C29.2814 26.9688 28.9297 27.3205 28.9297 27.7544C28.9297 28.1883 29.2814 28.5401 29.7153 28.5401H38.7503C39.1843 28.5401 39.536 28.1883 39.536 27.7544C39.536 27.3205 39.1843 26.9688 38.7503 26.9688Z'
        fill='#52C41A'
      />
      <path
        d='M27.4755 37.8465C27.1808 37.5359 26.6917 37.5184 26.3756 37.8072L23.8614 40.2034L22.8008 39.1035C22.5062 38.7929 22.017 38.7754 21.7009 39.0642C21.3966 39.383 21.3966 39.8846 21.7009 40.2034L23.3114 41.8533C23.4508 42.0094 23.6522 42.0957 23.8614 42.089C24.0686 42.0861 24.2663 42.0014 24.4113 41.8533L27.4754 38.9464C27.7791 38.6678 27.7994 38.1956 27.5207 37.8919C27.5064 37.8761 27.4913 37.861 27.4755 37.8465Z'
        fill='#52C41A'
      />
      <path
        d='M38.7503 39.5391H29.7153C29.2814 39.5391 28.9297 39.8908 28.9297 40.3247C28.9297 40.7586 29.2814 41.1104 29.7153 41.1104H38.7503C39.1843 41.1104 39.536 40.7586 39.536 40.3247C39.536 39.8908 39.1843 39.5391 38.7503 39.5391Z'
        fill='#52C41A'
      />
    </svg>
  );
}
