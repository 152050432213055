export default function SuccessRegister(props) {
  return (
    <svg
      width='80'
      height='80'
      viewBox='0 0 80 80'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle cx='40' cy='40' r='40' fill='#F7F8FA' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M66 52.47C66 57.9283 61.5762 62.3524 56.1176 62.3524C51.1388 62.3524 47.0202 58.6714 46.3353 53.8818H19.4118C18.6321 53.8818 18 53.2498 18 52.47V27.0583C18 26.2785 18.6321 25.6465 19.4118 25.6465H57.5294C58.3091 25.6465 58.9412 26.2785 58.9412 27.0583V42.9968C63.0234 44.2115 66 47.9927 66 52.47ZM49.0588 52.47C49.0588 56.3692 52.2188 59.5289 56.1176 59.5289C60.0168 59.5289 63.1764 56.3689 63.1764 52.47C63.1764 48.5709 60.0165 45.4112 56.1176 45.4112C52.2186 45.4112 49.0588 48.5712 49.0588 52.47ZM56.1176 28.47H20.8236V29.8972L38.4706 43.6227L56.1176 29.8972V28.47ZM46.3354 51.0583C46.6533 48.8355 47.7106 46.8514 49.2504 45.3632L45.1614 41.9958L39.3374 46.5256C39.0896 46.7184 38.7846 46.823 38.4707 46.823C38.1568 46.823 37.8518 46.7184 37.604 46.5256L31.7799 41.9958L20.8236 51.0186V51.0583H46.3354ZM20.8236 33.4742V47.3609L29.4956 40.2192L20.8236 33.4742ZM56.1176 42.5877V33.4742L47.4456 40.2192L51.6282 43.6637C52.9752 42.9757 54.5011 42.5877 56.1176 42.5877ZM54.2924 51.4718L55.4118 52.5912L57.9429 50.06C58.4943 49.5087 59.3882 49.5087 59.9394 50.06C60.4908 50.6114 60.4908 51.5053 59.9394 52.0565L56.41 55.5859C55.8587 56.1373 54.9648 56.1373 54.4135 55.5859L52.2959 53.4683C51.7446 52.917 51.7446 52.023 52.2959 51.4718C52.8472 50.9205 53.7411 50.9205 54.2924 51.4718Z'
        fill='#DC143C'
      />
    </svg>
  );
}
