import { useWasIt } from "../../hooks/v2/useWasIt";
import { useRef } from "react";
import { useIntersection } from "react-use";

export function LazyAware({
  render,
  rootMargin = "1000px",
}) {
  const ref = useRef(null);
  const intersection = useIntersection(ref, {
    root: null,
    rootMargin,
    threshold: 0.5,
  });

  const isIntersecting = !!intersection?.isIntersecting;
  const wasIntersected = useWasIt(intersection?.isIntersecting, true);

  return <>{render({ ref, isIntersecting, wasIntersected })}</>;
}
