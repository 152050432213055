import { PopupAlert, PopupLoading } from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import { newPathUser } from 'constants/layout/constant';
import withCreatorRole from 'hocs/withCreatorRole';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { handleChangeEmailConfirmStep1, handleChangeEmailStep2 } from 'store/actions/users';
import '../../sign-up/index.scss';
import BackHeader from '../back-header';
import { patternEmail, patternFullWidthJp } from 'utils';

function ProfileUpdateEmail() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [showPopupChangeEmailSuccess, setShowPopupChangeEmailSuccess] = useState(false);
  const { t } = useTranslation();
  const { loading, changeEmailStep2, changeEmailConfim1Failed } = useSelector(
    (state) => state.users
  );
  const dispatch = useDispatch();
  useEffect(() => {
    token && dispatch(handleChangeEmailConfirmStep1(token));
  }, [token]);

  useEffect(() => {
    changeEmailStep2 && setShowPopupChangeEmailSuccess(true);
  }, [changeEmailStep2]);

  useEffect(() => {
    changeEmailConfim1Failed && setTimeout(handleBack(), 2000);
  }, [changeEmailConfim1Failed]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: 'all',
  });

  const watchEmail = watch('new_email');

  const onSubmit = async (data) => {
    dispatch(handleChangeEmailStep2(data.new_email));
  };

  const handleBack = () => {
    navigate(`${newPathUser}profile/info`);
  };

  return (
    <React.Fragment>
      {loading && <PopupLoading className={'popup-loading'} />}
      {showPopupChangeEmailSuccess && (
        <PopupAlert
          header=''
          hiddenIcon
          content={t('Popup.changeMessToMail2')}
          isVisiblePopup={() => setShowPopupChangeEmailSuccess(false)}>
          <button className='btn btn-danger' onClick={() => handleBack()}>
            {t('Common.Ok')}
          </button>
        </PopupAlert>
      )}
      <BackHeader onBack={handleBack} label={t('User.changeEmail.title')} className='mt-4'/>
      <div className='bg-white'>
        <form
          className='fansite-form'
          encType='multipart/form-data'
          onSubmit={(e) => e.preventDefault()}>
          <React.Fragment>
            <div className='p-4' style={{ minHeight: 'calc(100vh - 135px)' }}>
              <InputWrapper
                id='fsite-new_email'
                label={t('Common.newEmail')}
                error={errors?.new_email ? errors?.new_email?.message : ''}>
                <input
                  placeholder={t('ValidateMsg.newEmailRequired')}
                  className='fsite-input'
                  name='new_email'
                  maxLength={255}
                  {...register('new_email', {
                    required: t('ValidateMsg.emailRequired'),
                    validate: {
                      fullWidth: (value) => {
                        return (
                          (value &&
                            !patternFullWidthJp.test(
                              value.toString().trim()
                            )) ||
                          t('ValidateMsg.TEXT_HALF_WIDTH')
                        );
                      },
                      email: (value) => {
                        return (
                          (value && patternEmail.test(value.toString().trim())) ||
                          t('ValidateMsg.EMAIL_FORMAT_INVALID')
                        );
                      },
                    }
                  })}
                />
              </InputWrapper>
              <InputWrapper>
                <div className='row'>
                  <div className='col-12 col-sm-6 col-md-6 mb-3'>
                    <button
                      className='btn bg-red w-100 font-weight-700 text-white app-btn'
                      disabled={loading || !watchEmail}
                      onClick={handleSubmit(onSubmit, console.log)}>
                      {t('Common.buttonChange')}
                    </button>
                  </div>
                  <div className='col-12 col-sm-6 col-md-6 mb-3'>
                    <button
                      className='btn w-100 font-weight-700 border app-btn bg-white'
                      onClick={handleBack}>
                      {t('Common.buttonCancel')}
                    </button>
                  </div>
                </div>
              </InputWrapper>
            </div>
          </React.Fragment>
        </form>
      </div>
    </React.Fragment>
  );
}

export default withCreatorRole(ProfileUpdateEmail);
