import { LayoutFan } from 'components';
import { InputWrapper } from 'components/FormLogin';
import PopupConfirm from 'components/Popup/confirmSuccess';
import PopupConfirmDelete from 'components/PopupConfirmDelete';
import { SearchIcon } from 'images';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { userSendMessage } from 'store/actions/message';
import { fanGetListCreator } from 'store/actions/users';
import { CustomOption, customStyles } from '../../utils';
import './message.scss';

const FanCreateMessage = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const userRef = useRef({});

  const [loading, setLoading] = useState(false);
  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const [confirmFail, setConfirmFail] = useState(false);
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [listFan, setListFan] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const [notYetSearch, setNotYetSearch] = useState(true);

  const {
    register,
    control,
    watch,
    handleSubmit,
    setError,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {},
  });

  const watchContent = watch('content', '');
  const watchUserID = watch('user_ids', '');

  const isDisabledButton = !watchUserID || !watchContent;

  useEffect(() => {
    if (state?.creatorId) {
      const defaultCreator = {
        label: state?.creatorName,
        value: state?.creatorId,
      };
      setListFan([defaultCreator]);
      setNotYetSearch(false);
      setValue('user_ids', defaultCreator);
    }
  }, [state]);

  const customStylesReSelect = {
    ...customStyles,
    menu: (provided, state) => ({
      ...provided,
      maxWidth: '343px',
      background: '#FFFFFF',
      boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.08)',
      borderRadius: '6px',
      display: state?.selectProps?.notYetSearch ? 'none' : 'block',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;

      return { ...provided, opacity, color: '#282727', fontWeight: 500 };
    },
  };

  const handleSearchUser = () => {
    dispatch(fanGetListCreator({ keyword: searchValue }, getListFan));
    setSearchValue('');
  };

  const getListFan = (data) => {
    setNotYetSearch(false);
    const listUser = data?.map((user) => ({ label: user.username, value: user.id }));
    setListFan(listUser || []);
    userRef.current.focus();
  };

  console.log(errors);

  const handleCheckSubmit = (data) => {
    if (!data?.content?.trim()) {
      setError('content', {
        type: 'required',
        message: t('ValidateMsg.contentMessagesRequired'),
      });
      return false;
    }
    if (data.type === 1 && !data.user_ids?.length) {
      setError('user_ids', {
        type: 'required',
        message: t('ValidateMsg.C101_VALIDATE_TYPE_MESSAGE'),
      });
      return false;
    }

    setShowPopupConfirm(true);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const params = {
      content: data.content,
      type: 1,
      to_user_id: data.user_ids.value,
    };

    dispatch(userSendMessage(params, handleConfirmSuccess));
  };

  const handleConfirmSuccess = ({ success }) => {
    if (!success) {
      setConfirmFail(true);
    } else {
      setConfirmSuccess(true);
    }
    setShowPopupConfirm(false);
    setLoading(false);
  };

  const handleSuccess = (isSuccess) => {
    setConfirmSuccess(false);
    setConfirmFail(false);
    setListFan([]);
    reset({
      type: null,
      user_ids: [],
      select_survey: null,
      content: '',
    });
    setSearchValue('');
    setNotYetSearch(true);
    userRef.current.value = null;
    if (isSuccess) {
      navigate(-1);
    }
  };

  return (
    <LayoutFan titleHeader={t('Messages.buttonCreateMessage')} loading={loading}>
      {showPopupConfirm && (
        <PopupConfirmDelete
          title={t('Popup.confirmSendMess')}
          setShowPopup={setShowPopupConfirm}
          handleOnSubmit={handleSubmit(onSubmit)}
        />
      )}
      {confirmSuccess && (
        <PopupConfirm
          title={t('Popup.createMessSuccess')}
          closeModal={() => handleSuccess(true)}
        />
      )}
      {confirmFail && (
        <PopupConfirm title={t('Popup.sendMessFail')} isWarning closeModal={handleSuccess} />
      )}
      <div className='create-message-page'>
        <form className='fansite-form' onSubmit={(e) => e.preventDefault()}>
          <InputWrapper
            label={t('Messages.selectRecipients')}
            id='user_ids'
            error={errors?.user_ids ? errors?.user_ids?.message : ''}>
            <Controller
              name='user_ids'
              control={control}
              render={({ field }) => (
                <Select
                  ref={userRef}
                  options={listFan}
                  isLoading={false}
                  isSearchable={true}
                  isMulti
                  closeMenuOnSelect
                  isClearable={true}
                  hideSelectedOptions={false}
                  backspaceRemovesValue={false}
                  noOptionsMessage={() => t('Messages.noCreator')}
                  value={field.value}
                  onChange={(val) => {
                    field.onChange(val[1] || val[0]);
                    setSearchValue('');
                  }}
                  placeholder={t('Messages.placeholderCreator')}
                  styles={customStylesReSelect}
                  components={{ Option: CustomOption }}
                  inputValue={searchValue}
                  openMenuOnFocus={!!listFan.length || !notYetSearch}
                  openMenuOnClick={!!listFan.length || !notYetSearch}
                  onInputChange={(value, action) => {
                    if (action.action === 'input-change') {
                      setSearchValue(value);
                    }
                  }}
                  // onBlur={() => {
                  //   if (!field.value && listFan.length) {
                  //     setError('user_ids', {
                  //       type: 'required',
                  //       message: t('ValidateMsg.C101_VALIDATE_TYPE_MESSAGE'),
                  //     });
                  //   }
                  // }}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                    }
                  }}
                  notYetSearch={notYetSearch}
                />
              )}
            />
          </InputWrapper>
          <button
            className='btn btn-danger btn-lg d-flex align-items-center mb-3'
            onClick={() => handleSearchUser()}>
            <SearchIcon className='icon-search' />
            {t('Common.search')}
          </button>

          <InputWrapper
            id='fsite-content'
            label={t('Common.thisArticle')}
            required={true}
            error={errors?.content ? errors?.content?.message : ''}>
            <textarea
              {...register('content', {
                required: t('ValidateMsg.contentMessagesRequired'),
                maxLength: t('ValidateMsg.maxlengthContentMessages'),
              })}
              placeholder=''
              maxLength={500}
              className='form-control'
              rows={6}
            />
          </InputWrapper>
          <InputWrapper id='fsite-submit' className='button-submit'>
            <button
              className='btn btn-danger btn-lg w-100 '
              onClick={handleSubmit(handleCheckSubmit)}
              disabled={isDisabledButton}>
              {t('Common.send')}
            </button>
          </InputWrapper>
        </form>
      </div>
    </LayoutFan>
  );
};

export default FanCreateMessage;
