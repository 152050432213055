/* eslint-disable import/no-anonymous-default-export */

import { TYPES } from '../actions';
const initialState = {
  status: null,
  loadingSearch: false,
  pagination: {},
  error: null,
  data: [],
  dataPost: [],
  category: [],
  listCreator: [],
  loadingCreatorByTag: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_CATEGORY_REQUEST:
    case TYPES.SEARCH_LIST_CREATOR_REQUEST:
      return {
        ...state,
        loadingSearch: true,
      };

    case TYPES.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.data.data,
        loadingSearch: false,
      };
    case TYPES.GET_CATEGORY_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loadingSearch: false,
      };
    case TYPES.SEARCH_POST_REQUEST:
      return {
        ...state,
        loadingSearch: true,
      };
    case TYPES.SEARCH_POST_SUCCESS:
      return {
        ...state,
        dataPost:
          action?.data?.data?.pagination?.current_page === 1
            ? action?.data?.data?.data
            : [...state.dataPost, ...action?.data?.data?.data],
        pagination: action?.data?.data?.pagination,
        loadingSearch: false,
      };
    case TYPES.SEARCH_POST_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loadingSearch: false,
      };

    case TYPES.SEARCH_LIST_CREATOR_SUCCESS:
      return {
        ...state,
        listCreator:
          action?.data?.data?.pagination?.current_page === 1
            ? action?.data?.data?.data
            : [...state.listCreator, ...action?.data?.data?.data],
        loadingSearch: false,
      };
    case TYPES.SEARCH_LIST_CREATOR_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loadingSearch: false,
      };

    default:
      return state;
  }
};
