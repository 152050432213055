export default function PlayTikTokIcon(props) {
  return (
    <svg
      width={95}
      height={104}
      viewBox='0 0 95 104'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_d_4245_123739)'>
        <path
          d='M68.708 44.3739L30.4663 21.003C29.8222 20.6091 29.0847 20.394 28.3298 20.3797C27.5749 20.3654 26.8299 20.5525 26.1713 20.9217C25.5127 21.291 24.9644 21.829 24.5828 22.4805C24.2011 23.132 24 23.8733 24 24.6284V71.3742C24.0006 72.129 24.2022 72.87 24.5839 73.5211C24.9657 74.1722 25.5139 74.71 26.1723 75.0791C26.8307 75.4482 27.5754 75.6354 28.3301 75.6214C29.0847 75.6074 29.8221 75.3928 30.4663 74.9996L68.708 51.6287C69.3292 51.2488 69.8425 50.7158 70.1986 50.0806C70.5547 49.4454 70.7417 48.7295 70.7417 48.0013C70.7417 47.2731 70.5547 46.5571 70.1986 45.922C69.8425 45.2868 69.3292 44.7537 68.708 44.3739Z'
          fill='white'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_4245_123739'
          x={-17}
          y={-6}
          width={116}
          height={116}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={12} />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0' />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_4245_123739'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_4245_123739'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
}
