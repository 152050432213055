import Icon from '@ant-design/icons';

const HeartActiveIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='18'
        height='16'
        fill='none'
        viewBox='0 0 18 16'>
        <path
          fill='currentColor'
          d='M13.758.326a4.869 4.869 0 00-1.55-.242 5.22 5.22 0 00-3.209 1.083c-.075-.058-.166-.109-.25-.158-.083-.05-.233-.175-.358-.242L8.074.626A2.938 2.938 0 007.55.408.29.29 0 007.474.36h-.049C6.933.192 6.425.1 5.9.084h-.091c-.234 0-.466.016-.7.05h-.1c-.25.033-.5.091-.741.174C1.157 1.326.049 4.701.99 7.651a10.778 10.778 0 002.51 3.999 31.751 31.751 0 005.307 4.133l.216.134.209-.125a32.048 32.048 0 005.275-4.134 10.606 10.606 0 002.508-4.007c.926-2.95-.182-6.325-3.258-7.325z'></path>
      </svg>
    )}
    {...props}
  />
);

export default HeartActiveIcon;
