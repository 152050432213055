import {
  AccountSidebar,
  HeaderListIdolV2,
  LayoutCreatorRight,
  MenuSidebarCustom,
  PopupConfirmFan,
  ToggleButton,
} from 'components';
import { newPathUser } from 'constants/layout/constant';
import useRedirect from 'hooks/useRedirect';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  userGetSettingNotification,
  userPutSettingNotification,
} from 'store/actions/notification';
import '../styles.scss';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { isMobile } from 'react-device-detect';
import { CloseIcon, MenuIcon } from 'images';
import { isAdmin, isAgency } from 'utils';
import { resetDataInfoOther, resetIDHomePage } from 'store/actions/usersNew';

export default function Index() {
  const dispatch = useDispatch();
  const { loading, setting } = useSelector((state) => state.notification);
  const { dataUser } = useSelector((state) => state.users);
  const { t } = useTranslation();
  const [isSetMessageArrive, setIsSetMessageArrive] = useState(false);
  const [isSetNotificationArrive, setIsSetNotificationArrive] = useState(false);
  const [isSetUserPackage, setIsSetUserPackage] = useState(false);
  const [isSetCreatorPost, setIsSetCreatorPost] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const navigate = useNavigate();

  const [params, setParams] = useState({});

  const handleSettingSuccess = ({ success }) => {
    success && setIsOpenModal(true);
  };

  const onSubmit = async () => {
    let _params = {
      message_notification: isSetMessageArrive
        ? params?.message_notification
        : +setting?.message_notification,
      admin_notification: isSetNotificationArrive
        ? params?.admin_notification
        : +setting?.admin_notification,
      plan_notification: isSetUserPackage
        ? params?.plan_notification
        : +setting?.plan_notification,
      follow_notification: isSetCreatorPost
        ? params?.follow_notification
        : +setting?.follow_notification,
    };
    try {
      await dispatch(userPutSettingNotification(_params, handleSettingSuccess));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    dispatch(userGetSettingNotification());
  }, []);

  useEffect(() => {
    if (setting) {
      const {
        message_notification,
        admin_notification,
        plan_notification,
        follow_notification,
      } = setting;
      setParams({
        message_notification,
        admin_notification,
        plan_notification,
        follow_notification,
      });
    }
  }, [setting]);

  const onSetMessageArrive = (checked) => {
    setIsSetMessageArrive(true);
    setParams({ ...params, message_notification: checked ? 1 : 0 });
  };

  const onSetNotificationArrive = (checked) => {
    setIsSetNotificationArrive(true);
    setParams({ ...params, admin_notification: checked ? 1 : 0 });
  };

  const onSetUserPackage = (checked) => {
    setIsSetUserPackage(true);
    setParams({ ...params, plan_notification: checked ? 1 : 0 });
  };

  const onSetCreatorPost = (checked) => {
    setIsSetCreatorPost(true);
    setParams({ ...params, follow_notification: checked ? 1 : 0 });
  };

  //* Error
  const error = useSelector((state) => state.notification.error);
  useRedirect(error);

  return (
    <>
      {isOpenModal && (
        <PopupConfirmFan text={t('Popup.configSettingSuccess')} className='popup-setting-noti'>
          <div
            className='ok btn'
            onClick={() => {
              setIsOpenModal(false);
              dispatch(resetIDHomePage());
              dispatch(resetDataInfoOther());
              navigate(`${newPathUser}`);
            }}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}
      <LayoutCreatorRight
        noButtonBack
        titlePage={t('Notification.setting.title')}
        loading={loading}
        className='setting-noti-layout'>
        <div className='notification-page'>
          {loading && !setting && (
            <div className='shimmer-loading'>
              <ShimmerPostDetails variant='SIMPLE' />
            </div>
          )}
          {setting && (
            <div className='setting-noti'>
              <div className='title'>{t('Notification.setting.variousEmail')}</div>
              <div className='toggle'>
                <label>{t('Notification.setting.messageArrives')}</label>
                <div>
                  <ToggleButton
                    id='message_notification'
                    defaultChecked={+setting?.message_notification === 1 ? true : false}
                    onChange={onSetMessageArrive}
                  />
                </div>
              </div>
              <div className='toggle'>
                <label>{t('Notification.setting.receiceNotifi')}</label>
                <div>
                  <ToggleButton
                    id='admin_notification'
                    defaultChecked={+setting?.admin_notification === 1 ? true : false}
                    onChange={onSetNotificationArrive}
                  />
                </div>
              </div>
              <div className='toggle'>
                <label>{t('Notification.setting.subscribed')}</label>
                <div>
                  <ToggleButton
                    id='plan_notification'
                    defaultChecked={+setting?.plan_notification === 1 ? true : false}
                    onChange={onSetUserPackage}
                  />
                </div>
              </div>
              <div className='toggle'>
                <label>{t('Notification.setting.followPost')}</label>
                <div>
                  <ToggleButton
                    id='follow_notification'
                    defaultChecked={+setting?.follow_notification === 1 ? true : false}
                    onChange={onSetCreatorPost}
                  />
                </div>
              </div>
              <div
                className='btn-setting'
                onClick={() => {
                  onSubmit();
                }}>
                <button type='button'>{t('Common.buttonUpdate')}</button>
              </div>
            </div>
          )}
        </div>
      </LayoutCreatorRight>
    </>
  );
}
