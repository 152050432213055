/* eslint-disable react-hooks/rules-of-hooks */
//components
import {
  Checkbox,
  CustomSelectTime,
  ImgCustom,
  LayoutCreatorRight,
  PopupConfirmFan,
  ReactCalendar,
  VideoDetail,
} from 'components';
import Select from 'react-select';
import { InputWrapper } from 'components/Input/InputWrapper';
import { pushNotify } from 'components/Notification';
//hocs
import withCreatorRole from 'hocs/withCreatorRole';
//hook
import useCalendar from 'hooks/useCalendar';
import useOutsideClickCustom from 'hooks/useOutsideClickCustom';
//img
import Textarea from 'rc-textarea';
//react
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
//store
import {
  creatorDeleteArticle,
  creatorEditArticle,
  creatorGetPostDetailNew,
} from 'store/actions/myPage';
import { creatorGetListPackage } from 'store/actions/users';
import { getPresignedUrlS3, setFileNameS3, uploadS3ByPresigned } from 'utils/upload';
//util
import {
  acceptedImageTypes,
  acceptedImgVideoTypes,
  convertToCurrentTime,
  currentTimeToUTC,
  CustomOption,
  failImgInvalid,
  failImgThan10,
  failImgThan10Mb,
  failMoreThan1Video,
  failShortVideo,
  failVideoInvalid,
  failVideoThan1,
  formatDateBE,
  formatHourMinuteBE,
  getDurationVideo,
  IMG_NO_WATER_MARK,
  isValidDate,
  linkS3Video,
  TEN_GB,
  patternNoFirstSpacing,
  TEN_MB,
  TYPE_VIDEOS,
  validateTimePostNormalRequired,
  decryptPath,
  linkS3VideoM3U8,
  renderTimeAfter24h,
  AVATAR_DEFAULT,
  failFileThan10Gb,
  checkLinkPostSale,
  sortListPackagePointAndDate,
  BTN_REMOVE,
} from 'utils';
import {
  ControlBar,
  CurrentTimeDisplay,
  Player,
  TimeDivider,
  VolumeMenuButton,
} from 'video-react';
import VideoCustomDetail from 'pages/creator/layout/recording/VideoCustomDetail';
import 'video-react/dist/video-react.css';
//scss
import '../normal.scss';
import { newPathUser } from 'constants/layout/constant';
import { TYPE_NORMAL, TYPE_SHORT } from '../post-normal';
import { useMemo } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import CloseIconV2 from 'images/CloseIconV2';
import UploadImageIcon from 'images/UploadImageIcon';
import CalendarIconV2 from 'images/CalendarIconV2';
import UploadImageIconV2 from 'images/UploadImageIconV2';
import UploadVideoIconV2 from 'images/UploadVideoIconV2';
import UploadCameraIconV2 from 'images/UploadCameraIconV2';
import { postDurationVideo } from 'store/actions/getDurationVideo';
import { BlueImg, BlueImg3 } from '../post-content-sale/elements/styled';
import LoadingIconV2 from 'images/LoadingIconV2';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { isMobile } from 'react-device-detect';
import moment from 'moment';
import { getEnv } from 'configs/env';
import MarkDownRead from 'components/MarkDownRead';
import { getInfoMetaPost } from 'store/actions/postMeta';
import MetaTagViewer from 'components/MetaTagViewer/MetaTagViewer';
import ProcessingStatusBar from '../components/ProcessingStatusBar';
import { CHUNK_FILE, uploadVideoWithMulti } from '../help';
import PerformerIcon from 'images/PerformerIcon';
import PerformerPopup from '../post-content/performerPopup';
import { useFixedPopup } from 'hooks/v2/useFixedPopup';
import {
  resetDataInfoOther,
  resetIDHomePage,
  updatePostMyPageWhenUpdate,
} from 'store/actions/usersNew';
import { customStyles } from './constants';
// import EditorWithHyperLink from 'components/RickEditor';

const EditPostNormal = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { loading, detailPost, statusPost, statusDeletePost, error } = useSelector(
    (state) => state.myPage
  );
  const { t } = useTranslation();
  const { loading: loadingListPackage, dataUser: dataProfile } = useSelector(
    (state) => state.users
  );
  const [timePost, setTimePost] = useState('00:00');
  const [timePostCheck, setTimePostCheck] = useState(null);
  const [timeRelease, setTimeRelease] = useState('00:00');
  const [timeReleaseCheck, setTimeReleaseCheck] = useState(null);
  const [timePostInit, setTimePostInit] = useState('00:00');
  const [timeReleaseInit, setTimeReleaseInit] = useState('00:00');
  const [creatorListPackage, setCreatorListPackage] = useState([]);
  const [listChecked, setListChecked] = useState([]);
  const [dataCheckBox, setDataCheckBox] = useState([]);
  const [videoUpload, setVideoUpload] = useState({ img: [], files: null });
  const [videoUploadPublicFlag0, setVideoUploadPublicFlag0] = useState({
    img: [],
    files: null,
  });
  const [imgUpload, setImgUpload] = useState({ img: [] });
  const [checkListImg, setCheckListImg] = useState({ img: [], files: [] });

  const [checkListImgPublicFlag0, setCheckListImgPublicFlag0] = useState({
    img: [],
    files: [],
  });
  const [datagetPresignedUrlS3, setDatagetPresignedUrlS3] = useState({
    main_video: '',
    images: [],
  });
  const [content, setContent] = useState();
  //loading
  const [popupLoading, setPopupLoading] = useState(false);
  const [isVisiblePopUpDeletePost, setIsVisiblePopDeletePost] = useState(false);
  const [isVisiblePopUpDeletePostComplete, setIsVisiblePopDeletePostComplete] =
    useState(false);
  const [isVisiblePopUpConfirmWarningApproved, setIsVisiblePopUpConfirmWarningApproved] =
    useState(false);
  const [disableStartTime, setDisableStartTime] = useState(false);
  const [checkPostIn24h, setCheckPostIn24h] = useState(false);
  //TIME POST & RELEASE
  const [isCheckTimePost, setIsCheckTimePost] = useState(false);
  const [isCheckTimeRelease, setIsCheckTimeRelease] = useState(false);
  const [isFailTime, setIsFailTime] = useState(false);
  const [isLoadingFirst, setIsLoadingFirst] = useState(true);
  const [isChangeTime1, setIsChangeTime1] = useState(false);

  const [showThumbnail, setShowThumbnail] = useState(false);
  const [durationVideo, setDurationVideo] = useState(0);
  const [seeNext, setSeeNext] = useState(false);
  const [typePost, setTypePost] = useState(TYPE_NORMAL);
  const [isChangeDataImage, setIsChangeDataImage] = useState(false);
  const [defaultValue, setDefaultValue] = useState({});
  const [type, setType] = useState(1);
  const [thumbnailUpload, setThumbnailUpload] = useState({ img: [], files: null });
  const [thumbnailUploadFlag0, setThumbnailUploadFlag0] = useState({ img: [], files: null });
  const [hiddenQuote, setHiddenQuote] = useState(false);

  const refUploadVideo = useRef(null);
  const refUploadImage = useRef(null);
  const refUploadCamera = useRef(null);

  const [idPostMeta, setIdPostMeta] = useState(null);
  const [postDetailMeta, setPostDetailMeta] = useState(null);

  const [isShowPerformerPopup, setIsShowPerformerPopup] = useState(false);
  const [listPerformer, setListPerformer] = useState([]);

  const [percentUpload, setPercentUpload] = useState([]);
  const [percentThumbnail, setPercentThumbnail] = useState([]);
  const [percentImg, setPercentImg] = useState([]);
  const [openProgress, setOpenProgress] = useState(false);
  const [flgVideo, setFlgVideo] = useState(false);
  const [isPostRejectByAdmin, setIsPostRejectByAdmin] = useState(false);

  const optionSend = [
    { value: '0', label: t('Creator.CreateLiveStream.everyone') },
    { value: '1', label: t('Creator.PostArticle.postShort.userFollow') },
  ];
  useFixedPopup(isShowPerformerPopup);

  //FORM
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    setError,
    clearErrors,
    getValues,
    formState: { errors, isValid },
  } = useForm({ mode: 'all', defaultValues: { ...detailPost }, reValidateMode: 'all' });
  const watchContent = watch('content', '');
  const watchEnableComment = watch('enable_comment', '');
  const watchFlag24 = watch('in_24h_flag', '');
  const watchFollowFlag = watch('follow_flag', '');
  const isShowErrorPackages = useMemo(() => {
    return (
      (Number(watchFollowFlag?.value) === 1 || listChecked?.length > 0) &&
      !(
        imgUpload?.img?.length > 0 ||
        videoUpload?.files ||
        videoUploadPublicFlag0?.files ||
        checkListImg?.img?.length > 0
      )
    );
  }, [
    watchFollowFlag?.value,
    listChecked?.length,
    imgUpload?.img?.length,
    videoUpload?.files,
    videoUploadPublicFlag0?.files,
    checkListImg?.img?.length,
  ]);

  const isHaveImgOrVideo = useMemo(() => {
    return imgUpload?.img?.length > 0 ||
      videoUpload?.files ||
      checkListImg?.img?.length > 0 ||
      videoUploadPublicFlag0?.files
      ? true
      : false;
  }, [
    checkListImg?.img?.length,
    imgUpload?.img?.length,
    videoUpload?.files,
    videoUploadPublicFlag0?.files,
  ]);

  const isApproved = useMemo(() => {
    return dataProfile?.status === 1;
    // return false;
  }, [dataProfile?.status]);

  //CHECK STATUS DELETE
  useEffect(() => {
    statusDeletePost && setIsVisiblePopDeletePostComplete(true);
  }, [statusDeletePost]);

  useEffect(() => {
    const top = document.getElementById('app_cocofans_0');
    top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
  }, []);

  //START REACT CALENDAR
  const {
    date: date1,
    toggleCalendar: toggleCalendar1,
    onChangeDate: onChangeDate1,
    isVisibleCalendar: isVisibleCalendar1,
  } = useCalendar();
  const {
    date: date2,
    toggleCalendar: toggleCalendar2,
    onChangeDate: onChangeDate2,
    isVisibleCalendar: isVisibleCalendar2,
  } = useCalendar();
  const datePickerRef1 = useRef({});
  const datePickerRef2 = useRef({});

  const disabledButton = useMemo(() => {
    if (isApproved) {
      if (isLoadingFirst) {
        return (
          (!isHaveImgOrVideo && !watchContent?.trim()) ||
          (isHaveImgOrVideo && !listPerformer?.length) ||
          (isCheckTimeRelease && date2 && !timeReleaseCheck && !detailPost?.unpublic_time) ||
          (isCheckTimePost && date1 && !timePostCheck && !detailPost?.public_time) ||
          (isCheckTimePost && !date1 && !detailPost?.public_time) ||
          (isCheckTimeRelease && !date2 && !detailPost?.unpublic_time) ||
          popupLoading ||
          statusPost ||
          isShowErrorPackages
        );
      }
      return (
        (!isHaveImgOrVideo && !watchContent?.trim()) ||
        (isHaveImgOrVideo && !listPerformer?.length) ||
        (isCheckTimeRelease && date2 && !timeReleaseCheck && !detailPost?.unpublic_time) ||
        (isCheckTimePost && date1 && !timePostCheck && !detailPost?.public_time) ||
        (isCheckTimePost && !date1 && !detailPost?.public_time) ||
        (isCheckTimeRelease && !date2 && !detailPost?.unpublic_time) ||
        !isFailTime ||
        popupLoading ||
        loading ||
        statusPost ||
        isShowErrorPackages
      );
    } else {
      if (isLoadingFirst) {
        return (
          !watchContent?.trim() || popupLoading || loading || statusPost || isShowErrorPackages
        );
      }
      return (
        !watchContent?.trim() ||
        !isFailTime ||
        popupLoading ||
        loading ||
        statusPost ||
        isShowErrorPackages
      );
    }
  }, [
    isApproved,
    isLoadingFirst,
    isHaveImgOrVideo,
    watchContent,
    listPerformer?.length,
    isFailTime,
    popupLoading,
    loading,
    statusPost,
    isShowErrorPackages,
    isCheckTimeRelease,
    date2,
    timeReleaseCheck,
    detailPost?.unpublic_time,
    detailPost?.public_time,
    isCheckTimePost,
    date1,
    timePostCheck,
  ]);

  const handleShowPopupComfirmSuccess = (detailPost) => {
    dispatch(updatePostMyPageWhenUpdate(detailPost));
    setPopupLoading(false);
    setOpenProgress(false);
    navigate(`${newPathUser}post/${detailPost?.id}`, {
      state: {
        isEditHomePage: location.state?.isEditHomePage,
        isPostShortMyPage: location.state?.isPostShortMyPage,
      },
    });
  };

  useOutsideClickCustom((e) => {
    if (isVisibleCalendar1) {
      const { className } = e?.target;
      if (
        e?.target !== datePickerRef1?.current &&
        className !== '' &&
        !className.includes('react-calendar')
      ) {
        toggleCalendar1();
      }
    }
  });
  useOutsideClickCustom((e) => {
    if (isVisibleCalendar2) {
      const { className } = e?.target;
      if (
        e?.target !== datePickerRef2?.current &&
        className !== '' &&
        !className.includes('react-calendar')
      ) {
        toggleCalendar2();
      }
    }
  });

  //GET DATA CHECKBOX
  useEffect(() => {
    if (isApproved) {
      dispatch(
        creatorGetListPackage('', '', ({ data }) => {
          const dataSort = sortListPackagePointAndDate(data);
          setCreatorListPackage(dataSort);
        })
      );
    }
  }, [dispatch, isApproved]);

  //GET DETAIL
  useEffect(() => {
    dispatch(
      creatorGetPostDetailNew(id, {
        filter_words: false,
      })
    );
  }, [id]);

  //START SET VALUE
  useEffect(() => {
    if (detailPost && creatorListPackage) {
      const {
        unpublic_time,
        public_time,
        content,
        enable_comment,
        packages,
        medias,
        created_at,
        in_24h_flag,
        type,
        follow_flag,
        highlight_flag,
        all_package,
        post_contracts,
        is_active,
      } = detailPost;
      if (is_active === 2) {
        setIsPostRejectByAdmin(true);
      }
      if (type && ![1, 4].includes(Number(type))) {
        navigate('/*');
      }
      setType(type);
      if (type === 4) {
        setTypePost(TYPE_SHORT);
        if (highlight_flag === 1) {
          setShowThumbnail(true);
        }
      }
      setListPerformer(
        post_contracts?.map((item) => ({
          id: item?.contract_id,
          first_name_romaji: item?.first_name_romaji,
          last_name_romaji: item?.last_name_romaji,
        }))
      );
      setValue('follow_flag', { value: follow_flag });
      setContent(content);
      setValue('created_at', created_at);
      setValue('content', content);
      setValue('enable_comment', enable_comment);
      setValue('in_24h_flag', in_24h_flag);
      in_24h_flag === 1 && setCheckPostIn24h(true);
      let defaultData = {
        content,
        enable_comment,
        in_24h_flag,
        follow_flag: { value: follow_flag },
      };
      if (public_time) {
        setIsCheckTimePost(true);
        setValue('check_time_post', 1);
        const publicTimeUtc = convertToCurrentTime(public_time);
        setValue('public_time', formatDateBE(publicTimeUtc));
        setTimePost(formatHourMinuteBE(publicTimeUtc));
        setTimePostInit(formatHourMinuteBE(public_time));
        defaultData = {
          ...defaultData,
          check_time_post: 1,
          public_time: formatDateBE(publicTimeUtc),
          timePost: formatHourMinuteBE(publicTimeUtc),
        };
      } else {
        defaultData = {
          ...defaultData,
          check_time_post: 0,
        };
        setValue('check_time_post', 0);
      }
      if (unpublic_time) {
        setIsCheckTimeRelease(true);
        setValue('check_time_release', 1);
        const unPublicTimeUtc = convertToCurrentTime(unpublic_time);
        setValue('unpublic_time', formatDateBE(unPublicTimeUtc));
        setTimeRelease(formatHourMinuteBE(unPublicTimeUtc));
        setTimeReleaseInit(formatHourMinuteBE(unpublic_time));
        defaultData = {
          ...defaultData,
          check_time_release: 1,
          unpublic_time: formatDateBE(unPublicTimeUtc),
          timeRelease: formatHourMinuteBE(unPublicTimeUtc),
        };
      } else {
        setValue('check_time_release', 0);
        defaultData = {
          ...defaultData,
          check_time_release: 0,
        };
      }

      if (creatorListPackage.length > 0) {
        if (all_package === 1) {
          setListChecked(
            creatorListPackage.map((item) => {
              return { ...item };
            })
          );
          setDataCheckBox(creatorListPackage.map((item) => ({ ...item, checked: true })));
        } else {
          if (packages?.length > 0) {
            const newListChecked = creatorListPackage.map((item) => {
              packages.map((pack) => {
                if (pack?.id === item?.id) {
                  item = { ...item, checked: true };
                }
              });
              return item;
            });
            setDataCheckBox(newListChecked);
            setListChecked(
              packages.map((item) => {
                return { ...item };
              })
            );
          } else {
            setDataCheckBox(creatorListPackage);
          }
        }
      }
      if (medias.length > 0) {
        const cloneListImgInit = medias.slice();
        const listType1 = [];
        const listTypeFlag0 = [];
        cloneListImgInit?.map((item) => {
          if (item.public_flag === 1 && item.type === 1) {
            listType1.push(item.url);
          }
          if (item.public_flag !== 1 && item.type === 1) {
            listTypeFlag0.push(item.url);
          }
          if (item.type === 2) {
            setDurationVideo(item?.duration);
            setDatagetPresignedUrlS3((prev) => ({ ...prev, main_video: item?.url }));
            if (item.public_flag === 1) {
              setFlgVideo(true);
              setVideoUpload((prev) => ({
                ...prev,
                img: decryptPath(item?.url),
                files: decryptPath(item?.url),
              }));
            } else {
              setVideoUpload({
                img: 0,
                files: 0,
              });
              setVideoUploadPublicFlag0({
                img: decryptPath(item?.url),
                files: decryptPath(item?.url),
              });
            }
          }
          if (item.type === 4) {
            if (item.public_flag === 1) {
              setThumbnailUpload({
                img: decryptPath(item?.url),
                files: decryptPath(item?.url),
              });
            } else {
              setThumbnailUploadFlag0({
                img: decryptPath(item?.url),
                files: decryptPath(item?.url),
              });
            }
          }
        });

        setCheckListImg({
          files: listType1,
          img: listType1,
        });
        setCheckListImgPublicFlag0({
          files: listTypeFlag0,
          img: listTypeFlag0,
        });
      }

      if (type !== 4) {
        defaultData = {
          ...defaultData,
          package_ids: JSON.stringify(packages?.map((e) => e.id)),
          all_package:
            creatorListPackage.length > 0
              ? creatorListPackage.length === packages.length
              : false,
        };
      }
      setDefaultValue(defaultData);
    }
  }, [setDataCheckBox, dataProfile, detailPost, creatorListPackage, setValue]);
  //END SET VALUE
  useEffect(() => {
    if (!getValues('public_time')) {
      setDisableStartTime(true);
    } else {
      setDisableStartTime(false);
    }
    if (typePost === TYPE_SHORT) {
      setIsFailTime(true);
    } else {
      if (!isCheckTimePost && !isCheckTimeRelease) {
        setIsFailTime(true);
      } else if (isCheckTimePost) {
        const publicTime = new Date(`${date1 || getValues('public_time')} ${timePost}`);
        const publicTimeOld = new Date(`${getValues('public_time')} ${timePost}`);
        if (isValidDate(publicTime) === true) {
          if (isCheckTimeRelease) {
            const unPublicTime = new Date(
              `${date2 || getValues('unpublic_time')} ${timeRelease}`
            );
            if (publicTimeOld < Date.now()) {
              setIsFailTime(true);
              setDisableStartTime(true);
            }
            if (publicTime < Date.now() && isChangeTime1) {
              setIsFailTime(false);
              if (!isLoadingFirst) {
                pushNotify('error', t('ValidateMsg.END_DAY_AFTER_DATE_NOW'));
              }
            }
            if (publicTime > unPublicTime) {
              setIsFailTime(false);
              if (!isLoadingFirst) {
                pushNotify('error', t('ValidateMsg.END_DAY_AFTER_OR_EQUAL_NOW'));
              }
            } else if (isValidDate(unPublicTime) === true) {
              if (unPublicTime <= Date.now()) {
                setIsFailTime(false);
                if (!isLoadingFirst) {
                  pushNotify('error', t('ValidateMsg.END_DAY_AFTER_DATE_NOW'));
                }
              } else {
                setIsFailTime(true);
              }
            } else setIsFailTime(false);
          } else {
            setIsFailTime(true);
            if (publicTime <= Date.now()) {
              if (!isLoadingFirst) {
                pushNotify('error', t('ValidateMsg.END_DAY_AFTER_DATE_NOW'));
              }
              setIsFailTime(false);
              publicTimeOld < Date.now()
                ? setDisableStartTime(true)
                : setDisableStartTime(false);
            } else {
              setIsFailTime(true);
            }
          }
        } else {
          setIsFailTime(false);
        }
      } else if (!isCheckTimePost && isCheckTimeRelease) {
        const unPublicTime = new Date(`${date2 || getValues('unpublic_time')} ${timeRelease}`);
        if (isValidDate(unPublicTime) === true) {
          if (unPublicTime < Date.now()) {
            setIsFailTime(false);
            if (!isLoadingFirst) {
              pushNotify('error', t('ValidateMsg.END_DAY_AFTER_DATE_NOW'));
            }
          } else {
            setIsFailTime(true);
          }
        } else {
          setIsFailTime(false);
        }
      } else {
        setIsFailTime(true);
      }
    }
  }, [
    date1,
    date2,
    timePost,
    timeRelease,
    isCheckTimePost,
    isCheckTimeRelease,
    typePost,
    isLoadingFirst,
  ]);

  const handleDeletePost = () => {
    setIsVisiblePopDeletePost(false);
    dispatch(creatorDeleteArticle(detailPost.id));
  };

  useEffect(() => {
    const isValidContent = watchContent?.trim() !== '';
    if (isValidContent) {
      const idPost = checkLinkPostSale(watchContent);
      if (idPost) {
        setIdPostMeta(idPost);
      } else {
        setIdPostMeta(null);
        setPostDetailMeta(null);
      }
    } else {
      setPostDetailMeta(null);
      setIdPostMeta(null);
    }
  }, [watchContent]);

  useEffect(() => {
    if (idPostMeta) {
      dispatch(
        getInfoMetaPost(idPostMeta, (dataMeta) => {
          setPostDetailMeta(dataMeta);
        })
      );
    } else {
      setPostDetailMeta(null);
    }
  }, [idPostMeta, dispatch]);

  //END REACT CALENDAR

  // START IMG VIDEO
  useEffect(() => {
    if (checkListImg.img?.length > 0) {
      const newArrImg = [];
      for (let i = 0; i < checkListImg.img.length; i++) {
        if (typeof checkListImg.img[i] !== 'string' && checkListImg.img[i] !== undefined) {
          newArrImg.push(URL.createObjectURL(checkListImg.img[i]));
        } else {
          newArrImg.push(checkListImg.img[i]);
        }
      }
      setCheckListImg((prev) => ({ ...prev, files: newArrImg }));
      const newList = [...checkListImg?.img, ...checkListImgPublicFlag0.img];
      setImgUpload({ img: newList });
    }

    if (videoUpload.img?.length) {
      const newArrVideo = [];
      if (typeof videoUpload.img[0] !== 'string' && videoUpload.img[0] !== undefined) {
        newArrVideo.push(URL.createObjectURL(videoUpload.img[0]));
        setVideoUpload({ img: videoUpload.img, files: newArrVideo });
      } else {
        setVideoUpload({ img: videoUpload.img, files: videoUpload.img });
      }
    }
  }, [videoUpload.img, checkListImg.img]);

  useEffect(() => {
    if (checkPostIn24h) {
      setIsCheckTimeRelease(false);
      setDisableStartTime(true);
    }
  }, [checkPostIn24h]);

  const inputImage = () => {
    const len = typeof videoUpload.img === 'string' ? 1 : videoUpload.img?.length;
    const isDisable =
      len + checkListImg.img.length >= 10 || typePost === TYPE_SHORT || !isPostRejectByAdmin;
    return (
      <div
        className={`inputFile ${isDisable && 'disableImgInput'}`}
        onClick={() => refUploadVideo?.current?.click()}>
        <div className='btn-upload'>
          <UploadImageIconV2 />
          <span>{t('Creator.PostArticle.imageUpload')}</span>
        </div>
        <input
          ref={refUploadVideo}
          style={{ display: 'none' }}
          type='file'
          multiple='multiple'
          accept={acceptedImgVideoTypes.map((item) => item).join(',')}
          onChange={handleInputImg}
          onClick={(e) => (e.target.value = null)}
          disabled={isDisable}
        />
      </div>
    );
  };

  const handleInputImg = (e) => {
    let sizeImgBiggerTenMb = false;
    let sizeVideoBiggerFiveGB = false;
    let checkImg = true;
    let video = null;
    let listImages = [];
    let checkVideos = true;
    let lengthVideoUpload = 0;
    let lentVideoUploadPublicFlag = videoUploadPublicFlag0.img ? 1 : 0;
    const checkLength =
      lentVideoUploadPublicFlag +
      checkListImg.img.length +
      checkListImgPublicFlag0.img.length +
      e.target.files.length;
    for (var i = 0; i < e.target.files.length; i++) {
      if (acceptedImageTypes.includes(e.target.files[i]['type'])) {
        if (e.target.files[i].size > TEN_MB) {
          sizeImgBiggerTenMb = true;
        }
        listImages.push(e.target.files[i]);
      } else if (TYPE_VIDEOS.includes(e.target.files[i]['type'])) {
        video = e.target.files[i];
        if (e.target.files[i].size > TEN_GB) {
          sizeVideoBiggerFiveGB = true;
        }
        lengthVideoUpload += 1;
      } else {
        checkImg = false;
      }
    }
    if (
      lengthVideoUpload > 1 ||
      ((videoUpload.img.length > 0 || videoUploadPublicFlag0.img.length > 0) &&
        lengthVideoUpload > 0)
    ) {
      checkVideos = false;
    }

    if (
      e.target.files[0] &&
      checkImg &&
      !sizeImgBiggerTenMb &&
      !sizeVideoBiggerFiveGB &&
      checkLength <= 10 &&
      checkVideos
    ) {
      setIsChangeDataImage(true);
      setCheckListImg((prev) => ({ ...prev, img: [...checkListImg.img, ...listImages] }));
      if (video) {
        getDurationVideo(video, (duration) => {
          setVideoUpload({ files: URL.createObjectURL(video), img: [video] });
          setDurationVideo(duration);
        });
        setVideoUpload({ files: URL.createObjectURL(video), img: [video] });
      }
      setTypePost(TYPE_NORMAL);
    } else {
      !checkVideos && failMoreThan1Video();
      sizeVideoBiggerFiveGB && failFileThan10Gb();
      sizeImgBiggerTenMb && failImgThan10Mb();
      checkLength > 10 && failImgThan10();
      !checkImg && failImgInvalid();
    }
  };

  const handleRemoveImg = (i, item) => {
    const { img } = checkListImg;
    const removeEle = img.splice(i, 1);
    if (img.length === 0) {
      setCheckListImg({ img: [], files: [] });
      setImgUpload({ img: [] });
    } else {
      setCheckListImg((prev) => ({
        ...prev,
        img: img.filter((item) => item !== removeEle[0]),
      }));
    }
  };

  const inputVideo = () => {
    const len = typeof videoUpload.img === 'string' ? 1 : videoUpload.img?.length;
    const isDisable =
      len > 0 || imgUpload.img.length > 10 || typePost === TYPE_NORMAL || !isPostRejectByAdmin;
    return (
      <div
        className={`inputFile ${isDisable && 'disableVideoInput'}`}
        onClick={() => refUploadImage?.current?.click()}>
        <div className='btn-upload'>
          <UploadVideoIconV2 />
          <span>{t('Creator.PostArticle.videoUpload')}</span>
        </div>
        <input
          ref={refUploadImage}
          style={{ display: 'none' }}
          type='file'
          multiple='multiple'
          accept={acceptedImgVideoTypes.map((item) => item).join(',')}
          onChange={handleInputVideo}
          disabled={isDisable}
          onClick={(e) => (e.target.value = null)}
        />
      </div>
    );
  };

  const handleInputVideo = async (e) => {
    let videoSelect = false;
    let imageSelect = false;
    const targetFiles = e.target.files;
    let targetFilesObject = [...targetFiles];
    if (
      targetFilesObject.length + checkListImg.length > 10 ||
      targetFilesObject.length + videoUpload.length > 10
    ) {
      return pushNotify('error', `${t('ValidateMsg.uploadThan10Img')}`);
    }
    /**
     * check img vs video
     */
    targetFilesObject.map((file) => {
      const isImage = file.type.includes('image');
      const isVideo = file.type.includes('video');
      const maxSize = isImage ? TEN_MB : TEN_GB;
      const fileType = isImage ? acceptedImageTypes : TYPE_VIDEOS;
      if (+file.size > +maxSize) {
        return pushNotify(
          'error',
          isImage ? `${t('ValidateMsg.imgThan10Mb1')}` : `${t('ValidateMsg.videoThan1Gb1')}`
        );
      } else if (isImage && !fileType.includes(file.type)) {
        return pushNotify('error', `${t('ValidateMsg.typeImg1')}`);
      } else if (isVideo && !fileType.includes(file.type)) {
        return pushNotify('error', `${t('ValidateMsg.typeVideo1')}`);
      } else if (!isVideo && !isImage) {
        pushNotify('error', `${t('ValidateMsg.typeImg1')}`);
        return pushNotify('error', `${t('ValidateMsg.typeVideo1')}`);
      } else if (
        (checkListImg.img.length > 0 && isVideo) ||
        (videoUpload.img.length > 0 && isImage)
      ) {
        return pushNotify('error', `${t('ValidateMsg.ImgAndVideoRequired')}`);
      } else if (isImage) {
        return (imageSelect = true);
      } else {
        return (videoSelect = true);
      }
    });

    const checkLengthVideo = videoUpload.img.length + e.target.files.length;
    if (videoSelect === true && (checkLengthVideo > 1 || e.target.files.length > 1)) {
      return pushNotify('error', `${t('ValidateMsg.uploadThan1Video')}`);
    }

    if (imageSelect && videoSelect) {
      return pushNotify('error', `${t('ValidateMsg.ImgAndVideoRequired')}`);
    } else if (imageSelect && !videoSelect) {
      let sizeImgBiggerTenMb = false;
      let checkImg = true;
      const checkLength = checkListImg.img.length + e.target.files.length;
      const newArrImg = [];
      for (var i = 0; i < e.target.files.length; i++) {
        if (e.target.files[i].size > TEN_MB) sizeImgBiggerTenMb = true;
        if (!acceptedImageTypes.includes(e.target.files[i]['type'])) checkImg = false;
        newArrImg.push(URL.createObjectURL(e.target.files[i]));
      }
      if (e.target.files[0] && checkImg && !sizeImgBiggerTenMb && checkLength <= 10) {
        setIsChangeDataImage(true);
        setCheckListImg((prev) => ({
          ...prev,
          files: [...checkListImg.files, ...newArrImg],
          img: [...checkListImg.img, ...e.target.files],
        }));
        setTypePost(TYPE_SHORT);
      } else {
        sizeImgBiggerTenMb && failImgThan10Mb();
        checkLength > 10 && failImgThan10();
        !checkImg && failImgInvalid();
      }
    } else if (!imageSelect && videoSelect) {
      let sizeImgBiggerTenGB = false;
      sizeImgBiggerTenGB = e.target.files[0].size > TEN_GB ? true : false;
      const checkTypes = e.target.files[0]['type'].includes('video');
      const checkLength = videoUpload.img.length + e.target.files.length;
      if (e.target.files[0]['type'] === 'video/x-ms-wmv') {
        dispatch(
          postDurationVideo(e.target.files[0], ({ data }) => {
            if (checkTypes && !sizeImgBiggerTenGB && checkLength <= 1) {
              if (data <= 60) {
                setDurationVideo(data);
                setIsChangeDataImage(true);
                setVideoUpload({
                  files: URL.createObjectURL(e.target.files[0]),
                  img: e.target.files,
                });
                setTypePost(TYPE_SHORT);
              } else {
                failShortVideo();
              }
            } else {
              sizeImgBiggerTenGB && failFileThan10Gb();
              !checkTypes && failVideoInvalid();
              checkLength > 1 && failVideoThan1();
            }
          })
          // () => setLoadingUploadFile(false)
        );
      } else {
        getDurationVideo(e.target.files[0], (duration) => {
          if (duration <= 60) {
            if (checkTypes && !sizeImgBiggerTenGB && checkLength <= 1) {
              setIsChangeDataImage(true);
              setVideoUpload({
                files: URL.createObjectURL(e.target.files[0]),
                img: e.target.files,
              });
              setDurationVideo(duration);
              setTypePost(TYPE_SHORT);
              // type_post('short');
              // short_video(e.target.files[0]);
            } else {
              sizeImgBiggerTenGB && failFileThan10Gb();
              !checkTypes && failVideoInvalid();
              checkLength > 1 && failVideoThan1();
            }
          } else {
            failShortVideo();
          }
        });
      }
    }
  };

  const inputCamera = () => {
    const isDisable =
      videoUpload.img.length + imgUpload.img.length >= 10 ||
      typePost === TYPE_SHORT ||
      !isMobile ||
      !isPostRejectByAdmin;
    return (
      <div
        className={`inputFile ${isDisable && 'disableVideoInput'}`}
        onClick={() => refUploadCamera?.current?.click()}>
        <div className='btn-upload'>
          <UploadCameraIconV2 />
          <span>{t('Creator.PostArticle.camera')}</span>
        </div>
        <input
          ref={refUploadCamera}
          style={{ display: 'none' }}
          type='file'
          accept={acceptedImgVideoTypes.map((item) => item).join(',')}
          onChange={handleInputImg}
          disabled={isDisable}
          onClick={(e) => (e.target.value = null)}
          capture='camera'
          multiple
        />
      </div>
    );
  };

  // END IMG VIDEO

  // START PLAN POST
  const handleOnchangePlanPost = (e, pack) => {
    const { checked } = e.target;
    if (checked) {
      setListChecked(
        listChecked.concat({
          id: pack.id,
          title: pack.title,
          checked: true,
        })
      );
      const newData = dataCheckBox.map((item) => {
        if (item.id === pack.id) {
          return { ...item, checked: true };
        } else return item;
      });
      setDataCheckBox(newData);
    } else {
      const newData = dataCheckBox.map((item) => {
        if (item.id === pack.id) {
          return { ...item, checked: false };
        } else return item;
      });
      setDataCheckBox(newData);
      setListChecked(listChecked.filter((item) => item.id !== pack.id));
    }
  };
  const handleAllPlanPost = (e) => {
    const { checked } = e.target;
    if (checked) {
      const newData = dataCheckBox.map((item) => {
        return { ...item, checked: true };
      });
      setDataCheckBox(newData);
      setListChecked(newData);
    } else {
      const newData = dataCheckBox.map((item) => {
        return { ...item, checked: false };
      });
      setDataCheckBox(newData);
      setListChecked([]);
    }
  };
  // END PLAN POST

  //START TIME POST
  const handleCheckTimePost = (e) => {
    const { value } = e.target;
    setValue('check_time_post', +value);
    if (+value === 1) {
      setIsCheckTimePost(true);
    } else {
      setIsCheckTimePost(false);
    }
  };
  // END TIME_POST

  //START TIME RELEASE
  const handleCheckTimeRelease = (e) => {
    const { value } = e.target;
    setValue('check_time_release', +value);
    if (+value === 1) {
      setIsCheckTimeRelease(true);
    } else {
      setIsCheckTimeRelease(false);
    }
  };
  // END TIME RELEASE

  const pcVideo = useMemo(() => {
    let percent = 0;
    if (videoUpload && typeof videoUpload.img?.[0] === 'object') {
      if (percentUpload?.length) {
        percentUpload.forEach((element) => {
          percent += Number(element);
        });
        percent /= percentUpload?.length;
      }
    }
    return Math.floor(percent);
  }, [percentUpload, videoUpload]);

  const pcThumbnail = useMemo(() => {
    let percent = 0;
    if (
      thumbnailUpload &&
      thumbnailUpload?.img[0] &&
      typeof thumbnailUpload?.img === 'object'
    ) {
      if (percentThumbnail?.length) {
        percentThumbnail.forEach((element) => {
          percent += Number(element);
        });
        percent /= percentThumbnail?.length;
      }
    }
    return Math.floor(percent);
  }, [percentThumbnail, thumbnailUpload]);

  const pcImg = useMemo(() => {
    let percent = 0;
    const findItemUpload = imgUpload.img?.find((item) => typeof item === 'object');
    if (imgUpload?.length && findItemUpload) {
      if (percentImg?.length) {
        percentImg.forEach((element) => {
          percent += Number(element);
        });
        percent /= percentImg?.length;
      }
    }
    return Math.floor(percent);
  }, [imgUpload, percentImg]);

  const countItemUpload = useMemo(() => {
    const findItemUpload =
      imgUpload.img?.length && imgUpload.img?.find((item) => typeof item === 'object');
    const countImg = imgUpload.img?.length && findItemUpload ? 1 : 0;
    const countThumbnail =
      thumbnailUpload && thumbnailUpload?.img[0] && typeof thumbnailUpload?.img === 'object'
        ? 1
        : 0;
    const countVideo = videoUpload && typeof videoUpload.img?.[0] === 'object' ? 1 : 0;
    return countImg + countVideo + countThumbnail;
  }, [videoUpload, imgUpload, thumbnailUpload]);

  const pcUpload = useMemo(() => {
    return Math.floor((pcVideo + pcImg + pcThumbnail) / countItemUpload);
  }, [pcImg, pcVideo, pcThumbnail, countItemUpload]);

  const uploadMainVideo = (dataEdit, thumbnail, main_video) => {
    if (typePost === TYPE_SHORT) {
      let newData = {
        ...dataEdit,
        thumbnail: main_video && thumbnail,
        duration: +durationVideo <= 60 ? +durationVideo * 1000 : +durationVideo,
        short_video: main_video,
      };
      delete newData['main_video'];
      dispatch(
        creatorEditArticle(detailPost.id, newData, type, handleShowPopupComfirmSuccess)
      );
    } else if (!imgUpload?.img?.length || imgUpload?.img?.length <= 0) {
      let newData = {
        ...dataEdit,
        thumbnail: main_video && thumbnail,
        duration: +durationVideo <= 60 ? +durationVideo * 1000 : +durationVideo,
        main_video,
      };
      dispatch(
        creatorEditArticle(detailPost.id, newData, type, handleShowPopupComfirmSuccess)
      );
    }
  };

  //ONSUBMIT
  const onSubmit = (data) => {
    if (countItemUpload > 0) {
      setOpenProgress(true);
    } else {
      setPopupLoading(true);
    }
    let publicTime;
    let unPublicTime;
    if (isCheckTimePost) {
      if (!!date1) {
        publicTime = currentTimeToUTC(new Date(`${date1 + ' ' + timePost}`));
      } else {
        publicTime = currentTimeToUTC(
          new Date(
            `${
              moment
                .utc(detailPost?.public_time || detailPost?.created_at, 'YYYY-MM-DD HH:mm:ss')
                .local()
                .format('YYYY-MM-DD') +
              ' ' +
              timePost
            }`
          )
        );
      }
    } else {
      publicTime = undefined;
    }
    if (isCheckTimeRelease) {
      if (!!date2) {
        unPublicTime = currentTimeToUTC(new Date(`${date2 + ' ' + timeRelease}`));
      } else {
        unPublicTime = currentTimeToUTC(
          new Date(
            `${
              moment
                .utc(detailPost?.unpublic_time, 'YYYY-MM-DD HH:mm:ss')
                .local()
                .format('YYYY-MM-DD') +
              ' ' +
              timeRelease
            }`
          )
        );
      }
    } else {
      unPublicTime = undefined;
    }
    let newData = {
      ...data,
      post_share_id: hiddenQuote ? null : detailPost?.post_share_id,
      enable_comment: data?.enable_comment || data?.enable_comment === 1 ? 1 : 0,
      follow_flag: data?.follow_flag?.value || 0,
      public_time: publicTime,
      unpublic_time: unPublicTime,
    };
    if (checkPackage) {
      newData = {
        ...newData,
        package_ids: listChecked,
        all_package:
          creatorListPackage.length > 0
            ? dataCheckBox.length === listChecked.length
              ? 1
              : 0
            : 0,
      };
    }

    if (type === 4) {
      newData = {
        content: newData?.content,
        enable_comment: newData?.enable_comment,
        follow_flag: newData?.follow_flag,
        in_24h_flag: newData?.in_24h_flag,
        highlight_flag: showThumbnail ? 1 : 0,
        public_time: publicTime,
      };
    }

    setDatagetPresignedUrlS3((prev) => ({ ...prev, ...newData }));
    const dataEdit = {
      ...datagetPresignedUrlS3,
      ...newData,
      contract_ids: isHaveImgOrVideo ? listPerformer?.map((e) => e.id) : [],
    };
    handleConfirmSubmit(dataEdit);
  };

  const handleConfirmSubmit = async (dataEdit) => {
    let main_video_pre_signed;
    let main_video;
    let thumbnail;
    let checkNewVideo = typeof videoUpload.img?.[0] === 'object';
    const checkNewImg = imgUpload.img?.filter((item) => {
      return typeof item === 'object';
    });
    const checkOld = imgUpload.img?.filter((item) => {
      return typeof item !== 'object';
    });
    let listLinkS3Img = checkOld || [];
    let listLinkPreSignedImg = [];
    if (thumbnailUpload?.files || thumbnailUploadFlag0?.files) {
      if (
        typeof thumbnailUpload?.img === 'string' ||
        typeof thumbnailUploadFlag0?.img === 'string'
      ) {
        thumbnail = thumbnailUpload?.files || thumbnailUploadFlag0?.files;
      } else {
        await getPresignedUrlS3(
          'image',
          `uploads/output/images`,
          setFileNameS3(thumbnailUpload?.img[0]?.name),
          'post'
        ).then((resUrl) => {
          thumbnail = resUrl.data.file_path;
          uploadS3ByPresigned(
            resUrl.data.pre_signed,
            thumbnailUpload?.img[0],
            setPercentThumbnail,
            percentThumbnail,
            0
          );
        });
      }
    }
    if (videoUpload?.files) {
      if (checkNewVideo) {
        if (videoUpload.img[0]?.size > CHUNK_FILE) {
          try {
            const resUrl = await uploadVideoWithMulti(videoUpload.img[0], setPercentUpload);
            main_video = resUrl.data.file_path;
            uploadMainVideo(dataEdit, thumbnail, main_video);
          } catch (error) {
            setPopupLoading(false);
            setOpenProgress(false);
          }
        } else {
          await getPresignedUrlS3(
            'video',
            `uploads/output/videos`,
            setFileNameS3(videoUpload.img[0].name),
            'post'
          )
            .then((resUrl) => {
              main_video_pre_signed = resUrl.data.pre_signed;
              main_video = resUrl.data.file_path;
            })
            .then(() => {
              uploadS3ByPresigned(
                main_video_pre_signed,
                videoUpload.img[0],
                setPercentUpload,
                percentUpload,
                0
              )
                .then(() => {
                  uploadMainVideo(dataEdit, thumbnail, main_video);
                })
                .catch((e) => {
                  console.log(e);
                  setPopupLoading(false);
                  setOpenProgress(false);
                });
            })
            .catch((_error) => {
              console.log(_error);
              setPopupLoading(false);
              setOpenProgress(false);
            });
        }
      } else if (!checkNewVideo) {
        main_video = videoUpload.files;
        if (typePost === TYPE_SHORT) {
          const newData = {
            ...dataEdit,
            thumbnail: videoUpload.files && thumbnail,
            duration: +durationVideo <= 60 ? +durationVideo * 1000 : +durationVideo,
            short_video: videoUpload.files,
          };
          delete newData['main_video'];
          dispatch(
            creatorEditArticle(detailPost.id, newData, type, handleShowPopupComfirmSuccess)
          );
          setPopupLoading(false);
          setOpenProgress(false);
        } else if (!imgUpload?.img?.length || imgUpload?.img?.length <= 0) {
          let newData = {
            ...dataEdit,
            thumbnail: main_video && thumbnail,
            duration: +durationVideo <= 60 ? +durationVideo * 1000 : +durationVideo,
            main_video,
          };
          dispatch(
            creatorEditArticle(detailPost.id, newData, type, handleShowPopupComfirmSuccess)
          );
          setPopupLoading(false);
          setOpenProgress(false);
        }
      }
    } else if (videoUploadPublicFlag0?.files) {
      main_video = videoUploadPublicFlag0?.files;
    }

    if (checkNewImg.length > 0) {
      const promiseList = checkNewImg.map((item) => {
        return getPresignedUrlS3(
          'image',
          `uploads/output/images`,
          setFileNameS3(item.name),
          'post'
        );
      });
      Promise.all(promiseList)
        .then((responses) => {
          responses.forEach((element) => {
            if (element.success) {
              listLinkS3Img.push(element.data.file_path);
              listLinkPreSignedImg.push(element.data.pre_signed);
            }
          });
          const promiseGenerate = listLinkPreSignedImg.map((m, i) => {
            return uploadS3ByPresigned(m, checkNewImg[i], setPercentImg, percentImg, i);
          });
          Promise.all(promiseGenerate).then(() => {
            let newData = {
              ...dataEdit,
              thumbnail: main_video && thumbnail,
              duration: +durationVideo <= 60 ? +durationVideo * 1000 : +durationVideo,
              main_video,
            };
            if (typePost === TYPE_SHORT) {
              newData = { ...newData, short_images: listLinkS3Img };
              delete newData['main_video'];
            } else {
              newData = { ...newData, images: listLinkS3Img };
            }
            dispatch(
              creatorEditArticle(detailPost.id, newData, type, handleShowPopupComfirmSuccess)
            );
            setPopupLoading(false);
            setOpenProgress(false);
          });
        })
        .catch((_errors) => {
          console.log(_errors);
          setPopupLoading(false);
          setOpenProgress(false);
        });
    } else if (!checkNewImg.length > 0 && checkOld.length > 0) {
      let newData = {
        ...dataEdit,
        main_video,
        images: listLinkS3Img,
        thumbnail: main_video && thumbnail,
      };

      if (type === 4) {
        newData = {
          ...newData,
          thumbnail: newData?.main_video && thumbnail,
          short_video: newData?.main_video,
          short_images: newData?.images,
        };
        delete newData['images'];
        delete newData['main_video'];
        !newData?.short_video && delete newData['short_video'];
        !newData?.short_images && delete newData['s hort_images'];
      }
      dispatch(
        creatorEditArticle(detailPost.id, newData, type, handleShowPopupComfirmSuccess)
      );
      setPopupLoading(false);
      setOpenProgress(false);
    }
    if (
      !checkNewImg.length > 0 &&
      !checkOld.length > 0 &&
      !videoUpload.files &&
      !checkNewVideo &&
      videoUpload?.files !== 0 &&
      videoUpload?.img !== 0
    ) {
      let newData = {
        ...dataEdit,
        images: null,
        thumbnail: main_video && thumbnail,
        main_video: null,
      };
      if (type === 4) {
        newData = {
          ...newData,
          thumbnail: main_video && thumbnail,
        };
        delete newData['images'];
        delete newData['main_video'];
      }
      dispatch(
        creatorEditArticle(detailPost.id, newData, type, handleShowPopupComfirmSuccess)
      );
      setPopupLoading(false);
      setOpenProgress(false);
    }
  };

  const checkPackage = useMemo(() => {
    return (
      Number(watchFollowFlag?.value) === 0 &&
      (imgUpload?.img?.length > 0 || videoUpload || checkListImg?.img?.length > 0)
    );
  }, [watchFollowFlag?.value, imgUpload, videoUpload]);

  useEffect(() => {
    if ((!imgUpload || imgUpload?.img?.length === 0) && !videoUpload) {
      setValue('follow_flag', optionSend[0]);
    }
  }, [imgUpload, videoUpload]);

  const handleInputThumbnail = (e) => {
    let sizeImgBiggerTenMb = false;
    sizeImgBiggerTenMb = e.target.files[0].size > TEN_MB ? true : false;
    const checkTypes = acceptedImageTypes.includes(e.target.files[0]['type']);
    if (e.target.files[0] && checkTypes && !sizeImgBiggerTenMb) {
      const file = URL.createObjectURL(e.target.files[0]);
      setThumbnailUpload((prev) => ({ ...prev, img: e.target.files, files: file }));
    } else {
      sizeImgBiggerTenMb && failImgThan10Mb(e.target.files[0].size);
      !checkTypes && failImgInvalid(e.target.files[0].name);
    }
  };

  const contentTypeNormal = () => {
    return (
      <>
        {!!videoUpload?.files && (
          <div className='item-wrap mb-3'>
            <div className='item-label'>{t('Creator.PostArticle.labelThumbnail1')}</div>
            <div className={`main-thumbnail mt-1`}>
              {thumbnailUpload.img?.length > 0 ? (
                <BlueImg3 url={`${decryptPath(thumbnailUpload.files)}`}>
                  <div className='bg-img4 bd-8'></div>
                  <ImgCustom src={thumbnailUpload.files} className='cover-img' />
                </BlueImg3>
              ) : (
                <div className='upload-file-btn'>
                  <UploadImageIcon className='icon' />
                  <span className='text'>
                    {t('ValidateMsg.checkThumbnailUploadMultiLine')}
                  </span>
                </div>
              )}

              <input
                type='file'
                name='main_thumbnail'
                disabled={!isPostRejectByAdmin}
                accept={acceptedImageTypes.map((item) => item).join(',')}
                onChange={handleInputThumbnail}
                onClick={(e) => (e.target.value = null)}
              />
              {isPostRejectByAdmin && (
                <img
                  className='zindex20 btn-remove'
                  onClick={() => {
                    setThumbnailUpload({ img: [], files: null });
                  }}
                  src={BTN_REMOVE}
                  alt='remove'
                />
              )}
            </div>
          </div>
        )}
        <InputWrapper
          id='fsite-answer'
          label={t('Creator.PostArticle.canReply')}
          error={errors?.email ? errors?.email?.message : ''}
          className='form-item-switch'>
          <label className={`switch`}>
            <input
              type='checkbox'
              {...register('enable_comment')}
              value={watchEnableComment}
              checked={watchEnableComment}
              onChange={() => {
                setValue('enable_comment', !watchEnableComment);
              }}
            />
            <span className='slider round'></span>
          </label>
        </InputWrapper>
        {checkPackage && (
          <InputWrapper
            id='fsite-category'
            className='box-category'
            label={t('Creator.PostArticle.selectPlan')}>
            <div className='box-checkbox scroll-bar-custom'>
              {dataCheckBox.length > 0 ? (
                <>
                  {/* check all */}
                  <Checkbox
                    register={{ ...register('all_package') }}
                    type='checkbox'
                    onChange={(e) => handleAllPlanPost(e)}
                    checked={listChecked?.length === dataCheckBox?.length}
                    text={t('Common.allPlan')}
                  />
                  {dataCheckBox.map((item, index) => (
                    <Controller
                      name={`package_ids[${index}]`}
                      control={control}
                      key={index}
                      render={() => {
                        return (
                          <Checkbox
                            id={item.id}
                            type='checkbox'
                            register={{ ...register(`package_ids[${index}]`) }}
                            onChange={(e) => handleOnchangePlanPost(e, item)}
                            text={item.title}
                            checked={item.checked}
                          />
                        );
                      }}
                    />
                  ))}
                </>
              ) : (
                <span style={{ color: '#ff0000' }}>{t('ValidateMsg.anySubscription')}</span>
              )}
            </div>
          </InputWrapper>
        )}

        <InputWrapper className='box-category'>
          <Checkbox
            type='checkbox'
            name={`in_24h_flag`}
            onChange={(e) => {
              setValue('in_24h_flag', watchFlag24 === 1 ? 0 : 1);
              setCheckPostIn24h(!checkPostIn24h);
            }}
            disabled
            checked={watchFlag24 === 1}
            text={t('Creator.PostArticle.postIn24h')}
            value={watchFlag24}
          />
        </InputWrapper>
        {isHaveImgOrVideo && (
          <div className='performer-kyc'>
            <InputWrapper
              label={t('Creator.PostArticle.postContent.PerformerInformation')}
              required></InputWrapper>
            {listPerformer?.length > 0 && (
              <div className='list-performer'>
                {listPerformer?.map((item) => {
                  return (
                    <div
                      key={item?.id}
                      id={item?.id}
                      className='item-performer'>{`${item.last_name_romaji} ${item.first_name_romaji}`}</div>
                  );
                })}
              </div>
            )}
            <div
              className={`btn-performer ${!isPostRejectByAdmin ? 'post-approved' : ''}`}
              onClick={() => {
                if (isPostRejectByAdmin) {
                  setIsShowPerformerPopup(true);
                }
              }}>
              <PerformerIcon
                className='performer-icon'
                color={!isPostRejectByAdmin && '#b1b6bb'}
              />
              {t('Creator.PostArticle.postContent.PerformerInformation')}
            </div>
          </div>
        )}
        <InputWrapper
          id='fsite-type-sell'
          label={t('Creator.PostArticle.settingTimePost')}
          className='box-type-sell'>
          <div className={`box-radio-checkbox post`}>
            <Checkbox
              type='radio'
              checked={!isCheckTimePost}
              value={0}
              register={{ ...register('check_time_post') }}
              onChange={handleCheckTimePost}
              text={t('Creator.PostArticle.notSpecified')}
              disabled={checkPostIn24h}
            />
            <Checkbox
              type='radio'
              register={{ ...register('check_time_post') }}
              onChange={handleCheckTimePost}
              text={t('Creator.PostArticle.specify')}
              checked={isCheckTimePost}
              value={1}
              disabled={checkPostIn24h}
            />
          </div>
        </InputWrapper>
        <div
          className='d-flex date-time-wrapper position-relative'
          style={{ marginBottom: isCheckTimePost && !date1 ? 16 : 0 }}>
          <InputWrapper id='fsite-start-time' className='box-date'>
            <div className='date'>
              <CalendarIconV2
                onClick={() => {
                  if (!(disableStartTime || !isCheckTimePost)) {
                    if (isVisibleCalendar2) {
                      toggleCalendar2();
                    }
                    toggleCalendar1();
                  }
                }}
                color={isVisibleCalendar1 && '#DC143C'}
              />
              <input
                value={
                  date1
                    ? date1
                    : detailPost?.public_time
                    ? convertToCurrentTime(detailPost?.public_time).split(' ')[0]
                    : null
                }
                placeholder='YYYY/MM/DD'
                readOnly
                onClick={() => {
                  if (isVisibleCalendar2) {
                    toggleCalendar2();
                  }
                  toggleCalendar1();
                }}
                disabled={!isCheckTimePost}
              />
              {isVisibleCalendar1 && isCheckTimePost && (
                <ReactCalendar
                  minDate={new Date()}
                  customPosition='top-left'
                  onChange={(e) => {
                    setIsLoadingFirst(false);
                    onChangeDate1(e);
                    setIsChangeTime1(true);
                  }}
                  inputRef={datePickerRef1}
                />
              )}
            </div>
          </InputWrapper>

          <InputWrapper id='fsite-birthday' className='box-time'>
            <CustomSelectTime
              placeholder='--:--'
              timePost={(e) => {
                setIsLoadingFirst(false);
                setTimePost(e);
                setTimePostCheck(e);
                setIsChangeTime1(true);
              }}
              handleOnClick={() => {
                if (isVisibleCalendar1) {
                  toggleCalendar1();
                }
                if (isVisibleCalendar2) {
                  toggleCalendar2();
                }
              }}
              checkDisabled={isCheckTimePost}
              initTime={timePost}
              showIconV2
              colorDefaultIcon='#BEBEBE'
            />
          </InputWrapper>
          {isCheckTimePost &&
            !date1 &&
            !detailPost?.public_time &&
            validateTimePostNormalRequired()}
          {isCheckTimePost &&
            date1 &&
            !timePostCheck &&
            !detailPost?.public_time &&
            validateTimePostNormalRequired({
              right: '0px',
            })}
        </div>
        <InputWrapper
          id='fsite-type-sell'
          label={t('Creator.PostArticle.settingTimePublish')}
          className='box-type-sell'>
          <div
            className={`box-radio-checkbox time-release ${checkPostIn24h && 'in-24h'} post`}>
            <Checkbox
              type='radio'
              register={{ ...register('check_time_release') }}
              onChange={handleCheckTimeRelease}
              text={t('Creator.PostArticle.notSet')}
              checked={!isCheckTimeRelease}
              value={0}
              disabled={checkPostIn24h}
            />
            <Checkbox
              type='radio'
              register={{ ...register('check_time_release') }}
              onChange={handleCheckTimeRelease}
              text={t('Creator.PostArticle.specify')}
              checked={isCheckTimeRelease}
              value={1}
              disabled={checkPostIn24h}
            />
          </div>
        </InputWrapper>
        <div className='d-flex date-time-wrapper position-relative'>
          <InputWrapper id='fsite-start-time' className='box-date'>
            <div className='date'>
              <CalendarIconV2
                onClick={() => {
                  if (isCheckTimeRelease) {
                    if (isVisibleCalendar1) {
                      toggleCalendar1();
                    }
                    toggleCalendar2();
                  }
                }}
                color={isVisibleCalendar2 && '#DC143C'}
              />
              <input
                placeholder='YYYY/MM/DD'
                value={
                  date2
                    ? date2
                    : detailPost?.unpublic_time
                    ? convertToCurrentTime(detailPost?.unpublic_time).split(' ')[0]
                    : null
                }
                readOnly
                onClick={() => {
                  if (isVisibleCalendar1) {
                    toggleCalendar1();
                  }
                  toggleCalendar2();
                }}
                disabled={!isCheckTimeRelease || checkPostIn24h}
              />
              {isVisibleCalendar2 && isCheckTimeRelease && (
                <ReactCalendar
                  minDate={new Date()}
                  customPosition='top-left'
                  onChange={(e) => {
                    setIsLoadingFirst(false);
                    onChangeDate2(e);
                  }}
                  inputRef={datePickerRef2}
                />
              )}
            </div>
          </InputWrapper>

          <InputWrapper id='fsite-birthday' className='box-time'>
            <CustomSelectTime
              placeholder='--:--'
              timePost={(e) => {
                setIsLoadingFirst(false);
                setTimeRelease(e);
                setTimeReleaseCheck(e);
              }}
              handleOnClick={() => {
                if (isVisibleCalendar1) {
                  toggleCalendar1();
                }
                if (isVisibleCalendar2) {
                  toggleCalendar2();
                }
              }}
              checkDisabled={isCheckTimeRelease}
              classNameCustom='top'
              initTime={timeRelease}
              showIconV2
              colorDefaultIcon='#BEBEBE'
            />
          </InputWrapper>
          {isCheckTimeRelease &&
            !date2 &&
            !detailPost?.unpublic_time &&
            validateTimePostNormalRequired()}
          {isCheckTimeRelease &&
            date2 &&
            !timeReleaseCheck &&
            !detailPost?.unpublic_time &&
            validateTimePostNormalRequired({
              right: '0px',
            })}
        </div>
        <div
          className={`see-next ${
            isCheckTimeRelease && !date2 && !detailPost?.unpublic_time ? 'edit' : ''
          }`}
          onClick={() => setSeeNext(false)}>
          {t('Creator.PostArticle.hideSeeNext')}
          <UpOutlined />
        </div>
      </>
    );
  };

  const contentTypeShort = () => {
    return (
      <>
        {!!videoUpload?.files && (
          <div className='item-wrap mb-3'>
            <div className='item-label'>{t('Creator.PostArticle.labelThumbnail1')}</div>
            <div className={`main-thumbnail mt-1`}>
              {thumbnailUpload.img.length > 0 ? (
                <BlueImg3 url={`${decryptPath(thumbnailUpload.files)}`}>
                  <div className='bg-img4 bd-8'></div>
                  <ImgCustom src={thumbnailUpload.files} className='cover-img' />
                </BlueImg3>
              ) : (
                <div className='upload-file-btn'>
                  <UploadImageIcon className='icon' />
                  <span className='text'>
                    {t('ValidateMsg.checkThumbnailUploadMultiLine')}
                  </span>
                </div>
              )}

              <input
                type='file'
                name='main_thumbnail'
                disabled={!isPostRejectByAdmin}
                accept={acceptedImageTypes.map((item) => item).join(',')}
                onChange={handleInputThumbnail}
                onClick={(e) => (e.target.value = null)}
              />
              {thumbnailUpload.files && isPostRejectByAdmin && (
                <img
                  className='zindex20 btn-remove'
                  onClick={() => {
                    setThumbnailUpload({ img: [], files: null });
                  }}
                  src={BTN_REMOVE}
                  alt='remove'
                />
              )}
            </div>
          </div>
        )}

        <InputWrapper
          id='fsite-answer'
          label={t('Creator.PostArticle.canReply')}
          error={errors?.email ? errors?.email?.message : ''}
          className='form-item-switch'>
          <label className={`switch`}>
            <input
              type='checkbox'
              {...register('enable_comment')}
              value={watchEnableComment}
              checked={watchEnableComment}
              onChange={() => {
                setValue('enable_comment', !watchEnableComment);
              }}
            />
            <span className='slider round'></span>
          </label>
        </InputWrapper>

        <InputWrapper className='box-category'>
          <Checkbox
            type='checkbox'
            name={`in_24h_flag`}
            onChange={(e) => {
              setValue('in_24h_flag', watchFlag24 === 1 ? 0 : 1);
              setCheckPostIn24h(!checkPostIn24h);
            }}
            disabled
            checked={watchFlag24 === 1}
            text={t('Creator.PostArticle.postIn24h')}
            value={watchFlag24}
          />
        </InputWrapper>
        {isHaveImgOrVideo && (
          <div className='performer-kyc'>
            {listPerformer?.length > 0 && (
              <div className='list-performer'>
                {listPerformer?.map((item) => {
                  return (
                    <div
                      key={item?.id}
                      id={item?.id}
                      className='item-performer'>{`${item.last_name_romaji} ${item.first_name_romaji}`}</div>
                  );
                })}
              </div>
            )}
            <div
              className={`btn-performer ${!isPostRejectByAdmin ? 'post-approved' : ''}`}
              onClick={() => {
                if (isPostRejectByAdmin) {
                  setIsShowPerformerPopup(true);
                }
              }}>
              <PerformerIcon
                className='performer-icon'
                color={!isPostRejectByAdmin && '#b1b6bb'}
              />
              {t('Creator.PostArticle.postContent.PerformerInformation')}
            </div>
          </div>
        )}
        <div className='see-next' onClick={() => setSeeNext(false)}>
          {t('Creator.PostArticle.hideSeeNext')}
          <UpOutlined />
        </div>
      </>
    );
  };

  const contentType = () => {
    if (typePost === TYPE_NORMAL) {
      return contentTypeNormal();
    }
    return contentTypeShort();
  };

  return (
    <>
      {openProgress && (
        <div className='layer-spinner'>
          <ProcessingStatusBar percentage={pcUpload} />
        </div>
      )}
      {popupLoading && (
        <div className='layer-spinner'>
          <LoadingIconV2 className='spinner-icon' />
        </div>
      )}
      {isShowPerformerPopup && (
        <PerformerPopup
          listPerformer={listPerformer}
          setListPerformer={setListPerformer}
          title={t('Contract.createContract')}
          handleClosePopup={() => setIsShowPerformerPopup(false)}
        />
      )}
      {isVisiblePopUpDeletePost && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePost(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.confirmDeletePost')}>
          <div className='ok btn' onClick={handleDeletePost}>
            {t('Common.buttonYes')}
          </div>
          <div className='btn cancel' onClick={() => setIsVisiblePopDeletePost(false)}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpDeletePostComplete && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePostComplete(e)}
          iconNote={true}
          text={t('Popup.deletePostSucess')}>
          <div
            className='ok btn'
            onClick={() => {
              dispatch(resetIDHomePage());
              dispatch(resetDataInfoOther());
              navigate(`${newPathUser}`);
            }}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpConfirmWarningApproved && (
        <PopupConfirmFan
          isVisiblePopup={() => setIsVisiblePopUpConfirmWarningApproved(false)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.confirmApproved')}>
          <div
            className='ok btn'
            onClick={() => setIsVisiblePopUpConfirmWarningApproved(false)}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}
      <LayoutCreatorRight titlePage={t('Common.postEdit')} className='edit-post-normal-header'>
        <div className='fansite-creator-post-normal edit'>
          {loading || loadingListPackage ? (
            <ShimmerPostDetails className='shimmer-loading' />
          ) : (
            detailPost && (
              <form onSubmit={(e) => e.preventDefault()} className='fansite-form form-edit'>
                <div
                  className={`wrapper-content-ogp ${
                    postDetailMeta?.title ? 'have-meta' : ''
                  }`}>
                  <InputWrapper
                    id='fsite-content'
                    label=''
                    required={true}
                    error={errors?.content ? errors?.content?.message : ''}
                    className={`${imgUpload && 'has-img'} text-post ${
                      postDetailMeta?.title ? 'have-meta' : ''
                    }`}>
                    <Textarea
                      {...register('content')}
                      placeholder={t('Creator.PostArticle.placeholderContent')}
                      maxLength={500}
                      autoSize
                      value={content}
                      onInput={(e) => {
                        if (e.target.value) {
                          clearErrors('content');
                          if (e.target.value.length > 500) {
                            setError('content', {
                              type: 'maxLength',
                              message: t('ValidateMsg.CONTENT_IS_TOO_LONG'),
                            });
                          } else clearErrors('content');
                          if (!isApproved) {
                            if (
                              !patternNoFirstSpacing.test(e.target.value?.toString()?.trim())
                            ) {
                              setError('content', {
                                type: 'firstSpace',
                                message: t('ValidateMsg.contentRequired'),
                              });
                            } else clearErrors('content');
                          }
                        } else {
                          if (!isApproved) {
                            setError('content', {
                              type: 'required',
                              message: t('ValidateMsg.itemRequired'),
                            });
                          }
                        }
                      }}
                      className='content-ogp'
                      onChange={(e) => {
                        setContent(e.target.value);
                        setValue('content', e.target.value);
                      }}
                    />
                  </InputWrapper>
                  {postDetailMeta?.title && (
                    <MetaTagViewer
                      title={postDetailMeta?.title}
                      url={postDetailMeta?.url}
                      thumbnail={postDetailMeta?.thumbnail}
                    />
                  )}
                </div>
                {isApproved && (
                  <div className='box-img-video'>
                    <div className='list-img-video'>
                      <div
                        className={`list-item scroll-bar-custom-horizontal list-imgs ${
                          videoUpload.img?.length > 0 && 'has-video'
                        }`}>
                        {videoUploadPublicFlag0?.files ? (
                          <div
                            className={`box-item ${
                              checkListImg.img?.length > 0 ? 'box-img2' : 'box-img1'
                            }`}>
                            <ImgCustom src={IMG_NO_WATER_MARK} />
                          </div>
                        ) : (
                          <>
                            {videoUpload.img.length > 0 && typePost === TYPE_NORMAL && (
                              <>
                                {videoUpload.files?.indexOf('.m3u8') === -1 && (
                                  <div
                                    className={`box-item box-video1 ${
                                      checkListImg.files.length > 0 ? '' : 'self-video'
                                    }`}>
                                    <Player poster=''>
                                      <source
                                        src={
                                          typeof videoUpload.files === 'string' &&
                                          typeof videoUpload.img === 'string'
                                            ? `${linkS3Video}${decryptPath(videoUpload.files)}`
                                            : videoUpload.files
                                        }
                                      />
                                      <ControlBar>
                                        <CurrentTimeDisplay order={4.1} />
                                        <TimeDivider order={4.2} />
                                        <VolumeMenuButton />
                                      </ControlBar>
                                    </Player>
                                    {isPostRejectByAdmin && (
                                      <CloseIconV2
                                        onClick={() => {
                                          setIsChangeDataImage(true);
                                          setVideoUpload({ img: [], files: null });
                                        }}
                                      />
                                    )}
                                  </div>
                                )}
                                {videoUpload.files?.indexOf('.m3u8') !== -1 && (
                                  <div
                                    className={`box-item w-100 ${
                                      checkListImg.files.length > 0 ? '' : 'self-video'
                                    }`}>
                                    <VideoCustomDetail
                                      src={`${linkS3VideoM3U8}${
                                        decryptPath(videoUpload.files) + '#t=0.1'
                                      }`}
                                      className='video-detail'
                                      isDetailPost
                                      isLiveArchive
                                      handleNavigate={() => {}}
                                    />
                                    {isPostRejectByAdmin && (
                                      <CloseIconV2
                                        onClick={() => {
                                          setIsChangeDataImage(true);
                                          setVideoUpload({ img: [], files: null });
                                        }}
                                      />
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                        {(checkListImg.files.length > 0 || checkListImg.img.length > 0) &&
                          checkListImg.files.map((item, index) => {
                            return (
                              <>
                                <div
                                  key={index}
                                  className={`box-item ${
                                    checkListImg.img?.length > 1 ? 'box-img2' : 'box-img1'
                                  }`}>
                                  <ImgCustom
                                    src={
                                      typeof item === 'string' &&
                                      typeof checkListImg.img[index] === 'string'
                                        ? `${item}`
                                        : item
                                    }
                                    alt=''
                                  />
                                  {isPostRejectByAdmin && (
                                    <CloseIconV2
                                      onClick={() => {
                                        setIsChangeDataImage(true);
                                        handleRemoveImg(index, item);
                                      }}
                                    />
                                  )}
                                </div>
                              </>
                            );
                          })}
                        {(checkListImgPublicFlag0.files.length > 0 ||
                          checkListImgPublicFlag0.img.length > 0) &&
                          checkListImgPublicFlag0.files.map((item, index) => {
                            return (
                              <>
                                <div
                                  className={`box-item ${
                                    checkListImg.img?.length > 0 ? 'box-img2' : 'box-img1'
                                  }`}>
                                  <ImgCustom src={IMG_NO_WATER_MARK} />
                                </div>
                              </>
                            );
                          })}
                      </div>

                      <div
                        className={`list-item list-videos`}
                        style={{ marginBottom: '17px' }}>
                        {typePost === TYPE_SHORT && (
                          <>
                            {(videoUpload.files || videoUpload.img.length > 0) && (
                              <>
                                {videoUpload.files?.indexOf('.m3u8') === -1 && (
                                  <div className={`box-item box-video1 self-video`}>
                                    <VideoDetail
                                      className='video-item'
                                      src={
                                        typeof videoUpload.files === 'string' &&
                                        typeof videoUpload.img === 'string'
                                          ? `${linkS3Video}${decryptPath(videoUpload.files)}`
                                          : videoUpload.files
                                      }
                                    />
                                    {isPostRejectByAdmin && (
                                      <CloseIconV2
                                        onClick={() => {
                                          setIsChangeDataImage(true);
                                          setVideoUpload({ img: [], files: null });
                                        }}
                                      />
                                    )}
                                  </div>
                                )}
                                {videoUpload.files?.indexOf('.m3u8') !== -1 && (
                                  <div className={`box-item`}>
                                    <VideoCustomDetail
                                      src={`${linkS3VideoM3U8}${
                                        decryptPath(videoUpload.files) + '#t=0.1'
                                      }`}
                                      className='video-detail'
                                      isDetailPost
                                      isLiveArchive
                                      handleNavigate={() => {}}
                                    />
                                    {isPostRejectByAdmin && (
                                      <CloseIconV2
                                        onClick={() => {
                                          setIsChangeDataImage(true);
                                          setVideoUpload({ img: [], files: null });
                                        }}
                                      />
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                            {videoUpload?.img === 0 && (
                              <div className={`box-item box-img1`}>
                                <ImgCustom src={IMG_NO_WATER_MARK} />
                              </div>
                            )}
                          </>
                        )}
                      </div>

                      <div className='box-btn-upload d-flex align-items-center'>
                        {inputImage()}
                        {inputVideo()}
                        {inputCamera()}
                      </div>
                      {!isHaveImgOrVideo && !watchContent && (
                        <div className='text-err'>
                          {t('Creator.PostArticle.postShort.noImgWhenOnlyFollow')}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {isShowErrorPackages && (
                  <div className='text-err'>
                    {t('Creator.PostArticle.postShort.noImgWhenOnlyFollow')}
                  </div>
                )}
                {detailPost?.share_type === 2 && !hiddenQuote && (
                  <>
                    {detailPost?.share_post ? (
                      <div
                        style={{
                          cursor: 'pointer',
                          position: 'relative',
                          marginBottom: '10px',
                        }}
                        className={`block-repost-no-img`}
                        onClick={() =>
                          navigate(`${newPathUser}post/${detailPost?.share_post?.id}`)
                        }>
                        <div className='d-flex' style={{ gap: '12px' }}>
                          {detailPost?.share_post?.avatar ? (
                            <ImgCustom
                              onClick={() =>
                                navigate(`${newPathUser}${detailPost?.share_post?.account_id}`)
                              }
                              style={{
                                minWidth: 44,
                                maxWidth: 44,
                                minHeight: 44,
                                maxHeight: 44,
                                borderRadius: '50%',
                                objectFit: 'cover',
                                cursor: 'pointer',
                              }}
                              className='cls-img'
                              screen='avatar_44_44'
                              src={detailPost?.share_post?.avatar}
                            />
                          ) : (
                            <img src={AVATAR_DEFAULT} alt='AVATAR_DEFAULT' />
                          )}
                          <div className='wrap--text'>
                            <p className='mb-0 cls-title fw-bold'>
                              {detailPost?.share_post?.account_name || 'no account name'}
                            </p>
                            <div
                              className='d-flex align-items-center'
                              style={{ fontSize: '12px' }}>
                              <p
                                className='cls-title-2nd cls-date'
                                style={{ color: '#666565', marginTop: '0px' }}>
                                {convertToCurrentTime(
                                  detailPost?.share_post?.public_time ||
                                    detailPost?.share_post?.created_at,
                                  'YYYY/MM/DD HH:mm'
                                )}
                              </p>
                              &nbsp;
                              {detailPost?.share_post?.unpublic_time && (
                                <>
                                  <p
                                    className='cls-title-2nd cls-date ms-1'
                                    style={{
                                      color: '#DC143C',
                                      marginTop: '0px',
                                      border: '1px solid',
                                      padding: '0px 5px',
                                    }}>
                                    <span className='me-1'>{t('Common.publicDay')}</span>
                                    {convertToCurrentTime(
                                      detailPost?.share_post?.unpublic_time,
                                      'YYYY/MM/DD HH:mm'
                                    )}
                                  </p>
                                </>
                              )}
                              {detailPost?.share_post?.in_24h_flag === 1 && (
                                <>
                                  <p
                                    className='cls-title-2nd cls-date ms-1'
                                    style={{
                                      color: '#DC143C',
                                      marginTop: '0px',
                                      border: '1px solid',
                                      padding: '0px 5px',
                                    }}>
                                    <span className='me-1'>{t('Common.publicDay')}</span>
                                    {convertToCurrentTime(
                                      renderTimeAfter24h(detailPost?.share_post?.public_time),
                                      'YYYY/MM/DD HH:mm'
                                    )}
                                  </p>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className='mt-3 block-img1'
                          style={{
                            whiteSpace: 'pre-wrap',
                            margin: '0',
                            fontWeight: '400',
                          }}>
                          <MarkDownRead
                            content={detailPost?.share_post?.content || ''}
                            charLimit={isMobile ? 90 : 160}
                          />
                        </div>
                        <div
                          className='mt-3 block-img1'
                          style={{
                            whiteSpace: 'pre-wrap',
                            margin: '0',
                            fontWeight: '400',
                          }}>
                          {`${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${
                            detailPost?.share_post?.id
                          }`}
                        </div>
                        <div style={{ position: 'absolute', right: 0, top: '5px' }}>
                          {isPostRejectByAdmin && (
                            <CloseIconV2
                              onClick={(e) => {
                                e.stopPropagation();
                                setHiddenQuote(true);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className={`block-repost-no-img`}>{t('Common.unavailable')}</div>
                    )}
                  </>
                )}
                <InputWrapper label={t('Creator.PostArticle.postShort.whoSeePost')}>
                  <Controller
                    name='follow_flag'
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={optionSend}
                        value={optionSend?.find((e) => +e?.value === +field?.value?.value)}
                        onChange={(val) => {
                          field.onChange({ value: +val?.value });
                        }}
                        noOptionsMessage={() => {
                          t('Common.listNull');
                        }}
                        isDisabled={dataProfile?.status !== 1}
                        isSearchable={false}
                        styles={customStyles}
                        components={{
                          Option: CustomOption,
                        }}
                      />
                    )}
                  />
                </InputWrapper>
                {!isApproved && (
                  <InputWrapper
                    id='fsite-answer'
                    label={t('Creator.PostArticle.canReply')}
                    error={errors?.email ? errors?.email?.message : ''}
                    className='form-item-switch'>
                    <label className={`switch`}>
                      <input
                        type='checkbox'
                        {...register('enable_comment')}
                        value={watchEnableComment}
                        checked={watchEnableComment}
                        onChange={() => {
                          setValue('enable_comment', !watchEnableComment);
                        }}
                      />
                      <span className='slider round'></span>
                    </label>
                  </InputWrapper>
                )}
                {isApproved && (
                  <>
                    {!seeNext && (
                      <div className='see-next' onClick={() => setSeeNext(true)}>
                        {t('Creator.PostArticle.seeNext')}
                        <DownOutlined />
                      </div>
                    )}
                    <div style={{ display: `${seeNext ? 'block' : 'none'}` }}>
                      {contentType()}
                    </div>
                  </>
                )}
                <InputWrapper id='fsite-submit' className={`button-submit`}>
                  <div className='fansite-creator-post-normal__footer'>
                    <button
                      className='btn btn-submit'
                      onClick={handleSubmit(onSubmit)}
                      disabled={disabledButton}>
                      {t('Common.buttonComplete')}
                    </button>
                    <button className='btn btn-cancel' onClick={() => navigate(-1)}>
                      {t('Common.buttonCancel')}
                    </button>
                  </div>
                </InputWrapper>
              </form>
            )
          )}
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(EditPostNormal);
