import {
  ENV,
  configParamsGet,
  configParamsPostJSON,
  configParamsPostNoBody,
  updateStatus,
} from 'utils/constant';
import { callApi, callApiWithOutShowError } from 'utils/request';
import Storage from 'utils/storage';
import { queryString } from 'utils/utils';
import { TYPES } from './index';
import { getEnv } from 'configs/env';

const ERROR_POINT_AWARDED = 'Point have been awarded';
const ERROR_POINT_AWARDED_JP = 'ポイントが与えられました';
const ERROR_RETRY = 'ValidateMsg.PAYMENT_RETRY_FAIL';
const ERROR_RETRY_CODE = 'PAYMENT_RETRY_FAIL';

const API = {
  getPaymentPackages: (queries) => `${ENV}/payments/packages?${queryString(queries)}`,
  getSettingSystem: (queries) => `${ENV}/setting/systems/${queries}`,
  getSettingSystemType1TimePurchase: (queries) => `${ENV}/setting/systems/${queries}`,
  postPaymentPonit: (queries) => `${ENV}/payments?${queryString(queries)}`,
  paymentVendo: () => `${ENV}/users/payments/create`,
  verifyVendo: () => `${ENV}/users/payments/verify`,
  cancelVendo: () => `${ENV}/users/payments/cancel`,
  getFanRequestPoint: (queries) => `${ENV}/request-point?${queryString(queries)}`,
  getPackageById: (id) => `${ENV}/packages/${id}`,
  applyVoucherAPI: (code) => `${ENV}/check-voucher/${code}`,
};

const getPaymentPackages = (queries) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_PAYMENT_PACKAGES_REQUEST));
    callApi(
      API.getPaymentPackages(queries),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_PAYMENT_PACKAGES_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_PAYMENT_PACKAGES_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getPackage = (id, cb) => {
  return (dispatch) => {
    callApi(
      API.getPackageById(id),
      configParamsGet,
      null,
      async (response) => {
        cb && cb(response);
      },
      (error) => {
        cb && cb();
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const postPaymentPonit = (queries) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.POST_PAYMENT_POINT_REQUEST));
    callApi(
      API.postPaymentPonit(queries),
      configParamsPostNoBody,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.POST_PAYMENT_POINT_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.POST_PAYMENT_POINT_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getSettingSystem = (queries) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.SETTING_SYSTEM_REQUEST));
    callApi(
      API.getSettingSystem(queries),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.SETTING_SYSTEM_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.SETTING_SYSTEM_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getSettingSystemType1TimePurchase = (queries, handleSuccess) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_SETTING_SYSTEM_TYPE_1_PURCHASE_REQUEST));
    callApi(
      API.getSettingSystemType1TimePurchase(queries),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_SETTING_SYSTEM_TYPE_1_PURCHASE_SUCCESS, {
            data: response.data,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_SETTING_SYSTEM_TYPE_1_PURCHASE_FAILED, {
            error: error.errors,
          })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getFanRequestPoint = (queries, cb, err) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_FAN_REQUEST_POINT_REQUEST));
    callApi(
      API.getFanRequestPoint(queries),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_FAN_REQUEST_POINT_SUCCESS, {
            data: response.data,
            // statusCode: queries?.payment_method === 2,
          })
        );
        cb && cb(response.data?.code);
      },
      (error) => {
        err && err();
        dispatch(updateStatus(TYPES.GET_FAN_REQUEST_POINT_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const requestPointVendo = (queries, handleSuccessRequest) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_REQUEST_POINT_VENDO_REQUEST));
    callApi(
      API.getFanRequestPoint(queries),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_REQUEST_POINT_VENDO_SUCCESS, {
            data: response.data,
          })
        );
        handleSuccessRequest && handleSuccessRequest(response.data?.code);
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_REQUEST_POINT_VENDO_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const verifyTokenVendo = (data, cb) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.VERIFY_PAYMENT_VENDO_REQUEST));
    callApiWithOutShowError(
      API.verifyVendo(),
      configParamsPostJSON(data),
      null,
      async (response) => {
        Storage.remove('transaction_id');
        await dispatch(
          updateStatus(TYPES.VERIFY_PAYMENT_VENDO_SUCCESS, {
            data: response.data,
            status: response.data?.status === 1,
          })
        );
        cb && cb(response?.data);
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.VERIFY_PAYMENT_VENDO_FAILED, { error: error?.errors.message })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const paymentVendo = (data, isFromPurChase, cb, cbFail) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.POST_PAYMENT_POINT_VENDO_REQUEST));
    callApiWithOutShowError(
      API.paymentVendo(),
      configParamsPostJSON(data),
      null,
      async (response) => {
        if (response?.success) {
          await dispatch(
            updateStatus(TYPES.POST_PAYMENT_POINT_VENDO_SUCCESS, {
              data: response?.data,
              status: response.data?.status === 1,
            })
          );
          cb && cb(response?.data);
        } else {
          console.log('ccc 222');
          dispatch(
            updateStatus(TYPES.POST_PAYMENT_POINT_VENDO_FAILED, {
              error: response?.message,
            })
          );
        }
      },
      (error) => {
        if (isFromPurChase) {
          if (
            error?.errors?.message === ERROR_POINT_AWARDED ||
            error?.errors?.message === ERROR_POINT_AWARDED_JP
          ) {
            return (window.location.href = '/purchase');
          }
          if (cbFail) {
            cbFail();
          }
        }
        if (
          error?.errors?.message === ERROR_RETRY ||
          error?.errors?.message === ERROR_RETRY_CODE
        ) {
          dispatch(updateStatus('POST_PAYMENT_POINT_HIDE_LOADING'));
        } else {
          dispatch(
            updateStatus(TYPES.POST_PAYMENT_POINT_VENDO_FAILED, {
              error: error?.errors?.message,
            })
          );
          dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        }
      }
    );
  };
};

const postPaymentPonitVendo = (queries, cb) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.POST_PAYMENT_POINT_VENDO_REQUEST));
    callApi(
      API.postPaymentPonit(queries),
      configParamsPostNoBody,
      null,
      async (response) => {
        cb && cb(response?.data?.code);
      },
      (error) => {
        dispatch(updateStatus(TYPES.POST_PAYMENT_POINT_VENDO_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const cancelPaymentPonitVendo = (code, cb) => {
  return (dispatch) => {
    callApi(
      API.cancelVendo(),
      configParamsPostJSON({
        code,
      }),
      null,
      cb,
      (error) => {
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const applyVoucher = (code, cb) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.APPLY_VOUCHER_PAYMENT_REQUEST));
    callApiWithOutShowError(
      API.applyVoucherAPI(code),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.POST_PAYMENT_POINT_VENDO_SUCCESS, {
            data: response.data,
            status: response.data?.status === 1,
          })
        );
        cb && cb(response?.data);
      },
      (error) => {
        cb && cb(error.errors);
        dispatch(updateStatus(TYPES.APPLY_VOUCHER_PAYMENT_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const checkPoint = ({queries, onSuccess = () => {}}) => {
  const API = {
    checkPoint: (queries) => `${ENV}/phone-otp/check?${queryString(queries)}`,
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CHECK_POINT_REQUEST));
    callApi(
      API.checkPoint(queries),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CHECK_POINT_SUCCESS, {
            data: response,
          })
        );
        onSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.CHECK_POINT_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const sendVerifyPhone = ({ data, onSuccess = () => {}, onFailed = () => {} }) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/phone-otp/send`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.INPUT_VERIFY_PHONE_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.INPUT_VERIFY_PHONE_SUCCESS, {
            data: response.data,
          })
        );
        onSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.INPUT_VERIFY_PHONE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        onFailed(error);
      }
    );
  };
};

const inputVerifyPhone = ({ data, onSuccess = () => {}, onFailed = () => {} }) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/phone-otp/verify`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.UPDATE_INPUT_VERIFY_PHONE_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.UPDATE_INPUT_VERIFY_PHONE_SUCCESS, {
            data: response,
          })
        );
        onSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.UPDATE_INPUT_VERIFY_PHONE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        onFailed(error);
      }
    );
  };
};

const updateStatusPaymentVendo = () => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.UPDATE_STATUS_VENDO_PAYMENT_REQUEST));
  };
};

const updateStatusPaymentFail = (messageError) => {
  return (dispatch) => {
    dispatch(
      updateStatus('PAYMENT_UPDATE_STATUS_PAYMENT_FAIL', {
        messageError,
      })
    );
  };
};

const resetAllPayment = () => {
  return (dispatch) => {
    dispatch(updateStatus('RESET_ALL_DATA_PAYMENT'));
  };
};

export {
  cancelPaymentPonitVendo,
  getFanRequestPoint,
  getPaymentPackages,
  getSettingSystem,
  getSettingSystemType1TimePurchase,
  paymentVendo,
  postPaymentPonit,
  postPaymentPonitVendo,
  requestPointVendo,
  updateStatusPaymentFail,
  updateStatusPaymentVendo,
  verifyTokenVendo,
  getPackage,
  applyVoucher,
  resetAllPayment,
  sendVerifyPhone,
  inputVerifyPhone,
  checkPoint
};
