import React from 'react';
import Avt from '../../images/AvatarDefault.svg';
import { linkS3, onErrImage } from 'utils/utils';
import { useNavigate } from 'react-router-dom';
import { ImgCustom } from '..';
import { newPathUser } from 'constants/layout/constant';

export default function UserItem({
  index,
  item,
  isPlan = false,
  isSubscribe = false,
  isDesc = false,
  isArray = false,
}) {
  const navigate = useNavigate();
  const { isIdol } = useNavigate();
  return (
    <div
      key={index}
      className={`item ${isSubscribe && 'is_subscribe'}`}
      onClick={() => navigate(`${newPathUser}${item?.account_id}`)}>
      <ImgCustom
        src={
          typeof item?.avatar === 'object' || !item?.avatar ? Avt : `${item?.avatar}`
        }
        onError={(e) => onErrImage(e, true)}
        alt='avt'
      />
      <div style={{ display: 'inline-block', position: 'relative' }}>
        <div className='name fw-bold'>{item?.account_name || item?.full_name}</div>
        {isPlan && <div className='name my-1'>{item?.plan_name}</div>}
        {isArray && (
          <div className='name my-1'>
            {item?.array_plan?.map((v, i) => (
              <span>
                {v}{' '}
                {item?.array_plan?.length > 1 && i !== item?.array_plan?.length - 1 && ', '}
              </span>
            ))}
          </div>
        )}
        {isSubscribe && item?.packages && (
          <>
            <span className='list-sub'>
              {item?.packages?.map((x) => (
                <div className={`item-list-sub ${!isIdol ? 'fan' : 'creator'}`}>{x.title}</div>
              ))}
            </span>
          </>
        )}
        {isDesc && <div className='name mt-1'>{item?.description}</div>}
      </div>
    </div>
  );
}
