import { useState, useRef } from 'react';
import ImagesGallery from './ImagesGallery';
import ThumbnailButton from 'components/ThumnailButton';
import { InputWrapper } from 'components/FormLogin';
import './index.scss';
import { pushNotify } from 'components/Notification';
import { useTranslation } from 'react-i18next';

const PreviewMultipleImages = ({
  id,
  maxSizeImage,
  maxSizeVideo,
  description,
  handleSelectImage,
  acceptImage,
  acceptVideo,
  isApprove,
  disabled = false,
}) => {
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const labelFileRef = useRef({});
  const { t } = useTranslation();

  const handleMultipleImages = (e) => {
    if (isApprove) {
      const videosSelect = [];
      const imagesSelect = [];
      const targetFiles = e.target.files;
      const targetFilesObject = [...targetFiles];

      targetFilesObject.map((file) => {
        const isImage = file.type.includes('image');
        const isVideo = file.type.includes('video');
        const currentFile = {
          isImage: isImage,
          type: file.type,
          file,
          url: URL.createObjectURL(file),
          name: file.name,
        };

        const maxSize = isImage ? maxSizeImage : maxSizeVideo;
        const fileType = isImage ? acceptImage : acceptVideo;
        if (+file.size > +maxSize) {
          return pushNotify(
            'error',
            isImage ? `${t('ValidateMsg.imgThan10Mb1')}` : `${t('ValidateMsg.videoThan1Gb1')}`
          );
        } else if (isImage && !fileType.includes(file.type)) {
          return pushNotify('error', `${t('ValidateMsg.typeImg1')}`);
        } else if (isVideo && !fileType.includes(file.type)) {
          return pushNotify('error', `${t('ValidateMsg.typeVideo1')}`);
        } else if (!isVideo && !isImage) {
          pushNotify('error', `${t('ValidateMsg.typeImg1')}`);
          return pushNotify('error', `${t('ValidateMsg.typeVideo1')}`);
        } else if (isImage) {
          return imagesSelect.push(currentFile);
        } else {
          return videosSelect.push(currentFile);
        }
      });

      e.target.value = '';
      if (
        (images.length && videos.length) ||
        (imagesSelect.length && videosSelect.length) ||
        (images.length && videosSelect.length) ||
        (videos.length && imagesSelect.length)
      ) {
        return pushNotify('error', `${t('ValidateMsg.ImgAndVideoRequired')}`);
      } else if (images.length + imagesSelect.length > 10) {
        return pushNotify('error', `${t('ValidateMsg.uploadThan10Img')}`);
      } else if (videos.length + videosSelect?.length > 1) {
        return pushNotify('error', `${t('ValidateMsg.uploadThan1Video')}`);
      }

      setImages([...images, ...imagesSelect]);
      setVideos([...videos, ...videosSelect]);
      handleSelectImage([...images, ...imagesSelect], [...videos, ...videosSelect]);
    } else {
      return pushNotify('error', `${t('Creator.Message.featureNotAvailable')}`);
    }
  };

  const handleDeleteSelectedFile = (e, isImage) => {
    if (isImage) {
      const indexListImage = e - videos.length;
      const listNew = [...images].filter((_, index) => index !== indexListImage);
      setImages(listNew);
      handleSelectImage(listNew, videos);
    } else {
      const listNew = [...videos].filter((_, index) => index !== e);
      setVideos(listNew);
      handleSelectImage(images, listNew);
    }
  };

  return (
    <>
      <div className='form-group mb-3' style={{ position: 'relative' }}>
        <InputWrapper
          label={`${t('PreviewMultipleImages.attached')}`}
          subLabel={description}
          className='mb-0'>
          <div className='group-upload-input'>
            <label htmlFor={id} ref={labelFileRef}>
              <ThumbnailButton
                label={t('PreviewMultipleImages.upload')}
                type='button'
                onClick={(e) => {
                  if (isApprove) {
                    labelFileRef?.current?.click();
                  } else {
                    pushNotify('error', `${t('Creator.Message.featureNotAvailable')}`);
                    e.preventdefault()
                  }
                }}
                disabled={disabled}
              />
            </label>
          </div>
        </InputWrapper>
        <input
          id={id}
          className='d-none'
          type='file'
          accept='image/*, video/mp4, video/quicktime'
          onChange={handleMultipleImages}
          multiple
        />
        <ImagesGallery
          handleDeleteFile={handleDeleteSelectedFile}
          medias={[...videos, ...images]}
          acceptImage={acceptImage}
        />
      </div>
    </>
  );
};

export default PreviewMultipleImages;
