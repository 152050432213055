import Icon from '@ant-design/icons';

const CirclePlusPurpleIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='none'
        viewBox='0 0 16 16'>
        <path
          stroke={props?.color || '#604DE7'}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M10.666 1.333H5.333c-2.667 0-4 1.334-4 4V14c0 .367.3.667.667.667h8.666c2.667 0 4-1.333 4-4V5.334c0-2.667-1.333-4-4-4z'></path>
        <path
          stroke={props?.color || '#604DE7'}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          strokeWidth='1.5'
          d='M5.667 8h4.667M8 10.333V5.666'></path>
      </svg>
    )}
    {...props}
  />
);

export default CirclePlusPurpleIcon;
