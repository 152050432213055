import Icon from '@ant-design/icons';

const AddPhotoIconV2 = (props) => (
  <Icon
    component={() => (
      <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M17.875 3.4375H4.125C3.7453 3.4375 3.4375 3.7453 3.4375 4.125V17.875C3.4375 18.2547 3.7453 18.5625 4.125 18.5625H17.875C18.2547 18.5625 18.5625 18.2547 18.5625 17.875V4.125C18.5625 3.7453 18.2547 3.4375 17.875 3.4375Z'
          stroke='currentColor'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18.5625 13.7465L14.9236 10.1076C14.7947 9.97868 14.6198 9.90625 14.4375 9.90625C14.2552 9.90625 14.0803 9.97868 13.9514 10.1076L10.1111 13.9478C9.9822 14.0768 9.80733 14.1492 9.625 14.1492C9.44266 14.1492 9.26779 14.0768 9.13886 13.9478L7.36113 12.1701C7.2322 12.0412 7.05733 11.9688 6.875 11.9688C6.69266 11.9688 6.51779 12.0412 6.38886 12.1701L3.4375 15.1215'
          stroke='currentColor'
          strokeWidth='1.2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.59375 8.76562C9.06837 8.76562 9.45312 8.38087 9.45312 7.90625C9.45312 7.43163 9.06837 7.04688 8.59375 7.04688C8.11913 7.04688 7.73438 7.43163 7.73438 7.90625C7.73438 8.38087 8.11913 8.76562 8.59375 8.76562Z'
          fill='none'
        />
      </svg>
    )}
    {...props}
  />
);

export default AddPhotoIconV2;
