import React, { useRef, useEffect, useLayoutEffect, useState } from 'react';
import {
  CancelRetweetIcon,
  CancelSussRetweetIcon,
  CloseIcon,
  CommentIcon,
  Edit3Icon,
  FlagIcon,
  FlowUnFlowIcon,
  HeartActiveIcon,
  HeartIcon,
  IconBookmark,
  PlayIcon,
  QuotedIcon,
  RePostIcon,
  RePostIconV2,
  SendChatIcon,
  ShareIcon,
  ThreePointIcon,
  TrashIcon,
  UnFlowIcon,
} from 'images';

import {
  IMG_NO_WATER_MARK_MOBILE,
  convertToCurrentTime,
  formatNumber,
  isAdmin,
  linkS3Video,
  onErrImage,
  decryptPath,
  linkS3VideoM3U8,
} from '../../utils/utils';
import { ChevronLeft, ChevronRight } from 'images';
import Slider from 'react-slick';
import {
  ImgCustom,
  PopupConfirmFan,
  PopupLockPostNormal,
  PopupSharePost,
  ReadMoreLess,
  Textarea,
} from '..';
import { useTranslation } from 'react-i18next';
import useUserRole from 'hooks/useUserRole';
import { useSelector } from 'react-redux';
import CommentPostTimeLine from 'components/comment/commentPostTimeLine';
import { InputWrapper } from 'components/Input/InputWrapper';
import { useDispatch } from 'react-redux';
import useRedirect from 'hooks/useRedirect';
import { getEnv } from 'configs/env';
import './PopupViewFullTimeLine.scss';
import { newPathUser } from 'constants/layout/constant';
import { useNavigate } from 'react-router-dom';
import {
  creatorDeleteArticle,
  postBookMarkDetail,
  creatorGetPostDetail,
  postCommentDetail,
  deleteCommentDetail,
  deleteReplyCommentDetail,
  postFollowCommentDetail,
  postFollowReplyCommentDetail,
  fanFollowCreator,
  updateLikeCommentDetail,
  updateLikeReplyComment,
  updatePostLikeDetail,
  postRetweet,
} from 'store/actions/usersNew';
import ReactPlayer from 'react-player';
import LoadingIconV2 from 'images/LoadingIconV2';
import { StyledDetailCard } from 'components/DetailCard/styles';
import AvatarPlaceholder from 'images/avatarPlaceholder.png';
import { AVATAR_DEFAULT } from 'utils';
import ReactHlsPlayer from 'react-hls-player';
import { useMemo } from 'react';
import useDisableContextMenu from 'hooks/useDisableContextMenu';

const PopupViewFullTimeLine = ({ item, close, idx, setIsOpen, setIdPackage }) => {
  const slideRef = useRef(null);
  const refVideo = useRef(null);
  useDisableContextMenu(refVideo);
  const [pageActive, setPageActive] = useState(idx ? idx : 0);
  const { t } = useTranslation();
  const widthScreen = window.innerWidth;
  const { isIdol } = useUserRole();
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const [popupDeleteComment, setPopupDeleteComment] = useState(null);

  const { detailPost, error } = useSelector((state) => state.usersNew);
  const { data: dataProfile, dataUser } = useSelector((state) => state.users);
  const [valueComment, setValueComment] = useState('');
  const [checkSendChat, setCheckSendChat] = useState(null);
  const [isVisiblePopup, setIsVisiblePopup] = useState(-1);
  const [flgRender, setFlgRender] = useState(false);
  const [flgRender1, setFlgRender1] = useState(false);
  const [widthContent, setWidthContent] = useState();
  const [isPlaying, setIsPlaying] = useState();
  const [isVisiblePopUpDeletePost, setIsVisiblePopDeletePost] = useState(null);
  const [isVisiblePopUpDeletePostComplete, setIsVisiblePopDeletePostComplete] =
    useState(false);
  const [showButton, setShowButton] = useState(true);
  const [isVisiblePopupGhim, setIsVisiblePopupGhim] = useState(-1);
  const [isVisibleQuote, setIsVisibleQuote] = useState(false);
  const [itemPostPopup, setItemPostPopup] = useState();

  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(creatorGetPostDetail(item?.id, false));
  }, []);

  useEffect(() => {
    if (!flgRender1) {
      setIsPlaying(
        detailPost?.medias?.findIndex((i) => i.type === 2) === pageActive &&
          !checkDisabledAction
      );
    }
  }, [detailPost]);

  useLayoutEffect(() => {
    const heightContent = document?.querySelector('.div-content')?.offsetHeight;
    setWidthContent(heightContent);
  }, [flgRender]);

  const checkDisabledAction = useMemo(() => {
    if (detailPost) {
      return (
        (!detailPost?.is_followed && detailPost?.follow_flag) || !detailPost?.is_media_display
      );
    }

    return (!item?.is_followed && item?.follow_flag) || !item?.is_media_display;
  }, [detailPost, item]);

  const disableDisplayMedia = useMemo(() => {
    if (detailPost) {
      return !detailPost.is_media_display;
    }
    return !item.is_media_display;
  }, [detailPost, item]);

  const disableComment = useMemo(() => {
    if (detailPost) {
      return !detailPost.enable_comment;
    }

    return !item.enable_comment;
  }, [detailPost, item]);

  const unFollowed = useMemo(() => {
    if (detailPost) {
      return !detailPost?.is_followed && detailPost?.follow_flag;
    }

    return !item?.is_followed && item?.follow_flag;
  }, [detailPost, item]);

  const handleRetweet = (id, data) => dispatch(postRetweet(id, data));

  useRedirect(error);
  const NextArrow = (props) => {
    const { className, onClick, slideCount } = props;
    return (
      <div
        style={{
          cursor: `${pageActive === item?.medias?.length - 1 && 'default'}`,
          opacity: `${pageActive === item?.medias?.length - 1 && '0.3'}`,
        }}
        className={className}
        onClick={() => {
          pageActive !== item?.medias?.length - 1 && setPageActive(pageActive + 1);
          slideRef.current?.slickGoTo(pageActive + 1);
          setIsPlaying(false);
        }}>
        <ChevronRight />
      </div>
    );
  };
  const PrevArrow = (props) => {
    const { className, onClick, slideCount } = props;
    return (
      <div
        style={{
          cursor: `${pageActive === 0 && 'default'}`,
          opacity: `${pageActive === 0 && '0.3'}`,
        }}
        className={className}
        onClick={() => {
          pageActive !== 0 && setPageActive(pageActive - 1);
          slideRef.current?.slickGoTo(pageActive - 1);
          setIsPlaying(false);
        }}>
        <ChevronLeft />
      </div>
    );
  };

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    swipe: false,
    afterChange: (current) => {
      //setPageActive(current);
      // slideRef?.current?.innerSlider?.list.setAttribute('tabindex', current);
      // slideRef?.current?.innerSlider?.list?.focus();
    },
    initialSlide: idx,
    infinite: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  useEffect(() => {
    slideRef?.current?.innerSlider?.list.setAttribute('tabindex', 0);
    slideRef?.current?.innerSlider?.list?.focus();
  }, [slideRef]);

  const handleLikePost = () => {
    dispatch(updatePostLikeDetail(detailPost));
  };

  const handlePostBookMark = (id) => {
    dispatch(postBookMarkDetail(id));
  };

  const handleSharePost = (dataPC) => {
    setPopupCopyLink(dataPC);
  };

  const GroupIcon = (comment) => {
    return (
      <div
        className={`GroupIcon scroll-bar-custom-horizontal ${unFollowed && 'disabled'} ${
          isAdmin && 'disabled'
        }`}
        style={{ padding: '0 16px' }}>
        <div className={`d-middle-popup-all ${checkDisabledAction && 'disabled'}`}>
          <div className='d-flex align-items-center justify-content-center'>
            {detailPost?.is_liked === 1 ? (
              <>
                {/* đã like */}
                <HeartActiveIcon
                  className={`like-icon active`}
                  onClick={() => {
                    !checkDisabledAction && handleLikePost();
                  }}
                />
              </>
            ) : (
              <>
                {/* chưa like */}
                <HeartIcon
                  style={{
                    color: `${checkDisabledAction ? '#BEBEBE' : '#787880'}`,
                  }}
                  className={`like-icon`}
                  onClick={() => {
                    !checkDisabledAction && handleLikePost();
                  }}
                />
              </>
            )}
            <p
              style={{
                color: `${checkDisabledAction ? '#BEBEBE' : '#787880'}`,
              }}
              className='mb-0 cls-text'>
              {formatNumber(detailPost?.number_like)}
            </p>
          </div>

          <div className='d-middle'>
            <label
              style={{ display: 'flex', alignItems: 'center' }}
              htmlFor={`comment-item-${item?.id}`}>
              <CommentIcon
                className='like-icon'
                style={{
                  color: `${checkDisabledAction ? '#BEBEBE' : '#787880'}`,
                }}
              />
              <p
                style={{
                  color: `${checkDisabledAction ? '#BEBEBE' : '#787880'}`,
                }}
                className='mb-0 cls-text'>
                {formatNumber(detailPost?.number_comment) || 0}
              </p>
            </label>
          </div>
          <div className={`d-middle div-ghim1`}>
            {checkDisabledAction ? (
              <RePostIconV2 />
            ) : (
              <>
                {item?.is_share === 1 ? (
                  <CancelSussRetweetIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setIsVisiblePopupGhim(isVisiblePopupGhim === item?.id ? -1 : item?.id);
                    }}
                    onBlur={() => setIsVisiblePopupGhim(-1)}
                  />
                ) : (
                  <RePostIcon
                    style={{ cursor: 'pointer', color: '#787880' }}
                    onClick={() => {
                      setIsVisiblePopupGhim(isVisiblePopupGhim === item?.id ? -1 : item?.id);
                    }}
                    onBlur={() => setIsVisiblePopupGhim(-1)}
                  />
                )}
                {isVisiblePopupGhim === item?.id && (
                  <div className='popup-ghim1'>
                    <div
                      className='item1'
                      onMouseDown={() => {
                        handleRetweet(item?.id, {
                          share_type: 1,
                        });
                        setIsVisiblePopupGhim(-1);
                      }}>
                      <CancelRetweetIcon />
                      <span className='text'>
                        {item?.is_share === 0 ? t('Common.repost') : t('Common.Unrepost')}
                      </span>
                    </div>
                    <div
                      className='item1'
                      onMouseDown={() => {
                        setItemPostPopup(item);
                        setIsVisibleQuote(true);
                        setIsVisiblePopupGhim(-1);
                      }}>
                      <QuotedIcon />
                      <span className='text'>{t('Common.QuotePost')}</span>
                    </div>
                  </div>
                )}
              </>
            )}
            <span
              style={{
                color: `${checkDisabledAction ? '#BEBEBE' : '#787880'}`,
              }}
              className='number ms-1'>
              {detailPost?.number_share ?? 0}
            </span>
          </div>
          <ShareIcon
            style={{
              color: `${checkDisabledAction ? '#BEBEBE' : '#787880'}`,
            }}
            onClick={() => {
              handleSharePost({
                link: `${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${item?.id}`,
                title: item?.title,
                id: item?.id,
              });
            }}
            className='like-icon'
          />
        </div>
        <div className={`${checkDisabledAction && 'disabled'}`}>
          {detailPost?.is_bookmarked === 1 ? (
            <>
              {/* đã bookmart */}
              <IconBookmark
                style={{
                  color: '#dc143c',
                }}
                fill={'#dc143c'}
                className='cls-icon'
                onClick={() => {
                  !checkDisabledAction && handlePostBookMark(detailPost?.id);
                }}
              />
            </>
          ) : (
            <>
              {/* chưa bookmart */}
              <IconBookmark
                style={{
                  color: `${checkDisabledAction ? '#BEBEBE' : '#787880'}`,
                }}
                fill={'none'}
                className='cls-icon'
                onClick={() => {
                  !checkDisabledAction && handlePostBookMark(detailPost?.id);
                }}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const handleFollowCreator = (dt) => dispatch(fanFollowCreator(dt.user_id));

  const handleDeleteComment = () => {
    const { id, type, idParent } = popupDeleteComment;
    type === 'comment'
      ? dispatch(deleteCommentDetail(id))
      : dispatch(deleteReplyCommentDetail(id, idParent));
    setPopupDeleteComment(false);
  };

  const handleLikeComment = (data) => {
    const { commentId, type, dataPost } = data;
    type === 'comment'
      ? dispatch(updateLikeCommentDetail(commentId, dataPost))
      : dispatch(updateLikeReplyComment(commentId, dataPost));
  };

  const handleFollowUserComment = (data) => {
    const { id, type } = data;
    type === 'comment'
      ? dispatch(postFollowCommentDetail(id))
      : dispatch(postFollowReplyCommentDetail(id));
  };

  const handleTextarea = (e, id, height) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    setValueComment(e.target.value);
    setCheckSendChat(id);
    if (e.target.value?.toString().trim() === '') {
      e.target.style.height = height;
      e.target.style.padding = '9px  30px  9px 12px';
      setCheckSendChat(null);
    }
  };

  const handlePostComment = (id, parent_id) => {
    setFlgRender1(true);
    const data = {
      parent_id: parent_id,
      content: valueComment,
    };
    checkSendChat && dispatch(postCommentDetail(id, data));
    setValueComment('');
    setCheckSendChat(null);
    const textareaId = document.getElementById(`comment-item1-${id}`);
    if (textareaId) textareaId.style.height = '44px';
  };

  const navigateByLink = (postId, type) => {
    navigate(
      `${newPathUser}post${
        Boolean(type === 2) ? '-sale' : Boolean(type === 3 || type === 4) ? '' : ''
      }/update/${postId}`
    );
  };

  const deletePostTimeLine = () => {
    setIsVisiblePopDeletePost(null);
    const { id } = isVisiblePopUpDeletePost;
    dispatch(creatorDeleteArticle(id, () => setIsVisiblePopDeletePostComplete(true)));
  };

  const imageWaterMarkFunc = (item) => (
    <ImgCustom
      src={IMG_NO_WATER_MARK_MOBILE}
      alt={item?.id}
      className='img_no_water_mark'
      avatarErr={false}
    />
  );

  const funcRenderImgOrVideoByType = (item) => {
    return <StyledDetailCard.Wrapper>{imageWaterMarkFunc(item)}</StyledDetailCard.Wrapper>;
  };

  const isMiniUrl = () => {
    return (
      (detailPost?.is_followed === 0 &&
        detailPost?.follow_flag === 1 &&
        detailPost?.user_id !== dataUser?.id) ||
      detailPost?.is_media_display === 0
    );
  };

  return (
    <>
      {popupCopyLink && (
        <PopupSharePost
          isVisiblePopup={() => setPopupCopyLink(false)}
          data={popupCopyLink || {}}
        />
      )}
      {isVisiblePopUpDeletePost && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePost(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.confirmDeletePost')}>
          <div className='btn cancel' onClick={() => setIsVisiblePopDeletePost(null)}>
            {t('Common.buttonNo')}
          </div>
          <div className='ok btn' onClick={deletePostTimeLine}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpDeletePostComplete && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePostComplete(e)}
          iconNote={true}
          text={t('Popup.deletePostSucess')}>
          <div
            className='ok btn'
            onClick={() => {
              setIsVisiblePopDeletePostComplete(false);
              close();
            }}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {popupDeleteComment && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupDeleteComment(false)}
          colorSvg='#FF9F43'
          fillColor={true}
          iconNote
          text={t('Popup.confirmDeleteComment')}
          className='popup-delete'>
          <div className='ok btn' onClick={handleDeleteComment}>
            {t('Common.buttonDelete')}
          </div>
          <div className='btn cancel' onClick={() => setPopupDeleteComment(false)}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}
      <div className='popup-wrap'>
        <div
          className='popup-view-full-post-timeline5 my-page-item'
          style={{ borderBottom: 'none' }}>
          {!detailPost && (
            <div className='loading-timeline'>
              <LoadingIconV2 className='spinner-icon' />
            </div>
          )}
          {detailPost?.is_followed === 0 && detailPost?.follow_flag === 1 ? (
            <div className='lock-screen'>
              <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleFollowCreator(item);
                }}>
                {t('Common.follow')}
              </button>
            </div>
          ) : (
            ''
          )}
          {detailPost?.is_media_display === 0 && (
            <PopupLockPostNormal
              post_id={item.id}
              detailPost={item}
              in_my_page
              point={dataUser?.points}
              setIsOpen={setIsOpen}
              setIdPackage={setIdPackage}
            />
          )}
          <CloseIcon className='close-icon' onClick={close} />
          {detailPost?.medias?.length > 1 && (
            <div
              className={`pagination-slide ${detailPost?.medias?.length === 1 && 'hidden'}`}>
              <span>
                {pageActive + 1}/{detailPost?.medias?.length}
              </span>
            </div>
          )}
          <Slider ref={slideRef} {...settings} className='slide-image-full-view'>
            {detailPost?.medias?.map((item2, index) => (
              <div className='list-images-item' key={index}>
                {item2.public_flag === 1 && (
                  <>
                    {item2.type === 1 || item2.type === 4 ? (
                      <ImgCustom
                        noIMGPlaceholder
                        className='change-bg'
                        screen={isMiniUrl() ? 'mini_32_auto_pc' : 'post_775_720_pc'}
                        src={item2.url}
                        alt=''
                        onError={(e) => onErrImage(e, false)}
                      />
                    ) : (
                      item2.type === 2 && (
                        <div className='video-custom-new' style={{ position: 'relative' }}>
                          {decryptPath(item2?.url) !== 'NULL' &&
                          decryptPath(item2?.url)?.indexOf('.m3u8') === -1 ? (
                            <ReactPlayer
                              ref={refVideo.current}
                              url={linkS3Video + decryptPath(item2?.url) + '#t=0.1'}
                              playing={isPlaying}
                              //className={`${isSafari && 'video-safari'}`}
                              controls={true}
                              onPlay={() => {
                                setIsPlaying(true);
                                setShowButton(false);
                              }}
                              onPause={() => {
                                setIsPlaying(false);
                                setShowButton(true);
                              }}
                              playsInline={true}
                              width='100%'
                              height='100%'
                              config={{
                                file: {
                                  attributes: {
                                    onContextMenu: (e) => e.preventDefault(),
                                    controlsList:
                                      'nodownload noremoteplayback noplaybackrate foobar',
                                    disablepictureinpicture: 'true',
                                  },
                                },
                              }}
                            />
                          ) : (
                            <ReactHlsPlayer
                              playerRef={refVideo}
                              src={`${linkS3VideoM3U8}${decryptPath(item2?.url) + '#t=0.1'}`}
                              autoPlay={isPlaying}
                              controls={true}
                              controlsList='noplaybackrate'
                              onPlay={() => {
                                refVideo.current.play();
                                setShowButton(false);
                              }}
                              onPause={() => {
                                refVideo.current.pause();
                                setShowButton(true);
                              }}
                              width='100%'
                              height='100%'
                              playsInline={true}
                            />
                          )}
                          {showButton && (
                            <div
                              className='button-play-icon'
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowButton(false);
                                setIsPlaying(true);
                                refVideo?.current?.play();
                              }}>
                              <PlayIcon />
                            </div>
                          )}
                        </div>
                      )
                    )}
                  </>
                )}
              </div>
            ))}
          </Slider>
        </div>

        <div className='card-item--detail left-content-comment'>
          <div
            style={{
              display: 'flex',
              gap: '12px',
              position: 'relative',
              padding: '16px',
              minHeight: '101px',
            }}>
            <ImgCustom
              onClick={() => navigate(`${newPathUser}${detailPost?.account_id}`)}
              isPlaceholderAvatar
              screen='avatar_44_44'
              style={{
                minWidth: 44,
                maxWidth: 44,
                minHeight: 44,
                maxHeight: 44,
                borderRadius: '50%',
                objectFit: 'cover',
                cursor: 'pointer',
              }}
              src={detailPost?.avatar ? detailPost?.avatar : AvatarPlaceholder}
              alt=''
              className='cls-img'
            />
            {/* <img
              onClick={() => navigate(`${newPathUser}/${detailPost?.account_id}`)}
              style={{
                minWidth: 44,
                maxWidth: 44,
                minHeight: 44,
                maxHeight: 44,
                borderRadius: '50%',
                objectFit: 'cover',
              }}
              width={44}
              height={44}
              src={detailPost?.avatar ? detailPost?.avatar : AvatarPlaceholder}
              alt=''
              className='cls-img'
            /> */}
            <div className='wrap--text'>
              <p className='mb-0 cls-title' style={{ marginTop: '-3px' }}>
                {detailPost?.account_name}
              </p>
              <p className='cls-title-2nd' style={{ marginTop: '-5px' }}>
                {convertToCurrentTime(
                  detailPost?.public_time || detailPost?.created_at,
                  'YYYY/MM/DD HH:mm'
                )}
              </p>
            </div>
            <div className={`box-popup ${isAdmin && 'disabled'}`}>
              <ThreePointIcon
                className='three-point-icon'
                onClick={() => {
                  setIsVisiblePopup(isVisiblePopup === item?.id ? -1 : item?.id);
                }}
                onBlur={() => setIsVisiblePopup(-1)}
              />
              {isVisiblePopup === item?.id && (
                <div className='popup-edit-comment'>
                  {item?.user_id !== dataUser?.id && (
                    <>
                      <div
                        style={{ marginBottom: `${checkDisabledAction && '5px'}` }}
                        className='item'
                        onMouseDown={() => {
                          handleFollowCreator(item);
                          setIsVisiblePopup(-1);
                        }}>
                        {detailPost?.is_followed === 0 || !detailPost?.is_followed ? (
                          <FlowUnFlowIcon />
                        ) : (
                          <UnFlowIcon />
                        )}
                        <span className='text'>
                          {detailPost?.is_followed === 0 || !detailPost?.is_followed
                            ? t('comment.listComment.flow')
                            : t('comment.listComment.unflow')}
                        </span>
                      </div>
                      {!checkDisabledAction && (
                        <div
                          className='item'
                          onMouseDown={() => {
                            setIsVisiblePopup(-1);
                            navigate(`${newPathUser}post-report`, {
                              state: {
                                post_id: item?.id,
                              },
                            });
                          }}>
                          <FlagIcon />
                          <span className='text'>{t('comment.listComment.iconFlag')}</span>
                        </div>
                      )}
                    </>
                  )}
                  {item?.user_id === dataUser?.id && (
                    <>
                      <div
                        className='item'
                        onMouseDown={() => {
                          navigateByLink(item?.id);
                          setIsVisiblePopup(-1);
                        }}>
                        <Edit3Icon />
                        <span className='text'>{t('comment.listComment.iconEdit')}</span>
                      </div>
                      <div
                        className='item'
                        onMouseDown={() => {
                          setIsVisiblePopDeletePost({ id: item?.id });
                          setIsVisiblePopup(-1);
                        }}>
                        <TrashIcon />
                        <span className='text'>{t('Common.buttonDelete')}</span>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className='div-content ' style={{ padding: '0 16px' }}>
            <ReadMoreLess
              content={item?.content}
              charLimit={isIdol ? (widthScreen > 991 ? 60 : widthScreen > 575 ? 45 : 30) : 30}
              onClick={() => {
                setFlgRender(!flgRender);
              }}
            />
          </div>
          <div className='line'></div>

          {GroupIcon(item?.number_comment)}

          <div className='line'></div>
          <div
            className='comment-wrap-popup'
            style={{ height: `calc(100% - ${widthContent}px - 195px)` }}>
            <div className='sroll-list' id='sroll-list-comment'>
              <CommentPostTimeLine
                dataPost={detailPost}
                handleDeleteComment={(e) => {
                  setPopupDeleteComment(e);
                }}
                handleLikeComment={handleLikeComment}
                dataProfile={dataUser}
                handleFollowUserComment={handleFollowUserComment}
                id={item?.id}
                setFlgRender1={setFlgRender1}
              />
            </div>
            <div className='div-list-cmt'>
              <div className={`comment block`}>
                {dataUser?.avatar ? (
                  <ImgCustom screen='avartar_navbar_60_60' src={dataUser?.avatar} />
                ) : (
                  <img src={AVATAR_DEFAULT} alt='AVATAR_DEFAULT' />
                )}
                <form onSubmit={(e) => e.preventDefault()}>
                  <InputWrapper id='fsite-comment' className='form-item'>
                    <Textarea
                      disabled={disableDisplayMedia || disableComment || unFollowed}
                      onChange={(e) => handleTextarea(e, item?.id, '44px')}
                      placeholder={t('comment.formAddRepComment.placeholder')}
                      onKeyDown={(e) => handleTextarea(e, item?.id, '44px')}
                      value={valueComment}
                      maxLength='500'
                      id={`comment-item1-${item?.id}`}
                      overflowHidden
                    />
                    <SendChatIcon
                      className={`${valueComment !== '' && 'active'}`}
                      onClick={() => valueComment !== '' && handlePostComment(item?.id, 0)}
                    />
                  </InputWrapper>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupViewFullTimeLine;
