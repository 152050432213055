/* eslint-disable no-unused-vars */
import {
  AvatarInfor,
  ImgCustom,
  LayoutCreatorRight,
  NavbarTypePost,
  PopupConfirmFan,
  PopupLoading,
  PopupLockPostNormal,
  PopupSharePost,
} from 'components';
import PopupViewFullMess from 'components/Account/PopupViewFullMess';
import PopupViewFullTimeLine from 'components/Account/PopupViewFullTimeLine';
import HeaderMobileV2 from 'components/HeaderV2/HeaderMobile';
import MarkDownRead from 'components/MarkDownRead';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import QuotePostMyPage from 'components/Popup/quotePostMyPage';
import { CommentPostTimeLineShowAll } from 'components/comment/commentListShowAll';
import { getEnv } from 'configs/env';
import { newPathUser } from 'constants/layout/constant';
import { useClickOutSize } from 'hooks/v2/clickOutSide';
import { usePopupPaymentSub } from 'hooks/v2/usePopupPaymentSub';
import {
  CancelRetweetIcon,
  CancelSussRetweetIcon,
  ChatIcon,
  FlagIcon,
  FlowUnFlowIcon,
  HeartActiveIcon,
  HeartIcon,
  IconBookmark,
  QuotedIcon,
  RePostIcon,
  RePostIconV2,
  ShareIcon,
  ThreePointIcon,
  UnFlowIcon,
} from 'images';
import Blur_img from 'images/Blur_Right.png';
import ImgEmptyData from 'images/img-empty-data.png';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import {
  deleteReplyCommentDetail,
  postFollowCommentDetail,
  postFollowReplyCommentDetail,
} from 'store/actions/myPage';
import {
  creatorDeleteArticle,
  deleteCommentDetail,
  fanFollowCreator,
  getIDTimeline,
  getListPostTimeLine,
  postBookMark,
  postNormalPaymentBuyPackageSuccess,
  postRetweet,
  resetActionUserNew,
  resetCommentDetail,
  resetDataInfoOther,
  resetDetailPost,
  resetIDHomePage,
  updateLikeCommentDetail,
  updatePostLikeDetail,
} from 'store/actions/usersNew';
import { AVATAR_DEFAULT, convertToCurrentTime, onErrImage, renderTimeAfter24h } from 'utils';
import { ContextTimeline, ProviderTimeline } from './contextTimeline';
import { TimelinePost } from './elements';
import CommentForm from './elements/commentForm';
import { StyledTimeline } from './elements/styled';
import './index.scss';
import { useFixedPopup } from 'hooks/v2/useFixedPopup';
import AvatarInforDetail from 'components/Account/AvatarInforDetail';

export const TimelineWrapperContext2 = createContext();

const CountLike = () => {
  const { countNumberView } = useContext(ContextTimeline);
  return <span className='number'>{countNumberView.countNumberLike}</span>;
};
const CountComment = () => {
  const { countNumberView } = useContext(ContextTimeline);
  return <span className='number'>{countNumberView.countNumberComment}</span>;
};
const RenderPostItem = ({
  item,
  index,
  tab,
  refOutSideThree,
  setIsVisiblePopup,
  isVisiblePopup,
  dataUser,
  setIsOpen,
  setIdPackage,
  handleFollowCreator,
  bookmarkFunc,
  checkDisabledAction,
  listImgFunc,
  setItemPostPopup,
  setIsVisibleQuote,
  setIsVisiblePopupGhim,
  handleSharePost,
  bookmarkChange,
  setPopupDeleteComment,
  handleLikeComment,
  handleFollowUserComment,
  setTrickReloadComment,
  trickReloadComment,
  isVisiblePopupGhim,
  handleRetweet,
  handleLikePost,
  page,
  widthScreen,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hoverId, setHoverId] = useState(false);

  const handleTimeline = () => {
    dispatch(getIDTimeline({ id: item?.id, tab: tab, page: page }));
    dispatch(resetIDHomePage());
    dispatch(resetDataInfoOther());
  };
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

  return (
    <ProviderTimeline>
      <div style={{ position: 'relative' }}>
        <div className='d-flex justify-content-between'>
          <div className={`avatar-infor `}>
            <div className='content-left'>
              <ImgCustom
                isPlaceholderAvatar
                screen='avatar_44_44'
                src={item?.avatar ? `${item?.avatar}` : AVATAR_DEFAULT}
                alt='avatar'
                onClick={(e) => {
                  e.stopPropagation();
                  handleTimeline && handleTimeline();
                  navigate(`${newPathUser}${item?.account_id}`, {
                    state: { name: 'homepage' },
                  });
                }}
                onError={(e) => onErrImage(e, true)}
              />
              <div style={{ display: 'flex' }}>
                <div
                  className='wrap--text'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleTimeline && handleTimeline();
                    navigate(`${newPathUser}${item?.account_id}`, {
                      state: { name: 'homepage' },
                    });
                  }}>
                  <span style={{ marginTop: '-5px' }} className='name'>
                    {item?.account_name}
                  </span>
                </div>
                <div
                  style={{
                    border: '1px solid #DC143C',
                    borderRadius: '20px',
                    alignSelf: 'flex-start',
                    padding: '0 5px',
                    cursor: 'pointer',
                    fontSize: '12px',
                    fontWeight: '500'
                  }}
                  className={`btn-followOrUnFollow ${
                    item?.is_followed === 1 ? 'following' : 'no-follow'
                  } ${item?.is_followed === 1 && hoverId === item?.id ? 'hover' : ''}`}
                  onMouseOver={() => {
                    if (item?.is_followed === 1 && item?.id) {
                      !isMobile && setHoverId(item.id);
                    }
                  }}
                  onMouseLeave={() => {
                    if (item?.is_followed === 1) {
                      setHoverId(false);
                    }
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFollowCreator(item);
                  }}>
                  {widthScreen > 991 ? (
                    item?.is_followed === 1
                      ? hoverId === item?.id
                        ? t('Creator.FollowList.unfollow')
                        : t('Creator.FollowList.following')
                      : t('Creator.FollowList.followUs')
                  ) : (
                    item?.is_followed === 1
                      ? t('Creator.FollowList.following')
                    : t('Creator.FollowList.followUs')
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='box-popup' style={{ padding: '16px' }}>
            <span
              className='btn-three-point'
              ref={refOutSideThree}
              onClick={() => {
                setIsVisiblePopup(isVisiblePopup === item?.id ? -1 : item?.id);
              }}
              onBlur={() => setIsVisiblePopup(-1)}>
              <ThreePointIcon style={{ color: '#666565' }} className='three-point-icon' />
            </span>

            {isVisiblePopup === item?.id && (
              <div
                className='popup-edit-comment'
                style={{ top: '40px', right: '10px', zIndex: '36' }}>
                <div
                  className='item'
                  onMouseDown={() => {
                    setIsVisiblePopup(-1);
                    navigate(`${newPathUser}post-report`, {
                      state: {
                        post_id: item?.id,
                        tab: tab,
                      },
                    });
                  }}>
                  <FlagIcon />
                  <span className='text'>{t('comment.listComment.iconFlag')}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        style={{
          position: 'relative',
        }}
        className='mobile-timeline1'>
        <div
          style={{
            position: 'relative',
            cursor: 'pointer',
          }}>
          {item?.is_followed === 0 &&
            item?.follow_flag === 1 &&
            !item?.share_post &&
            item?.user_id !== dataUser?.id && (
              <StyledTimeline.LockScreen1 style={{backgroundImage: `${isFirefox ? `url(${Blur_img})` : 'transparent'}`}}>
                <div className='text-follow 1'>{t('DetailCard.notYetFollow')}</div>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleFollowCreator(item);
                  }}>
                  {t('Common.follow')}
                </button>
              </StyledTimeline.LockScreen1>
            )}
          {item?.follow_flag === 0 && item?.is_media_display === 0 && !item?.share_post && (
            <div
              onClick={() => {
                dispatch(getIDTimeline({ id: item?.id, tab: tab, page: page }));
                return navigate(`${newPathUser}post/${item?.id}`, {
                  state: { tab: tab },
                });
              }}>
              <PopupLockPostNormal
                post_id={item.id}
                detailPost={item}
                in_my_page
                point={dataUser?.points}
                setIsOpen={setIsOpen}
                setIdPackage={setIdPackage}
              />
            </div>
          )}
          {!item?.share_post && listImgFunc(item?.medias, item, item)}
        </div>
        {item?.share_post && (
          <div
            style={{
              position: 'relative',
              cursor: 'pointer',
            }}>
            {item?.is_followed === 0 &&
              item?.follow_flag === 1 &&
              item?.user_id !== dataUser?.id && (
                <StyledTimeline.LockScreen1 style={{backgroundImage: `${isFirefox ? `url(${Blur_img})` : 'transparent'}`}}>
                  <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleFollowCreator(item);
                    }}>
                    {t('Common.follow')}
                  </button>
                </StyledTimeline.LockScreen1>
              )}
            {item?.follow_flag === 0 && item?.is_media_display === 0 && (
              <div
                onClick={() => {
                  dispatch(getIDTimeline({ id: item?.id, tab: tab, page: page }));
                  navigate(`${newPathUser}post/${item?.id}`, {
                    state: { tab: tab },
                  });
                }}>
                <PopupLockPostNormal
                  post_id={item?.id}
                  detailPost={item}
                  in_my_page
                  point={dataUser?.points}
                  setIsOpen={setIsOpen}
                  setIdPackage={setIdPackage}
                />
              </div>
            )}
            {listImgFunc(item?.medias, item, item)}
          </div>
        )}
        {(item?.content || item?.title) && (
          <div className={`content`} style={{ margin: '0 16px' }}>
            <div className='text'>
              <MarkDownRead
                isMeta
                charLimit={widthScreen > 575 ? 150 : 30}
                content={item?.type === 2 ? item?.title : item?.content}
              />
            </div>
          </div>
        )}
        <div className='d-flex content1 al-center mt-2' style={{ marginLeft: '16px' }}>
          <p
            className='cls-title-2nd cls-date me-1'
            style={{
              color: '#666565',
              marginTop: '0px',
              marginBottom: '0px',
              fontSize: '12px',
            }}>
            {convertToCurrentTime(item?.public_time || item?.created_at, 'YYYY/MM/DD HH:mm')}
          </p>
          {item?.unpublic_time && (
            <>
              <p
                className='cls-title-2nd cls-date ms-1 mb-left'
                style={{
                  color: '#DC143C',
                  marginTop: '0px',
                  border: '1px solid',
                  padding: '0px 5px',
                  marginBottom: '0',
                  marginRight: '3px',
                  width: 'fit-content',
                }}>
                <span className='me-1'>{t('Common.publicDay')}</span>
                {convertToCurrentTime(item?.unpublic_time, 'YYYY/MM/DD HH:mm')}
              </p>
            </>
          )}
          {item?.in_24h_flag === 1 && (
            <>
              <p
                className='cls-title-2nd cls-date ms-1 mb-left'
                style={{
                  color: '#DC143C',
                  marginTop: '0px',
                  border: '1px solid',
                  padding: '0px 5px',
                  marginBottom: '0',
                  marginRight: '3px',
                  width: 'fit-content',
                }}>
                <span className='me-1'>{t('Common.publicDay')}</span>
                {convertToCurrentTime(
                  renderTimeAfter24h(item?.public_time),
                  'YYYY/MM/DD HH:mm'
                )}
              </p>
            </>
          )}
        </div>
        {/* func quote start*/}
        {item?.share_post && item?.medias?.length > 0 && (
          <div
            className='block-quote-no-img mt-2'
            onClick={() => {
              dispatch(getIDTimeline({ id: item?.id, tab: tab, page: page }));
              navigate(`${newPathUser}post/${item?.share_post?.id}`);
            }}>
            <AvatarInforDetail
              images={item?.share_post?.avatar}
              name={item?.share_post?.account_name}
              content1={item?.share_post}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`${newPathUser}${item?.share_post?.account_id}`, {
                  state: { tab: tab },
                });
              }}
            />
            {item?.share_post?.content && (
              <div className={`content `}>
                <div className='text'>
                  <MarkDownRead
                    isMeta
                    charLimit={widthScreen > 575 ? 150 : 30}
                    content={item?.share_post?.content}
                  />
                </div>
              </div>
            )}
            <div
              className='d-flex align-items-center'
              style={{ fontSize: '12px', paddingLeft: '16px' }}>
              <p
                className='cls-title-2nd cls-date'
                style={{ color: '#666565', marginTop: '0px', fontSize: '12px' }}>
                {convertToCurrentTime(
                  item?.share_post?.public_time || item?.share_post?.created_at,
                  'YYYY/MM/DD HH:mm'
                )}
              </p>
              &nbsp;
              {item?.share_post?.unpublic_time && (
                <>
                  <p
                    className='cls-title-2nd cls-date ms-1'
                    style={{
                      color: '#DC143C',
                      marginTop: '0px',
                      border: '1px solid',
                      padding: '0px 5px',
                    }}>
                    <span className='me-1'>{t('Common.publicDay')}</span>
                    {convertToCurrentTime(item?.share_post?.unpublic_time, 'YYYY/MM/DD HH:mm')}
                  </p>
                </>
              )}
              {item?.share_post?.in_24h_flag === 1 && (
                <>
                  <p
                    className='cls-title-2nd cls-date ms-1'
                    style={{
                      color: '#DC143C',
                      marginTop: '0px',
                      border: '1px solid',
                      padding: '0px 5px',
                    }}>
                    <span className='me-1'>{t('Common.publicDay')}</span>
                    {convertToCurrentTime(
                      renderTimeAfter24h(item?.share_post?.public_time),
                      'YYYY/MM/DD HH:mm'
                    )}
                  </p>
                </>
              )}
            </div>
            <div className='mt-2 block-img1' style={{ padding: '0 16px', fontWeight: '500' }}>
              {`${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${item?.share_post?.id}`}
            </div>
          </div>
        )}
        {item?.share_post && item?.medias?.length === 0 && (
          <>
            <div
              className={`block-quote mt-2 ${
                item?.share_post?.medias?.length === 0 && 'mb-2'
              }`}
              style={{
                cursor: 'pointer',
                borderRadius: `${item?.share_post?.share_post && '8px'}`,
              }}>
              <AvatarInforDetail
                images={item?.share_post?.avatar}
                name={item?.share_post?.account_name}
                content1={item?.share_post}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`${newPathUser}${item?.share_post?.account_id}`, {
                    state: { tab: tab, name: 'homepage' },
                  });
                }}
              />
              {!item?.share_post?.share_post && (
                <div
                  style={{
                    position: 'relative',
                    cursor: 'pointer',
                  }}>
                  {item?.share_post?.is_followed === 0 &&
                    item?.share_post?.follow_flag === 1 &&
                    item?.share_post?.user_id !== dataUser?.id && (
                      <StyledTimeline.LockScreen2>
                        <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleFollowCreator(item?.share_post);
                          }}>
                          {t('Common.follow')}
                        </button>
                      </StyledTimeline.LockScreen2>
                    )}
                  {item?.share_post?.follow_flag === 0 &&
                    item?.share_post?.is_media_display === 0 && (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(getIDTimeline({ id: item?.id, tab: tab, page: page }));
                          navigate(`${newPathUser}post/${item?.share_post?.id}`);
                        }}>
                        <PopupLockPostNormal
                          is_class={true}
                          post_id={item?.share_post?.id}
                          detailPost={item?.share_post}
                          point={dataUser?.points}
                          setIsOpen={setIsOpen}
                          setIdPackage={setIdPackage}
                        />
                      </div>
                    )}
                  <div style={{ margin: '0 -16px' }}>
                    {listImgFunc(item?.share_post?.medias, item?.share_post, item)}
                  </div>
                </div>
              )}
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(getIDTimeline({ id: item?.id, tab: tab, page: page }));
                  navigate(`${newPathUser}post/${item?.share_post?.id}`);
                }}>
                {item?.share_post?.content && (
                  <div className={`content mt-2`} style={{ margin: '0 16px' }}>
                    <div className='text'>
                      <MarkDownRead
                        isMeta
                        charLimit={widthScreen > 575 ? 150 : 30}
                        content={item?.share_post?.content}
                      />
                    </div>
                  </div>
                )}
                <div
                  className='d-flex align-items-center mt-2'
                  style={{ fontSize: '12px', paddingLeft: '16px' }}>
                  <p
                    className='cls-title-2nd cls-date'
                    style={{ color: '#666565', marginTop: '0px', fontSize: '12px' }}>
                    {convertToCurrentTime(
                      item?.share_post?.public_time || item?.share_post?.created_at,
                      'YYYY/MM/DD HH:mm'
                    )}
                  </p>
                  &nbsp;
                  {item?.share_post?.unpublic_time && (
                    <>
                      <p
                        className='cls-title-2nd cls-date ms-1'
                        style={{
                          color: '#DC143C',
                          marginTop: '0px',
                          border: '1px solid',
                          padding: '0px 5px',
                        }}>
                        <span className='me-1'>{t('Common.publicDay')}</span>
                        {convertToCurrentTime(
                          item?.share_post?.unpublic_time,
                          'YYYY/MM/DD HH:mm'
                        )}
                      </p>
                    </>
                  )}
                  {item?.share_post?.in_24h_flag === 1 && (
                    <>
                      <p
                        className='cls-title-2nd cls-date ms-1'
                        style={{
                          color: '#DC143C',
                          marginTop: '0px',
                          border: '1px solid',
                          padding: '0px 5px',
                        }}>
                        <span className='me-1'>{t('Common.publicDay')}</span>
                        {convertToCurrentTime(
                          renderTimeAfter24h(item?.share_post?.public_time),
                          'YYYY/MM/DD HH:mm'
                        )}
                      </p>
                    </>
                  )}
                </div>
              </div>
              {(item?.share_post?.medias?.length === 0 || item?.share_post?.share_post) && (
                <div
                  className='block-img1 2'
                  style={{
                    whiteSpace: 'pre-wrap',
                    margin: '0',
                    fontWeight: '500',
                    padding: '0px 16px 16px 16px',
                  }}>
                  {`${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${item?.share_post?.id}`}
                </div>
              )}
            </div>
          </>
        )}
        {/* func quote end*/}
      </div>
      {(item?.share_type === 1 || item?.share_type === 2) && !item?.share_post && (
        <div
          className={`block-repost-no-img mt-2`}
          style={{ margin: `${item?.medias?.length > 0 ? '16px' : '0 16px'}` }}>
          {t('Common.unavailable')}
        </div>
      )}
      <div
        className={`interactive ${!item?.is_media_display && item?.type === 1 && 'disabled'}`}
        style={{ background: '#FAFAFA' }}>
        <div className='left'>
          <div className={`item ${checkDisabledAction(item) && 'disabled'}`}>
            {item?.is_liked === 1 ? (
              <>
                {/* đã like */}
                <HeartActiveIcon
                  className={`like-icon active`}
                  onClick={() => {
                    if (!checkDisabledAction(item) && !checkDisabledAction(item)) {
                      handleLikePost(item);
                    }
                  }}
                  disabled={checkDisabledAction(item)}
                />
              </>
            ) : (
              <>
                {/* chưa like */}
                <HeartIcon
                  className={`like-icon`}
                  onClick={() => {
                    if (!checkDisabledAction(item) && !checkDisabledAction(item)) {
                      handleLikePost(item);
                    }
                  }}
                  disabled={checkDisabledAction(item)}
                />
              </>
            )}
            {/* <CountLike /> */}
            <span className='number'>{item?.number_like}</span>
          </div>
          <div className={`item ${checkDisabledAction(item) && 'disabled'}`}>
            <ChatIcon
              className='chat-icon-top'
              onClick={() => {
                dispatch(getIDTimeline({ id: item?.id, tab: tab, page: page }));
                navigate(`${newPathUser}post/${item?.id}`, {
                  state: { tab: tab },
                });
              }}
              disabled={checkDisabledAction(item)}
            />
            <CountComment />
          </div>
          <div
            className={`item d-middle div-ghim1`}
            style={{ pointerEvents: `${item?.pin_share_enable === 0 && 'none'}` }}>
            {checkDisabledAction(item) ? (
              <RePostIconV2 />
            ) : (
              <>
                {item?.is_share === 1 ? (
                  <CancelSussRetweetIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setIsVisiblePopupGhim(isVisiblePopupGhim === item?.id ? -1 : item?.id);
                    }}
                    onBlur={() => setIsVisiblePopupGhim(-1)}
                  />
                ) : (
                  <RePostIcon
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setIsVisiblePopupGhim(isVisiblePopupGhim === item?.id ? -1 : item?.id);
                    }}
                    onBlur={() => setIsVisiblePopupGhim(-1)}
                  />
                )}
                {isVisiblePopupGhim === item?.id && (
                  <div className='popup-ghim1'>
                    <div
                      className='item1'
                      onMouseDown={() => {
                        handleRetweet(item?.id, {
                          share_type: 1,
                        });
                        setIsVisiblePopupGhim(-1);
                      }}>
                      <CancelRetweetIcon />
                      <span className='text'>
                        {item?.is_share === 0 ? t('Common.repost') : t('Common.Unrepost')}
                      </span>
                    </div>
                    <div
                      className='item1'
                      onMouseDown={() => {
                        setItemPostPopup(item);
                        setIsVisibleQuote(true);
                        setIsVisiblePopupGhim(-1);
                      }}>
                      <QuotedIcon />
                      <span className='text'>{t('Common.QuotePost')}</span>
                    </div>
                  </div>
                )}
              </>
            )}
            <span className={`number ms-1 ${checkDisabledAction(item) && 'disabled'}`}>
              {item?.number_share ?? 0}
            </span>
          </div>
          <div className={`item ${checkDisabledAction(item) && 'disabled'}`}>
            <ShareIcon
              className={`share-icon`}
              onClick={() => {
                handleSharePost({
                  link: `${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${item?.id}`,
                  title: item.title,
                });
              }}
              disabled={checkDisabledAction(item)}
            />
          </div>
        </div>
        <div className={`item ${checkDisabledAction(item) && 'disabled'}`}>
          <IconBookmark
            style={{
              color: item?.is_bookmarked === 1 ? '#dc143c' : 'inherit',
            }}
            fill={item?.is_bookmarked === 1 ? '#dc143c' : 'none'}
            onClick={() => {
              !checkDisabledAction(item) && !checkDisabledAction(item) && bookmarkChange(item);
            }}
            className='cls-icon'
          />
        </div>
      </div>
      <div className='comment-wrap' style={{ padding: '0 16px' }}>
        {/* {item?.latest_comment && Object.keys(item?.latest_comment).length > 0 && ( */}
        <CommentPostTimeLineShowAll
          defaultCount={item?.number_comment}
          handleTimeline={() => handleTimeline()}
          dataPost={item}
          handleDeleteComment={(e) => {
            setPopupDeleteComment(e);
          }}
          handleLikeComment={handleLikeComment}
          handleFollowUserComment={handleFollowUserComment}
          setTrickReloadComment={setTrickReloadComment}
          trickReloadComment={trickReloadComment}
          id={item?.id}
          dataProfile={dataUser}
          typeFollow='timeline'
          pages='timeline'
        />
        <div className={`comment block`} style={{ marginBottom: '16px' }}>
          {dataUser?.avatar ? (
            <ImgCustom className={'mt-1'} screen='avatar_44_44' src={dataUser?.avatar} />
          ) : (
            <img src={AVATAR_DEFAULT} alt='AVATAR_DEFAULT' />
          )}
          <CommentForm item={item} setTrickReloadComment={setTrickReloadComment} />
        </div>
      </div>
    </ProviderTimeline>
  );
};

const Timeline = ({ isNormal = false, isCreator = false }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const widthScreen = window.innerWidth;
  const [firstLoading, setFirstLoading] = useState(false);
  const top = document.getElementById('app_cocofans_0');
  const {
    paginationTimeLine,
    listPostTimeLine,
    isLoadingFollowCreate,
    initPageTimeline,
    idTimeline,
    idScrollBackRecommend,
    tabTimeline,
  } = useSelector((state) => state.usersNew);
  const [tab, setTab] = useState(
    idTimeline.tab
      ? idTimeline.tab
      : tabTimeline
      ? tabTimeline
      : location?.state?.tab === 2
      ? 2
      : 1
  );
  const [page, setPage] = useState(
    idTimeline && paginationTimeLine?.current_page ? +paginationTimeLine?.current_page : 1
  );

  const [isShowPopup, setShowPopup] = useState({
    status: false,
    dataItem: {},
  });
  const [isShowPopupMess, setShowPopupMess] = useState({
    status: false,
    dataItemMess: {},
  });
  const { dataUser } = useSelector((state) => state.users);
  const [isVisiblePopup, setIsVisiblePopup] = useState(-1);
  const [isVisiblePopUpDeletePost, setIsVisiblePopDeletePost] = useState(null);
  const [isVisiblePopUpDeletePostComplete, setIsVisiblePopDeletePostComplete] =
    useState(false);
  const [popupDeleteComment, setPopupDeleteComment] = useState(null);
  const [runTimeline, setRunTimeLine] = useState();
  const [flgPause, setFlgPause] = useState(false);
  const handleRunTimeLine = (e) => {
    setRunTimeLine(runTimeline !== e ? e : null);
  };
  const [isVisibleQuote, setIsVisibleQuote] = useState(false);
  const [loadingChangeTab, setLoadingChangeTab] = useState(false);
  const [itemPostPopup, setItemPostPopup] = useState();
  const [isVisiblePopupGhim, setIsVisiblePopupGhim] = useState(-1);

  const [trickReloadComment, setTrickReloadComment] = useState(null);
  const { idPackage, setIsOpen, setIdPackage, PopupPaymentBySub } = usePopupPaymentSub({
    urlRedirect: '',
    onClickSuccess: () => {
      dispatch(postNormalPaymentBuyPackageSuccess(idPackage));
    },
  });
  useFixedPopup(isVisibleQuote);
  // useEffect(() => {
  //   dispatch(resetActionUserNew());
  // }, []);

  // useEffect(() => {
  //   return () => {
  //     dispatch(resetCommentDetail());
  //   };
  // }, []);

  // useEffect(() => {
  //   top && top.scrollIntoView({ top: 0, left: 0, behavior: 'smooth', block: 'start' });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tab]);

  useEffect(() => {
    if (idTimeline) {
      const idPost = document.getElementById(idTimeline.id);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'start',
        });
      }
    }
    // } else if (idScrollBackRecommend) {
    //   const idPost = document.getElementById(idScrollBackRecommend);
    //   if (idPost) {
    //     idPost.scrollIntoView({
    //       block: 'center',
    //     });
    //   }
    // }
  }, [idTimeline]);

  const funCallApiListPostTimeLine = useCallback(
    (rs) => {
      setLoadingChangeTab(true);
      rs === 1 && setFirstLoading(true); // page = 1
      dispatch(
        getListPostTimeLine(
          {
            type: tab === 1 ? 'recommend' : 'follow',
            page: rs,
            limit: 10,
          },
          () => {
            setFirstLoading(false);
            setLoadingChangeTab(false);
          },
          () => {
            setFirstLoading(false);
            setLoadingChangeTab(false);
          }
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tab]
  );

  useEffect(() => {
    if (initPageTimeline < 1) {
      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
      funCallApiListPostTimeLine(1);
    }
  }, [funCallApiListPostTimeLine, tab]);

  const fetchNext = () => {
    const pages = paginationTimeLine?.total / paginationTimeLine?.per_page;
    if (pages > page) {
      setPage(page + 1);
      funCallApiListPostTimeLine(page + 1);
    }
  };

  const handleFollowCreator = (dt) => dispatch(fanFollowCreator(dt.user_id));

  const bookmarkFunc = (post, hidden = false) => <></>;

  const bookmarkChange = (post) => {
    dispatch(postBookMark(post));
  };

  const listImgFunc = (data, item, itemParent) => (
    <TimelinePost
      tab={tab}
      data={data}
      setShowPopup={setShowPopup}
      isShowPopup={isShowPopup}
      item={item}
      itemParent={itemParent}
      flgPause={flgPause}
      page={page}
    />
  );
  const handleSharePost = (data) => {
    setPopupCopyLink(data);
  };

  const handleShowPopup = (e) => {
    setPopupCopyLink(e);
  };

  const checkDisabledAction = (item) => {
    if (
      (item?.is_followed === 0 && item?.follow_flag === 1) ||
      (!item?.is_media_display && item?.type === 1)
    ) {
      return true;
    }
    return false;
  };

  const handleLikePost = (post) => {
    dispatch(updatePostLikeDetail(post));
  };

  const deletePostTimeLine = () => {
    setIsVisiblePopDeletePost(null);
    const { id } = isVisiblePopUpDeletePost;
    dispatch(creatorDeleteArticle(id, () => setIsVisiblePopDeletePostComplete(true)));
  };

  //API LIKE COMMENT
  const handleLikeComment = (data, cb = () => {}) => {
    const { dataPost, type, commentId } = data;
    cb();
    dispatch(updateLikeCommentDetail(commentId, dataPost));
  };

  // DELETE COMMENT
  const handleDeleteComment = () => {
    const { id, type, idParent, cb = () => {}, postId } = popupDeleteComment;
    type === 'comment'
      ? dispatch(deleteCommentDetail(id, postId || null, cb))
      : dispatch(deleteReplyCommentDetail(id, postId || null, cb));
    setPopupDeleteComment(false);
  };

  // FOLLOW USER IN COMMENT
  const handleFollowUserComment = (data, onRequest = () => {}, onFinally = () => {}) => {
    const { id, type } = data;
    type === 'comment'
      ? dispatch(postFollowCommentDetail(id, onRequest, onFinally))
      : dispatch(postFollowReplyCommentDetail(id, onRequest, onFinally));
  };

  const handleRetweet = (id, data) => dispatch(postRetweet(id, data));

  function extractURLs(text) {
    const urlPattern = /(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/\S*)?/gi;
    return text.match(urlPattern) || [];
  }

  const [refOutSideThree] = useClickOutSize(() => {
    setIsVisiblePopup(-1);
  });

  const Content = (
    <>
      {widthScreen > 1024 && <br />}
      {/* <HeaderMobileV2 /> */}
      <div
        className='mr-top-mb'
        style={{ padding: `${widthScreen < 1024 || isMobile ? '16px' : '0 24px'}` }}>
        <NavbarTypePost
          firstLoading={firstLoading}
          idTimeline={idTimeline}
          funcActive={setTab}
          setPage={setPage}
          revText
          defaultActive={location?.state?.tab === 2 ? 2 : 1}
          isNormal={isNormal}
          isCreator={isCreator}
          classNameCustom={!isCreator ? 'is_fan_post' : 'c157_timeline'}
        />
      </div>
      {isLoadingFollowCreate && <PopupLoading className={'popup-loading'} />}
      <PopupPaymentBySub />
      {firstLoading ? (
        <div style={{ padding: `${isMobile ? '0 16px' : '24px'}` }}>
          <ShimmerPostDetails hasImage imageType='circular' title />
        </div>
      ) : (
        <div
          className='my-page'
          id='page-creator'
          style={{ padding: `${isMobile ? '0px' : '24px'}`, borderRadius: '20px' }}>
          {listPostTimeLine?.length > 0 ? (
            <>
              <InfiniteLoad
                style={{ overflow: 'hidden' }}
                loading={loadingChangeTab}
                data={listPostTimeLine}
                fetchNext={() => fetchNext()}>
                {listPostTimeLine?.length > 0 &&
                  listPostTimeLine.map((item, index) => {
                    return (
                      <div
                        style={{
                          borderBottom: 'none',
                          background: '#FAFAFA',
                          marginBottom: '20px',
                          borderRadius: '8px',
                          display: `${tab === 2 && item?.is_followed === 0 && 'none'}`,
                        }}
                        key={index}
                        id={item?.id}
                        className={`my-page-item lock-popup style-mb-package-timeline ${
                          !item?.is_media_display && item?.type === 1 && 'popup_lock_post'
                        }`}>
                        <TimelineWrapperContext2.Provider
                          value={{ setRunTimeLine, runTimeline, handleRunTimeLine }}>
                          {tab === 1 || (tab === 2 && item?.is_followed === 1) ? (
                            <RenderPostItem
                              {...{
                                item,
                                index,
                                tab,
                                refOutSideThree,
                                setIsVisiblePopup,
                                isVisiblePopup,
                                dataUser,
                                setIsOpen,
                                setIdPackage,
                                handleFollowCreator,
                                bookmarkFunc,
                                checkDisabledAction,
                                listImgFunc,
                                setItemPostPopup,
                                setIsVisibleQuote,
                                setIsVisiblePopupGhim,
                                handleSharePost,
                                bookmarkChange,
                                setPopupDeleteComment,
                                handleLikeComment,
                                handleFollowUserComment,
                                setTrickReloadComment,
                                trickReloadComment,
                                isVisiblePopupGhim,
                                handleRetweet,
                                handleLikePost,
                                page,
                                widthScreen,
                              }}
                            />
                          ) : null}
                        </TimelineWrapperContext2.Provider>
                      </div>
                    );
                  })}
              </InfiniteLoad>
            </>
          ) : (
            !firstLoading && (
              <div className='empty-box'>
                <ImgCustom
                  src={ImgEmptyData}
                  alt='empty-data'
                  style={{ backgroundColor: 'transparent', paddingBottom: '10px' }}
                />
                <div>{tab === 1 ? t('MyPage.ListPostNull4') : t('MyPage.ListPostNull1')}</div>
                <div>{tab === 1 ? t('MyPage.ListPostNull5') : t('MyPage.ListPostNull2')}</div>
                <div>{tab === 1 && t('MyPage.ListPostNull6')}</div>
              </div>
            )
          )}
        </div>
      )}
    </>
  );
  return (
    <>
      {isVisibleQuote && (
        <QuotePostMyPage
          closeModal={() => setIsVisibleQuote(false)}
          isVisibleQuote={isVisibleQuote}
          item={itemPostPopup}
        />
      )}
      {popupCopyLink && (
        <PopupSharePost isVisiblePopup={handleShowPopup} data={popupCopyLink || {}} />
      )}
      {isShowPopupMess?.status && (
        <PopupViewFullMess
          item={isShowPopupMess?.dataItemMess}
          close={() => {
            setShowPopupMess({
              ...isShowPopupMess,
              status: false,
              dataItem: {},
            });
            dispatch(resetDetailPost());
          }}
        />
      )}
      {isVisiblePopUpDeletePost && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePost(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.confirmDeletePost')}>
          <div className='btn cancel' onClick={() => setIsVisiblePopDeletePost(null)}>
            {t('Common.buttonNo')}
          </div>
          <div className='ok btn' onClick={deletePostTimeLine}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpDeletePostComplete && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePostComplete(e)}
          iconNote={true}
          text={t('Popup.deletePostSucess')}>
          <div className='ok btn' onClick={() => setIsVisiblePopDeletePostComplete(false)}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {popupDeleteComment && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupDeleteComment(false)}
          colorSvg='#FF9F43'
          text={t('Popup.confirmDeleteComment')}
          className='popup-delete'>
          <div className='ok btn' onClick={handleDeleteComment}>
            {t('Common.buttonDelete')}
          </div>
          <div className='btn cancel' onClick={() => setPopupDeleteComment(false)}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}
      {isShowPopup?.status && (
        <PopupViewFullTimeLine
          item={isShowPopup?.dataItem}
          idx={isShowPopup?.idx}
          setIsOpen={setIsOpen}
          setIdPackage={setIdPackage}
          close={() => {
            setShowPopup({
              ...isShowPopup,
              status: false,
              dataItem: {},
            });
            dispatch(resetDetailPost());
            setFlgPause(false);
          }}
        />
      )}
      <LayoutCreatorRight className='time-line-home' noHeader>
        {Content}
      </LayoutCreatorRight>
    </>
  );
};

export default Timeline;
