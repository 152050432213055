import Icon from '@ant-design/icons';

const BackIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={26}
        height={26}
        viewBox='0 0 26 26'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M13 0C10.4288 0 7.91543 0.762437 5.77759 2.1909C3.63975 3.61935 1.97351 5.64968 0.989572 8.02512C0.0056327 10.4006 -0.251811 13.0144 0.249797 15.5362C0.751405 18.0579 1.98953 20.3743 3.80762 22.1924C5.6257 24.0105 7.94208 25.2486 10.4638 25.7502C12.9856 26.2518 15.5995 25.9944 17.9749 25.0104C20.3503 24.0265 22.3807 22.3603 23.8091 20.2224C25.2376 18.0846 26 15.5712 26 13C25.996 9.55341 24.6251 6.24912 22.188 3.81201C19.7509 1.3749 16.4466 0.00398351 13 0ZM18 14H10.4141L12.9492 16.5352C13.0422 16.628 13.116 16.7382 13.1664 16.8596C13.2168 16.9809 13.2428 17.111 13.2428 17.2424C13.2429 17.3738 13.2171 17.5039 13.1668 17.6253C13.1166 17.7467 13.0429 17.8571 12.95 17.95C12.8571 18.0429 12.7468 18.1166 12.6253 18.1668C12.5039 18.2171 12.3738 18.2429 12.2424 18.2428C12.111 18.2428 11.9809 18.2168 11.8596 18.1664C11.7382 18.116 11.628 18.0422 11.5352 17.9492L7.2942 13.7083C7.10668 13.5202 7.00138 13.2655 7.00138 13C7.00138 12.7345 7.10668 12.4798 7.2942 12.2917L11.5352 8.05078C11.7227 7.8636 11.977 7.75853 12.242 7.75867C12.507 7.75882 12.7611 7.86415 12.9485 8.05154C13.1359 8.23892 13.2412 8.49303 13.2413 8.75804C13.2415 9.02304 13.1364 9.27726 12.9492 9.46485L10.4141 12H18C18.2652 12 18.5196 12.1054 18.7071 12.2929C18.8946 12.4804 19 12.7348 19 13C19 13.2652 18.8946 13.5196 18.7071 13.7071C18.5196 13.8946 18.2652 14 18 14Z'
          fill='#1A1A1A'
        />
      </svg>
    )}
    {...props}
  />
);

export default BackIcon;
