import Icon from '@ant-design/icons';

const SusessPackageIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={20}
        height={20}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M11.5 2.5H7.75C4 2.5 2.5 4 2.5 7.75V12.25C2.5 16 4 17.5 7.75 17.5'
          stroke='#52C41A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.125 14.5L12.0724 15.25L14.125 13.75'
          stroke='#52C41A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.5 5.5V10.75L8.25 9'
          stroke='#52C41A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.5 10.75L4.75 9'
          stroke='#52C41A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.5 8.5V10.75'
          stroke='#52C41A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16 14.125C16 14.755 15.82 15.3593 15.5114 15.8607C15.3314 16.1693 15.1064 16.4393 14.8429 16.6579C14.2514 17.185 13.48 17.5 12.625 17.5C11.8857 17.5 11.2043 17.2621 10.6514 16.8571C10.285 16.5936 9.97643 16.2529 9.73858 15.8607C9.43001 15.3593 9.25 14.755 9.25 14.125C9.25 13.0643 9.73857 12.1064 10.5164 11.4957C11.095 11.0264 11.8343 10.75 12.625 10.75C13.4157 10.75 14.1421 11.02 14.7143 11.4764C15.4986 12.0936 16 13.0514 16 14.125Z'
          stroke='#52C41A'
          strokeWidth='1.5'
          strokeMiterlimit={10}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M17.5 8.5H14.5C12.25 8.5 11.5 7.75 11.5 5.5V2.5L17.5 8.5Z'
          stroke='#52C41A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default SusessPackageIcon;
