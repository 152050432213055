/* eslint-disable react-hooks/exhaustive-deps */
import {
  AccountSidebar,
  HeaderListIdolV2,
  LayoutCreatorRight,
  MenuSidebarCustom,
} from 'components';
import PickMonthCalendar from 'components/PickMonthCalendar';
import { newPathUser } from 'constants/layout/constant';
import withCreatorRole from 'hocs/withCreatorRole';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { REPORT_TYPE_ENUM, isAdmin, isAgency, isProdEnableLiveStream } from 'utils/utils';
import Content from './content';
import './index.scss';
import Livestream from './livestream';
import Revenues from './revenues';
import Subscriptions from './subscription';
import UserReactions from './user-reactions';
import { isMobile } from 'react-device-detect';
import { CloseIcon, MenuIcon } from 'images';
import { useSelector } from 'react-redux';

const ReportFunctionPage = () => {
  const { t } = useTranslation();
  const [reportComponentType, setReportComponentType] = useState();
  const [dateRevenues, setDateRevenues] = useState();
  const [dateUserReactions, setDateUserReactions] = useState();
  const [dateUserSubscriptions, setDateUserSubscriptions] = useState();
  const [dateContent, setDateContent] = useState();
  const [dateLiveStreams, setDateLiveStreams] = useState();
  const { dataUser } = useSelector((state) => state.users);

  const reportTypes = useMemo(() => {
    const listType = [
      {
        label: `${t('Report.sales_report')}`,
        onEmitDate: (e) => {
          setReportComponentType(REPORT_TYPE_ENUM.revenue);
          setDateRevenues(e);
        },
        type: REPORT_TYPE_ENUM.revenue,
        components: [<Revenues date={dateRevenues} />],
      },
      {
        label: `${t('Report.accounts_report')}`,
        onEmitDate: (e) => {
          setReportComponentType(REPORT_TYPE_ENUM.reactions);
          setDateUserReactions(e);
        },
        type: REPORT_TYPE_ENUM.reactions,
        components: [<UserReactions date={dateUserReactions} />],
      },
      {
        label: `${t('Report.subscription_report')}`,
        onEmitDate: (e) => {
          setReportComponentType(REPORT_TYPE_ENUM.subscription);
          setDateUserSubscriptions(e);
        },
        type: REPORT_TYPE_ENUM.subscription,
        customPositionCalendar: isMobile ? '' : 'top',
        components: [<Subscriptions date={dateUserSubscriptions} />],
      },
      {
        label: `${t('Report.content_report')}`,
        onEmitDate: (e) => {
          setReportComponentType(REPORT_TYPE_ENUM.content);
          setDateContent(e);
        },
        type: REPORT_TYPE_ENUM.content,
        customPositionCalendar: 'top',
        components: [<Content date={dateContent} />],
      },
    ];
    if (+isProdEnableLiveStream === 1) {
      return [
        ...listType,
        {
          label: `${t('Report.live_broadcast_report')}`,
          onEmitDate: (e) => {
            setReportComponentType(REPORT_TYPE_ENUM.livestream);
            setDateLiveStreams(e);
          },
          type: REPORT_TYPE_ENUM.livestream,
          customPositionCalendar: 'top',
          components: [<Livestream date={dateLiveStreams} />],
        },
      ];
    } else {
      return listType;
    }
  }, [
    dateContent,
    dateRevenues,
    dateUserReactions,
    dateUserSubscriptions,
    dateLiveStreams,
    t,
    isProdEnableLiveStream,
  ]);

  return (
    <LayoutCreatorRight
      noButtonBack
      titlePage={t('Common.report')}
      className='chart-report-layout'>
      <div className='bg-white  px-lg-4 chart-report-page'>
        {reportTypes.map(({ type, ...props }, index) => (
          <React.Fragment key={index}>
            <PickMonthCalendar {...props} />
            {reportComponentType === REPORT_TYPE_ENUM[type] && (
              <React.Fragment>{props.components}</React.Fragment>
            )}
          </React.Fragment>
        ))}
      </div>
    </LayoutCreatorRight>
  );
};

export default withCreatorRole(ReportFunctionPage);
