import BackgroundImageIcon from 'images/BackgroungImageIcon';
import Slider from 'react-slick';
import { linkS3 } from 'utils/utils';
import { ImgCustom } from '..';
import './index.scss';

const HomeQuickViewImageSigin = ({ initialImages, className = "" }) => {
  const settings = {
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: initialImages?.length > 1 ? 1 : initialImages?.length,
    slidesToScroll: 1,
    swipeToSlide: true,
    prevArrow: false,
    nextArrow: false,
    arrows: false,
    autoplay: true,
  };

  const renderSlides = () =>
    initialImages?.map((item, index) => (
      <div
        key={index}
        className={`background_img overflow-hidden position-relative ${
          initialImages?.length === 0 ? 'd-flex justify-content-center align-items-center' : ''
        }  `}>
        <div style={{ direction: 'ltr' }} className={`home-quick-views-container-singin d-flex`}>
          <ImgCustom
            src={`${item.url || ''}`}
            alt='home-quick-views'
            className='w-100 h-100'
            style={{ backgroundColor: "transparent" }}
          />
        </div>
        {initialImages?.length === 0 && <BackgroundImageIcon className='bg-icon-smaller' />}
      </div>
    ));

  return (
    <>
    <div className='scren-slide-sigin'>
      <Slider {...settings} className={`${className}`}>{renderSlides(initialImages)}</Slider>
    </div>
    </>
  );
};

export default HomeQuickViewImageSigin;
