import React from 'react';
import './LoadingSystem.scss';

export const LoadingSystem = () => {
  return (
    <>
      <span className='loader'></span>
    </>
  );
};
