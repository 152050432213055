import useUserRole from 'hooks/useUserRole';
import BackHeader from '../../pages/creator/layout/back-header';
import './v2/Layout.scss';
import { HeaderListIdolV2 } from 'components';
import Footer from './Footer/Footer';
import FooterListIdolV2 from 'components/FooterV2/FooterListIdolV2';
import FooterHompage from './FooterHompage/FooterHompage';
import HeaderListIdolHome from 'components/HeaderV2/HeaderListIdolHome';
const LayoutCreatorRight = ({
  children,
  titlePage,
  className,
  linkBack = '',
  link,
  avatarHeader,
  onClickAvatar,
  isAdmin = false,
  page,
  classNameHeader,
  noHeader = false,
  classContainer = '',
  rightContent = null,
  cb = null,
  noButtonBack = false,
  noFooter,
  footerVender = false,
  isFooterHome,
  isHome = false
}) => {
  const { isIdol } = useUserRole();
  return (
    <>
      <div
        className={`layout-right-creator ${isIdol && 'layout-desktop'} layout-desktop ${
          className || ''
        }`}>
        {!isHome &&
          <div className='hide-greater-than-1024 layout-header'>
            <HeaderListIdolV2 />
          </div>
        }
        {isHome &&
          <div className='hide-greater-than-1024 layout-header'>
            <HeaderListIdolHome />
          </div>
        }
        {!noHeader && (
          <BackHeader
            rightContent={rightContent}
            label={titlePage}
            redirectTo={linkBack}
            routeTo={link}
            avatar={avatarHeader}
            onClickAvatar={onClickAvatar}
            isAdmin={isAdmin}
            page={page}
            classNameHeader={classNameHeader}
            cb={cb}
            noButtonBack={noButtonBack}
          />
        )}
        <div className={`content-container ${classContainer}`}>
          {children}
          {footerVender && (
            <div className='footer-vender'>
              <div className='footer-terms'>
                <FooterListIdolV2 />
              </div>
            </div>
          )}
        </div>
        {!noFooter && !isFooterHome && <Footer />}
        {!noFooter && isFooterHome && <FooterHompage />}
      </div>
    </>
  );
};

export default LayoutCreatorRight;
