import Icon from '@ant-design/icons';

const CoinIconV2 = (props) => (
  <Icon
    component={() => (
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clip-path='url(#clip0_2940_22534)'>
          <mask
            id='mask0_2940_22534'
            maskUnits='userSpaceOnUse'
            x='0'
            y='0'
            width='24'
            height='24'>
            <path d='M0 1.90735e-06H24V24H0V1.90735e-06Z' fill='white' />
          </mask>
          <g mask='url(#mask0_2940_22534)'>
            <path
              d='M5.41363 11.5595C5.65598 11.5595 5.85266 11.7561 5.85266 11.9985C5.85266 12.2408 5.65598 12.4375 5.41363 12.4375C5.17129 12.4375 4.97461 12.2408 4.97461 11.9985C4.97461 11.7561 5.17129 11.5595 5.41363 11.5595Z'
              fill='#FFBF00'
            />
            <path
              d='M22.7992 11.9969C22.7992 17.8161 17.8185 22.7969 11.9992 22.7969C6.17995 22.7969 1.19922 17.8161 1.19922 11.9969C1.19922 6.17761 6.17995 1.19688 11.9992 1.19688C17.8185 1.19688 22.7992 6.17761 22.7992 11.9969Z'
              stroke='#FFBF00'
              strokeWidth='1.2'
              stroke-miterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M11.9986 20.4688C7.3989 20.4688 3.52539 16.5952 3.52539 11.9956C3.52539 7.39592 7.3989 3.52241 11.9986 3.52241C16.5982 3.52241 20.4717 7.39592 20.4717 11.9956C20.4717 16.5952 16.5982 20.4688 11.9986 20.4688Z'
              stroke='#FFBF00'
              strokeWidth='1.2'
              stroke-miterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M5.64453 10.2578C6.40931 7.46869 8.96707 5.41318 11.9963 5.41318'
              stroke='#FFBF00'
              strokeWidth='1.2'
              stroke-miterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M14.6335 14.6328C12.9235 14.063 11.0752 14.063 9.36523 14.6328C9.65016 13.7776 9.79284 12.8881 9.79284 11.9987C9.79284 11.1092 9.65016 10.2197 9.36523 9.36452C10.2205 9.64945 11.1099 9.79213 11.9994 9.79213C12.8888 9.79213 13.7783 9.64945 14.6335 9.36452C14.3486 10.2197 14.2059 11.1092 14.2059 11.9987C14.2059 12.8881 14.3486 13.7776 14.6335 14.6328Z'
              stroke='#FFBF00'
              strokeWidth='1.2'
              stroke-miterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </g>
        </g>
        <defs>
          <clipPath id='clip0_2940_22534'>
            <rect width='24' height='24' fill='white' />
          </clipPath>
        </defs>
      </svg>
    )}
    {...props}
  />
);

export default CoinIconV2;
