import { configParamsGet, ENV, updateStatus } from 'utils/constant';
import { callApi } from 'utils/request';
import { queryString } from 'utils/utils';
import { TYPES } from './index';

const API = {
  getListPointIconDonate: (queries) =>
    `${ENV}/donation-message-categories?${queryString(queries)}`,
};
const getListPointIconDonate = (queries, handleSuccess) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_POINT_ICON_DONATE_REQUEST));
    callApi(
      API.getListPointIconDonate(queries),
      configParamsGet,
      () => null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_POINT_ICON_DONATE_SUCCESS, {
            data: response,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      async (error) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_POINT_ICON_DONATE_FAILED, { error: error.errors })
        );
      }
    );
  };
};

export { getListPointIconDonate };
