import { useState } from 'react';
import { createContext } from 'react';

const ContextTimeline = createContext({
  countNumberView: {
    countNumberLike: 0,
    countNumberComment: 0,
    countNumberRepost: 0,
  },
  setCountNumberView: () => {}
});

const ProviderTimeline = ({ children }) => {
  const [countNumberView, setCountNumberView] = useState({
    countNumberLike: 0,
    countNumberComment: 0,
    countNumberRepost: 0,
  });
  return (
    <ContextTimeline.Provider value={{ countNumberView, setCountNumberView }}>
      {children}
    </ContextTimeline.Provider>
  );
};

export { ContextTimeline, ProviderTimeline };
