/* eslint-disable react-hooks/exhaustive-deps */
import { ImgCustom, PopupConfirmFan, PopupLoading } from 'components';
import { InputWrapper } from 'components/FormLogin';
import { pushNotify } from 'components/Notification';
import { getEnv } from 'configs/env';
import useUserRole from 'hooks/useUserRole';
import { EyeIcon, HiddenIcon } from 'images';
import platform from 'platform';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { linkTwitter, signInTwitter } from 'store/actions/twitter';
import { signIn } from 'store/actions/users';
import {
  ENV,
  LOGO_COCO_FANS,
  patternEmail,
  patternNoFirstSpacing,
  reloadBrowserParent,
  STORAGE,
  ERR_LOGIN_TWITTER,
} from 'utils';
import Storage from 'utils/storage.js';
import Footer from 'components/Footer';
import TwitterIconNew from 'images/twitterIconNew';

const SignInTwitter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [checkEmail, setCheckEmail] = useState(false);
  const [checkPassword, setCheckPassword] = useState(false);
  const { t } = useTranslation();
  const { loading, error } = useSelector((state) => state.users);
  const [popupConfirmLogin, setPopupConfirmLogin] = useState(false);
  const { isIdol } = useUserRole();
  const [isLoading, setIsLoading] = useState(false);
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const [isPopupLinkCoco, setIsPopupLinkCoco] = useState(false);
  const [tokenTwitter, setTokenTwitter] = useState({ token: null, secret: null });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });
  const watchEmail = watch('email', '');
  const watchPassword = watch('password', '');
  const isDisabledButton =
    !watchPassword || !watchEmail || watchPassword?.length < 8 || checkEmail || checkPassword;

  const role = isIdol === true ? 'creator' : 'fan';
  const API = {
    getProfile: () => `${ENV}/profile`,
  };

  const onSubmit = (data) => {
    setUserName(data.email);
    setPassword(data.password);
    const newData = {
      email: data.email,
      password: data.password,
      device_os: platform.description,
    };
    dispatch(
      signIn(
        newData,
        0,
        location?.search.substring(1) || location?.state?.link_share?.substring(1)
      )
    );
  };

  useEffect(() => {
    error?.messageId === 'SOMEONE_IS_LOGGED_IN_WITH_THIS_ACCOUNT_DO_YOU_WANT_TO_LOGIN?' &&
      setPopupConfirmLogin(true);
  }, [error, error?.messageId]);

  const handleConfirmLogin = () => {
    const data = { email: userName, password: password, device_os: platform.description };
    dispatch(
      signIn(
        data,
        1,
        (!location.search.includes('verify_age') && location?.search.substring(1)) ||
          location?.state?.link_share?.substring(1)
      )
    );
    setPopupConfirmLogin(false);
  };

  const handleClosePopupLogin = async (key, token = null, secret = null) => {
    setIsLoading(true);
    Storage.set(STORAGE.TOKEN_TWITTER, token);
    Storage.set(STORAGE.SERECT_TOKEN_TWITTER, secret);
    if (key === 'EMAIL_EXIST_YES') {
      setPopupConfirmLogin(false);
      dispatch(
        signInTwitter(
          {
            token,
            secret,
            device_os: platform.description,
            link_flag: 1,
          },
          ({ data }) => {
            data?.access_token && Storage.set(STORAGE.USER_ACCESS_TOKEN, data?.access_token);
            setTimeout(() => {
              window.close();
              window.onunload = refreshParent;
              function refreshParent() {
                window.opener.location.reload();
              }
            }, 1000);
            setIsLoading(false);
          },
          () => {
            setIsLoading(false);
            setTimeout(() => {
              window.close();
            }, 2000);
          }
        )
      );
    } else {
      window.close();
      window.onunload = refreshParent;
      function refreshParent() {
        if (ERR_LOGIN_TWITTER?.includes(key)) window.opener.location.href = '/sign-up';
      }
    }
  };

  const checkCaseLoginTwitter = async (data) => {
    if (data?.access_token) {
      Storage.set(STORAGE.USER_ACCESS_TOKEN, data.access_token);
      const rs = await fetch(API.getProfile(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${data.access_token}`,
        },
      });
      rs.json()
        .then((data) => {
          const dataProfile = data.data;
          dataProfile && reloadBrowserParent();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleLoginTwitter = useCallback(async () => {
    if (location.search) {
      setIsLoading(true);
      const { search } = location;
      const token = search.split('token=')?.[1].split('&secret')?.[0];
      const secret = search.split('secret=')?.[1];
      setTokenTwitter({ token: token, secret: secret });
      if (token && secret) {
        isAuth
          ? dispatch(
              linkTwitter(
                { token, secret },
                null,
                () => {
                  Storage.set(STORAGE.TOKEN_TWITTER, token);
                  Storage.set(STORAGE.SERECT_TOKEN_TWITTER, secret);
                  pushNotify('success', t('ValidateMsg.linkTwitter'));
                  setTimeout(() => {
                    reloadBrowserParent();
                  }, 2000);
                },
                () => {
                  setIsLoading(false);
                  setTimeout(() => {
                    window.close();
                  }, 2000);
                }
              )
            )
          : dispatch(
              signInTwitter(
                {
                  token,
                  secret,
                  device_os: platform.description,
                },
                async ({ data }) => {
                  Storage.set(STORAGE.TOKEN_TWITTER, token);
                  Storage.set(STORAGE.SERECT_TOKEN_TWITTER, secret);
                  data && checkCaseLoginTwitter(data);
                  setIsLoading(false);
                },
                (error) => {
                  setIsLoading(false);
                  if (error === 'EMAIL_EXISTS') {
                    setIsPopupLinkCoco(true);
                    Storage.set(STORAGE.TOKEN_TWITTER, token);
                    Storage.set(STORAGE.SERECT_TOKEN_TWITTER, secret);
                    window.close();
                    window.onunload = redirectParent;
                    function redirectParent() {
                      window.opener.location.href = '/sign-up/confirm';
                    }
                  } else if (ERR_LOGIN_TWITTER?.includes(error)) {
                    handleClosePopupLogin(error, token, secret);
                  } else
                    setTimeout(() => {
                      window.close();
                    }, 2000);
                }
              )
            );
      } else navigate('/sign-in');
    } else navigate('/sign-in');
  }, []);

  useEffect(() => {
    handleLoginTwitter();
  }, []);

  return (
    <>
      {(isLoading || loading) && <PopupLoading className={'popup-loading'} />}

      {isAuth ? (
        <></>
      ) : (
        <>
          {popupConfirmLogin && (
            <PopupConfirmFan
              isVisiblePopup={() => setPopupConfirmLogin(false)}
              colorSvg='#FF9F43'
              text={t('Popup.accoutLoginOtherBrowser')}
              className='popup-confirm'>
              <div className='btn cancel' onClick={() => setPopupConfirmLogin(false)}>
                {t('Common.buttonNo')}
              </div>
              <div className='ok btn' onClick={handleConfirmLogin}>
                {t('Common.buttonYes')}
              </div>
            </PopupConfirmFan>
          )}

          {isPopupLinkCoco ? (
            <PopupConfirmFan
              isVisiblePopup={() => setPopupConfirmLogin(false)}
              hiddenIcon
              text={t('Popup.loginTwitterEmailSuccess')}
              className='popup-confirm choice-option-twitter'>
              <div
                className='btn cancel'
                onClick={() =>
                  handleClosePopupLogin(
                    'EMAIL_EXIST_NO',
                    tokenTwitter.token,
                    tokenTwitter.secret
                  )
                }>
                {t('Common.buttonNo')}
              </div>
              <div
                className='ok btn'
                onClick={() =>
                  handleClosePopupLogin(
                    'EMAIL_EXIST_YES',
                    tokenTwitter.token,
                    tokenTwitter.secret
                  )
                }>
                {t('Common.buttonYes')}
              </div>
            </PopupConfirmFan>
          ) : (
            <div className='twitter-login'>
              <div className='container-login'>
                <div className='login-form'>
                  <div className='login-form'>
                    <form
                      className='form'
                      onSubmit={(e) => e.preventDefault()}
                      autoComplete='off'>
                      <div className='d-center'>
                        <ImgCustom
                          src={LOGO_COCO_FANS}
                          height={32}
                          width={143}
                          alt='logo'
                          className='logo-cocofans img-transparent'
                        />
                      </div>
                      <div className='d-center title text-center'>
                        {isIdol ? (
                          <span>{t('SignIn.title.users')}</span>
                        ) : (
                          <span>{t('SignIn.title.fan')}</span>
                        )}
                      </div>
                      <InputWrapper
                        id='fsite-email'
                        label={t('Common.email')}
                        required={true}
                        error={errors?.email ? errors?.email?.message : ''}>
                        <input
                          type='text'
                          placeholder={t('ValidateMsg.emailRequired')}
                          autoComplete='off'
                          maxLength={255}
                          {...register('email', {
                            required: t('ValidateMsg.emailRequired'),
                            validate: {
                              noFirstSpacing: (value) => {
                                return (
                                  (value &&
                                    patternNoFirstSpacing.test(value.toString().trim())) ||
                                  t('ValidateMsg.emailRequired')
                                );
                              },
                              email: (value) => {
                                return (
                                  (value && patternEmail.test(value.toString().trim())) ||
                                  t('ValidateMsg.EMAIL_FORMAT_INVALID')
                                );
                              },
                            },
                            onChange: (e) => {
                              const { value } = e.target;
                              !patternNoFirstSpacing.test(value.toString().trim()) ||
                              !patternEmail.test(value.toString().trim())
                                ? setCheckEmail(true)
                                : setCheckEmail(false);
                            },
                          })}
                        />
                      </InputWrapper>
                      <InputWrapper
                        id='fsite-password'
                        label={t('Common.password')}
                        required={true}
                        error={errors?.password ? errors?.password?.message : ''}>
                        <div className='password'>
                          <input
                            {...register('password', {
                              required: t('ValidateMsg.passwordRequired'),
                              minLength: {
                                value: 8,
                                message: t('ValidateMsg.minPassword'),
                              },
                              validate: {
                                noFirstSpacing: (value) =>
                                  (value &&
                                    patternNoFirstSpacing.test(value.toString().trim())) ||
                                  t('ValidateMsg.passwordRequired'),
                              },
                              onChange: (e) => {
                                const { value } = e.target;
                                !patternNoFirstSpacing.test(value.toString().trim())
                                  ? setCheckPassword(true)
                                  : setCheckPassword(false);
                              },
                            })}
                            autoComplete='off'
                            placeholder={t('ValidateMsg.passwordRequired')}
                            type={`${hiddenPassword ? 'password' : 'text'}`}
                          />
                          {hiddenPassword ? (
                            <HiddenIcon onClick={() => setHiddenPassword(false)} />
                          ) : (
                            <EyeIcon onClick={() => setHiddenPassword(true)} />
                          )}
                        </div>
                      </InputWrapper>
                      <div className='flat-btn'>
                        <label className='reset-password'>{t('SignIn.forgotPassword')}</label>
                        &nbsp;
                        <label
                          className='here'
                          onClick={() => {
                            navigate('/forgot-password');
                          }}>
                          {t('SignIn.clickHere')}
                        </label>
                      </div>
                      <button
                        className={`btn ${isDisabledButton ? `disabled` : `active`}`}
                        type='submit'
                        disabled={isDisabledButton}
                        onClick={handleSubmit(onSubmit)}>
                        {t('SignIn.login')}
                      </button>
                      <button
                        className={`btn register`}
                        type='button'
                        onClick={() => {
                          Storage.remove(STORAGE.TOKEN_TWITTER);
                          Storage.remove(STORAGE.SERECT_TOKEN_TWITTER);
                          window.location.href = '/sign-up';
                        }}>
                        {t('Common.signUp')}
                      </button>

                      <div className='or'>OR</div>

                      <a
                        href={getEnv(
                          isIdol
                            ? 'REACT_APP_API_TWITTER_CREATOR'
                            : 'REACT_APP_API_TWITTER_FAN'
                        )}
                        className={`btn twitter`}
                        target='popup'
                        onClick={() =>
                          window.open(
                            getEnv(
                              isIdol
                                ? 'REACT_APP_API_TWITTER_CREATOR'
                                : 'REACT_APP_API_TWITTER_FAN'
                            ),
                            'popup',
                            'width=600,height=600,scrollbars=no,resizable=no'
                          )
                        }>
                        <TwitterIconNew /> {t('Common.buttonLoginTwitter')}
                      </a>
                    </form>
                  </div>
                </div>
                <div className={`footer footer-login`}>
                  <Footer isClass={true} />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SignInTwitter;
