import Icon from '@ant-design/icons';

const NoStreamsIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width='100'
        height='100'
        viewBox='0 0 100 100'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M50 62.5C56.9036 62.5 62.5 56.9036 62.5 50C62.5 43.0964 56.9036 37.5 50 37.5C43.0964 37.5 37.5 43.0964 37.5 50C37.5 56.9036 43.0964 62.5 50 62.5Z'
          stroke='#FCECEF'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M30.1126 69.888C27.4981 67.278 25.4239 64.178 24.0087 60.7655C22.5935 57.353 21.8651 53.695 21.8651 50.0007C21.8651 46.3063 22.5935 42.6483 24.0087 39.2358C25.4239 35.8233 27.4981 32.7233 30.1126 30.1133'
          stroke='#FCECEF'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M69.8874 30.1133C72.5019 32.7233 74.5761 35.8233 75.9913 39.2358C77.4065 42.6483 78.135 46.3063 78.135 50.0007C78.135 53.695 77.4065 57.353 75.9913 60.7655C74.5761 64.178 72.5019 67.278 69.8874 69.888'
          stroke='#FCECEF'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M19.0641 80.9343C14.997 76.8743 11.7705 72.0521 9.56907 66.7438C7.36763 61.4355 6.2345 55.7451 6.2345 49.9984C6.2345 44.2517 7.36763 38.5614 9.56907 33.2531C11.7705 27.9447 14.997 23.1226 19.0641 19.0625'
          stroke='#FCECEF'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M80.9359 19.0625C85.003 23.1226 88.2295 27.9447 90.4309 33.2531C92.6324 38.5614 93.7655 44.2517 93.7655 49.9984C93.7655 55.7451 92.6324 61.4354 90.4309 66.7438C88.2295 72.0521 85.003 76.8743 80.9359 80.9343'
          stroke='#FCECEF'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default NoStreamsIcon;
