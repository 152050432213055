import { PopFanRanking } from 'components';
import { NumberViews } from 'components/LiveStream/NumberViews';
import { useDispatch, useSelector } from 'react-redux';
import { useContext } from 'react';
import { LivestreamFanContext } from '../liveStream';
import { formatCoin, linkS3 } from 'utils';
import { useState } from 'react';
import { PopupGeneral } from 'components';

import { CirclePlusPurpleIcon, DollarIcon, LiveRankingIcon, ProfileIcon } from 'images';
import { followUnFollowUserByType } from 'store/actions/lives';
import { useFullScreenHandle } from 'react-full-screen';
import { RealTimeFanViews } from 'components/LiveStream/Videos/Creator/RealTimeFanViews';

import {
  Block,
  Button,
  HoverTooltip,
  Text,
  WrapperBlockWatch,
  WrapperButton,
} from 'pages/creator/layout/livestream/components/styled';
import { NumberDonate } from 'components/LiveStream/NumberDonate';
import { propsStyled } from 'pages/creator/layout/livestream/components/props';
import { ListContentChat } from './ListContentChat';
import lodash from 'lodash';
import { useEffect } from 'react';
import moment from 'moment';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { newPathUser } from 'constants/layout/constant';

export const Content = ({ setIsUserLogin, isAuth = true }) => {
  const { userInfo } = useSelector((state) => state.lives);
  const { liveKey, DATA_RANKING_FAN, t, remainPoint } = useContext(LivestreamFanContext);
  let listSetting = null;
  let ngWords_user = null;
  let listPackages = null;
  let listUsers = null;
  const { i18n } = useTranslation();
  const [showMsg, setShowMsg] = useState([]);
  const [isVisibleFollowPopup, setIsVisibleFollowPopup] = useState(false);
  const handleClosePopup = () => {
    document.body.style.overflow = 'visible';
    setIsVisibleFollowPopup(false);
  };
  if (userInfo?.livestream !== null) {
    if (userInfo?.livestream?.ngWords?.length > 0) {
      listSetting = userInfo?.livestream?.ngWords[0]?.text?.split(',');
      // eslint-disable-next-line no-unused-vars
      listSetting = lodash.without(listSetting, undefined, null, '', ' ');
    }
    if (userInfo?.livestream?.ngWords_user?.length > 0) {
      ngWords_user = userInfo?.livestream?.ngWords_user[0]?.text?.split(',');
      // eslint-disable-next-line no-unused-vars
      ngWords_user = lodash.without(ngWords_user, undefined, null, '', ' ');
    }
    listPackages = userInfo?.livestream?.packages?.filter((pack) => pack.is_checked === 1);
    listUsers = userInfo?.livestream?.user_live;
  }
  useEffect(() => {
    if (userInfo?.livestream !== null) {
      let msg1, msg2, msg3, msg4;
      if (userInfo?.livestream?.all_follow === 1) {
        msg1 = ` ${t('Creator.ReviewLiveStream.follower')} `;
      }
      if (userInfo?.livestream?.all_member === 1) {
        msg2 = ` ${t('Creator.ReviewLiveStream.everyone')} `;
      }

      if (userInfo?.livestream?.user_package_checked) {
        msg3 = ` ${t('Common.subscription')} `;
      }

      if (userInfo?.livestream?.user_individual_checked) {
        msg4 = ` ${t('Creator.ReviewLiveStream.anyIndividual')} `;
      }

      const val = lodash.union([msg1, msg2, msg3, msg4]);
      setShowMsg(val);
    }
  }, [userInfo?.livestream, i18n.language]);

  const [timeRemaining, setTimeRemaining] = useState('00:00');
  const [timePast, setTimePast] = useState('00:00');

  const convertTime = (time) => {
    const hour =
      Math.floor(time / 60) < 9 ? '0' + Math.floor(time / 60) : Math.floor(time / 60);
    const minute = time % 60 < 9 ? '0' + (time % 60) : time % 60;
    return hour + ':' + minute;
  };
  useEffect(() => {
    if (userInfo?.livestream) {
      const timePass = userInfo?.livestream.realtime?.elapsed_time;
      setTimePast(timePass);
      // const dr = moment.duration(dataLiveStream.realtime.time_left, 'HH:mm').asMinutes();
      const minutes_left = userInfo?.livestream.realtime?.minutes_left;
      setTimeRemaining(convertTime(minutes_left));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.livestream]);
  const MINUTE_MS = 15000 * 4; // 60s;
  useEffect(() => {
    // CHECK IF HIEN TAI CHI CON 3 PHUT
    const interval = setInterval(() => {
      const timePlus = moment(timePast, 'HH:mm').add('1', 'minutes').format('HH:mm');
      setTimePast(timePlus);

      const dr = parseInt(moment.duration(timeRemaining, 'HH:mm').asMinutes()) - 1;
      if (dr <= 0) {
        setTimeRemaining('00:00');
      } else {
        setTimeRemaining(convertTime(dr));
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timePast, timeRemaining]);
  const queryRanking = document.querySelector('#ranking_fan_new');
  const checkShowMess = showMsg.filter((pack) => pack !== undefined);
  return (
    <WrapperButton>
      {isAuth && (
        <WrapperBlockWatch>
          <Block display='flex' gap='5px' totip>
            <Text fw='4' fs='2' cl='7' ovftext>
              {t('Common.buttonDeliveryDestination')}：
              {showMsg.length > 0
                ? showMsg.filter((pack) => pack !== undefined).toString()
                : ''}
              {userInfo?.livestream?.user_distribution_checked
                ? `${Boolean(checkShowMess.length) ? ',' : ''} ${t(
                    'Common.buttonDistributionList'
                  )}`
                : ''}
              {userInfo?.livestream?.user_donation_checked
                ? `${
                    Boolean(checkShowMess.length) ||
                    userInfo?.livestream?.user_distribution_checked
                      ? ','
                      : ''
                  } ${t('Common.buttonPayerList')}`
                : ''}
            </Text>
            <HoverTooltip style={{ top: '26px' }}>
              {showMsg.length > 0
                ? showMsg.filter((pack) => pack !== undefined).toString()
                : ''}
              {userInfo?.livestream?.user_distribution_checked
                ? `${Boolean(checkShowMess.length) ? ',' : ''} ${t(
                    'Common.buttonDistributionList'
                  )}`
                : ''}
              {userInfo?.livestream?.user_donation_checked
                ? `${
                    Boolean(checkShowMess.length) ||
                    userInfo?.livestream?.user_distribution_checked
                      ? ','
                      : ''
                  } ${t('Common.buttonPayerList')}`
                : ''}
            </HoverTooltip>
          </Block>

          <Block display='flex' gap='5px' mg='6px 0'>
            <Text fw='1' fs='1' cl='7' bdr='1' pdr='5px'>
              {t('Creator.Livestream.elapsedTime')} {timePast ? `${timePast}` : '00:00'}
            </Text>
            <Text fw='1' fs='1' cl='7'>
              {t('Creator.Livestream.timeLeft')} {timeRemaining ? `${timeRemaining}` : '00:00'}
            </Text>
          </Block>

          <Block display='flex' gap='3px' algc>
            <Block display='flex' totip>
              <Text fw='1' fs='2' cl='7' pdr='5px'>
                {t('Common.possessionPoints')}：
              </Text>
              <Text fw='1' fs='2' cl='2'>
                {formatCoin(remainPoint)}
              </Text>
              <HoverTooltip style={{ top: '26px', left: '78%' }} left='100px'>
                {formatCoin(remainPoint)}
              </HoverTooltip>
              <CirclePlusPurpleIcon
                color='#1480FF'
                style={{ paddingTop: 3, marginLeft: 3 }}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  return (window.location.href = `${newPathUser}purchase`);
                }}
              />
            </Block>
          </Block>
          <Block display='flex' algc gap='10px' mg='4px 0 0 0'>
            <Button
              style={{ marginRight: '-10px', marginLeft: '-6px', background: 'transparent' }}
              bd='none'
              onClick={(event) => {
                queryRanking.click();
                document.body.style.overflow = 'hidden';
              }}>
              <LiveRankingIcon />
            </Button>
            <Button
              bdc='1'
              bgc='6'
              bdr='4px'
              bd='1'
              cl='8'
              mw='76px'
              w='76px'
              maw='80px'
              h='26px'
              dfc
              onClick={() => {
                setIsVisibleFollowPopup(true);
                document.body.style.overflow = 'hidden';
              }}>
              <ProfileIcon className='profile-icon' />
              <Text className='number' fw='2'>
                <NumberViews id={liveKey} />
              </Text>
            </Button>
            <Block dfc gap='5px'>
              <DollarIcon fill={propsStyled.color[2]} size='16.67' />
              <Text cl='9' fw='5' fs='4' trl='0 -1px'>
                <NumberDonate isDonateC092 id={liveKey} />
              </Text>
            </Block>
          </Block>
        </WrapperBlockWatch>
      )}

      <Block style={{ height: `${isAuth ? 'calc(100% - 136px)' : '100%'}` }}>
        <ListContentChat setIsUserLogin={setIsUserLogin} />
      </Block>
      <PopFanRanking
        style={{ display: 'none' }}
        id='ranking_fan_new'
        data={DATA_RANKING_FAN}
        liveKey={liveKey}
      />
      {isVisibleFollowPopup && (
        <PopupGeneral
          generalClass='pp-up-fan-views'
          closeModal={handleClosePopup}
          className={`follow`}
          title={t('Creator.CreateLiveStream.audience')}>
          <RealTimeFanViews show={false} liveKey={liveKey} />
        </PopupGeneral>
      )}
    </WrapperButton>
  );
};
