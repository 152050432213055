import { HeaderListIdolV2, PopupRegisterSuccessFan } from 'components';
import FooterListIdolV2 from 'components/FooterV2/FooterListIdolV2';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './signUpWrapper.scss';

const SignUp = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const widthScreen = window.innerWidth;
  return (
     <>
     <div className='block-div-signin h-100 position-relative' style={{ backgroundColor: 'transparent' }}>
       <HeaderListIdolV2 />
       <div className='container-div'>
       <PopupRegisterSuccessFan>
          <div
            className={`btn go-login w-100`}
            onClick={() => navigate('/sign-in')}
            style={{ cursor: 'pointer' }}>
            {t('Common.goToLogin')}
          </div>
        </PopupRegisterSuccessFan>
       </div>
       <FooterListIdolV2 className={`${widthScreen > 575 && 'position-absolute'}`} />
     </div>
   </>
  );
};

export default SignUp;
