import { MessageItemFan, LayoutFan, PopupLoading } from 'components';
import React, { useEffect, useState } from 'react';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import PopupConfirmDelete from 'components/PopupConfirmDelete';
import { userDeleteGroupMessage, userGetListMessage } from 'store/actions/message';
import './message.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { AVATAR_DEFAULT, LOGO_COCO_FANS } from 'utils/constant';
import { isAdmin as IS_ADMIN, linkS3 } from 'utils/utils';
import { ADMIN_TYPE } from '../../utils/constant';
import { useTranslation } from 'react-i18next';

const ListMessage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [page, setPage] = useState(1);
  const { listMessage, loading } = useSelector((state) => state.message);
  const { t } = useTranslation();
  const [groupMessageDelete, setGroupMessageDelete] = useState({});
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  useEffect(() => {
    !search &&
      dispatch(
        userGetListMessage({
          page: page,
          limit: 20,
        })
      );
  }, [page]);

  const fetchNext = () => {
    setPage(page + 1);
  };

  const handleDelete = () => {
    dispatch(
      userDeleteGroupMessage(groupMessageDelete?.user_id, groupMessageDelete?.index, () => {
        setGroupMessageDelete({});
        setShowPopupConfirm(false);
      })
    );
  };

  const renderButtonNewMess = () => {
    return (
      <div className='message-add-new' style={{ padding: '16px 0 0 16px' }}>
        <button
          onClick={() => navigate('/message/create')}
          className='btn btn-outline-danger  d-flex align-items-center'>
          <PlusOutlined />
          <span className='ms-2'>{t('Messages.buttonCreateMessage')}</span>
        </button>
      </div>
    );
  };

  return (
    <>
      {IS_ADMIN && search ? (
        <PopupLoading className={'popup-loading'} />
      ) : (
        <LayoutFan titleHeader={t('Messages.title')} loading={loading}>
          {showPopupConfirm && (
            <PopupConfirmDelete
              title={t('Popup.confirmDeteleFullMess', {
                name: groupMessageDelete?.user?.account_name,
              })}
              setShowPopup={setShowPopupConfirm}
              handleOnSubmit={handleDelete}
            />
          )}
          {listMessage?.length > 0 ? (
            <>
              {!IS_ADMIN && renderButtonNewMess()}
              <InfiniteLoad loading={loading} data={listMessage || []} fetchNext={fetchNext}>
                <div className='list-buyer-creator pb-3'>
                  {listMessage.map((item, index) => {
                    const { user, id, created_at, user_id, not_seen } = item;
                    const isAdmin = ADMIN_TYPE.includes(user?.user_type);
                    const avatar = isAdmin
                      ? LOGO_COCO_FANS
                      : user?.avatar
                      ? `${user?.avatar}`
                      : AVATAR_DEFAULT;

                    return (
                      <MessageItemFan
                        images={avatar}
                        key={id}
                        name={isAdmin ? t('Common.Admin') : user?.account_name}
                        dateTime={created_at}
                        handleDelete={() => {
                          setGroupMessageDelete({ ...item, index });
                          setShowPopupConfirm(true);
                        }}
                        userId={user_id}
                        accountId={user?.account_id}
                        isAdmin={isAdmin}
                        unreadCount={Number(not_seen)}
                      />
                    );
                  })}
                </div>
              </InfiniteLoad>
            </>
          ) : (
            <div
              className='d-flex flex-column justify-content-center align-items-center h-100'
              style={{ minHeight: 200 }}>
              {!loading && !search && (
                <div className='empty-box mb-2 p-0'>{t('Messages.listMessagesNull')}</div>
              )}
              {!IS_ADMIN && renderButtonNewMess()}
            </div>
          )}
        </LayoutFan>
      )}
    </>
  );
};

export default ListMessage;
