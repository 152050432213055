import Icon from '@ant-design/icons';

const DangerCircleIconFill = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill={props.fillColor ?? 'none'}
        viewBox='0 0 16 16'>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M8 1.063a6.938 6.938 0 110 13.875A6.938 6.938 0 018 1.062z'
          clipRule='evenodd'></path>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M7.996 5.152v3.315'></path>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M7.996 10.848h.008'></path>
      </svg>
    )}
    {...props}
  />
);

export default DangerCircleIconFill;
