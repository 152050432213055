import useLockBodyScroll from 'hooks/useLockBodyScroll';
import { CloseIcon, MessageAddIcon, SendChatIcon } from 'images';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { fanDonateCreatorMessage, resetStatusFanDonate } from 'store/actions/lives';
import { formatCoin } from 'utils/utils';
import { PopupConfirmFan, PopupLoading } from '..';

import { newPathUser } from 'constants/layout/constant';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getUserInfoMe } from 'store/actions/users';
import { LIST_POINT_ICON_DONATE } from 'utils/constant';
import { getListPointIconDonate } from '../../store/actions/pointIconDonate';

const SendCoinPopup = ({
  link,
  closeModal,
  valueCoin,
  currentCoin = 0,
  isVisiblePopup,
  liveId,
  creatorId,
  transactionType,
  iconAndPoint,
  ...props
}) => {
  const [active, setActive] = useState(null);
  useLockBodyScroll();
  const [popupConfirmSend, setPopupConfirmSend] = useState(false);
  const [valueChoice, setValueChoice] = useState({});
  const [iconChoice, setIconChoice] = useState(0);
  const [isEnoughPoints, setIsEnoughPoints] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [listCoinIconDonate, setListCoinIconDonate] = useState([]);

  let { loading, status } = useSelector((state) => state.lives);
  let { loading: isloading } = useSelector((state) => state.pointIconDonate);

  const refPoint = useRef();

  useEffect(() => {
    if (status === 'FAN_DONATE_CREATOR_LIVE_SUCCESS') {
      if (parseInt(valueChoice.point) > 0) {
        setPopupConfirmSend(false);
        valueCoin(valueChoice, iconChoice);
        dispatch(resetStatusFanDonate());
      } else {
        console.log('sendCoin-Err1-points-not-valid');
      }
    }
    if (status === 'FAN_DONATE_CREATOR_LIVE_FAILED') {
      setPopupConfirmSend(false);
      dispatch(resetStatusFanDonate());
      // valueCoin(valueChoice, parseInt(currentCoin), false, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleGetListPointIconSuccess = ({ data }) => {
    setListCoinIconDonate(data);
  };

  useEffect(() => {
    dispatch(
      getListPointIconDonate({ active_flag: 1, sort: 'asc' }, handleGetListPointIconSuccess)
    );
  }, []);

  const handleDonateSuccess = () => {
    dispatch(getUserInfoMe())
  }

  const handleDoNotInsufficientCoin = () => {
    const queryString = {
      transaction_type: transactionType || 2,
      user_id: parseInt(creatorId),
      point_id: parseInt(valueChoice.point_id),
      point: parseInt(valueChoice.point),
    };
    if (!transactionType) {
      queryString.livestream_id = liveId;
    }

    dispatch(fanDonateCreatorMessage(queryString, handleDonateSuccess));
  };

  const handleSendCoin = () => {
    setPopupConfirmSend(true);
  };
  return (
    <>
      {isloading && <PopupLoading className={'popup-loading'} />}

      {popupConfirmSend && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupConfirmSend(false)}
          colorSvg='#FF9F43'
          text={t('Popup.messageSendPoint')}>
          {!loading ? (
            <>
              <div
                className='ok btn'
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleDoNotInsufficientCoin();
                }}>
                {t('Common.Ok')}
              </div>
              <div className='btn cancel' onClick={() => setPopupConfirmSend(false)}>
                {t('Common.buttonCancel')}
              </div>
            </>
          ) : (
            <>
              <div className={`btn ok`}>
                <div
                  className='spinner-border m-auto'
                  role='status'
                  style={{ width: '15px', height: '15px' }}></div>
              </div>
              <div className='btn cancel'> {t('Common.buttonCancel')} </div>
            </>
          )}
        </PopupConfirmFan>
      )}

      {isEnoughPoints !== null && !isEnoughPoints && (
        <PopupConfirmFan
          isVisiblePopup={() => setIsEnoughPoints(null)}
          colorSvg='#FF9F43'
          text={t('Popup.NotEnoughPoints')}>
          <div className='ok btn' onClick={() => navigate(`${newPathUser}purchase`)}>
            {t('Common.Ok')}
          </div>
          <div className='btn cancel' onClick={() => setIsEnoughPoints(null)}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}
      <div className='popup popup-send-coin'>
        <div className='popup-content'>
          <CloseIcon className='close-icon' onClick={isVisiblePopup} />
          <div className='title'>{t('Common.possessionPoints')}: </div>
          <div className='value-choice' style={{ display: 'flex', alignItems: 'center' }}>
            <strong ref={refPoint}>{formatCoin(currentCoin)}</strong>
            <MessageAddIcon
              style={{ marginLeft: 8 }}
              onClick={() => navigate(`${newPathUser}purchase`)}
            />
          </div>
          <div className={`box-coin ${isMobile ? 'is-mobile' : ''}`}>
            {listCoinIconDonate?.map((item) => {
              const srcImg = LIST_POINT_ICON_DONATE.find((x) => {
                return x.name === item?.icon;
              });
              return (
                <div
                  key={item.id}
                  className={`item ${item.id === active && 'active'}`}
                  onClick={() => {
                    setActive(item.id);
                    setValueChoice({ point: item.point, point_id: item?.id });
                    setIconChoice(srcImg);
                  }}>
                  <img src={srcImg?.icon} alt='' />
                  <div className='number'>{formatCoin(item.point)}</div>
                </div>
              );
            })}
          </div>
          <div className='box-btn'>
            <div
              className={`btn-submit ${valueChoice?.point > 0 && 'active'}`}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                if (valueChoice?.point > 0) {
                  if (parseInt(currentCoin) >= parseInt(valueChoice?.point)) {
                    setIsEnoughPoints(null);
                    handleSendCoin();
                  } else {
                    setIsEnoughPoints(false);
                  }
                }
              }}>
              <SendChatIcon />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendCoinPopup;
