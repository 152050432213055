import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './index.scss';
import { ReadMoreLess } from 'components';
import { newPathUser } from 'constants/layout/constant';

const PackageItem = ({ packageItem = {} }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const navigateEdit = (id) => {
    navigate(`${newPathUser}profile/subscription/update/${id}`);
  };
  const convertShowPrice = (price) => {
    return (
      <>
        1{t('Creator.Home.month')}/
        <CurrencyFormat
          thousandSeparator={true}
          value={price}
          className='currency-format'
          displayType='text'
        />
        {t('Common.pt')}
      </>
    );
  };

  return (
    <>
      <div className='package-item-container'>
        <p className='package-item-container__title text-center'>{packageItem?.title}</p>
        <p className='package-item-container__price text-center'>
          {convertShowPrice(packageItem?.price)}
        </p>
        <p className='package-item-container__description display-linebreak'>
          <ReadMoreLess content={packageItem?.description ? packageItem?.description : ''} />
        </p>
        <button
          className='package-item-container__update btn btn-danger'
          onClick={() => navigateEdit(packageItem?.id)}>
          {t('Creator.Home.subscriptionEdit')}
        </button>
        {!packageItem?.ticket_id ? (
          <button
            className='package-item-container__create-ticket btn'
            onClick={() =>
              navigate(`${newPathUser}profile/premium-ticket/create`, {
                state: { packageId: packageItem?.id, packageName: packageItem?.title },
              })
            }>
            {t('Creator.ReviewLiveStream.newPremiumTicket')}
          </button>
        ) : (
          <button
            className='package-item-container__edit-ticket btn'
            onClick={() =>
              navigate(`${newPathUser}profile/premium-ticket/update/${packageItem?.ticket_id}`, {
                state: { packageId: packageItem?.id, packageName: packageItem?.title },
              })
            }>
            {t('Creator.Home.editPremiumTicket')}
          </button>
        )}
      </div>
    </>
  );
};

export default PackageItem;
