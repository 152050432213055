import Icon from '@ant-design/icons';

const ExitFullScreenIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={18}
        height={18}
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M1 13.5714H4.42857V17H6.71429V11.2857H1V13.5714ZM4.42857 4.42857H1V6.71429H6.71429V1H4.42857V4.42857ZM11.2857 17H13.5714V13.5714H17V11.2857H11.2857V17ZM13.5714 4.42857V1H11.2857V6.71429H17V4.42857H13.5714Z'
          fill='white'
        />
        <path
          d='M0.5 13.5714V14.0714H1H3.92857V17V17.5H4.42857H6.71429H7.21429V17V11.2857V10.7857H6.71429H1H0.5V11.2857V13.5714ZM3.92857 1V3.92857H1H0.5V4.42857V6.71429V7.21429H1H6.71429H7.21429V6.71429V1V0.5H6.71429H4.42857H3.92857V1ZM10.7857 17V17.5H11.2857H13.5714H14.0714V17V14.0714H17H17.5V13.5714V11.2857V10.7857H17H11.2857H10.7857V11.2857V17ZM17 3.92857H14.0714V1V0.5H13.5714H11.2857H10.7857V1V6.71429V7.21429H11.2857H17H17.5V6.71429V4.42857V3.92857H17Z'
          stroke='black'
          strokeOpacity='0.1'
        />
      </svg>
    )}
    {...props}
  />
);

export default ExitFullScreenIcon;
