import DangerIcon from 'images/DangerIcon';
import { useTranslation } from 'react-i18next';
import './index.scss';

const PopupConfirmDelete = ({
  title,
  loading,
  setShowPopup,
  handleOnSubmit,
  isConfirm,
  textCancel,
  textOk,
  maxWidth
}) => {
  const closePopUp = () => setShowPopup(false);
  const { t } = useTranslation();
  const width = document?.querySelector('.back-header')?.offsetWidth;

  return (
    <div className='popup confirm-popup' style={{ zIndex: '9999' }}>
      <div className='popup-content confirm-popup-content' style={{ maxWidth: maxWidth ? maxWidth : width }}>
        <div className='d-flex'>
          <DangerIcon className='check-icon' />
          <p className='ms-1 mb-0'>{title}</p>
        </div>
        <div className='text-end mt-4'>
          {isConfirm ? (
            <button className='btn btn-bg-red text-white' onClick={closePopUp}>
              {textOk || t('Common.buttonYes')}
            </button>
          ) : (
            <>
              <button
                disabled={loading}
                className='btn btn-bg-red text-white'
                onClick={handleOnSubmit}>
                {textOk || t('Common.buttonYes')}
              </button>
              <button className='btn border cancel' onClick={closePopUp}>
                {textCancel || t('Common.buttonCancel')}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopupConfirmDelete;
