/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Chart from '../generalChart/chart';
import Table from '../generalChart/table';
import ChartCircle from '../generalChart/chartCircle';
import { getChartLivestreams } from 'store/actions/chartRepot';
import { useTranslation } from 'react-i18next';
const Livestream = ({ date }) => {
  const { from_date, to_date } = date;
  const dispatch = useDispatch();
  const { chartLivestreams } = useSelector((state) => state.chartReport);
  const [dataPieChart, setDataPieChart] = useState();
  const [genderChartCircle, setGenderChartCircle] = useState();
  const [clearActive, setClearActive] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(
      getChartLivestreams(
        { from_date, to_date },
        () => {
          setDataPieChart();
          setGenderChartCircle();
          setClearActive(true);
        },
        () => {
          setDataPieChart();
          setGenderChartCircle();
          setClearActive(true);
        }
      )
    );
  }, [from_date, to_date]);

  return (
    <>
      {chartLivestreams.length > 0 && (
        <>
          <Chart
            data={chartLivestreams || []}
            titleChart={t('Report.live_broadcast')}
            labelLeftChart={t('Creator.Livestream.numberOfViewers')}
          />
          <Table
            columns={chartLivestreams}
            itemClick={(e) => {
              setDataPieChart(e);
              setClearActive(false);
            }}
            clearActive={clearActive}
            genderChart={(e) => setGenderChartCircle(e)}
            totalTableField={t('Report.total_viewers')}
          />
          {dataPieChart && (
            <ChartCircle
              data={dataPieChart}
              genderChart={genderChartCircle}
              titleChart='Report.live_broadcast_viewers'
            />
          )}
        </>
      )}
    </>
  );
};
export default Livestream;
