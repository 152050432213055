import { useLayoutEffect } from 'react';

export default function useLockBodyScroll(lockScroll = false) {
  useLayoutEffect(() => {
    if (lockScroll) {
      document.body.style.overflow = 'hidden';
      document.body.style.padding = '0';
      return () => {
        document.body.style.overflow = 'visible';
        document.body.style.padding = '0';
      };
    } else {
      return;
    }
  }, []);
}
