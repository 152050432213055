import { CircleCheckIcon, DangerCircleIcon } from 'images';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import { useTranslation } from 'react-i18next';

const PopupFanBuyPackage = ({
  text,
  text2,
  text3,
  colorSvg = '#28C76F',
  children,
  className,
  iconNote = false,
  hiddenIcon = false,
  textColor,
}) => {
  useLockBodyScroll();
  const { t } = useTranslation();
  return (
    <div className={`popup confirm-fan ${className}`}>
      <div className='popup-content'>
        <div className='content'>
          {!hiddenIcon && (
            <>
              {iconNote ? (
                <DangerCircleIcon style={{ color: colorSvg }} className='check-icon' />
              ) : (
                <CircleCheckIcon style={{ color: colorSvg }} className='check-icon' />
              )}
            </>
          )}
          <div className='d-flex flex-column' style={{ marginLeft: '18.75px' }}>
            <span className='text' style={textColor !== undefined ? { color: textColor } : {}}>
              {text}
            </span>
            <span className='text3'>
              {text2}{' '}
              <b style={{ color: '#ff0000', marginBottom: '10px' }}>
                {text3} {t('Common.pt')}{' '}
              </b>
            </span>
            {/* <span className='text'>{text3}</span> */}
          </div>
        </div>
        <div className='box-button'>{children}</div>
      </div>
    </div>
  );
};

export default PopupFanBuyPackage;
