import { ListChat } from 'components/LiveStream/ListChat';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { LivestreamFanContext } from '../liveStream';
import { InputChat } from 'components/LiveStream/Chat/InputChat';
import { InputWrapper } from 'components/Input/InputWrapper';
import { SendChatIcon } from 'images';
import { useFullScreenHandle } from 'react-full-screen';
import { useState } from 'react';
import { BlockFan, ContentChatFan, FormChatFan, GiftBox } from './styled';

export const ListContentChat = ({ setIsUserLogin }) => {
  const { userInfo } = useSelector((state) => state.lives);
  const {
    setIsVisibleSendCoinPopup,
    checkHorizontalScreen,
    allowComment,
    liveKey,
    BoxGift,
    denyCharacter,
    t,
  } = useContext(LivestreamFanContext);
  const handle = useFullScreenHandle();
  const [valueChat, setValueChat] = useState('');

  return (
    <BlockFan style={{ height: '100%' }}>
      <ContentChatFan
        className={`content-chat ${
          checkHorizontalScreen === 'landscape' ? 'landscape' : 'portrait'
        }`}
        style={{
          cursor: 'default',
        }}>
        <ListChat denyCharacter={denyCharacter} keyId={`${liveKey}`} views='fan' id={userInfo?.auth_id} />
      </ContentChatFan>

      <BlockFan ipfan posicon>
        <InputChat
          denyCharacter={denyCharacter}
          allowComment={allowComment === 1 ? true : false}
          id={userInfo?.auth_id}
          isAuth={userInfo?.is_auth}
          isGift={{
            setIsVisibleSendCoinPopup: () => setIsVisibleSendCoinPopup(true),
            setIsUserLogin: () => setIsUserLogin(false),
          }}
        />
      </BlockFan>
    </BlockFan>
  );
};
