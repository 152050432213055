import Icon from '@ant-design/icons';

const NoPostBuyedIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={100}
        height={100}
        viewBox='0 0 100 100'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M71.875 71.875H27.2727L16.3756 11.941C16.2447 11.2209 15.8652 10.5697 15.3034 10.1008C14.7415 9.63185 14.0329 9.375 13.301 9.375H6.25'
          stroke='#FCECEF'
          strokeWidth={8}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M31.25 87.5C35.5647 87.5 39.0625 84.0022 39.0625 79.6875C39.0625 75.3728 35.5647 71.875 31.25 71.875C26.9353 71.875 23.4375 75.3728 23.4375 79.6875C23.4375 84.0022 26.9353 87.5 31.25 87.5Z'
          stroke='#FCECEF'
          strokeWidth={8}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M71.875 87.5C76.1897 87.5 79.6875 84.0022 79.6875 79.6875C79.6875 75.3728 76.1897 71.875 71.875 71.875C67.5603 71.875 64.0625 75.3728 64.0625 79.6875C64.0625 84.0022 67.5603 87.5 71.875 87.5Z'
          stroke='#FCECEF'
          strokeWidth={8}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M24.4318 56.25H73.4771C74.9408 56.25 76.358 55.7363 77.4817 54.7984C78.6055 53.8606 79.3644 52.5581 79.6263 51.118L84.375 25H18.75'
          stroke='#FCECEF'
          strokeWidth={8}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M65 33.875L52.5 45.75L40 33.875'
          stroke='#FCECEF'
          strokeWidth={6}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M52.5 11.25L52.5 43.75'
          stroke='#FCECEF'
          strokeWidth={6}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default NoPostBuyedIcon;
