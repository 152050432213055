import { ImgCustom } from 'components';
import Loading from 'components/Paging/Loading';
import { newPathUser } from 'constants/layout/constant';
import {
  Edit3Icon,
  ThreePointIcon,
  TrashIcon,
  FlagIcon,
  HeartIcon,
  Edit2Icon,
  ThreePointIconV3,
} from 'images';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  collapseReplyCommentDetail,
  editCommentDetailNew,
  getReplyCommentDetail,
} from 'store/actions/usersNew';
import { convertToCurrentTime, isAdmin, isAgency } from 'utils/utils';
import useUserRole from '../../hooks/useUserRole';
import FormEditReplyComment from './formEditReplyComment';
import './index.scss';
import { AVATAR_DEFAULT, STORAGE } from 'utils';
import { useClickOutSize } from 'hooks/v2/clickOutSide';
import Storage from 'utils/storage';
import { logUserProfile } from 'store/actions/myPage';
import { isMobile } from 'react-device-detect';
import MarkDownRead from 'components/MarkDownRead';

const ListRepComment = ({
  handleDeleteComment,
  handleLikeComment,
  handleFollowUserComment,
  item_comment,
  dataPost,
  isEnableComment = true,
  isPopupSaleContent = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loadingRepCommentDetail, replyCommentDetail, statusEditRepComment } = useSelector(
    (state) => state.usersNew
  );
  const dataProfile = useSelector((state) => state.users.dataUser);
  const [showFormEditComment, setFormEditComment] = useState(null);
  const [isVisiblePopup, setIsVisiblePopup] = useState(-1);
  const [checkPageRep, setCheckPageRep] = useState([]);
  const [checkGetRepComment, setCheckGetRepComment] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [idFan, seIdFan] = useState();
  const { isIdol } = useUserRole();
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);

  // esc func
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setFormEditComment(null);
    }
  }, []);
  // start func esc
  useEffect(() => {
    if (statusEditRepComment) {
      setFormEditComment(null);
      const textareaId = document.getElementById('textarea-edit-rep-comment');
      if (textareaId) textareaId.style.height = '38px';
    }
    document.addEventListener('keydown', escFunction);
    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction, statusEditRepComment]);

  useEffect(() => {
    !loadingRepCommentDetail && setCheckGetRepComment(null);
  }, [loadingRepCommentDetail]);

  const handleViewRepComment = (comment_id) => {
    if (checkPageRep.length > 0) {
      checkPageRep.map((item) => {
        if (item.comment_id === comment_id) {
          const checkPageRepFilter = checkPageRep.filter(
            (fil) => fil.comment_id !== comment_id
          );
          checkPageRepFilter.push({ page: item.page + 1, comment_id: comment_id });
          setCheckPageRep(checkPageRepFilter);
          return dispatch(getReplyCommentDetail(comment_id, item.page + 1, 5));
        } else {
          setCheckPageRep(checkPageRep.concat({ comment_id: comment_id, page: 1 }));
          return dispatch(getReplyCommentDetail(comment_id, 1, 5));
        }
      });
    } else {
      setCheckPageRep(checkPageRep.concat({ comment_id: comment_id, page: 1 }));
      return dispatch(getReplyCommentDetail(comment_id, 1, 5));
    }
  };

  const handleCollapseRepComment = (comment_id) => {
    const checkPageRepFilter = checkPageRep.filter((fil) => fil.comment_id !== comment_id);
    setCheckPageRep(checkPageRepFilter);
    dispatch(collapseReplyCommentDetail(comment_id));
  };

  const checkDisabledAction = (item) => {
    if (isPopupSaleContent) return false;
    if (
      (item?.is_followed === 0 && item?.follow_flag === 1 && dataPost?.is_my_post === 0) ||
      (item?.is_media_display === 0 && dataPost?.is_my_post === 0)
    ) {
      return true;
    }
    return false;
  };

  const [refOutSideThree] = useClickOutSize(() => {
    setIsVisiblePopup(-1);
  });
  return (
    <>
      {/* {isVisible && (
        <PopupFanDetail
          closeModal={() => setIsVisible(false)}
          isVisible={isVisible}
          id={idFan}
        />
      )} */}
      <div className='reply-comment'>
        {replyCommentDetail?.map(
          (item_rep) =>
            item_rep.parent_id === item_comment.id && (
              <div key={item_rep?.id} className='comment-item comment-item-reply 2'>
                <div
                  className='avatar'
                  onClick={() => navigate(`${newPathUser}${item_rep?.account_id}`)}>
                  {item_rep?.avatar ? (
                    <ImgCustom alt='' src={item_rep?.avatar} />
                  ) : (
                    <img src={AVATAR_DEFAULT} alt='AVATAR_DEFAULT' />
                  )}
                </div>
                {showFormEditComment?.id === item_rep?.id ? (
                  <>
                    <FormEditReplyComment
                      item_rep={showFormEditComment}
                      handleCancelEdit={() => {
                        setFormEditComment(null);
                      }}
                    />
                  </>
                ) : (
                  <>
                    <div className='content'>
                      <div className='info-comment'>
                        <div
                          className='left'
                          onClick={() => {
                            if (+dataProfile?.user_type !== 3) {
                              setIsVisible(true);
                              seIdFan(item_rep?.user_id);
                            }
                          }}>
                          <div className='dp-flex'>
                            <div
                              className='name custom_name'
                              style={{ maxWidth: `${!isMobile && '166px'}` }}
                              onClick={() =>
                                navigate(`${newPathUser}${item_rep?.account_id}`)
                              }>
                              {item_rep?.account_name}
                            </div>
                            <div className='time'>
                              {convertToCurrentTime(item_rep?.created_at, 'YYYY/MM/DD HH:mm')}
                            </div>
                            {item_rep.is_edited === 1 && (
                              <Edit2Icon className='icon-edited mb-1' />
                            )}
                          </div>

                          <div className='d-flex w-100 justify-content-between align-items-end'>
                            <div className='comment-content display-linebreak'>
                              <MarkDownRead
                                postId={dataPost?.id}
                                commentId={item_rep?.id}
                                parentId={item_rep.parent_id}
                                metaPost={item_rep?.metaPost}
                                content={item_rep?.content?.toString()}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='content-right'>
                          {(dataPost?.user_id === dataProfile?.id ||
                            item_rep?.user_id === dataProfile?.id) && (
                            <div className='box-popup'>
                              {checkDisabledAction(dataPost) ? (
                                <ThreePointIconV3 />
                              ) : (
                                <span
                                  className='btn-three-point'
                                  ref={refOutSideThree}
                                  onClick={() => {
                                    !checkDisabledAction(dataPost) &&
                                      setIsVisiblePopup(
                                        isVisiblePopup === item_rep?.id ? -1 : item_rep?.id
                                      );
                                  }}>
                                  <ThreePointIcon
                                    className='three-point-icon'
                                    onBlur={() => setIsVisiblePopup(-1)}
                                  />
                                </span>
                              )}
                              {isVisiblePopup === item_rep?.id && (
                                <div className='popup-edit-comment'>
                                  {item_rep?.user_id === dataProfile?.id && (
                                    <div
                                      className='item item1'
                                      onMouseDown={() => {
                                        dispatch(
                                          editCommentDetailNew(item_rep?.id, ({ data }) => {
                                            setFormEditComment(data);
                                          })
                                        );
                                        //setFormEditComment(item_rep);
                                        setIsVisiblePopup(-1);
                                      }}>
                                      <Edit3Icon color='#282727' />
                                      <span className='text'>
                                        {t('comment.listRepComment.iconEdit')}
                                      </span>
                                    </div>
                                  )}
                                  {item_rep?.user_id !== dataProfile?.id && isIdol && (
                                    <div
                                      className='item'
                                      onMouseDown={() => {
                                        setIsVisiblePopup(-1);
                                        navigate(`${newPathUser}report`, {
                                          state: {
                                            fan_id: item_rep?.user_id,
                                            fan_name: item_rep?.account_name,
                                          },
                                        });
                                      }}>
                                      <FlagIcon />
                                      <span className='text'>
                                        {t('comment.listRepComment.iconFlag')}
                                      </span>
                                    </div>
                                  )}
                                  <div
                                    className='item'
                                    onMouseDown={() => {
                                      setIsVisiblePopup(-1);
                                      handleDeleteComment({
                                        id: item_rep?.id,
                                        type: 'reply',
                                        idParent: item_rep.parent_id,
                                      });
                                    }}>
                                    <TrashIcon />
                                    <span className='text'>{t('Common.buttonDelete')}</span>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className={`like-reply `}
                        style={{
                          pointerEvents: isAdmin || isAgency ? 'none' : 'pointer',
                          color: isAdmin || isAgency ? '#B1B6BB' : '#787880',
                        }}>
                        {/* <span
                          className={`like ${item_rep?.status_like_comment === 1 && 'active'}`}
                          onClick={() => {
                            if (!isAdmin) {
                              handleLikeComment({ id: item_rep?.id, type: 'reply' });
                            }
                          }}>{`${t('comment.listRepComment.textLike')} ${
                          item_rep?.number_like
                        }`}</span> */}
                        <span
                          className={`like`}
                          style={{
                            cursor: `${checkDisabledAction(dataPost) ? 'default' : 'pointer'}`,
                          }}
                          onClick={() => {
                            if (!isAuth) {
                              dispatch(logUserProfile());
                            } else {
                              if (!isAdmin) {
                                !checkDisabledAction(dataPost) &&
                                  dataPost?.enable_comment !== 0 &&
                                  handleLikeComment({
                                    dataPost: item_rep,
                                    commentId: item_rep?.id,
                                    type: 'reply',
                                  });
                              }
                            }
                          }}>
                          <HeartIcon
                            style={{
                              color:
                                item_rep?.is_liked === 1
                                  ? '#dc143c'
                                  : checkDisabledAction(dataPost) || !isEnableComment
                                  ? '#BEBEBE'
                                  : '#787880',
                              cursor: `${
                                checkDisabledAction(dataPost) || !isEnableComment
                                  ? 'default'
                                  : 'pointer'
                              }`,
                            }}
                            fill={item_rep?.is_liked === 1 ? '#dc143c' : 'none'}
                            className='cls-icon'
                          />
                          <p
                            style={{
                              color: `${
                                checkDisabledAction(dataPost) || !isEnableComment
                                  ? '#BEBEBE'
                                  : '#787880'
                              }`,
                              cursor: `${
                                checkDisabledAction(dataPost) || !isEnableComment
                                  ? 'default'
                                  : 'pointer'
                              }`,
                              marginBottom: '0px',
                            }}>
                            {item_rep?.number_like}
                          </p>
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )
        )}
      </div>
      {(() => {
        const ViewMore = () => (
          <span
            className='view-more-comment'
            style={{
              cursor: `${
                checkDisabledAction(dataPost) || !isEnableComment ? 'default' : 'pointer'
              }`,
            }}
            onClick={() => {
              if (isEnableComment) {
                handleViewRepComment(item_comment.id);
                setCheckGetRepComment(item_comment.id);
              }
            }}>
            {t('comment.listRepComment.viewMore')} ...
          </span>
        );
        const Collapse = () => (
          <span
            className='view-more-comment'
            onClick={() => {
              if (isEnableComment) {
                handleCollapseRepComment(item_comment.id);
              }
            }}>
            {t('comment.listRepComment.collapseRepComment')}
          </span>
        );
        if (item_comment?.number_comment > 0) {
          let check = false;
          replyCommentDetail?.map((item) => {
            if (item_comment.id === item.parent_id) {
              if (item.currentPage !== item.totalPage) {
                check = false;
              } else check = true;
            }
            return check;
          });
          return check ? <Collapse /> : <ViewMore />;
        }
      })()}
      {loadingRepCommentDetail && checkGetRepComment === item_comment.id && (
        <Loading className='popup-loading' />
      )}
    </>
  );
};

export default ListRepComment;
