import { LazyAware } from '../lazyAware';
import OptionsItem from '../optionItem';
import { PlaceHolderItem } from '../style';
import { Card } from './Card';

const ItemImage = ({
  item,
  index,
  indexPlay,
  updateList,
  toggleValueVideo,
  medias,
  setShowPopup,
  handleFollowCreator,
  toggleFollowAllUser,
  setPopupCopyLink,
  isShowPopup,
  dataUser,
}) => {
  const isMiniUrl = () => {
    return (
      (item?.is_followed === 0 && item?.follow_flag === 1 && item?.user_id !== dataUser?.id) ||
      item?.is_media_display === 0
    );
  };
  return (
    <LazyAware
      key={item?.id}
      render={({ ref, wasIntersected, isIntersecting }) => {
        if (wasIntersected && isIntersecting) {
          if (index !== indexPlay) {
            updateList(index);
          }
        }
        if (isIntersecting) {
          window.scrollTo(0, 0);
        }
        return (
          <div className='page-list-short-video-item__video' ref={ref}>
            {wasIntersected ? (
              <>
                <Card
                  toggleValueVideo={() => {
                    toggleValueVideo(index);
                  }}
                  data={medias || []}
                  key={item?.id}
                  isIntersecting={isIntersecting}
                  isPlay={item?.isPlay}
                  isMiniUrl={isMiniUrl()}
                />
                <OptionsItem
                  item={item}
                  index={index}
                  toggleValueVideo={toggleValueVideo}
                  setShowPopup={setShowPopup}
                  handleFollowCreator={handleFollowCreator}
                  toggleFollowAllUser={toggleFollowAllUser}
                  setPopupCopyLink={setPopupCopyLink}
                  isShowPopup={isShowPopup}
                />
              </>
            ) : (
              <PlaceHolderItem />
            )}
          </div>
        );
      }}
    />
  );
};

export default ItemImage;
