import Icon from '@ant-design/icons';

const UpThumbnailIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={44}
        height={44}
        viewBox='0 0 44 44'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx={22} cy={22} r='21.5' fill='#DC143C' stroke='#EEEEEE' />
        <path
          d='M12.625 20.121C12.7907 20.121 12.9497 20.0551 13.0669 19.9379C13.1841 19.8207 13.25 19.6617 13.25 19.496V14.951C13.2505 14.499 13.4303 14.0656 13.7499 13.746C14.0695 13.4264 14.5029 13.2466 14.9549 13.2461H19.4999C19.6656 13.2461 19.8246 13.1802 19.9418 13.063C20.059 12.9458 20.1249 12.7868 20.1249 12.6211C20.1249 12.4553 20.059 12.2964 19.9418 12.1791C19.8246 12.0619 19.6656 11.9961 19.4999 11.9961H14.9549C14.1715 11.9969 13.4204 12.3085 12.8664 12.8625C12.3124 13.4165 12.0008 14.1676 12 14.951V19.496C12 19.6617 12.0658 19.8207 12.1831 19.9379C12.3003 20.0551 12.4592 20.121 12.625 20.121Z'
          fill='white'
        />
        <path
          d='M29.0449 11.9961H24.5C24.3342 11.9961 24.1753 12.0619 24.0581 12.1791C23.9408 12.2964 23.875 12.4553 23.875 12.6211C23.875 12.7868 23.9408 12.9458 24.0581 13.063C24.1753 13.1802 24.3342 13.2461 24.5 13.2461H29.0449C29.4969 13.2466 29.9303 13.4264 30.25 13.746C30.5696 14.0656 30.7494 14.499 30.7499 14.951V19.496C30.7499 19.6617 30.8157 19.8207 30.9329 19.9379C31.0501 20.0551 31.2091 20.121 31.3749 20.121C31.5406 20.121 31.6996 20.0551 31.8168 19.9379C31.934 19.8207 31.9999 19.6617 31.9999 19.496V14.951C31.999 14.1676 31.6874 13.4165 31.1335 12.8625C30.5795 12.3085 29.8284 11.9969 29.0449 11.9961Z'
          fill='white'
        />
        <path
          d='M19.4999 30.746H14.9549C14.5029 30.7455 14.0695 30.5657 13.7499 30.2461C13.4303 29.9264 13.2505 29.493 13.25 29.041V24.4961C13.25 24.3303 13.1841 24.1714 13.0669 24.0541C12.9497 23.9369 12.7907 23.8711 12.625 23.8711C12.4592 23.8711 12.3003 23.9369 12.1831 24.0541C12.0658 24.1714 12 24.3303 12 24.4961V29.041C12.0008 29.8245 12.3124 30.5756 12.8664 31.1296C13.4204 31.6835 14.1715 31.9951 14.9549 31.996H19.4999C19.6656 31.996 19.8246 31.9301 19.9418 31.8129C20.059 31.6957 20.1249 31.5367 20.1249 31.371C20.1249 31.2052 20.059 31.0462 19.9418 30.929C19.8246 30.8118 19.6656 30.746 19.4999 30.746Z'
          fill='white'
        />
        <path
          d='M31.3749 23.8711C31.2091 23.8711 31.0501 23.9369 30.9329 24.0541C30.8157 24.1714 30.7499 24.3303 30.7499 24.4961V29.041C30.7494 29.493 30.5696 29.9264 30.25 30.2461C29.9303 30.5657 29.4969 30.7455 29.0449 30.746H24.5C24.3342 30.746 24.1753 30.8118 24.0581 30.929C23.9408 31.0462 23.875 31.2052 23.875 31.371C23.875 31.5367 23.9408 31.6957 24.0581 31.8129C24.1753 31.9301 24.3342 31.996 24.5 31.996H29.0449C29.8284 31.9951 30.5795 31.6835 31.1335 31.1296C31.6874 30.5756 31.999 29.8245 31.9999 29.041V24.4961C31.9999 24.3303 31.934 24.1714 31.8168 24.0541C31.6996 23.9369 31.5406 23.8711 31.3749 23.8711Z'
          fill='white'
        />
        <path
          d='M26.2498 21.996C26.2498 24.3432 24.3471 26.2459 21.9999 26.2459C19.6528 26.2459 17.75 24.3432 17.75 21.996C17.75 19.6488 19.6528 17.7461 21.9999 17.7461C24.3471 17.7461 26.2498 19.6488 26.2498 21.996Z'
          stroke='white'
          strokeWidth='1.5'
        />
      </svg>
    )}
    {...props}
  />
);

export default UpThumbnailIcon;
