import Icon from '@ant-design/icons';

const GhimIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={20}
        height={20}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6.875 13.125L3.75 16.25'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M12.0571 2.31587L7.18652 7.18643C7.18652 7.18643 5.01902 6.10268 2.70224 7.97162C2.63382 8.02669 2.57776 8.09555 2.5377 8.1737C2.49763 8.25186 2.47445 8.33757 2.46968 8.42527C2.4649 8.51297 2.47863 8.60069 2.50997 8.68274C2.54131 8.76478 2.58956 8.83932 2.65158 8.9015L11.081 17.3309C11.1441 17.3942 11.2201 17.4432 11.3038 17.4746C11.3875 17.506 11.477 17.519 11.5661 17.5129C11.6553 17.5067 11.7421 17.4815 11.8207 17.4389C11.8993 17.3963 11.9678 17.3373 12.0216 17.2659C12.6774 16.3944 13.7073 14.6029 12.8115 12.8114L17.6821 7.94087C17.7993 7.82366 17.8651 7.66469 17.8651 7.49893C17.8651 7.33317 17.7993 7.1742 17.6821 7.05699L12.941 2.31587C12.8237 2.19866 12.6648 2.13281 12.499 2.13281C12.3333 2.13281 12.1743 2.19866 12.0571 2.31587V2.31587Z'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default GhimIcon;
