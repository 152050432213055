import Icon from '@ant-design/icons';

const MessageNew1808 = (props) => (
  <Icon
    component={() => (
      <svg
        width={22}
        height={22}
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M4.46849 14.8763C3.52501 13.2858 3.1946 11.4057 3.53929 9.58881C3.88399 7.77197 4.88007 6.14345 6.34051 5.00905C7.80094 3.87466 9.62527 3.31242 11.4709 3.42791C13.3165 3.54341 15.0565 4.32869 16.3642 5.63631C17.6718 6.94392 18.4571 8.68392 18.5726 10.5296C18.6881 12.3752 18.1259 14.1995 16.9915 15.66C15.8571 17.1204 14.2286 18.1165 12.4117 18.4612C10.5949 18.8059 8.7147 18.4755 7.12422 17.532L7.12424 17.532L4.5015 18.2813C4.39299 18.3123 4.27817 18.3137 4.16892 18.2854C4.05967 18.2571 3.95999 18.2001 3.88019 18.1203C3.80039 18.0405 3.74338 17.9408 3.71507 17.8316C3.68676 17.7223 3.68819 17.6075 3.71919 17.499L4.46854 14.8763L4.46849 14.8763Z'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11 11.791C11.4369 11.791 11.791 11.4369 11.791 11C11.791 10.5631 11.4369 10.209 11 10.209C10.5631 10.209 10.209 10.5631 10.209 11C10.209 11.4369 10.5631 11.791 11 11.791Z'
          fill='#1A1A1A'
        />
        <path
          d='M14.7969 11.791C15.2337 11.791 15.5879 11.4369 15.5879 11C15.5879 10.5631 15.2337 10.209 14.7969 10.209C14.36 10.209 14.0059 10.5631 14.0059 11C14.0059 11.4369 14.36 11.791 14.7969 11.791Z'
          fill='#1A1A1A'
        />
        <path
          d='M7.20312 11.791C7.63999 11.791 7.99414 11.4369 7.99414 11C7.99414 10.5631 7.63999 10.209 7.20312 10.209C6.76626 10.209 6.41211 10.5631 6.41211 11C6.41211 11.4369 6.76626 11.791 7.20312 11.791Z'
          fill='#1A1A1A'
        />
      </svg>
    )}
    {...props}
  />
);

export default MessageNew1808;
