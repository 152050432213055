import { Route, Routes } from 'react-router-dom';
import Maintain from '../pages/maintain';

const MaintainRoutes = () => {
  return (
    <Routes>
      <Route path='/maintain' element={<Maintain />} />
    </Routes>
  );
};

export default MaintainRoutes;
