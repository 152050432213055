import { useTranslation } from 'react-i18next';
import BgTigerPay from 'images/bg_tiger_pay.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { formatCoin } from 'utils/utils';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { PopupConfirmFan, PopupGeneral } from 'components';
import { postPaymentPonit } from 'store/actions/payment';
import { StyledStep2TigerPay } from './styled';
import { newPathUser } from 'constants/layout/constant';
import SuccessRegisterIcon from 'images/SuccessRegisterIcon';
import CurrencyFormat from 'react-currency-format';

const Step2Tiger = ({ setStep, dataVoucher, priceVoucher, codePayment }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  const [isVisiblePopupPayment, setIsVisiblePopupPayment] = useState(false);

  const { point, package_payment_id, price } = location?.state?.dataVendo || {};
  const { code_payment, post_payment_point } = useSelector((state) => state.payment);
  return (
    <StyledStep2TigerPay>
      {isVisiblePopupPayment && (
        <PopupConfirmFan
          isVisiblePopup={() => setIsVisiblePopupPayment(false)}
          colorSvg='#FFBF00'
          fillColor={true}
          iconNote={true}
          className='popup-payment-tiger'
          text={t('Popup.confirmPurchased')}>
          <div
            className='ok btn'
            onClick={() => {
              package_payment_id
                ? dispatch(
                    postPaymentPonit({
                      package_payment_id: package_payment_id,
                      payment_method: 2,
                      code: codePayment || code_payment,
                      voucher: dataVoucher?.code || null,
                    })
                  )
                : dispatch(
                    postPaymentPonit({
                      point: point,
                      price: price,
                      payment_method: 2,
                      code: codePayment || code_payment,
                      voucher: dataVoucher?.code || null,
                    })
                  );

              setIsVisiblePopupPayment(false);
            }}>
            {t('Common.Ok')}
          </div>
          <div
            className='btn cancel'
            style={{ fontWeight: 500 }}
            onClick={() => setIsVisiblePopupPayment(false)}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}
      {post_payment_point && (
        <PopupGeneral
          isVisible={post_payment_point}
          closeModal={() => {
            setStep(1);
          }}
          title={t('Purchase.payment.title')}
          className='popup-success-payment-tiger'>
          <div className='success-icon'>
            <SuccessRegisterIcon />
          </div>
          <div className='text-content'>
            <div className='div1'>{t('Purchase.payment.step3.text1')}</div>

            <div className='div2' style={{ marginTop: 20 }}>
              {t('Purchase.payment.step3.text2')}
            </div>
            <div className={`div3 custom ${i18n.language === 'en' && 'en'}`}>
              <span>{t('Purchase.payment.step2.text3')}</span>
              <span className='text-right'>{codePayment || code_payment}</span>
            </div>
            <div className={`div3 custom ${i18n.language === 'en' && 'en'}`}>
              <span>{t('Purchase.payment.step2.text4')}</span>
              <span className='text-right'>
                {formatCoin(point)} {t('Purchase.point')}
              </span>
            </div>
            <div className={`div3 custom ${i18n.language === 'en' && 'en'}`}>
              <span>{t('Purchase.payment.step2.text5')}</span>
              <span className='text-right'>
                {formatCoin(price)}
                {i18n.language === 'en' && <span style={{ marginLeft: 3 }} />}
                {t('Common.yen')}
              </span>
            </div>
            {dataVoucher && (
              <div className={`div3 custom ${i18n.language === 'jp' && 'jp'}`}>
                <span className='text'>{t('Purchase.payment.step2.textVoucher')}</span>
                <span className={`text-right ${i18n.language === 'jp' && 'jp'}`}>
                  <CurrencyFormat
                    className='price-voucher'
                    thousandSeparator={true}
                    value={priceVoucher}
                    displayType='text'
                  />
                </span>
              </div>
            )}
            <div className='div3' style={{ marginTop: 16 }}>
              {t('Purchase.payment.step3.text6')}
            </div>

            <div className='div2' style={{ marginTop: 20 }}>
              {t('Purchase.payment.step3.text7')}
            </div>
            <div> {t('Purchase.payment.step3.text10')}</div>

            <div className={`${i18n.language === 'en' && 'en'}`}>
              <div className={`div2 ${i18n.language === 'en' && 'en'}`}>
                {t('Purchase.payment.step3.text8')}
              </div>
              <div className={`div2 ${i18n.language === 'en' && 'en'}`}>
                {t('Purchase.payment.step3.text9')}
              </div>
            </div>
          </div>

          <div className='button-wrapper'>
            <div
              className='ok btn'
              onClick={() => {
                window.location.href = `${newPathUser}`;
              }}>
              {t('Purchase.payment.goToMyPage')}
            </div>
          </div>
        </PopupGeneral>
      )}
      <div className='payment-page step-2'>
        <div className='img-container'>
          <img src={BgTigerPay} alt='' className='img-tiger-pay' />
        </div>
        <div className='div1'>{t('Purchase.payment.step2.text1')}</div>

        <div className='div2' style={{ marginTop: 20 }}>
          {t('Purchase.payment.step2.text2')}
        </div>
        <div className={`div3 custom ${i18n.language === 'jp' && 'jp'}`}>
          <span className='text'>{t('Purchase.payment.step2.text3')}</span>
          <span className={`text-right ${i18n.language === 'jp' && 'jp'}`}>
            {codePayment || code_payment}
          </span>
        </div>
        <div className={`div3 custom ${i18n.language === 'jp' && 'jp'}`}>
          <span className='text'>{t('Purchase.payment.step2.text4')}</span>
          <span className={`text-right ${i18n.language === 'jp' && 'jp'}`}>
            {formatCoin(point)} {t('Purchase.point')}
          </span>
        </div>
        <div className={`div3 custom ${i18n.language === 'jp' && 'jp'}`}>
          <span className='text'>{t('Purchase.payment.step2.text5')}</span>
          <span className={`text-right ${i18n.language === 'jp' && 'jp'}`}>
            {formatCoin(price)}
            {i18n.language === 'en' && <span style={{ marginLeft: 3 }} />}
            {t('Common.yen')}
          </span>
        </div>
        {dataVoucher && (
          <div className={`div3 custom ${i18n.language === 'jp' && 'jp'}`}>
            <span className='text'>{t('Purchase.payment.step2.textVoucher')}</span>
            <span className={`text-right ${i18n.language === 'jp' && 'jp'}`}>
              <CurrencyFormat
                className='price-voucher'
                thousandSeparator={true}
                value={priceVoucher}
                displayType='text'
              />
            </span>
          </div>
        )}
        <div className='div2' style={{ marginTop: 20 }}>
          {t('Purchase.payment.step2.text6')}
        </div>
        <div style={{ marginBottom: 12 }}>{t('Purchase.payment.step2.text7')}</div>

        <div className='div2'>
          {t('Purchase.payment.step2.text8')}
          <div className='div3'></div>
          {t('Purchase.payment.step2.text9')}
        </div>
        <div>{t('Purchase.payment.step2.text10')}</div>

        <div className='div3' style={{ marginTop: 20 }}>
          {t('Purchase.payment.step2.text11')}
        </div>

        <div className='div3' style={{ marginTop: 20 }}>
          {t('Purchase.payment.step2.text12')}
        </div>

        <button
          className={`btn-payment btn-col top --active`}
          type='button'
          onClick={() => setIsVisiblePopupPayment(true)}>
          {t('Common.buttonRequest')}
        </button>
        <button
          className={`btn-payment btn-col --cancel`}
          type='button'
          onClick={() => setStep(1)}>
          {t('Common.buttonCancel')}
        </button>
      </div>
    </StyledStep2TigerPay>
  );
};

export default Step2Tiger;
