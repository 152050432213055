import { getEnv } from 'configs/env';
import i18n from 'translate/i18n';
export const CURRENT_USER_KEY = 'CURRENT_USER_KEY';
export const IDOL_SUB_DOMAIN = getEnv('REACT_APP_IDOL_SUB_DOMAIN');

export const CREATOR_STATUS_ENUM = {
  1: i18n.t('User.status1'),
  2: i18n.t('User.status2'),
  3: i18n.t('User.status3'),
  4: i18n.t('User.status4'),
  5: i18n.t('User.status5'),
  7: i18n.t('User.status7'),
};

export const CREATOR_STATUS_ENUM_COLOR = {
  1: 'green',
  // 2: 'grey',
  2: 'red',
  3: 'orange',
  4: 'yellow',
  5: 'red',
  9: 'orange',
  0: 'red',
  7: 'grey'
};

export const LIVE_STREAM_STATUS_ENUM = {
  PENDING: 1,
  COMING: 2,
  LIVE: 3,
  PAUSE: 4,
  END: 5,
};

export const convertPoint2Price = (point, tax, point_to_jp) => {
  let price = +point * +point_to_jp * (+tax + 1);
  return price.toFixed(0);
};

export const SNS_ENUM = {
  1: i18n.t('User.twitter'),
  2: i18n.t('User.youtube'),
  3: i18n.t('User.facebook'),
  4: i18n.t('User.instagram'),
  5: i18n.t('User.threads')
};