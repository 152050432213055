import Icon from '@ant-design/icons';

const HeartIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill={ props.fill || 'none'}
        viewBox='0 0 16 16'
        >
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M1.154 7.699c-.805-2.513.136-5.385 2.773-6.234A4.505 4.505 0 018 2.149c1.091-.844 2.679-1.129 4.065-.684 2.638.85 3.584 3.721 2.78 6.234C13.593 11.68 8 14.749 8 14.749s-5.552-3.021-6.846-7.05z'
          clipRule='evenodd'></path>
      </svg>
    )}
    {...props}
  />
);

export default HeartIcon;
