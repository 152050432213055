import React from 'react';
import styled from 'styled-components';
import { Image2Icon, Video2Icon } from 'images';

export const BlurImg = styled.div`
  background: url(${({ url }) => url});
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  cursor: pointer;
  .bg-img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9;
    background-size: cover;
    min-height: 100%;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
  }
  .cover-img {
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 10;
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }
`;

export const WrapperBlur = styled.div`
  position: absolute;
  inset: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 17;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(20px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  &.short-search-blur {
    border-radius: 20px;
  }

  &.hashtag-search-post {
    max-width: 100%;
    position: relative;
    inset: unset;
    top: 0;
    z-index: 100;
    left: 50%;
    bottom: 50%;

    transform: translate(-50%, -100%);
  }

  .text-follow {
    color: #1a1a1a;
    font-weight: 400;
    font-size: 12px;
  }

  button {
    background-color: #dc143c;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 6px 24px;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 700;
    height: 32px;
    &:hover {
      background-color: #ce1338 !important;
    }
  }
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`;

const StyledBookmark = {
  Wrapper: styled.div`
    position: absolute;
    top: 8px;
    left: 8px;
    opacity: 1;
    z-index: 10;
    display: flex;
    align-items: center;
    &.customPosition {
      left: 10.5px;
      top: 10.5px;
    }
    svg {
      min-height: 22px;
      max-height: 22px;
      min-width: 22px;
      max-width: 22px;
      color: #ddd;
    }
  `,
};
export const IconTypePost = ({ className, type, ...props }) => {
  return (
    <>
      {(type === 'image' || type === 'video') && (
        <StyledBookmark.Wrapper className={`${className}`} {...props}>
          {type === 'image' && <Image2Icon className='icon' />}
          {type === 'video' && <Video2Icon className='icon' />}
        </StyledBookmark.Wrapper>
      )}
    </>
  );
};
