import { configParamsGet, configParamsPostNoBody, ENV, updateStatus } from 'utils/constant';
import { callApi } from 'utils/request';
import { TYPES } from '.';
import { queryString } from '../../utils/utils';

const API = {
  postBuyArticle: (post_id, buy_by_ticket, price) =>
    `${ENV}/posts/single-sale/${post_id}/buy?buy_by_ticket=${buy_by_ticket}&price=${price}`,
  postBuyArticle1: (post_id, buy_by_ticket) =>
    `${ENV}/posts/single-sale/${post_id}/buy?buy_by_ticket=${buy_by_ticket}`,
  postNormalBuyPackage: (package_id) => `${ENV}/packages/${package_id}/buy`,
  getListPackageAnotherCreator: (creator_id, limit, page) =>
    `${ENV}/users/${creator_id}/packages?limit=${limit}&page=${page}`,
  postUnSubsriptionPackage: (package_id) => `${ENV}/packages/${package_id}/stop`,
  getListPostBuyed: (creator_id, querystring) =>
    `${ENV}/user/purchased-posts/${creator_id}?${queryString(querystring)}`,
  getListOfPurchasedPostCreators: (page, limit) =>
    `${ENV}/purchased-users?page=${page}&limit=${limit}`,
  getFanTicketsCreatorTotal: (creator_id) => `${ENV}/tickets/${creator_id}/total`,
};

const resetActionSellPost = () => (dispatch) => {
  dispatch(updateStatus('RESET_ACTION_SELL_POST'));
};

const postBuyArticle = (post_id, buy_by_ticket, price, handleSuccess) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.POST_BUY_ARTICLE_REQUEST));
    callApi(
      buy_by_ticket === 0 ? API.postBuyArticle(post_id, buy_by_ticket, price) : API.postBuyArticle1(post_id, buy_by_ticket),
      configParamsPostNoBody,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.POST_BUY_ARTICLE_SUCCESS, {
            res: response,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.POST_BUY_ARTICLE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        handleSuccess && handleSuccess();
        if(error?.errors?.messageId === 'THE_POST_HAS_BEEN_EDITED' ||  error?.errors?.messageId === 'DISCOUNT_HAS_ENDED') {
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      }
    );
  };
};

const postNormalBuyPackage = (package_id, handleSuccess, handleError) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.POST_NORMAL_BUY_PACKAGE_REQUEST));
    callApi(
      API.postNormalBuyPackage(package_id),
      configParamsPostNoBody,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.POST_NORMAL_BUY_PACKAGE_SUCCESS, {
            res: response,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleError && handleError(error.errors);
        dispatch(updateStatus(TYPES.POST_NORMAL_BUY_PACKAGE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getListPackageAnotherCreator = (creator_id, limit = 10, page) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_PACKAGE_ANOTHER_CREATOR_REQUEST));
    callApi(
      API.getListPackageAnotherCreator(creator_id, limit, page),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_PACKAGE_ANOTHER_CREATOR_SUCCESS, {
            res: response,
          })
        );
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_PACKAGE_ANOTHER_CREATOR_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const postUnSubsriptionPackage = (package_id, callback) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.POST_UNSUBSCRITION_PACKAGE_REQUEST));
    callApi(
      API.postUnSubsriptionPackage(package_id),
      configParamsPostNoBody,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.POST_UNSUBSCRITION_PACKAGE_SUCCESS, {
            res: response,
          })
        );
        callback && callback();
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.POST_UNSUBSCRITION_PACKAGE_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getListPostBuyed = (creator_id, querystring) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_POST_BUYED_REQUEST));
    callApi(
      API.getListPostBuyed(creator_id, querystring),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_POST_BUYED_SUCCESS, {
            res: response,
          })
        );
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_POST_BUYED_FAILED, {
            error: error.errors,
          })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getListOfPurchasedPostCreators = (page, limit) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_OF_PURCHASED_POST_CREATOR_REQUEST));
    callApi(
      API.getListOfPurchasedPostCreators(page, limit),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_OF_PURCHASED_POST_CREATOR_SUCCESS, {
            res: response,
          })
        );
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_OF_PURCHASED_POST_CREATOR_FAILED, {
            error: error.errors,
          })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getFanTicketsCreatorTotal = (creator_id, handleSuccess) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_FAN_TICKET_CREATOR_TOTAL_REQUEST));
    callApi(
      API.getFanTicketsCreatorTotal(creator_id),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_FAN_TICKET_CREATOR_TOTAL_SUCCESS, {
            res: response,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_FAN_TICKET_CREATOR_TOTAL_FAILED, {
            error: error.errors,
          })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const setIdScrollBackSellPackage = (id, cb) => {
  return (dispatch) => {
    dispatch(
      updateStatus('SET_ID_SCROLL_BACK_SELL_PACKAGE', {
        id,
      })
    );
    cb && cb();
  };
};

const clearIdScrollBackSellPackageAndData = () => (dispatch) => {
  dispatch(updateStatus('CLEAR_ID_SCROLL_BACK_SELL_PACKAGE_AND_DATA'));
};

export {
  postBuyArticle,
  resetActionSellPost,
  postNormalBuyPackage,
  getListPackageAnotherCreator,
  postUnSubsriptionPackage,
  getListPostBuyed,
  getListOfPurchasedPostCreators,
  getFanTicketsCreatorTotal,
  setIdScrollBackSellPackage,
  clearIdScrollBackSellPackageAndData,
};
