import { useTranslation } from 'react-i18next';

import { LayoutCenter } from 'components';
import { CONTENT_EN, CONTENT_JP } from './const';

import './styles.scss';

const USC2257Screen = () => {
  const { i18n } = useTranslation();

  const renderContentEn = CONTENT_EN?.map((item, index) => (
    <div key={index} className='wrapper-content'>
      <div>
        <strong>{item?.title} </strong>
        {item?.content}
      </div>
    </div>
  ));

  const renderContentJP = CONTENT_JP?.map((item, index) => (
    <div key={index} className='wrapper-content'>
      <div>
        <strong>{item?.title} </strong>
        {item?.content}
      </div>
    </div>
  ));

  return (
    <>
      {i18n.language === 'en' ? (
        <LayoutCenter
          titlePage={'USC2257'}
          notLogin={true}
          isShowLeftIcon={false}
          className={'title-page-creator page-legal'}
          uiMobile={true}>
          <div className={`container-fluid w-100 bg-white term-page creator`}>
            <div className='title-chapter'>2257 Disclosure Statement</div>

            {renderContentEn}
          </div>
        </LayoutCenter>
      ) : (
        <LayoutCenter
          titlePage='USC2257'
          notLogin={true}
          isShowLeftIcon={false}
          className={'title-page-creator page-legal'}
          uiMobile={true}>
          <div className={`container-fluid w-100 bg-white term-page creator`}>
            <div className='title-chapter'>2257 開示声明</div>

            {renderContentJP}
          </div>
        </LayoutCenter>
      )}
    </>
  );
};

export default USC2257Screen;
