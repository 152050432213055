/* eslint-disable import/no-anonymous-default-export */

import { TYPES } from 'store/actions';
import { LIVES_TYPES } from 'store/actions/livesTypes';
import { getEnv } from '../../configs/env';
import lodash from 'lodash';

const initialState = {
  loading: false,
  loadingFollowLive: false,
  // media devices
  streams: [],
  localStream: null,
  currentStream: null,
  viewStatus: null, // Status cua live 'private' || 'public'
  otherStreams: [],
  devicesList: [],

  config: {
    uid: 0,
    host: true,
    channelName: '',
    token: getEnv('REACT_APP_AGORA_APP_TOKEN'),
    resolution: '480p',
    // ...readDefaultState(),
    microphoneId: '',
    cameraId: '',
  },
  agoraClient: null,
  mode: 'live',
  codec: 'vp8',
  muteVideo: false,
  muteAudio: false,
  screen: false,
  profile: false,
  views: 0,
  // beauty: false
  testData: [],
  createData: null,
  error: null,
  loadingPackage: false, //* Using when call api get list package
  loadingUserKeyWord: false, //* Using when call api get list package
  packageData: [],
  listUserData: [],
  listUserDistributionData: [],
  allowMember: [], // list member get tu api,
  loadingAllFan: false, //* Using when call api allowMember,
  loadingCreate: false,
  loadingChangeStatus: false, // status call api Change active / draft live
  loadingLives: false, //! Use Fan load api list lives
  dataListLives: [], //! Use Fan List Data
  dataLiveStream: null,
  listLiveStream: [],
  useInfoLoading: false, //! Use Fan View LiveStream
  userInfo: null, //! Use Fan View LiveStream,
  loadingDeleteLive: false, //! Loading when delete live
  dataTopDonate: [], //! ARRAY LIST DATA USER DONATE CREATOR
  loadingTopDonate: false,

  loadingUpdateC92: false,
  buyPackageSuccess: false,

  dataLastLive: null, //! DATA LAST LIVESTREAM USER
  dataAfterDonate: null, //! DU LIEU SAU KHI FAN DONATE
  dataListPointsPackages: [], //! LIST STAMPS PACKAGES POINTS
  dataPreLivestream: {}, // Data pre-livestream
  isShowAgoraObs: false,
  dataListEffectCreator: [],
  listCheckPassword: null,
  pagination: {},
  loadingListLive: false,
  paginationListLive: {},
  idListLiveScrollBack: null,
  idLiveDeliveryScrollBack: null,
  paginationDelivery: {},
  listPackageSub: [],
  idScrollBackPackageSub: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    //* HANDLING LIVE STREAM DATA
    case LIVES_TYPES.SAVE_LIVE_STREAM_CREATED:
      return {
        ...state,
        createData: action.payload.data,
      };
    //* END HANDLING LIVE STREAM DATA
    //* GET LIST PACKAGE
    case TYPES.LIST_ALL_PACKAGE_REQUEST:
      return {
        ...state,
        loadingPackage: true,
      };
    case TYPES.LIST_ALL_PACKAGE_SUCCESS:
      return {
        ...state,
        packageData: action.payload.data,
        listPackageSub: action.payload.data,
        loadingPackage: false,
      };
    case TYPES.LIST_ALL_PACKAGE_FAILED:
      return {
        ...state,
        error: action.payload.error,
        loadingPackage: false,
      };
    case TYPES.LIST_ALL_USER_BY_KEY_REQUEST:
      return {
        ...state,
        loadingUserKeyWord: true,
      };
    case TYPES.LIST_ALL_USER_BY_KEY_DISTRIBUTION_REQUEST:
      return {
        ...state,
        loadingUserKeyWord: true,
      };
    case TYPES.LIST_ALL_USER_BY_KEY_SUCCESS:
      return {
        ...state,
        listUserData: action.payload.data,
        loadingUserKeyWord: false,
      };
    case TYPES.LIST_ALL_USER_BY_KEY_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        listUserDistributionData: action.payload.data,
        loadingUserKeyWord: false,
      };
    case TYPES.LIST_ALL_USER_BY_KEY_FAILED:
      return {
        ...state,
        error: action.payload.error,
        loadingUserKeyWord: false,
      };
    case TYPES.LIST_ALL_USER_BY_KEY_DISTRIBUTION_FAILED:
      return {
        ...state,
        error: action.payload.error,
        loadingUserKeyWord: false,
      };
    case LIVES_TYPES.SET_EMPTY_PACKAGE: {
      return {
        ...state,
        packageData: [],
      };
    }
    case LIVES_TYPES.SET_VALUE_PACKAGE: {
      return {
        ...state,
        packageData: action.payload.data,
      };
    }
    //* END GET LIST PACKAGE AND LIST allowMember

    //* GET LIST  LIST allowMember
    case TYPES.LIST_ALL_FAN_REQUEST:
      return {
        ...state,
        loadingAllFan: true,
      };
    case TYPES.LIST_ALL_FAN_SUCCESS:
      return {
        ...state,
        allowMember: action.payload.data,
        loadingAllFan: false,
      };

    case TYPES.LIST_ALL_FAN_FAILED:
      return {
        ...state,
        loadingAllFan: false,
        error: action.payload.error,
      };
    case LIVES_TYPES.SET_EMPTY_FAN_LIST:
      return {
        ...state,
        allowMember: [],
      };
    //* END GET LIST allowMember

    //* CREATOR - CREATE LIVE
    /* Dan - update 23-3-2022 them loading */
    case TYPES.CREATOR_CREATE_LIVE_REQUEST:
    case TYPES.CREATOR_UPDATE_LIVE_REQUEST:
      return {
        ...state,
        status: action.type,
        loadingCreate: true,
        loadingUpdateC92: true,
        isShowAgoraObs: false,
      };
    case TYPES.CREATOR_CREATE_LIVE_SUCCESS:
    case TYPES.CREATOR_UPDATE_LIVE_SUCCESS:
      return {
        ...state,
        status: action.type,
        createData: action.payload.data,
        dataLiveStream: action.payload.data, //! C092 using
        loadingCreate: false,
        loadingUpdateC92: false,
        isShowAgoraObs: true,
      };
    case TYPES.CREATOR_CREATE_LIVE_FAILED:
    case TYPES.CREATOR_UPDATE_LIVE_FAILED:
      console.log('CREATE_LIVE_FAILED: ', action);
      return {
        ...state,
        status: action.type,
        error: action.payload.error,
        loadingCreate: false,
        loadingUpdateC92: true,
        isShowAgoraObs: true,
      };

    //* END - CREATE LIVE

    //* CHANGE STATUS DRAF TO ACTIVE LIVE

    case TYPES.CREATOR_CHANGE_ACTIVE_DRAF_LIVE_REQUEST:
      return {
        ...state,
        loadingChangeStatus: true,
      };

    case TYPES.CREATOR_CHANGE_ACTIVE_DRAF_LIVE_SUCCESS:
      // console.log("SDSD: ", data?.live_key);
      return {
        ...state,
        createData: action.payload.data,
        loadingChangeStatus: false,
      };

    case TYPES.CREATOR_CHANGE_ACTIVE_DRAF_LIVE_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.payload.error,
        loadingChangeStatus: false,
      };

    //* END CHANGE STATUS DRAF TO ACTIVE LIVE

    //!  CHANGE STATUS LIVE TO ACTIVE

    case TYPES.CREATOR_CHANGE_STATUS_TO_LIVE_REQUEST:
      return {
        ...state,
        loadingChangeStatus: true,
      };

    case TYPES.CREATOR_CHANGE_STATUS_TO_LIVE_SUCCESS:
      return {
        ...state,
        createData: action.payload.data,
        dataLiveStream: action.payload.data,
        loadingChangeStatus: false,
      };
    case TYPES.CREATOR_CHANGE_STATUS_TO_LIVE_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.payload.error,
        loadingChangeStatus: false,
      };

    //! END CHANGE STATUS LIVE TO ACTIVE

    //! CHANGE STATUS LIVE TO PAUSE

    case TYPES.CREATOR_CHANGE_STATUS_TO_PAUSE_REQUEST:
      return {
        loadingChangeStatus: true,
      };

    case TYPES.CREATOR_CHANGE_STATUS_TO_PAUSE_SUCCESS:
      return {
        loadingChangeStatus: false,
      };

    case TYPES.CREATOR_CHANGE_STATUS_TO_PAUSE_FAILED:
      return {
        status: action.type,
        error: action.payload.error,
        loadingChangeStatus: false,
      };

    //! END CHANGE STATUS LIVE TO PAUSE

    //! CHANGE STATUS LIVE TO END

    case TYPES.CREATOR_CHANGE_STATUS_TO_END_REQUEST:
      return {
        ...state,
        loadingChangeStatus: true,
      };

    case TYPES.CREATOR_CHANGE_STATUS_TO_END_SUCCESS:
      return {
        ...state,
        createData: action.payload.data,
        loadingChangeStatus: false,
      };

    case TYPES.CREATOR_CHANGE_STATUS_TO_END_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.payload.error,
        loadingChangeStatus: false,
      };

    //! END CHANGE STATUS LIVE TO END

    // todo : LIST LIVES

    case TYPES.LIST_LIVES_REQUEST:
      return {
        ...state,
        loadingLives: true,
      };

    case TYPES.LIST_LIVES_SUCCESS:
      if (+action?.payload?.data?.page === 1) {
        return {
          ...state,
          dataListLives: action.payload.data.data,
          loadingLives: false,
          paginationDelivery: action.payload.data.pagination,
        };
      }
      return {
        ...state,
        dataListLives: [...state.dataListLives, ...action.payload.data.data],
        loadingLives: false,
        paginationDelivery: action.payload.data.pagination,
      };
    case TYPES.LIST_LIVES_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.payload.error,
        loadingLives: false,
      };
    case TYPES.GET_TOP_DONATE_REQUEST:
      return {
        ...state,
        // dataTopDonate: [],
        loadingTopDonate: true,
      };
    case TYPES.GET_TOP_DONATE_SUCCESS: {
      const newValues = [...state.dataTopDonate, ...action?.payload?.data];
      // const result = Array.from(
      //   newValues.reduce(
      //     (r, o) => r.set(o.user_id, o),
      //     new Map()
      //   ).values());
      return {
        ...state,
        dataTopDonate: newValues,
        loadingTopDonate: false,
      };
    }
    case TYPES.GET_TOP_DONATE_FAILED:
      return {
        ...state,
        error: action.payload.error,
        loadingTopDonate: false,
      };

    case TYPES.RESET_DATA_TOP_DONATE:
      return {
        ...state,
        dataTopDonate: [],
      };

    case 'RESET_LIST_LIVESTREAM_MY_SEFT':
      return {
        ...state,
        listLiveStream: [],
        idListLiveScrollBack: null,
        paginationListLive: {},
      };
    // todo : END LIST LIVES

    case LIVES_TYPES.CONFIG: {
      return { ...state, config: { ...state.config, ...action.payload } };
    }
    case LIVES_TYPES.CLIENT: {
      return { ...state, client: action.payload };
    }
    case LIVES_TYPES.LOADING: {
      return { ...state, loading: action.payload };
    }
    case LIVES_TYPES.CODEC: {
      return { ...state, codec: action.payload };
    }
    case LIVES_TYPES.VIDEO: {
      return { ...state, muteVideo: action.payload };
    }
    case LIVES_TYPES.AUDIO: {
      return { ...state, muteAudio: action.payload };
    }
    case LIVES_TYPES.SCREEN: {
      return { ...state, screen: action.payload };
    }
    case LIVES_TYPES.DEVICES_LIST: {
      return { ...state, devicesList: action.payload };
    }

    case LIVES_TYPES.LOCAL_STREAM: {
      return { ...state, localStream: action.payload };
    }

    case 'profile': {
      return { ...state, profile: action.payload };
    }
    case LIVES_TYPES.CURRENT_STREAM: {
      const { streams } = state;
      const newCurrentStream = action.payload;
      const otherStreams = streams.filter((it) => it.getId() !== newCurrentStream.getId());
      return { ...state, currentStream: newCurrentStream, otherStreams };
    }
    case LIVES_TYPES.ADD_STREAM: {
      const { streams, currentStream } = state;
      const newStream = action.payload;
      let newCurrentStream = currentStream;
      if (!newCurrentStream) {
        newCurrentStream = newStream;
      }
      if (streams.length === 4) return { ...state };
      const newStreams = [...streams, newStream];
      const otherStreams = newStreams.filter((it) => it.getId() !== newCurrentStream.getId());
      return {
        ...state,
        streams: newStreams,
        currentStream: newCurrentStream,
        otherStreams,
      };
    }
    case LIVES_TYPES.REMOVE_STREAM: {
      const { streams, currentStream } = state;
      const { stream, uid } = action;
      const targetId = stream ? stream.getId() : uid;
      let newCurrentStream = currentStream;
      const newStreams = streams.filter((stream) => stream.getId() !== targetId);
      if (currentStream && targetId === currentStream.getId()) {
        if (newStreams.length === 0) {
          newCurrentStream = null;
        } else {
          newCurrentStream = newStreams[0];
        }
      }
      const otherStreams = newCurrentStream
        ? newStreams.filter((it) => it.getId() !== newCurrentStream.getId())
        : [];
      return {
        ...state,
        streams: newStreams,
        currentStream: newCurrentStream,
        otherStreams,
      };
    }
    case LIVES_TYPES.CLEAR_ALL_STREAM: {
      // const {streams, localStream, currentStream, beauty} = state;
      const { streams, localStream, currentStream } = state;
      streams.forEach((stream) => {
        if (stream.isPlaying()) {
          stream.stop();
        }
        // stream.close();
      });

      if (localStream) {
        localStream.isPlaying() && localStream.stop();
        localStream.close();
      }
      if (currentStream) {
        currentStream.isPlaying() && currentStream.stop();
        currentStream.close();
      }
      return { ...state, currentStream: null, localStream: null, streams: [] };
    }
    // case 'enableBeauty': {
    //   return {
    //     ...state,
    //     beauty: action.enable
    //   }
    // }
    //START MORE TIME LIVESTREAM
    case TYPES.MORE_TIME_LIVESTREAM_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TYPES.MORE_TIME_LIVESTREAM_SUCCESS:
      return {
        ...state,
        dataLiveStream: action.payload.data,
        loading: false,
      };

    case TYPES.MORE_TIME_LIVESTREAM_FAILED:
      return {
        ...state,
        status: false,
        error: action.payload.error,
        loading: false,
      };
    //END MORE TIME LIVESTREAM

    //* START CREATOR_GET_LIVE_BY_KEY
    case TYPES.CREATOR_GET_LIVE_BY_KEY_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TYPES.CREATOR_GET_LIVE_BY_KEY_SUCCESS:
      return {
        ...state,
        dataLiveStream: action.payload.data,
        loading: false,
      };

    case TYPES.CREATOR_GET_LIVE_BY_KEY_FAILED:
      return {
        ...state,
        status: false,
        error: action.payload.error,
        loading: false,
      };

    //* END CREATOR_GET_LIVE_BY_KEY

    //* INFO FAN AND CHECK FOLLOW

    case TYPES.IS_FOLLOW_REQUEST:
      return {
        ...state,
        useInfoLoading: true,
      };
    case TYPES.IS_FOLLOW_SUCCESS:
      return {
        ...state,
        userInfo: action.payload.data,
        useInfoLoading: false,
      };
    case TYPES.IS_FOLLOW_FAILED:
      return {
        ...state,
        useInfoLoading: false,
      };

    //* END INFO FAN AND CHECK FOLLOW

    //* FAN BUY PACKAGE CREATOR

    case TYPES.FAN_BUY_PACKAGE_REQUEST:
      return {
        ...state,
        loading: true,
        buyPackageSuccess: false,
      };
    case TYPES.FAN_BUY_PACKAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        buyPackageSuccess: true,
        userInfo: action.payload.data,
      };
    case TYPES.FAN_BUY_PACKAGE_FAILED:
      return {
        ...state,
        loading: false,
        buyPackageSuccess: false,
      };
    //* END FAN BUY PACKAGE CREATOR

    // START UPDATE LIVESTREAM

    case TYPES.GET_LIST_LIVE_STREAM_REQUEST:
    case TYPES.CREATOR_UPDATE_LIVESTREAM_REQUEST:
      return {
        ...state,
        loadingListLive: true,
      };

    case TYPES.GET_LIST_LIVE_STREAM_FAILED:
    case TYPES.CREATOR_UPDATE_LIVESTREAM_FAILED:
      return {
        ...state,
        status: false,
        error: action?.payload?.error,
        loadingListLive: false,
      };

    case TYPES.DELETE_LIVE_STREAM_REQUEST:
      return {
        ...state,
        loadingDeleteLive: true,
      };
    case TYPES.DELETE_LIVE_STREAM_SUCCESS:
      state.listLiveStream = state.listLiveStream?.filter(
        (item) => item?.id !== action?.payload?.data
      );
      return {
        ...state,
        loadingDeleteLive: false,
      };
    case TYPES.DELETE_LIVE_STREAM_FAILED:
      return {
        ...state,
        loadingDeleteLive: false,
      };
    case TYPES.CREATOR_UPDATE_LIVESTREAM_SUCCESS:
      return {
        ...state,
        dataLiveStream: action.payload.data,
        loading: false,
      };

    case TYPES.GET_LIST_LIVE_STREAM_SUCCESS:
      if (+action?.payload?.data?.page === 1) {
        return {
          ...state,
          listLiveStream: action?.payload?.data?.data,
          paginationListLive: action?.payload?.data?.pagination,
          loadingListLive: false,
        };
      }
      return {
        ...state,
        listLiveStream: [...state?.listLiveStream, ...action?.payload?.data?.data],
        paginationListLive: action?.payload?.data?.pagination,
        loadingListLive: false,
      };

    case TYPES.RESET_LIST_ALL_LIVE_CREATOR_SUCCESS:
      return {
        ...state,
        listLiveStream: [],
        loading: false,
      };

    case TYPES.GET_DETAIL_LIVE_STREAM_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TYPES.GET_DETAIL_LIVE_STREAM_SUCCESS:
      console.log('GET_DETAIL_LIVE_STREAM_SUCCESS: ', action.payload?.data);
      return {
        ...state,
        dataLiveStream: action.payload?.data,
        loading: false,
      };
    case TYPES.GET_DETAIL_LIVE_STREAM_FAILED:
      // console.log("MMMMMM: ", action.payload);
      return {
        ...state,
        error: action?.payload?.error,
        loading: false,
      };
    // END UPDATE LIVESTREAM

    //* FOLLOW OR UNFOLLOW

    case TYPES.FOLLOW_UNFOLLOW_LIVE_REQUEST:
      return {
        ...state,
        loadingFollowLive: true,
        loading: true,
      };
    case TYPES.FOLLOW_UNFOLLOW_LIVE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case TYPES.FOLLOW_UNFOLLOW_LIVE_FAILED:
      return {
        ...state,
        loading: false,
      };

    //* END FOLLOW OR UNFOLLOW

    //* FAN DONATE

    case TYPES.FAN_DONATE_CREATOR_LIVE_REQUEST:
      return {
        ...state,
        status: action.type,
        loading: true,
      };

    case TYPES.FAN_DONATE_CREATOR_LIVE_SUCCESS:
      // console.log("DSDSDSD: ", action?.payload?.data);
      return {
        ...state,
        status: action.type,
        dataAfterDonate: action?.payload?.data,
        loading: false,
      };

    case TYPES.FAN_DONATE_CREATOR_LIVE_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.message,
        loading: false,
      };

    //* END FAN DONATE

    case TYPES.RESET_STATUS_FAN_DONATE:
      return {
        ...state,
        status: '',
        loading: false,
      };

    //* GET LIST PACKAGES POINTS
    case TYPES.FAN_LIST_PACKAGES_REQUEST:
      return {
        ...state,
        status: action.type,
        loading: true,
      };

    case TYPES.FAN_LIST_PACKAGES_SUCCESS:
      return {
        ...state,
        status: action.type,
        dataListPointsPackages: action?.payload?.data,
        loading: false,
      };

    case TYPES.FAN_LIST_PACKAGES_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.message,
        loading: false,
      };

    //* GET LIVESTREAM BY USER

    case TYPES.GET_LAST_LIVE_REQUEST:
      return {
        ...state,
        status: action.type,
        loadingCreate: true,
      };

    case TYPES.GET_LAST_LIVE_SUCCESS:
      return {
        ...state,
        status: action.type,
        dataLastLive: action?.payload?.data,
        // dataLiveStream: action?.payload?.data,
        loadingCreate: false,
      };

    case TYPES.GET_LAST_LIVE_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.message,
        dataLiveStream: null,
        loadingCreate: false,
      };

    case TYPES.CLEAR_DATA_LIVE_REQUEST:
      return {
        ...state,
        dataLiveStream: null,
        createData: null,
      };

    //* END GET LIVESTREAM BY USER

    //* END GET LIST PACKAGES POINTS
    // Get detail pre-livestream
    case TYPES.GET_DETAIL_PRE_LIVESTREAM_REQUEST:
      return {
        ...state,
        status: action.type,
        dataPreLivestream: {},
        loading: true,
      };
    case TYPES.GET_DETAIL_PRE_LIVESTREAM_SUCCESS:
      return {
        ...state,
        status: action.type,
        dataPreLivestream: action.payload.data,
        loading: false,
      };
    case TYPES.GET_DETAIL_PRE_LIVESTREAM_FAILED:
      return {
        ...state,
        status: action.type,
        dataPreLivestream: {},
        loading: false,
      };
    // Create Token agora
    // Get detail pre-livestream
    case TYPES.CREATE_TOKEN_AGORA_REQUEST:
      return {
        ...state,
        status: action.type,
        loading: true,
        isShowAgoraObs: false,
      };
    case TYPES.CREATE_TOKEN_AGORA_SUCCESS:
      return {
        ...state,
        status: action.type,
        loading: false,
        isShowAgoraObs: true,
      };
    case TYPES.CREATE_TOKEN_AGORA_FAILED:
      return {
        ...state,
        status: action.type,
        loading: false,
        isShowAgoraObs: true,
      };
    case TYPES.CREATE_TOKEN_AGORA_GUEST_REQUEST:
      return {
        ...state,
        status: action.type,
        loading: true,
      };
    case TYPES.CREATE_TOKEN_AGORA_GUEST_SUCCESS:
      return {
        ...state,
        status: action.type,
        loading: false,
      };
    case TYPES.CREATE_TOKEN_AGORA_GUEST_FAILED:
      return {
        ...state,
        status: action.type,
        loading: false,
      };
    case TYPES.CREATOR_GET_EFFECT_REQUEST:
      return {
        ...state,
        status: action.type,
        loading: true,
      };

    case TYPES.CREATOR_GET_EFFECT_SUCCESS:
      return {
        ...state,
        status: action.type,
        dataListEffectCreator: action?.payload?.data,
        loading: false,
      };

    case TYPES.CREATOR_GET_EFFECT_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.message,
        loading: false,
      };
    case 'SET_ID_SCROLL_BACK_LIST_LIVE':
      return { ...state, idListLiveScrollBack: action?.payload?.id };
    case 'SET_ID_SCROLL_BACK_LIVE_DELIVERY':
      return { ...state, idLiveDeliveryScrollBack: action?.payload?.id };
    case 'SET_ID_SCROLL_BACK_PACKAGE_SUB':
      return { ...state, idScrollBackPackageSub: action?.payload?.id };
    case 'LIST_CHECK_PASSWORD':
      return {
        ...state,
        listCheckPassword: action.data,
      };
    case 'RESET_ACTION_GET_LIST_LIVE':
      return {
        ...state,
        dataListLives: [],
        paginationDelivery: {},
        idLiveDeliveryScrollBack: null,
      };
    case 'UPDATE_EDIT_PREMIUM_PACKAGE_SUB_DATA':
      if (state.listPackageSub?.length > 0) {
        state.listPackageSub = state.listPackageSub?.map((item) => {
          if (item?.id === action.payload.data?.data?.package_id) {
            item.ticket_status = action.payload.data?.data?.status;
          }
          return item
        });
      }
      return {
        ...state,
      };
    case 'CLEAR_ID_SCROLL_BACK_PACKAGE_SUB_DATA':
      return {
        ...state,
        listPackageSub: [],
        idScrollBackPackageSub: null,
      };
    default:
      return state;
  }
};
