import Icon from '@ant-design/icons';

const CameraDisableIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={44}
        height={44}
        viewBox='0 0 44 44'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx={22} cy={22} r='21.5' fill='#BEBEBE' stroke='#EEEEEE' />
        <path
          d='M13.1362 12.25L30.8635 31.75'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18.811 14.0325L18.9993 13.75H24.9993L26.4993 16H29.4999C29.8977 16 30.2792 16.158 30.5606 16.4393C30.8419 16.7206 30.9999 17.1022 30.9999 17.5V27.4402'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M28.8182 29.5H14.5C14.1022 29.5 13.7206 29.342 13.4393 29.0607C13.158 28.7794 13 28.3978 13 28V17.5C13 17.1022 13.158 16.7206 13.4393 16.4393C13.7206 16.158 14.1022 16 14.5 16H16.5454'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M24.4506 24.6956C24.1312 25.0328 23.7457 25.3005 23.3181 25.4819C22.8905 25.6633 22.4301 25.7544 21.9656 25.7497C21.5012 25.745 21.0427 25.6444 20.6189 25.4543C20.1951 25.2643 19.8151 24.9888 19.5027 24.6451C19.1903 24.3014 18.9522 23.897 18.8032 23.457C18.6543 23.0171 18.5978 22.5512 18.6372 22.0884C18.6766 21.6256 18.8111 21.1759 19.0323 20.7675C19.2535 20.3591 19.5566 20.0007 19.9226 19.7148'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default CameraDisableIcon;
