import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

const useOutsideHover = (ref, callback, outcb) => {
  const handleHover = (e) => {
    callback(e);
  };

  const handleOutHover = (e) => {
    outcb(e);
  };
  useEffect(() => {
    const node = ref?.current;
    if (node) {
      node.addEventListener('mouseenter', handleHover);
      node.addEventListener('mouseleave', handleOutHover);

      return () => {
        node.removeEventListener('mouseenter', handleHover);
        node.removeEventListener('mouseleave', handleOutHover);
      };
    }
  });
};
const useOutsideHoverDom = (ref, callback, outcb) => {
  const handleHover = (e) => {
    callback(e);
  };

  const handleOutHover = (e) => {
    outcb(e);
  };
  useEffect(() => {
    if (ref) {
      ref?.addEventListener('mouseenter', handleHover);
      ref?.addEventListener('mouseleave', handleOutHover);

      return () => {
        ref?.removeEventListener('mouseenter', handleHover);
        ref?.removeEventListener('mouseleave', handleOutHover);
      };
    }
  });
};
const useOutsideMultipleDom = (ref, callback, outcb) => {
  const handleHover = (e) => {
    callback(e);
  };

  const handleOutHover = (e) => {
    outcb(e);
  };
  useEffect(() => {
    if (ref) {
      ref?.forEach((item) => {
        item?.addEventListener('mouseenter', handleHover);
        item?.addEventListener('mouseleave', handleOutHover);
        return () => {
          item?.removeEventListener('mouseenter', handleHover);
          item?.removeEventListener('mouseleave', handleOutHover);
        };
      });
    }
  });
};
function useMouseOver() {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const ref = useRef(null);

  const handleMouseOver = () => {
    setIsMouseOver(true);
  };

  const handleMouseOut = () => {
    setIsMouseOver(false);
  };

  useEffect(() => {
    const node = ref.current;
    if (node) {
      node.addEventListener('mouseover', handleMouseOver);
      node.addEventListener('mouseout', handleMouseOut);
    }

    return () => {
      if (node) {
        node.removeEventListener('mouseover', handleMouseOver);
        node.removeEventListener('mouseout', handleMouseOut);
      }
    };
  }, []);

  return [ref, isMouseOver];
}
export { useOutsideHover, useOutsideHoverDom, useOutsideMultipleDom, useMouseOver };
