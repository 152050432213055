export default function PurchasedHistory(props) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10 6.25V10'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13.2476 11.875L10 10'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.61133 7.79028H2.48633V4.66528'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.13798 14.8614C6.09946 15.8229 7.32447 16.4776 8.65809 16.7429C9.99171 17.0082 11.374 16.872 12.6303 16.3517C13.8865 15.8313 14.9603 14.9501 15.7157 13.8196C16.4711 12.689 16.8743 11.3598 16.8743 10C16.8743 8.64026 16.4711 7.31105 15.7157 6.18046C14.9603 5.04987 13.8865 4.16868 12.6303 3.64833C11.374 3.12798 9.99171 2.99183 8.65809 3.2571C7.32447 3.52238 6.09946 4.17716 5.13798 5.13864L2.48633 7.79029'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
