import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import './styles.scss';
import {
  creatorGetListDateLiveInMonth,
  creatorGetListInDate,
  setIdScrollBackCalender,
} from 'store/actions/calendar';
import { convertToCurrentTime, formatDateToString } from 'utils/utils';
import EventItem from './Event';
import { LayoutCreatorRight } from 'components';
import Calendar, { CalendarDayHeader } from 'components/CalendarCustom/calendar.js';
import { useGetIdFromRecommendUser } from 'hooks/v2/useGetIdFromRecommendUser';

export default function Index() {
  const {
    idScrollBackCalender,
    paramsCreatorListLiveInDate,
    paramsCreatorListDateLive,
    creatorListDateLive,
    creatorListLiveInDate,
    loading,
  } = useSelector((state) => state.calendar);
  const { idFromRecommendUser, clearIdScrollBackRecommend } = useGetIdFromRecommendUser();
  const [yearAndMonth, setYearAndMonth] = useState(
    idScrollBackCalender && paramsCreatorListDateLive
      ? [paramsCreatorListDateLive?.year, paramsCreatorListDateLive?.month]
      : [new Date().getFullYear(), new Date().getMonth() + 1]
  );
  const [now, setNow] = useState(
    paramsCreatorListLiveInDate ? paramsCreatorListLiveInDate?.date : 0
  );
  const [params, setParams] = useState({
    date: formatDateToString(new Date()),
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!idScrollBackCalender && !idFromRecommendUser) {
      dispatch(
        creatorGetListDateLiveInMonth({
          year: yearAndMonth[0],
          month: yearAndMonth[1],
        })
      );
      dispatch(creatorGetListInDate(params));
    }
  }, [dispatch, params, yearAndMonth, idScrollBackCalender, idFromRecommendUser]);

  useEffect(() => {
    if (idScrollBackCalender) {
      const idPost = document.getElementById(idScrollBackCalender);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    } else {
      const top = document.getElementById('app_cocofans_0');
      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
    }
  }, [idScrollBackCalender]);

  const setIdAndNavigate = (id, cb) => {
    dispatch(setIdScrollBackCalender(id, cb));
  };

  const convertListTime = () => {
    let arr = [];
    creatorListLiveInDate.map((item) => {
      let temp = {
        ...item,
        time: new Date(convertToCurrentTime(item?.time_start_event)).getHours(),
        time_start_event: convertToCurrentTime(item?.time_start_event),
        check_live: item?.livestream?.status === 3 ? true : false,
      };

      return arr.push(temp);
    });
    const newArr = arr.reduce((acc, obj) => {
      const key = new Date(obj?.time_start_event).getHours().toString();
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, []);
    return newArr;
  };

  return (
    <>
      <LayoutCreatorRight
        noButtonBack
        titlePage={t('Common.calendar')}
        className='calendar-layout'>
        <div className='calendar-page creator'>
          <Calendar
            isCalendarPage={true}
            yearAndMonth={yearAndMonth}
            onYearAndMonthChange={setYearAndMonth}
            renderDay={(calendarDayObject) => (
              <div>
                <CalendarDayHeader
                  listEventDate={creatorListDateLive.map((v) => v.date) || []}
                  calendarDayObject={calendarDayObject}
                  now={now}
                  setNow={setNow}
                  setParams={setParams}
                  setIdAndNavigate={setIdAndNavigate}
                  clearIdScrollBackRecommend={clearIdScrollBackRecommend}
                />
              </div>
            )}
          />
          <div className='event-container shimmer-loading'>
            {loading && <ShimmerPostDetails hasImage imageType='circular' title />}
            {new Array(25).fill(0).map(
              (v, i) =>
                !loading && (
                  <div id={`calender-${i}`} className='event-time'>
                    <div className='hour'>{`${i}`.padStart(2, 0)}:00</div>
                    <div className='desc'>
                      {convertListTime().map(
                        (item, index) =>
                          index + 1 === i && (
                            <EventItem
                              idBack={`calender-${i}`}
                              key={index}
                              item={item}
                              setIdAndNavigate={setIdAndNavigate}
                            />
                          )
                      )}
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </LayoutCreatorRight>
    </>
  );
}
