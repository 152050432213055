import { LayoutFan } from 'components';
import Calendar, { CalendarDayHeader } from 'components/CalendarCustom/calendar.js';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fanGetListDateLiveInMonth, fanGetListInDate } from 'store/actions/calendar';
import { convertToCurrentTime, formatDateToString } from 'utils/utils';
import EventItem from './Event';
import './styles.scss';
export default function Index() {
  const { fanListDateLive, fanListLiveInDate, loading } = useSelector(
    (state) => state.calendar
  );
  const [yearAndMonth, setYearAndMonth] = useState([
    new Date().getFullYear(),
    new Date().getMonth() + 1,
  ]);
  const { t } = useTranslation();

  const [now, setNow] = useState(0);
  const [params, setParams] = useState({
    date: formatDateToString(new Date()),
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      fanGetListDateLiveInMonth({
        year: yearAndMonth[0],
        month: yearAndMonth[1],
      })
    );
    dispatch(fanGetListInDate(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, yearAndMonth]);

  const convertListTime = () => {
    let arr = [];
    fanListLiveInDate.map((item) => {
      let temp = {
        ...item,
        time: new Date(convertToCurrentTime(item?.time_start_event)).getHours(),
        time_start_event: convertToCurrentTime(item?.time_start_event),
        check_live: item?.livestream?.status === 3 ? true : false,
      };

      return arr.push(temp);
    });
    const newArr = arr.reduce((acc, obj) => {
      const key = new Date(obj?.time_start_event).getHours().toString();
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, []);
    return newArr;
  };

  return (
    <>
      <LayoutFan titleHeader={t('Common.calendar')} loading={loading}>
        <div className='calendar-page fan'>
          <Calendar
            yearAndMonth={yearAndMonth}
            onYearAndMonthChange={setYearAndMonth}
            renderDay={(calendarDayObject) => (
              <div>
                <CalendarDayHeader
                  listEventDate={fanListDateLive.map((v) => v.date) || []}
                  calendarDayObject={calendarDayObject}
                  now={now}
                  setNow={setNow}
                  setParams={setParams}
                />
              </div>
            )}
          />
          <div className='event-container'>
            {new Array(25).fill(0).map((v, i) => (
              <div className='event-time'>
                <div className='hour'>{`${i}`.padStart(2, 0)}:00</div>
                <div className='desc'>
                  {convertListTime().map(
                    (item, index) => index + 1 === i && <EventItem key={index} item={item} />
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </LayoutFan>
    </>
  );
}
