import { ReadMoreLess } from 'components';
import { CloseIcon } from 'images';
import LimitedTicketIconEN from 'images/LimitedTicketIconEN.png';
import LimitedTicketIconJP from 'images/LimitedTicketIconJP.png';
import PremiumTicketIconEN from 'images/PremiumTicketIconEN.png';
import PremiumTicketIconJP from 'images/PremiumTicketIconJP.png';
import { useCallback, useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import {
  getFanRequestPoint,
  getPackage,
  paymentVendo,
  postPaymentPonitVendo,
} from 'store/actions/payment';
import { PAYMENT_CONST, formatCoin } from 'utils';
import Storage from 'utils/storage';
import './popupPayment.scss';
import { clearCards } from 'store/actions/card';
import { toast } from 'react-toastify';

export const PopupPaymentCard = ({
  item,
  title,
  setIsShowPopupPayment,
  setIsShowPopupListCard,
  urlRedirectPayment,
  idPackage,
  resetAll,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [loadingPackage, setLoadingPackage] = useState(false);
  const [itemPackage, setItemPackage] = useState(item);
  const [cardPayment, setCardPayment] = useState(null);

  const { cards, idCardActive } = useSelector((state) => state.card);
  const { setting_system } = useSelector((state) => state.payment);

  useEffect(() => {
    if (cards?.length > 0) {
      const itemDefault = cards?.find((e) => {
        if (idCardActive) {
          return e?.id === idCardActive;
        }
        return +e?.default === 1;
      });
      setCardPayment(itemDefault);
    } else {
      setCardPayment(null);
    }

    return () => {
      dispatch(clearCards());
    };
  }, [cards, dispatch, setCardPayment, idCardActive]);

  const convertNameTypeCard = (item) => {
    let type = '';
    const cardType = item?.card_type;
    if (item?.card_type === 'VISA') {
      type = 'Visa';
    } else if (cardType === 'MASTER_CARD') {
      type = 'MasterCard';
    } else if (cardType === 'JCB') {
      type = 'JCB';
    }
    return `${type}*${item?.card_number?.slice(-4)}`;
  };

  const handleSuccessPackage = useCallback((rs) => {
    if (rs) {
      setItemPackage(rs?.data);
    }
    setLoadingPackage(false);
  }, []);

  useEffect(() => {
    if (item) {
      setItemPackage(item);
    } else {
      if (idPackage) {
        setLoadingPackage(true);
        dispatch(getPackage(idPackage, handleSuccessPackage));
      }
    }
  }, [dispatch, handleSuccessPackage, idPackage, item]);

  const paymentSuccess = (data) => {
    if (data?.status === 5) {
      Storage.set(PAYMENT_CONST.URL_REDIRECT, urlRedirectPayment);
      Storage.set(PAYMENT_CONST.CREDIT_CARD_ID, cardPayment?.id);
      window.location.href = data?.verification_url;
    }
  };

  const handlePayment = (code) => {
    dispatch(
      paymentVendo(
        {
          code,
          credit_card_id: cardPayment?.id,
          payment_gateway: setting_system
        },
        false,
        paymentSuccess
      )
    );
  };

  const handleSuccessRequest = (code) => {
    if (code) {
      dispatch(
        postPaymentPonitVendo(
          {
            package_id: itemPackage?.id || idPackage,
            payment_method: 1,
            code,
          },
          (codeBySub) => {
            Storage.set(PAYMENT_CONST.CODE_SUB, codeBySub);
            handlePayment(code);
          }
        )
      );
    }
  };

  const handleBySub = () => {
    dispatch(
      getFanRequestPoint({ payment_method: 1, is_sub: 1, point: 1 }, handleSuccessRequest, () => {
        var condition = navigator.onLine;
        if (!condition) {
          toast.error(t('Creator.Message.disconnect'), {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
            hideProgressBar: true,
            theme: 'colored',
            className: 'display-linebreak',
            enableMultiContainer: true,
            icon: ({ theme, type }) => (
              <svg
                width={18}
                height={18}
                viewBox='0 0 18 18'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z'
                  stroke='#DC143C'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M11.25 6.75L6.75 11.25'
                  stroke='#DC143C'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M11.25 11.25L6.75 6.75'
                  stroke='#DC143C'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            ),
          });
        }
      })
    );
  };

  return (
    <div className='popup popup-payment-card'>
      <div className='popup-content payment-card'>
        <>
          <div className='title'>{title}</div>
          <CloseIcon
            className='close-icon'
            onClick={() => {
              resetAll(true);
              setIsShowPopupPayment(false);
            }}
          />
        </>
        {loadingPackage ? (
          <div className='shimmer-loading'>
            <ShimmerPostDetails hasImage imageType='circular' title />
          </div>
        ) : (
          <div className='content'>
            <div className='content-wrapper'>
              <div className='popup-payment-content'>
                <div className='item-sub'>
                  <div
                    className={`plan-item-subscription px-3 py-4 mb-4 w-100 ${
                      itemPackage?.fan_buy_package && 'selled'
                    } ${!!itemPackage?.ticket_id ? 'premium' : ''} ${
                      !itemPackage?.fan_buy_package &&
                      itemPackage?.limited_flag &&
                      itemPackage?.number_paid >= itemPackage?.limited
                        ? 'limited'
                        : ''
                    }`}>
                    {!!itemPackage?.ticket_id &&
                      !(
                        !itemPackage?.fan_buy_package &&
                        itemPackage?.limited_flag &&
                        itemPackage?.number_paid >= itemPackage?.limited
                      ) && (
                        <div className='premium-label'>
                          {i18n?.language === 'en' ? (
                            <img src={PremiumTicketIconEN} alt='' />
                          ) : (
                            <img src={PremiumTicketIconJP} alt='' />
                          )}
                        </div>
                      )}
                    {!!(
                      !itemPackage?.fan_buy_package &&
                      itemPackage?.limited_flag &&
                      itemPackage?.number_paid >= itemPackage?.limited
                    ) && (
                      <div className='limited-label 111'>
                        {i18n?.language === 'en' ? (
                          <img src={LimitedTicketIconEN} alt='' />
                        ) : (
                          <img src={LimitedTicketIconJP} alt='' />
                        )}
                      </div>
                    )}
                    <p
                      className={`plan-item-subscription__title text-center ${
                        itemPackage?.ticket_id ? 'premium' : ''
                      }`}>
                      {itemPackage?.title}
                    </p>
                    <p className='plan-item-subscription__plan-price'>
                      {t('Common.oneMonth')}/ {formatCoin(itemPackage?.price)}
                      {t('Common.pt')}
                    </p>
                    <div className='plan-subscription p-3 display-linebreak'>
                      <ReadMoreLess
                        content={itemPackage?.description ? itemPackage?.description : ''}
                      />
                    </div>
                  </div>
                </div>
                <div className='item-info'>
                  <div className='block-info mt-24'>
                    <div className='block-info-item'>
                      <div className='block-info-item-first'>
                        {t('Purchase.payment.vendo.total')}:
                      </div>
                      <CurrencyFormat
                        className='block-info-item-last'
                        thousandSeparator={true}
                        value={itemPackage?.price}
                        displayType='text'
                        prefix='￥'
                      />
                    </div>
                  </div>
                  <div className='hyphen-payment'></div>
                  <div className='block-info'>
                    <div className='block-info-item'>
                      <div className='block-info-item-first'>
                        {t('Purchase.payment.vendo.paymentInformation')}:
                      </div>
                      <div className='block-info-item-last'>
                        {convertNameTypeCard(cardPayment)} &nbsp;
                        <span
                          className='btn-change'
                          onClick={() => {
                            setIsShowPopupPayment(false);
                            setIsShowPopupListCard(true);
                          }}>
                          {t('Purchase.payment.vendo.change')}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='hyphen-payment mb-24'></div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className='button-actions-relative'>
          <div className='box-button'>
            <button
              onClick={() => {
                handleBySub();
              }}
              className='btn btn-submit'>
              {t('Common.confirm')}
            </button>
            <button
              onClick={() => {
                setIsShowPopupPayment(false);
                setIsShowPopupListCard(true);
              }}
              className='btn btn-cancel'>
              {t('Common.return')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
