/* eslint-disable react-hooks/exhaustive-deps */
import useUserRole from 'hooks/useUserRole';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { STORAGE } from 'utils/constant';
import Storage from 'utils/storage';
import { resetActionBankAccount } from 'store/actions/bankAccount';
import { resetActionCalendar } from 'store/actions/calendar';
import { resetActionFeedBack } from 'store/actions/feedback';
import { resetActionMyPage } from 'store/actions/myPage';
import { resetNotifiSetting } from 'store/actions/notification';
import { resetActionSellPost } from 'store/actions/sellPost';
import { logout, resetActionUser } from 'store/actions/users';
import i18n from 'translate/i18n';
import { getQueryParams, setOverflowTablet } from 'utils';
import { useState } from 'react';
import { newPathUser } from 'constants/layout/constant';

function useApp() {
  const { isIdol, isFan } = useUserRole();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  // const urlParams = new URLSearchParams(location.search)?.get('current_language')?.toString();
  const oldEmail = Storage.get('email');
  const [loadingNavigateLogin, setLoadingNavigateLogin] = useState(false);
  /*
   * on scroll to top
   */
  useEffect(() => {
    window.scrollTo(0, 0);
    // if (urlParams && i18n.language !== urlParams) {
    //   i18n.changeLanguage(urlParams);
    //   navigate(0);
    // }
  }, []);
  /**
   *  handle navigate when login fan => creator, creator => fan
   */
  useEffect(() => {
    // if (
    //   location.pathname?.includes('/sign-up-by-fan') &&
    //   getQueryParams(location.search, 'email') &&
    //   isIdol
    // ) {
    //   setLoadingNavigateLogin(true);
    //   getQueryParams(location.search, 'is_link') === '1'
    //     ? isAuth
    //       ? getQueryParams(location.search, 'email') === oldEmail
    //         ? (window.location.href = `${newPathUser}`)
    //         : dispatch(
    //             logout(() => {
    //               window.location.href = '/sign-in';
    //             })
    //           )
    //       : (window.location.href = '/sign-in')
    //     : isAuth
    //     ? dispatch(
    //         logout(() => {
    //           window.location.href = `${location.pathname + location?.search}`;
    //         })
    //       )
    //     : setLoadingNavigateLogin(false);
    // }

    // if (
    //   isFan &&
    //   getQueryParams(location.search, 'email') &&
    //   location.pathname?.includes('/my-page')
    // ) {
    //   setLoadingNavigateLogin(true);
    //   isAuth
    //     ? getQueryParams(location.search, 'email') === oldEmail
    //       ? (window.location.href = '/my-page')
    //       : dispatch(
    //           logout(() => {
    //             window.location.href = '/sign-in';
    //           })
    //         )
    //     : (window.location.href = '/sign-in');
    // }

    // if (location?.pathname === '/' && isAuth) {
    //   window.location.href = `/home-page`
    // }
  }, []);
  /**
   * handle clear reducer
   */
  useEffect(() => {
    dispatch(resetActionUser());
    dispatch(resetActionMyPage());
    // dispatch(resetActionCalendar());
    dispatch(resetActionSellPost());
    dispatch(resetActionBankAccount());
    // dispatch(resetActionFeedBack());
    dispatch(resetNotifiSetting());
  }, [location]);
  /**
   * handle navigate when click other link
   */
  if (location?.pathname === `${newPathUser}`)
    // document.getElementById('root').style.overflow = 'auto';
    setOverflowTablet();
  else if (location?.pathname?.includes('/verify_age=true') && !isIdol && isAuth)
    navigate('/my-page');
  else if (location?.pathname?.includes('/verify_age=true') && isIdol && isAuth)
    navigate(`${newPathUser}`);

  return { loadingNavigateLogin };
}

export default useApp;
