import Footer from 'components/Footer';
import { useTranslation } from 'react-i18next';
import { ImgCustom } from '..';
import useLockBodyScroll from '../../hooks/useLockBodyScroll';

const PopupPaymentSuccess = ({ point = 0, children }) => {
  const { t } = useTranslation();
  useLockBodyScroll();
  return (
    <div className='popup payment-success'>
      <div className='popup-content'>
        <div className='content'>
          <ImgCustom className='mb-3' src='/images/icon/ic-tick.svg' alt='ic-tick' />
          <div className='point'>{point} {t('Popup.messageSuccessfullyHavePoint')}</div>
          <div className='box-button'>{children}</div>
        </div>
        <div className='footer'>
          <Footer isClass={true} />
        </div>
      </div>
    </div>
  );
};

export default PopupPaymentSuccess;
