import Icon from '@ant-design/icons';

const CloseIconV2 = (props) => (
  <Icon
    component={() => (
      <svg
        width={40}
        height={40}
        viewBox='0 0 40 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g filter='url(#filter0_d_3265_54888)'>
          <circle cx={20} cy={18} r={12} fill='#F7F8FA' />
          <path
            d='M20.7073 17.9998L24.8538 13.8533C24.9474 13.7595 25 13.6324 25 13.4998C24.9999 13.3673 24.9473 13.2402 24.8535 13.1464C24.7598 13.0527 24.6327 13 24.5001 13C24.3676 13 24.2405 13.0526 24.1467 13.1462L20.0002 17.2927L15.8538 13.1462C15.76 13.0526 15.6328 13 15.5003 13C15.3677 13 15.2406 13.0527 15.1469 13.1464C15.0532 13.2402 15.0005 13.3673 15.0004 13.4998C15.0004 13.6324 15.053 13.7595 15.1467 13.8533L19.2931 17.9998L15.1467 22.1462C15.1002 22.1926 15.0633 22.2478 15.0381 22.3084C15.013 22.3691 15 22.4341 15 22.4998C15 22.5655 15.0129 22.6306 15.038 22.6913C15.0632 22.7519 15.1 22.8071 15.1464 22.8535C15.1929 22.9 15.248 22.9368 15.3087 22.962C15.3694 22.9871 15.4345 23 15.5001 23C15.5658 23 15.6309 22.987 15.6915 22.9618C15.7522 22.9367 15.8073 22.8998 15.8538 22.8533L20.0002 18.7069L24.1467 22.8533C24.2405 22.947 24.3676 22.9996 24.5001 22.9995C24.6327 22.9995 24.7598 22.9468 24.8535 22.8531C24.9473 22.7594 24.9999 22.6323 25 22.4997C25 22.3672 24.9474 22.24 24.8538 22.1462L20.7073 17.9998Z'
            fill='#DC143C'
          />
        </g>
        <defs>
          <filter
            id='filter0_d_3265_54888'
            x={0}
            y={0}
            width={40}
            height={40}
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'>
            <feFlood floodOpacity={0} result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy={2} />
            <feGaussianBlur stdDeviation={4} />
            <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_3265_54888'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_3265_54888'
              result='shape'
            />
          </filter>
        </defs>
      </svg>
    )}
    {...props}
  />
);

export default CloseIconV2;
