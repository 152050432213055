import Icon from '@ant-design/icons';

const MinusIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M13.5 9.00223H4.5'
          stroke='#787880'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default MinusIcon;
