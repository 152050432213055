import { useEffect } from 'react';
import { useState } from 'react';
import { returnSizeStamps } from './utils';
import {
  effect_13,
  effect_14,
  effect_16,
  effect_19,
  effect_21,
  effect_3,
  effect_4,
  effect_6,
  effect_7,
  effect_8,
} from 'images';
import styled from 'styled-components';
import { useLayoutEffect } from 'react';
import { ImgCustom } from '../../../..';
import { isMobile } from 'react-device-detect';

const handleActionStamps = async (stampKey = '') => {
  if (stampKey !== '' || stampKey !== undefined) {
  }
};
const AutoExpireStamps = ({ stampKey, children }) => {
  const [isExpired, setIsExpired] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsExpired(true);
      handleActionStamps(stampKey);
    }, 1800);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  if (isExpired) {
    return null;
  }

  return <> {children} </>;
};

const showStampsById = (stampId) => {
  switch (stampId) {
    case 'effect_13':
      return effect_13;
    case 'effect_14':
      return effect_14;
    case 'effect_16':
      return effect_16;
    case 'effect_19':
      return effect_19;
    case 'effect_21':
      return effect_21;
    case 'effect_3':
      return effect_3;
    case 'effect_4':
      return effect_4;
    case 'effect_6':
      return effect_6;
    case 'effect_7':
      return effect_7;
    case 'effect_8':
      return effect_8;
    default:
      return effect_13;
  }
};
const StampLayout = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 5px;
  top: 0;
  left: 0;
  /* @media screen and (min-width: 576px) and (min-height: 450px) {
    position: absolute;
    width: 450px;
    height: 250px;
    inset: 0;
    margin: auto;
  } */
`;
const getRandom = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

const AutoExpireStampsView = ({ dataStamps, liveKey, host, isDevice, setDataStamps }) => {
  useLayoutEffect(() => {
    setDataStamps([]);
  }, []);
  return (
    <StampLayout className='stamps-display'>
      {dataStamps?.map((item, index) => {
        return (
          <AutoExpireStamps
            key={index}
            liveKey={liveKey}
            stampKey={item?.key}
            handleActionStamps={handleActionStamps}>
            <img
              key={index}
              style={{
                zIndex: 500,
                width: `${returnSizeStamps({
                  isDevice: isMobile,
                })}px`,
                height: `${returnSizeStamps({
                  isDevice: isMobile,
                })}px`,
                position: 'absolute',
                left: `${getRandom(0, 40)}%`,
                top: `${getRandom(0, 40)}%`,
              }}
              src={showStampsById(item?.stampGiff)}
              width={returnSizeStamps({
                isDevice: isMobile,
              })}
              height={returnSizeStamps({
                isDevice: isMobile,
              })}
              alt={item?.key}
            />
          </AutoExpireStamps>
        );
      })}
    </StampLayout>
  );
};

export { AutoExpireStampsView };
