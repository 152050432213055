import React from 'react';
import styled from 'styled-components';
import { Image2Icon, Video2Icon } from 'images';
const StyledBookmark = {
  Wrapper: styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    opacity: 1;
    z-index: 10;
    display: flex;
    align-items: center;
    &.customPosition {
      left: 10.5px;
      top: 10.5px;
    }
    svg {
      min-height: 23px;
      max-height: 23px;
      min-width: 23px;
      max-width: 23px;
      color: #ddd;
    }
  `,
};
const IconTypePost = ({ className, type, ...props }) => {
  
  return (
    <>
      {(type === 'image' || type === 'video') && (
        <StyledBookmark.Wrapper className={`${className}`} {...props}>
          {type === 'image' && <Image2Icon className='icon' />}
          {type === 'video' && <Video2Icon className='icon' />}
        </StyledBookmark.Wrapper>
      )}
    </>
  );
};

export default IconTypePost;