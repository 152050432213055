import { Edit2Icon } from 'images';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ImgCustom } from '..';
import './AvartaInfor.scss';
import { useState } from 'react';
import PopupConfirmDelete from 'components/PopupConfirmDelete';
import { AVATAR_DEFAULT } from 'utils';
const AvatarInfoCreator = ({
  images,
  name = '',
  content1 = '',
  content2 = '',
  className = '',
  type = '',
  time = '',
  link = '',
  handleUnblockFan,
  id,
  handleOpenFanDetail,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isUnblock, setIsUnblock] = useState(false);
  const [isVisibleDelete, setIsVisibleDelete] = useState(false);

  return (
    <>
      {isVisibleDelete && (
        <PopupConfirmDelete
          title={t('Popup.confirmUnBlockMessageUser', {
            name: name,
          })}
          setShowPopup={setIsVisibleDelete}
          handleOnSubmit={() => {
            setIsVisibleDelete(false);
            setIsUnblock(true);
            handleUnblockFan(id);
          }}
        />
      )}
      <div
        id={id}
        className={`avatar-infor avatar-infor-creator ${className} ${
          isUnblock ? 'faded' : ''
        }`}>
        <div
          className={`content-left ${type}`}
          onClick={() => {
            type === 'block' && handleOpenFanDetail(true);
          }}>
          <ImgCustom
            isPlaceholderAvatar
            src={images ? `${images}` : AVATAR_DEFAULT}
            alt='avatar'
            screen={
              images && className === 'avatar-infor-blocklist'
                ? 'recommend_top_56_56'
                : images && className !== 'avatar-infor-blocklist'
                ? 'avatar_44_44'
                : ''
            }
          />
          <div>
            <span className='name'>{name}</span>
            {content1 && (
              <span className='content1'>
                {+content1 === 2
                  ? `${t('Acount.AvatarInfoCreator.content2')}`
                  : +content1 === 3
                  ? `${t('Acount.AvatarInfoCreator.content3')}`
                  : ''}
              </span>
            )}
            <span className='content2'>{content2}</span>
          </div>
        </div>

        <div className={`content-right ${type}`}>
          {type === 'block' && (
            <>
              <div
                className='d-flex flex-column align-items-end'
                onClick={() => {
                  // setIsUnblock(true);
                  // handleUnblockFan(id);
                  setIsVisibleDelete(true);
                }}>
                <div className='time' style={{ whiteSpace: 'nowrap' }}>
                  {time}
                </div>
                <div className='btn bnt-block'>
                  {t('Acount.AvatarInfoCreator.buttonRelease')}
                </div>
              </div>
            </>
          )}

          {type === 'edit' && (
            <>
              <div onClick={() => navigate(link)} className='link' style={{ width: '100%' }}>
                <div className='d-middle'>
                  <span className='account--icon'>
                    <Edit2Icon />
                  </span>
                  <span className='account-text'>
                    {t('Acount.AvatarInfoCreator.buttonEdit')}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AvatarInfoCreator;
