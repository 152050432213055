import {
  AwardIcon,
  BookIcon,
  CopyrightIcon,
  DocumentIcon,
  ImageIcon,
  LogoutIcon,
  MessageQuestionIcon,
  NotificationIcon,
  PaperIcon,
  Dollar2Icon,
  IconHome,
  IconVideoCall,
  IconCalendar,
  LiveIcon,
  PurchasedHistory,
  QuestionnaireIcon,
  MessagesList,
  BookmarksList,
  SettingsSidebar,
  AnalysisIcon,
  SearchSidebar,
  IconRanking,
} from 'images';
import i18n from 'translate/i18n';
import { newPathUser } from './constant';
import { STATUS_ACCEPT } from 'utils';

const ArchiveTick = <BookmarksList />;
const Award = <AwardIcon />;
const Search = <SearchSidebar />;
const Book = <BookIcon />;
const Calendar = <IconCalendar />;
const Copyright = <CopyrightIcon />;
const Document = <DocumentIcon />;
const Home = <IconHome />;
const Image = <ImageIcon />;
const Logout = <LogoutIcon />;
const Message = <MessagesList />;
const MessageQuestion = <MessageQuestionIcon />;
const Notification = <NotificationIcon />;
const Paper = <PaperIcon />;
const Dollar2 = <Dollar2Icon />;
const Setting = <SettingsSidebar />;
const ShortVideo = <IconVideoCall />;
const PurchasedHistoryIcon = <PurchasedHistory />;
const Live = <LiveIcon />;
const Questionnaire = <QuestionnaireIcon />;
const Analysis = <AnalysisIcon />;
const Ranking = <IconRanking />;

export const ROUTER_MENU_FAN_OPTION = [
  {
    id: '/my-page',
    label: i18n.t('MenuSiderbar.home'),
    icon: Home,
    subMenu: ['/user/'],
  },
  {
    id: '/message',
    label: i18n.t('MenuSiderbar.msg'),
    icon: Message,
    subMenu: ['/message/'],
    callbackType: 'fan-message',
    //Messager F003
  },
  {
    label: i18n.t('MenuSiderbar.bookmark'),
    icon: ArchiveTick,
    id: '/favorite/list-post',
    subMenu: ['/favorite/'],
    //Bookmark F006
  },
  {
    label: i18n.t('MenuSiderbar.list'),
    icon: Document,
    id: '#',
    //List
    children: [
      {
        id: '/follow-follower',
        label: 'Follow/Follower list',
        //List Follow/Follower
      },
      {
        id: '/follow-list',
        label: i18n.t('MenuSiderbar.followList'),
        //List Follow F016
      },
      {
        id: '/follower-list',
        label: i18n.t('MenuSiderbar.followerList'),
        //List follower
      },
      {
        id: '/subscribe-list',
        label: i18n.t('MenuSiderbar.listSubscriber'),
        //List đã tham gia Subscription
      },
      {
        id: '/browse-history-list',
        label: i18n.t('MenuSiderbar.browsingHistory'),
        //List lịch sử view
      },
      {
        id: '/like-list',
        label: i18n.t('MenuSiderbar.likeList'),
        //List Like
      },
      {
        label: i18n.t('MenuSiderbar.liveStreamingList'),
        id: '#',
        //List phát sóng trực tiếp
        children: [
          {
            id: '/lives/list/live',
            label: i18n.t('MenuSiderbar.duringDelivery'),
            tab_navigate_list_live: 1,
            defaultActive: true,
            //Đang phát sóng F014
          },
          {
            id: '/lives/list/live',
            label: i18n.t('MenuSiderbar.deliverySchedule'),
            tab_navigate_list_live: 2,
            //Lịch phát sóng
          },
        ],
      },
      {
        label: i18n.t('MenuSiderbar.purchasedItem'),
        id: '/list-post-buyed',
        //Item đã muas
      },
    ],
  },

  {
    label: i18n.t('Common.pointPurchase'),
    icon: Award,
    id: `${newPathUser}purchase`,
    //Mua point F015
  },
  {
    id: '/notification',
    label: i18n.t('MenuSiderbar.news'),
    icon: Notification,
    //Thông báo F008
  },
  {
    label: i18n.t('MenuSiderbar.help'),
    icon: MessageQuestion,
    id: '/help',
    subMenu: ['/help'],
    //Help F009
  },
  {
    label: i18n.t('Common.calendar'),
    icon: Calendar,
    id: '/calendar',
    //Calendar
  },
  {
    label: i18n.t('Common.setting'),
    icon: Setting,
    id: '#',
    children: [
      {
        id: '/notification/setting',
        label: i18n.t('MenuSiderbar.notificationSettings'),
      },
      // {
      //   id: '/setting/language',
      //   label: i18n.t('MenuSiderbar.languageSetting'),
      // },
    ],
    subMenuExact: ['/notification/setting', '/language/setting'],
    subMenu: ['/notification/setting', '/language/setting'],
    //Setting thông báo F010
  },
  {
    label: i18n.t('Footer.FooterListIdol.term'),
    icon: Book,
    id: '/terms-of-use',
    //Điều khoản sử dụng F011
  },
  {
    label: i18n.t('Footer.FooterListIdol.law'),
    icon: Copyright,
    id: '/transactions-law',
    //Chính sách bảo mật F012
  },
  {
    label: i18n.t('Footer.FooterListIdol.policy'),
    icon: Paper,
    id: '/privacy-policy',
    //Luật giao dịch Thương mại đặc định F013
  },
  // AF005
  {
    id: '/affiliator/banners',
    label: i18n.t('MenuSiderbar.createBanner'),
    icon: Image,
    role: 'affiliator',
  },
  {
    id: '/affiliator/sales-management',
    label: i18n.t('MenuSiderbar.salesManagement'),
    icon: Dollar2,
    role: 'affiliator',
  },
  {
    id: '/logout',
    label: i18n.t('Common.logOut'),
    icon: Logout,
    callbackType: 'logout',
  },
];

export const ROUTER_MENU_CREATOR_OPTION = [
  // {
  //   id: `${newPathUser}`,
  //   label: 'MenuSiderbar.home',
  //   icon: Home,
  //   subMenu: [`${newPathUser}profile/subscription`, `${newPathUser}profile/premium-ticket`],
  //   subMenuExact: [`${newPathUser}profile`, `${newPathUser}profile/info`],
  // },

  {
    label: 'MenuSiderbar.homeSidebar',
    icon: Home,
    id: `${newPathUser}timeline`,
  },

  {
    label: 'MenuSiderbar.ranking',
    icon: Ranking,
    id: `${newPathUser}ranking`,
    statusAccept: STATUS_ACCEPT,
  },

  {
    label: 'Common.search',
    icon: Search,
    id: `${newPathUser}search`,
  },
  {
    label: 'MenuSiderbar.shortVideo',
    icon: ShortVideo,
    id: `${newPathUser}list-short-video`,
  },
  {
    label: 'MenuSiderbar.live',
    icon: Live,
    id: '#',
    isProdEnablePageLiveStream: true,
    //List phát sóng trực tiếp
    children: [
      {
        label: 'MenuSiderbar.creator.liveStreamingList',
        id: `${newPathUser}lives/list/live`,
        tab_navigate_list_live: 1,
        defaultActive: true,
        //Đang phát sóng F014
      },

      {
        label: 'Common.liveStreamingList',
        // icon: LiveStreamList,
        id: `${newPathUser}live/create`,
      },
      {
        label: 'MenuSiderbar.duringDelivery',
        // icon: LiveStreamList,
        id: `${newPathUser}live/list-livestream`,
      },
      {
        label: 'MenuSiderbar.creator.recording',
        id: `${newPathUser}profile/recording`,
      },
      // {
      //   id: `${newPathUser}lives/list/live`,
      //   label: 'MenuSiderbar.deliverySchedule',
      //   tab_navigate_list_live: 2,
      //   //Lịch phát sóng
      // },
    ],
  },
  {
    label: 'MenuSiderbar.purchasedItem',
    id: `${newPathUser}list-post-buyed`,
    icon: PurchasedHistoryIcon,
    //Item đã muas
  },
  // {
  //   label: 'MenuSiderbar.creator.list',
  //   icon: List,
  //   id: '#',
  //   children: [
  //     // {
  //     //   label: 'MenuSiderbar.creator.notification',
  //     //   id: `${newPathUser}profile/notification`,
  //     // },

  //     {
  //       label: 'MenuSiderbar.likeList',
  //       id: `${newPathUser}like-list`,
  //       //List Like
  //     },
  //   ],
  // },
  {
    label: 'MenuSiderbar.creator.message',
    icon: Message,
    id: `${newPathUser}message`,
  },

  {
    label: 'MenuSiderbar.creator.surveyList',
    icon: Questionnaire,
    id: `${newPathUser}surveys`,
  },

  {
    label: 'Common.calendar',
    icon: Calendar,
    id: `${newPathUser}calendar`,
  },

  {
    label: 'MenuSiderbar.Analysis',
    icon: Analysis,
    id: '#',
    children: [
      {
        label: 'MenuSiderbar.listSubscriber',
        id: `${newPathUser}subscriber`,
      },
      {
        label: 'MenuSiderbar.buyerListForEachContent',
        id: `${newPathUser}buyer`,
      },

      {
        label: 'MenuSiderbar.creator.rankingPayment',
        id: `${newPathUser}ranking-buyer`,
      },

      {
        label: 'MenuSiderbar.creator.report',
        // icon: Chart,
        id: `${newPathUser}report`,
      },

      {
        label: 'MenuSiderbar.creator.sales',
        // icon: ConfirmSale,
        id: `${newPathUser}sale-confirmation`,
      },
    ],
  },

  {
    label: 'MenuSiderbar.bookmark',
    icon: ArchiveTick,
    id: `${newPathUser}favorite/list-post`,
  },

  // {
  //   label: 'Common.pointPurchase',
  //   icon: PointPurchase,
  //   id: `${newPathUser}purchase`,
  // },

  {
    label: 'Common.setting',
    icon: Setting,
    id: '#',
    children: [
      {
        label: 'MenuSiderbar.notificationSettings',
        id: `${newPathUser}notification/setting`,
      },
      {
        label: 'MenuSiderbar.passwordSetting',
        id: `${newPathUser}change-password`,
      },
      // {
      //   label: 'MenuSiderbar.cardManagement',
      //   id: `${newPathUser}card-management`,
      //   statusAccept: STATUS_ACCEPT,
      //   isProdPageEnable: true,
      // },
      {
        label: 'MenuSiderbar.help',
        // icon: MessageQuestion,
        id: `${newPathUser}help`,
      },

      {
        label: 'MenuSiderbar.creator.block',
        id: `${newPathUser}block`,
      },

      {
        label: 'MenuSiderbar.withDraw',
        id: `${newPathUser}setting/with-draw`,
      },

      // {
      //   label: 'User.title',
      //   // icon: ConfirmSale,
      //   id: `${newPathUser}profile-settings`,
      // },
      {
        id: 'logout',
        label: 'Common.logOut',
        // icon: Logout,
        callbackType: 'logout',
      },
    ],
    //Setting thông báo F010
  },
];

export const ROUTER_MENU_CREATOR_OPTION_NOT_LOGIN = [
  // {
  //   id: `${newPathUser}`,
  //   label: 'MenuSiderbar.home',
  //   icon: Home,
  //   subMenu: [`${newPathUser}profile/subscription`, `${newPathUser}profile/premium-ticket`],
  //   subMenuExact: [`${newPathUser}profile`, `${newPathUser}profile/info`],
  // },
  {
    label: 'MenuSiderbar.ranking',
    icon: Ranking,
    id: `${newPathUser}ranking`,
    isWatchWhenNotLogin: true
  },

  {
    label: 'Common.search',
    icon: Search,
    id: `${newPathUser}search`,
    isWatchWhenNotLogin: true
  },
  {
    label: 'MenuSiderbar.shortVideo',
    icon: ShortVideo,
    id: `${newPathUser}list-short-video`,
    isWatchWhenNotLogin: true
  },
  {
    label: 'MenuSiderbar.live',
    icon: Live,
    id: '#',
    isProdEnablePageLiveStream: true,
    //List phát sóng trực tiếp
    children: [
      {
        label: 'MenuSiderbar.creator.liveStreamingList',
        id: `${newPathUser}lives/list/live`,
        tab_navigate_list_live: 1,
        defaultActive: true,
        //Đang phát sóng F014
      },

      {
        label: 'Common.liveStreamingList',
        // icon: LiveStreamList,
        id: `${newPathUser}live/create`,
      },
      {
        label: 'MenuSiderbar.duringDelivery',
        // icon: LiveStreamList,
        id: `${newPathUser}live/list-livestream`,
      },
      {
        label: 'MenuSiderbar.creator.recording',
        id: `${newPathUser}profile/recording`,
      },
      // {
      //   id: `${newPathUser}lives/list/live`,
      //   label: 'MenuSiderbar.deliverySchedule',
      //   tab_navigate_list_live: 2,
      //   //Lịch phát sóng
      // },
    ],
  },
  {
    label: 'MenuSiderbar.purchasedItem',
    id: `${newPathUser}list-post-buyed`,
    icon: PurchasedHistoryIcon,
    //Item đã muas
  },
  // {
  //   label: 'MenuSiderbar.creator.list',
  //   icon: List,
  //   id: '#',
  //   children: [
  //     // {
  //     //   label: 'MenuSiderbar.creator.notification',
  //     //   id: `${newPathUser}profile/notification`,
  //     // },

  //     {
  //       label: 'MenuSiderbar.likeList',
  //       id: `${newPathUser}like-list`,
  //       //List Like
  //     },
  //   ],
  // },
  {
    label: 'MenuSiderbar.creator.message',
    icon: Message,
    id: `${newPathUser}message`,
  },

  {
    label: 'MenuSiderbar.creator.surveyList',
    icon: Questionnaire,
    id: `${newPathUser}surveys`,
  },

  {
    label: 'Common.calendar',
    icon: Calendar,
    id: `${newPathUser}calendar`,
  },

  {
    label: 'MenuSiderbar.Analysis',
    icon: Analysis,
    id: '#',
    children: [
      {
        label: 'MenuSiderbar.listSubscriber',
        id: `${newPathUser}subscriber`,
      },
      {
        label: 'MenuSiderbar.buyerListForEachContent',
        id: `${newPathUser}buyer`,
      },

      {
        label: 'MenuSiderbar.creator.rankingPayment',
        id: `${newPathUser}ranking-buyer`,
      },

      {
        label: 'MenuSiderbar.creator.report',
        // icon: Chart,
        id: `${newPathUser}report`,
      },

      {
        label: 'MenuSiderbar.creator.sales',
        // icon: ConfirmSale,
        id: `${newPathUser}sale-confirmation`,
      },
    ],
  },

  {
    label: 'MenuSiderbar.bookmark',
    icon: ArchiveTick,
    id: `${newPathUser}favorite/list-post`,
  },

  // {
  //   label: 'Common.pointPurchase',
  //   icon: PointPurchase,
  //   id: `${newPathUser}purchase`,
  // },

  {
    label: 'Common.setting',
    icon: Setting,
    id: '#',
    children: [
      {
        label: 'MenuSiderbar.notificationSettings',
        id: `${newPathUser}notification/setting`,
      },
      {
        label: 'MenuSiderbar.passwordSetting',
        id: `${newPathUser}change-password`,
      },
      {
        label: 'MenuSiderbar.help',
        // icon: MessageQuestion,
        id: `${newPathUser}help`,
      },

      {
        label: 'MenuSiderbar.creator.block',
        id: `${newPathUser}block`,
      },

      {
        label: 'MenuSiderbar.withDraw',
        id: `${newPathUser}setting/with-draw`,
      },

      // {
      //   label: 'User.title',
      //   // icon: ConfirmSale,
      //   id: `${newPathUser}profile-settings`,
      // },
      {
        id: 'logout',
        label: 'Common.logOut',
        // icon: Logout,
        callbackType: 'logout',
      },
    ],
    //Setting thông báo F010
  },
];

export const ROUTER_MENU_CREATOR_NOT_APPROVED_OPTION = [
  // {
  //   id: `${newPathUser}`,
  //   label: 'MenuSiderbar.home',
  //   icon: Home,
  //   subMenu: [`${newPathUser}profile/subscription`, `${newPathUser}profile/premium-ticket`],
  //   subMenuExact: [`${newPathUser}profile`, `${newPathUser}profile/info`],
  // },

  {
    label: 'MenuSiderbar.homeSidebar',
    icon: Home,
    id: `${newPathUser}timeline`,
  },

  {
    label: 'MenuSiderbar.ranking',
    icon: Ranking,
    id: `${newPathUser}ranking`,
    statusAccept: STATUS_ACCEPT,
  },

  {
    label: 'Common.search',
    icon: Search,
    id: `${newPathUser}search`,
  },

  {
    label: 'MenuSiderbar.shortVideo',
    icon: ShortVideo,
    id: `${newPathUser}list-short-video`,
  },

  // {
  //   label: 'MenuSiderbar.creator.subscribed',
  //   id: `${newPathUser}subscriber`,
  // },
  // {
  //   label: 'Common.buyerListForEachContent',
  //   id: `${newPathUser}buyer`,
  // },
  {
    label: 'MenuSiderbar.live',
    id: '#',
    icon: Live,
    isProdEnablePageLiveStream: true,
    //List phát sóng trực tiếp
    children: [
      {
        label: 'MenuSiderbar.liveStreamingList',
        id: `${newPathUser}lives/list/live`,
        tab_navigate_list_live: 1,
        defaultActive: true,
        //Đang phát sóng F014
      },
      // {
      //   id: `${newPathUser}lives/list/live`,
      //   label: 'MenuSiderbar.deliverySchedule',
      //   tab_navigate_list_live: 2,
      //   //Lịch phát sóng
      // },
    ],
  },

  {
    label: 'MenuSiderbar.purchasedItem',
    id: `${newPathUser}list-post-buyed`,
    icon: PurchasedHistoryIcon,
    //Item đã muas
  },
  // {
  //   label: 'MenuSiderbar.creator.rankingPayment',
  //   id: `${newPathUser}ranking-buyer`,
  // },
  // {
  //   label: 'MenuSiderbar.creator.notification',
  //   id: `${newPathUser}profile/notification`,
  // },
  // {
  //   label: 'MenuSiderbar.creator.recording',
  //   id: `${newPathUser}profile/recording`,
  // },
  // {
  //   label: 'MenuSiderbar.likeList',
  //   id: `${newPathUser}like-list`,
  //   //List Like
  // },

  // {
  //   label: 'MenuSiderbar.browsingHistory',
  //   id: `${newPathUser}browse-history-list`,
  //   //List lịch sử view
  // },

  //NOT APPROVED

  // {
  //   label: 'MenuSiderbar.creator.liveStreamingList',
  //   icon: LiveStreamList,
  //   id: `${newPathUser}live/list-livestream`,
  // },
  // {
  //   label: 'MenuSiderbar.creator.sales',
  //   icon: ConfirmSale,
  //   id: `${newPathUser}sale-confirmation`,
  // },
  // {
  //   label: 'Common.pointPurchase',
  //   icon: PointPurchase,
  //   id: `${newPathUser}purchase`,
  // },
  // {
  //   label: 'MenuSiderbar.creator.report',
  //   icon: Chart,
  //   id: `${newPathUser}report-function`,
  // },
  {
    label: 'MenuSiderbar.creator.message',
    icon: Message,
    id: `${newPathUser}message`,
  },

  {
    label: 'MenuSiderbar.creator.surveyList',
    icon: Questionnaire,
    id: `${newPathUser}surveys`,
  },

  {
    label: 'Common.calendar',
    icon: Calendar,
    id: `${newPathUser}calendar`,
  },
  {
    label: 'MenuSiderbar.bookmark',
    icon: ArchiveTick,
    id: `${newPathUser}favorite/list-post`,
  },
  {
    label: 'Common.setting',
    icon: Setting,
    id: '#',
    children: [
      {
        label: 'MenuSiderbar.notificationSettings',
        id: `${newPathUser}notification/setting`,
      },
      {
        label: 'MenuSiderbar.passwordSetting',
        id: `${newPathUser}change-password`,
      },
      // {
      //   label: 'MenuSiderbar.cardManagement',
      //   id: `${newPathUser}card-management`,
      //   statusAccept: STATUS_ACCEPT,
      //   isProdPageEnable: true,
      // },
      {
        label: 'MenuSiderbar.help',
        // icon: MessageQuestion,
        id: `${newPathUser}help`,
      },
      {
        label: 'MenuSiderbar.creator.block',
        id: `${newPathUser}block`,
      },
      {
        label: 'MenuSiderbar.withDraw',
        id: `${newPathUser}setting/with-draw`,
      },
      {
        label: 'User.title',
        // icon: ConfirmSale,
        id: `${newPathUser}profile-settings`,
      },
      {
        id: 'logout',
        label: 'Common.logOut',
        // icon: Logout,
        callbackType: 'logout',
      },
    ],
  },
];

export const ROUTER_MENU_ADMIN_OPTION = [
  {
    id: `${newPathUser}`,
    label: 'ホーム',
    icon: Home,
  },
  {
    id: 'logout',
    label: i18n.t('Common.logOut'),
    icon: Logout,
    callbackType: 'logout',
  },
];
