import useLockBodyScroll from 'hooks/useLockBodyScroll';
import { LoadingSystem } from '../Loading/LoadingSystem';
import LoadingIconV2 from 'images/LoadingIconV2';

const PopupLoading = ({ className, ...props }) => {
  useLockBodyScroll(true);

  return (
    <div className={`${className} layer-spinner`}>
      <LoadingIconV2 className='spinner-icon' />
    </div>
  );
};

export default PopupLoading;
