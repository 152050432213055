import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatCoin, isAdmin, isAgency, isProdEnable } from 'utils/utils';
import { PopupConfirmFan, PopupGeneral, PopupLoading } from '..';
import { getFanTicketsCreatorTotal, postBuyArticle } from '../../store/actions/sellPost';
import { InputWrapper } from '../Input/InputWrapper';
import { newPathUser } from 'constants/layout/constant';
import './index.scss';
import LoadingIconV2 from 'images/LoadingIconV2';
import { logUserProfile } from 'store/actions/myPage';
import { STORAGE } from 'utils';
import Storage from 'utils/storage';
import { pushNotify } from 'components/Notification';

const PopupUserBuyPostF021 = ({ data_post = {}, data_profile, sellBy }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.myPage);
  const { status } = useSelector((state) => state.users.dataUser);
  const location = useLocation();
  const { loadingGetFanTicketCreator, loadingBuyPost } = useSelector(
    (state) => state.sellPost
  );
  const { t } = useTranslation();
  const { register, handleSubmit, watch, setValue } = useForm({
    mode: 'all',
    defaultValues: { type_sell: '1' },
  });
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleConfirm, setIsVisibleConfirm] = useState(false);
  const watchTypeSell = watch('type_sell', '1');
  const [numberTicket, setNumberTicket] = useState(null);
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);

  const isDisabledButton =
    watchTypeSell === '1'
      ? !data_profile?.points && data_profile?.points !== 0
        ? true
        : data_profile?.points < data_post?.price
        ? true
        : false
      : numberTicket === 0 ||
        numberTicket < data_post?.number_premium_ticket ||
        data_post?.number_premium_ticket === 0
      ? true
      : false;

  const onSubmit = () => {
    setIsVisibleConfirm(true);
  };
  const handleVisiblePopup = () => {
    if (!isAuth) {
      dispatch(logUserProfile());
    } else {
      dispatch(
        getFanTicketsCreatorTotal(data_post?.user_id, ({ data }) => {
          setNumberTicket(data?.tickets_total);
          if (data_post?.price) {
            setValue('type_sell', '1');
          }
          setIsVisible(true);
        })
      );
    }
  };
console.log('data_post?.price ',data_post?.price )
  useEffect(() => {
    if (data_post?.price === null) {
      setValue('type_sell', '2');
    }
  }, [data_post]);

  useEffect(() => {
    sellBy(watchTypeSell);
  }, [sellBy, watchTypeSell]);

  return (
    <>
      {(loading || loadingGetFanTicketCreator || loadingBuyPost) && (
        <div className='layer-spinner'>
          <LoadingIconV2 className='spinner-icon' />
        </div>
      )}
      <button
        className='btn bold btn-danger hover-blue'
        style={{ background: '#28c76f', border: 'none', padding: '8px 24px' }}
        onClick={handleVisiblePopup}
        disabled={isAdmin || isAgency}>
        {t('Popup.buy')}
      </button>
      {isVisibleConfirm && (
        <PopupConfirmFan
          isVisiblePopup={false}
          iconNote={true}
          colorSvg='#FF9F43'
          text={
            watchTypeSell === '1'
              ? t('Popup.purchasePoint', {
                  numberPart: data_post?.number_part || data_post?.code,
                  price: data_post?.price_discount
                    ? data_post?.price_discount
                    : data_post?.price,
                })
              : t('Popup.productNumberContent', {
                  code: data_post?.number_part || data_post?.code,
                  ticket: data_post?.number_premium_ticket,
                })
          }
          className='popup-confirm-buy-post-premium-ticket'
          style={{ minHeight: '267px' }}>
          <div
            className='ok btn'
            onClick={() => {
              setIsVisibleConfirm(false);
              dispatch(
                postBuyArticle(
                  data_post?.id,
                  watchTypeSell === '1' ? 0 : 1,
                  data_post?.price_discount ? data_post?.price_discount : data_post?.price,
                  () => {
                    setIsVisible(false);
                  }
                )
              );
            }}>
            {t('Common.Ok')}
          </div>
          <div className='btn cancel' onClick={() => setIsVisibleConfirm(false)}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisible && (
        <PopupGeneral
          className={`popup-user-selll-post`}
          generalClass='popup-user-selll-post-view'
          title=''>
          <form onSubmit={(e) => e.preventDefault()} className='fansite-form popup-price-sale'>
            {data_post?.price !== null && (
              <InputWrapper id='fsite-type-sell'>
                <div className='box-radio-checkbox d-flex justify-content-between'>
                  <label className='item'>
                    <input {...register('type_sell')} type='radio' value='1' defaultChecked />
                    <span className='checkmark radio'></span>
                    <span className='text-title-point'>{t('Popup.buyWithPoints')}</span>
                  </label>
                  <button
                    className='button btn btn-sell-point'
                    onClick={() => {
                      window.localStorage.setItem('idPost', data_post?.id)
                      navigate(`${newPathUser}purchase`, {
                        state: {
                          idPost: data_post?.id,
                          pathname: location.state?.pathname
                        },
                      });
                    }}>
                    {t('Common.pointPurchase')}
                  </button>
                </div>
                <div className='info_sell_wrapper'>
                  <div className='info_sell'>
                    <div className='text-info-price'>
                      {t('Common.possessionPoints')}:{' '}
                      <b>
                        {formatCoin(data_profile?.points) || 0}
                        {t('Common.pt')}
                      </b>
                    </div>
                    <div className='text-info-price d-flex'>
                      {t('Popup.sellingPrice')}{' '}
                      <b style={{ color: '#DC143C', marginLeft: '3px' }}>
                        {data_post?.price_discount
                          ? formatCoin(data_post?.price_discount)
                          : formatCoin(data_post?.price) || 0}
                        {t('Common.pt')}
                      </b>
                      {data_post?.price_discount && (
                        <p
                          className='point-price'
                          style={{
                            textDecorationLine: 'line-through',
                            marginLeft: '8px',
                            marginBottom: '0',
                          }}>
                          {formatCoin(data_post?.price)}Pt
                        </p>
                      )}
                    </div>
                    {data_post?.price_discount && (
                      <div className='text-info-price'>
                        {t('PostDetail.discount')}:{' '}
                        <b>
                          {formatCoin(data_post?.discount) || 0}
                          {t('Common.percent')}
                        </b>
                      </div>
                    )}
                  </div>
                </div>
              </InputWrapper>
            )}
            {data_post?.number_premium_ticket && (
              <InputWrapper id='fsite-type-sell'>
                <div className='box-radio-checkbox'>
                  <label className='item'>
                    {/* {data_post?.price && (
                      <input
                        type='radio'
                        {...register('type_sell')}
                        value='2'
                        disabled={!data_post?.number_premium_ticket ? true : false}
                      />
                    )} */}
                    <input
                      type='radio'
                      {...register('type_sell')}
                      value='2'
                      //checked={!data_post?.price}
                      disabled={!data_post?.number_premium_ticket ? true : false}
                    />
                    <span
                      className='checkmark radio'
                      style={{
                        color: `${!data_post?.number_premium_ticket && '#BEBEBE'}`,
                        border: `${!data_post?.number_premium_ticket && 'none'}`,
                        background: `${!data_post?.number_premium_ticket && '#bebebe4d'}`,
                        marginTop: '5px',
                      }}></span>
                    <span
                      className='text'
                      style={{ color: `${!data_post?.number_premium_ticket && '#BEBEBE'}` }}>
                      {t('Popup.premiumTicket')}
                    </span>
                  </label>
                </div>
                <div className='info_sell' style={{ marginBottom: '32px' }}>
                  <div className='text-info-price'>
                    {t('Popup.possessionPremiumTicket')}{' '}
                    <b>
                      {numberTicket || 0}
                      {t('Common.sheet')}
                    </b>
                  </div>
                  <div className='text-info-price'>
                    {t('Popup.premiumTicketRequired')}{' '}
                    <b>
                      {data_post?.number_premium_ticket || 0}
                      {t('Common.sheet')}
                    </b>
                  </div>
                </div>
              </InputWrapper>
            )}
            <div className='hyphen-price'></div>
            <div className='btn-submit-wrapper mt-4'>
              <InputWrapper
                id='fsite-submit'
                className={`button-submit btn-submit-price ${
                  isDisabledButton ? 'disabled' : ''
                }`}>
                <button
                  style={{ padding: '6px 30px' }}
                  onClick={handleSubmit(onSubmit)}
                  disabled={isDisabledButton}>
                  {watchTypeSell === '1' ? `${t('Popup.purchase')}` : `${t('Popup.exchange')}`}
                </button>
              </InputWrapper>
              <InputWrapper id='fsite-submit' className={`btn-cancel btn-cancel-price`}>
                <button onClick={() => setIsVisible(false)}>{t('Common.buttonCancel')}</button>
              </InputWrapper>
            </div>
          </form>
        </PopupGeneral>
      )}
    </>
  );
};

export default PopupUserBuyPostF021;
