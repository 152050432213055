import { Checkbox, PopupGeneral } from 'components';
import { ArrowRightIcon } from 'images';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
const PopupShowListPackage = ({ allPackage, listCheckedInit, handleSubmit }) => {
  const [isVisiblePopUp, setIsVisiblePopUp] = useState(false);
  const [listPackage, setListPackage] = useState();
  const [listChecked, setListChecked] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    allPackage?.length > 0 && setListPackage(allPackage);
    listCheckedInit?.length > 0 && setListChecked(listCheckedInit);
  }, [allPackage, listCheckedInit]);

  const handleOnchangePlanPost = (e, pack) => {
    const { checked } = e.target;
    if (checked) {
      setListChecked(
        listChecked.concat({
          tag_id: pack.id,
        })
      );
      const newData = listPackage.map((item) => {
        if (item.id === pack.id) {
          return { ...item, checked: true };
        } else return item;
      });
      setListPackage(newData);
    } else {
      const newData = listPackage.map((item) => {
        if (item.id === pack.id) {
          return { ...item, checked: false };
        } else return item;
      });
      setListPackage(newData);
      setListChecked(listChecked.filter((item) => item.tag_id !== pack.id));
    }
  };

  return (
    <>
      <div className='btn p-0 btn-show-more' onClick={() => setIsVisiblePopUp(true)}>
        <span>{t('Common.seeNext')}</span>
        <ArrowRightIcon />
      </div>
      {isVisiblePopUp && (
        <PopupGeneral
          className='popup-list-package'
          generalClass='pp-up-list-package-views'
          closeModal={(e) => setIsVisiblePopUp(e)}
          title={t('Popup.categoryFavorite')}>
          <div className='list-package-wrapper box-checkbox scroll-bar-custom'>
            {listPackage.map((item, index) => (
              <Checkbox
                key={index}
                text={item.tag}
                defaultChecked={item.checked}
                onClick={(e) => handleOnchangePlanPost(e, item)}
              />
            ))}
          </div>
          <div className='w-100 d-flex justify-content-end box-btn-submit'>
            <button
              className='btn btn-cancel rounded-6px'
              type='button'
              onClick={() => setIsVisiblePopUp(false)}>
              {t('Common.buttonCancel')}
            </button>
            <button
              className='btn btn-submit rounded-6px'
              type='button'
              onClick={() => {
                handleSubmit(listChecked, listPackage);
                setIsVisiblePopUp(false);
              }}>
              {t('Common.Ok')}
            </button>
          </div>
        </PopupGeneral>
      )}
    </>
  );
};

export default PopupShowListPackage;
