export default function EmptyHiddenCommentIconV2(props) {
  return (
    <svg
      width={100}
      height={100}
      viewBox='0 0 100 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M93.8327 29.1654C93.8327 39.5667 85.4007 47.9987 74.9994 47.9987C64.598 47.9987 56.166 39.5667 56.166 29.1654C56.166 18.764 64.598 10.332 74.9994 10.332C85.4007 10.332 93.8327 18.764 93.8327 29.1654Z'
        stroke='#FCECEF'
        strokeWidth={4}
      />
      <path
        d='M29.1673 58.3333H62.5006M29.1673 41.6667H45.834M91.5272 50.9025C91.62 51.9782 91.6673 53.0669 91.6673 54.1667C91.6673 74.8773 74.878 87.5 54.1673 87.5H25.0006C15.7959 87.5 8.33398 80.0381 8.33398 70.8333V50C8.33398 29.2893 20.9566 12.5 41.6673 12.5H50.0006C51.1004 12.5 52.1891 12.5473 53.2648 12.6401M66.6673 29.1667H83.334'
        stroke='#FCECEF'
        strokeWidth={4}
        strokeLinecap='round'
      />
    </svg>
  );
}
