import {
 configParamsPostFormData, ENV
} from 'utils/constant';
import { callApi } from 'utils/request';

const API = {
  postDurationVideo: () => `${ENV}/video/duration`,
};

const postDurationVideo = (file, handleSuccess, handleError) => {
  const formData = new FormData();
  formData.append('file', file);
  return () => {
    callApi(
      API.postDurationVideo(),
      configParamsPostFormData(formData),
      null,
      (response) => {
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleError && handleError(error.errors);
      }
    );
  };
};
export { postDurationVideo };
