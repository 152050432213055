import styled, { css, keyframes } from 'styled-components';
const animation = keyframes`
0% {
  width: 0;
}
100% {
  width: 100%;
}
`;
export const StyledTimeline = {
  Card: styled.div`
    overflow: hidden;
    width: 100%;
    height: 100%;
  `,
  Global: styled.div`
    position: relative;
    &.slideshow {
      margin: 0 auto;
      overflow: hidden;
      max-width: 100%;
      height: 100%;
      width: 100%;
    }
    .slideshowSlider {
      white-space: nowrap;
      transition: ease 1000ms;
      height: 100%;
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        aspect-ratio: 2/3;
      }
    }
    .slide {
      display: inline-block;
      height: 100%;
      width: 100%;
      position: relative;
    }
  `,
  Loading: styled.div`
    position: absolute;
    z-index: 50;
    height: 3px;
    top: 3px;
    width: calc(100% - 64px);
    right: 32px;
    box-shadow: inset 0px 0px 0px 1px #c8c8cd;
    overflow: hidden;
    background: rgba(40, 39, 39, 0.3);
    border-radius: 20px;
    .progress-bar {
      display: flex;
      height: 100%;
      width: 0;
      background: #ff0000;
      ${({ play }) =>
        play
          ? css`
              animation: ${animation} 15s linear 0s infinite normal;
            `
          : ''};
    }
  `,
  Count: styled.div`
    position: absolute;
    color: #fff;
    left: 10px;
    top: 20px;
    background: rgb(112 112 112 / 50%);
    border-radius: 4px;
    padding: 2px 12px;
    z-index: 10;
  `,
  Button: styled.button`
    cursor: pointer;
    position: absolute;
    z-index: 3;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    border: none;
    background: #ff0000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    // Position
    top: 50%;
    transform: translateY(-50%);
    ${({ right = false }) =>
      right
        ? css`
            right: 20px;
          `
        : css`
            left: 20px;
          `}
  `,
  ButtonPlay: styled.button`
    cursor: pointer;
    position: absolute;
    z-index: 3;
    width: 89px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    width: 89px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    background: rgba(28, 27, 27, 0.5);
    display: block;
    color: white;
    ${({ isShow = false }) =>
      isShow
        ? css`
            display: block;
          `
        : css`
            display: none;
          `};
  `,
  LineDot: styled.div`
    position: absolute;
    z-index: 5;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 16px;
    display: flex;
    gap: 7px;
    .dot_i {
      cursor: pointer;
      width: 6px;
      height: 2px;
      background-color: #b1b6bb;
      border-radius: 10px;
      &.active {
        background-color: #ff0000;
      }
    }
  `,
};
