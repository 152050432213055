import useLockBodyScroll from 'hooks/useLockBodyScroll';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import HeaderListIdolV2  from 'components/HeaderV2/HeaderListIdolV2';
import SuccessRegisterMailIcon from 'images/SuccessRegisterMailIcon';
import SuccessRegisterIcon from 'images/SuccessRegisterIcon';

const PopupVerifyEmail = ({ children, tokenExist, urlExpired }) => {
  const { t } = useTranslation();
  useLockBodyScroll();
  return (
    <>
      <HeaderListIdolV2/>
      <div className='popup register-success-fan verify-email' style={{ backgroundColor: "transparent"}}>
        <div className='popup-content'>
          {tokenExist ? (
            <>
              <div className='content'>
              <SuccessRegisterIcon /> 
                <div className='text success-register-text text-center'>
                  {t('Popup.accountHasActived')}
                </div>
                <div className={`box-button`}>
                  <Link className='btn btn-outline-danger-v2' to={'/sign-in'}>
                    {t('Common.goToLogin')}
                  </Link>
                </div>
              </div>
            </>
          ) : urlExpired && (
            <>
              <div className='content'>
                <SuccessRegisterMailIcon /> 
                <div className='text success-register-text text-center'>
                  {t('Popup.URLValidate')}
                </div>
                <div className={`box-button`}>
                  <Link className='btn btn-outline-danger-v2' to={'/sign-up'}>
                    {t('Popup.newRegistrationPage')}
                  </Link>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PopupVerifyEmail;
