import moment from 'moment';
import { useState } from 'react';
import { getAge } from 'utils/utils';

const initialState = {
  format: 'YYYY/MM/DD',
};

const useCalendar = ({ format } = { ...initialState }) => {
  const [date, setDate] = useState();
  const [isVisibleCalendar, setIsVisibleCalendar] = useState(false);
  const [currentAge, setCurrentAge] = useState();
  const toggleCalendar = (value) => setIsVisibleCalendar(value || !isVisibleCalendar);
  const [count, setCount] = useState(0);

  const onChangeDate = (e) => {
    setCount(count + 1);
    if (!e) {
      setDate();
    } else {
      setDate(moment(e).format(format));
      setCurrentAge(getAge(e));
      toggleCalendar();
    }
  };

  const onValueDate = (e) => {
    if (!e) {
      setDate();
    } else {
      setDate(moment(e).format(format));
    }
  };

  return {
    date,
    currentAge,
    toggleCalendar,
    onChangeDate,
    onValueDate,
    isVisibleCalendar,
    setIsVisibleCalendar,
    setDate,
    checkOldDate: count,
  };
};

export default useCalendar;
