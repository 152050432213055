import React from 'react';
import { useNavigate } from 'react-router-dom';
import useUserRole from '../../hooks/useUserRole';
import './index.scss';
import { getEnv } from 'configs/env';
import { isAdmin, isAgency } from '../../utils/utils';
import { LOGO_NOT_FOUND, STORAGE } from '../../utils/constant';
import Storage from 'utils/storage';
import { useTranslation } from 'react-i18next';
import { newPathUser } from 'constants/layout/constant';
import { HeaderListIdolV2, ImgCustom } from 'components';
const PageNotFound = () => {
  const { isIdol } = useUserRole();
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const { t } = useTranslation();

  const navigate = useNavigate();
  return (
    <div className='block-div-signin'>
      <HeaderListIdolV2 />
      <div className='fansite-verify-age'>
        <div className='box-verify-age'>
          <div className='bg-white'>
            <div className='title'>{t('NotFound.title')}</div>
            <div className='header-popup'>
              <ImgCustom src={LOGO_NOT_FOUND} alt='logo' className='logo-cocofans' />
            </div>
            <div className='text display-linebreak content-notfound' style={{ padding: '0 16px 0 16px' }}>
              {t('NotFound.content')}
            </div>
            <div className='btn-wrapper'>
              <div
                className='btn btn-yes'
                onClick={() => {
                  isAuth
                    ? isAdmin
                      ? (window.location.href = `${getEnv(
                          'REACT_APP_ADMIN_URL'
                        )}/revenue-management`)
                      : isAgency
                      ? (window.location.href = `${getEnv(
                          'REACT_APP_AGENCY_URL'
                        )}/revenue-management`)
                      : navigate(`${isIdol ? `/` : '/my-page'}`)
                    : navigate(`/`);
                }}>
                {t('Common.return')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageNotFound;
