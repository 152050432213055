import Icon from '@ant-design/icons';

const PrevMonthIconNew = (props) => (
  <Icon
    component={() => (
      <svg
        width='22'
        height='40'
        viewBox='0 0 22 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.0607 16.0607L11 15L6 20L11 25L12.0607 23.9393L8.12132 20L12.0607 16.0607Z'
          fill='#1A1A1A'
          // fill-opacity='0.25'
        />
      </svg>
    )}
    {...props}
  />
);

export default PrevMonthIconNew;
