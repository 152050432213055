import { LayoutCreatorRight, PopupConfirmFan, PopupLoading } from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import PopupConfirm from 'components/Popup/confirmSuccess';
import { newPathUser } from 'constants/layout/constant';
import withCreatorRole from 'hocs/withCreatorRole';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import { ArrowLeftIcon } from 'images';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { creatorEditPremiumTicket, creatorGetTicketDetail } from 'store/actions/premiumTicket';
import { customStyles, selectTimeRegister, CustomOption } from 'utils';
import './index.scss';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import {
  clearIdScrollBackPackageSubAndData,
  updatePremiumPackageSubData,
} from 'store/actions/lives';

const PremiumTicketEdit = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const [focusTimeRegister, setFocusTimeRegister] = useState(false);
  const [valueConditionMonth, setValueConditionMonth] = useState();
  const [popupConfirm, setPopupConfirm] = useState(false);
  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const { t } = useTranslation();
  useLockBodyScroll();
  const { loading, dataDetail } = useSelector((state) => state.premiumTicket);
  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'all' });
  const watchTimeRegister = watch('condition_month', '');
  // const watchExpired = watch('expired_month', '');
  const isDisabledButton = !watchTimeRegister;

  useEffect(() => {
    dispatch(creatorGetTicketDetail(id, handleSetInitialData));
  }, []);

  const handleSetInitialData = ({ success, data }) => {
    if (success) {
      // setValue('expired_month', data?.expired_month);
      const monthSelected = selectTimeRegister.find((e) => e.value === data.condition_month);
      setValueConditionMonth(monthSelected);
      setValue('condition_month', monthSelected);
    }
  };

  const handleShowConfirm = ({ success }) => {
    if (success) {
      dispatch(clearIdScrollBackPackageSubAndData());
      setConfirmSuccess(true);
    }
  };

  const handleShowPopup = (e) => {
    setPopupConfirm(e);
  };

  const handleSuccess = () => {
    navigate(`${newPathUser}profile/subscription`);
  };

  //onSubmit
  const onSubmit = (data) => {
    const newData = {
      ...data,
      package_id: location?.state?.packageId,
      condition_month: +data.condition_month.value,
      // expired_month: data.expired_month,
      status: dataDetail?.status,
    };

    dispatch(creatorEditPremiumTicket(id, newData, handleShowConfirm));
  };

  const handleDistribution = () => {
    if (dataDetail?.status) {
      setPopupConfirm(true);
    } else {
      handleCancel();
    }
  };

  const handleCancelSuccess = (data) => {
    dispatch(updatePremiumPackageSubData(data));
    setPopupConfirm(false);
  };

  //onCancel
  const handleCancel = () => {
    const newData = {
      package_id: location?.state?.packageId,
      condition_month: +dataDetail?.condition_month,
      // expired_month: dataDetail?.expired_month,
      status: dataDetail?.status ? 0 : 1,
    };
    dispatch(creatorEditPremiumTicket(id, newData, handleCancelSuccess));
  };

  return (
    <>
      {popupConfirm && (
        <PopupConfirmFan
          isVisiblePopup={handleShowPopup}
          colorSvg='#FF9F43'
          text={t('Popup.stopTicket1')}
          text2={t('Popup.stopTicket2')}
          iconNote={true}>
          <div className='ok btn' onClick={() => handleCancel()}>
            {dataDetail?.status
              ? t('Creator.Home.discontinued')
              : t('Creator.Home.releaseSuspensionOfDistribution')}
          </div>
          <div className='btn cancel' onClick={() => setPopupConfirm(false)}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}
      {confirmSuccess && (
        <PopupConfirm title={t('Popup.updateTicketSuccess')} closeModal={handleSuccess} />
      )}
      <LayoutCreatorRight
        titlePage={t('Creator.Home.editPremiumTicket')}
        link={`${newPathUser}profile/subscription`}
        className='fansite-creator-premium-ticket-post-edit'>
        {loading ? (
          <div className='shimmer-loading'>
            <ShimmerPostDetails hasImage imageType='circular' title />
          </div>
        ) : (
          <>
            <div className='fansite-creator-premium-ticket-post'>
              <form onSubmit={(e) => e.preventDefault()} className='fansite-form'>
                <InputWrapper
                  id='fsite-condition'
                  className='fsite-condition'
                  label={t('Creator.Home.selectTargetSubscription')}>
                  <input value={location?.state?.packageName} disabled />
                </InputWrapper>

                <InputWrapper
                  id='fsite-timeRegister'
                  label={t('Creator.Home.subscriptionPeriodRequiredForAcquisition')}
                  error={errors?.condition_month ? errors?.condition_month?.message : ''}>
                  <div className='timeRegister'>
                    <Controller
                      name='condition_month'
                      control={control}
                      rules={{
                        required:
                          'Creator.Home.pleaseSpecifyTheSubscriptionPeriodRequiredForAcquisition',
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          isSearchable={false}
                          isClearable={false}
                          options={selectTimeRegister}
                          onFocus={() => setFocusTimeRegister(true)}
                          onBlur={() => setFocusTimeRegister(false)}
                          onClick={(e) => setFocusTimeRegister(false)}
                          className='select-subscription'
                          onChange={(val) => {
                            field.onChange(val);
                            setFocusTimeRegister(false);
                            setValueConditionMonth(val);
                          }}
                          styles={customStyles}
                          placeholder={t('Creator.Home.enterSubscriptionPeriod')}
                          value={valueConditionMonth}
                          components={{
                            Option: CustomOption,
                          }}
                        />
                      )}
                    />
                    <ArrowLeftIcon className={`${focusTimeRegister ? 'focus' : 'no-focus'}`} />
                  </div>
                </InputWrapper>
                <div className='box-button d-block d-sm-flex'>
                  <InputWrapper
                    id='fsite-submit'
                    className={`button button-submit ${isDisabledButton ? 'disabled' : ''}`}>
                    <button onClick={handleSubmit(onSubmit)} disabled={isDisabledButton}>
                      {t('Common.buttonEdit')}
                    </button>
                  </InputWrapper>

                  <InputWrapper id='fsite-submit' className={`button button-cancel`}>
                    <button onClick={() => handleDistribution()}>
                      {dataDetail?.status
                        ? t('Creator.Home.premiumTicketDistributionStop')
                        : t('Creator.Home.releaseSuspensionOfDistribution')}
                    </button>
                  </InputWrapper>
                </div>
              </form>
            </div>
          </>
        )}
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(PremiumTicketEdit);
