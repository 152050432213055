import { LayoutCreatorRight, PopupLoading } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { newPathUser } from 'constants/layout/constant';
import { Cron } from 'croner';
import { usePopupPaymentSub } from 'hooks/v2/usePopupPaymentSub';
import TopListIcon from 'images/TopListIcon';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { logUserProfile } from 'store/actions/myPage';
import {
  byPackageFromRankingSUCCESS,
  clearListRanking,
  followUserRanking,
  getDataRanking,
  setIdScrollBack,
} from 'store/actions/ranking';
import { isAuth } from 'utils';
import OptionSearchRanking from './OptionSearch';
import RankingItem from './RankingItem';
import './index.scss';
import { useGetIdFromRecommendUser } from 'hooks/v2/useGetIdFromRecommendUser';

const RankingPage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { listRanking, pagination, idScrollBackRanking } = useSelector(
    (state) => state.ranking
  );
  const { idFromRecommendUser, clearIdScrollBackRecommend } = useGetIdFromRecommendUser();
  const formatDate = 'YYYY-MM-DD: HH:mm';
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFetchNext, setIsLoadingFetchNext] = useState(false);
  const [isLoadingFollow, setIsLoadingFollow] = useState(false);
  const [timeUpdateData, setTimeUpdateData] = useState(null);
  const [page, setPage] = useState(
    pagination?.current_page ? +pagination?.current_page + 1 : 1
  );
  const [idPostBySuccess, setIdPostBySuccess] = useState(null);

  const intervalTypeRealTime = useRef(null);
  const { setIsOpen, setIdPackage, PopupPaymentBySub } = usePopupPaymentSub({
    urlRedirect: '',
    onClickSuccess: () => {
      if (idPostBySuccess) {
        handleByPackageSuccess(idPostBySuccess);
      }
    },
  });

  const type = searchParams.get('type');
  const option = searchParams.get('option');

  const LIMIT_PAGE = 5;
  const formatYearMonthDayJP = 'YYYY年MM月DD日';
  const formatMonthDayJP = 'MM月DD日';

  const setIdAndNavigate = (id, url) => {
    dispatch(
      setIdScrollBack(`ranking-${id}`, () => {
        navigate(url, {
          state: {
            idFromRanking: id,
          },
        });
      })
    );
  };

  const setDefaultData = () => {
    setPage(1);
    dispatch(clearListRanking());
  };

  const handleFollowSuccess = () => {
    setIsLoadingFollow(false);
  };

  const handleByPackageSuccess = (id) => {
    dispatch(byPackageFromRankingSUCCESS(id));
  };

  const handleFollowCreator = (item) => {
    if (isAuth) {
      setIsLoadingFollow(true);
      dispatch(
        followUserRanking(item?.user_id, handleFollowSuccess, () => setIsLoadingFollow(false))
      );
    } else {
      dispatch(logUserProfile(item));
    }
  };

  const getValueTime = useCallback(() => {
    if (timeUpdateData) {
      const time = moment(timeUpdateData, formatDate);
      const dayMonthPrev1Day = moment(timeUpdateData, formatDate)
        .subtract(1, 'days')
        .format('MMMM DD');
      const dayMonthPrev1DayJP = moment(timeUpdateData, formatDate)
        .subtract(1, 'days')
        .format(formatMonthDayJP);

      const monthNameDayYear = time.format('MMMM DD, YYYY');
      const yearMonthDayJP = time.format(formatYearMonthDayJP);
      const dayOfWeek = time.format('dddd').substring(0, 3).toLowerCase();
      const dayOfWeekUpFirst = time
        .format('dddd')
        .substring(0, 3)
        .toLowerCase()
        .replace(/^./, (str) => str.toUpperCase());
      const dayJP = `Common.${dayOfWeek}`;
      if (option === 'now') {
        if (i18n.language === 'en') {
          return `Updated at ${time.format(
            'HH:00'
          )} on ${dayOfWeekUpFirst},\n ${monthNameDayYear}`;
        } else {
          return `${yearMonthDayJP}(${t(dayJP)})${time.format('HH:00')}更新`;
        }
      } else if (option === 'day') {
        if (i18n.language === 'en') {
          return `Updated on ${dayOfWeekUpFirst}, ${monthNameDayYear} \n (Tally date: ${dayMonthPrev1Day})`;
        } else {
          return `${yearMonthDayJP}(${t(dayJP)})更新 \n (集計日：${dayMonthPrev1DayJP})`;
        }
      } else if (option === 'week') {
        const dayWeek = time.clone().startOf('week');
        const prevWeek = dayWeek.clone().weekday(-6).format('MMMM DD');
        const prevWeekJP = dayWeek.clone().weekday(-6).format(formatMonthDayJP);

        const dayWeekAdd1 = dayWeek.clone().add(1, 'days');
        const dayWeekAdd1UpFirst = dayWeekAdd1
          .format('dddd')
          .substring(0, 3)
          .toLowerCase()
          .replace(/^./, (str) => str.toUpperCase());
        const dayOfWeekAdd1 = dayWeekAdd1.format('dddd').substring(0, 3).toLowerCase();
        const dayJPAdd1 = `Common.${dayOfWeekAdd1}`;
        if (i18n.language === 'en') {
          return `Updated on ${dayWeekAdd1UpFirst}, ${dayWeekAdd1.format(
            'MMMM DD, YYYY'
          )} \n (Tally date: ${prevWeek} to ${dayWeek.format('MMMM DD')})`;
        } else {
          return `${dayWeekAdd1.format(formatYearMonthDayJP)}(${t(
            dayJPAdd1
          )})更新 \n (集計日：${prevWeekJP} ~ ${dayWeek.format(formatMonthDayJP)})`;
        }
      } else if (option === 'month') {
        const prevMonth = time.clone().subtract(1, 'months');
        const startMonth = prevMonth.clone().startOf('month');
        const endMonth = prevMonth.clone().endOf('month');

        const endMonthAdd1 = endMonth.clone().add(1, 'days');
        const dayOfWeekAdd1 = endMonthAdd1.format('dddd').substring(0, 3).toLowerCase();
        const dayJPAdd1 = `Common.${dayOfWeekAdd1}`;

        if (i18n.language === 'en') {
          return `Updated on ${dayOfWeekAdd1.replace(/^./, (str) =>
            str.toUpperCase()
          )}, ${endMonthAdd1.format('MMMM DD, YYYY')} \n (Tally date: ${startMonth.format(
            'MMMM DD'
          )} to ${endMonth.format('MMMM DD')})`;
        } else {
          return `${endMonthAdd1.format(formatYearMonthDayJP)}(${t(
            dayJPAdd1
          )})更新 \n (集計日：${startMonth.format(formatMonthDayJP)} ~ ${endMonth.format(
            formatMonthDayJP
          )})`;
        }
      }
    } else {
      return null;
    }
  }, [timeUpdateData, option, i18n.language, t]);

  const handleSuccessListRanking = (rs) => {
    setIsLoading(false);
    setIsLoadingFetchNext(false);
  };
  const getData = useCallback(() => {
    if (type && option && !idScrollBackRanking && !idFromRecommendUser) {
      setTimeUpdateData(moment().format(formatDate));
      const params = {
        type,
        option,
        page,
        hour: moment().utc().format('H'),
        limit: LIMIT_PAGE,
      };
      setIsLoading(true);
      dispatch(getDataRanking(params, handleSuccessListRanking, handleFailListRanking));
    }
  }, [type, option, idScrollBackRanking, page, dispatch, idFromRecommendUser]);

  useEffect(() => {
    if (idScrollBackRanking) {
      const idPost = document.getElementById(idScrollBackRanking);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    } else {
      const top = document.getElementById('app_cocofans_0');
      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
    }
  }, [idScrollBackRanking]);

  const handleFailListRanking = () => {
    setIsLoading(false);
    setIsLoadingFetchNext(false);
  };

  useEffect(() => {
    if (option === 'now') {
      if (intervalTypeRealTime.current) {
        intervalTypeRealTime.current?.stop();
      }
      intervalTypeRealTime.current = Cron('0 0 * * * *', () => {
        getData();
      });
    } else {
      intervalTypeRealTime.current?.stop();
    }
  }, [type, option, getData]);

  useEffect(() => {
    return () => {
      intervalTypeRealTime.current?.stop();
    };
  }, []);

  useEffect(() => {
    if (type && option) {
      getData();
    } else {
      navigate(`${newPathUser}ranking?type=1&option=now`, { replace: true });
    }
  }, [getData, navigate, option, type]);

  const fetchNext = () => {
    const pages = pagination?.total / pagination?.per_page;
    if (pages > page) {
      setIsLoadingFetchNext(true);
      if (idScrollBackRanking || idFromRecommendUser) {
        dispatch(setIdScrollBack());
        clearIdScrollBackRecommend();
      } else {
        setPage(page + 1);
      }
    } else if (pages + 1 > page) {
      if (idScrollBackRanking || idFromRecommendUser)  {
        setIsLoadingFetchNext(true);
        dispatch(setIdScrollBack());
        clearIdScrollBackRecommend();
      }
    }
  };

  return (
    <>
      <PopupPaymentBySub />
      {isLoadingFollow && <PopupLoading className={'popup-loading'} />}
      <LayoutCreatorRight
        noButtonBack
        rightContent={getValueTime()}
        titlePage={`${t('Common.ranking')}`}
        className='ranking-layout'>
        <div className='ranking-container'>
          <OptionSearchRanking
            isLoading={isLoading}
            type={type}
            option={option}
            timeUpdateData={getValueTime()}
            setDefaultData={setDefaultData}
          />
          {isLoading && !isLoadingFetchNext ? (
            <div className='shimmer-loading'>
              <ShimmerPostDetails hasImage imageType='circular' title />
            </div>
          ) : (
            <>
              {listRanking?.length > 0 ? (
                <div className='list-ranking'>
                  <InfiniteLoad
                    loading={isLoadingFetchNext}
                    data={listRanking || []}
                    fetchNext={fetchNext}>
                    {listRanking?.map((item) => (
                      <RankingItem
                        key={item?.id}
                        item={item}
                        setIsOpen={setIsOpen}
                        setIdPackage={setIdPackage}
                        setIdPostBySuccess={setIdPostBySuccess}
                        handleFollowCreator={handleFollowCreator}
                        handleByPackageSuccess={handleByPackageSuccess}
                        setIdAndNavigate={setIdAndNavigate}
                      />
                    ))}
                  </InfiniteLoad>
                </div>
              ) : (
                !isLoading && (
                  <div className='no-data'>
                    <div>
                      <TopListIcon />
                    </div>
                    <div className='text'>{t('SidebarRecommendPost.noDataListCreator')}</div>
                  </div>
                )
              )}
            </>
          )}
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default RankingPage;
