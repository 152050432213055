// import { InputWrapper } from 'components/Input/InputWrapper';
import { getEnv } from 'configs/env';
import { useEffect, useRef, useState } from 'react';
import { ArrowLeftIcon, CheckedIcon } from '../../images';
import { ArrowDownIconLive } from 'images';
import Storage from 'utils/storage';

import './index.scss';
import useOutsideClick from 'hooks/useOutsideClick';
import { useTranslation } from 'react-i18next';

const PopupC90SelectDonations = ({
  handleListSelectedUser,
  isShow,
  handleCloseCheckBox,
  listUserSelected,
  liveKey,
  setIsVisiblePopUpDonation,
}) => {
  const { t } = useTranslation();
  const [isVisiblePopUp, setIsVisiblePopUp] = useState(false);
  // const [checkCallApi, setCheckCallApi] = useState(false);

  const [dataSearch, setDataSearch] = useState([]);
  const [listSelected, setListSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(2);
  const limit = 15;
  const [msgDataNull, setMsgDataNull] = useState('');

  let refDiv = useRef(null);

  useEffect(() => {
    listUserSelected && setListSelected(listUserSelected);
  }, [listUserSelected]);

  // const scrollToBottom = () => {
  //   if (isVisiblePopUp) {
  //     const scroll = tableSetting.current.scrollHeight - tableSetting.current.clientHeight;
  //     tableSetting.current.scrollTo(0, scroll);
  //   }
  // };

  const handleSubmit = async (page) => {
    setLoading(true);

    if (refDiv) {
      refDiv.current.style.display = 'block';
    }

    // ${getEnv('REACT_APP_API_SERVER')}/fans?live_key=${liveKey}&page=${page}&limit=${limit}
    const res = await fetch(
      `${getEnv(
        'REACT_APP_API_SERVER'
      )}/livestreams/${liveKey}/top-users?page=${page}&limit=${limit}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
        },
      }
    );
    res
      .json()
      .then((data) => {
        let obj = {};
        if (data?.data?.length < limit) {
          setMaxPage(page);
        }
        if (data?.data?.length > 0) {
          listSelected.forEach((item) => {
            obj[item?.id] = true;
          });
          const newArr = data?.data?.map((item) => {
            return { ...item, id: item?.user_id, checked: obj[item?.user_id] };
          });
          setMaxPage((prev) => prev + 1);
          setDataSearch(dataSearch.concat(newArr));
        } else {
          setMaxPage(page);
        }

        setLoading(false);
        setMsgDataNull(t('Popup.noPayperList'));
      })
      .catch(() => {
        setLoading(false);
        if (refDiv) {
          refDiv.current.style.display = 'none';
        }
      });
  };

  useEffect(() => {
    if (isVisiblePopUp) {
      handleSubmit(1);
    } else {
      setDataSearch([]);
      if (refDiv) {
        refDiv.current.style.display = 'none';
      }
    }
    if (!isShow) {
      setDataSearch([]);
      if (refDiv) {
        refDiv.current.style.display = 'none';
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow, isVisiblePopUp, setListSelected, page]);

  const handleSelectUser = (item) => {
    const x = listSelected?.find((oldItem) => oldItem?.id === item?.id);
    const newListChecked = !x
      ? listSelected.concat(item)
      : listSelected.filter((y) => y.id !== item.id);
    listSelected > 0 &&
      setListSelected(
        !x ? listSelected.concat(item) : listSelected.filter((y) => y.id !== item.id)
      );
    handleListSelectedUser(newListChecked);
  };

  useEffect(() => {
    if (dataSearch?.length > 0) {
      const obj = {};
      listSelected.forEach((item) => {
        obj[item?.id] = true;
      });
      const newArr = dataSearch?.map((item) => {
        return { ...item, checked: obj[item?.id] };
      });
      setDataSearch(newArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listSelected]);

  useOutsideClick(refDiv, (e) => {
    if (isVisiblePopUp) {
      setIsVisiblePopUp(false);
      setIsVisiblePopUpDonation(false);
      if (!dataSearch.length) {
        handleCloseCheckBox(false);
      } else {
        handleCloseCheckBox(true);
      }
    }
  });

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (page + 1 <= maxPage) {
        setPage((prev) => prev + 1);
        handleSubmit(page + 1);
      }
    }
  };

  return (
    <>
      <div
        className={`btn-popup-select-user ${isShow ? '' : 'notAllow'}`}
        style={{
          width: 'auto',
          border: 'none',
          color: '#a9a9a9',
          transform: `${isVisiblePopUp ? 'rotate(180deg)' : 'rotate(0deg)'}`,
        }}>
        <ArrowLeftIcon
          onClick={() => {
            if (isShow) {
              if (isVisiblePopUp) {
                setIsVisiblePopUp(false);
                setIsVisiblePopUpDonation(false);
              } else {
                setIsVisiblePopUp(true);
                setPage(1);
                setMaxPage(2);
                setIsVisiblePopUpDonation(true);
              }
            }
          }}
        />
      </div>
      <div
        onScroll={(e) => {
          handleScroll(e);
        }}
        className='list-search-distributions scroll-bar-custom '
        style={{ display: 'none' }}
        ref={refDiv}>
        {loading && (
          <div
            className='spinner-border m-auto'
            role='status'
            style={{ width: '15px', height: '15px', color: '#ff0000' }}></div>
        )}
        {isVisiblePopUp && !loading && !dataSearch?.length && (
          <div className='no-data'>{msgDataNull}</div>
        )}
        {dataSearch?.map((item, index) => (
          <>
            <div
              key={item.id}
              className={`item-search ${item.id} ${item?.checked ? 'active-dvc' : ''}`}
              onClick={() => handleSelectUser(item)}>
              {item.account_name}
              <CheckedIcon
                key={index}
                className={`icon-checked ${item?.checked ? 'active' : 'hidden'}`}
              />
            </div>
          </>
        ))}
      </div>
    </>
  );
};

export default PopupC90SelectDonations;
