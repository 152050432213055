import Icon from '@ant-design/icons';

const TopListIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width='100'
        height='100'
        viewBox='0 0 100 100'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M89.5833 93.7526H72.9167C71.812 93.7513 70.7529 93.312 69.9718 92.5308C69.1907 91.7497 68.7513 90.6906 68.75 89.5859V56.2526C68.7513 55.1479 69.1907 54.0888 69.9718 53.3077C70.7529 52.5266 71.812 52.0872 72.9167 52.0859H89.5833C90.688 52.0872 91.7471 52.5266 92.5282 53.3077C93.3093 54.0888 93.7487 55.1479 93.75 56.2526V89.5859C93.7487 90.6906 93.3093 91.7497 92.5282 92.5308C91.7471 93.312 90.688 93.7513 89.5833 93.7526ZM72.9167 56.2526V89.5859H89.5833V56.2526H72.9167Z'
          fill='#FCECEF'
        />
        <path
          d='M58.3333 93.75H41.6667C40.562 93.7487 39.5029 93.3093 38.7218 92.5282C37.9407 91.7471 37.5013 90.688 37.5 89.5833V47.9167C37.5013 46.812 37.9407 45.7529 38.7218 44.9718C39.5029 44.1907 40.562 43.7513 41.6667 43.75H58.3333C59.438 43.7513 60.4971 44.1907 61.2782 44.9718C62.0593 45.7529 62.4987 46.812 62.5 47.9167V89.5833C62.4987 90.688 62.0593 91.7471 61.2782 92.5282C60.4971 93.3093 59.438 93.7487 58.3333 93.75ZM41.6667 47.9167V89.5833H58.3333V47.9167H41.6667Z'
          fill='#FCECEF'
        />
        <path
          d='M27.0833 93.7474H10.4167C9.31199 93.7461 8.25291 93.3067 7.47178 92.5256C6.69065 91.7445 6.25126 90.6854 6.25 89.5807V64.5807C6.25126 63.476 6.69065 62.417 7.47178 61.6358C8.25291 60.8547 9.31199 60.4153 10.4167 60.4141H27.0833C28.188 60.4153 29.2471 60.8547 30.0282 61.6358C30.8093 62.417 31.2487 63.476 31.25 64.5807V89.5807C31.2487 90.6854 30.8093 91.7445 30.0282 92.5256C29.2471 93.3067 28.188 93.7461 27.0833 93.7474ZM10.4167 64.5807V89.5807H27.0833V64.5807H10.4167Z'
          fill='#FCECEF'
        />
        <path
          d='M41.4022 39.5749C40.8398 39.5744 40.2853 39.4426 39.7829 39.1899C39.2805 38.9371 38.8441 38.5705 38.5086 38.1192C38.173 37.6679 37.9475 37.1445 37.8501 36.5906C37.7527 36.0367 37.7861 35.4677 37.9476 34.929L40.4846 26.4787L35.0098 22.0975C34.4265 21.6306 34.0027 20.9941 33.7967 20.276C33.5908 19.5578 33.613 18.7935 33.8602 18.0885C34.1075 17.3835 34.5675 16.7727 35.1769 16.3405C35.7862 15.9082 36.5148 15.6759 37.2618 15.6755H43.5017L46.6857 8.24859C46.9635 7.59973 47.4256 7.04671 48.0149 6.6581C48.6041 6.26949 49.2944 6.06239 50.0002 6.0625C50.706 6.06261 51.3963 6.26991 51.9854 6.65871C52.5745 7.0475 53.0365 7.60067 53.3141 8.24961L56.4981 15.6755H62.7379C63.4852 15.6758 64.2139 15.9082 64.8233 16.3406C65.4328 16.773 65.8928 17.3841 66.1399 18.0893C66.387 18.7946 66.4088 19.5591 66.2025 20.2773C65.9962 20.9955 65.5718 21.6319 64.9881 22.0985L59.5152 26.4788L62.0522 34.9301C62.2706 35.6573 62.2538 36.4348 62.0043 37.1519C61.7548 37.8691 61.2853 38.4891 60.6628 38.9237C60.0402 39.3584 59.2963 39.5854 58.5372 39.5724C57.778 39.5595 57.0423 39.3072 56.4349 38.8516L50 34.0268L43.5648 38.8526C42.9413 39.3215 42.1823 39.575 41.4022 39.5749ZM50 29.7248C50.7795 29.7233 51.5385 29.975 52.1627 30.442L57.5724 34.4967L55.4158 27.3129C55.2066 26.6234 55.2098 25.8869 55.425 25.1993C55.6402 24.5117 56.0574 23.9047 56.6223 23.4575L61.139 19.8422H56.1279C55.4219 19.8428 54.7313 19.6357 54.142 19.2469C53.5528 18.858 53.0908 18.3045 52.8137 17.6551L50 11.0918L47.1863 17.6561C46.9089 18.3052 46.4469 18.8585 45.8577 19.2472C45.2684 19.6359 44.5779 19.8428 43.872 19.8422H38.8611L43.3817 23.4606C43.9456 23.9081 44.3617 24.5149 44.5762 25.2021C44.7906 25.8893 44.7934 26.6251 44.5841 27.3139L42.4275 34.4967L47.8373 30.4409C48.4618 29.9747 49.2206 29.7234 50 29.7248Z'
          fill='#FCECEF'
        />
      </svg>
    )}
    {...props}
  />
);

export default TopListIcon;
