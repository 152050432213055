import styled from 'styled-components';

const WrapperFilter = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: ${({ m }) => m};
  align-items: center;
  width: ${({ width }) => width};
`;

const Span = styled.span`
  margin-right: 10px;
`;
export { WrapperFilter, Span };
