import { DBRealTime } from '../firebase/config';
import {
  //   numChildren,
  //   onValue,
  // onValue,
  // orderByKey,
  // limitToLast,
  // endAt,
  // once,
  // orderByChild,
  // query,
  // limitToFirst,
  // equalTo
  serverTimestamp,
  onDisconnect,
  set,
  get,
  ref,
  child,
  update,
  remove,
} from 'firebase/database';

export const insertData = (target = '', params = {}) => {
  return set(ref(DBRealTime, `${target}`), params);
};

export const realTimeLives = (target = '', liveKey = '', params = {}) => {
  const userStatusDatabaseRef = ref(DBRealTime, `${target}`);
  const data = {
    key: liveKey || 'null',
    user_id: params?.id || 'null',
    creator_follow: params.creator_follow, // 1 la user co follow creator , 0 la chua
    comment : params.comment, // 1 la cho phep comment,  0 la ko cho comment
    // email: params?.email || 'null',
    name: params?.account_name || 'null',
    avatar: params?.avatar || 'null',
    createdAt: serverTimestamp(),
  };

  // set(ref(DBRealTime, `${target}`), data);

  onDisconnect(userStatusDatabaseRef)
    .remove(userStatusDatabaseRef)
    .then(() => {
      set(userStatusDatabaseRef, data);
    });
};


export const getOne = (target = '') => {
  // get(child(DBRealTime, target))
  //   .then((snapShot) => {
  //     if (snapShot.exists()) {
  //       return snapShot.val();
  //     }
  //   })
  //   .catch((error) => {
  //     alert('unsuccessfull , error ', error);
  //   });

  const dbRef = ref(DBRealTime);
  return get(child(dbRef, target));

};

export const filterBy = () => { };

export const updateData = (target = '', params = {}) => {
  return update(ref(DBRealTime, `/${target}`), params);
};

export const deleteData = (target = '') => {
  return remove(ref(DBRealTime, target));
};
