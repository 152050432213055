// import { TYPES } from './index';
import Storage from '../../utils/storage';
import { callApi } from '../../utils/request';
import { TYPES } from './index';
import { LIVES_TYPES } from './livesTypes';
import lodash from 'lodash';
import { getEnv } from 'configs/env';
import { uploadS3ByPresigned, getPresignedUrlS3 } from 'utils/upload';
import { pushNotify } from 'components/Notification';
import { updateData } from 'hooks/useRealTimeDB';
import i18n from 'translate/i18n';
import { queryString } from 'utils';
const updateStatus = (status, data) => ({
  type: status,
  payload: data,
});

const startLoading = () => ({
  type: LIVES_TYPES.LOADING,
  payload: true,
});
const stopLoading = () => ({
  type: LIVES_TYPES.LOADING,
  payload: false,
});

const setClient = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus(LIVES_TYPES.CLIENT, payload));
  };
};

const setCodec = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus(LIVES_TYPES.CODEC, payload));
  };
};
const setVideo = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus(LIVES_TYPES.VIDEO, payload));
  };
};
const setAudio = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus(LIVES_TYPES.AUDIO, payload));
  };
};

const setLocalStream = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus(LIVES_TYPES.LOCAL_STREAM, payload));
  };
};

const setCurrentStream = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus(LIVES_TYPES.CURRENT_STREAM, payload));
  };
};

const setDevicesList = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus(LIVES_TYPES.DEVICES_LIST, payload));
  };
};

const addLocal = (evt) => {
  const { stream } = evt;
  return (dispatch) => {
    dispatch(setLocalStream(stream));
    dispatch(setCurrentStream(stream));
  };
};

const addStream = (evt) => {
  const { stream } = evt;
  return (dispatch) => {
    dispatch(updateStatus(LIVES_TYPES.ADD_STREAM, stream));
  };
};

const removeStream = (evt) => {
  const { stream } = evt;
  return (dispatch) => {
    dispatch(updateStatus(LIVES_TYPES.REMOVE_STREAM, stream));
  };
};

const removeStreamById = (evt) => {
  const { uid } = evt;

  return (dispatch) => {
    dispatch({ type: LIVES_TYPES.REMOVE_STREAM, uid: uid });
  };
};

const clearAllStream = () => ({
  type: LIVES_TYPES.CLEAR_ALL_STREAM,
});

const connectionStateChanged = (evt) => {
  console.log('DUC-connectionStateChanged: ', evt);
};

const updateConfig = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus(LIVES_TYPES.CONFIG, payload));
  };
};

const setEmptyPackageAction = () => (dispatch) =>
  dispatch(updateStatus(LIVES_TYPES.SET_EMPTY_PACKAGE));

const setValuePackageAction = (data) => (dispatch) =>
  dispatch(updateStatus(LIVES_TYPES.SET_VALUE_PACKAGE, { data }));

const getListPackage = (callback) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/packages`;
  const configParams = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIST_ALL_PACKAGE_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.LIST_ALL_PACKAGE_SUCCESS, {
            data: response.data,
          })
        );
        callback && callback(response.data);
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIST_ALL_PACKAGE_FAILED, { error: error.errors }));
        callback && callback();
      }
    );
  };
};

const updatePremiumPackageSubData = (data) => (dispatch) =>
  dispatch(updateStatus('UPDATE_EDIT_PREMIUM_PACKAGE_SUB_DATA', { data }));

const getListUserByKeyWord = (params, handleSuccess, handleFailed, isDistribution) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/search-users?${queryString(params)}`;
  const configParams = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  const type = isDistribution
    ? TYPES.LIST_ALL_USER_BY_KEY_DISTRIBUTION
    : TYPES.LIST_ALL_USER_BY_KEY;

  return (dispatch) => {
    dispatch(updateStatus(`${type}_REQUEST`));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(`${type}_SUCCESS`, {
            data: response.data,
          })
        );
        handleSuccess && handleSuccess();
      },
      (error) => {
        dispatch(updateStatus(`${type}_FAILED`, { error: error.errors }));
        handleFailed && handleFailed();
      }
    );
  };
};

const getListFan = () => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/creator/v1/list-fan`;
  const configParams = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIST_ALL_FAN_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.LIST_ALL_FAN_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIST_ALL_FAN_FAILED, { error: error.errors }));
      }
    );
  };
};

const creatLiveStream = (data, upload = true, handleSuccess) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/livestreams`;
  const configParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_CREATE_LIVE_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        if (response.success) {
          if (data?.image !== undefined) {
            // User upload image

            let name = data.img_background.split('/');
            const path = `${name[0]}/${name[1]}/${name[2]}/${name[3]}`;
            let timeAway = data?.imageWidth && data?.imageWidth >= 2048 ? 10000 : 10000;
            handleSuccess && handleSuccess(response.data);
            (() => {
              dispatch(setCreateLiveStreamData(response?.data));
              dispatch(
                updateStatus(TYPES.CREATOR_CREATE_LIVE_SUCCESS, {
                  data: response?.data,
                })
              );
            })();
          } else {
            // User khong upload image

            dispatch(setCreateLiveStreamData(response?.data));
            dispatch(
              updateStatus(TYPES.CREATOR_CREATE_LIVE_SUCCESS, {
                data: response?.data,
              })
            );
          }
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATOR_CREATE_LIVE_FAILED, { error: error.errors }));
      }
    );
  };
};

const updateTypeLiveStream = (data, update_type = '', cb = () => {}) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/livestreams/${
    data?.id
  }?update_type=${update_type}`;
  const configParams = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_UPDATE_LIVE_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        if (update_type === 'delivery') {
          pushNotify(
            'success',
            i18n.t('ValidateMsg.liveDeliverySuccess'),
            'update_c92_success'
          );
        }
        if (update_type === 'hour_minute') {
          pushNotify(
            'success',
            i18n.t('ValidateMsg.liveHourMinuteSuccess'),
            'update_c92_success'
          );
          cb && cb();
        }
        if (update_type === 'ng_word') {
          pushNotify('success', i18n.t('ValidateMsg.liveNgWordSuccess'), 'update_c92_success');
        }

        dispatch(
          updateStatus(TYPES.CREATOR_UPDATE_LIVE_SUCCESS, {
            data: response?.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATOR_UPDATE_LIVE_FAILED, { error: error.errors }));
      }
    );
  };
};

/*
  //! delivery: Thay đổi các thông tin: allow_member, allow_follow, package, users.
  //! hour_minute: Gia hạn thời gian livestream.
  //! ng_word: Thay đổi ng_word.
  //! null: update Livestream (livestream chưa được phát)
*/
const updateLiveStream = (data, update_type = '', cb = () => {}) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/livestreams/${data?.id}`;
  const configParams = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_UPDATE_LIVE_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        if (response.success) {
          console.log('updateLiveStream-DONE-SUCCESS: ', response);

          if (data?.image) {
            // co update image
            let name = data.img_background.split('/');
            const path = `${name[0]}/${name[1]}/${name[2]}/${name[3]}`;
            let timeAway = data?.imageWidth && data?.imageWidth >= 2048 ? 20000 : 4000;
            getPresignedUrlS3('image', path, name[4] || '')
              .then((resUrl) => {
                uploadS3ByPresigned(resUrl?.data?.pre_signed, data?.image).then((_res) => {
                  setTimeout(function () {
                    dispatch(setCreateLiveStreamData(response?.data));
                    dispatch(
                      updateStatus(TYPES.CREATOR_CREATE_LIVE_SUCCESS, {
                        data: response?.data,
                      })
                    );
                  }, timeAway);
                });
              })
              .catch((_error) => {
                dispatch(
                  updateStatus(TYPES.CREATOR_UPDATE_LIVE_SUCCESS, {
                    data: response.data,
                  })
                );
              });
          } else {
            if (update_type === 'delivery') {
              pushNotify(
                'success',
                i18n.t('ValidateMsg.liveDeliverySuccess'),
                'update_c92_success'
              );
            }
            if (update_type === 'hour_minute') {
              pushNotify(
                'success',
                i18n.t('ValidateMsg.liveHourMinuteSuccess'),
                'update_c92_success'
              );
              cb && cb();
            }
            if (update_type === 'ng_word') {
              pushNotify(
                'success',
                i18n.t('ValidateMsg.liveNgWordSuccess'),
                'update_c92_success'
              );
            }

            dispatch(
              updateStatus(TYPES.CREATOR_UPDATE_LIVE_SUCCESS, {
                data: response?.data,
              })
            );
          }
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATOR_UPDATE_LIVE_FAILED, { error: error.errors }));
      }
    );
  };
};

// const creatLiveStream = (data) => {
//   const url = `${getEnv('REACT_APP_API_SERVER')}/creator/livestream`;
//   const formData = new FormData();
//   const {
//     packages,
//     users,
//     ng_setting,
//     total_hour_live,
//     total_minute_live,
//     date_live,
//     hour_minute,
//     title,
//     description,
//     all_follow,
//     all_member,
//     image,
//   } = data;
//   if (packages.length > 0) {
//     for (var packagesItem = 0; packagesItem < packages.length; packagesItem++) {
//       formData.append(`packages[${[packagesItem]}][id]`, `${packages[packagesItem]?.id}`);
//       formData.append(`packages[${[packagesItem]}][name]`, `${packages[packagesItem]?.name}`);
//     }
//   }
//   if (users.length > 0) {
//     for (var allowMemberItem = 0; allowMemberItem < users.length; allowMemberItem++) {
//       formData.append(
//         `users[${[allowMemberItem]}][id]`,
//         `${users[allowMemberItem]?.id}`
//       );
//       formData.append(
//         `users[${[allowMemberItem]}][name]`,
//         `${users[allowMemberItem]?.name}`
//       );
//     }
//   }
//   if (ng_setting.length > 0) {
//     for (var ngItem = 0; ngItem < ng_setting.length; ngItem++) {
//       formData.append(`ng_setting[${[ngItem]}][id]`, `${ng_setting[ngItem]?.id}`);
//       formData.append(`ng_setting[${[ngItem]}][name]`, `${ng_setting[ngItem]?.name}`);
//     }
//   }
//   formData.append('total_hour_live', total_hour_live);
//   formData.append('total_minute_live', total_minute_live);
//   formData.append('date_live', date_live);
//   formData.append('hour_minute', hour_minute);
//   formData.append('title', title);
//   formData.append('description', description);
//   formData.append('all_follow', all_follow ? 1 : 0);
//   formData.append('all_member', all_member ? 1 : 0);
//   image && formData.append('image', image);

//   //post kiủ for này sẽ bị lỗi
//   // for (let key in data) {
//   //   if (key === 'all_follower' || key === 'all_member') {
//   //     if (data[key] === false) {
//   //       data[key] = 0;
//   //     } else {
//   //       data[key] = 1;
//   //     }
//   //   }
//   //   formData.append(key, data[key]);
//   //   if (key === 'allow_member' || key === 'packages' || 'ng_setting') {
//   //     for (let i = 0; i < data[key].length; i++) {
//   //       formData.append(`${key}[${[i]}][id]`, `${data[key][i]?.id}`);
//   //       formData.append(`${key}[${[i]}][name]`, `${data[key][i]?.name}`);
//   //     }
//   //   }
//   // }

//   const configParams = {
//     method: 'POST',
//     headers: {
//       Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
//     },
//     body: formData,
//   };

//   return (dispatch) => {
//     dispatch(updateStatus(TYPES.CREATOR_CREATE_LIVE_REQUEST));
//     callApi(
//       url,
//       configParams,
//       null,
//       async (response) => {
//         if (response.success) {
//           await dispatch(
//             updateStatus(TYPES.CREATOR_CREATE_LIVE_SUCCESS, {
//               data: response.data,
//             })
//           );
//         }
//       },
//       (error) => {
//         dispatch(updateStatus(TYPES.CREATOR_CREATE_LIVE_FAILED, { error: error.errors }));
//       }
//     );
//   };
// };

//* API UPDATE CHANGE STATUS DRAF TO PENDING

const updateChangeStatusDrafToPenDing = (liveKey = '') => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/creator/v1/change-active/${liveKey}`;
  const configParams = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_CHANGE_ACTIVE_DRAF_LIVE_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        if (response.success) {
          await dispatch(
            updateStatus(TYPES.CREATOR_CHANGE_ACTIVE_DRAF_LIVE_SUCCESS, {
              data: response.data,
            })
          );
        }
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.CREATOR_CHANGE_ACTIVE_DRAF_LIVE_FAILED, { error: error.errors })
        );
      }
    );
  };
};

const updateChangeStatusToLive = (id = '', callback) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/livestreams/${id}/start`;
  const configParams = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_CHANGE_STATUS_TO_LIVE_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        if (response.success) {
          if (callback) {
            callback();
          }
          await dispatch(
            updateStatus(TYPES.CREATOR_CHANGE_STATUS_TO_LIVE_SUCCESS, {
              data: response.data,
            })
          );
        }
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.CREATOR_CHANGE_STATUS_TO_LIVE_FAILED, { error: error.errors })
        );
      }
    );
  };
};

//* CREATOR CHANGE STATUS TO PAUSE LIVE

const updateChangeStatusPauseLive = (liveKey = '') => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/creator/v1/pause-livestream/${liveKey}`;
  const configParams = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_CHANGE_STATUS_TO_PAUSE_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        if (response.success) {
          await dispatch(
            updateStatus(TYPES.CREATOR_CHANGE_STATUS_TO_PAUSE_SUCCESS, {
              data: response.data,
            })
          );
        }
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.CREATOR_CHANGE_STATUS_TO_PAUSE_FAILED, { error: error.errors })
        );
      }
    );
  };
};

//* CREATOR CHANGE STATUS TO END LIVE

const updateChangeStatusEndLive = (id = '', liveKey, user_live = '', callback) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/livestreams/${id}/end`;
  let data = {
    user_ids: user_live,
  };
  if (user_live === '') {
    data.user_ids = [];
  }
  const configParams = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_CHANGE_STATUS_TO_END_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        if (response.success) {
          if (callback) {
            callback();
          }
          await dispatch(
            updateStatus(TYPES.CREATOR_CHANGE_STATUS_TO_END_SUCCESS, {
              data: response.data,
            })
          );
        }
        //! UPDATE SET END LIVE
        const newObj = { status: 5 };
        updateData(`/lives/${liveKey}/config`, newObj);
        return (window.location.href = '/live/list-livestream');
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.CREATOR_CHANGE_STATUS_TO_END_FAILED, { error: error.errors })
        );
      }
    );
  };
};

//* LIST LIVESTREAM PLAY
const getListLives = (status = 'delivery', page, limit = 10) => {
  const url = `${getEnv(
    'REACT_APP_API_SERVER'
  )}/favorite-livestreams?type=${status}&page=${page}&limit=${limit}&favorite_flag=1`;
  const configParams = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIST_LIVES_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        // response.data = lodash.orderBy(response.data, ['id'], ['desc']);
        await dispatch(
          updateStatus(TYPES.LIST_LIVES_SUCCESS, {
            data: response,
            page,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIST_LIVES_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

//* END LIST LIVESTREAM PLAY

const setCreateLiveStreamData = (liveStream) => (dispatch) =>
  dispatch(updateStatus(LIVES_TYPES.SAVE_LIVE_STREAM_CREATED, { data: liveStream }));

const setEmptyFanListAction = () => (dispatch) =>
  dispatch(updateStatus(LIVES_TYPES.SET_EMPTY_FAN_LIST));

//* START TIME LIVESTREAM
const updateMoreTimeLivestream = (liveKey = '', data) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/creator/v1/more-stream-time/${liveKey}`;

  const configParams = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.MORE_TIME_LIVESTREAM_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        if (response.success) {
          await dispatch(
            updateStatus(TYPES.MORE_TIME_LIVESTREAM_SUCCESS, {
              data: response.data,
            })
          );
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.MORE_TIME_LIVESTREAM_FAILED, { error: error.errors }));
      }
    );
  };
};
//* END TIME LIVESTREAM

//* START GET LIVESTREAM
const creatorGetLiveByKey = (id = '') => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/livestreams/${id}`;

  const configParams = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_GET_LIVE_BY_KEY_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        if (response.success) {
          await dispatch(
            updateStatus(TYPES.CREATOR_GET_LIVE_BY_KEY_SUCCESS, {
              data: response.data,
            })
          );
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATOR_GET_LIVE_BY_KEY_FAILED, { error: error.errors }));
      }
    );
  };
};
//* START GET LIVESTREAM

//* START UPDATE LIVESTREAM
const creatorUpdateLiveStream = (liveKey = '', data) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/creator/v1/get-livestream/${liveKey}/update
  `;
  const configParams = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_UPDATE_LIVESTREAM_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        if (response.success) {
          await dispatch(
            updateStatus(TYPES.CREATOR_UPDATE_LIVESTREAM_SUCCESS, {
              data: response.data,
            })
          );
        }
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.CREATOR_UPDATE_LIVESTREAM_FAILED, { error: error.errors })
        );
      }
    );
  };
};
//* END UPDATE LIVESTREAM

//* 29-3-2020 INFO FAN AND CHECK FOLLOW CREATOR

const infoUserAndCheckFollow = (liveKey = '') => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/livestreams/${liveKey}/permission`;
  const configParams = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      'Cache-Control': 'no-cache',
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.IS_FOLLOW_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        if (response.success) {
          await dispatch(
            updateStatus(TYPES.IS_FOLLOW_SUCCESS, {
              data: response.data,
            })
          );
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.IS_FOLLOW_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};
//* END 29-3-2020 INFO FAN AND CHECK FOLLOW CREATOR

const getListLiveStream = (page = 1, limit = 10) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/livestreams?page=${page}&limit=${limit}`;

  const configParams = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_LIVE_STREAM_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        // response.data = lodash.orderBy(response.data, ['id'], ['desc']);
        await dispatch(
          updateStatus(TYPES.GET_LIST_LIVE_STREAM_SUCCESS, {
            data: response,
            page,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_LIVE_STREAM_FAILED, { error: error.errors }));
      }
    );
  };
};

const deleteListLiveStream = (id) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/livestreams/${id}`;

  const configParams = {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.DELETE_LIVE_STREAM_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        const msg =
          i18n.t(`ValidateMsg.${response?.message}`) !== undefined
            ? i18n.t(`ValidateMsg.${response?.message}`)
            : i18n.t(`ValidateMsg.DELETE_LIVESTREAM_SUCCESS`);
        pushNotify('success', msg);
        await dispatch(
          updateStatus(TYPES.DELETE_LIVE_STREAM_SUCCESS, {
            data: id,
          })
        );
        // dispatch(getListLiveStream());
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_LIVE_STREAM_FAILED, { error: error.errors }));
      }
    );
  };
};

const detailLive = (id, params) => {
  let url = `${getEnv('REACT_APP_API_SERVER')}/livestreams/${id}?`;
  if (params) {
    Object.keys(params).map((key) => {
      url += key + '=' + params[key] + '&';
    });
  }
  const configParams = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_DETAIL_LIVE_STREAM_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_DETAIL_LIVE_STREAM_SUCCESS, {
            data: response.data,
          })
        );
        // dispatch(getListLiveStream());
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_DETAIL_LIVE_STREAM_FAILED, { error: error.errors }));
      }
    );
  };
};

const resetListAllLiveCreator = () => (dispatch) =>
  dispatch(updateStatus(TYPES.RESET_LIST_ALL_LIVE_CREATOR_SUCCESS));

const resetDataTopDonate = () => (dispatch) =>
  dispatch(updateStatus(TYPES.RESET_DATA_TOP_DONATE));

const resetListLiveStream = () => (dispatch) => {
  dispatch(updateStatus('RESET_LIST_LIVESTREAM_MY_SEFT'));
};

//* TOP DONATE
const getTopDonate = (liveKey = '', page = 1, limit = 10) => {
  const url = `${getEnv(
    'REACT_APP_API_SERVER'
  )}/livestreams/${liveKey}/top-users?page=${page}&limit=${limit}`;
  const configParams = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_TOP_DONATE_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        if (response.success) {
          await dispatch(
            updateStatus(TYPES.GET_TOP_DONATE_SUCCESS, {
              data: response.data,
            })
          );
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_TOP_DONATE_FAILED, { error: error.errors }));
      }
    );
  };
};
// END TOP DONATE

const getTopOneDonate = async (liveKey = '', page = 1, limit = 10, handleSuccess) => {
  const url = `${getEnv(
    'REACT_APP_API_SERVER'
  )}/livestreams/${liveKey}/top-users?page=${page}&limit=${limit}`;
  const configParams = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return callApi(
    url,
    configParams,
    null,
    async (response) => {
      if (response.success) {
        handleSuccess(response?.data);
      }
    },
    (_error) => {
      return window.location.reload();
    }
  );
};
//* FOLLOW OR UNFOLLOW BY TYPES

const followUnFollowUserByType = (id, haveReload = false) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/users/${id}/following`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.FOLLOW_UNFOLLOW_LIVE_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FOLLOW_UNFOLLOW_LIVE_SUCCESS, {
            data: response.data,
          })
        );
        haveReload && window.location.reload();
      },
      (error) => {
        dispatch(updateStatus(TYPES.FOLLOW_UNFOLLOW_LIVE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

//* END FOLOW OR UNFOLLOW BY TYPES

//* FANS MUA PACKAGES

const fanBuyPackages = (packageId = 0, liveKey = '', handleFailed) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/packages/${packageId}/buy`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.FAN_BUY_PACKAGE_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        if (response.success) {
          await dispatch(
            updateStatus(TYPES.FAN_BUY_PACKAGE_SUCCESS, {
              data: response.data,
            })
          );
          return window.location.reload();
        }
      },
      (error) => {
        handleFailed && handleFailed(error.errors);
        dispatch(updateStatus(TYPES.FAN_BUY_PACKAGE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};
//* END FAN MUA PACKAGES

//* FAN DONATE

const fanDonateCreatorMessage = (queries, cb) => {
  const queryString = Object.keys(queries)
    .map((key) => (queries[key] ? `${key}=${queries[key]}` : ''))
    .filter((s) => Boolean(s))
    .join('&');
  const url = `${getEnv('REACT_APP_API_SERVER')}/donation-messages/?` + queryString;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.FAN_DONATE_CREATOR_LIVE_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FAN_DONATE_CREATOR_LIVE_SUCCESS, {
            data: response.data,
          })
        );
        cb && cb();
      },
      (error) => {
        dispatch(updateStatus(TYPES.FAN_DONATE_CREATOR_LIVE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const fanDonateCreatorLive = (queries) => {
  const queryString = Object.keys(queries)
    .map((key) => (queries[key] ? `${key}=${queries[key]}` : ''))
    .filter((s) => Boolean(s))
    .join('&');
  const url = `${getEnv('REACT_APP_API_SERVER')}/donation-livestreams?` + queryString;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.FAN_DONATE_CREATOR_LIVE_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FAN_DONATE_CREATOR_LIVE_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.FAN_DONATE_CREATOR_LIVE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

//* END FAN DONATE

//* LIST PACKAGES POINTS
const listPackagePoints = () => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/donate/categories?active_flag=1`;
  const configParams = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.FAN_LIST_PACKAGES_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FAN_LIST_PACKAGES_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.FFAN_LIST_PACKAGES_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};
//* END LIST PACKAGES POINTS

const resetStatusFanDonate = () => (dispatch) =>
  dispatch(updateStatus(TYPES.RESET_STATUS_FAN_DONATE));
// Pre-livestream
const duplicatePreLivestream = (cbSuccess = () => {}) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/pre-livestream?filter_words=false`;
  const config = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_DETAIL_PRE_LIVESTREAM_REQUEST));
    callApi(
      url,
      config,
      null,
      (resp) => {
        dispatch(updateStatus(TYPES.GET_DETAIL_PRE_LIVESTREAM_SUCCESS, resp));
        cbSuccess(resp.data);
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_DETAIL_PRE_LIVESTREAM_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

//* GET LAST RECORD LIVESTREAM BY USER

const getLastRecordLiveStreamByUser = () => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/pre-livestream`;
  const configParams = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LAST_LIVE_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LAST_LIVE_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LAST_LIVE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};
//* END LAST RECORD LIVESTREAM BY USER
// Create Token Agora
const createTokenAgora = ({ name = '', exSec = 60, action = () => {} }) => {
  const url = `${getEnv(
    'REACT_APP_API_SERVER'
  )}/creator/livestreams/agora/token?channel_name=${name}&expiredSec=${exSec}`;
  const config = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATE_TOKEN_AGORA_REQUEST));
    callApi(
      url,
      config,
      null,
      (resp) => {
        dispatch(updateStatus(TYPES.CREATE_TOKEN_AGORA_SUCCESS, resp));
        action(resp?.data);
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATE_TOKEN_AGORA_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const clearDataLive = () => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CLEAR_DATA_LIVE_REQUEST));
  };
};
const createTokenAgoraGuest = ({ name = '', exSec = 60, action = () => {} }) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/livestreams/agora/token?channel_name=${name}`;
  const config = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATE_TOKEN_AGORA_GUEST_REQUEST));
    callApi(
      url,
      config,
      null,
      (resp) => {
        dispatch(updateStatus(TYPES.CREATE_TOKEN_AGORA_GUEST_SUCCESS, resp));
        action(resp?.data);
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATE_TOKEN_AGORA_GUEST_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};
const listEffectCreator = () => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/user-donate/categories?active_flag=1`;
  const configParams = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_GET_EFFECT_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CREATOR_GET_EFFECT_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATOR_GET_EFFECT_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const checkPasswordLive = (liveKey = '', data, success = () => {}, failed = () => {}) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/livestreams/${liveKey}/check-password`;

  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };

  return (dispatch) => {
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        success(response);
      },
      (error) => {
        failed();
      },
      {
        messV2: true,
      }
    );
  };
};

const setIdScrollListLive = (id, cb) => (dispatch) => {
  dispatch(
    updateStatus('SET_ID_SCROLL_BACK_LIST_LIVE', {
      id,
    })
  );
  cb && cb();
};

const setIdScrollLiveDelivery = (id, cb) => (dispatch) => {
  dispatch(
    updateStatus('SET_ID_SCROLL_BACK_LIVE_DELIVERY', {
      id,
    })
  );
  cb && cb();
};

const setIdScrollBackPackageSub = (id, cb) => {
  return (dispatch) => {
    dispatch(
      updateStatus('SET_ID_SCROLL_BACK_PACKAGE_SUB', {
        id,
      })
    );
    cb && cb();
  };
};

const clearIdScrollBackPackageSubAndData = (id, cb) => {
  return (dispatch) => {
    dispatch(
      updateStatus('CLEAR_ID_SCROLL_BACK_PACKAGE_SUB_DATA', {
        id,
      })
    );
    cb && cb();
  };
};

const resetActionGetListLive = (cb) => (dispatch) => {
  dispatch(updateStatus('RESET_ACTION_GET_LIST_LIVE'));
  cb && cb();
};
export {
  updateConfig,
  startLoading,
  stopLoading,
  setClient,
  setCodec,
  setVideo,
  setAudio,
  setDevicesList,
  addLocal,
  addStream,
  removeStream,
  removeStreamById,
  connectionStateChanged,
  clearAllStream,
  setCurrentStream,
  setLocalStream,
  getListPackage,
  setEmptyPackageAction,
  setValuePackageAction, // 16-5-2022
  getListFan,
  setEmptyFanListAction,
  setCreateLiveStreamData,
  creatLiveStream,
  updateChangeStatusDrafToPenDing,
  updateChangeStatusToLive,
  updateChangeStatusPauseLive,
  updateChangeStatusEndLive,
  getListLives,
  updateMoreTimeLivestream,
  creatorGetLiveByKey,
  creatorUpdateLiveStream,
  getListLiveStream,
  infoUserAndCheckFollow,
  deleteListLiveStream,
  detailLive,
  updateLiveStream, // UPDATE LIVE 14-5-2022,
  resetListAllLiveCreator, // RESET LIST WHEN CREATOR LEAVE
  getTopDonate, // C092,
  followUnFollowUserByType,
  fanBuyPackages,
  resetDataTopDonate,
  fanDonateCreatorLive,
  fanDonateCreatorMessage,
  resetStatusFanDonate,
  listPackagePoints,
  getTopOneDonate,
  getLastRecordLiveStreamByUser, // C090
  duplicatePreLivestream,
  createTokenAgora,
  createTokenAgoraGuest,
  listEffectCreator,
  checkPasswordLive,
  resetActionGetListLive,
  getListUserByKeyWord,
  updateTypeLiveStream,
  clearDataLive,
  resetListLiveStream,
  setIdScrollListLive,
  setIdScrollLiveDelivery,
  setIdScrollBackPackageSub,
  clearIdScrollBackPackageSubAndData,
  updatePremiumPackageSubData,
};
