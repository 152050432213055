import Icon from '@ant-design/icons';

const Top1Icon = (props) => (
  <Icon
    component={() => (
      <svg
        width='18'
        height='24'
        viewBox='0 0 18 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M4.2668 16.6445L3.31533 16.6909L1.47062 21.9909C1.41183 22.1597 1.54862 22.3323 1.72641 22.3137L4.31218 22.0429C4.38441 22.0354 4.45623 22.0604 4.50816 22.1111L6.36699 23.9289C6.49477 24.0539 6.70918 24.0035 6.76791 23.8347L8.62468 18.5001L8.07718 17.9687L4.2668 16.6445Z'
          fill='#FF4755'
        />
        <path
          d='M8.62477 18.501L8.99996 17.4231L3.68949 15.6172L3.31543 16.6919C4.8234 17.7679 6.64927 18.4266 8.62477 18.501Z'
          fill='#FC2B3A'
        />
        <path
          d='M13.7331 16.6445L14.6845 16.6909L16.5293 21.9909C16.588 22.1597 16.4513 22.3323 16.2735 22.3137L13.6877 22.0429C13.6155 22.0354 13.5437 22.0604 13.4918 22.1111L11.6329 23.9289C11.5052 24.0539 11.2907 24.0035 11.232 23.8347L9.37524 18.5001L9.92274 17.9687L13.7331 16.6445Z'
          fill='#FF4755'
        />
        <path
          d='M9.37519 18.501L9 17.4231L14.3105 15.6172L14.6845 16.6919C13.1766 17.7679 11.3507 18.4266 9.37519 18.501Z'
          fill='#FC2B3A'
        />
        <path
          d='M8.99993 16.8804C0.288116 16.8804 0.291772 8.41814 0.298991 8.27269C0.527694 3.66534 4.33577 0 8.99993 0C13.6641 0 17.4722 3.66534 17.7009 8.27273C17.7081 8.41819 17.7117 16.8804 8.99993 16.8804Z'
          fill='#FFE27A'
        />
        <path
          d='M8.9999 16.5462C4.33574 16.5462 0.527805 12.8808 0.299055 8.27344C0.291836 8.41889 0.288086 8.56528 0.288086 8.71252C0.288086 13.5239 4.18851 17.4243 8.9999 17.4243C13.8113 17.4243 17.7117 13.5239 17.7117 8.71252C17.7117 8.56523 17.708 8.41889 17.7007 8.27344C17.472 12.8808 13.6641 16.5462 8.9999 16.5462Z'
          fill='#F9CF58'
        />
        <path
          d='M8.99999 15.0074C2.15075 15.0074 2.14156 8.27228 2.15075 8.12706C2.3772 4.54131 5.35714 1.70312 8.99999 1.70312C12.6428 1.70312 15.6228 4.54131 15.8492 8.12706C15.8584 8.27223 15.8492 15.0074 8.99999 15.0074Z'
          fill='#FFBA57'
        />
        <path
          d='M8.99978 14.6974C5.35698 14.6974 2.37756 11.8591 2.15111 8.27344C2.14192 8.41866 2.13672 8.565 2.13672 8.71252C2.13672 12.5029 5.20942 15.5756 8.99978 15.5756C12.7901 15.5756 15.8628 12.5029 15.8628 8.71252C15.8628 8.56495 15.8576 8.41866 15.8485 8.27344C15.622 11.8591 12.6426 14.6974 8.99978 14.6974Z'
          fill='#FFAC3E'
        />
        <path
          d='M9.39137 12.79C8.91488 12.79 8.52859 12.4037 8.52859 11.9272V11.4709V7.09198C8.52859 7.09198 8.01235 7.60972 7.39468 7.29908C6.91309 7.05687 6.91309 6.43348 6.91309 6.43348L7.97682 4.63789C8.13216 4.37567 8.41435 4.21484 8.71913 4.21484H9.39137C9.86785 4.21484 10.2541 4.60109 10.2541 5.07762V11.4709V11.9272C10.2541 12.4037 9.86785 12.79 9.39137 12.79Z'
          fill='#FFE27A'
        />
        <path
          d='M8.40995 6.41419C8.18097 6.80077 7.69408 6.9684 7.288 6.77597C7.12365 6.69811 6.99695 6.57713 6.91272 6.43425C6.67314 6.83954 6.80284 7.36093 7.20175 7.60725C7.61312 7.86132 8.16283 7.70963 8.4092 7.29361L8.52855 7.0921V6.44658C8.52855 6.3818 8.443 6.35841 8.40995 6.41419Z'
          fill='#F9CF58'
        />
        <path
          d='M9.39135 12.3354C8.91486 12.3354 8.52856 11.9492 8.52856 11.4727V12.3509C8.52856 12.8274 8.91486 13.2136 9.39135 13.2136C9.86783 13.2136 10.2541 12.8274 10.2541 12.3509V11.4727C10.2541 11.9492 9.86783 12.3354 9.39135 12.3354Z'
          fill='#F9CF58'
        />
      </svg>
    )}
    {...props}
  />
);

export default Top1Icon;
