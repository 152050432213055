import { CloseIcon } from 'images';
import { useTranslation } from 'react-i18next';
import PopupCardManagementPage from './card-management';
import './listCard.scss';
import { useSelector } from 'react-redux';

const PopupListCardSub = ({
  title,
  setIsShowPopupListCard,
  setIsShowPopupCreateAndUpdateCard,
  setIsShowPopupPayment,
  resetAll,
}) => {
  const { t } = useTranslation();
  const { cards } = useSelector((state) => state.card);

  return (
    <div className='popup popup-list-card-sub'>
      <div className='popup-content cards'>
        <>
          <div className='title'>{title}</div>
          <CloseIcon
            className='close-icon'
            onClick={() => {
              resetAll(true);
              setIsShowPopupListCard(false);
            }}
          />
        </>
        <div className='content'>
          <div className='content-wrapper'>
            <PopupCardManagementPage
              setIsShowPopupCreateAndUpdateCard={setIsShowPopupCreateAndUpdateCard}
              setIsShowPopupListCard={setIsShowPopupListCard}
            />
          </div>
        </div>

        <div className='button-actions-relative'>
          <div className='box-button'>
            <button
              disabled={!cards?.length}
              onClick={() => {
                setIsShowPopupPayment(true);
                setIsShowPopupListCard(false);
              }}
              className='btn btn-submit'>
              {t('Common.continue')}
            </button>
            <button
              onClick={() => {
                resetAll(true)
                setIsShowPopupListCard(false);
              }}
              className='btn btn-cancel'>
              {t('Common.return')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupListCardSub;
