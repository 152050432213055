/* eslint-disable react-hooks/rules-of-hooks */
import { callApi } from 'utils/request';
import useUserRole from 'hooks/useUserRole';
import {
  queryString,
  configParamsPostNoBody,
  configParamsPostJSON,
  ENV,
  configParamsGet,
  STORAGE,
} from 'utils';
import Storage from 'utils/storage';
const { isIdol } = useUserRole();
const role = isIdol === true ? 'creator' : 'fan';

const API = {
  signInTwitter: () => `${ENV}/twitter/login`,
  createTweetTwitter: () => `${ENV}/tweet`,
  getInfoUserTwitter: (params) => `${ENV}/twitter/user?${queryString(params)}`,
  linkTwitter: () => `${ENV}/twitter/link`,
  unLinkTwitter: () => `${ENV}/twitter/unlink`,
  followTwitter: (id) => `${ENV}/users/${id}/twitter/following`,
  unFollowTwitter: (id) => `${ENV}/users/${id}/twitter/following`,
};

const signInTwitter = (data, handleSuccess, handleFailed) => {
  return () => {
    callApi(
      API.signInTwitter(),
      configParamsPostJSON(data),
      null,
      async (response) => {
        handleSuccess && handleSuccess(response);
      },
      async (error) => {
        handleFailed && handleFailed(error.errors.messageId);
      }
    );
  };
};

const createTweetTwitter = (data, handleSuccess, handleFailed) => {
  return () => {
    callApi(
      API.createTweetTwitter(),
      configParamsPostJSON(data),
      null,
      async (response) => {
        handleSuccess && handleSuccess(response);
      },
      async (error) => {
        handleFailed && handleFailed(error);
      }
    );
  };
};

const getInfoUserTwitter = (params, handleSuccess, handleFailed) => {
  return () => {
    callApi(
      API.getInfoUserTwitter(params),
      configParamsGet,
      null,
      async (response) => {
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleFailed && handleFailed(error);
      }
    );
  };
};

const linkTwitter = (data, token, handleSuccess, handleFailed) => {
  return () => {
    callApi(
      API.linkTwitter(),
      configParamsPostJSON(data, token),
      null,
      async (response) => {
        handleSuccess && handleSuccess(response);
      },
      async (error) => {
        handleFailed && handleFailed(error);
      }
    );
  };
};

const unLinkTwitter = (handleSuccess, handleFailed) => {
  return () => {
    callApi(
      API.unLinkTwitter(),
      configParamsPostNoBody,
      null,
      async (response) => {
        Storage.remove(STORAGE.TOKEN_TWITTER);
        Storage.remove(STORAGE.SERECT_TOKEN_TWITTER);
        handleSuccess && handleSuccess(response);
      },
      async (error) => {
        handleFailed && handleFailed(error);
      }
    );
  };
};

const followTwitter = (user_id, handleSuccess, handleFailed) => {
  return () => {
    callApi(
      API.followTwitter(user_id),
      configParamsPostNoBody,
      null,
      async (response) => {
        handleSuccess && handleSuccess(response);
      },
      async (error) => {
        handleFailed && handleFailed(error);
      }
    );
  };
};

const unFollowTwitter = (user_id, handleSuccess, handleFailed) => {
  return () => {
    callApi(
      API.unFollowTwitter(user_id),
      configParamsPostNoBody,
      null,
      async (response) => {
        handleSuccess && handleSuccess(response);
      },
      async (error) => {
        handleFailed && handleFailed(error);
      }
    );
  };
};

export {
  followTwitter,
  signInTwitter,
  createTweetTwitter,
  getInfoUserTwitter,
  linkTwitter,
  unLinkTwitter,
  unFollowTwitter,
};
