import { CircleCheckIcon } from 'images';
import DangerIcon from 'images/DangerIcon';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import React from 'react';
import { useTranslation } from 'react-i18next';

const PopupConfirmError = ({
  closeModal,
  title,
  isWarning,
  isMobile,
  isNoButton = false,
  className,
}) => {
  useLockBodyScroll();
  const { t } = useTranslation();
  const handleShowPopup = (e) => {
    closeModal(false);
  };

  return (
    <div
      className={`popup popup-withdraw-success popup-error-buy-package ${className} ${
        isNoButton ? 'no-button' : ''
      }`}
      style={{ zIndex: '9999' }}>
      <div
        className='popup-content'
        style={{ height: 'auto', padding: '32px', maxWidth: isMobile ? '330px' : '' }}>
        <div className='content mb-3' style={{ alignItems: 'start' }}>
          {isWarning ? (
            <DangerIcon
              className='me-3 text-success font-24 mg-icon'
              style={{ width: '40px', height: '40px', top: '-7px', position: 'relative' }}
            />
          ) : (
            <CircleCheckIcon className='me-3 text-success font-24 mg-icon' />
          )}
          <b className='ps-2'>{title}</b>
        </div>
        <div className='text-end'>
          {!isNoButton && (
            <button className='btn btn-bg-red text-white' onClick={handleShowPopup}>
              {t('Common.Ok')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopupConfirmError;
