import { Checkbox } from 'components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setIdCardActive } from 'store/actions/card';

export const CardItem = ({ item }) => {
  const { t } = useTranslation();

  const { idCardActive } = useSelector((state) => state.card);
  const dispatch = useDispatch();

  const convertNameTypeCard = (item) => {
    let type = '';
    const cardType = item?.card_type;
    if (item?.card_type === 'VISA') {
      type = 'Visa';
    } else if (cardType === 'MASTER_CARD') {
      type = 'MasterCard';
    } else if (cardType === 'JCB') {
      type = 'JCB';
    }
    return `${type}*${item?.card_number?.slice(-4)}`;
  };

  const handleChangeCardActive = (id) => {
    dispatch(setIdCardActive(id));
  };

  const checkActiveItem = useMemo(() => {
    if (idCardActive) {
      return idCardActive === item?.id;
    }
    return +item?.default === 1;
  }, [idCardActive, item?.id, item?.default]);

  return (
    <div className='card-item'>
      <div className='card-item-left'>
        <div className='card-item-left_checkbox'>
          <Checkbox
            id={item?.id}
            type='checkbox'
            disabled={checkActiveItem}
            onChange={() => handleChangeCardActive(item?.id)}
            text=''
            checked={checkActiveItem}
          />
        </div>
        <div className='card-item-left_info'>
          <div className='info-name'>
            {+item?.default === 1 && (
              <span className='card-default'>{t('Creator.cardManagement.cardDefault')}</span>
            )}
            <span className={`card-name ${item?.default === 1 ? 'is-have-default' : ''}`}>
              {item?.card_holder_name}
            </span>
          </div>
          <div className='info-card-number text-card'>{convertNameTypeCard(item)}</div>
          <div className='info-card-date text-card'>
            {t('Creator.cardManagement.dateExpiry')}: {item?.card_expired_month}/
            {item?.card_expired_year}
          </div>
        </div>
      </div>
    </div>
  );
};
