export default function EmptySurveyIconV2(props) {
  return (
    <svg
      width={100}
      height={100}
      viewBox='0 0 100 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M79.283 13.1018H70.2361V9.0546C70.2361 7.74516 68.8078 7.15003 67.4983 7.15003H60.4752C58.8087 2.38848 54.6423 0.00770755 49.8807 0.00770755C45.1716 -0.16933 40.8883 2.71818 39.2863 7.15003H32.3821C31.0726 7.15003 29.7632 7.74516 29.7632 9.0546V13.1018H20.7161C15.3549 13.159 10.9688 17.3883 10.7168 22.7439V90.9528C10.7168 96.1906 15.4783 99.9997 20.7161 99.9997H79.283C84.5208 99.9997 89.2823 96.1906 89.2823 90.9528V22.7441C89.0303 17.3883 84.6443 13.159 79.283 13.1018ZM34.5245 11.9116H41.0717C42.2146 11.7721 43.1326 10.9027 43.3336 9.7689C44.0385 6.69889 46.7319 4.49515 49.8807 4.41222C53.0004 4.50677 55.6532 6.71735 56.3088 9.7689C56.5223 10.9419 57.5009 11.8225 58.6895 11.9116H65.4748V21.4347H34.5245V11.9116ZM84.5208 90.9531C84.5208 93.5719 81.9019 95.2384 79.283 95.2384H20.7161C18.0972 95.2384 15.4783 93.5719 15.4783 90.9531V22.7441C15.7212 20.0181 17.9796 17.9135 20.7161 17.8636H29.763V23.9346C29.8888 25.2684 31.0439 26.2662 32.3819 26.1964H67.4981C68.8606 26.2709 70.052 25.2869 70.2359 23.9346V17.8634H79.2828C82.019 17.9135 84.2777 20.0179 84.5205 22.7439V90.9531H84.5208Z'
        fill='#FCECEF'
      />
      <path
        d='M40.833 53.2178C39.9401 52.2766 38.4577 52.2235 37.4999 53.0989L29.8813 60.3601L26.6673 57.027C25.7744 56.0857 24.292 56.0329 23.3342 56.908C22.4121 57.8741 22.4121 59.3941 23.3342 60.3601L28.2146 65.3598C28.6371 65.8328 29.2472 66.0944 29.8811 66.0741C30.5091 66.0652 31.1081 65.8084 31.5476 65.3598L40.8326 56.551C41.7531 55.7066 41.8146 54.2757 40.97 53.3554C40.9267 53.3074 40.8809 53.2616 40.833 53.2178Z'
        fill='#FCECEF'
      />
      <path
        d='M74.9978 58.3364H47.6191C46.3041 58.3364 45.2383 59.4023 45.2383 60.7172C45.2383 62.0321 46.3041 63.098 47.6191 63.098H74.9978C76.3127 63.098 77.3786 62.0321 77.3786 60.7172C77.3786 59.4023 76.3127 58.3364 74.9978 58.3364Z'
        fill='#FCECEF'
      />
      <path
        d='M40.833 34.1719C39.9401 33.2307 38.4577 33.1776 37.4999 34.053L29.8813 41.3142L26.6673 37.9811C25.7744 37.0398 24.292 36.9867 23.3342 37.8621C22.4121 38.8282 22.4121 40.3482 23.3342 41.3142L28.2146 46.3139C28.6371 46.7869 29.2472 47.0485 29.8811 47.0282C30.5091 47.0193 31.1081 46.7625 31.5476 46.3139L40.8326 37.5051C41.7531 36.6607 41.8146 35.2298 40.97 34.3095C40.9267 34.2615 40.8809 34.2157 40.833 34.1719Z'
        fill='#FCECEF'
      />
      <path
        d='M74.9978 39.2905H47.6191C46.3041 39.2905 45.2383 40.3564 45.2383 41.6713C45.2383 42.9862 46.3041 44.0521 47.6191 44.0521H74.9978C76.3127 44.0521 77.3786 42.9862 77.3786 41.6713C77.3786 40.3564 76.3127 39.2905 74.9978 39.2905Z'
        fill='#FCECEF'
      />
      <path
        d='M40.833 72.2641C39.9401 71.3229 38.4577 71.27 37.4999 72.1452L29.8813 79.4064L26.6673 76.0733C25.7744 75.132 24.292 75.0792 23.3342 75.9543C22.4121 76.9204 22.4121 78.4404 23.3342 79.4064L28.2146 84.4061C28.6371 84.8791 29.2472 85.1407 29.8811 85.1204C30.5091 85.1115 31.1081 84.8547 31.5476 84.4061L40.8326 75.5973C41.7531 74.7529 41.8146 73.322 40.97 72.4017C40.9267 72.3539 40.8809 72.3081 40.833 72.2641Z'
        fill='#FCECEF'
      />
      <path
        d='M74.9978 77.3828H47.6191C46.3041 77.3828 45.2383 78.4487 45.2383 79.7636C45.2383 81.0785 46.3041 82.1444 47.6191 82.1444H74.9978C76.3127 82.1444 77.3786 81.0785 77.3786 79.7636C77.3786 78.4487 76.3127 77.3828 74.9978 77.3828Z'
        fill='#FCECEF'
      />
    </svg>
  );
}
