/* eslint-disable react-hooks/rules-of-hooks */
import AffiliatorLayout from 'pages/affiliator/layout';
import BrowseHistoryListScreen from 'pages/bookmark/browse-history';
import FollowListScreen from 'pages/bookmark/follow';
import FollowerListScreen from 'pages/bookmark/follower';
import LikeListScreen from 'pages/bookmark/like';
import SubscribeListScreen from 'pages/bookmark/subscribe';
import Calendar from 'pages/calendar';
// import CreatorConfirmChangeEmail from 'pages/creator/confirm-change-email';
import CreatorHomePage from 'pages/creator/layout/home-page';
import PlanList from 'pages/creator/layout/plan-list';
import FavoriteListPost from 'pages/favorite/ListPost';
import ViewOnTimeLine from 'pages/favorite/ViewOnTimeLine/ViewOnTimeLine';
import ListHelpScreen from 'pages/help-screen/ListHelpScreen';
import PostHelp from 'pages/help-screen/PostHelp';
import ListPostBuyed from 'pages/list-post-buyed';
import ListFanRankingBuyerOfCreator from 'pages/list-ranking-buyed';
import List from 'pages/lives/List';
import { LivePending } from 'pages/lives/LivePending';
import FanCreateMessage from 'pages/message/createMessage';
import ListMessage from 'pages/message/ListMessage';
import MessageDetail from 'pages/message/MessageDetail';
import PostDetailScreen from 'pages/my-page/detail';
import NotificationScreen from 'pages/notification';
import NotificationDetailScreen from 'pages/notification/detail';
import SettingNotificationScreen from 'pages/notification/setting';
import SettingLanguage from 'pages/notification/setting/language';
import PostReport from 'pages/post-report';
import ListPurchaseScreen from 'pages/purchase';
import PaymentScreen from 'pages/purchase/payment';
import PaymentSuccessScreen from 'pages/purchase/payment/PaymentSuccess';
import Search from 'pages/search';
import SurveyDetailScreen from 'pages/survey/detail';
import ChangeEmail from 'pages/user/changEmail';
import ChangePassword from 'pages/user/changePassword';
import ConfirmNewEmail from 'pages/user/confirmNewEmail';
import Profile from 'pages/user/profile';
import RequestLeave from 'pages/user/requestLeave';
import TranferAccountInfor from 'pages/user/tranferAccountInfor';
import Timeline from 'pages/timeline';
import React from 'react';

export const FAN_ROUTES = [
  { path: '/my-page', element: <Timeline isNormal/> },
  { path: '/search-type', element: <Search /> },
  { path: '/survey/:id', element: <SurveyDetailScreen /> },
  { path: '/post/:id', element: <PostDetailScreen /> },
  { path: '/calendar', element: <Calendar /> },
  { path: '/purchase', element: <ListPurchaseScreen /> },
  { path: '/payment', element: <PaymentScreen /> },
  { path: '/payment-success', element: <PaymentSuccessScreen /> },
  { path: '/follow-list', element: <FollowListScreen /> },
  { path: '/follower-list', element: <FollowerListScreen /> },
  { path: '/subscribe-list', element: <SubscribeListScreen /> },
  { path: '/like-list', element: <LikeListScreen /> },
  { path: '/browse-history-list', element: <BrowseHistoryListScreen /> },
  { path: '/notification', element: <NotificationScreen /> },
  { path: '/notification/:id', element: <NotificationDetailScreen /> },
  { path: '/notification/setting', element: <SettingNotificationScreen /> },
  { path: '/message', element: <ListMessage /> },
  { path: '/message/:id', element: <MessageDetail /> },
  { path: '/message/create', element: <FanCreateMessage /> },
  { path: '/help', element: <ListHelpScreen /> },
  { path: '/help/post', element: <PostHelp /> },
  { path: '/lives/list/:type', element: <List /> },
  { path: '/favorite/view-on-timeline', element: <ViewOnTimeLine /> },
  { path: '/favorite/list-post', element: <FavoriteListPost /> },
  { path: '/post-report', element: <PostReport /> },
  { path: '/user/profile', element: <Profile /> },
  { path: '/user/profile/change-password', element: <ChangePassword /> },
  { path: '/user/profile/transfer-account-infor', element: <TranferAccountInfor /> },
  { path: '/user/change-email/:token', element: <ChangeEmail /> },
  { path: '/user/confirm-email/:token', element: <ConfirmNewEmail /> },
  { path: '/user/request-leave', element: <RequestLeave /> },
  { path: '/list-post-buyed', element: <ListPostBuyed /> },
  { path: '/ranking-buyer/:account_id', element: <ListFanRankingBuyerOfCreator /> },
  { path: '/language/setting', element: <SettingLanguage /> },
  { path: '/home-page/:account_id', element: <CreatorHomePage /> },
  { path: '/home-page/:creator_id/plan-list', element: <PlanList /> },
  { path: '/affiliator/*', element: <AffiliatorLayout /> },
  { path: '/lives/pending/:liveKey', element: <LivePending /> },
  { path: '/timeline', element: <Timeline /> },
];
