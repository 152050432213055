import ForgotPassword from './ForgotPassword';

const Index = () => {
  const rootClass = 'forgot-password-page';

  return (
    <div className={`${rootClass} h-100`}>
      <ForgotPassword />
    </div>
  );
};

export default Index;
