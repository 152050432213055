import CrossIcon from 'images/CrossIcon';
import { ImgCustom } from '..';
import { onErrImage } from '../../utils/utils';
import ReactPlayer from 'react-player';
const ImagesGallery = ({ medias, handleDeleteFile, acceptImage }) => {
  const onDeleteFile = (e, isImage) => {
    handleDeleteFile(e, isImage);
  };
  return (
    <div className='file-multiple-selected'>
      <div className='file-selected-row scroll-bar-custom'>
        {medias.map((media, index) => {
          if (!media) return null;
          return (
            <div key={index} className='file-selected-item mt-3'>
              <div className='file-selected-item-inner'>
                {media?.isImage ? (
                  <ImgCustom
                    className='selected-image'
                    src={media.url}
                    alt=''
                    onError={(e) => onErrImage(e, false)}
                  />
                ) : (
                  <ReactPlayer
                    className='selected-image'
                    width='100%'
                    height='100%'
                    controls
                    config={{
                      attributes: {
                        disablePictureInPicture: false,
                        controlsList: 'nodownload',
                        onContextMenu: e => e.preventDefault(),
                      },
                    }}
                    url={media.url}
                  />
                )}
                <CrossIcon
                  className='delete-file-selected'
                  onClick={() => onDeleteFile(index, media?.isImage)}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ImagesGallery;
