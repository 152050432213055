import { ReactCalendar, LayoutCreatorRight, PopupLoading } from 'components';
import React, { useState } from 'react';
import './styles.scss';
import { isMappable } from '../../../../../utils/utils';
import { useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { clearListSurvey, creatorPostSurvey } from 'store/actions/survey';
import useCalendar from 'hooks/useCalendar';
import { PlusOutlined } from '@ant-design/icons';
import withCreatorRole from 'hocs/withCreatorRole';
import PopupConfirmDelete from 'components/PopupConfirmDelete';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { newPathUser } from 'constants/layout/constant';
import VerticalIcon from 'images/VerticalIcon';
import DeleteIcon from 'images/DeleteIcon';
import ClockIconV2 from 'images/ClockIconV2';
import CalendarIconV2 from 'images/CalendarIconV2';
import ICMoreIcon from 'images/IcMoreIcon';
import ICCloseIcon from 'images/IcCloseIcon';
import ICCopyUpIcon from 'images/IcCopyUp';
import { useClickOutSize } from 'hooks/v2/clickOutSide';

const typeAnswer = {
  CHECKBOX: 1,
  OTHER: 2,
};

const PostSurveyScreen = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.survey.loading);
  const { t } = useTranslation();
  const { date, toggleCalendar, onChangeDate, isVisibleCalendar } = useCalendar();
  const location = useLocation();
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [isOpenCustomTime, setIsOpenCustomTime] = useState(false);
  const [valueTime, setValueTime] = useState('');
  const [countAnswer, setCountAnswer] = useState(0);

  const [refOutSide] = useClickOutSize(() => {
    setIsOpenCustomTime(false);
  });

  const {
    getValues,
    register,
    unregister,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      questions: [
        {
          title: '',
          hasOther: false,
          answer: [
            {
              title: '',
              type: typeAnswer.CHECKBOX,
              id: countAnswer,
            },
          ],
        },
      ],
    },
  });

  const navigate = useNavigate();

  const { fields, append, remove, swap, update } = useFieldArray({
    control,
    name: 'questions',
  });

  const watchTitle = watch('title', '');
  const watchQuestions = watch('questions');

  const handleCustomTime = () => {
    setIsOpenCustomTime(false);
    const time = `${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}`;
    setValueTime(time);
    setValue('time', time);
  };

  const onSubmit = async (data) => {
    setShowPopupConfirm(false);
    setIsLoading(true);

    const endDate = date + ' ' + data.time;
    const endDateUTC = moment.utc(moment(endDate).utc()).format('YYYY-MM-DD HH:mm');
    try {
      const arr = {
        title: data.title,
        description: data.description,
        end_date: endDateUTC,
        questions: data.questions.map((question) => {
          return {
            title: question.title,
            answers: question.answer.filter((j) => j !== null),
          };
        }),
      };
      dispatch(creatorPostSurvey(arr, handleSuccess, () => setIsLoading(false)));
    } catch (err) {
      console.log(err);
    }
  };

  const handleSuccess = ({ success }) => {
    setIsLoading(false);
    dispatch(clearListSurvey());
    if (location?.state?.back === 'message') navigate(`${newPathUser}message/create`);
    else if (success) navigate(`${newPathUser}surveys`);
  };

  const dragEnd = (result) => {
    const questions = [...fields];

    const [newQuestions] = questions.splice(result.source.index, 1);
    questions.splice(result.destination.index, 0, newQuestions);

    if (result.source.index === result.destination.index) return;
    swap(result.source.index, result.destination.index);

    // setValue('questions', questions);
  };

  const dragEndAnswer = (result, array, index) => {
    const answers = [...array];

    const [newAnswers] = answers.splice(result.source.index, 1);
    answers.splice(result.destination.index, 0, newAnswers);

    setValue(`questions.${index}.answer`, answers);
  };

  const questionValid =
    isMappable(watchQuestions) && watchQuestions?.find((ques) => !ques?.title?.trim());
  const answerValid =
    isMappable(watchQuestions) &&
    watchQuestions?.find((ques) => {
      return ques?.answer.find((ans) => !ans?.title?.trim() && ans?.type === 1);
    });
  const isDisabledSubmit =
    !watchTitle.trim() ||
    !date ||
    date === '' ||
    valueTime === '' ||
    !isMappable(watchQuestions) ||
    questionValid ||
    questionValid ||
    answerValid;

  return (
    <>
      <LayoutCreatorRight
        titlePage={t('Creator.Post.messageQuestionnaireCreation')}
        className='create-survey-layout'>
        {showPopupConfirm && (
          <PopupConfirmDelete
            title={t('Popup.confirmCreateSurvey')}
            setShowPopup={setShowPopupConfirm}
            handleOnSubmit={handleSubmit(onSubmit)}
          />
        )}
        {/* Dan - update 23-3-2022 them loading */}
        {loading && <PopupLoading className='popup-loading' />}
        <div className='container-fluid w-100 bg-white post-survey-page'>
          <form className='form' onSubmit={(e) => e.preventDefault()}>
            <div className='survey-box'>
              <div className='title mb-3'>
                <label>{t('Creator.Post.enterTheSurveyTitle')}</label>
                <span className='fansite-form-input__required'>*</span>
                <div>
                  <input
                    maxLength={100}
                    placeholder={t('Creator.Post.enterTheSurveyTitle')}
                    {...register(`title`, {
                      onBlur: (e) => (e.target.value = e.target.value.trim()),
                    })}
                  />
                </div>
              </div>
              <div className='title'>
                <label>{t('Creator.Post.enterQuestionnaire')}</label>
                <span className='fansite-form-input__required'>*</span>
                <div>
                  <textarea
                    maxLength={500}
                    placeholder={t('Creator.Post.upTo1024Characters')}
                    {...register(`description`, {
                      onBlur: (e) => (e.target.value = e.target.value.trim()),
                    })}
                  />
                </div>
              </div>
              {/* Date time */}
              <div className='w-100 mt-3 d-flex'>
                <div
                  className='date'
                  onClick={() => {
                    if (isOpenCustomTime) {
                      setIsOpenCustomTime(false);
                    }
                  }}>
                  <label>{t('Creator.Post.answerDeadlineSetting')}</label>
                  <span className='fansite-form-input__required'>*</span>
                  {isVisibleCalendar ? (
                    <CalendarIconV2 className='crp' color='#DC143C' />
                  ) : (
                    <CalendarIconV2
                      className='crp'
                      onClick={() => {
                        toggleCalendar();
                        setValue('date', date);
                      }}
                    />
                  )}
                  <input
                    placeholder='YYYY/MM/DD'
                    {...register('date')}
                    onClick={() => {
                      toggleCalendar();
                      setValue('date', date);
                    }}
                    value={date}
                    readOnly
                    role='button'
                  />
                  {isVisibleCalendar && (
                    <ReactCalendar
                      value={date ? moment(date, 'YYYY/MM/DD').toDate() : null}
                      minDate={new Date()}
                      onChange={onChangeDate}
                      toggleCalendar={toggleCalendar}
                    />
                  )}
                </div>
                <div className='time'>
                  <div
                    onClick={() => {
                      setIsOpenCustomTime(!isOpenCustomTime);
                      if (isVisibleCalendar) {
                        toggleCalendar();
                      }
                    }}>
                    <label class='invisible'>{t('Creator.Post.answerDeadlineSetting')}</label>
                    <input
                      type='text'
                      placeholder='--:--'
                      {...register('time')}
                      readOnly
                      value={valueTime}
                      className='crp'
                    />
                    <div className='time-icon'>
                      {isOpenCustomTime ? (
                        <ClockIconV2 className='crp' color='#DC143C' />
                      ) : (
                        <ClockIconV2
                          className='crp'
                          onClick={() => {
                            setIsOpenCustomTime(!isOpenCustomTime);
                            if (isVisibleCalendar) {
                              toggleCalendar();
                            }
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {isOpenCustomTime && (
                    <div className='custom-time-post' ref={refOutSide}>
                      <div className='d-flex'>
                        <div className='hour'>
                          {new Array(24).fill(1).map((v, i) => (
                            <div className='event-time' key={i}>
                              <div
                                className={`item item-hour ${hour === i && 'active'}`}
                                onClick={() => setHour(i)}>
                                {`${i}`.padStart(2, 0)}
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className='minute'>
                          {new Array(60).fill(1).map((v, i) => (
                            <div className='event-time' key={i}>
                              <div
                                className={`item item-minute ${minute === i && 'active'}`}
                                onClick={() => setMinute(i)}>
                                {`${i}`.padStart(2, 0)}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className='box-btn'>
                        <div className={`btn`} onClick={handleCustomTime}>
                          {t('Common.Ok')}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* List question */}
              <DragDropContext onDragEnd={(result) => dragEnd(result)}>
                <Droppable droppableId='listQuestion'>
                  {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {watchQuestions.map((field, index) => (
                        <Draggable
                          draggableId={`id-${index}`}
                          key={`id-${index}`}
                          index={index}>
                          {(provided) => (
                            <div
                              className='question-box'
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              key={index}>
                              <div className='text-center mb-2'>
                                <ICMoreIcon alt='ic-more' draggable={false} />
                              </div>
                              <div className='d-flex justify-content-center'>
                                <div className='question-title'>
                                  {t('Creator.Post.enterSurveyAnswer')}
                                </div>
                                <span className='fansite-form-input__required'>*</span>
                              </div>
                              <div className='question-header'>
                                <input
                                  className='input-title'
                                  placeholder={t(
                                    'Creator.Post.enterTheQuestionnaireQuestionText'
                                  )}
                                  maxLength={100}
                                  {...register(`questions.${index}.title`, {
                                    required: true,
                                    onBlur: (e) => (e.target.value = e.target.value.trim()),
                                  })}
                                />
                                {errors?.questions && errors?.questions[index]?.title && (
                                  <label className='error-message mt-2 ms-4'>
                                    {t('Creator.Survey.pleaseEnterYourQuestion')}
                                  </label>
                                )}
                              </div>
                              <hr className='hyphen' />
                              {/* List answer */}
                              <div className='answer'>
                                <label className='answer-title'>{t('Creator.Post.answer')}</label>
                                <span className='fansite-form-input__required'>*</span>
                                <DragDropContext
                                  onDragEnd={(result) =>
                                    dragEndAnswer(result, field?.answer, index)
                                  }>
                                  <Droppable droppableId='listAnswer'>
                                    {(answerProvided) => (
                                      <div
                                        className='list-answer'
                                        {...answerProvided?.droppableProps}
                                        ref={answerProvided?.innerRef}>
                                        {field?.answer
                                          // ?.sort((a, b) => a?.type?.localeCompare(b?.type)) Cho Other nằm cuối
                                          ?.map((v, i) => (
                                            <Draggable
                                              draggableId={`ans-${i}`}
                                              key={`ans-${i}`}
                                              index={i}>
                                              {(answerProvided) =>
                                                v !== null && (
                                                  <div
                                                    {...answerProvided.draggableProps}
                                                    {...answerProvided.dragHandleProps}
                                                    ref={answerProvided.innerRef}
                                                    key={v?.id}>
                                                    <div className='list-answer-item d-flex my-2'>
                                                      <VerticalIcon className='list-answer-drag' />
                                                      {v?.type === typeAnswer.CHECKBOX ? (
                                                        <input
                                                          className='list-answer-input'
                                                          placeholder={`${t(
                                                            'Creator.Post.answer'
                                                          )}${i + 1}`}
                                                          key={v?.id}
                                                          maxLength={255}
                                                          {...register(
                                                            `questions.${index}.answer.${i}.title`,
                                                            {
                                                              required: true,
                                                            }
                                                          )}
                                                        />
                                                      ) : (
                                                        <input
                                                          className='list-answer-input'
                                                          disabled
                                                          key={v?.id}
                                                          maxLength={255}
                                                          value={t('Survey.others')}
                                                        />
                                                      )}
                                                      {/* Remove answer */}
                                                      <ICCloseIcon
                                                        style={{
                                                          display:
                                                            field?.answer.length === 1
                                                              ? 'none'
                                                              : '',
                                                        }}
                                                        onClick={() => {
                                                          if (field?.answer.length === 1) {
                                                            return false;
                                                          }
                                                          if (v?.type === typeAnswer.OTHER) {
                                                            unregister(
                                                              `questions.${index}.answer.${i}`
                                                            );
                                                            setValue(
                                                              `questions.${index}.answer.${i}`,
                                                              null
                                                            );
                                                            setValue(
                                                              `questions.${index}.hasOther`,
                                                              false
                                                            );
                                                            update(index, {
                                                              title: field?.title,
                                                              hasOther:
                                                                getValues()?.questions[index]
                                                                  ?.hasOther,
                                                              answer: field.answer.filter(
                                                                (j) => j !== null
                                                              ),
                                                            });
                                                          } else {
                                                            unregister(
                                                              `questions.${index}.answer.${i}`
                                                            );
                                                            update(index, {
                                                              title: field?.title,
                                                              hasOther:
                                                                getValues()?.questions[index]
                                                                  ?.hasOther,
                                                              answer: field.answer.filter(
                                                                (j) => j !== null
                                                              ),
                                                            });
                                                          }
                                                        }}
                                                        alt='ic-close'
                                                      />
                                                    </div>
                                                    {errors?.questions &&
                                                      errors?.questions[index]?.answer &&
                                                      errors?.questions[index]?.answer[i]
                                                        ?.title && (
                                                        <label className='error-message mb-2 ms-4'>
                                                          {t(
                                                            'Creator.Survey.pleaseEnterYourSurveyResponses'
                                                          )}
                                                        </label>
                                                      )}
                                                  </div>
                                                )
                                              }
                                            </Draggable>
                                          ))}
                                        {/* Add option or other */}
                                        <div
                                          className='add-answer add-answer_other'
                                          draggable={false}>
                                          {getValues()?.questions[index]?.hasOther ===
                                            false && (
                                            <button
                                              onClick={() => {
                                                update(index, {
                                                  title: field?.title,
                                                  hasOther: true,
                                                  answer: [
                                                    ...field?.answer,
                                                    {
                                                      title: null,
                                                      type: typeAnswer.OTHER,
                                                    },
                                                  ],
                                                });
                                              }}>
                                              <PlusOutlined className='add-answer-icon_plus' />
                                              {t('Creator.Survey.add')}
                                            </button>
                                          )}
                                        </div>
                                        <div className='add-answer add-answer_new'>
                                          <button
                                            onClick={() => {
                                              update(index, {
                                                title: field?.title,
                                                hasOther:
                                                  getValues()?.questions[index]?.hasOther,
                                                answer: [
                                                  ...field?.answer,
                                                  {
                                                    title: '',
                                                    type: typeAnswer.CHECKBOX,
                                                    id: countAnswer + 1,
                                                  },
                                                ],
                                              });
                                              setCountAnswer(countAnswer + 1);
                                            }}>
                                            <PlusOutlined className='add-answer-icon_plus' />
                                            {t('Creator.Survey.addOption')}
                                          </button>
                                        </div>
                                        {answerProvided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </DragDropContext>
                              </div>
                              <div className='icon'>
                                <div
                                  className='me-3'
                                  onClick={() => {
                                    append(field);
                                  }}>
                                  <ICCopyUpIcon alt='ic-copy' />
                                </div>
                                <div
                                  onClick={() => remove(index)}
                                  style={{ position: 'relative', top: '-1px' }}>
                                  <DeleteIcon />
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <div className='question-add'>
                <button
                  onClick={() => {
                    append({
                      title: '',
                      hasOther: false,
                      answer: [
                        {
                          title: '',
                          type: 1,
                          id: 0,
                        },
                      ],
                    });
                  }}>
                  <PlusOutlined className='question-add-icon_plus' />
                  <span className='question-add-text'>{t('Creator.Survey.addNewSurvey')}</span>
                </button>
              </div>

              <div className='container-btn'>
                <button
                  className={`btn ${isDisabledSubmit ? 'disabled' : 'active'}`}
                  type='submit'
                  disabled={isDisabledSubmit}
                  onClick={() => setShowPopupConfirm(true)}>
                  {t('Creator.Survey.create')}
                </button>
                <button
                  className={`btn btn-back`}
                  type='button'
                  onClick={() => {
                    navigate(`${newPathUser}surveys`);
                  }}>
                  {t('Common.buttonCancel')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(PostSurveyScreen);
