import Icon from '@ant-design/icons';

const ImageIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        fill='none'
        viewBox='0 0 20 20'>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M19.21 5.899v8.151c0 3.02-1.89 5.15-4.91 5.15H5.65c-3.02 0-4.9-2.13-4.9-5.15V5.899C.75 2.879 2.64.75 5.65.75h8.65c3.02 0 4.91 2.129 4.91 5.149z'
          clipRule='evenodd'></path>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M3.281 14.43l1.528-1.613a1.401 1.401 0 011.98-.06l.937.952a1.434 1.434 0 002.027.02c.037-.036 2.334-2.822 2.334-2.822a1.687 1.687 0 012.375-.229c.048.04 2.218 2.267 2.218 2.267'></path>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M8.313 7.133a1.754 1.754 0 11-3.508 0 1.754 1.754 0 013.508 0z'
          clipRule='evenodd'></path>
      </svg>
    )}
    {...props}
  />
);

export default ImageIcon;
