export const LIST_COUNTRY_JA = [
  {
    id: 1,
    country_name: 'アフガニスタン',
    country_code: 'AFG',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 2,
    country_name: 'オーランド諸島',
    country_code: 'ALA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 3,
    country_name: 'アルバニア',
    country_code: 'ALB',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 4,
    country_name: 'アルジェリア',
    country_code: 'DZA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 5,
    country_name: '米サモア',
    country_code: 'ASM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 6,
    country_name: 'アンドラ',
    country_code: 'AND',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 7,
    country_name: 'アンゴラ',
    country_code: 'AGO',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 8,
    country_name: 'アングィラ',
    country_code: 'AIA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 9,
    country_name: '南極大陸',
    country_code: 'ATA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 10,
    country_name: 'アンチグアバーブーダ',
    country_code: 'ATG',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 11,
    country_name: 'アルゼンチン',
    country_code: 'ARG',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 12,
    country_name: 'アルメニア',
    country_code: 'ARM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 13,
    country_name: 'アルバ',
    country_code: 'ABW',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 14,
    country_name: 'オーストラリア',
    country_code: 'AUS',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 15,
    country_name: 'オーストリア',
    country_code: 'AUT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 16,
    country_name: 'アゼルバイジャン',
    country_code: 'AZE',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 17,
    country_name: 'バハマ',
    country_code: 'BHS',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 18,
    country_name: 'バーレーン',
    country_code: 'BHR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 19,
    country_name: 'バングラデシュ',
    country_code: 'BGD',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 20,
    country_name: 'バルバドス',
    country_code: 'BRB',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 21,
    country_name: 'ベラルーシ',
    country_code: 'BLR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 22,
    country_name: 'ベルギー',
    country_code: 'BEL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 23,
    country_name: 'ベリーズ',
    country_code: 'BLZ',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 24,
    country_name: 'ベナン',
    country_code: 'BEN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 25,
    country_name: 'バミューダ',
    country_code: 'BMU',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 26,
    country_name: 'ブータン',
    country_code: 'BTN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 27,
    country_name: 'ボリビア',
    country_code: 'BOL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 28,
    country_name: 'ボスニア·ヘルツェゴビナ',
    country_code: 'BIH',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 29,
    country_name: 'ボツワナ',
    country_code: 'BWA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 30,
    country_name: 'ブーベ島',
    country_code: 'BVT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 31,
    country_name: 'ブラジル',
    country_code: 'BRA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 32,
    country_name: 'イギリス領インド洋地域',
    country_code: 'IOT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 33,
    country_name: 'ブルネイ·ダルサラーム国',
    country_code: 'BRN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 34,
    country_name: 'ブルガリア',
    country_code: 'BGR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 35,
    country_name: 'ブルキナファソ',
    country_code: 'BFA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 36,
    country_name: 'ブルンジ',
    country_code: 'BDI',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 37,
    country_name: 'カンボジア',
    country_code: 'KHM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 38,
    country_name: 'カメルーン',
    country_code: 'CMR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 39,
    country_name: 'カナダ',
    country_code: 'CAN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 40,
    country_name: 'カーボベルデ',
    country_code: 'CPV',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 41,
    country_name: 'ケイマン諸島',
    country_code: 'CYM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 42,
    country_name: '中央アフリカ共和国',
    country_code: 'CAF',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 43,
    country_name: 'チャド',
    country_code: 'TCD',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 44,
    country_name: 'チリ',
    country_code: 'CHL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 45,
    country_name: '中国',
    country_code: 'CHN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 46,
    country_name: 'クリスマス島',
    country_code: 'CXR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 47,
    country_name: 'ココス（キーリング）諸島',
    country_code: 'CCK',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 48,
    country_name: 'コロンビア',
    country_code: 'COL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 49,
    country_name: 'コモロ',
    country_code: 'COM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 50,
    country_name: 'コンゴ',
    country_code: 'COG',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 51,
    country_name: 'コンゴ民主共和国',
    country_code: 'COD',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 52,
    country_name: 'クック諸島',
    country_code: 'COK',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 53,
    country_name: 'コスタリカ',
    country_code: 'CRI',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 54,
    country_name: 'コートジボワール',
    country_code: 'CIV',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 55,
    country_name: 'クロアチア',
    country_code: 'HRV',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 56,
    country_name: 'キューバ',
    country_code: 'CUB',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 57,
    country_name: 'キプロス',
    country_code: 'CYP',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 58,
    country_name: 'チェコ共和国',
    country_code: 'CZE',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 59,
    country_name: 'デンマーク',
    country_code: 'DNK',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 60,
    country_name: 'ジブチ',
    country_code: 'DJI',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 61,
    country_name: 'ドミニカ',
    country_code: 'DMA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 62,
    country_name: 'ドミニカ共和国',
    country_code: 'DOM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 63,
    country_name: '東ティモール',
    country_code: 'TLS',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 64,
    country_name: 'エクアドル',
    country_code: 'ECU',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 65,
    country_name: 'エジプト',
    country_code: 'EGY',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 66,
    country_name: 'エルサルバドル',
    country_code: 'SLV',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 67,
    country_name: '赤道ギニア',
    country_code: 'GNQ',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 68,
    country_name: 'エリトリア',
    country_code: 'ERI',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 69,
    country_name: 'エストニア',
    country_code: 'EST',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 70,
    country_name: 'エチオピア',
    country_code: 'ETH',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 71,
    country_name: 'フォークランド諸島（マルビナス）',
    country_code: 'FLK',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 72,
    country_name: 'フェロー諸島',
    country_code: 'FRO',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 73,
    country_name: 'フィジー',
    country_code: 'FJI',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 74,
    country_name: 'フィンランド',
    country_code: 'FIN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 75,
    country_name: 'フランス',
    country_code: 'FRA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 76,
    country_name: '仏領ギアナ',
    country_code: 'GUF',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 77,
    country_name: 'フランス領ポリネシア',
    country_code: 'PYF',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 78,
    country_name: 'フランス領極南諸島',
    country_code: 'ATF',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 79,
    country_name: 'ガボン',
    country_code: 'GAB',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 80,
    country_name: 'ガンビア',
    country_code: 'GMB',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 81,
    country_name: 'グルジア',
    country_code: 'GEO',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 82,
    country_name: 'ドイツ',
    country_code: 'DEU',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 83,
    country_name: 'ガーナ',
    country_code: 'GHA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 84,
    country_name: 'ジブラルタル',
    country_code: 'GIB',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 85,
    country_name: 'ギリシャ',
    country_code: 'GRC',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 86,
    country_name: 'グリーンランド',
    country_code: 'GRL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 87,
    country_name: 'グレナダ',
    country_code: 'GRD',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 88,
    country_name: 'グアドループ島',
    country_code: 'GLP',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 89,
    country_name: 'グアム島',
    country_code: 'GUM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 90,
    country_name: 'グアテマラ',
    country_code: 'GTM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 91,
    country_name: 'ガーンジー島',
    country_code: 'GGY',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 92,
    country_name: 'ギニア',
    country_code: 'GIN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 93,
    country_name: 'ギニアビサウ',
    country_code: 'GNB',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 94,
    country_name: 'ガイアナ',
    country_code: 'GUY',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 95,
    country_name: 'ハイチ',
    country_code: 'HTI',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 96,
    country_name: 'ハード島·マクドナルド諸島',
    country_code: 'HMD',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 97,
    country_name: 'ローマ法王庁（バチカン市国）',
    country_code: 'VAT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 98,
    country_name: 'ホンジュラス',
    country_code: 'HND',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 99,
    country_name: '香港',
    country_code: 'HKG',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 100,
    country_name: 'ハンガリー',
    country_code: 'HUN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 101,
    country_name: 'アイスランド',
    country_code: 'ISL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 102,
    country_name: 'インド',
    country_code: 'IND',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 103,
    country_name: 'インドネシア',
    country_code: 'IDN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 104,
    country_name: 'イラン',
    country_code: 'IRN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 105,
    country_name: 'イラク',
    country_code: 'IRQ',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 106,
    country_name: 'アイルランド',
    country_code: 'IRL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 107,
    country_name: 'マン島',
    country_code: 'IMN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 108,
    country_name: 'イスラエル',
    country_code: 'ISR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 109,
    country_name: 'イタリア',
    country_code: 'ITA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 110,
    country_name: 'ジャマイカ',
    country_code: 'JAM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 111,
    country_name: '日本',
    country_code: 'JPN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 112,
    country_name: 'ジャージ',
    country_code: 'JEY',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 113,
    country_name: 'ヨルダン',
    country_code: 'JOR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 114,
    country_name: 'カザフスタン',
    country_code: 'KAZ',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 115,
    country_name: 'ケニア',
    country_code: 'KEN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 116,
    country_name: 'キリバス',
    country_code: 'KIR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 117,
    country_name: '朝鮮民主主義人民共和国',
    country_code: 'PRK',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 118,
    country_name: '大韓民国',
    country_code: 'KOR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 119,
    country_name: 'クウェート',
    country_code: 'KWT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 120,
    country_name: 'キルギスタン',
    country_code: 'KGZ',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 121,
    country_name: 'ラオス人民民主共和国',
    country_code: 'LAO',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 122,
    country_name: 'ラトビア',
    country_code: 'LVA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 123,
    country_name: 'レバノン',
    country_code: 'LBN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 124,
    country_name: 'レソト',
    country_code: 'LSO',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 125,
    country_name: 'リベリア',
    country_code: 'LBR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 126,
    country_name: 'リビア',
    country_code: 'LBY',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 127,
    country_name: 'リヒテンシュタイン',
    country_code: 'LIE',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 128,
    country_name: 'リトアニア',
    country_code: 'LTU',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 129,
    country_name: 'ルクセンブルク',
    country_code: 'LUX',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 130,
    country_name: 'マカオ',
    country_code: 'MAC',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 131,
    country_name: 'マケドニア旧ユーゴスラビア共和国',
    country_code: 'MKD',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 132,
    country_name: 'マダガスカル',
    country_code: 'MDG',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 133,
    country_name: 'マラウイ',
    country_code: 'MWI',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 134,
    country_name: 'マレーシア',
    country_code: 'MYS',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 135,
    country_name: 'モルディブ',
    country_code: 'MDV',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 136,
    country_name: 'マリ',
    country_code: 'MLI',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 137,
    country_name: 'マルタ',
    country_code: 'MLT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 138,
    country_name: 'マーシャル諸島',
    country_code: 'MHL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 139,
    country_name: 'マルティニーク島',
    country_code: 'MTQ',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 140,
    country_name: 'モーリタニア',
    country_code: 'MRT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 141,
    country_name: 'モーリシャス',
    country_code: 'MUS',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 142,
    country_name: 'マヨット島',
    country_code: 'MYT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 143,
    country_name: 'メキシコ',
    country_code: 'MEX',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 144,
    country_name: 'ミクロネシア連邦',
    country_code: 'FSM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 145,
    country_name: 'モルドバ共和国',
    country_code: 'MDA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 146,
    country_name: 'モナコ',
    country_code: 'MCO',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 147,
    country_name: 'モンゴル国',
    country_code: 'MNG',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 148,
    country_name: 'モントセラト',
    country_code: 'MSR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 149,
    country_name: 'モロッコ',
    country_code: 'MAR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 150,
    country_name: 'モザンビーク',
    country_code: 'MOZ',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 151,
    country_name: 'ミャンマー',
    country_code: 'MMR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 152,
    country_name: 'ナミビア',
    country_code: 'NAM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 153,
    country_name: 'ナウル',
    country_code: 'NRU',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 154,
    country_name: 'ネパール',
    country_code: 'NPL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 155,
    country_name: 'オランダ',
    country_code: 'NLD',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 156,
    country_name: 'オランダ領アンティル',
    country_code: 'ANT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 157,
    country_name: 'ニューカレドニア',
    country_code: 'NCL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 158,
    country_name: 'ニュージーランド',
    country_code: 'NZL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 159,
    country_name: 'ニカラグア',
    country_code: 'NIC',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 160,
    country_name: 'ニジェール',
    country_code: 'NER',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 161,
    country_name: 'ナイジェリア',
    country_code: 'NGA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 162,
    country_name: 'ニウエ',
    country_code: 'NIU',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 163,
    country_name: 'ノーフォーク島',
    country_code: 'NFK',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 164,
    country_name: '北マリアナ諸島連邦',
    country_code: 'MNP',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 165,
    country_name: 'ノルウェー',
    country_code: 'NOR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 166,
    country_name: 'オマーン',
    country_code: 'OMN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 167,
    country_name: 'パキスタン',
    country_code: 'PAK',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 168,
    country_name: 'パラオ',
    country_code: 'PLW',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 169,
    country_name: 'パレスチナ',
    country_code: 'PSE',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 170,
    country_name: 'パナマ',
    country_code: 'PAN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 171,
    country_name: 'パプアニューギニア',
    country_code: 'PNG',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 172,
    country_name: 'パラグアイ',
    country_code: 'PRY',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 173,
    country_name: 'ペルー',
    country_code: 'PER',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 174,
    country_name: 'フィリピン',
    country_code: 'PHL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 175,
    country_name: 'ピトケアン',
    country_code: 'PCN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 176,
    country_name: 'ポーランド',
    country_code: 'POL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 177,
    country_name: 'ポルトガル',
    country_code: 'PRT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 178,
    country_name: 'プエルトリコ',
    country_code: 'PRI',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 179,
    country_name: 'カタール',
    country_code: 'QAT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 180,
    country_name: 'リユニオン',
    country_code: 'REU',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 181,
    country_name: 'ルーマニア',
    country_code: 'ROU',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 182,
    country_name: 'ロシア',
    country_code: 'RUS',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 183,
    country_name: 'ルワンダ',
    country_code: 'RWA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 184,
    country_name: 'セント·ヘレナ島',
    country_code: 'SHN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 185,
    country_name: 'セントクリストファーネイビス',
    country_code: 'KNA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 186,
    country_name: 'セントルシア',
    country_code: 'LCA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 187,
    country_name: 'サンピエール島·ミクロン島',
    country_code: 'SPM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 188,
    country_name: 'セントビンセント·グレナディーン諸島',
    country_code: 'VCT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 189,
    country_name: 'サモア',
    country_code: 'WSM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 190,
    country_name: 'サン·マリノ',
    country_code: 'SMR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 191,
    country_name: 'サントメプリンシペ',
    country_code: 'STP',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 192,
    country_name: 'サウジアラビア',
    country_code: 'SAU',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 193,
    country_name: 'セネガル',
    country_code: 'SEN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 194,
    country_name: 'セルビア·モンテネグロ',
    country_code: 'SCG',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 195,
    country_name: 'セイシェル',
    country_code: 'SYC',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 196,
    country_name: 'シエラレオネ',
    country_code: 'SLE',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 197,
    country_name: 'シンガポール',
    country_code: 'SGP',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 198,
    country_name: 'スロバキア',
    country_code: 'SVK',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 199,
    country_name: 'スロベニア',
    country_code: 'SVN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 200,
    country_name: 'ソロモン諸島',
    country_code: 'SLB',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 201,
    country_name: 'ソマリア',
    country_code: 'SOM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 202,
    country_name: '南アフリカ',
    country_code: 'ZAF',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 203,
    country_name: '南ジョージア島·南サンドイッチ諸島',
    country_code: 'SGS',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 204,
    country_name: 'スペイン',
    country_code: 'ESP',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 205,
    country_name: 'スリ·ランカ',
    country_code: 'LKA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 206,
    country_name: 'スーダン',
    country_code: 'SDN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 207,
    country_name: 'スリナム',
    country_code: 'SUR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 208,
    country_name: 'スバールバル諸島·ヤンマイエン島',
    country_code: 'SJM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 209,
    country_name: 'スワジランド',
    country_code: 'SWZ',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 210,
    country_name: 'スウェーデン',
    country_code: 'SWE',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 211,
    country_name: 'スイス',
    country_code: 'CHE',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 212,
    country_name: 'シリアアラブ共和国',
    country_code: 'SYR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 213,
    country_name: '台湾',
    country_code: 'TWN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 214,
    country_name: 'タジキスタン',
    country_code: 'TJK',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 215,
    country_name: 'タンザニア連合共和国',
    country_code: 'TZA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 216,
    country_name: 'タイ',
    country_code: 'THA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 217,
    country_name: 'トーゴ',
    country_code: 'TGO',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 218,
    country_name: 'トケラウ',
    country_code: 'TKL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 219,
    country_name: 'トンガ',
    country_code: 'TON',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 220,
    country_name: 'トリニダード·トバゴ',
    country_code: 'TTO',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 221,
    country_name: 'チュニジア',
    country_code: 'TUN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 222,
    country_name: 'トルコ',
    country_code: 'TUR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 223,
    country_name: 'トルクメニスタン',
    country_code: 'TKM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 224,
    country_name: 'タークス·カイコス諸島',
    country_code: 'TCA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 225,
    country_name: 'ツバル',
    country_code: 'TUV',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 226,
    country_name: 'ウガンダ',
    country_code: 'UGA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 227,
    country_name: 'ウクライナ',
    country_code: 'UKR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 228,
    country_name: 'アラブ首長国連邦',
    country_code: 'ARE',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 229,
    country_name: 'イギリス',
    country_code: 'GBR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 230,
    country_name: '米国',
    country_code: 'USA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 231,
    country_name: 'アメリカ合衆国外諸島',
    country_code: 'UMI',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 232,
    country_name: 'ウルグアイ',
    country_code: 'URY',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 233,
    country_name: 'ウズベキスタン',
    country_code: 'UZB',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 234,
    country_name: 'バヌアツ',
    country_code: 'VUT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 235,
    country_name: 'ベネズエラ',
    country_code: 'VEN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 236,
    country_name: 'ベトナム',
    country_code: 'VNM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 237,
    country_name: '英領バージン諸島',
    country_code: 'VGB',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 238,
    country_name: '米領バージン諸島',
    country_code: 'VIR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 239,
    country_name: 'ワリー·エ·フトゥーナ',
    country_code: 'WLF',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 240,
    country_name: '西サハラ',
    country_code: 'ESH',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 241,
    country_name: 'イエメン',
    country_code: 'YEM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 242,
    country_name: 'ザンビア',
    country_code: 'ZMB',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 243,
    country_name: 'ジンバブエ',
    country_code: 'ZWE',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
];

export const LIST_COUNTRY_EN = [
  {
    id: 1,
    country_name: 'Afghanistan',
    country_code: 'AFG',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 2,
    country_name: 'Aland Islands',
    country_code: 'ALA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 3,
    country_name: 'Albania',
    country_code: 'ALB',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 4,
    country_name: 'Algeria',
    country_code: 'DZA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 5,
    country_name: 'American Samoa',
    country_code: 'ASM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 6,
    country_name: 'Andorra',
    country_code: 'AND',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 7,
    country_name: 'Angola',
    country_code: 'AGO',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 8,
    country_name: 'Anguilla',
    country_code: 'AIA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 9,
    country_name: 'Antarctica',
    country_code: 'ATA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 10,
    country_name: 'Antigua and Barbuda',
    country_code: 'ATG',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 11,
    country_name: 'Argentina',
    country_code: 'ARG',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 12,
    country_name: 'Armenia',
    country_code: 'ARM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 13,
    country_name: 'Aruba',
    country_code: 'ABW',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 14,
    country_name: 'Australia',
    country_code: 'AUS',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 15,
    country_name: 'Austria',
    country_code: 'AUT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 16,
    country_name: 'Azerbaijan',
    country_code: 'AZE',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 17,
    country_name: 'Bahamas',
    country_code: 'BHS',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 18,
    country_name: 'Bahrain',
    country_code: 'BHR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 19,
    country_name: 'Bangladesh',
    country_code: 'BGD',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 20,
    country_name: 'Barbados',
    country_code: 'BRB',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 21,
    country_name: 'Belarus',
    country_code: 'BLR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 22,
    country_name: 'Belgium',
    country_code: 'BEL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 23,
    country_name: 'Belize',
    country_code: 'BLZ',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 24,
    country_name: 'Benin',
    country_code: 'BEN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 25,
    country_name: 'Bermuda',
    country_code: 'BMU',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 26,
    country_name: 'Bhutan',
    country_code: 'BTN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 27,
    country_name: 'Bolivia',
    country_code: 'BOL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 28,
    country_name: 'Bosnia and Herzegovina',
    country_code: 'BIH',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 29,
    country_name: 'Botswana',
    country_code: 'BWA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 30,
    country_name: 'Bouvet Island',
    country_code: 'BVT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 31,
    country_name: 'Brazil',
    country_code: 'BRA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 32,
    country_name: 'British Indian Ocean Territory',
    country_code: 'IOT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 33,
    country_name: 'Brunei Darussalam',
    country_code: 'BRN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 34,
    country_name: 'Bulgaria',
    country_code: 'BGR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 35,
    country_name: 'Burkina Faso',
    country_code: 'BFA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 36,
    country_name: 'Burundi',
    country_code: 'BDI',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 37,
    country_name: 'Cambodia',
    country_code: 'KHM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 38,
    country_name: 'Cameroon',
    country_code: 'CMR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 39,
    country_name: 'Canada',
    country_code: 'CAN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 40,
    country_name: 'Cape Verde',
    country_code: 'CPV',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 41,
    country_name: 'Cayman Islands',
    country_code: 'CYM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 42,
    country_name: 'Central African Republic',
    country_code: 'CAF',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 43,
    country_name: 'Chad',
    country_code: 'TCD',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 44,
    country_name: 'Chile',
    country_code: 'CHL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 45,
    country_name: 'China',
    country_code: 'CHN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 46,
    country_name: 'Christmas Island',
    country_code: 'CXR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 47,
    country_name: 'Cocos (Keeling) Islands',
    country_code: 'CCK',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 48,
    country_name: 'Colombia',
    country_code: 'COL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 49,
    country_name: 'Comoros',
    country_code: 'COM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 50,
    country_name: 'Congo',
    country_code: 'COG',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 51,
    country_name: 'Congo, The Democratic Republic Of The',
    country_code: 'COD',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 52,
    country_name: 'Cook Islands',
    country_code: 'COK',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 53,
    country_name: 'Costa Rica',
    country_code: 'CRI',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 54,
    country_name: "Cote D'Ivoire",
    country_code: 'CIV',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 55,
    country_name: 'Croatia',
    country_code: 'HRV',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 56,
    country_name: 'Cuba',
    country_code: 'CUB',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 57,
    country_name: 'Cyprus',
    country_code: 'CYP',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 58,
    country_name: 'Czech Republic',
    country_code: 'CZE',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 59,
    country_name: 'Denmark',
    country_code: 'DNK',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 60,
    country_name: 'Djibouti',
    country_code: 'DJI',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 61,
    country_name: 'Dominica',
    country_code: 'DMA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 62,
    country_name: 'Dominican Republic',
    country_code: 'DOM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 63,
    country_name: 'East Timor',
    country_code: 'TLS',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 64,
    country_name: 'Ecuador',
    country_code: 'ECU',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 65,
    country_name: 'Egypt',
    country_code: 'EGY',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 66,
    country_name: 'El Salvador',
    country_code: 'SLV',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 67,
    country_name: 'Equatorial Guinea',
    country_code: 'GNQ',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 68,
    country_name: 'Eritrea',
    country_code: 'ERI',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 69,
    country_name: 'Estonia',
    country_code: 'EST',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 70,
    country_name: 'Ethiopia',
    country_code: 'ETH',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 71,
    country_name: 'Falkland Islands (Malvinas)',
    country_code: 'FLK',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 72,
    country_name: 'Faroe Islands',
    country_code: 'FRO',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 73,
    country_name: 'Fiji',
    country_code: 'FJI',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 74,
    country_name: 'Finland',
    country_code: 'FIN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 75,
    country_name: 'France',
    country_code: 'FRA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 76,
    country_name: 'French Guiana',
    country_code: 'GUF',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 77,
    country_name: 'French Polynesia',
    country_code: 'PYF',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 78,
    country_name: 'French Southern Territories',
    country_code: 'ATF',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 79,
    country_name: 'Gabon',
    country_code: 'GAB',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 80,
    country_name: 'Gambia',
    country_code: 'GMB',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 81,
    country_name: 'Georgia',
    country_code: 'GEO',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 82,
    country_name: 'Germany',
    country_code: 'DEU',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 83,
    country_name: 'Ghana',
    country_code: 'GHA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 84,
    country_name: 'Gibraltar',
    country_code: 'GIB',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 85,
    country_name: 'Greece',
    country_code: 'GRC',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 86,
    country_name: 'Greenland',
    country_code: 'GRL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 87,
    country_name: 'Grenada',
    country_code: 'GRD',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 88,
    country_name: 'Guadeloupe',
    country_code: 'GLP',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 89,
    country_name: 'Guam',
    country_code: 'GUM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 90,
    country_name: 'Guatemala',
    country_code: 'GTM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 91,
    country_name: 'Guernsey',
    country_code: 'GGY',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 92,
    country_name: 'Guinea',
    country_code: 'GIN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 93,
    country_name: 'Guinea-Bissau',
    country_code: 'GNB',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 94,
    country_name: 'Guyana',
    country_code: 'GUY',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 95,
    country_name: 'Haiti',
    country_code: 'HTI',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 96,
    country_name: 'Heard Island And McDonald Islands',
    country_code: 'HMD',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 97,
    country_name: 'Holy See (Vatican City State)',
    country_code: 'VAT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 98,
    country_name: 'Honduras',
    country_code: 'HND',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 99,
    country_name: 'Hong Kong',
    country_code: 'HKG',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 100,
    country_name: 'Hungary',
    country_code: 'HUN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 101,
    country_name: 'Iceland',
    country_code: 'ISL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 102,
    country_name: 'India',
    country_code: 'IND',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 103,
    country_name: 'Indonesia',
    country_code: 'IDN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 104,
    country_name: 'Iran, Islamic Republic Of',
    country_code: 'IRN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 105,
    country_name: 'Iraq',
    country_code: 'IRQ',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 106,
    country_name: 'Ireland',
    country_code: 'IRL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 107,
    country_name: 'Isle Of Man',
    country_code: 'IMN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 108,
    country_name: 'Israel',
    country_code: 'ISR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 109,
    country_name: 'Italy',
    country_code: 'ITA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 110,
    country_name: 'Jamaica',
    country_code: 'JAM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 111,
    country_name: 'Japan',
    country_code: 'JPN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 112,
    country_name: 'Jersey',
    country_code: 'JEY',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 113,
    country_name: 'Jordan',
    country_code: 'JOR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 114,
    country_name: 'Kazakhstan',
    country_code: 'KAZ',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 115,
    country_name: 'Kenya',
    country_code: 'KEN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 116,
    country_name: 'Kiribati',
    country_code: 'KIR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 117,
    country_name: "Korea, Democratic People's Republic Of",
    country_code: 'PRK',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 118,
    country_name: 'Korea, Republic Of',
    country_code: 'KOR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 119,
    country_name: 'Kuwait',
    country_code: 'KWT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 120,
    country_name: 'Kyrgyzstan',
    country_code: 'KGZ',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 121,
    country_name: "Lao People's Democratic Republic",
    country_code: 'LAO',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 122,
    country_name: 'Latvia',
    country_code: 'LVA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 123,
    country_name: 'Lebanon',
    country_code: 'LBN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 124,
    country_name: 'Lesotho',
    country_code: 'LSO',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 125,
    country_name: 'Liberia',
    country_code: 'LBR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 126,
    country_name: 'Libya',
    country_code: 'LBY',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 127,
    country_name: 'Liechtenstein',
    country_code: 'LIE',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 128,
    country_name: 'Lithuania',
    country_code: 'LTU',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 129,
    country_name: 'Luxembourg',
    country_code: 'LUX',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 130,
    country_name: 'Macau',
    country_code: 'MAC',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 131,
    country_name: 'Macedonia, The Former Yugoslav Republic Of',
    country_code: 'MKD',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 132,
    country_name: 'Madagascar',
    country_code: 'MDG',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 133,
    country_name: 'Malawi',
    country_code: 'MWI',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 134,
    country_name: 'Malaysia',
    country_code: 'MYS',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 135,
    country_name: 'Maldives',
    country_code: 'MDV',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 136,
    country_name: 'Mali',
    country_code: 'MLI',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 137,
    country_name: 'Malta',
    country_code: 'MLT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 138,
    country_name: 'Marshall Islands',
    country_code: 'MHL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 139,
    country_name: 'Martinique',
    country_code: 'MTQ',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 140,
    country_name: 'Mauritania',
    country_code: 'MRT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 141,
    country_name: 'Mauritius',
    country_code: 'MUS',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 142,
    country_name: 'Mayotte',
    country_code: 'MYT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 143,
    country_name: 'Mexico',
    country_code: 'MEX',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 144,
    country_name: 'Micronesia, Federated States Of',
    country_code: 'FSM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 145,
    country_name: 'Moldova, Republic Of',
    country_code: 'MDA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 146,
    country_name: 'Monaco',
    country_code: 'MCO',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 147,
    country_name: 'Mongolia',
    country_code: 'MNG',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 148,
    country_name: 'Montserrat',
    country_code: 'MSR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 149,
    country_name: 'Morocco',
    country_code: 'MAR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 150,
    country_name: 'Mozambique',
    country_code: 'MOZ',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 151,
    country_name: 'Myanmar',
    country_code: 'MMR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 152,
    country_name: 'Namibia',
    country_code: 'NAM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 153,
    country_name: 'Nauru',
    country_code: 'NRU',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 154,
    country_name: 'Nepal',
    country_code: 'NPL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 155,
    country_name: 'Netherlands',
    country_code: 'NLD',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 156,
    country_name: 'Netherlands Antilles',
    country_code: 'ANT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 157,
    country_name: 'New Caledonia',
    country_code: 'NCL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 158,
    country_name: 'New Zealand',
    country_code: 'NZL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 159,
    country_name: 'Nicaragua',
    country_code: 'NIC',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 160,
    country_name: 'Niger',
    country_code: 'NER',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 161,
    country_name: 'Nigeria',
    country_code: 'NGA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 162,
    country_name: 'Niue',
    country_code: 'NIU',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 163,
    country_name: 'Norfolk Island',
    country_code: 'NFK',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 164,
    country_name: 'Northern Mariana Islands',
    country_code: 'MNP',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 165,
    country_name: 'Norway',
    country_code: 'NOR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 166,
    country_name: 'Oman',
    country_code: 'OMN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 167,
    country_name: 'Pakistan',
    country_code: 'PAK',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 168,
    country_name: 'Palau',
    country_code: 'PLW',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 169,
    country_name: 'Palestine',
    country_code: 'PSE',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 170,
    country_name: 'Panama',
    country_code: 'PAN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 171,
    country_name: 'Papua New Guinea',
    country_code: 'PNG',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 172,
    country_name: 'Paraguay',
    country_code: 'PRY',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 173,
    country_name: 'Peru',
    country_code: 'PER',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 174,
    country_name: 'Philippines',
    country_code: 'PHL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 175,
    country_name: 'Pitcairn',
    country_code: 'PCN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 176,
    country_name: 'Poland',
    country_code: 'POL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 177,
    country_name: 'Portugal',
    country_code: 'PRT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 178,
    country_name: 'Puerto Rico',
    country_code: 'PRI',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 179,
    country_name: 'Qatar',
    country_code: 'QAT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 180,
    country_name: 'Reunion',
    country_code: 'REU',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 181,
    country_name: 'Romania',
    country_code: 'ROU',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 182,
    country_name: 'Russia',
    country_code: 'RUS',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 183,
    country_name: 'Rwanda',
    country_code: 'RWA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 184,
    country_name: 'Saint Helena',
    country_code: 'SHN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 185,
    country_name: 'Saint Kitts And Nevis',
    country_code: 'KNA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 186,
    country_name: 'Saint Lucia',
    country_code: 'LCA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 187,
    country_name: 'Saint Pierre And Miquelon',
    country_code: 'SPM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 188,
    country_name: 'Saint Vincent And The Grenadines',
    country_code: 'VCT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 189,
    country_name: 'Samoa',
    country_code: 'WSM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 190,
    country_name: 'San Marino',
    country_code: 'SMR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 191,
    country_name: 'Sao Tome And Principe',
    country_code: 'STP',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 192,
    country_name: 'Saudi Arabia',
    country_code: 'SAU',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 193,
    country_name: 'Senegal',
    country_code: 'SEN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 194,
    country_name: 'Serbia And Montenegro',
    country_code: 'SCG',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 195,
    country_name: 'Seychelles',
    country_code: 'SYC',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 196,
    country_name: 'Sierra Leone',
    country_code: 'SLE',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 197,
    country_name: 'Singapore',
    country_code: 'SGP',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 198,
    country_name: 'Slovakia',
    country_code: 'SVK',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 199,
    country_name: 'Slovenia',
    country_code: 'SVN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 200,
    country_name: 'Solomon Islands',
    country_code: 'SLB',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 201,
    country_name: 'Somalia',
    country_code: 'SOM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 202,
    country_name: 'South Africa',
    country_code: 'ZAF',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 203,
    country_name: 'South Georgia And The South Sandwich Islands',
    country_code: 'SGS',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 204,
    country_name: 'Spain',
    country_code: 'ESP',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 205,
    country_name: 'Sri Lanka',
    country_code: 'LKA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 206,
    country_name: 'Sudan',
    country_code: 'SDN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 207,
    country_name: 'Suriname',
    country_code: 'SUR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 208,
    country_name: 'Svalbard And Jan Mayen',
    country_code: 'SJM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 209,
    country_name: 'Swaziland',
    country_code: 'SWZ',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 210,
    country_name: 'Sweden',
    country_code: 'SWE',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 211,
    country_name: 'Switzerland',
    country_code: 'CHE',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 212,
    country_name: 'Syrian Arab Republic',
    country_code: 'SYR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 213,
    country_name: 'Taiwan',
    country_code: 'TWN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 214,
    country_name: 'Tajikistan',
    country_code: 'TJK',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 215,
    country_name: 'Tanzania, United Republic Of',
    country_code: 'TZA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 216,
    country_name: 'Thailand',
    country_code: 'THA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 217,
    country_name: 'Togo',
    country_code: 'TGO',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 218,
    country_name: 'Tokelau',
    country_code: 'TKL',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 219,
    country_name: 'Tonga',
    country_code: 'TON',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 220,
    country_name: 'Trinidad And Tobago',
    country_code: 'TTO',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 221,
    country_name: 'Tunisia',
    country_code: 'TUN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 222,
    country_name: 'Turkey',
    country_code: 'TUR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 223,
    country_name: 'Turkmenistan',
    country_code: 'TKM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 224,
    country_name: 'Turks And Caicos Islands',
    country_code: 'TCA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 225,
    country_name: 'Tuvalu',
    country_code: 'TUV',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 226,
    country_name: 'Uganda',
    country_code: 'UGA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 227,
    country_name: 'Ukraine',
    country_code: 'UKR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 228,
    country_name: 'United Arab Emirates',
    country_code: 'ARE',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 229,
    country_name: 'United Kingdom',
    country_code: 'GBR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 230,
    country_name: 'United States',
    country_code: 'USA',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 231,
    country_name: 'United States Minor Outlying Islands',
    country_code: 'UMI',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 232,
    country_name: 'Uruguay',
    country_code: 'URY',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 233,
    country_name: 'Uzbekistan',
    country_code: 'UZB',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 234,
    country_name: 'Vanuatu',
    country_code: 'VUT',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 235,
    country_name: 'Venezuela',
    country_code: 'VEN',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 236,
    country_name: 'Vietnam',
    country_code: 'VNM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 237,
    country_name: 'Virgin Islands, British',
    country_code: 'VGB',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 238,
    country_name: 'Virgin Islands, U.S.',
    country_code: 'VIR',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 239,
    country_name: 'Wallis And Futuna',
    country_code: 'WLF',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 240,
    country_name: 'Western Sahara',
    country_code: 'ESH',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 241,
    country_name: 'Yemen',
    country_code: 'YEM',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 242,
    country_name: 'Zambia',
    country_code: 'ZMB',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
  {
    id: 243,
    country_name: 'Zimbabwe',
    country_code: 'ZWE',
    status: 1,
    created_at: '2022-10-18 00:47:29',
    updated_at: '2022-10-18 00:47:29',
  },
];
