import Icon from '@ant-design/icons';

const ThreePointIconV2 = (props) => (
  <Icon
    component={() => (
      <svg
        width='3'
        height='13'
        viewBox='0 0 3 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx='1.5' cy='1.5' r='1.5' transform='rotate(90 1.5 1.5)' fill='#666565' />
        <circle cx='1.5' cy='6.5' r='1.5' transform='rotate(90 1.5 6.5)' fill='#666565' />
        <circle cx='1.5' cy='11.5' r='1.5' transform='rotate(90 1.5 11.5)' fill='#666565' />
      </svg>
    )}
    {...props}
  />
);

export default ThreePointIconV2;
