/* eslint-disable import/no-anonymous-default-export */

import { TYPES } from '../actions';
const initialState = {
  status: null,
  loading: false,
  error: null,
  data: {},
  pagination: {},
  surveys: [],
  idScrollBackSurvey: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_SURVEY_REQUEST:
    case TYPES.GET_LIST_SURVEY_REQUEST:
    case TYPES.CREATE_SURVEY_REQUEST:
    case TYPES.POST_SURVEY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.REP_SURVEY_ANSWER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.REP_SURVEY_ANSWER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case TYPES.REP_SURVEY_ANSWER_FAILED:
      return {
        ...state,
        error: action.data.error,
        loading: false,
      };
    case TYPES.DELETE_SURVEY_SUCCESS:
      state.surveys = state.surveys?.filter((e) => +e?.id !== +action.data?.id);
      return {
        ...state,
        loading: false,
        surveys: state?.surveys,
      };
    case TYPES.GET_LIST_SURVEY_SUCCESS:
      if (+action.data?.pagination?.current_page === 1) {
        return {
          ...state,
          loading: false,
          pagination: action.data?.pagination,
          surveys: action.data?.data,
        };
      }
      return {
        ...state,
        loading: false,
        pagination: action.data?.pagination,
        surveys: [...state?.surveys, ...action.data?.data],
      };
    case TYPES.GET_SURVEY_SUCCESS:
    case TYPES.CREATE_SURVEY_SUCCESS:
    case TYPES.POST_SURVEY_SUCCESS:
      return {
        ...state,
        data: action.data.data,
        loading: false,
      };
    case TYPES.GET_SURVEY_FAILED:
    case TYPES.CREATE_SURVEY_FAILED:
    case TYPES.POST_SURVEY_FAILED:
    case TYPES.GET_LIST_SURVEY_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };
    case 'SET_ID_SCROLL_BACK_SURVEY':
      return {
        ...state,
        idScrollBackSurvey: action.data?.data?.id || null,
      };
    case 'CLEAR_LIST_SURVEY':
      return {
        ...state,
        pagination: {},
        surveys: [],
        idScrollBackSurvey: null,
      };
    default:
      return state;
  }
};
