import {
  AvatarInfor,
  ButtonBookmark,
  ImgCustom,
  ListImgTimelinePostDetail,
  ReadMoreLess,
  VideoCustom,
  ViewFullImage,
} from 'components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { postBookMarkDetail } from 'store/actions/myPage';
import { IMG_NO_WATER_MARK, linkS3Video, decryptPath } from 'utils';
import { StyledPostDetail } from './styles';

const ContentShortType4 = ({ detailPost = {}, checkDisableWithType3And4 }) => {
  const dispatch = useDispatch();
  const [dataViewFull, setDataViewFull] = useState({});
  const { t } = useTranslation();
  const handlePostBookMark = () => {
    dispatch(postBookMarkDetail(detailPost?.id));
  };

  const fanBookmarkFunc = () => (
    <ButtonBookmark post={detailPost} onClick={handlePostBookMark} className={`custom`} />
  );
  return (
    <>
      {dataViewFull?.image?.url && (
        <ViewFullImage dataViewFull={dataViewFull} close={() => setDataViewFull({})} />
      )}

      <AvatarInfor
        className='avatar-info-detail'
        images={detailPost?.user?.avatar}
        name={detailPost?.user?.account_name || detailPost?.user?.username}
        content1={`${t('Common.id')}: ${detailPost?.user?.account_id}`}
      />

      <div className='content'>
        <div className='text'>
          <ReadMoreLess content={detailPost?.content} />
        </div>
      </div>
      <StyledPostDetail.Wrapper>
        {checkDisableWithType3And4 && (
          <StyledPostDetail.WrapperBlur>
            {t('DetailCard.notYetFollow')}
          </StyledPostDetail.WrapperBlur>
        )}
        {detailPost?.medias?.length > 0 && (
          <>
            {detailPost?.medias?.[0].type === 1 ? (
              <>
                {(() => {
                  const data = detailPost?.medias
                    ?.filter((item) => item?.type === 1)
                    .filter((item) => item.public_flag === 1);
                  return (
                    data.length > 0 && (
                      <StyledPostDetail.TimelineWrapper>
                        <ListImgTimelinePostDetail
                          data={data}
                          key={data?.id}
                          elementBookmark={fanBookmarkFunc()}
                        />
                      </StyledPostDetail.TimelineWrapper>
                    )
                  );
                })()}
              </>
            ) : (
              <>
                {detailPost.medias[0].public_flag === 1 ? (
                  <StyledPostDetail.VideoWrapper>
                    {fanBookmarkFunc()}
                    <VideoCustom
                      src={`${linkS3Video}${decryptPath(detailPost.medias[0].url) + '#t=0.1'}`}
                      handleNavigate={() => {}}
                      isDetailPost
                    />
                  </StyledPostDetail.VideoWrapper>
                ) : (
                  <ImgCustom
                    src={IMG_NO_WATER_MARK}
                    className='video-detail trailer_video img_no_water_mark'
                  />
                )}
              </>
            )}
          </>
        )}
      </StyledPostDetail.Wrapper>
    </>
  );
};

export default ContentShortType4;
