import { CardDetail } from 'components/DetailCard';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './index.scss';
import { Button } from 'pages/creator/layout/livestream/components/styled';
import { getListPostCreator, resetActionCreatorHomePage } from 'store/actions/creator';
import { useMemo } from 'react';
import { newPathUser } from 'constants/layout/constant';
import { ReadMoreLess } from 'components';
import MarkDownRead from 'components/MarkDownRead';

const ListContentCard = ({ handleEventFanQuitWhenSubcriber }) => {
  const [contentTab, setContentTab] = useState(3);
  // const [typeSearch, setTypeSearch] = useState(false);
  const { pagination, listPostCreator } = useSelector((state) => state.creator);
  const { userInfo } = useSelector((state) => state.lives);
  const { liveKey } = useParams();
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { data: userProfile } = useSelector((state) => state.users);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const tabs = [
    {
      label: `${t('HomePageContent.postNormalType3')}`,
      value: 3,
    },
    {
      label: `${t('HomePageContent.postShortType4')}`,
      value: 4,
    },
    {
      label: `${t('HomePageContent.postType1')}`,
      value: 1,
    },
    {
      label: `${t('HomePageContent.saleContentType2')}`,
      value: 2,
    },
  ];

  useEffect(() => {
    state?.tab && setContentTab(state?.tab);
  }, []);

  useEffect(() => {
    dispatch(resetActionCreatorHomePage());
    setPage(1);
  }, [contentTab]);

  useMemo(
    () => {
      userInfo?.livestream?.user?.account_id &&
        dispatch(
          getListPostCreator(
            {
              type: contentTab,
              page: page || 1,
              limit: itemsPerPage || 10,
              account_id: userInfo?.livestream?.user?.account_id || '',
              id: userProfile?.id,
            },
            false
          )
        );
      // return () => {
      //   setTypeSearch(true);
      // };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentTab, page, userProfile]
  );

  const fetchNext = () => {
    const pages = pagination?.total / pagination?.per_page;
    if (pages > page) {
      setPage(page + 1);
    }
  };
  const ref = useRef();

  return (
    <>
      <Button
        className={`subscribe-btn text-center text-white btn-sub-live`}
        onClick={() => handleEventFanQuitWhenSubcriber()}>
        {`${t('homePageHeader.subscribeHere')}`}
      </Button>
      <div className='live-content-description'>
        <MarkDownRead
          content={userInfo?.livestream?.live_description}
          charLimit={70}
          isMeta
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      </div>
    </>
  );
};

export { ListContentCard };
