import { PlusOutlined } from '@ant-design/icons';
import { LayoutCreatorRight, PopupLoading } from 'components';
import { pushNotify } from 'components/Notification';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import PopupConfirmDelete from 'components/PopupConfirmDelete';
import { newPathUser } from 'constants/layout/constant';
import withCreatorRole from 'hocs/withCreatorRole';
import { useGetIdFromRecommendUser } from 'hooks/v2/useGetIdFromRecommendUser';
import EmptySurveyIconV2 from 'images/EmptySurveyIcon';
import SurveyIconAnswered from 'images/SurveyIconAnswered';
import SurveyIconNotAnswer from 'images/SurveyIconNotAnswer';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { deleteSurvey, getListSurvey, setIdScrollBackSurvey } from 'store/actions/survey';
import './index.scss';

const ListSurvey = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { idFromRecommendUser, clearIdScrollBackRecommend } = useGetIdFromRecommendUser();

  const { surveys, idScrollBackSurvey, pagination } = useSelector((state) => state.survey);
  const [page, setPage] = useState(
    pagination?.current_page ? +pagination?.current_page + 1 : 1
  );
  const { t } = useTranslation();
  const [isScrollToTop, setIsScrollToTop] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingScroll, setLoadingScroll] = useState(false);
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [surveyDelete, setSurveyDelete] = useState(null);
  const { dataUser: dataProfile } = useSelector((state) => state.users);

  useEffect(() => {
    if (idScrollBackSurvey) {
      const idPost = document.getElementById(idScrollBackSurvey);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idScrollBackSurvey]);

  useEffect(() => {
    if (!idScrollBackSurvey && isScrollToTop) {
      const top = document.getElementById('app_cocofans_0');
      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
    }
  }, [idScrollBackSurvey, isScrollToTop]);

  useEffect(() => {
    if (!idScrollBackSurvey && !idFromRecommendUser) {
      if (page === 1) {
        setLoading(true);
      } else {
        setLoadingScroll(true);
      }
      dispatch(getListSurvey({ page }, handleListSurvey));
    }
  }, [page, idScrollBackSurvey, idFromRecommendUser, dispatch]);

  const isApproved = useMemo(() => {
    return dataProfile?.status === 1;
  }, [dataProfile?.status]);

  const handleListSurvey = () => {
    setLoading(false);
    setLoadingScroll(false);
  };

  const fetchNext = () => {
    const pages = pagination?.total / pagination?.per_page;
    if (pages > page) {
      setLoadingScroll(true);
      if (idScrollBackSurvey || idFromRecommendUser) {
        setIsScrollToTop(false);
        dispatch(setIdScrollBackSurvey());
        clearIdScrollBackRecommend();
      } else {
        setPage(page + 1);
      }
    } else if (pages + 1 > page) {
      if (idScrollBackSurvey || idFromRecommendUser) {
        setLoadingScroll(true);
        setIsScrollToTop(false);
        dispatch(setIdScrollBackSurvey());
        clearIdScrollBackRecommend();
      }
    }
  };

  const handleDelete = () => {
    setShowPopupConfirm(false);
    setIsLoading(true);
    dispatch(deleteSurvey(surveyDelete?.id, handleDeleteSurveySuccess));
  };

  const handleConfirmDeleteSurvey = (id, index) => {
    setSurveyDelete({ id, index });
    setShowPopupConfirm(true);
  };

  const handleDeleteSurveySuccess = () => {
    setSurveyDelete(null);
    setIsLoading(false);
  };

  const renderSurveyItem = (survey, index) => {
    return (
      <div id={`survey-${survey?.id}`} className='survey-item' key={survey?.id}>
        <div
          className='survey-item_info'
          onClick={() =>
            dispatch(
              setIdScrollBackSurvey(`survey-${survey?.id}`, () =>
                navigate(`${newPathUser}surveys/` + survey.id)
              )
            )
          }>
          <div className='survey-item_info_left'>
            {survey?.is_answered ? <SurveyIconAnswered /> : <SurveyIconNotAnswer />}
          </div>
          <div className='survey-item_info_right'>
            <p className='survey-item_info_right-title'>{survey?.title}</p>
            <p className='survey-item_info_right-description'>{survey?.description}</p>
          </div>
        </div>
        <div className='survey-item_action'>
          <div className='survey-item_action_up'>
            <p className='survey-item_action_up-time'>
              {moment.utc(survey?.created_at).local().format('YYYY/MM/DD')}
            </p>
          </div>
          <div className='survey-item_action_down'>
            <button
              disabled={survey?.is_answered}
              onClick={() =>
                dispatch(
                  setIdScrollBackSurvey(`survey-${survey?.id}`, () =>
                    navigate(`${newPathUser}surveys/edit/` + survey.id)
                  )
                )
              }
              className='btn btn-edit'>
              {t('Creator.Survey.edit')}
            </button>
            <button
              onClick={() => handleConfirmDeleteSurvey(survey.id, index)}
              className='btn btn-cancel'>
              {t('Creator.Survey.delete')}
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderlistSurvey = () => {
    return (
      <div className='survey-container_list'>
        {renderBtnAddNewSurvey()}
        {surveys?.map(renderSurveyItem)}
      </div>
    );
  };

  const renderEmptySurvey = () => {
    return (
      <div className='survey-container_empty'>
        <div className='survey-container_empty-icon'>
          <EmptySurveyIconV2 />
        </div>
        <div className='survey-container_empty-text'>{t('Survey.noData')}</div>
        {renderBtnAddNewSurvey()}
      </div>
    );
  };

  const renderBtnAddNewSurvey = () => {
    return (
      <div className='survey-add-new'>
        <button
          onClick={() => {
            if (isApproved) {
              dispatch(
                setIdScrollBackSurvey(`survey-create`, () =>
                  navigate(`${newPathUser}create-surveys`)
                )
              );
            } else {
              pushNotify('error', `${t('Creator.Message.featureNotAvailable')}`);
            }
          }}
          className='btn d-flex align-items-center'>
          <PlusOutlined />
          <span className='ms-2'>{t('Creator.Survey.createNew')}</span>
        </button>
      </div>
    );
  };

  return (
    <>
      {isLoading && <PopupLoading />}
      <LayoutCreatorRight
        noButtonBack
        titlePage={t('Creator.Survey.surveyList')}
        link={`${newPathUser}`}
        className='survey-layout'>
        {showPopupConfirm && (
          <PopupConfirmDelete
            title={t('Popup.confirmDeleteSurvey')}
            setShowPopup={setShowPopupConfirm}
            handleOnSubmit={handleDelete}
          />
        )}
        {loading && !isLoading && !loadingScroll ? (
          <div style={{ padding: `${isMobile ? '16px' : '24px'}` }}>
            <ShimmerPostDetails className='shimmer-loading' />
          </div>
        ) : (
          <div className='survey-container'>
            {surveys?.length > 0 ? (
              <>
                <div className='survey-container-header'>{t('Creator.Survey.title')}</div>
                <InfiniteLoad
                  loading={loadingScroll}
                  data={surveys || []}
                  fetchNext={fetchNext}>
                  {renderlistSurvey()}
                </InfiniteLoad>
              </>
            ) : (
              !loading && renderEmptySurvey()
            )}
          </div>
        )}
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(ListSurvey);
