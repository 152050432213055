import { useState, useEffect, useCallback, useLayoutEffect } from 'react';

const useVisbilityState = () => {
  const [visibilityState, setVisibilityState] = useState(null);

  const handleVisbilityChange = useCallback(() => {
    setVisibilityState(document.visibilityState);
  }, [setVisibilityState]);

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisbilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisbilityChange);
  }, [handleVisbilityChange]);

  return visibilityState;
};

const useVisbilityStateHook = (cb, cbrt = () => {}) => {
  const onVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      cb();
    } else {
      cbrt && cbrt();
    }
  };

  useLayoutEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => document.removeEventListener('visibilitychange', onVisibilityChange);
  }, []);
};

export { useVisbilityState, useVisbilityStateHook };
