import React from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import { convertTextMonthEN, USER_REACTIONS_ENUM, widthSreen } from 'utils';

const ReportAccountChart = ({ data }) => {
  const { t, i18n } = useTranslation();
  const USER_REACTIONS_ENUM = {
    number_content: i18n.t('Report.number_content'),
    number_like: i18n.t('Report.number_like'),
    number_bookmark: i18n.t('Report.number_bookmark'),
    number_follow: i18n.t('homePageHeader.follower'),
  };
  const renderLegendText = (value) => (
    <span style={{ color: '#7B7B7B' }}>{USER_REACTIONS_ENUM[value]}</span>
  );
  const renderToolTipText = (value, name) => [value, USER_REACTIONS_ENUM[name]];
  return (
    <React.Fragment>
      <p className='report-chart-label'>{t('Report.account_report')}</p>
      <ResponsiveContainer width='100%' height='100%' minWidth={500} minHeight={350}>
        <ComposedChart width={'100%'} height={'100%'} data={data}>
          <Legend
            formatter={renderLegendText}
            yAxisId={1}
            verticalAlign='top'
            align='left'
            wrapperStyle={{
              paddingBottom: 20,
            }}
            iconSize={10}
            fontSize={12}
            payload={[
              {
                id: 'number_content',
                value: 'number_content',
                type: 'square',
                color: '#FF0000',
              },
              { id: 'number_like', value: 'number_like', type: 'square', color: '#91BC3A' },
              {
                id: 'number_bookmark',
                value: 'number_bookmark',
                type: 'square',
                color: '#744EA3',
              },
              {
                id: 'number_follow',
                value: 'number_follow',
                type: 'circle',
                color: '#3FA3FF',
              },
            ]}
          />
          <CartesianGrid stroke='#DFDFDF' vertical={false} />
          <XAxis
            dataKey='month'
            tickFormatter={(e) =>
              i18n.language === 'jp' ? `${+e} ${t('Common.month')}` : convertTextMonthEN(+e)
            }
            tick={{ fill: '#282727', fontSize: 12 }}
            dy={10}
            tickLine={false}
            stroke='#282727'
          />
          <YAxis
            yAxisId={1}
            width={60}
            tickLine={false}
            axisLine={false}
            tick={{ fill: '#282727', fontSize: 12 }}
            label={{
              value: `${t('Report.comment_like_bookmark')}`,
              position: 'insideBottomLeft',
              offset: 10,
              angle: -90,
            }}
          />
          <YAxis
            dataKey='number_follow'
            orientation='right'
            yAxisId={2}
            tickLine={false}
            width={40}
            axisLine={false}
            tick={{ fill: '#282727', fontSize: 12 }}
            label={{
              value: `${t('Report.number_of_followers')}`,
              position: 'insideRight',
              offset: 1,
              angle: -90,
            }}
          />
          <Tooltip formatter={renderToolTipText} yAxisId={1} />
          <Bar
            dataKey='number_content'
            fill='#FF0000'
            barSize={widthSreen > 991 ? 16 : 6}
            yAxisId={1}
            label={{ fill: '#FF0000', fontSize: 8, position: 'top' }}
          />
          <Bar
            dataKey='number_like'
            fill='#91BC3A'
            barSize={widthSreen > 991 ? 16 : 6}
            yAxisId={1}
            label={{ fill: '#91BC3A', fontSize: 8, position: 'top' }}
          />
          <Bar
            dataKey='number_bookmark'
            fill='#744EA3'
            barSize={widthSreen > 991 ? 16 : 6}
            yAxisId={1}
            label={{ fill: '#744EA3', fontSize: 8, position: 'top' }}
          />
          <Line
            type='linear'
            dataKey='number_follow'
            stroke='#3FA3FF'
            strokeWidth={widthSreen > 991 ? 3 : 2}
            yAxisId={2}
            fill='#3FA3FF'
          />
        </ComposedChart>
      </ResponsiveContainer>
    </React.Fragment>
  );
};

export default ReportAccountChart;
