import { useEffect } from 'react';

function ButtonSubmit({ type = 'submit', ...props }) {
  // useEffect(() => {
  //   window.addEventListener(
  //     'keydown',
  //     function (e) {
  //       if (
  //         type === 'submit' &&
  //         (e.keyIdentifier == 'U+000A' || e.keyIdentifier == 'Enter' || e.keyCode == 13)
  //       ) {
  //         e.preventDefault();
  //         return false;
  //       }
  //     },
  //     true
  //   );
  // }, []);

  return (
    <button type={type} {...props}>
      {props.children}
    </button>
  );
}

export default ButtonSubmit;
