import { useEffect, useRef } from 'react';

export const useDetectChangeOnoriention = () => {
  const widthScreen = window.innerWidth;
  const refTimeout = useRef(null);
  useEffect(() => {
    if (widthScreen > 575) {
      let countChanging = 0;

      const handleOrientationChange = () => {
        countChanging = countChanging + 1;

        refTimeout.current = setTimeout(() => {
          if (countChanging === 1) {
            countChanging = 0;

            window.location.reload();
          } else {
            countChanging = 0;
          }
        }, 1000);
      };

      window.addEventListener(
        'orientationchange',

        handleOrientationChange,

        false
      );

      return () => {
        clearTimeout(refTimeout.current);
        window.removeEventListener(
          'orientationchange',

          handleOrientationChange,

          false
        );
      };
    }
  }, []);
};
