import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import { returnSizeStamps } from '../ManageAgoraOBSPlayer/utils';
const WrapperEffect = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 5%;
  top: 0px;
`;
const ImageEffect = styled.img`
  z-index: 50;
  width: ${returnSizeStamps({
    isDevice: isMobile,
  })}px;
  height: ${returnSizeStamps({
    isDevice: isMobile,
  })}px;
  position: absolute;
  left: ${({ left }) => left};
  top: ${({ top }) => top};
`;
export { WrapperEffect, ImageEffect };
