import Icon from '@ant-design/icons';

const IconHome = ({isActive}) => {
  return (
    <Icon
      component={() => (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='25'
          height='24'
          viewBox='0 0 25 24'
          fill='none'>
          <path
            d='M15.1245 19.4982V14.9981C15.1245 14.7992 15.0454 14.6084 14.9048 14.4678C14.7641 14.3271 14.5734 14.2481 14.3745 14.2481H11.3745C11.1755 14.2481 10.9848 14.3271 10.8441 14.4678C10.7035 14.6084 10.6245 14.7992 10.6245 14.9981V19.4982C10.6245 19.6971 10.5454 19.8878 10.4048 20.0285C10.2642 20.1691 10.0734 20.2482 9.87454 20.2482L5.37509 20.2488C5.27659 20.2488 5.17906 20.2294 5.08805 20.1917C4.99705 20.154 4.91436 20.0988 4.8447 20.0291C4.77505 19.9595 4.7198 19.8768 4.6821 19.7858C4.6444 19.6948 4.625 19.5973 4.625 19.4988V10.8306C4.625 10.7261 4.64683 10.6228 4.6891 10.5272C4.73137 10.4317 4.79314 10.346 4.87045 10.2757L12.3699 3.45681C12.508 3.33128 12.6879 3.26172 12.8745 3.26172C13.061 3.26171 13.2409 3.33126 13.379 3.45677L20.8795 10.2757C20.9568 10.346 21.0186 10.4317 21.0609 10.5272C21.1032 10.6228 21.125 10.7261 21.125 10.8306V19.4988C21.125 19.5973 21.1056 19.6948 21.0679 19.7858C21.0302 19.8768 20.975 19.9595 20.9053 20.0291C20.8356 20.0988 20.753 20.154 20.6619 20.1917C20.5709 20.2294 20.4734 20.2488 20.3749 20.2488L15.8744 20.2482C15.6755 20.2482 15.4847 20.1691 15.3441 20.0285C15.2035 19.8878 15.1244 19.6971 15.1245 19.4982V19.4982Z'
            stroke={isActive ? '#DC143C' : '#BEBEBE'}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      )}
    />
  );
};

export default IconHome;
