/* eslint-disable react-hooks/exhaustive-deps */
import {
  LayoutCreatorRight,
  PaginationTable,
  PopupLoading,
  PopupFanDetail,
  HeaderListIdolV2,
  AccountSidebar,
  MenuSidebarCustom,
} from 'components';
import SelectDateGroup from 'components/SelectDateCustom';
import withCreatorRole from 'hocs/withCreatorRole';
import { ArrowDownIcon, IconArrowLeftPaginate, IconArrowRightPaginate } from 'images';
import React, { useEffect, useState } from 'react';
// import CSV from './CSV';
import { CSVLink } from 'react-csv';
import CurrencyFormat from 'react-currency-format';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIDSale,
  getRevenue,
  getSettingLimitPayment,
  getTopFans,
  getTransactions,
  getTransactionsCSV,
  postUserWithdrawRequest,
  resetIDSale,
} from 'store/actions/saleConfirmation';
import {
  convertTextMonthEN,
  convertToCurrentTime,
  isEmptyObject,
  isProdEnableLiveStream,
  setOverflowTablet,
} from 'utils/utils';
import { formatCSV_YYYYMMDD } from 'utils/constant';
import PopupConfirmWithdraw from './ConfirmWithdraw';
import './index.scss';
import PopupWithdrawSuccess from './WithdrawSuccess';
import { useTranslation } from 'react-i18next';
import { newPathUser } from 'constants/layout/constant';
import { useNavigate } from 'react-router-dom';
import { getUserPoints } from 'store/actions/users';
import { ShimmerButton, ShimmerPostDetails } from 'react-shimmer-effects';
import { useMemo } from 'react';
import { useLayoutEffect } from 'react';
import DateRangePicker from './date-range-picker/DateRangePicker';
import moment from 'moment';

const SaleConfirmation = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    loading,
    loadingListTopFans,
    loadingListTransactions,
    loadingListTransactionsCSV,
    dataRevenue,
    listTopFans,
    listTransactions,
    paginationTransaction,
    listTransactionsCSV,
    settingLimitPayment,
    idSale
  } = useSelector((state) => state.saleConfirmation);
  const [pageTransactions, setPageTransactions] = useState(idSale && paginationTransaction ? +paginationTransaction?.current_page : 1);
  const itemPerPage = 20;

  const saleDetail = [
    {
      id: 'subscription_revenue',
      title: `${t('Sales.subscription_revenue')}`,
      index: 3,
      count: 'subscription_count',
    },
    {
      id: 'content_revenue',
      title: `${t('Sales.content_revenue')}`,
      index: 4,
      count: 'content_count',
    },
    {
      id: 'livestream_revenue',
      title: `${t('Sales.livestream_revenue')}`,
      index: 2,
      count: 'livestream_count',
    },
    {
      id: 'other_revenue',
      title: `${t('Sales.other_revenue')}`,
      index: 1,
      count: 'other_count',
    },
  ];

  const saleDetailWithoutLive = [
    {
      id: 'subscription_revenue',
      title: `${t('Sales.subscription_revenue')}`,
      index: 3,
      count: 'subscription_count',
    },
    {
      id: 'content_revenue',
      title: `${t('Sales.content_revenue')}`,
      index: 4,
      count: 'content_count',
    },
    {
      id: 'other_revenue',
      title: `${t('Sales.other_revenue')}`,
      index: 1,
      count: 'other_count',
    },
  ];

  const dataSaleDetail = useMemo(() => {
    if (+isProdEnableLiveStream === 1) {
      return saleDetail;
    } else {
      return saleDetailWithoutLive;
    }
  }, [isProdEnableLiveStream]);

  const {
    data: dataProfile,
    userPoints,
    dataUser,
    loading: loadingPoints,
  } = useSelector((state) => state.users);
  const [isVisibleFanDetail, setIsVisibleFanDetail] = useState(false);
  const [idFan, seIdFan] = useState(false);
  const [showRankingPayment, setShowRankingPayment] = useState(false);
  const [isVisiblePopupWithdraw, setIsVisiblePopupWithdraw] = useState(false);
  const [isVisiblePopupWithdrawSuccess, setIsVisiblePopupWithdrawSuccess] = useState(false);
  const [saleDetailSelect, setSaleDetailSelect] = useState(idSale?.type || dataSaleDetail[0]);
  const [selectDate, setSelectDate] = useState({
    year: new Date().getFullYear(),
    month: '',
  });
  const [dateRange, setDateRange] = useState(idSale?.date || {
    date_from: moment().startOf('year').format('YYYY-MM-DD'),
    date_to: moment(new Date()).format('YYYY-MM-DD'),
  });
  const [convertDataExport, setConvertDataExport] = useState([]);
  const [isLoadingWithdraw, setIsLoadingWithdraw] = useState(false);
  const currentPoints = dataUser?.earned_points ?? 0;

  const handleSelectDate = (date) => {
    setSelectDate(date);
    setPageTransactions(1);
  };
  const handleWithdrawSuccess = () => {
    setIsVisiblePopupWithdrawSuccess(true);
    setIsLoadingWithdraw(false);
  };
  const handleWithdrawFailed = () => {
    setIsLoadingWithdraw(false);
  };
  const submitWithdraw = (value) => {
    setIsVisiblePopupWithdraw(false);
    setIsLoadingWithdraw(true);
    dispatch(
      postUserWithdrawRequest({ amount: value }, handleWithdrawSuccess, handleWithdrawFailed)
    );
  };

  useEffect(() => {
    if (idSale) {
      const idPost = document.getElementById(idSale.id);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idSale]);

  useLayoutEffect(() => {
    if(!idSale?.id) {
      const top = document.getElementById('app_cocofans_0');
      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
      dispatch(getSettingLimitPayment());
      dispatch(getUserPoints());
    }
  }, []);

  useEffect(() => {
    if (isVisiblePopupWithdraw) {
      document.getElementById('root').style.overflow = 'hidden';
      document.getElementById('root').style.position = 'fixed';
      document.getElementById('root').style.width = '100%';
    } else {
      // document.getElementById('root').style.overflow = 'auto';
      setOverflowTablet();
      document.getElementById('root').style.position = 'revert';
    }
  }, [isVisiblePopupWithdraw]);

  useEffect(() => {
    if(!idSale?.id) {
      dispatch(getTopFans({ ...dateRange, sort: 'desc', limit: 10 }));
      dispatch(getRevenue({ ...dateRange, sort: 'desc' }));
    }
  }, [dateRange, idSale]);

  useLayoutEffect(() => {
    //const top = document.getElementById('app_cocofans_0');
    //top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
    if(!idSale?.id) {
      dispatch(
        getTransactions({
          ...dateRange,
          sort: 'desc',
          type: saleDetailSelect.index,
          page: pageTransactions,
          limit: itemPerPage,
        })
      );
    }
  }, [dateRange, saleDetailSelect, pageTransactions, idSale]);

  useLayoutEffect(() => {
    if(!idSale?.id) {
      dispatch(
        getTransactionsCSV({
          ...dateRange,
          sort: 'desc',
          type: saleDetailSelect.index,
        })
      );
    }
  }, [dateRange, saleDetailSelect, idSale]);

  const funcFormatDate = (date) => {
    const a = date.split(' ')[0].slice(5).split('-');
    const a1 =
      i18n.language === 'jp' ? `${a[0]}${t('Common.month')}` : convertTextMonthEN(a[0]);
    const a2 = `${a[1]} ${i18n.language === 'jp' ? t('Common.sun') : `  `}`;
    const b = date.split(' ')[1];
    return `${a1 + a2} ${b}`;
  };

  useEffect(() => {
    listTransactionsCSV?.length > 0
      ? setConvertDataExport(
          listTransactionsCSV.map((row) => ({
            ...row,
            created_at: `${
              formatCSV_YYYYMMDD + convertToCurrentTime(row.created_at, 'YYYY/MM/DD HH:mm:ss')
            }`,
            price: `${row.price} ${t('Common.yen')}`,
          }))
        )
      : setConvertDataExport([]);
  }, [listTransactionsCSV, t]);

  const headers = [
    { label: `${t('Common.created_at')}`, key: 'created_at' },
    { label: `${t('Sales.benefit')}`, key: 'price' },
    { label: `${t('Sales.account_name')}`, key: 'account_name' },
  ];

  const handleConvertTypeTransactions = (type) => {
    switch (type) {
      case 1:
        return `${t('Sales.message_tip')}`;
      case 2:
        return `${t('Sales.live_streaming_tip')}`;
      case 3:
        return `${t('Sales.plan_purchase')}`;
      case 4:
        return `${t('Sales.content_purchase')}`;
      default:
        break;
    }
  };

  const date = new Date();

  const nameFileCSV = `${t(`Sales.${saleDetailSelect.id}`)}_${dateRange.date_from}${
    dateRange.date_from
  }_${date?.toISOString()?.split('T')?.[0]?.split('-')?.join('')}${date
    ?.toTimeString()
    ?.split(' ')?.[0]
    ?.split(':')
    ?.join('')}`;

  const handleChangeDate = (dateRange) => {
    setDateRange(dateRange);
    setPageTransactions(1);
    dispatch(resetIDSale());
  };

  return (
    <>
      {isVisibleFanDetail && (
        <PopupFanDetail
          closeModal={() => setIsVisibleFanDetail(false)}
          isVisible={isVisibleFanDetail}
          id={idFan}
        />
      )}
      {isLoadingWithdraw && <PopupLoading />}
      <LayoutCreatorRight
        noButtonBack
        className='sale-confirm-layout'
        titlePage={t('Sales.sales_confirmation')}>
        {loadingPoints ? (
          <div className='shimmer-loading'>
            <ShimmerPostDetails />
          </div>
        ) : (
          <div className='revenue-page-creator px-sm-4 px-3 py-sm-3'>
            {/* <CSV selectDate={selectDate} nameFile={nameFileCSV} /> */}
            {!dataProfile?.creator?.[0]?.agency_id && userPoints?.is_withdrawal !== 0 && (
              <div className='group-point text-center'>
                <p className='fw-bold mb-0'>{t('Sales.withdrawable_amount')}</p>
                <div className='point-number-group fw-bold'>
                  <strong className='point-number'>
                    <CurrencyFormat
                      thousandSeparator={true}
                      value={userPoints?.points || 0}
                      className='currency-format'
                      displayType='text'
                      decimalScale={2}
                    />
                  </strong>
                  <strong className='point-unit'>{t('Common.yen')}</strong>
                </div>
                <button
                  disabled={
                    !userPoints?.points || userPoints?.points === 0 || userPoints?.is_withdrawal === 0
                  }
                  onClick={() =>
                    userPoints?.available_withdrawal && setIsVisiblePopupWithdraw(true)
                  }
                  className={` btn-withdraw-money ${
                    userPoints?.available_withdrawal ? '' : 'disabled'
                  }`}>
                  {t('Sales.apply_for_transfer')}
                </button>
                {isVisiblePopupWithdraw && (
                  <PopupConfirmWithdraw
                    closeModal={(e) => setIsVisiblePopupWithdraw(e)}
                    onSubmitButton={(e) => submitWithdraw(e)}
                    currentPoint={userPoints?.points || 0}
                    adminSetting={settingLimitPayment?.min_price || 0}
                  />
                )}
                {isVisiblePopupWithdrawSuccess && (
                  <PopupWithdrawSuccess
                    closeModal={(e) => setIsVisiblePopupWithdrawSuccess(e)}
                  />
                )}
              </div>
            )}

            <div className='ranking-payment'>
              <div className='filter-date'>
                {/* <SelectDateGroup notDate notMulti handleSelect={handleSelectDate} /> */}
                <DateRangePicker isBack={idSale?.date} dateRangeNew={dateRange} onChangeDate={handleChangeDate} />
              </div>
              <div className='raking-payment-table mb-3'>
                <div
                  className='toggle-header'
                  onClick={() => setShowRankingPayment(!showRankingPayment)}>
                  <div>{t('Sales.billing_ranking')}</div>
                  <span className='toggle-icon'>
                    <ArrowDownIcon className={showRankingPayment && 'active'} />
                  </span>
                </div>
                {showRankingPayment &&
                  (loadingListTopFans ? (
                    <div className='table-payment'>
                      <div className='pt-3'>
                        <ShimmerPostDetails />
                      </div>
                    </div>
                  ) : (
                    <div className='table-payment table-top-fans'>
                      <div className='table-payment-header thead'>
                        <div className='tr'>
                          <div className='th no'>{t('Sales.rank')}</div>
                          <div className='th username'>{t('Sales.account_name')}</div>
                          <div className='th amount text-end'>
                            {t('Sales.amount_of_money')}
                          </div>
                        </div>
                      </div>
                      {listTopFans?.length > 0
                        ? listTopFans.map((item, index) => (
                            <div className='table-payment-body tbody'>
                              <div
                                className='tr item-payment'
                                key={item.user_id}
                                onClick={() => {
                                  // setIsVisibleFanDetail(true);
                                  // seIdFan(item.user_id);
                                  navigate(`${newPathUser}${item?.account_id}`);
                                }}
                                style={{ cursor: 'pointer' }}>
                                <div className='td no'>{index + 1}</div>
                                <div className='td username'>
                                  {item?.account_name || item?.full_name}
                                </div>
                                <div className='td amount text-end'>
                                  <CurrencyFormat
                                    thousandSeparator={true}
                                    value={item?.price}
                                    className='currency-format'
                                    displayType='text'
                                    decimalScale={2}
                                  />
                                  {i18n.language === 'en' && (
                                    <span style={{ marginLeft: 3 }} />
                                  )}
                                  {t('Common.yen')}
                                </div>
                              </div>
                            </div>
                          ))
                        : !loading && <div className='empty-box'>{t('Sales.no_billers')}</div>}
                    </div>
                  ))}
              </div>
              <div className='sale-details'>
                <div className='sale-detail-header'>
                  <div className='title-sale-detail'>{t('Sales.sales_details')}</div>
                  <div className='d-flex align-items-center'>
                    <span className='me-1 text-total-sale'>{t('Sales.total_sales')}</span>
                    <div className='sale-number text-red'>
                      <CurrencyFormat
                        thousandSeparator={true}
                        value={dataRevenue['total']}
                        className='currency-format'
                        displayType='text'
                        decimalScale={2}
                      />
                      {i18n.language === 'en' && <span style={{ marginLeft: 3 }} />}
                      <span>{t('Common.yen')}</span>
                    </div>
                  </div>
                  {loadingListTransactionsCSV ? (
                    <div className='shimmer-button-pc'>
                      <ShimmerButton />
                    </div>
                  ) : (
                    <CSVLink
                      data={convertDataExport}
                      headers={headers}
                      className='btn btn-export-csv d-middle cancel'
                      filename={nameFileCSV}>
                      {t('Common.csv')}
                    </CSVLink>
                  )}
                  {/* <CSVLink
                    data={convertDataExport}
                    headers={headers}
                    className='btn btn-export-csv d-middle'
                    filename={nameFileCSV}>
                    {t('Common.csv')}
                  </CSVLink> */}
                </div>
                <div className='csv-container-mobile'>
                  {loadingListTransactionsCSV ? (
                    <div className='shimmer-button-mobile'>
                      <ShimmerButton />
                    </div>
                  ) : (
                    <CSVLink
                      data={convertDataExport}
                      headers={headers}
                      className='btn btn-export-csv d-middle mobile'
                      filename={nameFileCSV}>
                      {t('Common.csv')}
                    </CSVLink>
                  )}
                  {/* <CSVLink
                    data={convertDataExport}
                    headers={headers}
                    className='btn btn-export-csv d-middle mobile'
                    filename={nameFileCSV}>
                    {t('Common.csv')}
                  </CSVLink> */}
                </div>
                <div className='sale-select'>
                  <div className='row'>
                    {!isEmptyObject(dataRevenue) &&
                      dataSaleDetail.map((item, index) => (
                        <div className='col-sm-12 col-md-6' key={index}>
                          <div
                            className={`sale-select-item ${
                              saleDetailSelect.index === item.index && 'active'
                            }`}
                            onClick={() => {
                              setSaleDetailSelect(item);
                              setPageTransactions(1);
                              if(idSale) {
                                dispatch(getIDSale({id: null, page: pageTransactions, date: dateRange, type: saleDetailSelect}))
                              }
                            }}>
                            <div className='_title'>{t(`Sales.${item.id}`)}</div>
                            <div className='_amount'>
                              <CurrencyFormat
                                thousandSeparator={true}
                                value={dataRevenue[item.id]}
                                className='currency-format'
                                displayType='text'
                                decimalScale={2}
                              />
                              {i18n.language === 'en' && <span style={{ marginLeft: 3 }} />}
                              {t('Common.yen')}
                            </div>
                            <div className='_record'>
                              {dataRevenue[item.count]}
                              {t('Common.piece')}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <div className='my-3 fw-bold text-center'>
                    {t(`Sales.${saleDetailSelect.id}`)}
                  </div>
                </div>
              </div>

              <div className='mb-3 fw-500 text-sale-history'>{t('Common.sales_history')}</div>
              <div className='table-payment table-history-sale'>
                <div className='table-payment-header thead'>
                  <div className='tr'>
                    <div className='th date'>{t('Common.created_at')}</div>
                    <div className='th amount'>{t('Sales.benefit')}</div>
                    <div className='th username'>{t('Sales.account_name')}</div>
                    <div className='th type'>{t('Sales.type')}</div>
                  </div>
                </div>
                <div className='table-payment-body tbody'>
                  {loadingListTransactions ? (
                    <div className='pt-3'>
                      <ShimmerPostDetails />
                    </div>
                  ) : (
                    <>
                      {listTransactions?.length > 0
                        ? listTransactions.map((item, index) => (
                            <div
                              className='tr item-payment'
                              key={index}
                              id={item?.id}
                              onClick={() => {
                                // setIsVisibleFanDetail(true);
                                // seIdFan(item.user_id);
                                dispatch(getIDSale({id: item?.id, page: pageTransactions, date: dateRange, type: saleDetailSelect}))
                                navigate(`${newPathUser}${item?.account_id}`);
                              }}
                              style={{ cursor: 'pointer' }}>
                              <div className='td date'>
                                {funcFormatDate(
                                  convertToCurrentTime(item.created_at, 'YYYY-MM-DD HH:mm:ss')
                                )}
                              </div>
                              <div className='td amount'>
                                <CurrencyFormat
                                  thousandSeparator={true}
                                  value={item.price}
                                  className='currency-format'
                                  displayType='text'
                                  decimalScale={2}
                                />
                                {i18n.language === 'en' && <span style={{ marginLeft: 3 }} />}
                                {t('Common.yen')}
                              </div>
                              <div className='td username'>
                                {item?.account_name || item?.user?.full_name}
                              </div>
                              <div className='td type'>
                                {handleConvertTypeTransactions(item.transaction_type)}
                              </div>
                            </div>
                          ))
                        : !loading && (
                            <div className='empty-box'>{t('Sales.no_sales_history')}</div>
                          )}
                    </>
                  )}
                </div>
                <div className='table-payment-foot d-lg-flex d-none'>
                  <PaginationTable
                    itemsPerPage={itemPerPage}
                    pageTransactions={pageTransactions}
                    total={paginationTransaction.total}
                    disabledLinkClassName='disabled-prev-next'
                    currentPageTable={(e) => {
                      if(idSale) {
                        dispatch(getIDSale({id: null, page: pageTransactions, date: dateRange}))
                      }
                      setPageTransactions(e);
                    }}
                    previousIcon={<IconArrowLeftPaginate />}
                    nextIcon={<IconArrowRightPaginate />}
                  />
                </div>
                <div
                  className='table-payment-foot mobile d-lg-none d-flex'
                  style={{ marginTop: 24, justifyContent: 'flex-end' }}>
                  <PaginationTable
                    itemsPerPage={itemPerPage}
                    pageTransactions={pageTransactions}
                    total={paginationTransaction.total}
                    disabledLinkClassName='disabled-prev-next'
                    currentPageTable={(e) => {
                      if(idSale) {
                        dispatch(getIDSale({id: null, page: pageTransactions, date: dateRange}))
                      }
                      setPageTransactions(e)
                    }}
                    previousIcon={<IconArrowLeftPaginate />}
                    nextIcon={<IconArrowRightPaginate />}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(SaleConfirmation);
