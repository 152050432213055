import { TYPES } from 'store/actions';

/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  loading: false,
  error: null,
  pagination: {},
  listCommentHide: [],
  idScrollBackCommentHide: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_LIST_COMMENT_HIDE_FROM_POST_SUCCESS:
      if (+action?.data?.pagination?.current_page === 1) {
        return {
          ...state,
          pagination: action?.data?.pagination,
          listCommentHide: action.data.data,
        };
      }
      return {
        ...state,
        pagination: action?.data?.pagination,
        listCommentHide: state.cards.concat(action.data.data),
      };
    case TYPES.GET_SUB_LIST_COMMENT_HIDE_FROM_POST_SUCCESS:
      const listSub = action?.data?.data;
      state.listCommentHide = state?.listCommentHide?.map((item) => {
        if (+item?.id === +action.data?.postId) {
          return {
            ...item,
            paginationSub: action?.data?.pagination,
            listSub:
              +action?.data?.pagination?.current_page === 1
                ? listSub
                : item?.listSub?.concat(listSub),
          };
        }
        return item;
      });

      return {
        ...state,
        listCommentHide: state?.listCommentHide,
      };
    case TYPES.UPDATE_LIKE_COMMENT_HIDE_COMMENT_SUCCESS:
      const idUpdateLike = action?.data?.id;
      const idParent = action?.data?.parentId;
      if (idParent) {
        state.listCommentHide = state?.listCommentHide?.map((item) => {
          if (+item?.id === +idParent) {
            const listSub = item?.listSub;
            if (listSub && !!listSub?.length) {
              item.listSub = listSub?.map((itemSub) => {
                if (+itemSub?.id === +idUpdateLike) {
                  return {
                    ...itemSub,
                    is_liked: action?.data?.is_liked,
                    number_like:
                      action?.data?.is_liked === 1
                        ? itemSub?.number_like + 1
                        : itemSub?.number_like - 1,
                  };
                }
                return itemSub;
              });
            }
            return item;
          }
          return item;
        });
      } else {
        state.listCommentHide = state?.listCommentHide?.map((item) => {
          if (+item?.id === +idUpdateLike) {
            return {
              ...item,
              is_liked: action?.data?.is_liked,
              number_like:
                action?.data?.is_liked === 1 ? item?.number_like + 1 : item?.number_like - 1,
            };
          }
          return item;
        });
      }

      return {
        ...state,
        listCommentHide: state?.listCommentHide,
      };

    case TYPES.UN_HIDE_COMMENT_FORM_POST_SUCCESS:
      state.listCommentHide = state?.listCommentHide?.filter(
        (item) => +item?.id !== +action.data?.data?.id
      );
      return {
        ...state,
        listCommentHide: state?.listCommentHide,
      };
    case TYPES.FOLLOW_USER_HIDE_COMMENT_SUCCESS:
      state.listCommentHide = state?.listCommentHide?.map((item) => {
        if (+item?.user_id === +action.data?.data?.id) {
          return {
            ...item,
            is_followed: item?.is_followed === 1 ? 0 : 1,
          };
        }
        return item;
      });
      return {
        ...state,
        listCommentHide: state?.listCommentHide,
      };
    case 'SET_ID_SCROLL_BACK_HIDE_COMMENT':
      return {
        ...state,
        idScrollBackCommentHide: action.data?.data?.id || null,
      };
    case 'CLEAR_ID_AND_DATA_HIDE_COMMENT':
      return {
        ...state,
        idScrollBackCommentHide: null,
        pagination: {},
        listCommentHide: [],
      };
    default:
      return state;
  }
};
