import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { newPathUser } from 'constants/layout/constant';
import { StyledNavbarF025 } from './styles';
import './NavbarF025.scss';
import { useDispatch, useSelector } from 'react-redux';
import { resetIDSearchBuyed, resetIDSubcribe } from 'store/actions/users';

const NavbarF025 = ({ defaultActive, resetPage, className }) => {
  const { t } = useTranslation();
  const [tabActive, setTabActive] = useState(defaultActive || 1);
  const {
    loading,
  } = useSelector((state) => state.users);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    defaultActive && setTabActive(defaultActive);
  }, [defaultActive]);
  const handleActiveTab = (tab) => {
    navigate(tab === 1 ? `${newPathUser}subscribe-list` : `${newPathUser}list-post-buyed`);
  };
  return (
    <div className={className}>
      <div
        className={`tab-item ${tabActive === 1 ? 'active' : ''}`}
        style={{ cursor: `${loading ? 'default' : 'pointer'}`, pointerEvents: `${tabActive === 1 ? 'none' : 'auto'}`}}
        disabled={loading}
        onClick={() => {
          if(!loading) {
            dispatch(resetIDSubcribe());
            handleActiveTab(1);
            resetPage();
          }
        }}>
        {t('Navbar.F025.tab1')}
      </div>
      <div
        className={`tab-item ${tabActive === 2 ? 'active' : ''}`}
        style={{ cursor: `${loading ? 'default' : 'pointer'}`, pointerEvents: `${tabActive === 2 ? 'none' : 'auto'}`}}
        disabled={loading}
        onClick={() => {
          if(!loading) {
            dispatch(resetIDSearchBuyed());
            handleActiveTab(2);
            resetPage();
          }
        }}>
        {t('Navbar.F025.tab2')}
      </div>
    </div>
  );
};

export default NavbarF025;
