import { combineReducers } from 'redux';
import users from './users';
import search from './search';
import myPage from './myPage';
import calendar from './calendar';
import survey from './survey';
import lives from './lives';
import handleError from './handleError';
import notification from './notification';
import premiumTicket from './premiumTicket';
import payment from './payment';
import timeLinePost from './timelinePost';
import creator from './creator';
import listIdol from './listIdol';
import message from './message';
import feedback from './feedback';
import sellPost from './sellPost';
import affiliator from './affiliator';
import admin from './admin';
import bankAccount from './bankAccount';
import saleConfirmation from './saleConfirmation';
import chartReport from './chartReport';
import maintain from './maintain';
import pointIconDonate from './pointIconDonate';
import usersNew from './usersNew';
import card from './card';
import postMeta from './postMeta';
import ranking from './ranking';
import commentHide from './commentHide';

const appReducer = combineReducers({
  handleError,
  users,
  search,
  myPage,
  calendar,
  survey,
  lives,
  notification,
  premiumTicket,
  payment,
  timeLinePost,
  creator,
  listIdol,
  message,
  feedback,
  affiliator,
  sellPost,
  admin,
  bankAccount,
  saleConfirmation,
  chartReport,
  maintain,
  pointIconDonate,
  usersNew,
  card,
  postMeta,
  ranking,
  commentHide,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
