import Icon from '@ant-design/icons';

const MiniCoin = (props) => (
  <Icon
    component={() => (
      <svg
        width='21'
        height='20'
        viewBox='0 0 21 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M10.0007 19.8906C4.48471 19.8906 0 15.4302 0 9.94397C0 4.45776 4.48471 -0.00268936 10.0007 -0.00268936C15.5168 -0.00268936 20.0015 4.45776 20.0015 9.94397C20.0015 15.4302 15.5168 19.8906 10.0007 19.8906ZM10.0007 1.16294C5.1332 1.16294 1.17196 5.10274 1.17196 9.94397C1.17196 14.7852 5.1332 18.725 10.0007 18.725C14.8683 18.725 18.8295 14.7852 18.8295 9.94397C18.8295 5.10274 14.8683 1.16294 10.0007 1.16294Z'
          fill='#EFAA33'
        />
        <path
          d='M10.0025 17.5625C5.77955 17.5625 2.3457 14.1472 2.3457 9.94709C2.3457 5.74696 5.77955 2.33168 10.0025 2.33168C14.2255 2.33168 17.6593 5.74696 17.6593 9.94709C17.6593 14.1472 14.2255 17.5625 10.0025 17.5625ZM10.0025 3.49731C6.42804 3.49731 3.51767 6.39194 3.51767 9.94709C3.51767 13.5022 6.42804 16.3969 10.0025 16.3969C13.577 16.3969 16.4874 13.5022 16.4874 9.94709C16.4874 6.39194 13.577 3.49731 10.0025 3.49731Z'
          fill='#EFAA33'
        />
        <path
          d='M12.8609 12.9219H7.12997C6.86823 12.9219 6.63774 12.747 6.56742 12.4984L5.48531 8.69843C5.42671 8.49639 5.48531 8.27881 5.63376 8.13116C5.78221 7.98351 6.00098 7.92523 6.20412 7.97963L7.97769 8.46142L9.54421 6.44878C9.64969 6.30113 9.82157 6.21954 10.0013 6.21954C10.181 6.21954 10.3529 6.30113 10.4662 6.44489L12.0327 8.45753L13.8062 7.97574C14.0094 7.92135 14.2282 7.97963 14.3766 8.12727C14.5251 8.27881 14.5797 8.49639 14.5251 8.69843L13.4312 12.4984C13.3531 12.747 13.1226 12.9219 12.8609 12.9219ZM7.57141 11.7563H12.4194L13.107 9.37449L11.9624 9.68533C11.7319 9.74749 11.4897 9.6659 11.3451 9.4794L10.0013 7.75428L8.66133 9.4794C8.51679 9.6659 8.27068 9.74749 8.0441 9.68533L6.89557 9.37061L7.57141 11.7563Z'
          fill='#EFAA33'
        />
      </svg>
    )}
    {...props}
  />
);

export default MiniCoin;
