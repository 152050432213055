import styled from 'styled-components';

export const StyledBG = {
  BlueImg: styled.div`
    background: url(${({ url }) => "'" + url + "'"});
    height: ${({ height }) => height};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .bg-img {
      max-width: 100%;
      height: 100%;
      position: absolute;
      z-index: 9;
      min-height: 100%;
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(20px);
    }
    @media screen and (max-width: 768px) {
    }
  `,
};
