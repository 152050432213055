import { useEffect, useRef } from 'react';

export const useClickOutSize = (eventClick) => {
  const refOutSide = useRef();
  const handleClickOutside = (event) => {
    if (refOutSide.current && !refOutSide.current.contains(event.target)) {
      eventClick && eventClick();
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refOutSide]);

  return [refOutSide];
};
