import Icon from '@ant-design/icons';

const LawIcon = (props) => (
  <Icon
    component={() => (
      <svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M10.6667 1.66797L6 6.33464L1.33333 1.66797' stroke='currentColor' strokeWidth='2.0' strokeLinecap='round' strokeLinejoin='round'/>
      </svg>
      
    )}
    {...props}
  />
);

export default LawIcon;
