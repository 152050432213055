  import { useEffect, useState } from 'react';
  import { useDispatch } from 'react-redux';
  import { useLocation, useNavigate } from 'react-router-dom';
  import { getSystemSettingMaintain } from '../store/actions/maintain';
  import { convertToCurrentTime } from '../utils/utils';

  function useCheckMaintain() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [maintain, setMaintain] = useState(true);
    const handleSettingType2Success = ({ data }) => {
      const today = new Date();
      const startDate = new Date(convertToCurrentTime(data?.date_from));
      const endDate = new Date(convertToCurrentTime(data?.date_to));
      if (today >= startDate && today <= endDate) {
        setMaintain(true);
        navigate('/maintain', { state: { date_from: data?.date_from, date_to: data?.date_to } });
      } else setMaintain(false);
    };

    useEffect(() => {
      dispatch(getSystemSettingMaintain(handleSettingType2Success));
    }, [pathname]);
    return { maintain };
  }

  export default useCheckMaintain;
