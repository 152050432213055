import { LayoutCreatorRight, PopupLoading } from 'components';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { resetNotiDetail, userGetDetailNotification } from 'store/actions/notification';
import '../styles.scss';
import useLoading from 'hooks/useLoading';
import { getTitleByTypeAdmin } from 'utils/utils';
import { newPathUser } from 'constants/layout/constant';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import MarkDownRead from 'components/MarkDownRead';
import { getEnv } from 'configs/env';

export default function NotificationDetail() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dataNotify, loading: isLoading } = useSelector((state) => state.notification);
  const { id } = useParams();
  const initLoading = useLoading();
  const language = localStorage.getItem('i18nextLng');

  useEffect(() => {
    dispatch(userGetDetailNotification(id));

    return () => {
      dispatch(resetNotiDetail());
    };
  }, []);

  const getDataFromContent = () => {
    if (dataNotify?.content) {
      try {
        return JSON.parse(dataNotify?.content);
      } catch (error) {
        return {};
      }
    }
    return {};
  };

  const renderBodyContent = () => {
    if (dataNotify?.content_type === 28) {
      const data = getDataFromContent();
      let endDate = data?.end_date;
      let startDate = data?.start_date;

      if (data?.end_date) {
        if (i18n.language === 'en') {
          endDate = moment(data?.end_date, 'YYYY-MM-DD hh:mm:ss').format('YYYY/MM/DD');
        } else {
          endDate = moment(data?.end_date, 'YYYY-MM-DD hh:mm:ss').format('YYYY年MM月DD日');
        }
      }
      if (data?.start_date) {
        if (i18n.language === 'en') {
          startDate = moment(data?.start_date, 'YYYY-MM-DD hh:mm:ss').format('YYYY/MM/DD');
        } else {
          startDate = moment(data?.start_date, 'YYYY-MM-DD hh:mm:ss').format('YYYY年MM月DD日');
        }
      }

      return (
        <MarkDownRead
          content={t('Creator.NotificationList.contentType28', {
            voucherCode: data?.voucher_code,
            voucherName: data?.voucher_name,
            endDate,
            bonus: data?.bonus,
            startDate,
            priceBonus: data?.price_bonus,
            urlContact: `${getEnv('REACT_APP_FAN_URL')}/post-help`
          })}
        />
      );
    }
    if (dataNotify?.content_type === 29) {
      const data = getDataFromContent();
      let endDate = data?.end_date;
      let startDate = data?.start_date;

      if (data?.end_date) {
        if (i18n.language === 'en') {
          endDate = moment(data?.end_date, 'YYYY-MM-DD hh:mm:ss').format('YYYY/MM/DD');
        } else {
          endDate = moment(data?.end_date, 'YYYY-MM-DD hh:mm:ss').format('YYYY年MM月DD日');
        }
      }
      if (data?.start_date) {
        if (i18n.language === 'en') {
          startDate = moment(data?.start_date, 'YYYY-MM-DD hh:mm:ss').format('YYYY/MM/DD');
        } else {
          startDate = moment(data?.start_date, 'YYYY-MM-DD hh:mm:ss').format('YYYY年MM月DD日');
        }
      }

      return (
        <MarkDownRead
          content={t('Creator.NotificationList.contentType29', {
            voucherCode: data?.voucher_code,
            voucherName: data?.voucher_name,
            endDate,
            startDate,
            bonus: data?.bonus,
            priceBonus: data?.price_bonus,
            urlContact: `${getEnv('REACT_APP_FAN_URL')}/post-help`
          })}
        />
      );
    }
    if (dataNotify?.content_type === 30 || dataNotify?.content_type === 31) {
      if (language === 'en') {
        return <MarkDownRead content={dataNotify?.content_en} isPageNoti />;
      } else return <MarkDownRead content={dataNotify?.content} isPageNoti />;
    }

    return <MarkDownRead content={dataNotify?.content} />;
  };

  return (
    <LayoutCreatorRight
      titlePage={t('MenuSiderbar.creator.notification')}
      className='notification-detail-layout'
      cb={() => {
        navigate(`${newPathUser}profile/notification`, { state: { tab: 2 } });
      }}>
      <div className='creator-notification-page '>
        {(isLoading && !dataNotify) || initLoading ? (
          <div className='shimmer-loading'>
            <ShimmerPostDetails />
          </div>
        ) : (
          <div className='detail-noti'>
            <h5 className='detail-notification-title'>Sharegram</h5>
            <div className='date'>
              {moment.utc(dataNotify?.start_time).local().format('YYYY/MM/DD HH:mm')}
            </div>
            {dataNotify?.content_type === 28}
            <div
              className='title'
              dangerouslySetInnerHTML={{
                __html: getTitleByTypeAdmin(
                  +dataNotify?.title,
                  i18n.language === 'en' ? (dataNotify?.content_type === null ? dataNotify?.title : dataNotify?.title_en) : dataNotify?.title,
                  getDataFromContent()?.bonus
                ),
              }}
            />
            {renderBodyContent()}
          </div>
        )}
      </div>
    </LayoutCreatorRight>
  );
}
