import Icon from '@ant-design/icons';

const IconHome = (props) => (
  <Icon
    component={() => (
      <svg
        width={20}
        height={20}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M10 15V12.5'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.3916 2.35053L2.6166 6.97553C1.9666 7.49219 1.54994 8.58386 1.6916 9.40053L2.79994 16.0339C2.99994 17.2172 4.13327 18.1755 5.33327 18.1755H14.6666C15.8583 18.1755 16.9999 17.2089 17.1999 16.0339L18.3083 9.40053C18.4416 8.58386 18.0249 7.49219 17.3833 6.97553L11.6083 2.35886C10.7166 1.64219 9.27494 1.64219 8.3916 2.35053Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default IconHome;
