import React, { useRef, useEffect } from 'react';
import { CloseIcon } from 'images';
import './ViewFullImage.scss';
import { linkS3, linkS3Video, onErrImage, decryptPath } from '../../utils/utils';
import { ChevronLeft, ChevronRight } from 'images';
import Slider from 'react-slick';
import { useState } from 'react';
import { ImgCustom } from '..';
import { useTranslation } from 'react-i18next';

const ViewFullImage = ({ dataViewFull, close, isDefaultScreen = null }) => {
  const slideRef = useRef(null);
  const { image, listImage, initPageActive } = dataViewFull;
  const [pageActive, setPageActive] = useState(initPageActive ? initPageActive : 0);
  const { t } = useTranslation();

  const NextArrow = (props) => {
    const { className, onClick, slideCount } = props;
    return (
      <div
        style={{
          cursor: `${pageActive === dataViewFull?.listImage?.length - 1 && 'default'}`,
          opacity: `${pageActive === dataViewFull?.listImage?.length - 1 && '0.3'}`,
        }}
        className={className}
        onClick={() => {
          if (pageActive !== dataViewFull?.listImage?.length - 1) {
            onClick();
            if (pageActive + 1 === slideCount) {
              setPageActive(0);
            } else {
              setPageActive(pageActive + 1);
            }
          }
        }}>
        <ChevronRight />
      </div>
    );
  };
  const PrevArrow = (props) => {
    const { className, onClick, slideCount } = props;
    return (
      <div
        style={{
          cursor: `${pageActive === 0 && 'default'}`,
          opacity: `${pageActive === 0 && '0.3'}`,
        }}
        className={className}
        onClick={() => {
          if (pageActive !== 0) {
            onClick();
            if (pageActive + 1 === 1) {
              setPageActive(slideCount - 1);
            } else {
              setPageActive(pageActive - 1);
            }
          }
        }}>
        <ChevronLeft />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    swipe: false,
    // afterChange: (current) => {
    //   // setPageActive(current);
    //   slideRef?.current?.innerSlider?.list.setAttribute('tabindex', current);
    //   slideRef?.current?.innerSlider?.list?.focus();
    // },
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  useEffect(() => {
    slideRef?.current?.innerSlider?.list.setAttribute('tabindex', 0);
    slideRef?.current?.innerSlider?.list?.focus();
  }, [slideRef]);

  return (
    <div className='view-full-image'>
      <CloseIcon className='close-icon' onClick={close} />
      <div className={`pagination-slide ${listImage?.length === 1 && 'hidden'}`}>
        <span>
          {pageActive + 1}/{listImage?.length}
        </span>
      </div>
      {listImage?.length > 1 ? (
        <Slider ref={slideRef} {...settings} className='slide-image-full-view'>
          {listImage?.map((item, index) => (
            <div className='list-images-item' key={index}>
              {item.type === 1 || item.type === 4 ? (
                <ImgCustom
                  screen={isDefaultScreen}
                  src={item.url}
                  alt=''
                  onError={(e) => onErrImage(e, false)}
                />
              ) : (
                item.type === 2 && (
                  <video controls>
                    <source
                      src={`${linkS3Video}${decryptPath(item.url)}#t=0.001`}
                      type='video/mp4'
                    />
                    {t('messageNotSupportVideo')}
                  </video>
                )
              )}
            </div>
          ))}
        </Slider>
      ) : image?.type === 1 || image?.type === 4 ? (
        <ImgCustom
          screen={isDefaultScreen}
          src={image?.url}
          alt=''
          onError={(e) => onErrImage(e, false)}
        />
      ) : (
        image?.type === 2 && (
          <video controls>
            <source src={`${linkS3Video}${decryptPath(image.url)}#t=0.001`} type='video/mp4' />
            {t('messageNotSupportVideo')}
          </video>
        )
      )}
    </div>
  );
};

export default ViewFullImage;
