import Icon from '@ant-design/icons';

const NoSearchList = (props) => (
  <Icon
    component={() => (
      <svg
        width='100'
        height='100'
        viewBox='0 0 100 100'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M45.3125 78.125C63.4343 78.125 78.125 63.4343 78.125 45.3125C78.125 27.1907 63.4343 12.5 45.3125 12.5C27.1907 12.5 12.5 27.1907 12.5 45.3125C12.5 63.4343 27.1907 78.125 45.3125 78.125Z'
          stroke='#FCECEF'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M68.5117 68.5156L87.4963 87.5002'
          stroke='#FCECEF'
          strokeWidth='4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default NoSearchList;
