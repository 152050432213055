import Icon from '@ant-design/icons';

const NoLikeList = (props) => (
  <Icon
    component={() => (
      <svg
        width='100'
        height='100'
        viewBox='0 0 100 100'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M81.25 3.125H18.75C14.6075 3.12996 10.6361 4.77776 7.70694 7.70694C4.77776 10.6361 3.12996 14.6075 3.125 18.75V65.625C3.12996 69.7675 4.77776 73.7389 7.70694 76.6681C10.6361 79.5972 14.6075 81.245 18.75 81.25H36L47.5625 95.7031C47.8553 96.0692 48.2266 96.3648 48.6491 96.5679C49.0716 96.771 49.5344 96.8765 50.0031 96.8765C50.4719 96.8765 50.9347 96.771 51.3571 96.5679C51.7796 96.3648 52.151 96.0692 52.4437 95.7031L64 81.25H81.25C85.3925 81.245 89.3639 79.5972 92.2931 76.6681C95.2222 73.7389 96.87 69.7675 96.875 65.625V18.75C96.87 14.6075 95.2222 10.6361 92.2931 7.70694C89.3639 4.77776 85.3925 3.12996 81.25 3.125ZM90.625 65.625C90.625 68.1114 89.6373 70.496 87.8791 72.2541C86.121 74.0123 83.7364 75 81.25 75H62.5C62.0313 74.9998 61.5687 75.1051 61.1462 75.3079C60.7237 75.5108 60.3523 75.806 60.0594 76.1719L50 88.75L39.9406 76.175C39.648 75.8086 39.2767 75.5127 38.8542 75.3093C38.4317 75.1059 37.9689 75.0002 37.5 75H18.75C16.2636 75 13.879 74.0123 12.1209 72.2541C10.3627 70.496 9.375 68.1114 9.375 65.625V18.75C9.375 16.2636 10.3627 13.879 12.1209 12.1209C13.879 10.3627 16.2636 9.375 18.75 9.375H81.25C83.7364 9.375 86.121 10.3627 87.8791 12.1209C89.6373 13.879 90.625 16.2636 90.625 18.75V65.625Z'
          fill='#FCECEF'
        />
        <path
          d='M70.275 26.5871C67.6518 23.9311 64.1624 22.3049 60.4415 22.0044C56.7206 21.7039 53.0154 22.749 50 24.9496C46.9847 22.7481 43.279 21.7028 39.5578 22.0039C35.8365 22.305 32.3471 23.9326 29.725 26.5902C26.8073 29.6273 25.1778 33.6755 25.1778 37.8871C25.1778 42.0986 26.8073 46.1468 29.725 49.1839L47.1875 67.1339C47.494 67.5807 47.8968 67.953 48.3662 68.2234C48.8357 68.4938 49.3598 68.6554 49.9 68.6964C50.4297 68.691 50.951 68.5632 51.4231 68.3231C51.8953 68.0829 52.3055 67.7369 52.6219 67.3121L70.2625 49.1871C73.1823 46.1505 74.8142 42.1022 74.8166 37.8896C74.8189 33.677 73.1915 29.6268 70.275 26.5871ZM65.7969 44.8214L50 61.0527L34.2031 44.8214C32.4091 42.9581 31.4068 40.4721 31.4068 37.8855C31.4068 35.2989 32.4091 32.8129 34.2031 30.9496C35.1019 30.0545 36.1724 29.3504 37.3503 28.8797C38.5282 28.409 39.7891 28.1814 41.0573 28.2106C42.3254 28.2397 43.5745 28.5251 44.7295 29.0495C45.8845 29.5739 46.9215 30.3265 47.7781 31.2621C48.3765 31.834 49.1723 32.1531 50 32.1531C50.8277 32.1531 51.6235 31.834 52.2219 31.2621C53.079 30.3273 54.1161 29.5754 55.2711 29.0515C56.426 28.5276 57.6749 28.2425 58.9428 28.2133C60.2107 28.1841 61.4714 28.4115 62.6492 28.8817C63.827 29.3519 64.8977 30.0553 65.7969 30.9496C67.5909 32.8129 68.5932 35.2989 68.5932 37.8855C68.5932 40.4721 67.5909 42.9581 65.7969 44.8214Z'
          fill='#FCECEF'
        />
      </svg>
    )}
    {...props}
  />
);

export default NoLikeList;
