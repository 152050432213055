import Icon from '@ant-design/icons';

const CopyIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width='24'
        height='24'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M13.125 13.125H16.875V3.125H6.875V6.875'
          stroke={props?.color || '#dc143c'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.1249 6.875H3.12492V16.875H13.1249V6.875Z'
          stroke={props?.color || '#dc143c'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default CopyIcon;
