import styled from 'styled-components';

export const FieldChangedStyle = styled.div`
  background-color: #dc143c;
  position: absolute;
  border-radius: ${({ borderRadius }) => borderRadius ?? '10px'};
  height: ${({ height }) => height ?? '36px'};
  width: ${({ width }) => width ?? '36px'};
  z-index: 20;
  right: 10px;
  top: ${({ top }) => top ?? '10px'};
  bottom: ${({ bottom }) => bottom ?? '36px'};
  display: flex;
  align-items: center;
  justify-content: center;
`;
