import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import MetaTagViewer from 'components/MetaTagViewer/MetaTagViewer';
import { useSelector, useDispatch } from 'react-redux';
import { urlRegex } from 'utils/regex';
import { getDetailPostMeta } from 'store/actions/postMeta';

const Wrapper = styled.div`
  padding-right: 8px;
  //max-height: ${({ isFullContent }) => (isFullContent ? '150px' : '66px')};
  overflow: ${({ isFullContent }) => (isFullContent ? 'auto' : 'hidden')};
  white-space: pre-line;
  word-break: break-word;
  .btn-read {
    color: #1480ff;
    cursor: pointer;
    &.btn-read-less {
      position: absolute;
      right: 8px;
      bottom: 0;
    }
    &.btn-read-more {
      display: ${({ isShowMore }) => (isShowMore ? 'inline-block' : 'none')};
    }
  }
`;

export const ReadMoreHomePage = ({ content = '', charLimit = 0 }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isFullContent, setIsFullContent] = useState(false);
  const hasUrl = useMemo(() => urlRegex.test(content), [content]);

  const newContent = useMemo(() => {
    const lines = content.split('\n');
    const firstTwoLines = lines.slice(0, 3).join('\n');
    return content
      ? content?.split('\n')?.length > 3
        ? firstTwoLines?.slice(0, charLimit)
        : content.slice(0, charLimit)
      : '';
  }, [charLimit, content]);

  useEffect(() => {
    if (hasUrl) {
      const match = newContent.match(/\/post-sale\/(\d+)/);
      if (match) {
        const id = match[1];
        dispatch(getDetailPostMeta(id));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasUrl]);

  const { postDetail } = useSelector((state) => state.postMeta);

  return (
    <>
      <Wrapper isFullContent={isFullContent} isShowMore={content?.length > charLimit}>
        {isFullContent ? (
          <>{content}</>
        ) : (
          <>
            {newContent}
            {hasUrl &&
              newContent.match(/\/post-sale\/(\d+)/) &&
              postDetail?.data !==
                null(
                  <MetaTagViewer
                    title={postDetail?.data?.title}
                    url={content}
                    img={postDetail?.data?.thumbnail}
                  />
                )}
          </>
        )}
      </Wrapper>
      {!isFullContent && (content?.split('\n')?.length > 3 || content?.length > charLimit) && (
        <div
          style={{ color: '#1480ff', cursor: 'pointer' }}
          className='btn-read btn-read-more'
          onClick={() => {
            setIsFullContent((prev) => !prev);
          }}>
          {t('ReadMoreLess.moreText')}
        </div>
      )}
      {/* {isFullContent && (
        <div
          style={{ color: '#1480ff', cursor: 'pointer' }}
          className='btn-read btn-read-less'
          onClick={() => {
            setIsFullContent((prev) => !prev);
          }}>
          {t('ReadMoreLess.lessText')}
        </div>
      )} */}
    </>
  );
};
