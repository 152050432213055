import Icon from '@ant-design/icons';

const NewCrown = (props) => (
  <Icon
    component={() => (
      <svg
        version='1.1'
        id='Layer_1'
        xmlns='http://www.w3.org/2000/svg'
        width={20}
        height={20}
        xmlnsXlink='http://www.w3.org/1999/xlink'
        // x='0px'
        // y='0px'
        viewBox='0 0 24 24'
        style={{ enableBackground: 'new 0 0 24 24' }}
        xmlSpace='preserve'
       >
        <g>
          <path
            d='M18,18.3H6c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1H18
  c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1-0.1-0.2-0.1-0.3C18.2,18.3,18.1,18.3,18,18.3z'
          />
          <path
            d='M22,7.1L22,7.1C22,7.1,21.9,7,21.8,7c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0.1l-5.5,4L12.4,5c-0.1-0.1-0.2-0.2-0.4-0.2
  c0,0,0,0,0,0c-0.2,0-0.3,0.1-0.4,0.2L8,11.1l-5.5-4C2.4,7.1,2.4,7,2.2,7c0,0,0,0,0,0C2.1,7,2,7.1,2,7.1C1.9,7.2,1.8,7.3,1.8,7.4
  c0,0.1,0,0.2,0,0.3l3.8,9.7c0,0.1,0.1,0.2,0.2,0.2c0.1,0.1,0.2,0.1,0.3,0.1H18c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.1,0.2-0.2
  l3.8-9.7c0-0.1,0-0.2,0-0.3C22.2,7.3,22.1,7.2,22,7.1z M20.7,8.8l-3.1,7.9H6.4L3.3,8.8l4.6,3.4c0.1,0,0.1,0.1,0.2,0.1
  c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.1L12,6.2l3.4,5.9c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1
  c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1L20.7,8.8z'
          />
        </g>
      </svg>
    )}
    {...props}
  />
);

export default NewCrown;
