import { LayoutCreatorRight, PopupConfirmFan, PopupLoading } from 'components';
import { newPathUser } from 'constants/layout/constant';
import withCreatorRole from 'hocs/withCreatorRole';
import { ArrowLeftIcon } from 'images';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { getListCategoryFeedBack, PostFeedBack, resetStatus } from 'store/actions/feedback';
import { CustomOption, customStyles } from 'utils/utils';
import '../styles.scss';
import { isMobile } from 'react-device-detect';

const PostHelpCreator = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, handleSubmit, watch, control } = useForm({});
  const [focusCategory, setFocusCategory] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isSearchable] = useState(false);
  const watchText = watch('question_content', '');
  const watchCategory = watch('category_content', '');
  const { t, i18n } = useTranslation();
  const {
    listCategoryFeedsBack = [],
    loading,
    status,
  } = useSelector((state) => state.feedback);
  const optionListContent = listCategoryFeedsBack.map((item) => ({
    ...item,
    value: item?.id,
    label: item?.name,
  }));

  const onSubmit = (data) => {
    const newData = {
      ...data,
      category_title: t('Creator.Post.category'),
      question_title: t('Help.inquiry'),
    };
    dispatch(PostFeedBack(newData));
  };

  useEffect(() => {
    dispatch(getListCategoryFeedBack(i18n.language));

    return () => dispatch(resetStatus());
  }, [dispatch, i18n.language]);

  useEffect(() => {
    status === true && setIsOpenModal(true);
  }, [status]);

  const customStylesSelect = {
    ...customStyles,

    control: (base, state) => ({
      ...base,
      width: '100%',
      maxHeight: 60,
      boxShadow: 'none',

      borderColor: state.isFocused ? '#1480FF' : '#E5E6EB',
      svg: {
        color: '#666565',
        transform: state.menuIsOpen ? 'rotate(180deg)' : '',
      },
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      display: 'block',
      paddingRight: '20px',
    }),
    menu: (base, state) => ({
      ...base,
      width: `${isMobile ? '100%' : '50%'}`,
      border: 'none !important',
      borderRadius: '8px',
      overflow: 'hidden',
      wordBreak: 'break-word',
      zIndex: 16,
      position: 'absolute',
      left: '0',
      boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.06)',
    }),
    option: (base, state) => {
      return {
        ...base,
        position: 'relative',
        backgroundColor: state.isSelected ? '#FFDBDB' : 'transparent',
        color: '#1A1A1A',

        '&.content-right': {
          color: '#DC143C',
        },
        svg: {
          color: '#DC143C',
          display: state.isSelected ? 'block' : 'none',
          position: 'absolute',
          right: -12,
          alignItems: 'center',
          margin: 'auto',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          marginLeft: '10px',
        },
        fontWeight: state.isSelected ? 500 : 400,
        padding: '12px 24px 12px 16px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '100%',
        display: 'block',
      };
    },
    placeholder: (base) => ({
      ...base,
      fontWeight: 400,
      fontSize: 14,
      color: '#B1b6bb',
      position: 'absolute',
      padding: '2px 2px 2px 0',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      return {
        ...provided,
        opacity,
        fontWeight: 500,
        fontSize: 14,
        position: 'absolute',
        padding: '2px 2px 2px 0',
        color: '#282727',
      };
    },
    dropdownIndicator: (base) => ({
      ...base,
      display: 'block',
    }),
  };

  return (
    <LayoutCreatorRight
      className='help-header help-post'
      linkBack={`${newPathUser}help`}
      titlePage={t('Help.inquiry')}>
      <div className='question-container'>
        {/* {loading && <PopupLoading className={'popup-loading'} />} */}
        {isOpenModal && (
          <PopupConfirmFan
            text={t('Popup.weWillContact')}
            colorSvg='#28c76f'
            className='popup-success-post-help'>
            <div
              className='ok btn'
              onClick={() => {
                if (status) {
                  setIsOpenModal(false);
                  navigate(`${newPathUser}help`);
                }
              }}>
              {t('Common.Ok')}
            </div>
          </PopupConfirmFan>
        )}
        <div className='container-fluid w-100 bg-white creator-post-help'>
          <form onSubmit={(e) => e.preventDefault()} className='fansite-form'>
            <div className='title mb-3'>
              <label>{t('Creator.Post.category')}</label>
              <div className='category'>
                <Controller
                  name='category_content'
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      value={[...optionListContent]?.find((e) => e?.id === watchCategory?.id)}
                      options={listCategoryFeedsBack?.length > 0 ? [...optionListContent] : []}
                      onFocus={() => setFocusCategory(true)}
                      onBlur={() => setFocusCategory(false)}
                      onClick={(e) => setFocusCategory(false)}
                      onChange={(val) => {
                        field.onChange(val);
                        setFocusCategory(false);
                      }}
                      noOptionsMessage={() => {
                        t('Common.listNull');
                      }}
                      placeholder={t('Creator.Post.selectCategory')}
                      isSearchable={isSearchable}
                      styles={customStylesSelect}
                      components={{
                        Option: CustomOption,
                        DropdownIndicator: () => <ArrowLeftIcon />,
                      }}
                    />
                  )}
                />
                {/* <ArrowLeftIcon className={`${focusCategory ? 'focus' : 'no-focus'}`} /> */}
              </div>
            </div>
            <div className='title mb-4'>
              <label>{t('Help.inquiry')}</label>
              <textarea
                {...register('question_content')}
                placeholder={t('Help.form.placholderQuestion')}
                className='question-content'
              />
            </div>
            <div className={`button-submit`}>
              <button
                className='button-red-new'
                type='submit'
                onClick={handleSubmit(onSubmit)}
                disabled={!watchCategory || !watchText.trim()}>
                {t('Common.send')}
              </button>
              <button className='cancel' onClick={() => navigate(`${newPathUser}help`)}>
                {t('Common.buttonCancel')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </LayoutCreatorRight>
  );
};

export default withCreatorRole(PostHelpCreator);
