import { useState } from 'react';
import { DATA_NAVBAR_AF004 } from 'utils/utils';
import './index.scss';
const NavbarSalesManagement = ({ tabActive }) => {
  const [active, setActive] = useState(DATA_NAVBAR_AF004[0].title);
  return (
    <ul className='header'>
      {DATA_NAVBAR_AF004.map(({ title }) => (
        <li
          className={`${active === title && 'active'}`}
          onClick={() => {
            setActive(title);
            tabActive(title);
          }}>
          {title}
        </li>
      ))}
    </ul>
  );
};

export default NavbarSalesManagement;
