import { decryptPath, funcThumbnailIMG, linkS3Video } from 'utils';
import { StyledTimeline } from './styled';
import { ImgCustom } from 'components';
import { PlayIcon } from 'images';
import { useDispatch } from 'react-redux';
import { getPostDetailNoStore } from 'store/actions/myPage';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import { isMobile, isSafari, isTablet } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { LoadingImg } from 'components/LiveStream/LoadingImg';
import useCheckFullVideo from 'hooks/v2/useCheckFullVideo';
import { useSelector } from 'react-redux';

const WrapperHideIconVideo = styled.div`
  video::-webkit-media-controls-overlay-play-button {
    ${({ isFull = false }) =>
      isFull
        ? css`
            display: block;
          `
        : css`
            display: none;
          `};
  }

  *::-webkit-media-controls-start-playback-button {
    ${({ isFull = false }) =>
      isFull
        ? css`
            display: block;
          `
        : css`
            display: none !important;
            -webkit-appearance: none;
          `};
  }
`;

export const VideoInList = ({
  tmpData = [],
  item,
  isMiniUrl = () => {},
  handleNavigate = () => {},
  typeShow = 'full_items',
  isMini
}) => {
  const dispatch = useDispatch();
  const [isPlaying, setIsPlaying] = useState(false);
  const [controlCustom, setControlCustom] = useState(false);
  const [runFirstLoadVideo, setRunFirstLoadVideo] = useState(false);
  const [loading, setLoading] = useState(false);
  const videoRef = useRef();
  const [isFullScreen, setIsFullScreen] = useCheckFullVideo(videoRef);
  const containerRef = useRef();
  const thumbnailTMP = item?.medias?.filter(
    (item) => item?.type === 4 && item?.public_flag === 1
  );
  const { dataUser } = useSelector((state) => state.users);
  const getVideo = () => {
    if (tmpData[0]?.type === 2) {
      setLoading(true);
      dispatch(
        getPostDetailNoStore({
          id: item?.id,
          onSuccess: (res) => {
            setRunFirstLoadVideo(true);
            const srcTMP = item?.medias?.filter(
              (item) => item?.type === 2 && item?.public_flag === 1
            );
            const urlVideo = srcTMP && srcTMP[0].url;
            videoRef.current.src = `${linkS3Video}${decryptPath(urlVideo)}#t=0.001`;
            setIsPlaying(true);
            videoRef.current.play();
            setLoading(false);
          },
        })
      );
    }
  };

  const checkFollow = (item) => {
    if ((item?.user_id !== dataUser?.id && item?.is_followed === 0 && item?.follow_flag === 1) || (item?.follow_flag === 0 && item?.is_media_display) === 0) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    const video = videoRef?.current;
    if (checkFollow(item)) {
      video?.pause();
    }
  }, [item]);

  const pauseOtherVideos = () => {
    const videos = document.querySelectorAll('video');

    videos.forEach((video) => {
      if (video !== videoRef.current) {
        video?.pause();
        video.muted = true;
        setIsPlaying(false);
      }
    });
  };

    const handleIntersection = (entries) => {
    const [entry] = entries;
    const video = videoRef?.current;

    var isPlaying =
      videoRef?.current &&
      video.currentTime > 0 &&
      !video.paused &&
      !video.ended &&
      video.readyState > video.HAVE_CURRENT_DATA;
    if(isSafari) {
      video?.setAttribute('playsinline', '');
    }
    if (entry?.isIntersecting && videoRef?.current && !checkFollow(item)) {
      // Tạm dừng tất cả các video khác trước khi phát video hiện tại
      pauseOtherVideos();
      if (!isPlaying) {
        setTimeout(() => {
          video.play().catch((error) => {
            console.error('Error playing video:', error);
          });
        }, 10);
        //video.muted = true;
        setIsPlaying(true);
      }
    } else if (videoRef?.current) {
      video?.pause();
      //video.muted = true;
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 1,
    });
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

  const chooseUIImage = {
    full_items: (
      <StyledTimeline.BlueImg
        url={`${decryptPath(tmpData[0].url, { isMini: true })}`}
        height={`${isMobile ? 319 : 319}`}
        width={`${isMobile ? 185 : 319}`}>
        <div className='bg-img'></div>
        <ImgCustom
          // isMini={isMiniUrl()}
          className='set-w-h-img'
          screen={'post_319_auto_pc'}
          src={tmpData[0]?.url}
          alt=''
        />
      </StyledTimeline.BlueImg>
    ),
    three_items: (
      <StyledTimeline.BlueImg
        url={`${decryptPath(tmpData[0].url, { isMini: true })}`}
        height={`${isMobile ? 343 : 640}`}
        width={`${isMobile ? 185 : 319}`}>
        <div className='bg-img'></div>
        <ImgCustom
          className='secondary-img set-w-h-img'
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'contain',
            position: 'absolute',
            zIndex: '11',
            background: 'transparent',
          }}
          // isMini={isMiniUrl()}
          screen={'post_319_auto_pc'}
          src={tmpData[0]?.url}
          alt=''
        />
      </StyledTimeline.BlueImg>
    ),
  };
  const chooseCSSVideo = {
    full_items: {},
    three_items: {
      inset: '0',
      margin: 'auto',
    },
  };

  return (
    <div
      ref={containerRef}
      className='position-relative w-100'
      style={{
        cursor: 'pointer',
        zIndex: '11',
        background: tmpData[0]?.type === 2 && '#000',
        width: '100%',
        height: '100%',
      }}
      onClick={() => {
        if (!isPlaying) {
          const video = videoRef.current;
          if (video) {
            video.controls = !video.controls;
          }
          setControlCustom(false);
          handleNavigate(item?.type, item?.id);
        }
      }}>
      {tmpData[0]?.type === 1 ? (
        chooseUIImage[typeShow]
      ) : (
        <WrapperHideIconVideo style={{ height: '70%' }}>
          {funcThumbnailIMG(decryptPath(tmpData[0]?.url)) ? (
            <>
              {!isPlaying && controlCustom && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    handleNavigate(item?.type, item?.id);
                  }}
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '70%',
                    zIndex: 32,
                    top: 0,
                  }}></div>
              )}
              <video
                poster={`${
                  thumbnailTMP?.length === 0
                    ? isMini ? funcThumbnailIMG(decryptPath(tmpData[0]?.url, { isMini: true })) : funcThumbnailIMG(decryptPath(tmpData[0]?.url))
                    : isMini ? decryptPath(item?.medias[0]?.url, { isMini: true }) + '#t=0.1'  : decryptPath(item?.medias[0]?.url) + '#t=0.1'
                }`}
                ref={videoRef}
                autoplay
                muted
                src={`${
                  isFirefox
                    ? `${linkS3Video}${decryptPath(tmpData[0]?.url)}`
                    : `${linkS3Video}${decryptPath(tmpData[0]?.url) + '#t=0.1'}`
                }`}
                preload='none'
                style={{
                  height: '100%',
                  width: '100%',
                  objectFit: 'contain',
                  position: 'absolute',
                  zIndex: '10',
                  background: 'transparent',
                  ...chooseCSSVideo[typeShow],
                }}
                playsInline
                controls={isFullScreen ? true : controlCustom}
                controlsList='nodownload noremoteplayback noplaybackrate foobar'
                disablePictureInPicture
                onPlay={() => {
                  videoRef.current.play();
                  setIsPlaying(true);
                  setControlCustom(true);
                }}
                onPause={(e) => {
                  if (videoRef.current.seeking) return;
                  setControlCustom(true);
                  videoRef.current.pause();
                  setIsPlaying(false);
                }}>
                Your browser does not support the video tag.
              </video>
              {!isFullScreen && (
                <StyledTimeline.ButtonPlay
                  widthButton='56px'
                  heightButton='56px'
                  onClick={(e) => {
                    e.stopPropagation();
                    // if (runFirstLoadVideo) {
                    if (isPlaying) {
                      setControlCustom(true);
                      setIsPlaying(false);
                      videoRef.current.pause();
                    } else {
                      setControlCustom(true);
                      setIsPlaying(true);
                      videoRef.current.play();
                    }
                    // } else {
                    //   getVideo();
                    // }
                  }}>
                  {!isPlaying &&
                    (loading ? (
                      <LoadingImg
                        styleMore={{
                          margin: 0,
                          height: '48px',
                        }}
                      />
                    ) : (
                      <PlayIcon
                        style={{
                          filter: 'drop-shadow(0px 0px 0.5px #000)',
                        }}
                      />
                    ))}
                </StyledTimeline.ButtonPlay>
              )}
            </>
          ) : (
            <video
              width='100%'
              height='100%'
              preload='none'
              controlsList='nodownload'
              src={linkS3Video + decryptPath(tmpData[0]?.url) + '#t=0.1'}></video>
          )}
        </WrapperHideIconVideo>
      )}
    </div>
  );
};
