import { LayoutCreatorRight, LayoutFan, PopupLoading } from 'components';
import PopupConfirm from 'components/Popup/confirmSuccess';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fanReplySurvey, getDetailSurvey } from 'store/actions/survey';
import './styles.scss';
import { newPathUser } from 'constants/layout/constant';

const SurveyDetailScreen = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { handleSubmit } = useForm({
    defaultValues: {
      questions: [],
    },
  });
  const [arr, setArr] = useState({});
  const dispatch = useDispatch();
  const { id } = useParams();
  const { t } = useTranslation();

  const data = useSelector((state) => state.survey.data);
  const loading = useSelector((state) => state.survey.loading);
  const [otherAnswer, setOtherAnswer] = useState({});
  const [expired, setExpired] = useState(false);
  const [confirmSuccess, setConfirmSuccess] = useState(false);

  useEffect(() => {
    dispatch(getDetailSurvey(id, handleSetData));
    // !state && navigate('/*');
  }, []);

  const handleSetData = (dataSurvey) => {
    const endTime = moment.utc(dataSurvey?.end_date).local().format('YYYY/MM/DD HH:mm');
    const nowTime = moment().format('YYYY/MM/DD HH:mm');
    if (!dataSurvey?.is_done && endTime < nowTime) {
      return setExpired(true);
    }
    const listQuestion = {};
    const listOther = {};
    for (const question of dataSurvey?.survey_questions) {
      listQuestion[question?.id] = [];
      for (const answer of question?.survey_answers) {
        if (answer?.type === 2) {
          listOther[answer?.id] = '';
        }
      }
    }
    setArr(listQuestion);
    setOtherAnswer(listOther);
  };

  const onSubmit = async () => {
    const params = [];
    for (const [key, value] of Object.entries(arr)) {
      const other = value.find((value) => otherAnswer[value]) || null;

      params.push({
        id: key,
        answer_id: value,
        other: other ? otherAnswer[other] : null,
      });
    }
    dispatch(fanReplySurvey(id, { questions: params }, handleReplySuccess));
  };

  const handleReplySuccess = ({ data, success }) => {
    if (success) {
      setConfirmSuccess(true);
    }
  };

  const handleConfirmExpired = () => {
    setExpired(false);
    navigate(-1);
  };

  const isExist = Object.values(arr).find((value) => value.length);

  return (
    <>
      {expired && (
        <PopupConfirm
          title={t('Popup.noRepBecauseExpried')}
          isWarning
          closeModal={handleConfirmExpired}
          isMobile={true}
        />
      )}
      {confirmSuccess && (
        <PopupConfirm
          title={t('Creator.Survey.confirmAnswer')}
          closeModal={() => {
            setConfirmSuccess(false);
            navigate(0);
          }}
        />
      )}
      {loading && <PopupLoading className={'popup-loading'} />}
      <LayoutCreatorRight
        className='survey-detail-page-header'
        titlePage={state?.title}
        classContainer={'bg-grey'}>
        <div className={`survey-detail-page`}>
          <div className='expired-date'>
            {t('Survey.term')} {moment.utc(data?.end_date).local().format('YYYY/MM/DD HH:mm')}
          </div>
          <form className='form' onSubmit={(e) => e.preventDefault()}>
            {data?.survey_questions?.map((question, i) => (
              <div key={i} className='list-questions'>
                <div className='question-text'>{question?.title}</div>
                {question?.survey_answers?.map((ans) => (
                  <div key={ans?.id}>
                    <div className='choice'>
                      <label className='custom-checkbox-label'>
                        <input
                          disabled={data?.is_done || expired}
                          type='checkbox'
                          className='input'
                          checked={ans?.users[0]}
                          onClick={(e) => {
                            if (e.target.checked) {
                              const newAnswerIds = [...arr[question.id], ans?.id];
                              setArr({ ...arr, [question?.id]: newAnswerIds });
                            } else {
                              const newAnswerIds = [...arr[question.id]];
                              const indexId = newAnswerIds?.findIndex((id) => id === ans?.id);
                              newAnswerIds.splice(indexId, 1);
                              setArr({ ...arr, [question?.id]: newAnswerIds });
                            }
                          }}
                        />
                        <span
                          className={`checkmark ${
                            data?.is_done || expired ? 'disabled-select' : ''
                          }`}></span>
                        <label
                          className={`${data?.is_done || expired ? 'disabled-select' : ''}`}>
                          {ans?.title || t('Survey.others')}
                        </label>
                        {ans?.type === 2 && (
                          <input
                            maxLength={255}
                            disabled={data?.is_done || expired}
                            className={`input-other ${
                              data?.is_done || expired ? 'disabled-select' : ''
                            }`}
                            defaultValue={ans?.users[0]?.pivot?.other_answer}
                            onChange={(e) => {
                              setOtherAnswer({
                                ...otherAnswer,
                                [ans?.id]: e.target.value,
                              });
                            }}
                          />
                        )}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            ))}
            <div className='footer-btn'>
              {!data?.is_done ? (
                <>
                  <button
                    disabled={!isExist || data?.is_done || expired}
                    className={`btn-answer ${
                      !isExist || data?.is_done || expired ? `disabled` : `active`
                    }`}
                    type='submit'
                    onClick={handleSubmit(onSubmit)}>
                    {t('Survey.buttonQuestionnaire')}
                  </button>
                  <button className={`btn-answer btn-back`} onClick={() => navigate(-1)}>
                    {t('Common.buttonCancel')}
                  </button>
                </>
              ) : (
                <button className={`btn-answer btn-cancel`} onClick={() => navigate(-1)}>
                  {t('Common.return')}
                </button>
              )}
            </div>
          </form>
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default SurveyDetailScreen;
