import React, { useRef, useEffect, useLayoutEffect } from 'react';
import {
  CloseIcon,
  CommentIcon,
  Edit3Icon,
  FlagIcon,
  FlowUnFlowIcon,
  HeartActiveIcon,
  HeartIcon,
  IconBookmark,
  RePostIcon,
  SendChatIcon,
  ShareIcon,
  ThreePointIcon,
  TrashIcon,
  UnFlowIcon,
} from 'images';

import { convertToCurrentTime, formatNumber, isAdmin } from '../../utils/utils';
import { useState, useCallback } from 'react';
import { ImgCustom, PopupConfirmFan, PopupSharePost, ReadMoreLess, Textarea } from '..';
import { useTranslation } from 'react-i18next';
import useUserRole from 'hooks/useUserRole';
import { useSelector } from 'react-redux';
import CommentPostTimeLine from 'components/comment/commentPostTimeLine';
import { InputWrapper } from 'components/Input/InputWrapper';
import Avatar from 'images/Avatar.png';
import { useDispatch } from 'react-redux';
import {
  creatorDeleteArticle,
  postBookMarkDetail,
  postLikeDetail,
  postCommentDetail,
  deleteCommentDetail,
  deleteReplyCommentDetail,
  likeCommentDetail,
  likeReplyCommentDetail,
  postFollowCommentDetail,
  postFollowReplyCommentDetail,
  creatorGetPostDetail,
  fanFollowCreator,
  updateLikeCommentDetail,
  updateLikeReplyComment,
  updatePostLikeDetail,
} from 'store/actions/usersNew';
import useRedirect from 'hooks/useRedirect';
import { getEnv } from 'configs/env';
import './PopupViewFullMess.scss';
import { newPathUser } from 'constants/layout/constant';
import { useNavigate } from 'react-router-dom';
import AvatarPlaceholder from 'images/avatarPlaceholder.png';
import { AVATAR_DEFAULT, STORAGE } from 'utils';
import { logUserProfile } from 'store/actions/myPage';
import Storage from 'utils/storage';

const PopupViewFullMess = ({ item, close }) => {
  const { t } = useTranslation();
  const widthScreen = window.innerWidth;
  const { isIdol } = useUserRole();
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const [popupDeleteComment, setPopupDeleteComment] = useState(null);
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const { detailPost, statusPostComment, error } = useSelector((state) => state.usersNew);
  const dataProfile = useSelector((state) => state.users.dataUser);
  const { dataUser } = useSelector((state) => state.users);
  const [valueComment, setValueComment] = useState('');
  const [checkSendChat, setCheckSendChat] = useState(null);
  const [isVisiblePopup, setIsVisiblePopup] = useState(-1);
  const [flgRender, setFlgRender] = useState(false);
  const [widthContent, setWidthContent] = useState();
  const [isVisiblePopUpDeletePost, setIsVisiblePopDeletePost] = useState(null);
  const [isVisiblePopUpDeletePostComplete, setIsVisiblePopDeletePostComplete] =
    useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(creatorGetPostDetail(item?.id, false));
  }, []);

  const dispatch = useDispatch();

  useRedirect(error);

  const handleLikePost = (post) => {
    dispatch(updatePostLikeDetail(post));
  };

  const handlePostBookMark = (post) => {
    dispatch(postBookMarkDetail(post?.id));
  };

  const handleSharePost = (dataPC) => {
    setPopupCopyLink(dataPC);
  };

  const GroupIcon = (comment) => {
    return (
      <div
        className={`GroupIcon scroll-bar-custom-horizontal ${
          item?.is_followed === 0 && item?.follow_flag === 1 && 'disabled'
        } ${isAdmin && 'disabled'}`}
        style={{ padding: '0 16px' }}>
        <div className={`d-middle-popup-all ${checkDisabledAction(item) && 'disabled'}`}>
          <div className='d-flex align-items-center justify-content-center'>
            {detailPost?.is_liked === 1 ? (
              <>
                {/* đã like */}
                <HeartActiveIcon
                  className={`like-icon active`}
                  onClick={() => {
                    if (!isAuth) {
                      dispatch(logUserProfile());
                    } else {
                      !checkDisabledAction(item) && handleLikePost(detailPost);
                    }
                  }}
                />
              </>
            ) : (
              <>
                {/* chưa like */}
                <HeartIcon
                  className={`like-icon`}
                  onClick={() => {
                    if (!isAuth) {
                      dispatch(logUserProfile());
                    } else {
                      !checkDisabledAction(item) && handleLikePost(detailPost);
                    }
                  }}
                />
              </>
            )}
            <p className='mb-0 cls-text'>{formatNumber(detailPost?.number_like)}</p>
          </div>

          <div className='d-middle'>
            <label
              style={{ display: 'flex', alignItems: 'center' }}
              htmlFor={`comment-item-${item?.id}`}>
              <CommentIcon className='like-icon' />
              <p className='mb-0 cls-text'>{formatNumber(detailPost?.number_comment) || 0}</p>
            </label>
          </div>
          <ShareIcon
            onClick={() => {
              if (!isAuth) {
                dispatch(logUserProfile());
              } else {
                handleSharePost({
                  link: `${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${item?.id}`,
                  title: item?.title,
                  id: item?.id,
                });
              }
            }}
            className='like-icon'
          />
        </div>
        <IconBookmark
          style={{
            color: detailPost?.is_bookmarked === 1 ? '#dc143c' : '#787880',
          }}
          fill={detailPost?.is_bookmarked === 1 ? '#dc143c' : 'none'}
          onClick={() => {
            if (!isAuth) {
              dispatch(logUserProfile());
            } else {
              !checkDisabledAction(item) && handlePostBookMark(item);
            }
          }}
          className='cls-icon mx-0'
        />
      </div>
    );
  };

  const handleDeleteComment = () => {
    const { id, type, idParent } = popupDeleteComment;
    type === 'comment'
      ? dispatch(deleteCommentDetail(id))
      : dispatch(deleteReplyCommentDetail(id, idParent));
    setPopupDeleteComment(false);
  };

  const handleLikeComment = (data) => {
    const { commentId, type, dataPost } = data;
    type === 'comment'
      ? dispatch(updateLikeCommentDetail(commentId, dataPost))
      : dispatch(updateLikeReplyComment(commentId, dataPost));
  };

  const handleFollowUserComment = (data) => {
    const { id, type } = data;
    type === 'comment'
      ? dispatch(postFollowCommentDetail(id))
      : dispatch(postFollowReplyCommentDetail(id));
  };

  const handleTextarea = (e, id, height) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    setValueComment(e.target.value);
    setCheckSendChat(id);
    if (e.target.value?.toString().trim() === '') {
      e.target.style.height = height;
      e.target.style.padding = '9px  30px  9px 12px';
      setCheckSendChat(null);
    }
  };

  // ESC FUNC
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setCheckSendChat(null);
      setValueComment('');
      const textareaId = document.getElementById('textarea-post-comment-full');
      if (textareaId) textareaId.style.height = '44px';
    }
  }, []);

  useRedirect(error);
  // START FUNC ESC
  useEffect(() => {
    if (statusPostComment) {
      setCheckSendChat(null);
      setValueComment('');
      const textareaId = document.getElementById('textarea-post-comment-full');
      if (textareaId) textareaId.style.height = '44px';
    }
    document.addEventListener('keydown', escFunction);
    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction, statusPostComment]);

  const handlePostComment = (id, parent_id) => {
    const data = {
      parent_id: parent_id,
      content: valueComment,
    };
    checkSendChat && dispatch(postCommentDetail(id, data));
  };

  const navigateByLink = (postId, type) => {
    navigate(
      `${newPathUser}post${
        Boolean(type === 2) ? '-sale' : Boolean(type === 3 || type === 4) ? '' : ''
      }/update/${postId}`
    );
  };

  const deletePostTimeLine = () => {
    setIsVisiblePopDeletePost(null);
    const { id } = isVisiblePopUpDeletePost;
    dispatch(creatorDeleteArticle(id, () => setIsVisiblePopDeletePostComplete(true)));
    close();
  };

  useLayoutEffect(() => {
    const heightContent = document?.querySelector('.div-content-mess')?.offsetHeight;
    setWidthContent(heightContent);
  }, [flgRender]);

  const handleFollowCreator = (dt) => dispatch(fanFollowCreator(dt.user_id));

  const checkDisabledAction = (item) => {
    if (item?.is_followed === 0 && item?.follow_flag === 1) {
      return true;
    }
    return false;
  };

  return (
    <>
      {popupCopyLink && (
        <PopupSharePost
          isVisiblePopup={() => setPopupCopyLink(false)}
          data={popupCopyLink || {}}
        />
      )}
      {isVisiblePopUpDeletePost && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePost(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.confirmDeletePost')}>
          <div className='btn cancel' onClick={() => setIsVisiblePopDeletePost(null)}>
            {t('Common.buttonNo')}
          </div>
          <div className='ok btn' onClick={deletePostTimeLine}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpDeletePostComplete && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePostComplete(e)}
          iconNote={true}
          text={t('Popup.deletePostSucess')}>
          <div className='ok btn' onClick={() => setIsVisiblePopDeletePostComplete(false)}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {popupDeleteComment && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupDeleteComment(false)}
          colorSvg='#FF9F43'
          text={t('Popup.confirmDeleteComment')}
          className='popup-delete'>
          <div className='ok btn' onClick={handleDeleteComment}>
            {t('Common.buttonDelete')}
          </div>
          <div className='btn cancel' onClick={() => setPopupDeleteComment(false)}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}
      <div className='popup-wrap-mess'>
        <div className='card-item--detail left-content-comment-mess'>
          <div className='close-icon'>
            <CloseIcon onClick={close} />
          </div>
          <div
            style={{
              display: 'flex',
              gap: '12px',
              position: 'relative',
              padding: '16px',
              minHeight: '88px',
            }}>
            <ImgCustom
              onClick={() => navigate(`${newPathUser}${detailPost?.account_id}`)}
              isPlaceholderAvatar
              screen='avatar_44_44'
              style={{
                minWidth: 44,
                maxWidth: 44,
                minHeight: 44,
                maxHeight: 44,
                borderRadius: '50%',
                objectFit: 'cover',
              }}
              src={detailPost?.avatar ? detailPost?.avatar : AvatarPlaceholder}
              alt=''
              className='cls-img'
            />
            {/* <img
              style={{
                minWidth: 44,
                maxWidth: 44,
                minHeight: 44,
                maxHeight: 44,
                borderRadius: '50%',
                objectFit: 'cover',
              }}
              width={44}
              height={44}
              src={detailPost?.avatar ? detailPost?.avatar : AvatarPlaceholder}
              alt=''
              className='cls-img'
            /> */}
            <div className='wrap--text'>
              <p className='mb-0 cls-title' style={{ marginTop: '-3px' }}>
                {detailPost?.account_name}
              </p>
              <p className='cls-title-2nd' style={{ marginTop: '-5px' }}>
                {convertToCurrentTime(
                  detailPost?.public_time || detailPost?.created_at,
                  'YYYY/MM/DD HH:mm'
                )}
              </p>
            </div>
            <div className={`box-popup ${isAdmin && 'disabled'}`}>
              <ThreePointIcon
                className='three-point-icon'
                onClick={() => {
                  setIsVisiblePopup(isVisiblePopup === item?.id ? -1 : item?.id);
                }}
                onBlur={() => setIsVisiblePopup(-1)}
              />
              {isVisiblePopup === item?.id && (
                <div className='popup-edit-comment'>
                  {item?.user_id !== dataUser?.id && (
                    <>
                      <div
                        className='item'
                        onMouseDown={() => {
                          if (!isAuth) {
                            setIsVisiblePopup(-1);
                            dispatch(logUserProfile());
                          } else {
                            handleFollowCreator(item);
                            setIsVisiblePopup(-1);
                          }
                        }}>
                        {detailPost?.is_followed === 0 || !detailPost?.is_followed ? (
                          <FlowUnFlowIcon />
                        ) : (
                          <UnFlowIcon />
                        )}
                        <span className='text'>
                          {detailPost?.is_followed === 0 || !detailPost?.is_followed
                            ? t('comment.listComment.flow')
                            : t('comment.listComment.unflow')}
                        </span>
                      </div>
                      {!checkDisabledAction(item) && (
                        <div
                          className='item'
                          onMouseDown={() => {
                            if (!isAuth) {
                              setIsVisiblePopup(-1);
                              dispatch(logUserProfile());
                            } else {
                              setIsVisiblePopup(-1);
                              navigate(`${newPathUser}post-report`, {
                                state: {
                                  fan_id: item?.user_id,
                                  fan_name: item?.user?.account_name || item?.user?.full_name,
                                  post_id: item?.id,
                                },
                              });
                            }
                          }}>
                          <FlagIcon />
                          <span className='text'>{t('comment.listComment.iconFlag')}</span>
                        </div>
                      )}
                    </>
                  )}
                  {item?.user_id === dataUser?.id && (
                    <>
                      <div
                        className='item'
                        onMouseDown={() => {
                          if (!isAuth) {
                            setIsVisiblePopup(-1);
                            dispatch(logUserProfile());
                          } else {
                            navigateByLink(item?.id);
                            setIsVisiblePopup(-1);
                          }
                        }}>
                        <Edit3Icon />
                        <span className='text'>{t('comment.listComment.iconEdit')}</span>
                      </div>
                      <div
                        className='item'
                        onMouseDown={() => {
                          if (!isAuth) {
                            setIsVisiblePopup(-1);
                            dispatch(logUserProfile());
                          } else {
                            setIsVisiblePopDeletePost({ id: item?.id });
                            setIsVisiblePopup(-1);
                          }
                        }}>
                        <TrashIcon />
                        <span className='text'>{t('Common.buttonDelete')}</span>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className='div-content-mess' style={{ padding: '0 16px 16px 16px' }}>
            <ReadMoreLess
              content={item?.content}
              charLimit={isIdol ? (widthScreen > 991 ? 60 : widthScreen > 575 ? 45 : 30) : 30}
              onClick={() => {
                setFlgRender(!flgRender);
              }}
            />
          </div>
          <div className='line'></div>

          {GroupIcon(item?.number_comment)}

          <div className='line'></div>
          <div
            className='comment-wrap-popup'
            style={{ height: `calc(100% - ${widthContent}px - 172px)` }}>
            <div className='sroll-list' id='sroll-list-comment'>
              <CommentPostTimeLine
                dataPost={detailPost}
                handleDeleteComment={(e) => {
                  setPopupDeleteComment(e);
                }}
                handleLikeComment={handleLikeComment}
                dataProfile={dataProfile}
                handleFollowUserComment={handleFollowUserComment}
                id={item?.id}
              />
            </div>
            <div className='div-list-cmt'>
              <div className={`comment block`}>
                {dataProfile?.avatar ? (
                  <ImgCustom screen='avartar_navbar_60_60' src={dataProfile?.avatar} />
                ) : (
                  <img src={AVATAR_DEFAULT} alt='AVATAR_DEFAULT' />
                )}
                <form onSubmit={(e) => e.preventDefault()}>
                  <InputWrapper id='fsite-comment' className='form-item'>
                    <Textarea
                      disabled={
                        item?.enable_comment === 0 ||
                        (item?.is_followed === 0 && item?.follow_flag === 1)
                      }
                      onChange={(e) => handleTextarea(e, item?.id, '44px')}
                      placeholder={t('comment.formAddRepComment.placeholder')}
                      onKeyDown={(e) => handleTextarea(e, item?.id, '44px')}
                      value={valueComment}
                      maxLength='500'
                      id='textarea-post-comment-full'
                      overflowHidden
                      style={{
                        border: '1px solid #E5E6EB',
                        background: `${
                          (item?.is_followed === 0 && item?.follow_flag === 1) ||
                          (!item?.is_media_display && item?.type === 1) ||
                          item?.enable_comment === 0
                            ? '#efefef'
                            : '#fff'
                        }`,
                      }}
                    />
                    <SendChatIcon
                      className={`${valueComment !== '' && 'active'}`}
                      onClick={() => {
                        if (!isAuth) {
                          dispatch(logUserProfile());
                        } else {
                          valueComment !== '' && handlePostComment(item?.id, 0)
                        }
                      } }
                    />
                  </InputWrapper>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupViewFullMess;
