import Icon from '@ant-design/icons';

const SettingsSidebar = (props) => (
  <Icon
    component={() => (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M10 13.75C12.0711 13.75 13.75 12.0711 13.75 10C13.75 7.92893 12.0711 6.25 10 6.25C7.92893 6.25 6.25 7.92893 6.25 10C6.25 12.0711 7.92893 13.75 10 13.75Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.3489 5.08493C14.5491 5.26215 14.7383 5.45135 14.9155 5.6515L17.0501 5.95631C17.3962 6.55908 17.6631 7.20402 17.8441 7.87513L16.5508 9.59949C16.5508 9.59949 16.567 10.1338 16.5508 10.4006L17.8447 12.1255C17.6632 12.7965 17.3959 13.4413 17.0493 14.0438L14.9155 14.3486C14.9155 14.3486 14.5492 14.7379 14.349 14.9151L14.0442 17.0497C13.4415 17.3958 12.7965 17.6627 12.1254 17.8437L10.4012 16.5505C10.1343 16.5667 9.86674 16.5667 9.5999 16.5505L7.87501 17.8443C7.20402 17.6628 6.55926 17.3955 5.95673 17.049L5.65198 14.9152C5.45183 14.738 5.26262 14.5488 5.0854 14.3487L2.95085 14.0439C2.60469 13.4411 2.33781 12.7962 2.15681 12.1251L3.45013 10.4007C3.45013 10.4007 3.43386 9.86636 3.45008 9.59954L2.15625 7.87465C2.3377 7.20366 2.60503 6.5589 2.95159 5.95637L5.0853 5.65162C5.26251 5.45147 5.45172 5.26226 5.65187 5.08504L5.95668 2.95048C6.55945 2.60433 7.20438 2.33744 7.8755 2.15645L9.59975 3.44972C9.86659 3.4335 10.1342 3.43349 10.401 3.44971L12.1259 2.15588C12.7969 2.33734 13.4416 2.60466 14.0442 2.95122L14.3489 5.08493Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default SettingsSidebar;
