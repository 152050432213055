/* eslint-disable import/no-anonymous-default-export */

import { TYPES } from '../actions';
const initialState = {
  status: null,
  loadingBuyPost: false,
  error: null,
  check_buy_post: null,
  check_buy_package: null,
  check_unSubscription: null,
  listPackageAnotherCreator: [],
  loading: false,
  pagination: null,
  list_creator: [],
  listCreatorPagination: {},
  list_post_buyed: [],
  loadingGetFanTicketCreator: false,
  loadingListPackageMore: false,
  idScrollBackSellPackage: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.POST_BUY_ARTICLE_REQUEST:
      return {
        ...state,
        loadingBuyPost: true,
      };
    case TYPES.POST_NORMAL_BUY_PACKAGE_REQUEST:
      return {
        ...state,
        loadingBuyPost: true,
        check_buy_package: false,
      };
    case TYPES.POST_UNSUBSCRITION_PACKAGE_REQUEST:
      return {
        ...state,
        loadingBuyPost: true,
        check_unSubscription: false,
      };
    case TYPES.GET_LIST_PACKAGE_ANOTHER_CREATOR_REQUEST:
      const listPackageAnotherCreatorLength = state.listPackageAnotherCreator.length;
      if (listPackageAnotherCreatorLength) {
        return { ...state, loadingListPackageMore: true, check_buy_package: false };
      } else {
        return {
          ...state,
          loading: true,
          check_buy_package: false,
        };
      }
    case TYPES.GET_LIST_POST_BUYED_REQUEST:
    case TYPES.GET_LIST_OF_PURCHASED_POST_CREATOR_REQUEST:
      return {
        ...state,
        loading: true,
        check_buy_package: false,
      };
    case TYPES.GET_FAN_TICKET_CREATOR_TOTAL_REQUEST:
      return {
        ...state,
        loadingGetFanTicketCreator: true,
      };

    case TYPES.POST_BUY_ARTICLE_SUCCESS:
      return {
        ...state,
        check_buy_post: action?.data?.res?.message,
        loadingBuyPost: false,
      };
    case TYPES.POST_NORMAL_BUY_PACKAGE_SUCCESS:
      return {
        ...state,
        check_buy_package: true,
        loadingBuyPost: false,
      };
    case TYPES.GET_LIST_PACKAGE_ANOTHER_CREATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingListPackageMore: false,
        listPackageAnotherCreator: action?.data?.res?.data,
        pagination: action?.data?.res?.pagination,
      };
    case TYPES.GET_LIST_POST_BUYED_SUCCESS:
      const current_page = action?.data?.res?.pagination?.current_page;
      return {
        ...state,
        loading: false,
        list_post_buyed:
          current_page === 1
            ? action?.data?.res?.data
            : [...state?.list_post_buyed, ...action?.data?.res?.data],
        pagination: action?.data?.res?.pagination,
      };
    case TYPES.GET_LIST_OF_PURCHASED_POST_CREATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        list_creator:
          action?.data?.res?.pagination?.current_page === 1
            ? action?.data?.res?.data
            : [...state.list_creator, ...action?.data?.res?.data],
        listCreatorPagination: action?.data?.res?.pagination,
      };
    case TYPES.POST_UNSUBSCRITION_PACKAGE_SUCCESS:
      return {
        ...state,
        check_unSubscription: true,
        loadingBuyPost: false,
      };

    case TYPES.GET_FAN_TICKET_CREATOR_TOTAL_SUCCESS:
      return {
        ...state,
        loadingGetFanTicketCreator: false,
      };
    case TYPES.GET_FAN_TICKET_CREATOR_TOTAL_FAILED:
      return {
        ...state,
        loadingGetFanTicketCreator: false,
      };
    case TYPES.POST_BUY_ARTICLE_FAILED:
    case TYPES.POST_NORMAL_BUY_PACKAGE_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        check_buy_package: action?.data?.error?.messageId,
        loadingBuyPost: false,
      };
    case TYPES.POST_UNSUBSCRITION_PACKAGE_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        check_unSubscription: false,
        loadingBuyPost: false,
      };
    case TYPES.GET_LIST_PACKAGE_ANOTHER_CREATOR_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
        loadingListPackageMore: false,
      };
    case TYPES.GET_LIST_POST_BUYED_FAILED:
    case TYPES.GET_LIST_OF_PURCHASED_POST_CREATOR_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };
    case 'SET_ID_SCROLL_BACK_SELL_PACKAGE':
      return { ...state, idScrollBackSellPackage: action?.data?.id };
    case 'CLEAR_ID_SCROLL_BACK_SELL_PACKAGE_AND_DATA':
      return {
        ...state,
        idScrollBackSellPackage: null,
        check_buy_post: null,
        check_unSubscription: null,
        check_buy_package: null,
        listPackageAnotherCreator: [],
        //list_creator: [],
        listCreatorPagination: {},
        list_post_buyed: [],
      };
    case 'RESET_ACTION_SELL_POST':
      return {
        ...state,
        check_buy_post: null,
        check_unSubscription: null,
        check_buy_package: null,
        // listPackageAnotherCreator: [],
        //list_creator: [],
        listCreatorPagination: {},
        list_post_buyed: [],
      };
    default:
      return state;
  }
};
