import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import AgoraRTC from 'agora-rtc-sdk-ng';
import { getEnv } from 'configs/env';
import { isMobile } from 'react-device-detect';

const VideoMobile = () => {
    const { id } = useParams();
    const mediaQueryList = window.matchMedia("(orientation: portrait)");
    const client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
    const appID = getEnv('REACT_APP_AGORA_APP_ID');
    const localTracks = {
        videoTrack: null,
        audioTrack: null,
    };

    console.log("SDSDS: ", id);

    let remoteUsers = {};
    const options = {
        appid: appID,
        channel: 'ddddd-112255',
        uid: null,
        token: null,
        role: id === 'host' ? 'host' : 'audience', // host or audience
        audienceLatency: 1,
    };

const [useId, setUserId] = useState('');
async function subscribe(user, mediaType) {
    const uid = user.uid;
    setUserId(uid);
    // subscribe to a remote user
    await client.subscribe(user, mediaType);
    console.log("subscribe success");
    if (mediaType === 'video') {
      const player = `
        <div id="player-wrapper-${uid}">
          <p class="player-name">remoteUser(${uid})</p>
          <div id="player-${uid}" class="player"></div>
        </div>
      `;
      //document.getElementById("#remote-playerlist").append(player);
      let objectFit = 'contain';
      if (isMobile) {
        objectFit = 'unset';
      }
      user.videoTrack.play(`player-${uid}`, { fit: objectFit });
    }
    if (mediaType === 'audio') {
      user.audioTrack.play();
    }
  }

  function handleUserPublished(user, mediaType) {
    const id = user.uid;
    remoteUsers[id] = user;
    subscribe(user, mediaType);
  }
  
  function handleUserUnpublished(user, mediaType) {
    if (mediaType === 'video') {
      const id = user.uid;
      delete remoteUsers[id];
    }
  }

  const [local, setLocal] = useState({
    videoTrack: null,
    audioTrack: null,
  });

    const join = async () => {
       // setIsLoading(true);
       let videoSize = '480p_1';

   // alert("matches " + mediaQueryList.matches + ' mobi: ' + isMobile);

    if (isMobile) {
        videoSize = '480p_3'; // 480 × 480 - frame : 15 - 400
    } else {
        videoSize = '480p_1'; // 640 × 480 - frame : 15 - 500
      
    }
        if (options.role === 'host') {
            await client.setClientRole('host');

            [options.uid, localTracks.audioTrack, localTracks.videoTrack] = await Promise.all([
                // join the channel
                await client.join(options.appid, options.channel, options.token || null, options.uid || null),
                // create local tracks, using microphone and camera
 
                await AgoraRTC.createMicrophoneAudioTrack(),
                await AgoraRTC.createCameraVideoTrack({ 
                  encoderConfig: videoSize,
                  
                })

                // await AgoraRTC.createCameraVideoTrack({
                //     encoderConfig: {
                //         width: isMobile ?   : 640 : 640,
                //         height: isMobile ? (mediaQueryList.matches) ? 640 : 480 : 480
                //     },
                // //     // optimizationMode: (platform?.name === 'Chrome' || platform?.name === 'Chrome Mobile') ? 'motion' : '',
                // })
            ]);

             localTracks && await localTracks.videoTrack.setEncoderConfiguration(
              videoSize
            );
            await localTracks.videoTrack.play('local-player', { fit: 'contain' });
            await client.publish(Object.values(localTracks));
            setLocal(localTracks);

        } else {

            await client.setClientRole('audience', { level: 1 });
            await client.join(options.appid, options.channel, options.token || null, options.uid || null);
        
        }

        client.on("user-published", handleUserPublished);
        client.on("user-unpublished", handleUserUnpublished);

        console.log("publish success");
       // setIsLoading(false);
    }

    const fanViewRef = useRef();

    useEffect(() => {
        join();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOrientationChange = async (evt) => {
      // alert(mediaQueryList.matches);
        // alert(mediaQueryList.matches);
        // return window.location.reload();
        // handleStopLive();
        // join();
        // console.log("DDDXXX: ", local);
        // alert(local.videoTrack);
    
        // const realWidth = Math.round((window.screen.width * window.devicePixelRatio) / 2);
        // const realHeight = Math.round((window.screen.height * window.devicePixelRatio) / 2);
     
        // if (evt.matches) {
        //   if (localTracks.videoTrack) {
        //     // alert('DP: ' + local.videoTrack + '-rw:' + realWidth + '-rh:' + realHeight);
           
        //     await localTracks.videoTrack.setEncoderConfiguration({
        //       width : 360,
        //       height: 640,
        //       frameRate: 24,
        //       bitrateMax: 800, bitrateMin: 212
        //     })
        //      // return await localTracks.videoTrack.setEncoderConfiguration('480p_8');
            
        //   }
        // } else {
        //   if (localTracks.videoTrack) {
        //    // alert('Land: ' + local.videoTrack + '-rw:' + realWidth + '-rh:' + realHeight);
        //    await localTracks.videoTrack.setEncoderConfiguration({
        //     width : 640,
        //     height: 360,
        //     frameRate: 24,
        //     bitrateMax: 800, bitrateMin: 212
        //   })
                
        //      // return await localTracks.videoTrack.setEncoderConfiguration('480p_8');
        //   } 
        // }
      }
    
      useEffect(() => {
        if (id === 'host' && isMobile) {
          // handleOrientationChange(mediaQueryList);
         // mediaQueryList.addEventListener('change', handleOrientationChange);
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [mediaQueryList])
      
    return (
        <>
        {id === 'host' && (
              <div id='local-player'
              className='sc-creator-views current-view'
              style={{ height: '100%', width: '100%' }} >
              </div>  
        )}

        {id !== 'host' && (
            // <div id='remote-playerlist'></div>
            <div
              ref = {fanViewRef}
              id={`player-${useId}`}
              style={{ height: '100%', width: '100%' }}
              className={`lsv-fan current-view`}>
            </div>
        )}
        </>
    )
}

export default VideoMobile