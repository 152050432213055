import { ChatIcon, HeartActiveIcon, HeartIcon, RePostIcon, ShareIcon } from 'images';
import { useState } from 'react';
import { isAdmin, isAgency } from 'utils';
import { getEnv } from '../../../../../configs/env';
const GroupLikeChatShared = ({
  detailPost,
  handleLikePost,
  loadingAction,
  handleSharePost,
}) => {
  const [disabledLike, setDisabledLike] = useState(false);
  const [currentLike, setCurrentLike] = useState({
    like: detailPost?.user_like_status,
    numberLike: detailPost?.number_like,
  });

  const _onLikePost = (status) => {
    setDisabledLike(true);
    setCurrentLike({
      ...currentLike,
      like: status,
      numberLike: status
        ? Number(currentLike?.numberLike) + 1
        : Number(currentLike?.numberLike) - 1,
    });
    setDisabledLike(false);
  };

  return (
    <div
      className='interactive block'
      style={{
        pointerEvents: isAdmin || isAgency ? 'none' : 'pointer',
        color: isAdmin || isAgency ? '#B1B6BB' : '#787880',
      }}>
      <div className='left'>
        <div className={`item ${disabledLike ? 'disabled' : ''}`}>
          {currentLike?.like === 1 ? (
            <>
              {/* đã like */}
              <HeartActiveIcon
                className={`like-icon active`}
                onClick={() => {
                  _onLikePost(0);
                  handleLikePost(detailPost?.id);
                }}
              />
            </>
          ) : (
            <>
              {/* chưa like */}
              <HeartIcon
                className={`like-icon}`}
                onClick={() => {
                  _onLikePost(1);
                  handleLikePost(detailPost?.id);
                }}
              />
            </>
          )}
          <span className='number'>{currentLike?.numberLike}</span>
        </div>
        <div className='item'>
          <ChatIcon className='chat-icon' />
          <span className='number'>{detailPost?.number_comment || 0}</span>
        </div>
        {/* <div className={`item ${loadingAction && 'disabled'}`}>
          <RePostIcon />
          <span className='number'>{detailPost?.number_repost ?? 0}</span>
        </div> */}
        <div className='item'>
          <ShareIcon
            className={`share-icon ${loadingAction && 'disabled'}`}
            onClick={() => {
              handleSharePost({
                link: `${getEnv('REACT_APP_FAN_URL')}/post/${detailPost?.id}`,
                title: detailPost.title,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default GroupLikeChatShared;
