import { CardDetail } from 'components/DetailCard';
import { InputWrapper } from 'components/Input/InputWrapper';
import { newPathUser } from 'constants/layout/constant';
import useUserRole from 'hooks/useUserRole';
import { ClearInputIcon, SearchIcon } from 'images';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { resetActionCreatorHomePage } from 'store/actions/creator';
import {
  getListPostCreator,
  getPageShort,
  resetIDHomePage,
  resetPopupPostShortVideo,
} from 'store/actions/usersNew';
import { useHorizontalScroll } from '../../hooks/useHorizontalScroll';
import {
  ENV,
  STORAGE,
  getQueryParams,
  isAgency,
  isEmptyObject,
  isProdEnableLiveStream,
} from '../../utils';
import './index.scss';
import Storage from 'utils/storage';
import { ToggleButton } from 'components';
import { isMobile, isTablet } from 'react-device-detect';
import { useMemo } from 'react';
import { logUserProfile } from 'store/actions/myPage';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { useCallback } from 'react';

const HomePageContent = ({
  setLoading,
  setLoadingChangeTab,
  loadingChangeTab,
  setCreatorProfile,
  creatorProfile,
}) => {
  const [resetTab, setResetTab] = useState(0);
  const { detailCreator } = useSelector((state) => state.creator);
  const {
    pagination,
    listPostCreator,
    dataInfoOther,
    initPageHome,
    idHomePage,
    paginationPostHome,
    pageShort
  } = useSelector((state) => state.usersNew);
  const navigate = useNavigate();
  const { account_id } = useParams();
  const itemsPerPage = 10;
  const dispatch = useDispatch();
  const { isFan, isIdol } = useUserRole();
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const { data: userProfile, loading: loadingUser } = useSelector((state) => state.users);
  const { t, i18n } = useTranslation();
  const [checkSearch, setCheckSearch] = useState(false);
  const location = useLocation();
  const scrollToTabRef = useRef();
  const [countScroll, setCountScroll] = useState(0);
  const [flgSwitching, setFlgSwitching] = useState(idHomePage?.isMedia || false);
  const [searchWord, setSearchWord] = useState(idHomePage?.keySearch || '');
  const [contentTab, setContentTab] = useState(idHomePage?.tab || null);
  const [page, setPage] = useState(idHomePage?.page || 1);
  const [flgSearch, setFlgSearch] = useState(false);
  const [flgCheckTag, setFlgCheckTag] = useState(false);
  const [active, setActive] = useState();
  const parts1 = getQueryParams(location.search, 'post')?.split('?sub=')[1];
  const [titleSub, setTitleSub] = useState(
    parts1?.length >= 1
      ? parts1
      : location?.state?.packageName
      ? location?.state?.packageName
      : getQueryParams(location.search, 'sub')
      ? getQueryParams(location.search, 'sub')
      : ''
  );

  useEffect(() => {
    if (pageShort) {
      setPage(pageShort)
    }
  },[pageShort])

  const [flgTabLoading, setFlgTabLoading] = useState(false);
  const widthScreen = window.innerWidth;
  // UI F017-C020
  const [hashtags, setHashtags] = useState([]);
  const API = {
    getPoint: (id) => `${ENV}/users/${id}/hashtags`,
  };

  const callAPIHastag = async (id) => {
    await fetch(API.getPoint(id), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      },
    })
      .then((rs) => rs.json())
      .then((data) => {
        setHashtags(data.data);
      });
  };

  const tabs_live = [
    {
      label: `${t('HomePageContent.postType1')}`,
      value: 1,
      link: '?post=post-normal',
    },
    {
      label: `${t('HomePageContent.postShortType4')}`,
      value: 4,
      link: '?post=post-shorts',
    },
    {
      label: `${t('HomePageContent.saleContentType2')}`,
      value: 2,
      link: `?post=post-sales`,
    },
    {
      label: `${t('HomePageContent.postNormalType3')}`,
      value: 3,
      link: '?post=live-archives',
    },
  ];

  const tabs_live_sub = [
    {
      label: `${t('HomePageContent.postType1')}`,
      value: 1,
      link: `?post=post-normal?sub=${titleSub}`,
    },
    {
      label: `${t('HomePageContent.postShortType4')}`,
      value: 4,
      link: `?post=post-shorts?sub=${titleSub}`,
    },
    {
      label: `${t('HomePageContent.saleContentType2')}`,
      value: 2,
      link: `?post=post-sales?sub=${titleSub}`,
    },
  ];

  const tabs_without_live = [
    {
      label: `${t('HomePageContent.postType1')}`,
      value: 1,
      link: `?post=post-normal`,
    },
    {
      label: `${t('HomePageContent.postShortType4')}`,
      value: 4,
      link: `?post=post-shorts`,
    },
    {
      label: `${t('HomePageContent.saleContentType2')}`,
      value: 2,
      link: `?post=post-sales`,
    },
  ];

  const tabs = useMemo(() => {
    if (+isProdEnableLiveStream === 1) {
      if (
        location?.state?.packageName ||
        getQueryParams(location.search, 'post')?.split('?sub=')[1]
      ) {
        return tabs_live_sub;
      } else {
        return tabs_live;
      }
    } else {
      if (
        location?.state?.packageName ||
        getQueryParams(location.search, 'post')?.split('?sub=')[1]
      ) {
        return tabs_live_sub;
      } else {
        return tabs_without_live;
      }
    }
  }, [isProdEnableLiveStream, i18n.language, location]);

  const mediaTabs = [
    {
      label: `${t('Common.media')}`,
      value: false,
    },
    {
      label: `${t('Common.all')}`,
      value: true,
    },
  ];
  // asyn await

  useEffect(() => {
    const tmp = getQueryParams(location.search, 'post')?.split('?')[0];
    if (idHomePage?.type && idHomePage?.type !== (tmp ? tmp : 'post-normal')) {
      if (location?.state?.name !== 'homepage') {
        const top = document.getElementById('tab_id');
        top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
      }
      setPage(1);
      dispatch(resetIDHomePage());
      dispatch(getPageShort(null));
      dispatch(resetPopupPostShortVideo());
    }
    if (location?.state?.packageName) {
      setTitleSub(location?.state?.packageName);
    }
    const top = document.getElementById('tab_id');
    if (!idHomePage?.page && getQueryParams(location.search, 'post')?.includes('post-sales')) {
      setPage(1);
      setContentTab(2);
      flgCheckTag && top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
    } else if (!idHomePage?.page && getQueryParams(location.search, 'post')?.includes('post-shorts')) {
      setContentTab(4);
      setPage(1);
      flgCheckTag && top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
    } else if (!idHomePage?.page && getQueryParams(location.search, 'post')?.includes('live-archives')) {
      setPage(1);
      flgCheckTag && top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
      setContentTab(3);
    } else if (!idHomePage?.page) {
      flgCheckTag && top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
      setPage(1);
      setContentTab(1);
    }
  }, [location.state, location, location.search]);
  const userState = useSelector((state) => state.users);
  useEffect(
    () => {
      if (dataInfoOther?.id && !idHomePage?.id && contentTab && !userState?.logoutProcess) {
        if (location?.state?.name === 'homepage' || location?.state?.pageProfile) {
          const top = document.getElementById('app_cocofans_0');
          top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
        }
        const parts = getQueryParams(location.search, 'post')?.split('?sub=')[1];
        const subTitle =
          parts?.length >= 1
            ? parts
            : location?.state?.packageName
            ? location?.state?.packageName
            : getQueryParams(location.search, 'sub')
            ? getQueryParams(location.search, 'sub')
            : '';
        const payload = {
          type: contentTab || 1,
          page: page || 1,
          sub_title: subTitle,
          limit:
            widthScreen <= 575 && contentTab === 1 && !flgSwitching
              ? 6
              : widthScreen > 575 && contentTab === 1 && !flgSwitching
              ? 12
              : contentTab === 4 && widthScreen <= 575
              ? 4
              : itemsPerPage,
          keyword: searchWord,
          only_media: contentTab === 1 && !flgSwitching ? 1 : 0,
          hashtag_id: contentTab === 3 ? '' : active,
          account_id: account_id || '',
          id: location?.state?.dataUserID ? location?.state?.dataUserID : dataInfoOther?.id,
        };
        const tokenAgency =
          localStorage.getItem(STORAGE.AGENCY_TOKEN)?.replace(/"/g, '') || false;
        page === 1 && setLoadingChangeTab(true);
        if (subTitle) {
          dispatch(resetPopupPostShortVideo());
        }
        dispatch(
          getListPostCreator(
            payload,
            !account_id,
            () => setLoadingChangeTab(false),
            () => setLoadingChangeTab(false),
            isAgency && tokenAgency
          )
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contentTab, dataInfoOther?.id, flgSwitching, idHomePage?.id]
  );

  const callAPI = useCallback(
    (rs) => {
      const parts = getQueryParams(location.search, 'post')?.split('?sub=')[1];
      const subTitle =
        parts?.length >= 1
          ? parts
          : location?.state?.packageName
          ? location?.state?.packageName
          : getQueryParams(location.search, 'sub')
          ? getQueryParams(location.search, 'sub')
          : '';
      const payload = {
        type: contentTab,
        page: rs || 1,
        sub_title: subTitle,
        limit:
          contentTab === 1 && !flgSwitching
            ? 12
            : contentTab === 4 && widthScreen <= 575
            ? 4
            : itemsPerPage,
        keyword: !flgSearch ? '' : searchWord,
        only_media: contentTab === 1 && !flgSwitching ? 1 : 0,
        account_id: account_id || '',
        id: location?.state?.dataUserID ? location?.state?.dataUserID : dataInfoOther?.id,
      };
      const tokenAgency =
        localStorage.getItem(STORAGE.AGENCY_TOKEN)?.replace(/"/g, '') || false;
      dispatch(
        getListPostCreator(
          payload,
          !account_id,
          () => setLoadingChangeTab(false),
          () => setLoadingChangeTab(false),
          isAgency && tokenAgency
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      location.search,
      contentTab,
      page,
      active,
      dataInfoOther?.id,
      account_id,
      location,
      flgSwitching,
      searchWord,
    ]
  );

  useEffect(() => {
    account_id === userProfile?.id && navigate(`${newPathUser}`);
  }, [account_id, navigate, userProfile]);

  useEffect(() => {
    setResetTab(resetTab + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentTab]);

  const fetchNext = () => {
    const pages = pagination?.total / pagination?.per_page;
    if (pages > page) {
      setPage(page + 1);
      callAPI(page + 1);
    }
  };

  const onChangeSearchWord = (e) => {
    setSearchWord(e.target.value);
  };

  const handleSearch = async () => {
    setPage(1);
    setLoadingChangeTab(true);
    setCheckSearch(true);
    const parts = getQueryParams(location.search, 'post')?.split('?sub=')[1];
    const subTitle =
      parts?.length >= 1
        ? parts
        : location?.state?.packageName
        ? location?.state?.packageName
        : getQueryParams(location.search, 'sub')
        ? getQueryParams(location.search, 'sub')
        : '';
    dispatch(
      getListPostCreator(
        {
          type: contentTab,
          page: 1,
          sub_title: subTitle,
          limit: itemsPerPage,
          keyword: searchWord,
          only_media: contentTab === 1 && !flgSwitching ? 1 : 0,
          account_id: account_id || '',
          id: dataInfoOther?.id,
        },
        !account_id,
        () => setLoadingChangeTab(false),
        () => setLoadingChangeTab(false)
      )
    );
  };
  const ref = useRef();
  const scrollRef = useHorizontalScroll();

  const handleChangeDisplay = () => {
    setPage(1);
    setFlgSwitching(!flgSwitching);
    dispatch(resetIDHomePage());
  };

  return (
    <div
      className={`bg-white home-page-content pt-3 ${
        contentTab === 3 || contentTab === 4 ? '' : 'two-column'
      } ${isIdol ? 'creator' : 'fan'} ${
        page === pagination?.total_pages && isFan && 'more-padding-bottom'
      }`}
      ref={ref}>
      {isIdol && [2].includes(userProfile?.status) && <div className='popup-creator-block' />}
      {/* {!loadingUser && ( */}
      <>
        <div className='home-page-content-search'>
          <div ref={scrollToTabRef}></div>
          <InputWrapper className='d-flex search__wrapper'>
            <div className='search-container position-relative w-80' id='pin-post'>
              <input
                style={{ background: '#fff', fontWeight: '400' }}
                className='fsite-input px-5 search-input'
                placeholder={t('HomePageContent.placeholderSearch', {
                  name: dataInfoOther?.account_name,
                })}
                value={searchWord}
                onBlur={onChangeSearchWord}
                onChange={(e) => {
                  setSearchWord(e.target.value);
                }}
              />
              <SearchIcon className='position-absolute search-icon' />
              {!!searchWord?.trim() && (
                <ClearInputIcon
                  className='clear-icon'
                  onClick={() => {
                    setSearchWord('');
                    setFlgSearch(false);
                  }}
                />
              )}
            </div>
            <button
              className='font-size-12 font-weight-500 btn-search'
              onClick={() => {
                if (!isAuth) {
                  dispatch(logUserProfile());
                } else {
                  setFlgSearch(true);
                  handleSearch();
                }
              }}>
              {t('Common.search')}
            </button>
          </InputWrapper>
        </div>
          <div
            disabled={loadingChangeTab}
            className='d-flex font-size-14 new-list-tab-type-post'
            ref={scrollRef}>
            {tabs.map((tab) => (
              <div
                disabled={loadingChangeTab}
                key={tab.value}
                role='button'
                style={{
                  Width: isFan ? 120 : '',
                  justifyContent: 'center',
                  cursor: `${loadingChangeTab ? 'default' : 'pointer'}`,
                  pointerEvents: `${contentTab === tab.value ? 'none' : 'auto'}`,
                }}
                onClick={() => {
                  if (!loadingChangeTab) {
                    setFlgCheckTag(true);
                    setFlgTabLoading(true);
                    dispatch(resetIDHomePage());
                    dispatch(getPageShort(null));
                    dispatch(resetPopupPostShortVideo());
                    setPage(1);
                    navigate(`${newPathUser}${dataInfoOther?.account_id}${tab.link}`);
                    contentTab !== tab.value && setLoadingChangeTab(true);
                    setContentTab(tab.value);
                    setFlgSwitching(false);
                  }
                }}
                className={`item-post content-tab  ${
                  contentTab === tab.value ? 'active' : ''
                }`}>
                {tab.label}
              </div>
            ))}
          </div>
        {contentTab === 1 && (
          <div className='display-media-wrap'>
            <div className='media-wrap'>
              {mediaTabs?.map((item) => {
                return (
                  <div
                    key={item.value}
                    className={`item-media ${item.value === flgSwitching ? 'active' : ''}`}>
                    {item.label}
                  </div>
                );
              })}
            </div>
            <div className='toggle'>
              <label className='me-2'>{t('Common.changeDisplay')}</label>
              <div>
                <ToggleButton
                  disabled={loadingChangeTab}
                  checked={flgSwitching}
                  id='message_notification'
                  onChange={!loadingChangeTab && handleChangeDisplay}
                />
              </div>
            </div>
          </div>
        )}
      </>
      {/* )} */}
      {loadingChangeTab ? (
        <div className='mobile-loading' style={{ padding: `${isTablet && '16px 0'}` }}>
          <ShimmerPostDetails />
        </div>
      ) : (
        <>
          {listPostCreator?.length > 0 && (
            <CardDetail
              searchWord={searchWord}
              list={listPostCreator}
              type={contentTab}
              setPage={setPage}
              page={page}
              fetchNext={fetchNext}
              isSearch={checkSearch}
              isMedia={flgSwitching}
              loadingChangeTab={loadingChangeTab}
              setCreatorProfile={setCreatorProfile}
              creatorProfile={creatorProfile}
            />
          )}
        </>
      )}
    </div>
  );
};

export default HomePageContent;
