import { ENV, callApi, configParamsGet, updateStatus } from 'utils';
import { TYPES } from '.';
import Storage from '../../utils/storage';

const getCommentHide = ({ page, limit = 20, postId }, callback) => {
  let url = `${ENV}/posts/${postId}/comments?hidden=1&page=${page}&limit=${limit}`;
  return (dispatch) => {
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_COMMENT_HIDE_FROM_POST_SUCCESS, {
            data: response.data,
            pagination: response?.pagination,
          })
        );
        callback && callback(response);
      },
      () => {
        callback && callback({});
      }
    );
  };
};

const getCommentSubHide = ({ page, limit = 20, postId }, callback) => {
  let url = `${ENV}/comments/${postId}/comments?&page=${page}&limit=${limit}`;
  return (dispatch) => {
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_SUB_LIST_COMMENT_HIDE_FROM_POST_SUCCESS, {
            postId,
            data: response.data,
            pagination: response?.pagination,
          })
        );
        callback && callback(response);
      },
      () => {
        callback && callback({});
      }
    );
  };
};

const unHideComment = (id, cb) => {
  const url = `${ENV}/comments/${id}/hide`;
  const configParams = {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      'Content-Type': 'application/json',
    },
  };

  return (dispatch) => {
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.UN_HIDE_COMMENT_FORM_POST_SUCCESS, {
            data: {
              id,
            },
          })
        );
        cb(response);
      },
      (error) => {
        cb(error);
      }
    );
  };
};

const followUserHideComment = (id, handleSuccess, handleFailed) => {
  const url = `${ENV}/users/${id}/following`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.FOLLOW_USER_HIDE_COMMENT_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FOLLOW_USER_HIDE_COMMENT_SUCCESS, {
            data: {
              id,
            },
          })
        );
        handleSuccess && handleSuccess(id);
      },
      (error) => {
        handleFailed && handleFailed();
        dispatch(updateStatus(TYPES.FOLLOW_USER_HIDE_COMMENT_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const setIdScrollBackHideComment = (id, cb) => {
  return (dispatch) => {
    dispatch(
      updateStatus('SET_ID_SCROLL_BACK_HIDE_COMMENT', {
        data: {
          id,
        },
      })
    );
    cb && cb();
  };
};

const clearIdAndDataHideComment = () => {
  return (dispatch) => {
    dispatch(updateStatus('CLEAR_ID_AND_DATA_HIDE_COMMENT'));
  };
};

const updateLikeCommentHideComment = (commentId, parentId, onSuccess = () => {}) => {
  const url = `${ENV}/comments/${commentId}/likes`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.UPDATE_LIKE_COMMENT_HIDE_COMMENT_SUCCESS, {
            id: commentId,
            parentId: parentId,
            is_liked: response?.data?.is_liked,
          })
        );
        onSuccess(response);
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.UPDATE_LIKE_COMMENT_HIDE_COMMENT_FAILED, { err: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

export {
  followUserHideComment,
  getCommentHide,
  unHideComment,
  getCommentSubHide,
  setIdScrollBackHideComment,
  clearIdAndDataHideComment,
  updateLikeCommentHideComment,
};
