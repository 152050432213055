import Icon from '@ant-design/icons';

const TimelineIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        fill='none'
        viewBox='0 0 24 24'>
        <path
          fill='currentColor'
          d='M23.297 0h-7.078a.703.703 0 00-.703.703v2.522l-1.197 1.2a.703.703 0 000 .993l1.197 1.2v2.523c0 .388.315.703.703.703h7.078A.703.703 0 0024 9.14V.703A.703.703 0 0023.297 0zm-.703 8.438h-5.672v-2.11a.703.703 0 00-.205-.496l-.907-.91.907-.91a.703.703 0 00.205-.496v-2.11h5.672v7.032z'></path>
        <path
          fill='currentColor'
          d='M12.703 10.012V6.956c1.867-.664 1.868-3.31 0-3.975V.703a.703.703 0 00-1.406 0V2.98c-1.874.662-1.874 3.316 0 3.978v3.054c-1.87.663-1.871 3.313 0 3.976v3.055c-1.87.663-1.871 3.313 0 3.977v2.277a.703.703 0 001.406 0V21.02c1.87-.663 1.871-3.314 0-3.977v-3.055c1.87-.663 1.871-3.313 0-3.976zm-.705-5.746a.704.704 0 11-.002 1.407.704.704 0 01.002-1.407zm.002 7.03a.704.704 0 11-.002 1.409.704.704 0 01.002-1.408zm0 8.438a.704.704 0 11.002-1.407.704.704 0 01-.002 1.407z'></path>
        <path
          fill='currentColor'
          d='M8.484 10.303V7.781a.703.703 0 00-.703-.703H.703A.703.703 0 000 7.781v8.438c0 .388.315.703.703.703h7.078a.703.703 0 00.703-.703v-2.522l1.197-1.2a.703.703 0 000-.993l-1.197-1.2zm-1.2 2.607a.703.703 0 00-.206.496v2.11H1.406V8.484h5.672v2.11c0 .186.074.364.205.496l.907.91-.907.91zM23.297 14.11h-7.078a.703.703 0 00-.703.703v2.521l-1.197 1.2a.703.703 0 000 .994l1.197 1.2v2.569c0 .388.315.703.703.703h7.078a.703.703 0 00.703-.703v-8.485a.703.703 0 00-.703-.703zm-.703 8.484h-5.672v-2.157a.703.703 0 00-.205-.496l-.907-.91.907-.91a.703.703 0 00.205-.496v-2.11h5.672v7.079z'></path>
        <path
          fill='currentColor'
          d='M5.672 9.89H2.859a.703.703 0 000 1.407h2.813a.703.703 0 000-1.406zM5.672 12.703H2.859a.703.703 0 100 1.406h2.813a.703.703 0 000-1.406zM18.328 4.266h2.813a.703.703 0 000-1.407h-2.813a.703.703 0 000 1.407zM18.328 7.078h2.813a.703.703 0 000-1.406h-2.813a.703.703 0 000 1.406zM18.328 18.328h2.813a.703.703 0 100-1.406h-2.813a.703.703 0 000 1.406zM18.328 21.164h2.813a.703.703 0 000-1.406h-2.813a.703.703 0 000 1.406z'></path>
      </svg>
    )}
    {...props}
  />
);

export default TimelineIcon;
