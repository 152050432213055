import { newPathUser } from 'constants/layout/constant';
import useUserRole from 'hooks/useUserRole';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { STORAGE } from 'utils/constant';
import Storage from 'utils/storage';

const PublicRoutes = () => {
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const isParams = Storage.has(STORAGE.PARAMS);
  const { isIdol } = useUserRole();

  return isAuth ? (
    <Navigate
      to={`${
        isIdol
          ? isParams
            ? Storage.get(STORAGE.PARAMS)
            : `${newPathUser}`
          : isParams
          ? Storage.get(STORAGE.PARAMS)
          : '/my-page'
      }`}
    />
  ) : (
    <Outlet />
  );
};

export default PublicRoutes;
