import Icon from '@ant-design/icons';

const GhimPostIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={20}
        height={20}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M18.124 8.38224L13.5515 12.9547C13.9075 13.9453 14.055 15.6023 12.5211 17.6411C12.4137 17.7839 12.2768 17.9019 12.1199 17.9872C11.9629 18.0725 11.7894 18.1231 11.6112 18.1356C11.5819 18.1377 11.5526 18.1387 11.5234 18.1387C11.3591 18.1387 11.1965 18.1063 11.0448 18.0434C10.893 17.9806 10.7552 17.8884 10.6391 17.7722L6.87403 14.0072L4.191 16.6902C4.07379 16.8075 3.91482 16.8733 3.74906 16.8733C3.58329 16.8733 3.42432 16.8075 3.3071 16.6903C3.18988 16.5731 3.12402 16.4141 3.12402 16.2483C3.12401 16.0826 3.18985 15.9236 3.30706 15.8064L5.99012 13.1233L2.20965 9.34282C2.08561 9.21864 1.98909 9.06974 1.92637 8.90582C1.86364 8.74189 1.83611 8.5666 1.84557 8.39133C1.85502 8.21607 1.90125 8.04475 1.98125 7.88853C2.06125 7.7323 2.17322 7.59465 2.3099 7.48453C4.29629 5.88205 6.19753 6.19768 7.02975 6.45868L11.6151 1.8733L11.6152 1.87322C11.8498 1.63922 12.1677 1.50781 12.499 1.50781C12.8304 1.50781 13.1483 1.63922 13.3829 1.87322L18.124 6.61444C18.2401 6.73051 18.3322 6.86831 18.395 7.01998C18.4578 7.17164 18.4902 7.3342 18.4902 7.49836C18.4902 7.66252 18.4578 7.82507 18.395 7.97673C18.3321 8.12839 18.24 8.26618 18.124 8.38224Z'
          fill='#1A1A1A'
        />
      </svg>
    )}
    {...props}
  />
);

export default GhimPostIcon;
