import { PopupFanDetail, ImgCustom, LayoutCreatorRight, PopupLoading } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import withCreatorRole from 'hocs/withCreatorRole';
import NoFollow from 'images/NoFollow.svg';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  clearScrollBackFollowing,
  creatorGetListMyFan,
  followOrUnFollowUser,
  setIdScrollBackFollower,
} from 'store/actions/users';
import './index.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { newPathUser } from 'constants/layout/constant';
import DefaultAvatar from 'images/DefaultAvatar.svg';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { isMobile } from 'react-device-detect';
import { useLayoutEffect } from 'react';
import { resetIDHomePage } from 'store/actions/usersNew';
import { useGetIdFromRecommendUser } from 'hooks/v2/useGetIdFromRecommendUser';

const FollowerPage = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [idFan, seIdFan] = useState(false);
  const [hoverId, setHoverId] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { idFromRecommendUser, clearIdScrollBackRecommend } = useGetIdFromRecommendUser();
  const navigate = useNavigate();
  const { account_id } = useParams();
  const {
    listFans = [],
    loading,
    paginationFollower: pagination,
    dataUser,
    idScrollBackFollower,
    isLoadingFollow,
  } = useSelector((state) => state.users);
  const [page, setPage] = useState(
    pagination?.current_page ? +pagination?.current_page + 1 : 1
  );
  const fetchNext = () => {
    const pages = pagination?.total / pagination?.per_page;
    if (pages > page) {
      if (idScrollBackFollower || idFromRecommendUser) {
        dispatch(setIdScrollBackFollower());
        clearIdScrollBackRecommend();
      } else {
        setPage(page + 1);
      }
    } else if (pages + 1 > page) {
      if (idScrollBackFollower || idFromRecommendUser) {
        dispatch(setIdScrollBackFollower());
        clearIdScrollBackRecommend();
      }
    }
  };
  useLayoutEffect(() => {
    if (
      (location.state?.IDUSER ||
        dataUser?.account_id ||
        location?.pathname?.split('/').pop()) &&
      !idScrollBackFollower &&
      !idFromRecommendUser
    ) {
      setIsLoading(true);
      dispatch(
        creatorGetListMyFan(
          location?.pathname?.split('/').pop() ||
            location.state?.IDUSER ||
            dataUser?.account_id,
          page,
          20,
          () => setIsLoading(false)
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, idScrollBackFollower, idFromRecommendUser]);

  useEffect(() => {
    if (idScrollBackFollower) {
      const idPost = document.getElementById(idScrollBackFollower);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
    return () => {
      clearIdScrollBackRecommend();
    };
  }, [idScrollBackFollower]);

  const handleFollowOrUnfollow = (id) => {
    const elementToUpdate = listFans.find((element) => element.id === id);
    if (elementToUpdate) {
      if (elementToUpdate.is_followed === 1) {
        elementToUpdate.is_followed = 0;
      } else {
        elementToUpdate.is_followed = 1;
      }
    }
    dispatch(followOrUnFollowUser(id));
  };

  const renderEmptyData = () => {
    return (
      <div className='no-data' style={{ whiteSpace: 'nowrap' }}>
        <img src={NoFollow} alt='No Follow' width={100} height={100} />
        {t('Follower.listNull')}
      </div>
    );
  };

  return (
    <>
      {isLoadingFollow && <PopupLoading className={'popup-loading'} />}
      {isVisible && (
        <PopupFanDetail
          closeModal={() => setIsVisible(false)}
          isVisible={isVisible}
          id={idFan}
        />
      )}
      <LayoutCreatorRight
        titlePage={t('Follower.title')}
        className='follow-header'
        classNameHeader='container-header'>
        <div className='follow-follower-page'>
          <div className='menu'>
            <div
              className={`menu-item`}
              onClick={() => {
                if (!isLoading) {
                  dispatch(clearScrollBackFollowing());
                  clearIdScrollBackRecommend();
                  dispatch(
                    setIdScrollBackFollower(`id-menu`, () => {
                      navigate(`${newPathUser}follow-list/${account_id}`, {
                        state: {
                          IDUSER: account_id,
                        },
                      });
                    })
                  );
                }
              }}>
              {t('Follow.menu_name')}
            </div>
            <div className={`menu-item active`} onClick={() => {}}>
              {t('Follower.menu_name')}
            </div>
          </div>
        </div>
        <div className='tab-content'>
          <div className='follower-page-creator'>
            <InfiniteLoad
              loading={loading}
              data={listFans}
              fetchNext={fetchNext}
              className='div-block'>
              <div className='list-follower-creator'>
                {isLoading && !listFans.length ? (
                  <div className='shimmer-loading'>
                    <ShimmerPostDetails hasImage imageType='circular' title />
                  </div>
                ) : (
                  <>
                    {listFans.length > 0
                      ? listFans.map((item) => (
                          <>
                            <div
                              id={`follower-${item?.id}`}
                              className='account-follower'
                              key={item?.id}>
                              <div
                                className={`content-left`}
                                onClick={() => {
                                  if (item?.user_type === 3) {
                                    setIsVisible(true);
                                    seIdFan(item.id);
                                  } else {
                                    dispatch(resetIDHomePage());
                                    dispatch(
                                      setIdScrollBackFollower(`follower-${item?.id}`, () => {
                                        navigate(`${newPathUser}${item?.account_id}`);
                                      })
                                    );
                                  }
                                }}>
                                {item?.avatar ? (
                                  <ImgCustom
                                    screen={item?.avatar ? 'recommend_top_56_56' : ''}
                                    src={item?.avatar ? `${item?.avatar}` : DefaultAvatar}
                                    alt='avatar'
                                  />
                                ) : (
                                  <img src={DefaultAvatar} alt={item?.id} />
                                )}
                                <div className='content-left-item-info'>
                                  <span className='name'>
                                    {item?.account_name ||
                                      item?.full_name ||
                                      t('Creator.accountNoName')}
                                  </span>

                                  <span className='list-sub'>
                                    {item?.packages?.map((x) => (
                                      <div className='item-list-sub' key={x?.id}>
                                        {x.title}
                                      </div>
                                    ))}
                                  </span>
                                </div>
                              </div>
                              {item?.account_id !== dataUser?.account_id && (
                                <div
                                  className={`btn-followOrUnFollow ${
                                    item?.is_followed === 1 ? 'following' : 'no-follow'
                                  } ${
                                    item?.is_followed === 1 && hoverId === item?.id
                                      ? 'hover'
                                      : ''
                                  }`}
                                  onClick={() => handleFollowOrUnfollow(item.id)}
                                  onMouseOver={() => {
                                    if (item?.is_followed === 1 && item?.id) {
                                      !isMobile && setHoverId(item.id);
                                    }
                                  }}
                                  onMouseLeave={() => {
                                    if (item?.is_followed === 1) {
                                      setHoverId(false);
                                    }
                                  }}>
                                  {item?.is_followed === 1
                                    ? hoverId === item?.id
                                      ? t('Creator.FollowList.unfollow')
                                      : t('Creator.FollowList.following')
                                    : t('Creator.FollowList.followUs')}
                                </div>
                              )}
                            </div>
                          </>
                        ))
                      : renderEmptyData()}
                  </>
                )}
              </div>
            </InfiniteLoad>
          </div>
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(FollowerPage);
