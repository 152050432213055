import { ChevronLeft, ChevronRight, PlayIcon } from 'images';
import { useContext, useEffect, useRef, useState } from 'react';
import { linkS3, SECOND_DELAY_TIMELINE } from 'utils';
import { ImgCustom } from '..';
import { TimelineContext } from './index';
import { StyledTimeline } from './styled';
export const Card = () => {
  const { data, elementBookmark } = useContext(TimelineContext);
  const [run, setRun] = useState(false);
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);
  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };
  useEffect(() => {
    if (run) {
      resetTimeout();
      timeoutRef.current = setTimeout(() => {
        console.log(index);
        setIndex((prev) => (prev === data.length - 1 ? 0 : prev + 1));
      }, SECOND_DELAY_TIMELINE * 1000);

      return () => {
        resetTimeout();
      };
    }
  }, [index, run]);
  const refCard = useRef();
  return (
    <StyledTimeline.Card ref={refCard}>
      <StyledTimeline.Global className='slideshow'>
        {elementBookmark}
        {data.length > 1 && (
          <>
            <StyledTimeline.Button
              onClick={() => setIndex((prev) => (prev === 0 ? data.length - 1 : prev - 1))}>
              <ChevronLeft />
            </StyledTimeline.Button>
            <StyledTimeline.Button
              right
              onClick={() => setIndex((prev) => (prev === data.length - 1 ? 0 : prev + 1))}>
              <ChevronRight />
            </StyledTimeline.Button>
            <StyledTimeline.LineDot>
              {data.map((_, i) => (
                <div
                  key={i}
                  className={`dot_i ${i === index ? 'active' : ''}`}
                  onClick={() => setIndex(i)}
                />
              ))}
            </StyledTimeline.LineDot>
          </>
        )}
        {data.length > 1 && (
          <StyledTimeline.Count>
            {index + 1}/{data.length}
          </StyledTimeline.Count>
        )}
        <StyledTimeline.ButtonPlay isShow={!run} onClick={() => setRun(!run)}>
          <PlayIcon />
        </StyledTimeline.ButtonPlay>
        <div
          className='slideshowSlider'
          onClick={() => setRun(!run)}
          style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}>
          {data.map((dt, i) => (
            <div className='slide' key={i}>
              <StyledTimeline.Loading
                play={run && index === i ? 1 : 0 && index < data?.length - 1}>
                <div className='progress-bar' />
              </StyledTimeline.Loading>
              <ImgCustom screen='timeline_auto_375_pc' avatarErr={false} src={dt?.url} />
            </div>
          ))}
        </div>
      </StyledTimeline.Global>
    </StyledTimeline.Card>
  );
};
