import { LayoutCreatorRight } from 'components';
import { newPathUser } from 'constants/layout/constant';
import withCreatorRole from 'hocs/withCreatorRole';
import { useGetIdFromRecommendUser } from 'hooks/v2/useGetIdFromRecommendUser';
import { MinusIcon, PlusIcon } from 'images';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { getListFeedBack, setIdScrollBackHelp } from 'store/actions/feedback';
import { logout } from 'store/actions/users';
import './styles.scss';

const HelpScreen = () => {
  const { idScrollBackHelp, listFeeds, loading, isLoadingFeedback } = useSelector(
    (state) => state.feedback
  );
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { idFromRecommendUser } = useGetIdFromRecommendUser();

  useEffect(() => {
    if (
      i18n.language &&
      ((!idScrollBackHelp && !idFromRecommendUser) || i18n.language !== idScrollBackHelp)
    ) {
      dispatch(getListFeedBack(i18n.language === 'en' ? 'en' : 'ja'));
    }
  }, [dispatch, i18n.language, idScrollBackHelp, idFromRecommendUser]);

  const navigate = useNavigate();
  const [openContent, setOpenContent] = useState(null);

  const handleToggleContent = (question) => {
    if (openContent === question) {
      setOpenContent(null);
    } else {
      setOpenContent(question);
    }
  };

  const renderTextWithLinks = (id, text) => {
    if (id === 2) {
      const inquiryTextEn = 'top page';
      const inquiryText = 'TOPページ';

      const parts = text.split(i18n.language === 'en' ? inquiryTextEn : inquiryText);

      return parts.map((part, index) => {
        return (
          <span key={index}>
            {index > 0 && (
              <span
                className='custom-css-link-help'
                onClick={() => {
                  dispatch(logout());
                }}>
                {i18n.language === 'en' ? inquiryTextEn : inquiryText}
              </span>
            )}

            {part}
          </span>
        );
      });
    } else if (id === 7 || id === 8 || id === 23 || id === 38) {
      const inquiryTextEn = 'inquiry form';
      const inquiryText = 'お問い合わせフォーム';
      const inquiryLink = `${newPathUser}post-help`;

      const parts = text.split(i18n.language === 'en' ? inquiryTextEn : inquiryText);

      return parts.map((part, index) => {
        return (
          <span key={index}>
            {index > 0 && (
              <a
                style={{ wordWrap: 'break-word' }}
                href={inquiryLink}
                target='_blank'
                rel='noopener noreferrer'>
                {i18n.language === 'en' ? inquiryTextEn : inquiryText}
              </a>
            )}

            {part}
          </span>
        );
      });
    } else if (id === 27) {
      const inquiryTextEn = 'Inquiry Form';
      const inquiryText = 'お問い合わせフォーム';
      const inquiryLink = `${newPathUser}post-help`;

      const parts = text.split(i18n.language === 'en' ? inquiryTextEn : inquiryText);

      return parts.map((part, index) => {
        return (
          <span key={index}>
            {index > 0 && (
              <a
                style={{ wordWrap: 'break-word' }}
                href={inquiryLink}
                target='_blank'
                rel='noopener noreferrer'>
                {i18n.language === 'en' ? inquiryTextEn : inquiryText}
              </a>
            )}

            {part}
          </span>
        );
      });
    } else if (id === 28 || id === 46 || id === 48) {
      const inquiryTextEn = 'inquiry';
      const inquiryText = 'お問い合わせ';
      const inquiryLink = `${newPathUser}post-help`;

      const parts = text.split(i18n.language === 'en' ? inquiryTextEn : inquiryText);

      return parts.map((part, index) => {
        return (
          <span key={index}>
            {index > 0 && (
              <a
                style={{ wordWrap: 'break-word' }}
                href={inquiryLink}
                target='_blank'
                rel='noopener noreferrer'>
                {i18n.language === 'en' ? inquiryTextEn : inquiryText}
              </a>
            )}

            {part}
          </span>
        );
      });
    } else if (id === 25) {
      const inquiryTextEn = 'Change password';
      const inquiryText = 'パスワードの変更';
      const inquiryLink = `${newPathUser}change-password`;

      const parts = text.split(i18n.language === 'en' ? inquiryTextEn : inquiryText);

      return parts.map((part, index) => {
        return (
          <span key={index}>
            {index > 0 && (
              <a
                style={{ wordWrap: 'break-word' }}
                href={inquiryLink}
                target='_blank'
                rel='noopener noreferrer'>
                {i18n.language === 'en' ? inquiryTextEn : inquiryText}
              </a>
            )}

            {part}
          </span>
        );
      });
    } else if (id === 24) {
      const inquiryTextEn = 'Reset password';
      const inquiryText = 'パスワードの再設定';

      const parts = text.split(i18n.language === 'en' ? inquiryTextEn : inquiryText);

      return parts.map((part, index) => {
        return (
          <span key={index}>
            {index > 0 && (
              <span
                className='custom-css-link-help'
                onClick={() => {
                  dispatch(logout('/forgot-password'));
                }}>
                {i18n.language === 'en' ? inquiryTextEn : inquiryText}
              </span>
            )}

            {part}
          </span>
        );
      });
    } else {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      const parts = text.split(urlRegex);

      return parts.map((part, index) => {
        if (part.match(urlRegex)) {
          return (
            <a
              style={{ wordWrap: 'break-word' }}
              href={part}
              key={index}
              target='_blank'
              rel='noopener noreferrer'>
              {part}
            </a>
          );
        } else {
          return (
            <span style={{ display: 'block' }} key={index}>
              {part}
            </span>
          );
        }
      });
    }
  };

  return (
    <>
      <LayoutCreatorRight noButtonBack className='help-header' titlePage={t('Common.help')}>
        <div className='container-fluid w-100 bg-white creator-help-page'>
          <div className='box-text'>
            {/* <SmsEditIcon /> */}
            <span className='text'>{t('Help.inquiry')}</span>
          </div>
          <div className='text1'>{t('Help.helpText')}</div>
          <div
            className='btn-help'
            onClick={() => {
              dispatch(
                setIdScrollBackHelp(`${i18n.language}`, () =>
                  navigate(`${newPathUser}post-help`)
                )
              );
            }}>
            {t('Help.inquiry')}
          </div>
          <div className='divider'></div>
          <div className='box-text'>
            {/* <MessageQuestionIcon /> */}
            <span className='text'>{t('Help.frequetylQuestion')}</span>
          </div>
          <div className='text1'>{t('Help.helpText')}</div>

          {((loading && !listFeeds.length) || isLoadingFeedback) && <ShimmerPostDetails />}

          {listFeeds.map((item, index) => {
            return (
              <>
                <div key={index} className='title'>
                  {item?.name}
                </div>
                {item?.question.map((q, index) => {
                  return (
                    <div className='box-content' key={index}>
                      <div className='item'>
                        <div
                          className={`item-header ${
                            q?.question !== openContent && 'no-active'
                          }`}
                          onClick={() => handleToggleContent(q?.question)}>
                          <span className='sub-title'>{q?.question}</span>
                          <span className='icon'>
                            {openContent === q?.question ? (
                              // <span className='icon-subtraction'></span>
                              <MinusIcon />
                            ) : (
                              <PlusIcon />
                            )}
                          </span>
                        </div>
                        {openContent === q?.question && (
                          <div className='item-content'>
                            {renderTextWithLinks(q?.id, q?.answer)}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </>
            );
          })}
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(HelpScreen);
