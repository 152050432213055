/* eslint-disable react-hooks/exhaustive-deps */
import { CustomReSelect, LayoutFan, PopupConfirmFan } from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import { ArrowLeftIcon } from 'images';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  autoFormatPhone,
  checkMaxLengthInputNumber,
  customStyles,
  disableSpaceKeyCode,
  isEmptyObject,
  MONEY_TYPE,
  patternCharacterLatinh,
  patternCharacterLatinhNoSpace,
  patternNoFirstSpacing,
  maxLengthInputTypeNumber,
} from 'utils/utils';
import { getInforBankAccount, putInforBankAccount } from '../../store/actions/bankAccount';
import NavbarUserProfile from './components/navbar';
import './user.scss';

const TranferAccountInfor = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: data_profile } = useSelector((state) => state.users);
  const { bankAccount, status, loading } = useSelector((state) => state.bankAccount);
  const [focusDepositType, setFocusDepositType] = useState(false);
  const [isVisiblePopup, setIsVisiblePopup] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    mode: 'all',
  });
  const watchBankName = watch('bank_name', '');
  const watchBankBranch = watch('bank_branch', '');
  const watchBankDepositType = watch('type', '');
  const watchAccountName = watch('account_name', '');
  const watchBankAccountNumber = watch('account_number', '');
  const watchPhone = watch('phone', '');
  const isDisabledButton =
    !watchBankName ||
    !watchBankBranch ||
    !watchPhone ||
    !watchBankAccountNumber ||
    !watchBankDepositType ||
    !watchAccountName ||
    !isEmptyObject(errors) ||
    loading;
  const { t } = useTranslation();

  useEffect(() => {
    if (data_profile) {
      data_profile?.is_affiliate !== 1 && navigate('/*');
    }
  }, [data_profile]);

  useEffect(() => {
    dispatch(getInforBankAccount());
  }, []);
  useEffect(() => {
    status === true && setIsVisiblePopup(true);
  }, [status]);

  useEffect(() => {
    if (bankAccount) {
      const {
        bank_name = '',
        bank_branch = '',
        type = '',
        account_name = '',
        account_number = '',
      } = bankAccount;

      let { phone = '' } = bankAccount;
      setValue('bank_name', bank_name || '');
      setValue('bank_branch', bank_branch || '');
      setValue('type', type || '');
      setValue('account_name', account_name || '');
      setValue('account_number', account_number || '');
      setValue('phone', autoFormatPhone(phone) || '');
    }
  }, [bankAccount]);

  const onSubmit = async (data) => {
    const newData = {
      ...data,
      type: data.type.value || watchBankDepositType,
      phone: data.phone?.split('-').join(''),
    };
    dispatch(putInforBankAccount(newData));
  };

  const customStyleReactSelect = {
    ...customStyles,
    dropdownIndicator: (base) => ({
      ...base,
      display: 'block',
    }),
  };

  const WithDropdownIndicator = () => {
    return <ArrowLeftIcon className={`${focusDepositType ? 'focus' : 'no-focus'}`} />;
  };

  return (
    <div>
      {isVisiblePopup && (
        <PopupConfirmFan text={t('Popup.saved')} colorSvg='#28c76f'>
          <div
            className='ok btn'
            onClick={() => {
              setIsVisiblePopup(false);
              navigate('/my-page');
            }}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}
      <LayoutFan titleHeader={t('User.inforBank.title')} loading={loading}>
        <div className='user-wrapper'>
          <div className='box-user-wrapper'>
            <NavbarUserProfile />
            <form
              onSubmit={(e) => e.preventDefault()}
              className='fansite-form form-tranfer-account'>
              <InputWrapper
                label={t('User.inforBank.bankName')}
                required={true}
                error={errors?.bank_name ? errors?.bank_name?.message : ''}>
                <input
                  maxLength={4}
                  type='number'
                  onInput={(e) => maxLengthInputTypeNumber(e)}
                  {...register('bank_name', {
                    required: t('ValidateMsg.bankNameRequired'),
                    validate: {
                      noFirstSpacing: (value) =>
                        (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                        t('ValidateMsg.bankNameRequired'),
                      characterJP: (value) => {
                        return (
                          (value &&
                            patternCharacterLatinhNoSpace?.test(value.toString().trim())) ||
                          t('ValidateMsg.bankNameRequired')
                        );
                      },
                    },
                  })}
                  onKeyDown={(e) => disableSpaceKeyCode(e)}
                  placeholder={t('ValidateMsg.bankNameRequired')}
                />
              </InputWrapper>
              <InputWrapper
                label={t('User.inforBank.bankBranch')}
                required={true}
                error={errors?.bank_branch ? errors?.bank_branch?.message : ''}>
                <input
                  maxLength={4}
                  type='number'
                  onInput={(e) => maxLengthInputTypeNumber(e)}
                  {...register('bank_branch', {
                    required: t('ValidateMsg.bankBranchRequired'),
                    validate: {
                      noFirstSpacing: (value) =>
                        (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                        t('ValidateMsg.bankBranchRequired'),
                      characterJP: (value) => {
                        return (
                          (value &&
                            patternCharacterLatinhNoSpace?.test(value.toString().trim())) ||
                          t('ValidateMsg.bankBranchRequired')
                        );
                      },
                    },
                  })}
                  onKeyDown={(e) => disableSpaceKeyCode(e)}
                  placeholder={t('ValidateMsg.bankBranchRequired')}
                />
              </InputWrapper>

              <InputWrapper label={t('User.inforBank.depositType')}>
                <div
                  className='gender'
                  onBlur={() => setFocusDepositType(false)}
                  onClick={() => setFocusDepositType(!focusDepositType)}>
                  <Controller
                    name='type'
                    control={control}
                    rules={{ required: t('ValidateMsg.depositTypeRequired') }}
                    error={errors?.type ? errors?.type?.message : ''}
                    render={({ field }) => (
                      <>
                        <CustomReSelect
                          field={field}
                          valueOption={MONEY_TYPE}
                          isMulti={false}
                          placeholder={t('ValidateMsg.depositTypeRequired')}
                          isSearchable={false}
                          stylesCustoms={customStyleReactSelect}
                          customComponent={{ DropdownIndicator: WithDropdownIndicator }}
                        />
                      </>
                    )}
                  />
                </div>
              </InputWrapper>

              <InputWrapper
                label={t('User.inforBank.accountNumber')}
                required={true}
                error={errors?.account_number ? errors?.account_number?.message : ''}>
                <input
                  id='account_number_F004'
                  onInput={() => checkMaxLengthInputNumber('account_number_F004')}
                  maxlength={45}
                  {...register('account_number', {
                    required: t('ValidateMsg.accountNumberRequired'),
                    validate: {
                      noFirstSpacing: (value) =>
                        (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                        t('ValidateMsg.accountNumberRequired'),
                      characterJP: (value) => {
                        return (
                          (value &&
                            patternCharacterLatinhNoSpace?.test(value.toString().trim())) ||
                          t('ValidateMsg.accountNumberRequired')
                        );
                      },
                    },
                  })}
                  placeholder={t('ValidateMsg.accountNumberRequired')}
                />
              </InputWrapper>

              <InputWrapper
                label={t('User.inforBank.accountName')}
                required={true}
                error={errors?.account_name ? errors?.account_name?.message : ''}>
                <input
                  maxLength={45}
                  {...register('account_name', {
                    required: t('ValidateMsg.accountNameRequired'),
                    validate: {
                      noFirstSpacing: (value) =>
                        (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                        t('ValidateMsg.accountNameRequired'),
                      characterJP: (value) => {
                        return (
                          (value && patternCharacterLatinh?.test(value.toString().trim())) ||
                          t('ValidateMsg.accountNameRequired')
                        );
                      },
                    },
                  })}
                  placeholder={t('ValidateMsg.accountNameRequired')}
                />
              </InputWrapper>

              <InputWrapper
                label={t('User.inforBank.phone')}
                required={true}
                error={errors?.phone ? errors?.phone?.message : ''}>
                <input
                  placeholder='0XX-XXXX-XXXX・0X-XXXX-XXXX・0120-XXXX-XXXX'
                  maxLength={14}
                  id='phone_F004'
                  onInput={() => checkMaxLengthInputNumber('phone_F004')}
                  {...register('phone', {
                    required: t('ValidateMsg.PHONE_IS_REQUIRED'),
                    validate: (value) => {
                      const cleaned = ('' + value).replace(/\D/g, '');
                      const match10 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
                      const match11 = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
                      const match12 = cleaned.match(/^(\d{4})(\d{4})(\d{4})$/);
                      if (
                        (!match10 && !match11 && !match12) ||
                        (match12 && match12[1] !== '0120') ||
                        cleaned.substring(0, 1) !== '0'
                      ) {
                        return t('ValidateMsg.C002_VALIDATE_PHONE_FORMAT');
                      }
                    },
                    onChange: (e) => {
                      const x = autoFormatPhone(e.target.value);
                      if (x) e.target.value = x;
                    },
                  })}
                />
              </InputWrapper>

              <InputWrapper
                id='fsite-submit'
                className={`button-submit ${isDisabledButton ? 'disabled' : ''}`}>
                <button onClick={handleSubmit(onSubmit)} disabled={isDisabledButton}>
                  {t('Common.buttonUpdate')}
                </button>
              </InputWrapper>
            </form>
          </div>
        </div>
      </LayoutFan>
    </div>
  );
};

export default TranferAccountInfor;
