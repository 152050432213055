import { PlusOutlined } from '@ant-design/icons';
import { newPathUser } from 'constants/layout/constant';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PackageItem, PopupGeneral, PopupLoading } from '..';
import { Button } from '../../pages/creator/layout/livestream/components/styled';
import { getListPackage } from '../../store/actions/lives';
import InfiniteLoad from '../Paging/InfiniteLoad';
import './index.scss';
const PopupListPackageC092 = ({ classNameCustom = '' }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { loadingPackage, packageData = [], pagination } = useSelector((state) => state.lives);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getListPackage(
        () => setIsLoading(false),
        () => setIsLoading(false)
      )
    );
  }, [page]);

  const fetchNext = () => {
    const pages = pagination?.total / pagination?.per_page;
    if (pages > page) {
      setPage(page + 1);
    }
  };
  return (
    <>
      {isLoading && <PopupLoading className={'popup-loading'} />}
      <Button
        bgc='5'
        cl='1'
        bdr='6px'
        dfc
        bd='none'
        h='44px'
        w='100%'
        mg='10px 0px'
        mmg='76px 0px 0px 0px'
        className={`subscribe-btn text-center text-white}`}
        onClick={() => setIsOpenPopup(true)}>
        {`${t('homePageHeader.subscribeHere')}`}
      </Button>
      {isOpenPopup && (
        <>
          <PopupGeneral
            className={`popup-list-package-c092 ${classNameCustom}`}
            generalClass='popup-list-package-c092-view fansite-creator-subcription-list'
            closeModal={() => setIsOpenPopup(false)}
            title=''>
            <div className='d-flex justify-content-center'>
              <button
                onClick={() => navigate(`${newPathUser}profile/subscription/create`)}
                className='btn btn-creator-package d-flex align-items-center'>
                <span className='d-flex me-3'>
                  <PlusOutlined />
                </span>
                {t('Creator.Home.newSubscription')}
              </button>
            </div>
            <div style={{ height: 500, overflow: 'hidden' }}>
              <div className='list-package-wrapper scroll-bar-custom'>
                {!!packageData.length ? (
                  <InfiniteLoad
                    loading={loadingPackage}
                    data={packageData}
                    fetchNext={() => fetchNext()}
                    className='list-package'>
                    {packageData.map((item) => (
                      <PackageItem packageItem={item} />
                    ))}
                  </InfiniteLoad>
                ) : (
                  !packageData.length &&
                  !loadingPackage && (
                    <div className='subscription-empty'>
                      <div className='empty-text'>{t('Creator.Home.noSubscriptionList')}</div>
                    </div>
                  )
                )}
              </div>
            </div>
          </PopupGeneral>
        </>
      )}
    </>
  );
};

export default PopupListPackageC092;
