import Icon from '@ant-design/icons';

const CheckedIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='13'
        height='10'
        fill='none'
        viewBox='0 0 13 10'>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M11.833 1.666l-7.448 7.5L1 5.756'></path>
      </svg>
    )}
    {...props}
  />
);

export default CheckedIcon;
