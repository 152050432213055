import {
  configParamsDELETE,
  configParamsGet,
  configParamsPostJSON,
  configParamsPUT,
  ENV,
  updateStatus,
} from '../../utils/constant';
import { callApi } from '../../utils/request';
import { TYPES } from './index';

const getListSurvey = ({ page = 1, limit = 20, chk_expired = 0 }, handleListSurvey) => {
  let url = `${ENV}/surveys?page=${page}&limit=${limit}&chk_expired=${chk_expired}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_SURVEY_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_SURVEY_SUCCESS, {
            data: response?.data,
            pagination: response?.pagination,
          })
        );
        handleListSurvey && handleListSurvey(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_SURVEY_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        handleListSurvey && handleListSurvey({});
      }
    );
  };
};

const getDetailSurvey = (id, handleSetData) => {
  let url = `${ENV}/surveys/${id}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_SURVEY_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_SURVEY_SUCCESS, {
            data: response.data,
          })
        );
        handleSetData && handleSetData(response.data || {});
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_SURVEY_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const fanReplySurvey = (id, params, handleSuccess) => {
  let url = `${ENV}/surveys/${id}/answer `;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.REP_SURVEY_ANSWER_REQUEST));
    callApi(
      url,
      configParamsPUT(params),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.REP_SURVEY_ANSWER_SUCCESS, {
            data: response.data,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.REP_SURVEY_ANSWER_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const createSurvey = (id, params) => {
  let url = `${ENV}/surveys/${id}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATE_SURVEY_REQUEST));
    callApi(
      url,
      configParamsPostJSON(params),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CREATE_SURVEY_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATE_SURVEY_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const creatorPostSurvey = (params, handleSuccess, handleError) => {
  let url = `${ENV}/surveys`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.POST_SURVEY_REQUEST));
    callApi(
      url,
      configParamsPostJSON(params),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.POST_SURVEY_SUCCESS, {
            data: response.data,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleError && handleError();
        dispatch(updateStatus(TYPES.POST_SURVEY_FAILED, { error: error.errors }));
      }
    );
  };
};

const creatorUpdateSurvey = (id, params, handleSuccess) => {
  let url = `${ENV}/surveys/${id}`;
  return (dispatch) => {
    callApi(
      url,
      configParamsPUT(params),
      null,
      async (response) => {
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleSuccess && handleSuccess({});
      }
    );
  };
};

const getDetailAnalysisSurvey = (id, handleSetDataSurveyAnalysis) => {
  let url = `${ENV}/surveys/analysis/${id}`;
  return () => {
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        handleSetDataSurveyAnalysis && handleSetDataSurveyAnalysis(response);
      },
      (error) => {
        handleSetDataSurveyAnalysis && handleSetDataSurveyAnalysis(error);
      }
    );
  };
};

const deleteSurvey = (id, handleDeleteSurveySuccess) => {
  let url = `${ENV}/surveys/${id}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.DELETE_SURVEY_REQUEST));
    callApi(
      url,
      configParamsDELETE,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.DELETE_SURVEY_SUCCESS, {
            data: response.data,
            id,
          })
        );
        handleDeleteSurveySuccess && handleDeleteSurveySuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.DELETE_SURVEY_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        handleDeleteSurveySuccess && handleDeleteSurveySuccess({});
      }
    );
  };
};

const creatorGetDetailSurvey = (id, handleSuccess, handleFailed) => {
  let url = `${ENV}/surveys/${id}`;
  return () => {
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleFailed && handleFailed();
      }
    );
  };
};

const setIdScrollBackSurvey = (id, cb) => {
  return (dispatch) => {
    dispatch(
      updateStatus('SET_ID_SCROLL_BACK_SURVEY', {
        data: {
          id,
        },
      })
    );
    cb && cb();
  };
};

const clearListSurvey = () => {
  return (dispatch) => {
    dispatch(updateStatus('CLEAR_LIST_SURVEY'));
  };
};

export {
  getListSurvey,
  getDetailSurvey,
  createSurvey,
  creatorPostSurvey,
  creatorUpdateSurvey,
  getDetailAnalysisSurvey,
  deleteSurvey,
  creatorGetDetailSurvey,
  fanReplySurvey,
  setIdScrollBackSurvey,
  clearListSurvey,
};
