import Icon from '@ant-design/icons';

const ICCopyUpIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M12.9415 10.9503V2.22656C12.9415 1.19281 12.1003 0.351562 11.0665 0.351562H2.34277C1.30902 0.351562 0.467773 1.19281 0.467773 2.22656V10.9503C0.467773 11.9841 1.30902 12.8253 2.34277 12.8253H11.0665C12.1003 12.8253 12.9415 11.9841 12.9415 10.9503ZM1.71777 10.9503V2.22656C1.71777 1.88198 1.99819 1.60156 2.34277 1.60156H11.0665C11.4111 1.60156 11.6915 1.88198 11.6915 2.22656V10.9503C11.6915 11.2949 11.4111 11.5753 11.0665 11.5753H2.34277C1.99819 11.5753 1.71777 11.2949 1.71777 10.9503ZM15.6569 5.18198H14.1378C13.7928 5.18198 13.5128 5.46156 13.5128 5.80698C13.5128 6.1524 13.7928 6.43198 14.1378 6.43198H15.6569C16.0015 6.43198 16.2819 6.7124 16.2819 7.05698V15.7807C16.2819 16.1253 16.0015 16.4057 15.6569 16.4057H6.93361C6.58902 16.4057 6.30861 16.1253 6.30861 15.7807V14.0528C6.30861 13.7078 6.02902 13.4278 5.68361 13.4278C5.33819 13.4278 5.05861 13.7078 5.05861 14.0528V15.7807C5.05861 16.8145 5.89986 17.6557 6.93361 17.6557H15.6569C16.6907 17.6557 17.5319 16.8145 17.5319 15.7807V7.05698C17.5319 6.02323 16.6907 5.18198 15.6569 5.18198Z'
          fill='#666565'
        />
      </svg>
    )}
    {...props}
  />
);

export default ICCopyUpIcon;
