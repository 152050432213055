import Icon from '@ant-design/icons';

const JCBIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={props?.width || 32}
        height={props?.height || 12}
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M48 35.0471C48 39.1547 44.6553 42.4995 40.5477 42.4995H0V12.9836C0 8.87599 3.34474 5.53125 7.45232 5.53125H48V35.0471Z'
          fill='white'
        />
        <path
          d='M37.5254 8.02539C34.5915 8.02539 32.1856 10.4019 32.1856 13.3652V18.9105H39.7259C39.902 18.9105 40.1074 18.9105 40.2541 18.9398C41.9558 19.0278 43.2174 19.908 43.2174 21.4337C43.2174 22.6366 42.3665 23.6635 40.7822 23.8689V23.9276C42.5132 24.0449 43.8335 25.0132 43.8335 26.5095C43.8335 28.1232 42.3665 29.1794 40.4301 29.1794H32.1562V40.0352H39.99C42.924 40.0352 45.3298 37.6586 45.3298 34.6953V8.02539H37.5254Z'
          fill='url(#paint0_linear_6102_615223)'
        />
        <path
          d='M34.7968 27.4777H37.8775C37.9215 27.4777 37.9949 27.4704 38.0682 27.463C38.1416 27.4557 38.2149 27.4483 38.2589 27.4483C38.8457 27.331 39.3445 26.8029 39.3445 26.0694C39.3445 25.3652 38.8457 24.8371 38.2589 24.6904C38.1709 24.6611 37.9949 24.6611 37.8775 24.6611H34.7968V27.4777Z'
          fill='url(#paint1_linear_6102_615223)'
        />
        <path
          d='M37.8775 20.5242C38.4643 20.6122 38.9631 21.0816 38.9631 21.7858C38.9631 22.4899 38.4643 22.9594 37.8775 23.0474C37.8482 23.0767 37.6721 23.0767 37.5841 23.0767H34.7968V20.4948H37.5841C37.6326 20.4948 37.6988 20.5037 37.7584 20.5117C37.807 20.5182 37.8511 20.5242 37.8775 20.5242Z'
          fill='url(#paint2_linear_6102_615223)'
        />
        <path
          d='M8.00982 8.02539C5.07583 8.02539 2.66996 10.4019 2.66996 13.3652V26.5388C4.1663 27.2723 5.72131 27.7418 7.27632 27.7418C9.12473 27.7418 10.1223 26.6269 10.1223 25.1012V18.8811H14.6993V25.0718C14.6993 27.4777 13.203 29.4435 8.12718 29.4435C5.04649 29.4435 2.64062 28.7687 2.64062 28.7687V40.0058H10.4744C13.4083 40.0058 15.8142 37.6293 15.8142 34.666V8.02539H8.00982Z'
          fill='url(#paint3_linear_6102_615223)'
        />
        <path
          d='M22.7676 8.02539C19.8336 8.02539 17.4277 10.4019 17.4277 13.3652V20.3481C18.7774 19.2039 21.1246 18.4704 24.9094 18.6464C26.9338 18.7344 29.105 19.2919 29.105 19.2919V21.5511C28.0194 20.9936 26.7285 20.4948 25.0561 20.3775C22.1808 20.1721 20.4497 21.5804 20.4497 24.0449C20.4497 26.5388 22.1808 27.9472 25.0561 27.7124C26.7285 27.5951 28.0194 27.067 29.105 26.5388V28.798C29.105 28.798 26.9632 29.3555 24.9094 29.4435C21.1246 29.6195 18.7774 28.886 17.4277 27.7418V40.0645H25.2615C28.1955 40.0645 30.6013 37.688 30.6013 34.7247V8.02539H22.7676Z'
          fill='url(#paint4_linear_6102_615223)'
        />
        <defs>
          <linearGradient
            id='paint0_linear_6102_615223'
            x1='32.1798'
            y1='21.7814'
            x2='45.3648'
            y2='21.7814'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='#007940' />
            <stop offset='0.2285' stopColor='#00873F' />
            <stop offset='0.7433' stopColor='#40A737' />
            <stop offset={1} stopColor='#5CB531' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_6102_615223'
            x1='32.1798'
            y1='21.7814'
            x2='45.3648'
            y2='21.7814'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='#007940' />
            <stop offset='0.2285' stopColor='#00873F' />
            <stop offset='0.7433' stopColor='#40A737' />
            <stop offset={1} stopColor='#5CB531' />
          </linearGradient>
          <linearGradient
            id='paint2_linear_6102_615223'
            x1='32.1798'
            y1='21.7814'
            x2='45.3648'
            y2='21.7814'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='#007940' />
            <stop offset='0.2285' stopColor='#00873F' />
            <stop offset='0.7433' stopColor='#40A737' />
            <stop offset={1} stopColor='#5CB531' />
          </linearGradient>
          <linearGradient
            id='paint3_linear_6102_615223'
            x1='2.66329'
            y1='24.0169'
            x2='16.0523'
            y2='24.0169'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='#1F286F' />
            <stop offset='0.4751' stopColor='#004E94' />
            <stop offset='0.8261' stopColor='#0066B1' />
            <stop offset={1} stopColor='#006FBC' />
          </linearGradient>
          <linearGradient
            id='paint4_linear_6102_615223'
            x1='17.3515'
            y1='24.0169'
            x2='30.3548'
            y2='24.0169'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='#6C2C2F' />
            <stop offset='0.1735' stopColor='#882730' />
            <stop offset='0.5731' stopColor='#BE1833' />
            <stop offset='0.8585' stopColor='#DC0436' />
            <stop offset={1} stopColor='#E60039' />
          </linearGradient>
        </defs>
      </svg>
    )}
    {...props}
  />
);

export default JCBIcon;
