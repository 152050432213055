import React, { useRef, useEffect, useLayoutEffect, useMemo } from 'react';
import { CloseIcon, SendChatIcon } from 'images';

import { checkLinkPostSale } from 'utils/utils';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useUserRole from 'hooks/useUserRole';
import { useSelector } from 'react-redux';
import CommentPostTimeLine from 'components/comment/commentPostTimeLine';
import { InputWrapper } from 'components/Input/InputWrapper';
import { useDispatch } from 'react-redux';
import {
  creatorDeleteArticle,
  postCommentDetail,
  deleteCommentDetail,
  deleteReplyCommentDetail,
  postFollowCommentDetail,
  postFollowReplyCommentDetail,
  creatorGetPostDetail,
  resetCommentDetail,
  updateLikeCommentDetail,
  updateLikeReplyComment,
  getIDHomePage,
  getPageShort,
} from 'store/actions/usersNew';
import useRedirect from 'hooks/useRedirect';
import './PopupViewFullMess.scss';
import { useNavigate } from 'react-router-dom';
import { ImgCustom, PopupConfirmFan, PopupSharePost, Textarea } from 'components';
import { AVATAR_DEFAULT, STORAGE } from 'utils';
import { hideCommentPost, logUserProfile } from 'store/actions/myPage';
import Storage from 'utils/storage';
import { getInfoMetaPost } from 'store/actions/postMeta';
import MetaTagViewer from 'components/MetaTagViewer/MetaTagViewer';
import { CommentPostTimeLineShowAll } from 'components/comment/commentListShowAll';
import HideCommentIcon from 'images/HideCommentIcon';
import { clearIdAndDataHideComment } from 'store/actions/commentHide';

const PopupViewFullMess = ({
  item,
  close,
  toggleValueVideo,
  index,
  className,
  setFlgRenderCMT,
  closePopupComment,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const [popupDeleteComment, setPopupDeleteComment] = useState(null);
  const [popupHideComment, setPopupHideComment] = useState(null);
  const { dataUser } = useSelector((state) => state.users);
  const { dataInfoOther, initPageHome } = useSelector((state) => state.usersNew);
  const { detailPost, statusPostComment, error, loadingPostComment } = useSelector(
    (state) => state.usersNew
  );
  const dataProfile = useSelector((state) => state.users.dataUser);
  const [countComment, setCountComment] = useState(0);
  const [valueComment, setValueComment] = useState('');
  const [checkSendChat, setCheckSendChat] = useState(null);
  const [isVisiblePopUpDeletePost, setIsVisiblePopDeletePost] = useState(null);
  const [isVisiblePopUpDeletePostComplete, setIsVisiblePopDeletePostComplete] =
    useState(false);
  const refScrollTo = useRef(null);
  const [trickReloadComment, setTrickReloadComment] = useState(null);
  const [idPostMeta, setIdPostMeta] = useState(null);
  const [postDetailMeta, setPostDetailMeta] = useState(null);
  useEffect(() => {
    dispatch(creatorGetPostDetail(item?.id, false));

    return () => {
      dispatch(resetCommentDetail());
    };
  }, []);

  const dispatch = useDispatch();

  useRedirect(error);

  // const handleDeleteComment = () => {
  //   toggleValueVideo(index, 'number_comment', -1);
  //   setCountComment((prev) => prev - 1);
  //   const { id, type, idParent } = popupDeleteComment;
  //   type === 'comment'
  //     ? dispatch(deleteCommentDetail(id))
  //     : dispatch(deleteReplyCommentDetail(id, idParent));
  //   setPopupDeleteComment(false);
  // };
  const handleDeleteComment = () => {
    const { id, type, idParent, cb = () => {}, postId, totalComment } = popupDeleteComment;
    type === 'comment'
      ? dispatch(
          deleteCommentDetail(id, postId, (response) => {
            cb();
            toggleValueVideo(index, 'number_comment', - totalComment);
          })
        )
      : dispatch(
          deleteReplyCommentDetail(id, postId, (response) => {
            cb();
            toggleValueVideo(index, 'number_comment', -1);
          })
        );
    setPopupDeleteComment(false);
  };

  const handleHideComment = () => {
    const { id, cb = () => {} } = popupHideComment;
    dispatch(hideCommentPost(id, cb));
    setPopupHideComment(false);
  };

  // const handleLikeComment = (data) => {
  //   const { commentId, type, dataPost } = data;
  //   type === 'comment'
  //     ? dispatch(updateLikeCommentDetail(commentId, dataPost))
  //     : dispatch(updateLikeReplyComment(commentId, dataPost));
  // };
  const handleLikeComment = (data, cb = () => {}) => {
    const { commentId, type, dataPost } = data;
    cb();
    dispatch(updateLikeCommentDetail(commentId, dataPost));
    // type === 'comment'
    //   ? dispatch(updateLikeCommentDetail(commentId, dataPost))
    //   : dispatch(updateLikeReplyComment(commentId, dataPost));
  };
  const handleFollowUserComment = (data) => {
    const { id, type } = data;
    type === 'comment'
      ? dispatch(postFollowCommentDetail(id))
      : dispatch(postFollowReplyCommentDetail(id));
  };

  const handleTextarea = (e, id, height) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    const idPost = checkLinkPostSale(e.target.value);
    if (idPost) {
      setIdPostMeta(idPost);
    } else {
      setIdPostMeta(null);
      setPostDetailMeta(null);
    }
    setValueComment(e.target.value);
    setCheckSendChat(id);
    if (e.target.value?.toString().trim() === '') {
      e.target.style.height = height;
      setCheckSendChat(null);
    }
  };

  useEffect(() => {
    if (idPostMeta) {
      dispatch(
        getInfoMetaPost(idPostMeta, (dataMeta) => {
          if (dataMeta?.title) {
            setPostDetailMeta(dataMeta);
          } else {
            setPostDetailMeta(null);
          }
        })
      );
    } else {
      setPostDetailMeta(null);
    }
  }, [idPostMeta, dispatch]);

  // ESC FUNC
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setCheckSendChat(null);
      setValueComment('');
    }
  }, []);

  useRedirect(error);
  // START FUNC ESC
  useEffect(() => {
    if (statusPostComment) {
      setCheckSendChat(null);
      setValueComment('');
    }
    document.addEventListener('keydown', escFunction);
    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction, statusPostComment]);

  const addCountComment = () => {
    toggleValueVideo(index, 'number_comment');
  };

  const handlePostComment = (id, parent_id) => {
    setFlgRenderCMT(true);
    const data = {
      parent_id: parent_id,
      content: valueComment,
    };
    if (checkSendChat) {
      addCountComment();
      const btnID = document.getElementById(`textarea-post-comment-full`);
      btnID.style.height = '44px';
      setIdPostMeta(null);
      setPostDetailMeta(null);
      const handleSuccess = (data) => {
        setTrickReloadComment({
          id: id,
          data: data.data,
        });
      };
      dispatch(postCommentDetail(id, data, handleSuccess, undefined, false));
    }
  };

  const deletePostTimeLine = () => {
    setIsVisiblePopDeletePost(null);
    const { id } = isVisiblePopUpDeletePost;
    dispatch(creatorDeleteArticle(id, () => setIsVisiblePopDeletePostComplete(true)));
    close();
  };

  const disableArea = (item) => {
    return (
      (item?.is_followed === 0 &&
        item?.follow_flag === 1 &&
        dataUser?.id !== dataInfoOther?.id) ||
      detailPost?.enable_comment !== 1
    );
  };

  const isCreator = useMemo(() => {
    return +dataUser?.id === +item?.user_id;
  }, [dataUser?.id, item?.user_id]);

  return (
    <>
      {popupCopyLink && (
        <PopupSharePost
          isVisiblePopup={() => setPopupCopyLink(false)}
          data={popupCopyLink || {}}
        />
      )}
      {isVisiblePopUpDeletePost && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePost(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.confirmDeletePost')}>
          <div className='btn cancel' onClick={() => setIsVisiblePopDeletePost(null)}>
            {t('Common.buttonNo')}
          </div>
          <div className='ok btn' onClick={deletePostTimeLine}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpDeletePostComplete && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePostComplete(e)}
          iconNote={true}
          text={t('Popup.deletePostSucess')}>
          <div className='ok btn' onClick={() => setIsVisiblePopDeletePostComplete(false)}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {popupDeleteComment && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupDeleteComment(false)}
          colorSvg='#FF9F43'
          text={t('Popup.confirmDeleteComment')}
          className='popup-delete'>
          <div className='ok btn' onClick={handleDeleteComment}>
            {t('Common.buttonDelete')}
          </div>
          <div className='btn cancel' onClick={() => setPopupDeleteComment(false)}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}
      {popupHideComment && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupHideComment(false)}
          colorSvg='#FF9F43'
          iconNote
          text={t('Popup.confirmHideComment')}
          className='popup-delete'>
          <div className='ok btn' onClick={handleHideComment}>
            {t('Common.buttonYes')}
          </div>
          <div className='btn cancel' onClick={() => setPopupHideComment(false)}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}
      <div className='popup-wrap-short-video1'>
        <div className={`card-item--detail left-content-comment-mess ${className}`}>
          <div className='close-icon'>
            <CloseIcon onClick={close} />
          </div>
          <div className='card-item--detail--header'>
            <div className='card-item--detail--header-left'>
              <div className='card-item--detail--header-title'>{t('Common.comment')}</div>
              <div className='card-item--detail--header-comment'>{countComment}</div>
            </div>
            <div className='card-item--detail--header-right'>
              {isCreator && (
                <HideCommentIcon
                  className='btn-list-hide-comment'
                  onClick={() => {
                    dispatch(getPageShort(initPageHome));
                    dispatch(
                      getIDHomePage({
                        id: item?.id,
                        tab: 4,
                        isMedia: false,
                        page: initPageHome,
                        type: 'post-shorts',
                      })
                    );
                    dispatch(clearIdAndDataHideComment());
                    navigate(`/comment-hide/${item?.id}`);
                  }}
                />
              )}
            </div>
          </div>
          <div className='card-item--detail-line'></div>
          <div className='comment-wrap-popup'>
            <div
              className='comment-wrap'
              style={{
                height: 'calc(100% - 100px)',
                overflow: 'auto',
                padding: '0 20px',
              }}>
              <CommentPostTimeLineShowAll
                isPopup={true}
                dataPost={detailPost}
                handleDeleteComment={(e) => {
                  setPopupDeleteComment(e);
                }}
                handleHideComment={(e) => {
                  setPopupHideComment(e);
                }}
                handleLikeComment={handleLikeComment}
                handleFollowUserComment={handleFollowUserComment}
                setTrickReloadComment={setTrickReloadComment}
                trickReloadComment={trickReloadComment}
                id={item?.id}
                dataProfile={dataProfile}
                defaultFetch={10}
                setCountComment={setCountComment}
                defaultCount={item?.number_comment}
                typeFollow='default'
                addCountComment={addCountComment}
              />
            </div>
            <div className='div-list-cmt'>
              <div className={`comment block`}>
                {dataProfile?.avatar ? (
                  <ImgCustom screen='avartar_navbar_60_60' src={dataProfile?.avatar} />
                ) : (
                  <img src={AVATAR_DEFAULT} alt='AVATAR_DEFAULT' />
                )}
                <form
                  className='form-comment-popup form-comment-popup-short-search'
                  onSubmit={(e) => e.preventDefault()}>
                  <InputWrapper
                    id='fsite-comment'
                    className={`form-item form-input-comment-meta ${
                      disableArea(item) ? 'disable' : ''
                    } ${postDetailMeta?.title ? 'haveMeta' : ''}`}>
                    <Textarea
                      disabled={disableArea(item)}
                      onChange={(e) => handleTextarea(e, item?.id, '44px')}
                      placeholder={t('comment.formAddRepComment.placeholder')}
                      onKeyDown={(e) => handleTextarea(e, item?.id, '44px')}
                      value={valueComment}
                      maxLength='500'
                      className='input-comment'
                      id='textarea-post-comment-full'
                      overflowHidden
                    />
                    {postDetailMeta?.title && (
                      <MetaTagViewer
                        title={postDetailMeta?.title}
                        url={postDetailMeta?.url}
                        thumbnail={postDetailMeta?.thumbnail}
                      />
                    )}
                    <SendChatIcon
                      className={`icon-send ${valueComment !== '' && 'active'}`}
                      onClick={() => {
                        if (!isAuth) {
                          dispatch(logUserProfile(item));
                        } else {
                          if (
                            valueComment !== '' &&
                            detailPost?.enable_comment === 1 &&
                            !loadingPostComment
                          ) {
                            handlePostComment(item?.id, 0);
                          }
                        }
                      }}
                    />
                  </InputWrapper>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupViewFullMess;
