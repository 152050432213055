import {
  AvatarInfoCreator,
  LayoutCreatorRight,
  PopupFanDetail,
  PopupLoading,
} from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import withCreatorRole from 'hocs/withCreatorRole';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  blockOrUnblockUser,
  creatorGetListMyBlock,
  getIDBlock,
} from 'store/actions/users';
import './index.scss';
import { convertToCurrentTime } from '../../../../utils/utils';
import { newPathUser } from 'constants/layout/constant';
import NoBlockList from 'images/NoBlockList.svg';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { useCallback } from 'react';
import { updateBlockInfoOther } from 'store/actions/usersNew';

const BlockPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    listBlock,
    listBlockPagination,
    loadingListBlockMore,
    idBlock,
    loadingBlock,
    initPageBlock,
  } = useSelector((state) => state.users);
  const [page, setPage] = useState(idBlock && initPageBlock ? +initPageBlock : 1);
  const [isVisible, setIsVisible] = useState(false);
  const [idFan, seIdFan] = useState(false);
  const [userId, setUserId] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  // api list page
  const fetchNext = () => {
    const pages = listBlockPagination?.total / listBlockPagination?.per_page;
    if (pages > page) {
      funCallApi(page + 1);
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (idBlock) {
      const idPost = document.getElementById(idBlock?.id);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idBlock]);

  const funCallApi = useCallback(
    (rs) => {
      dispatch(creatorGetListMyBlock(rs, 15, (_response) => setLoading(false)));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page]
  );

  useEffect(() => {
    if (initPageBlock < 1) {
      const top = document.getElementById('app_cocofans_0');
      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
      dispatch(creatorGetListMyBlock(page, 15, (_response) => setLoading(false)));
    }
  }, []);

  const handleUnblockFan = (id) => {
    dispatch(
      blockOrUnblockUser(id, (data) => {
        dispatch(updateBlockInfoOther(id, 0));
      })
    );
  };

  return (
    <>
      {loadingBlock && <PopupLoading className={'popup-loading'} />}
      {isVisible && (
        <PopupFanDetail
          classNameCustom='block-page'
          closeModal={() => {
            setIsVisible(false);
            setPage(1);
            dispatch(creatorGetListMyBlock(page, 15));
          }}
          isVisible={isVisible}
          id={idFan}
          userId={userId}
        />
      )}

      <LayoutCreatorRight
        noButtonBack
        titlePage={t('MenuSiderbar.creator.block')}
        className='block-list-layout'
        classNameHeader='block-page-header'>
        <div className='block-page-creator h-100'>
          <InfiniteLoad loading={loadingListBlockMore} data={listBlock} fetchNext={fetchNext}>
            <div className='list-block-creator'>
              {loading && !listBlock.length && (
                <div className='shimmer-loading'>
                  <ShimmerPostDetails />
                </div>
              )}
              {listBlock.length > 0
                ? listBlock.map((item, index) => (
                    <AvatarInfoCreator
                      className='avatar-infor-blocklist'
                      key={index}
                      id={item?.id}
                      images={item?.avatar}
                      name={item?.account_name || item?.full_name || t('Common.noData')}
                      time={moment(
                        convertToCurrentTime(item?.blocked_at, 'YYYY/MM/DD HH:mm:ss')
                      ).format('YYYY/MM/DD HH:mm:ss')}
                      type='block'
                      handleUnblockFan={handleUnblockFan}
                      handleOpenFanDetail={(e) => {
                        // setIsVisible(e);
                        // seIdFan(item.account_id);
                        // setUserId(item?.id);
                        dispatch(getIDBlock({ id: item?.id, page: page }));
                        navigate(`${newPathUser}${item?.account_id}`);
                      }}
                    />
                  ))
                : !loading && (
                    <div className='no-data'>
                      <img src={NoBlockList} alt='No Block' width={100} height={100} />
                      {t('Creator.BlockList.noBlockList')}
                    </div>
                  )}
            </div>
          </InfiniteLoad>
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(BlockPage);
