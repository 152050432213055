import { HeaderListIdolV2, PopupLoading, PopupVerifyEmail } from 'components';
import FooterListIdolV2 from 'components/FooterV2/FooterListIdolV2';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { verifyEmail } from '../../store/actions/users';
import './index.scss';

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const { tokenExist, urlExpired, loading } = useSelector((state) => state.users);
  const { search } = useLocation();
  const widthScreen = window.innerWidth;

  useEffect(() => {
    dispatch(verifyEmail(search));
  }, []);

  return (
    <>
      {loading && <PopupLoading className={'popup-loading'} />}
      <div className='fansite-verify-email'>
        {!loading && (
          <div className='block-div-signin' style={{ backgroundColor: 'transparent', background: `${widthScreen <= 575 && '#fff'}` }}>
            <HeaderListIdolV2 />
            <div className='container-div verify-success-container'>
              <PopupVerifyEmail tokenExist={tokenExist} urlExpired={urlExpired} />
              
            </div>
            <FooterListIdolV2 className='footer-verify-sign-up'/>
          </div>
        )}
      </div>
    </>
  );
};

export default VerifyEmail;
