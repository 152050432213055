export default function UploadVideoIcon(props) {
  return (
    <svg
      width='52'
      height='40'
      viewBox='0 0 52 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M51.507 2.47667C51.3563 2.38286 51.1839 2.32938 51.0065 2.32137C50.8292 2.31335 50.6527 2.35108 50.4941 2.43091L38.0141 8.67091C37.8417 8.75756 37.6968 8.89035 37.5954 9.05451C37.494 9.21867 37.4402 9.40774 37.44 9.60067V22.0807C37.4402 22.2736 37.494 22.4627 37.5954 22.6268C37.6968 22.791 37.8417 22.9238 38.0141 23.0104L50.4941 29.2504C50.6388 29.3228 50.7983 29.3605 50.96 29.3607C51.2358 29.3607 51.5004 29.2511 51.6954 29.0561C51.8904 28.861 52 28.5965 52 28.3207V3.36067C51.9999 3.18354 51.9546 3.00936 51.8683 2.85466C51.7821 2.69995 51.6577 2.56984 51.507 2.47667Z'
        fill='#DC143C'
      />
      <path
        d='M34.32 0.242188H5.2C2.32812 0.242188 0 2.57031 0 5.44219V26.2422C0 29.1141 2.32812 31.4422 5.2 31.4422H34.32C37.1919 31.4422 39.52 29.1141 39.52 26.2422V5.44219C39.52 2.57031 37.1919 0.242188 34.32 0.242188Z'
        fill='#F8D0D8'
      />
      <path
        d='M28.8163 19.2651L20.4963 10.9451C20.3013 10.7502 20.0368 10.6406 19.761 10.6406C19.4853 10.6406 19.2208 10.7502 19.0258 10.9451L10.7058 19.2651C10.5163 19.4613 10.4115 19.724 10.4139 19.9967C10.4162 20.2693 10.5256 20.5302 10.7184 20.723C10.9113 20.9158 11.1721 21.0252 11.4448 21.0276C11.7175 21.03 11.9802 20.9251 12.1763 20.7357L18.72 14.192V38.7214C18.72 38.9973 18.8296 39.2618 19.0246 39.4568C19.2196 39.6519 19.4842 39.7614 19.76 39.7614C20.0358 39.7614 20.3004 39.6519 20.4954 39.4568C20.6904 39.2618 20.8 38.9973 20.8 38.7214V14.192L27.3437 20.7357C27.5398 20.9251 27.8025 21.03 28.0752 21.0276C28.3479 21.0252 28.6087 20.9158 28.8016 20.723C28.9944 20.5302 29.1038 20.2693 29.1061 19.9967C29.1085 19.724 29.0037 19.4613 28.8142 19.2651H28.8163Z'
        fill='#DC143C'
      />
    </svg>
  );
}
