export default function MuteShortVideoIcon(props) {
  return (
    <svg
      width={88}
      height={88}
      viewBox='0 0 88 88'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g filter='url(#filter0_d_2476_166290)'>
        <rect x={24} y={20} width={40} height={40} rx={20} fill='white' fillOpacity='0.3' />
        <path
          d='M48.9982 42.5C49.1639 42.5 49.3229 42.4341 49.4401 42.3169C49.5573 42.1997 49.6232 42.0408 49.6232 41.875V38.125C49.6232 37.9592 49.5573 37.8003 49.4401 37.6831C49.3229 37.5658 49.1639 37.5 48.9982 37.5C48.8324 37.5 48.6734 37.5658 48.5562 37.6831C48.439 37.8003 48.3732 37.9592 48.3732 38.125V41.875C48.3732 42.0408 48.439 42.1997 48.5562 42.3169C48.6734 42.4341 48.8324 42.5 48.9982 42.5Z'
          fill='white'
        />
        <path
          d='M51.4982 36.25C51.3324 36.25 51.1734 36.3159 51.0562 36.4331C50.939 36.5503 50.8732 36.7092 50.8732 36.875V43.125C50.8732 43.2908 50.939 43.4497 51.0562 43.5669C51.1734 43.6842 51.3324 43.75 51.4982 43.75C51.6639 43.75 51.8229 43.6842 51.9401 43.5669C52.0573 43.4497 52.1232 43.2908 52.1232 43.125V36.875C52.1232 36.7092 52.0573 36.5503 51.9401 36.4331C51.8229 36.3159 51.6639 36.25 51.4982 36.25Z'
          fill='white'
        />
        <path
          d='M38.2125 32.7046C38.101 32.5819 37.9453 32.5086 37.7797 32.5007C37.6141 32.4928 37.4522 32.551 37.3295 32.6626C37.2069 32.7741 37.1335 32.9297 37.1257 33.0953C37.1178 33.2609 37.176 33.4228 37.2875 33.5455L39.7462 36.2501H36.5C36.1686 36.2504 35.8509 36.3822 35.6165 36.6166C35.3822 36.8509 35.2504 37.1686 35.25 37.5001V42.5001C35.2504 42.8315 35.3822 43.1492 35.6165 43.3835C35.8509 43.6179 36.1686 43.7497 36.5 43.7501H40.0355L45.4913 47.9934C45.5837 48.0652 45.6943 48.1097 45.8107 48.1217C45.9271 48.1338 46.0445 48.1129 46.1496 48.0615C46.2547 48.0101 46.3433 47.9302 46.4052 47.831C46.4672 47.7317 46.5 47.6171 46.5 47.5001V43.6792L49.7875 47.2955C49.8427 47.3562 49.9094 47.4055 49.9836 47.4405C50.0579 47.4755 50.1383 47.4955 50.2203 47.4994C50.3023 47.5033 50.3842 47.491 50.4615 47.4632C50.5387 47.4355 50.6097 47.3928 50.6705 47.3375C50.7312 47.2823 50.7805 47.2157 50.8154 47.1414C50.8504 47.0672 50.8704 46.9868 50.8743 46.9048C50.8782 46.8228 50.866 46.7408 50.8382 46.6636C50.8104 46.5863 50.7677 46.5153 50.7125 46.4546L38.2125 32.7046Z'
          fill='white'
        />
        <path
          d='M45.4125 38.7665C45.4974 38.8598 45.6085 38.9253 45.7312 38.9543C45.854 38.9833 45.9827 38.9745 46.1004 38.929C46.218 38.8835 46.3192 38.8034 46.3905 38.6994C46.4618 38.5954 46.5 38.4722 46.5 38.346V32.5C46.5 32.383 46.4672 32.2683 46.4052 32.1691C46.3433 32.0698 46.2547 31.9899 46.1496 31.9385C46.0445 31.8871 45.9271 31.8663 45.8107 31.8783C45.6943 31.8904 45.5837 31.9348 45.4913 32.0067L42.3781 34.4281C42.3101 34.4809 42.2537 34.5473 42.2126 34.6229C42.1715 34.6986 42.1464 34.7819 42.139 34.8677C42.1316 34.9535 42.142 35.04 42.1696 35.1215C42.1972 35.2031 42.2413 35.2781 42.2992 35.3419L45.4125 38.7665Z'
          fill='white'
        />
      </g>
      <defs>
        <filter
          id='filter0_d_2476_166290'
          x={0}
          y={0}
          width={88}
          height={88}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'>
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={12} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0' />
          <feBlend
            mode='multiply'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_2476_166290'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_2476_166290'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
}
