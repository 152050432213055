import React, { useRef, useEffect, useState } from 'react';
import { onErrImage, decryptPath } from 'utils';
import { ChevronLeft, ChevronRight } from 'images';
import Slider from 'react-slick';
import { ImgCustom } from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useRedirect from 'hooks/useRedirect';
import './PopupViewFullTimeLine.scss';
import './PopupViewFullIMG.scss';
import { LoadingImg } from 'components/LiveStream/LoadingImg';

const PopupViewFullIMG = ({ close, idx, className, item }) => {
  const slideRef = useRef(null);
  const [pageActive, setPageActive] = useState(
    item?.iSMany === 2 && item?.dataItem?.filter((i) => i.url !== '')?.length === 3
      ? idx
      : idx === 1 && item?.dataItem[1]?.url !== ''
      ? 0
      : idx === 2 && item?.dataItem[2]?.url !== ''
      ? 1
      : idx === 2 && item?.dataItem[1]?.url === ''
      ? 1
      : idx
  );
  const { t } = useTranslation();
  const { detailPost, error } = useSelector((state) => state.usersNew);

  useState(false);
  useRedirect(error);
  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        style={{
          cursor: `${
            pageActive === item?.dataItem?.filter((i) => i.url !== '')?.length - 1 && 'default'
          }`,
          opacity: `${
            pageActive === item?.dataItem?.filter((i) => i.url !== '')?.length - 1 && '0.3'
          }`,
        }}
        className={className}
        onClick={() => {
          pageActive !== item?.dataItem?.filter((i) => i.url !== '')?.length - 1 &&
            setPageActive(pageActive + 1);
          slideRef.current?.slickGoTo(pageActive + 1);
        }}>
        <ChevronRight />
      </div>
    );
  };
  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div
        style={{
          cursor: `${pageActive === 0 && 'default'}`,
          opacity: `${pageActive === 0 && '0.3'}`,
        }}
        className={className}
        onClick={() => {
          pageActive !== 0 && setPageActive(pageActive - 1);
          slideRef.current?.slickGoTo(pageActive - 1);
        }}>
        <ChevronLeft />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    swipe: false,
    afterChange: (current) => {
      // setPageActive(current);
      // slideRef?.current?.innerSlider?.list.setAttribute('tabindex', current);
      // slideRef?.current?.innerSlider?.list?.focus();
    },
    initialSlide:
      item?.iSMany === 2 && item?.dataItem?.filter((i) => i.url !== '')?.length === 3
        ? idx
        : idx === 1 && item?.dataItem[1]?.url !== ''
        ? 0
        : idx === 2 && item?.dataItem[2]?.url !== ''
        ? 1
        : idx === 2 && item?.dataItem[1]?.url === ''
        ? 1
        : idx,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  useEffect(() => {
    slideRef?.current?.innerSlider?.list.setAttribute('tabindex', 0);
    slideRef?.current?.innerSlider?.list?.focus();
  }, [slideRef]);

  return (
    <>
      <div className={`popup-wrap ${className ? className : ''}`} style={{ zIndex: '4000' }}>
        <div
          className='popup-view-full-post-timeline my-page-item'
          style={{ borderBottom: 'none' }}>
          {!item && (
            <div className='loading-timeline'>
              <LoadingImg />
            </div>
          )}
          <svg
            onClick={close}
            className='close-icon'
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            fill='none'
            viewBox='0 0 18 18'>
            <path
              stroke='currentColor'
              strokeLinecap='round'
              strokeWidth='1.5'
              d='M4.5 13.875l9.711-9.378M4.5 4.5l9.711 9.378'></path>
          </svg>
          {item?.iSMany === 2 && item?.dataItem?.filter((i) => i.url !== '')?.length > 1 && (
            <div className={`pagination-slide`}>
              <span>
                {pageActive + 1}/{item?.dataItem?.filter((i) => i.url !== '')?.length}
              </span>
            </div>
          )}
          <Slider
            ref={slideRef}
            {...settings}
            className='slide-image-full-view slide-popup-post-details'>
            {Number(item?.iSMany) === 2 &&
              item?.dataItem
                ?.filter((i) => i.url !== '')
                ?.map((i, index) => (
                  <div className='list-images-item popup-post-details' key={index}>
                    <ImgCustom
                      noIMGPlaceholder
                      className='change-bg '
                      screen='post_775_720_pc'
                      src={`${decryptPath(i.url)}`}
                      alt=''
                      onError={(e) => onErrImage(e, false)}
                    />
                  </div>
                ))}
            {Number(item?.iSMany) === 1 && (
              <div className='list-images-item popup-post-details' key={1}>
                <ImgCustom
                  noIMGPlaceholder
                  className='change-bg '
                  screen='post_775_720_pc'
                  src={`${decryptPath(item?.dataItem)}`}
                  alt=''
                  onError={(e) => onErrImage(e, false)}
                />
              </div>
            )}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default PopupViewFullIMG;
