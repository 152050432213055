/* eslint-disable react-hooks/exhaustive-deps */
import { FanRankingItem, LayoutFan, PopupFanDetail } from 'components';
import { InputWrapper } from 'components/FormLogin';
import useLoading from 'hooks/useLoading';
import { ArrowLeftIcon } from 'images';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { fanGetListRankingBuyer, setListRankingBuyer } from 'store/actions/users';
import {
  CustomOption,
  customStyles as customStylesReselect,
  OPTION_SORT_RANKING_BUYER,
} from 'utils/utils';
import './index.scss';

const ListRankingBuyer = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { account_id } = useParams();
  const { listRankingBuyer = [], loading } = useSelector((state) => state.users);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedSort, setSelectedSort] = useState('desc');
  const [isVisible, setIsVisible] = useState(false);
  const [idFan, seIdFan] = useState(false);
  const optionYear = new Array(11).fill(0).map((a, b) => {
    return { value: new Date().getFullYear() - b, label: new Date().getFullYear() - b };
  });
  const isLoading = useLoading();
  const [loadingCustom, setLoadingCustom] = useState(false);
  const loadingFunc = () => {
    setLoadingCustom(true);
    setTimeout(() => {
      setLoadingCustom(false);
    }, 1000);
  };
  const optionMonth = new Array(12).fill(0).map((a, b) => {
    return { value: b + 1, label: b + 1 };
  });

  const [convertListBuyer, setConvertListBuyer] = useState([]);

  useEffect(() => {
    dispatch(
      fanGetListRankingBuyer(account_id, {
        page: 1,
        limit: 10,
        sort: selectedSort,
        year: selectedYear,
        month: selectedMonth,
        active_flag: 1,
      })
    );
  }, [selectedMonth, selectedSort, selectedYear]);

  useEffect(() => {
    selectedSort === 'desc'
      ? setConvertListBuyer(
          listRankingBuyer.map((item, index) => {
            return { ...item, rank: index + 1 };
          })
        )
      : setConvertListBuyer(
          listRankingBuyer.map((item, index) => {
            return { ...item, rank: listRankingBuyer.length - index };
          })
        );
  }, [listRankingBuyer]);

  const handleResetSearch = () => {
    dispatch(setListRankingBuyer());
  };

  const onChangeSelectYear = (value) => {
    setSelectedYear(value.value);
    handleResetSearch();
    loadingFunc();
  };
  const onChangeSelectMonth = (value) => {
    setSelectedMonth(value.value);
    handleResetSearch();
    loadingFunc();
  };
  const onChangeSelectSort = (value) => {
    setSelectedSort(value.value);
    handleResetSearch();
    loadingFunc();
  };

  const customStyles = {
    ...customStylesReselect,
    control: () => ({
      minWidth: 87,
      width: 'max-content',
      display: 'flex',
      height: '32px',
      border: '1px solid #b1b6bb',
      borderRadius: '6px',
    }),
    valueContainer: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      height: 32,
      minHeight: 32,
    }),
    menu: (base, state) => ({
      ...base,
      width: '100%',
      border: 'none !important',
      borderRadius: '6px',
      overflow: 'hidden',
      zIndex: 2,
    }),
    menuList: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      maxHeight: 200,
    }),
  };

  const [focusYear, setFocusYear] = useState(false);
  const [focusMonth, setFocusMonth] = useState(false);
  const [focusSort, setFocusSort] = useState(false);

  const WithDropdownIndicatorYear = () => {
    return <ArrowLeftIcon className={`${focusYear ? 'focus' : 'no-focus'}`} />;
  };
  const WithDropdownIndicatorMonth = () => {
    return <ArrowLeftIcon className={`${focusMonth ? 'focus' : 'no-focus'}`} />;
  };
  const WithDropdownIndicatorSort = () => {
    return <ArrowLeftIcon className={`${focusSort ? 'focus' : 'no-focus'}`} />;
  };

  return (
    <>
      {isVisible && (
        <PopupFanDetail
          closeModal={() => setIsVisible(false)}
          isVisible={isVisible}
          id={idFan}
        />
      )}
      <LayoutFan
        titleHeader={t('MenuSiderbar.creator.rankingPayment')}
        loading={isLoading || loadingCustom}>
        <div className={`buyer-page-creator`}>
          <form className='fansite-form' onSubmit={(e) => e.preventDefault()}>
            <InputWrapper id='fsite-select'>
              <div
                className='select-box disabled'
                onBlur={() => setFocusYear(false)}
                onClick={() => setFocusYear(!focusYear)}>
                <Select
                  options={optionYear}
                  isSearchable={false}
                  isClearable={false}
                  defaultValue={optionYear[0]}
                  onChange={onChangeSelectYear}
                  styles={customStyles}
                  isDisabled
                  components={{
                    Option: CustomOption,
                    DropdownIndicator: WithDropdownIndicatorYear,
                  }}
                />
              </div>
            </InputWrapper>
            <InputWrapper id='fsite-select'>
              <div
                className='select-box disabled'
                onBlur={() => setFocusMonth(false)}
                onClick={() => setFocusMonth(!focusMonth)}>
                <Select
                  options={optionMonth}
                  isSearchable={false}
                  isClearable={false}
                  defaultValue={{
                    value: new Date().getMonth() + 1,
                    label: new Date().getMonth() + 1,
                  }}
                  onChange={onChangeSelectMonth}
                  styles={customStyles}
                  isDisabled
                  components={{
                    Option: CustomOption,
                    DropdownIndicator: WithDropdownIndicatorMonth,
                  }}
                />
              </div>
            </InputWrapper>
            <InputWrapper id='fsite-select'>
              <div
                className='select-box disabled'
                onBlur={() => setFocusSort(false)}
                onClick={() => setFocusSort(!focusSort)}>
                <label className='select-label' htmlFor='sortBox'>
                  {t('Creator.RankingPaymentList.sort')}
                </label>
                <Select
                  id='sortBox'
                  options={OPTION_SORT_RANKING_BUYER}
                  isSearchable={false}
                  isClearable={false}
                  defaultValue={{
                    label: t('Creator.RankingPaymentList.descendingOrder'),
                    value: 'desc',
                  }}
                  onChange={onChangeSelectSort}
                  placeholder={t('Creator.RankingPaymentList.descendingOrder')}
                  styles={{
                    ...customStyles,
                    control: () => ({
                      minWidth: i18n.language === 'jp' ? 87 : 165,
                      width: 'max-content',
                      display: 'flex',
                      height: '32px',
                      border: '1px solid #b1b6bb',
                      borderRadius: '6px',
                    }),
                  }}
                  isDisabled
                  components={{
                    Option: CustomOption,
                    DropdownIndicator: WithDropdownIndicatorSort,
                  }}
                />
              </div>
            </InputWrapper>
          </form>
          <div className='list-ranking-buyer'>
            {convertListBuyer?.length > 0
              ? convertListBuyer.map((item, index) => {
                  return (
                    <FanRankingItem
                      key={index}
                      item={item}
                      handleClick={(e) => {
                        seIdFan(e);
                        setIsVisible(true);
                      }}
                    />
                  );
                })
              : !loading && (
                  <div className='empty-box'>{t('Creator.BuyerList.noPayerList')}</div>
                )}
          </div>
        </div>
      </LayoutFan>
    </>
  );
};

export default ListRankingBuyer;
