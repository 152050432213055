import React from 'react';
import { TableCustom } from 'components';
import { convertTextMonthEN, formatCoin, isProdEnableLiveStream } from 'utils';
import { useTranslation } from 'react-i18next';
const Table = ({ columns }) => {
  const { t, i18n } = useTranslation();
  return (
    <TableCustom className='general'>
      <thead>
        <tr>
          <th></th>
          {columns.map((item, index) => (
            <th key={index}>
              {i18n.language === 'jp' ? (
                <>{+item.month + `${t('Common.month')}`}</>
              ) : (
                convertTextMonthEN(+item.month)
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>{t('Sales.total_sales')}</th>
          {columns.map((item) => (
            <td key={item.month}>{formatCoin(item.total)}</td>
          ))}
        </tr>
        <tr>
          <th>{t('Common.subscription')}</th>
          {columns.map((item) => (
            <td key={item.month}>{formatCoin(item.subscription_revenue)}</td>
          ))}
        </tr>
        <tr>
          <th>{t('Common.content')}</th>
          {columns.map((item) => (
            <td key={item.month}>{formatCoin(item.content_revenue)}</td>
          ))}
        </tr>
        <tr>
        <th>{t('Report.other_tips')}</th>
          {columns.map((item) => (
            <td key={item.month}>{formatCoin(item.other_revenue)}</td>
          ))}
        </tr>
        {+isProdEnableLiveStream === 1 && (
          <>
            <tr>
              <th>{t('Common.live_streaming')}</th>
              {columns.map((item) => (
                <td key={item.month}>{formatCoin(item.livestream_revenue)}</td>
              ))}
            </tr>
            <tr>
              <th>{t('Report.other_tips')}</th>
              {columns.map((item) => (
                <td key={item.month}>{formatCoin(item.other_revenue)}</td>
              ))}
            </tr>
          </>
        )}
      </tbody>
    </TableCustom>
  );
};

export default Table;
