import Icon from '@ant-design/icons';

const SendChatIconV2 = (props) => (
  <Icon
    component={() => (
      <svg
        width='22'
        height='22'
        viewBox='0 0 22 22'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M20.1764 9.69201L2.656 0.82403C2.36121 0.674832 2.02305 0.610512 1.68673 0.639665C1.35041 0.668817 1.03194 0.790055 0.773892 0.987174C0.515843 1.18429 0.330504 1.4479 0.242644 1.74278C0.154784 2.03765 0.168589 2.34973 0.282214 2.63732L3.2906 10.2508H11.1064C11.3265 10.2508 11.5376 10.3299 11.6932 10.4705C11.8488 10.6112 11.9362 10.8019 11.9362 11.0008C11.9362 11.1998 11.8488 11.3905 11.6932 11.5312C11.5376 11.6718 11.3265 11.7508 11.1064 11.7508H3.2906L0.282214 19.3644C0.193278 19.5907 0.16561 19.833 0.201526 20.0709C0.237443 20.3089 0.3359 20.5356 0.488656 20.7321C0.641411 20.9287 0.844023 21.0892 1.07953 21.2005C1.31504 21.3117 1.5766 21.3703 1.84232 21.3714C2.12747 21.3709 2.40769 21.3042 2.6561 21.1777L20.1763 12.3097C20.4337 12.1794 20.6481 11.9894 20.7972 11.7594C20.9464 11.5294 21.0251 11.2675 21.0251 11.0009C21.0251 10.7342 20.9465 10.4724 20.7973 10.2423C20.6481 10.0123 20.4338 9.82232 20.1764 9.69201Z'
          fill={props?.color || '#DC143C'}
        />
      </svg>
    )}
    {...props}
  />
);

export default SendChatIconV2;
