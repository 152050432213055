import { HeaderListIdolV2, PopupLoading } from 'components';
import FooterListIdolV2 from 'components/FooterV2/FooterListIdolV2';
import { InputWrapper } from 'components/FormLogin';
import useRedirect from 'hooks/useRedirect';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { forgotPassword } from 'store/actions/users';
import HomeQuickViewImageSigin from 'components/HomeQuickViewImageSigin';
import IdolDemo1 from 'images/img-slice-n1.png';
import IdolDemo2 from 'images/img-slice-n2.png';
import IdolDemo3 from 'images/img-slice-n3.png';
import PopupSuccess from './PopupSuccess';
import { isEmptyObject, patternEmail, patternFullWidthJp, patternNoFirstSpacing } from 'utils';

const ForgotPassword = () => {
  const { loading } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigation = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: 'all' });
  const watchEmail = watch('email', '');
  const isLoading = useSelector((state) => state.users.loading);
  const success = useSelector((state) => state.users.success);

  const error = useSelector((state) => state);
  useRedirect(error);

  const disableSubmit = (errors) => {
    return !watchEmail || !isEmptyObject(errors);
  }

  const onSubmit = (data) => {
    try {
      dispatch(forgotPassword(data));
    } catch (err) {
      console.log(err);
    }
  };

  const initialImages = [
    {
      url: IdolDemo1,
    },
    {
      url: IdolDemo2,
    },
    {
      url: IdolDemo3,
    },
  ];

  return (
    <>
      {loading && <PopupLoading className={'popup-loading'} />}
      <div className='block-div-signin' style={{ backgroundColor: 'transparent' }}>
        <HeaderListIdolV2 />
        <div className='container-div'>
          <div className='container-left' style={{ marginBottom: '38px', marginTop: '66px' }}>
            <HomeQuickViewImageSigin className='mt-24' initialImages={initialImages} />
          </div>
          <div className='container-login'>
            <div className='login-form forgot-password'>
              <form className='form' onSubmit={(e) => e.preventDefault()}>
                <div className='w-100 d-center title'>{t('ForgotPassword.title')}</div>
                {isLoading && <PopupLoading className={'popup-loading'} />}
                <InputWrapper
                  id='fsite-email'
                  label={t('Common.email')}
                  required={true}
                  error={errors?.email ? errors?.email?.message : ''}>
                  <input
                    type='text'
                    placeholder={t('ValidateMsg.emailRequired')}
                    {...register('email', {
                      required: ('ValidateMsg.emailRequired'),
                      validate: {
                        noFirstSpacing: (value) => {
                          return (
                            (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                            ('ValidateMsg.emailRequired')
                          );
                        },
                        fullWidth: (value) => {
                          return (
                            (value && !patternFullWidthJp.test(value.toString().trim())) ||
                            ('ValidateMsg.TEXT_HALF_WIDTH')
                          );
                        },
                        email: (value) => {
                          return (
                            (value && patternEmail.test(value.toString().trim())) ||
                            ('ValidateMsg.EMAIL_FORMAT_INVALID')
                          );
                        },
                      },
                    })}
                    className='form-control block-div-signin__active'
                    maxLength={255}
                  />
                </InputWrapper>
                <button
                  className={`btn btn-forgot-pass ${disableSubmit(errors) ? `disabled` : `active`}`}
                  type='submit'
                  disabled={disableSubmit(errors)}
                  onClick={handleSubmit(onSubmit)}>
                  {t('ForgotPassword.buttonSendEmail')}
                </button>
                {success && (
                  <PopupSuccess
                    closeModal={() => {
                      navigation('/sign-in');
                    }}
                    title={t('ForgotPassword.sendEmailSuccess')}
                    isWarning={false}
                  />
                )}
              </form>
            </div>
          </div>
        </div>
        <FooterListIdolV2 />
      </div>
    </>
  );
};

export default ForgotPassword;
