import { PlusOutlined } from '@ant-design/icons';
import { LayoutCreatorRight, PopupConfirmFan } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { newPathUser } from 'constants/layout/constant';
import { useGetIdFromRecommendUser } from 'hooks/v2/useGetIdFromRecommendUser';
import LoadingIconV2 from 'images/LoadingIconV2';
import NoCardsIcon from 'images/NoCardsIcon';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import {
  changeDefaultCard,
  checkCanUpdate,
  deleteCard,
  getCardsPayment,
  setDataPaymentCard,
  setIdScrollBackCard,
} from 'store/actions/card';
import { CardItem } from './cardItem';
import './index.scss';

const CardManagementPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { idFromRecommendUser, clearIdScrollBackRecommend } = useGetIdFromRecommendUser();
  const { paymentCards, pagination, idScrollBackCard } = useSelector((state) => state.card);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSpin, setIsLoadingSpin] = useState(false);
  const [page, setPage] = useState(
    pagination?.current_page ? +pagination?.current_page + 1 : 1
  );
  const LIMIT_PAGE = 20;

  const [isShowPopupCanNotUpdate, setShowPopupCanNotUpdate] = useState({
    isShow: false,
    message: '',
    typeCard: null,
  });
  const [isShowPopupDeleteCard, setIsShowPopupDeleteCard] = useState({
    isShow: false,
    id: null,
    typeCard: null,
  });
  const [isShowPopupDeleteSuccess, setIsShowPopupDeleteSuccess] = useState(false);

  const [cardsAfterDelete, setCardsAfterDelete] = useState([]);

  const isHaveDataCard = useMemo(() => {
    return !!paymentCards?.length;
  }, [paymentCards]);

  const fetchNext = () => {
    const pages = pagination?.total / pagination?.per_page;
    if (pages > page) {
      setIsLoading(true);
      if (idScrollBackCard || idFromRecommendUser) {
        dispatch(setIdScrollBackCard());
        clearIdScrollBackRecommend();
      } else {
        setPage(page + 1);
      }
    } else if (pages + 1 > page) {
      if (idScrollBackCard || idFromRecommendUser) {
        setIsLoading(true);
        dispatch(setIdScrollBackCard());
        clearIdScrollBackRecommend();
      }
    }
  };

  const getCardsSuccess = (rs) => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (idScrollBackCard) {
      const idPost = document.getElementById(idScrollBackCard);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idScrollBackCard]);

  useEffect(() => {
    if (!idScrollBackCard && !idFromRecommendUser) {
      setIsLoading(true);
      dispatch(
        getCardsPayment(
          {
            page,
            limit: LIMIT_PAGE,
          },
          getCardsSuccess
        )
      );
    }
  }, [dispatch, page, idScrollBackCard, idFromRecommendUser]);

  const renderButtonAdd = () => {
    return (
      <div className='button-add-card'>
        <button
          onClick={() => {
            dispatch(
              setIdScrollBackCard(`card-create`, () => navigate(`${newPathUser}create/card`))
            );
          }}
          className='btn btn-outline-danger  d-flex align-items-center'>
          <PlusOutlined />
          <span className='ms-2'>{t('Creator.cardManagement.createNew')}</span>
        </button>
      </div>
    );
  };

  const renderNoData = () => {
    return (
      !isLoading && (
        <div className='no-data'>
          <div>
            <NoCardsIcon />
          </div>
          <div className='text'>{t('Creator.cardManagement.noData')}</div>
          {renderButtonAdd()}
        </div>
      )
    );
  };

  const updateCardDelete = () => {
    dispatch(
      setDataPaymentCard(cardsAfterDelete, () => {
        setCardsAfterDelete([]);
        setIsShowPopupDeleteSuccess(false);
      })
    );
  };

  const handleDeleteSuccess = (id) => {
    setIsLoadingSpin(false);
    if (id) {
      setIsShowPopupDeleteSuccess(true);
      let itemIsDefault = false;
      let newCards = paymentCards?.filter((e) => {
        if (e?.id === id) {
          if (+e?.default === 1) {
            itemIsDefault = true;
          }
          return null;
        } else {
          return e;
        }
      });
      if (itemIsDefault) {
        newCards = newCards?.map((e, index) => {
          if (index === 0) {
            return {
              ...e,
              default: 1,
            };
          }
          return e;
        });
      }
      setCardsAfterDelete(newCards);
    }
  };

  const handleDeleteCard = () => {
    if (isShowPopupDeleteCard?.id) {
      setIsLoadingSpin(true);
      dispatch(deleteCard(isShowPopupDeleteCard?.id, handleDeleteSuccess));
    }
    setIsShowPopupDeleteCard(null);
  };

  const handleChangeDefaultCardSuccess = (id) => {
    if (id) {
      const newCards = [];
      paymentCards.forEach((item) => {
        if (item?.id === id) {
          newCards.push({
            ...item,
            default: 1,
          });
        } else {
          newCards.push({
            ...item,
            default: 0,
          });
        }
      });
      dispatch(setDataPaymentCard(newCards));
    }
    setIsLoadingSpin(false);
  };

  const handleChangeDefaultCard = (id) => {
    if (id) {
      setIsLoadingSpin(true);
      dispatch(changeDefaultCard(id, handleChangeDefaultCardSuccess));
    }
  };

  const handleCheckCanUpdateSuccess = (rs) => {
    if (rs?.id) {
      dispatch(
        setIdScrollBackCard(`card-${rs?.id}`, () =>
          navigate(`${newPathUser}card/${rs?.id}`, {
            state: {
              ...rs,
            },
          })
        )
      );
    } else {
      setShowPopupCanNotUpdate({
        isShow: true,
        message: rs?.messageError,
        typeCard: rs?.typeCard,
      });
    }
  };

  const handleCheckCanUpdate = (id, typeCard) => {
    if (id) {
      dispatch(checkCanUpdate(id, typeCard, handleCheckCanUpdateSuccess));
    }
  };

  return (
    <>
      <LayoutCreatorRight
        noButtonBack
        titlePage={`${t('Common.cardManagement')}`}
        className='card-management-layout'>
        {isShowPopupCanNotUpdate?.isShow && (
          <PopupConfirmFan
            colorSvg='#FF9F43'
            iconNote
            text={t(`${isShowPopupCanNotUpdate?.message}`, {
              typeCard: isShowPopupCanNotUpdate?.typeCard,
            })}>
            <div
              className='ok btn'
              onClick={() => {
                setShowPopupCanNotUpdate({
                  isShow: false,
                  message: '',
                  typeCard: null,
                });
              }}>
              {t('Common.Ok')}
            </div>
          </PopupConfirmFan>
        )}
        {isShowPopupDeleteCard?.isShow && (
          <PopupConfirmFan
            colorSvg='#FF9F43'
            iconNote
            text={t('Popup.confirmDeleteCard', {
              typeCard: isShowPopupDeleteCard?.typeCard,
            })}>
            <div
              className='ok btn'
              onClick={() => {
                handleDeleteCard();
              }}>
              {t('Common.Ok')}
            </div>
            <div className={`btn cancel`} onClick={() => setIsShowPopupDeleteCard(null)}>
              {t('Common.buttonNo')}
            </div>
          </PopupConfirmFan>
        )}
        {isShowPopupDeleteSuccess && (
          <PopupConfirmFan text={t('Popup.confirmDeleteCardSuccess')}>
            <div className='ok btn' onClick={updateCardDelete}>
              {t('Common.Ok')}
            </div>
          </PopupConfirmFan>
        )}
        {isLoadingSpin && (
          <div className='layer-spinner'>
            <LoadingIconV2 className='spinner-icon' />
          </div>
        )}
        <div className='card-management-container'>
          {isLoading && !isHaveDataCard ? (
            <div className='shimmer-loading'>
              <ShimmerPostDetails hasImage imageType='circular' title />
            </div>
          ) : (
            <>
              {isHaveDataCard ? (
                <>
                  {renderButtonAdd()}
                  <InfiniteLoad
                    loading={isLoading}
                    data={paymentCards || []}
                    fetchNext={fetchNext}>
                    <div className='list-card'>
                      {paymentCards?.map((item) => (
                        <CardItem
                          key={item?.id}
                          item={item}
                          handleCheckCanUpdate={handleCheckCanUpdate}
                          handleChangeDefaultCard={handleChangeDefaultCard}
                          setIsShowPopupDeleteCard={setIsShowPopupDeleteCard}
                        />
                      ))}
                    </div>
                  </InfiniteLoad>
                </>
              ) : (
                renderNoData()
              )}
            </>
          )}
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default CardManagementPage;
