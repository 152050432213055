/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const useRedirect = (error, url) => {
  let navigate = useNavigate();
  useEffect(() => {
    switch (error?.message) {
      case 'auth.token_does_not_exist':
        return navigate(!url ? '/' : url);
      default:
        return false;
    }
  }, [error]);
};
export default useRedirect;
