import { newPathUser } from 'constants/layout/constant';
import AvatarDemo from 'images/Avatar.png';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  blockOrUnblockInProfileFan,
  followOrUnFollowUser,
  getDetailFan,
  resetActionGetProfileAnotherFan,
} from 'store/actions/users';
import { linkS3 } from 'utils/utils';
import { ImgCustom, PopupGeneral, PopupLoading, ReadMoreLess } from '..';
import useUserRole from '../../hooks/useUserRole';
import './index.scss';

const PopupFanDetail = ({ classNameCustom = '', closeModal, id, isVisible, userId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isIdol } = useUserRole();
  const {
    data: dataProfile,
    anotherFanDetail,
    loading,
    dataUser,
  } = useSelector((state) => state.users);
  const { t } = useTranslation();

  useEffect(() => {
    if (dataUser?.id !== id) {
      isVisible && id && dispatch(getDetailFan(id));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, id]);

  const handleBlockUser = () => {
    dispatch(blockOrUnblockInProfileFan(userId));
  };

  const handleFollowUser = () => {
    dispatch(followOrUnFollowUser(userId));
  };

  return (
    <>
      {loading && <PopupLoading className={'popup-loading'} />}
      {anotherFanDetail && (
        <>
          <PopupGeneral
            className={`popup-fan-detail ${classNameCustom}`}
            generalClass='popup-fan-detail-view'
            closeModal={() => {
              closeModal(false);
              dispatch(resetActionGetProfileAnotherFan());
            }}
            title=''>
            <div className='account'>
              <div className={`content-left`}>
                <ImgCustom
                  src={anotherFanDetail?.avatar ? `${anotherFanDetail?.avatar}` : AvatarDemo}
                  alt='avatar'
                  screen='popup_fan_detail_70_70'
                />
                <div>
                  <span className='name'>
                    {anotherFanDetail?.account_name ||
                      anotherFanDetail?.full_name ||
                      'account no name'}
                  </span>
                </div>
              </div>
            </div>
            <div className='description display-linebreak'>
              <ReadMoreLess
                charLimit={50}
                content={anotherFanDetail?.description ? anotherFanDetail?.description : ''}
              />
            </div>
            <div className='btn-wrapper'>
              <div
                className={`button btn-follow ${
                  anotherFanDetail.is_followed === 1 && 'active'
                }`}
                onClick={handleFollowUser}>
                {anotherFanDetail.is_followed === 1
                  ? `${t('Common.unfollow')}`
                  : `${t('Common.follow')}`}
              </div>
              <div
                className={`button btn-block  ${
                  anotherFanDetail.is_blocked === 1 && 'active'
                }`}
                onClick={handleBlockUser}>
                {anotherFanDetail.is_blocked === 1
                  ? `${t('Popup.unBlock')}`
                  : `${t('Popup.block')}`}
              </div>
              <div
                className={`button btn-open-mess`}
                onClick={() => navigate(`${newPathUser}message/${anotherFanDetail?.id}`)}>
                {t('Popup.messageCreation')}
              </div>
            </div>
          </PopupGeneral>
        </>
      )}
    </>
  );
};

export default PopupFanDetail;
