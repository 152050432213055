export default function Rank3(props) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_3083_207008)'>
        <path
          d='M10.9674 17.9004L7.36988 16.7012L6.31529 16.6953L4.47062 21.9952C4.41183 22.164 4.54862 22.3367 4.72641 22.318L7.31218 22.0473C7.38441 22.0397 7.45623 22.0647 7.50816 22.1155L9.36699 23.9332C9.49477 24.0582 9.70918 24.0078 9.76791 23.839L11.6247 18.5044L10.9674 17.9004Z'
          fill='#0083FD'
        />
        <path
          d='M11.6248 18.501L12 17.4231L6.68949 15.6172L6.31543 16.6919C7.8234 17.7679 9.64927 18.4266 11.6248 18.501Z'
          fill='#0072FC'
        />
        <path
          d='M13.0325 17.9004L16.6299 16.7012L17.6845 16.6953L19.5293 21.9953C19.588 22.1641 19.4513 22.3367 19.2735 22.3181L16.6877 22.0473C16.6155 22.0398 16.5437 22.0647 16.4918 22.1155L14.6329 23.9333C14.5052 24.0582 14.2907 24.0079 14.232 23.8391L12.3752 18.5045L13.0325 17.9004Z'
          fill='#0083FD'
        />
        <path
          d='M12.3752 18.501L12 17.4231L17.3105 15.6172L17.6845 16.6919C16.1766 17.7679 14.3507 18.4266 12.3752 18.501Z'
          fill='#0072FC'
        />
        <path
          d='M11.9999 16.6924C3.29897 16.6924 3.29175 8.41819 3.29897 8.27273C3.52767 3.66534 7.33575 0 11.9999 0C16.6641 0 20.4721 3.66534 20.7008 8.27273C20.7081 8.41819 20.7008 16.6924 11.9999 16.6924Z'
          fill='#FF9838'
        />
        <path
          d='M11.9999 16.5461C7.33574 16.5461 3.5278 12.8808 3.29905 8.27344C3.29184 8.41889 3.28809 8.56528 3.28809 8.71252C3.28809 13.5239 7.18851 17.4243 11.9999 17.4243C16.8113 17.4243 20.7117 13.5239 20.7117 8.71252C20.7117 8.56528 20.708 8.41889 20.7007 8.27344C20.472 12.8808 16.6641 16.5461 11.9999 16.5461Z'
          fill='#FF8709'
        />
        <path
          d='M18.8628 8.71463C18.8628 11.6534 15.7901 15.1558 11.9998 15.1558C8.20942 15.1558 5.13672 11.4607 5.13672 8.71463C5.13672 8.56711 5.14136 8.42072 5.15055 8.2755C5.37695 4.68975 8.35694 1.85156 11.9998 1.85156C15.6426 1.85156 18.6226 4.68975 18.849 8.2755C18.8582 8.42072 18.8628 8.56711 18.8628 8.71463Z'
          fill='#CE7430'
        />
        <path
          d='M11.9998 14.6974C8.35698 14.6974 5.37756 11.8591 5.15111 8.27344C5.14192 8.41866 5.13672 8.56495 5.13672 8.71252C5.13672 12.5029 8.20942 15.5756 11.9998 15.5756C15.7901 15.5756 18.8628 12.5029 18.8628 8.71252C18.8628 8.56495 18.8576 8.41866 18.8485 8.27344C18.622 11.8591 15.6426 14.6974 11.9998 14.6974Z'
          fill='#BC672E'
        />
        <path
          d='M12.0085 12.786C10.5304 12.786 9.58987 11.767 9.40883 10.5581C9.39238 10.4482 9.38718 10.2122 9.38718 10.2122C9.51304 9.89148 9.82532 9.66442 10.1906 9.66442C10.6671 9.66442 11.0534 10.0507 11.0534 10.5272C11.0534 11.0538 11.4818 11.4823 12.0085 11.4823C12.5351 11.4823 12.9636 11.0538 12.9636 10.5272C12.9636 10.3689 12.9249 10.2194 12.8564 10.0878C12.8564 10.0878 12.7916 9.27423 12.0547 9.27423C11.3155 9.27423 11.266 8.2698 11.266 8.2698C11.4163 8.01644 11.6926 7.84661 12.0085 7.84661C12.5352 7.84661 12.9636 7.41817 12.9636 6.89153C12.9636 6.36489 12.5352 5.93645 12.0085 5.93645C11.4819 5.93645 11.0534 6.36489 11.0534 6.89153C11.0534 7.36806 10.6671 7.75431 10.1907 7.75431C9.71418 7.75431 9.32788 7.36806 9.32788 6.89153C9.32788 5.41347 10.5304 4.21094 12.0085 4.21094C13.4866 4.21094 14.6891 5.41347 14.6891 6.89153C14.6891 7.59241 14.4188 8.23122 13.9769 8.70939C14.3246 9.08561 14.5661 9.5613 14.6533 10.0884C14.6533 10.0884 14.6559 10.3777 14.6308 10.525C14.417 11.7823 13.4866 12.786 12.0085 12.786Z'
          fill='#FFAA5C'
        />
        <path
          d='M12.0085 8.69709C11.6925 8.69709 11.4171 8.52675 11.2668 8.27344C11.1904 8.40216 11.1458 8.55197 11.1458 8.71252C11.1458 9.18905 11.532 9.5753 12.0085 9.5753C12.3768 9.5753 12.6965 9.7852 12.8558 10.0913C12.9243 9.95972 12.9637 9.81061 12.9637 9.65227C12.9636 9.12553 12.5351 8.69709 12.0085 8.69709Z'
          fill='#EA9957'
        />
        <path
          d='M12.0085 12.3349C10.7237 12.3349 9.64757 11.4261 9.38797 10.2177C9.34968 10.3153 9.32788 10.4213 9.32788 10.5325C9.32788 12.0106 10.5304 13.2131 12.0085 13.2131C13.4866 13.2131 14.6892 12.0106 14.6892 10.5325C14.6892 10.383 14.6762 10.2366 14.6526 10.0938C14.4422 11.3634 13.337 12.3349 12.0085 12.3349Z'
          fill='#EA9957'
        />
      </g>
      <defs>
        <clipPath id='clip0_3083_207008'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
