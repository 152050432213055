import moment from 'moment';
import {
  validCard3,
  validCard4,
  validJCBCard,
  validMasterCard,
  validVisaCard,
} from 'utils/regex';
import * as yup from 'yup';

export const schema = (t) => {
  return yup
    .object({
      nameCard: yup
        .string()
        .trim()
        .required(t('ValidateMsg.CARD_HOLDER_NAME_REQUIRED'))
        .max(30, t('ValidateMsg.CARD_HOLDER_NAME_MAX_30'))
        .test('is-valid-card-name', t('ValidateMsg.CARD_HOLDER_NAME_INVALID'), (nameCard) => {
          if (!nameCard) {
            return false;
          }
          const reg = /^[\p{L}\p{M}\s]+$/u;
          const regRomaji = /^[A-Za-z\s]+$/u;
          return reg.test(nameCard) && regRomaji.test(nameCard);
        }),
      cardNumber: yup
        .string()
        .trim()
        .required(t('ValidateMsg.CARD_NUMBER_REQUIRED'))
        .test('check-type', t('ValidateMsg.CARD_NUMBER_INVALID'), (cardNumber) => {
          if (cardNumber) {
            const nameCleaned = cardNumber.replace(/\s/g, '');
            return (
              validMasterCard.test(nameCleaned) ||
              validVisaCard.test(nameCleaned) ||
              validJCBCard.test(nameCleaned)
            );
          }
          return false;
        }),
      dateExpiry: yup
        .string()
        .required(t('ValidateMsg.DATE_EXPIRY_REQUIRED'))
        .test(
          'is-valid-more-than-current-now',
          t('ValidateMsg.CARD_CVV_MORE_THAN_CURRENT_NOW'),
          function (dateExpiry) {
            if (!dateExpiry || dateExpiry?.length !== 6) {
              return this.createError({
                message: t('ValidateMsg.END_DATE_MUST_BE_AFTER_TODAY'),
              });
            } else {
              const month = dateExpiry?.slice(0, 2);
              const year = dateExpiry?.slice(2, 6);
              const checkMonth = moment(month, 'MM');
              if (!checkMonth.isValid()) {
                return this.createError({
                  message: t('ValidateMsg.END_DATE_MUST_BE_AFTER_TODAY'),
                });
              }
              const checkYear = moment(year, 'YYYY');
              if (!checkYear.isValid()) {
                return this.createError({
                  message: t('ValidateMsg.END_DATE_MUST_BE_AFTER_TODAY'),
                });
              }
              const monthYearCard = moment(moment(`${year}-${month}`, 'YYYY-MM'));
              const timeNow = moment(moment().format('YYYY-MM'), 'YYYY-MM');
              const diff = monthYearCard.diff(timeNow);
              if (diff < 0) {
                return this.createError({
                  message: t('ValidateMsg.CARD_CVV_MORE_THAN_CURRENT_NOW'),
                });
              }
              return true;
            }
          }
        ),
      securityCode: yup
        .string()
        .required(t('ValidateMsg.CARD_CVV_REQUIRED'))
        .when('cardNumber', (cardNumber, yup) =>
          yup.test('check-type', t('ValidateMsg.CARD_CVV_INVALID'), (securityCode) => {
            if (cardNumber && securityCode) {
              const nameCleaned = cardNumber.replace(/\s/g, '');
              if (validMasterCard.test(nameCleaned) || validJCBCard.test(nameCleaned)) {
                return validCard3.test(securityCode);
              } else if (validVisaCard.test(nameCleaned)) {
                return validCard4.test(securityCode);
              }
              return false;
            }
            return false;
          })
        ),
      surName: yup
        .string()
        .trim()
        .required(t('ValidateMsg.LAST_NAME_REQUIRED'))
        .max(20, t('ValidateMsg.LAST_NAME_MAX_20'))
        .test('is-valid-last-name', t('ValidateMsg.LAST_NAME_INVALID'), (name) => {
          if (!name) {
            return false;
          }
          const reg = /^[\p{L}\p{M}\s]+$/u;
          return reg.test(name);
        }),
      givenName: yup
        .string()
        .trim()
        .required(t('ValidateMsg.FIRST_NAME_REQUIRED'))
        .max(20, t('ValidateMsg.FIRST_NAME_MAX_20'))
        .test('is-valid-first-name', t('ValidateMsg.FIRST_NAME_INVALID'), (name) => {
          if (!name) {
            return false;
          }
          const reg = /^[\p{L}\p{M}\s]+$/u;
          return reg.test(name);
        }),
      phone: yup
        .string()
        .trim()
        .required(t('ValidateMsg.PHONE_IS_REQUIRED'))
        .test(
          'is-valid-format-phone',
          t('ValidateMsg.C002_VALIDATE_PHONE_FORMAT'),
          function (phone) {
            if (phone) {
              const cleaned = ('' + phone).replace(/\D/g, '');
              const match10 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
              const match11 = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
              const match12 = cleaned.match(/^(\d{4})(\d{4})(\d{4})$/);
              if (
                (!match10 && !match11 && !match12) ||
                (match12 && match12[1] !== '0120') ||
                cleaned.substring(0, 1) !== '0'
              ) {
                return false;
              } else {
                return true;
              }
            } else {
              return true;
            }
          }
        ),
    })
    .required();
};
