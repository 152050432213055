import { LayoutCreatorRight, PopupLoading } from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import PopupConfirm from 'components/Popup/confirmSuccess';
import { newPathUser } from 'constants/layout/constant';
import withCreatorRole from 'hocs/withCreatorRole';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import { ArrowLeftIcon } from 'images';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { customStyles, selectTimeRegister, CustomOption, customStylesReselect, CustomDropdownIndicator } from 'utils';
import { creatorPostPremiumTicket } from 'store/actions/premiumTicket';
import './index.scss';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { clearIdScrollBackPackageSubAndData } from 'store/actions/lives';

const PremiumTicketCreate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const [focusTimeRegister, setFocusTimeRegister] = useState(false);
  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const { t } = useTranslation();
  useLockBodyScroll();
  const { loading } = useSelector((state) => state.premiumTicket);
  const {
    register,
    handleSubmit,
    watch,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: 'all' });
  const watchTimeRegister = watch('condition_month', '');
  // const watchExpired = watch('expired_month', '');

  const isDisabledButton = !watchTimeRegister;

  const handleShowConfirm = ({ success }) => {
    if (success) {
      dispatch(clearIdScrollBackPackageSubAndData());
      setConfirmSuccess(true);
    }
  };

  const handleSuccess = () => {
    setConfirmSuccess(false);
    navigate(`${newPathUser}profile/subscription`);
  };

  //onSubmit
  const onSubmit = (data) => {
    const newData = {
      ...data,
      package_id: location?.state?.packageId,
      condition_month: data.condition_month.value,
    };

    dispatch(creatorPostPremiumTicket(newData, handleShowConfirm));
  };

  return (
    <>
      {loading && <PopupLoading className={'popup-loading'} />}
      {confirmSuccess && (
        <PopupConfirm title={t('Popup.createTicketSuccess')} closeModal={handleSuccess} />
      )}
      <LayoutCreatorRight
        titlePage={t('Creator.Home.premiumTicketCreation')}
        link={`${newPathUser}profile/subscription`}
        className='premium-ticket-post-page'
        >
        {loading ? (
          <div className='shimmer-loading'>
            <ShimmerPostDetails hasImage imageType='circular' title />
          </div>
        ) : (
          <>
            <div className='fansite-creator-premium-ticket-post'>
              <form onSubmit={(e) => e.preventDefault()} className='fansite-form'>
                <InputWrapper
                  id='fsite-condition'
                  className='fsite-condition'
                  label={t('Creator.Home.selectTargetSubscription')}>
                  <input
                    placeholder={t('Creator.Home.enterExpirationDate')}
                    disabled
                    value={location?.state?.packageName}
                  />
                </InputWrapper>

                <InputWrapper
                  id='fsite-timeRegister'
                  label={t('Creator.Home.subscriptionPeriodRequiredForAcquisition')}
                  error={errors?.condition_month ? errors?.condition_month?.message : ''}>
                  <div className='timeRegister'>
                    <Controller
                      name='condition_month'
                      control={control}
                      rules={{
                        required: (
                          'Creator.Home.pleaseSpecifyTheSubscriptionPeriodRequiredForAcquisition'
                        ),
                      }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={selectTimeRegister}
                          onFocus={() => setFocusTimeRegister(true)}
                          onBlur={(val) => {
                            setFocusTimeRegister(false);
                            if (!field?.value?.value) {
                              setError('condition_month', {
                                type: 'required',
                                message: (
                                  'Creator.Home.pleaseSpecifyTheSubscriptionPeriodRequiredForAcquisition'
                                ),
                              });
                            }
                          }}
                          onClick={() => setFocusTimeRegister(false)}
                          onChange={(val) => {
                            field.onChange(val);
                            setFocusTimeRegister(false);
                            if (val?.value) {
                              clearErrors(['condition_month']);
                            }
                          }}
                          className='select-subscription'
                          placeholder={t('Creator.Home.enterSubscriptionPeriod')}
                          styles={{
                            ...customStylesReselect,
                            menu: (base, state) => ({
                              ...base,
                              border: 'none !important',
                              borderRadius: '8px',
                              overflow: 'hidden',
                              zIndex: 2,
                              width: '100%',
                              boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.06)',
                            }),
                          }}
                          components={{
                            Option: CustomOption,
                            DropdownIndicator: CustomDropdownIndicator,
                          }}
                        />
                      )}
                    />
                  </div>
                </InputWrapper>
                <div className='fansite-creator-premium-ticket-post__footer'>
                  <InputWrapper
                    id='fsite-submit'
                    className={`button button-submit
                ${isDisabledButton ? 'disabled' : ''}
              `}>
                    <button onClick={handleSubmit(onSubmit)} disabled={isDisabledButton}>
                      {t('Common.buttonSave')}
                    </button>
                  </InputWrapper>
                  <InputWrapper id='fsite-submit' className={`button button-cancel`}>
                    <button onClick={() => navigate(`${newPathUser}profile/subscription`)}>
                      {t('Common.buttonCancel')}
                    </button>
                  </InputWrapper>
                </div>
              </form>
            </div>
          </>
        )}
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(PremiumTicketCreate);
