import Icon from '@ant-design/icons';

const NoSubscribedListIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={100}
        height={100}
        viewBox='0 0 100 100'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_3965_343030)'>
          <path
            d='M7.8125 7.8125H24.6875V24.6875H7.8125V7.8125Z'
            stroke='#FCECEF'
            strokeWidth={8}
            strokeMiterlimit={10}
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M41.5625 7.8125H92.1875V24.6875H41.5625V7.8125Z'
            stroke='#FCECEF'
            strokeWidth={8}
            strokeMiterlimit={10}
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M7.8125 75.3125H24.6875V92.1875H7.8125V75.3125Z'
            stroke='#FCECEF'
            strokeWidth={8}
            strokeMiterlimit={10}
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M41.5625 75.3125H92.1875V92.1875H41.5625V75.3125Z'
            stroke='#FCECEF'
            strokeWidth={8}
            strokeMiterlimit={10}
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M7.8125 41.5457H24.6875V58.4207H7.8125V41.5457Z'
            stroke='#FCECEF'
            strokeWidth={8}
            strokeMiterlimit={10}
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M41.5625 41.5457H92.1875V58.4207H41.5625V41.5457Z'
            stroke='#FCECEF'
            strokeWidth={8}
            strokeMiterlimit={10}
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
        <defs>
          <clipPath id='clip0_3965_343030'>
            <rect width={94} height={94} fill='white' transform='translate(3 3)' />
          </clipPath>
        </defs>
      </svg>
    )}
    {...props}
  />
);

export default NoSubscribedListIcon;
