/* eslint-disable import/no-anonymous-default-export */

import { TYPES } from '../actions';
const initialState = {
  status: null,
  loading: false,
  error: null,
  listFavoriteCreator: [],
  listRankingContent: [],
  listNewCreator: [],
  listPickupCreator: [],
  listRankingCreator: [],
  listBanner: [],
  listCreatorLivestream: [],
  listSuggestCreator: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_LIST_RANKING_NEW_CREATOR_REQUEST:
    case TYPES.GET_LIST_RANKING_CONTENT_REQUEST:
    case TYPES.GET_LIST_RANKING_FAVORITE_CREATOR_REQUEST:
    case TYPES.GET_LIST_PICK_UP_CREATOR_REQUEST:
    case TYPES.GET_LIST_RANKING_CREATOR_REQUEST:
    case TYPES.GET_LIST_BANNER_REQUEST:
    case TYPES.GET_LIST_CREATOR_LIVESTREAM_REQUEST:
    case TYPES.GET_SUGGEST_CREATOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_LIST_RANKING_NEW_CREATOR_FAILED:
    case TYPES.GET_LIST_RANKING_CONTENT_FAILED:
    case TYPES.GET_LIST_RANKING_FAVORITE_CREATOR_FAILED:
    case TYPES.GET_LIST_PICK_UP_CREATOR_FAILED:
    case TYPES.GET_LIST_RANKING_CREATOR_FAILED:
    case TYPES.GET_LIST_CREATOR_LIVESTREAM_FAILED:
    case TYPES.GET_LIST_BANNER_FAILED:
    case TYPES.GET_SUGGEST_CREATOR_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };
    case TYPES.GET_LIST_RANKING_FAVORITE_CREATOR_SUCCESS:
      return {
        ...state,
        listFavoriteCreator: action.data.res,
        loading: false,
      };
    case TYPES.GET_LIST_RANKING_NEW_CREATOR_SUCCESS:
      return {
        ...state,
        listNewCreator: action.data.res,
        loading: false,
      };
    case TYPES.GET_LIST_RANKING_CREATOR_SUCCESS:
      return {
        ...state,
        listRankingCreator: action.data.res,
        loading: false,
      };
    case TYPES.GET_LIST_RANKING_CONTENT_SUCCESS:
      return {
        ...state,
        listRankingContent: action.data.res,
        loading: false,
      };
    case TYPES.GET_LIST_PICK_UP_CREATOR_SUCCESS:
      return {
        ...state,
        listPickupCreator: action.data.res,
        loading: false,
      };
    case TYPES.GET_LIST_BANNER_SUCCESS:
      return {
        ...state,
        listBanner: action.data.res,
        loading: false,
      };
    case TYPES.GET_LIST_CREATOR_LIVESTREAM_SUCCESS:
      return {
        ...state,
        listCreatorLivestream: action.data.res,
        loading: false,
      };
    case TYPES.GET_SUGGEST_CREATOR_SUCCESS:
      return {
        ...state,
        listSuggestCreator: action.data.res,
        loading: false,
      };
    case 'RESET_ACTION_LIST_IDOL':
      return {
        ...state,
        listSuggestCreator: [],
      };
    default:
      return state;
  }
};
