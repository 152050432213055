import { useEffect } from 'react';
import { setOverflowTablet } from 'utils';

export function useFixedPopup(targetValue) {
  useEffect(() => {
    if (targetValue) {
      document.getElementById('root').style.overflow = 'hidden';
      //document.getElementById('root').style.position = 'fixed';
      document.getElementById('root').style.width = '100%';
    } else {
      // document.getElementById('root').style.overflow = 'auto';
      setOverflowTablet();
      document.getElementById('root').style.position = 'revert';
    }
  }, [targetValue]);
}
