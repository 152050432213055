import { configParamsGet, ENV, updateStatus } from 'utils/constant';
import { callApi } from 'utils/request';
import { queryParamsTimezone, queryString } from 'utils/utils';
import { TYPES } from './index';

const API = {
  getListBannerAffiliator: (queries) =>
    `${ENV}/fan/affiliator/banners?${queryString(queries)}`,
  getListBannerDetailAffiliator: (banner_id) => `${ENV}/fan/affiliator/banners/${banner_id}`,
  fanAffiliatorWithBanner: (queries) =>
    `${ENV}/fan/affiliator/click-banner?${queryString(queries)}`,
  getListRevenueSalesHistory: (queries) =>
    `${ENV}/fan/affiliate/revenue/sale-history?${
      queryString(queries) + queryParamsTimezone()
    }`,
  getListRevenueSalesHistoryCSV: (queries) =>
    `${ENV}/fan/affiliate/revenue/sale-history?${
      queryString(queries) + queryParamsTimezone()
    }`,
  getListRevenueSumary: () => `${ENV}/fan/affiliate/revenue/summary`,
  getListRevenuePayementHistory: (queries) =>
    `${ENV}/fan/affiliate/revenue/payment-history?${queryString(queries)}`,
  getListRevenuePayementHistoryMonth: (queries) =>
    `${ENV}/fan/affiliate/revenue/payment_history?${queryString(queries)}`,
  getLimitPaymentAffilicate: () => `${ENV}/setting/limit-payment`,
};

const getListBannerAffiliator = (queries) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_BANNER_AFFILIATOR_REQUEST));
    callApi(
      API.getListBannerAffiliator(queries),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_BANNER_AFFILIATOR_SUCCESS, {
            data: response,
          })
        );
      },
      async (error) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_BANNER_AFFILIATOR_FAILED, { error: error.errors })
        );
      }
    );
  };
};

const getListBannerDetailAffiliator = ({ banner_id }, handleSuccess, handleFailed) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_BANNER_DETAIL_AFFILIATOR_REQUEST));
    callApi(
      API.getListBannerDetailAffiliator(banner_id),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_BANNER_DETAIL_AFFILIATOR_SUCCESS, {
            data: response,
          })
        );
        handleSuccess && handleSuccess();
      },
      async (error) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_BANNER_DETAIL_AFFILIATOR_FAILED, { error: error.errors })
        );
        handleFailed && handleFailed(error.errors);
      }
    );
  };
};

const fanAffiliatorWithBanner = (queries) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.FAN_AFFILIATOR_WITH_BANNER_REQUEST));
    callApi(
      API.fanAffiliatorWithBanner(queries),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FAN_AFFILIATOR_WITH_BANNER_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.FAN_AFFILIATOR_WITH_BANNER_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

// START AF004

const getListRevenueSalesHistory = (queries, handleSuccess) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_REVENUE_SALES_HISTORY_REQUEST));
    callApi(
      API.getListRevenueSalesHistory(queries),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_REVENUE_SALES_HISTORY_SUCCESS, {
            data: response,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_REVENUE_SALES_HISTORY_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getListRevenueSalesHistoryCSV = (queries, handleSuccess) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_REVENUE_SALES_HISTORY_CSV_REQUEST));
    callApi(
      API.getListRevenueSalesHistoryCSV(queries),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_REVENUE_SALES_HISTORY_CSV_SUCCESS, {
            data: response,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_REVENUE_SALES_HISTORY_CSV_FAILED, {
            error: error.errors,
          })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getListRevenueSumary = (handleSuccess) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_REVENUE_SUMARY_REQUEST));
    callApi(
      API.getListRevenueSumary(),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_REVENUE_SUMARY_SUCCESS, {
            data: response,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_REVENUE_SUMARY_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getListRevenuePayementHistory = (queries, handleSuccess) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_REVENUE_PAYMENT_HISTORY_REQUEST));
    callApi(
      API.getListRevenuePayementHistory(queries),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_REVENUE_PAYMENT_HISTORY_SUCCESS, {
            data: response,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_REVENUE_PAYMENT_HISTORY_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getListRevenuePayementHistoryMonth = (queries, handleSuccess) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_REVENUE_PAYMENT_HISTORY_MONTH_REQUEST));
    callApi(
      API.getListRevenuePayementHistoryMonth(queries),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIST_REVENUE_PAYMENT_HISTORY_MONTH_SUCCESS, {
            data: response,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIST_REVENUE_PAYMENT_HISTORY_MONTH_FAILED, {
            error: error.errors,
          })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getLimitPaymentAffilicate = (handleSuccess) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIMIT_PAYMENT_AFFILICATE_REQUEST));
    callApi(
      API.getLimitPaymentAffilicate(),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_LIMIT_PAYMENT_AFFILICATE_SUCCESS, {
            data: response,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.GET_LIMIT_PAYMENT_AFFILICATE_FAILED, {
            error: error.errors,
          })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

// END AF004

export {
  getListBannerAffiliator,
  getListBannerDetailAffiliator,
  fanAffiliatorWithBanner,
  getListRevenuePayementHistory,
  getListRevenuePayementHistoryMonth,
  getListRevenueSalesHistory,
  getListRevenueSalesHistoryCSV,
  getListRevenueSumary,
  getLimitPaymentAffilicate,
};
