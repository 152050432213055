import useLockBodyScroll from 'hooks/useLockBodyScroll';
import useUserRole from 'hooks/useUserRole';
import HeaderListIdolV2  from 'components/HeaderV2/HeaderListIdolV2';
import { useTranslation } from 'react-i18next';

const PopupSignUpConfirmTwitter = ({ children }) => {
  const { t } = useTranslation();
  useLockBodyScroll();
  const { isIdol } = useUserRole();
  return (
    <>
      <HeaderListIdolV2/>
      <div className='popup register-success-fan' style={{ backgroundColor: "transparent", zIndex: "1"}}>
        <div className='popup-content'>
          <div className='content confirm-twitter'>
            <div className='content-header'></div>
            <div className='content-body'>
              {t('Popup.loginTwitterEmailExist')}
            </div>
            <div className={`box-button ${isIdol === true ? 'w-100' : ''}`}>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupSignUpConfirmTwitter;
