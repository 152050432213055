import { PlusOutlined } from '@ant-design/icons';
import { ImgCustom, LayoutCreatorRight } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { newPathUser } from 'constants/layout/constant';
import withCreatorRole from 'hocs/withCreatorRole';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import EmptyIcon from 'images/empty.png';
import { getListPackage, setIdScrollBackPackageSub } from 'store/actions/lives';
import './index.scss';
import PackageItem from './packageItem/packageItem';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { sortListPackagePointAndDate } from 'utils';
import { useMemo } from 'react';

const SubcriptionList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { listPackageSub, pagination, idScrollBackPackageSub } = useSelector(
    (state) => state.lives
  );
  const { dataUser } = useSelector((state) => state.users);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const listPackage = useMemo(() => {
    if (listPackageSub && !!listPackageSub?.length) {
      return sortListPackagePointAndDate(listPackageSub);
    }
    return [];
  }, [listPackageSub]);

  useLayoutEffect(() => {
    if (!idScrollBackPackageSub) {
      setIsLoading(true);
      dispatch(
        getListPackage(() => {
          setIsLoading(false);
        })
      );
    }
  }, [dispatch, page, idScrollBackPackageSub]);

  useEffect(() => {
    if (idScrollBackPackageSub) {
      const idPost = document.getElementById(idScrollBackPackageSub);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    } else {
      const top = document.getElementById('app_cocofans_0');
      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
    }
  }, [idScrollBackPackageSub]);

  const fetchNext = () => {
    const pages = pagination?.total / pagination?.per_page;
    if (pages > page) {
      setPage(page + 1);
    }
  };

  return (
    <>
      <LayoutCreatorRight
        linkBack={`${newPathUser}${dataUser?.account_id}`}
        className='subcription-list-header'
        titlePage={t('Creator.Home.subscriptionList')}>
        {isLoading ? (
          <div className='shimmer-loading'>
            <ShimmerPostDetails hasImage imageType='circular' title />
          </div>
        ) : (
          <>
            <div className='fansite-creator-subcription-list'>
              {!listPackage.length && (
                <div className='no-data'>
                  <div className='no-data_icon'>
                    <ImgCustom
                      src={EmptyIcon}
                      alt='empty'
                      style={{ backgroundColor: 'transparent' }}
                    />
                  </div>
                  <div className='empty-text'>{t('Creator.Home.noSubscriptionList')}</div>
                </div>
              )}
              <div id="item-sub-create" className='d-flex justify-content-center'>
                <button
                  onClick={() =>
                    dispatch(
                      setIdScrollBackPackageSub('item-sub-create', () => {
                        navigate(`${newPathUser}profile/subscription/create`);
                      })
                    )
                  }
                  className='btn btn-outline-danger btn-outline-red subscription-btn-add'>
                  <span className='d-flex me-3'>
                    <PlusOutlined />
                  </span>
                  {t('Creator.Home.newSubscription')}
                </button>
              </div>

              {!!listPackage.length && (
                <InfiniteLoad
                  loading={isLoading}
                  data={listPackage}
                  fetchNext={() => fetchNext()}
                  className='list-package'>
                  {listPackage.map((item) => (
                    <PackageItem key={item?.id} item={item} />
                  ))}
                </InfiniteLoad>
              )}
            </div>
          </>
        )}
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(SubcriptionList);
