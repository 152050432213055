import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import { LOGO_COCO_FANS } from '../../utils/constant';
import { BtnMess, ContentMess, ContentTitle, LogoCoco, Message, Wrapper } from './styled';
import PageNotFound from 'pages/not-found';
import { ImgCustom } from '..';

function ShowMessageLeave() {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const checkLeave = searchParams.get('leave');

  return checkLeave === 'yes' ? (
    <Wrapper className='message-leave'>
      <Message>
        <ContentTitle className='jtf-center'>{t('Common.withdrawal_completed')}</ContentTitle>
        <ContentMess>
          <span className='select-mess'>
            {t('Common.the_withdrawal_process_has_been_completed')}
          </span>
          <span className='select-mess'>
            {t('Common.we_look_forward_to_seeing_you_again')}
          </span>
        </ContentMess>
        <BtnMess>
          <Link className='link-to-top' to='/'>{t('Common.to_the_top_of_the_site')}</Link>
        </BtnMess>
      </Message>
      <LogoCoco>
        <ImgCustom src={LOGO_COCO_FANS} className='img-cocofan' alt='logo-cocofan' />
      </LogoCoco>
    </Wrapper>
  ) : (
    <PageNotFound />
  );
}

export default ShowMessageLeave;
