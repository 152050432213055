import styled, { css } from 'styled-components';

export const ListHighLight = {
  Wrapper: styled.div`
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    gap: 30px;

    &::-webkit-scrollbar {
      display: none;
    }
    @media screen and (max-width: 575px) {
      gap: 15px;
    }
  `,
  Item: styled.div`
    img {
      width: 78px;
      height: 78px;
      border: 2px solid #ee3c81;
      padding: 2px;
      border-radius: 50%;
      margin-bottom: 0;
      z-index: 0;
      object-fit: cover;
      ${({ isFan }) =>
        isFan
          ? css`
              width: 90px;
              height: 90px;
            `
          : css`
              width: 78px;
              height: 78px;
            `};
      @media screen and (max-width: 575px) {
        width: 74px;
        height: 74px;
      }
    }
  `,
  BlueImg: styled.div`
    background: url(${({ url }) => "'" + url + "'"});
    height: ${({ height }) => height};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
    .bg-img {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 9;
      background-size: cover;
      min-height: 100%;
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(20px);
      &.bdt {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        @media screen and (max-width: 991px) {
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
        }
      }
    }
  `,
};
