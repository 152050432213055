import { ImgCustom, ReadMoreLess } from 'components';
import { newPathUser } from 'constants/layout/constant';
import {
  FlagIcon,
  ThreePointIcon,
  HeartActiveIcon,
  HeartIcon,
  TrashIcon,
  FlowUnFlowIcon,
  ChatIcon,
  ThreePointIconV3,
  UnFlowIcon,
  Edit3Icon,
  Edit2Icon,
} from 'images';
import Avatar from 'images/Avatar.png';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { convertToCurrentTime, decryptPath, formatNumber } from 'utils/utils';
import { postFollowCommentDetail } from 'store/actions/usersNew';
import './index.scss';
import { AVATAR_DEFAULT, STORAGE } from 'utils';
import FormEditComment from 'components/comment/formEditComment';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useClickOutSize } from 'hooks/v2/clickOutSide';
import Storage from 'utils/storage';
import { logUserProfile } from 'store/actions/myPage';
import MarkDownRead from 'components/MarkDownRead';

const ListTopFirstComment = ({
  handleDeleteComment,
  handleLikeComment,
  dataProfile,
  dataPost,
  handleFollowUserComment,
  handleNavigate,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { statusPostRepComment, statusEditComment } = useSelector((state) => state.usersNew);
  const { dataUser } = useSelector((state) => state.users);
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const [showFormReplyComment, setFormReplyComment] = useState(-1);
  const [showFormEditComment, setFormEditComment] = useState(null);
  const [isVisiblePopup, setIsVisiblePopup] = useState(-1);
  const dispatch = useDispatch();

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setFormEditComment(null);
      setFormReplyComment(null);
    }
  }, []);

  useEffect(() => {
    if (statusPostRepComment) {
      setFormReplyComment(null);
      const textareaId = document.getElementById('textarea-post-rep-comment');
      if (textareaId) textareaId.style.height = '44px';
    }

    if (statusEditComment) {
      setFormEditComment(null);
      const textareaId = document.getElementById('textarea-edit-comment');
      if (textareaId) textareaId.style.height = '44px';
    }

    document.addEventListener('keydown', escFunction);
    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction, statusPostRepComment, statusEditComment]);

  const checkDisabledAction = (item) => {
    if (
      (item?.is_followed === 0 && item?.follow_flag === 1 && item?.user_id !== dataUser?.id) ||
      (!item?.is_media_display && item?.type === 1 && item?.user_id !== dataUser?.id)
    ) {
      return true;
    }
    return false;
  };

  const [refOutSideThree] = useClickOutSize(() => {
    setIsVisiblePopup(-1);
  });

  return (
    <>
      {dataPost?.latest_comment && (
        <div className='list_comment block'>
          <div className='list-comment'>
            <div className='comment-item-wrapper'>
              <div className='comment-item'>
                <div className='avatar'>
                  {!dataPost?.latest_comment?.avatar ||
                  decryptPath(dataPost?.latest_comment?.avatar) === 'NULL' ? (
                    <img
                      src={AVATAR_DEFAULT}
                      alt='avt'
                      onClick={() => {
                        navigate(`${newPathUser}${dataPost?.latest_comment?.account_id}`);
                      }}
                    />
                  ) : (
                    <ImgCustom
                      isPlaceholderAvatar
                      src={
                        !!dataPost?.latest_comment?.avatar &&
                        decryptPath(dataPost?.latest_comment?.avatar) !== 'NULL'
                          ? `${dataPost?.latest_comment?.avatar}`
                          : AVATAR_DEFAULT
                      }
                      alt='avatar comment'
                      screen='avatar_44_44'
                      onClick={() => {
                        navigate(`${newPathUser}${dataPost?.latest_comment?.account_id}`);
                      }}
                    />
                  )}
                </div>
                <div className='content' style={{ justifyContent: 'space-between' }}>
                  {showFormEditComment?.id === dataPost?.latest_comment.id ? (
                    <>
                      <FormEditComment
                        item_comment={dataPost?.latest_comment}
                        handleCancelEdit={() => {
                          setFormEditComment(null);
                          setFormReplyComment(null);
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <div className='info-comment w-100'>
                        <div className='left'>
                          <div
                            className='name custom_name list-top-first-comment'
                            onClick={() => {
                              navigate(
                                `${newPathUser}${dataPost?.latest_comment?.account_id}`
                              );
                            }}>
                            <span className='account-name'>
                              {dataPost?.latest_comment?.account_name ||
                                dataPost?.latest_comment?.full_name}
                            </span>
                            <span className='time ms-2'>
                              {convertToCurrentTime(dataPost?.latest_comment?.created_at)}
                            </span>
                            {dataPost?.latest_comment?.is_edited === 1 && (
                              <div style={{ display: 'inline-flex' }}>
                                <Edit2Icon className='icon-edited' />
                              </div>
                            )}
                          </div>
                          <div className='d-flex w-100 justify-content-between align-items-end'>
                            <div className='comment-content display-linebreak mw-550'>
                              <MarkDownRead
                                isMeta
                                content={dataPost?.latest_comment.content?.toString()}
                              />
                            </div>
                          </div>
                          <div
                            className={`d-middle mt-2`}
                            style={{
                              cursor: `${
                                dataPost?.is_followed === 0 &&
                                dataPost?.follow_flag === 1 &&
                                'default'
                              }`,
                              gap: '25px',
                              color: '#787880',
                            }}>
                            <div
                              className={`d-middle action-icons ${
                                checkDisabledAction(dataPost) && 'disabled'
                              }`}>
                              {dataPost?.latest_comment?.is_liked === 1 ? (
                                <>
                                  {/* đã like */}
                                  <HeartActiveIcon
                                    disabled={checkDisabledAction(dataPost)}
                                    className={`like-icon active`}
                                    onClick={() => {
                                      if (!isAuth) {
                                        dispatch(logUserProfile());
                                      } else {
                                        !checkDisabledAction(dataPost) &&
                                          handleLikeComment(dataPost?.latest_comment?.id);
                                      }
                                    }}
                                  />
                                </>
                              ) : (
                                <>
                                  {/* chưa like */}
                                  <HeartIcon
                                    disabled={checkDisabledAction(dataPost)}
                                    className={`like-icon`}
                                    onClick={() => {
                                      if (!isAuth) {
                                        dispatch(logUserProfile());
                                      } else {
                                        !checkDisabledAction(dataPost) &&
                                          handleLikeComment(dataPost?.latest_comment?.id);
                                      }
                                    }}
                                  />
                                </>
                              )}
                              <span className='number'>
                                {formatNumber(dataPost?.latest_comment?.number_like)}
                              </span>
                            </div>
                            <div
                              className={`d-middle ${
                                (checkDisabledAction(dataPost) ||
                                  dataPost?.enable_comment === 0) &&
                                'disabled'
                              }`}>
                              <label
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  cursor: 'pointer',
                                }}
                                htmlFor={`comment-item-rep-${dataPost?.id}`}
                                onClick={() => {
                                  if (!isAuth) {
                                    dispatch(logUserProfile());
                                  } else {
                                    handleNavigate(dataPost?.type, dataPost?.id);
                                  }
                                }}>
                                <ChatIcon
                                  //style={{ cursor: "pointer", color: "#787880" }}
                                  className='chat-icon'
                                />
                                <span className='number' style={{ marginLeft: '5px' }}>
                                  {formatNumber(dataPost?.latest_comment?.number_comment) || 0}
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='box-popup' style={{ padding: '0' }}>
                          <div className='d-flex justify-content-end'>
                            {checkDisabledAction(dataPost) ? (
                              <ThreePointIconV3 />
                            ) : (
                              <span
                                className='btn-three-point'
                                ref={refOutSideThree}
                                onClick={() => {
                                  setIsVisiblePopup(
                                    isVisiblePopup === dataPost?.id ? -1 : dataPost?.id
                                  );
                                }}>
                                <ThreePointIcon
                                  className='three-point-icon'
                                  onBlur={() => setIsVisiblePopup(-1)}
                                />
                              </span>
                            )}
                          </div>
                          {isVisiblePopup === dataPost?.id &&
                            !checkDisabledAction(dataPost) && (
                              <div className='popup-edit-comment'>
                                {dataPost?.latest_comment?.user_id === dataProfile?.id && (
                                  <div
                                    className='item item1'
                                    onMouseDown={() => {
                                      setFormEditComment(dataPost.latest_comment);
                                      setIsVisiblePopup(-1);
                                    }}>
                                    <Edit3Icon color='#282727' />
                                    <span className='text'>
                                      {t('comment.listComment.iconEdit')}
                                    </span>
                                  </div>
                                )}
                                {dataPost?.latest_comment?.user_id !== dataProfile?.id && (
                                  <div
                                    className='item'
                                    onMouseDown={() => {
                                      if (!isAuth) {
                                        setIsVisiblePopup(-1);
                                        dispatch(logUserProfile());
                                      } else {
                                        handleFollowUserComment(
                                          dataPost.latest_comment.user_id
                                        );
                                        setIsVisiblePopup(-1);
                                      }
                                    }}>
                                    {dataPost?.latest_comment?.is_followed === 0 ? (
                                      <FlowUnFlowIcon />
                                    ) : (
                                      <UnFlowIcon />
                                    )}

                                    <span className='text'>
                                      {dataPost?.latest_comment?.is_followed === 0
                                        ? t('comment.listComment.flow')
                                        : t('comment.listComment.unflow')}
                                    </span>
                                  </div>
                                )}
                                {dataPost?.latest_comment?.user_id === dataProfile?.id &&
                                  !checkDisabledAction(dataPost) && (
                                    <div
                                      className='item'
                                      onMouseDown={() => {
                                        if (!isAuth) {
                                          setIsVisiblePopup(-1);
                                          dispatch(logUserProfile());
                                        } else {
                                          setIsVisiblePopup(-1);
                                          handleDeleteComment(dataPost?.latest_comment.id);
                                        }
                                      }}>
                                      <TrashIcon />
                                      <span className='text'>{t('Common.buttonDelete')}</span>
                                    </div>
                                  )}
                                {dataPost?.latest_comment?.user_id !== dataProfile?.id &&
                                  !checkDisabledAction(dataPost) && (
                                    <div
                                      className='item'
                                      onMouseDown={() => {
                                        if (!isAuth) {
                                          setIsVisiblePopup(-1);
                                          dispatch(logUserProfile());
                                        } else {
                                          setIsVisiblePopup(-1);
                                          navigate(`${newPathUser}report`, {
                                            state: {
                                              fan_id: dataPost?.latest_comment?.user_id,
                                              fan_name:
                                                dataPost?.latest_comment?.account_name ||
                                                dataPost?.latest_comment?.full_name,
                                              post_id: dataPost?.id,
                                              account_id: dataPost?.latest_comment?.account_id,
                                            },
                                          });
                                        }
                                      }}>
                                      <FlagIcon />
                                      <span className='text'>
                                        {t('comment.listComment.iconFlag')}
                                      </span>
                                    </div>
                                  )}
                              </div>
                            )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ListTopFirstComment;
