import './index.scss';
const TableNoBorderRadius = ({ children, pagination, ...props }) => {
  return (
    <>
      <div className='table-wrapper'>
        <table className={`table-affiliator table-no-border-radius ${props.className}`}>
          {children}
        </table>
      </div>
      {pagination()}
    </>
  );
};

export default TableNoBorderRadius;
