/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';

const initialState = {
  status: null,
  loading: false,
  error: null,
  chartRevenues: [],
  chartUserReactions: [],
  chartUserSubscriptions: [],
  chartSales: [],
  chartLivestreams: [],
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_CHART_REVENUES_REQUEST:
    case TYPES.GET_USER_REACTIONS_REQUEST:
    case TYPES.GET_USER_SUBSCRIPTIONS_REQUEST:
    case TYPES.GET_CHART_SALES_REQUEST:
    case TYPES.GET_CHART_LIVESTREAMS_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
      };
    case TYPES.GET_CHART_REVENUES_SUCCESS:
      return {
        ...state,
        loading: false,
        chartRevenues: action?.data?.data,
      };
    case TYPES.GET_USER_REACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        chartUserReactions: action?.data?.data,
      };
    case TYPES.GET_USER_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        chartUserSubscriptions: action?.data?.data,
      };
    case TYPES.GET_CHART_SALES_SUCCESS:
      return {
        ...state,
        loading: false,
        chartSales: action?.data?.data,
      };
    case TYPES.GET_CHART_LIVESTREAMS_SUCCESS:
      return {
        ...state,
        loading: false,
        chartLivestreams: action?.data?.data,
      };

    case TYPES.GET_CHART_REVENUES_FAILED:
    case TYPES.GET_USER_REACTIONS_FAILED:
    case TYPES.GET_USER_SUBSCRIPTIONS_FAILED:
    case TYPES.GET_CHART_SALES_FAILED:
    case TYPES.GET_CHART_LIVESTREAMS_FAILED:
      return {
        ...state,
        loading: false,
        status: action.type,
      };

    case 'RESET_ACTION_CHART_REPORT':
      return {
        ...state,
        loading: false,
        status: null,
        chartRevenues: [],
        chartUserReactions: [],
        chartUserSubscriptions: [],
        chartSales: [],
        chartLivestreams: [],
      };

    default:
      return state;
  }
};
