/* eslint-disable react-hooks/exhaustive-deps */
import { LayoutCreatorRight } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getListLives,
  resetActionGetListLive,
  setIdScrollLiveDelivery,
} from 'store/actions/lives';
import Items from './components/Items';
import { newPathUser } from 'constants/layout/constant';
import './List.scss';
import NoStreamsIcon from 'images/NoLiveStreamIcon';
import NoStreamsIcon1 from 'images/NoLiveStreamIcon1';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import LocalStorage from 'utils/storage';
import { useGetIdFromRecommendUser } from 'hooks/v2/useGetIdFromRecommendUser';
import { resetDataInfoOther, resetIDHomePage } from 'store/actions/usersNew';
moment.locale('en');

const LIST_TAB_LIVESTREAM = [
  { id: 1, label: 'MenuSiderbar.duringDelivery', tab_navigate_list_live: 1 },
  { id: 2, label: 'MenuSiderbar.deliverySchedule', tab_navigate_list_live: 2 },
];

const List = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.lives.dataListLives);
  const isLoading = useSelector((state) => state.lives.loadingLives);
  const pagination = useSelector((state) => state.lives.paginationDelivery);
  const [isScrollToTop, setIsScrollToTop] = useState(true);
  const { idFromRecommendUser, clearIdScrollBackRecommend } = useGetIdFromRecommendUser();
  const idLiveDeliveryScrollBack = useSelector(
    (state) => state.lives.idLiveDeliveryScrollBack
  );
  const [page, setPage] = useState(
    pagination?.current_page ? +pagination?.current_page + 1 : 1
  );

  const [isFromLive, setIsFromLive] = useState(LocalStorage.get('from-live'));
  const { t } = useTranslation();

  useEffect(() => {
    if (idLiveDeliveryScrollBack) {
      const idScroll = document.getElementById(idLiveDeliveryScrollBack);
      if (idScroll) {
        idScroll.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idLiveDeliveryScrollBack]);

  useEffect(() => {
    if (!idLiveDeliveryScrollBack && isScrollToTop) {
      const top = document.getElementById('app_cocofans_0');
      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
    }
  }, [idLiveDeliveryScrollBack, isScrollToTop, idFromRecommendUser]);

  const fetchNext = () => {
    const pages = pagination?.total / pagination?.per_page;
    if (pages > page) {
      if (idLiveDeliveryScrollBack || idFromRecommendUser) {
        setIsScrollToTop(false);
        dispatch(setIdScrollLiveDelivery());
        clearIdScrollBackRecommend();
      } else {
        setPage(page + 1);
      }
    } else if (pages + 1 > page) {
      if (idLiveDeliveryScrollBack || idFromRecommendUser) {
        setIsScrollToTop(false);
        dispatch(setIdScrollLiveDelivery());
        clearIdScrollBackRecommend();
      }
    }
  };

  useEffect(() => {
    if (LocalStorage.get('from-live')) {
      setIsFromLive(LocalStorage.get('from-live'));
    }
  }, [LocalStorage.get('from-live')]);

  useEffect(() => {
    if (!idLiveDeliveryScrollBack && !idFromRecommendUser) {
      dispatch(
        getListLives(
          state?.tab_navigate_list_live === 1 || !state?.tab_navigate_list_live
            ? 'delivery'
            : 'schedule',
          page,
          10
        )
      );
    }
  }, [page, state, idLiveDeliveryScrollBack, idFromRecommendUser]);

  const handleCb = () => {
    if (+isFromLive === 1) {
      LocalStorage.remove('from-live');
      dispatch(resetIDHomePage());
      dispatch(resetDataInfoOther());
      navigate(`${newPathUser}`);
    } else {
      navigate(-1);
    }
  };

  return (
    <LayoutCreatorRight
      noButtonBack
      cb={handleCb}
      className='list-live-another-header'
      titlePage={
        state?.tab_navigate_list_live === 1 || !state?.tab_navigate_list_live
          ? t('MenuSiderbar.duringDelivery')
          : t('MenuSiderbar.deliverySchedule')
      }>
      <div className='list-lives-page'>
        <div className='tab-container'>
          {LIST_TAB_LIVESTREAM.map((v, i) => (
            <div
              key={i}
              className={`item ${
                (state?.tab_navigate_list_live || 1) === i + 1 ? ` active ` : ``
              }`}
              onClick={() => {
                if (!isLoading) {
                  dispatch(
                    resetActionGetListLive(() => {
                      clearIdScrollBackRecommend();
                      setIsScrollToTop(true);
                      setPage(1);
                      navigate(`${newPathUser}lives/list/live`, {
                        state: { tab_navigate_list_live: v.tab_navigate_list_live },
                      });
                    })
                  );
                }
              }}>
              {t(`${v.label}`)}
            </div>
          ))}
        </div>
        {isLoading && page === 1 ? (
          <div className='shimmer-bg'>
            <ShimmerPostDetails hasImage imageType='circular' title />
          </div>
        ) : (
          <InfiniteLoad loading={isLoading} data={data} fetchNext={fetchNext}>
            <div
              className={`list-live-stream`}
              style={{ minHeight: data.length ? 'unset' : 'calc(100vh - 288px)' }}>
              {data.length ? (
                <>
                  {state?.tab_navigate_list_live === 1 || !state?.tab_navigate_list_live ? (
                    <>
                      <Items
                        key={data[0].id}
                        item={data[0]}
                        isDataFirst
                        isItemLive
                        liveKey={data[0]?.live_key || ''}
                      />
                      {data?.slice(1)?.map((item) => (
                        <Items
                          isItemLive
                          key={item.id}
                          item={item}
                          liveKey={item?.live_key || ''}
                        />
                      ))}
                    </>
                  ) : (
                    <>
                      {data?.map((item) => (
                        <Items key={item.id} item={item} liveKey={item?.live_key || ''} />
                      ))}
                    </>
                  )}
                </>
              ) : (
                <div className='no-data'>
                  <div className='no-data__icon'>
                    {state?.tab_navigate_list_live === 1 || !state?.tab_navigate_list_live ? (
                      <NoStreamsIcon />
                    ) : (
                      <div style={{ marginBottom: '12px' }}>
                        <NoStreamsIcon1 />
                      </div>
                    )}
                  </div>
                  <div className='no-data__text'>{t('Common.listNull')}</div>
                </div>
              )}
            </div>
          </InfiniteLoad>
        )}
      </div>
    </LayoutCreatorRight>
  );
};

export default List;
