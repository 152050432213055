import useUserRole from 'hooks/useUserRole';
import AboutCompany from 'pages/about-company';
import CreatorLayout from 'pages/creator/layout';
import ExplanationForAffilliator from 'pages/explanation-for-affilliator';
import LawPage from 'pages/legal/law';
import PrivacyPage from 'pages/legal/privacy';
import TermPage from 'pages/legal/term';
import ListIdol from 'pages/list-idol';
import { LiveStream } from 'pages/lives/liveStream';
import { Demo } from 'pages/lives/obs/Demo';
import { TestShare } from 'pages/lives/obs/TestShare';
import { TestVideo } from 'pages/lives/obs/TestVideo';
import VideoMobile from 'pages/lives/obs/VideoMobile';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ADMIN_FAN_ROUTES, FAN_ROUTES, NO_LOGIN_ROUTES } from 'routes';
import PublicRoutes from 'routes/PublicRoutes';
import { STORAGE } from 'utils/constant';
import Storage from 'utils/storage';
import './App.css';
import useCheckMaintain from './hooks/useCheckMaintain';
//F0
import { PopupGeneral, PopupLoading } from 'components';
import ShowMessageLeave from 'components/showMessageLeave';
import useApp from 'hooks/useApp';
import ComplianceNotice from 'pages/legal/compliance-notice';
import { useEffect, useState } from 'react';
import VerifyAge from './pages/check-age';
import CreatorConfirmChangeEmail from './pages/creator/confirm-change-email';
import Home from './pages/Home';
import PageNotFound from './pages/not-found';
import SignInTwitter from './pages/sign-in-twitter';
import MaintainRoutes from './routes/MaintainRoutes';
import ProtectedRoutes from './routes/ProtectedRoutes';
import SpecialRoutes from './routes/SpecialRoutes';
import { logoutAllTabs } from './store/actions/users';
import { isAdmin } from './utils';
import { loginAllTabs } from './hooks/useCheckLoginAdminOrAgency';
import V2Layout from 'pages/v2/layout';
import { newPathUser } from 'constants/layout/constant';
import { isMobile, isTablet } from 'react-device-detect';
import PopupConfirm from 'components/Popup/confirmSuccess';
import { useTranslation } from 'react-i18next';
import USC2257Screen from 'pages/legal/usc2257';
import { useSelector } from 'react-redux';
import { useDetectChangeOnoriention } from 'hooks/v2/useDetectChangeOnorientaion';
import useSetVarHeight from 'hooks/useSetVarHeight';

function App() {
  const { isIdol } = useUserRole();
  const { t } = useTranslation();
  const { maintain } = useCheckMaintain();
  const { loadingNavigateLogin } = useApp();
  const isSSOLogin = window.sessionStorage.getItem('check_sso');

  useDetectChangeOnoriention();
  useSetVarHeight();
  useEffect(() => {
    logoutAllTabs();
    loginAllTabs();
  }, []);
  const { isShortMypage } = useSelector((state) => state.usersNew);
  const checkURLShort = window.location.pathname;
  
  return (
    <>
      <div id='app_cocofans_0' />
      <div className='App'>
        {isMobile && (
          <div className='rotate-popup'>
            <PopupConfirm
              isNoButton
              isWarning={true}
              title={t('Popup.rotateWarning')}
              className='popup-warning-rotate'></PopupConfirm>
          </div>
        )}
        {isSSOLogin && <PopupLoading className={'popup-loading'} />}
        {loadingNavigateLogin && (
          <PopupLoading className={'popup-loading loading-reload-page'} />
        )}
        {maintain ? (
          <MaintainRoutes />
        ) : (
          <Routes>
            <Route path='/terms-of-use' element={<TermPage />} />
            <Route path='/privacy-policy' element={<PrivacyPage />} />
            <Route path='/usc2257' element={<USC2257Screen />} />
            <Route path='/transactions-law' element={<LawPage />} />
            <Route path='/compliance-notice' element={<ComplianceNotice />} />
            <Route path='test-share' element={<TestShare />} />
            <Route path='test-video/:id' element={<TestVideo />} />
            <Route path='video-mobile/:id' element={<VideoMobile />} />
            <Route path='/demo-obs/:id' element={<Demo />} />
            <Route path='/about-company' element={<AboutCompany />} />
            <Route
              path='/explanation-for-affiliator'
              element={<ExplanationForAffilliator />}
            />
            {/* <Route
              path='/'
              element={
                JSON.parse(Storage.get(STORAGE.VERIFY_AGE)) ? <SignIn /> : <VerifyAge />
              }
            /> */}
            <Route path='/oauth/twitter' element={<SignInTwitter />} />
            <Route path='/message/leave' element={<ShowMessageLeave />} />
            <Route path='/not-found' element={<PageNotFound />} />
            {/* <Route path='*' element={<Navigate to='/not-found' />} /> */}
            <Route
              element={
                JSON.parse(Storage.get(STORAGE.VERIFY_AGE)) ? <PublicRoutes /> : <VerifyAge />
              }>
              {NO_LOGIN_ROUTES.map((route) => (
                <Route key={route.path} {...route} />
              ))}
            </Route>
            <Route
              element={
                JSON.parse(Storage.get(STORAGE.VERIFY_AGE)) ? <SpecialRoutes /> : <VerifyAge />
              }>
              {!isAdmin && <Route path='/lives/:liveKey' element={<LiveStream />} />}
            </Route>
            <Route
              element={
                JSON.parse(Storage.get(STORAGE.VERIFY_AGE)) ? (
                  <ProtectedRoutes />
                ) : (
                  <VerifyAge />
                )
              }>
              {/* <Route path='/' element={<Home />} /> */}
              <Route path='/confirm-email/:token' element={<CreatorConfirmChangeEmail />} />
              {!isIdol ? (
                isAdmin ? (
                  ADMIN_FAN_ROUTES.map((route) => <Route key={route.path} {...route} />)
                ) : (
                  FAN_ROUTES.map((route) => <Route key={route.path} {...route} />)
                )
              ) : (
                <Route path={`${newPathUser}/*`} element={<V2Layout />} />
              )}
            </Route>
          </Routes>
        )}
      </div>
    </>
  );
}

export default App;
