import Icon from '@ant-design/icons';

const CopyrightIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        fill='none'
        viewBox='0 0 24 24'>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M12 22C6.48 22 2 17.52 2 12S6.48 2 12 2s10 4.48 10 10-4.48 10-10 10z'></path>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M14.88 15a4 4 0 01-2.64 1c-2.21 0-4-1.79-4-4s1.79-4 4-4a4 4 0 012.64 1'></path>
      </svg>
    )}
    {...props}
  />
);

export default CopyrightIcon;
