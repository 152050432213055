import { CircleCheckIcon, DangerCircleIcon } from 'images';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import DangerCircleIconFill from 'images/DangerCircleIconFill';
import DangerIconV2 from 'images/DangerIconV2';
const PopupConfirmFan = ({
  text,
  text2,
  subTextLive,
  colorSvg = '#28C76F',
  children,
  className,
  iconNote = false,
  hiddenIcon = false,
  textColor,
  lockScroll = true,
  fillColor = '',
  isDanger = false,
  ...props
}) => {
  useLockBodyScroll(lockScroll);
  return (
    <div className={`popup confirm-fan ${className}`}>
      <div className='popup-content'>
        <div className='content'>
          {!hiddenIcon && (
            <>
              {isDanger ? (
                <>
                  <span
                    style={{
                      marginTop: '-1px',
                      marginRight: '6px',
                    }}>
                    <DangerIconV2 color={colorSvg} className='check-icon danger-icon' />
                  </span>
                </>
              ) : (
                <>
                  {iconNote && fillColor ? (
                    <DangerCircleIconFill
                      style={{ color: colorSvg }}
                      className='check-icon danger-icon'
                    />
                  ) : iconNote && !fillColor ? (
                    <DangerCircleIcon color={colorSvg} className='check-icon danger-icon' />
                  ) : (
                    <CircleCheckIcon style={{ color: colorSvg }} className='check-icon' />
                  )}
                </>
              )}
            </>
          )}
          <div className='d-flex flex-column' style={{ marginLeft: hiddenIcon ? 0 : 18.75 }}>
            <span className='text' style={textColor !== undefined ? { color: textColor } : {}}>
              {text}
            </span>
            {subTextLive !== undefined ? (
              <span className='text' style={{ marginBottom: '5px' }}>
                {' '}
                {subTextLive}
              </span>
            ) : (
              text2 && (
                <span className='text2' style={{ whiteSpace: 'pre-line' }}>
                  {text2}
                </span>
              )
            )}
          </div>
        </div>
        {props.content && props.content}
        <div className='box-button'>{children}</div>
      </div>
    </div>
  );
};

export default PopupConfirmFan;
