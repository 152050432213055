import { LayoutCreatorRight, PopupLoading } from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import PopupConfirm from 'components/Popup/confirmSuccess';
import PopupConfirmDelete from 'components/PopupConfirmDelete';
import { newPathUser } from 'constants/layout/constant';
import withCreatorRole from 'hocs/withCreatorRole';
import React, { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  deleteCreatorPackage,
  getCreatorPackageDetail,
  updateCreatorPackage,
} from 'store/actions/users';
import { isEmptyObject, maxLengthInputTypeNumber, patternNoFirstSpacing } from 'utils/utils';
import './index.scss';

const SubcriptionCreate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useParams();
  const { loading, creatorPackageDetail } = useSelector((state) => state.users);
  const [showPopupDelete, setShowPopupDelete] = useState(false);
  const [valueCoin, setValueCoin] = useState();
  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const [isDeleteAction, setIsDeleteAction] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getCreatorPackageDetail(query.id, handleSetInitialData));
    window.scrollTo(0, 0);
  }, []);

  const handleSetInitialData = ({ success, data }) => {
    if (success) {
      setValueCoin(data?.price);
      setValue('title', data?.title);
      setValue('price', data?.price);
      setValue('description', data?.description);
      setValue('limited', data?.limited);
      setValue('limited_flag', data?.limited_flag);
    }
  };

  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
    setValue,
  } = useForm({ mode: 'all' });
  const watchName = watch('title', '');
  const watchPrice = watch('price', '');
  const watchLimited = watch('limited', '');
  const watchLimitedFlag = watch('limited_flag', '');

  const validateLimited = watchLimitedFlag
    ? !watchLimited || Number(watchLimited) <= 0
    : false;

  const isDisabledButton =
    !watchName ||
    watchName.length > 30 ||
    validateLimited ||
    loading ||
    !watchPrice < 0 ||
    !valueCoin ||
    !isEmptyObject(errors);

  // setHeight textarea
  const handleTextarea = (e) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    if (e.target.value === '') {
      e.target.style.height = '178px';
      e.target.style.padding = '9px  30px  9px 12px';
    }
  };

  const handleConfirmSuccess = () => {
    setConfirmSuccess(false);
    setIsDeleteAction(false);
    navigate(`${newPathUser}profile/subscription`);
  };

  //onSubmit
  const onSubmit = (data) => {
    const newData = {
      ...data,
      limited_flag: +data.limited_flag,
      limited: data.limited_flag ? data.limited : null,
      price: +watchPrice,
      id: query.id,
    };
    dispatch(
      updateCreatorPackage(newData, ({ success }) => success && setConfirmSuccess(true))
    );
  };

  const onDelete = () => {
    setIsDeleteAction(true);
    dispatch(
      deleteCreatorPackage(query.id, ({ success }) => {
        if (success) {
          setShowPopupDelete(false);
          setConfirmSuccess(true);
        }
      })
    );
  };

  return (
    <>
      {loading && <PopupLoading className={'popup-loading'} />}
      {confirmSuccess && (
        <PopupConfirm
          title={
            isDeleteAction
              ? t('Popup.deleteCreatorPackageSuccess')
              : t('Popup.updateCreatorPackageSuccess')
          }
          closeModal={handleConfirmSuccess}
        />
      )}
      <LayoutCreatorRight
        titlePage={t('Creator.Home.subscriptionEdit')}
        link={`${newPathUser}profile/subscription`}>
        {Object.keys(creatorPackageDetail).length > 0 && (
          <div className='fansite-creator-subcription-post'>
            {showPopupDelete && (
              <PopupConfirmDelete
                title={
                  creatorPackageDetail?.number_paid
                    ? t('Popup.canNotDeteleSubscription')
                    : t('Popup.confirmDeleteSubscription')
                }
                setShowPopup={setShowPopupDelete}
                handleOnSubmit={onDelete}
                loading={loading}
                isConfirm={creatorPackageDetail?.number_paid}
              />
            )}

            <form onSubmit={(e) => e.preventDefault()} className='fansite-form'>
              <InputWrapper
                id='fsite-name'
                className='fsite-name'
                label={t('Creator.Home.subscriptionName')}
                required={true}
                notification={true}
                notificationContent={t('Creator.Home.upTo30Characters')}
                error={errors?.title ? errors?.title?.message : ''}>
                <div className='input-name'>
                  <input
                    maxLength={30}
                    {...register('title', {
                      required: t('Creator.Home.pleaseEnterASubscriptionName'),
                      validate: {
                        noFirstSpacing: (value) =>
                          (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                          t('Creator.Home.pleaseEnterASubscriptionName'),
                      },
                    })}
                    placeholder={t('Creator.Home.enterPlanName')}
                  />
                </div>
                {watchName?.length > 30 && (
                  <p className='fansite-form-input__error text-danger'>
                    {t('Creator.ReviewLiveStream.enterTheSubscriptionName')}
                  </p>
                )}
              </InputWrapper>

              <InputWrapper
                className='fsite-price'
                label={t('Creator.Home.subscriptionAmount')}
                required={true}
                error={errors?.price ? errors?.price?.message : ''}
                notification={true}
                notificationContent={t('Creator.Home.youCanSetBetween0PtAnd10000Pt')}>
                <div className='input-price'>
                  <CurrencyFormat
                    {...register('price', {
                      required: t('Creator.Home.pleaseEnterTheAmount'),
                      max: {
                        value: 10000,
                        message: t(
                          'Creator.Home.pleaseSetTheAmountWithinTheRangeOf0PtTo10000Pt'
                        ),
                      },
                      min: {
                        value: 0,
                        message: t(
                          'Creator.Home.pleaseEnterASubscriptionAmountGreaterThanOrEqualTo0'
                        ),
                      },
                    })}
                    decimalSeparator={false}
                    decimalScale={0}
                    value={valueCoin}
                    maxLength={6}
                    className='currency-format'
                    placeholder=''
                    thousandSeparator
                    onValueChange={(values) => {
                      const { value, floatValue } = values;
                      setValue(
                        'price',
                        floatValue >= 0 ? floatValue : value === '-' || value === '' ? '' : -1
                      );
                      setValueCoin(value);
                    }}
                  />
                </div>
              </InputWrapper>

              <InputWrapper
                id='fsite-content'
                label={t('Creator.Home.subscriptionDescription')}
                className={`text-post`}>
                <textarea
                  {...register('description')}
                  placeholder={t('Creator.Home.upTo1024Characters')}
                  maxLength={1024}
                  onKeyDown={(e) => handleTextarea(e)}
                  onChange={(e) => handleTextarea(e)}
                />
              </InputWrapper>

              <InputWrapper
                id='fsite-answer'
                label={t('Creator.Home.setPlanCapacity')}
                className='form-item-switch'>
                <label className='switch'>
                  <input
                    type='checkbox'
                    {...register('limited_flag')}
                    onChange={(e) => {
                      setValue('limited_flag', e.target.checked);
                      if (!e.target.checked) {
                        setValue('limited', '');
                        clearErrors(['limited']);
                      }
                    }}
                  />
                  <span className='slider round'></span>
                </label>
              </InputWrapper>

              <InputWrapper
                id='fsite-member'
                className='fsite-member'
                label={t('Creator.Home.capacity')}
                notification={true}
                notificationContent={t('Creator.Home.halfWidthDigit')}
                error={errors?.limited ? errors?.limited?.message : ''}>
                <div className='input-member'>
                  <input
                    maxLength={9}
                    disabled={!watchLimitedFlag}
                    {...register('limited', {
                      valueAsNumber: true,
                    })}
                    onChange={(e) => {
                      const value = e.target.value.trim();
                      setValue('limited', value);
                      if (value !== '0' && value && value > 0) {
                        clearErrors(['limited']);
                      }
                    }}
                    onInput={(e) => maxLengthInputTypeNumber(e)}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setValue('limited', e.target.value.trim());
                      if (value === '0' || !value || value < 0) {
                        setError('limited', {
                          type: 'required',
                          message: t('Creator.Home.pleaseEnterTheNumberOfPeopleFrom0'),
                        });
                      }
                    }}
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </div>
              </InputWrapper>

              <div className='row mt-5'>
                <div className='col-12 col-sm-12 col-md-6'>
                  <InputWrapper
                    id='fsite-submit'
                    className={`button-submit ${isDisabledButton ? 'disabled' : ''}`}>
                    <button
                      type='submit'
                      onClick={handleSubmit(onSubmit)}
                      disabled={isDisabledButton}>
                      {t('Creator.Home.renewSubscription')}
                    </button>
                  </InputWrapper>
                </div>
                <div className='col-12 col-sm-12 col-md-6'>
                  <InputWrapper id='fsite-back' className=''>
                    <button
                      className='button-back btn w-100 font-weight-700 border app-btn bg-white'
                      type='button'
                      onClick={() => setShowPopupDelete(true)}>
                      {t('Creator.Home.removeSubscription')}
                    </button>
                  </InputWrapper>
                </div>
              </div>
            </form>
          </div>
        )}
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(SubcriptionCreate);
