import Icon from '@ant-design/icons';

const RePostIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M18.4917 14.5588L16.1748 16.875L13.8594 14.5588'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.625 2.375C10.2108 2.375 9.875 2.71079 9.875 3.125C9.875 3.53921 10.2108 3.875 10.625 3.875V2.375ZM10.625 3.875H12.25V2.375H10.625V3.875ZM15.5 7.125V16.25H17V7.125H15.5ZM12.25 3.875C14.0449 3.875 15.5 5.33007 15.5 7.125H17C17 4.50165 14.8734 2.375 12.25 2.375V3.875Z'
          fill='currentColor'
        />
        <path
          d='M1.50827 5.55147L3.82522 3.125L6.14062 5.55147'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.375 17.625C9.78921 17.625 10.125 17.2892 10.125 16.875C10.125 16.4608 9.78921 16.125 9.375 16.125L9.375 17.625ZM9.375 16.125L7.82353 16.125L7.82353 17.625L9.375 17.625L9.375 16.125ZM4.57353 12.875L4.57353 3.52941L3.07353 3.52941L3.07353 12.875L4.57353 12.875ZM7.82353 16.125C6.02861 16.125 4.57353 14.6699 4.57353 12.875L3.07353 12.875C3.07353 15.4984 5.20018 17.625 7.82353 17.625L7.82353 16.125Z'
          fill='currentColor'
        />
      </svg>
    )}
    {...props}
  />
);

export default RePostIcon;
