import styled from 'styled-components';

export const Styled = {
  Wrapper: styled.div`
    position: relative;
    max-width: 500px;
    display: flex;
    gap: '24px';
    height: 100%;
    @media screen and (max-width: 575px) {
      max-width: 100%;
      min-width: 100%;
    }
  `,
  WrapperBlur: styled.div`
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    z-index: 12;
    top: 0;
  `,
  ButtonPlay: styled.button`
    cursor: pointer;
    position: absolute;
    z-index: 3;
    width: 68px;
    height: 48px;
    padding: 2px;
    border: none;
    border-radius: 14px;
    background: rgba(0, 0, 0, 0.66);
    inset: 0;
    margin: auto;
    color: white;
  `,
};
