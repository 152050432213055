import { Checkbox, LayoutCreatorRight, PopupConfirmFan, PopupGeneral } from 'components';
import { newPathUser } from 'constants/layout/constant';
import CoinPackageBig from 'images/CoinPackageBig.svg';
import CoinPackageSmall from 'images/CoinPackageSmall.svg';
import SuccessRegisterIcon from 'images/SuccessRegisterIcon';
import BgTigerPay from 'images/bg_tiger_pay.jpg';
import Payment1 from 'images/payment/payment1.png';
import Payment2 from 'images/payment/payment2.png';
import Payment4 from 'images/payment/payment4.png';
import { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { getFanRequestPoint, getSettingSystem, postPaymentPonit } from 'store/actions/payment';
import { PAYMENT_CONST } from 'utils';
import Storage from 'utils/storage';
import { formatCoin } from 'utils/utils';
import '../styles.scss';
import { clearScrollIdAndDataVendo } from 'store/actions/card';
import { toast } from 'react-toastify';

const PaymentScreen = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'all', defaultValues: {} });
  const {
    loading,
    post_payment_point,
    status_fan_request_point,
    code_payment,
    setting_system,
  } = useSelector((state) => state.payment);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { point, package_payment_id, price } = location?.state || {};
  const [step, setStep] = useState(1);
  const [isVisiblePopupPayment, setIsVisiblePopupPayment] = useState(false);

  const watchNumber = watch('payment.number', null);
  const watchDate = watch('payment.date', null);
  const watchCvv = watch('payment.cvv', null);

  const creditCard = watch('credit_card');
  const tigerPay = watch('tiger_pay', false);
  const vendoPay = watch('vendo_pay', null);

  const isValidPayment = () => {
    return watchNumber && watchDate && watchCvv ? true : false;
  };

  useEffect(() => {
    dispatch(getSettingSystem(11));
  }, []);

  useEffect(() => {
    !point &&
      !price &&
      navigate(`${newPathUser}purchase`, {
        state: {
          pathname: location.state?.pathname,
        },
      });
  }, [point, package_payment_id, price, navigate]);

  useEffect(() => {
    status_fan_request_point === true && !loading && setStep(2);
  }, [status_fan_request_point, loading]);

  useEffect(() => {
    if (post_payment_point) {
      setStep(3);
    }
  }, [post_payment_point]);

  useEffect(() => {
    dispatch(clearScrollIdAndDataVendo());
  }, []);

  const onSubmit = (data) => {
    const { tiger_pay } = data;
    const dataVendo = {
      package_payment_id: package_payment_id,
      point: point,
      price: price,
      payment_method: tiger_pay ? 2 : 1,
      settingMethod: setting_system,
    };
    if (tiger_pay) {
      dispatch(
        getFanRequestPoint(
          { payment_method: 2, point: 1 },
          (code) => {
            return navigate(`${newPathUser}payment/tigerpay`, {
              state: {
                dataVendo,
                codePayment: code,
              },
            });
          },
          () => {
            var condition = navigator.onLine;
            if (!condition) {
              toast.error(t('Creator.Message.disconnect'), {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
                hideProgressBar: true,
                theme: 'colored',
                className: 'display-linebreak',
                enableMultiContainer: true,
                icon: ({ theme, type }) => (
                  <svg
                    width={18}
                    height={18}
                    viewBox='0 0 18 18'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z'
                      stroke='#DC143C'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M11.25 6.75L6.75 11.25'
                      stroke='#DC143C'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M11.25 11.25L6.75 6.75'
                      stroke='#DC143C'
                      strokeWidth='1.5'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                ),
              });
            }
          }
        )
      );
    } else {
      Storage.set(PAYMENT_CONST.DATA_VENDO, JSON.stringify(dataVendo));
      return navigate(`${newPathUser}payment/checkout`, {
        state: {
          dataVendo,
          pathname: location.state?.pathname,
        },
      });
    }
  };

  const [loadingCustom, setLoadingCustom] = useState(false);
  useEffect(() => {
    setLoadingCustom(true);
    setTimeout(() => {
      setLoadingCustom(false);
    }, 1500);
    clearTimeout(() => setLoadingCustom(false));
  }, [step]);

  return (
    <>
      {isVisiblePopupPayment && (
        <PopupConfirmFan
          isVisiblePopup={() => setIsVisiblePopupPayment(false)}
          colorSvg='#FFBF00'
          fillColor={true}
          iconNote={true}
          className='popup-payment-tiger'
          text={t('Popup.confirmPurchased')}>
          <div
            className='ok btn'
            // style={{ width: '50px', background: '#ff0000' }}
            onClick={() => {
              tigerPay
                ? package_payment_id
                  ? dispatch(
                      postPaymentPonit({
                        package_payment_id: package_payment_id,
                        payment_method: 2,
                        code: code_payment,
                      })
                    )
                  : dispatch(
                      postPaymentPonit({
                        point: point,
                        price: price,
                        payment_method: 2,
                        code: code_payment,
                      })
                    )
                : dispatch(
                    postPaymentPonit({
                      point: point,
                      price: price,
                      payment_method: 1,
                      code: code_payment,
                    })
                  );

              setIsVisiblePopupPayment(false);
            }}>
            {t('Common.Ok')}
          </div>
          <div
            className='btn cancel'
            style={{ fontWeight: 500 }}
            onClick={() => setIsVisiblePopupPayment(false)}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}
      <LayoutCreatorRight
        titlePage={`${
          step === 1 ? t('Purchase.payment.titleStep1') : t('Purchase.payment.title')
        }`}
        linkBack={`${newPathUser}purchase`}
        page={'page-F015'}
        className='purchase-page-header'
        cb={() => {
          if (point) {
            return navigate(`${newPathUser}purchase`, {
              state: {
                pathname: location.state?.pathname,
              },
            });
          }
          return navigate(-1, {
            state: { ...location.state },
          });
        }}>
        {loadingCustom ? (
          <div className='shimmer-loading'>
            <ShimmerPostDetails />
          </div>
        ) : (
          <>
            {step === 1 && (
              <div className={`payment-page step-1`}>
                <form className='--body' onSubmit={(e) => e.preventDefault()}>
                  <div className='card'>
                    <div>
                      <div className='point-card'>
                        <img
                          src={point < 100000 ? CoinPackageSmall : CoinPackageBig}
                          alt='coin package'
                          className='coin-package-img'
                        />
                        <div className='coin-package-number'>{formatCoin(point)}</div>
                        <div className='coin-package-text'>{t('Purchase.point')}</div>
                      </div>
                      <div className='price-card'>￥{formatCoin(price)}</div>
                    </div>
                  </div>
                  <div className='payment-option'>
                    <h6>{t('Purchase.payment.methodPayment')}</h6>
                    <div className='vendo-payment'>
                      <label className={`custom-radio-select`}>
                        <Checkbox
                          type='radio'
                          className={`checkbox-payment-vendo ${price < 1500 ? 'disable' : ''}`}
                          checked={vendoPay != null && !!vendoPay}
                          disabled={price < 1500}
                          onChange={() => {
                            setValue('vendo_pay', true);
                            setValue('credit_card', false);
                            setValue('tiger_pay', false);
                          }}
                        />
                        <label className={`label-credit ${price < 1500 ? 'disable' : ''}`}>
                          {t('Purchase.creditCardTitle')}
                        </label>
                        {price < 1500 && (
                          <span
                            style={{
                              color: 'red',
                              marginLeft: '10px',
                              fontSize: '11px',
                              marginTop: '2px',
                              cursor: 'default',
                            }}>
                            {t('Purchase.payment.maxyen')}
                          </span>
                        )}
                      </label>
                      <div className='icon-payment'>
                        {setting_system == '1' ? (
                          <>
                            <img src={Payment1} alt='ic-vendo-pay' />
                            <img src={Payment2} alt='ic-vendo-pay' />
                          </>
                        ) : (
                          <img className='icon-payment_card' src={Payment4} alt='ic-vendo-pay' />
                        )}
                      </div>
                    </div>
                    {/* <div>
                      <label className='custom-radio-select'>
                        <input
                          type='radio'
                          className='checkbox-payment-vendo'
                          checked={vendoPay != null && !vendoPay}
                          onChange={() => {
                            setValue('tiger_pay', true);
                            setValue('credit_card', false);
                            setValue('vendo_pay', false);
                          }}
                        />
                        <label>TIGER PAY</label>
                      </label>
                      <div className='icon-payment'>
                        <img src='/images/icon/payment/ic-tiger-pay.svg' alt='ic-tiger-pay' />
                      </div>
                      <div className='note-tiger-pay'>
                        {t('Purchase.payment.step1.tigerPayNote')}
                      </div>
                    </div> */}

                    {creditCard && (
                      <div className='form-payment'>
                        <div className='card-number mb-3'>
                          <label>カード番号</label>
                          <div>
                            <input
                              placeholder='Enter card number'
                              {...register(`payment.number`, {
                                required: true,
                                minLength: 16,
                                maxLength: 19,
                              })}
                            />
                            {errors?.payment &&
                              errors?.payment?.number?.type?.includes('min') && (
                                <label className='err-msg mt-1'>Min length is 16</label>
                              )}
                            {errors?.payment &&
                              errors?.payment?.number?.type?.includes('max') && (
                                <label className='err-msg mt-1'>Max length is 19</label>
                              )}
                          </div>
                        </div>
                        <div className='expired-date mb-3'>
                          <label>有効期限</label>
                          <div>
                            <CurrencyFormat
                              {...register(`payment.date`, { required: true })}
                              format='##/##'
                              placeholder='MM / YY'
                              mask={['M', 'M', 'Y', 'Y']}
                              className='fsite-input'
                            />
                          </div>
                        </div>
                        <div className='cvv'>
                          <label>
                            セキュリティコード
                            {/* <span className='tooltip'>
                  <img
                    className='ms-2'
                    src='/images/icon/payment/ic-info.svg'
                    alt='ic-tooltip'
                  />
                  <span className='tooltiptext'>
                    The security code, or CVV, refers to the extra 3 or 4 number on the
                    back or front of your card.
                  </span>
                </span> */}
                          </label>
                          <div>
                            <input
                              placeholder='Enter CVV'
                              {...register(`payment.cvv`, {
                                required: true,
                                minLength: 3,
                              })}
                            />
                            {errors?.payment &&
                              errors?.payment?.cvv?.type?.includes('min') && (
                                <label className='err-msg mt-1'>Min length is 3</label>
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                    <button
                      className={`btn-payment ${
                        isValidPayment() === true || tigerPay || vendoPay
                          ? `--active`
                          : `--disabled`
                      }`}
                      type='submit'
                      disabled={!(isValidPayment() === true || tigerPay || vendoPay)}
                      onClick={handleSubmit(onSubmit)}>
                      {t('Purchase.payment.buttonStep1')}
                    </button>
                  </div>
                  {/* <button
        className={`btn-payment ${
          isValidPayment() === true || watchTigerPay ? `--active` : `--disabled`
        }`}
        type='submit'
        disabled={isValidPayment() === true || watchTigerPay ? false : true}
        onClick={handleSubmit(onSubmit)}>
        {t('Purchase.payment.buttonStep1')}
      </button> */}
                </form>
              </div>
            )}
            {(step === 2 || step === 3) && (
              <div className='payment-page step-2'>
                <div className='img-container'>
                  <img src={BgTigerPay} alt='' className='img-tiger-pay' />
                </div>
                <div className='div1'>{t('Purchase.payment.step2.text1')}</div>

                <div className='div2' style={{ marginTop: 20 }}>
                  {t('Purchase.payment.step2.text2')}
                </div>
                <div className={`div3 custom ${i18n.language === 'jp' && 'jp'}`}>
                  <span className='text'>{t('Purchase.payment.step2.text3')}</span>
                  <span className={`text-right ${i18n.language === 'jp' && 'jp'}`}>
                    {code_payment}
                  </span>
                </div>
                <div className={`div3 custom ${i18n.language === 'jp' && 'jp'}`}>
                  <span className='text'>{t('Purchase.payment.step2.text4')}</span>
                  <span className={`text-right ${i18n.language === 'jp' && 'jp'}`}>
                    {formatCoin(point)} {t('Purchase.point')}
                  </span>
                </div>
                <div className={`div3 custom ${i18n.language === 'jp' && 'jp'}`}>
                  <span className='text'>{t('Purchase.payment.step2.text5')}</span>
                  <span className={`text-right ${i18n.language === 'jp' && 'jp'}`}>
                    {formatCoin(price)}
                    {i18n.language === 'en' && <span style={{ marginLeft: 3 }} />}
                    {t('Common.yen')}
                  </span>
                </div>

                <div className='div2' style={{ marginTop: 20 }}>
                  {t('Purchase.payment.step2.text6')}
                </div>
                <div style={{ marginBottom: 12 }}>{t('Purchase.payment.step2.text7')}</div>

                <div className='div2'>
                  {t('Purchase.payment.step2.text8')}
                  <div className='div3'></div>
                  {t('Purchase.payment.step2.text9')}
                </div>
                <div>{t('Purchase.payment.step2.text10')}</div>

                <div className='div3' style={{ marginTop: 20 }}>
                  {t('Purchase.payment.step2.text11')}
                </div>

                <div className='div3' style={{ marginTop: 20 }}>
                  {t('Purchase.payment.step2.text12')}
                </div>

                <button
                  className={`btn-payment btn-col top --active`}
                  type='button'
                  onClick={() => setIsVisiblePopupPayment(true)}>
                  {t('Common.buttonRequest')}
                </button>
                <button
                  className={`btn-payment btn-col --cancel`}
                  type='button'
                  onClick={() => setStep(1)}>
                  {t('Common.buttonCancel')}
                </button>
              </div>
            )}
            {step === 3 && (
              <>
                <PopupGeneral
                  isVisible={step === 3}
                  closeModal={() => {
                    setStep(1);
                  }}
                  title={t('Purchase.payment.title')}
                  className='popup-success-payment-tiger'>
                  <div className='success-icon'>
                    <SuccessRegisterIcon />
                  </div>
                  <div className='text-content'>
                    <div className='div1'>{t('Purchase.payment.step3.text1')}</div>

                    <div className='div2' style={{ marginTop: 20 }}>
                      {t('Purchase.payment.step3.text2')}
                    </div>
                    <div className={`div3 custom ${i18n.language === 'en' && 'en'}`}>
                      <span>{t('Purchase.payment.step2.text3')}</span>
                      <span className='text-right'>{code_payment}</span>
                    </div>
                    <div className={`div3 custom ${i18n.language === 'en' && 'en'}`}>
                      <span>{t('Purchase.payment.step2.text4')}</span>
                      <span className='text-right'>
                        {formatCoin(point)} {t('Purchase.point')}
                      </span>
                    </div>
                    <div className={`div3 custom ${i18n.language === 'en' && 'en'}`}>
                      <span>{t('Purchase.payment.step2.text5')}</span>
                      <span className='text-right'>
                        {formatCoin(price)}
                        {i18n.language === 'en' && <span style={{ marginLeft: 3 }} />}
                        {t('Common.yen')}
                      </span>
                    </div>

                    <div className='div3' style={{ marginTop: 16 }}>
                      {t('Purchase.payment.step3.text6')}
                    </div>

                    <div className='div2' style={{ marginTop: 20 }}>
                      {t('Purchase.payment.step3.text7')}
                    </div>
                    <div> {t('Purchase.payment.step3.text10')}</div>

                    <div className={`${i18n.language === 'en' && 'en'}`}>
                      <div className={`div2 ${i18n.language === 'en' && 'en'}`}>
                        {t('Purchase.payment.step3.text8')}
                      </div>
                      <div className={`div2 ${i18n.language === 'en' && 'en'}`}>
                        {t('Purchase.payment.step3.text9')}
                      </div>
                    </div>
                  </div>

                  <div className='button-wrapper'>
                    <div
                      className='ok btn'
                      onClick={() => {
                        window.location.href = `${newPathUser}`;
                      }}>
                      {t('Purchase.payment.goToMyPage')}
                    </div>
                  </div>
                </PopupGeneral>
                {/* <div className='div1'>{t('Purchase.payment.step3.text1')}</div>

    <div className='div2' style={{ marginTop: 20 }}>
      {t('Purchase.payment.step3.text2')}
    </div>
    <div className='div3 custom'>
      <span>{t('Purchase.payment.step2.text3')}</span>
      <span className='text-right'>{code_payment}</span>
    </div>
    <div className='div3 custom'>
      <span>{t('Purchase.payment.step2.text4')}</span>
      <span className='text-right'>
        {formatCoin(point)} {t('Purchase.point')}
      </span>
    </div>
    <div className='div3 custom'>
      <span>{t('Purchase.payment.step2.text5')}</span>
      <span className='text-right'>
        {formatCoin(price)}
        {i18n.language === 'en' && <span style={{ marginLeft: 3 }} />}
        {t('Common.yen')}
      </span>
    </div>

    <div className='div3' style={{ marginTop: 16 }}>
      {t('Purchase.payment.step3.text6')}
    </div>

    <div className='div2' style={{ marginTop: 20 }}>
      {t('Purchase.payment.step3.text7')}
    </div>
    <div style={{ marginBottom: 12 }}> {t('Purchase.payment.step3.text10')}</div>

    <div className='div2'>
      {t('Purchase.payment.step3.text8')}
      {t('Purchase.payment.step3.text9')}
    </div>
    <button
      className={`btn-payment --cancel`}
      type='button'
      onClick={() => navigate(`${newPathUser}`)}>
      {t('Purchase.payment.goToMyPage')}
    </button> */}
              </>
            )}
          </>
        )}
      </LayoutCreatorRight>
    </>
  );
};

export default PaymentScreen;
