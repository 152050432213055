import Icon from '@ant-design/icons';

const PaperIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='18'
        height='20'
        fill='none'
        viewBox='0 0 18 20'>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M11.738.762H5.085A3.82 3.82 0 001.25 4.49v10.713a3.828 3.828 0 003.742 3.91c.03 0 .06.002.092 0h7.989a3.887 3.887 0 003.729-3.91V6.038L11.738.762z'
          clipRule='evenodd'></path>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M11.476.75v2.909a2.574 2.574 0 002.568 2.575h2.755'></path>
      </svg>
    )}
    {...props}
  />
);

export default PaperIcon;
