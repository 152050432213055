import { LayoutCreatorRight, PopupLoading } from 'components';
import withCreatorRole from 'hocs/withCreatorRole';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import './index.scss';
import { deleteSurvey, getDetailAnalysisSurvey } from 'store/actions/survey';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { newPathUser } from 'constants/layout/constant';
import PopupConfirmDelete from 'components/PopupConfirmDelete';
import MarkDownRead from 'components/MarkDownRead';

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div
        className='custom-tooltip'
        style={{
          background: 'white',
          border: '1px solid #787880',
          height: 'auto',
          padding: '5px 5px 0',
        }}>
        <p className='label' style={{ textAlign: 'center', marginBottom: '5px' }}>
          {`${payload[0]?.payload?.title} : ${payload[0]?.payload?.users_count}`}{' '}
          {payload[0]?.payload?.label}
        </p>
      </div>
    );
  }
  return null;
};

const MessageQuestionnaire = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [mobileChart, setMobileChart] = useState(false);
  const [dataSurveyAnalysis, setDataSurveyAnalysis] = useState({});
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const width = window.screen.width;
    if (width < 500) {
      setMobileChart(true);
    }
    setLoading(true);
    dispatch(getDetailAnalysisSurvey(id, handleSetDataSurveyAnalysis));
  }, [t]);

  const handleSetDataSurveyAnalysis = ({ success, data }) => {
    if (success) {
      const lengthTitle = mobileChart ? 6 : 13;
      const convertData = {
        ...data,
        survey_questions: data?.survey_questions?.map((quest) => ({
          ...quest,
          survey_answers: quest?.survey_answers.map((ans) => ({
            ...ans,
            title: ans?.title || t('Survey.others'),
            label: t('Survey.answer'),
            shortTitle: !ans?.title
              ? t('Survey.others')
              : ans?.title?.length > lengthTitle
              ? ans?.title.slice(0, lengthTitle) + '...'
              : ans?.title,
          })),
        })),
      };
      setDataSurveyAnalysis(convertData);
    }
    setLoading(false);
  };

  const handleDeleteSurveySuccess = ({ success, data }) => {
    if (success) {
      navigate(`${newPathUser}surveys`);
    }
  };

  const handleDelete = () => {
    setLoading(true);
    dispatch(deleteSurvey(dataSurveyAnalysis?.id, handleDeleteSurveySuccess));
  };

  return (
    <>
      <LayoutCreatorRight
        className='message-questionnaire-creator-header'
        titlePage={dataSurveyAnalysis?.title}
        link={`${newPathUser}surveys`}>
        {loading && <PopupLoading className={'popup-loading'} />}
        {showPopupConfirm && (
          <PopupConfirmDelete
            title={t('Popup.confirmDeleteSurvey')}
            setShowPopup={setShowPopupConfirm}
            handleOnSubmit={handleDelete}
          />
        )}
        {dataSurveyAnalysis?.id && (
          <>
            <div className='message-questionnaire-creator'>
              <div className='creator-wrapper'>
                <div className='title'>
                  <MarkDownRead content={dataSurveyAnalysis?.description} />
                </div>
                {dataSurveyAnalysis?.survey_questions?.map((question) => {
                  const heightChart = question?.survey_answers?.length * 40 + 50 + 'px';
                  return (
                    <div className='chart-wrapper'>
                      <div className='content-left'>
                        <div className='sub-title'>{question?.title}</div>
                      </div>
                      <div className='content-right' style={{ height: heightChart }}>
                        <ResponsiveContainer className={'chart'}>
                          <BarChart data={question?.survey_answers} layout='vertical'>
                            <CartesianGrid
                              strokeDasharray='1 0'
                              vertical
                              horizontal={false}
                              stroke='#dfdfdf'
                            />
                            <XAxis
                              type='number'
                              allowDecimals={false}
                              dataKey='users_count'
                              className='xaxis'
                            />
                            <YAxis
                              type='category'
                              dataKey='shortTitle'
                              className='yaxis'
                              width={mobileChart ? 100 : 200}
                            />
                            <Bar dataKey='users_count' fill='#1480FF' barSize={16} />
                            <Tooltip
                              cursor={{ fill: 'transparent' }}
                              content={<CustomTooltip />}
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className='container-btn'>
                <button
                  className='btn active button-red-new'
                  type='submit'
                  onClick={() => setShowPopupConfirm(true)}>
                  {t('Creator.Survey.delete')}
                </button>
                <button
                  className={`btn btn-back cancel`}
                  type='button'
                  onClick={() => {
                    navigate(`${newPathUser}surveys`);
                  }}>
                  {t('Common.buttonCancel')}
                </button>
              </div>
            </div>
          </>
        )}
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(MessageQuestionnaire);
