import Icon from '@ant-design/icons';

const VerticalIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width='10'
        height='13'
        viewBox='0 0 10 13'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M2.12276 11.2369L2.12276 11.2503'
          stroke='#E5E6EB'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.12276 6.74473L2.12276 6.75812'
          stroke='#E5E6EB'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.12276 2.26036L2.12276 2.27375'
          stroke='#E5E6EB'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.66671 11.2369L7.66671 11.2503'
          stroke='#E5E6EB'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.66671 6.74473L7.66671 6.75812'
          stroke='#E5E6EB'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.66671 2.26036L7.66671 2.27375'
          stroke='#E5E6EB'
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default VerticalIcon;
