import useLockBodyScroll from 'hooks/useLockBodyScroll';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircleCheckIcon } from 'images';
import './styles.scss';

export default function PopupSuccess({ closeModal, title, isMobile }) {
  useLockBodyScroll();
  const { t } = useTranslation();
  const handleShowPopup = (e) => {
    closeModal(false);
  };
  return (
    <div className='popup popup-forgot-success' style={{ zIndex: '9999' }}>
      <div
        className='popup-content'
        style={{ height: 'auto', maxWidth: isMobile ? '330px' : '' }}>
        <div className='content mb-3'>
          <CircleCheckIcon className='me-3 text-success font-24' />
          <b className='ps-2'>{title}</b>
        </div>
        <div className='text-end'>
          <button className='btn btn-danger' onClick={handleShowPopup}>
            {t('Common.Ok')}
          </button>
        </div>
      </div>
    </div>
  );
}
