import Icon from '@ant-design/icons';

const Image2Icon = (props) => (
  <Icon
    component={() => (
      <svg
        width={23}
        height={23}
        viewBox='0 0 23 23'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M14.0458 5.3008L4.13412 5.3008C2.59701 5.3008 1.37793 6.51988 1.37793 8.05699L1.37793 17.9157C1.37793 19.4528 2.59702 20.6719 4.13412 20.6719L14.0458 20.6719C15.5829 20.6719 16.802 19.4528 16.802 17.9157L16.802 8.00398C16.749 6.51988 15.5299 5.3008 14.0458 5.3008ZM6.25427 2.96863L14.7879 2.96863C17.173 2.96863 19.1342 4.92977 19.1342 7.31494L19.1342 15.8485C19.1342 16.1666 19.5052 16.3256 19.7172 16.1136C20.3003 15.5835 20.6713 14.8415 20.6713 13.9404L20.6713 6.83791C20.6713 3.81669 18.2331 1.37852 15.2119 1.37852L8.1094 1.37852C7.26134 1.37852 6.46629 1.74955 5.93625 2.33259C5.72423 2.59761 5.93625 2.96863 6.25427 2.96863Z'
          fill='#F2F3F5'
        />
      </svg>
    )}
    {...props}
  />
);

export default Image2Icon;
