/* eslint-disable import/no-anonymous-default-export */

import { TYPES } from 'store/actions';

const initialState = {
  loading: false,
  error : null,
  status : null
};

export default (state = initialState, action) => {

  switch (action.type) {
    case TYPES.LIST_ERROR:
        return {
          status: action.type,
          error:  (action?.data?.error !== undefined || action?.data?.error !== null ) ? action?.data?.error : action?.payload?.error?.messageId,
          loading: false,
        };
    default:
      return state;
  }
};
