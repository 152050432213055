import { LayoutCreatorRight, PopupConfirmFan, PopupLoading } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import {
  followUserHideComment,
  getCommentHide,
  setIdScrollBackHideComment,
  unHideComment,
} from 'store/actions/commentHide';
import { logUserProfile } from 'store/actions/myPage';
import { isAuth } from 'utils';
import './index.scss';
import { CommentHideItem } from './item';
import EmptyHiddenCommentIconV2 from 'images/EmptyHiddenCommentIconV2';

const CommentHideFromPostPage = () => {
  const { t } = useTranslation();
  const { postId } = useParams();
  const dispatch = useDispatch();
  const { listCommentHide, pagination, idScrollBackCommentHide } = useSelector(
    (state) => state?.commentHide
  );
  const [page, setPage] = useState(
    pagination?.current_page ? +pagination?.current_page + 1 : 1
  );
  const [isScrollToTop, setIsScrollToTop] = useState(true);
  const [isLoadingShimmer, setIsLoadingShimmer] = useState(false);
  const [isLoadingCircle, setIsLoadingCircle] = useState(false);
  const [isLoadingFetchNext, setIsLoadingFetchNext] = useState(false);
  const [popupHideComment, setPopupHideComment] = useState(null);

  useEffect(() => {
    if (page && !idScrollBackCommentHide) {
      setIsLoadingShimmer(true);
      dispatch(
        getCommentHide(
          {
            page,
            limit: 20,
            postId,
          },
          () => {
            setIsLoadingShimmer(false);
            setIsLoadingCircle(false);
          }
        )
      );
    }
  }, [dispatch, page, postId, idScrollBackCommentHide]);

  useEffect(() => {
    if (idScrollBackCommentHide) {
      const idPost = document.getElementById(idScrollBackCommentHide);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idScrollBackCommentHide]);

  useEffect(() => {
    if (!idScrollBackCommentHide && isScrollToTop) {
      const top = document.getElementById('app_cocofans_0');
      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
    }
  }, [idScrollBackCommentHide, isScrollToTop]);

  const fetchNext = () => {
    const pages = pagination?.total / pagination?.per_page;
    if (pages > page) {
      setIsLoadingFetchNext(true);
      if (idScrollBackCommentHide) {
        setIsScrollToTop(false);
        dispatch(setIdScrollBackHideComment());
      } else {
        setPage(page + 1);
      }
    } else if (pages + 1 > page) {
      if (idScrollBackCommentHide) {
        setIsLoadingFetchNext(true);
        setIsScrollToTop(false);
        dispatch(setIdScrollBackHideComment());
      }
    }
  };

  const handleHideComment = () => {
    const { id, cb = () => {} } = popupHideComment;
    dispatch(unHideComment(id, cb));
    setPopupHideComment(false);
  };

  const handleFollowCreator = (item) => {
    if (isAuth) {
      setIsLoadingCircle(true);
      dispatch(
        followUserHideComment(
          item?.user_id,
          () => setIsLoadingCircle(false),
          () => setIsLoadingCircle(false)
        )
      );
    } else {
      dispatch(logUserProfile(item));
    }
  };

  const handleRedirect = (id, cbRedirect) => {
    dispatch(
      setIdScrollBackHideComment(`hide-comment-${id}`, () => {
        cbRedirect();
      })
    );
  };
  return (
    <>
      {popupHideComment && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupHideComment(false)}
          colorSvg='#FF9F43'
          iconNote
          text={t('CommentHide.notiUnHide')}
          className='popup-delete'>
          <div className='ok btn' onClick={handleHideComment}>
            {t('Common.buttonYes')}
          </div>
          <div className='btn cancel' onClick={() => setPopupHideComment(false)}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}
      {isLoadingCircle && <PopupLoading className={'popup-loading'} />}
      <LayoutCreatorRight
        titlePage={`${t('CommentHide.title')}`}
        className='comment-hide-from-post-layout'>
        <div className='comment-hide-container'>
          {isLoadingShimmer ? (
            <div className='shimmer-loading'>
              <ShimmerPostDetails hasImage imageType='circular' title />
            </div>
          ) : (
            <>
              {listCommentHide?.length > 0 ? (
                <InfiniteLoad
                  loading={isLoadingFetchNext}
                  data={listCommentHide || []}
                  fetchNext={fetchNext}>
                  <div className='list-comment-hide'>
                    {listCommentHide?.map((item) => (
                      <CommentHideItem
                        key={item?.id}
                        id={`hide-comment-${item?.id}`}
                        item={item}
                        handleHideComment={(e) => {
                          setPopupHideComment(e);
                        }}
                        handleFollowCreator={handleFollowCreator}
                        handleRedirect={handleRedirect}
                      />
                    ))}
                  </div>
                </InfiniteLoad>
              ) : (
                <div className='no-data-comment-hide'>
                  <EmptyHiddenCommentIconV2 />
                  <div className='text'>{t('SidebarRecommendPost.noDataListCreator')}</div>
                </div>
              )}
            </>
          )}
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default CommentHideFromPostPage;
