import styled, { css } from 'styled-components';

export const Styled = {
  WrapperFixed: styled.div`
    margin-top: 24px;
    height: 100%;
    @media screen and (max-width: 1367px) {
      width: 25vw !important;
    }
  `,
  Wrapper: styled.div`
    filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.08));
    position: fixed;
    background-color: transparent;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 82px);
  `,
  CocoFansRecommendWrapper: styled.div`
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 20px;
    background-color: #fff;
    padding: 20px;
    max-width: 330px;
    width: 330px;
    @media screen and (max-width: 1367px) {
      max-width: 25vw;
    }
    // box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    ${({ height100 }) =>
      height100
        ? css`
            height: calc(100%);
            margin-bottom: 0;
          `
        : css`
            //height: 435px;
            height: 100%;
          `}
  `,
  ListCocoFansRecommend: styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    gap: 15px;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
    }

    .btn-follow {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 12px;
      width: 80px;
      height: 36px;
      border: 1px solid #b1b6bb;
      border-radius: 6px;
      background: #ffffff;
    }

    .btn-followOrUnFollow {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      border: 1px solid #e5e6eb;
      width: 160px;
      background-color: #fff;
      color: #000;
    }
    .btn-followOrUnFollow.active {
      width: 277px;
      color: #fff;
      background-color: #dc143c;
      border-color: #dc143c;
    }

    button {
      height: 32.1px;
      padding-top: 0;
      padding-bottom: 0;
      font-weight: 600;
    }

    .item {
      display: flex;
      gap: 12px;
      align-items: center;
      padding-right: 20px;
      cursor: pointer;
      ${({ paddingBottomLastChild }) =>
        paddingBottomLastChild
          ? css`
              &:last-child {
                padding-bottom: 24px;
              }
            `
          : css``}
      img {
        min-height: 56px;
        min-width: 56px;
        max-height: 56px;
        max-width: 56px;
        border-radius: 50%;
      }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 12px;
        line-height: 22px;
        padding-right: 16px;
        overflow: hidden;
        flex: 1 1 100%;
      }
      .name {
        font-weight: 900;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .content {
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  `,
  PostContentRecommendWrapper: styled.div`
    height: 50%;
    position: relative;
    overflow: hidden;
    border-radius: 20px;
    background-color: #fff;
    padding: 20px 0 0 20px;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08);
    max-width: 330px;
  `,
  ListPostContentRecommend: styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 56px);
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
    }
    .item {
      display: flex;
      gap: 6px;
      flex-direction: column;
      padding-bottom: 15px;
      cursor: pointer;
      &:last-child {
        padding-bottom: 10px;
      }

      img {
        min-height: 180px;
        min-width: 290px;
        max-height: 180px;
        max-width: 290px;
        object-fit: cover;
        border-radius: 6px;
        /* background-color: #000; */
      }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        padding-right: 16px;
        flex: 1 1 100%;
        overflow: hidden;
      }
      .name {
        font-weight: 900;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .content {
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  `,
  Title: styled.div`
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 20px;
    ${({ fontSize16 }) =>
      fontSize16
        ? css`
            font-size: 16px;
          `
        : css`
            font-size: 20px;
          `}
    @media screen and (max-width: 1279px) {
      font-size: 16px;
    }
  `,
  NoData: styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f1afbb;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    text-align: center;
    .text {
      margin-right: -16px;
      color: #b1b6bb;
      font-weight: 400;
      font-size: 14px;
    }
  `,

  ItemList: styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    width: 100%;
    .name {
      font-weight: 700;
      font-size: 14px;
      color: #282727;
      padding-right: 4px;
    }
    .item-rank {
      min-width: 22px;
      width: 22px;
      text-align: center;
      &.width-large {
        min-width: 40px;
        width: 40px;
      }
    }
  `,
};
