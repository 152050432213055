import { newPathUser } from 'constants/layout/constant';
import React, { Component } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './notification.scss';

toast.configure();
// eslint-disable-next-line consistent-return
const pushNotify = (type, message, id) => {
  const checkId = window.location.pathname;
  if (!toast.isActive(id)) {
    // bị lỗi notifi nếu bật
    // toast.dismiss();
    const customMessage = message?.split(',,')?.join(`\n`);
    switch (type) {
      case 'success':
        return toast.success(customMessage, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: true,
          className: 'display-linebreak',
          containerId: id,
          theme: 'colored',
          icon: ({ theme, type }) => (
            <svg
              width={18}
              height={18}
              viewBox='0 0 18 18'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M12.0938 7.3125L7.96873 11.25L5.90625 9.28125'
                stroke='#52C41A'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z'
                stroke='#52C41A'
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </svg>
          ),
          enableMultiContainer: true,
        });
      case 'error':
        console.log('customMessage',customMessage)
        return toast.error(customMessage, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: true,
          containerId: id || 'refresh',
          theme: 'colored',
          className: `display-linebreak ${
            ((checkId?.includes('/sign-in') &&
              id &&
              id?.includes('SOMEONE_IS_LOGGED_IN_WITH_THIS_ACCOUNT_DO_YOU_WANT_TO_LOGIN?')) ||
              (checkId?.includes('/oauth/twitter') &&
                [
                  'TOO_SOME_SUBSCRIBERS',
                  'EMAIL_EXIST',
                  'ACCOUNT_ALREADY_EXISTS_ANOTHER_LINK',
                ]?.includes(id)) ||
              (!checkId?.includes(`${newPathUser}`) &&
                ['TOO_SOME_SUBSCRIBERS']?.includes(id))) &&
            'd-none'
          }`,
          enableMultiContainer: true,
          icon: ({ theme, type }) => (
            <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z" stroke="#DC143C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M11.25 6.75L6.75 11.25" stroke="#DC143C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M11.25 11.25L6.75 6.75" stroke="#DC143C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          ),
        });
      case 'warn':
        return toast.warn(customMessage, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: true,
          containerId: id,
          theme: 'colored',
          className: 'display-linebreak',
          enableMultiContainer: true,
          icon: ({ theme, type }) => (
            <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z" stroke="#FFBF00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M9 5.625V9.5625" stroke="#FFBF00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M9 12.7969C9.38833 12.7969 9.70312 12.4821 9.70312 12.0938C9.70312 11.7054 9.38833 11.3906 9 11.3906C8.61167 11.3906 8.29688 11.7054 8.29688 12.0938C8.29688 12.4821 8.61167 12.7969 9 12.7969Z" fill="#FFBF00" />
            </svg>
          ),
        });
      case 'info':
        return toast.info(customMessage, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
          hideProgressBar: true,
          containerId: id,
          theme: 'colored',
          className: 'display-linebreak',
          enableMultiContainer: true,
          icon: ({ theme, type }) => (
            <svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z" stroke="#1480FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M9 5.625V9.5625" stroke="#1480FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M9 12.7969C9.38833 12.7969 9.70312 12.4821 9.70312 12.0938C9.70312 11.7054 9.38833 11.3906 9 11.3906C8.61167 11.3906 8.29688 11.7054 8.29688 12.0938C8.29688 12.4821 8.61167 12.7969 9 12.7969Z" fill="#1480FF" />
            </svg>
          ),
        });
      default:
        return toast(customMessage);
    }
  }
};

// eslint-disable-next-line react/prefer-stateless-function
class Notification extends Component {
  render() {
    return (
      <ToastContainer hideProgressBar closeOnClick enableMultiContainer autoClose={3000} />
    );
  }
}

export default Notification;
export { pushNotify };
