import './index.scss';
const Tooltip2 = ({ children, className, onClick, text = '', ...props }) => {
  return (
    <div className={`tooltip ${className}`} {...props}>
      {text && (
        <div className='text' onClick={onClick}>
          {text}
        </div>
      )}
      <span className='tooltiptext'>{children}</span>
    </div>
  );
};

export default Tooltip2;
