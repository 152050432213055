import Icon from '@ant-design/icons';

const TwitterV2 = (props) => (
  <Icon
    component={() => (
      <svg width={40} height={40} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_8345_984)">
          <path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.95431 31.0457 0 20 0C8.95431 0 0 8.95431 0 20C0 31.0457 8.95431 40 20 40Z" fill="#0F1419" />
          <path d="M27.689 9H31.5225L23.1474 18.319L33 31H25.2855L19.2432 23.309L12.3295 31H8.49367L17.4516 21.0323L8 9H15.9104L21.372 16.0298L27.689 9ZM26.3435 28.7662H28.4677L14.7561 11.1165H12.4767L26.3435 28.7662Z" fill="white" />
        </g>
        <defs>
          <clipPath id="clip0_8345_984">
            <rect width={40} height={40} fill="white" />
          </clipPath>
        </defs>
      </svg>
    )}
    {...props}
  />
);

export default TwitterV2;
