import { CloseIcon, CircleCheckIcon } from 'images';
import useLockBodyScroll from 'hooks/useLockBodyScroll';

const PopupAlert = ({
  header, content, hiddenClose, hiddenIcon, isVisiblePopup, children, ...props
}) => {
  useLockBodyScroll();
  const handleShowPopup = (e) => {
    e.preventDefault();
    isVisiblePopup(null);
  };

  return (
    <div className='popup popup-alert'>
      <div className='popup-content'>
        {
          header && (
          <div className='popup-header'>
            {header}
            {
              hiddenClose && <CloseIcon className='close-icon cursor-pointer' onClick={handleShowPopup} />
            }
          </div>
          )
        }
        
        {
          content && (
            <div className='popup-body'>
              {
                !hiddenIcon && <CircleCheckIcon className='me-3 text-green' />
              }
              {content}
            </div>
          )
        }
        
        <div className='popup-footer'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default PopupAlert;
