import Icon from '@ant-design/icons';

const BookmarkIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M14.999 17.5L9.99845 14.375L4.99902 17.5V3.75C4.99902 3.58424 5.06487 3.42527 5.18208 3.30806C5.29929 3.19085 5.45826 3.125 5.62402 3.125H14.374C14.5398 3.125 14.6988 3.19085 14.816 3.30806C14.9332 3.42527 14.999 3.58424 14.999 3.75V17.5Z'
          fill='#DC143C'
          stroke='#DC143C'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default BookmarkIcon;
