import { LayoutListIdol, PopupLoading } from 'components';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getListBannerCreator,
  getListCreatorLivestream,
  getListFavoriteCreator,
  getListNewCreator,
  getListPickupCreator,
  getListRankingContent,
  getListRankingCreator,
} from '../../store/actions/listIdol';
import CreatorLivestream from './creator-livestream';
import ListBanner from './list-banner';
import NewCreator from './new-creator';
import CreatorPickupOperator from './pickup-operator';
import PopularContent from './popular-content';
import PopularCreator from './popular-creator';
import RankingCreator from './ranking-creator';
function ListIdol() {
  const {
    listFavoriteCreator,
    listRankingContent,
    listNewCreator,
    listCreatorLivestream,
    listPickupCreator,
    listRankingCreator,
    listBanner,
    loading,
  } = useSelector((state) => state.listIdol);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getListFavoriteCreator());
    dispatch(getListRankingContent());
    dispatch(getListNewCreator());
    dispatch(getListCreatorLivestream());
    dispatch(getListRankingCreator());
    dispatch(getListPickupCreator());
    dispatch(getListBannerCreator());
  }, []);

  return (
    <>
      {loading && <PopupLoading className={'popup-loading'} />}
      <LayoutListIdol>
        <PopularCreator data={listFavoriteCreator || []} />
        <PopularContent data={listRankingContent || []} />
        <NewCreator data={listNewCreator || []} listBanner={listBanner} />
        <CreatorLivestream data={listCreatorLivestream || []} />
        <CreatorPickupOperator data={listPickupCreator || []} />
        <RankingCreator data={listRankingCreator || []} />
        <ListBanner data={listBanner || []} />
      </LayoutListIdol>
    </>
  );
}

export default ListIdol;
