import { newPathUser } from 'constants/layout/constant';
import {
  Edit3Icon,
  EyeV2Icon,
  FlagIcon,
  FlowUnFlowIcon,
  HiddenV2Icon,
  ThreePointIcon,
  TrashIcon,
  UnFlowIcon,
} from 'images';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logUserProfile } from 'store/actions/myPage';
import { STORAGE } from 'utils';
import Storage from 'utils/storage';
const InfoBox = ({
  item,
  index,
  handleFollowCreator,
  setIsShowOtherBox,
  toggleValueVideo,
  navigate,
  toggleFollowAllUser,
  setIsVisiblePopDeletePost,
  handleHiddenPost,
  toggleHiddenUser,
  t,
}) => {
  const refOutSide = useRef();
  const { dataUser } = useSelector((state) => state.users);
  const handleClickOutside = (event) => {
    const threePointIcon = document.getElementById('three-icon-timeline');
    if (event.target === threePointIcon) {
    } else if (refOutSide.current && !refOutSide.current.contains(event.target)) {
      setIsShowOtherBox &&
        setIsShowOtherBox({
          isShow: false,
          indexShow: -1,
        });
    }
  };
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refOutSide]);
  const dispatch = useDispatch();
  return (
    <div className='popup-edit-comment' ref={refOutSide}>
      {dataUser?.id !== item?.user_id && (
        <>
          {/* <div
            className='item'
            onMouseDown={() => {
              if (!isAuth) {
                dispatch(logUserProfile(item));
              } else {
                handleFollowCreator(item?.user_id);
                if (item?.follow_flag === 1) {
                  toggleFollowAllUser(index, item?.user_id, false, 0);
                } else {
                  toggleFollowAllUser(
                    index,
                    item?.user_id,
                    true,
                    item?.is_followed === 1 ? 0 : 1
                  );
                }
              }
            }}>
            {item.is_followed !== 1 ? <FlowUnFlowIcon /> : <UnFlowIcon />}
            <span className='text'>
              {item?.is_followed === 0 || !item?.is_followed
                ? t('comment.listComment.flow')
                : t('comment.listComment.unflow')}
            </span>
          </div> */}
          <div
            className='item'
            onMouseDown={() => {
              if (!isAuth) {
                setIsShowOtherBox({
                  isShow: false,
                  indexShow: -1,
                });
                dispatch(logUserProfile(item));
              } else {
                setIsShowOtherBox({
                  isShow: false,
                  indexShow: -1,
                });
                navigate(`${newPathUser}post-report`, {
                  state: {
                    post_id: item?.id,
                  },
                });
              }
            }}>
            <FlagIcon />
            <span className='text'>{t('comment.listComment.iconFlag')}</span>
          </div>
        </>
      )}
      {dataUser?.id === item?.user_id && (
        <>
          {item?.is_active !== 0 && item?.is_active !== 2 && (
            <div
              className='item'
              onMouseDown={() => {
                if (!isAuth) {
                  dispatch(logUserProfile(item));
                } else {
                  handleHiddenPost(item);
                  if (item?.is_hided === 1) {
                    toggleHiddenUser(index, item?.user_id, false, 0, 'public');
                  } else {
                    toggleHiddenUser(
                      index,
                      item?.user_id,
                      true,
                      item?.is_hided === 1 ? 0 : 1,
                      item?.post_status === 'private' ? 'public' : 'private'
                    );
                  }
                }
              }}>
              {item.is_hided === 0 ? <EyeV2Icon /> : <HiddenV2Icon />}
              <span className='text'>
                {item?.is_hided === 0 ? t('Common.hiddenPost') : t('Common.openPost')}
              </span>
            </div>
          )}
          <div
            className='item'
            style={{ marginBottom: '6px' }}
            onMouseDown={() => {
              if (!isAuth) {
                setIsShowOtherBox({
                  isShow: false,
                  indexShow: -1,
                });
                dispatch(logUserProfile());
              } else {
                setIsShowOtherBox({
                  isShow: false,
                  indexShow: -1,
                });
                navigate(`${newPathUser}post/update/${item?.id}`, {
                  state: {
                    isPostShortMyPage: true,
                  },
                });
              }
            }}>
            <Edit3Icon />
            <span className='text'>{t('comment.listComment.iconEdit')}</span>
          </div>
          <div
            className='item'
            onMouseDown={() => {
              if (!isAuth) {
                setIsShowOtherBox({
                  isShow: false,
                  indexShow: -1,
                });
                dispatch(logUserProfile());
              } else {
                setIsVisiblePopDeletePost({ id: item?.id });
                setIsShowOtherBox({
                  isShow: false,
                  indexShow: -1,
                });
              }
            }}>
            <TrashIcon />
            <span className='text'>{t('Common.buttonDelete')}</span>
          </div>
        </>
      )}
    </div>
  );
};

export default InfoBox;
