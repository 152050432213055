import { PopupLoading } from 'components';
import Footer from 'components/Footer';
import { newPathUser } from 'constants/layout/constant';
import SuccessRegisterIcon from 'images/SuccessRegisterIcon';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { handleChangeEmailConfirmStep2 } from 'store/actions/users';
import { STORAGE } from 'utils/constant.js';
import Storage from 'utils/storage';
import './index.scss';
function ProfileConfirmChangeEmail() {
  const { token } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { loading, changeEmailConfirmStep2, changeEmailConfim2Failed } = useSelector(
    (state) => state.users
  );
  const dispatch = useDispatch();

  const handleBack = () => {
    navigate(`${newPathUser}profile/info`);
  };

  useEffect(() => {
    if (changeEmailConfim2Failed) {
      setTimeout(handleBack(), 3000);
    }
  }, [changeEmailConfim2Failed]);

  useEffect(() => {
    if (changeEmailConfirmStep2) {
      Storage.remove(STORAGE.USER_ACCESS_TOKEN);
    }
  }, [changeEmailConfirmStep2]);

  useEffect(() => {
    token && dispatch(handleChangeEmailConfirmStep2(token));
  }, [token]);

  return (
    <React.Fragment>
      {loading && <PopupLoading className={'popup-loading'} />}

      <div className='page-succes-wrapper'>
        {changeEmailConfirmStep2 && (
          <div className='page-success-box'>
            <div className='page-header'>
              <Footer isClass={true} />
            </div>
            <div className='page-body'>
              <div className='text-center mb-5'>
                <SuccessRegisterIcon className='mb-4' />
                <div className='message-success'>
                  {t('ValidateMsg.messageConfirmNewEmail')}
                </div>
              </div>
              <Link className='btn btn-outline-danger btn-lg bold w-100' to={'/sign-in'}>
                {t('Common.goToLogin')}
              </Link>
            </div>
            <div className='page-footer'>
              <Footer isClass={true} />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default ProfileConfirmChangeEmail;
