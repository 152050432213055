import {
  AccountSidebar,
  HeaderListIdolV2,
  ImgCustom,
  LayoutCreatorRight,
  MenuSidebarCustom,
  PopupLockPostNormal,
  PopupSharePost,
  ToggleButton,
} from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import {
  BookmarkLabel,
  ChatIcon,
  HeartActiveIcon,
  HeartIcon,
  NoBookmarkList,
  PlayIcon,
  ShareIcon,
} from 'images';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetDataFavor, resetDetailPost } from 'store/actions/usersNew';
import {
  fanGetListBookmark,
  followOrUnFollowUser,
  getIDFavorite,
  postBookMark,
  resetIDLike,
} from 'store/actions/users';
import {
  convertToCurrentTime,
  decryptPath,
  formatNumber,
  funcThumbnailIMG,
  isAdmin,
  linkS3Video,
  linkS3VideoM3U8,
} from 'utils/utils';
import ReactHlsPlayer from 'react-hls-player';
import { newPathUser } from 'constants/layout/constant';
import '../index.scss';
import { getEnv } from 'configs/env';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { StyledTimeLine } from 'components/DetailCard/styles';
import { LOGO_THUMBNAIL_COCOFAN } from 'utils';
import { isMobile } from 'react-device-detect';
import { postLikeBookmark } from 'store/actions/myPage';
import HeaderMobileV2 from 'components/HeaderV2/HeaderMobile';
import { useRef } from 'react';
import useDisableContextMenu from 'hooks/useDisableContextMenu';

const ViewOnTimeLine = () => {
  const {
    loading,
    fanListBookmark,
    fanListBookmarkPagination,
    dataUser,
    idFavorite,
    initPageBook,
  } = useSelector((state) => state.users);
  const refVideo = useRef();
  useDisableContextMenu(refVideo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(idFavorite && initPageBook ? +initPageBook : 1);
  const [idBookmark, setIdBookmark] = useState();
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const itemsPerPage = 20;
  const fetchNext = () => {
    const pages = fanListBookmarkPagination?.total / fanListBookmarkPagination?.per_page;
    if (pages > page) {
      funCallApi(page + 1);
      setPage(page + 1);
    }
  };
  const { t } = useTranslation();

  //api like post
  const handleLikePost = (post) => {
    dispatch(postLikeBookmark(post));
  };

  const funCallApi = useCallback(
    (page) => {
      setIsLoading(true);
      dispatch(
        fanGetListBookmark(
          page,
          itemsPerPage,
          () => setIsLoading(false),
          () => setIsLoading(false)
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page]
  );

  const handleSharePost = (data) => {
    setPopupCopyLink(data);
  };

  useEffect(() => {
    dispatch(resetDetailPost());
  }, []);

  useEffect(() => {
    if (initPageBook < 1) {
      setIsLoading(true);
      dispatch(
        fanGetListBookmark(
          page,
          itemsPerPage,
          () => setIsLoading(false),
          () => setIsLoading(false)
        )
      );
    }
  }, []);

  useEffect(() => {
    if (idFavorite) {
      const idPost = document.getElementById(idFavorite.id);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idFavorite]);

  const handleNavigate = (type, post_id) => {
    dispatch(getIDFavorite({ id: post_id, tab: 1, page: page }));
    if (type === 2) {
      navigate(`${newPathUser}post-sale/${post_id}`);
    } else {
      navigate(`${newPathUser}post/${post_id}`);
    }
  };

  const checkDisabledAction = (item) => {
    if (
      (item?.is_followed === 0 && item?.follow_flag === 1 && item?.user_id !== dataUser?.id) ||
      (!item?.is_media_display && item?.type === 1 && item?.user_id !== dataUser?.id)
    ) {
      return true;
    }
    return false;
  };

  const renderImageOrVideo = (post) => {
    const thumbnailTMP = post?.medias?.filter(
      (item) => item?.type === 4 && item?.public_flag === 1
    );
    const isMiniUrl = () => {
      return (
        (post?.is_followed === 0 &&
          post?.follow_flag === 1 &&
          post?.user_id !== dataUser?.id) ||
        post?.is_media_display === 0
      );
    };

    const itemTypeNormal = post?.medias
      ?.filter((item) => item?.type === 1 || item?.type === 2)
      ?.filter((item) => item?.public_flag === 1)[0];
    const itemTypeSale = post?.medias?.find((item) => item?.type === 4);
    const item = post?.type !== 2 ? itemTypeNormal : itemTypeSale;

    if (item?.type !== 2) {
      return (
        <>
          {!item?.url || decryptPath(item?.url) === 'NULL' ? (
            <div className='logo-container'>
              <img src={LOGO_THUMBNAIL_COCOFAN} alt='logo sharegram' className='logo-img' />
            </div>
          ) : (
            <div
              className='blue-img'
              style={{
                backgroundImage: `url("${decryptPath(item?.url, { isMini: true })}")`,
              }}
              height={360}
              width={'100%'}
              onClick={() => {}}>
              <div className='bg-img'></div>

              <ImgCustom
                screen={
                  isMiniUrl()
                    ? 'post_212_auto_pc'
                    : `${isMobile ? 'thumbnail_auto_193' : 'thumbnail_auto_168'}`
                }
                src={item ? `${item?.url}` : ''}
                alt={item?.id}
                className={`${item ? 'cls-img' : 'img-default'} image-item`}
                avatarErr={false}
              />
            </div>
          )}
        </>
      );
    } else {
      if (item?.url?.indexOf('.m3u8') === -1) {
        return (
          <>
            <video
              src={`${linkS3Video}${decryptPath(item?.url) + '#t=0.1'}`}
              preload='none'
              poster={`${
                thumbnailTMP?.length === 0
                  ? funcThumbnailIMG(decryptPath(item?.url))
                  : decryptPath(post?.medias[0]?.url) + '#t=0.1'
              }`}
              title={`${linkS3Video}${decryptPath(item?.url)}`}
              alt=''
              className={'cls-img video-wrapper-bookmark'}
            />
            <div className='play-button-bookmark'>
              <PlayIcon className='play-button-icon' />
            </div>
          </>
        );
      } else {
        return (
          <>
            <ReactHlsPlayer
              playerRef={refVideo}
              src={`${linkS3VideoM3U8}${decryptPath(item?.url) + '#t=0.1'}`}
              title={`${linkS3VideoM3U8}${decryptPath(item?.url)}`}
              autoPlay={false}
              controls={false}
              playsInline={true}
              className={'cls-img video-wrapper-bookmark'}
              width='100%'
              height='100%'
            />
            <div className='play-button-bookmark'>
              <PlayIcon className='play-button-icon' />
            </div>
          </>
        );
      }
    }
  };

  return (
    <>
      {popupCopyLink && (
        <PopupSharePost
          isVisiblePopup={() => setPopupCopyLink(false)}
          data={popupCopyLink || {}}
        />
      )}
      <HeaderMobileV2 titlePage={t('Favorite.title')} />
      <LayoutCreatorRight
        noButtonBack
        titlePage={t('Favorite.title')}
        className='like-list-bookmark-layout page-view-on-timeline'>
        <div className={`fan-favorite-wrapper `}>
          <ul className='header header-bookmarks'>
            <li className='active'>{t('Favorite.bookmarkList')}</li>
            <li
              onClick={() => {
                dispatch(resetIDLike());
                navigate(`${newPathUser}like-list`);
              }}
              className={`${isLoading && !fanListBookmark.length && 'disable-like'}`}>
              {t('Favorite.likeList')}
            </li>
          </ul>
          <div className='bookmark-navigation-checkbox'>
            <div className='navigate-label'>
              {/* <span className='first-label active-label'>{t('Favorite.tabLeft')}</span>
              <span className=''>{t('Favorite.tabRight')}</span> */}
            </div>
            <div className='checkbox-container'>
              <span className='checkbox-label'>{t('Common.changeDisplay')}</span>
              <ToggleButton
                disabled={isLoading && !fanListBookmark.length}
                id='navigation-checkbox'
                defaultChecked={true}
                onChange={() => {
                  dispatch(resetDataFavor());
                  navigate(`${newPathUser}favorite/list-post`);
                }}
              />
            </div>
          </div>
          {isLoading && !fanListBookmark.length && (
            <div className='shimmer-loading'>
              <ShimmerPostDetails hasImage imageType='circular' title />
            </div>
          )}
          <InfiniteLoad loading={loading} data={fanListBookmark} fetchNext={fetchNext}>
            <div className={`fan-view-on-timeline `}>
              {fanListBookmark.length > 0
                ? fanListBookmark.map((item, index) => (
                    <div
                      key={index}
                      id={item?.id}
                      className={`item ${item?.id === idBookmark ? 'faded' : ''}`}>
                      {/* {handleLeftIcon(item)} */}
                      <div
                        className='img-wrapper thumbnail-bookmark-large'
                        onClick={() => {
                          handleNavigate(item?.type, item?.id);
                        }}>
                        {renderImageOrVideo(item)}
                        {item?.is_followed === 0 &&
                        item?.follow_flag === 1 &&
                        !isAdmin &&
                        dataUser?.id !== item?.user_id ? (
                          <StyledTimeLine.WrapperBlur className='bookmark-large-image'>
                            <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                dispatch(followOrUnFollowUser(item?.user_id));
                              }}>
                              {t('Common.follow')}
                            </button>
                          </StyledTimeLine.WrapperBlur>
                        ) : (
                          ''
                        )}
                        {item?.is_media_display === 0 && item?.user_id !== dataUser?.id && (
                          <PopupLockPostNormal
                            className='popup-lock-view-on-timeline'
                            post_id={item.id}
                            detailPost={item}
                            is_3_column
                            is_des
                            in_my_page
                            point={dataUser?.points}
                          />
                        )}
                      </div>
                      <BookmarkLabel
                        className={`${
                          checkDisabledAction(item) ? 'disabled-bookmark' : ''
                        } bookmark-label`}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          if (!checkDisabledAction(item)) {
                            setIdBookmark(item?.id);
                            dispatch(postBookMark(item?.id), () => {
                              setIdBookmark();
                            });
                          }
                        }}
                      />
                      <div
                        style={{ cursor: "pointer" }}
                        className='account-info block-card-sale'
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(getIDFavorite({ id: item.id, tab: 1, page: page }));
                          navigate(`${newPathUser}${item?.account_id}`);
                        }}>
                        <ImgCustom
                          className='account-avatar'
                          isPlaceholderAvatar
                          screen='avatar_mini_32_32'
                          alt='avatar'
                          src={decryptPath(item?.avatar)}
                        />
                        <span className='account-name'>{item?.account_name}</span>
                      </div>
                      {/* <div className='like-comment-wrapper scroll-bar-custom-horizontal'>
                    <ReportIcon
                      className='cls-icon share-icon'
                      onClick={() =>
                        navigate(`${newPathUser}post-report`, { state: { post_id: item.id } })
                      }
                    />
                  </div> */}
                      <div
                        style={{
                          position: 'absolute',
                          background: '#fafafa',
                          width: '100%',
                          borderBottomLeftRadius: '8px',
                          borderBottomRightRadius: '8px',
                        }}>
                        <div
                          className='content-post'
                          onClick={() => {
                            handleNavigate(item?.type, item?.id);
                          }}>
                          {item?.type === 2 ? item?.title ?? <br /> : item?.content ?? <br />}
                        </div>
                        <div className='action-post'>
                          <div className='time-post'>
                            {convertToCurrentTime(item.created_at)}
                          </div>
                          <div
                            className={`button-wrapper ${
                              checkDisabledAction(item) && 'disabled'
                            }`}>
                            <div className='like d-flex align-items-center'>
                              {item?.is_liked === 1 ? (
                                <>
                                  {/* đã like */}
                                  <HeartActiveIcon
                                    className={`like-icon active`}
                                    style={{ color: '#ff0000' }}
                                    onClick={() =>
                                      !checkDisabledAction(item) && handleLikePost(item)
                                    }
                                    disabled={checkDisabledAction(item)}
                                  />
                                </>
                              ) : (
                                <>
                                  {/* chưa like */}
                                  <HeartIcon
                                    className={`like-icon}`}
                                    onClick={() =>
                                      !checkDisabledAction(item) && handleLikePost(item)
                                    }
                                    disabled={checkDisabledAction(item)}
                                  />
                                </>
                              )}
                              <span style={{ marginLeft: 5.5, whiteSpace: 'nowrap' }}>
                                {formatNumber(item.number_like) || 0}
                              </span>
                            </div>
                            <div className='comment d-flex align-items-center'>
                              <ChatIcon
                                onClick={() => {
                                  handleNavigate(item?.type, item?.id);
                                }}
                              />
                              <span style={{ marginLeft: 5.5, whiteSpace: 'nowrap' }}>
                                {formatNumber(item.number_comment) || 0}
                              </span>
                            </div>

                            <ShareIcon
                              className={`share-icon `}
                              onClick={() => {
                                if (item?.type === 2) {
                                  !checkDisabledAction(item) &&
                                    handleSharePost({
                                      link: `${getEnv(
                                        'REACT_APP_FAN_URL'
                                      )}${newPathUser}post-sale/${item?.id}`,
                                      title: item.title,
                                    });
                                } else {
                                  !checkDisabledAction(item) &&
                                    handleSharePost({
                                      link: `${getEnv(
                                        'REACT_APP_FAN_URL'
                                      )}${newPathUser}post/${item?.id}`,
                                      title: item.title,
                                    });
                                }
                              }}
                              disabled={checkDisabledAction(item)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : !isLoading && (
                    <span className='no-data'>
                      <NoBookmarkList />
                      {t('Favorite.listNull')}
                    </span>
                  )}
            </div>
          </InfiniteLoad>
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default ViewOnTimeLine;
