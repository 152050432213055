import Icon from '@ant-design/icons';

const PlusLiveIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={20}
        height={20}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M9.99839 6.93945V13.0448'
          stroke='#787880'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M13.0545 9.99215H6.94336'
          stroke='#787880'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.9041 1.6665H6.09459C3.37236 1.6665 1.66602 3.59324 1.66602 6.3208V13.6789C1.66602 16.4064 3.36443 18.3332 6.09459 18.3332H13.9041C16.6343 18.3332 18.3327 16.4064 18.3327 13.6789V6.3208C18.3327 3.59324 16.6343 1.6665 13.9041 1.6665Z'
          stroke='#787880'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default PlusLiveIcon;
