import { HeaderListIdolV2 } from 'components';
import FooterListIdolV2 from 'components/FooterV2/FooterListIdolV2';
import PopupSignUpConfirmTwitter from 'components/Popup/signupConfirmTwitter';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ENV, STORAGE } from 'utils';
import platform from 'platform';
import { signInTwitter } from 'store/actions/twitter';
import Storage from 'utils/storage.js';
import './signInWrapper.scss';
import { ButtonWrapper } from './style';

const SignUpConfirm = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const API = {
    getProfile: () => `${ENV}/profile`,
  };
  const checkCaseLoginTwitter = async (data) => {
    if (data?.access_token) {
      Storage.set(STORAGE.USER_ACCESS_TOKEN, data.access_token);
      const rs = await fetch(API.getProfile(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${data.access_token}`,
        },
      });
      rs.json()
        .then((data) => {
          const dataProfile = data.data;
          if (dataProfile) {
            window.location.href = "/timeline"
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const linkAccount = () => {
    const token = Storage.get(STORAGE.TOKEN_TWITTER);
    const secret = Storage.get(STORAGE.SERECT_TOKEN_TWITTER);
    dispatch(
      signInTwitter(
        { token, secret, device_os: platform.description, link_flag: 1 },
        async ({ data }) => {
          checkCaseLoginTwitter(data)
        },
        () => {}
      )
    )
  }

  return (
    <>
      <div className='block-div-signin' style={{ backgroundColor: 'transparent'}}>
        <HeaderListIdolV2 />
        <div className='container-div' style={{ padding: '108px 0'}}>
          <PopupSignUpConfirmTwitter>
            <ButtonWrapper>
              <div
                className={`btn go-login w-100`}
                onClick={linkAccount}
                style={{ cursor: 'pointer' }}>
                {t('Common.buttonYes')}
              </div>
              <div
                className={`btn cancel w-100`}
                onClick={() => {
                  navigate('/sign-up')
                }}
                style={{ cursor: 'pointer' }}>
                {t('Common.buttonNo')}
              </div>
            </ButtonWrapper>
          </PopupSignUpConfirmTwitter>
        </div>
        <FooterListIdolV2 />
      </div>
    </>
  );
};

export default SignUpConfirm;
