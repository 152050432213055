import Footer from 'components/Footer';
import BackHeader from 'pages/creator/layout/back-header';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserInfo } from 'store/actions/users';
import { LOGO_COCO_FANS } from 'utils/constant';
import Storage from 'utils/storage';
import { ImgCustom } from '..';
import { STORAGE } from '../../utils/constant';
import AccountSidebar from '../Account/AccountSidebar';
import MenuSidebarCustom from '../MenuSidebarCustom';
import './Layout.scss';

const LayoutAffiliator = ({ children, titlePage = '' }) => {
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const navigate = useNavigate();
  const widthScreen = window.innerWidth;
  const user = useSelector((state) => state.users.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserInfo());
  }, [dispatch]);

  useEffect(() => {
    if (user?.is_affiliate === 1 || Storage.get(STORAGE.IS_AFFILIATE)) {
      Storage.set(STORAGE.IS_AFFILIATE, true);
    } else {
      navigate('/*');
    }
  }, [user]);

  useEffect(() => {
    if (widthScreen <= 991) {
      setIsMobileScreen(true);
    }
  }, [widthScreen]);

  return (
    <>
      {user && (
        <>
          <div className='wrapper__header-affiliator'>
            <div className='box-fansite-affiliator header-affiliator'>
              <div className={`fansite-header-affiliator`}>
                <div className='content-left'>
                  <ImgCustom
                    src={LOGO_COCO_FANS}
                    height={36}
                    width={106}
                    alt='logo'
                    className='logo-cocofans'
                  />
                </div>
                <div className='content-right'>
                  <BackHeader className='affiliator-back-header' label={titlePage} />
                </div>
              </div>
            </div>
          </div>
          <div id='' className='d-flex w-100 affiliator-wrapper'>
            <div
              className={`layout-with-menu-sidebar justify-content-center flex-nowrap affiliator`}>
              {!isMobileScreen && (
                <div className='layoutSider-wrapper'>
                  <div className={`layoutSider affiliator `}>
                    <AccountSidebar user={user} />
                    <MenuSidebarCustom />
                  </div>
                </div>
              )}
              <div className='layout-content layout-content-affiliator layout-desktop'>
                {children}
                <div className='footer footer-affiliator'>
                  <Footer isClass={true} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default LayoutAffiliator;
