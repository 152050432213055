export default function EmptyMessageIconV2(props) {
    return (
      <svg
        width='98'
        height='100'
        viewBox='0 0 98 100'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M84.8797 30.375H58.7015V11.7016C58.7015 8.65625 57.5172 5.80625 55.375 3.68594C53.2297 1.54062 50.3828 0.359375 47.3578 0.359375H11.3578C5.1031 0.359375 0.0140381 5.44844 0.0140381 11.7031V35.7031C0.0140381 41.9578 5.1031 47.0469 11.3578 47.0469H12.014V53.7031C12.014 55.8938 13.3219 57.8391 15.3437 58.6578C15.9953 58.9203 16.6719 59.0484 17.3422 59.0484C18.7422 59.0484 20.1062 58.4938 21.1047 57.4578L28.7265 49.8781V72.1797C28.7265 79.4063 34.6062 85.2859 41.8328 85.2859H60.8812L73.5109 97.8469C74.6469 99.0188 76.1703 99.6422 77.7281 99.6422C78.4719 99.6422 79.2218 99.5 79.9406 99.2094C82.1859 98.3016 83.6344 96.1391 83.6344 93.7047V85.2859H84.8781C92.1047 85.2859 97.9844 79.4063 97.9844 72.1797V43.4813C97.9859 36.2547 92.1062 30.375 84.8797 30.375ZM28.7375 43.2578L17.7672 54.1656C17.5765 54.3641 17.3531 54.4156 17.1015 54.3109C16.9187 54.2375 16.7015 54.0719 16.7015 53.7016V44.7016C16.7015 43.4063 15.6531 42.3578 14.3578 42.3578H11.3578C7.68748 42.3578 4.70154 39.3719 4.70154 35.7016V11.7016C4.70154 8.03125 7.68748 5.04531 11.3578 5.04531H47.3578C49.1297 5.04531 50.8 5.73906 52.0687 7.00781C53.3234 8.25 54.014 9.91719 54.014 11.7016V30.375H41.8328C38.339 30.375 35.05 31.7406 32.5703 34.2188C30.1453 36.6438 28.7953 39.8469 28.7375 43.2578ZM93.2984 72.1781C93.2984 76.8203 89.5219 80.5969 84.8797 80.5969H81.2922C79.9984 80.5969 78.9484 81.6469 78.9484 82.9406V93.7031C78.9484 94.4703 78.4125 94.7719 78.1828 94.8641C77.9594 94.9531 77.3844 95.1063 76.8484 94.5531L63.5031 81.2797C63.064 80.8438 62.4703 80.5969 61.85 80.5969H41.8328C37.1906 80.5969 33.414 76.8203 33.414 72.1781V43.4813C33.414 41.2375 34.2922 39.1266 35.8844 37.5344C37.4781 35.9406 39.5906 35.0625 41.8328 35.0625H84.8797C89.5219 35.0625 93.2984 38.8391 93.2984 43.4813V72.1781Z'
          fill='#FCECEF'
        />
        <path
          d='M48.8469 54.4062C46.9406 54.4062 45.389 55.9594 45.389 57.8641C45.389 59.7688 46.9422 61.3219 48.8469 61.3219C50.7547 61.3219 52.3078 59.7688 52.3078 57.8641C52.3078 55.9594 50.7547 54.4062 48.8469 54.4062Z'
          fill='#FCECEF'
        />
        <path
          d='M63.3547 54.4062C61.4484 54.4062 59.8969 55.9594 59.8969 57.8641C59.8969 59.7688 61.45 61.3219 63.3547 61.3219C65.2625 61.3219 66.8156 59.7688 66.8156 57.8641C66.8156 55.9594 65.264 54.4062 63.3547 54.4062Z'
          fill='#FCECEF'
        />
        <path
          d='M77.8641 54.4062C75.9578 54.4062 74.4062 55.9594 74.4062 57.8641C74.4062 59.7688 75.9594 61.3219 77.8641 61.3219C79.7719 61.3219 81.325 59.7688 81.325 57.8641C81.325 55.9594 79.7719 54.4062 77.8641 54.4062Z'
          fill='#FCECEF'
        />
      </svg>
    );
  }
  