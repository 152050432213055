import { PopupNgSetting } from 'components';
import { NumberDonate } from 'components/LiveStream/NumberDonate';
import { NumberViews } from 'components/LiveStream/NumberViews';
import { DollarIcon, ProfileIcon, LiveRankingIcon } from 'images';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ListContentChat } from './ListContentChat';
import { propsStyled } from './props';
import { Block, Button, HoverTooltip, Text, WrapperBlock, WrapperButton } from './styled';
import lodash from 'lodash';
import { useState } from 'react';
import { useEffect } from 'react';

export const GroupButton = ({
  setIsVisibleFollowPopup,
  setPageTopDonate,
  setIsVisibleCoinPopup,
  setIsVisibleDeliverySettingPopup,
  setIsVisibleDeliveryChangePopup,
  listNgSetting,
  denyCharacter,
  setListNgSetting,
  timePast,
  timeRemaining,
  checkHorizontalScreen,
  setIsResetChange,
}) => {
  let { dataLiveStream, loadingCreate } = useSelector((state) => state.lives);
  const { id, liveKey } = useParams();
  const { t, i18n } = useTranslation();
  let listSetting = null;
  let ngWords_user = null;
  let listPackages = null;
  let listUsers = null;

  const [showMsg, setShowMsg] = useState([]);

  if (dataLiveStream !== null) {
    if (dataLiveStream?.ngWords?.length > 0) {
      listSetting = dataLiveStream?.ngWords[0]?.text?.split(',');
      // eslint-disable-next-line no-unused-vars
      listSetting = lodash.without(listSetting, undefined, null, '', ' ');
    }
    if (dataLiveStream?.ngWords_user?.length > 0) {
      ngWords_user = dataLiveStream?.ngWords_user[0]?.text?.split(',');
      // eslint-disable-next-line no-unused-vars
      ngWords_user = lodash.without(ngWords_user, undefined, null, '', ' ');
    }
    listPackages = dataLiveStream?.packages?.filter((pack) => pack.is_checked === 1);
    listUsers = dataLiveStream?.user_live;
  }
  useEffect(() => {
    if (dataLiveStream !== null) {
      let msg1, msg2, msg3, msg4;
      if (dataLiveStream?.all_follow === 1) {
        msg1 = ` ${t('Creator.ReviewLiveStream.follower')} `;
      }
      if (dataLiveStream?.all_member === 1) {
        msg2 = ` ${t('Creator.ReviewLiveStream.everyone')} `;
      }

      if (listPackages !== null && listPackages !== undefined && listPackages.length > 0) {
        msg3 = ` ${t('Common.subscription')} `;
      }

      if (listUsers !== null && listPackages !== undefined && listUsers.length > 0) {
        msg4 = ` ${t('Creator.ReviewLiveStream.anyIndividual')} `;
      }

      const val = lodash.union([msg1, msg2, msg3, msg4]);
      setShowMsg(val);
    }
  }, [dataLiveStream, i18n.language]);
  const checkShowMess = showMsg.filter((pack) => pack !== undefined);
  return (
    <>
      <WrapperButton>
        <WrapperBlock>
          <Block display='flex' gap='5px' totip>
            <Text fw='4' fs='2' cl='7' ovftext>
              {t('Common.buttonDeliveryDestination')}：
              {showMsg.length > 0
                ? showMsg.filter((pack) => pack !== undefined).toString()
                : ''}
              {dataLiveStream?.user_distributions?.length
                ? `${Boolean(checkShowMess.length) ? ',' : ''} ${t(
                    'Common.buttonDistributionList'
                  )}`
                : ''}
              {dataLiveStream?.user_donations?.length
                ? `${
                    Boolean(checkShowMess.length) || dataLiveStream?.user_distributions?.length
                      ? ','
                      : ''
                  } ${t('Common.buttonPayerList')}`
                : ''}
            </Text>
            <HoverTooltip style={{ top: '26px' }}>
              {showMsg.length > 0
                ? showMsg.filter((pack) => pack !== undefined).toString()
                : ''}
              {dataLiveStream?.user_distributions?.length
                ? `${Boolean(checkShowMess.length) ? ',' : ''} ${t(
                    'Common.buttonDistributionList'
                  )}`
                : ''}
              {dataLiveStream?.user_donations?.length
                ? `${
                    Boolean(checkShowMess.length) || dataLiveStream?.user_distributions?.length
                      ? ','
                      : ''
                  } ${t('Common.buttonPayerList')}`
                : ''}
            </HoverTooltip>
          </Block>
          <Block display='flex' gap='5px' mg='6px 0 4px 0'>
            <Text fw='1' fs='1' cl='7' bdr='1' pdr='5px'>
              {t('Creator.Livestream.elapsedTime')} {timePast ? `${timePast}` : '00:00'}
            </Text>
            <Text fw='1' fs='1' cl='7'>
              {t('Creator.Livestream.timeLeft')} {timeRemaining ? `${timeRemaining}` : '00:00'}
            </Text>
          </Block>
          <Block display='flex' algc gap='8px' mg='0px 0 8px 0px'>
            <Button
              style={{
                marginRight: '-6px',
                marginLeft: '-6px',
                background: 'transparent',
              }}
              bd='none'
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setPageTopDonate(1);
                setIsVisibleCoinPopup(true);
                document.body.style.overflow = 'hidden';
              }}>
              <LiveRankingIcon />
            </Button>
            <Button
              bdc='1'
              bgc='6'
              bdr='4px'
              bd='1'
              cl='8'
              mw='76px'
              w='76px'
              maw='80px'
              h='26px'
              dfc
              onClick={() => {
                setIsVisibleFollowPopup(true);
                document.body.style.overflow = 'hidden';
              }}>
              <ProfileIcon className='profile-icon' />
              <Text className='number' fw='2'>
                <NumberViews id={liveKey} />
              </Text>
            </Button>
            <Block dfc gap='5px'>
              <DollarIcon fill={propsStyled.color[2]} size='16.67' />
              <Text cl='9' fw='5' fs='4' trl='0 -1px'>
                <NumberDonate isDonateC092 id={liveKey} />
              </Text>
            </Block>
          </Block>
          <Block display='flex' gap='8px' style={{ marginTop: '-1px' }}>
            <Block totip>
              <Button
                bgc='10'
                cl='1'
                bdr='6px'
                fw='4'
                fs='1'
                bd='none'
                w='86px'
                mw='120px'
                ovftext
                onClick={() => {
                  if (!loadingCreate) {
                    document.body.style.overflow = 'hidden';
                    setIsVisibleDeliverySettingPopup(true);
                    setIsResetChange((prev) => prev + 1);
                  }
                }}>
                {t('Popup.changeDelivery')}
              </Button>
              <HoverTooltip> {t('Popup.changeDelivery')}</HoverTooltip>
            </Block>
            <Block totip>
              <Button
                bgc='10'
                cl='1'
                bdr='6px'
                fw='4'
                fs='1'
                bd='none'
                w='70px'
                mw='110px'
                ovftext
                // disabled={dataLiveStream?.obs_flag === 1}
                onClick={() => {
                  if (!loadingCreate) {
                    document.body.style.overflow = 'hidden';
                    setIsVisibleDeliveryChangePopup(true);
                  }
                }}>
                {t('Popup.deliveryExtension')}
              </Button>
              <HoverTooltip> {t('Popup.deliveryExtension')}</HoverTooltip>
            </Block>
            <PopupNgSetting
              showScreen='C092'
              liveKey={liveKey}
              id={id}
              dataSetting={listNgSetting}
              handleAddNgSetting={(e) => setListNgSetting(e)}
              isShowButtonCancel
              newStyle={{
                color: '#FFFFFF',
                backgroundColor: '#666565',
                borderRadius: '6px',
                fontWeight: '700',
                fontSize: '12px',
                width: `60px `,
              }}
            />
          </Block>
        </WrapperBlock>
        <Block style={{ height: 'calc(100% - 152px)' }}>
          <ListContentChat denyCharacter={denyCharacter} liveKey={liveKey} checkHorizontalScreen={checkHorizontalScreen} />
        </Block>
      </WrapperButton>
    </>
  );
};
