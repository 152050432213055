import useLockBodyScroll from 'hooks/useLockBodyScroll';
import useUserRole from 'hooks/useUserRole';
import HeaderListIdolV2  from 'components/HeaderV2/HeaderListIdolV2';
import { useTranslation } from 'react-i18next';
import SuccessRegister from 'images/SuccessRegister';

const PopupRegisterSuccessFan = ({ children }) => {
  const { t } = useTranslation();
  useLockBodyScroll();
  const { isIdol } = useUserRole();
  return (
    <>
      <HeaderListIdolV2/>
      <div className='popup register-success-fan' style={{ backgroundColor: "transparent"}}>
        <div className='popup-content'>
          <div className='content'>
            <SuccessRegister />
            <h3 className='mt-4 text-center'>{t('Popup.titleConfirmEmail')}</h3>
            <div className='text success-register-text mt-3'>
              {t('Popup.verificationEmail')}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupRegisterSuccessFan;
