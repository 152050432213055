import { CloseIcon } from 'images';
import { CreateAndUpdateCard } from './card-management/createAndUpdateCard';
import './popupCreateAndUpdate.scss';

export const PopupCreateAndUpdate = ({
  title,
  setIsShowPopupCreateAndUpdateCard,
  setIsShowPopupListCard,
  resetAll,
}) => {
  return (
    <div className='popup popup-create-card'>
      <div className='popup-content create-card'>
        <>
          <div className='title'>{title}</div>
          <CloseIcon
            className='close-icon'
            onClick={() => {
              resetAll(true);
              setIsShowPopupCreateAndUpdateCard(false);
            }}
          />
        </>
        <div className='content'>
          <div className='content-wrapper'>
            <CreateAndUpdateCard
              setIsShowPopupListCard={setIsShowPopupListCard}
              setIsShowPopupCreateAndUpdateCard={setIsShowPopupCreateAndUpdateCard}
            />
          </div>
        </div>

        <div className='button-actions-relative'></div>
      </div>
    </div>
  );
};
