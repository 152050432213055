 const returnSizeStamps = ({host, isDevice}) => {
    if (host) {
      if (isDevice) {
        return 126;
      } else {
        return 115;
      }
    } else {
      if (isDevice) {
        return 126;
      } else {
        return 220;
      }
    }
  };

  export {
    returnSizeStamps
  }