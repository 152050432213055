import useLockBodyScroll from 'hooks/useLockBodyScroll';

const MessageUpdateEmail = ({ text, children, className }) => {
  useLockBodyScroll();
  return (
    <div className={`popup confirm-fan ${className}`}>
      <div className='popup-content'>
        <div className='content'>
          <div className='d-flex flex-column'>
            <span className='text'>{text}</span>
          </div>
        </div>
        <div className='box-button'>{children}</div>
      </div>
    </div>
  );
};

export default MessageUpdateEmail;
