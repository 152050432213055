import { PlusOutlined } from '@ant-design/icons';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { newPathUser } from 'constants/layout/constant';
import NoCardsIcon from 'images/NoCardsIcon';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { CardItem } from './cardItem';
import './step1ListCard.scss';
import { useDispatch, useSelector } from 'react-redux';
import { clearScrollIdAndDataVendo, setIdScrollBackCardVendo } from 'store/actions/card';

const Step1ListCard = ({
  isLoading,
  fetchNext,
  handleChangeDefaultCard,
  isHaveDataCard,
  cards,
  setStep,
  dataVendo,
  idCheckActive,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { idCardActive } = useSelector((state) => state.card);
  const dispatch = useDispatch();

  const renderButtonAdd = () => {
    return (
      <div className='button-add-card'>
        <button
          onClick={() => {
            dispatch(setIdScrollBackCardVendo(`card-create`, () => {}));
            navigate(`${newPathUser}payment/vendo/create/card`, {
              state: {
                idActive: idCardActive,
                dataVendo,
              },
            });
          }}
          className='btn btn-outline-danger  d-flex align-items-center'>
          <PlusOutlined />
          <span className='ms-2'>{t('Creator.cardManagement.createNew')}</span>
        </button>
      </div>
    );
  };

  const renderNoData = () => {
    return (
      !isLoading && (
        <div className='no-data'>
          <div>
            <NoCardsIcon />
          </div>
          <div className='text'>{t('Creator.cardManagement.noData')}</div>
          {renderButtonAdd()}
        </div>
      )
    );
  };

  return (
    <div className='card-payment-layout'>
      <div className='card-payment-container'>
        <>
          {isHaveDataCard ? (
            <>
              {renderButtonAdd()}
              <InfiniteLoad loading={isLoading} data={cards || []} fetchNext={fetchNext}>
                <div className='list-card'>
                  {cards?.map((item) => (
                    <CardItem
                      key={item?.id}
                      item={item}
                      idCheckActive={idCheckActive}
                      handleChangeDefaultCard={handleChangeDefaultCard}
                    />
                  ))}
                </div>
              </InfiniteLoad>
            </>
          ) : (
            renderNoData()
          )}
        </>
      </div>
      {!!cards?.length && (
        <div className='button-actions-relative'>
          <div className='box-button'>
            <button
              disabled={!cards?.length}
              onClick={() => {
                setStep(2);
              }}
              className='btn btn-submit'>
              {t('Common.continue')}
            </button>
            <button
              onClick={() => {
                navigate(`${newPathUser}purchase`, {
                  state: {
                    point: dataVendo?.package_payment_id ? null : dataVendo?.point,
                  },
                });
              }}
              className='btn btn-cancel'>
              {t('Common.return')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Step1ListCard;
