import { AccountSidebar, Header, PopupLoading } from 'components';
import useRedirect from 'hooks/useRedirect';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from 'store/actions/users';
import FooterTabsFan from '../FooterTabs/fan';
import MenuSidebarCustom from '../MenuSidebarCustom';
import SidebarRecommendPost from '../SidebarRecommendPost';
import './Layout.scss';
import './LayoutFan.scss';

const LayoutFan = ({
  children,
  className,
  titleHeader,
  isAdmin = false,
  navigateCreator = false,
  accountId = '',
  avatarHeader,
  page = '',
  loading = false,
  loadingReload = false,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.data);
  const error = useSelector((state) => state.handleError.error);

  const loadPrifle = useSelector((state) => state.users);

  const layoutRightRef = useRef();
  useRedirect(error);
  useEffect(() => {
    if (!loadPrifle?.isLoadProfile) {
      dispatch(getUserInfo());
    }
  }, []);
  const layoutLeftRef = useRef();
  return (
    <>
      {loading && (
        <PopupLoading
          className={`popup-loading ${loadingReload ? 'loading-reload-page' : ''}`}
        />
      )}
      <div className={`layout-fan ${className}`}>
        <Header
          titleHeader={titleHeader}
          avatarHeader={avatarHeader}
          isAdmin={isAdmin}
          navigateCreator={navigateCreator}
          accountId={accountId}
          page={page}
        />
        <div className={`layout-with-menu-sidebar justify-content-end fan`}>
          <div className='layoutSider-wrapper  d-lg-block d-none'>
            <div className='layoutSider'>
              <AccountSidebar user={user} />
              <MenuSidebarCustom />
            </div>
          </div>
          <div className='layout-content'>
            <div className={`layout-left ${page}`} ref={layoutLeftRef}>
              <div className='wrapper-children'>{children}</div>
              <FooterTabsFan />
            </div>
            <div className='layout-right' ref={layoutRightRef}>
              <SidebarRecommendPost
                style={{ width: `${layoutRightRef?.current?.offsetWidth}px` }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LayoutFan;
