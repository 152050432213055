import Icon from '@ant-design/icons';

const ExitLiveIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={44}
        height={44}
        viewBox='0 0 44 44'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx={22} cy={22} r='21.5' fill='#DC143C' stroke='#EEEEEE' />
        <path
          d='M17.6875 18.0625L13.751 22L17.6875 25.9375'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M24.25 22L13.7528 22'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M24.25 13.75L29.5 13.75C29.6989 13.75 29.8897 13.829 30.0303 13.9697C30.171 14.1103 30.25 14.3011 30.25 14.5L30.25 29.5C30.25 29.6989 30.171 29.8897 30.0303 30.0303C29.8897 30.171 29.6989 30.25 29.5 30.25L24.25 30.25'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default ExitLiveIcon;
