import { Loading } from 'components/LiveStream/Loading';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { CURRENT_USER_KEY } from 'constants/shared';
import Storage from 'utils/storage';

import {
  infoUserAndCheckFollow,
  // startLoading,
  // updateConfig
} from 'store/actions/lives';
import LoadingIconV2 from 'images/LoadingIconV2';
//import { getUserInfo } from 'store/actions/users';

export const WrapperLives = (props) => {
  const dispatch = useDispatch();

  const stateUserLoading = useSelector((state) => state.lives.useInfoLoading);
  // const stateUserInfo = useSelector((state) => state.lives.userInfo);
  // const stateLive = useSelector((state) => state.lives);

  const { liveKey } = useParams();

  useEffect(() => {
    const user = Storage.get(CURRENT_USER_KEY);
    dispatch(infoUserAndCheckFollow(liveKey));
    if (!user) {
      // dispatch(getUserInfo());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //* Dispatch Set Channel
  // useMemo(() => {

  //  // dispatch(startLoading());
  //  // dispatch(updateConfig({ channelName: liveKey, host: false }));
  // }, [liveKey]);
  //* End Dispatch Set Channel

  //  console.log("stateLive: ", stateLive);
  return (
    <>
      {/* {stateUserLoading ? (
        <div
        style={{
          width: '100%',
          height: '100%',
          minHeight: '100%',
          backgroundColor: '#000000',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Loading />
      </div>
    ) : props.children } */}
      {props.children}

      {/* {(stateLive.config?.channelName !== '') ? props.children : <Loading />} */}
    </>
  );
};
