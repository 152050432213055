import { PlusOutlined } from '@ant-design/icons';
import './index.scss';

export default function ThumbnailButton({ label, variant, ...props }) {
  return (
    <button className={`thumbnail-btn`} {...props}>
      <PlusOutlined />
      &nbsp;
      {label}
    </button>
  );
}
