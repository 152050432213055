// import React from 'react';
import styled, { keyframes } from 'styled-components';
import EmojiWrapper from './EmojiWrapper';


// const floatAnimation = (one, two) => keyframes`
//   0% { opacity : 0; bottom: 0%; transform : translateX(0)}
//   50% { opacity : 1; transform: translateX(${one}px)}
//   100% {opacity: 0; bottom: 50%; transform: translateX(${two}px)}
// `;


// const EmojiBubble = styled(EmojiWrapper)`
//   position: absolute;
//   user-select: none;
//   bottom: 0;
//   animation: ${({ one, two }) => floatAnimation(one, two)}
//   ${({ size }) => (size < 3 ? 1 : 1)}s ease-in-out forwards; 
// `;


const floatAnimation = (one, two) => keyframes`
0% { opacity : 0; bottom: 14%; transform : translateX(0)}
50% { opacity : 1; transform: translateX(${one}px)}
100% {opacity : 0; bottom: 150%; transform: translateX(${two}px)}
`;

const EmojiBubble = styled(EmojiWrapper)`
  position: absolute;
  user-select: none;
  bottom: 0;
  left: ${({ left }) => (left ? left : 15)}%;
  font-size: ${({ size }) => (size ? size : 2)}rem;
  animation: ${({ one, two }) => floatAnimation(one, two)}
    ${({ size }) => (size < 3 ? 1.7 : 1.7)}s ease-in-out forwards;
`;



export default EmojiBubble;

