import React from 'react'

export const TestShare = () => {
    const shareData = {
        title: 'Test-Share-Mobile',
        text: 'Fansite',
        url: 'https://dantri.com.vn'
    }
  return (
    <div>
        <h5> Button Share - Mobile</h5>
        <button onClick={async (event) => {
              event.preventDefault();
             try {
                await navigator.share(shareData)
                alert('shared successfully');
              } catch(err) {
                 // alert('Error: ' + err);
              } 

        }}> Share-Mobile</button>
    </div>
  )
}
