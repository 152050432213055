import React, { useCallback, useEffect, useMemo, useRef, useState /* useRef */ } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { getEnv } from 'configs/env';

import { useDispatch, useSelector } from 'react-redux';
import {
  fanBuyPackages,
  updateChangeStatusEndLive,
  followUnFollowUserByType,
  updateTypeLiveStream,
} from 'store/actions/lives';

import { isMobile } from 'react-device-detect';

import { onValue, ref, query, orderByChild, equalTo } from 'firebase/database';
import { DBRealTime } from '../../../../firebase/config';
import { serverTimestamp } from 'firebase/database';

import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { ImgCustom, PopupConfirmFan } from 'components';

import './VideoPlayer.scss';

import Camera from 'images/Camera.png';
import CameraDisabled from 'images/CameraDisabled.png';
import MicroPhone from 'images/MicroPhone.png';
import MicroPhoneDisabled from 'images/MicroPhoneDisabled.png';

import { PopupDenyFanView } from 'components/Popup/fanVewLives';
import PopupFanBuyPackage from 'components/Popup/PopupFanBuyPackage';

//import { formatCoin } from 'utils/utils';
import { deleteData, getOne, realTimeLives, updateData } from 'hooks/useRealTimeDB';

import platform from 'platform';
import useDevice from 'hooks/useDevice';
import CurrencyFormat from 'react-currency-format';
import { messageValidate } from 'utils/messageValidate';
import { decryptPath, formatCoin } from 'utils/utils';
import { QueueList } from 'utils/queueList';
import { getPresignedUrlS3, setLinkSaveDB, uploadS3ByPresigned } from 'utils/upload';
import { useTranslation } from 'react-i18next';
import { AutoExpireStampsView } from './ManageAgoraOBSPlayer/AutoExpireStamps';
import FullScreenIOS, { isIOSSafari } from './FullscreenIOS';
import styles from './ios.module.css';
import { useNavigate } from 'react-router-dom';
import { newPathUser } from 'constants/layout/constant';
import OutLiveIcon from 'images/livestreams/OutLiveIcon';
import MaxScreenLiveIcon from 'images/livestreams/MaxScreenLiveIcon';
import MicroPhoneIcon from 'images/livestreams/MicroPhoneIcon';
import MicroPhoneDisableIcon from 'images/livestreams/MicroPhoneIconDisable';
import CameraDisableIcon from 'images/livestreams/CameraDisableIcon';
import CameraIcon from 'images/livestreams/CameraIcon';
import CopyLinkIcon from 'images/livestreams/CopyLinkIcon';
import UpThumbnailDisableIcon from 'images/livestreams/UpThumbnailIDisablecon';
import UpThumbnailIcon from 'images/livestreams/UpThumbnailIcon';
import ExitFullScreenIcon from 'images/livestreams/ExitFullScreenIcon';
import { LockIcon } from 'images';
import { pushNotify } from 'components/Notification';
import { BlueImgLive } from './styled';
import { usePromptV2 } from 'hooks/v2/usePromtV2';
import { usePopupPaymentSub } from 'hooks/v2/usePopupPaymentSub';
import PopupBlockUserNoLogin from 'components/Popup/blockUserNoLogin';

const appID = getEnv('REACT_APP_AGORA_APP_ID');
const client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
const baseUrl = 'https://api.agora.io/v1/apps';
export const AgoraOBSPlayer = (props) => {
  const {
    id,
    liveKey,
    type,
    host,
    view,
    showButton,
    allowView,
    messageAllow,
    checkHorizontalScreen,
    allowComment,
    creatorIsFollow,
    remainPoint, //* SO POINTS DANG CO CUA FANS
    isAuth,
    token = null,
    ViewAgora = null,
    ViewAgoraFan = null,
    showAlert = false,
    checkShowAlert,
    setCheckShowAlert = () => {},
  } = props;
  let { videoConfig } = props;
  if (videoConfig === undefined) {
    videoConfig = {
      width: 640,
      height: 480,
    };
  }
  const navigate = useNavigate();
  const localTracks = {
    videoTrack: null,
    audioTrack: null,
  };
  // Agora client options
  const options = {
    appid: appID,
    channel: liveKey,
    uid: null,
    token,
    role: host ? 'host' : 'audience', // host or audience
    audienceLatency: 1,
  };
  const [local, setLocal] = useState({
    videoTrack: null,
    audioTrack: null,
  });

  const setMuteAudioWatch = useCallback(
    async (value = false) => {
      await local.audioTrack.setMuted(value);
    },
    [local.audioTrack]
  );

  const setMuteVideoWatch = useCallback(
    async (value = false) => {
      await local.videoTrack.setMuted(value);
    },
    [local.videoTrack]
  );

  const stateCtx = useSelector((state) => state.lives);
  const [uid, setUID] = useState(0);
  const [remoteUsers, setRemoteUsers] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const [muteVideo, setMuteVideo] = useState(false);
  const [muteAudio, setMuteAudio] = useState(false);

  const [popupConfirmFan, setPopupConfirmFan] = useState(false);

  const [autoPlayAudioFail, setAutoPlayAudioFail] = useState(null);
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const loadingChangeStatus = useSelector((state) => state.lives.loadingChangeStatus);

  const isCallAPIBuyPackage = useSelector((state) => state.lives.loading);

  const [fanUser, setFanUser] = useState({});

  const [checkBuyPackage, setCheckBuyPackage] = useState();

  // const { setIsOpen, setIdPackage, PopupPaymentBySub } = usePopupPaymentSub({
  //   urlRedirect: '',
  //   onClickSuccess: () => {
  //     return window.location.reload();
  //   },
  // });

  useEffect(() => {
    if (!host) {
      !ViewAgoraFan && handleRotateDevice();
      return !ViewAgoraFan && join();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (host) {
      !ViewAgora && join();
    }
    return () => {
      localTracks?.videoTrack && localTracks?.videoTrack.stop();
      localTracks?.audioTrack && localTracks?.audioTrack.stop();
      handleStopLive();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
  // usePrompt(t('LiveStream.V5.liveCancel'), showAlert && checkShowAlert, () => {
  //   handleStopLive();
  // });
  const isShowPromptV2 = useMemo(() => {
    return showAlert && checkShowAlert;
  }, [showAlert, checkShowAlert]);
  usePromptV2(t('LiveStream.V5.liveCancel'), isShowPromptV2, () => {
    handleStopLive();
  });
  const [isEnoughPoints, setIsEnoughPoints] = useState(null);
  const [isConfirmBuyPackage, setIsConfirmBuyPackage] = useState(null);

  //* NIEU USER DUOC XEM THI MOI TANG SO USER LEN
  useEffect(() => {
    if (!host && stateCtx?.userInfo !== null && stateCtx?.userInfo?.auth_id) {
      if (allowView) {
        updateUserViewLiveStream();
      } else {
        deleteData(`/lives/${liveKey}/${stateCtx?.userInfo?.auth_id}`);
      }
    }
    return  () => {
      if (stateCtx?.userInfo?.auth_id) {
        deleteData(`/lives/${liveKey}/${stateCtx?.userInfo?.auth_id}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCtx?.userInfo, allowView]);

  AgoraRTC.onAutoplayFailed = () => {
    if (autoPlayAudioFail) return;
    return setAutoPlayAudioFail(true);
  };
  const updateUserViewLiveStream = () => {
    if (stateCtx.userInfo !== null && isAuth === 1) {
      const userInfo = {
        id: stateCtx.userInfo.auth_id,
        avatar: stateCtx.userInfo.avatar,
        account_name: stateCtx.userInfo.account_name,
        comment: allowComment,
        creator_follow: creatorIsFollow,
      };
      realTimeLives(`/lives/${liveKey}/${userInfo.id}`, liveKey, userInfo);

      //* LOGS USER XEM LIVE
      const logsData = {
        id: userInfo?.id || 0,
        logs_at: serverTimestamp(),
      };
      updateData(`/logs/lives/${liveKey}/${userInfo.id}`, logsData);
      //* END LOGS USER XEM LIVE
    }
  };
  let configFrameLive = {
    width: 960,
    height: 720,
    frameRate: 30,
    bitrateMin: 500,
    bitrateMax: 1750,
  };
  useEffect(() => {
    if (isMobile) {
      configFrameLive.width = window.innerWidth;
      configFrameLive.height = window.innerHeight;
      // localTracks.videoTrack = AgoraRTC.createCameraVideoTrack({
      //   // encoderConfig: videoSize,
      //   encoderConfig: configFrameLive,
      //   optimizationMode:
      //     platform?.name === 'Chrome' || platform?.name === 'Chrome Mobile' ? 'motion' : '',
      // });
    } else {
      configFrameLive.width = 960;
      configFrameLive.height = 720;
    }
  }, [checkHorizontalScreen]);

  const join = async () => {
    setIsLoading(true);

    // New Config frame live
    if (isMobile) {
      configFrameLive.width = window.innerWidth;
      configFrameLive.height = window.innerHeight;
    } else {
      configFrameLive.width = 1280;
      configFrameLive.height = 720;
    }
    if (options.role === 'host') {
      await client.setClientRole('host');
      await client.join(
        options.appid,
        options.channel,
        options.token || null,
        options.uid || null
      );
      localTracks.videoTrack = await AgoraRTC.createCameraVideoTrack({
        // encoderConfig: videoSize,
        encoderConfig: '720_6',
        optimizationMode:
          platform?.name === 'Chrome' || platform?.name === 'Chrome Mobile' ? 'motion' : '',
      });

      localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      await localTracks.videoTrack.play('local-player', { fit: 'contain' });
      await client.publish(Object.values(localTracks));
      setLocal(localTracks);
    } else {
      client.setClientRole('audience', { level: 1 });
      !ViewAgoraFan &&
        (await client.join(
          options.appid,
          options.channel,
          options.token || null,
          options.uid || null
        ));
    }

    if (!ViewAgora) {
      client.on('user-published', handleUserPublished);
      client.on('user-unpublished', handleUserUnpublished);
    }
    client.on('user-left', async (_user, reason) => {
      if (reason === 'ServerTimeOut') {
        if (!host) {
          return window.location.reload();
        }
      }
    });

    setIsLoading(false);
  };

  const fanRef = useRef();
  const fanViewRef = useRef();

  const [isCameraOff, setIsCameraOff] = useState(false);

  const subscribe = async (user, mediaType) => {
    const uid = user.uid;
    setUID(uid);
    // subscribe to a remote user
    await client.subscribe(user, mediaType);

    setFanUser(user);
    let objectFit = 'contain';
    if (isMobile) {
      objectFit = 'unset';
    }
    if (mediaType === 'video') {
      if (fanViewRef.current) {
        !ViewAgoraFan && (fanViewRef.current.innerHTML = '');
      }
      if (allowView) {
        setIsCameraOff(false);

        user.videoTrack.play(`player-${uid}`, { fit: objectFit });
      }
    }
    if (mediaType === 'audio') {
      if (allowView) {
        user.audioTrack.play();
      } else {
        user.audioTrack.stop();
      }
    }

    // setUserTracks(user);
  };
  useEffect(() => {
    AgoraRTC.getCameras(true);
  }, []);
  const handleUserPublished = (user, mediaType) => {
    const id = user.uid;
    remoteUsers[id] = user;
    setRemoteUsers(remoteUsers);
    // if (fanViewRef.current) {
    //   fanViewRef.current.innerHTML = '';
    // }
    subscribe(user, mediaType);
    if (!user?.hasVideo) {
      setIsCameraOff(true);
    }
    setIsLoading(false);
  };

  const handleUserUnpublished = (user, mediaType) => {
    setShowStamps({ show: false, stampId: '', stampImg: '' });
    setDataStamps([]);
    if (mediaType === 'video') {
      setIsCameraOff(true);
      setIsLoading(true);
      const id = user.uid;
      delete remoteUsers[id];
      setRemoteUsers({});
    }
  };

  //* DETECT IF PACKAGE CHANGE
  useEffect(() => {
    if (!host) {
      if (messageAllow) {
        setIsConfirmBuyPackage(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageAllow]);

  //TODO : 16-4-2022 ACTION

  const toggleVideo = async () => {
    const newValue = !muteVideo;
    setMuteVideo(newValue);
    if (newValue) {
      await local.videoTrack.setMuted(true);
    } else {
      await local.videoTrack.setMuted(false);
    }
  };

  useEffect(() => {
    if (muteVideo) {
      setMuteAudioWatch(muteVideo);
    }
  }, [muteVideo, setMuteAudioWatch]);

  useEffect(() => {
    if (muteVideo) {
      setMuteVideoWatch(muteVideo);
    }
  }, [muteVideo, setMuteVideoWatch]);

  const toggleAudio = async () => {
    const newValue = !muteAudio;
    setMuteAudio(newValue);
    if (newValue) {
      await local.audioTrack.setMuted(true);
    } else {
      await local.audioTrack.setMuted(false);
    }
    setMuteAudio(newValue);
  };

  const confirmStopLive = () => {
    setPopupConfirmFan(!popupConfirmFan);
  };

  const handleStopLive = async (cb = () => {}) => {
    for (const trackName in local) {
      const track = local[trackName];
      if (track) {
        track.stop();
        track.close();
        await client.unpublish(track);
        // setAutoPlayAudioFail(false);
        local[trackName] = undefined;
        localTracks[trackName] = undefined;
      }
    }
    // cancel event confirm leave
    setCheckShowAlert(false);
    // remove remote users and player views
    setRemoteUsers({}); // remoteUsers = {};
    await client.leave();
    cb && cb();
  };

  const handleRotateDevice = async () => {
    for (const trackName in local) {
      const track = local[trackName];
      if (track) {
        track.stop();
        track.close();
        // setAutoPlayAudioFail(false);
        local[trackName] = undefined;
        localTracks[trackName] = undefined;
      }
    }
    // remove remote users and player views
    setRemoteUsers({}); // remoteUsers = {};
    await client.leave();
  };

  useEffect(() => {
    window.onpopstate = () => {
      handleStopLive();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //TODO: 16-4-2022 END ACTION

  const isDevice = useDevice();

  //TODO: 15-4-2022 HANDLE FULLSCREEN
  const handle = useFullScreenHandle();
  //TODO: END HANDLE FULLSCREEN

  const [showPopupFanWhenLiveEnd, setShowPopupFanWhenLiveEnd] = useState(false);

  useMemo(() => {
    //! HANDLE EVENT LIVE END ALL HOST IN LIVE OUT SCREEN
    onValue(
      ref(DBRealTime, `/lives/${liveKey}/config`),
      (snapshot) => {
        if (snapshot.val() !== null) {
          const statusLive = snapshot.val().status;
          if (statusLive && statusLive === 5) {
            if (host) {
              setCheckShowAlert(false);
              setTimeout(() => {
                return (window.location.href = `${newPathUser}live/list-livestream`);
              }, 1000);
            } else {
              setShowPopupFanWhenLiveEnd(true);
              setIsLoading(false);
              setTimeout(() => {
                return (window.location.href = `${newPathUser}lives/list/live`);
              }, 5000);
            }
          }
        } //! END if (snapshot.val() !== null)
      },
      {
        onlyOnce: false,
      }
    );
    //! END HANDLE EVENT LIVE END ALL HOST IN LIVE
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [copySuccess, setCopySuccess] = useState(false);

  // * LISTERN STAMPS
  const [stampsQueue, setStampsQueue] = useState([]);
  const [showStamps, setShowStamps] = useState({ show: false, stampId: '', stampImg: '' });
  const [dataStamps, setDataStamps] = useState([]);

  let q = new QueueList();

  useMemo(() => {
    const messageRef = query(
      ref(DBRealTime, `/stamps/${liveKey}`),
      orderByChild('status'),
      equalTo(1)
    );

    onValue(messageRef, (snapshotData) => {
      const newData = [];
      snapshotData.forEach((ss) => {
        newData.push({ ...ss.val(), key: ss.key });
      });
      if (newData.length > 0) {
        return setStampsQueue(newData);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (stampsQueue.length > 0) {
      stampsQueue.forEach((obj, _i) => {
        q.enqueue(obj);
      });
      let interval = null;
      if (!q.isEmpty) {
        let objPeek = q.peek();
        if (!dataStamps.length) {
          setShowStamps({
            show: true,
            stampId: objPeek?.stampGiff,
            stampImg: objPeek?.stampGiff,
          });
          setDataStamps([...dataStamps, objPeek]);
        }
        interval = setInterval(() => {
          let objDeque = q.dequeue();
          if (objDeque !== undefined) {
            setShowStamps({
              show: true,
              stampId: objDeque?.stampGiff,
              stampImg: objDeque?.stampGiff,
            });
            setDataStamps([...dataStamps, objDeque]);

            updateData(`/stamps/${liveKey}/${objDeque?.key}`, { status: 0 }).then(() => {});
          } else {
            // setShowStamps({show: false, stampId: '', stampImg: '' });
            // setDataStamps([]);
          }
        }, 30);
      }
      return () => clearInterval(interval);

      // for (let [key, value] of Object.entries(stampsQueue)) {
      //   q.enqueue(value);
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stampsQueue]);
  // Stop recording
  const stopRecording = async (uid, resourceid, sid) => {
    let tmp = {
      cname: stateCtx.dataLiveStream.live_key,
      uid: uid,
      clientRequest: {
        async_stop: true,
      },
    };

    const res = await fetch(
      `${baseUrl}/${appID}/cloud_recording/resourceid/${resourceid}/sid/${sid}/mode/mix/stop`,
      {
        method: 'post',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: `Basic NWFjOGFhOGRmZTg4NGNjNDhiMDczMjY1MGZhN2QwN2I6NjcxOTEzNzkyODY3NGI0Mzg0ODQxNmQyODhkZjYyNWE=`,
          Accept: 'application/json',
        },
        body: JSON.stringify(tmp),
      }
    );
    res
      .json()
      .then((rs) => {})
      .catch((e) => {});
  };

  const getUserViewsLive = () => {
    stopRecording(
      stateCtx.dataLiveStream?.uid,
      stateCtx.dataLiveStream?.resource_id,
      stateCtx.dataLiveStream?.sid
    );
    getOne(`/logs/lives/${liveKey}`)
      .then((snapshot) => {
        let data = [];
        if (snapshot.exists()) {
          snapshot.forEach((ss) => {
            data.push(parseInt(ss.key));
          });
          dispatch(updateChangeStatusEndLive(id, liveKey, data));
        } else {
          dispatch(updateChangeStatusEndLive(id, liveKey, ''));
        }
      })
      .catch(() => {
        dispatch(updateChangeStatusEndLive(id, liveKey, ''));
      });
  };

  //* FEE CONVERT POINTS TO YEN

  // const feePointToYen = 0.1; //* 10%
  const convertShowPrice = (price) => {
    return (
      <>
        1{t('Creator.Home.month')}/
        <CurrencyFormat
          thousandSeparator={true}
          value={price}
          className=''
          displayType='text'
        />
        {t('Common.pt')}
      </>
    );
  };

  const [screenshotSuccess, setScreenshotSuccess] = useState(false);
  useEffect(() => {
    if (screenshotSuccess) {
      const time = setTimeout(() => setScreenshotSuccess(false), 1500);
      return () => {
        clearTimeout(time);
      };
    }
  }, [screenshotSuccess]);
  const downloadImageData = async (data) => {
    const canvas = document.createElement('canvas');
    canvas.width = data.width; // 960; // data.width;
    canvas.height = data.height; // 720; // data.height;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    ctx.putImageData(data, 0, 0);

    canvas.toBlob((blob) => {
      const linkImages = setLinkSaveDB('images', `livestream/tsc/${liveKey}`);
      let name = linkImages.split('/');
      const path = `${name[0]}/${name[1]}/${name[2]}/${name[3]}/${name[4]}/${name[5]}`;
      let file = new File([blob], name[6], { type: 'image/png' });
      getPresignedUrlS3('image', path, name[6] || '').then((resUrl) => {
        uploadS3ByPresigned(resUrl?.data?.pre_signed, file).then((_res) => {
          pushNotify(
            'success',
            i18n.t('LiveStream.V5.takeshotSuccess'),
            'update_thumbnail_success'
          );
          const values = {
            img_background: resUrl.data.file_path,
            id: id,
          };
          return dispatch(updateTypeLiveStream(values, 'img_background'));
        });
      });
    }, 'image/png');

    canvas.remove();
  };

  const useRefAutoClick = useRef(null);
  useEffect(() => {
    let timerFunc = setTimeout(() => {
      if (stateCtx?.dataLiveStream?.img_background === 'NULL' && !muteVideo) {
        useRefAutoClick?.current?.click();
      }
    }, 60000);
    return () => clearTimeout(timerFunc);
  }, []);

  const [openIOS, setOpenIOS] = useState(false);

  const UIMobileIOS = Boolean(isMobile && isIOSSafari(window)) ? (
    <FullScreenIOS classNames={styles} isOpen={openIOS}>
      {openIOS ? (
        <div
          style={{
            zIndex: 250,
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
          }}>
          <div
            id='local-player'
            className={` ${isMobile ? 'sc-creator-views-mobile' : ''} ${
              ViewAgora ? '' : type !== 'review' ? 'creator_no-obs' : ''
            } sc-creator-views current-view`}
            style={{ height: '100%', width: '100%' }}></div>
          <div
            style={{
              position: 'fixed',
              bottom: 24,
              right: 24,
              zIndex: 999,
              cursor: 'pointer',
            }}
            onClick={() => {
              setOpenIOS(false);
              props?.setIsOpenIOS && props?.setIsOpenIOS(false);
            }}>
            <ExitFullScreenIcon alt='Exit' />
          </div>
        </div>
      ) : (
        <>
          <div
            id='local-player'
            className={` ${isMobile ? 'sc-creator-views-mobile' : ''} ${
              ViewAgora ? '' : type !== 'review' ? 'creator_no-obs' : ''
            } sc-creator-views current-view`}
            style={{ height: '100%', width: '100%' }}></div>
        </>
      )}
    </FullScreenIOS>
  ) : (
    <FullScreen handle={handle} className={type === 'review' ? 'sc-creator-rv-screen' : ''}>
      <div
        id='local-player'
        className={` ${isMobile ? 'sc-creator-views-mobile' : ''} ${
          ViewAgora ? '' : type !== 'review' ? 'creator_no-obs' : ''
        } sc-creator-views current-view`}
        style={{ height: '100%', width: '100%' }}></div>
      {handle.active && (
        <div
          style={{
            position: 'fixed',
            bottom: 24,
            right: 24,
            zIndex: 999,
            cursor: 'pointer',
          }}
          onClick={handle.exit}>
          <ExitFullScreenIcon className='exit-full-screen' />
        </div>
      )}
    </FullScreen>
  );

  return (
    <>
      {/* Fan Loading */}
      {/* <PopupPaymentBySub /> */}
      {isLoading && !showPopupFanWhenLiveEnd && allowView !== null && allowView && (
        <div className='loading-video-live'>
          <BlueImgLive
            url={`${decryptPath(stateCtx?.userInfo?.livestream?.img_background, {
              isMini: true,
            })}`}>
            <div className='bg-img'></div>
            <p className='text-pause-live'>{t('LiveStream.V5.liveStopped')}</p>
          </BlueImgLive>
        </div>
      )}

      {(allowView || host) && !isLoading && showStamps?.show && (
        <AutoExpireStampsView
          dataStamps={dataStamps}
          liveKey={liveKey}
          host={host}
          isDevice={isDevice}
          setDataStamps={setDataStamps}
        />
      )}

      {host && ViewAgora ? (
        <FullScreen
          handle={handle}
          className={type === 'review' ? 'sc-creator-rv-screen' : ''}>
          {ViewAgora}
          {handle.active && (
            <div
              style={{
                position: 'fixed',
                bottom: 24,
                right: 24,
                zIndex: 999,
                cursor: 'pointer',
              }}
              onClick={handle.exit}>
              <ExitFullScreenIcon className='exit-full-screen' />
            </div>
          )}
        </FullScreen>
      ) : host ? (
        UIMobileIOS
      ) : (
        ''
      )}

      {host && view === 'creator' && popupConfirmFan && (
        <div>
          <PopupConfirmFan
            className='creator-confirm-close-live'
            colorSvg='#FF9F43'
            text={t('LiveStream.V5.messageEndLive')}>
            {!loadingChangeStatus ? (
              <>
                <div
                  className={`btn ok`}
                  onClick={() => {
                    setCheckShowAlert(false);
                    window.localStorage.removeItem('cpw_ls');
                    return getUserViewsLive();
                  }}>
                  OK
                </div>
                <div className='btn cancel' onClick={() => setPopupConfirmFan(false)}>
                  {t('Common.buttonCancel')}
                </div>
              </>
            ) : (
              <>
                <div className={`btn ok`}>
                  <div
                    className='spinner-border m-auto'
                    role='status'
                    style={{ width: '15px', height: '15px' }}></div>
                </div>
                <div className='btn cancel'> {t('Common.buttonCancel')} </div>
              </>
            )}
          </PopupConfirmFan>
        </div>
      )}

      {/* BUTTON ACTION SCREEN CREATOR REVIEW  */}
      {!ViewAgora &&
        !isLoading &&
        host &&
        view === 'creator' &&
        showButton === 'screen-review' && (
          <div className='box-button d-flex' style={{ zIndex: 100 }}>
            <span className='bg-white rounded-circle d-middle' onClick={toggleVideo}>
              <ImgCustom src={!muteVideo ? Camera : CameraDisabled} alt='' />
            </span>
            <span className='bg-white rounded-circle d-middle' onClick={toggleAudio}>
              <ImgCustom src={!muteAudio ? MicroPhone : MicroPhoneDisabled} alt='' />
            </span>
          </div>
        )}
      {/* END BUTTON ACTION SCREEN CREATOR REVIEW  */}

      {/* BUTTON ACTION SCREEN CREATOR LIVESTREAM   */}

      {!isLoading &&
        host &&
        view === 'creator' &&
        showButton === 'screen-creator-live' &&
        !openIOS && (
          <>
            <div className={`group-service-vertical group-service`}>
              {
                <>
                  <div
                    className={`box-button-lstream d-flex 
          
                    ${i18n.language === 'en' && 'en'}`}
                    style={{ zIndex: 100 }}>
                    {!ViewAgora && (
                      <>
                        <span
                          style={{
                            cursor: 'pointer',
                          }}
                          ref={useRefAutoClick}
                          onClick={(e) => {
                            if (!muteVideo) {
                              const imageData = local.videoTrack.getCurrentFrameData();
                              downloadImageData(imageData);
                            }
                          }}
                          className='bg-white rounded-circle d-middle snapshot_take'>
                          {muteVideo ? <UpThumbnailDisableIcon /> : <UpThumbnailIcon />}
                          <div className='hover_snapshot_take'>
                            {t('LiveStream.V5.takeshot')}
                          </div>
                        </span>
                      </>
                    )}

                    <span className='wrap-copy-success'>
                      <CopyLinkIcon
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${getEnv('REACT_APP_FAN_URL')}/lives/${liveKey}`
                          );
                          setCopySuccess(true);
                          setTimeout(() => {
                            setCopySuccess(false);
                          }, 1500);
                        }}
                      />
                      {copySuccess && (
                        <div className='copy-success'>
                          {t('LiveStream.V5.messageCopyLink')}
                        </div>
                      )}
                    </span>

                    {!ViewAgora ? (
                      <>
                        {muteVideo ? (
                          <CameraDisableIcon onClick={toggleVideo} />
                        ) : (
                          <CameraIcon onClick={toggleVideo} />
                        )}
                        {muteAudio ? (
                          <MicroPhoneDisableIcon onClick={toggleAudio} />
                        ) : (
                          <MicroPhoneIcon onClick={toggleAudio} />
                        )}
                      </>
                    ) : (
                      <>
                        <CameraDisableIcon style={{ cursor: 'not-allowed' }} />
                        <MicroPhoneDisableIcon style={{ cursor: 'not-allowed' }} />
                      </>
                    )}
                    <MaxScreenLiveIcon
                      onClick={() => {
                        if (isIOSSafari(window)) {
                          setOpenIOS(true);
                          props?.setIsOpenIOS && props?.setIsOpenIOS(true);
                        } else {
                          handle.enter();
                        }
                      }}
                    />
                    <OutLiveIcon onClick={confirmStopLive} />
                  </div>
                </>
              }
            </div>
          </>
        )}

      {!host && !allowView && isAuth === 0 && (
        <PopupBlockUserNoLogin handleOk={() => {
          window.sessionStorage.setItem('url_no_login', `${getEnv('REACT_APP_FAN_URL')}${newPathUser}${`lives/${liveKey}`}`);
          window.location.href = "/sign-in"
        }} hideCancel />
      )}

      {/* END USER NOT LOGIN AND NOT ALLOW VIEW */}

      {/* USER IS LOGGIN BUT NOT ALLOW VIEW   */}

      {!host && !allowView && isAuth === 1 && (
        <>
          {isEnoughPoints !== null && !isEnoughPoints && (
            <PopupConfirmFan
              isVisiblePopup={() => setIsEnoughPoints(null)}
              colorSvg='#FF9F43'
              text={t('Popup.NotEnoughPoints')}>
              <div
                className='ok btn'
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  return (window.location.href = `${newPathUser}purchase`);
                }}>
                OK
              </div>
              <div className='btn cancel' onClick={() => setIsEnoughPoints(null)}>
                {t('Common.buttonCancel')}
              </div>
            </PopupConfirmFan>
          )}

          {/* POPUP CONFIRM  MUA PACKAGE */}
          {isConfirmBuyPackage !== null && isConfirmBuyPackage && (
            <PopupFanBuyPackage
              isVisiblePopup={() => setIsConfirmBuyPackage(null)}
              colorSvg='#FF9F43'
              text={t('Popup.subscribeJoin')}
              text2={t('LiveStream.V5.yourPoint')}
              text3={`${formatCoin(remainPoint)}`}
              className='fan-buy-package-live'>
              <button
                className='ok btn'
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  // CALL API FAN MUA PACKAGES CUA CREATOR
                  if (!isCallAPIBuyPackage) {
                    return dispatch(
                      fanBuyPackages(parseInt(messageAllow.packageId), liveKey, (err) => {
                        setCheckBuyPackage(err?.messageId);
                        document.querySelector(
                          '.popup.confirm-fan.fan-buy-package-live'
                        ).style.display = 'none';
                      })
                    );
                  }
                }}>
                {isCallAPIBuyPackage ? (
                  <span
                    className='spinner-border m-auto'
                    style={{ width: '1rem', height: '1rem' }}
                    role='status'></span>
                ) : (
                  'OK'
                )}
              </button>
              <div
                className='btn cancel'
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  return (window.location.href = `${newPathUser}lives/list/live`);
                }}>
                {t('Common.buttonCancel')}
              </div>
            </PopupFanBuyPackage>
          )}
          {checkBuyPackage === 'TOO_SOME_SUBSCRIBERS' && (
            <PopupConfirmFan
              isVisiblePopup={false}
              iconNote={true}
              colorSvg='#FF9F43'
              text={t('ValidateMsg.TOO_SOME_SUBSCRIBERS')}>
              <div
                className='btn ok'
                onClick={() => navigate(`${newPathUser}lives/list/live`)}>
                {t('Common.Ok')}
              </div>
            </PopupConfirmFan>
          )}
          {/* END POPUP CONFIRM MUA PACKAGE */}
          {isConfirmBuyPackage === null && (
            <PopupDenyFanView
              className={
                messageAllow !== null && messageAllow.package !== null
                  ? 'dn-s-package'
                  : undefined
              }>
              <LockIcon
                className='live-icon-lock'
                style={{ height: 'auto', maxHeight: '32px', width: 'auto' }}
              />
              {messageAllow !== null && messageAllow.package !== null && (
                <>
                  <span
                    className='live-title-lock'
                    style={{
                      marginBottom: '12px',
                      textAlign: 'center',
                      marginTop: '15px',
                    }}>
                    {t('ValidateMsg.F014_MESSAGE_AFTER_MESSAGE_PACKAGE')}
                  </span>
                  <span className='live-title-lock'>{messageAllow?.package}</span>
                  <span
                    className='live-deslink-lock'
                    style={{ marginTop: '0px', marginBottom: '16px' }}>
                    {convertShowPrice(messageAllow?.message)}
                  </span>
                  {messageAllow?.description &&
                    typeof messageAllow?.description &&
                    messageAllow?.description !== 'undefined' && (
                      <span className='live-title-lock-description display-linebreak'>
                        {messageAllow?.description}
                      </span>
                    )}
                </>
              )}

              {messageAllow !== null && messageAllow.package === null && (
                <span className='live-deslink-lock'>
                  {t(`ValidateMsg.${messageAllow?.key}`)}
                </span>
              )}

              {/* TRUONG HOP CHUA FOLLOW  */}
              {messageAllow?.message === messageValidate.F014_MESSAGE_IF_USER_NOT_FOLLOW && (
                <button
                  className='live-btn-back-lock'
                  disabled={isCallAPIBuyPackage || stateCtx?.loadingFollowLive}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    return dispatch(
                      followUnFollowUserByType(
                        stateCtx?.userInfo?.livestream?.user_id,
                        '1',
                        'F014',
                        true
                      )
                    );
                  }}>
                  {isCallAPIBuyPackage ? (
                    <span
                      className='spinner-border m-auto'
                      style={{ width: '2rem', height: '2rem' }}
                      role='status'></span>
                  ) : (
                    `${t('Common.follow')}`
                  )}
                </button>
              )}
              {/* TRUONG HOP MUA PACKAGE */}
              {messageAllow?.message !== messageValidate.F014_MESSAGE_IF_USER_NOT_FOLLOW && (
                <button
                  className='live-btn-back-lock'
                  disabled={isCallAPIBuyPackage}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (messageAllow !== null && messageAllow.package !== null) {
                      const currentPoints = parseInt(remainPoint);
                      if (currentPoints >= parseInt(messageAllow?.message)) {
                        //* SHOW CONFIRM HOI MUON MUA HAY KHONG
                        setIsEnoughPoints(null);
                        //* OPEN POPUP CONFIRM

                        setIsConfirmBuyPackage(true);
                      } else {
                        //* SHOW POPUP KO DU POINTS
                        setIsEnoughPoints(false);

                        setIsConfirmBuyPackage(null);
                      }
                    } else {
                      return (window.location.href = `${newPathUser}lives/list/live`);
                    }
                  }}>
                  {isCallAPIBuyPackage ? (
                    <span
                      className='spinner-border m-auto'
                      style={{ width: '2rem', height: '2rem' }}
                      role='status'></span>
                  ) : (
                    <>
                      {/* TRUONG HOP KO NAM TRONG LIST USE DUOC XEM THI XUAT HIEN CHU OK */}
                      {messageAllow?.message !==
                      messageValidate.F014_USER_NOT_IN_LIST_VIEW_LIVE
                        ? `${t('LiveStream.V5.membership')}`
                        : 'OK'}
                    </>
                  )}
                </button>
              )}
            </PopupDenyFanView>
          )}
        </>
      )}
      {/* END USER IS LOGGIN BUT NOT ALLOW VIEW  */}

      {/* SHOW POPUP WHEN CREATOR END LIVE FOR FAN  */}
      {showPopupFanWhenLiveEnd && (
        <PopupConfirmFan
          isVisiblePopup={() => {}}
          colorSvg='#FF9F43'
          text={t('LiveStream.V5.livehasEnded')}>
          <div
            className='ok btn'
            onClick={(event) => {
              event.preventDefault();
              return (window.location.href = `${newPathUser}lives/list/live`);
            }}>
            OK
          </div>
        </PopupConfirmFan>
      )}
      {/* END SHOW POPUP WHEN CREATE END LIVE FOR FAN */}

      {!host && !isLoading && allowView && (
        <>
          {ViewAgoraFan ? (
            ViewAgoraFan
          ) : (
            <div
              ref={fanViewRef}
              id={`player-${uid}`}
              style={{ height: '100%', width: '100%' }}
              className={`lsv-fan current-view`}>
              {autoPlayAudioFail && !isCameraOff && (
                <div
                  ref={fanRef}
                  style={{
                    position: 'absolute',
                    zIndex: '2',
                    top: '0%',
                    display: 'flex',
                    width: '70%',
                    justifyContent: 'left',
                  }}>
                  <button
                    className='btn btn-follow'
                    style={{ backgroundColor: '#B1B6BB' }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setAutoPlayAudioFail(false);
                    }}>
                    {' '}
                    Click me to resume the audio/video{' '}
                  </button>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};
