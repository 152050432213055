import { useEffect, useState } from "react";

export function useWasIt(value, targetValue) {
  const [itWas, setItWas] = useState(value === targetValue)

  useEffect(() => {
    if (!itWas && value === targetValue) {
      setItWas(true)
    }
  }, [itWas, targetValue, value])

  return itWas
}
