import { CloseIcon, SendChatIcon, CirclePlusPurpleIcon } from 'images';
import { useEffect, useState } from 'react';
import { ImgCustom, PopupConfirmFan } from '..';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import { formatCoin } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import {
  fanDonateCreatorLive,
  listPackagePoints,
  resetStatusFanDonate,
} from 'store/actions/lives';

import { CURRENT_USER_KEY } from 'constants/shared';
import { LIST_POINT_ICON_DONATE } from 'utils/constant';

import Storage from 'utils/storage';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { newPathUser } from 'constants/layout/constant';
import { isMobile } from 'react-device-detect';

const SendCoinPopupLive = ({
  link,
  closeModal,
  valueCoin,
  currentCoin = 0,
  isVisiblePopup,
  liveId,
  creatorId,
  transactionType,
  setIsEnoughPoints,
  handleEventFanQuitWhenEnoughsPoint,
  ...props
}) => {
  const [active, setActive] = useState(null);
  useLockBodyScroll();
  const [popupConfirmSend, setPopupConfirmSend] = useState(false);
  const [valueChoice, setValueChoice] = useState(0);
  const navigate = useNavigate();
  const [stampIcon, setStampIcon] = useState('');
  const [stampGift, setStampGift] = useState('');
  const { t } = useTranslation();

  const dispatch = useDispatch();

  let { loading, status, dataAfterDonate, dataListPointsPackages } = useSelector(
    (state) => state.lives
  );

  const refPoint = useRef();
  useEffect(() => {
    dispatch(listPackagePoints());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log('loading: ', loading, ' - status: ', status);

    if (status === 'FAN_DONATE_CREATOR_LIVE_SUCCESS') {
      const value = parseInt(dataAfterDonate?.points);
      if (parseInt(valueChoice) > 0) {
        setPopupConfirmSend(false);
        valueCoin(valueChoice, value, false, true, stampIcon, stampGift);
        dispatch(resetStatusFanDonate());
      } else {
        console.log('sendCoin-Err1-points-not-valid');
      }
    }
    if (status === 'FAN_DONATE_CREATOR_LIVE_FAILED') {
      setPopupConfirmSend(false);
      dispatch(resetStatusFanDonate());
      // valueCoin(valueChoice, parseInt(currentCoin), false, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const handleDoNotInsufficientCoin = () => {
    //* CALL API UPDATE POINTS
    const user = Storage.get(CURRENT_USER_KEY);
    if (user) {
      const queryString = {
        // transaction_type: transactionType || 2,
        livestream_id: parseInt(liveId),
        user_id: parseInt(creatorId),
        point_id: parseInt(active),
      };
      // if (!transactionType) {
      //   queryString.livestream_id = liveId;
      // }
      dispatch(fanDonateCreatorLive(queryString));
    } else {
      return navigate(0);
    }
  };

  const handleSendCoin = () => {
    setPopupConfirmSend(true);
  };

  return (
    <>
      {popupConfirmSend && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupConfirmSend(false)}
          colorSvg='#FF9F43'
          text={t('Popup.messageSendPoint')}>
          {!loading ? (
            <>
              <div
                className='ok btn'
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  handleDoNotInsufficientCoin();
                }}>
                OK
              </div>
              <div className='btn cancel' onClick={() => setPopupConfirmSend(false)}>
                {t('Common.buttonCancel')}
              </div>
            </>
          ) : (
            <>
              <div className={`btn ok`}>
                <div
                  className='spinner-border m-auto'
                  role='status'
                  style={{ width: '15px', height: '15px' }}></div>
              </div>
              <div className='btn cancel'> {t('Common.buttonCancel')} </div>
            </>
          )}
        </PopupConfirmFan>
      )}

      <div className='popup popup-send-coin'>
        <div className='popup-content'>
          <CloseIcon className='close-icon' onClick={isVisiblePopup} />
          <div className='title'>{t('Common.possessionPoints')}</div>
          <div className='value-choice' style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                fontWeight: '700',
                fontSize: '20px',
                color: '#1A1A1A',
              }}
              ref={refPoint}>
              {formatCoin(currentCoin)}
            </div>
            <CirclePlusPurpleIcon
              color='#1480FF'
              style={{ marginLeft: 8 }}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                return handleEventFanQuitWhenEnoughsPoint();
              }}
            />
          </div>
          <div className={`box-coin ${isMobile ? 'is-mobile' : ''}`}>
            {loading ? (
              <div
                className='spinner-border m-auto'
                role='status'
                style={{ color: '#F90F00', width: '20px', height: '20px' }}></div>
            ) : (
              <>
                {dataListPointsPackages?.map((item) => {
                  const srcImg = LIST_POINT_ICON_DONATE.find((x) => {
                    return x.name === item.icon;
                  });
                  return (
                    <div
                      key={item.id}
                      className={`item ${item.id === active && 'active'}`}
                      onClick={() => {
                        setActive(item.id);
                        setValueChoice(item.point);

                        setStampIcon(item.icon);
                        setStampGift(item.gift);
                      }}>
                      <img src={srcImg?.icon} alt='' />
                      <div className='number'>{formatCoin(item.point)}</div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div className='box-btn'>
            <div
              className={`btn-submit default-grey ${valueChoice > 0 && 'active'}`}
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();
                if (valueChoice > 0) {
                  if (parseInt(currentCoin) >= parseInt(valueChoice)) {
                    setIsEnoughPoints(null);
                    handleSendCoin();
                  } else {
                    setIsEnoughPoints(false);
                    isVisiblePopup()
                  }
                }
              }}>
              <SendChatIcon />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendCoinPopupLive;
