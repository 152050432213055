//import { memo } from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import ProgressiveImage from 'react-progressive-graceful-image';
import { isSafari } from 'react-device-detect';
import { useMemo, useRef } from 'react';

import { LOGO_THUMBNAIL_COCOFAN, onErrImage, decryptPath } from 'utils';
import ImgDemo from 'images/ImagePlacehoder.png';
import AvatarPlaceholder from 'images/avatarPlaceholder.png';

import './index.scss';
import useCheckVersionBrowser from 'hooks/useCheckVersionBrowser';
import useDisableContextMenu from 'hooks/useDisableContextMenu';

const ImgCustom = ({
  src = '',
  alt = 'image',
  isPlaceholderAvatar = false,
  isLogoPlaceholder = false,
  className,
  avatarErr = true,
  isAdmin = false,
  live = false,
  screen = '',
  noIMGPlaceholder = false,
  isMini,
  ...props
}) => {
  const versionSafariSupport = 16.4;
  const versionBrowser = useCheckVersionBrowser();
  const ref = useRef();
  useDisableContextMenu(ref);
  const loadingLazySupport = () => {
    return +versionBrowser >= versionSafariSupport;
  };
  src = decryptPath(src, isMini);
  if (!isMini) {
    switch (screen) {
      case 'avartar_navbar_60_60':
        src = `${src}?width=60&height=60`;
        break;
      case 'home_avartar_100_100':
        src = `${src}?width=100&height=100`;
        break;
      case 'home_banner_720_200':
        src = `${src}?width=720&height=200`;
        break;
      case 'home_img_short_video_hight_light_72_72':
        src = `${src}?width=72&height=72`;
        break;
      case 'home_3_img_default_243_280':
        src = `${src}?width=243&height=280`;
        break;
      case 'home_img_post_640_418':
        src = `${src}?width=640&height=418`;
        break;
      case 'recommend_top_56_56':
        src = `${src}?width=56&height=56`;
        break;
      case 'recommend_sale_footer_290_180':
        src = `${src}?width=290&height=auto`;
        break;
      case 'edit_profile_3_img_328_274':
        src = `${src}?width=328&height=274`;
        break;
      case 'timeline_flow_top_140_180':
        src = `${src}?width=140&height=180`;
        break;
      case 'timeline_auto_375_pc':
        src = `${src}?width=auto&height=375`;
        break;
      case 'timeline_auto_480_pc':
        src = `${src}?width=480&height=auto`;
        break;
      case 'post_775_720_pc':
        src = `${src}?width=775&height=auto`;
        break;
      case 'post_640_auto_pc':
        src = `${src}?width=640&height=auto`;
        break;
      case 'post_240_auto_pc':
        src = `${src}?width=240&height=auto`;
        break;
      case 'post_319_auto_pc':
        src = `${src}?width=319&height=auto`;
        break;
      case 'avatar_profile_160_160':
        src = `${src}?width=160&height=160`;
        break;
      case '3_img_profile_210_241':
        src = `${src}?width=210&height=241`;
        break;
      case 'avatar_44_44':
        src = `${src}?width=44&height=44`;
        break;
      case 'img_highline_190_248':
        src = `${src}?width=190&height=248`;
        break;
      case 'popup_fan_detail_70_70':
        src = `${src}?width=70&height=70`;
        break;
      case 'avatar_notification_list_60_60':
        src = `${src}?width=60&height=60`;
        break;
      case 'avatar_mini_28_28':
        src = `${src}?width=28&height=28`;
        break;
      case 'image_preview_223_223':
        src = `${src}?width=223&height=auto`;
        break;
      case 'image_preview_223_auto':
        src = `${src}?width=223&height=auto`;
        break;
      case 'image_post_detail_auto_360':
        src = `${src}?width=auto&height=360`;
        break;
      case 'thumbnail_auto_168':
        src = `${src}?width=auto&height=168`;
        break;
      case 'thumbnail_auto_193':
        src = `${src}?width=auto&height=193`;
        break;
      case 'image_preview_171_171':
        src = `${src}?width=171&height=auto`;
        break;
      case 'image_preview_200_200':
        src = `${src}?width=auto&height=200`;
        break;
      case 'post_343_auto_pc':
        src = `${src}?width=343&height=auto`;
        break;
      case 'post_326_auto_pc':
        src = `${src}?width=326&height=auto`;
        break;
      case 'post_183_auto_pc':
        src = `${src}?width=183&height=auto`;
        break;
      case 'post_355_auto_pc':
        src = `${src}?width=355&height=auto`;
        break;
      case 'post_330_auto_pc':
        src = `${src}?width=330&height=auto`;
        break;
      case 'post_212_auto_pc':
        src = `${src}?width=212&height=auto`;
        break;
      case 'mini_32_auto_pc':
        src = `${src}?width=32&height=auto`;
        break;
      case 'banner_330_186_pc':
        src = `${src}?width=320&height=186`;
        break;
      case 'banner_343_93_pc':
        src = `${src}?width=343&height=93`;
        break;
      case 'post_197_11_pc':
        src = `${src}?width=200&height=auto`;
        break;
      case 'avatar_mini_32_32':
        src = `${src}?width=32&height=32`;
        break;
      default:
        break;
    }
  }

  const imgPlaceholder = useMemo(() => {
    if (isLogoPlaceholder) {
      return LOGO_THUMBNAIL_COCOFAN;
    }
    if (!noIMGPlaceholder) {
      if (isPlaceholderAvatar) {
        return AvatarPlaceholder;
      }

      return ImgDemo;
    }
    return null;
  }, [noIMGPlaceholder, isPlaceholderAvatar, isLogoPlaceholder]);

  return (
    <ProgressiveImage src={src} placeholder={imgPlaceholder}>
      {(src) =>
        isSafari ? (
          <img
            ref={ref}
            loading={loadingLazySupport() ? 'lazy' : ''}
            src={src}
            alt={alt}
            className={`img-custom ${className}`}
            onError={(e) => onErrImage(e, avatarErr, isAdmin, live)}
            {...props}
          />
        ) : (
          <img
            ref={ref}
            loading='lazy'
            src={src}
            alt={alt}
            className={`img-custom ${className}`}
            onError={(e) => onErrImage(e, avatarErr, isAdmin, live)}
            {...props}
          />
        )
      }
    </ProgressiveImage>
  );
};
export default ImgCustom;
