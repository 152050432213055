import axios from 'axios';
import { getEnv } from 'configs/env';
import { getAccessToken } from './constant';

function generateId(fileName = '') {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!-.*()';
  const length = 20;
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  const date = new Date().toISOString().split('T')[0].replace(/-/g, '');
  return fileName ? `${date}-${result}-${fileName}` : `${date}-${result}`;
}

const formatImage = 'png';
const formatVideo = 'mp4';

export const setLinkSaveDB = (type = '', uploadTo = '') => {
  const live = `${type}-${Math.floor(Math.random() * 100)}`;
  return `uploads/output/${type}/${uploadTo}/${live}-${generateId('')}.${
    type === 'images' ? formatImage : formatVideo
  }`;
};

export const uploadS3ByPresigned = async (url = '', binary, setPercentUpload, listPercent, index) => {
  let res = await axios({
    method: 'PUT',
    url,
    responseType: 'arraybuffer',
    data: binary,
    onUploadProgress: ({loaded , total}) => {
      if (setPercentUpload) {
        listPercent[index] = Math.floor((loaded * 100) / total)
        setPercentUpload(listPercent?.slice(0))
      }

    }
  });
  return res.data;
};

export const uploadS3ByPresignedMulti = async (url = '', binary, setPercentUpload, listPercent, index) => {
  const response = await axios({
    method: 'PUT',
    url,
    responseType: 'arraybuffer',
    data: binary,
    onUploadProgress: ({loaded , total}) => {
      if (setPercentUpload) {
        listPercent[index] = Math.floor((loaded * 100) / total)
        setPercentUpload(listPercent?.slice(0))
      }

    }
  });
  let etag = response.headers.etag;
  return etag;
};

export const getPresignedUrlS3 = async (type = '', path = '', name = '', content_type) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/upload`;
  const params = content_type
    ? {
        file_name: name,
        path,
        type,
        content_type,
      }
    : {
        file_name: name,
        path,
        type,
      };
  let res = await axios({
    method: 'POST',
    url,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: getAccessToken,
    },
    data: JSON.stringify(params),
  });
  return res.data;
};

export const getPresignedMultiVideoUrlS3 = async (
  type = '',
  path = '',
  name = '',
  content_type,
  numParts
) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/multipart-upload`;
  const params = {
    total_parts: numParts,
    file_name: name,
    type,
    path: path,
    content_type,
  };

  let res = await axios({
    method: 'POST',
    url,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: getAccessToken,
    },
    data: JSON.stringify(params),
  });
  return res.data;
};

export const getPresignedMultiVideoUrlS3Complete = async (
  upload_id = '',
  bucket = '',
  key = '',
  parts
) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/multipart-upload/completed`;
  const params = {
    upload_id,
    bucket,
    key,
    parts,
  };

  let res = await axios({
    method: 'POST',
    url,
    responseType: 'json',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: getAccessToken,
    },
    data: JSON.stringify(params),
  });
  return res.data;
};

export const setFileNameS3 = (fileName = '') => {
  const currentTimeTamp = new Date().valueOf();
  const removeSpecialCharacter = fileName.replace(
    /[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi,
    ''
  );
  return `${currentTimeTamp}_${removeSpecialCharacter}`;
};

export const setFileNameS3Contract = (fileName = '', accountID) => {
  const currentTimeTamp = new Date().valueOf();
  const removeSpecialCharacter = fileName.replace(
    /[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi,
    ''
  );
  let lastDotIndex = removeSpecialCharacter.lastIndexOf('.');
  let textAfterLastDot = removeSpecialCharacter.substring(lastDotIndex + 1);
  return `contract_${accountID}_${currentTimeTamp}.${textAfterLastDot}`;
};