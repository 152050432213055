import PopupPaymentCardComponent from 'components/paymentCard';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { clearCards } from 'store/actions/card';
import {
  paymentVendo,
  updateStatusPaymentFail,
  updateStatusPaymentVendo,
} from 'store/actions/payment';
import { PAYMENT_CONST } from 'utils';
import Storage from 'utils/storage';

export const usePopupPaymentSub = ({ urlRedirect, onClickSuccess }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);
  const [isShowPopupCreateAndUpdateCard, setIsShowPopupCreateAndUpdateCard] = useState(false);
  const [isShowPopupPayment, setIsShowPopupPayment] = useState(false);
  const [idPackage, setIdPackage] = useState(null);
  const [isShowModalOTP, setIsShowModalOTP] = useState({
    isShow: false,
    urlOTP: null,
  });

  const urlRedirectPayment = useMemo(() => {
    return urlRedirect || location?.pathname;
  }, [location?.pathname, urlRedirect]);
  const { setting_system } = useSelector((state) => state.payment);
  const [verifyVendo] = useState(Storage.get(PAYMENT_CONST.VERIFY_VENDO));
  const [codeRollBack] = useState(Storage.get(PAYMENT_CONST.CODE_ROLLBACK));
  const [creditCardId] = useState(Storage.get(PAYMENT_CONST.CREDIT_CARD_ID));

  const paymentCheckWithStatus = useCallback(
    (data) => {
      const status = data?.status;
      if (status === 5) {
        Storage.set(PAYMENT_CONST.URL_REDIRECT, urlRedirectPayment);
        setIsShowModalOTP({
          isShow: true,
          urlOTP: data?.verification_url,
        });
      } else if (status === 3) {
        dispatch(updateStatusPaymentFail(data?.message));
      }
    },
    [urlRedirectPayment, dispatch]
  );

  useEffect(() => {
    if (verifyVendo && codeRollBack) {
      dispatch(
        paymentVendo(
          {
            code: codeRollBack,
            credit_card_id: creditCardId,
            payment_gateway: setting_system
          },
          paymentCheckWithStatus
        )
      );
    }
  }, [dispatch, verifyVendo, codeRollBack, creditCardId, paymentCheckWithStatus]);

  useEffect(() => {
    return () => {
      dispatch(updateStatusPaymentVendo());
      dispatch(clearCards());
      Storage.remove(PAYMENT_CONST.VERIFY_VENDO);
      Storage.remove(PAYMENT_CONST.CODE_ROLLBACK);
      Storage.remove(PAYMENT_CONST.CREDIT_CARD_ID);
    };
  }, [dispatch]);

  const PopupPaymentBySub = () => (
    <PopupPaymentCardComponent
      isShowPopupListCard={isOpen}
      idPackage={idPackage}
      urlRedirectPayment={urlRedirectPayment}
      onClickSuccess={onClickSuccess}
      isShowModalOTP={isShowModalOTP}
      setIsShowPopupListCard={setIsOpen}
      setIsShowModalOTP={setIsShowModalOTP}
      isShowPopupCreateAndUpdateCard={isShowPopupCreateAndUpdateCard}
      setIsShowPopupCreateAndUpdateCard={setIsShowPopupCreateAndUpdateCard}
      isShowPopupPayment={isShowPopupPayment}
      setIsShowPopupPayment={setIsShowPopupPayment}
    />
  );

  return {
    isOpen,
    idPackage,
    setIsOpen,
    setIdPackage,
    PopupPaymentBySub,
  };
};
