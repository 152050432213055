import { EditIcon, MessageAddIcon } from 'images';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { ENV, STORAGE, formatCoin, isProdEnable, linkS3, setOverflowTablet } from 'utils';
import { ImgCustom, Tooltip2 } from '..';
import { NotificationIcon } from 'images';
import useUserRole from '../../hooks/useUserRole';
import { AVATAR_DEFAULT, LOGO_COCO_FANS } from 'utils';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  resetIDNoti,
  resetIDNotiAdmin,
  resetTabNoti,
  userGetBadgeNotification,
} from 'store/actions/notification';
import { ShimmerCategoryItem } from 'react-shimmer-effects';
import { useSelector } from 'react-redux';
import { newPathUser } from 'constants/layout/constant';
import Storage from 'utils/storage';
import { logUserProfile, settingMinimalMenu } from 'store/actions/myPage';
import {
  getUserInfoOthers,
  resetIDHomePage,
  resetPopupPostShortVideo,
} from 'store/actions/usersNew';
import { clearScrollIdAndDataVendo } from 'store/actions/card';

const AccountSidebar = ({ user, children, isAdmin, isAgency, setIsVisbleMenu }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { isIdol } = useUserRole();
  const dispatch = useDispatch();
  const [unreadNumber, setUnreadNumber] = useState(0);
  const [point, setPoint] = useState(0);
  const { isMinimal } = useSelector((state) => state.myPage);
  const { dataUser } = useSelector((state) => state.users);
  const { dataInfoOther } = useSelector((state) => state.usersNew);
  const { dataNoMypage } = useSelector((state) => state.notification);
  const API = {
    getPoint: () => `${ENV}/points`,
  };
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  useEffect(() => {
    if (isAuth) {
      // dispatch(
      //   userGetBadgeNotification(
      //     ({ success, data }) => success && setUnreadNumber(data.admin + data.activity)
      //   )
      // );
      dispatch(userGetBadgeNotification());
    }
    //callAPIPonit();
  }, []);

  const callAPIPonit = async () => {
    await fetch(API.getPoint(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      },
    })
      .then((rs) => rs.json())
      .then((data) => {
        setPoint(data.data.points);
      });
  };

  return (
    <>
      <div className={`account-sidebar ${isMinimal && 'is_minimal'}`}>
        {!user ? (
          <div
            style={{
              marginLeft: 15,
              marginRight: 15,
              marginTop: 15,
              height: 76,
              maxHeight: 76,
            }}>
            <ShimmerCategoryItem
              hasImage
              imageType='circular'
              imageWidth={60}
              imageHeight={60}
              title
            />
          </div>
        ) : (
          <>
            {children}
            <div className='dp-flex 1'>
              <div
                className='left'
                onClick={
                  isAdmin || isAgency
                    ? null
                    : () => {
                        if (!isAuth) {
                          dispatch(logUserProfile());
                        } else {
                          const top = document.getElementById('app_cocofans_0');
                          top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
                          dispatch(resetPopupPostShortVideo());
                          dispatch(resetIDHomePage());
                          setIsVisbleMenu && setIsVisbleMenu(false);
                          document.getElementById('root').style.position = 'revert';
                          if (dataUser?.account_id) {
                            dispatch(getUserInfoOthers(dataUser?.account_id));
                            dataUser?.account_id &&
                              navigate(`${newPathUser}${dataUser?.account_id}`);
                          }
                        }
                      }
                }>
                {user?.avatar ? (
                  <ImgCustom
                    screen='avartar_navbar_60_60'
                    isPlaceholderAvatar
                    src={
                      isAdmin || isAgency
                        ? LOGO_COCO_FANS
                        : user?.avatar
                        ? `${user?.avatar}`
                        : AVATAR_DEFAULT
                    }
                    alt='avatar'
                    className={`${(isAdmin || isAgency) && 'avatar_admin'}`}
                  />
                ) : (
                  <img src={AVATAR_DEFAULT} alt='AVATAR_DEFAULT' />
                )}

                {/* {!isAdmin && (
                  <EditIcon
                    onClick={() => {
                      document.getElementById('root').style.overflow = 'auto';
                      navigate('/user/profile');
                    }}
                  />
                )} */}
              </div>
              {!isMinimal && (
                <div className='content-right'>
                  <div className='name-noti'>
                    <div className='name'>
                      {isAdmin
                        ? `${t('Layout.LayoutCreatorWithMenuSidebar.admin')}`
                        : isAgency
                        ? `${t('Layout.LayoutCreatorWithMenuSidebar.agency')}`
                        : user?.account_name || user?.name_japanese || user?.full_name}
                    </div>

                    {/* comment button notification */}
                    {!isAdmin && !isAgency && (
                      <div
                        className='mobile-noti'
                        onClick={() => {
                          if (!isAuth) {
                            dispatch(logUserProfile());
                          } else {
                            document.getElementById('root').style.position = 'revert';
                            // document.getElementById('root').style.overflow = 'auto';
                            setOverflowTablet();
                            setIsVisbleMenu && setIsVisbleMenu(false);
                            if (location.pathname === `${newPathUser}profile/notification`) {
                              navigate(0);
                            } else {
                              dispatch(resetTabNoti());
                              dispatch(resetIDNoti());
                              dispatch(resetIDNotiAdmin());
                              navigate(`${newPathUser}profile/notification`);
                            }
                          }
                        }}>
                        <div className='noti'>
                          {dataNoMypage?.admin + dataNoMypage?.activity > 0 && (
                            <span className='quantity-noti'>
                              {dataNoMypage?.admin + dataNoMypage?.activity < 100 ? (
                                dataNoMypage?.admin + dataNoMypage?.activity
                              ) : (
                                <span>99+</span>
                              )}
                            </span>
                          )}
                          <NotificationIcon className='icon-noti' style={{ width: '100%' }} />
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    <span className='color-gray1'>
                      {isAdmin || isAgency ? '' : user?.account_id}
                    </span>
                  </div>
                </div>
              )}
            </div>
            {!isMinimal && (
              <>
                <div className='infor'>
                  {!isAdmin && !isAgency && (
                    <div className='current-point'>
                      <span className='color-gray'>{t('Common.possessionPoints')}:</span>
                      <Tooltip2
                        className='info-point'
                        text={
                          <span
                            className='point'
                            style={{ fontWeight: 500, color: '#DC143C', marginLeft: '5px' }}>
                            {formatCoin(dataUser?.points) || 0}
                          </span>
                        }>
                        {formatCoin(dataUser?.points) || 0}
                      </Tooltip2>
                    </div>
                  )}
                  {+isProdEnable === 1 && !isAdmin && !isAgency && (
                    <div
                      onClick={() => {
                        window.localStorage.removeItem('idPost');
                        dispatch(clearScrollIdAndDataVendo());
                        document.getElementById('root').style.position = 'revert';
                        setOverflowTablet();
                        navigate(`${newPathUser}purchase`, {
                          state: {
                            pathname: `${location?.pathname}${location?.search}`,
                          },
                        });
                      }}
                      className='buy-point-link'>
                      {t('Common.pointPurchase')}
                    </div>
                  )}
                </div>
                {!isAdmin && !isAgency && (
                  <div
                    className={`link-F016 ${isAdmin ? 'admin' : ''}`}
                    onClick={() => {
                      if (!isAuth) {
                        dispatch(logUserProfile());
                      } else {
                        document.getElementById('root').style.position = 'revert';
                        setOverflowTablet();
                        if (
                          !location.pathname.includes(`${newPathUser}subscribe-list`) &&
                          !isAdmin
                        ) {
                          navigate(`${newPathUser}subscribe-list`);
                        }
                      }
                    }}>
                    {t('Common.checkTicketPremium')}
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default AccountSidebar;
