import React from 'react';
import './InputWrapper.scss';
import { Tooltip } from '..';
import { useTranslation } from 'react-i18next';

export const InputWrapper = (props) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        marginBottom: props?.marginBottom,
        height: props?.height,
        borderRadius: props?.borderRadius,
        border: props?.border,
        overflow: props?.overflow,
      }}
      className={
        'fansite-form-input__controls' + (props.className ? ' ' + props.className : '')
      }>
      {props.label && (
        <label
          onClick={props.customFocusHandler}
          id={props.id ? props.id : ''}
          htmlFor={props.id ? props.id : ''}
          className='form-label fansite-form-input__label d-lex align-items-center display-block'>
          {props.label}
          &nbsp;
          {props.required && (
            <span className='fansite-form-input__required'>
              *{/* ({t('Input.InputWrapper.must')}) */}
            </span>
          )}
          {props.asterisk && <span className='asterisk'>*</span>}
          {props.note}
          {props.notification && <Tooltip>{props.notificationContent}</Tooltip>}
          {!!props.countLength && (
            <span className='text-count-length'>
              {props.countLength}/{t('Input.InputWrapper.letter', { max: props.maxLength })}
            </span>
          )}
          {props.status && <div className='uploaded'>{t('Input.InputWrapper.Submitted')}</div>}
        </label>
      )}
      {props.contract && (
        <button
          className='btn mt-3'
          style={{
            background: 'transparent',
            color: '#1480FF',
            border: 'none',
            padding: '8px 24px',
            display: 'flex',
            alignItems: 'center',
            margin: '0 auto',
            gap: '8px',
            marginBottom: '15px',
          }}
          onClick={props.handleDownload}>
          <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M21 1H3V23H21V1Z'
              stroke='#1480FF'
              strokeWidth={2}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M12 17V7'
              stroke='#1480FF'
              strokeWidth={2}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M16 13L12 17L8 13'
              stroke='#1480FF'
              strokeWidth={2}
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
          {t('Affiliator.AF005.Contract')}
        </button>
      )}
      {props.children}
      {props.error && (
        <p className='fansite-form-input__error text-danger'>{t(props.error)}</p>
      )}
    </div>
  );
};
