import React, { useEffect, useState } from 'react';
import { onValue, ref, query, equalTo, orderByChild } from 'firebase/database';
import { DBRealTime } from '../../firebase/config';
import { useParams } from 'react-router-dom';
import { formatCoin, formatNumber } from 'utils/utils';

export const NumberDonate = ({isDonateC092 = false}) => {

  const { liveKey } = useParams();
  const messageRef = query(
    ref(DBRealTime, `/chat/${liveKey}`),
    orderByChild('type'),
    equalTo(1),
  );
  const [views, setViews] = useState(0);
  useEffect(() => {
    onValue(messageRef, (snapshot) => {
      if (snapshot.val() === false) {
        return;
      }
      let total = 0;
      snapshot.forEach((ss) => {
        total += parseInt(ss.val().message);
      });
      setViews(total);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{isDonateC092 ? formatNumber(views || 0) : formatCoin(views || 0)}</>;
};
