import Icon from '@ant-design/icons';

const HeartLikeIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx='10' cy='10' r='10' fill='#DC143C' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.19575 10.2996C4.63093 8.62441 5.29103 6.70973 7.14236 6.14317C8.11619 5.84465 9.19109 6.02066 10.0007 6.59922C10.7666 6.03666 11.881 5.84665 12.8538 6.14317C14.7051 6.70973 15.3694 8.62441 14.8051 10.2996C13.926 12.9548 10.0007 15 10.0007 15C10.0007 15 6.10431 12.9858 5.19575 10.2996Z'
          fill='white'
        />
      </svg>
    )}
    {...props}
  />
);

export default HeartLikeIcon;
