/* eslint-disable import/no-anonymous-default-export */

import { TYPES } from '../actions';
const initialState = {
  status: null,
  loading: false,
  error: null,
  creatorListDateLive: [],
  paramsCreatorListDateLive: {},
  creatorListLiveInDate: [],
  paramsCreatorListLiveInDate: {},
  fanListDateLive: [],
  fanListLiveInDate: [],
  idScrollBackCalender: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CREATOR_GET_LIST_DATE_LIVE_MONTH_REQUEST:
    case TYPES.CREATOR_GET_LIST_IN_DATE_REQUEST:
    case TYPES.FAN_GET_LIST_DATE_LIVE_MONTH_REQUEST:
    case TYPES.FAN_GET_LIST_IN_DATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.CREATOR_GET_LIST_DATE_LIVE_MONTH_FAILED:
    case TYPES.CREATOR_GET_LIST_IN_DATE_FAILED:
    case TYPES.FAN_GET_LIST_DATE_LIVE_MONTH_FAILED:
    case TYPES.FAN_GET_LIST_IN_DATE_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };
    case TYPES.CREATOR_GET_LIST_DATE_LIVE_MONTH_SUCCESS:
      return {
        ...state,
        creatorListDateLive: action.data.data,
        paramsCreatorListDateLive: action.data.params,
        loading: false,
      };
    case TYPES.CREATOR_GET_LIST_IN_DATE_SUCCESS:
      return {
        ...state,
        creatorListLiveInDate: action.data.data,
        paramsCreatorListLiveInDate: action.data.params,
        loading: false,
      };
    case TYPES.FAN_GET_LIST_DATE_LIVE_MONTH_SUCCESS:
      return {
        ...state,
        fanListDateLive: action.data.data,
        loading: false,
      };
    case TYPES.FAN_GET_LIST_IN_DATE_SUCCESS:
      return {
        ...state,
        fanListLiveInDate: action.data.data,
        loading: false,
      };
    case 'RESET_ACTION_CALENDAR':
      return {
        ...state,
        creatorListDateLive: [],
        creatorListLiveInDate: [],
        fanListDateLive: [],
        fanListLiveInDate: [],
        idScrollBackCalender: null,
        paramsCreatorListLiveInDate: {},
        paramsCreatorListDateLive: {},
      };
    case 'SET_ID_SCROLL_BACK_CALENDER':
      return {
        ...state,
        idScrollBackCalender: action.data?.data?.id || null,
      };
    default:
      return state;
  }
};
