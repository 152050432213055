import {
  ImgCustom,
  PopupConfirmFan,
  PopupLoading,
  PopupSharePost
} from 'components';
import MarkDownRead from 'components/MarkDownRead';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { getEnv } from 'configs/env';
import { newPathUser } from 'constants/layout/constant';
import {
  ChatIcon,
  HeartActiveIcon,
  HeartIcon,
  IconBookmark,
  MuteIconAct,
  ShareIcon,
  ThreePointIcon
} from 'images';
import PlayTikTokIcon from 'images/PlayIconTiktok';
import MuteShortVideoIcon from 'images/muteShortVideo';
import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { isMobile, isSafari } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetActionCreatorHomePage } from 'store/actions/creator';
import { logUserProfile } from 'store/actions/myPage';
import { clearIsLoadDataSearchShort } from 'store/actions/users';
import {
  creatorDeleteArticle,
  fanFollowCreator,
  postBookMarkDetail,
  postLikeDetail
} from 'store/actions/usersNew';
import {
  STORAGE,
  formatNumber,
  isAdmin,
  setOverflowTablet
} from 'utils';
import Storage from 'utils/storage';
import { Card } from './ListImgTimeline/Card';
import './PopupSearchShortVideo.scss';
import VideoShortPlayer from './VideoPlayerShort';
import PopupViewFullMess from './comment/popupCommentSearchShort';
import InfoBox from './infoBox';
import { LazyAwareSearchShort } from './lazyAwareSearchShort';
import { LayerWrapClickVideo, LockScreen, PlaceHolderItem } from './style';

export const TimelineWrapperContext = createContext();

const PopupSearchShortVideo = ({ item, close, indexIdx, keyword, page, setPage }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isShowOtherBox, setIsShowOtherBox] = useState({
    isShow: false,
    indexShow: -1,
  });
  const [listShort, setListShort] = useState([]);
  const [indexPlay, setIndexPlay] = useState(0);
  const [hoverId, setHoverId] = useState(false);
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const [isShowPopup, setShowPopup] = useState({
    index: 0,
    status: false,
    dataItem: {},
  });
  const { pagination, searchShortVideo, isLoadDataSearchShort, isLoadingFollowCreate } =
    useSelector((state) => state.usersNew);
  const { dataUser, dataInfoOther, loading, searchShortVideoPagination } = useSelector(
    (state) => state.users
  );
  const dispatch = useDispatch();
  const listRefVideo = useRef([]);

  const widthC154 = document?.querySelector('.page-list-short-video1.c154')?.offsetWidth;
  const [flgRenderCMT, setFlgRenderCMT] = useState(false);
  const widthScreen = window.innerWidth;
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);

  useEffect(() => {
    dispatch(resetActionCreatorHomePage());
  }, []);

  useEffect(() => {
    // const arrayFilter = searchShortVideo.filter((i) => i.id !== item?.id);
    if (isLoadDataSearchShort && !flgRenderCMT) {
      const arrayFilter = searchShortVideo.filter(
        (i, index) => index >= indexIdx && i.id !== item?.id
      );
      const arrayFind = searchShortVideo.find((i) => i.id === item?.id);
      const arrayNew = [arrayFind].concat(arrayFilter);
      if (arrayNew && arrayNew?.length > 0) {
        const listChange = arrayNew?.map((item, index) => {
          let newItem = item;
          if (listShort[index]) {
            newItem = listShort[index];
          }
          if (index === indexPlay) {
            return {
              ...newItem,
              isPlay:
                (item?.is_followed === 0 && item?.follow_flag === 1) ||
                newItem?.isPlay === false
                  ? false
                  : true,

              isMute: isSafari && isMobile ? true : false,
              //type: newItem?.medias && newItem?.medias[0]?.type,
              duration:
                newItem?.medias && newItem?.medias[0]?.type === 2
                  ? newItem?.medias[0]?.duration
                  : 0,
            };
          }
          return {
            ...newItem,
            isPlay: false,
            isMute: true,
            //type: newItem?.medias && newItem?.medias[0]?.type,
            duration:
              newItem?.medias && newItem?.medias[0]?.type === 2
                ? newItem?.medias[0]?.duration
                : 0,
          };
        });
        setListShort(listChange);
      }
    }
  }, [searchShortVideo, isLoadDataSearchShort]);

  const toggleFollowAllUser = useCallback(
    (index, userId, playValue = true, followValue = 1) => {
      const listClone = JSON.parse(JSON.stringify(listShort)) || [];
      if (listClone?.length > 0) {
        const newList = listClone?.map((item, indexItem) => {
          if (index === indexItem) {
            return {
              ...item,
              isPlay: playValue,
              is_followed: followValue,
            };
          } else if (item?.user_id === userId) {
            return {
              ...item,
              isPlay: false,
              is_followed: followValue,
            };
          }
          return item;
        });
        setListShort(newList);
      }
    },
    [listShort]
  );

  const updateList = useCallback(
    (indexItem) => {
      setIndexPlay(indexItem);
      if (indexItem === listShort?.length - 1) {
        setPage(page + 1);
      }
      if (listShort && listShort?.length > 0) {
        const listChange = listShort?.map((item, index) => {
          if (index === indexItem) {
            return {
              ...item,
              isPlay: item?.is_followed === 0 && item?.follow_flag === 1 ? false : true,
              isMute: isSafari && isMobile ? true : false,
              type: item?.medias && item?.medias[0]?.type,
            };
          }
          return {
            ...item,
            isPlay: false,
            isMute: true,
            type: item?.medias && item?.medias[0]?.type,
          };
        });
        setListShort(listChange);
      }
    },
    [listShort, page]
  );

  // const funCallApiListPostShort1 = useCallback(() => {
  //   if (searchShortVideoPagination?.current_page < searchShortVideoPagination?.total_pages) {
  //     dispatch(searchShortVideoAction(keyword, page, isMobile ? 5 : 10));
  //   }
  // }, [page]);
  // useEffect(() => {
  //   if (page === searchShortVideoPagination?.current_page) return;
  //   funCallApiListPostShort1();
  // }, [funCallApiListPostShort1]);

  const fetchNext = () => {
    // const pages = searchShortVideoPagination?.total / searchShortVideoPagination?.per_page;
    // if (pages > page) {
    //   setPage(page + 1);
    // }
  };

  const handleLikePost = (id) => {
    dispatch(postLikeDetail(id));
  };

  const handleFollowCreator = (id) => {
    dispatch(fanFollowCreator(id));
    setIsShowOtherBox({
      isShow: false,
      indexShow: -1,
    });
  };

  const handlePostBookMark = (post) => {
    dispatch(postBookMarkDetail(post?.id));
  };

  const toggleValueVideo = useCallback(
    (index, name = 'isPlay', value) => {
      const listClone = JSON.parse(JSON.stringify(listShort)) || [];
      if (listClone?.length > 0) {
        if (name === 'is_liked') {
          listClone[index][name] = value || !listClone[index][name];
          if (value === 1) {
            listClone[index]['number_like'] = listClone[index]['number_like'] + 1;
          } else {
            listClone[index]['number_like'] =
              listClone[index]['number_like'] > 0 ? listClone[index]['number_like'] - 1 : 0;
          }
        } else if (name === 'number_comment') {
          listClone[index]['number_comment'] =
            listClone[index]['number_comment'] + (value || 1);
        } else if (name === 'delete_reply_comment') {
          listClone[index]['number_comment'] = listClone[index]['number_comment'] - 1;
        } else if (name === 'delete_comment') {
          listClone[index]['number_comment'] = listClone[index]['number_comment'] - value;
        } else if (name === 'is_followed') {
          listClone[index][name] = value;
        } else {
          listClone[index][name] = value || !listClone[index][name];
        }
      }
      setListShort(listClone);
    },
    [listShort]
  );

  const toggleFollowAndPlay = useCallback(
    (index, userId) => {
      toggleFollowAllUser(index, userId, true, 1);
    },
    [toggleFollowAllUser]
  );

  useEffect(() => {
    if (listShort) {
      listRefVideo.current = listRefVideo.current.slice(0, listShort?.length);
    }
  }, [listShort]);

  useEffect(() => {
    if (
      indexIdx === searchShortVideo?.length - 1 &&
      page < searchShortVideoPagination?.total_pages
    ) {
      setPage(page + 1);
    }
  }, []);

  useEffect(() => {
    if (popupCopyLink) {
      document.getElementById('root').style.overflow = 'hidden';
      document.getElementById('root').style.position = 'fixed';
      document.getElementById('root').style.width = '100%';
    } else {
      // document.getElementById('root').style.overflow = 'auto';
      setOverflowTablet();
      document.getElementById('root').style.position = 'revert';
    }
  }, [popupCopyLink]);

  const renderItemVideo = (item, index, isLockScreen) => {
    const { medias } = item;
    if (medias) {
      if (medias[0]?.type === 2) {
        return (
          <LazyAwareSearchShort
            key={index}
            render={({ ref, wasIntersected, isIntersecting }) => {
              return (
                <div className='page-list-short-video-item__video' ref={ref}>
                  {wasIntersected ? (
                    <>
                      <VideoShortPlayer
                        item={item}
                        index={index}
                        indexPlay={indexPlay}
                        wasIntersected={wasIntersected}
                        isIntersecting={isIntersecting}
                        updateList={updateList}
                        isLockScreen={isLockScreen}
                      />
                      <LayerWrapClickVideo
                        onClick={() => {
                          toggleValueVideo(index);
                        }}
                      />
                      {renderIcon(item, index)}
                    </>
                  ) : (
                    <PlaceHolderItem />
                  )}
                </div>
              );
            }}
          />
        );
      } else {
        return (
          <LazyAwareSearchShort
            key={index}
            render={({ ref, wasIntersected, isIntersecting }) => {
              if (wasIntersected && isIntersecting) {
                if (index !== indexPlay) {
                  updateList(index);
                }
              }
              return (
                <div className='page-list-short-video-item__video' ref={ref}>
                  {wasIntersected ? (
                    <>
                      <Card
                        toggleValueVideo={() => {
                          toggleValueVideo(index);
                        }}
                        data={medias || []}
                        key={item?.id}
                        isMiniUrl={isLockScreen}
                        isIntersecting={isIntersecting}
                        isPlay={item?.isPlay}
                      />
                      {renderIcon(item, index)}
                    </>
                  ) : (
                    <PlaceHolderItem />
                  )}
                </div>
              );
            }}
          />
        );
      }
    }
  };

  const renderIcon = (item, index) => {
    return (
      <>
        <div
          className='play-icon index10'
          onClick={(e) => {
            e.stopPropagation();
            toggleValueVideo(index);
          }}>
          {!item?.isPlay && <PlayTikTokIcon />}
        </div>
        <svg
          onClick={() => {
            dispatch(clearIsLoadDataSearchShort());
            close();
          }}
          className='close-icon'
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          fill='none'
          viewBox='0 0 18 18'>
          <path
            stroke='currentColor'
            strokeLinecap='round'
            strokeWidth='1.5'
            d='M4.5 13.875l9.711-9.378M4.5 4.5l9.711 9.378'></path>
        </svg>
        <div
          className='mute-icon index10'
          onClick={() => {
            if (item?.type !== 1) {
              toggleValueVideo(index, 'isMute');
            }
          }}>
          {item?.isMute || item?.type === 1 ? <MuteShortVideoIcon /> : <MuteIconAct />}
        </div>
        <div className='block-info index10'>
          <div
            className={`avatar ${item?.isFullContent ? 'full-content' : ''}`}
            onClick={() => navigate(`${newPathUser}${item?.account_id}`)}>
            <ImgCustom screen='avatar_44_44' src={item?.avatar} alt='avatar11' />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span className='name'>{item?.account_name}</span>
              {!(item?.follow_flag === 1 && item?.is_followed === 0) && item?.user_id !== dataUser?.id && (
                <div
                  style={{
                    border: '1px solid #DC143C',
                    borderRadius: '20px',
                    alignSelf: 'flex-start',
                    padding: '0 5px',
                    marginLeft: '15px',
                    cursor: 'pointer',
                    fontSize: '12px',
                    fontWeight: '500',
                  }}
                  className={`btn-followOrUnFollow ${
                    item?.is_followed === 1 ? 'following' : 'no-follow'
                  } ${item?.is_followed === 1 && hoverId === item?.id ? 'hover' : ''}`}
                  onMouseOver={() => {
                    if (item?.is_followed === 1 && item?.id) {
                      !isMobile && setHoverId(item.id);
                    }
                  }}
                  onMouseDown={() => {
                    if (!isAuth) {
                      setIsShowOtherBox(false);
                      dispatch(logUserProfile(item));
                    } else {
                      handleFollowCreator(item?.user_id);
                      setIsShowOtherBox(false);
                      if (item?.follow_flag === 1) {
                        toggleFollowAllUser(index, item?.user_id, false, 0);
                      } else {
                        toggleFollowAllUser(
                          index,
                          item?.user_id,
                          true,
                          item?.is_followed === 1 ? 0 : 1
                        );
                      }
                    }
                  }}
                  onMouseLeave={() => {
                    if (item?.is_followed === 1) {
                      setHoverId(false);
                    }
                  }}>
                  {widthScreen > 991 ? (
                    item?.is_followed === 1
                    ? hoverId === item?.id
                      ? t('Creator.FollowList.unfollow')
                      : t('Creator.FollowList.following')
                    : t('Creator.FollowList.followUs')
                  ) : (
                    item?.is_followed === 1
                      ? t('Creator.FollowList.following')
                    : t('Creator.FollowList.followUs')
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={`content-des ${item?.isFullContent ? 'full-content' : ''}`}>
            <MarkDownRead
              isFullContent={item?.isFullContent}
              setIsFullContent={() => {
                toggleValueVideo(index, 'isFullContent');
              }}
              content={item?.content}
              charLimit={isMobile ? 32 : 48}
            />
            {/* {item?.isFullContent && (
              <div
                className='btn-read btn-read-less'
                onClick={() => {
                  toggleValueVideo(index, 'isFullContent');
                }}>
                {t('ReadMoreLess.lessText')}
              </div>
            )} */}
          </div>
        </div>
        <div
          className={`group-icon  ${
            item?.is_followed === 0 &&
            item?.follow_flag === 1 &&
            !isAdmin &&
            dataUser?.id !== item?.user_id
              ? 'index10'
              : 'index25'
          } ${isAdmin && 'disabled'}`}>
          <div className='icon-like'>
            <div
              className='bg-icon'
              onClick={() => {
                if (!isAuth) {
                  dispatch(logUserProfile(item));
                } else {
                  toggleValueVideo(index, 'is_liked', item?.is_liked === 1 ? 0 : 1);
                  handleLikePost(item?.id);
                }
              }}>
              {item?.is_liked === 1 ? (
                <>
                  <HeartActiveIcon className={`like-icon active`} />
                </>
              ) : (
                <>
                  <HeartIcon className={`like-icon`} />
                </>
              )}
            </div>
            <p className='mb-0 cls-text' style={{ color: '#fff' }}>
              {formatNumber(item?.number_like)}
            </p>
          </div>
          <div className='icon-chat index10'>
            <div className='bg-icon'>
              <ChatIcon
                className='chat-icon'
                onClick={() => {
                  setShowPopup({
                    ...isShowPopup,
                    status: true,
                    dataItem: item,
                    index: index,
                  });
                }}
              />
            </div>
            <p className='mb-0 cls-text' style={{ color: '#fff' }}>
              {formatNumber(item?.number_comment) || 0}
            </p>
          </div>
          <div className='bg-icon index10'>
            <ShareIcon
              onClick={() => {
                if (!isAuth) {
                  dispatch(logUserProfile(item));
                } else {
                  setPopupCopyLink({
                    link: `${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${item?.id}`,
                    title: item?.title,
                    id: item?.id,
                  });
                }
              }}
              className='like-icon'
            />
          </div>
          <div
            style={{ margin: '21px 0px' }}
            className='bg-icon index10'
            onClick={() => {
              if (!isAuth) {
                dispatch(logUserProfile(item));
              } else {
                toggleValueVideo(index, 'is_bookmarked', item?.is_bookmarked === 1 ? 0 : 1);
                handlePostBookMark(item);
              }
            }}>
            <IconBookmark
              style={{
                color: item?.is_bookmarked === 1 ? '#dc143c' : 'inherit',
              }}
              fill={item?.is_bookmarked === 1 ? '#dc143c' : 'none'}
              className='cls-icon'
            />
          </div>
          <div
            className='bg-icon index10'
            onClick={() => {
              setIsShowOtherBox({
                isShow: true,
                indexShow: index,
              });
            }}>
            <ThreePointIcon style={{ color: '#1A1A1A' }} className='three-point-icon' />
          </div>
          {isShowOtherBox?.isShow && isShowOtherBox?.indexShow === index && (
            <InfoBox
              item={item}
              index={index}
              handleFollowCreator={handleFollowCreator}
              setIsShowOtherBox={setIsShowOtherBox}
              toggleValueVideo={toggleValueVideo}
              toggleFollowAllUser={toggleFollowAllUser}
              setIsVisiblePopDeletePost={setIsVisiblePopDeletePost}
              navigate={navigate}
              t={t}
            />
          )}
        </div>
      </>
    );
  };

  const isLockScreen = (item) => {
    return (
      item?.is_followed === 0 &&
      item?.follow_flag === 1 &&
      dataUser?.id !== item?.user_id &&
      !isAdmin
    );
  };

  const [isVisiblePopUpDeletePost, setIsVisiblePopDeletePost] = useState(null);
  const [isVisiblePopUpDeletePostComplete, setIsVisiblePopDeletePostComplete] =
    useState(false);
  const deletePostTimeLine = () => {
    setIsVisiblePopDeletePost(null);
    const { id } = isVisiblePopUpDeletePost;
    dispatch(creatorDeleteArticle(id, () => setIsVisiblePopDeletePostComplete(true)));
  };

  return (
    <div>
      {isLoadingFollowCreate && <PopupLoading className={'popup-loading'} />}

      {isVisiblePopUpDeletePost && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePost(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.confirmDeletePost')}>
          <div className='btn cancel' onClick={() => setIsVisiblePopDeletePost(null)}>
            {t('Common.buttonNo')}
          </div>
          <div className='ok btn' onClick={deletePostTimeLine}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpDeletePostComplete && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePostComplete(e)}
          iconNote={true}
          text={t('Popup.deletePostSucess')}>
          <div
            className='ok btn'
            onClick={() => {
              setIsVisiblePopDeletePostComplete(false);
              close();
            }}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {isShowPopup?.status && (
        <PopupViewFullMess
          setFlgRenderCMT={setFlgRenderCMT}
          index={isShowPopup?.index}
          toggleValueVideo={toggleValueVideo}
          item={isShowPopup?.dataItem}
          toggleFollowAllUser={toggleFollowAllUser}
          close={() => {
            setShowPopup({
              ...isShowPopup,
              status: false,
              dataItem: {},
            });
          }}
        />
      )}
      {popupCopyLink && (
        <PopupSharePost
          isVisiblePopup={() => setPopupCopyLink(false)}
          data={popupCopyLink || {}}
        />
      )}
      <div className='popup-wrap-short-search'>
        <>
          {listShort?.length > 0 ? (
            <InfiniteLoad
              loading={loading && !listShort?.length}
              data={listShort}
              fetchNext={fetchNext}>
              <div
                className='page-list-short-video-search c154 popup-short'
                id='short-video-list-search'>
                {listShort.map((item, index) => (
                  <div
                    key={item?.id}
                    className={`page-list-short-video-search-item ${
                      isMobile ? 'isMobie' : ''
                    }`}>
                    {isLockScreen(item) && (
                        <div
                          style={item?.medias?.length === 0 ? { paddingBottom: '35px' } : {}}>
                          <LockScreen>
                            <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
                            {!isAdmin && (
                              <button
                                onClick={(e) => {
                                  if (!isAuth) {
                                    dispatch(logUserProfile(item));
                                  } else {
                                    e.stopPropagation();
                                    toggleFollowAndPlay(index, item?.user_id);
                                    handleFollowCreator(item?.user_id);
                                  }
                                }}>
                                {t('Common.follow')}
                              </button>
                            )}
                          </LockScreen>
                        </div>
                      )}
                    {renderItemVideo(item, index, isLockScreen(item))}
                  </div>
                ))}
              </div>
            </InfiniteLoad>
          ) : (
            <div className='empty-box'>
              {/* <div>{t('MenuSiderbar.creator.listShortVideoNull')}</div> */}
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default PopupSearchShortVideo;
