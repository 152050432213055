import { PopupLoading } from 'components';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LayoutAffiliator from '../../../../components/Layout/LayoutAffiliator';
import NavbarSalesManagement from './components/navbar';
import './index.scss';
import SalesHistory from './sales-history';
import SalesSumary from './sales-sumary';
const AffiliatorSaleManagement = () => {
  const { loading } = useSelector((state) => state.affiliator);
  const { loading: loading2 } = useSelector((state) => state.saleConfirmation);
  const { t } = useTranslation();
  const [tabActive, setTabActive] = useState(t('Common.sales_history'));
  return (
    <>
      {(loading || loading2) && <PopupLoading className={'popup-loading'} />}
      <LayoutAffiliator titlePage={t('Affiliator.AF004.salesManagementScreen')}>
        <div className={`affiliator-sales-management`}>
          <NavbarSalesManagement tabActive={(e) => setTabActive(e)} />
          <div className='content-box-affiliator'>
            {tabActive === t('Common.sales_history') ? (
              <SalesHistory tabActive={tabActive} />
            ) : (
              <SalesSumary />
            )}
          </div>
        </div>
      </LayoutAffiliator>
    </>
  );
};

export default AffiliatorSaleManagement;
