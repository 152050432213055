export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: '#1A1A1A',
    backgroundColor: state.isSelected ? '#FFDBDB' : 'transparent',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#FFDBDB',
      color: '#1A1A1A',
    },
    '&.content-right': {
      color: 'red',
    },
    svg: {
      color: 'red',
      display: state.isSelected ? 'block' : 'none',
    },
  }),
  control: (provided, state) => ({
    width: '100%',
    display: 'flex',
    minHeight: '44px',
    height: '44px',
    // border: state.isFocused ? '1px solid #86b7fe' : '1px solid #E5E6EB',
    border: '1px solid #E5E6EB',
    borderRadius: '6px',
    backgroundColor: state.isDisabled ? '#EFEFEF' : '#ffffff',
    color: state.isDisabled ? '#B1B6BB' : '#1A1A1A',
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 30,
    maxWidth: '343px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.08)',
    borderRadius: '6px',
    display: state?.selectProps?.notYetSearch ? 'none' : 'block',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#BEBEBE',
    fontWeight: 400,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    return { ...provided, opacity, color: '#1A1A1A', fontWeight: 400, marginTop: '-8px' };
  },
  multiValue: (provided) => ({
    ...provided,
    background: '#F2F3F5',
  }),
};
