import { LayoutCreatorRight, PopupAlert, PopupLoading } from 'components';
import DragNDrop from 'components/DragNDrop';
import { InputWrapper } from 'components/Input/InputWrapper';
import { newPathUser } from 'constants/layout/constant';
import withCreatorRole from 'hocs/withCreatorRole';
import { ArrowDownIcon } from 'images';
import { mapCountriesToSelectlabel } from 'pages/creator/sign-up';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { typeOf } from 'react-read-more-read-less';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import {
  editCreatorProfileByFanLogin,
  getListCountry,
  getUserInfo,
} from 'store/actions/users';
import {
  acceptedImageTypes,
  customStyles,
  getPresignedUrlS3,
  isEmptyObject,
  setFileNameS3,
  TEN_MB,
  uploadS3ByPresigned,
} from 'utils';
import ButtonSubmit from '../../../../components/Button/ButtonSubmit';
import '../../sign-up/index.scss';
import BackHeader from '../back-header';
import './index.scss';

function ProfileInfo() {
  const [isLoading, setIsLoading] = useState(false);
  let dataS3Update = {};
  const navigate = useNavigate();
  const [showPopupSuccess, setShowPopupSuccess] = useState(false);

  const { t } = useTranslation();
  const {
    loading,
    data: userProfile,
    listCountries,
    listCountryLoading,
    creatorUpdateProfileFailed,
    creatorUpdateProfileSuccess,
  } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm({
    mode: 'all',
  });

  useEffect(() => {
    dispatch(getListCountry());
  }, []);

  useEffect(() => {
    creatorUpdateProfileFailed && setIsLoading(false);
  }, [creatorUpdateProfileFailed]);

  useEffect(() => {
    creatorUpdateProfileSuccess && setShowPopupSuccess(true);
    setIsLoading(false);
  }, [creatorUpdateProfileSuccess]);

  const watchFullName = watch('name_japanese');
  const watchUsername = watch('name_romaji');
  const watchFrontSideCard = watch('identify_img_front', undefined);
  const watchBackSideCard = watch('identify_img_back', undefined);
  const watchCountryId = watch('country_id');
  const disableButton =
    !watchFullName ||
    !watchUsername ||
    !watchFrontSideCard?.name ||
    !watchBackSideCard?.name ||
    !watchCountryId ||
    !isEmptyObject(errors);
  console.log(!watchFrontSideCard?.name, !watchBackSideCard?.name);


  const onCustomValidate = (values) => {
    return new Promise((resolve, reject) => {
      let isError = false;
      if (!values?.identify_img_front) {
        setError('identify_img_front', {
          type: 'required',
          message: t('ValidateMsg.required'),
        });
        isError = true;
      } else if (!values?.identify_img_back) {
        setError('identify_img_back', {
          type: 'required',
          message: t('ValidateMsg.required'),
        });
        isError = true;
      }
      return isError === true ? reject(values) : resolve(values);
    });
  };
  const onSubmit = async (data) => {
    const result = await onCustomValidate(data);
    data = {
      ...data,
      step: 2,
      phone: ('' + data?.phone).replace(/\D/g, ''),
    };
    if (result) {
      await setIsLoading(true);
      const resultFront = await getPresignedUrlS3(
        'image',
        'uploads/output/images/identifier',
        setFileNameS3(watchFrontSideCard.name)
      );
      if (resultFront?.data) {
        dataS3Update = {
          ...dataS3Update,
          front_card: resultFront?.data?.file_path,
          front_card_pre_signed: resultFront?.data?.pre_signed,
          front_card_file: watchFrontSideCard,
        };
      }

      const resultBack = await getPresignedUrlS3(
        'image',
        'uploads/output/images/identifier',
        setFileNameS3(watchBackSideCard.name)
      );
      if (resultBack?.data) {
        dataS3Update = {
          ...dataS3Update,
          back_card: resultBack?.data?.file_path,
          back_card_pre_signed: resultBack?.data?.pre_signed,
          back_card_file: watchBackSideCard,
        };
      }

      const {
        front_card,
        front_card_pre_signed,
        front_card_file,
        back_card,
        back_card_pre_signed,
        back_card_file,
      } = dataS3Update;
      front_card && (await uploadS3ByPresigned(front_card_pre_signed, front_card_file));
      back_card && (await uploadS3ByPresigned(back_card_pre_signed, back_card_file));
      if (front_card && back_card) {
        const cardUpload = {
          identify_img_front: front_card,
          identify_img_back: back_card,
        };
        await dispatch(editCreatorProfileByFanLogin({ ...data, ...cardUpload }));
      }
    }
  };

  const handleBack = (status) => {
    navigate(`${newPathUser}`);
    setShowPopupSuccess(false);
    dispatch(getUserInfo());
  };

  return (
    <LayoutCreatorRight
      titlePage={t('ProfileSetting.titleProfileSetting')}
      classNameHeader={'disabled update-profile'}>
      {(loading || isLoading) && <PopupLoading className={'popup-loading'} />}
      {showPopupSuccess && (
        <PopupAlert
          header=''
          content={t('Popup.saved')}
          isVisiblePopup={() => setShowPopupSuccess(false)}>
          <button className='btn btn-danger' onClick={() => handleBack()}>
            {t('Common.Ok')}
          </button>
        </PopupAlert>
      )}
      <div className='bg-white creator-profile-info creator-profile-info-status-6'>
        <form
          className='fansite-form'
          encType='multipart/form-data'
          onSubmit={(e) => e.preventDefault()}>
          <React.Fragment>
            <div className='px-24 pt-24'>
              <InputWrapper
                id='fsite-japan-name'
                label={t('SignUp.nameJapanese')}
                error={errors?.name_japanese ? errors?.name_japanese?.message : ''}>
                <input
                  {...register('name_japanese', {
                    required: t('ValidateMsg.C002_VALIDATE_NAME_JAPAN_REQUIRED'),
                    maxLength: {
                      value: 50,
                      message: t('ValidateMsg.C002_VALIDATE_NAME_JAPAN_MAXSIZE'),
                    },
                    pattern: {
                      value: /^[ａ-ｚ﻿Ａ-Ｚぁ-んァ-ン一-龥　]+$/gu,
                      message: t('ValidateMsg.C002_VALIDATE_NAME_JAPAN_FORMAT'),
                    },
                  })}
                  onBlur={(e) => {
                    setValue('name_japanese', e.target.value.trim());
                    if (!e.target.value.trim()) {
                      setError('name_japanese', {
                        type: 'required',
                        message: t('ValidateMsg.C002_VALIDATE_NAME_JAPAN_REQUIRED'),
                      });
                    }
                  }}
                  placeholder={t('ValidateMsg.C002_VALIDATE_NAME_JAPAN_REQUIRED')}
                />
              </InputWrapper>

              <InputWrapper
                id='fsite-full-name'
                label={t('SignUp.nameRomaji')}
                error={errors?.name_romaji ? errors?.name_romaji?.message : ''}>
                <input
                  maxLength={50}
                  {...register('name_romaji', {
                    required: t('ValidateMsg.C002_VALIDATE_NAME_ROMAJI_REQUIRED'),
                    maxLength: {
                      value: 50,
                      message: t('ValidateMsg.C002_VALIDATE_NAME_ROMAJI_MAXSIZE'),
                    },
                    pattern: {
                      value: /^[A-Za-z 　]+$/gu,
                      message: t('ValidateMsg.nameRomajiFormat'),
                    },
                  })}
                  onBlur={(e) => {
                    setValue('name_romaji', e.target.value.trim());
                    if (!e.target.value.trim()) {
                      setError('name_romaji', {
                        type: 'required',
                        message: t('ValidateMsg.C002_VALIDATE_NAME_ROMAJI_REQUIRED'),
                      });
                    }
                  }}
                  placeholder={t('ValidateMsg.C002_VALIDATE_NAME_ROMAJI_REQUIRED')}
                />
              </InputWrapper>

              <InputWrapper
                id='fsite-phone-number'
                label={t('Common.phone')}
                required={true}
                error={errors?.phone ? errors?.phone?.message : ''}>
                <input
                  type='text'
                  className='fsite-input'
                  placeholder='0XX-XXXX-XXXX・0X-XXXX-XXXX・0120-XXXX-XXXX'
                  maxLength={14}
                  {...register('phone', {
                    required: t('ValidateMsg.PHONE_IS_REQUIRED'),
                    validate: (value) => {
                      const cleaned = ('' + value).replace(/\D/g, '');
                      const match10 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
                      const match11 = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
                      const match12 = cleaned.match(/^(\d{4})(\d{4})(\d{4})$/);

                      if (
                        (!match10 && !match11 && !match12) ||
                        (match12 && match12[1] !== '0120') ||
                        cleaned.substring(0, 1) !== '0'
                      ) {
                        return t('ValidateMsg.C002_VALIDATE_PHONE_FORMAT');
                      }
                    },
                    onChange: (e) => {
                      const value = e.target.value;
                      const cleaned = ('' + value).replace(/\D/g, '');
                      const match10 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);

                      if (match10) {
                        e.target.value = match10[1] + '-' + match10[2] + '-' + match10[3];
                      }

                      const match11 = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
                      if (match11) {
                        e.target.value = match11[1] + '-' + match11[2] + '-' + match11[3];
                      }

                      const match12 = cleaned.match(/^(\d{4})(\d{4})(\d{4})$/);
                      if (match12) {
                        if (match12[1] === '0120') {
                          e.target.value = '0120-' + match12[2] + '-' + match12[3];
                        } else {
                          e.target.value = match12[1] + '-' + match12[2] + '-' + match12[3];
                        }
                      }
                    },
                  })}
                />
              </InputWrapper>
              <InputWrapper
                id='fsite-nation'
                label={t('Common.country')}
                error={errors?.country_id ? errors?.country_id?.message : ''}>
                <div className='gender'>
                  <Controller
                    name='country_id'
                    control={control}
                    rules={{ required: t('ValidateMsg.required') }}
                    render={({ field }) => (
                      <Select
                        ref={field.ref}
                        options={mapCountriesToSelectlabel(listCountries)}
                        isLoading={listCountryLoading}
                        onChange={(val) => {
                          field.onChange(val.value);
                          setValue('country_name', val.label);
                        }}
                        placeholder={t('SignUp.placeHolderCountry')}
                        className='nation-zindex'
                        styles={customStyles}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                        noOptionsMessage={() => t('Common.searchNoResult')}
                      />
                    )}
                  />
                  <ArrowDownIcon />
                </div>
              </InputWrapper>
              <InputWrapper
                id='fsite-identify-card'
                label={t('SignUp.identification')}
                required={true}
                error={
                  errors?.identify_img_front
                    ? errors?.identify_img_front.message
                    : errors?.identify_img_back
                    ? errors?.identify_img_back.message
                    : ''
                }>
                <div className='row'>
                  <div className='col-sm-12 col-md-6'>
                    <DragNDrop
                      label={t('SignUp.surfaceSide')}
                      name='identify_img_front'
                      setValue={setValue}
                      register={register}
                      setError={setError}
                      clearErrors={clearErrors}
                      maxSize={TEN_MB}
                      isSelected={watchFrontSideCard}
                      accept={acceptedImageTypes.map((item) => item).join(',')}
                    />
                  </div>
                  <div className='col-sm-12 col-md-6'>
                    <DragNDrop
                      label={t('SignUp.backSide')}
                      name='identify_img_back'
                      setValue={setValue}
                      register={register}
                      setError={setError}
                      clearErrors={clearErrors}
                      maxSize={TEN_MB}
                      isSelected={watchBackSideCard}
                      accept={acceptedImageTypes.map((item) => item).join(',')}
                    />
                  </div>
                </div>
              </InputWrapper>
              <InputWrapper>
                <div className='row d-flex justify-content-center'>
                  <div className='col-12 col-sm-6 col-md-6 mb-3'>
                    <ButtonSubmit
                      className='btn bg-red w-100 font-weight-700 text-white app-btn'
                      disabled={isLoading || disableButton}
                      onClick={handleSubmit(onSubmit)}>
                      {t('Common.keep')}
                    </ButtonSubmit>
                  </div>
                </div>
              </InputWrapper>
            </div>
          </React.Fragment>
        </form>
      </div>
    </LayoutCreatorRight>
  );
}

export default withCreatorRole(ProfileInfo);
