import { ImgCustom } from 'components';
import { ArrowLeftIcon } from 'images';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';

import { TYPES } from '../../store/actions';
import { decryptPath, updateStatus } from 'utils';

import {
  getListCocoRecommend,
  getListPostContentRecommend,
  getListTopDonate,
} from 'store/actions/recommend';
import {
  CustomOption,
  customStyles as customStylesReselect,
  formatCoin,
  isAdmin,
  isAgency,
  RANK1_BIG_SIZE,
  RANK2_BIG_SIZE,
  RANK3_BIG_SIZE,
  widthSreen,
} from 'utils';
import InfiniteLoad from '../Paging/InfiniteLoad';
import { Styled } from './styles';
import { LazyAware } from 'components/v2/LazyAware';
import { newPathUser } from 'constants/layout/constant';
import { fanFollowCreator, resetDataInfoOther, resetIDHomePage, resetPopupPostShortVideo, setIdScrollBackRecommend } from 'store/actions/usersNew';
import { isMobile } from 'react-device-detect';
import { logUserProfile } from 'store/actions/myPage';
import Storage from 'utils/storage';
import bannerLeft from 'images/PC-oppai-banner-330-186.gif';
import { STORAGE } from 'utils';

const SidebarRecommendPost = ({ hideListPost = false, ...props }) => {
  const dispatch = useDispatch();
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [listCocoFansRe, setListCocoFansRe] = useState([]);

  const [listPostContentRe, setListPostContentRe] = useState([]);
  const [listDonateLive, setListDonateLive] = useState({
    data: [],
    pagination: {},
    loading: false,
  });
  const [hoverId, setHoverId] = useState(false);
  const [typeDonate, setTypeDonate] = useState(1);
  const [focusDonate, setFocusDonate] = useState(false);
  const [pageDonate, setPageDonate] = useState(1);

  const user = useSelector((state) => state.users);

  useEffect(() => {
    setListCocoFansRe(user?.recommendedData);
    setListPostContentRe(user?.listPostrecommendedData);
  }, [user?.recommendedData, user?.listPostrecommendedData]);

  const onChangeTypeDonate = (value) => {
    setTypeDonate(value.value);
    setPageDonate(1);
    setListDonateLive({ data: [], pagination: {}, loading: false });
  };
  const optionTypeDonate = [
    { label: t('SidebarRecommendPost.selectTypeLiving'), value: 1 },
    { label: t('SidebarRecommendPost.selectTypeMonth'), value: 2 },
  ];
  const WithDropdownIndicatorYear = () => {
    return <ArrowLeftIcon className={`${focusDonate ? 'focus' : 'no-focus'}`} />;
  };
  const customStyles = {
    ...customStylesReselect,
    control: () => ({
      minWidth: i18n.language === 'en' ? 150 : 131,
      width: 'max-content',
      display: 'flex',
      height: '44px',
      border: '1px solid #b1b6bb',
      borderRadius: '6px',
      background: '#fff',
      fontSize: 14,
    }),
  };

  const handleCallApi1 = useCallback(() => {
    if (!pathname.includes('/lives/list/live')) {
      if (!user.loadingRecommended) {
        if (!isAgency && !isAdmin && Storage.get(STORAGE.USER_ACCESS_TOKEN)) {
          dispatch(
            getListCocoRecommend(
              (data) => {
                dispatch(
                  updateStatus(TYPES.LOADING_RECOMMENDED_DATA, {
                    data: data,
                  })
                );

                // setListCocoFansRe(data);
              },
              () => {}
            )
          );
        }
        // !hideListPost &&
        //   dispatch(
        //     getListPostContentRecommend((data) => {
        //       dispatch(
        //         updateStatus(TYPES.LOADING_LIST_POST_CONTENT_RECOMMEND, {
        //           data: data,
        //         })
        //       );
        //       // setListPostContentRe(data);
        //     })
        //   );
      }
    }
  }, [pathname]);

  useEffect(() => {
    handleCallApi1();
  }, [handleCallApi1]);

  const handleCallApi2 = useCallback(() => {
    if (widthSreen >= 1024) {
      if (pathname.includes('/lives/list/live')) {
        setListDonateLive((prev) => ({ ...prev, loading: true }));
        dispatch(
          getListTopDonate({ type: typeDonate, page: pageDonate, limit: 10 }, (response) =>
            setListDonateLive({
              data: [...listDonateLive.data, ...response.data],
              pagination: response.pagination,
              loading: false,
            })
          ),
          () => {
            setListDonateLive((prev) => ({ ...prev, loading: false }));
          }
        );
      }
    }
  }, [typeDonate, pageDonate]);

  const handleFollowRecomment = (e) => {
    dispatch(
      fanFollowCreator(e, (data) => {
        const newData = listCocoFansRe?.map((item) => {
          if (+item.id === +e) {
            const newItem = { ...item };
            if (newItem.is_follow === 1) {
              newItem.is_follow = 0;
            } else {
              newItem.is_follow = 1;
            }
            return newItem;
          } else return item;
        });
        setListCocoFansRe(newData);
      })
    );
  };

  const fetchNext = () => {
    if (listDonateLive.pagination.total_pages > pageDonate) {
      setPageDonate(pageDonate + 1);
    }
  };

  useEffect(() => {
    handleCallApi2();
  }, [handleCallApi2]);

  return (
    <Styled.WrapperFixed top0={hideListPost}>
      <Styled.Wrapper {...props} height100={hideListPost}>
        <>
          {/* <Styled.PostContentRecommendWrapper>
            <Link to='https://sharegram-blog.com/oppai-coin-lp/' target='_blank' className='link_route'>
              <ImgCustom
                isPlaceholderAvatar
                screen='banner_330_186_pc'
                src={bannerLeft}
              />
            </Link>
          </Styled.PostContentRecommendWrapper> */}
          <Styled.CocoFansRecommendWrapper height100={hideListPost}>
            <Styled.Title fontSize16={hideListPost}>
              {t('SidebarRecommendPost.titleCocoFans')}
            </Styled.Title>
            <Styled.ListCocoFansRecommend paddingBottomLastChild={hideListPost}>
              {listCocoFansRe?.map((item) => (
                <LazyAware
                  key={item?.id}
                  render={({ ref, wasIntersected }) => (
                    <div
                      ref={ref}
                      className='item'
                      key={item?.id}
                      onClick={() => {
                        dispatch(setIdScrollBackRecommend(`recomend`, pathname))
                        dispatch(resetIDHomePage());
                        dispatch(resetDataInfoOther())
                        !isAdmin && !isAgency && dispatch(resetPopupPostShortVideo());
                        navigate(`${newPathUser}${item?.account_id}`);
                      }}>
                      {/* {wasIntersected && ( */}
                      <>
                        <ImgCustom
                          isPlaceholderAvatar
                          screen='recommend_top_56_56'
                          src={`${item?.avatar}`}
                        />

                        <div className='info'>
                          <div className='name'>{item?.account_name}</div>
                          <div className='content'>{item?.description}</div>
                        </div>
                        <div
                          className={`btn-followOrUnFollow ${
                            item?.is_follow === 1 ? 'following' : 'no-follow'
                          } ${item?.is_follow === 1 && hoverId === item?.id ? 'hover' : ''}`}
                          onMouseOver={() => {
                            if (item?.is_follow === 1 && item?.id) {
                              !isMobile && setHoverId(item.id);
                            }
                          }}
                          onMouseLeave={() => {
                            if (item?.is_follow === 1) {
                              setHoverId(false);
                            }
                          }}
                          onClick={(e) => {
                            if (!isAuth) {
                              e.stopPropagation();
                              dispatch(logUserProfile(item));
                            } else {
                              e.stopPropagation();
                              handleFollowRecomment(item?.id);
                            }
                          }}>
                          {item?.is_follow === 1
                            ? hoverId === item?.id
                              ? t('Creator.FollowList.unfollow')
                              : t('Creator.FollowList.following')
                            : t('Creator.FollowList.followUs')}
                        </div>
                      </>
                      {/* )} */}
                    </div>
                  )}
                />
              ))}
              {!listCocoFansRe?.length && (
                <Styled.NoData>{t('SidebarRecommendPost.noDataListCreator')}</Styled.NoData>
              )}
            </Styled.ListCocoFansRecommend>
          </Styled.CocoFansRecommendWrapper>
          {/* {!hideListPost && (
            <Styled.PostContentRecommendWrapper>
              <Styled.Title>{t('SidebarRecommendPost.titlePostContentRe')}</Styled.Title>
              <Styled.ListPostContentRecommend>
                {listPostContentRe?.length > 0 &&
                  listPostContentRe?.map((item) => (
                    <LazyAware
                      key={item?.id}
                      render={({ ref, wasIntersected }) => (
                        <div
                          ref={ref}
                          className='item'
                          key={item?.id}
                          onClick={() => {
                            if (!isAuth) {
                              dispatch(logUserProfile());
                            } else {
                              !isAdmin &&
                                !isAgency &&
                                navigate(`${newPathUser}post-sale/${item?.id}`);
                            }
                          }}>
                          {wasIntersected && (
                            <>
                              <Styled.BlueImg
                                url={`${decryptPath(item?.url)}?width=290&height=auto`}
                                width={290}
                                height={180}>
                                <div className='bg-img'></div>
                                <ImgCustom
                                  isPlaceholderAvatar
                                  screen='recommend_sale_footer_290_180'
                                  src={`${decryptPath(item?.url)}`}
                                  avatarErr={false}
                                  style={{
                                    objectFit: 'contain',
                                    position: 'absolute',
                                    zIndex: '14',
                                    background: 'transparent',
                                  }}
                                />
                              </Styled.BlueImg>
                              <div className='info'>
                                <div className='name'>{item?.title}</div>
                                <div className='content'>
                                  {t('SidebarRecommendPost.sellingPrice')}
                                  {formatCoin(item?.price)} Pt
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    />
                  ))}
                {!listPostContentRe?.length && (
                  <Styled.NoData>{t('SidebarRecommendPost.noDataListPost')}</Styled.NoData>
                )}
              </Styled.ListPostContentRecommend>
            </Styled.PostContentRecommendWrapper>
          )} */}
        </>
        {/* ) : (
          <>
            <Styled.DonateWrapper>
              <Styled.Title>{t('SidebarRecommendPost.titleDonate')}</Styled.Title>
              <Styled.SelectDonate
                onBlur={() => setFocusDonate(false)}
                onClick={() => setFocusDonate(!focusDonate)}>
                <Select
                  options={optionTypeDonate}
                  isSearchable={false}
                  isClearable={false}
                  defaultValue={optionTypeDonate[0]}
                  onChange={onChangeTypeDonate}
                  styles={customStyles}
                  components={{
                    Option: CustomOption,
                    DropdownIndicator: WithDropdownIndicatorYear,
                  }}
                />
              </Styled.SelectDonate>
              <Styled.ListDonate id='sidebarListDonate'>
                <InfiniteLoad
                  loading={listDonateLive.loading}
                  data={listDonateLive.data}
                  fetchNext={fetchNext}
                  scrollableTargetCustom='sidebarListDonate'>
                  {listDonateLive.data?.map((item, index) => (
                    <div className='item' key={item?.id}>
                      {item.rank === 1 ? (
                        <ImgCustom
                          className='rank-icon'
                          src={RANK1_BIG_SIZE}
                          alt='rank-icon'
                        />
                      ) : item.rank === 2 ? (
                        <ImgCustom
                          className='rank-icon'
                          src={RANK2_BIG_SIZE}
                          alt='rank-icon'
                        />
                      ) : item.rank === 3 ? (
                        <ImgCustom
                          className='rank-icon'
                          src={RANK3_BIG_SIZE}
                          alt='rank-icon'
                        />
                      ) : (
                        <span className='number-ranking'>{item.rank}</span>
                      )}
                      <ImgCustom className='avatar' src={`${item?.user?.avatar}`} />
                      <div className='name'>
                        {item?.user?.full_name || item?.user?.account_name}
                      </div>
                    </div>
                  ))}
                </InfiniteLoad>

                {!listDonateLive.data?.length && (
                  <Styled.NoData>{t('SidebarRecommendPost.noDataTopDonate')}</Styled.NoData>
                )}
              </Styled.ListDonate>
            </Styled.DonateWrapper>
          </>
        )} */}
      </Styled.Wrapper>
    </Styled.WrapperFixed>
  );
};

export default SidebarRecommendPost;
