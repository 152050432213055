import classNames from 'classnames';
import ArrowLeft from 'images/ic-arrow-left.svg';
import ArrowRight from 'images/ic-arrow-right.svg';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { range } from 'ramda';

import { isAdmin as IS_ADMIN, formatDateToString } from 'utils';
import { ImgCustom } from '..';

import {
  createDaysForCurrentMonth,
  createDaysForNextMonth,
  createDaysForPreviousMonth,
  getYearDropdownOptions,
} from './helpers';
import './style.scss';
import {
  NextMonthIcon,
  NextMonthIconNew,
  NextYearIcon,
  NextYearIconNew,
  PrevMonthIcon,
  PrevMonthIconNew,
  PrevYearIcon,
  PrevYearIconNew,
} from 'images';

Calendar.propTypes = {
  yearAndMonth: PropTypes.arrayOf(PropTypes.number).isRequired, // ex: [2022, 2] => Feb 2022
  onYearAndMonthChange: PropTypes.func.isRequired,
  renderDay: PropTypes.func,
};
export default function Calendar({
  yearAndMonth,
  onYearAndMonthChange,
  renderDay = () => null,
  isCalendarPage = false,
}) {
  const [year, month] = yearAndMonth;
  const { t } = useTranslation();

  let currentMonthDays = createDaysForCurrentMonth(year, month);
  let previousMonthDays = createDaysForPreviousMonth(year, month, currentMonthDays);
  let nextMonthDays = createDaysForNextMonth(year, month, currentMonthDays);
  let calendarGridDayObjects = [...previousMonthDays, ...currentMonthDays, ...nextMonthDays];

  const handleMonthNavBackButtonClick = () => {
    let nextYear = year;
    let nextMonth = month - 1;
    if (nextMonth === 0) {
      nextMonth = 12;
      nextYear = year - 1;
    }
    onYearAndMonthChange([nextYear, nextMonth]);
  };

  const handleMonthNavForwardButtonClick = () => {
    let nextYear = year;
    let nextMonth = month + 1;
    if (nextMonth === 13) {
      nextMonth = 1;
      nextYear = year + 1;
    }
    onYearAndMonthChange([nextYear, nextMonth]);
  };

  const handleYearNavForwardButtonClick = () => {
    const nextYear = year + 1;
    const nextMonth = month;
    onYearAndMonthChange([nextYear, nextMonth]);
  };

  const handleYearNavBackButtonClick = () => {
    const nextYear = year - 1;
    const nextMonth = month;
    onYearAndMonthChange([nextYear, nextMonth]);
  };

  const handleMonthSelect = (evt) => {
    let nextYear = year;
    let nextMonth = parseInt(evt.target.value, 10);
    onYearAndMonthChange([nextYear, nextMonth]);
  };

  const handleYearSelect = (evt) => {
    let nextMonth = month;
    let nextYear = parseInt(evt.target.value, 10);
    onYearAndMonthChange([nextYear, nextMonth]);
  };

  function getMonthDropdownOptions() {
    const monthOfYear = [
      `${t('Common.months.jan')}`,
      `${t('Common.months.feb')}`,
      `${t('Common.months.mar')}`,
      `${t('Common.months.apr')}`,
      `${t('Common.months.may')}`,
      `${t('Common.months.jun')}`,
      `${t('Common.months.jul')}`,
      `${t('Common.months.aug')}`,
      `${t('Common.months.sep')}`,
      `${t('Common.months.oct')}`,
      `${t('Common.months.nov')}`,
      `${t('Common.months.dec')}`,
    ];
    return range(1, 13).map((m) => ({
      value: m,
      label: monthOfYear[m - 1],
    }));
  }

  const daysOfWeek = [
    t('Common.daysOfWeek.sun'),
    t('Common.daysOfWeek.mon'),
    t('Common.daysOfWeek.tue'),
    t('Common.daysOfWeek.wed'),
    t('Common.daysOfWeek.thu'),
    t('Common.daysOfWeek.fri'),
    t('Common.daysOfWeek.sat'),
  ];

  return (
    <div className={`calendar-root custom-calendar ${IS_ADMIN ? 'admin' : ''}`}>
      <div className='navigation-header'>
        <div className='month-nav-arrow-buttons'>
          {/* <ImgCustom
            src={ArrowLeft}
            onClick={() => !IS_ADMIN && handleMonthNavBackButtonClick()}
            alt='arrow-left'
            className={IS_ADMIN ? 'admin' : ''}
          /> */}

          {isCalendarPage ? (
            <>
              <PrevYearIconNew onClick={() => !IS_ADMIN && handleYearNavBackButtonClick()} />
              <PrevMonthIconNew onClick={() => !IS_ADMIN && handleMonthNavBackButtonClick()} />
            </>
          ) : (
            <>
              <PrevYearIcon onClick={() => !IS_ADMIN && handleYearNavBackButtonClick()} />
              <PrevMonthIcon onClick={() => !IS_ADMIN && handleMonthNavBackButtonClick()} />
            </>
          )}
        </div>
        <div className='month-year'>
          <select
            className='year-select'
            value={year}
            onChange={handleYearSelect}
            disabled={IS_ADMIN}>
            {getYearDropdownOptions(year).map(({ label, value }) => (
              <option value={value} key={value}>
                {`${label}${t('Common.year')}`}
              </option>
            ))}
          </select>
          <select
            className='month-select'
            value={month}
            onChange={handleMonthSelect}
            disabled={IS_ADMIN}>
            {getMonthDropdownOptions().map(({ label, value }) => (
              <option value={value} key={value}>
                {`${label}${t('Common.month')}`}
              </option>
            ))}
          </select>
        </div>
        <div className='month-nav-arrow-buttons'>
          {/* <ImgCustom
            src={ArrowRight}
            onClick={() => !IS_ADMIN && handleMonthNavForwardButtonClick()}
            alt='arrow-right'
            className={IS_ADMIN ? 'admin' : ''}
          /> */}

          {isCalendarPage ? (
            <>
              <NextMonthIconNew
                onClick={() => !IS_ADMIN && handleMonthNavForwardButtonClick()}
              />
              <NextYearIconNew
                onClick={() => !IS_ADMIN && handleYearNavForwardButtonClick()}
              />
            </>
          ) : (
            <>
              <NextMonthIcon onClick={() => !IS_ADMIN && handleMonthNavForwardButtonClick()} />
              <NextYearIcon onClick={() => !IS_ADMIN && handleYearNavForwardButtonClick()} />
            </>
          )}
        </div>
      </div>
      <div className='days-of-week'>
        {daysOfWeek.map((day) => (
          <div key={day} className={'day-of-week-header-cell'}>
            {day}
          </div>
        ))}
      </div>
      <div className={`days-grid`}>
        {calendarGridDayObjects.map((day) => (
          <div
            key={day.dateString}
            className={classNames('day-grid-item-container', {
              'current-month': day.isCurrentMonth,
            })}>
            <div
              className='day-content-wrapper'
              onClick={() => {
                const { dateString } = day;
                const checkMonth = dateString.split('-')?.[1];
                const checkYear = dateString.split('-')?.[0];
                if ((+checkMonth !== month || +checkYear !== year) && !IS_ADMIN) {
                  let nextMonth = parseInt(checkMonth, 10);
                  let nextYear = parseInt(checkYear, 10);
                  onYearAndMonthChange([nextYear, nextMonth]);
                }
              }}>
              {renderDay(day)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

CalendarDayHeader.propTypes = {
  calendarDayObject: PropTypes.object.isRequired,
};

export function CalendarDayHeader({
  listEventDate = [],
  calendarDayObject,
  now,
  setNow,
  setParams,
  setIdAndNavigate,
  clearIdScrollBackRecommend,
}) {
  const date = new Date();
  const isNow = formatDateToString(date) === calendarDayObject.dateString;
  const isEvent = listEventDate.includes(calendarDayObject.dateString);
  const isSelected = now === calendarDayObject.dateString;

  return (
    <div
      className={
        `day-grid-item-header ` +
        (isNow ? ' --now' : '') +
        (isEvent ? ' --event' : '') +
        (isSelected ? ' --selected' : '')
      }
      onClick={() => {
        if (!IS_ADMIN) {
          setIdAndNavigate(null);
          clearIdScrollBackRecommend();
          setNow(calendarDayObject.dateString);
          setParams({ date: calendarDayObject.dateString });
        }
      }}>
      {calendarDayObject.dayOfMonth}
    </div>
  );
}
