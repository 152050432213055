/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';

const initialState = {
  status: null,
  loading: false,
  error: null,
  listTopFans: [],
  loadingListTopFans: false,
  listTransactions: [],
  loadingListTransactions: false,
  dataRevenue: {},
  loadingDataRevenue: false,
  dataWithdrawRequest: {},
  paginationTransaction: {},
  listTransactionsCSV: [],
  loadingListTransactionsCSV: false,
  settingLimitPayment: null,
  idSale: null
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.POST_USER_WITHDRAW_REQUEST_REQUEST:
    case TYPES.GET_SETTING_LIMIT_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
      };
    case TYPES.GET_TOP_FANS_REQUEST:
      return {
        ...state,
        loadingListTopFans: true,
        status: null,
      };
    case TYPES.GET_TRANSACTIONS_REQUEST:
      return {
        ...state,
        loadingListTransactions: true,
        status: null,
      };

    case TYPES.GET_TRANSACTIONS_CSV_REQUEST:
      return {
        ...state,
        loadingListTransactionsCSV: true,
        status: null,
      };
    case TYPES.GET_REVENUE_REQUEST:
      return {
        ...state,
        loadingDataRevenue: true,
        status: null,
      };
    case TYPES.GET_TOP_FANS_SUCCESS:
      return {
        ...state,
        loadingListTopFans: false,
        listTopFans: action?.data?.data?.data,
      };

    case TYPES.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loadingListTransactions: false,
        listTransactions: action?.data?.data?.data,
        paginationTransaction: action?.data?.data?.pagination,
      };
    case TYPES.GET_TRANSACTIONS_CSV_SUCCESS:
      return {
        ...state,
        loadingListTransactionsCSV: false,
        listTransactionsCSV: action?.data?.data,
      };

    case TYPES.GET_REVENUE_SUCCESS:
      return {
        ...state,
        loadingDataRevenue: false,
        dataRevenue: action?.data?.data,
      };

    case TYPES.POST_USER_WITHDRAW_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case TYPES.GET_SETTING_LIMIT_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        settingLimitPayment: action?.data?.data?.data,
      };

    case TYPES.GET_TOP_FANS_FAILED:
    case TYPES.GET_REVENUE_FAILED:
    case TYPES.GET_TRANSACTIONS_FAILED:
    case TYPES.POST_USER_WITHDRAW_REQUEST_FAILED:
    case TYPES.GET_TRANSACTIONS_CSV_FAILED:
    case TYPES.GET_SETTING_LIMIT_PAYMENT_FAILED:
      return {
        ...state,
        loading: false,
        loadingDataRevenue: false,
        loadingListTopFans: false,
        loadingListTransactions: false,
        loadingListTransactionsCSV: false,
        status: action.type,
      };
    case 'SET_ID_SALE':
      return {
        ...state,
        idSale: action.data,
      };
    case 'RESET_ID_SALE':
      return {
        ...state,
        listTransactions: [],
        idSale: null
      };
    case 'RESET_ACTION_SALE_CONFIRMATION':
      return {
        ...state,
        loading: false,
        loadingDataRevenue: false,
        loadingListTopFans: false,
        loadingListTransactions: false,
        loadingListTransactionsCSV: false,
        status: null,
        listTopFans: [],
        //listTransactions: [],
        listRevenue: [],
        dataWithdrawRequest: {},
        listTransactionsCSV: [],
      };

    default:
      return state;
  }
};
