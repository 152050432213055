import styled from 'styled-components';
export const StyledNavbarF025 = {
  Wrapper: styled.div`
    height: 50px;
    display: flex;
    justify-content: space-between;
  `,
  Item: styled.div`
    color: #787880;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 100%;
    border-bottom: 1px solid #eeeeee;
    cursor: pointer;
    &.active {
      color: #ff0000;
      border-bottom: 2px solid #ff0000;
    }
  `,
};
