import { ImgCustom, LayoutCreatorRight, PopupSharePost } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import withCreatorRole from 'hocs/withCreatorRole';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { newPathUser } from 'constants/layout/constant';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import {
  CommentIcon,
  HeartIcon,
  IconBookmark,
  NoSearchList,
  ShareIcon,
  SusessPackageIcon,
} from 'images';
import { isMobile, isTablet } from 'react-device-detect';
import { logUserProfile } from 'store/actions/myPage';
import {
  IMG_DEMO,
  IMG_NO_WATER_MARK_MOBILE,
  STORAGE,
  convertToCurrentTime,
  decryptPath,
  formatCoin,
  formatNumber,
  isAdmin,
  msToTime,
} from 'utils';
import Storage from 'utils/storage';
import { getEnv } from 'configs/env';
import img_no_water_mark_mobile_2_column_post_content_en from 'images/img_no_water_mark_mobile_2_column_post_content_en.jpg';
import img_no_water_mark_mobile_2_column_post_content from 'images/img_no_water_mark_mobile_2_column_post_content.jpg';
import i18n from 'translate/i18n';
import { StyledTimeLine } from 'components/DetailCard/styles';
import {
  getIDSearchSale,
  postBookMark,
  postLikeLive,
  searchSaleContentAction,
  setIdScrollBackRecommend,
} from 'store/actions/usersNew';
import useUserRole from 'hooks/useUserRole';
import sale_lable from 'images/sale.png';

const SearchSaleContent = ({
  keyword,
  loadingClickSearch,
  setFirstLoading,
  page,
  setPage,
  flgSearch,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const { isIdol } = useUserRole();
  const { dataUser, loading: loadingUser } = useSelector((state) => state.users);
  const {
    searchSaleContent = [],
    searchSaleContentPagination,
    dataInfoOther,
    idSearchSale,
    initPageSeachSale,
  } = useSelector((state) => state.usersNew);
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const keywordParams = searchParams.get('keyword');
  const navigate = useNavigate();
  const fetchNext = () => {
    const pages = searchSaleContentPagination?.total / searchSaleContentPagination?.per_page;
    if (pages > page) {
      funCallApi(page + 1);
      setPage(page + 1);
    }
  };

  useEffect(() => {
    if (idSearchSale) {
      const idPost = document.getElementById(idSearchSale.id);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idSearchSale]);

  const funCallApi = useCallback(
    (rs) => {
      setIsLoading(true);
      dispatch(
        searchSaleContentAction(
          !flgSearch && !keywordParams ? '' : keyword,
          rs,
          10,
          (_response) => setIsLoading(false),
          (_errors) => setIsLoading(false)
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, keyword]
  );

  useEffect(() => {
    if (initPageSeachSale < 1) {
      setIsLoading(true);
      setFirstLoading(true);
      dispatch(
        searchSaleContentAction(
          keyword,
          page,
          10,
          (_response) => {
            setFirstLoading(false);
            setIsLoading(false);
          },
          (_errors) => setIsLoading(false)
        )
      );
    }
  }, []);

  const checkDisabledAction = (item) => {
    if (
      (item?.is_followed === 0 &&
        item?.follow_flag === 1 &&
        dataUser?.id !== dataInfoOther?.id) ||
      (item?.is_media_display === 0 && item?.type === 1) ||
      isAdmin
    ) {
      return true;
    }
    return false;
  };

  const handleSharePost = (data) => {
    setPopupCopyLink(data);
  };

  const bookmarkChange = (post) => dispatch(postBookMark(post));

  const handleLikePost = (post) => dispatch(postLikeLive(post));

  const navigateByLink = (postId, type) => {
    navigate(
      `${newPathUser}post${
        Boolean(type === 2) ? '-sale' : Boolean(type === 3 || type === 4) ? '' : ''
      }/update/${postId}`,
      {
        state: {
          redirectBack: '/search',
        },
      }
    );
  };

  const msToTime = (s) => {
    // Pad to 2 or 3 digits, default is 2
    var pad = (n, z = 2) => ('00' + n).slice(-z);
    return (
      pad((s / 3.6e6) | 0) +
      ':' +
      pad(((s % 3.6e6) / 6e4) | 0) +
      ':' +
      pad(((s % 6e4) / 1000) | 0)
    );
  };

  const renderImageToPostTab2 = (post) => {
    const item = post?.media_preview;
    return (
      <StyledTimeLine.BlueImg
        url={`${decryptPath(item?.url, { isMini: true })}`}
        height={isMobile ? 193 : 183}
        width={isMobile ? 343 : 326}>
        <div className='bg-img br-10'></div>
        <ImgCustom
          screen={`${isMobile ? 'post_183_auto_pc' : 'post_326_auto_pc'}`}
          src={item ? `${item?.url}` : IMG_NO_WATER_MARK_MOBILE}
          alt=''
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            cursor: 'pointer',
            position: 'absolute',
            zIndex: '10',
            background: 'transparent',
          }}
          avatarErr={false}
          onClick={() => {
            dispatch(getIDSearchSale({ id: post?.id, tab: 2, keyword: keyword, page: page }));
          }}
        />
        <div
          className='account-info block-card-sale'
          onClick={(e) => {
            e.stopPropagation();
            dispatch(setIdScrollBackRecommend(`recomend`, pathname));
            dispatch(getIDSearchSale({ id: post?.id, tab: 2, keyword: keyword, page: page }));
            navigate(`${newPathUser}${post?.account_id}`);
          }}>
          <ImgCustom
            className='account-avatar'
            isPlaceholderAvatar
            screen='avatar_mini_32_32'
            alt='avatar'
            src={decryptPath(post?.avatar)}
          />
          <span className='account-name'>{post?.account_name}</span>
        </div>
      </StyledTimeLine.BlueImg>
    );
  };

  return (
    <>
      {popupCopyLink && (
        <PopupSharePost
          isVisiblePopup={() => setPopupCopyLink(false)}
          data={popupCopyLink || {}}
        />
      )}
      <div
        style={{ borderBottom: 'none' }}
        className={`search-short-video-page row card-item--detail2 my-page-item ${
          isIdol ? 'creator' : 'fan'
        }`}>
        <InfiniteLoad
          style={{ overflow: 'hidden' }}
          className='row two-column d-flex'
          loading={isLoading}
          data={searchSaleContent}
          fetchNext={() => fetchNext()}>
          <>
            {(isLoading && !searchSaleContent.length) || loadingClickSearch ? (
              <div className='shimmer-loading' style={{ paddingTop: '0' }}>
                <ShimmerPostDetails hasImage imageType='circular' title />
              </div>
            ) : (
              <>
                {searchSaleContent.length > 0 ? (
                  searchSaleContent.map((post, index) => (
                    <div
                      key={index}
                      id={post?.id}
                      className='col-6 cs-col mobile-tab3 style-mobile'
                      style={{
                        height: `${isMobile ? '183px' : '193px'}`,
                        marginBottom: `${isTablet ? '140px' : ''}`,
                      }}>
                      <div
                        className='align-items-center justify-content-center'
                        onClick={() => {
                          dispatch(setIdScrollBackRecommend(`recomend`, pathname));
                          dispatch(
                            getIDSearchSale({
                              id: post?.id,
                              tab: 2,
                              keyword: keyword,
                              page: page,
                            })
                          );
                          navigate(`${newPathUser}post-sale/${post?.id}`, {
                            state: {
                              redirectBack: '/search',
                            },
                          });
                        }}
                        style={{
                          position: 'relative',
                          borderTopLeftRadius: '10px',
                          borderTopRightRadius: '10px',
                          overflow: 'hidden',
                          cursor: 'pointer',
                          aspectRatio: '16/9',
                        }}>
                        {post?.media_preview?.public_flag === 0 ? (
                          <img
                            width='100%'
                            height='100%'
                            src={
                              i18n.language === 'jp'
                                ? img_no_water_mark_mobile_2_column_post_content
                                : img_no_water_mark_mobile_2_column_post_content_en
                            }
                            alt=''
                          />
                        ) : (
                          <>
                            {post?.media_preview?.url ? (
                              <>
                                {renderImageToPostTab2(post)}
                                {post?.price_discount && (
                                  <>
                                    <img
                                      src={sale_lable}
                                      width={'68'}
                                      height={'44'}
                                      alt='1'
                                      style={{
                                        position: 'absolute',
                                        top: '8px',
                                        left: '0px',
                                        zIndex: '20',
                                      }}
                                    />
                                    <div className='content-sale'>
                                      <p>{t('PostDetail.sale')}</p>
                                      <p className='d-flex justify-content-center'>
                                        -{post?.discount}%
                                      </p>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <ImgCustom
                                  src={IMG_DEMO}
                                  className='img-default'
                                  onClick={() => navigateByLink(post?.id)}
                                />
                              </>
                            )}
                          </>
                        )}
                        {post?.media_preview?.public_flag !== 0 && (
                          <div
                            className={`wrap--text1 ${isAdmin && 'disabled'}`}
                            onClick={() => {
                              dispatch(setIdScrollBackRecommend(`recomend`, pathname));
                              dispatch(
                                getIDSearchSale({
                                  id: post?.id,
                                  tab: 2,
                                  keyword: keyword,
                                  page: page,
                                })
                              );
                              navigate(`${newPathUser}post-sale/${post?.id}`, {
                                state: {
                                  redirectBack: '/search',
                                },
                              });
                            }}
                            style={{
                              position: 'absolute',
                              bottom: '0px',
                              left: '0px',
                              zIndex: '10',
                              cursor: 'pointer',
                            }}>
                            <div className='d-middle'>
                              <HeartIcon
                                style={{
                                  outline: 'none',
                                  color: post?.is_liked === 1 ? '#dc143c' : 'inherit',
                                }}
                                fill={post?.is_liked === 1 ? '#dc143c' : 'none'}
                                onClick={(e) => {
                                  if (!isAuth) {
                                    e.stopPropagation();
                                    dispatch(logUserProfile(post));
                                  } else {
                                    e.stopPropagation();
                                    !checkDisabledAction(post) && handleLikePost(post);
                                  }
                                }}
                                disabled={checkDisabledAction(post)}
                                className='cls-icon'
                              />
                              <p className='mb-0 cls-text' style={{ color: '#fff' }}>
                                {formatNumber(post?.number_like)}
                              </p>
                            </div>
                            <div className='d-middle'>
                              <label
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                                htmlFor={`comment-item-${post?.id}`}>
                                <CommentIcon
                                  style={{ marginTop: '0px' }}
                                  onClick={() => {
                                    dispatch(setIdScrollBackRecommend(`recomend`, pathname));
                                    dispatch(
                                      getIDSearchSale({
                                        id: post?.id,
                                        tab: 2,
                                        keyword: keyword,
                                        page: page,
                                      })
                                    );
                                    navigate(`${newPathUser}post-sale/${post?.id}`, {
                                      state: {
                                        redirectBack: '/search',
                                      },
                                    });
                                  }}
                                  className='cls-icon creator_post_live'
                                />
                                <p className='mb-0 cls-text' style={{ color: '#fff' }}>
                                  {formatNumber(post?.number_comment) || 0}
                                </p>
                              </label>
                            </div>
                            <ShareIcon
                              onClick={(e) => {
                                if (!isAuth) {
                                  e.stopPropagation();
                                  dispatch(logUserProfile(post));
                                } else {
                                  e.stopPropagation();
                                  handleSharePost({
                                    link: `${getEnv(
                                      'REACT_APP_FAN_URL'
                                    )}${newPathUser}post-sale/${post?.id}`,
                                    title: post.title,
                                    id: post?.id,
                                  });
                                }
                              }}
                              className='cls-icon'
                            />
                          </div>
                        )}
                      </div>

                      <div className='wrap--text2'>
                        <div className='d-flex justify-content-between'>
                          <p
                            className='mb-0 cls-title'
                            style={{
                              width: '60%',
                              color: '#282727',
                              fontWeight: '500',
                              fontSize: '14px',
                            }}>
                            {post?.title}
                          </p>
                          <div className='d-flex' style={{ gap: '20px' }}>
                            {post?.is_media_display === 1 && (
                              <div style={{ marginTop: '1px', marginRight: '-10px' }}>
                                <SusessPackageIcon />
                              </div>
                            )}
                            <div
                              className={`d-middle ${isAdmin && 'disabled'}`}
                              style={{ marginRight: '-10px' }}>
                              <IconBookmark
                                style={{
                                  color: post?.is_bookmarked === 1 ? '#dc143c' : '#666565',
                                }}
                                fill={post?.is_bookmarked === 1 ? '#dc143c' : 'none'}
                                onClick={() => {
                                  if (!isAuth) {
                                    dispatch(logUserProfile(post));
                                  } else {
                                    !checkDisabledAction(post) && bookmarkChange(post);
                                  }
                                }}
                                disabled={checkDisabledAction(post)}
                                className='cls-icon mg-left-auto'
                              />
                            </div>
                          </div>
                        </div>
                        <p className='mb-0 cls-title-2nd2' style={{ height: '34px' }}>
                          {post?.content}
                        </p>
                        <div className='d-flex w-100 mb-1 mt-1 align-items-center'>
                          <div className='text-label'>{t('Purchase.point')}: </div>
                          {post?.price !== null || post?.price_discount !== null ? (
                            <>
                              <p className='point' style={{ marginBottom: '0' }}>
                                {post?.price_discount
                                  ? formatCoin(post?.price_discount)
                                  : formatCoin(post?.price) || 0}
                                {i18n.language === 'en' && ' '}
                                Pt
                              </p>
                              {post?.price_discount && (
                                <p className='point-price' style={{ marginBottom: '0px' }}>
                                  {formatCoin(post?.price)} {i18n.language === 'en' && ' '} Pt
                                </p>
                              )}
                            </>
                          ) : (
                            '-'
                          )}
                        </div>
                        <div className='d-flex w-100'>
                          <div className='text-label'>{t('Purchase.premium')}: </div>
                          {post?.number_premium_ticket ? (
                            <p className='point'>
                              {formatCoin(post?.number_premium_ticket) || 0}
                              {i18n.language === 'en' && ' '}
                              {t('Creator.PostArticle.postContent.afterContentCondition')}
                            </p>
                          ) : (
                            '-'
                          )}
                        </div>
                        <div
                          className='d-flex w-100 align-items-center mt-1'
                          style={{ fontSize: '12px' }}>
                          <p className='me-1' style={{ color: '#666565' }}>
                            {t('Common.postTime')}
                          </p>
                          <p className='me-1' style={{ color: '#1a1a1a', fontWeight: '500' }}>
                            {convertToCurrentTime(post?.created_at, 'YYYY/MM/DD')}
                          </p>
                          {post?.duration && (
                            <>
                              <p className='me-1' style={{ color: '#666565' }}>
                                {t('Common.videoMinutes')}
                              </p>
                              <p style={{ color: '#1a1a1a', fontWeight: '500' }}>
                                {msToTime(post?.duration) || 0}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='no-data'>
                    <NoSearchList />
                    {t('Search.listNull')}
                  </div>
                )}
              </>
            )}
          </>
        </InfiniteLoad>
      </div>
    </>
  );
};

export default withCreatorRole(SearchSaleContent);
