import { getEnv } from 'configs/env';
import { detectDomain } from 'utils/utils';
const useUserRole = () => {
  const { subdomain } = detectDomain();
  // const isIdol = subdomain === getEnv('REACT_APP_IDOL_SUB_DOMAIN') || false;
  const isIdol = true;
  return {
    isIdol,
    isFan: !isIdol,
  };
};


export default useUserRole;
