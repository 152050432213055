import Icon from '@ant-design/icons';

const AvatarDefault = (props) => (
  <Icon
    component={() => (
      <svg width="84" height="84" viewBox="0 0 84 84" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="42" cy="42" r="41" fill="#EEEEEE" stroke="white" strokeWidth="2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M50.1529 34.7478C50.1529 39.2709 46.5264 42.8976 42.0002 42.8976C37.4755 42.8976 33.8474 39.2709 33.8474 34.7478C33.8474 30.2248 37.4755 26.5996 42.0002 26.5996C46.5264 26.5996 50.1529 30.2248 50.1529 34.7478ZM42.0002 57.3996C35.3202 57.3996 29.6802 56.3139 29.6802 52.1251C29.6802 47.9347 35.3557 46.8875 42.0002 46.8875C48.6817 46.8875 54.3202 47.9732 54.3202 52.162C54.3202 56.3524 48.6447 57.3996 42.0002 57.3996Z" fill="#787880"/>
      </svg>
      
    )}
    {...props}
  />
);

export default AvatarDefault;
