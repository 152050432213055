import { ImgCustom } from 'components';
import { linkCloudFront } from 'utils';
import './index.scss';
import { BlueImg } from './styled';
import React from 'react';

const MetaTagViewer = ({ title, thumbnail, url, className }) => {
  const navigateUrl = () => {
    window.open(url, '_blank').focus();
  };
  return (
    <div className={`meta-tag-viewer ${className}`}>
      <div className='meta-tag-container'>
        <div className='meta-tag-image' onClick={navigateUrl}>
          <BlueImg className='csp' url={`${linkCloudFront + thumbnail}?width=auto&height=100`}>
            <div className='bg-img'></div>
            <ImgCustom
              screen='post_197_11_pc'
              src={linkCloudFront + thumbnail}
              className='video-detail main_video cover-img'
              style={{ marginTop: 0 }}
              avatarErr={false}
            />
          </BlueImg>
        </div>
        <div className='meta-tag-content'>
          <div className='meta-tag-title'>{title}</div>
          <a href={url} target='_blank' className='meta-tag-url' rel='noreferrer'>
            {url}
          </a>
        </div>
      </div>
    </div>
  );
};

export default React.memo(MetaTagViewer);
