import {
  configParamsDELETE,
  configParamsGet,
  configParamsPostJSON,
  ENV,
  updateStatus,
} from 'utils/constant';
import { callApi } from 'utils/request';
import { queryString, isAdmin as IS_ADMIN, STORAGE } from 'utils';
import Storage from 'utils/storage';
import { TYPES } from './index';

const API = {
  createMessage: () => `${ENV}/send-messages`,
  userSendMessage: () => `${ENV}/messages`,
  userGetListMessage: (queries) => `${ENV}/message-groups?${queryString(queries)}`,
  userGetListMessageToAdmin: (id, queries) => `${ENV}/users/${id}/message-groups?${queryString(queries)}`,
  userDeleteGroupMessage: (id) => `${ENV}/message-groups/${id}`,
  userGetMessageDetail: (id, queries) =>
    `${ENV}/users/${id}/detail-message?${queryString(queries)}`,
  userGetMessageDetailToAdmin: (id, userId, queries) =>
    `${ENV}/users/${id}/message-groups/${userId}/messages?${queryString(queries)}`,
  userDeleteMessage: (id) => `${ENV}/messages/${id}`,
};

const createMessage = (params, handleSuccess) => {
  return () => {
    callApi(
      API.createMessage(),
      configParamsPostJSON(params),
      null,
      async (response) => {
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleSuccess && handleSuccess(error);
      }
    );
  };
};

const userSendMessage = (params, handleSuccess) => {
  return () => {
    callApi(
      API.userSendMessage(),
      configParamsPostJSON(params),
      null,
      async (response) => {
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleSuccess && handleSuccess(error);
      }
    );
  };
};

const userGetListMessage = (queries, callback) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_MESSAGE_REQUEST));
    callApi(
      API.userGetListMessage(queries),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_MESSAGE_SUCCESS, response));
        callback && callback(response)
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_MESSAGE_FAILED, { err: error.errors }));
        callback && callback(error)
      }
    );
  };
};

const userGetListMessageToAdmin = (id, queries, callback) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_MESSAGE_TO_ADMIN_REQUEST));
    callApi(
      API.userGetListMessageToAdmin(id, queries),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.GET_LIST_MESSAGE_TO_ADMIN_SUCCESS, response));
        callback && callback(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_LIST_MESSAGE_TO_ADMIN_FAILED, { err: error.errors }));
        callback && callback(error);
      }
    );
  };
};

const userDeleteGroupMessage = (id, index, handleSuccess) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.DELETE_GROUP_MESSAGE_REQUEST));
    callApi(
      API.userDeleteGroupMessage(id),
      configParamsDELETE,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.DELETE_GROUP_MESSAGE_SUCCESS, { ...response, index })
        );
        handleSuccess && handleSuccess();
      },
      (error) => {
        handleSuccess && handleSuccess({});
        dispatch(updateStatus(TYPES.DELETE_GROUP_MESSAGE_FAILED, { err: error.errors }));
      }
    );
  };
};

const deleteMessage = (id, handleSuccess) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.DELETE_MESSAGE_REQUEST));
    callApi(
      API.userDeleteGroupMessage(id),
      configParamsDELETE,
      null,
      async (response) => {
        await dispatch(updateStatus(TYPES.DELETE_MESSAGE_SUCCESS));
        handleSuccess && handleSuccess();
      },
      (error) => {
        handleSuccess && handleSuccess({});
        dispatch(updateStatus(TYPES.DELETE_MESSAGE_FAILED, { err: error.errors }));
      }
    );
  };
};

const userGetMessageDetail = ({ id, ...params }, resultData, isSetLoading) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_MESSAGE_REQUEST));
    callApi(
      API.userGetMessageDetail(id, params),
      configParamsGet,
      null,
      async (response) => {
        resultData && resultData(response, isSetLoading);
      },
      () => {
        resultData && resultData({});
      }
    );
  };
};

const userGetMessageDetailToAdmin = ({ id, userId, ...params }, resultData, isSetLoading) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_LIST_MESSAGE_TO_ADMIN_REQUEST));
    callApi(
      API.userGetMessageDetailToAdmin(id, userId, params),
      configParamsGet,
      null,
      async (response) => {
        resultData && resultData(response, isSetLoading);
      },
      () => {
        resultData && resultData({});
      }
    );
  };
};

const userDeleteMessage = (id, handleSuccess) => {
  return () => {
    callApi(
      API.userDeleteMessage(id),
      configParamsDELETE,
      null,
      async (response) => {
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleSuccess && handleSuccess({});
      }
    );
  };
};

const getIDMess = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('ID_MESS', payload));
  };
};

const resetIDMess = () => (dispatch) => {
  dispatch(updateStatus('RESET_ID_MESS'));
};

export {
  createMessage,
  userGetListMessage,
  userDeleteGroupMessage,
  userGetMessageDetail,
  userDeleteMessage,
  userSendMessage,
  deleteMessage,
  userGetListMessageToAdmin,
  userGetMessageDetailToAdmin,
  getIDMess,
  resetIDMess
};
