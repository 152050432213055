import { ImgCustom, LayoutFan } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { newPathUser } from 'constants/layout/constant';
import useLoading from 'hooks/useLoading';
import useRedirect from 'hooks/useRedirect';
import { SearchIcon } from 'images';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { searchListCreator, searchPost } from 'store/actions/search';
import { STORAGE } from 'utils/constant';
import Storage from 'utils/storage';
import {
  getPlaceholderSearch,
  GET_TAB_VALUE_SEARCH,
  linkS3,
  LIST_TAB,
  LIST_TAB_CHILD,
} from 'utils/utils';
import { fanListTag } from '../../store/actions/users';
import './styles.scss';

export default function Index() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const itemsPerPage = 10;

  const [tab, setTab] = useState(1);
  const [tabChild, setTabChild] = useState(1);
  const { dataPost, pagination, loadingSearch, listCreator } = useSelector(
    (state) => state.search
  );
  const { category } = useSelector((state) => state.users);
  const error = useSelector((state) => state.search.error);
  useRedirect(error);
  const isLoading = useLoading();
  const [loadingCustom, setLoadingCustom] = useState(false);

  const [active, setActive] = useState([-1]);
  const [input, setInput] = useState('');

  const [checkFirstTimeSearch1, setCheckFirstTimeSearch1] = useState(false);
  const [checkFirstTimeSearch2, setCheckFirstTimeSearch2] = useState(false);
  const [checkFirstTimeSearch3, setCheckFirstTimeSearch3] = useState(false);

  const [page, setPage] = useState(1);
  const fetchNext = () => {
    const pages = pagination?.total / pagination?.per_page;
    if (pages > page) {
      setPage(page + 1);
    }
  };
  const [params, setParams] = useState({
    keyword: '',
  });

  const [pageSearchCreator, setPageSearchCreator] = useState(1);
  const fetchNextSearchCreator = () => setPageSearchCreator(pageSearchCreator + 1);
  const [paramsSearchCreator, setParamsSearchCreator] = useState({
    keyword: '',
    is_active: 1,
    search_type: 1,
  });

  const [pageSearchCreatorByTag, setPageSearchCreatorByTag] = useState(1);
  const fetchNextSearchCreatorByTag = () =>
    setPageSearchCreatorByTag(pageSearchCreatorByTag + 1);
  const [paramsSearchCreatorByTag, setParamsSearchCreatorByTag] = useState({
    keyword: '',
    is_active: 1,
    search_type: 2,
    tag: active,
  });

  const loadingFunc = () => {
    setLoadingCustom(true);
    setTimeout(() => {
      setLoadingCustom(false);
    }, 1000);
  };

  const handleClearParam = () => {
    document.getElementById('id-input-search').value = '';
    setInput();
    setParams({ keyword: 1 });
    setParamsSearchCreator({
      keyword: '',
      is_active: 1,
      search_type: 1,
    });
    setParamsSearchCreatorByTag({
      keyword: '',
      is_active: 1,
      search_type: 2,
      tag: active,
    });
  };

  useEffect(() => {
    dispatch(fanListTag());
  }, []);

  useEffect(() => {
    if (dataPost && checkFirstTimeSearch2) {
      Storage.set(STORAGE.SEARCH_POST, JSON.stringify(dataPost));
    }
    if (listCreator && checkFirstTimeSearch1) {
      Storage.set(STORAGE.SEARCH_CREATOR, JSON.stringify(listCreator));
    }
    if (listCreator && checkFirstTimeSearch3) {
      Storage.set(STORAGE.SEARCH_CREATOR_BY_TAG, JSON.stringify(listCreator));
    }

    if (!Storage.has(STORAGE.SEARCH_POST)) {
      Storage.set(STORAGE.SEARCH_POST, JSON.stringify([]));
    }
    if (!Storage.has(STORAGE.SEARCH_CREATOR)) {
      Storage.set(STORAGE.SEARCH_CREATOR, JSON.stringify([]));
    }
    if (!Storage.has(STORAGE.SEARCH_CREATOR_BY_TAG)) {
      Storage.set(STORAGE.SEARCH_CREATOR_BY_TAG, JSON.stringify([]));
    }
  }, [
    checkFirstTimeSearch1,
    checkFirstTimeSearch2,
    checkFirstTimeSearch3,
    dataPost,
    listCreator,
  ]);

  useEffect(() => {
    if (tab === 1) {
      dispatch(searchListCreator(pageSearchCreator, itemsPerPage, paramsSearchCreator));
      dispatch(searchPost(page, itemsPerPage, params));
    } else {
      dispatch(
        searchListCreator(pageSearchCreatorByTag, itemsPerPage, paramsSearchCreatorByTag)
      );
    }
  }, [
    params,
    tabChild,
    page,
    paramsSearchCreator,
    pageSearchCreator,
    tab,
    pageSearchCreatorByTag,
    paramsSearchCreatorByTag,
  ]);

  const onSubmit = (e) => {
    const keyCode = e.key;
    const value = e.target.value;
    if (tab === 1 && keyCode === 'Enter') {
      setPageSearchCreator(1);
      setParamsSearchCreator({ ...paramsSearchCreator, keyword: value });
      setCheckFirstTimeSearch1(true);
      setPage(1);
      setParams({ ...params, keyword: value });
      setCheckFirstTimeSearch2(true);
    } else {
      if (keyCode === 'Enter') {
        setPageSearchCreatorByTag(1);
        setParamsSearchCreatorByTag({ ...paramsSearchCreatorByTag, keyword: value });
        setCheckFirstTimeSearch3(true);
      }
    }
  };

  //* Error

  return (
    <LayoutFan titleHeader={t('Search.title')} loading={isLoading || loadingCustom}>
      <div className='search-page'>
        <div className='tab-container'>
          {LIST_TAB.map((v, i) => (
            <div
              key={i}
              className={`item ${tab === i + 1 ? ` active ` : ``}`}
              onClick={() => {
                setTab(i + 1);
                if (i !== 2) {
                  setParams({ type: GET_TAB_VALUE_SEARCH(i + 1), keyword: '' });
                } else {
                  setActive([1]);
                  setParams({ type: 'category', category_id: '1' });
                }
                handleClearParam();
                loadingFunc();
              }}>
              {v.label}
            </div>
          ))}
        </div>
        <div className='body'>
          {tab === 2 && (
            <div className='list-btn scroll-bar-custom-horizontal'>
              {category.map((v, j) => (
                <div
                  key={j}
                  onClick={() => {
                    let tmp = [...active];
                    if (active.includes(v?.id)) {
                      tmp = tmp.filter((item) => item !== v?.id);
                      setActive(tmp);
                    } else {
                      tmp.push(v?.id);
                      setActive(tmp);
                    }
                    setPageSearchCreatorByTag(1);
                    setParamsSearchCreatorByTag((prev) => ({ ...prev, tag: tmp }));
                    loadingFunc();
                    setCheckFirstTimeSearch3(true);
                  }}
                  className={active.includes(v?.id) ? `selected` : ``}>
                  {v?.tag}
                </div>
              ))}
            </div>
          )}
          <form onSubmit={(e) => e.preventDefault()} className='form-search'>
            <div className='form-item'>
              <div
                className='d-flex'
                onClick={() => {
                  if (tab === 1) {
                    tabChild === 1 &&
                      setParamsSearchCreator({ ...paramsSearchCreator, keyword: input });
                    tabChild === 2 && setParams({ ...params, keyword: input });
                  } else {
                    setParamsSearchCreatorByTag((prev) => ({ ...prev, keyword: input }));
                  }
                }}>
                <SearchIcon />
              </div>
              <input
                id='id-input-search'
                type='text'
                placeholder={getPlaceholderSearch(tab)}
                onChange={(e) => setInput(e.target.value)}
                onBlur={(e) => {
                  const value = e.target.value;
                  if (tab === 1) {
                    setPageSearchCreator(1);
                    setParamsSearchCreator({ ...paramsSearchCreator, keyword: value });
                    setCheckFirstTimeSearch1(true);
                    setPage(1);
                    setParams({ ...params, keyword: value });
                    setCheckFirstTimeSearch2(true);
                  } else {
                    setPageSearchCreatorByTag(1);
                    setParamsSearchCreatorByTag({
                      ...paramsSearchCreatorByTag,
                      keyword: value,
                    });
                    setCheckFirstTimeSearch3(true);
                  }
                }}
                onKeyPress={(e) => onSubmit(e)}
              />
            </div>
          </form>
          {tab === 1 ? (
            <>
              <div className='px-16'>
                <div className='tab-container'>
                  {LIST_TAB_CHILD.map((v, i) => (
                    <div
                      key={i}
                      className={`item ${tabChild === i + 1 ? ` active ` : ``}`}
                      onClick={() => {
                        setTabChild(i + 1);
                        loadingFunc();
                      }}>
                      {v.label}
                    </div>
                  ))}
                </div>
              </div>
              {tabChild === 1 ? (
                <>
                  {checkFirstTimeSearch1 ? (
                    <>
                      {listCreator.length > 0 ? (
                        <InfiniteLoad
                          loading={loadingSearch}
                          data={listCreator}
                          fetchNext={fetchNextSearchCreator}>
                          <div className='list'>
                            {listCreator.map((item, index) => (
                              <div
                                key={index}
                                className='item'
                                onClick={() => navigate(`${newPathUser}${item.account_id}`)}>
                                <ImgCustom src={`${item?.avatar}`} />
                                <div style={{ cursor: 'pointer', width: '100%' }}>
                                  <div className='name'>
                                    {item?.account_name || item?.username || item?.full_name}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </InfiniteLoad>
                      ) : (
                        <div className='no-result'>{t('Search.thereIsNoSearchResult')}</div>
                      )}
                    </>
                  ) : (
                    <>
                      {JSON.parse(Storage.get(STORAGE.SEARCH_CREATOR))?.length > 0 ? (
                        <InfiniteLoad
                          loading={loadingSearch}
                          data={JSON.parse(Storage.get(STORAGE.SEARCH_CREATOR))}
                          fetchNext={fetchNextSearchCreator}>
                          <div className='list'>
                            {JSON.parse(Storage.get(STORAGE.SEARCH_CREATOR))?.map(
                              (item, index) => (
                                <div
                                  key={index}
                                  className='item'
                                  onClick={() => navigate(`${newPathUser}${item?.account_id}`)}>
                                  <ImgCustom src={`${item?.avatar}`} />
                                  <div style={{ cursor: 'pointer', width: '100%' }}>
                                    <div className='name'>
                                      {item?.account_name || item?.username || item?.full_name}
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </InfiniteLoad>
                      ) : (
                        <div className='no-result'>{t('Search.thereIsNoSearchResult')}</div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <div>
                  {checkFirstTimeSearch2 ? (
                    <>
                      {dataPost?.length > 0 ? (
                        <InfiniteLoad
                          loading={loadingSearch}
                          data={dataPost}
                          fetchNext={fetchNext}>
                          <div className='list'>
                            {dataPost.map((item, index) => (
                              <div
                                key={index}
                                className='item'
                                onClick={() => navigate(`/post/${item?.id}`)}>
                                <ImgCustom src={`${item?.avatar}`} />
                                <div style={{ cursor: 'pointer', width: '100%' }}>
                                  <div className='name'>{item?.user?.account_name || ''}</div>
                                  {tab === 1 && (
                                    <div className='title'>{item?.title || ''}</div>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </InfiniteLoad>
                      ) : (
                        <div className='no-result'>{t('Search.thereIsNoSearchResult')}</div>
                      )}
                    </>
                  ) : (
                    <>
                      {JSON?.parse(Storage.get(STORAGE.SEARCH_POST))?.length > 0 ? (
                        <InfiniteLoad
                          loading={loadingSearch}
                          data={JSON?.parse(Storage.get(STORAGE.SEARCH_POST))}
                          fetchNext={fetchNext}>
                          <div className='list'>
                            {JSON?.parse(Storage.get(STORAGE.SEARCH_POST))?.map(
                              (item, index) => (
                                <div
                                  key={index}
                                  className='item'
                                  onClick={() => navigate(`/post/${item?.id}`)}>
                                  <ImgCustom src={`${item?.avatar}`} />
                                  <div style={{ cursor: 'pointer', width: '100%' }}>
                                    <div className='name'>
                                      {item?.user?.account_name || ''}
                                    </div>
                                    {tab === 1 && (
                                      <div className='title'>{item?.title || ''}</div>
                                    )}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </InfiniteLoad>
                      ) : (
                        <div className='no-result'>{t('Search.thereIsNoSearchResult')}</div>
                      )}
                    </>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              {checkFirstTimeSearch3 ? (
                <>
                  {listCreator.length > 0 ? (
                    <InfiniteLoad
                      loading={loadingSearch}
                      data={listCreator}
                      fetchNext={fetchNextSearchCreatorByTag}>
                      <div className='list'>
                        {listCreator.map((item, index) => (
                          <div
                            key={index}
                            className='item'
                            onClick={() => navigate(`${newPathUser}${item?.account_id}`)}>
                            <ImgCustom src={`${item?.avatar}`} />
                            <div style={{ cursor: 'pointer', width: '100%' }}>
                              <div className='name'>
                                {item?.account_name || item?.username || item?.full_name}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </InfiniteLoad>
                  ) : (
                    <div className='no-result'>{t('Search.thereIsNoSearchResult')}</div>
                  )}
                </>
              ) : (
                <>
                  {JSON.parse(Storage.get(STORAGE.SEARCH_CREATOR_BY_TAG))?.length > 0 ? (
                    <InfiniteLoad
                      loading={loadingSearch}
                      data={JSON.parse(Storage.get(STORAGE.SEARCH_CREATOR_BY_TAG))}
                      fetchNext={fetchNextSearchCreatorByTag}>
                      <div className='list'>
                        {JSON.parse(Storage.get(STORAGE.SEARCH_CREATOR_BY_TAG))?.map(
                          (item, index) => (
                            <div
                              key={index}
                              className='item'
                              onClick={() => navigate(`${newPathUser}${item?.account_id}`)}>
                              <ImgCustom src={`${item?.avatar}`} />
                              <div style={{ cursor: 'pointer', width: '100%' }}>
                                <div className='name'>
                                  {item?.account_name || item?.username || item?.full_name}
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </InfiniteLoad>
                  ) : (
                    <div className='no-result'>{t('Search.thereIsNoSearchResult')}</div>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </LayoutFan>
  );
}
