import React, { useRef, useEffect, useLayoutEffect, useState } from 'react';
import {
  CloseIcon,
  CommentIcon,
  FlagIcon,
  FlowUnFlowIcon,
  HeartActiveIcon,
  HeartIcon,
  IconBookmark,
  PlayIcon,
  SendChatIcon,
  ShareIcon,
  ThreePointIcon,
} from 'images';

import {
  IMG_NO_WATER_MARK,
  convertToCurrentTime,
  decryptPath,
  formatNumber,
  isAdmin,
  isFirefox,
  linkS3Video,
  onErrImage,
} from 'utils';
import { ChevronLeft, ChevronRight } from 'images';
import Slider from 'react-slick';
import {
  ImgCustom,
  PopupConfirmFan,
  PopupSharePost,
  ReadMoreLess,
  Textarea,
} from 'components';
import { useTranslation } from 'react-i18next';
import useUserRole from 'hooks/useUserRole';
import { useSelector } from 'react-redux';
import { InputWrapper } from 'components/Input/InputWrapper';
import Avatar from 'images/Avatar.png';
import { useDispatch } from 'react-redux';
import useRedirect from 'hooks/useRedirect';
import { getEnv } from 'configs/env';
import './PopupViewFullTimeLine.scss';
import { newPathUser } from 'constants/layout/constant';
import { useNavigate } from 'react-router-dom';
import {
  creatorDeleteArticle,
  postBookMarkDetail,
  creatorGetPostDetail,
  postCommentDetail,
  deleteCommentDetail,
  deleteReplyCommentDetail,
  fanFollowCreator,
  updatePostLikeDetail,
} from 'store/actions/usersNew';
import ReactPlayer from 'react-player';
import { LoadingImg } from 'components/LiveStream/LoadingImg';
import { isMobile, isSafari, isTablet } from 'react-device-detect';
import ReactHlsPlayer from 'react-hls-player';
import useDisableContextMenu from 'hooks/useDisableContextMenu';
import { VideoTag } from './VideoTag';
import FieldChanged from 'components/FieldChanged/FieldChanged';
import useCheckFullVideo from 'hooks/v2/useCheckFullVideo';

const PopupViewFullTimeLine = ({ item, close, idx, className, setOpenIndex, openIndex , isQuote = false}) => {
  const slideRef = useRef(null);
  const refVideo = useRef(null);
  useDisableContextMenu(refVideo);

  const [pageActive, setPageActive] = useState(idx);
  const { t } = useTranslation();
  const widthScreen = window.innerWidth;
  const { isIdol } = useUserRole();
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const [popupDeleteComment, setPopupDeleteComment] = useState(null);

  const { detailPost, error } = useSelector((state) => state.usersNew);
  const { data: dataProfile, dataUser } = useSelector((state) => state.users);
  const [valueComment, setValueComment] = useState('');
  const [checkSendChat, setCheckSendChat] = useState(null);
  const [isVisiblePopup, setIsVisiblePopup] = useState(-1);
  const [flgRender, setFlgRender] = useState(false);
  const [widthContent, setWidthContent] = useState();
  const [isPlaying, setIsPlaying] = useState(false);

  const [isVisiblePopUpDeletePost, setIsVisiblePopDeletePost] = useState(null);
  const [isVisiblePopUpDeletePostComplete, setIsVisiblePopDeletePostComplete] =
    useState(false);
  const [showButton, setShowButton] = useState(true);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  // useEffect(() => {
  //   if (Object.keys(detailPost).length) return;
  //   dispatch(creatorGetPostDetail(item?.id, false));
  // }, [item?.id]);

  useRedirect(error);

  const [controlCustom, setControlCustom] = useState(false);
  const [isFullScreen, setIsFullScreen] = useCheckFullVideo(refVideo);
  const NextArrow = (props) => {
    const { className } = props;
    return (
      <div
        style={{
          cursor: `${
            pageActive === item?.medias?.filter((i) => i.type !== 4)?.length - 1 && 'default'
          }`,
          opacity: `${
            pageActive === item?.medias?.filter((i) => i.type !== 4)?.length - 1 && '0.3'
          }`,
        }}
        className={className}
        onClick={() => {
          pageActive !== item?.medias?.filter((i) => i.type !== 4)?.length - 1 &&
            setPageActive(pageActive + 1);
          slideRef.current?.slickGoTo(pageActive + 1);
          setIsPlaying(false);
        }}>
        <ChevronRight />
      </div>
    );
  };
  const PrevArrow = (props) => {
    const { className } = props;
    return (
      <div
        style={{
          cursor: `${pageActive === 0 && 'default'}`,
          opacity: `${pageActive === 0 && '0.3'}`,
        }}
        className={className}
        onClick={() => {
          pageActive !== 0 && setPageActive(pageActive - 1);
          slideRef.current?.slickGoTo(pageActive - 1);
          setIsPlaying(false);
        }}>
        <ChevronLeft />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    swipe: false,
    afterChange: (current) => {
      // setIsPlaying(false);
      // setPageActive(current);
      // slideRef?.current?.innerSlider?.list.setAttribute('tabindex', current);
      // slideRef?.current?.innerSlider?.list?.focus();
    },
    initialSlide: idx,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  // useEffect(() => {
  //   slideRef?.current?.innerSlider?.list.setAttribute('tabindex', 0);
  //   slideRef?.current?.innerSlider?.list?.focus();
  // }, [slideRef]);

  const ref = useRef();
  const handleLikePost = (post) => {
    dispatch(updatePostLikeDetail(post));
  };

  const handlePostBookMark = (id) => {
    dispatch(postBookMarkDetail(id));
  };

  const handleSharePost = (dataPC) => {
    setPopupCopyLink(dataPC);
  };

  const GroupIcon = (comment) => {
    return (
      <div
        className={`GroupIcon scroll-bar-custom-horizontal ${
          item?.is_followed === 0 && item?.follow_flag === 1 && 'disabled'
        } ${isAdmin && 'disabled'}`}
        style={{ padding: '0 16px' }}>
        <div className={`d-middle-popup-all ${checkDisabledAction(item) && 'disabled'}`}>
          <div className='d-flex align-items-center justify-content-center'>
            {detailPost?.is_liked === 1 ? (
              <>
                {/* đã like */}
                <HeartActiveIcon
                  className={`like-icon active`}
                  onClick={() => {
                    !checkDisabledAction(item) && handleLikePost(detailPost);
                  }}
                />
              </>
            ) : (
              <>
                {/* chưa like */}
                <HeartIcon
                  className={`like-icon`}
                  onClick={() => {
                    !checkDisabledAction(item) && handleLikePost(detailPost);
                  }}
                />
              </>
            )}
            <p className='mb-0 cls-text'>{formatNumber(detailPost?.number_like)}</p>
          </div>

          <div className='d-middle'>
            <label
              style={{ display: 'flex', alignItems: 'center' }}
              htmlFor={`comment-item-${item?.id}`}>
              <CommentIcon className='like-icon' />
              <p className='mb-0 cls-text'>{formatNumber(detailPost?.number_comment) || 0}</p>
            </label>
          </div>
          {/* <div className={`d-middle ${checkDisabledAction(item) && 'disabled'}`}>
            <RePostIcon />
            <span className='number ms-1' style={{ color: '#666565' }}>
              {item?.number_repost ?? 0}
            </span>
          </div> */}
          <ShareIcon
            onClick={() => {
              handleSharePost({
                link: `${getEnv('REACT_APP_FAN_URL')}/post/${item?.id}`,
                title: item?.title,
                id: item?.id,
              });
            }}
            className='like-icon'
          />
        </div>
        <div className={`${checkDisabledAction(item) && 'disabled'}`}>
          {detailPost?.is_bookmarked === 1 ? (
            <>
              {/* đã bookmart */}
              <IconBookmark
                style={{
                  color: '#dc143c',
                }}
                fill={'#dc143c'}
                className='cls-icon'
                onClick={() => {
                  !checkDisabledAction(item) && handlePostBookMark(detailPost?.id);
                }}
              />
            </>
          ) : (
            <>
              {/* chưa bookmart */}
              <IconBookmark
                style={{
                  color: '#787880',
                }}
                fill={'none'}
                className='cls-icon'
                onClick={() => {
                  !checkDisabledAction(item) && handlePostBookMark(detailPost?.id);
                }}
              />
            </>
          )}
        </div>
      </div>
    );
  };

  const handleFollowCreator = (dt) => dispatch(fanFollowCreator(dt.user_id));

  const handleDeleteComment = () => {
    const { id, type, idParent } = popupDeleteComment;
    type === 'comment'
      ? dispatch(deleteCommentDetail(id))
      : dispatch(deleteReplyCommentDetail(id, idParent));
    setPopupDeleteComment(false);
  };

  const handleTextarea = (e, id, height) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    setValueComment(e.target.value);
    setCheckSendChat(id);
    if (e.target.value?.toString().trim() === '') {
      e.target.style.height = height;
      e.target.style.padding = '9px  30px  9px 12px';
      setCheckSendChat(null);
    }
  };

  const handlePostComment = (id, parent_id) => {
    const data = {
      parent_id: parent_id,
      content: valueComment,
    };
    checkSendChat && dispatch(postCommentDetail(id, data));
    setValueComment('');
    setCheckSendChat(null);
    const textareaId = document.getElementById(`comment-item1-${id}`);
    if (textareaId) textareaId.style.height = '44px';
  };

  const deletePostTimeLine = () => {
    setIsVisiblePopDeletePost(null);
    const { id } = isVisiblePopUpDeletePost;
    dispatch(creatorDeleteArticle(id, () => setIsVisiblePopDeletePostComplete(true)));
    close();
  };

  useLayoutEffect(() => {
    const heightContent = document?.querySelector('.div-content')?.offsetHeight;
    setWidthContent(heightContent);
  }, [flgRender]);

  const checkDisabledAction = (item) => {
    if (
      (item?.is_followed === 0 && item?.follow_flag === 1 && !isAdmin) ||
      item?.is_media_display === 0
    ) {
      return true;
    }
    return false;
  };

  const isMiniUrl = (detailPost) => {
    return (
      (detailPost?.is_followed === 0 &&
        detailPost?.follow_flag === 1 &&
        detailPost?.user_id !== dataUser?.id) ||
      detailPost?.is_media_display === 0
    );
  };

  const listIdChanged = Array.isArray(detailPost?.medias_changed?.images)
    ? detailPost?.medias_changed?.images
    : [];

  return (
    <>
      {popupCopyLink && (
        <PopupSharePost
          isVisiblePopup={() => setPopupCopyLink(false)}
          data={popupCopyLink || {}}
        />
      )}
      {isVisiblePopUpDeletePost && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePost(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.confirmDeletePost')}>
          <div className='btn cancel' onClick={() => setIsVisiblePopDeletePost(null)}>
            {t('Common.buttonNo')}
          </div>
          <div className='ok btn' onClick={deletePostTimeLine}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpDeletePostComplete && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePostComplete(e)}
          iconNote={true}
          text={t('Popup.deletePostSucess')}>
          <div className='ok btn' onClick={() => setIsVisiblePopDeletePostComplete(false)}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {popupDeleteComment && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupDeleteComment(false)}
          colorSvg='#FF9F43'
          text={t('Popup.confirmDeleteComment')}
          className='popup-delete'>
          <div className='ok btn' onClick={handleDeleteComment}>
            {t('Common.buttonDelete')}
          </div>
          <div className='btn cancel' onClick={() => setPopupDeleteComment(false)}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}
      <div className={`popup-wrap ${className ? className : ''}`}>
        <div
          className='popup-view-full-post-timeline my-page-item 2'
          style={{ borderBottom: 'none' }}>
          {!detailPost && (
            <div className='loading-timeline'>
              <LoadingImg />
            </div>
          )}
          {/* {detailPost?.is_followed === 0 &&
          detailPost?.follow_flag === 1 &&
          detailPost?.is_my_post === 0 &&
          !isAdmin ? (
            <div className='lock-screen'>
              <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleFollowCreator(item);
                }}>
                {t('Common.follow')}
              </button>
            </div>
          ) : (
            ''
          )}
          {detailPost?.is_media_display === 0 && !isAdmin && (
            <PopupLockPostNormal
              post_id={item.id}
              detailPost={item}
              in_my_page
              point={dataUser?.points}
            />
          )} */}
          <CloseIcon className='close-icon' onClick={close} />
          {item?.medias?.filter((i) => i.type !== 4)?.length > 1 && (
            <div className={`pagination-slide ${item?.medias?.length === 1 && 'hidden'}`}>
              <span>
                {pageActive + 1}/{item?.medias?.filter((i) => i.type !== 4)?.length}
              </span>
            </div>
          )}
          {item?.medias?.length > 0 ? (
            <Slider
              ref={slideRef}
              {...settings}
              className='slide-image-full-view slide-post-details'>
              {item?.medias
                ?.filter((i) => i.type !== 4)
                .map((item2, index) => (
                  <div
                    className={`list-images-item-detail ${item2.type === 2 ? 'video' : ''}`}
                    key={index}>
                    {item2.type === 1 ? (
                      <>
                        {item2?.public_flag === 1 ? (
                          <div
                            className='blue-img'
                            style={{
                              backgroundImage: `url("${decryptPath(item2?.url, true)}")`,
                            }}
                            height={360}
                            onClick={() => {
                              if (detailPost?.is_media_display === 0 && !isAdmin) return;
                              if (setOpenIndex && !isQuote) {
                                setOpenIndex(index);
                              }
                            }}>
                            <div className='bg-img'></div>
                            {isAdmin && listIdChanged?.includes(item2?.id) && (
                              <FieldChanged top='10px' />
                            )}
                            <ImgCustom
                              className='change-bg image-item'
                              screen={
                                isMiniUrl(item) && !isAdmin
                                  ? 'mini_32_auto_pc'
                                  : 'image_post_detail_auto_360'
                              }
                              src={item2.url}
                              alt=''
                            />
                          </div>
                        ) : (
                          <div className='video-detail trailer_video'>
                            <img
                              src={IMG_NO_WATER_MARK}
                              alt=''
                              className='video-detail trailer_video'
                              style={{ marginTop: 0 }}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {item2.type === 2 && item2?.public_flag === 1 ? (
                          <div
                            style={{ position: 'relative', zIndex: '99', height: '100%' }}
                            // className={`post-details-video ${
                            //   isMobile && showButton ? 'show-btn-play' : 'unshow-btn-play'
                            // }`}
                            className='post-details-video'>
                            <>
                              {decryptPath(item2?.url)?.indexOf('.m3u8') === -1 ? (
                                // <ReactPlayer
                                //   ref={refVideo}
                                //   url={linkS3Video + decryptPath(item2?.url) + '#t=0.1'}
                                //   playing={isPlaying}
                                //   controls={isMobile && !isTablet && isSafari ? false : true}
                                //   onPlay={() => {
                                //     setShowButton(false);
                                //     setIsPlaying(true);
                                //   }}
                                //   onPause={() => {
                                //     setShowButton(true);
                                //     setIsPlaying(false);
                                //   }}
                                //   config={{
                                //     file: {
                                //       attributes: {
                                //         controlsList:
                                //           'nodownload noremoteplayback noplaybackrate foobar',
                                //         disablepictureinpicture: 'true',
                                //         onContextMenu: (e) => e.preventDefault(),
                                //       },
                                //     },
                                //   }}
                                //   playsInline={true}
                                //   width='100%'
                                //   height='100%'
                                //   style={{
                                //     display: 'flex',
                                //     height: '360px',
                                //     width: '100%',
                                //     gap: '2px',
                                //     justifyContent: 'center',
                                //     alignItems: 'center',
                                //   }}
                                // />
                                <VideoTag isMini={isMiniUrl(item) && isFirefox} item={item2} data={item?.medias} />
                              ) : (
                                <div
                                  className={`${
                                    isMobile && showButton
                                      ? 'show-btn-play'
                                      : 'unshow-btn-play'
                                  }`}>
                                  <ReactHlsPlayer
                                    playerRef={ref}
                                    ref={refVideo}
                                    src={linkS3Video + decryptPath(item2?.url)}
                                    playing={isPlaying}
                                    controls={true}
                                    onPlay={() => {
                                      ref.current.play();
                                      setShowButton(false);
                                      setIsPlaying(true);
                                    }}
                                    onPause={() => {
                                      ref.current.pause();
                                      setShowButton(true);
                                      setIsPlaying(false);
                                    }}
                                    controlsList='noplaybackrate'
                                    disablePictureInPicture
                                    playsInline={true}
                                    width='100%'
                                    height='100%'
                                    style={{
                                      display: 'flex',
                                      width: '100%',
                                      gap: '2px',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  />
                                  {showButton && (
                                    <div
                                      style={{
                                        width: '100%',
                                        height: '77%',
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                      }}
                                      onClick={(e) => {
                                        if (isMobile && isSafari) {
                                          return;
                                        }
                                        if (detailPost?.is_media_display === 0 && !isAdmin)
                                          return;

                                        if (!isPlaying && showButton) {
                                          setOpenIndex(index);
                                        }
                                      }}>
                                      <div
                                        className='button-play-icon button-play-icon-post-details'
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          setShowButton(false);
                                          setIsPlaying(true);
                                          if (ref) {
                                            ref.current?.play();
                                          }
                                          if (setOpenIndex) {
                                            setOpenIndex(null);
                                          }
                                        }}>
                                        <PlayIcon />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              )}
                            </>
                          </div>
                        ) : (
                          <div className={`video-no-watermark ${isMobile ? 'h-w-100' : ''}`}>
                            <img
                              src={IMG_NO_WATER_MARK}
                              alt=''
                              className={`video-no-watermark-img ${
                                isMobile ? 'img-cover' : ''
                              }`}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ))}
            </Slider>
          ) : item?.type === 1 || item?.type === 4 ? (
            <ImgCustom
              noIMGPlaceholder
              screen='post_775_720_pc'
              src={item?.media_preview?.url}
              alt=''
              onError={(e) => onErrImage(e, false)}
            />
          ) : (
            <></>
          )}
        </div>

        <div className='card-item--detail left-content-comment'>
          <div
            style={{
              display: 'flex',
              gap: '12px',
              position: 'relative',
              padding: '16px',
              minHeight: '101px',
            }}>
            <ImgCustom
              isPlaceholderAvatar
              screen='avartar_navbar_60_60'
              style={{
                minWidth: 60,
                maxWidth: 60,
                minHeight: 60,
                maxHeight: 60,
                borderRadius: '50%',
                objectFit: 'cover',
              }}
              src={`${detailPost?.avatar}`}
              alt=''
              className='cls-img'
            />
            <div className='wrap--text'>
              <p className='mb-0 cls-title'>{detailPost?.account_name}</p>
              <p className='cls-title-2nd'>
                {convertToCurrentTime(
                  detailPost?.public_time || detailPost?.created_at,
                  'YYYY/MM/DD HH:mm'
                )}
              </p>
            </div>
            <div className={`box-popup ${isAdmin && 'disabled'}`}>
              <ThreePointIcon
                className='three-point-icon'
                onClick={() => {
                  setIsVisiblePopup(isVisiblePopup === item?.id ? -1 : item?.id);
                }}
                onBlur={() => setIsVisiblePopup(-1)}
              />
              {isVisiblePopup === item?.id && (
                <div className='popup-edit-comment'>
                  <div
                    className='item'
                    onMouseDown={() => {
                      handleFollowCreator(item);
                      setIsVisiblePopup(-1);
                    }}>
                    <FlowUnFlowIcon />
                    <span className='text'>
                      {detailPost?.is_followed === 0 || !detailPost?.is_followed
                        ? t('comment.listComment.flow')
                        : t('comment.listComment.unflow')}
                    </span>
                  </div>
                  {!checkDisabledAction(item) && (
                    <div
                      className='item'
                      onMouseDown={() => {
                        setIsVisiblePopup(-1);
                        navigate(`${newPathUser}post-report`, {
                          state: {
                            post_id: item?.id,
                          },
                        });
                      }}>
                      <FlagIcon />
                      <span className='text'>{t('comment.listComment.iconFlag')}</span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className='div-content ' style={{ padding: '0 16px' }}>
            <ReadMoreLess
              content={item?.content}
              charLimit={isIdol ? (widthScreen > 991 ? 60 : widthScreen > 575 ? 45 : 30) : 30}
              onClick={() => {
                setFlgRender(!flgRender);
              }}
            />
          </div>
          <div className='line'></div>

          {GroupIcon(item?.number_comment)}

          <div className='line'></div>
          <div
            className='comment-wrap-popup'
            style={{ height: `calc(100% - ${widthContent}px - 195px)` }}>
            {/* <div className='sroll-list'>
              <CommentPostTimeLine
                dataPost={detailPost}
                handleDeleteComment={(e) => {
                  setPopupDeleteComment(e);
                }}
                handleLikeComment={handleLikeComment}
                dataProfile={dataUser}
                handleFollowUserComment={handleFollowUserComment}
                id={item?.id}
              />
            </div> */}
            <div className='div-list-cmt'>
              <div className={`comment block`}>
                <ImgCustom
                  screen='avartar_navbar_60_60'
                  src={dataUser?.avatar ? `${dataUser.avatar}` : Avatar}
                />
                <form onSubmit={(e) => e.preventDefault()}>
                  <InputWrapper id='fsite-comment' className='form-item'>
                    <Textarea
                      disabled={
                        item?.is_media_display === 0 ||
                        item?.enable_comment === 0 ||
                        (item?.is_followed === 0 && item?.follow_flag === 1)
                      }
                      onChange={(e) => handleTextarea(e, item?.id, '38px')}
                      placeholder={t('comment.formAddRepComment.placeholder')}
                      onKeyDown={(e) => handleTextarea(e, item?.id, '38px')}
                      value={valueComment}
                      maxLength='500'
                      id={`comment-item1-${item?.id}`}
                      overflowHidden
                    />
                    <SendChatIcon
                      className={`${valueComment !== '' && 'active'}`}
                      onClick={() => valueComment !== '' && handlePostComment(item?.id, 0)}
                    />
                  </InputWrapper>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupViewFullTimeLine;
