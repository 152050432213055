import { CloseIcon } from 'images';
import './index.scss';
import { useTranslation } from 'react-i18next';
import ProfileContract from './profile-contract';
import { useState } from 'react';

const PerformerPopup = ({ title, handleClosePopup, listPerformer, setListPerformer }) => {
  const { t } = useTranslation();

  const [listPerformerSample, setListPerformerSample] = useState(listPerformer);
  return (
    <div className={`popup post-sale-performer`}>
      <div className='popup-content sale-performer'>
        <>
          <div className='title'>{title}</div>
          <CloseIcon className='close-icon' onClick={() => handleClosePopup()} />
          <div className='content'>
            <div className='content-wrapper'>
              <ProfileContract
                listPerformer={listPerformerSample}
                setListPerformer={setListPerformer}
                setListPerformerSample={setListPerformerSample}
                isShowProfile={false}
              />
            </div>
          </div>
          <div className='button-actions-relative'>
            <div className='box-button'>
              <button
                onClick={() => {
                  setListPerformer(listPerformerSample);
                  handleClosePopup()
                }}
                disabled={!listPerformerSample?.length}
                className='btn btn-bg-red text-white'>
                {t('Common.buttonComplete')}
              </button>
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default PerformerPopup;
