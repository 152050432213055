import { useRef, useEffect } from 'react';
export const useHorizontalScroll = () => {
  const elRef = useRef();
  useEffect(() => {
    const el = elRef.current;
    if (el) {
      document.body.style.overflow = 'auto';

      let isDown = false;
      let startX;
      let scrollLeft;

      el.addEventListener('mousedown', (e) => {
        isDown = true;
        el.classList.add('active');
        startX = e.pageX - el.offsetLeft;
        scrollLeft = el.scrollLeft;
      });
      el.addEventListener('mouseleave', () => {
        isDown = false;
        el.classList.remove('active');
      });
      el.addEventListener('mouseup', () => {
        isDown = false;
        el.classList.remove('active');
      });
      el.addEventListener('mousemove', (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - el.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        el.scrollLeft = scrollLeft - walk;
        // console.log(walk);
      });

      const onWheel = (e) => {
        if (e.deltaY == 0) return;
        e.preventDefault();
        // el.scrollTo({
        //   left: el.scrollLeft + el?.offsetWidth * e.deltaY,
        //   behavior: 'smooth',
        // });
      };
      el.addEventListener('wheel', onWheel);
      return () => el.removeEventListener('wheel', onWheel);
    }
  });
  return elRef;
};
