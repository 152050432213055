import Icon from '@ant-design/icons';

const TrashIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={props?.size || 20}
        height={props?.size || 20}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M16.875 4.375L3.125 4.37501'
          stroke={props?.color || '#1A1A1A'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M6.875 1.875H13.125'
          stroke={props?.color || '#1A1A1A'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M15.625 4.375V16.25C15.625 16.4158 15.5592 16.5747 15.4419 16.6919C15.3247 16.8092 15.1658 16.875 15 16.875H5C4.83424 16.875 4.67527 16.8092 4.55806 16.6919C4.44085 16.5747 4.375 16.4158 4.375 16.25V4.375'
          stroke={props?.color || '#1A1A1A'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default TrashIcon;
