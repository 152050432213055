import Icon from '@ant-design/icons';

const MasterCardIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={props?.width || 32}
        height={props?.height || 12}
        viewBox='0 0 48 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path d='M30.4911 3.35742H17.5117V26.6817H30.4911V3.35742Z' fill='#FF5F00' />
        <path
          d='M18.3347 15.0211C18.3327 12.7748 18.8417 10.5575 19.8233 8.53697C20.8049 6.51648 22.2332 4.74577 24.0003 3.35892C21.812 1.6389 19.184 0.569248 16.4166 0.272217C13.6491 -0.0248136 10.854 0.462766 8.35054 1.67922C5.84712 2.89568 3.73644 4.79193 2.25976 7.15125C0.783091 9.51056 0 12.2377 0 15.0211C0 17.8044 0.783091 20.5316 2.25976 22.8909C3.73644 25.2502 5.84712 27.1465 8.35054 28.3629C10.854 29.5794 13.6491 30.067 16.4166 29.7699C19.184 29.4729 21.812 28.4032 24.0003 26.6832C22.2333 25.2964 20.8049 23.5257 19.8233 21.5052C18.8417 19.4847 18.3327 17.2674 18.3347 15.0211Z'
          fill='#EB001B'
        />
        <path
          d='M47.9995 15.0211C47.9997 17.8044 47.2167 20.5315 45.7401 22.8908C44.2635 25.2502 42.1529 27.1464 39.6495 28.3629C37.1461 29.5794 34.351 30.067 31.5836 29.7699C28.8162 29.4729 26.1882 28.4032 24 26.6832C25.7655 25.295 27.1928 23.524 28.1742 21.5038C29.1556 19.4836 29.6655 17.267 29.6655 15.0211C29.6655 12.7751 29.1556 10.5585 28.1742 8.53833C27.1928 6.51816 25.7655 4.74717 24 3.35892C26.1882 1.6389 28.8162 0.569242 31.5836 0.272215C34.351 -0.0248127 37.1461 0.462775 39.6495 1.67925C42.1529 2.89572 44.2635 4.79198 45.7401 7.1513C47.2167 9.51061 47.9997 12.2378 47.9995 15.0211Z'
          fill='#F79E1B'
        />
        <path
          d='M46.5849 24.2125V23.735H46.7775V23.6377H46.2871V23.735H46.4797V24.2125H46.5849ZM47.537 24.2125V23.6367H47.3867L47.2138 24.0327L47.0408 23.6367H46.8904V24.2125H46.9966V23.7782L47.1587 24.1526H47.2688L47.4309 23.7772V24.2125H47.537Z'
          fill='#F79E1B'
        />
      </svg>
    )}
    {...props}
  />
);

export default MasterCardIcon;
