/* eslint-disable import/no-anonymous-default-export */

import _ from 'lodash';
import { TYPES } from 'store/actions';

const initialState = {
  status: null,
  loading: false,
  error: null,
  pagination: {},
  listCreator: [],
  listPost: [],
  detailCreator: {},
  postsCreator: [],
  listPostCreator: [],
  listLiveSteams: [],
  listNotificationCreator: [],
  statusLike: null,
  loadingAction: false,
  liveKey: null,
  listRecording: [],
  recordingDetail: null,
  listPostShortVideo: [],
  idScrollBackRecording: null,
  paginationRecording: {},
  loadingDel: false
};
// UI F002-C030
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_LIST_CREATOR_REQUEST:
    case TYPES.LIST_RECORDING_REQUEST:
    case TYPES.GET_DETAIL_RECORDING_REQUEST:
    case TYPES.UPDATE_DETAIL_RECORDING_REQUEST:
    case TYPES.GET_LIST_NOTIFICATIONS_CREATOR_REQUEST:
    case TYPES.POSTS_CREATOR_REQUEST:
    case TYPES.LIVE_STREAM_CREATOR_REQUEST:
      return {
        ...state,
        status: action.type,
        loading: true,
        postsCreator: [],
        listPostCreator: [],
      };
    case TYPES.DELETE_RECORDING_REQUEST:
      return {
        ...state,
        status: action.type,
        loadingDel: true,
      };
    case TYPES.DETAIL_CREATOR_REQUEST:
      return {
        ...state,
        status: action.type,
        loading: true,
        detailCreator: {},
      };
    case TYPES.LIST_POSTS_CREATOR_REQUEST:
      return {
        ...state,
        status: action.type,
        loading: true,
        // listPostCreator: [],
      };
    case TYPES.LIST_POSTS_SHORT_VIDEO_REQUEST:
      return {
        ...state,
        status: action.type,
        loading: true,
      };
    case TYPES.POST_LIKE_CREATOR_REQUEST:
      return {
        ...state,
        status: action.type,
      };
    case TYPES.POST_BOOKMARK_F017_REQUEST:
      return {
        ...state,
        loadingAction: true,
      };
    case TYPES.POST_BOOKMARK_F017_SUCCESS:
      state.listPostCreator = state.listPostCreator.map((item) => {
        if (+item.id === +action.data.data.post_id) {
          const newItem = { ...item };
          if (newItem.user_bookmark_status === 1) {
            newItem.user_bookmark_status = 0;
            --newItem.number_bookmark;
          } else {
            newItem.user_bookmark_status = 1;
            ++newItem.number_bookmark;
          }
          return newItem;
        } else return item;
      });
      return {
        ...state,
        loadingAction: false,
        listPostCreator: state.listPostCreator,
      };
    case TYPES.GET_LIST_CREATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        listCreator: action.data.res,
      };

    case TYPES.LIST_RECORDING_SUCCESS:
      return {
        ...state,
        loading: false,
        paginationRecording: action?.data?.res?.pagination,
        listRecording:
          action?.data?.res?.pagination?.current_page === 1
            ? action?.data?.res?.data
            : [...state.listRecording, ...action?.data?.res?.data],
      };

    case "SET_ID_SCROLL_BACK_RECORDING":
      return {
        ...state,
        idScrollBackRecording: action.data?.data?.id || null,
      };

    case "CLEAR_ID_SCROLL_BACK_RECORDING":
      return {
        ...state,
        listRecording: [],
        idScrollBackRecording: null,
        paginationRecording: {}
      };

    case TYPES.GET_DETAIL_RECORDING_SUCCESS:
      return {
        ...state,
        loading: false,
        recordingDetail: action.data.data,
      };

    case TYPES.UPDATE_DETAIL_RECORDING_SUCCESS:
      return {
        ...state,
        loading: false,
        recordingDetail: {
          ...state.recordingDetail,
          is_show: action.data.data?.is_show,
        },
      };

    case TYPES.DELETE_RECORDING_SUCCESS:
      return {
        ...state,
        listRecording: state.listRecording.filter((item) => item.id !== action?.data?.liveKey),
        loading: false,
        loadingDel: false
      };

    case TYPES.DETAIL_CREATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        detailCreator: action?.data?.res?.data,
      };
    case TYPES.POSTS_CREATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        postsCreator: action.data.res,
      };
    case TYPES.LIST_POSTS_CREATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        pagination: action?.data?.res?.pagination,
        listPostCreator:
          action?.data?.res?.pagination?.current_page === 1
            ? action?.data?.res?.data
            : [...state.listPostCreator, ...action?.data?.res?.data],
      };

    case TYPES.LIST_POSTS_SHORT_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        pagination: action?.data?.res?.pagination,
        listPostShortVideo:
          action?.data?.res?.pagination?.current_page === 1
            ? action?.data?.res?.data
            : [...state.listPostShortVideo, ...action?.data?.res?.data]?.filter(
                (value, index, self) => index === self.findIndex((t) => t.id === value.id)
              ),
      };

    case TYPES.GET_LIST_NOTIFICATIONS_CREATOR_SUCCESS:
      const _listNotificationCreator =
        action?.data?.res?.data.admin + action?.data?.res?.data.activity;
      return {
        ...state,
        loading: false,
        listNotificationCreator: _listNotificationCreator,
      };
    case TYPES.LIVE_STREAM_CREATOR_SUCCESS:
      const _liveKey = action.data?.res.data.map((item) => item?.live_key);
      return {
        ...state,
        loading: false,
        listLiveSteams: action?.data.res,
        liveKey: _liveKey,
      };
    case TYPES.FAN_FOLLOW_CREATOR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.FAN_FOLLOW_CREATOR_SUCCESS:
      state.detailCreator = {
        ...state.detailCreator,
        status_follow: action.data.data.status_follow,
      };
      return {
        ...state,
        loading: false,
        detailCreator: state.detailCreator,
      };
    case TYPES.FAN_FOLLOW_CREATOR_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };
    case TYPES.UPDATE_DETAIL_RECORDING_FAILED:
    case TYPES.POST_LIKE_CREATOR_FAILED:
    case TYPES.GET_LIST_CREATOR_FAILED:
    case TYPES.LIST_RECORDING_FAILED:
    case TYPES.GET_DETAIL_RECORDING_FAILED:
    case TYPES.DELETE_RECORDING_FAILED:
    case TYPES.DETAIL_CREATOR_FAILED:
    case TYPES.POSTS_CREATOR_FAILED:
    case TYPES.LIST_POSTS_CREATOR_FAILED:
    case TYPES.LIST_POSTS_SHORT_VIDEO_FAILED:
    case TYPES.LIVE_STREAM_CREATOR_FAILED:
    case TYPES.GET_LIST_NOTIFICATIONS_CREATOR_FAILED:
    case TYPES.POST_BOOKMARK_F017_FAILED:
      return {
        ...state,
        status: false,
        loading: false,
      };

    case 'RESET_ACTION_CREATOR_PAGE':
      return {
        ...state,
        listCreator: [],
        listPostCreator: [],
        pagination: {},
        // listRecording: [],
        listPostShortVideo: [],
      };
    case 'RESET_LIST_POST_SHORT_VIDEO':
      return {
        ...state,
        listCreator: [],
        listPostCreator: [],
        pagination: {},
        listRecording: [],
        listPostShortVideo: [],
      };

    case 'RESET_ACTION_DATA_POPUP_RECOR':
      return {
        ...state,
        recordingDetail: null,
      };

    default:
      return state;
  }
};
