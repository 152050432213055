import { InputWrapper } from 'components/Input/InputWrapper';
import { SendChatIcon } from 'images';
import Avatar from 'images/Avatar.png';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { postRepCommentDetail } from 'store/actions/usersNew';
import { checkLinkPostSale, linkS3, onErrImage } from 'utils/utils';
import './index.scss';
import Textarea from 'rc-textarea';
import { ImgCustom } from '..';
import { AVATAR_DEFAULT } from 'utils';
import { getInfoMetaPost } from 'store/actions/postMeta';
import MetaTagViewer from 'components/MetaTagViewer/MetaTagViewer';

const FormAddRepComment = ({
  item_comment,
  addCountComment,
  isEnableComment = true,
  setFlgRender1,
}) => {
  const dispatch = useDispatch();
  const { loadingRepCommentDetail, loadingPostComment } = useSelector(
    (state) => state.usersNew
  );
  const { dataUser } = useSelector((state) => state.users);
  const [checkSendChat, setCheckSendChat] = useState('');
  const [valueComment, setValueComment] = useState('');
  const { t } = useTranslation();
  const [idPostMeta, setIdPostMeta] = useState(null);
  const [postDetailMeta, setPostDetailMeta] = useState(null);

  const handleTextarea = (e, id) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    const idPost = checkLinkPostSale(e.target.value);
    if (idPost) {
      setIdPostMeta(idPost);
    } else {
      setIdPostMeta(null);
      setPostDetailMeta(null);
    }
    setValueComment(e.target.value);
    setCheckSendChat(id);
    if (e.target.value?.toString().trim() === '') {
      e.target.style.height = '44px';
      e.target.style.padding = '9px  30px  9px 12px';
      setCheckSendChat(null);
    }
  };

  useEffect(() => {
    if (idPostMeta) {
      dispatch(
        getInfoMetaPost(idPostMeta, (dataMeta) => {
          if (dataMeta?.title) {
            setPostDetailMeta(dataMeta);
          } else {
            setPostDetailMeta(null);
          }
        })
      );
    } else {
      setPostDetailMeta(null);
    }
  }, [idPostMeta, dispatch]);

  // api post reply comment
  const handlePostComment = (comment_id) => {
    setFlgRender1 && setFlgRender1(true);
    const data = {
      content: valueComment?.toString(),
    };
    if (postDetailMeta) {
      dispatch(postRepCommentDetail(comment_id, data, postDetailMeta));
    } else {
      dispatch(postRepCommentDetail(comment_id, data));
    }
    setIdPostMeta(null);
    setPostDetailMeta(null);
    setValueComment('')
  };

  useEffect(() => {
    setCheckSendChat(null);
  }, [loadingRepCommentDetail]);

  return (
    <div className='comment form-reply-comment'>
      {dataUser?.avatar ? (
        <ImgCustom screen='avartar_navbar_60_60' src={dataUser?.avatar} alt='avatar' />
      ) : (
        <img src={AVATAR_DEFAULT} alt='AVATAR_DEFAULT' />
      )}
      <form className={`form-reply-comment ${postDetailMeta?.title ? 'haveMeta' : ''}`} onSubmit={(e) => e.preventDefault()}>
        <InputWrapper
          id='fsite-comment'
          className={`form-item form-input-comment-meta ${!isEnableComment ? 'disable' : ''} ${
            postDetailMeta?.title ? 'haveMeta' : ''
          }`}>
          <Textarea
            disabled={!isEnableComment}
            onChange={(e) => handleTextarea(e, item_comment?.id)}
            placeholder={t('comment.formAddRepComment.textArea', {
              name: item_comment?.account_name,
            })}
            maxLength='500'
            value={checkSendChat ? valueComment : ''}
            id='textarea-post-rep-comment'
            autoSize
            style={{ background: '#fff' }}
          />
          {postDetailMeta?.title && (
            <MetaTagViewer
              title={postDetailMeta?.title}
              url={postDetailMeta?.url}
              thumbnail={postDetailMeta?.thumbnail}
            />
          )}
          <SendChatIcon
            // style={{ transform: "rotate(45deg)"}}
            className={`${valueComment !== '' && 'active'} icon-send-rep-comment`}
            onClick={() => {
              if (valueComment !== '' && !loadingPostComment && isEnableComment) {
                addCountComment && addCountComment();
                handlePostComment(item_comment?.id);
              }
            }}
          />
        </InputWrapper>
      </form>
    </div>
  );
};

export default FormAddRepComment;
