import { ImgCustom, PopupFanDetail, ReadMoreLess } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { newPathUser } from 'constants/layout/constant';
import {
  CommentIcon,
  Edit2Icon,
  Edit3Icon,
  FlagIcon,
  FlowUnFlowIcon,
  HeartIcon,
  ThreePointIcon,
  ThreePointIconV3,
  TrashIcon,
  UnFlowIcon,
} from 'images';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { editCommentDetailNew, getCommentDetail } from 'store/actions/usersNew';
import { convertToCurrentTime, decryptPath, isAdmin, isAgency } from 'utils/utils';
import useUserRole from '../../hooks/useUserRole';
import FormAddRepComment from './formAddRepComment';
import FormEditComment from './formEditComment';
import './index.scss';
import ListRepComment from './listRepComment';
import { AVATAR_DEFAULT, STORAGE } from 'utils';
import { forwardRef } from 'react';
import { useClickOutSize } from 'hooks/v2/clickOutSide';
import Storage from 'utils/storage';
import { logUserProfile } from 'store/actions/myPage';
import { isMobile } from 'react-device-detect';
import MarkDownRead from 'components/MarkDownRead';

const CommentPostTimeLine = forwardRef(
  (
    {
      handleDeleteComment,
      handleLikeComment,
      dataProfile,
      dataPost,
      handleFollowUserComment,
      id,
      addCountComment,
      idScrollable = 'sroll-list-comment',
      isPopupSaleContent = false,
      isEnableComment = true,
      setFlgRender1,
      handleClose,
    },
    ref
  ) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const {
      loadingCommentDetail,
      statusPostRepComment,
      statusEditComment,
      paginationComment,
      commentDetail,
    } = useSelector((state) => state.usersNew);
    const [showFormReplyComment, setFormReplyComment] = useState(-1);
    const [showFormEditComment, setFormEditComment] = useState(null);
    const [isVisiblePopup, setIsVisiblePopup] = useState(-1);
    const [isVisible, setIsVisible] = useState(false);
    const [idFan, seIdFan] = useState(false);
    const { isIdol } = useUserRole();
    const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
    // esc func
    const escFunction = useCallback((event) => {
      if (event.keyCode === 27) {
        setFormEditComment(null);
        setFormReplyComment(null);
      }
    }, []);
    // start func esc

    useEffect(() => {
      if (paginationComment?.current_page === 1) setPage(1);
    }, [paginationComment]);

    useEffect(() => {
      if (statusPostRepComment) {
        setFormReplyComment(null);
        const textareaId = document.getElementById('textarea-post-rep-comment');
        if (textareaId) textareaId.style.height = '38px';
      }

      if (statusEditComment) {
        setFormEditComment(null);
        const textareaId = document.getElementById('textarea-edit-comment');
        if (textareaId) textareaId.style.height = '38px';
      }

      document.addEventListener('keydown', escFunction);
      return () => {
        document.removeEventListener('keydown', escFunction);
      };
    }, [escFunction, statusPostRepComment, statusEditComment]);

    const [page, setPage] = useState(1);
    const itemsPerPage = 10;
    const fetchNext = () => {
      const pages = paginationComment?.total / paginationComment?.per_page;
      pages > page && setPage(page + 1);
    };

    //get list reply comment
    useEffect(() => {
      if (!id) return;
      dispatch(getCommentDetail(id, page, itemsPerPage));
    }, [page]);

    const checkDisabledAction = (item) => {
      if (isPopupSaleContent) return false;
      if (
        (item?.is_followed === 0 && item?.follow_flag === 1 && dataPost?.is_my_post === 0) ||
        (item?.is_media_display === 0 && dataPost?.is_my_post === 0)
      ) {
        return true;
      }
      return false;
    };

    const [refOutSideThree] = useClickOutSize(() => {
      setIsVisiblePopup(-1);
    });
    return (
      <>
        {isVisible && (
          <PopupFanDetail
            closeModal={() => setIsVisible(false)}
            isVisible={isVisible}
            id={idFan}
          />
        )}
        {/* {loadingCommentDetail && <ShimmerPostDetails hasImage imageType='circular' title />} */}
        {dataPost && commentDetail.length > 0 && (
          <div className='list_comment_detail block'>
            {ref && <div ref={ref} />}
            <div className='list-comment'>
              <InfiniteLoad
                loading={loadingCommentDetail}
                data={commentDetail}
                scrollableTargetCustom={idScrollable}
                fetchNext={fetchNext}>
                {commentDetail.map((item_comment, index) => {
                  return (
                    <>
                      <div key={index} className='comment-item-wrapper'>
                        <div className='comment-item'>
                          <div
                            className='avatar'
                            onClick={
                              !isAdmin
                                ? () => {
                                    handleClose && handleClose();
                                    navigate(`${newPathUser}${item_comment?.account_id}`);
                                  }
                                : null
                            }>
                            {!item_comment?.avatar ||
                            decryptPath(item_comment?.avatar) === 'NULL' ? (
                              <img src={AVATAR_DEFAULT} alt='avt'></img>
                            ) : (
                              <ImgCustom
                                isPlaceholderAvatar
                                screen='avatar_44_44'
                                src={`${item_comment?.avatar}`}
                              />
                            )}
                          </div>
                          <div className='content'>
                            {showFormEditComment?.id === item_comment?.id ? (
                              <>
                                <FormEditComment
                                  item_comment={showFormEditComment}
                                  handleCancelEdit={() => {
                                    setFormEditComment(null);
                                    setFormReplyComment(null);
                                    setIsVisiblePopup(-1);
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <div className='info-comment'>
                                  <div className='left'>
                                    <div className='dp-flex'>
                                      <div
                                        className='name custom_name'
                                        style={{ maxWidth: `${!isMobile && '166px'}`}}
                                        onClick={
                                          !isAdmin
                                            ? () => {
                                                handleClose && handleClose();
                                                navigate(
                                                  `${newPathUser}${item_comment?.account_id}`
                                                );
                                              }
                                            : null
                                        }>
                                        {item_comment?.account_name}
                                      </div>
                                      <div className='time'>
                                        {convertToCurrentTime(
                                          item_comment?.created_at,
                                          'YYYY/MM/DD HH:mm'
                                        )}
                                      </div>
                                      {item_comment.is_edited === 1 && (
                                        <Edit2Icon className='icon-edited mb-1' />
                                      )}
                                      {/* {item_comment?.user_id === dataProfile?.id && ( */}
                                      <div className={`box-popup ${isAdmin && 'disabled'}`}>
                                        {checkDisabledAction(dataPost) ? (
                                          <ThreePointIconV3 />
                                        ) : (
                                          <span
                                            className={`btn-three-point ${
                                              (isAdmin || isAgency) && 'disabled'
                                            }`}
                                            ref={refOutSideThree}
                                            onClick={() => {
                                              !checkDisabledAction(dataPost) &&
                                                setIsVisiblePopup(
                                                  isVisiblePopup === item_comment?.id
                                                    ? -1
                                                    : item_comment?.id
                                                );
                                            }}>
                                            <ThreePointIcon
                                              className='three-point-icon'
                                              onBlur={() => setIsVisiblePopup(-1)}
                                            />
                                          </span>
                                        )}
                                        {isVisiblePopup === item_comment?.id && (
                                          <div className='popup-edit-comment'>
                                            {item_comment?.user_id === dataProfile?.id && (
                                              <div
                                                className='item item1'
                                                onMouseDown={() => {
                                                  if (!isAuth) {
                                                    dispatch(logUserProfile());
                                                  } else {
                                                    dispatch(
                                                      editCommentDetailNew(
                                                        item_comment?.id,
                                                        ({ data }) => {
                                                          setFormEditComment(data);
                                                        }
                                                      )
                                                    );
                                                  }
                                                  //setIsVisiblePopup(-1);
                                                }}>
                                                <Edit3Icon color='#282727' />
                                                <span className='text'>
                                                  {t('comment.listComment.iconEdit')}
                                                </span>
                                              </div>
                                            )}
                                            {(item_comment?.user_id === dataProfile?.id ||
                                              dataPost?.is_my_post === 1) && (
                                              <div
                                                className='item'
                                                onMouseDown={() => {
                                                  if (!isAuth) {
                                                    setIsVisiblePopup(-1);
                                                    dispatch(logUserProfile());
                                                  } else {
                                                    setIsVisiblePopup(-1);
                                                    handleDeleteComment({
                                                      id: item_comment?.id,
                                                      type: 'comment',
                                                    });
                                                  }
                                                }}>
                                                <TrashIcon />
                                                <span className='text'>
                                                  {t('Common.buttonDelete')}
                                                </span>
                                              </div>
                                            )}
                                            {item_comment?.user_id !== dataProfile?.id && (
                                              <div
                                                className='item'
                                                onMouseDown={() => {
                                                  if (!isAuth) {
                                                    setIsVisiblePopup(-1);
                                                    dispatch(logUserProfile());
                                                  } else {
                                                    handleFollowUserComment({
                                                      id: item_comment?.user_id,
                                                      type: 'comment',
                                                    });
                                                    setIsVisiblePopup(-1);
                                                  }
                                                }}>
                                                {item_comment?.is_followed !== 1 ? (
                                                  <FlowUnFlowIcon />
                                                ) : (
                                                  <UnFlowIcon />
                                                )}
                                                <span className='text'>
                                                  {item_comment?.is_followed !== 1
                                                    ? t('comment.listComment.flow')
                                                    : t('comment.listComment.unflow')}
                                                </span>
                                              </div>
                                            )}
                                            {item_comment?.user_id !== dataProfile?.id &&
                                              isIdol && (
                                                <div
                                                  className='item'
                                                  onMouseDown={() => {
                                                    if (!isAuth) {
                                                      setIsVisiblePopup(-1);
                                                      dispatch(logUserProfile());
                                                    } else {
                                                      setIsVisiblePopup(-1);
                                                      navigate(`${newPathUser}post-report`, {
                                                        state: {
                                                          fan_id: item_comment?.user_id,
                                                          fan_name: item_comment?.account_name,
                                                          account_id: item_comment?.account_id,
                                                        },
                                                      });
                                                    }
                                                  }}>
                                                  <FlagIcon />
                                                  <span className='text'>
                                                    {t('comment.listComment.iconFlag')}
                                                  </span>
                                                </div>
                                              )}
                                          </div>
                                        )}
                                      </div>
                                      {/* )} */}
                                    </div>

                                    <div
                                      className='d-flex w-100 justify-content-between align-items-end'
                                      // style={{ maxWidth: 'calc(100% - 32px)' }}>
                                      style={{ maxWidth: '100%' }}>
                                      <div
                                        className='comment-content display-linebreak font-size-12 introduce readmore-top-cmt'
                                        style={{
                                          whiteSpace: 'pre-wrap',
                                          margin: '0',
                                          maxWidth: '550px',
                                          width: '100%',
                                        }}>
                                        <MarkDownRead
                                          postId={id}
                                          commentId={item_comment?.id}
                                          metaPost={item_comment?.metaPost}
                                          isMeta
                                          content={item_comment?.content?.toString() || ''}
                                          charLimit={isMobile ? 90 : 160}
                                        />
                                      </div>
                                      {/* {item_comment.is_edited === 1 && (
                                      <Edit3Icon className='icon-edited' />
                                    )} */}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  // className={`like-reply ${item_comment.total_rep_comment > 0 && '0'}`}
                                  className={`like-reply1 `}
                                  style={{
                                    pointerEvents: isAdmin || isAgency ? 'none' : 'pointer',
                                    color: isAdmin || isAgency ? '#B1B6BB' : '#787880',
                                    cursor: `${
                                      checkDisabledAction(dataPost) ||
                                      dataPost?.enable_comment === 0
                                        ? 'default'
                                        : 'pointer'
                                    }`,
                                  }}>
                                  <span
                                    className={`like `}
                                    onClick={() => {
                                      if (!isAuth) {
                                        dispatch(logUserProfile(dataPost));
                                      } else {
                                        if (!isAdmin) {
                                          !checkDisabledAction(dataPost) &&
                                            dataPost?.enable_comment !== 0 &&
                                            handleLikeComment({
                                              dataPost: item_comment,
                                              commentId: item_comment?.id,
                                              type: 'comment',
                                            });
                                        }
                                      }
                                    }}>
                                    <HeartIcon
                                      style={{
                                        color:
                                          item_comment?.is_liked === 1
                                            ? '#dc143c'
                                            : checkDisabledAction(dataPost) ||
                                              dataPost?.enable_comment === 0
                                            ? '#BEBEBE'
                                            : '#787880',
                                        cursor: `${
                                          checkDisabledAction(dataPost) ||
                                          dataPost?.enable_comment === 0
                                            ? 'default'
                                            : 'pointer'
                                        }`,
                                      }}
                                      fill={item_comment?.is_liked === 1 ? '#dc143c' : 'none'}
                                      className='cls-icon1 me-2'
                                    />
                                    <p
                                      style={{
                                        color: `${
                                          checkDisabledAction(dataPost) ||
                                          dataPost?.enable_comment === 0
                                            ? '#BEBEBE'
                                            : '#787880'
                                        }`,
                                        cursor: `${
                                          checkDisabledAction(dataPost) ||
                                          dataPost?.enable_comment === 0
                                            ? 'default'
                                            : 'pointer'
                                        }`,
                                        marginBottom: '0px',
                                      }}>
                                      {item_comment?.number_like}
                                    </p>
                                  </span>
                                  <span className={`comment-icon `}>
                                    <CommentIcon
                                      style={{
                                        color: `${
                                          checkDisabledAction(dataPost) ||
                                          dataPost?.enable_comment === 0
                                            ? '#BEBEBE'
                                            : '#787880'
                                        }`,
                                        cursor: `${
                                          checkDisabledAction(dataPost) ||
                                          dataPost?.enable_comment === 0
                                            ? 'default'
                                            : 'pointer'
                                        }`,
                                      }}
                                      className='cls-icon creator_post_live'
                                      onClick={() => {
                                        if (!isAuth) {
                                          dispatch(logUserProfile(dataPost));
                                        } else {
                                          !isAdmin &&
                                          !checkDisabledAction(dataPost) &&
                                          dataPost?.enable_comment !== 0 &&
                                          setFormReplyComment(item_comment?.id);
                                        }
                                      }}
                                    />
                                  </span>

                                  {/* {dataProfile?.id !== item_comment.user_id && !checkDisabledAction(dataPost) && (
                                  <span
                                    className={`follow ${
                                      item_comment?.is_followed === 1 && 'active'
                                    }`}
                                    onClick={() => {
                                      if (!isAdmin) {
                                        handleFollowUserComment({
                                          id: item_comment?.user_id,
                                          type: 'comment',
                                        });
                                      }
                                    }}>
                                    {item_comment?.is_followed === 1
                                      ? `${t('Common.unfollow')}`
                                      : `${t('Common.follow')}`}
                                  </span>
                                )} */}

                                  {/* {!!dataPost?.enable_comment && (
                                  <span
                                    className='reply-btn'
                                    onClick={() => {
                                      !isAdmin && setFormReplyComment(item_comment?.id);
                                    }}>
                                    {t('comment.listComment.buttonReply')}
                                  </span>
                                )} */}
                                </div>
                              </>
                            )}

                            {/* form reply comment */}
                            {showFormReplyComment === item_comment?.id && (
                              <FormAddRepComment
                                addCountComment={addCountComment}
                                item_comment={item_comment}
                                isEnableComment={isEnableComment}
                                setFlgRender1={setFlgRender1}
                                handleOnblur={() => setFormReplyComment(null)}
                              />
                            )}

                            {/* list reply comment */}
                            <ListRepComment
                              isPopupSaleContent={isPopupSaleContent}
                              item_comment={item_comment}
                              showFormReplyComment={showFormReplyComment}
                              handleDeleteComment={handleDeleteComment}
                              handleLikeComment={handleLikeComment}
                              dataPost={dataPost}
                              isEnableComment={isEnableComment}
                              handleFollowUserComment={handleFollowUserComment}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </InfiniteLoad>
            </div>
          </div>
        )}
      </>
    );
  }
);

export default CommentPostTimeLine;
