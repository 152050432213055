import { newPathUser } from 'constants/layout/constant';
import SuccessRegisterIcon from 'images/SuccessRegisterIcon';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getListPackageAnotherCreator, postNormalBuyPackage } from 'store/actions/sellPost';
import { getUserPoints } from 'store/actions/users';
import { isProdEnable } from 'utils';
import { PopupConfirmFan, PopupGeneral, PopupLoading } from '..';
import PopupConfirmError from './confirmErrrorBuy';
import './index.scss';

const PopupSellPackageF017 = ({
  id,
  openPopup = false,
  isVisiblePopup,
  buyPackageSuccess,
  item,
  creator_id,
  setCheckedInput,
  setIsOpen,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [successSellPackagePopup, setSuccessSellPackagePopup] = useState(false);
  const [notEnoughPointPopup, setNotEnoughPointPopup] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isCannotBuy, setIsCannotBuy] = useState(false);
  const { loading } = useSelector((state) => state.users);
  const { t } = useTranslation();

  useEffect(() => {
    setIsVisible(openPopup);
  }, [openPopup]);

  useEffect(() => {
    if (+item.price === 0) {
      dispatch(getUserPoints());
    }
  }, [dispatch, item.price]);

  return (
    <>
      {loading && <PopupLoading className={'popup-loading'} />}
      {successSellPackagePopup && (
        <PopupGeneral
          isVisible={isVisiblePopup}
          closeModal={() => {
            isVisiblePopup(false);
            dispatch(getListPackageAnotherCreator(creator_id));
          }}
          title={t('Plan.title')}
          className='popup-success-subscribe'>
          <SuccessRegisterIcon />
          <p className='text-center success-message py-3'>
            {t('Plan.buySub.successText', { title: item.title })}
          </p>
          <div className='button-wrapper'>
            <div
              className='ok btn'
              onClick={() => {
                // buyPackageSuccess(true);
                // setSuccessSellPackagePopup(false);
                // isVisiblePopup(false);
                // dispatch(getListPackageAnotherCreator(creator_id));
                isVisiblePopup(false);
                setCheckedInput(false);
                dispatch(getListPackageAnotherCreator(creator_id));

                // navigate(`${newPathUser}/${creator_id}`);
              }}>
              {t('Common.Ok')}
            </div>
          </div>
        </PopupGeneral>
      )}

      {notEnoughPointPopup && (
        <PopupConfirmFan
          isVisiblePopup={false}
          iconNote={true}
          colorSvg='#FFBF00'
          fillColor={true}
          text={
            <>
              <div>{t('Popup.NotEnoughPoints')}</div>
              <div>{t('Popup.buyPoint')}</div>
            </>
          }
          className='popup-content sell-package-page'>
          <div className='ok btn' onClick={() => navigate(`${newPathUser}purchase`)}>
            {t('Common.Ok')}
          </div>
          <div
            className='btn cancel'
            onClick={() => {
              isVisiblePopup(false);
              setNotEnoughPointPopup(false);
            }}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}

      {isCannotBuy && (
        <PopupConfirmError
          className='plan-list-error-buy-package'
          title={t('ValidateMsg.YOU_BOUGHT_PACKAGE_OF_THIS_USER')}
          isWarning={true}
          closeModal={() => {
            setCheckedInput(false);
            dispatch(getListPackageAnotherCreator(creator_id));
            setIsCannotBuy(false);
            isVisiblePopup(false);
          }}
        />
      )}

      {!loading && isVisible && (
        <PopupConfirmFan
          isVisiblePopup={false}
          iconNote={true}
          colorSvg='#FFBF00'
          fillColor={true}
          text={t('Popup.subscribeJoin')}
          className='popup-content sell-package-page'>
          <div
            className='ok btn'
            onClick={() => {
              setIsVisible(false);
              if (item.price === 0 || +isProdEnable === 0) {
                dispatch(
                  postNormalBuyPackage(
                    id,
                    () => {
                      setSuccessSellPackagePopup(true);
                    },
                    (error) => {
                      if (error.messageId === 'YOU_BOUGHT_PACKAGE_OF_THIS_USER') {
                        setIsCannotBuy(true);
                      }
                      error.messageId === 'TOO_SOME_SUBSCRIBERS' && isVisiblePopup(false);
                    }
                  )
                );
              } else if (+item.price !== 0) {
                isVisiblePopup(false);
                setIsOpen && setIsOpen(true);
              }
            }}>
            {t('Common.Ok')}
          </div>
          <div
            className='btn cancel'
            onClick={() => {
              isVisiblePopup(false);
            }}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}
    </>
  );
};

export default PopupSellPackageF017;
