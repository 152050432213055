import Icon from '@ant-design/icons';

const Edit2Icon = (props) => (
  <Icon
    component={() => (
      <svg
        width={props?.size || 14}
        height={props?.size || 14}
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M5.25 11.8129H2.625C2.50897 11.8129 2.39769 11.7668 2.31564 11.6848C2.23359 11.6027 2.1875 11.4915 2.1875 11.3754V8.93164C2.1875 8.87419 2.19882 8.8173 2.2208 8.76422C2.24279 8.71114 2.27502 8.66291 2.31564 8.62228L8.87814 2.05978C8.96019 1.97773 9.07147 1.93164 9.1875 1.93164C9.30353 1.93164 9.41481 1.97773 9.49686 2.05978L11.9406 4.50356C12.0227 4.58561 12.0688 4.69689 12.0688 4.81292C12.0688 4.92895 12.0227 5.04023 11.9406 5.12228L5.25 11.8129Z'
          stroke={props?.color || '#BEBEBE'}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.4375 3.5L10.5 6.5625'
          stroke={props?.color || '#BEBEBE'}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default Edit2Icon;
