export default function LineIcon(props) {
  return (
    <svg
      width={44}
      height={44}
      viewBox='0 0 44 44'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle cx={22} cy={22} r='21.5' fill='white' stroke='#E5E6EB' />
      <path
        d='M34.7154 20.0284C34.7154 14.4988 29.1719 10 22.3577 10C15.5435 10 10 14.4988 10 20.0284C10 24.9858 14.4015 29.1383 20.3342 29.9224C20.7373 30.0093 21.2851 30.1877 21.423 30.5317C21.5481 30.8444 21.5052 31.3344 21.4635 31.6494C21.4635 31.6494 21.3187 32.5216 21.2874 32.7069C21.233 33.0197 21.0384 33.9289 22.3577 33.3741C23.677 32.8193 29.4742 29.1834 32.0664 26.1986C33.8571 24.2353 34.7154 22.2384 34.7154 20.0284Z'
        fill='#06C755'
      />
      <path
        d='M30.603 23.2203H27.1281C27.0667 23.2203 27.0078 23.1959 26.9643 23.1525C26.9209 23.1091 26.8965 23.0501 26.8965 22.9887V17.5969C26.8965 17.5355 26.9209 17.4765 26.9643 17.4331C27.0078 17.3896 27.0667 17.3652 27.1281 17.3652H30.603C30.6644 17.3652 30.7233 17.3896 30.7668 17.4331C30.8102 17.4765 30.8346 17.5355 30.8346 17.5969V18.4737C30.8346 18.5351 30.8102 18.5941 30.7668 18.6375C30.7233 18.681 30.6644 18.7054 30.603 18.7054H28.2436V19.6146H30.603C30.6644 19.6146 30.7233 19.639 30.7668 19.6825C30.8102 19.7259 30.8346 19.7848 30.8346 19.8463V20.7312C30.8346 20.7926 30.8102 20.8516 30.7668 20.895C30.7233 20.9384 30.6644 20.9629 30.603 20.9629H28.2436V21.8733H30.603C30.6644 21.8733 30.7233 21.8977 30.7668 21.9411C30.8102 21.9846 30.8346 22.0435 30.8346 22.1049V22.9806C30.8357 23.0117 30.8305 23.0427 30.8194 23.0717C30.8082 23.1008 30.7913 23.1273 30.7697 23.1497C30.7481 23.172 30.7222 23.1898 30.6936 23.202C30.6649 23.2141 30.6341 23.2204 30.603 23.2203Z'
        fill='white'
      />
      <path
        d='M17.7616 23.2215C17.8231 23.2215 17.882 23.1971 17.9254 23.1537C17.9689 23.1102 17.9933 23.0513 17.9933 22.9898V22.1142C17.9933 22.0527 17.9689 21.9938 17.9254 21.9504C17.882 21.9069 17.8231 21.8825 17.7616 21.8825H15.4022V17.5969C15.4022 17.5355 15.3778 17.4765 15.3343 17.4331C15.2909 17.3896 15.232 17.3652 15.1705 17.3652H14.2902C14.2288 17.3652 14.1699 17.3896 14.1264 17.4331C14.083 17.4765 14.0586 17.5355 14.0586 17.5969V22.9864C14.0586 23.0478 14.083 23.1067 14.1264 23.1502C14.1699 23.1936 14.2288 23.218 14.2902 23.218H17.7651L17.7616 23.2215Z'
        fill='white'
      />
      <path
        d='M19.8522 17.3574H18.9765C18.846 17.3574 18.7402 17.4632 18.7402 17.5937V22.9867C18.7402 23.1172 18.846 23.223 18.9765 23.223H19.8522C19.9827 23.223 20.0885 23.1172 20.0885 22.9867V17.5937C20.0885 17.4632 19.9827 17.3574 19.8522 17.3574Z'
        fill='white'
      />
      <path
        d='M25.8182 17.3574H24.9425C24.8811 17.3574 24.8222 17.3818 24.7787 17.4253C24.7353 17.4687 24.7109 17.5276 24.7109 17.5891V20.7929L22.2437 17.4605C22.2381 17.4517 22.2315 17.4435 22.2241 17.4362L22.2102 17.4223L22.1974 17.4119H22.1905L22.1777 17.4026H22.1708L22.158 17.3956H22.1499H22.1372H22.1291H22.1152H22.1059H22.092H22.0827H22.07H21.177C21.1155 17.3956 21.0566 17.4201 21.0132 17.4635C20.9697 17.5069 20.9453 17.5659 20.9453 17.6273V23.0203C20.9453 23.0817 20.9697 23.1406 21.0132 23.1841C21.0566 23.2275 21.1155 23.2519 21.177 23.2519H22.0538C22.1152 23.2519 22.1741 23.2275 22.2176 23.1841C22.261 23.1406 22.2854 23.0817 22.2854 23.0203V19.7852L24.7561 23.121C24.7722 23.1446 24.7927 23.1651 24.8163 23.1813L24.8302 23.1905H24.8371L24.8487 23.1963H24.8603H24.8684H24.8846C24.9051 23.2014 24.9261 23.2041 24.9472 23.2044H25.8182C25.8796 23.2044 25.9386 23.18 25.982 23.1366C26.0255 23.0931 26.0499 23.0342 26.0499 22.9728V17.5937C26.0505 17.5629 26.0449 17.5323 26.0336 17.5036C26.0222 17.475 26.0052 17.4489 25.9837 17.4269C25.9621 17.4049 25.9363 17.3874 25.9079 17.3755C25.8795 17.3636 25.849 17.3574 25.8182 17.3574Z'
        fill='white'
      />
    </svg>
  );
}
