import Icon from '@ant-design/icons';

const IconRanking = ({ isActive }) => {
  return (
    <Icon
      component={() => (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='25'
          height='24'
          viewBox='0 0 25 24'
          fill='none'>
          <path
            d='M5.625 5.25V10.4153C5.625 14.1372 8.602 17.2222 12.3238 17.2498C13.2145 17.2566 14.0978 17.087 14.9226 16.7508C15.7475 16.4146 16.4976 15.9185 17.1298 15.291C17.7621 14.6636 18.2639 13.9172 18.6063 13.0949C18.9487 12.2727 19.125 11.3907 19.125 10.5V5.25C19.125 5.05109 19.046 4.86032 18.9053 4.71967C18.7647 4.57902 18.5739 4.5 18.375 4.5H6.375C6.17609 4.5 5.98532 4.57902 5.84467 4.71967C5.70402 4.86032 5.625 5.05109 5.625 5.25Z'
            stroke={isActive ? '#DC143C' : '#BEBEBE'}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M9.375 21H15.375'
            stroke={isActive ? '#DC143C' : '#BEBEBE'}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M12.375 17.25V21'
            stroke={isActive ? '#DC143C' : '#BEBEBE'}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M18.957 12H19.8743C20.67 12 21.433 11.6839 21.9956 11.1213C22.5582 10.5587 22.8743 9.79565 22.8743 9V7.5C22.8743 7.30109 22.7953 7.11032 22.6546 6.96967C22.514 6.82902 22.3232 6.75 22.1243 6.75H19.1243'
            stroke={isActive ? '#DC143C' : '#BEBEBE'}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M5.80932 12H4.86328C4.06763 12 3.30457 11.6839 2.74196 11.1213C2.17935 10.5587 1.86328 9.79565 1.86328 9V7.5C1.86328 7.30109 1.9423 7.11032 2.08295 6.96967C2.2236 6.82902 2.41437 6.75 2.61328 6.75H5.61328'
            stroke={isActive ? '#DC143C' : '#BEBEBE'}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      )}
    />
  );
};

export default IconRanking;
