import { useState, useEffect } from "react";

const useUploadImage = () => {
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [preview, setPreview] = useState();

    useEffect(() => {
        if (!selectedFile) {
          setPreview(undefined);
          return;
        }
        const objectUrl = URL.createObjectURL(selectedFile);
        setPreview(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
      }, [selectedFile]);
    const destroyState = () => {
        setSelectedFile(undefined);
        setPreview(null);
    }
    return {
        selectedFile, setSelectedFile, preview, setPreview, destroyState
    }
}

export default useUploadImage;