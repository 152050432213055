import React from 'react';
import styled from 'styled-components';
import { StartNormalIcon } from '../../images';
import './index.scss';

const ButtonBookmark = ({ className, post, number_bookmark, bookmark_status, ...props }) => {
  return (
    <div
      className={` wrapper-bookmark ${className} ${
        (post?.user_bookmark_status === 1 || bookmark_status === 1) && 'active'
      }`}
      {...props}
      id='component-bookmark'>
      <div
        className={`button-bookmark ${
          (post?.user_bookmark_status === 1 || bookmark_status === 1) && 'active'
        }`}>
        <StartNormalIcon />
        <span className='number-book-mark'>
          {post?.number_bookmark || number_bookmark || 0}
        </span>
      </div>
    </div>
  );
};

export default ButtonBookmark;
