import { LayoutCenter } from 'components';
import './index.scss';
const AboutCompany = () => {
  return (
    <div style={{ maxWidth: 1056, display: 'flex', margin: 'auto', justifyContent: 'center' }}>
      <LayoutCenter titlePage='会社概要' notLogin={true} isShowLeftIcon={false} uiMobile className={'title-page-creator page-legal'}>
        <div className='about-company-page'>
          <div className=''>
            この利用規約（以下，「本規約」といいます。）は，株式会社ZESS（以下，「当社」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本サービスをご利用いただきます。
          </div>
          <h2>対象</h2>
          <div>
            株式会社ZESS（以下「当社」といいます。）は、当社が運営・提供するウェブサイト（スマートフォン向けウェブサイトを含みます。以下同じ）及びアプリケーションに関する製品及びサービス（以下総じて「本サービス」といいます）を利用するお客様（以下「ユーザー」といいます。）から、本サービス利用に伴い各情報をお預かりするにあたり、各情報の保護に努め、本サービスの円滑な提供を実施するため、アプリケーションプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。
          </div>
          <div>
            ユーザーによる本サービスのご利用に際し、当社は、ユーザーから以下の情報を取得する場合があります。
            ユーザーによる本サービスのご利用に際し、当社は、ユーザーから以下の情報を取得する場合があります。
            当社は、本サービスを運営するウェブサイト及びアプリケーションの提供、利用状況の解析等並びに広告表示等を目的として、上記ウェブサイト及びアプリケーションの機能とは独立した以下の情報収集モジュールを利用することがあります。
            Google
            Inc.「GoogleAnalytics」「FirebaseAnalytics」「FirebaseCrashlytics」利用目的：本サービスの向上・マーケティング調査の目的でアクセス解析を行うため
            対象URL：https://policies.google.com/privacy
            当社は本ポリシーの改善に努め、本ポリシーの継続的な見直しを行います。本ポリシーの改訂を行った場合、当社は改訂後のポリシーを周知するものとし、重要な変更を行う際は本サービス内において別途告知いたします。
            ユーザーは、本ポリシー及び本サービスの利用規約をご確認いただき、いずれも同意の上で本サービスをご利用ください。お客様が本サービスを利用したこと（変更後の本ポリシーについては、変更後において本サービスの利用を継続したこと）を以て、本ポリシー等に同意したものとみなします。
          </div>
        </div>
      </LayoutCenter>
    </div>
  );
};
export default AboutCompany;
