import Icon from '@ant-design/icons';

const ChatIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        fill='none'
        viewBox='0 0 20 20'>
        <path
          fillRule='evenodd'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M15.892 15.892a8.338 8.338 0 01-9.404 1.67c-.456-.183-.83-.332-1.184-.332-.99.006-2.22.965-2.86.326-.64-.64.32-1.872.32-2.867 0-.355-.143-.722-.326-1.178a8.338 8.338 0 011.67-9.405c3.251-3.252 8.533-3.252 11.784 0a8.335 8.335 0 010 11.786z'
          clipRule='evenodd'></path>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='2'
          d='M13.282 10.344h.008M9.942 10.344h.008M6.6 10.344h.008'></path>
      </svg>
    )}
    {...props}
  />
);

export default ChatIcon;
