import { InputWrapper } from 'components/Input/InputWrapper';
import MetaTagViewer from 'components/MetaTagViewer/MetaTagViewer';
import { SendChatIcon } from 'images';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getInfoMetaPost } from 'store/actions/postMeta';
import { postCommentDetail } from 'store/actions/usersNew';
import { checkLinkPostSale } from 'utils';

const CommentForm = ({ item, setTrickReloadComment = () => {} }) => {
  const { t } = useTranslation();
  const [valueComment, setValueComment] = useState('');
  const [idPostMeta, setIdPostMeta] = useState(null);
  const [checkSendChat, setCheckSendChat] = useState(null);
  const [postDetailMeta, setPostDetailMeta] = useState(null);
  const dispatch = useDispatch();
  const refArea = useRef(null);

  useEffect(() => {
    if (idPostMeta) {
      dispatch(
        getInfoMetaPost(idPostMeta, (dataMeta) => {
          if (dataMeta?.title) {
            setPostDetailMeta(dataMeta);
          } else {
            setPostDetailMeta(null);
          }
        })
      );
    }
  }, [idPostMeta, dispatch]);

  const disableArea = (item) => {
    return (
      (item?.is_followed === 0 && item?.follow_flag === 1) ||
      (!item?.is_media_display && item?.type === 1) ||
      item?.enable_comment === 0
    );
  };

  const handlePostComment = (id, parent_id) => {
    const data = {
      parent_id: parent_id,
      content: valueComment,
    };
    checkSendChat &&
      dispatch(
        postCommentDetail(
          id,
          data,
          (data) => {
            setIdPostMeta(null);
            setValueComment('');
            setCheckSendChat(null);
            setPostDetailMeta(null);
            setTrickReloadComment({
              id: id,
              data: data.data,
            });
          },
          postDetailMeta,
          false
        )
      );

    refArea.current.style.height = '44px';
  };

  const handleTextarea = (e, id, height) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    const { value } = e.target;
    const idPost = checkLinkPostSale(value);
    if (idPost) {
      setIdPostMeta(idPost);
    } else {
      setIdPostMeta(null);
      setPostDetailMeta(null);
    }
    setValueComment(value);
    setCheckSendChat(id);
    if (value?.toString().trim() === '') {
      e.target.style.height = height;
      e.target.style.padding = '9px  30px  9px 12px';
      setCheckSendChat(null);
    }
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <InputWrapper
        id='fsite-comment'
        className={`form-item form-input-comment-meta ${disableArea(item) ? 'disable' : ''} ${
          postDetailMeta?.title ? 'haveMeta' : ''
        }`}>
        <textarea
          ref={refArea}
          disabled={disableArea(item)}
          onChange={(e) => handleTextarea(e, item?.id, '44px')}
          placeholder={t('comment.formAddRepComment.placeholder')}
          value={valueComment}
          id-parent={item?.id}
          maxLength='500'
          id={`comment-item-${item?.id}`}
          style={{
            background: `${disableArea(item) ? '#efefef' : '#fff'}`,
            padding: '9px 12px',
          }}
        />
        {postDetailMeta?.title && (
          <MetaTagViewer
            title={postDetailMeta?.title}
            url={postDetailMeta?.url}
            thumbnail={postDetailMeta?.thumbnail}
          />
        )}
        <SendChatIcon
          style={{
            cursor: `${item?.is_followed === 0 && item?.follow_flag === 1 && 'default'}`,
          }}
          className={`${valueComment && 'active'}`}
          onClick={() => {
            // TODO:
            valueComment && handlePostComment(item?.id, 0);
            setValueComment('');
          }}
        />
      </InputWrapper>
    </form>
  );
};

export default CommentForm;
