import Icon from '@ant-design/icons';

const CoinBadge = (props) => (
  <Icon
    component={() => (
      <svg
        width='67'
        height='67'
        viewBox='0 0 67 67'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M33.1406 66.5078C14.9579 66.5078 0.174805 51.8047 0.174805 33.7203C0.174805 15.636 14.9579 0.932884 33.1406 0.932884C51.3232 0.932884 66.1063 15.636 66.1063 33.7203C66.1063 51.8047 51.3232 66.5078 33.1406 66.5078ZM33.1406 4.77517C17.0955 4.77517 4.03798 17.7621 4.03798 33.7203C4.03798 49.6786 17.0955 62.6655 33.1406 62.6655C49.1856 62.6655 62.2432 49.6786 62.2432 33.7203C62.2432 17.7621 49.1856 4.77517 33.1406 4.77517Z'
          fill='white'
        />
        <path
          d='M33.1408 58.8281C19.2205 58.8281 7.90137 47.5702 7.90137 33.7252C7.90137 19.8802 19.2205 8.62233 33.1408 8.62233C47.0611 8.62233 58.3802 19.8802 58.3802 33.7252C58.3802 47.5702 47.0611 58.8281 33.1408 58.8281ZM33.1408 12.4646C21.3581 12.4646 11.7645 22.0063 11.7645 33.7252C11.7645 45.4442 21.3581 54.9858 33.1408 54.9858C44.9235 54.9858 54.517 45.4442 54.517 33.7252C54.517 22.0063 44.9235 12.4646 33.1408 12.4646Z'
          fill='white'
        />
        <path
          d='M42.5668 43.5312H23.6759C22.8131 43.5312 22.0534 42.9549 21.8216 42.1352L18.2546 29.6094C18.0614 28.9434 18.2546 28.2262 18.7439 27.7395C19.2332 27.2528 19.9544 27.0607 20.624 27.24L26.4703 28.8281L31.634 22.1938C31.9817 21.7071 32.5483 21.4381 33.1407 21.4381C33.733 21.4381 34.2996 21.7071 34.6731 22.181L39.8368 28.8153L45.6831 27.2272C46.3527 27.0479 47.0739 27.24 47.5632 27.7267C48.0525 28.2262 48.2328 28.9434 48.0525 29.6094L44.4469 42.1352C44.1894 42.9549 43.4296 43.5312 42.5668 43.5312ZM25.131 39.689H41.1117L43.3781 31.8379L39.6051 32.8625C38.8453 33.0674 38.0469 32.7985 37.5704 32.1837L33.1407 26.4971L28.7238 32.1837C28.2473 32.7985 27.4361 33.0674 26.6892 32.8625L22.9033 31.8251L25.131 39.689Z'
          fill='white'
        />
      </svg>
    )}
    {...props}
  />
);

export default CoinBadge;
