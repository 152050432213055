/* eslint-disable react-hooks/exhaustive-deps */
// import React, { useState, useEffect } from 'react';
// import { db } from '../../firebase/config';
// import { query, collection, where, onSnapshot } from 'firebase/firestore';

// export const NumberViews = (props) => {
//   const [views, setViews] = useState(null);
//   const { id } = props;
//   const collectionRef = collection(db, 'lives-user-watch');
//   // const getViews = () => {
//   //   const q = query(collectionRef, where('key', '==', `ducnhm2-at-bapjp-1647518400-223857703396397`));
//   //   onSnapshot(q, (querySnapshot) => {
//   //     querySnapshot.forEach((doc) => {
//   //       console.log("DSDSDS: ", doc.len);
//   //       setViews(doc.data().number_views);
//   //     });
//   //   });
//   // };
//   let unsubscribed = false;
//   const countListener = () => {
//     if (unsubscribed) return; // unsubscribed? do nothing.
//     const q = query(collectionRef, where('key', '==', `${id}`));
//     // let count = 0;
//     return onSnapshot(q, (snapshot) => {
//       setViews(snapshot.size);
//       // snapshot.docChanges().forEach(change => {
//       //   console.log("CHANGE TYPE: ", change);
//       //   console.log("VIEW-TYPE: ", views, change.doc.si);
//       //  if (change.type === "added") setViews(views + 1);
//       //  if (change.type === "removed") setViews([...views, views - 1]);
//       // });
//     });

//     // return q.onSnapshot(snapshot => {
//     //     snapshot.docChanges().forEach(change => {
//     //         if (change.type === "added") onCountChange(++count);
//     //         if (change.type === "removed") onCountChange(--count);
//     //     });
//     // });
//   };

//   useEffect(() => {
//     countListener(); 
//     return () => unsubscribed  = true; 
//   }, []);
  
//   return <>{views}</>;
// };


import React, { useState, useEffect } from 'react';
import { DBRealTime } from '../../firebase/config';
import {
  onValue,
  ref
} from 'firebase/database';
import { formatNumber } from 'utils';

export const NumberViews = (props) => {
  const [views, setViews] = useState(null);
  const { id } = props;
  const getRealTimeNumberViews = () => {
    
    onValue(ref(DBRealTime, `/lives/${id}`), (snapshot) => {
      if (snapshot.val() === false) {
        return;
      }
      
      if (snapshot.size >= 1) {
        if (snapshot.val().likes) {
          setViews(snapshot.size-2)
        } else {
          setViews(snapshot.size - 1);
        }
        
      } else {
        setViews(null);
      }
    });
  }
  
  useEffect(() => {
    getRealTimeNumberViews();
  }, []);

  return <>{formatNumber(views)}</>;
};

