import Icon from '@ant-design/icons';

const Video2Icon = (props) => (
  <Icon
    component={() => (
      <svg
        width={23}
        height={23}
        viewBox='0 0 23 23'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M15.1595 7.92571V16.1943C15.1591 16.5597 15.0137 16.9099 14.7554 17.1683C14.497 17.4266 14.1467 17.572 13.7814 17.5724H4.1347C3.22128 17.5713 2.34558 17.208 1.6997 16.5621C1.05381 15.9163 0.690498 15.0406 0.689453 14.1271V5.85857C0.689871 5.4932 0.835197 5.14292 1.09355 4.88457C1.3519 4.62621 1.70218 4.48089 2.06755 4.48047H11.7142C12.6276 4.48151 13.5033 4.84483 14.1492 5.49071C14.7951 6.13659 15.1584 7.0123 15.1595 7.92571ZM21.0177 6.2962C20.9133 6.23564 20.7949 6.20356 20.6742 6.20314C20.5535 6.20273 20.4348 6.23401 20.33 6.29385L16.8848 8.26258C16.7793 8.32285 16.6916 8.40993 16.6307 8.515C16.5697 8.62007 16.5376 8.73939 16.5376 8.86087V13.192C16.5376 13.3135 16.5697 13.4328 16.6307 13.5379C16.6916 13.6429 16.7793 13.73 16.8848 13.7903L20.33 15.759C20.4348 15.8189 20.5535 15.8502 20.6742 15.8498C20.7949 15.8493 20.9133 15.8172 21.0177 15.7567C21.1221 15.6961 21.2087 15.6091 21.269 15.5046C21.3292 15.4 21.3609 15.2814 21.3609 15.1607V6.89214C21.3609 6.77145 21.3292 6.65287 21.269 6.54828C21.2088 6.4437 21.1221 6.35677 21.0177 6.2962Z'
          fill='#F2F3F5'
        />
      </svg>
    )}
    {...props}
  />
);

export default Video2Icon;
