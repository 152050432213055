import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Chart from './components/chart';
import Table from './components/table';
import { getChartRevenues } from 'store/actions/chartRepot';
const Revenues = ({ date }) => {
  const { from_date, to_date } = date;
  const dispatch = useDispatch();
  const { chartRevenues } = useSelector((state) => state.chartReport);
  useEffect(() => {
    dispatch(getChartRevenues({ from_date, to_date }));
  }, [from_date, to_date]);

  return (
    <>
      <Chart data={chartRevenues || []} />
      <Table columns={chartRevenues} />
    </>
  );
};
export default Revenues;
