import ResetPassword from './ResetPassword';

const Index = () => {
  const rootClass = 'reset-password-page';

  return (
    <div className={`${rootClass} h-100`}>
      <ResetPassword />
    </div>
  );
};

export default Index;
