import { LayoutCreatorRight, PopupLoading } from 'components';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './index.scss';
import Items from './item';
import lodash from 'lodash';
import {
  getListLiveStream,
  setIdScrollListLive,
} from 'store/actions/lives';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { useNavigate } from 'react-router-dom';
import { newPathUser } from 'constants/layout/constant';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { PlusOutlined } from '@ant-design/icons';
import LocalStorage from 'utils/storage';
import { useGetIdFromRecommendUser } from 'hooks/v2/useGetIdFromRecommendUser';
import { resetDataInfoOther, resetIDHomePage } from 'store/actions/usersNew';

export default function ListLivestream() {
  const dispatch = useDispatch();
  const [isScrollToTop, setIsScrollToTop] = useState(true);
  const { idFromRecommendUser, clearIdScrollBackRecommend } = useGetIdFromRecommendUser();
  let data = useSelector((state) => state.lives.listLiveStream);
  let idListLiveScrollBack = useSelector((state) => state.lives.idListLiveScrollBack);
  let pagination = useSelector((state) => state.lives.paginationListLive);
  const loading = useSelector((state) => state.lives.loadingListLive);

  const loadingDeleteLive = useSelector((state) => state.lives.loadingDeleteLive);
  const [page, setPage] = useState(
    pagination?.current_page ? +pagination?.current_page + 1 : 1
  );
  const itemsPerPage = 10;
  const [isFromLive, setIsFromLive] = useState(LocalStorage.get('from-live'));

  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    if (idListLiveScrollBack) {
      const idScroll = document.getElementById(idListLiveScrollBack);
      if (idScroll) {
        idScroll.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idListLiveScrollBack]);

  useEffect(() => {
    if (!idListLiveScrollBack && isScrollToTop) {
      const top = document.getElementById('app_cocofans_0');
      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
    }
  }, [idListLiveScrollBack, isScrollToTop]);


  useEffect(() => {
    if (LocalStorage.get('from-live')) {
      setIsFromLive(LocalStorage.get('from-live'));
    }
  }, [LocalStorage.get('from-live')]);

  const fetchNext = () => {
    const pages = pagination?.total / pagination?.per_page;
    if (pages > page) {
      if (idListLiveScrollBack || idFromRecommendUser) {
        setIsScrollToTop(false);
        dispatch(setIdScrollListLive());
        clearIdScrollBackRecommend();
      } else {
        setPage(page + 1);
      }
    } else if (pages + 1 > page) {
      if (idListLiveScrollBack || idFromRecommendUser) {
        setIsScrollToTop(false);
        dispatch(setIdScrollListLive());
        clearIdScrollBackRecommend();
      }
    }
  };
  if (data.length > 0) {
    data = lodash.uniqBy(data, 'id');
  }

  useEffect(() => {
    if (!idListLiveScrollBack && !idFromRecommendUser) {
      dispatch(getListLiveStream(page, itemsPerPage));
    }
  }, [page, idListLiveScrollBack, idFromRecommendUser, dispatch]);

  const handleCb = () => {
    if (+isFromLive === 1) {
      LocalStorage.remove('from-live');
      dispatch(resetIDHomePage());
      dispatch(resetDataInfoOther());
      navigate(`${newPathUser}`);
    } else {
      navigate(-1);
    }
  };

  return (
    <LayoutCreatorRight
      noButtonBack
      cb={handleCb}
      titlePage={t('Creator.CreateLiveStream.duringDelivery')}
      className={'creator-live-list-page during-delivery-livestreams-layout'}>
      {loading && !data.length && (
        <div className='shimmer-loading'>
          <ShimmerPostDetails hasImage imageType='circular' title />
        </div>
      )}
      {!loading && (
        <>
          <div className={`box-btn-add-live ${!data.length && 'no-data'}`}>
            {!data.length && (
              <div className='text'>{t('Creator.ListLiveStream.noLiveStreamingList')}</div>
            )}
            <div
              id='list-live-btn-create'
              className='btn btn-create-live'
              onClick={() => {
                dispatch(
                  setIdScrollListLive(`list-live-btn-create`, () => {
                    navigate(`${newPathUser}live/create`, { state: { createLive: true } });
                  })
                );
              }}>
              <PlusOutlined />
              {t('Creator.ListLiveStream.createNewLiveStream')}
            </div>
          </div>
        </>
      )}

      {data.length > 0 && (
        <InfiniteLoad loading={loading} data={data} fetchNext={fetchNext}>
          <div className='creator-list-live-stream'>
            {data.map((item, index) => (
              <>
                {index === 0 && item?.status === 3 ? (
                  <Items
                    isDataFirst
                    key={index}
                    id={item?.id}
                    item={item}
                    liveKey={item?.live_key || ''}
                    password={item?.is_password}
                  />
                ) : (
                  <Items
                    key={index}
                    id={item?.id}
                    item={item}
                    liveKey={item?.live_key || ''}
                    password={item?.is_password}
                  />
                )}
              </>
            ))}
          </div>
        </InfiniteLoad>
      )}

      {loadingDeleteLive && <PopupLoading className={'popup-loading'} />}
    </LayoutCreatorRight>
  );
}
