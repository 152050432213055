import i18n from 'translate/i18n';

export const DATA_NAVBAR = [
  { title: i18n.t('User.tabProfile'), link: '/user/profile' },
  { title: i18n.t('User.tabChangePass'), link: '/user/profile/change-password' },
];

export const DATA_NAVBAR_AFFILIATOR = [
  { title: i18n.t('User.tabProfile'), link: '/user/profile' },
  { title: i18n.t('User.tabChangePass'), link: '/user/profile/change-password' },
  { title: i18n.t('User.tabAffiliate'), link: '/user/profile/transfer-account-infor' },
];
