import { DBRealTime } from '../../../../../firebase/config';
import { onValue, orderByChild, ref, limitToLast, query } from 'firebase/database';
import { deleteData, getOne } from 'hooks/useRealTimeDB';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ImageEffect, WrapperEffect } from './styled';
import { effectShow } from 'components/Popup/sendEffectCreator';
import { useRef } from 'react';

const EffectCreator = () => {
  const [showEffect, setShowEffect] = useState(null);

  const { liveKey } = useParams();
  const refDiv = useRef(null);
  const messageRef = query(ref(DBRealTime, `/lives/${liveKey}/effect-creator`));
  useEffect(() => {
    onValue(messageRef, (snapshot) => {
      const newData = [];
      snapshot.forEach((ss) => {
        newData.push(ss.val());
      });
      if (newData.length > 0) {
        return setShowEffect(newData);
      }
    });
  }, []);

  useEffect(() => {
    const cb = setTimeout(() => {
      setShowEffect(null);
    }, 1500);
    return () => {
      clearTimeout(cb);
    };
  }, [showEffect]);
  const winWidth = refDiv?.current?.clientWidth;
  const winHeight = refDiv?.current?.clientHeight;
  const getRandom = (min, max) => Math.random() * (max - 200 - min) + min;

  return (
    <WrapperEffect ref={refDiv}>
      {showEffect && (
        <ImageEffect
          alt=''
          src={effectShow[showEffect]}
          top={`${getRandom(0, winHeight)}px`}
          left={`${getRandom(0, winWidth)}px`}
        />
      )}
    </WrapperEffect>
  );
};
const useEffectUpdateTime = () => {
  const [showEffectUpdate, setShowEffectUpdate] = useState(null);

  const { liveKey } = useParams();
  const messageRef = query(ref(DBRealTime, `/lives/${liveKey}/update_time_live`));
  useEffect(() => {
    onValue(messageRef, (snapshot) => {
      const newData = [];
      snapshot.forEach((ss) => {
        newData.push(ss.val());
      });
      if (newData.length > 0) {
        return setShowEffectUpdate(newData);
      }
    });
  }, []);

  useEffect(() => {
    const cb = setTimeout(() => {
      setShowEffectUpdate(null);
    }, 1500);
    return () => {
      clearTimeout(cb);
    };
  }, [showEffectUpdate]);
  return {
    showEffectUpdate
  }
}
export { EffectCreator, useEffectUpdateTime };
