import { HeaderListIdolV2 } from 'components';
import useRedirect from 'hooks/useRedirect';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getUserInfo } from 'store/actions/users';
import { isAuth } from 'utils';
import './Layout.scss';
const LayoutRightWithSidebar = ({ view, children }) => {
  const error = useSelector((state) => state.handleError.error);
  useRedirect(error);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    isAuth && dispatch(getUserInfo());
  }, [location]);

  return (
    <>
      <div className='layout-live-stream'>
        <div className='wrapper__header-affiliator d-none d-lg-block'>
          <HeaderListIdolV2 />
        </div>
        <div className={`layout-full-screen-live`}>
          <div className={`layout-with-menu-sidebar livestream ${view}`}>{children}</div>
        </div>
      </div>
    </>
  );
};

export default LayoutRightWithSidebar;
