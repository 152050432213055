import UploadImageConfirmationModal from 'components/UploadImageConfirmationModal';
import useUploadImage from 'hooks/useUploadImage';
import { CameraIcon } from 'images';
import CrossIcon from 'images/CrossIcon';
import IdentifyCardCameraIcon from 'images/identify-card-camera-icon';
import React, { useEffect, useRef, useState } from 'react';
import { acceptedImageTypes, decryptPath, linkS3 } from 'utils/utils';
import '../CreatorQuickViewImage/index.scss';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { ImgCustom } from '..';
import { StyledDrag } from './styled';
import PDFIcon from 'images/profile-contract/PDF.png';
import DOCIcon from 'images/profile-contract/Doc.png';

const DragNDrop = (
  {
    label,
    error,
    name,
    setValue,
    setError,
    clearErrors,
    register,
    defaultValue,
    isSelected,
    maxSize,
    is_required,
    widthBlue,
    heightBlue,
    isCMND,
    accept,
    isPdf,
    isUpload,
    isDoc,
    isContract,
    ...props
  },
  refs
) => {
  const { selectedFile, setSelectedFile, preview, destroyState, setPreview } =
    useUploadImage();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  // const [isInvalidate, setIsInvalidate] = useState(1);
  const labelForInputFileRef = useRef({});
  useEffect(() => {
    if (defaultValue) {
      setPreview(`${defaultValue}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const onChangeFile = (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    if (
      isDoc &&
      files[0]['type'] !==
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' &&
      files[0]['type'] !== 'application/msword' && files[0]['type'] !== 'application/vnd.ms-word.document.macroEnabled.12'
      && files[0]['type'] !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.template'
    ) {
      setError(name, {
        type: 'format',
        message: 'ValidateMsg.C090_VALIDATE_DOC',
      });
      destroyState();
    } else if (!accept.includes(files[0]['type']) && !isDoc) {
      setError(name, {
        type: 'format',
        message: isPdf ? 'ValidateMsg.C090_VALIDATE_PDF' : 'ValidateMsg.C090_VALIDATE_IMAGE',
      });
      destroyState();
    } else if (files[0].size > maxSize) {
      if (isPdf) {
        setError(name, {
          type: 'format',
          message: 'ValidateMsg.PDF_MAX_SIZE',
        });
      } else if (isDoc) {
        setError(name, {
          type: 'format',
          message: 'ValidateMsg.DOC_MAX_SIZE',
        });
      } else {
        setError(name, {
          type: 'format',
          message: isUpload ? 'ValidateMsg.IMG_MAX_SIZE' : 'ValidateMsg.imgProfileMaxSize',
        });
      }
      destroyState();
    } else {
      clearErrors(name);
      setSelectedFile(files[0]);
      setValue(name, files[0]);
      e.target.value = '';
    }

    setIsOpen(false);
  };

  const openChangeFileModal = () => {
    setIsOpen(true);
  };

  const onResolve = () => {
    labelForInputFileRef?.current?.click();
  };

  const onReject = (type) => {
    if (is_required) {
      if (type === 'dismiss') {
        setIsOpen(false);
      } else {
        setValue(name, undefined);
        destroyState();
        setIsOpen(false);
        setError(name, {
          type: 'required',
          message: 'ValidateMsg.required',
        });
      }
    } else {
      if (type === 'dismiss') {
        setIsOpen(false);
      } else {
        destroyState();
        setIsOpen(false);
        setValue(name, undefined);
      }
    }
  };

  const isPdfViewer = isPdf && preview && selectedFile?.type === 'application/pdf';
  const isDocViewer = isDoc && preview;

  return (
    <React.Fragment>
      <UploadImageConfirmationModal
        isOpen={isOpen}
        onResolve={onResolve}
        onReject={onReject}
        isSelected={isSelected}
        defaultValue={defaultValue}
        preview={preview}
        isPdf={isPdf}
        isDoc={isDoc}
      />

      <div
        className={
          isPdfViewer || isDocViewer
            ? 'identify-card pdf-viewer-container'
            : 'identify-card position-relative d-flex align-items-center mt-2'
        }>
        {!preview && (
          <React.Fragment>
            <div className='upload-label flex-column'>
              {isDoc ? (
                <img src={DOCIcon} alt='upload doc' />
              ) : isPdf ? (
                <img src={PDFIcon} alt='upload pdf' />
              ) : (
                <IdentifyCardCameraIcon />
              )}
              {isUpload && <p className='identify-card-des'>{t('Contract.uploadFile')}</p>}
              <p className='identify-card-des'>{label}</p>
            </div>
          </React.Fragment>
        )}
        <label htmlFor={name} className='d-none' ref={labelForInputFileRef}></label>
        <input
          type='file'
          {...props}
          id={name}
          multiple={false}
          name={name}
          onChange={onChangeFile}
          onClick={() => setIsOpen(false)}
          className='d-none'
          accept={accept}
        />
        <div
          className={
            (isPdf && preview) || (isDoc && preview)
              ? 'd-none'
              : 'camera position-absolute d-flex align-items-center justify-content-center rounded-6 bg-white py-2 right bottom cancel'
          }
          onClick={openChangeFileModal}
          role='button'>
          {/* <CameraIcon className='text-danger icon' /> */}
          <p className='upload-des mb-0 ms-2'>
            {!preview
              ? isContract
                ? `${t('DragNDrop.docUpload')}`
                : isPdf
                ? `${t('DragNDrop.pdfUpload')}`
                : `${t('DragNDrop.imgUpload')}`
              : `${t('DragNDrop.imgChange')}`}
          </p>
        </div>
        {!isDoc && preview && selectedFile?.type !== 'application/pdf' && (
          <React.Fragment>
            <StyledDrag.BlueImg
              url={`${decryptPath(preview)}`}
              height={heightBlue}
              width={widthBlue}>
              <div className='bg-img'></div>
              <CrossIcon
                className='thumbnail-cross-icon'
                onClick={() => {
                  destroyState();
                  setValue(name, undefined);
                  isCMND &&
                    setError(name, {
                      type: 'required',
                      message: 'ValidateMsg.required',
                    });
                }}
                style={{
                  position: 'absolute',
                  zIndex: '50',
                  background: 'transparent',
                }}
              />
              <ImgCustom
                style={{
                  objectFit: 'contain',
                  position: 'absolute',
                  zIndex: '14',
                  background: 'transparent',
                }}
                src={preview}
                className='card-img'
                alt={name || 'identify-card'}
                onError={(e) => {
                  destroyState();
                  e.target.value = '';
                }}
              />
            </StyledDrag.BlueImg>
          </React.Fragment>
        )}
        {isPdf && preview && selectedFile?.type === 'application/pdf' && (
          <div className='pdf-viewer'>
            <img src={PDFIcon} alt='upload pdf' />
            <label className='link'>{selectedFile.name}</label>
            <CrossIcon
              className='thumbnail-cross-icon'
              onClick={() => {
                destroyState();
                setValue(name, undefined);
                isCMND &&
                  setError(name, {
                    type: 'required',
                    message: 'ValidateMsg.required',
                  });
              }}
              style={{
                position: 'absolute',
                zIndex: '50',
                background: 'transparent',
              }}
            />
          </div>
        )}
        {isDoc && preview && (
          <div className='pdf-viewer'>
            <img src={DOCIcon} alt='upload pdf' />
            <label className='link-doc'>{selectedFile.name}</label>
            <CrossIcon
              className='thumbnail-cross-icon'
              onClick={() => {
                destroyState();
                setValue(name, undefined);
                isCMND &&
                  setError(name, {
                    type: 'required',
                    message: 'ValidateMsg.required',
                  });
              }}
              style={{
                position: 'absolute',
                zIndex: '50',
                background: 'transparent',
              }}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default React.forwardRef(DragNDrop);
