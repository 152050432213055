import { Checkbox } from 'components';
import { Edit2Icon, TrashIcon } from 'images';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const CardItem = ({
  item,
  setIsShowPopupDeleteCard,
  handleChangeDefaultCard,
  handleCheckCanUpdate,
}) => {
  const { t } = useTranslation();

  const [isEditHover, setIsEditHover] = useState(false);
  const [isDeleteHover, setIsDeleteHover] = useState(false);

  const onHoverOverIcon = (isEditIcon = true) => {
    if (isEditIcon) {
      setIsEditHover(true);
    } else {
      setIsDeleteHover(true);
    }
  };
  const onMouseLeaveIcon = (isEditIcon = true) => {
    if (isEditIcon) {
      setIsEditHover(false);
    } else {
      setIsDeleteHover(false);
    }
  };

  const convertNameTypeCard = (item) => {
    let type = '';
    const cardType = item?.card_type;
    if (item?.card_type === 'VISA') {
      type = 'Visa';
    } else if (cardType === 'MASTER_CARD') {
      type = 'MasterCard';
    } else if (cardType === 'JCB') {
      type = 'JCB';
    }
    return `${type}*${item?.card_number?.slice(-4)}`;
  };

  return (
    <div id={`card-${item?.id}`} className='card-item'>
      <div className='card-item-left'>
        <div className='card-item-left_checkbox'>
          <Checkbox
            id={item.id}
            type='checkbox'
            disabled={+item?.default === 1}
            onChange={(e) => handleChangeDefaultCard(item.id)}
            text=''
            checked={+item?.default === 1}
          />
        </div>
        <div className='card-item-left_info'>
          <div className='info-name'>
            {+item?.default === 1 && (
              <span className='card-default'>{t('Creator.cardManagement.cardDefault')}</span>
            )}
            <span className={`card-name ${item?.default === 1 ? "is-have-default" : ""}`}>{item?.card_holder_name}</span>
          </div>
          <div className='info-card-number text-card'>{convertNameTypeCard(item)}</div>
          <div className='info-card-date text-card'>
            {t('Creator.cardManagement.dateExpiry')}: {item?.card_expired_month}/
            {item?.card_expired_year}
          </div>
        </div>
      </div>
      <div className='card-item-right'>
        <span>
          <Edit2Icon
            onClick={() => handleCheckCanUpdate(item?.id, convertNameTypeCard(item))}
            onMouseOver={onHoverOverIcon}
            onMouseLeave={onMouseLeaveIcon}
            color={isEditHover ? '#1A1A1A' : '#666565'}
            size={18}
            className='edit-card-icon card-icon'
          />
        </span>
        <span>
          <TrashIcon
            onClick={() => {
              setIsShowPopupDeleteCard({
                isShow: true,
                id: item?.id,
                typeCard: convertNameTypeCard(item),
              });
            }}
            onMouseOver={() => onHoverOverIcon(false)}
            onMouseLeave={() => onMouseLeaveIcon(false)}
            color={isDeleteHover ? '#1A1A1A' : '#666565'}
            size={18}
            className='delete-card-icon card-icon'
          />
        </span>
      </div>
    </div>
  );
};
