import { ImgCustom, PopupLockPostNormal } from 'components';
import { newPathUser } from 'constants/layout/constant';
import { ChatIcon, HeartActiveIcon, HeartIcon } from 'images';
import Top1Icon from 'images/rank/Top1Icon';
import Top2Icon from 'images/rank/Top2Icon';
import Top3Icon from 'images/rank/Top3Icon';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { decryptPath, funcThumbnailIMG } from 'utils';
import AvatarInfo from './avatarInfo';
import './index.scss';
import { BlurImg, IconTypePost, WrapperBlur } from './styled';

export const RankingItem = ({
  item,
  handleFollowCreator,
  setIdPostBySuccess,
  setIsOpen,
  setIdPackage,
  handleByPackageSuccess,
  setIdAndNavigate,
}) => {
  const { t, i18n } = useTranslation();
  const { dataUser } = useSelector((state) => state.users);

  const isMini = useMemo(() => {
    return (
      dataUser?.id !== item?.user_id && item?.is_followed === 0 && item?.follow_flag === 1
    );
  }, [item, dataUser]);

  const navigatePostDetail = () => {
    if (item?.type === 1) {
      return setIdAndNavigate(item?.id, `${newPathUser}post/${item?.id}`);
    } else {
      return setIdAndNavigate(item?.id, `${newPathUser}post-sale/${item?.id}`);
    }
  };

  const renderIconRank = useCallback(
    (rank) => {
      if (rank === 1) {
        return <Top1Icon className='icon-rank-ranking-page' />;
      } else if (rank === 2) {
        return <Top2Icon className='icon-rank-ranking-page' />;
      } else if (rank === 3) {
        return <Top3Icon className='icon-rank-ranking-page' />;
      }
      return (
        <>
          {i18n.language === 'en' && (
            <span>{t('Acount.FanRankingItem.rankingItem')}&nbsp;</span>
          )}
          {rank}
          {i18n.language !== 'en' && (
            <span>&nbsp;{t('Acount.FanRankingItem.rankingItem')}</span>
          )}
        </>
      );
    },
    [i18n.language]
  );

  const renderImgOrVideo = (media) => {
    if (media?.type === 1 || media?.type === 4) {
      return (
        <BlurImg onClick={navigatePostDetail} url={decryptPath(media?.url, { isMini: true })}>
          <div className='bg-img'></div>
          <ImgCustom
            isMini={isMini}
            screen='post_326_auto_pc'
            src={item?.media_preview?.url}
            className='thumbnail-img cover-img'
          />
        </BlurImg>
      );
    } else {
      return (
        <BlurImg
          onClick={navigatePostDetail}
          url={`${funcThumbnailIMG(
            decryptPath(media?.url, {
              isMini: true,
            })
          )}`}>
          <div className='bg-img'></div>
          {!isMini && (
            <ImgCustom
              screen='post_326_auto_pc'
              src={funcThumbnailIMG(decryptPath(item?.media_preview?.url))}
              className='thumbnail-img cover-img'
            />
          )}
        </BlurImg>
      );
    }
  };

  const renderIconPreview = (item) => {
    if (!isMini) {
      if (item?.medias_count > 1) {
        return <IconTypePost className='preview-icon-ranking' type='image' />;
      }
      if (item?.media_preview?.type === 2 || item?.media_preview?.type === 4) {
        return <IconTypePost className='preview-icon-ranking' type='video' />;
      }
    }
  };

  const renderFollowAndByPackage = (item) => {
    if (dataUser?.id !== item?.user_id && item?.type === 1) {
      return (
        <>
          {item?.is_followed === 0 && item?.follow_flag === 1 ? (
            <WrapperBlur>
              <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
              <button onClick={() => handleFollowCreator(item)}>{t('Common.follow')}</button>
            </WrapperBlur>
          ) : (
            <>
              {item?.is_media_display === 0 && item?.packages?.length > 0 && (
                <PopupLockPostNormal
                  post_id={item.id}
                  detailPost={{
                    ...item,
                    type: 1,
                  }}
                  one_column
                  is_des
                  handleByPackageSuccess={handleByPackageSuccess}
                  // handleBySuccess
                  // setIdPostBySuccess={setIdPostBySuccess}
                  point={dataUser?.points}
                  // setIsOpen={setIsOpen}
                  // setIdPackage={setIdPackage}
                />
              )}
            </>
          )}
        </>
      );
    }
  };

  const renderTitleAndInteractive = (item) => {
    return (
      <>
        <div className='info-title' onClick={() => navigatePostDetail()}>
          {item?.type === 1 ? item?.content : item?.title}
        </div>
        <div className='interactive'>
          <div className='interactive-item'>
            {item?.is_liked === 1 ? (
              <HeartActiveIcon
                className={`like-icon active`}
                onClick={() => navigatePostDetail()}
              />
            ) : (
              <HeartIcon className={`like-icon`} onClick={() => navigatePostDetail()} />
            )}
            <span className='count'>{item?.number_like}</span>
          </div>
          <div className='interactive-item'>
            <ChatIcon className='chat-icon' onClick={() => navigatePostDetail()} />
            <span className='count'>{item?.number_comment}</span>
          </div>
        </div>
      </>
    );
  };

  const renderRankingPc = () => {
    return (
      <div className='page-ranking-item_info_pc'>
        <div className='page-ranking-item_info_pc-left'>
          <>
            {renderFollowAndByPackage(item)}
            {renderImgOrVideo(item?.media_preview)}
            {renderIconPreview(item)}
          </>
        </div>
        <div className='page-ranking-item_info_pc-right'>
          {renderTitleAndInteractive(item)}
          <AvatarInfo
            setIdAndNavigate={setIdAndNavigate}
            handleFollowCreator={handleFollowCreator}
            userId={dataUser?.id}
            item={item}
          />
        </div>
      </div>
    );
  };

  const renderRankingMobile = () => {
    return (
      <div className='page-ranking-item_info_mobile'>
        <div className='page-ranking-item_info_mobile-top'>
          <div className='page-ranking-item_info_mobile-top-left'>
            <>
              {renderFollowAndByPackage(item)}
              {renderImgOrVideo(item?.media_preview)}
              {renderIconPreview(item)}
            </>
          </div>
          <div className='page-ranking-item_info_mobile-top-right'>
            {renderTitleAndInteractive(item)}
          </div>
        </div>
        <div className='page-ranking-item_info_mobile-bot'>
          <AvatarInfo
            userId={dataUser?.id}
            setIdAndNavigate={setIdAndNavigate}
            handleFollowCreator={handleFollowCreator}
            item={item}
          />
        </div>
      </div>
    );
  };

  return (
    <div id={`ranking-${item?.id}`} className='page-ranking-item'>
      <div className='page-ranking-item_rank'>{renderIconRank(item?.rank)}</div>
      {renderRankingPc()}
      {renderRankingMobile()}
    </div>
  );
};

export default RankingItem;
