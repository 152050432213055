import i18n from 'translate/i18n';

export const messageValidate = {
  //F014 MESSAGE IF USER NOT LIST VIEW LIVE
  F014_USER_NOT_IN_LIST_VIEW_LIVE:
    i18n.language !== 'jp'
      ? "Switched to individual distribution. I can't watch live."
      : '個別配信に切り替わりました。ライブの視聴ができません。',
  F014_MESSAGE_AFTER_MESSAGE_PACKAGE:
    i18n.language !== 'jp'
      ? 'Switched to subscription limited delivery.'
      : 'サブスク限定配信に切り替わりました。',
  F014_MESSAGE_IF_USER_NOT_FOLLOW:
    i18n.language !== 'jp' ? "You can't watch live." : 'ライブの視聴ができません。',
  F014_MESSAGE_IF_USER_NOT_ALLOW_ALL_MEMBER:
    i18n.language !== 'jp' ? "You can't watch live." : 'ライブの視聴ができません。',
  F014_MESSAGE_IF_USER_NOT_LOGIN:
    i18n.language !== 'jp'
      ? `Log in to operate this function. required.`
      : `この機能は操作できるためにログイ
    ンが必要です。`,
  F014_MESSAGE_GUESS_WHEN_ACTION_IN_LIVE:
    i18n.language !== 'jp'
      ? 'You must be logged in to operate this feature.'
      : 'この機能は操作できるためにログインが必要です。',
};
