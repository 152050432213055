import { newPathUser } from 'constants/layout/constant';
import { FlagIcon, FlowUnFlowIcon, UnFlowIcon } from 'images';
import { memo, useEffect } from 'react';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { logUserProfile } from 'store/actions/myPage';
import { STORAGE } from 'utils';
import Storage from 'utils/storage';

const InfoBox = ({
  item,
  index,
  toggleFollowAllUser,
  handleFollowCreator,
  setIsShowOtherBox,
  toggleValueVideo,
  navigate,
  t,
}) => {
  const dispatch = useDispatch();
  const refOutSide = useRef();
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const handleClickOutside = (event) => {
    if (refOutSide.current && !refOutSide.current.contains(event.target)) {
      setIsShowOtherBox && setIsShowOtherBox(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [refOutSide]);

  return (
    <div className='popup-edit-comment' ref={refOutSide}>
      {/* <div
        className='item'
        onMouseDown={() => {
          if (!isAuth) {
            setIsShowOtherBox(false);
            dispatch(logUserProfile(item));
          } else {
            handleFollowCreator(item?.user_id);
            setIsShowOtherBox(false);
            if (item?.follow_flag === 1) {
              toggleFollowAllUser(index, item?.user_id, false, 0);
            } else {
              toggleFollowAllUser(index, item?.user_id, true, item?.is_followed === 1 ? 0 : 1);
            }
          }
        }}>
        {item?.is_followed !== 1 ? <FlowUnFlowIcon /> : <UnFlowIcon />}
        <span className='text'>
          {item?.is_followed === 0 || !item?.is_followed
            ? t('comment.listComment.flow')
            : t('comment.listComment.unflow')}
        </span>
      </div> */}
      <div
        className='item'
        onMouseDown={() => {
          if (!isAuth) {
            setIsShowOtherBox(false);
            dispatch(logUserProfile(item));
          } else {
            setIsShowOtherBox(false);
            navigate(`${newPathUser}post-report`, {
              state: {
                post_id: item?.id,
              },
            });
          }
        }}>
        <FlagIcon />
        <span className='text'>{t('comment.listComment.iconFlag')}</span>
      </div>
    </div>
  );
};

export default memo(InfoBox);
