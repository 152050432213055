import styled from 'styled-components';

export const Styled = {
  WrapperFixed: styled.div`
    margin-top: 24px;

    .popup-post {
      .popup-content {
        width: 379px;
      }
    }

    .fansite-form-input__error {
      font-weight: 400;
    }
  `,

  Wrapper: styled.div`
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.08);
    padding: 20px;
    margin-bottom: ${({ mgbt }) => mgbt};
    height: ${({ height }) => height};
    overflow: hidden;
    padding-right: ${(paddingRight) => paddingRight};
    position: relative;
  `,

  Title: styled.div`
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 15px;
    font-size: 20px;

    @media screen and (max-width: 1279px) {
      font-size: 16px;
    }
  `,

  Field: styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    color: #666565;
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;

    &.no-data {
      position: absolute;
      top: 50%;
      left: 30%;
    }
  `,

  Content: styled.p`
    color: ${({ color }) => (color ? color : '#1a1a1a')};
    font-weight: ${({ fw }) => (fw ? fw : 700)};
    margin-left: 5px;
    margin-bottom: 0;
    cursor: ${({ cursor }) => (cursor ? cursor : 'default')};
    position: relative;

    .enable {
      top: 0;
      height: 45px;
      width: 45px;
      position: absolute;
      background-color: #1a1a1a91;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .pdf-empty {
      color: #979cb5;
      cursor: default;
      margin-bottom: 0;
    }
  `,

  Button: styled.button`
    height: 44px;
    padding: 8px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--neutral-line, #e5e6eb);
    background: var(--neutral-white, #fff);
    width: ${({ width }) => (width ? width : '144px')};
    margin-bottom: ${({ mgbt }) => (mgbt ? mgbt : '15px')};

    &:hover {
      background-color: #f1f1f1;
    }

    &:disabled {
      background-color: #eeeeee;
    }
  `,

  ContentKYC: styled.div`
    border-bottom: 1px solid #e5e6eb;
    margin-bottom: 10px;
    position: relative;
  `,
};

export const BlueImg = styled.div`
  background: url(${({ url }) => url});
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  background-size: cover;
  width: 100%;
  overflow: hidden;
  &.bd-10 {
    border-radius: 10px;
  }
  &.csp {
    cursor: pointer;
  }
  .bg-img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9;
    background-size: cover;
    min-height: 100%;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    &.bd-8 {
      border-radius: 8px;
    }
  }
  .cover-img {
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 10;
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }
`;
