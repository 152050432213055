/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { onValue, ref, query, limitToLast, orderByChild } from 'firebase/database';
import { DBRealTime } from '../../firebase/config';
import avatarDemo from 'images/Avatar.png';
import { useParams } from 'react-router-dom';
import { formatCoin, linkS3 } from 'utils/utils';
import { AVATAR_DEFAULT, LIST_POINT_ICON_DONATE } from 'utils/constant';
import { useTranslation } from 'react-i18next';
import { ImgCustom } from '..';
import Top1Icon from 'images/rank/Top1Icon';
import { Fragment } from 'react';
import { useMemo } from 'react';
import MarkDownRead from 'components/MarkDownRead';

export const ListChat = (props) => {
  const { liveKey } = useParams();
  const chatBox = useRef();
  const { id, denyCharacter } = props;

  const [data, setData] = useState([]);
  const { t } = useTranslation();

  const regexMessage = useMemo(() => {
    if (denyCharacter) {
      return RegExp(`${denyCharacter?.filter((e) => e).join('|')}`, 'gi') || '';
    }
  }, [denyCharacter]);

  const removeSpecifiedChars = (text) => {
    return text.replace(regexMessage, function (match, contents, offset, input_string) {
      let str = '*';
      if (match?.length && match?.length <= 2) {
        str = new Array(match?.length + 1).join('*');
      } else {
        const first = match[0];
        const last = match[match.length - 1];
        const hide = new Array(match.length - 1).join('*');
        str = `${first}${hide}${last}`;
      }
      return str;
    });
  };
  const convertMessage = (word) => {
    return removeSpecifiedChars(word, denyCharacter);
  };

  const messageRef = query(
    ref(DBRealTime, `/chat/${liveKey}`),
    orderByChild('createdAt'),
    limitToLast(50)
  );
  useEffect(() => {
    onValue(messageRef, (snapshot) => {
      const newData = [];
      snapshot.forEach((ss) => {
        const dataMes = {
          ...ss.val(),
          message:
            ss.val()?.type === '0' ? convertMessage(ss.val()?.message) : ss.val()?.message,
        };
        newData.push(dataMes);
      });
      if (newData.length > 0) {
        return setData(newData);
      }
    });
  }, [regexMessage]);

  useEffect(() => {
    const domNode = chatBox.current;
    if (domNode) {
      domNode.scrollTop = domNode.scrollHeight;
    }
  });

  return (
    <>
      {/* <div className='bg-box-shadow'></div> */}
      <div className='box' ref={chatBox}>
        {data.map((item, index) => {
          const srcImg = LIST_POINT_ICON_DONATE.find((x) => {
            return x.name === item?.icon;
          });
          return (
            <Fragment key={index}>
              {item.type === '0' ? (
                <>
                  {item?.id === id ? (
                    <div
                      className={`comment ${
                        item?.creator === 1 ? 'lv2' : item?.id === id ? 'lv3' : 'lv1'
                      }`}>
                      <span className='name'>{item.name} </span>
                      <span className='text-live'>
                        <MarkDownRead className='d-ib' content={item?.message} />
                      </span>
                    </div>
                  ) : (
                    <>
                      {item.is_block === 0 && (
                        <div
                          className={`comment ${
                            item?.creator === 1 ? 'lv2' : item?.id === id ? 'lv3' : 'lv1'
                          }`}>
                          <span className='name'>{item.name} </span>
                          <span className='text-live'>
                            <MarkDownRead className='d-ib' content={item?.message} />
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </>
              ) : (
                <div className={`sendCoin ${item.type === '2' && 'sendCoin_top1'}`}>
                  <ImgCustom
                    src={item?.avatar ? `${item?.avatar}` : AVATAR_DEFAULT}
                    alt=''
                    isPlaceholderAvatar
                    className='avatar'
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = avatarDemo;
                    }}
                  />
                  <div className='box-coin'>
                    <div className='name'>{item.name}</div>
                    {item.type !== '2' ? (
                      <div className='text-live'>{`${formatCoin(item.message)} ${t(
                        'LiveStream.Videos.send'
                      )}`}</div>
                    ) : (
                      <div className='text-live'>{`${t(
                        'LiveStream.Videos.top1Ranking'
                      )}`}</div>
                    )}
                  </div>
                  {item.type !== '2' ? (
                    <img src={srcImg?.icon} alt='' className='coin' />
                  ) : (
                    <Top1Icon />
                  )}
                </div>
              )}
            </Fragment>
          );
        })}
      </div>
    </>
  );
};
