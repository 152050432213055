import Icon from '@ant-design/icons';

const NoBookmarkList = (props) => (
  <Icon
    component={() => (
      <svg
        width='100'
        height='100'
        viewBox='0 0 100 100'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M74.9954 87.5L49.9925 71.875L24.9954 87.5V18.75C24.9954 17.9212 25.3247 17.1263 25.9107 16.5403C26.4968 15.9542 27.2916 15.625 28.1204 15.625H71.8704C72.6992 15.625 73.4941 15.9542 74.0801 16.5403C74.6662 17.1263 74.9954 17.9212 74.9954 18.75V87.5Z'
          stroke='#FCECEF'
          strokeWidth='5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default NoBookmarkList;
