import { TYPES } from './index';
import { callApi } from '../../utils/request';
import { getEnv } from 'configs/env';
import { configParamsGet, updateStatus } from '../../utils/constant';

const searchPost = (page, limit, params) => {
  let url = `${getEnv('REACT_APP_API_SERVER')}/posts?page=${page}&limit=${limit}&`;

  Object.keys(params).map((key) => {
    url += key + '=' + params[key] + '&';
  });

  return (dispatch) => {
    dispatch(updateStatus(TYPES.SEARCH_POST_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.SEARCH_POST_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.SEARCH_POST_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getCategory = () => {
  let url = `${getEnv('REACT_APP_API_SERVER')}/fan/v1/categories`;

  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_CATEGORY_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_CATEGORY_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_CATEGORY_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const searchListCreator = (page, limit, params) => {
  let url = `${getEnv('REACT_APP_API_SERVER')}/creators?page=${page}&limit=${limit}`;
  Object.keys(params).map((key) => {
    if (key === 'tag') {
      return params[key].map((item) => (url += '&tag[]' + '=' + item));
    } else {
      return (url += '&' + key + '=' + params[key]);
    }
  });

  return (dispatch) => {
    dispatch(updateStatus(TYPES.SEARCH_LIST_CREATOR_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.SEARCH_LIST_CREATOR_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.SEARCH_LIST_CREATOR_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

export { getCategory, searchPost, searchListCreator };
