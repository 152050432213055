import { TYPES } from 'store/actions';

/* eslint-disable import/no-anonymous-default-export */
const initialState = {
  status: null,
  loading: false,
  loadingAction: false,
  error: null,
  postDetail: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_DETAIL_POST_META_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_DETAIL_POST_META_SUCCESS:
      return {
        ...state,
        loading: false,
        status: action.type,
        postDetail: action.data,
      };
    case TYPES.GET_DETAIL_POST_META_FAILED:
      return {
        ...state,
        loading: false,
        status: action.type,
        error: action.data.error,
      };
    case 'CLEAR_POST_META':
      return {
        ...state,
        loading: false,
        postDetail: {},
      };
    default:
      return state;
  }
};
