/* eslint-disable react-hooks/rules-of-hooks */
import { range } from 'ramda';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import i18n from 'translate/i18n';
dayjs.extend(weekday);
dayjs.extend(weekOfYear);

export const daysOfWeek = [
  i18n.t('Common.sun'),
  i18n.t('Common.mon'),
  i18n.t('Common.tue'),
  i18n.t('Common.wed'),
  i18n.t('Common.thu'),
  i18n.t('Common.fri'),
  i18n.t('Common.sat'),
];

const monthOfYear = [
  `${i18n.t('Common.jan')}`,
  `${i18n.t('Common.feb')}`,
  `${i18n.t('Common.mar')}`,
  `${i18n.t('Common.apr')}`,
  `${i18n.t('Common.may')}`,
  `${i18n.t('Common.jun')}`,
  `${i18n.t('Common.jul')}`,
  `${i18n.t('Common.aug')}`,
  `${i18n.t('Common.sep')}`,
  `${i18n.t('Common.oct')}`,
  `${i18n.t('Common.nov')}`,
  `${i18n.t('Common.dec')}`,
];

export function getYearDropdownOptions(currentYear) {
  let minYear = currentYear - 4;
  let maxYear = currentYear + 5;
  return range(minYear, maxYear + 1).map((y) => ({ label: `${y}`, value: y }));
}

export function getMonthDropdownOptions() {
  return range(1, 13).map((m) => ({
    value: m,
    label: monthOfYear[m - 1],
  }));
}

export function getNumberOfDaysInMonth(year, month) {
  return dayjs(`${year}-${month}-01`).daysInMonth();
}

export function createDaysForCurrentMonth(year, month) {
  return [...Array(getNumberOfDaysInMonth(year, month))].map((_, index) => {
    return {
      dateString: dayjs(`${year}-${month}-${index + 1}`).format('YYYY-MM-DD'),
      dayOfMonth: index + 1,
      isCurrentMonth: true,
    };
  });
}

export function createDaysForPreviousMonth(year, month, currentMonthDays) {
  const firstDayOfTheMonthWeekday = getWeekday(currentMonthDays[0].dateString);
  const previousMonth = dayjs(`${year}-${month}-01`).subtract(1, 'month');

  const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday;

  const previousMonthLastMondayDayOfMonth = dayjs(currentMonthDays[0].dateString)
    .subtract(visibleNumberOfDaysFromPreviousMonth, 'day')
    .date();

  return [...Array(visibleNumberOfDaysFromPreviousMonth)].map((_, index) => {
    return {
      dateString: dayjs(
        `${previousMonth.year()}-${previousMonth.month() + 1}-${
          previousMonthLastMondayDayOfMonth + index
        }`
      ).format('YYYY-MM-DD'),
      dayOfMonth: previousMonthLastMondayDayOfMonth + index,
      isCurrentMonth: false,
      isPreviousMonth: true,
    };
  });
}

export function createDaysForNextMonth(year, month, currentMonthDays) {
  const lastDayOfTheMonthWeekday = getWeekday(`${year}-${month}-${currentMonthDays.length}`);
  const nextMonth = dayjs(`${year}-${month}-01`).add(1, 'month');
  const visibleNumberOfDaysFromNextMonth = 6 - lastDayOfTheMonthWeekday;

  return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {
    return {
      dateString: dayjs(`${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`).format(
        'YYYY-MM-DD'
      ),
      dayOfMonth: index + 1,
      isCurrentMonth: false,
      isNextMonth: true,
    };
  });
}

// sunday === 0, saturday === 6
export function getWeekday(dateString) {
  return dayjs(dateString).weekday();
}

export function isWeekendDay(dateString) {
  return [6, 0].includes(getWeekday(dateString));
}
