import { useLayoutEffect, useRef, useState } from 'react';
import 'video-react/dist/video-react.css';
import { PlayIcon } from '../../images';
import './index.scss';
import { logUserProfile } from 'store/actions/myPage';
import { STORAGE } from 'utils';
import Storage from 'utils/storage';
import { useDispatch } from 'react-redux';
function VideoCustom({
  src,
  background_video,
  customVideo = false,
  handleNavigate,
  controls = false,
  isDetailPost = false,
  noPlayVideo = false,
  ...props
}) {
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const ref = useRef();
  const dispatch = useDispatch();
  const buttonRef = useRef();
  const [showButton, setShowButton] = useState(true);
  useLayoutEffect(() => {
    if (ref.current && buttonRef.current) {
      ref.current.addEventListener('click', function () {
        if (!this.paused) this.pause();
        else {
          isDetailPost ? this.play() : handleNavigate();
        }
        isDetailPost && setShowButton(true);
      });
    }
  }, [ref, buttonRef]);

  return (
    <div className={`video-custom ${props.className}`}>
      <video
        src={src}
        ref={ref}
        controls={isDetailPost && !showButton}
        onPlay={() => {
          ref.current.play();
          setShowButton(false);
        }}
        onPause={() => {
          ref.current.pause();
          setShowButton(true);
        }}
      />
      {showButton && (
        <button
          className='button-play-custom'
          ref={buttonRef}
          onClick={() => {
            noPlayVideo && handleNavigate()
            !noPlayVideo && ref.current.play();
            !noPlayVideo && setShowButton(false);
          }}>
          <PlayIcon />
        </button>
      )}
    </div>
  );
}

export default VideoCustom;
