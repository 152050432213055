import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { DATA_NAVBAR, DATA_NAVBAR_AFFILIATOR } from './contant';
import './index.scss';
const NavbarUserProfile = () => {
  const { data: data_profile } = useSelector((state) => state.users);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <ul className='header'>
      {data_profile?.is_affiliate === 1
        ? DATA_NAVBAR_AFFILIATOR.map(({ title, link }) => (
            <li
              className={`${pathname === link && 'active'}`}
              onClick={() => navigate(link)}>
              {title}
            </li>
          ))
        : DATA_NAVBAR.map(({ title, link }) => (
            <li
              className={`${pathname === link && 'active'}`}
              onClick={() => navigate(link)}>
              {title}
            </li>
          ))}
    </ul>
  );
};

export default NavbarUserProfile;
