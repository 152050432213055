import Icon from '@ant-design/icons';

const ICCloseIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g id='Iconly Normal'>
          <g id='Plus'>
            <path
              id='Line_185'
              d='M16.2428 7.75844L7.75753 16.2437'
              stroke='#787880'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              id='Line_186'
              d='M16.2425 16.2437L7.75719 7.75844'
              stroke='#787880'
              strokeWidth='1.5'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </g>
        </g>
      </svg>
    )}
    {...props}
  />
);

export default ICCloseIcon;
