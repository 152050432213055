import MAINTAIN from 'images/maintain.png';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { HeaderListIdolV2, ImgCustom } from 'components';
import { convertToCurrentTime } from 'utils/utils';
// import './index.scss';
const Maintain = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { date_from, date_to } = location?.state || {};
  const [dateSetting, setDateSetting] = useState({ dateFrom: null, dateTo: null });
  useEffect(() => {
    const dateFrom = convertToCurrentTime(date_from, 'YYYY/MM/DD HH:mm');
    const dateTo = convertToCurrentTime(date_to, 'YYYY/MM/DD HH:mm');
    setDateSetting({ dateFrom, dateTo });
  }, [location]);

  return (
    <div className='block-div-signin'>
    <HeaderListIdolV2 />
    <div className='fansite-verify-age'>
      <div className='box-verify-age'>
        <div className='bg-white'>
          <div className='title maintenance'>{t('Maintain.title')}</div>
          <div className='header-popup'>
            <ImgCustom src={MAINTAIN} alt='logo' className='logo-cocofans logo-setting' />
          </div>
          <div className='note'>
              {t('Maintain.time')}
          </div>
          <div className='note'>
            {dateSetting.dateFrom} ~ {dateSetting.dateTo}
          </div>
          <div className='text display-linebreak content-maintenance'>
            {t('Maintain.text1')}
          </div>
          <div className='text display-linebreak content-maintenance'>
            {t('Maintain.text2')}
          </div>
        </div>
      </div>
    </div>
  </div>
  )
};

export default Maintain;
