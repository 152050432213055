import Icon from '@ant-design/icons';

const ThreePointIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={13}
        height={4}
        viewBox='0 0 13 4'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx='1.5' cy={2} r='1.5' fill='#666565' />
        <circle cx='6.5' cy={2} r='1.5' fill='#666565' />
        <circle cx='11.5' cy={2} r='1.5' fill='#666565' />
      </svg>
    )}
    {...props}
  />
);

export default ThreePointIcon;
