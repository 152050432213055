import Icon from '@ant-design/icons';

const EffectIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={24}
        height={24}
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z'
          stroke='#FFBF00'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.625 11.0625C9.14277 11.0625 9.5625 10.6428 9.5625 10.125C9.5625 9.60723 9.14277 9.1875 8.625 9.1875C8.10723 9.1875 7.6875 9.60723 7.6875 10.125C7.6875 10.6428 8.10723 11.0625 8.625 11.0625Z'
          fill='#FFBF00'
        />
        <path
          d='M15.375 11.0625C15.8928 11.0625 16.3125 10.6428 16.3125 10.125C16.3125 9.60723 15.8928 9.1875 15.375 9.1875C14.8572 9.1875 14.4375 9.60723 14.4375 10.125C14.4375 10.6428 14.8572 11.0625 15.375 11.0625Z'
          fill='#FFBF00'
        />
        <path
          d='M15.898 14.2495C15.5029 14.9337 14.9347 15.5018 14.2504 15.8968C13.5662 16.2918 12.79 16.4998 11.9999 16.4998C11.2099 16.4998 10.4337 16.2918 9.74944 15.8968C9.06519 15.5019 8.49694 14.9337 8.10181 14.2496'
          stroke='#FFBF00'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default EffectIcon;
