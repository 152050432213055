import { PopupGeneral } from 'components';
import { InputWrapper } from 'components/FormLogin';
import { newPathUser } from 'constants/layout/constant';
import { EyeIcon, EyeV2Icon, HiddenIcon, HiddenV2Icon } from 'images';
import { InformationOBS } from 'pages/creator/layout/livestream/components/styled';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkPasswordLive } from 'store/actions/lives';

export const useCheckPasswordLivestream = (
  handleSuccess = () => {},
  id,
  fromLoad = false,
  isPassword = 0
) => {
  const listCheckPassword = window.localStorage.getItem('cpw_ls');
  const setListCheck = (value) => window.localStorage.setItem('cpw_ls', value);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [getCb, setGetCb] = useState(null);
  const handleSetPassword = (data) => {
    if (isPassword || data?.livestream?.is_password) {
      const liveId = (id || data?.livestream?.id).toString();
      if (listCheckPassword === liveId) {
        setOpenDialog(false);
      } else {
        setValue('');
        setOpenDialog(true);
      }
    } else {
      setOpenDialog(false);
    }
    setGetCb(data);
  };
  const handleCheckPassword = (success = () => {}) => {
    listCheckPassword !== id?.toString()
      ? dispatch(
          checkPasswordLive(
            id,
            { password: value },
            () => {
              success(getCb);
              setListCheck(id);
              //   dispatch(updateStatus('LIST_CHECK_PASSWORD', livekey));
            },
            () => {
              fromLoad && navigate(`${newPathUser}live/list-livestream`);
              //   const newData = listCheckPassword.filter(item => item !== livekey)
              setListCheck(null);
              //   dispatch(updateStatus('LIST_CHECK_PASSWORD', null));
              window.localStorage.removeItem('cpw_ls');
            }
          )
        )
      : success(getCb);

    setOpenDialog(false);
  };
  // const refPassword = useRef();
  // const [disabled, setDisabled] = useState(true)
  // useEffect(() => {
  //   setDisabled(true)
  // }, [])
  const [value, setValue] = useState('');
  const UISetPassword = openDialog && (
    <PopupGeneral
      closeModal={() => {
        setOpenDialog(false);
        fromLoad && navigate(`${newPathUser}live/list-livestream`);
      }}
      className='delivery-setting'
      title={t('Creator.CreateLiveStream.titlePassword')}>
      <InformationOBS height='175px'>
        {/* <div>{t('Creator.CreateLiveStream.contentPassword')}</div> */}
        <InputWrapper label={t('Common.password')}>
          <div
            style={{
              position: 'relative',
            }}>
            <input
              // ref={refPassword}
              style={{
                padding: '9px 36px 9px 12px',
              }}
              autoComplete='new-password'
              value={value}
              className='fsite-input'
              onChange={(e) => {
                // setDisabled(refPassword?.current?.value > 0 ? false : true)
                setValue(e.target.value);
              }}
              placeholder={t('Creator.CreateLiveStream.placeholderPassword')}
              type={`${hiddenPassword ? 'password' : 'text'}`}
            />

            <div
              style={{
                position: 'absolute',
                right: '12px',
                top: '8px',
              }}>
              {hiddenPassword ? (
                <EyeV2Icon onClick={() => setHiddenPassword(false)} />
              ) : (
                <HiddenV2Icon onClick={() => setHiddenPassword(true)} />
              )}
            </div>
          </div>
        </InputWrapper>
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '1px',
            background: '#f0f0f0',
            left: 0,
            bottom: '58px',
          }}
        />
        <div
          className='information_obs-footer'
          style={{
            borderTop: 'none',
          }}>
          <button
            className='information_obs-button w-92'
            onClick={() => handleCheckPassword(handleSuccess)}
            disabled={value.length === 0}>
            OK
          </button>
          <button
            className='information_obs-button cancel w-119'
            onClick={() => setOpenDialog(false)}>
            {t('Common.buttonCancel')}
          </button>
        </div>
      </InformationOBS>
    </PopupGeneral>
  );
  return {
    handleSetPassword,
    UISetPassword,
  };
};
