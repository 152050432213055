import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

export const WrapperProcessingSubmitting = styled.div`
  width: 720px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px -1px 0px 0px #f0f0f0 inset;
  padding: 16px 24px;
  font-family: 'Noto Sans JP', sans-serif;
  @media screen and (max-width: 575px) {
    margin: 0px 20px;
  }
  .txt_title_process_data {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.2%;
    color: #000000;
  }

  .wrapper-content-process {
    margin-top: 24px;
  }

  .txt_subtitle_process_data {
    margin: 0;
    padding: 16px 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #000000;
  }
`;

export const ProcessBar = styled.div`
  height: 8px;
  border-radius: 4px;
`;

export const Processbar = styled(ProcessBar)`
  position: relative;
  overflow: hidden;
  background-color: #e5e6eb;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => props.percentage}%;
    height: 100%;
    border-radius: 4px;
    background-color: #dc143c;
  }
`;

function ProcessingStatusBar({ percentage = 0 }) {
  const { t } = useTranslation();
  return (
    <WrapperProcessingSubmitting>
      <div className='wrapper-header-process'>
        <p id='process-data-upload' className='txt_title_process_data'>
          {t('Creator.PostArticle.uploading')} ({percentage}%)
        </p>
      </div>
      <div className='wrapper-content-process'>
        <Processbar id='process-data-upload' percentage={percentage} className='process-bar' />
        <p className='txt_subtitle_process_data'>{t('Creator.PostArticle.uploadDes')}</p>
      </div>
    </WrapperProcessingSubmitting>
  );
}

export default ProcessingStatusBar;
