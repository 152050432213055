import {
  AvatarInfor,
  ImgCustom,
  LayoutCreatorRight,
  ListComment,
  // ListImages,
  PopupConfirmFan,
  PopupLoading,
  PopupSharePost,
  PopupShowVideo,
  VideoDetail,
  ViewFullImage,
} from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import withCreatorRole from 'hocs/withCreatorRole';
import useRedirect from 'hooks/useRedirect';
import {
  ChatIcon,
  HeartActiveIcon,
  HeartIcon,
  IconBookmark,
  SendChatIcon,
  ShareIcon,
} from 'images';
import Avatar from 'images/Avatar.png';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  postCommentDetail,
  postBookMark,
  postLike,
  deleteCommentDetail,
  creatorGetPostDetail,
  postFollowReplyCommentDetail,
  postFollowCommentDetail,
  deleteReplyCommentDetail,
  updateLikeCommentDetail,
  updateLikeReplyComment,
  resetDetailPost,
} from 'store/actions/usersNew';
import {
  convertToCurrentTime,
  IMG_NO_WATER_MARK,
  isAdmin,
  isAgency,
  linkS3,
  decryptPath,
  linkS3VideoM3U8,
  isProdEnableLiveStream,
  formatCoin,
  linkS3Video,
} from 'utils/utils';
import GroupLikeChatShared from '../components/GroupLikeChatShared';
import VideoCustomDetail from 'pages/creator/layout/recording/VideoCustomDetail';
import '../detail.scss';
import { newPathUser } from 'constants/layout/constant';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { useRef } from 'react';
import CommentPostTimeLine from 'components/comment/commentPostTimeLine';
import { getEnv } from 'configs/env';
import { BlueImg } from '../styled';
import ListImagesNew from '../post-content-sale/elements/ListImagesNew';
import MarkDownRead from 'components/MarkDownRead';
import axios from 'axios';
import { CommentPostTimeLineShowAll } from 'components/comment/commentListShowAll';
import CommentForm from '../post-content-detail/elements/commentForm';
import HideCommentIcon from 'images/HideCommentIcon';
import { clearIdAndDataHideComment } from 'store/actions/commentHide';
import { hideCommentPost } from 'store/actions/myPage';
import { useContext } from 'react';
import { ContextTimeline, ProviderTimeline } from 'pages/timeline/contextTimeline';

const CountComment = () => {
  const { countNumberView } = useContext(ContextTimeline);
  return <span className='number'>{countNumberView.countNumberComment}</span>;
};

const Post = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { detailPost, loading, error, statusPostComment, loadingAction } = useSelector(
    (state) => state.usersNew
  );

  const { data: userProfile, dataUser } = useSelector((state) => state.users);

  // const { detailPost, loading } = useSelector((state) => state.usersNew);
  const dataProfile = useSelector((state) => state.users.dataUser);
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const [popupDeleteComment, setPopupDeleteComment] = useState(null);
  const [checkSendChat, setCheckSendChat] = useState(null);
  const [popupHideComment, setPopupHideComment] = useState(null);
  const [valueComments, setValueComments] = useState(() =>
    Array.from({ length: 1 }, () => '')
  );
  const { id } = useParams();
  const [dataViewFull, setDataViewFull] = useState({});
  const [thumbnail, setThumbnail] = useState(IMG_NO_WATER_MARK);
  const [zipFile, setZipFile] = useState(null);
  const refArea = useRef(null);
  const refVideoMain = useRef(null);
  const [loaddingDown, setLoaddingDown] = useState(false);

  // useDisabledF12();
  const { t, i18n } = useTranslation();
  useRedirect(error);

  useEffect(() => {
    dispatch(creatorGetPostDetail(id, false));

    return () => {
      dispatch(resetDetailPost());
    };
  }, []);

  useEffect(() => {
    if (detailPost?.type && Number(detailPost?.type) !== 2) {
      navigate('/*');
    }
  }, [detailPost]);

  useEffect(() => {
    detailPost &&
      detailPost?.medias?.map((item) => {
        if (item?.type === 4 && item?.public_flag === 1) {
          setThumbnail(item);
        }
        if (item?.type === 5) {
          setZipFile(item);
        }
      });
  }, [detailPost]);

  const handleSharePost = (dataPC) => {
    setPopupCopyLink(dataPC);
  };
  // SETHEIGHT TEXTAREA
  const handleTextarea = (index, e, id, height) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    const { value } = e.target;
    const newCommentValues = valueComments?.map((comment, idx) => {
      if (idx !== index) {
        return comment;
      }
      return value;
    });
    setValueComments(newCommentValues);
    setCheckSendChat(id);
    if (e.target.value?.toString().trim() === '') {
      e.target.style.height = height;
      e.target.style.padding = '9px  30px  9px 12px';
      setCheckSendChat(null);
    }
  };

  //API LIKE POST
  const handleLikePost = (id) => {
    dispatch(postLike(id));
  };

  //API LIKE COMMENT
  const handleLikeComment = (data) => {
    const { commentId, type, dataPost } = data;
    type === 'comment'
      ? dispatch(updateLikeCommentDetail(commentId, dataPost))
      : dispatch(updateLikeReplyComment(commentId, dataPost));
  };

  const handlePostComment = (id, parent_id, index) => {
    const data = {
      parent_id: parent_id,
      content: valueComments[index],
    };
    checkSendChat && dispatch(postCommentDetail(id, data));
    refArea.current.style.height = '44px';
    setCheckSendChat(null);
    setValueComments(['']);
  };
  const [trickReloadComment, setTrickReloadComment] = useState(null);
  // DELETE
  // const handleDeleteComment = () => {
  //   const { id, type, idParent } = popupDeleteComment;
  //   type === 'comment'
  //     ? dispatch(deleteCommentDetail(id))
  //     : dispatch(deleteReplyCommentDetail(id, idParent));
  //   setPopupDeleteComment(false);
  // };

  // DELETE COMMENT
  const handleDeleteComment = () => {
    const { id, type, idParent, cb = () => {}, postId } = popupDeleteComment;
    type === 'comment'
      ? dispatch(deleteCommentDetail(id, postId || null, cb))
      : dispatch(deleteReplyCommentDetail(id, postId || null, cb));
    setPopupDeleteComment(false);
  };

  // FOLLOW USER IN COMMENT

  const handleFollowUserComment = (data, onRequest = () => {}, onFinally = () => {}) => {
    const { id, type } = data;
    type === 'comment'
      ? dispatch(postFollowCommentDetail(id, onRequest, onFinally))
      : dispatch(postFollowReplyCommentDetail(id, onRequest, onFinally));
  };

  const bookmarkChange = (post) => {
    dispatch(postBookMark(post));
  };

  const handleDownloadFile = (item) => {
    if (+isProdEnableLiveStream === 1) {
      const a = document.createElement('a');
      const linkDownLoad = `${linkS3}${decryptPath(item.url)}`;
      a.href = linkDownLoad;
      a.download = linkDownLoad;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  const pauseVideoWhenOpenPopup = () => {
    refVideoMain.current?.pauseVideo();
  };

  const onClickFullView = () => {
    const list = {
      image: thumbnail,
      listImage: [thumbnail],
      initPageActive: 0,
    };
    setDataViewFull(list);
  };

  const checkDisabledAction = (item) => {
    if (
      (item?.is_followed === 0 && item?.follow_flag === 1 && item?.is_my_post === 0) ||
      (!item?.is_media_display && item?.type === 1 && item?.is_my_post === 0) ||
      item?.is_active === 0 ||
      item?.is_active === 2
    ) {
      return true;
    }
    return false;
  };
  const handleToViewHead = () => {
    //contentRef.current.scrollIntoView();
  };
  const handleDownload = (item1) => {
    setLoaddingDown(true);
    const url = `${linkS3Video}${decryptPath(item1)}`;
    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      onDownloadProgress: (progressEvent) => {
        const total = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      },
    })
      .then((response) => {
        const timestamp = Date.now();
        const dateObj = new Date(timestamp);
        const year = dateObj.getFullYear().toString();
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const day = dateObj.getDate().toString().padStart(2, '0');
        const hours = dateObj.getHours().toString().padStart(2, '0');
        const minutes = dateObj.getMinutes().toString().padStart(2, '0');
        const seconds = dateObj.getSeconds().toString().padStart(2, '0');
        const result = year + month + day + hours + minutes + seconds;

        setLoaddingDown(false);
        document.querySelector('#root').style.overflow = 'auto';
        const url = window.URL.createObjectURL(response.data);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.href = url;
        a.download = `sharegram_video_${detailPost?.id}_${result}.mp4`;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((err) => {
        window.location.href = `/not-found`;
        throw Error(err);
      })
      .finally(() => {});
  };

  const isCreator = useMemo(() => {
    return +detailPost?.user_id === +dataUser?.id;
  }, [dataUser?.id, detailPost?.user_id]);

  const checkIsShowButtonHideComment = useMemo(() => {
    return isCreator;
  }, [isCreator]);

  // HIDE COMMENT
  const handleHideComment = () => {
    const { id, cb = () => {} } = popupHideComment;
    dispatch(hideCommentPost(id, cb));
    setPopupHideComment(false);
  };

  return (
    <>
      {dataViewFull?.image?.url && (
        <ViewFullImage dataViewFull={dataViewFull} close={() => setDataViewFull({})} />
      )}
      {loaddingDown && <PopupLoading className={'popup-loading'} />}
      {popupHideComment && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupHideComment(false)}
          colorSvg='#FF9F43'
          iconNote
          text={t('Popup.confirmHideComment')}
          className='popup-delete'>
          <div className='ok btn' onClick={handleHideComment}>
            {t('Common.buttonYes')}
          </div>
          <div className='btn cancel' onClick={() => setPopupHideComment(false)}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}

      {popupCopyLink && (
        <PopupSharePost
          isVisiblePopup={() => setPopupCopyLink(false)}
          data={popupCopyLink || {}}
        />
      )}

      {popupDeleteComment && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupDeleteComment(false)}
          colorSvg='#FF9F43'
          text={t('Popup.confirmDeleteComment')}
          className='popup-delete'>
          <div className='ok btn' onClick={() => handleDeleteComment()}>
            {t('Common.buttonDelete')}
          </div>
          <div className='btn cancel' onClick={() => setPopupDeleteComment(false)}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}
      <ProviderTimeline>
      <LayoutCreatorRight
        className='detail-post-sale-mode2-header'
        titlePage={t('PostDetail.creator.title.type1')}
        page={'page-C083_1'}>
        {loading && !detailPost ? (
          <div className='shimmer-loading shimmer-mode-2'>
            <ShimmerPostDetails hasImage imageType='circular' title />
          </div>
        ) : (
          <div className='fansite-creator-post-detail creator-post-content-detail post-sale-mode-2'>
            <div
              className='content block'
              style={{
                borderBottom: 'none',
                background: '#FAFAFA',
                borderRadius: '8px',
              }}>
              <div style={{ position: 'relative' }}>
                <div className='d-flex justify-content-between avatar'>
                  <AvatarInfor
                    images={detailPost?.avatar}
                    name={detailPost?.account_name}
                    content1={detailPost}
                    onClick={
                      !isAdmin
                        ? () => navigate(`${newPathUser}${detailPost?.account_id}`)
                        : null
                    }
                  />
                </div>
              </div>
              <div className='content-image'>
                <div
                  className='package-row'
                  style={{ marginTop: '0px', marginBottom: '16px' }}>
                  <div className='package-row-item'>
                    {thumbnail?.public_flag === 1 ? (
                      <BlueImg
                        className='csp'
                        onClick={onClickFullView}
                        url={`${decryptPath(thumbnail?.url, { isMini: true })}`}>
                        <div className='bg-img'></div>
                        <ImgCustom
                          src={thumbnail?.url}
                          className='cover-img'
                          style={{ marginTop: 0 }}
                          avatarErr={false}
                        />
                      </BlueImg>
                    ) : (
                      <div className='video-detail trailer_video'>
                        <img
                          src={IMG_NO_WATER_MARK}
                          alt=''
                          className='video-detail trailer_video'
                          style={{ marginTop: 0 }}
                        />
                      </div>
                    )}
                  </div>
                  <div className='package-row-item'>
                    {detailPost?.title && (
                      <>
                        <span className='text-title'>{t('PostDetail.productTitle')}</span>
                        <div className='text-content display-linebreak'>
                          {detailPost?.title}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {detailPost?.medias?.map(
                  (item) =>
                    item?.type === 3 && (
                      <div className='show-video-sample'>
                        {item?.public_flag === 1 && thumbnail?.public_flag === 1 ? (
                          <PopupShowVideo
                            cb={pauseVideoWhenOpenPopup}
                            item={detailPost?.medias?.filter((item) => item.type === 3)}
                            thumbnail={decryptPath(thumbnail.url)}
                          />
                        ) : (
                          <PopupShowVideo water_mark={false} />
                        )}
                      </div>
                    )
                )}
                {detailPost?.medias?.map(
                  (item) =>
                    item?.type === (zipFile ? 3 : 2) && (
                      <div className='post-mode-2-video'>
                        {item?.public_flag === 1 &&
                        thumbnail?.public_flag === 1 &&
                        decryptPath(item.url)?.indexOf('.m3u8') === -1 ? (
                          <VideoDetail
                            ref={refVideoMain}
                            //className='video-detail main_video'
                            link={item.url}
                            background_video={decryptPath(thumbnail?.url)}
                          />
                        ) : item?.public_flag === 1 &&
                          thumbnail?.public_flag === 1 &&
                          decryptPath(item.url)?.indexOf('.m3u8') !== -1 ? (
                          <VideoCustomDetail
                            src={`${linkS3VideoM3U8}${decryptPath(item.url) + '#t=0.1'}`}
                            thumbnail={`${decryptPath(thumbnail?.url)}`}
                            className='video-detail'
                            isLiveArchive
                            isDetailPost
                            handleNavigate={() => {}}
                          />
                        ) : (
                          <div className='video-detail main_video'>
                            <ImgCustom
                              src={IMG_NO_WATER_MARK}
                              className='video-detail main_video'
                              style={{
                                marginTop: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                              }}
                              avatarErr={false}
                            />
                          </div>
                        )}
                      </div>
                    )
                )}

                {/* {zipFile && (
                  <div
                    className={`file-zip ${+isProdEnableLiveStream === 1 ? 'is-pointer' : ''}`}
                    style={{ position: 'relative' }}
                    onClick={() => handleDownloadFile(zipFile)}>
                    <div className='file-zip-content'>
                      <ZipFileIconV2 className='anticon file-zip-anticon' />
                      <div className='file_name'>{zipFile.file_name}</div>
                    </div>
                  </div>
                )} */}
              </div>

              <div className='list-img-detail mode2'>
                {(() => {
                  const data = detailPost?.medias
                    ?.filter((item) => item?.type === 1)
                    .filter((item) => item.public_flag === 1);
                  return (
                    data?.length > 0 && (
                      // <ListImages
                      //   images={data}
                      //   number_bookmark={detailPost?.bookmark_total}
                      //   id_post={detailPost?.id}
                      //   bookmark_status={detailPost?.user_bookmark_status}
                      //   className='custom-creator'
                      //   is_idol={true}
                      //   onClickImage={setDataViewFull}
                      //   isPostDetail
                      // />
                      <ListImagesNew images={data} onClickImage={setDataViewFull} />
                    )
                  );
                })()}
              </div>

              <div className='content-item'>
                {detailPost?.content && (
                  <div className='package '>
                    <span className='text-title'>{t('PostDetail.productDesc')}</span>
                    <div className='text-content'>
                      <MarkDownRead isMeta content={detailPost?.content} />
                    </div>
                  </div>
                )}

                {detailPost?.packages?.length > 0 && (
                  <div className='package'>
                    <span className='text-title'>{t('PostDetail.planContent')}</span>
                    <div className='text-content'>
                      {detailPost?.all_package === 1
                        ? t('Common.allPlan')
                        : detailPost.package_names}
                    </div>
                  </div>
                )}
                {detailPost?.number_part && (
                  <div className='number-part package'>
                    <span className='text-title'>{t('PostDetail.partNunber')}</span>
                    <div className='text-content'>{detailPost.number_part}</div>
                  </div>
                )}
                {detailPost?.public_time && detailPost?.unpublic_time && (
                  <div className='date package'>
                    <span className='text-title'>{t('PostDetail.salesPeriod')}</span>
                    <div className='text-content'>
                      {detailPost?.public_time &&
                        convertToCurrentTime(detailPost?.public_time, 'YYYY/MM/DD HH:mm:ss')}
                      {` ~ `}
                      {detailPost?.unpublic_time &&
                        convertToCurrentTime(detailPost?.unpublic_time, 'YYYY/MM/DD HH:mm:ss')}
                    </div>
                  </div>
                )}

                {detailPost?.price_discount && (
                  <div className='package price' style={{ alignItems: 'start' }}>
                    <div className='left'>
                      <span className='text-title'>{t('PostDetail.discount')}</span>
                      <div className='text-price'>{detailPost.discount}%</div>
                    </div>
                  </div>
                )}

                {detailPost?.price_discount && (
                  <div className='package price' style={{ alignItems: 'start' }}>
                    <div className='left'>
                      <span className='text-title'>{t('PostDetail.dateStartEnd')}</span>
                      <div className='text-price'>
                        {convertToCurrentTime(detailPost?.discount_start, 'YYYY/MM/DD HH:mm')}{' '}
                        - {convertToCurrentTime(detailPost?.discount_end, 'YYYY/MM/DD HH:mm')}
                      </div>
                    </div>
                  </div>
                )}

                {detailPost?.price !== null && (
                  <div className='package price' style={{ alignItems: 'start' }}>
                    <div className='left'>
                      <>
                        <div className={`text-title`}>{t('Popup.sellingPrice')}</div>
                        <div className='d-flex w-100'>
                          <div className='text-price'>
                            {detailPost?.price_discount
                              ? formatCoin(detailPost?.price_discount)
                              : formatCoin(detailPost?.price) || 0}
                            {i18n.language === 'en' && ' '}
                            {t('Common.pt')}
                          </div>
                          {detailPost?.price_discount && (
                            <p
                              className='point-price'
                              style={{
                                textDecorationLine: 'line-through',
                                marginLeft: '8px',
                                marginBottom: '0',
                              }}>
                              {formatCoin(detailPost?.price)} {i18n.language === 'en' && ' '}{' '}
                              Pt
                            </p>
                          )}
                        </div>
                      </>
                    </div>
                  </div>
                )}

                <div className='number_premium_ticket package'>
                  <span className='text-title'>{t('PostDetail.purchasePremiumTicket')}</span>
                  <div className='text-content'>
                    {detailPost?.number_premium_ticket
                      ? t('PostDetail.premiumTicketTarget')
                      : t('PostDetail.notPremiumTicketTarget')}
                  </div>
                </div>

                {detailPost?.number_premium_ticket && (
                  <div className='number_premium_ticket package'>
                    <span className='text-title'>{t('PostDetail.numberPremiumTicket')} </span>
                    <div
                      className='text-content'
                      style={{ color: '#CE1338', fontWeight: '700' }}>
                      {detailPost.number_premium_ticket}
                      {i18n.language === 'en' && ' '}
                      {t('Common.sheet')}
                    </div>
                  </div>
                )}
                {!!detailPost?.is_media_display && !isAdmin && !isAgency && (
                  <button
                    className='btn bold btn-danger hover-blue mt-3'
                    style={{
                      background: '#28c76f',
                      border: 'none',
                      padding: '8px 24px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                    onClick={() =>
                      handleDownload(detailPost?.medias.find((i) => i.type === 2)?.url)
                    }
                    disabled={loaddingDown || isAdmin || isAgency}>
                    <svg
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M8.0625 8.8125L12 12.749L15.9375 8.8125'
                        stroke='white'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M12 2.25L12 12.7472'
                        stroke='white'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M20.25 12.75L20.25 18C20.25 18.1989 20.171 18.3897 20.0303 18.5303C19.8897 18.671 19.6989 18.75 19.5 18.75L4.5 18.75C4.30109 18.75 4.11032 18.671 3.96967 18.5303C3.82902 18.3897 3.75 18.1989 3.75 18L3.75 12.75'
                        stroke='white'
                        strokeWidth='1.5'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </svg>
                    {t('Affiliator.AF005.download')}
                  </button>
                )}
              </div>

              <div
                className={`interactive ${
                  !detailPost?.is_media_display && detailPost?.type === 1 && 'disabled'
                }`}
                style={{ background: '#FAFAFA' }}>
                <div className='left'>
                  <div className={`item ${checkDisabledAction(detailPost) && 'disabled'}`}>
                    {detailPost?.is_liked === 1 ? (
                      <>
                        {/* đã like */}
                        <HeartActiveIcon
                          className={`like-icon active`}
                          onClick={() => {
                            !checkDisabledAction(detailPost) && handleLikePost(detailPost?.id);
                          }}
                          disabled={checkDisabledAction(detailPost)}
                        />
                      </>
                    ) : (
                      <>
                        {/* chưa like */}
                        <HeartIcon
                          className={`like-icon`}
                          onClick={() => {
                            !checkDisabledAction(detailPost) && handleLikePost(detailPost?.id);
                          }}
                          disabled={checkDisabledAction(detailPost)}
                        />
                      </>
                    )}
                    <span className='number'>{detailPost?.number_like}</span>
                  </div>
                  <div className={`item ${checkDisabledAction(detailPost) && 'disabled'}`}>
                    <ChatIcon
                      className='chat-icon'
                      disabled={checkDisabledAction(detailPost)}
                    />
                    <CountComment />
                  </div>
                  {/* <div className={`item ${checkDisabledAction(detailPost) && 'disabled'}`}>
                    <RePostIcon />
                    <span className='number'>{detailPost?.number_repost ?? 0}</span>
                  </div> */}

                  <div className={`item ${checkDisabledAction(detailPost) && 'disabled'}`}>
                    <ShareIcon
                      className={`share-icon`}
                      onClick={() => {
                        handleSharePost({
                          link: `${getEnv('REACT_APP_FAN_URL')}${newPathUser}post-sale/${
                            detailPost?.id
                          }`,
                          title: detailPost.title,
                        });
                      }}
                      disabled={checkDisabledAction(detailPost)}
                    />
                  </div>
                </div>
                {checkIsShowButtonHideComment && (
                  <div className={`item ${checkDisabledAction(detailPost) && 'disabled'}`}>
                    <HideCommentIcon
                      disabled={checkDisabledAction(detailPost)}
                      className=''
                      onClick={() => {
                        dispatch(clearIdAndDataHideComment());
                        navigate(`/comment-hide/${detailPost?.id}`);
                      }}
                    />
                  </div>
                )}
                {detailPost?.user_id !== dataUser?.id && (
                  <div className={`item ${checkDisabledAction(detailPost) && 'disabled'}`}>
                    <IconBookmark
                      style={{
                        color: detailPost?.is_bookmarked === 1 ? '#dc143c' : 'inherit',
                      }}
                      fill={detailPost?.is_bookmarked === 1 ? '#dc143c' : 'none'}
                      onClick={() => {
                        !checkDisabledAction(detailPost) &&
                          !checkDisabledAction(detailPost) &&
                          bookmarkChange(detailPost);
                      }}
                      className='cls-icon'
                    />
                  </div>
                )}
              </div>
              {isAdmin || isAgency ? (
                <>
                  <div className='content-interactive'>
                    <GroupLikeChatShared
                      detailPost={detailPost}
                      loadingAction={loadingAction}
                    />
                  </div>

                  <ListComment
                    dataPost={detailPost}
                    handleDeleteComment={(e) => {
                      setPopupDeleteComment(e);
                    }}
                    handleLikeComment={handleLikeComment}
                    dataProfile={dataProfile}
                    handleFollowUserComment={handleFollowUserComment}
                    isAdmin={isAdmin}
                  />
                </>
              ) : (
                <>
                  <div className='comment-wrap' style={{ padding: '0 16px' }}>
                    <CommentPostTimeLineShowAll
                      defaultCount={detailPost?.number_comment}
                      dataPost={detailPost}
                      handleDeleteComment={(e) => {
                        setPopupDeleteComment(e);
                      }}
                      handleHideComment={(e) => {
                        setPopupHideComment(e);
                      }}
                      handleLikeComment={handleLikeComment}
                      handleFollowUserComment={handleFollowUserComment}
                      setTrickReloadComment={setTrickReloadComment}
                      trickReloadComment={trickReloadComment}
                      id={detailPost?.id}
                      dataProfile={dataUser}
                      idScrollable=''
                      isPopupSaleContent
                      renderFolowCMT
                      typeFollow='detailPostSale'
                    />
                  </div>
                  <div
                    className={`comment block ${(isAdmin || isAgency) && 'disabled'}`}
                    style={{ marginBottom: '50px', padding: '0 16px' }}>
                    <ImgCustom
                      screen='avatar_44_44'
                      isPlaceholderAvatar
                      src={
                        isAdmin || isAgency
                          ? Avatar
                          : dataUser?.avatar
                          ? `${dataUser?.avatar}`
                          : Avatar
                      }
                    />
                    <CommentForm
                      item={detailPost}
                      handleToViewHead={handleToViewHead}
                      setTrickReloadComment={setTrickReloadComment}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </LayoutCreatorRight>
      </ProviderTimeline>
    </>
  );
};

export default withCreatorRole(Post);
