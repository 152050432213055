import Icon from '@ant-design/icons';

const SearchIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='18'
        height='19'
        fill='none'
        viewBox='0 0 18 19'>
        <circle
          cx='8.805'
          cy='8.805'
          r='7.49'
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'></circle>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M14.016 14.404l2.936 2.93'></path>
      </svg>
    )}
    {...props}
  />
);

export default SearchIcon;
