import styled from 'styled-components';

export const BlueImgLive = styled.div`
  background: url(${({ url }) => url});
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain !important;
  width: 100%;
  position: absolute;
  .bg-img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9;
    background-size: contain;
    min-height: 100%;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
  }
  .cover-img {
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    z-index: 10;
    max-width: 100%;
    max-height: 100%;
    height: auto;
  }
`;
