import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ImgCustom, Tooltip2 } from '..';
import AvatarDemo from '../../images/Avatar.png';
import {
  convertToCurrentTime,
  isAdmin,
  linkS3,
  onErrImage,
  renderTimeAfter24h,
} from '../../utils/utils';
import './AvartaInfor.scss';
import { AVATAR_DEFAULT } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { HiddenPostV3, LockedPostIcon } from 'images';
import { newPathUser } from 'constants/layout/constant';
import { resetDataInfoOther, resetIDHomePage } from 'store/actions/usersNew';
const AvatarInfor = ({
  images,
  name = '',
  content1 = '',
  contentRight = '',
  className = '',
  id = '',
  link = false,
  deleteMessage = false,
  onClick,
  handleTimeline,
  handleBookmark,
  ...props
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { detailPost } = useSelector((state) => state.usersNew);
  const dispatch = useDispatch();

  const renderIcon = () => {
    if (detailPost?.is_active === 2 && !isAdmin) {
      return (
        <Tooltip2
          style={{ zIndex: 30 }}
          className={'title-header'}
          text={
            <LockedPostIcon
              className='icon-status-post'
              style={{ display: 'flex', alignItems: 'center' }}
            />
          }>
          {t('Popup.AdminManagementPost.post_has_been_rejected')}
        </Tooltip2>
      );
    }
    if (detailPost?.is_hided === 1) {
      return <HiddenPostV3 className='icon-status-post' />;
    } else return <></>;
  };

  return (
    <div className={`avatar-infor ${className}`} {...props}>
      <div className='content-left'>
        <ImgCustom
          isPlaceholderAvatar
          screen='avatar_44_44'
          src={images ? `${images}` : AVATAR_DEFAULT}
          alt='avatar'
          onClick={(e) => {
            e.stopPropagation();
            handleTimeline && handleTimeline();
            handleBookmark && handleBookmark();
            navigate(`${newPathUser}${content1?.account_id}`, {
              state: { name: 'homepage' },
            });
          }}
          onError={(e) => onErrImage(e, true)}
        />
        <div
          className='wrap--text'
          onClick={(e) => {
            e.stopPropagation();
            handleTimeline && handleTimeline();
            handleBookmark && handleBookmark();
            navigate(`${newPathUser}${content1?.account_id}`, {
              state: { name: 'homepage' },
            });
          }}>
          <span className='name'>{name}</span>
          <div className='d-flex content1 al-center'>
            <p
              className='cls-title-2nd cls-date me-1'
              style={{ color: '#666565', marginTop: '0px', marginBottom: '0px' }}>
              {convertToCurrentTime(
                content1?.public_time || content1?.created_at,
                'YYYY/MM/DD HH:mm'
              )}
            </p>
            {content1?.unpublic_time && (
              <>
                <p
                  className='cls-title-2nd cls-date ms-1 mb-left'
                  style={{
                    color: '#DC143C',
                    marginTop: '0px',
                    border: '1px solid',
                    padding: '0px 5px',
                    marginBottom: '0',
                    marginRight: '3px',
                    width: 'fit-content',
                  }}>
                  <span className='me-1'>{t('Common.publicDay')}</span>
                  {convertToCurrentTime(content1?.unpublic_time, 'YYYY/MM/DD HH:mm')}
                </p>
              </>
            )}
            {content1?.in_24h_flag === 1 && (
              <>
                <p
                  className='cls-title-2nd cls-date ms-1 mb-left'
                  style={{
                    color: '#DC143C',
                    marginTop: '0px',
                    border: '1px solid',
                    padding: '0px 5px',
                    marginBottom: '0',
                    marginRight: '3px',
                    width: 'fit-content',
                  }}>
                  <span className='me-1'>{t('Common.publicDay')}</span>
                  {convertToCurrentTime(
                    renderTimeAfter24h(content1?.public_time),
                    'YYYY/MM/DD HH:mm'
                  )}
                </p>
              </>
            )}
            {/* <>{renderIcon()}</> */}
          </div>
        </div>
      </div>
      {link && (
        <div className='btn-wrapper'>
          <div className='link' onClick={() => navigate(`/message/${id}`)}>
            {contentRight}
          </div>
          {deleteMessage && (
            <div className='delete-message' onClick={() => {}}>
              {t('Common.buttonDelete')}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AvatarInfor;
