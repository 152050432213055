import { ImgCustom, LayoutCreatorRight } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import withCreatorRole from 'hocs/withCreatorRole';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { followOrUnFollowUser, getIDSearchUser, searchUserAction } from 'store/actions/users';
import './index.scss';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { newPathUser } from 'constants/layout/constant';
import DefaultAvatar from 'images/DefaultAvatar.svg';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { NoSearchList } from 'images';
import { isMobile } from 'react-device-detect';
import { logUserProfile } from 'store/actions/myPage';
import { STORAGE } from 'utils';
import Storage from 'utils/storage';
import { setIdScrollBackRecommend } from 'store/actions/usersNew';

const SearchUser = ({ keyword, loadingClickSearch, setFirstLoading, page, setPage, flgSearch }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [idFan, seIdFan] = useState(false);
  const [hoverId, setHoverId] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const navigate = useNavigate();
  const { searchUser = [], searchUserPagination, idSearchUser, initPageSeachUser } = useSelector((state) => state.users);
  const [searchParams] = useSearchParams();
  const keywordParams = searchParams.get('keyword');

  const fetchNext = () => {
    const pages = searchUserPagination?.total / searchUserPagination?.per_page;
    if (pages > page) {
      funCallApi(page + 1);
      setPage(page + 1);
    }
  };
  const widthScreen = window.innerWidth;
  
  useEffect(() => {
    if (idSearchUser) {
      const idPost = document.getElementById(idSearchUser.id);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idSearchUser]);
  
  const funCallApi = useCallback(
    (rs) => {
      setIsLoading(true);
      dispatch(
        searchUserAction(
          !flgSearch && !keywordParams ? '' : keyword,
          rs,
          10,
          (_response) => setIsLoading(false),
          (_errors) => setIsLoading(false)
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page]
  );

  useEffect(() => {
    if(initPageSeachUser < 1) {
      setIsLoading(true);
      setFirstLoading(true);
      dispatch(
        searchUserAction(
          keyword,
          page,
          10,
          (_response) => {
            setFirstLoading(false);
            setIsLoading(false)
          },
          (_errors) => setIsLoading(false)
        )
      );
    }
  }, [page]);

  const handleFollowOrUnfollow = (id) => {
    const elementToUpdate = searchUser.find((element) => element.id === id);
    if (elementToUpdate) {
      if (elementToUpdate.is_followed === 1) {
        elementToUpdate.is_followed = 0;
      } else {
        elementToUpdate.is_followed = 1;
      }
    }
    dispatch(followOrUnFollowUser(id));
  };
  return (
    <>
      <div className='follower-page-creator' style={{ minHeight: '622px', marginTop: "0px" }}>
        <InfiniteLoad
          loading={isLoading && !loadingClickSearch}
          data={searchUser}
          fetchNext={fetchNext}>
          <div className='list-follower-creator search-user'>
            {(isLoading && !searchUser.length) || loadingClickSearch ? (
              <div className='shimmer-loading' style={{ padding: `${widthScreen > 575 && '24px 0px'}`}}>
                <ShimmerPostDetails hasImage imageType='circular' title />
              </div>
            ) : (
              <>
                {searchUser.length > 0 ? (
                  searchUser.map((item, index) => (
                    <>
                      <div className='account-follower1 search-account-item' key={index} id={item?.id}>
                        <div
                          className={`content-left`}
                          onClick={() => {
                            if (item?.user_type === 3) {
                              setIsVisible(true);
                              seIdFan(item.id);
                            } else {
                              dispatch(setIdScrollBackRecommend(`recomend`, pathname))
                              dispatch(getIDSearchUser({id: item?.id, tab: 3, keyword: keyword, page: page }))
                              navigate(`${newPathUser}${item?.account_id}`);
                            }
                          }}>
                          <ImgCustom
                            screen={item?.avatar ? 'recommend_top_56_56' : ''}
                            src={item?.avatar ? `${item?.avatar}` : DefaultAvatar}
                            alt='avatar'
                          />
                          <div>
                            <span className='name'>
                              {item?.account_name ||
                                item?.full_name ||
                                t('Creator.accountNoName')}
                            </span>

                            <span className='list-sub description'>
                              <div className='description-text'>{item?.description}</div>
                            </span>
                          </div>
                        </div>
                        <div
                          className={`btn-followOrUnFollow ${
                            item?.is_followed === 1 ? 'following' : 'no-follow'
                          } ${item?.is_followed === 1 && hoverId === item?.id ? 'hover' : ''}`}
                          onClick={() => {
                            if (!isAuth) {
                              dispatch(logUserProfile(item));
                            } else {
                              handleFollowOrUnfollow(item.id);
                            }
                          }}
                          onMouseOver={() => {
                            if (item?.is_followed === 1 && item?.id) {
                              !isMobile && setHoverId(item.id);
                            }
                          }}
                          onMouseLeave={() => {
                            if (item?.is_followed === 1) {
                              setHoverId(false);
                            }
                          }}>
                          {item?.is_followed === 1
                            ? hoverId === item?.id
                              ? t('Creator.FollowList.unfollow')
                              : t('Creator.FollowList.following')
                            : t('Creator.FollowList.followUs')}
                        </div>
                      </div>
                    </>
                  ))
                ) : (
                  <div className='no-data'>
                    <NoSearchList />
                    {t('Search.listNull')}
                  </div>
                )}
              </>
            )}
          </div>
        </InfiniteLoad>
      </div>
    </>
  );
};

export default withCreatorRole(SearchUser);
