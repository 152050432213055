import Icon from '@ant-design/icons';

const IconAccount = ({ isActive }) => {
  return (
    <Icon
      component={() => (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='25'
          height='24'
          viewBox='0 0 25 24'
          fill='none'>
          <path
            d='M12.125 21C17.0956 21 21.125 16.9706 21.125 12C21.125 7.02944 17.0956 3 12.125 3C7.15444 3 3.125 7.02944 3.125 12C3.125 16.9706 7.15444 21 12.125 21Z'
            stroke={isActive ? '#DC143C' : '#BEBEBE'}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M12.125 15C14.1961 15 15.875 13.3211 15.875 11.25C15.875 9.17893 14.1961 7.5 12.125 7.5C10.0539 7.5 8.375 9.17893 8.375 11.25C8.375 13.3211 10.0539 15 12.125 15Z'
            stroke={isActive ? '#DC143C' : '#BEBEBE'}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M6.10547 18.6913C6.67042 17.5806 7.53171 16.6478 8.594 15.9963C9.65628 15.3448 10.8781 15 12.1243 15C13.3705 15 14.5923 15.3448 15.6546 15.9963C16.7169 16.6478 17.5782 17.5806 18.1431 18.6913'
            stroke={isActive ? '#DC143C' : '#BEBEBE'}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      )}
    />
  );
};

export default IconAccount;
