import Icon from '@ant-design/icons';

const UnFlowIcon = (props) => (
  <Icon
    component={() => (
      <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.625 10.625H19.375" stroke="#1A1A1A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.4375 12.5C11.0263 12.5 13.125 10.4013 13.125 7.8125C13.125 5.22367 11.0263 3.125 8.4375 3.125C5.84867 3.125 3.75 5.22367 3.75 7.8125C3.75 10.4013 5.84867 12.5 8.4375 12.5Z" stroke="#1A1A1A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.73535 15.6248C2.55671 14.6463 3.58238 13.8594 4.7403 13.3196C5.89822 12.7797 7.16031 12.5 8.43789 12.5C9.71547 12.5 10.9776 12.7798 12.1355 13.3196C13.2934 13.8595 14.319 14.6463 15.1404 15.6249" stroke="#1A1A1A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )}
    {...props}
  />
);

export default UnFlowIcon;
