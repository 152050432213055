// eslint-disable-next-line import/no-anonymous-default-export
export const LIVES_TYPES = {
  CONFIG: 'CONFIG',
  CLIENT: 'CLIENT',
  LOADING: 'LOADING',
  CODEC: 'CODEC',
  VIDEO: 'VIDEO',
  AUDIO: 'AUDIO',
  SCREEN: 'SCREEN',
  DEVICES_LIST: 'DEVICES_LIST',
  LOCAL_STREAM: 'LOCAL_STREAM',
  CURRENT_STREAM: 'CURRENT_STREAM',
  ADD_STREAM: 'ADD_STREAM',
  REMOVE_STREAM: 'REMOVE_STREAM',
  CLEAR_ALL_STREAM: 'CLEAR_ALL_STREAM',
  SET_EMPTY_PACKAGE: 'SET_EMPTY_PACKAGE',
  SET_VALUE_PACKAGE: 'SET_VALUE_PACKAGE',
  SET_EMPTY_FAN_LIST: 'SET_EMPTY_FAN_LIST',
  SAVE_LIVE_STREAM_CREATED: 'SAVE_LIVE_STREAM_CREATED',
};
