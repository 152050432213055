import Icon from '@ant-design/icons';

const MicroPhoneIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={44}
        height={44}
        viewBox='0 0 44 44'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx={22} cy={22} r='21.5' fill='#DC143C' stroke='#EEEEEE' />
        <path
          d='M22 12.25H22C22.9946 12.25 23.9484 12.6451 24.6517 13.3483C25.3549 14.0516 25.75 15.0054 25.75 16V22C25.75 22.9946 25.3549 23.9484 24.6517 24.6517C23.9484 25.3549 22.9946 25.75 22 25.75H22C21.5075 25.75 21.0199 25.653 20.5649 25.4645C20.11 25.2761 19.6966 24.9999 19.3483 24.6517C19.0001 24.3034 18.7239 23.89 18.5355 23.4351C18.347 22.9801 18.25 22.4925 18.25 22V16C18.25 15.0054 18.6451 14.0516 19.3483 13.3483C20.0516 12.6451 21.0054 12.25 22 12.25V12.25Z'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M22 28.75V31.75'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M28.7086 22.75C28.524 24.4001 27.7377 25.9243 26.5 27.0312C25.2624 28.1381 23.6602 28.75 21.9998 28.75C20.3394 28.75 18.7372 28.1381 17.4996 27.0312C16.262 25.9243 15.4757 24.4001 15.291 22.75'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default MicroPhoneIcon;
