import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { convertTextMonthEN, GENDER_ENUM, widthSreen } from 'utils';
import { useTranslation } from 'react-i18next';

const Chart = ({ data, titleChart = '', labelLeftChart = '' }) => {
  const { i18n, t } = useTranslation();
  const GENDER_ENUM = {
    female: t('Common.woman'),
    male: t('Common.men'),
  };
  const renderLegendText = (value) => (
    <span style={{ color: '#7B7B7B' }}>{GENDER_ENUM[value]}</span>
  );
  const renderToolTipText = (value, name) => [value, GENDER_ENUM[name]];

  return (
    <>
      <p className='report-chart-label'>{titleChart}</p>
      <ResponsiveContainer
        width='100%'
        height='100%'
        minWidth={500}
        minHeight={250}
        className='chart-content'>
        <ComposedChart width={500} height={250} data={data}>
          <CartesianGrid stroke='#DFDFDF' vertical={false} />
          <XAxis
            dataKey='month'
            tickFormatter={(e) =>
              i18n.language === 'jp' ? `${+e} ${t('Common.month')}` : convertTextMonthEN(+e)
            }
            tick={{ fill: '#282727', fontSize: 12 }}
            tickLine={false}
            stroke='#282727'
          />
          <YAxis
            width={60}
            tickLine={false}
            axisLine={false}
            tick={{ fill: '#282727', fontSize: 12 }}
            minTickGap={1}
            allowDecimals={false}
            label={{
              value: labelLeftChart,
              position: 'insideBottomLeft',
              offset: 10,
              angle: -90,
            }}
            dataKey={'total'}
          />
          <Tooltip formatter={renderToolTipText} />
          <Legend
            formatter={renderLegendText}
            align='left'
            wrapperStyle={{ left: 60, bottom: -10 }}
            iconSize={10}
            payload={[
              {
                id: 'female',
                value: 'female',
                type: 'rect',
                color: '#90BE32',
              },
              {
                id: 'male',
                value: 'male',
                type: 'rect',
                color: '#FF0000',
              },
            ]}
          />
          <Bar
            dataKey='male'
            stackId='col'
            fill='#FF0000'
            barSize={widthSreen > 991 ? 16 : 6}
          />
          <Bar
            dataKey='female'
            stackId='col'
            fill='#90BE32'
            barSize={widthSreen > 991 ? 16 : 6}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

export default Chart;
