import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getTabTimeline, resetIDTimeline } from 'store/actions/usersNew';
const NavbarTypePost = ({
  funcActive = () => {},
  classNameCustom,
  defaultActive,
  revText = false,
  isNormal,
  isCreator,
  setPage,
  idTimeline,
  firstLoading
}) => {
  const { tabTimeline } = useSelector((state) => state.usersNew);
  const { t } = useTranslation();
  const location = useLocation();
  const [tabActive, setTabActive] = useState(tabTimeline ? tabTimeline : idTimeline.tab ? idTimeline.tab : location?.state?.tab === 2 ? 2 : 1);
  const dispatch = useDispatch();
  useEffect(() => {
    defaultActive && setTabActive(defaultActive);
  }, [defaultActive]);
  useEffect(() => {
    setTabActive(tabTimeline ? tabTimeline : location?.state?.tab === 2 ? 2 : 1);
  }, [isNormal, isCreator]);

  const handleActiveTab = (tab) => {
    setPage(1);
    setTabActive(tab);
    funcActive(tab);
  };

  useEffect(() => {
    if(idTimeline.tab) {
      setTabActive(idTimeline.tab)
    }
  }, [idTimeline.tab]);

  return (
    <div className={`navbar-type-post ${classNameCustom}`}>
      <div
        className={`tab-item ${tabActive === 1 ? 'active' : ''}`}
        disabled={firstLoading}
        style={{ cursor: `${firstLoading ? 'default' : 'pointer'}`}}
        onClick={() => {
          if(!firstLoading) {
            dispatch(resetIDTimeline())
            handleActiveTab(1)}
            dispatch(getTabTimeline(1));
          }
        }>
        {revText ? t('Navbar.tab2') : t('Navbar.tab1')}
      </div>
      <div
        className={`tab-item ${tabActive === 2 ? 'active' : ''}`}
        disabled={firstLoading}
        style={{ cursor: `${firstLoading ? 'default' : 'pointer'}`}}
        onClick={() => {
          if(!firstLoading) {
            dispatch(resetIDTimeline())
            handleActiveTab(2)
            dispatch(getTabTimeline(2));
          }
        }}>
        {revText ? t('Navbar.tab1') : t('Navbar.tab2')}
      </div>
    </div>
  );
};

export default NavbarTypePost;
