/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';
const initialState = {
  status: null,
  loading: false,
  loadingDeleteMessageGroup: false,
  loadingDeleteMessage: false,
  error: null,
  listMessage: [],
  listMessageToAdmin: [],
  paginationListMessage: {},
  paginationListMessageToAdmin: {},
  idMessScroll: {},
  initPageMess: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_LIST_MESSAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_LIST_MESSAGE_TO_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_LIST_MESSAGE_SUCCESS:
      const currentPage = action?.data?.pagination?.current_page;
      const listNewMessage =
        currentPage > 1 ? [...state.listMessage, ...action.data.data] : action.data.data;
      return {
        ...state,
        listMessage: listNewMessage,
        paginationListMessage: action.data.pagination,
        initPageMess: action.data.pagination?.current_page,
        loading: false,
      };
    case TYPES.GET_LIST_MESSAGE_TO_ADMIN_SUCCESS:
      const curPage = action?.data?.pagination?.current_page;
      const listNewDataMessage =
        curPage > 1 ? [...state.listMessageToAdmin, ...action.data.data] : action.data.data;
      return {
        ...state,
        listMessageToAdmin: listNewDataMessage,
        paginationListMessageToAdmin: action.data.pagination,
        loading: false,
      };
    case TYPES.GET_LIST_MESSAGE_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };
    case TYPES.GET_LIST_MESSAGE_TO_ADMIN_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
      };
    case TYPES.DELETE_GROUP_MESSAGE_REQUEST:
      return {
        ...state,
        loadingDeleteMessageGroup: true,
      };
    case TYPES.DELETE_MESSAGE_REQUEST:
      return {
        ...state,
        loadingDeleteMessage: true,
      };
    case TYPES.DELETE_GROUP_MESSAGE_SUCCESS:
      const newListMessage = [...state.listMessage];
      newListMessage?.splice(action.data.index, 1);

      return {
        ...state,
        listMessage: newListMessage,
        paginationListMessage: action.data.pagination,
        loadingDeleteMessageGroup: false,
      };
    case TYPES.DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        loadingDeleteMessage: false,
      };
    case TYPES.DELETE_MESSAGE_FAILED:
      return {
        ...state,
        loadingDeleteMessage: false,
      };
    case TYPES.DELETE_GROUP_MESSAGE_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loadingDeleteMessageGroup: false,
      };
    case 'ID_MESS':
      return {
        ...state,
        idMessScroll: action.data
      };
    case 'RESET_ID_MESS':
      return {
        ...state,
        idMessScroll: {},
        listMessage: [],
        listMessageToAdmin: [],
        initPageMess: 0
      };
    default:
      return state;
  }
};
