import styled from 'styled-components';

export const WrapperPaymentVendoStyled = styled.div`
  padding: 20px 24px;
  .shimmer-loading {
    padding: 0px;
  }
  .mt-24 {
    margin-top: 24px !important;
  }
  .mb-8 {
    margin-bottom: 8px !important;
  }
  .mb-14 {
    margin-bottom: 14px !important;
  }
  .mb-24 {
    margin-bottom: 24px !important;
  }
  .mb-4 {
    margin-bottom: 4px !important;
  }
  .mt-4 {
    margin-top: 4px !important;
  }
  .psr {
    position: relative;
  }
  .title-vendo {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #282727;
    margin-bottom: 12px;
  }
  .block-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px;
    width: 100%;
    &.block-item_50 {
      width: 50%;
    }
    @media (max-width: 1367px) {
      .holder {
        display: none;
      }
      &.block-item_50 {
        width: 100%;
      }
      flex-direction: column;
      gap: 0px;
      &.sub-block {
        flex-direction: row;
        gap: 17px;
      }
      &.sub-col {
        flex-direction: column;
        gap: 12px;
      }
    }
    .item {
      width: 100%;
      label {
        font-weight: 400;
        font-size: 14px;
        color: #1a1a1a;
      }
      .tooltiptext {
        text-align: start;
      }
      .item-input {
        height: 44px;
        background: #ffffff;
        border: 1px solid #e5e6eb;
        border-radius: 6px;
        padding: 12px;
        width: 100%;
        font-weight: 400;
        font-size: 14px;
        color: #1a1a1a;
        &::placeholder {
          color: #bebebe;
        }
        &:active,
        &:focus {
          box-shadow: none;
          border: 1px solid #e5e6eb;
          outline: none;
        }
      }
      .icon-card {
        position: absolute;
        right: 14px;
        top: 32px;
      }
    }
    .btn {
      width: 100%;
      font-weight: 500;
      font-size: 16px;
      border-radius: 8px;
      height: 44px;
      &.btn-submit {
        background: #dc143c;
        color: #ffffff;
      }
      &.btn-cancel {
        background: #ffffff;
        color: #1a1a1a;
        border: 1px solid #e5e6eb;
      }
    }
  }
  .popup-success-payment-tiger {
    .text-content {
      margin: -10px auto 16px !important;
      text-align: center;
      width: 100%;
      padding: 0 24px;
      .title-success {
        font-weight: 700;
        font-size: 14px;
        color: #52c41a;
        margin-bottom: 18px;
      }
      .title-fail {
        font-weight: 700;
        font-size: 14px;
        color: #dc143c;
        margin-bottom: 18px;
      }
      .content {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        display: block;
      }
    }
    .button-wrapper {
      margin: 16px 0px;
      display: flex;
      gap: 8px;
      .btn {
        height: 32px;
        margin: 0 !important;
        padding: 5px 14px !important;
        &.btn-cancel {
          background: #ffffff;
          border: 1px solid #e5e6eb;
          border-radius: 6px;
          font-weight: 500;
          font-size: 14px;
          color: #1a1a1a;
        }
        &.btn-ok {
          background: #dc143c;
          color: #fff;
        }
      }
    }
  }
`;

export const WrapperConfirmStyled = styled.div`
  padding-top: 4px;
  .--item {
    width: 270px;
    min-height: 255px;
    height: fit-content;
    @media (max-width: 769px) {
      width: 343px;
      min-height: 260px;
    }
    background: #ffffff;
    border: 2px solid #e5e6eb;
    border-radius: 10px;
    text-align: center;
    padding: 12px 13px 14px;
    margin: auto;
    .--point {
      width: 100%;
      height: 175px;
      background: #f2f3f5;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 74px;
        height: 80px;
      }
      .point-number {
        color: #1a1a1a;
        font-weight: 500;
        font-size: 26px;
      }
      .point-text {
        color: #1a1a1a;
        font-weight: 400;
        font-size: 14px;
      }
    }
    .--btn {
      color: #1480ff;
      font-weight: 700;
      font-size: 26px;
      padding-top: 16px;
    }
  }
  .block-info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .block-info-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-first {
        color: #666565;
        font-weight: 400;
        font-size: 14px;
      }
      &-last {
        color: #1a1a1a;
        font-weight: 400;
        font-size: 14px;
        max-width: calc(100% - 90px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &.d-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-end;
          gap: 16px;
        }
        .search-voucher {
          position: relative;
          width: 192px;
          max-width: 192px;
          height: 44px;
          .search-voucher-input {
            width: 100%;
            max-width: 100%;
            height: 100%;
            padding: 10px 16px;
            border-radius: 8px;
            border: 1px solid #e5e6eb;
            font-size: 14px;
            font-weight: normal;
            color: #1A1A1A;
            &::placeholder {
              color: #BEBEBE;
            }
            &.haveData {
              padding: 10px 42px 10px 16px;
            }
          }
          .clear-icon-voucher {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 16px;
          }
        }
        .btn-apply-voucher {
          width: 88px;
          height: 44px;
          border-radius: 8px;
          padding: 8px 24px;
          background-color: #DC143C;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          color: #ffffff;
          font-weight: 500;
          font-size: 16px;
          &.disable {
            opacity: 0.3;
          }
        }
        .price-voucher {
          color: #52C41A;
          font-size: 14px;
          font-weight: 700;
        }
        .delete-voucher-icon {
          cursor: pointer;
        }
      }
      .btn-change {
        color: #1480ff;
        cursor: pointer;
      }
    }
  }
  .hyphen-payment {
    width: 100%;
    border: 1px solid #e5e6eb;
    margin: 16px 0px;
  }
  .agree-policy {
    .ps-2 {
      font-weight: 400;
      font-size: 14px;
      color: #1a1a1a;
      a {
        text-decoration: underline;
        color: #dc143c;
      }
    }
  }
`;
