import { PopupConfirmFan, ReadMoreLess, PopupLoading, PopupSellPackageF017 } from 'components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { newPathUser } from 'constants/layout/constant';
import { postUnSubsriptionPackage } from 'store/actions/sellPost';
import { formatCoin } from 'utils';
import PremiumTicketIconJP from 'images/PremiumTicketIconJP.png';
import PremiumTicketIconEN from 'images/PremiumTicketIconEN.png';
import { resetDataInfoOther, resetIDHomePage } from 'store/actions/usersNew';
import { setIdScrollBackPackageSub } from 'store/actions/lives';

const PackageItem = ({ item, titleSubscription, buyPackageSuccess, creator_id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checkedInput, setCheckInput] = useState();
  const [showModalUnSubscription, setShowModalUnSubscription] = useState(false);
  const [id, setId] = useState();
  const [title, setTitle] = useState();
  const [openPopupBuyPackage, setOpenPopupBuyPackage] = useState(false);
  const { loadingBuyPost } = useSelector((state) => state.sellPost);
  const { t, i18n } = useTranslation();
  const toggleModal = ({ fan_buy_package, id, title }) => {
    setId(id);
    setTitle(title);
    if (fan_buy_package === 1) {
      setShowModalUnSubscription(!showModalUnSubscription);
    } else {
      titleSubscription(title);
      setOpenPopupBuyPackage(true);
    }
  };
  const handleUnSubscription = () => {
    dispatch(postUnSubsriptionPackage(id));
    setShowModalUnSubscription(false);
    setId(null);
    titleSubscription(title);
  };

  return (
    <>
      {showModalUnSubscription && (
        <PopupConfirmFan
          isVisiblePopup={(e) => {
            setShowModalUnSubscription(e);
            setId();
          }}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.messageLeave')}>
          <div
            className='btn cancel'
            style={{ fontWeight: 500 }}
            onClick={() => {
              setShowModalUnSubscription(false);
              setId();
            }}>
            {t('Common.buttonCancel')}
          </div>
          <div
            className='ok btn'
            style={{ width: '50px', background: '#ff0000' }}
            onClick={handleUnSubscription}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}
      {loadingBuyPost && id === item.id && <PopupLoading className={'popup-loading'} />}
      {openPopupBuyPackage && id === item.id && (
        <PopupSellPackageF017
          id={id}
          openPopup={openPopupBuyPackage}
          isVisiblePopup={(e) => {
            setOpenPopupBuyPackage(e);
          }}
          buyPackageSuccess={(e) => buyPackageSuccess(e)}
          item={item}
        />
      )}
      <div
        id={`item-sub-${item?.id}`}
        className={`item-subscription-plan px-3 py-4 mb-4 w-100 ${
          item?.fan_buy_package && 'selled'
        } ${item?.ticket_id ? 'premium-color' : ''}`}>
        {!!item?.ticket_id && item?.ticket_status === 1 && (
          <div className='premium-label'>
            {i18n?.language === 'en' ? (
              <img src={PremiumTicketIconEN} alt='' />
            ) : (
              <img src={PremiumTicketIconJP} alt='' />
            )}
          </div>
        )}
        <p
          className={`item-subscription-plan__title text-center ${
            item?.ticket_id ? 'premium-color' : ''
          }`}>
          {item?.title}
        </p>
        <p className='item-subscription-plan__plan-price'>
          {t('Common.oneMonth')}/ {formatCoin(item?.price)}
          {t('Common.pt')}
        </p>
        <div className='plan-subscription p-3 display-linebreak'>
          <ReadMoreLess content={item?.description ? item?.description : ''} />
        </div>
        <div className='item-subscription-plan__footer'>
          <button
            className='item-subscription-plan__button btn btn-edit'
            onClick={() =>
              dispatch(
                setIdScrollBackPackageSub(`item-sub-${item?.id}`, () => {
                  navigate(`${newPathUser}profile/subscription/update/${item.id}`);
                })
              )
            }>
            {t('Creator.Home.subscriptionEdit')}
          </button>
          {!item?.ticket_id ? (
            <button
              className='item-subscription-plan__button btn'
              onClick={() =>
                dispatch(
                  setIdScrollBackPackageSub(`item-sub-${item?.id}`, () => {
                    navigate(`${newPathUser}profile/premium-ticket/create`, {
                      state: { packageId: item?.id, packageName: item?.title },
                    });
                  })
                )
              }>
              {t('Creator.ReviewLiveStream.newPremiumTicket')}
            </button>
          ) : (
            <button
              className='item-subscription-plan__button btn'
              onClick={() =>
                dispatch(
                  setIdScrollBackPackageSub(`item-sub-${item?.id}`, () => {
                    navigate(
                      `${newPathUser}profile/premium-ticket/update/${item?.ticket_id}`,
                      {
                        state: { packageId: item?.id, packageName: item?.title },
                      }
                    );
                  })
                )
              }>
              {t('Creator.Home.editPremiumTicket')}
            </button>
          )}
          <button
            className='item-subscription-plan__button btn btn-edit1'
            onClick={() => {
              dispatch(resetIDHomePage());
              dispatch(resetDataInfoOther());
              dispatch(
                setIdScrollBackPackageSub(`item-sub-${item?.id}`, () => {
                  navigate(
                    `${newPathUser}${item.account_id}?post=post-normal?sub=${item?.title}`,
                    {
                      state: { packageId: item?.id, packageName: item?.title },
                    }
                  );
                })
              );
            }}>
            {t('Creator.Home.listofPosts')} {`(${t('Creator.Home.numberPost')}: ${item?.post_count || 0})`} 
          </button>
        </div>
      </div>
    </>
  );
};

export default PackageItem;
