import fileDownload from 'js-file-download';
import { S3 } from 'aws-sdk';
import axios from 'axios';
import { getEnv } from 'configs/env';

// import { Stream, PassThrough } from 'stream';

// export const downloadS3Img = (data) => {
//   const linkDownLoad = `${data?.banner_image}`;
//   const getName = data?.banner_image?.split('/');
//   const nameFileDownload = getName[getName.length - 1]?.includes('.')
//     ? getName[getName.length - 1]
//     : `${getName[getName.length - 1] + '.jpg'}`;
//   Axios.get(linkDownLoad, {
//     responseType: 'blob',
//   }).then((res) => {
//     fileDownload(res.data, nameFileDownload);
//   });
// };

const s3bucket = new S3({
  accessKeyId: getEnv('REACT_APP_AWS_ACCESS_KEY_ID') ,
  secretAccessKey: getEnv('REACT_APP_AWS_SECRET_ACCESS_KEY') ,
  signatureVersion: 'v4',
  region: getEnv('REACT_APP_AWS_REGION')
});

export const downloadS3Img = async (data) => {

    const params = {
      Bucket: getEnv('REACT_APP_AWS_BUCKET_NAME'),
      Expires: +getEnv('REACT_APP_AWS_EXPIRES_TIME'),
      Key: data?.banner_image
    };

    const url = await s3bucket
      .getSignedUrlPromise('getObject', params)
      .catch((err) => {
        console.log("errr: ", err);
      });

    const getName = data?.banner_image?.split('/');
    const nameFileDownload = getName[getName.length - 1]?.includes('.')
    ? getName[getName.length - 1]
    : `${getName[getName.length - 1] + '.png'}`;

    return await axios.get(url, {
      responseType: 'blob',
    }).then((res) => {
      fileDownload(res.data, nameFileDownload);
  });;

};

