import { CheckedIcon } from 'images';
import AvatarDemo from 'images/Avatar.png';
import React from 'react';
import Select, { components } from 'react-select';
import { customStyles, linkS3 } from 'utils/utils';
import { ImgCustom } from '..';
import './index.scss';
const CustomReSelect = ({
  field,
  valueOption,
  avatar = false,
  customWidth,
  indicatorContainerHidden = false,
  stylesCustoms,
  placeholder,
  customComponent,
  ...props
}) => {
  const { Placeholder, Option } = components;

  const OptionReSelect = (props) => {
    const { data } = props;
    return (
      <Option {...props} className='option'>
        <div className='content-left'>
          {avatar && (
            <ImgCustom
              className='avatar'
              src={data.avatar ? `${data.avatar}` : AvatarDemo}
              alt=''
            />
          )}
          {data.label}
        </div>
        <div className='content-right'>
          <CheckedIcon />
        </div>
      </Option>
    );
  };

  const CustomPlaceholder = (props) => {
    return <Placeholder {...props} children={placeholder} />;
  };

  const CustomReSelect = {
    ...customStyles,
    control: (base, state) => ({
      ...base,
      width: customWidth ? 120 : '100%',
      maxHeight: 60,
      overflow: 'auto',
      border: state.isFocused
        ? '1px solid #b1b6bb !important'
        : '1px solid #b1b6bb !important',
      boxShadow: state.isFocused
        ? '1px solid #b1b6bb !important'
        : '1px solid #b1b6bb !important',
    }),
    menu: (base, state) => ({
      ...base,
      width: customWidth ? 343 : '100%',
      border: 'none !important',
      borderRadius: '6px',
      overflow: 'hidden',
    }),
    option: (base, state) => {
      return {
        ...base,
        backgroundColor: state.isSelected ? '#FFDBDB' : 'transparent',
        color: '#282727',
        height: 40,
        '&:hover': {
          backgroundColor: '#FFDBDB',
          color: '#282727',
        },
        '&.content-right': {
          color: 'red',
        },
        svg: {
          color: 'red',
          display: state.isSelected ? 'block' : 'none',
        },
        fontWeight: state.isSelected ? 500 : 400,
      };
    },
    placeholder: (base) => ({
      ...base,
      fontWeight: 500,
      fontSize: 14,
      color: '#B1b6bb',
      position: 'absolute',
      padding: '2px 2px 2px 6px',
    }),
    dropdownIndicator: (base) => ({
      ...base,
      display: 'none',
    }),
    indicatorsContainer: () => ({
      display: indicatorContainerHidden ? 'none' : 'flex',
    }),
    valueContainer: (base) => ({
      ...base,
      display: 'flex',
      flexWrap: 'wrap',
      padding: '0',
      overflow: 'visible',
    }),
    multiValue: (base) => ({
      ...base,
      alignItems: 'center',
    }),
    input: (base) => ({
      ...base,
      height: 44,
      padding: '2px 2px 2px 6px',
      margin: 0,
      position: 'relative',
    }),
  };

  return (
    <Select
      {...field}
      {...props}
      options={valueOption || [{}]}
      value={valueOption.find((c) => c.value === field.value)}
      onChange={(val) => {
        field.onChange(val);
      }}
      components={{
        Option: OptionReSelect,
        Placeholder: CustomPlaceholder,
        ...customComponent,
      }}
      styles={{ ...CustomReSelect, ...stylesCustoms }}
      className='react-select-custom'
    />
  );
};

export default CustomReSelect;
