import { PopupLoading, PopupMessageUpdateEmail } from 'components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { STORAGE } from 'utils/constant';
import Storage from 'utils/storage';
import { handleChangeEmailConfirmStep2 } from '../../store/actions/users';
import './user.scss';
const ConfirmNewEmail = () => {
  const dispatch = useDispatch();
  const { changeEmailConfirmStep2 } = useSelector((state) => state.users);
  const { loading } = useSelector((state) => state.users);
  const { t } = useTranslation();
  const [isVisibleUpdateEmailPopup, setIsVisibleUpdateEmailPopup] = useState(false);
  const location = useLocation();
  const { token } = useParams();
  const newEmail = location.search.split('new_email=')?.[1];
  const navigate = useNavigate()
  useEffect(() => {
    changeEmailConfirmStep2 && setIsVisibleUpdateEmailPopup(true);
  }, [changeEmailConfirmStep2]);

  useEffect(() => {
    token && dispatch(handleChangeEmailConfirmStep2(token));
  }, [token]);

  return (
    <div>
      {loading && <PopupLoading className={'popup-loading'} />}
      {isVisibleUpdateEmailPopup && (
        <PopupMessageUpdateEmail text={t('Popup.changeEmailSuccess')} className='update-email'>
          <div
            className='ok btn'
            style={{ marginTop: 14 }}
            onClick={() => {
              setIsVisibleUpdateEmailPopup(false);
              Storage.remove(STORAGE.CURRENT_USER_KEY);
              Storage.remove(STORAGE.USER_ACCESS_TOKEN);
              navigate('/sign-in')
            }}>
            {t('Common.Ok')}
          </div>
        </PopupMessageUpdateEmail>
      )}
      {changeEmailConfirmStep2 !== null && (
        <div className='confirm-change-email-step2-page'></div>
      )}
    </div>
  );
};

export default ConfirmNewEmail;
