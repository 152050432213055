import Icon from '@ant-design/icons';

const BlockIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g clip-path='url(#clip0_2129_221559)'>
          <path
            d='M10.0002 0.835938C8.18717 0.835937 6.41489 1.37355 4.90744 2.3808C3.39999 3.38805 2.22507 4.81968 1.53127 6.49467C0.837468 8.16966 0.655937 10.0128 1.00964 11.7909C1.36333 13.5691 2.23637 15.2024 3.51835 16.4844C4.80034 17.7664 6.43368 18.6394 8.21184 18.9931C9.99 19.3468 11.8331 19.1653 13.5081 18.4715C15.1831 17.7777 16.6147 16.6028 17.622 15.0953C18.6292 13.5879 19.1668 11.8156 19.1668 10.0026C19.164 7.57233 18.1973 5.24242 16.4788 3.52396C14.7604 1.8055 12.4304 0.838805 10.0002 0.835938ZM2.50017 10.0026C2.4995 8.30191 3.08022 6.65209 4.146 5.32677L14.676 15.8568C13.5739 16.7401 12.2446 17.2939 10.8413 17.4543C9.43801 17.6146 8.018 17.3749 6.74508 16.7629C5.47216 16.1509 4.3982 15.1914 3.64712 13.9953C2.89604 12.7991 2.49843 11.415 2.50017 10.0026ZM15.8543 14.6784L5.32433 4.14844C6.76369 2.99461 8.57819 2.41247 10.4202 2.51358C12.2621 2.61468 14.002 3.3919 15.3064 4.69633C16.6109 6.00076 17.3881 7.74065 17.4892 9.58261C17.5903 11.4246 17.0082 13.2391 15.8543 14.6784Z'
            fill={props?.color || '#666565'}
          />
        </g>
        <defs>
          <clipPath id='clip0_2129_221559'>
            <rect width='20' height='20' fill='white' />
          </clipPath>
        </defs>
      </svg>
    )}
    {...props}
  />
);

export default BlockIcon;
