/* eslint-disable react-hooks/rules-of-hooks */
import useUserRole from 'hooks/useUserRole';
import {
  callApi,
  checkNavigateByRole,
  configParamsDELETE,
  configParamsGet,
  configParamsGetNoToken,
  configParamsPostFormData,
  configParamsPostFormDataWithToken,
  configParamsPostJSON,
  configParamsPostNoBody,
  configParamsPUT,
  configParamsPUTNoToken,
  configParamsPatch,
  ENV,
  isAdmin,
  isAgency,
  queryLanguage,
  queryParamsTimezone,
  queryString,
  ROLE_ACCOUNT_BANNED,
  STORAGE,
  updateStatus,
  isAdmin as IS_ADMIN,
  ENVUSER,
  configParamsPatchNoBody,
  configParamsPatchNoToken,
  callApiWithOutShowError,
} from 'utils';
import Storage from 'utils/storage';
import { TYPES } from './index';
import { BroadcastChannel } from 'broadcast-channel';
import { newPathUser } from 'constants/layout/constant';
const { isIdol } = useUserRole();
const role = isIdol === true ? 'creator' : 'fan';
const API = {
  signIn: (token_del_flag) => `${ENVUSER}/login?token_del_flag=${token_del_flag}`,
  signUpUser: () => `${ENVUSER}/register`,
  verifyEmail: (data) => `${ENVUSER}/email-verification${data}`,
  getUserInfo: () => `${ENVUSER}/profile`,
  getUserInfoOthers: (id) => `${ENVUSER}/users/${id}`,
  getUserInfoMe: (csAdminId) => {
    if (Number(csAdminId) === 0) {
      return `${ENVUSER}/users`;
    } else return `${ENVUSER}/users?csAdminId=${csAdminId}`;
  },
  signUp: (data) =>
    `${ENV}/fan/register?is_affiliator=${data.is_affiliator}${
      data?.affiliator_ref ? `&affiliator_ref=${data?.affiliator_ref}` : ''
    }`,
  forgotPassword: () => `${ENVUSER}/reset-password`,
  resetPassword: (token) => `${ENV}/reset-password/${token}`,
  checkToken: (token) => `${ENV}/check-token/${token}`,

  editUserProfile: () => `${ENV}/users`,
  fanUpdatePassword: () => `${ENV}/profile/change-password`,
  fanListTag: (lan) => `${ENV}/tags?active_flag=1&language=${lan}`,
  creatorGetListMyFollow: (page, limit, package_id) =>
    `${ENV}/subscribers?package_id=${package_id}&page=${page}&limit=${limit}`,
  creatorGetListMyFan: (id, page, limit) =>
    `${ENV}/users/${id}/followers?page=${page}&limit=${limit}`,
  creatorGetListMyBlock: (page, limit) => `${ENV}/blocked-users?page=${page}&limit=${limit}`,
  getListCountry: (language) => `${ENV}/countries?language=${language}`,
  fanSignUpCreator: () => `${ENV}/fan/creator-register`,
  editProfile: () => `${ENVUSER}/profile/settings`,
  editProfileIndividual: () => `${ENVUSER}/profile/personal-information`,
  getPoint: () => `${ENV}/points`,
  getSaleContents: (page, limit) => `${ENV}/sale-contents?page=${page}&limit=${limit}`,
  getContentBuyers: (page, limit, id, method, keyword) =>
    `${ENV}/content-buyers?post_id=${id}&method=${method}&keyword=${keyword}&page=${page}&limit=${limit}`,
};

// sign in
const signIn = (data, token_del_flag, link_introduce = '') => {
  const formData = new FormData();
  formData.append('email', data.email);
  formData.append('password', data.password);
  formData.append('device_os', data.device_os);
  return (dispatch) => {
    dispatch(updateStatus(TYPES.SIGN_IN_REQUEST));
    callApi(
      API.signIn(token_del_flag),
      configParamsPostFormData(formData),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.SIGN_IN_SUCCESS, {
            data: response?.data?.access_token,
          })
        );
        Storage.set('email', data?.email);
        Storage.set(STORAGE.USER_STATUS, response?.data?.status);
        if (response?.data?.access_token) {
          const isURL = window.sessionStorage.getItem('url_no_login');
          if (isURL) {
            window.sessionStorage.removeItem('url_no_login');
            window.location.href = isURL;
          } else {
            window.location.href = `${newPathUser}timeline`;
          }
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.SIGN_IN_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

// sign up
const signUp = (data, checkTokenTwitter) => {
  const birthday = data.birthday.split('-').reverse().join('/');
  const formData = new FormData();
  formData.append('full_name', data.full_name);
  formData.append('gender', data.gender.value);
  formData.append('email', data.email);
  formData.append('password', data.password);
  formData.append('re_password', data.re_password);
  formData.append('birthday', birthday);
  formData.append('is_type', 3);
  if (checkTokenTwitter) {
    formData.append('oauth_token', Storage.get(STORAGE.TOKEN_TWITTER));
    formData.append('oauth_secret', Storage.get(STORAGE.SERECT_TOKEN_TWITTER));
  }
  return (dispatch) => {
    dispatch(updateStatus(TYPES.SIGN_UP_REQUEST));
    callApi(
      API.signUp(data),
      configParamsPostFormData(formData),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.SIGN_UP_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.SIGN_UP_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

// verify email
const verifyEmail = (data) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.VERIFY_EMAIL_REQUEST));
    callApiWithOutShowError(
      API.verifyEmail(data),
      configParamsPostNoBody,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.VERIFY_EMAIL_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        if (
          error.errors.messageId === 'URL_EXPIRED' ||
          error.errors.messageId === 'ACCOUNT_NOT_FOUND'
        ) {
          dispatch(updateStatus(TYPES.VERIFY_EMAIL_URL_FAILED, { error: error.errors }));
        }
        dispatch(updateStatus(TYPES.VERIFY_EMAIL_FAILED, { error: error.errors }));
      }
    );
  };
};

// Forgot password
const forgotPassword = (data) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isIdol } = useUserRole();
  // const shouldBeUrl = isIdol === true ? 2 : 3;
  const shouldBeUrl = 5;
  const formData = new FormData();
  formData.append('email', data.email);
  formData.append('type', shouldBeUrl);

  return (dispatch) => {
    dispatch(updateStatus(TYPES.FORGOT_PASSWORD_REQUEST));
    callApi(
      API.forgotPassword(),
      configParamsPostFormData(formData),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FORGOT_PASSWORD_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.FORGOT_PASSWORD_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

// Reset password
const resetPassword = (data, token) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.RESET_PASSWORD_REQUEST));
    callApi(
      API.resetPassword(token),
      configParamsPUTNoToken(data),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.RESET_PASSWORD_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.RESET_PASSWORD_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

// Check token
const checkToken = (token) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CHECK_TOKEN_REQUEST));
    callApi(
      API.checkToken(token),
      configParamsGetNoToken,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CHECK_TOKEN_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.CHECK_TOKEN_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getUserInfo = (handleSuccess) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_USER_INFO_REQUEST));
    callApi(
      API.getUserInfo(),
      configParamsGet,
      null,
      async (response) => {
        response.success && checkNavigateByRole(isIdol, response?.data?.status);
        await dispatch(
          updateStatus(TYPES.GET_USER_INFO_SUCCESS, {
            data: response,
          })
        );
        handleSuccess && handleSuccess(response);
        //window.scrollTo(0, 0);
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_USER_INFO_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        handleSuccess && handleSuccess(error);
      }
    );
  };
};

const getUserInfoOthers = (id, handleSuccess) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_USER_INFO_OTHERS_REQUEST));
    callApi(
      API.getUserInfoOthers(id),
      configParamsGet,
      null,
      async (response) => {
        //response.success && checkNavigateByRole(isIdol, response?.data?.status);
        await dispatch(
          updateStatus(TYPES.GET_USER_INFO_OTHERS_SUCCESS, {
            data: response,
          })
        );
        handleSuccess && handleSuccess(response);
        window.scrollTo(0, 0);
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_USER_INFO_OTHERS_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        window.location.href = `/not-found`;
      }
    );
  };
};

const getUserInfoMe = (cb) => {
  const csAdminId = localStorage.getItem(STORAGE.CS_ADMIN_ID);

  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_USER_INFO_ME_REQUEST));
    callApi(
      API.getUserInfoMe(csAdminId),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_USER_INFO_ME_SUCCESS, {
            data: response,
          })
        );
        cb && cb(response);
        Storage.set(STORAGE.USER_STATUS, response?.data?.status);
        window.scrollTo(0, 0);
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_USER_INFO_ME_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getUserPoints = () => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_USER_POINTS_REQUEST));
    callApi(
      API.getPoint(),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_USER_POINTS_SUCCESS, {
            data: response,
          })
        );
        window.scrollTo(0, 0);
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_USER_POINTS_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const editUserProfile = (data) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.EDIT_PROFILE_REQUEST));
    callApi(
      API.editUserProfile(),
      configParamsPUT(data),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.EDIT_PROFILE_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.EDIT_PROFILE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const changePassword = (data, callback) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CHANGE_PASSWORD_REQUEST));
    callApi(
      API.fanUpdatePassword(),
      configParamsPUT(data),
      null,
      async (response) => {
        callback && callback(response);
        await dispatch(
          updateStatus(TYPES.CHANGE_PASSWORD_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        callback && callback(error);
        dispatch(updateStatus(TYPES.CHANGE_PASSWORD_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const signUpUser = (data, checkTokenTwitter) => {
  const formData = new FormData();
  formData.append('email', data?.email);
  formData.append('password', data?.password);
  formData.append('password_confirmation', data?.password_confirmation);
  formData.append('gender', data?.gender);
  //formData.append('full_name', data?.name_romaji);
  formData.append('account_name', data?.username);
  formData.append('birthday', data?.birthday);
  //formData.append('name_japanese', data?.name_japanese);
  formData.append('country_name', data?.country_name);
  formData.append('country_id', data?.country_id);
  //formData.append('name_romaji', data?.name_romaji);
  formData.append('account_id', data?.account_id);
  //formData.append('img_front', data?.front_card);
  //formData.append('img_back', data?.back_card);
  //formData.append('phone', data?.phone);
  if (checkTokenTwitter) {
    formData.append('token', Storage.get(STORAGE.TOKEN_TWITTER));
    formData.append('secret', Storage.get(STORAGE.SERECT_TOKEN_TWITTER));
  }
  return (dispatch) => {
    dispatch(updateStatus(TYPES.SIGN_UP_REQUEST));
    callApi(
      API.signUpUser(),
      configParamsPostFormData(formData),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.SIGN_UP_SUCCESS, {
            data: response.data,
          })
        );
        if (response.success) {
          window.location.href = `sign-up/success`;
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.SIGN_UP_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const signUpCreatorByFan = (data, handleSuccess, handleFailed) => {
  const formData = new FormData();
  formData.append('email', data?.email);
  formData.append('password', data?.password);
  formData.append('gender', data?.gender);
  formData.append('username', data?.username);
  formData.append('birthday', data?.birthday);
  formData.append('account_id', data?.account_id);

  return (dispatch) => {
    dispatch(updateStatus(TYPES.SIGN_UP_REQUEST));
    callApi(
      API.fanSignUpCreator(),
      configParamsPostFormDataWithToken(formData, data?.token),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.SIGN_UP_SUCCESS, {
            data: response.data,
          })
        );
        handleSuccess && handleSuccess(response?.data);
      },
      (error) => {
        dispatch(updateStatus(TYPES.SIGN_UP_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        handleFailed && handleFailed(error);
      }
    );
  };
};

const fanListTag = (lan) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CATEGORY_PROFILE_REQUEST));
    callApi(
      API.fanListTag(lan),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CATEGORY_PROFILE_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.CATEGORY_PROFILE_FAILED, { error: error.errors }));
        // dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const creatorGetListMyFollow = (page, limit, package_id, callback) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_LIST_SUBSCRIBER_REQUEST));
    callApi(
      API.creatorGetListMyFollow(page, limit, package_id),
      configParamsGet,
      null,
      async (response) => {
        callback && callback(response);
        await dispatch(
          updateStatus(TYPES.CREATOR_LIST_SUBSCRIBER_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        callback && callback(error);
        dispatch(updateStatus(TYPES.CREATOR_LIST_SUBSCRIBER_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const creatorGetListMyFan = (id, page = 1, limit = 10, callback) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_LIST_FAN_REQUEST));
    callApi(
      API.creatorGetListMyFan(id, page, limit),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CREATOR_LIST_FAN_SUCCESS, {
            data: response,
          })
        );
        callback && callback(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATOR_LIST_FAN_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        callback && callback(error);
      }
    );
  };
};

const creatorGetListMyBlock = (page = 1, limit = 10, callback) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_LIST_BLOCK_REQUEST));
    callApi(
      API.creatorGetListMyBlock(page, limit),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CREATOR_LIST_BLOCK_SUCCESS, {
            data: response.data,
            listBlockPagination: response.pagination,
          })
        );
        callback && callback(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATOR_LIST_BLOCK_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        callback && callback(error);
      }
    );
  };
};

const getListCountry = (language) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_COUNTRY_REQUEST));
    callApi(
      API.getListCountry(language),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_COUNTRY_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_COUNTRY_FAILED, { error: error.errors }));
      }
    );
  };
};

const editCreatorProfile = (data) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.EDIT_PROFILE_BASIC_REQUEST));
    callApi(
      API.editProfile(),
      configParamsPUT(data),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.EDIT_PROFILE_BASIC_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.EDIT_PROFILE_BASIC_FAILED, { error: error.errors }));
      }
    );
  };
};

const getDetailFan = (id) => {
  const url = `${ENV}/users/${id}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_PROFILE_ANOTHER_FAN_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_PROFILE_ANOTHER_FAN_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_PROFILE_ANOTHER_FAN_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const blockOrUnblockUser = (id, cb) => {
  const url = `${ENV}/users/${id}/blocking`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.BLOCK_OR_UNBLOCK_USER_REQUEST));
    callApi(
      url,
      configParamsPostNoBody,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.BLOCK_OR_UNBLOCK_USER_SUCCESS, {
            data: response.data,
            id: id,
          })
        );
        cb && cb(response.data);
      },
      (error) => {
        dispatch(updateStatus(TYPES.BLOCK_OR_UNBLOCK_USER_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const blockOrUnblockInProfileFan = (id) => {
  const url = `${ENV}/users/${id}/blocking`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.BLOCK_OR_UNBLOCK_IN_PROFILE_FAN_REQUEST));
    callApi(
      url,
      configParamsPostNoBody,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.BLOCK_OR_UNBLOCK_IN_PROFILE_FAN_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.BLOCK_OR_UNBLOCK_IN_PROFILE_FAN_FAILED, { err: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const blockOrUnblockFanMessage = (id, handleBlockSuccess) => {
  const url = `${ENV}/users/${id}/blocking`;
  return () => {
    callApi(
      url,
      configParamsPostNoBody,
      null,
      async (response) => {
        handleBlockSuccess && handleBlockSuccess(response);
      },
      () => {
        handleBlockSuccess && handleBlockSuccess();
      }
    );
  };
};

const followOrUnFollowUser = (id, flg, onRequest = () => {}, onFinally = () => {}) => {
  const url = `${ENV}/users/${id}/following`;
  return (dispatch) => {
    onRequest();
    dispatch(updateStatus(TYPES.FOLLOW_OR_UN_FOLLOW_USER_REQUEST));
    callApi(
      url,
      configParamsPostNoBody,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FOLLOW_OR_UN_FOLLOW_USER_SUCCESS, {
            data: response.data,
            id: id,
            flg: flg,
          })
        );
        await dispatch(
          updateStatus(TYPES.FOLLOW_USER_COMMENT_DETAIL_SUCCESS, {
            data: response.data,
          })
        );
        await dispatch(
          updateStatus(TYPES.FOLLOW_USER_REPLY_COMMENT_DETAIL_SUCCESS, {
            data: response.data,
          })
        );
        onFinally();
      },
      (error) => {
        onFinally();
        dispatch(updateStatus(TYPES.FOLLOW_OR_UN_FOLLOW_USER_FAILED, { error: error.errors }));
      }
    );
  };
};

const editCreatorProfileIndividual = (data) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.EDIT_PROFILE_REQUEST));
    callApi(
      API.editProfileIndividual(),
      configParamsPUT(data),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.EDIT_PROFILE_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.EDIT_PROFILE_FAILED, { error: error.errors }));
      }
    );
  };
};

const editCreatorProfileByFanLogin = (data) => {
  const url = `${ENV}/creator/information`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.EDIT_PROFILE_REQUEST));
    callApi(
      url,
      configParamsPUT(data),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.EDIT_PROFILE_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.EDIT_PROFILE_FAILED, { error: error.errors }));
      }
    );
  };
};

const requestLeave = () => {
  const url = `${ENV}/request-leave`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.REQUEST_LEAVE_REQUEST));
    callApi(
      url,
      configParamsPatchNoBody,
      null,
      async (response) => {
        if (response.success) {
          Storage.remove(STORAGE.USER_ACCESS_TOKEN);
          window.location.href = '/message/leave?leave=yes';
        }
      },
      (error) => {
        dispatch(updateStatus(TYPES.REQUEST_LEAVE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const requestApprove = (data) => {
  const url = `${ENV}/approval-request`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.REQUEST_APPROVE_REQUEST));
    callApi(
      url,
      configParamsPatch(data),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.REQUEST_APPROVE_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.REQUEST_APPROVE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const creatorGetListMyBuyer = (page, limit = 10, user_id, callback) => {
  const url = user_id
    ? `${ENV}/purchased-posts?user_id=${user_id}&limit=${limit}&page=${page}`
    : `${ENV}/purchased-posts?limit=${limit}&page=${page}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_LIST_BUYER_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CREATOR_LIST_BUYER_SUCCESS, {
            data: response,
          })
        );
        callback && callback(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATOR_LIST_BUYER_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        callback && callback(error);
      }
    );
  };
};

const setListMyBuyer = () => {
  return (dispatch) => {
    dispatch(updateStatus('CREATOR_LIST_BUYER_REFRESH'));
  };
};

const creatorGetListRankingBuyer = (account_id, queries, callback) => {
  const url = `${ENV}/top-paying-users/${account_id}?${
    queryString(queries) + queryParamsTimezone()
  }`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_LIST_RANKING_BUYER_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        callback && callback(response);
        await dispatch(
          updateStatus(TYPES.CREATOR_LIST_RANKING_BUYER_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        callback && callback(error);
        dispatch(
          updateStatus(TYPES.CREATOR_LIST_RANKING_BUYER_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const fanGetListRankingBuyer = (account_id, queries) => {
  const url = `${ENV}/top-paying-users/${account_id}?${
    queryString(queries) + queryParamsTimezone()
  }`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.FAN_LIST_RANKING_BUYER_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FAN_LIST_RANKING_BUYER_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.FAN_LIST_RANKING_BUYER_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const setListRankingBuyer = () => {
  return (dispatch) => {
    dispatch(updateStatus('CREATOR_LIST_RANKING_BUYER_REFRESH'));
  };
};
const logoutChannel = new BroadcastChannel('logout');
const navigateLogout = () => (window.location.href = `/sign-in`);
const navigateForgot = () => (window.location.href = `/forgot-password`);
// logout
const logout = (customUrl, callBack) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const url = `${ENVUSER}/logout`;
  logoutChannel.postMessage('logout');

  if (isAdmin || isAgency) {
    Storage.remove(STORAGE.PARAMS);
    Storage.remove(STORAGE.USER_ACCESS_TOKEN);
    Storage.remove(STORAGE.IS_ADMIN);
    Storage.remove(STORAGE.CURRENT_USER_KEY);
    Storage.remove(STORAGE.IS_AGENCY);
    Storage.remove(STORAGE.USER_ID);
    window.localStorage.removeItem('cpw_ls');
    navigateLogout();
  } else {
    return (dispatch) => {
      dispatch(updateStatus(TYPES.LOGOUT_REQUEST));
      callApiWithOutShowError(
        url,
        configParamsPostNoBody,
        null,
        async (response) => {
          await dispatch(
            updateStatus(TYPES.LOGOUT_SUCCESS, {
              data: response.data,
            })
          );
          Storage.remove(STORAGE.USER_ACCESS_TOKEN);
          Storage.remove(STORAGE.IS_AFFILIATE);
          Storage.remove(STORAGE.CURRENT_USER_KEY);
          Storage.remove(STORAGE.TOKEN_TWITTER);
          Storage.remove(STORAGE.SERECT_TOKEN_TWITTER);
          Storage.remove(STORAGE.USER_STATUS);
          callBack && callBack(response);
          customUrl ? navigateForgot() : navigateLogout();
        },
        (error) => {
          dispatch(updateStatus(TYPES.LOGOUT_FAILED, { error: error.errors }));
        }
      );
    };
  }
};

const logoutAllTabs = () => {
  logoutChannel.onmessage = () => {
    navigateLogout();
    logoutChannel.close();
  };
};

const userFollowing = (id, page = 1, limit = 10, callback) => {
  const url = `${ENV}/users/${id}/followings?page=${page}&limit=${limit}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.USER_FOLLOWING_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.USER_FOLLOWING_SUCCESS, {
            data: response,
          })
        );
        callback && callback(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.USER_FOLLOWING_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        callback && callback(error);
      }
    );
  };
};

const creatorPackage = (queries, handleShowConfirm, handleSubmitFail) => {
  const url = `${ENV}/packages`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_PACKAGE_REQUEST));
    callApi(
      url,
      configParamsPostJSON(queries),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CREATOR_PACKAGE_SUCCESS, {
            data: response.data,
          })
        );
        handleShowConfirm && handleShowConfirm(response);
      },
      (error) => {
        handleSubmitFail && handleSubmitFail();
        dispatch(updateStatus(TYPES.CREATOR_PACKAGE_FAILED, { error: error.errors }));
      }
    );
  };
};

const updateCreatorPackage = (params, handleShowConfirm, handleSubmitFail) => {
  const url = `${ENV}/packages/${params.id}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.UPDATE_CREATOR_PACKAGE_REQUEST));
    callApi(
      url,
      configParamsPUT(params),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.UPDATE_CREATOR_PACKAGE_SUCCESS, {
            data: response.data,
          })
        );
        handleShowConfirm && handleShowConfirm(response);
      },
      (error) => {
        handleSubmitFail && handleSubmitFail();
        dispatch(updateStatus(TYPES.UPDATE_CREATOR_PACKAGE_FAILED, { error: error.errors }));
      }
    );
  };
};

const deleteCreatorPackage = (id, handleDeleteSuccess, handleSubmitFail) => {
  const url = `${ENV}/packages/${id}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.DELETE_CREATOR_PACKAGE_REQUEST));
    callApi(
      url,
      configParamsDELETE,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.DELETE_CREATOR_PACKAGE_SUCCESS, {
            data: response.data,
          })
        );
        handleDeleteSuccess && handleDeleteSuccess(response);
      },
      (error) => {
        handleSubmitFail && handleSubmitFail();
        dispatch(updateStatus(TYPES.DELETE_CREATOR_PACKAGE_FAILED, { error: error.errors }));
      }
    );
  };
};

const getCreatorPackageDetail = (id, handleSetInitialData) => {
  const url = `${ENV}/packages/${id}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_CREATOR_PACKAGE_DETAIL_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_CREATOR_PACKAGE_DETAIL_SUCCESS, {
            data: response.data,
          })
        );
        handleSetInitialData && handleSetInitialData(response);
      },
      async (error) => {
        await dispatch(
          updateStatus(TYPES.GET_CREATOR_PACKAGE_DETAIL_FAILED, { error: error.errors })
        );
        handleSetInitialData && handleSetInitialData(error);
      }
    );
  };
};

const creatorGetListPackage = (query = '', limit = 10, handleSuccess) => {
  const url = `${ENV}/packages/?sort=asc&limit=${limit}&query=${query}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_LIST_PACKAGE_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CREATOR_LIST_PACKAGE_SUCCESS, {
            data: response,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATOR_LIST_PACKAGE_FAILED, { error: error.errors }));
        handleSuccess && handleSuccess(error);
      }
    );
  };
};

const fanGetListBookmark = (page = 1, limit = 10, handleSuccess, handleFail) => {
  const url = `${ENV}/bookmarks?limit=${limit}&page=${page}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.FAN_GET_LIST_BOOKMARK_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FAN_GET_LIST_BOOKMARK_SUCCESS, {
            data: response.data,
            pagination: response.pagination,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleFail();
        dispatch(updateStatus(TYPES.FAN_GET_LIST_BOOKMARK_FAILED, { error: error.errors }));
      }
    );
  };
};

const searchTimeline = (keyword = '', page = 1, limit = 10, handleSuccess, handleFailed) => {
  const url = `${ENV}/searches?keyword=${encodeURIComponent(
    keyword
  )}&limit=${limit}&page=${page}&type=post`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.FAN_GET_LIST_BOOKMARK_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FAN_GET_LIST_BOOKMARK_SUCCESS, {
            data: response.data,
            pagination: response.pagination,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.FAN_GET_LIST_BOOKMARK_FAILED, { error: error.errors }));
        handleFailed && handleFailed(error);
      }
    );
  };
};

const clearIsLoadDataSearchShort = () => {
  return (dispatch) => {
    dispatch(updateStatus('CLEAR_IS_LOAD_DATA_SEARCH_SHORT'));
  };
};

const searchShortVideoAction = (
  keyword = '',
  page = 1,
  limit = 10,
  handleSuccess,
  handleFailed
) => {
  const url = `${ENV}/searches?keyword=${encodeURIComponent(
    keyword
  )}&limit=${limit}&page=${page}&type=shortvideo`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.SEARCH_SHORT_VIDEO_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.SEARCH_SHORT_VIDEO_SUCCESS, {
            data: response.data,
            pagination: response.pagination,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.SEARCH_SHORT_VIDEO_FAILED, { error: error.errors }));
        handleFailed && handleFailed(error);
      }
    );
  };
};

const searchUserAction = (keyword = '', page = 1, limit = 10, handleSuccess, handleFailed) => {
  const url = `${ENV}/searches?keyword=${encodeURIComponent(
    keyword
  )}&limit=${limit}&page=${page}&type=user`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.SEARCH_USER_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.SEARCH_USER_SUCCESS, {
            data: response.data,
            pagination: response.pagination,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.SEARCH_USER_FAILED, { error: error.errors }));
        handleFailed && handleFailed(error);
      }
    );
  };
};

const searchHashtagAction = (
  keyword = '',
  page = 1,
  limit = 10,
  handleSuccess,
  handleFailed
) => {
  const url = `${ENV}/searches?keyword=${encodeURIComponent(
    keyword
  )}&limit=${limit}&page=${page}&type=hashtag`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.SEARCH_HASHTAG_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.SEARCH_HASHTAG_SUCCESS, {
            data: response.data,
            pagination: response.pagination,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.SEARCH_HASHTAG_FAILED, { error: error.errors }));
        handleFailed && handleFailed(error);
      }
    );
  };
};

const searchHashtagPostsAction = (
  id = '',
  keyword = '',
  page = 1,
  limit = 10,
  handleSuccess,
  handleFailed
) => {
  const url = `${ENV}/hashtags/${id}/posts?keyword=${keyword}&limit=${limit}&page=${page}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.SEARCH_HASHTAG_POSTS_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.SEARCH_HASHTAG_POSTS_SUCCESS, {
            data: response.data,
            pagination: response.pagination,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.SEARCH_HASHTAG_POSTS_FAILED, { error: error.errors }));
        handleFailed && handleFailed(error);
      }
    );
  };
};

const postBookMark = (id, handleSuccess) => {
  const url = `${ENV}/posts/${id}/bookmarks`;
  const configParams = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };

  return (dispatch) => {
    dispatch(updateStatus(TYPES.BOOK_MARK_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.BOOK_MARK_SUCCESS, {
            data: response.data,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.BOOK_MARK_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const resetActionUser = () => (dispatch) => {
  dispatch(updateStatus('RESET_ACTION_USER'));
};

const resetActionGetProfileAnotherFan = () => (dispatch) => {
  dispatch(updateStatus('RESET_ACTION_GET_PROFILE_ANOTHER_FAN'));
};

const handleChangeEmailStep1 = () => {
  const url = `${ENV}/change-email/step1`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.USER_CHANGE_EMAIL_1_REQUEST));
    callApi(
      url,
      configParamsPostNoBody,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.USER_CHANGE_EMAIL_1_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.USER_CHANGE_EMAIL_1_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};
const handleChangeEmailConfirmStep1 = (token) => {
  const url = `${ENV}/change-email/step1-confirm/${token}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.USER_CHANGE_EMAIL_CONFIRM_1_REQUEST));
    callApi(
      url,
      configParamsPostNoBody,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.USER_CHANGE_EMAIL_CONFIRM_1_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.USER_CHANGE_EMAIL_CONFIRM_1_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const handleChangeEmailStep2 = (new_email) => {
  const url = `${ENV}/change-email/step2?new_email=${encodeURIComponent(new_email)}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.USER_CHANGE_EMAIL_2_REQUEST));
    callApi(
      url,
      configParamsPostNoBody,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.USER_CHANGE_EMAIL_2_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.USER_CHANGE_EMAIL_2_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};
const handleChangeEmailConfirmStep2 = (token, new_email) => {
  const url = `${ENV}/change-email/step2-confirm/${token}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.USER_CHANGE_EMAIL_CONFIRM_2_REQUEST));
    callApi(
      url,
      configParamsPostNoBody,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.USER_CHANGE_EMAIL_CONFIRM_2_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.USER_CHANGE_EMAIL_CONFIRM_2_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const fanViewedPosts = (page = 1, limit = 10) => {
  const url = `${ENV}/viewed-posts?tab=post&page=${page}&limit=${limit}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.FAN_VIEWED_POSTS_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FAN_VIEWED_POSTS_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.FAN_VIEWED_POSTS_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const fanViewed = (page = 1, limit = 10) => {
  const url = `${ENV}/viewed-posts?tab=view&page=${page}&limit=${limit}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.FAN_VIEWED_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FAN_VIEWED_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.FAN_VIEWED_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const creatorGetListFan = (params, resultData) => {
  const url = `${ENV}/search-users?${queryString(params)}`;
  return () => {
    callApi(url, configParamsGet, null, async (response) => {
      resultData && resultData(response, params?.page);
    });
  };
};

const fanGetListCreator = (params, resultData) => {
  const url = `${ENV}/creators?${queryString(params)}`;
  return () => {
    callApi(url, configParamsGet, null, async (response) => {
      resultData && resultData(response);
    });
  };
};

const fanGetListPackages = (page, limit = 10, callback) => {
  const url = `${ENV}/user-subscriptions?page=${page}&limit=${limit}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.FAN_GET_LIST_PACKAGES_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FAN_GET_LIST_PACKAGES_SUCCESS, {
            data: response.data,
            fanListPackagesPagination: response.pagination,
          })
        );
        callback && callback(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.FAN_GET_LIST_PACKAGES_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        callback && callback(error);
      }
    );
  };
};

const fanGetListLikedPost = (params, callback) => {
  const url = `${ENV}/liked-posts?${queryString(params)}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.FAN_GET_LIKED_POST_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        callback && callback(response);
        await dispatch(
          updateStatus(TYPES.FAN_GET_LIKED_POST_SUCCESS, {
            data: response.data,
            pagination: response.pagination,
          })
        );
      },
      (error) => {
        callback && callback(error);
        dispatch(updateStatus(TYPES.FAN_GET_LIKED_POST_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const updateListLikeFromPost = (id, value) => (dispatch) => {
  dispatch(
    updateStatus('UPDATE_LIST_LIKE_FROM_DETAIL_POST', {
      id,
      value
    })
  );
};

const getSaleContents = (page, limit) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_SALE_CONTENTS_REQUEST));
    callApi(
      API.getSaleContents(page, limit),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_SALE_CONTENTS_SUCCESS, {
            data: response,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_SALE_CONTENTS_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const getContentBuyers = (page, limit, id, method, keyword, callback) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_CONTENT_BUYER_REQUEST));
    callApi(
      API.getContentBuyers(page, limit, id, method, keyword),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_CONTENT_BUYER_SUCCESS, {
            data: response,
          })
        );
        callback && callback(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_CONTENT_BUYER_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        callback && callback(error);
      }
    );
  };
};

const setDataItemBookmarkFromId = (id, type, value) => {
  return (dispatch) => {
    if (type === 'like') {
      dispatch(
        updateStatus(TYPES.SET_COUNT_LIKE_POST_BOOKMARK_SUCCESS, {
          data: {
            id,
            is_liked: value,
            number_like: value === 1 ? 1 : -1,
          },
        })
      );
    } else if (type === 'comment') {
      dispatch(
        updateStatus(TYPES.SET_COUNT_COMMENT_BOOKMARK_SUCCESS, {
          data: {
            id,
            number_comment: value,
          },
        })
      );
    }
  };
};

const setDataItemFollowUser = (id, value) => {
  return (dispatch) => {
    dispatch(
      updateStatus(TYPES.FOLLOW_OR_UN_FOLLOW_USER_SUCCESS, {
        data: value,
        id: id,
      })
    );
  };
};

const setIdScrollBackFollowing = (id, cb) => (dispatch) => {
  dispatch(
    updateStatus('SET_ID_SCROLL_BACK_FOLLOWING', {
      id,
    })
  );
  cb && cb();
};

const setIdScrollBackFollower = (id, cb) => (dispatch) => {
  dispatch(
    updateStatus('SET_ID_SCROLL_BACK_FOLLOWER', {
      id,
    })
  );
  cb && cb();
};

const clearScrollBackFollow = () => (dispatch) => {
  dispatch(updateStatus('CLEAR_SCROLL_BACK_FOLLOW'));
};

const clearScrollBackFollower = () => (dispatch) => {
  dispatch(updateStatus('CLEAR_SCROLL_BACK_FOLLOWER'));
};

const clearScrollBackFollowing = () => (dispatch) => {
  dispatch(updateStatus('CLEAR_SCROLL_BACK_FOLLOWING'));
};

const handleResetFanViewed = () => (dispatch) => {
  dispatch(updateStatus('HANDLE_RESET_FAN_VIEWED'));
};
const handleResetFanViewPost = () => (dispatch) => {
  dispatch(updateStatus('HANDLE_RESET_FAN_VIEWED_POST'));
};
const handleResetListContentBuyers = () => (dispatch) => {
  dispatch(updateStatus('RESET_CONTENT_BUYER'));
};

const handleResetDataProfile = () => (dispatch) => {
  dispatch(updateStatus('RESET_DATA_PROFILE'));
};

const handleResetDataProfileUser = () => (dispatch) => {
  dispatch(updateStatus('RESET_DATA_PROFILE_USER'));
};

const getIDSearchUser = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('ID_POST_SEARCH_USER', payload));
  };
};

const resetIDSearchUser = () => (dispatch) => {
  dispatch(updateStatus('RESET_ID_SEARCH_USER'));
};

const getIDSearchBuyer = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('ID_POST_BUYER', payload));
  };
};

const resetIDSearchBuyer = () => (dispatch) => {
  dispatch(updateStatus('RESET_ID_BUYER'));
};

const resetIDSearchBuyerNew = () => (dispatch) => {
  dispatch(updateStatus('RESET_ID_BUYER_NEW'));
};

const getIDSearchBuyed = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('ID_POST_BUYED', payload));
  };
};

const resetIDSearchBuyed = () => (dispatch) => {
  dispatch(updateStatus('RESET_ID_BUYED'));
};

const getIDSubcribe = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('ID_SUBCRIBE', payload));
  };
};

const resetIDSubcribe = () => (dispatch) => {
  dispatch(updateStatus('RESET_SUBCRIBE'));
};

const getIDLike = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('ID_LIKE', payload));
  };
};

const resetIDLike = () => (dispatch) => {
  dispatch(updateStatus('RESET_ID_LIKE'));
};

const getIDFavorite = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('ID_FAVORITE', payload));
  };
};

const resetIDFavorite = () => (dispatch) => {
  dispatch(updateStatus('RESET_ID_FAVORITE'));
};

const setIdSubscriber = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('SET_ID_SCROLL_BACK_SUBSCRIBER', payload));
  };
};

const resetIdSubscriber = () => (dispatch) => {
  dispatch(updateStatus('RESET_ID_SUBSCRIBER'));
};

const getIDBlock = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('SET_ID_BLOCK', payload));
  };
};

const resetIDBlock = () => (dispatch) => {
  dispatch(updateStatus('RESET_ID_BLOCK'));
};

const getIDRankingBuy = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('SET_ID_RANKING_BUY', payload));
  };
};

const resetIDRankingBuy = () => (dispatch) => {
  dispatch(updateStatus('RESET_ID_RANKING_BUY'));
};

const updateListRankingBySelectSort = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('UPDATE_LIST_BY_SELECT_SORT', payload));
  };
};

export {
  signIn,
  logout,
  forgotPassword,
  resetPassword,
  signUp,
  getUserInfo,
  editUserProfile,
  changePassword,
  fanListTag,
  signUpUser,
  creatorGetListMyFollow,
  creatorGetListMyFan,
  creatorGetListMyBlock,
  creatorGetListMyBuyer,
  setListMyBuyer,
  creatorGetListRankingBuyer,
  setListRankingBuyer,
  checkToken,
  getListCountry,
  editCreatorProfile,
  editCreatorProfileIndividual,
  requestLeave,
  requestApprove,
  getDetailFan,
  followOrUnFollowUser,
  blockOrUnblockUser,
  creatorPackage,
  updateCreatorPackage,
  deleteCreatorPackage,
  getCreatorPackageDetail,
  verifyEmail,
  userFollowing,
  creatorGetListPackage,
  fanGetListBookmark,
  postBookMark,
  resetActionUser,
  handleChangeEmailStep1,
  handleChangeEmailStep2,
  handleChangeEmailConfirmStep1,
  handleChangeEmailConfirmStep2,
  fanViewedPosts,
  fanViewed,
  creatorGetListFan,
  blockOrUnblockInProfileFan,
  blockOrUnblockFanMessage,
  resetActionGetProfileAnotherFan,
  fanGetListPackages,
  handleResetFanViewed,
  handleResetFanViewPost,
  fanGetListLikedPost,
  fanGetListCreator,
  fanGetListRankingBuyer,
  searchHashtagPostsAction,
  signUpCreatorByFan,
  editCreatorProfileByFanLogin,
  logoutAllTabs,
  getUserInfoOthers,
  getUserInfoMe,
  getUserPoints,
  searchUserAction,
  getSaleContents,
  searchShortVideoAction,
  searchHashtagAction,
  getContentBuyers,
  searchTimeline,
  clearIsLoadDataSearchShort,
  handleResetListContentBuyers,
  handleResetDataProfile,
  handleResetDataProfileUser,
  getIDSearchUser,
  resetIDSearchUser,
  resetIDSearchBuyer,
  getIDSearchBuyer,
  getIDSearchBuyed,
  resetIDSearchBuyed,
  getIDSubcribe,
  resetIDSubcribe,
  getIDLike,
  resetIDLike,
  getIDFavorite,
  resetIDFavorite,
  setIdScrollBackFollowing,
  setIdScrollBackFollower,
  clearScrollBackFollow,
  setIdSubscriber,
  resetIdSubscriber,
  setDataItemFollowUser,
  setDataItemBookmarkFromId,
  getIDBlock,
  resetIDBlock,
  updateListRankingBySelectSort,
  getIDRankingBuy,
  resetIDRankingBuy,
  resetIDSearchBuyerNew,
  clearScrollBackFollower,
  clearScrollBackFollowing,
  updateListLikeFromPost,
};
