import RouteSuspense from 'components/RouteSuspense';
import PostContentSaleDetail from 'pages/creator/layout/post-article/post-content-sale';
import PostContentDetail from '../pages/creator/layout/post-article/post-content-detail';
import PageNotFound from 'pages/not-found';
import CreatorHomePage from 'pages/creator/layout/home-page';

export const AGENCY_ROUTES = [
  {
    path: '/post/:id',
    element: (
      <RouteSuspense>
        <PostContentDetail />
      </RouteSuspense>
    ),
  },
  {
    path: '/',
    element: <CreatorHomePage />,
  },
  {
    path: '/:account_id',
    element: (
      <RouteSuspense>
        <CreatorHomePage />
      </RouteSuspense>
    ),
  },
  {
    path: '/post-sale/:id',
    element: (
      <RouteSuspense>
        <PostContentSaleDetail />
      </RouteSuspense>
    ),
  }, {
    path: '*',
    element: (
      <RouteSuspense>
        <PageNotFound />
      </RouteSuspense>
    ),
  },
];
