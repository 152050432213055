import { getEnv } from 'configs/env';
import {
  configParamsGet,
  configParamsPUT,
  configParamsPatch,
  updateStatus,
} from 'utils/constant';
import { callApi } from 'utils/request';
import { queryString } from '../../utils/utils';
import { TYPES } from './index';

const ENV = getEnv('REACT_APP_API_SERVER');
const API = {
  userGetListNotification: (queries) => `${ENV}/notifications?${queryString(queries)}`,
  userGetDetailNotification: (id) => `${ENV}/notifications/${id}`,
  userGetBadgeNotification: () => `${ENV}/user/notification/badge`,
  userGetSettingNotification: () => `${ENV}/notification-setting`,
  userPutSettingNotification: () => `${ENV}/notification-setting`,
};

// User Notification
const userGetListNotification = (queries, callback) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.LIST_NOTIFICATION_REQUEST));
    callApi(
      API.userGetListNotification(queries),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.LIST_NOTIFICATION_SUCCESS, {
            data: response,
            type: queries.type,
          })
        );
        callback && callback(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.LIST_NOTIFICATION_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        callback && callback(error);
      }
    );
  };
};

const userGetDetailNotification = (id, handleSuccess) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_DETAIL_NOTICATION_REQUEST));
    callApi(
      API.userGetDetailNotification(id),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_DETAIL_NOTICATION_SUCCESS, {
            data: response.data,
          })
        );
        handleSuccess && handleSuccess();
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_DETAIL_NOTICATION_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const userGetBadgeNotification = (handleSetDataUnread) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_NOTICATION_MYPAGE_REQUEST));
    callApi(
      API.userGetBadgeNotification(),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_NOTICATION_MYPAGE_SUCCESS, {
            data: response.data,
          })
        );
        handleSetDataUnread && handleSetDataUnread();
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_NOTICATION_MYPAGE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

//setting notification
const userGetSettingNotification = () => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_SETTING_NOTIFICATION_REQUEST));
    callApi(
      API.userGetSettingNotification(),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_SETTING_NOTIFICATION_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_SETTING_NOTIFICATION_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const userPutSettingNotification = (params, handleSuccess) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.SETTING_NOTIFICATION_REQUEST));
    callApi(
      API.userPutSettingNotification(),
      configParamsPatch(params),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.SETTING_NOTIFICATION_SUCCESS, {
            data: response.data,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.SETTING_NOTIFICATION_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const resetNotifiSetting = () => (dispatch) => {
  dispatch(updateStatus('RESET_NOTIFI_SETTING'));
};
const resetNotiDetail = () => (dispatch) => {
  dispatch(updateStatus('RESET_NOTI_DETAIL'));
};

const getIDNoti = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('ID_NOTI1', payload));
  };
};

const resetIDNoti = () => (dispatch) => {
  dispatch(updateStatus('RESET_ID_NOTI1'));
};

const getIDNotiAdmin = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('ID_NOTI1_ADMIN', payload));
  };
};

const resetIDNotiAdmin = () => (dispatch) => {
  dispatch(updateStatus('RESET_ID_NOTI1_ADMIN'));
};

const getTabNoti = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('TAB_NOTI', payload));
  };
};

const resetTabNoti = () => (dispatch) => {
  dispatch(updateStatus('RESET_TAB_NOTI'));
};

export {
  userGetSettingNotification,
  userPutSettingNotification,
  userGetListNotification,
  resetNotiDetail,
  userGetDetailNotification,
  userGetBadgeNotification,
  resetNotifiSetting,
  getIDNoti,
  resetIDNoti,
  getIDNotiAdmin,
  resetIDNotiAdmin,
  getTabNoti,
  resetTabNoti
};
