export default function UploadMultiImageIcon(props) {
  return (
    <svg
      width={props?.width || 22}
      height={props?.height || 22}
      viewBox='0 0 22 22'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M14.75 5H2.75C1.475 5 0.5 5.975 0.5 7.25V19.25C0.5 20.525 1.475 21.5 2.75 21.5H14.75C16.025 21.5 17 20.525 17 19.25V7.25C17 5.975 16.025 5 14.75 5ZM11.75 14H9.5V15.5C9.5 15.95 9.2 16.25 8.75 16.25C8.3 16.25 8 15.95 8 15.5V14H5.75C5.3 14 5 13.7 5 13.25C5 12.8 5.3 12.5 5.75 12.5H8V11C8 10.55 8.3 10.25 8.75 10.25C9.2 10.25 9.5 10.55 9.5 11V12.5H11.75C12.2 12.5 12.5 12.8 12.5 13.25C12.5 13.7 12.2 14 11.75 14Z'
        fill='#DC143C'
      />
      <path
        d='M19.25 0.5H7.25C5.975 0.5 5 1.475 5 2.75V4.25H14.75C16.4 4.25 17.75 5.6 17.75 7.25V17H19.25C20.525 17 21.5 16.025 21.5 14.75V2.75C21.5 1.475 20.525 0.5 19.25 0.5Z'
        fill='#FCECEF'
      />
    </svg>
  );
}
