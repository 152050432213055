import Icon from '@ant-design/icons';

const CoinIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        fill='none'
        viewBox='0 0 20 20'>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M8.325 14.984a6.658 6.658 0 100-13.317 6.658 6.658 0 000 13.317zM10.816 16.566a4.12 4.12 0 003.375 1.75 4.13 4.13 0 004.125-4.125 4.134 4.134 0 00-1.725-3.358'></path>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M6.667 9.499c0 .642.5 1.167 1.108 1.167h1.25c.534 0 .967-.459.967-1.017 0-.608-.267-.825-.658-.967l-2-.7c-.4-.141-.667-.358-.667-.966 0-.559.433-1.017.967-1.017h1.25C9.5 6.007 10 6.524 10 7.166M8.333 10.708v.617M8.333 5.342v.65'></path>
      </svg>
    )}
    {...props}
  />
);

export default CoinIcon;
