import React, { useEffect, useState } from 'react';
import './index.scss';
import ReactPaginate from 'react-paginate';
import { ChevronLeft, ChevronRight } from 'images';
const PaginationTable = ({
  itemsPerPage,
  total,
  currentPageTable,
  disabledLinkClassName,
  previousIcon,
  nextIcon,
  pageTransactions,
}) => {
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    setPageCount(Math.ceil(total / itemsPerPage));
  }, [itemOffset, itemsPerPage, total, pageTransactions]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % total;
    setItemOffset(newOffset);
    currentPageTable(event.selected + 1);
  };

  return (
    <ReactPaginate
      breakLabel='...'
      forcePage={pageTransactions - 1}
      nextLabel={nextIcon ?? <ChevronRight />}
      onPageChange={handlePageClick}
      pageRangeDisplayed={3}
      pageCount={pageCount}
      previousLabel={previousIcon ?? <ChevronLeft />}
      renderOnZeroPageCount={null}
      className='pagination-table'
      disabledLinkClassName={disabledLinkClassName}
    />
  );
};

export default PaginationTable;
