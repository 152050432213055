import { Link } from 'react-router-dom';
import { ImgCustom } from '..';
import { LOGO_COCO_FANS } from '../../utils/constant';
import './Footer.scss';

const Footer = ({ isClass = false }) => {
  return isClass ? (
    <Link to='/' reloadDocument>
      <ImgCustom src={LOGO_COCO_FANS} height={32} width={96} alt='logo' />
    </Link>
  ) : (
    <Link to='/' reloadDocument>
      <ImgCustom src={LOGO_COCO_FANS} height={32} width={96} alt='logo' />
    </Link>
  );
};

export default Footer;
