import Icon from '@ant-design/icons';

const FileZipUploadIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='27'
        height='25'
        fill='none'
        viewBox='0 0 27 25'>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M14.44 12.2l-3.293 3.293a4.66 4.66 0 000 6.6 4.66 4.66 0 006.6 0l5.186-5.186c3.64-3.64 3.64-9.56 0-13.2-3.64-3.64-9.56-3.64-13.2 0L4.08 9.36c-3.12 3.12-3.12 8.187 0 11.32'></path>
      </svg>
    )}
    {...props}
  />
);

export default FileZipUploadIcon;
