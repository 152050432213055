import { pushNotify } from 'components/Notification';
import { ClearInputIcon, TrashIcon } from 'images';
import CoinPackageBig from 'images/CoinPackageBig.svg';
import CoinPackageSmall from 'images/CoinPackageSmall.svg';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  applyVoucher,
  getFanRequestPoint,
  paymentVendo,
  postPaymentPonitVendo,
} from 'store/actions/payment';
import { PAYMENT_CONST } from 'utils';
import Storage from 'utils/storage';
import { WrapperConfirmStyled } from './styled';
import { useEffect, useMemo, useState } from 'react';
import { PopupLoading } from 'components';
import { toast } from 'react-toastify';
import PopupPhone from 'components/Popup/popupPhone';
import { checkPoint, sendVerifyPhone } from 'store/actions/payment';
import PopupPhoneVerify from 'components/Popup/popupPhoneVerify';
import { getListCountry } from 'store/actions/users';

const StepVendo2 = ({
  dataVendo,
  setIsShowPopupCancelPay,
  setStep,
  cardPayment,
  setClearCards,
  isStepDetail,
  setIsStepDetail,
  dataVoucher,
  setDataVoucher,
  setSearchVoucher,
  searchVoucher,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [isPhone, setIsPhone] = useState(false);
  const [isPopup, setIsPopup] = useState(false);
  const [counter, setCounter] = useState(120);

  const [phone, setPhone] = useState(null);
  const [countryCode, setCountryCode] = useState(null);

  const { loading, dataSend } = useSelector((state) => state.payment);
  const priceVoucher = useMemo(() => {
    if (dataVoucher?.bonus && dataVendo?.point) {
      return (+dataVoucher?.bonus * +dataVendo?.point) / 100;
    }
    return 0;
  }, [dataVoucher?.bonus, dataVendo?.point]);

  const paymentSuccess = (data) => {
    if (data?.status === 5) {
      Storage.set(PAYMENT_CONST.CREDIT_CARD_ID, cardPayment?.id);
      window.location.href = data?.verification_url;
    }
  };

  useEffect(() => {
    const language = i18n.language === 'jp' ? 'ja' : i18n.language;
    dispatch(getListCountry(language));
  }, [i18n.language]);

  const handlePayment = (code) => {
    dispatch(
      paymentVendo(
        {
          code,
          credit_card_id: dataVendo?.settingMethod === 2 ? null : cardPayment?.id,
          payment_gateway: 2
          //payment_gateway: dataVendo?.settingMethod || 2
        },
        true,
        paymentSuccess
      )
    );
  };

  const handleSuccessRequest = (code) => {
    let dataBody = {
      point: dataVendo?.point,
      price: dataVendo?.price,
      payment_method: 1,
      voucher: dataVoucher?.code || null,
      code,
    };
    if (dataVendo?.package_payment_id) {
      dataBody = {
        package_payment_id: dataVendo?.package_payment_id,
        payment_method: 1,
        voucher: dataVoucher?.code || null,
        code,
      };
    }
    dispatch(
      postPaymentPonitVendo(dataBody, (codeVendo) => {
        Storage.set(PAYMENT_CONST.CODE_VENDO, codeVendo);
        handlePayment(codeVendo);
      })
    );
  };

  const handleSubmitPayment = () => {
    dispatch(
      getFanRequestPoint(
        { payment_method: 1, point: dataVendo?.point, phone_verify_id: dataSend?.id },
        handleSuccessRequest,
        () => {
          var condition = navigator.onLine;
          if (!condition) {
            toast.error(t('Creator.Message.disconnect'), {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
              hideProgressBar: true,
              theme: 'colored',
              className: 'display-linebreak',
              enableMultiContainer: true,
              icon: ({ theme, type }) => (
                <svg
                  width={18}
                  height={18}
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z'
                    stroke='#DC143C'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M11.25 6.75L6.75 11.25'
                    stroke='#DC143C'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M11.25 11.25L6.75 6.75'
                    stroke='#DC143C'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              ),
            });
          }
        }
      )
    );
  };

  const convertNameTypeCard = (item) => {
    let type = '';
    const cardType = item?.card_type;
    if (item?.card_type === 'VISA') {
      type = 'Visa';
    } else if (cardType === 'MASTER_CARD') {
      type = 'MasterCard';
    } else if (cardType === 'JCB') {
      type = 'JCB';
    }
    return `${type}*${item?.card_number?.slice(-4)}`;
  };

  const handleCheckApplyVoucher = (data) => {
    if (data?.code) {
      setDataVoucher(data);
      setSearchVoucher('');
      return pushNotify('success', `${t('Purchase.payment.vendo.checkVoucherSuccess')}`);
    } else {
      return pushNotify('error', `${t(data?.message)}`);
    }
  };

  const handleApplyVoucher = () => {
    if (searchVoucher?.trim()) {
      dispatch(applyVoucher(searchVoucher?.trim(), handleCheckApplyVoucher));
    }
  };

  const handalSubmit = (e) => {
    const data = {
      ...e,
      country_code_number: e?.country_code_number?.replace(/\(|\)/g, "") || '+81'
    }
    dispatch(
      sendVerifyPhone({
        data: data,
        onSuccess: (res) => {
          if (res?.success) {
            setIsPhone(false);
            setIsPopup(true);
          }
        },
      })
    );
  };

  return (
    <>
      {loading && <PopupLoading className={'popup-loading'} />}
      {isPhone && (
        <PopupPhone
          setIsPhone={setIsPhone}
          setIsPopup={setIsPopup}
          handalSubmit={handalSubmit}
          setCountryCode={setCountryCode}
          setPhone={setPhone}
        />
      )}
      {isPopup && (
        <PopupPhoneVerify
          counter={counter}
          setCounter={setCounter}
          setIsPopup={setIsPopup}
          handleSubmitPayment={handleSubmitPayment}
          phone={phone}
          countryCode={countryCode}
        />
      )}

      <WrapperConfirmStyled>
        <div className='--item fixed-point-item'>
          <div className='--point'>
            <img
              src={dataVendo?.point < 100000 ? CoinPackageSmall : CoinPackageBig}
              alt='coin package'
              className='coin-package-img'
            />
            <CurrencyFormat
              className='point-number'
              thousandSeparator={true}
              value={dataVendo?.point}
              displayType='text'
            />
            <div className='point-text'>{t('Purchase.point')}</div>
          </div>

          <div className={`${Number(dataVendo?.price) > 1000000 ? 'long-price' : ''} --btn`}>
            ￥
            <CurrencyFormat
              thousandSeparator={true}
              value={Number(dataVendo?.price)}
              displayType='text'
            />
          </div>
        </div>
        <div className='block-info mt-24'>
          <div className='block-info-item'>
            <div className='block-info-item-first'>{t('Purchase.payment.vendo.total')}:</div>
            <CurrencyFormat
              className='block-info-item-last'
              thousandSeparator={true}
              value={dataVendo?.price}
              displayType='text'
              prefix='￥'
            />
          </div>
        </div>
        {isStepDetail ? (
          <>
            {/* 20/03 */}
            {/* <div className='hyphen-payment'></div> */}
            <div className='block-info'>
              {/* <div className='block-info-item'>
                <div className='block-info-item-first'>{t('Common.voucher')}:</div>
                <div className='block-info-item-last d-row'>
                  <div className='search-voucher'>
                    <input
                      style={{
                        color: `${dataVoucher ? '#bebebe' : '#1A1A1A'}`,
                        background: `${dataVoucher ? '#EFEFEF' : '#FFF'}`,
                        fontWeight: '400',
                      }}
                      className={`search-voucher-input ${
                        searchVoucher?.trim() ? 'haveData' : ''
                      }`}
                      disabled={dataVoucher}
                      placeholder={t('Purchase.payment.vendo.enterVoucherCode')}
                      value={dataVoucher ? dataVoucher?.code : searchVoucher}
                      onChange={(e) => {
                        setSearchVoucher(e.target.value);
                      }}
                    />
                    {!!searchVoucher?.trim() && (
                      <ClearInputIcon
                        className='clear-icon-voucher'
                        onClick={() => {
                          setSearchVoucher('');
                        }}
                      />
                    )}
                  </div>
                  <button
                    disabled={dataVoucher}
                    onClick={handleApplyVoucher}
                    className={`btn-apply-voucher ${dataVoucher ? 'disable' : ''}`}>
                    {t('Common.apply')}
                  </button>
                </div>
              </div>
              {dataVoucher && (
                <div className='block-info-item mt-4'>
                  <div className='block-info-item-first'>{t('Common.lableVoucher')}:</div>
                  <div className='block-info-item-last d-row'>
                    <CurrencyFormat
                      className='price-voucher'
                      thousandSeparator={true}
                      value={priceVoucher}
                      displayType='text'
                    />
                    <div
                      style={{
                        marginLeft: '-15px',
                        color: '#52C41A',
                        fontWeight: 'bold',
                        fontSize: '14px',
                      }}>
                      Pt
                    </div>
                    <TrashIcon
                      className='delete-voucher-icon'
                      color='#666565'
                      onClick={() => {
                        setDataVoucher(null);
                      }}
                    />
                  </div>
                </div>
              )} */}
            </div>
          </>
        ) : (
          <>
            {dataVoucher && (
              <>
                <div className='hyphen-payment'></div>
                <div className='block-info'>
                  <div className='block-info-item mt-4'>
                    <div className='block-info-item-first'>{t('Common.lableVoucher')}:</div>
                    <div className='block-info-item-last d-row'>
                      <CurrencyFormat
                        className=''
                        thousandSeparator={true}
                        value={priceVoucher}
                        displayType='text'
                      />
                      <div
                        style={{
                          marginLeft: '-15px',
                          fontSize: '14px',
                        }}>
                        Pt
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
        {dataVendo?.settingMethod !== 2 && (
          <>
            <div className='hyphen-payment'></div>
            <div className='block-info'>
              <div className='block-info-item'>
                <div className='block-info-item-first'>
                  {t('Purchase.payment.vendo.paymentInformation')}:
                </div>
                <div className='block-info-item-last'>
                  {convertNameTypeCard(cardPayment)}{' '}
                  {isStepDetail && (
                    <span
                      className='btn-change'
                      onClick={() => {
                        setClearCards();
                        setStep(1);
                      }}>
                      {t('Purchase.payment.vendo.change')}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        <div className='hyphen-payment mb-24'></div>
        <div className='block-item sub-col'>
          <button
            className='btn btn-submit'
            onClick={() => {
              if (!isStepDetail) {
                setIsStepDetail(false);
              } else {
                const data = {
                  point: dataVendo?.point,
                  method_payment: 1,
                };
                dispatch(
                  checkPoint({
                    queries: data,
                    onSuccess: (res) => {
                      if (res?.data) {
                        setIsPhone(true);
                      } else {
                        handleSubmitPayment();
                      }
                    },
                  })
                );
              }
            }}>
            {t('Common.confirmPay')}
          </button>
          <button
            className='btn btn-cancel'
            onClick={() => {
              if (isStepDetail) {
                setIsShowPopupCancelPay(true);
              } else {
                setIsStepDetail(true);
              }
            }}>
            {t('Common.buttonCancel')}
          </button>
        </div>
      </WrapperConfirmStyled>
    </>
  );
};

export default StepVendo2;
