import Icon from '@ant-design/icons';

const EyeIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='14'
        height='9'
        fill='none'
        viewBox='0 0 14 9'
      >
        <path
          fill='#B1B6BB'
          d='M7 .08C3.318.08.333 4.04.333 4.04S3.318 8.003 7 8.003c3.681 0 6.666-3.961 6.666-3.961S10.681.08 7 .08zm0 7.053A3.092 3.092 0 116.99.95 3.092 3.092 0 017 7.133z'
        ></path>
        <path
          fill='#B1B6BB'
          d='M7 5.638a1.597 1.597 0 100-3.195 1.597 1.597 0 000 3.195z'
        ></path>
      </svg>
    )}
    {...props}
  />
);

export default EyeIcon;
