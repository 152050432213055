import { UploadFileIcon } from 'images';
import ThumbnailImg from 'images/thumbnail.jpg';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  acceptedImageTypes,
  acceptedVideoTypes,
  decryptPath,
  failFileThan10Gb,
  failImgInvalid,
  failImgThan10,
  failImgThan10Mb,
  failVideoInvalid,
  failVideoLonger3Minutes,
  failVideoThan1,
  failZipThan1,
  getDurationVideo,
  IMG_NO_WATER_MARK,
  linkS3Video,
  linkS3VideoM3U8,
  TEN_GB,
  TEN_MB,
} from 'utils/utils';
import {
  ControlBar,
  CurrentTimeDisplay,
  Player,
  TimeDivider,
  VolumeMenuButton,
} from 'video-react';
import 'video-react/dist/video-react.css';
import { ImgCustom } from 'components';
import { BTN_REMOVE } from 'utils/constant';
import VideoCustomDetail from 'pages/creator/layout/recording/VideoCustomDetail';
import '../content.scss';
import UploadMultiImageIcon from 'images/UploadMultiImage';
import ZipFileIconV2 from 'images/ZipFileIconV2';
import UploadVideoIcon from 'images/UploadVideoIcon';
import UploadImageIcon from 'images/UploadImageIcon';
import { BlueImg } from '../styled';
import CurrencyFormat from 'react-currency-format';
import { getInfo } from 'react-mediainfo';
import {
  Array10Item,
  LEN_LIST_IMG,
  compareTimeValid,
  convertTimeToSecond,
  defaultDuration,
  getSecondsFromHHMMSS,
  toHHMMSS,
} from '../help';
import moment from 'moment';
import { InputSampleImg } from './inputSampleImg';

const ListImgVideo = ({
  main_video,
  trailer_video,
  thumbnail,
  list_img,
  file_zip,
  initData = [],
  setDurationVideoTrailer,
  errorTimeDurationInput,
  setErrorTimeDurationInput,
  isErrorDurationInput,
  isPostApprovedByAdmin,
  isPostRejectByAdmin,
  isPostWaitApprove,
}) => {
  const [videoUpload, setVideoUpload] = useState({ img: [], files: null });
  const [trailerUpload, setTrailerUpload] = useState({ img: [], files: null });
  const [thumbnailUpload, setThumbnailUpload] = useState({ img: [], files: null });
  const [fileZip, setFileZip] = useState({ img: [], files: null, file_name: '' });
  const { t } = useTranslation();
  const [checkVideoUpload, setCheckVideoUpload] = useState(false);
  const [checkTrailerUpload, setCheckTrailerUpload] = useState(false);
  const [checkThumbnailUpload, setCheckThumbnailUpload] = useState(false);
  const [checkZiplUpload, setCheckZiplUpload] = useState(false);
  const [checkListImg, setCheckListImg] = useState({ img: [], files: [] });
  const [checkListImgPublicFlag0, setCheckListImgPublicFlag0] = useState({
    img: [],
    files: [],
  });

  const [valueTimeDurationInput, setValueTimeDurationInput] = useState(defaultDuration);

  const handleOnChangeDurationInput = (data) => {
    setValueTimeDurationInput(data.formattedValue);
  };
  const handleOnBlurDurationInput = (event) => {
    const value = event.target.value;
    const timeValid = moment(valueTimeDurationInput, 'mm:ss').isValid();
    if (timeValid) {
      const seconds = Math.max(0, getSecondsFromHHMMSS(value));
      const time = toHHMMSS(seconds);
      setValueTimeDurationInput(time);
      const compareTime = compareTimeValid(time);
      setErrorTimeDurationInput(compareTime);
    } else {
      setValueTimeDurationInput(defaultDuration);
      const compareTime = compareTimeValid(defaultDuration);
      setErrorTimeDurationInput(compareTime);
    }
  };

  const getDurationFromInput = useMemo(() => {
    return convertTimeToSecond(valueTimeDurationInput);
  }, [valueTimeDurationInput]);

  useEffect(() => {
    if (!trailerUpload?.files) {
      setDurationVideoTrailer(getDurationFromInput);
    }
  }, [getDurationFromInput, setDurationVideoTrailer, trailerUpload?.files]);

  // START IMG VIDEO
  useEffect(() => {
    if (initData.length > 0) {
      initData.forEach(({ url, type, file_name, public_flag, duration }) => {
        if (type === 2) {
          setVideoUpload({ img: url, files: url });
          public_flag === 1 && setCheckVideoUpload(true);
        }
        if (type === 3) {
          setTrailerUpload({ img: url, files: url });
          public_flag === 1 && setCheckTrailerUpload(true);
          setDurationVideoTrailer(duration);
        }
        if (type === 4) {
          setThumbnailUpload({ img: url, files: url });
          public_flag === 1 && setCheckThumbnailUpload(true);
        }
        if (type === 5) {
          setFileZip({ img: url, files: url, file_name: file_name });
          public_flag === 1 && setCheckZiplUpload(true);
        }
      });
      const listImgInit = initData?.filter((item) => item.type === 1);
      const cloneListImgInit = listImgInit.slice();
      const listImg = [];
      const listFiles = Array10Item.map((i) => {
        if (cloneListImgInit[i]) {
          const url = cloneListImgInit[i]?.url;
          listImg.push({
            data: url,
            indexFile: i,
            publicFlag: cloneListImgInit[i]?.public_flag,
          });
          return url;
        }
        return null;
      });
      setCheckListImg({
        files: listFiles,
        img: listImg,
      });
      setCheckListImgPublicFlag0({
        files: cloneListImgInit
          ?.filter((item) => item.public_flag !== 1)
          .map((item) => item?.url),
        img: cloneListImgInit
          ?.filter((item) => item.public_flag !== 1)
          .map((item) => item?.url),
      });
    }
  }, [initData, setVideoUpload, setTrailerUpload, setThumbnailUpload, setFileZip]);

  useEffect(() => {
    if (checkListImg.img?.length) {
      const newArrImg = [];
      checkListImg.files.forEach((ele) => {
        if (typeof ele !== 'string' && ele != null) {
          newArrImg.push(URL.createObjectURL(ele));
        } else {
          newArrImg.push(ele);
        }
      });
      setCheckListImg({ img: checkListImg.img, files: newArrImg });
      const newList = [...checkListImg?.img];
      list_img(newList);
    } else {
      list_img([...checkListImg?.img]);
    }
  }, [checkListImg.img]);

  useEffect(() => {
    if (thumbnailUpload.img?.length) {
      const newArrVideo = [];
      if (typeof thumbnailUpload.img[0] !== 'string' && thumbnailUpload.img[0] !== undefined) {
        newArrVideo.push(URL.createObjectURL(thumbnailUpload.img[0]));
        setThumbnailUpload({ img: thumbnailUpload.img, files: newArrVideo });
        thumbnail(thumbnailUpload.img[0]);
      } else {
        setThumbnailUpload({ img: thumbnailUpload.img, files: thumbnailUpload.img });
        thumbnail(thumbnailUpload.img);
      }
    }
  }, [thumbnail, thumbnailUpload.img]);

  useEffect(() => {
    if (fileZip.img?.length) {
      const newArrVideo = [];
      if (typeof fileZip.img[0] !== 'string' && fileZip.img[0] !== undefined) {
        newArrVideo.push(URL.createObjectURL(fileZip.img[0]));
        setFileZip({ img: fileZip.img, files: newArrVideo });
        file_zip(fileZip.img[0]);
      } else {
        setFileZip({ img: fileZip.img, files: fileZip.img, file_name: fileZip.file_name });
        file_zip(fileZip.img);
      }
    }
  }, [fileZip.file_name, fileZip.img]);

  useEffect(() => {
    if (videoUpload.img?.length) {
      const newArrVideo = [];
      if (typeof videoUpload.img[0] !== 'string' && videoUpload.img[0] !== undefined) {
        newArrVideo.push(URL.createObjectURL(videoUpload.img[0]));
        setVideoUpload({ img: videoUpload.img, files: newArrVideo });
        main_video(videoUpload.img[0]);
      } else {
        setVideoUpload({ img: videoUpload.img, files: videoUpload.img });
        main_video(videoUpload.img);
      }
    }
  }, [videoUpload.img]);

  useEffect(() => {
    if (trailerUpload.img?.length) {
      const newArrVideo = [];
      if (typeof trailerUpload.img[0] !== 'string' && trailerUpload.img[0] !== undefined) {
        newArrVideo.push(URL.createObjectURL(trailerUpload.img[0]));
        setTrailerUpload({ img: trailerUpload.img, files: newArrVideo });
        trailer_video(trailerUpload.img[0]);
      } else {
        setTrailerUpload({ img: trailerUpload.img, files: trailerUpload.img });
        trailer_video(trailerUpload.img);
      }
    }
  }, [trailerUpload.img, trailer_video]);

  const handleInputVideoOrZip = async (e) => {
    let sizeImgBiggerFiveGB = false;
    sizeImgBiggerFiveGB = e.target.files[0].size > TEN_GB ? true : false;

    const type = e.target.files[0]['type'];
    const typeFileVideo = type.includes('video');
    const checkTypes = typeFileVideo;

    const checkLength = videoUpload.img.length + e.target.files.length;
    if (e.target.files[0] && checkTypes && !sizeImgBiggerFiveGB && checkLength <= 1) {
      if (typeFileVideo) {
        if (e.target.files[0]['type'] === 'video/x-ms-wmv') {
          const mediaInfoLib = await getInfo(e.target.files[0]);
          const durationVideo = +mediaInfoLib?.media?.track[0]?.Duration;
          if (durationVideo < 3 * 60) {
            setVideoUpload({ img: [], files: null });
            // setDurationVideo(0);
            failVideoLonger3Minutes();
            return true;
          } else {
            setVideoUpload((prev) => ({ ...prev, img: e.target.files }));
            // setDurationVideo(durationVideo);
          }
        } else {
          getDurationVideo(e.target.files[0], (duration) => {
            if (duration < 3 * 60) {
              setVideoUpload({ img: [], files: null });
              // setDurationVideo(0);
              failVideoLonger3Minutes();
              return true;
            } else {
              setVideoUpload((prev) => ({ ...prev, img: e.target.files }));
              // setDurationVideo(duration);
            }
          });
        }
      } else {
        setFileZip((prev) => ({ ...prev, img: e.target.files }));
      }
    } else {
      if (!checkTypes) failVideoInvalid();
      if (checkLength > 1) typeFileVideo ? failVideoThan1() : failZipThan1();
      sizeImgBiggerFiveGB && failFileThan10Gb();
    }
  };

  const handleInputTrailer = (e) => {
    let sizeImgBiggerFiveGB = false;
    sizeImgBiggerFiveGB = e.target.files[0].size > TEN_GB ? true : false;

    const checkTypes = e.target.files[0]['type'].includes('video');
    const checkLength = trailerUpload.img.length + e.target.files.length;
    if (e.target.files[0] && checkTypes && !sizeImgBiggerFiveGB && checkLength <= 1) {
      getDurationVideo(e.target.files[0], (duration) => {
        setDurationVideoTrailer(duration);
      });
      setTrailerUpload((prev) => ({ ...prev, img: e.target.files }));
    } else {
      sizeImgBiggerFiveGB && failFileThan10Gb();
      !checkTypes && failVideoInvalid();
      checkLength > 1 && failVideoThan1();
    }
  };

  const handleInputThumbnail = (e) => {
    let sizeImgBiggerTenMb = false;
    sizeImgBiggerTenMb = e.target.files[0].size > TEN_MB ? true : false;
    const checkTypes = acceptedImageTypes.includes(e.target.files[0]['type']);
    if (e.target.files[0] && checkTypes && !sizeImgBiggerTenMb) {
      setThumbnailUpload((prev) => ({ ...prev, img: e.target.files }));
    } else {
      sizeImgBiggerTenMb && failImgThan10Mb();
      !checkTypes && failImgInvalid();
    }
  };

  const handleInputListImg = (e, indexFile) => {
    let sizeImgBiggerTenMb = false;
    let checkImg = true;
    const checkLength = checkListImg.img.length + e.target.files.length;
    for (var i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size > TEN_MB) sizeImgBiggerTenMb = true;
      if (!acceptedImageTypes.includes(e.target.files[i]['type'])) checkImg = false;
    }
    if (e.target.files[0] && checkImg && !sizeImgBiggerTenMb && checkLength <= 10) {
      // setListImg((prev) => ({ ...prev, img: [...listImg.img, ...e.target.files] }));
      let listImgClone = JSON.parse(JSON.stringify(checkListImg));
      listImgClone.files[indexFile] = e.target.files[0];
      listImgClone.img = [...checkListImg.img, { data: e.target.files[0], indexFile }];
      setCheckListImg(listImgClone);
    } else {
      sizeImgBiggerTenMb && failImgThan10Mb();
      checkLength > 10 && failImgThan10();
      !checkImg && failImgInvalid();
    }
  };
  const handleRemoveImg = (i) => {
    const { img, files } = checkListImg;
    img.length === 0 && setCheckListImg({ img: [], files: [] });
    setCheckListImg((prev) => ({
      ...prev,
      img: img.filter((item) => item?.indexFile !== i),
      files: files.map((item, index) => {
        if (index === i) {
          return null;
        }
        return item;
      }),
    }));
  };

  // END IMG VIDEO
  return (
    <>
      <div className='box-img-video'>
        <div className='item-wrap'>
          <div className='item-label'>{t('Creator.PostArticle.labelThumbnail')}</div>
          {checkThumbnailUpload ? (
            <div
              className={`main-thumbnail ${
                (typeof thumbnailUpload.img === 'string' ||
                  typeof thumbnailUpload?.img?.[0] === 'object') &&
                'bg_thumbnail'
              }`}>
              {thumbnailUpload.img.length > 0 ? (
                <BlueImg
                  url={`${
                    typeof thumbnailUpload.files === 'string' &&
                    typeof thumbnailUpload.img === 'string'
                      ? `${decryptPath(thumbnailUpload.files)}`
                      : decryptPath(thumbnailUpload?.files)
                  }`}>
                  <div className='bg-img bd-8'></div>
                  <ImgCustom
                    src={
                      typeof thumbnailUpload.files === 'string' &&
                      typeof thumbnailUpload.img === 'string'
                        ? `${thumbnailUpload.files}`
                        : thumbnailUpload.files
                    }
                    className='cover-img'
                  />
                </BlueImg>
              ) : (
                <div className='upload-file-btn'>
                  <UploadImageIcon className='icon' />
                  <span className='text'>
                    {t('ValidateMsg.checkThumbnailUploadMultiLine')}
                  </span>
                </div>
              )}
              <input
                type='file'
                name='main_thumbnail'
                disabled={isPostWaitApprove}
                accept={acceptedImageTypes.map((item) => item).join(',')}
                onChange={handleInputThumbnail}
                onClick={(e) => (e.target.value = null)}
              />
              {thumbnailUpload.img.length > 0 && !isPostWaitApprove && (
                <img
                  className='zindex20 btn-remove'
                  src={BTN_REMOVE}
                  onClick={() => {
                    setThumbnailUpload({ img: [], files: ThumbnailImg });
                    thumbnail();
                  }}
                  alt='icon-remove'
                />
              )}
            </div>
          ) : (
            <div className={`main-thumbnail`}>
              <ImgCustom src={IMG_NO_WATER_MARK} className='img_thumbnail' />
            </div>
          )}
        </div>
        <div className='item-wrap'>
          <div className='item-label'>{t('Creator.PostArticle.labelSampleVideo')}</div>
          {checkTrailerUpload ? (
            <div className='trailer-video box-item '>
              {trailerUpload.img.length > 0 ? (
                <Player poster='' className='video'>
                  <source
                    src={
                      typeof trailerUpload.files === 'string' &&
                      typeof trailerUpload.img === 'string'
                        ? `${linkS3Video}${decryptPath(trailerUpload.files) + '#t=0.1'}`
                        : trailerUpload.files
                    }
                  />
                  <ControlBar>
                    <CurrentTimeDisplay order={4.1} />
                    <TimeDivider order={4.2} />
                    <VolumeMenuButton />
                  </ControlBar>
                </Player>
              ) : (
                <div className='upload-file-btn'>
                  <UploadVideoIcon className='icon' />
                  <span className='text'>
                    {t('ValidateMsg.checkTrailerVideoUploadMultiLine')}
                  </span>
                </div>
              )}
              <input
                type='file'
                name='trailer_video'
                onChange={handleInputTrailer}
                disabled={isPostWaitApprove}
                accept={acceptedVideoTypes.map((item) => item).join(',')}
                onClick={(e) => (e.target.value = null)}
              />

              {trailerUpload.img.length > 0 && !isPostWaitApprove && (
                <img
                  className='zindex20 btn-remove'
                  src={BTN_REMOVE}
                  onClick={() => {
                    setTrailerUpload({ img: [], files: null });
                    setDurationVideoTrailer(getDurationFromInput);
                    trailer_video();
                  }}
                  alt='icon-remove'
                />
              )}
            </div>
          ) : (
            <div className='trailer-video box-item center'>
              <ImgCustom src={IMG_NO_WATER_MARK} />
            </div>
          )}
        </div>
        <div className='list-video'>
          <div className='item-label'>{t('Creator.PostArticle.labelMainVideo')}</div>
          <div
            className={`main-video box-item ${fileZip.img.length > 0 && 'file-zip-height'}`}>
            {checkVideoUpload ? (
              <>
                {videoUpload.img.length > 0 && videoUpload.files?.indexOf('.m3u8') === -1 ? (
                  <Player poster='' className='video'>
                    <source
                      src={
                        typeof videoUpload.files === 'string' &&
                        typeof videoUpload.img === 'string'
                          ? `${linkS3Video}${decryptPath(videoUpload.files)}`
                          : videoUpload.files
                      }
                    />
                    <ControlBar>
                      <CurrentTimeDisplay order={4.1} />
                      <TimeDivider order={4.2} />
                      <VolumeMenuButton />
                    </ControlBar>
                  </Player>
                ) : videoUpload.img.length > 0 &&
                  videoUpload.files?.indexOf('.m3u8') !== -1 ? (
                  <VideoCustomDetail
                    src={`${linkS3VideoM3U8}${decryptPath(videoUpload.files) + '#t=0.1'}`}
                    className='edit-post-content'
                    isDetailPost
                    isLiveArchive
                    handleNavigate={() => {}}
                  />
                ) : (
                  <div className='upload-file-btn'>
                    <UploadFileIcon className='icon' />
                    <span className='text'>{t('ValidateMsg.checkVideoMainVideoPost')}</span>
                  </div>
                )}
                <input
                  type='file'
                  name='main_video'
                  disabled={!isPostRejectByAdmin}
                  onChange={handleInputVideoOrZip}
                  accept={acceptedVideoTypes.map((item) => item).join(',')}
                  onClick={(e) => (e.target.value = null)}
                />
                {videoUpload.img.length > 0 && isPostRejectByAdmin && (
                  <img
                    className='zindex20 btn-remove'
                    src={BTN_REMOVE}
                    onClick={() => {
                      setVideoUpload({ img: [], files: null });
                      main_video();
                    }}
                    alt='icon-remove'
                  />
                )}
              </>
            ) : (
              <div className={`main-thumbnail`}>
                <ImgCustom src={IMG_NO_WATER_MARK} className='img_thumbnail' />
              </div>
            )}
          </div>
        </div>
        <div className='setting-time'>
          <div className='setting-time_head'>
            <span className='setting-time-title'>
              {t('Creator.PostArticle.titleSettingTime')}
            </span>
            <span className='setting-time-input-time'>
              <CurrencyFormat
                className={`time-split-video ${isErrorDurationInput ? 'error' : ''}`}
                format='##:##'
                placeholder='mm:ss'
                value={valueTimeDurationInput}
                onValueChange={handleOnChangeDurationInput}
                onBlur={handleOnBlurDurationInput}
                disabled={!!trailerUpload?.files}
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault();
                }}
              />
            </span>
          </div>
          <div className='setting-time_info'>
            <div className='setting-time_info_item focus-red'>
              {t('Creator.PostArticle.infoSetting.info1')}
            </div>
            <div className='setting-time_info_item'>
              {t('Creator.PostArticle.infoSetting.info2')}
            </div>
            <div className='setting-time_info_item'>
              {t('Creator.PostArticle.infoSetting.info3')}
            </div>
            {isErrorDurationInput && (
              <div className='setting-time_info_item focus-red'>
                {t(errorTimeDurationInput)}
              </div>
            )}
          </div>
          <div className='setting-time_errors'></div>
        </div>
        <div className='list-img-sample'>
          <div className='item-label'>{t('Creator.PostArticle.labelSampleImg')}</div>
          <div className='list-img'>
            {Array10Item.map((item) => {
              if (checkListImg?.files[item]) {
                if (checkListImg?.img[item]?.publicFlag === 0) {
                  return (
                    <div className='img-des box-item' key={item}>
                      <ImgCustom src={IMG_NO_WATER_MARK} className='img_thumbnail' />
                    </div>
                  );
                }
                return (
                  <>
                    <div className='img-des box-item' key={item}>
                      <BlueImg
                        url={`${
                          typeof checkListImg.img[item] === 'string' &&
                          typeof item === 'string'
                            ? `${decryptPath(checkListImg?.files[item])}`
                            : decryptPath(checkListImg?.files[item])
                        }`}>
                        <div className='bg-img'></div>
                        <ImgCustom
                          src={
                            typeof checkListImg.img[item] === 'string' &&
                            typeof item === 'string'
                              ? `${checkListImg?.files[item]}`
                              : `${checkListImg?.files[item]}`
                          }
                          className='cover-img'
                        />
                        {!isPostWaitApprove && (
                          <img
                            className='zindex20 btn-remove'
                            src={BTN_REMOVE}
                            onClick={() => {
                              handleRemoveImg(item);
                            }}
                            alt='icon-remove'
                          />
                        )}
                      </BlueImg>
                    </div>
                  </>
                );
              }
              return (
                <InputSampleImg
                  key={item}
                  item={item}
                  isPostWaitApprove={isPostWaitApprove}
                  handleInputListImg={handleInputListImg}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(ListImgVideo);
