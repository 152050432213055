import UploadMultiImageIcon from 'images/UploadMultiImage';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { acceptedImageTypes } from 'utils';

export const InputSampleImg = ({ indexFile, handleInputListImg }) => {
  const { t } = useTranslation();
  const refImg = useRef();
  return (
    <div className={`img-des box-item add-input`}>
      <div
        className='add-icon add-icon-cursor'
        onClick={() => {
          refImg.current?.click();
        }}>
        <UploadMultiImageIcon width={24} height={24} />
        <span className='text'>{t('ValidateMsg.checkMultiImage')}</span>
      </div>
      <input
        style={{ display: 'none' }}
        ref={refImg}
        type='file'
        name='list_img'
        accept={acceptedImageTypes.map((item) => item).join(',')}
        className='add-multi'
        onChange={(e) => {
          handleInputListImg(e, indexFile);
        }}
        onClick={(e) => (e.target.value = null)}
      />
    </div>
  );
};
