/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';

const initialState = {
  status: null,
  loading: false,
  error: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_LIST_POINT_ICON_DONATE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_LIST_POINT_ICON_DONATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case TYPES.GET_LIST_POINT_ICON_DONATE_FAILED:
      return {
        ...state,
        loading: true,
        status: action.type,
      };

    default:
      return state;
  }
};
