import moment from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { useClickOutSize } from 'hooks/v2/clickOutSide';
const ReactCalendar = (props) => {

  const [refOutSide] = useClickOutSize(props?.toggleCalendar)
  const { i18n } = useTranslation();
  const formatDate = props?.jpFormat
    ? {}
    : {
        formatDay: (locale, date) => moment(date).format('DD'),
        formatMonth: (locale, date) =>
          props?.formatMMM ? moment(date).format('MMM') : moment(date).format('MM'),
        formatYear: (locale, date) => moment(date).format('YYYY'),
      };
  return (
    <>
      <Calendar
        {...props}
        inputRef={refOutSide}
        defaultValue={props.defaultValue}
        defaultActiveStartDate={props.defaultActiveStartDate}
        locale={i18n.language === 'en' ? 'en-EN' : 'ja-JP'}
        {...formatDate}
        className={`react-calendar-custom ${props.customPosition} ${props.className}`}
        prevLabel={
          <svg
            width='12'
            height='7'
            viewBox='0 0 12 7'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M9.975 0.88125C10.275 0.58125 10.725 0.58125 11.025 0.88125C11.175 1.03125 11.25 1.18125 11.25 1.40625C11.25 1.63125 11.175 1.78125 11.025 1.93125L6.525 6.43125C6.225 6.73125 5.775 6.73125 5.475 6.43125L0.975 1.93125C0.675 1.63125 0.675 1.18125 0.975 0.88125C1.275 0.58125 1.725 0.58125 2.025 0.88125L6 4.85625L9.975 0.88125Z'
              fill='#1E232A'
            />
          </svg>
        }
        prev2Label={
          <>
            <svg
              width='12'
              height='7'
              viewBox='0 0 12 7'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M9.975 0.88125C10.275 0.58125 10.725 0.58125 11.025 0.88125C11.175 1.03125 11.25 1.18125 11.25 1.40625C11.25 1.63125 11.175 1.78125 11.025 1.93125L6.525 6.43125C6.225 6.73125 5.775 6.73125 5.475 6.43125L0.975 1.93125C0.675 1.63125 0.675 1.18125 0.975 0.88125C1.275 0.58125 1.725 0.58125 2.025 0.88125L6 4.85625L9.975 0.88125Z'
                fill='#1E232A'
              />
            </svg>
            <svg
              width='12'
              height='7'
              viewBox='0 0 12 7'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M9.975 0.88125C10.275 0.58125 10.725 0.58125 11.025 0.88125C11.175 1.03125 11.25 1.18125 11.25 1.40625C11.25 1.63125 11.175 1.78125 11.025 1.93125L6.525 6.43125C6.225 6.73125 5.775 6.73125 5.475 6.43125L0.975 1.93125C0.675 1.63125 0.675 1.18125 0.975 0.88125C1.275 0.58125 1.725 0.58125 2.025 0.88125L6 4.85625L9.975 0.88125Z'
                fill='#1E232A'
              />
            </svg>
          </>
        }
        nextLabel={
          <svg
            width='12'
            height='7'
            viewBox='0 0 12 7'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
              d='M9.975 0.88125C10.275 0.58125 10.725 0.58125 11.025 0.88125C11.175 1.03125 11.25 1.18125 11.25 1.40625C11.25 1.63125 11.175 1.78125 11.025 1.93125L6.525 6.43125C6.225 6.73125 5.775 6.73125 5.475 6.43125L0.975 1.93125C0.675 1.63125 0.675 1.18125 0.975 0.88125C1.275 0.58125 1.725 0.58125 2.025 0.88125L6 4.85625L9.975 0.88125Z'
              fill='#1E232A'
            />
          </svg>
        }
        next2Label={
          <>
            <svg
              width='12'
              height='7'
              viewBox='0 0 12 7'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M9.975 0.88125C10.275 0.58125 10.725 0.58125 11.025 0.88125C11.175 1.03125 11.25 1.18125 11.25 1.40625C11.25 1.63125 11.175 1.78125 11.025 1.93125L6.525 6.43125C6.225 6.73125 5.775 6.73125 5.475 6.43125L0.975 1.93125C0.675 1.63125 0.675 1.18125 0.975 0.88125C1.275 0.58125 1.725 0.58125 2.025 0.88125L6 4.85625L9.975 0.88125Z'
                fill='#1E232A'
              />
            </svg>
            <svg
              width='12'
              height='7'
              viewBox='0 0 12 7'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M9.975 0.88125C10.275 0.58125 10.725 0.58125 11.025 0.88125C11.175 1.03125 11.25 1.18125 11.25 1.40625C11.25 1.63125 11.175 1.78125 11.025 1.93125L6.525 6.43125C6.225 6.73125 5.775 6.73125 5.475 6.43125L0.975 1.93125C0.675 1.63125 0.675 1.18125 0.975 0.88125C1.275 0.58125 1.725 0.58125 2.025 0.88125L6 4.85625L9.975 0.88125Z'
                fill='#1E232A'
              />
            </svg>
          </>
        }
      />
    </>
  );
};

export default ReactCalendar;
