import { Textarea } from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import MetaTagViewer from 'components/MetaTagViewer/MetaTagViewer';
import { SendChatIcon } from 'images';
import { useCallback } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getInfoMetaPost } from 'store/actions/postMeta';
import { postCommentDetail } from 'store/actions/usersNew';
import { checkLinkPostSale } from 'utils';

const CommentForm = ({
  item,
  setDataGetListBookmark,
  dataGetListBookmark,
  setTrickReloadComment = () => {},
}) => {
  const { t } = useTranslation();
  const [valueComment, setValueComment] = useState('');
  const [idPostMeta, setIdPostMeta] = useState(null);
  const [checkSendChat, setCheckSendChat] = useState(null);
  const [postDetailMeta, setPostDetailMeta] = useState(null);
  const dispatch = useDispatch();
  const refArea = useRef(null);

  useEffect(() => {
    if (idPostMeta) {
      dispatch(
        getInfoMetaPost(idPostMeta, (dataMeta) => {
          if (dataMeta?.title) {
            setPostDetailMeta(dataMeta);
          } else {
            setPostDetailMeta(null);
          }
        })
      );
    } else {
      setPostDetailMeta(null);
      setIdPostMeta(null);
    }
  }, [idPostMeta, dispatch]);

  const funcDisableCMT = (item) => {
    if (
      (item?.is_followed === 0 && item?.follow_flag === 1) ||
      (item?.is_media_display === 0 && item?.type === 1) ||
      item?.enable_comment === 0
    ) {
      return true;
    }
    return false;
  };

  const disableArea = (item) => {
    return funcDisableCMT(
      item?.share_type === 2 && item?.share_post?.share_type === 1
        ? item
        : item?.share_type === 1 && item?.share_post?.share_type === 2
        ? item?.share_post
        : item?.share_type === 2 && item?.share_post?.share_type === 2
        ? item
        : item?.share_type === 2
        ? item
        : item?.share_type === 1
        ? item?.share_post
        : item
    );
  };

  const handlePostComment = useCallback(
    (id, parent_id) => {
      const data = {
        parent_id: parent_id,
        content: valueComment,
      };
      checkSendChat &&
        dispatch(
          postCommentDetail(id, data, (data) => {
            setDataGetListBookmark(
              dataGetListBookmark.map((item) => {
                if (+item?.id === +data?.data?.post_id) {
                  const newItem = { ...item };
                  ++newItem.number_comment;
                  if (newItem?.latest_comment) {
                    newItem.latest_comment.id = data?.data?.id;
                    newItem.latest_comment.content = data?.data?.content;
                    newItem.latest_comment.created_at = data?.data?.created_at;
                    newItem.latest_comment.avatar = data?.data?.avatar;
                    newItem.latest_comment.account_name = data?.data?.account_name;
                    newItem.latest_comment.user_id = data?.data?.user_id;
                    newItem.latest_comment.number_like = data?.data?.number_like;
                    newItem.latest_comment.is_liked = data?.data?.is_liked;
                    newItem.latest_comment.is_edited = data?.data?.is_edited;
                  } else {
                    newItem.latest_comment = {
                      id: data?.data?.id,
                      avatar: data?.data?.avatar,
                      user_id: data?.data?.user_id,
                      account_name: data?.data?.account_name,
                      content: data?.data?.content,
                      created_at: data?.data?.created_at,
                      number_like: data?.data?.number_like,
                      is_liked: data?.data?.is_liked,
                      is_edited: data?.data?.is_edited,
                    };
                  }
                  return newItem;
                } else return item;
              })
            );
            setTrickReloadComment({
              id: id,
              data: data.data,
            });
          })
        );

      setCheckSendChat(null);
      setValueComment('');
      setPostDetailMeta(null);
      setIdPostMeta(null);
      const textareaId = document.getElementById(`comment-item-${id}`);
      if (textareaId) textareaId.style.height = '44px';
    },
    [checkSendChat, dataGetListBookmark, dispatch, setDataGetListBookmark, valueComment]
  );

  const handleTextarea = (e, id, height) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    const { value } = e.target;
    setValueComment(value);
    const idPost = checkLinkPostSale(e.target.value);
    if (idPost) {
      setIdPostMeta(idPost);
    } else {
      setIdPostMeta(null);
      setPostDetailMeta(null);
    }
    setCheckSendChat(id);
    if (e.target.value?.toString().trim() === '') {
      e.target.style.height = height;
      e.target.style.padding = '9px  30px  9px 12px';
      setCheckSendChat(null);
    }
  };
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <InputWrapper
        id='fsite-comment'
        className={`form-item form-input-comment-meta ${disableArea(item) ? 'disable' : ''} ${
          postDetailMeta?.title ? 'haveMeta' : ''
        }`}>
        <Textarea
          disabled={funcDisableCMT(
            item?.share_type === 2 && item?.share_post?.share_type === 1
              ? item
              : item?.share_type === 1 && item?.share_post?.share_type === 2
              ? item?.share_post
              : item?.share_type === 2 && item?.share_post?.share_type === 2
              ? item
              : item?.share_type === 2
              ? item
              : item?.share_type === 1
              ? item?.share_post
              : item
          )}
          onChange={(e) => handleTextarea(e, item?.id, '44px')}
          placeholder={t('comment.formAddRepComment.placeholder')}
          onKeyDown={(e) => handleTextarea(e, item?.id, '44px')}
          value={valueComment}
          maxLength='500'
          id={`comment-item-${item?.id}`}
          overflowHidden
          style={{
            border: '1px solid #E5E6EB',
            background: `${
              funcDisableCMT(
                item?.share_type === 2 && item?.share_post?.share_type === 1
                  ? item
                  : item?.share_type === 1 && item?.share_post?.share_type === 2
                  ? item?.share_post
                  : item?.share_type === 2 && item?.share_post?.share_type === 2
                  ? item
                  : item?.share_type === 2
                  ? item
                  : item?.share_type === 1
                  ? item?.share_post
                  : item
              )
                ? '#efefef'
                : '#fff'
            }`,
          }}
        />
        {postDetailMeta?.title && (
          <MetaTagViewer
            title={postDetailMeta?.title}
            url={postDetailMeta?.url}
            thumbnail={postDetailMeta?.thumbnail}
          />
        )}
        <SendChatIcon
          style={{
            cursor: `${item?.is_followed === 0 && item?.follow_flag === 1 && 'default'}`,
          }}
          className={`${valueComment && 'active'}`}
          onClick={() => {
            valueComment && handlePostComment(item?.id, 0);
            setValueComment('');
          }}
        />
      </InputWrapper>
    </form>
  );
};

export default CommentForm;
