import { LayoutCreatorRight, PopupConfirmFan, PopupLoading } from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import { ArrowLeftIcon } from 'images';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { customStyles, patternNoFirstSpacing, queryLanguage, CustomOption } from 'utils/utils';
import withCreatorRole from 'hocs/withCreatorRole';
import { getReportCategories, postUserReport } from 'store/actions/feedback';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { newPathUser } from 'constants/layout/constant';
import { resetDataInfoOther, resetIDHomePage } from 'store/actions/usersNew';

const PostReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, listCategoryReport } = useSelector((state) => state.feedback);
  const location = useLocation();
  const { fan_id, fan_name } = location?.state || {};
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({ mode: 'all' });
  const watchCategory = watch('report_categorie_id', '');
  const watchContent = watch('content', '');
  const { t } = useTranslation();
  const isDisabledButton = !watchCategory || !watchContent || !fan_id;
  const [focusCategory, setFocusCategory] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const optionListContent = listCategoryReport.map((item) => ({
    ...item,
    value: item?.id,
    label: item?.name,
  }));

  useEffect(() => {
    (!fan_id || !fan_name) && navigate('/*');
    dispatch(getReportCategories({ limit: 100000, active_flag: 1, language: queryLanguage }));
  }, []);

  const handleSendReportSuccess = ({ success }) => {
    success && setIsOpenModal(true);
  };

  const onSubmit = (data) => {
    const newData = {
      ...data,
      type: 2,
      report_categorie_id: data.report_categorie_id?.id,
      report_id: fan_id,
    };
    dispatch(postUserReport(newData, handleSendReportSuccess));
  };

  return (
    <div>
      {loading && <PopupLoading className={'popup-loading'} />}
      {isOpenModal && (
        <PopupConfirmFan text={t('Popup.reportSendSuccess')}>
          <div
            className='ok btn'
            onClick={() => {
              setIsOpenModal(false);
              dispatch(resetIDHomePage());
              dispatch(resetDataInfoOther());
              navigate(`${newPathUser}`);
            }}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}
      <LayoutCreatorRight
        className='post-report-page-header'
        titlePage={t('PostReport.title')}>
        <div className='post-report-page'>
          <form onSubmit={(e) => e.preventDefault()} className='fansite-form'>
            <div className='title'>{t('PostReport.pleaseDetailProblem')}</div>
            <InputWrapper id='fsite-id' label={t('PostReport.creator.reportTo')} required>
              <input disabled value={fan_name} />
            </InputWrapper>

            <InputWrapper
              id='fsite-category'
              label={t('PostReport.category')}
              required={true}
              error={errors?.category ? errors?.category?.message : ''}>
              <div className='category'>
                <Controller
                  name='report_categorie_id'
                  control={control}
                  rules={{ required: t('ValidateMsg.categoryRequired') }}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        options={listCategoryReport.length > 0 ? [...optionListContent] : [{}]}
                        onFocus={() => setFocusCategory(true)}
                        onBlur={() => setFocusCategory(false)}
                        onClick={(e) => setFocusCategory(false)}
                        onChange={(val) => {
                          field.onChange(val);
                          setFocusCategory(false);
                        }}
                        placeholder={t('PostReport.category')}
                        styles={customStyles}
                        components={{ Option: CustomOption }}
                      />
                    </>
                  )}
                />
                <ArrowLeftIcon className={`${focusCategory ? 'focus' : 'no-focus'}`} />
              </div>
            </InputWrapper>
            <InputWrapper id='fsite-text' label={t('PostReport.problemDetail')}>
              <textarea
                {...register('content', {
                  required: t('PostReport.pleaseDetailProblem'),
                  validate: {
                    noFirstSpacing: (value) =>
                      (value && patternNoFirstSpacing?.test(value.toString().trim())) ||
                      t('PostReport.pleaseDetailProblem'),
                  },
                  maxLength: {
                    value: 2000,
                    message: t('ValidateMsg.CONTENT_WITHIN_2000_CHARACTERS'),
                  },
                })}
                placeholder={t('PostReport.placeholderInputRear')}
              />
            </InputWrapper>

            <InputWrapper
              id='fsite-submit'
              className={`button-submit ${isDisabledButton ? 'disabled' : ''}`}>
              <button onClick={handleSubmit(onSubmit)} disabled={isDisabledButton}>
                {t('PostReport.buttonReport')}
              </button>
            </InputWrapper>
          </form>
        </div>
      </LayoutCreatorRight>
    </div>
  );
};

export default withCreatorRole(PostReport);
