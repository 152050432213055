import styled, { css } from 'styled-components';
import { propsStyled } from './props';

const { color, PropsDiv, PropsText, PropsButton, FlexCenter } = propsStyled;

const WrapperMobile = styled.div`
  display: none;
  /* height: 100%; */
  @media screen and (max-width: 575px), (max-height: 450px) {
    display: block;
    .group-chat-box {
      @media screen and (max-height: 450px) {
        height: calc(100% - 220px) !important;
        .content-chat {
          max-height: 80%;
        }
      }
    }
    .sendCoin {
      width: 222px;
      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 106px;
      }
    }
    .comment {
      .text {
        overflow-wrap: anywhere;
      }
    }
  }
`;
const WrapperPC = styled.div`
  display: none;
  @media screen and (min-width: 576px) and (min-height: 450px) {
    display: block;
  }
`;
const GlobalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 30px;
  &.live {
    padding: 23px 30px;
  }
  @media screen and (max-width: 1023px) {
    &.live {
      padding: 0px;
    }
  }
  @media screen and (max-width: 575px), (max-height: 450px) {
    height: 100%;
  }
  ${({ viewfan }) =>
    viewfan &&
    css`
      @media screen and (min-width: 575px) {
      }
    `}
  ${({ mh }) => mh && `min-height: ${mh};`}
`;
const Wrapper = styled.div`
  ${PropsDiv};
  width: 100%;
  aspect-ratio: 16/9;
  max-width: 972px;
  @media screen and (max-width: 575px), (max-height: 450px) {
    height: 100%;
  }
`;
const WrapperLive = styled.div`
  ${PropsDiv};
  position: relative;
  width: 100% !important;
  .current-view {
    div > div {
      border-radius: 10px;
    }
  }
  .bg-live {
    position: relative !important;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    aspect-ratio: 16/9;
    max-width: 972px;
    border-radius: 10px;
    .fullscreen {
      width: 100%;
      height: 100%;
      #local-player {
        div {
          border-radius: 10px;
        }
      }
    }
  }
  .text-pause-live {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    font-weight: 700;
    font-size: 40px;
    z-index: 10;
  }
  @media screen and (max-width: 1023px) {
    .bg-live {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  @media screen and (max-width: 575px), (max-height: 450px) {
    width: 100%;
    height: 100%;
  }
`;

const WrapperAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .content-info {
    display: flex;
    flex-direction: row;
    gap: 12px;
    img {
      border-radius: 40px;
    }
    .title {
      font-weight: 700;
      font-size: 18px;
      color: #1a1a1a;
    }
    .name {
      font-weight: 500;
      font-size: 14px;
      color: #1a1a1a;
    }
    &__right {
      width: calc(100% - 60px);
      padding-top: 4px;
      .name,
      .title {
        width: 100%;
        white-space: normal;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    &__left {
      img {
        width: 56px;
        height: 56px;
      }
    }
  }
  .content-share {
    .share-icon-live {
      cursor: pointer;
      width: 36px;
      height: 36px;
      background: #efefef;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const WrapperButton = styled.div`
  width: 100%;
  height: calc(100% - 36px);
  max-width: 330px;
  border: 1px solid ${color[3]};
  background: ${color[1]};
  border-radius: 20px;
  ${PropsDiv};
  @media screen and (max-width: 1023px) {
    max-width: 100%;
    height: 545px;
  }
  @media screen and (min-width: 576px) and (min-height: 450px) {
    display: block;
  }
`;

const WrapperBlock = styled.div`
  padding: 16px;
  background: #f2f3f9;
  border-radius: 20px 20px 0px 0px;
`;

const WrapperBlockWatch = styled.div`
  padding: 15px 16px;
  background: #f2f3f9;
  @media screen and (max-width: 1023px) {
    border-radius: 20px 20px 0px 0px;
  }
`;

const Block = styled.div`
  position: relative;
  ${PropsDiv};
  ${FlexCenter};
  .two-column {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .card-item--detail {
    min-height: 35vh;
  }
  ${({ totip }) =>
    totip &&
    `
    &:hover{
      ${HoverTooltip}{
        display: block !important;
      }
    }
   `}
  ${({ live }) =>
    live &&
    `
    height: 26px;
    background: #FF0000;
    padding: 0 10px;
    color: ${color[1]};
    border-radius: 4px;
   `}
`;

const Button = styled.button`
  position: relative;
  ${PropsButton};
  ${FlexCenter}
  ${({ totip }) =>
    totip &&
    `
    &:hover{
      ${HoverTooltip}{
        display: block !important;
      }
    }
   `}
   &:disabled {
    background-color: #a3a3a3;
    cursor: not-allowed;
  }
`;
const ContentChat = styled.div`
  ${PropsDiv};
  height: 100%;
  .form-item-live {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: #f2f3f9;
    border-radius: 0px 0px 20px 20px;
    padding: 8px 10px;
    margin-bottom: 0px;
    .text {
      position: relative;
      width: 100%;
      height: 44px;
      background: #ffffff;
      border: 1px solid #e5e6eb;
      border-radius: 8px;
      padding: 8px 70px 8px 12px;
      &::placeholder {
        color: #bebebe;
      }
      &:focus {
        outline: none;
        border: 1px solid #e5e6eb;
      }
    }
    .button-send {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 22px;
      left: unset;
      svg {
        color: #bebebe;
      }
      &.active {
        svg {
          color: #dc143c;
        }
      }
    }
  }
  .box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 24px 10px 56px;
  }
  @media screen and (min-width: 576px) {
    .text {
      color: ${color[2]} !important;
    }
    .box {
      padding-right: 12px !important;
      padding-left: 12px !important;

      .text {
        overflow-wrap: anywhere;
      }
      .sendCoin {
        .name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 116px;
        }
        .text {
          color: ${propsStyled.color[1]} !important;
        }
        .coin {
          margin-left: -10px;
        }
      }
    }
  }
`;
const HoverTooltip = styled.div`
  display: none;
  position: absolute;
  background: #1a1a1a;
  border-radius: 8px;
  top: 42px;
  z-index: 50;
  padding: 5px;
  width: fit-content;
  white-space: nowrap;
  font-weight: 400;
  font-size: 12px;
  color: #eeeeee;
  left: 50%;
  padding: 8px 9px;
  transform: translateX(-50%);
  &::after {
    content: '';
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    width: 7px;
    height: 6px;
    background: #282727;
    position: absolute;
    top: 1px;
    left: 50%;
    transform: translate(-50%, -100%);
  }
`;

const Text = styled.div`
  ${PropsText}
  ${({ mr }) => mr && `margin-right: ${mr};`}
`;

const InformationOBS = styled.div`
  height: ${({ height = '290px' }) => height};
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  .hr-hyphen-obs {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 64px;
    border-top: 1px solid #f0f0f0;
  }
  .fansite-form-input__controls {
    padding: 8px 7px !important;
  }
  .information_obs {
    &-title {
      font-size: 14px;
      font-weight: 700px;
      margin-bottom: 6px;
    }
    &-link {
      width: 229px;
      margin-bottom: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      font-size: 14px;
      color: #a1a3a8;
      font-weight: 400;
    }
    &-copy {
      color: #ff0000;
    }
    &-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
      border-top: 1px solid #f0f0f0;
      padding: 16px 24px;
    }
    &-wrap {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 37px;
    }
    &-button {
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 6px 36px;
      width: 92px;
      height: 32px;
      font-weight: 400;
      font-size: 14px;
      background: #dc143c;
      color: #fff;
      outline: none;
      border: none;
      margin-top: auto;
      border-radius: 6px;
      &.w-92 {
        width: 92px;
      }

      &.cancel {
        background: #ffffff !important;
        color: #1a1a1a;
        border: 1px solid #e5e6eb;
        white-space: nowrap;
        &.w-119 {
          width: 119px;
        }
      }
      &:disabled {
        background: #dc143c4d;
      }
    }
    &-bot {
      font-size: 14px;
      color: #787880;
      font-weight: 400;
      max-width: 343px;
    }
  }
  .wrapper_obs-link {
    display: flex;
    justify-content: flex-start;
  }
`;
export {
  Wrapper,
  WrapperLive,
  WrapperButton,
  Text,
  Block,
  Button,
  ContentChat,
  WrapperMobile,
  WrapperPC,
  GlobalWrapper,
  HoverTooltip,
  InformationOBS,
  WrapperAvatar,
  WrapperBlock,
  WrapperBlockWatch,
};
