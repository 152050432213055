import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { convertTextMonthEN, formatCoin, isProdEnableLiveStream, REVENUE_ENUM, widthSreen } from 'utils';
import { useTranslation } from 'react-i18next';

const ReportRevenueChart = ({ data }) => {
  const { t, i18n } = useTranslation();
  const REVENUE_ENUM = {
    subscription_revenue: t('Common.subscription'),
    content_revenue: t('Common.content'),
    livestream_revenue: t('Common.live_streaming'),
    other_revenue: t('Report.other_tips'),
    total: t('Sales.total_sales'),
  };
  const renderLegendText = (value) => (
    <span style={{ color: '#7B7B7B' }}>{REVENUE_ENUM[value]}</span>
  );
  const renderToolTipText = (value, name) => [value, REVENUE_ENUM[name]];
  return (
    <>
      <p className='report-chart-label'>{t('Report.sales_report')}</p>
      <ResponsiveContainer
        width='100%'
        height='100%'
        minWidth={500}
        minHeight={300}
        className='chart-revenue'>
        <ComposedChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}>
          <CartesianGrid stroke='#DFDFDF' vertical={false} />
          <XAxis
            dataKey='month'
            tickFormatter={(e) =>
              i18n.language === 'jp' ? `${+e} ${t('Common.month')}` : convertTextMonthEN(+e)
            }
            tick={{ fill: '#282727', fontSize: 12 }}
            tickLine={false}
            interval={0}
            stroke='#282727'
          />
          <YAxis
            type='number'
            tickFormatter={(e) => formatCoin(e)}
            tickLine={false}
            axisLine={false}
            tick={{ fill: '#282727', fontSize: 12 }}
          />
          <Tooltip formatter={renderToolTipText} />
          <Legend
            formatter={renderLegendText}
            align={`${widthSreen > 991 ? 'center' : 'left'}`}
          />
          <Bar
            dataKey='subscription_revenue'
            stackId='col'
            fill='#FF0000'
            barSize={widthSreen > 991 ? 16 : 6}
          />
          <Bar
            dataKey='content_revenue'
            stackId='col'
            fill='#91BC3A'
            barSize={widthSreen > 991 ? 16 : 6}
          />
          <Bar
            dataKey='other_revenue'
            fill='#24A3C5'
            stackId='col'
            barSize={widthSreen > 991 ? 16 : 6}
          />
          {+isProdEnableLiveStream === 1 && (
            <>
              <Bar
                dataKey='livestream_revenue'
                fill='#744EA3'
                stackId='col'
                barSize={widthSreen > 991 ? 16 : 6}
              />
              <Bar
                dataKey='other_revenue'
                fill='#24A3C5'
                stackId='col'
                barSize={widthSreen > 991 ? 16 : 6}
              />
            </>
          )}
          <Line
            type='total'
            dataKey='total'
            stroke='#3FA3FF'
            strokeWidth={widthSreen > 991 ? 3 : 2}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

export default ReportRevenueChart;
