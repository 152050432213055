//components
import {
  AvatarInfoCreator,
  Checkbox,
  LayoutCreatorRight,
  PopupChoiceImgHighlightC156,
  PopupConfirmFan,
  PopupLoading,
} from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import { newPathUser } from 'constants/layout/constant';
//hocs
import withCreatorRole from 'hocs/withCreatorRole';
//hook
//img
import { ArrowLeftIcon } from 'images';
import TextArea from 'rc-textarea';
import { useEffect } from 'react';
//react
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
//store
import {
  creatorDeleteArticle,
  creatorEditTimeLineArticle,
  creatorGetPostDetail,
} from 'store/actions/myPage';
//util
import {
  CustomOption,
  customStylesHeight32,
  getPresignedUrlS3,
  isEmptyObject,
  patternNoFirstSpacing,
  setFileNameS3,
  uploadS3ByPresigned,
} from 'utils';
//scss
import '../short.scss';
import ListImgVideo from './listImgVideo';
//data

const EditPostShort = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { loading } = useSelector((state) => state.myPage);
  const { data: dataProfile } = useSelector((state) => state.users);
  const [isVisiblePopUpConfirm1, setIsVisiblePopUpConfirm1] = useState(false);
  const [isVisiblePopUpConfirm2, setIsVisiblePopUpConfirm2] = useState(false);
  const [isVisiblePopUpDeletePost, setIsVisiblePopDeletePost] = useState(false);
  const [isVisiblePopUpDeletePostComplete, setIsVisiblePopDeletePostComplete] =
    useState(false);
  //img && video
  const [imgUpload, setImgUpload] = useState([]);
  const [videoUpload, setVideoUpload] = useState();
  const [typePost, setTypePost] = useState();
  const [dataGetPresignedUrlS3, setDataGetPresignedUrlS3] = useState();
  const { t } = useTranslation();
  const [focusChoiceUseSee, setFocusChoiceUseSee] = useState(false);
  const [new24h, setNew24h] = useState();
  //loading
  const [popupLoading, setPopupLoading] = useState(false);
  const [detailPost, setDetailPost] = useState();

  useEffect(() => {
    dispatch(
      creatorGetPostDetail(id, true, (data) => {
        setDetailPost(data);
        setValue('content', data?.content);
        setValue('enable_comment', data?.enable_comment);
        setTypePost(data?.type === 4 ? 'short' : 'normal');
        setValue('in_24h_flag', data?.in_24h_flag);
        setNew24h(data?.in_24h_flag);
        setValue(
          'follow_flag',
          [
            { value: '0', label: t('Creator.CreateLiveStream.everyone') },
            { value: '1', label: t('Creator.PostArticle.postShort.userFollow') },
          ].find(({ value }) => +value === data?.follow_flag)
        );
        if (data.medias.length) {
          if (data.medias?.[0]?.type === 2) {
            //video
            setDataGetPresignedUrlS3({ short_video: data?.medias?.[0]?.url });
            data.medias?.[0]?.public_flag === 0
              ? setVideoUpload({
                  img: 0,
                  files: 0,
                })
              : setVideoUpload((prev) => ({
                  ...prev,
                  img: data.medias?.[0]?.url,
                  files: data.medias[0]?.url,
                }));
          } else {
            if (data?.type === 4) {
              const filterThumbnail = data?.medias?.filter((item) => item?.type !== 4) || [];
              setDataGetPresignedUrlS3({
                short_images: filterThumbnail.map(({ url }) => url),
              });
              setImgUpload(filterThumbnail.map(({ url }) => url));
            } else {
              setDataGetPresignedUrlS3({
                images: data?.medias?.map(({ url }) => url),
              });
              setImgUpload(data?.medias?.map(({ url }) => url));
            }
          }
        }
      })
    );
  }, [id]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    clearErrors,
    control,
    getValues,
    setError,
    setValue,
  } = useForm({
    mode: 'all',
  });
  const watchContent = watch('content', '');
  const watchFollowFlag = watch('follow_flag', '');

  const disabledButton =
    !isEmptyObject(errors) ||
    popupLoading ||
    loading ||
    isVisiblePopUpConfirm1 ||
    isVisiblePopUpConfirm2 ||
    detailPost?.type === 3
      ? watchFollowFlag?.value === '1'
        ? imgUpload?.length
          ? false
          : true
        : !watchContent && !imgUpload?.length && !videoUpload
      : !videoUpload &&
        !imgUpload?.length &&
        !dataGetPresignedUrlS3?.short_video &&
        !dataGetPresignedUrlS3?.short_images;
  //START END CALENDAR

  const handleShowPopupConfirmSuccess = (e) => {
    setIsVisiblePopUpConfirm1(false);
    setIsVisiblePopUpConfirm2(true);
    setPopupLoading(false);
  };
  //ONSUBMIT
  const onSubmit = async (data) => {
    const newData = {
      ...data,
      enable_comment: data.enable_comment ? '1' : '0',
      follow_flag: data.follow_flag.value,
      type: detailPost.type,
      content: data.content,
    };
    typePost !== 'short' && delete newData.in_24h_flag;
    setDataGetPresignedUrlS3((prev) => ({ ...prev, ...newData }));
    setIsVisiblePopUpConfirm1(true);
  };

  const handleCallApiSubmit = (data) => {
    dispatch(
      creatorEditTimeLineArticle(
        detailPost?.id,
        data,
        (e) => handleShowPopupConfirmSuccess(e),
        () => setPopupLoading(false)
      )
    );
  };
  const handleConfirmSubmit = async () => {
    setIsVisiblePopUpConfirm1(false);
    setPopupLoading(true);

    let short_video_pre_signed;
    let short_video;

    const checkNewImg = imgUpload?.filter((item) => {
      return typeof item === 'object';
    });
    const checkOld = imgUpload?.filter((item) => {
      return typeof item !== 'object';
    });

    let listLinkS3Img = checkOld || [];
    let listLinkPreSignedImg = [];
    if (
      typeof videoUpload === 'object' &&
      !videoUpload?.img &&
      detailPost?.medias[0]?.public_flag === 1
    ) {
      getPresignedUrlS3(
        'video',
        `uploads/output/videos`,
        setFileNameS3(videoUpload.name),
        'post'
      )
        .then((resUrl) => {
          short_video_pre_signed = resUrl.data.pre_signed;
          short_video = resUrl.data.file_path;
        })
        .then(() => {
          uploadS3ByPresigned(short_video_pre_signed, videoUpload)
            .then(() => {
              const newData = {
                ...dataGetPresignedUrlS3,
                short_video_pre_signed: short_video_pre_signed,
                short_video: short_video,
                short_video_file: videoUpload,
              };
              handleCallApiSubmit(newData);
            })
            .catch(() => setPopupLoading(false));
        })
        .catch((_error) => setPopupLoading(false));
    } else if (checkNewImg?.length > 0 && imgUpload?.length > 0) {
      const promiseList = checkNewImg.map((item) => {
        return getPresignedUrlS3(
          'image',
          `uploads/output/images`,
          setFileNameS3(item.name),
          'post'
        );
      });
      Promise.all(promiseList)
        .then((responses) => {
          responses.forEach((element) => {
            if (element.success) {
              listLinkS3Img.push(element.data.file_path);
              listLinkPreSignedImg.push(element.data.pre_signed);
            }
          });
          const promiseGenerate = listLinkPreSignedImg.map((m, i) => {
            return uploadS3ByPresigned(m, checkNewImg[i]);
          });
          Promise.all(promiseGenerate).then(() => {
            const newData =
              typePost === 'normal'
                ? {
                    ...dataGetPresignedUrlS3,
                    images: listLinkS3Img,
                  }
                : {
                    ...dataGetPresignedUrlS3,
                    short_images: listLinkS3Img,
                  };
            handleCallApiSubmit(newData);
          });
        })
        .catch((_errors) => setPopupLoading(false));
    } else {
      handleCallApiSubmit(dataGetPresignedUrlS3);
    }
  };

  const handleDeletePost = () => {
    setIsVisiblePopDeletePost(false);
    dispatch(
      creatorDeleteArticle(detailPost.id, () => setIsVisiblePopDeletePostComplete(true))
    );
  };

  return (
    <>
      {(loading || popupLoading) && <PopupLoading className={'popup-loading'} />}
      {isVisiblePopUpDeletePost && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePost(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.confirmDeletePost')}>
          <div className='btn cancel' onClick={() => setIsVisiblePopDeletePost(false)}>
            {t('Common.buttonNo')}
          </div>
          <div className='ok btn' onClick={handleDeletePost}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpDeletePostComplete && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePostComplete(e)}
          iconNote={true}
          text={t('Popup.deletePostSucess')}>
          <div className='ok btn' onClick={() => navigate(`${newPathUser}`)}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpConfirm1 && (
        <PopupConfirmFan
          isVisiblePopup={() => setIsVisiblePopUpConfirm1(false)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.confirmEditPost')}>
          <div
            className='btn cancel'
            onClick={() => {
              setIsVisiblePopUpConfirm1(false);
              setPopupLoading(false);
            }}>
            {t('Common.buttonNo')}
          </div>
          <div className='ok btn' onClick={handleConfirmSubmit}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpConfirm2 && (
        <PopupConfirmFan
          isVisiblePopup={() => setIsVisiblePopUpConfirm2(false)}
          colorSvg='#28C76F'
          iconNote={false}
          text={`${t('Popup.postCompleted')} ${
            +detailPost?.in_24h_flag !== +new24h
              ? +detailPost?.type === 4 && +new24h === 1
                ? t('Creator.PostArticle.postShort.check24h')
                : t('Creator.PostArticle.postShort.noCheck24h')
              : ''
          }`}>
          <div
            className='ok btn'
            onClick={() => {
              setIsVisiblePopUpConfirm2(false);
              navigate(`${newPathUser}post-short/${detailPost?.id}`);
            }}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      <LayoutCreatorRight
        titlePage={t(
          `Creator.PostArticle.postShort.${
            detailPost?.type === 3 ? 'titleEditNormal' : 'titleEditShort'
          }`
        )}>
        <div className='fansite-creator-post-short edit'>
          <AvatarInfoCreator
            name={dataProfile?.account_name || dataProfile?.name_japanese}
            content2={`${t('Common.id')}: ${dataProfile?.account_id}`}
            images={dataProfile?.avatar && dataProfile?.avatar}
          />
          <form onSubmit={(e) => e.preventDefault()} className='fansite-form'>
            <div className='follow-flag'>
              <label>{t('Creator.PostArticle.postShort.whoSeePost')}</label>
              <Controller
                name='follow_flag'
                control={control}
                render={({ field }) => (
                  <div>
                    <Select
                      {...field}
                      options={[
                        { value: '0', label: t('Creator.CreateLiveStream.everyone') },
                        { value: '1', label: t('Creator.PostArticle.postShort.userFollow') },
                      ]}
                      onFocus={() => setFocusChoiceUseSee(true)}
                      onBlur={() => setFocusChoiceUseSee(false)}
                      onClick={() => setFocusChoiceUseSee(false)}
                      onChange={(val) => {
                        field.onChange(val);
                        setFocusChoiceUseSee(false);
                      }}
                      noOptionsMessage={() => {
                        t('Common.listNull');
                      }}
                      defaultValue={getValues('follow_flag')}
                      isSearchable={false}
                      styles={customStylesHeight32}
                      components={{
                        Option: CustomOption,
                      }}
                      className='selected'
                    />
                    <ArrowLeftIcon className={`${focusChoiceUseSee ? 'focus' : 'no-focus'}`} />
                  </div>
                )}
              />
            </div>
            {detailPost && (
              <InputWrapper
                id='fsite-content'
                label=''
                required={true}
                error={errors?.content ? errors?.content?.message : ''}
                className={`${imgUpload && 'has-img'} text-post`}>
                <TextArea
                  {...register('content')}
                  placeholder={t('Creator.PostArticle.placeholderContent')}
                  maxLength={500}
                  autoSize
                  value={getValues('content')}
                  onInput={(e) => {
                    if (e.target.value) {
                      clearErrors('content');
                      if (e.target.value.length >= 500) {
                        setError('content', {
                          type: 'maxLength',
                          message: t('ValidateMsg.CONTENT_IS_TOO_LONG'),
                        });
                      } else clearErrors('content');
                      if (!patternNoFirstSpacing.test(e.target.value.toString().trim())) {
                        setError('content', {
                          type: 'firstSpace',
                          message: t('ValidateMsg.contentRequired'),
                        });
                      } else clearErrors('content');
                    } else {
                      clearErrors('content');
                    }
                  }}
                  onChange={(e) => {
                    setValue('content', e.target.value);
                  }}
                />
              </InputWrapper>
            )}

            <ListImgVideo
              short_video={(e) => {
                setVideoUpload(e);
                setDataGetPresignedUrlS3((prev) => ({ ...prev, short_video: e }));
              }}
              list_img={(e) => {
                setImgUpload(e);
                detailPost?.type === 4
                  ? setDataGetPresignedUrlS3({ short_images: e })
                  : setDataGetPresignedUrlS3({ images: e });
              }}
              type_post={(e) => setTypePost(e)}
              initData={detailPost ? detailPost?.medias : []}
              initType={detailPost?.type === 4 ? 'short' : 'normal'}
              showErr={detailPost?.type === 3 && !imgUpload && watchFollowFlag?.value === '1'}
              setLoadingUploadFile={(e) => setPopupLoading(e)}
            />

            <InputWrapper
              id='fsite-answer'
              label={t('Creator.PostArticle.canReply')}
              className='form-item-switch'>
              <label className='switch'>
                <input type='checkbox' {...register('enable_comment')} defaultChecked />
                <span className='slider round'></span>
              </label>
            </InputWrapper>
            {typePost === 'short' && (
              <InputWrapper className='box-category'>
                <Checkbox
                  type='checkbox'
                  name={`in_24h_flag`}
                  onChange={(e) => {
                    setValue('in_24h_flag', e.target.checked ? 1 : 0);
                    setNew24h(e.target.checked ? 1 : 0);
                  }}
                  text={t('Creator.PostArticle.postShort.24h')}
                  value={1}
                  checked={getValues('in_24h_flag') === 1 ? true : false}
                />
              </InputWrapper>
            )}
            <div className={`list-button-submit ${detailPost?.type === 4 && 'short'}`}>
              {detailPost?.type === 4 && (
                <PopupChoiceImgHighlightC156
                  title={t('Popup.choiceImgHighlightC156.title')}
                  old_thumbnail={detailPost?.medias.find((thumb) => thumb.type === 4)}
                  loading={(e) => setPopupLoading(e)}
                  detailPost={detailPost}
                />
              )}

              <InputWrapper
                id='fsite-submit'
                className={`button-submit ${disabledButton ? 'disabled' : ''}`}>
                <button onClick={handleSubmit(onSubmit)} disabled={disabledButton}>
                  {t('Common.keep2')}
                </button>
              </InputWrapper>
              <InputWrapper id='fsite-submit' className={`delete button-submit`}>
                <button onClick={() => setIsVisiblePopDeletePost(true)}>
                  {t('Common.buttonDelete')}
                </button>
              </InputWrapper>
            </div>
          </form>
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(EditPostShort);
