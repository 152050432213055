import './index.scss';
import { useEffect } from 'react';
import { useState } from 'react';

const BlurImage = ({ children, ...props }) => {
  const [sourceLoaded, setSourceLoaded] = useState(null);

  useEffect(() => {
    setSourceLoaded(props.src)
  }, [props.src])

  return (
    <>
      <div style={{ ...props.style }} >
        <div className="blur-image" style={{ backgroundImage: `url(${sourceLoaded})` }}>
          {children}
        </div>
      </div>
    </>
  );
};
export default BlurImage;
