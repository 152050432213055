import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
  AccountSidebar,
  HeaderListIdolV2,
  LayoutCreatorRight,
  MenuSidebarCustom,
  PopupConfirmFan,
  PopupLoading,
} from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import DangerIcon from 'images/DangerIcon';

import {
  ENV,
  STORAGE,
  callApiWithOutShowError,
  configParamsPatch,
  isEmptyObject,
} from 'utils';
import { newPathUser } from 'constants/layout/constant';
import Storage from 'utils/storage';

import './index.scss';
import { useSelector } from 'react-redux';

const WithDraw = () => {
  const [reason, setReason] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowPopupConfirm, setIsShowPopupConfirm] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { dataUser } = useSelector((state) => state.users);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'all' });
  const notes = [
    'WithDraw.deletedAccount',
    'WithDraw.deleteSubscription',
    'WithDraw.restorePoint',
    'WithDraw.notBill',
  ];

  const onSubmit = () => {
    setIsShowPopupConfirm(false);
    setIsLoading(true);
    const url = `${ENV}/request-leave`;
    callApiWithOutShowError(
      url,
      configParamsPatch({ reason_leave: reason }),
      null,
      () => {
        Storage.remove(STORAGE.PARAMS);
        Storage.remove(STORAGE.USER_ACCESS_TOKEN);
        Storage.remove(STORAGE.IS_ADMIN);
        Storage.remove(STORAGE.CURRENT_USER_KEY);
        Storage.remove(STORAGE.IS_AGENCY);
        Storage.remove(STORAGE.USER_ID);
        navigate('/with-draw/success');
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        console.log(error);
      }
    );
  };
  const onOpenConfirmPopup = ({ reason }) => {
    setReason(reason);
    setIsShowPopupConfirm(true);
  };

  return (
    <>
      <LayoutCreatorRight
        noButtonBack
        titlePage={t('WithDraw.title')}
        link={`${newPathUser}`}
        className='withdraw-setting-layout'>
        {isLoading && <PopupLoading />}
        {isShowPopupConfirm && (
          <PopupConfirmFan
            isVisiblePopup={(e) => setIsShowPopupConfirm(e)}
            colorSvg='#FF9F43'
            iconNote={true}
            text={t('WithDraw.confirm')}
            className={'popup-confirm__with-draw'}>
            <div className='btn confirm' onClick={onSubmit}>
              {t('Common.buttonYes')}
            </div>
            <div className='btn cancel' onClick={() => setIsShowPopupConfirm(false)}>
              {t('WithDraw.cancel')}
            </div>
          </PopupConfirmFan>
        )}
        <div className='with-draw-container'>
          <div className='notes'>
            <DangerIcon />
            <div className='notes__contents'>
              <label>{t('WithDraw.withdrawal')}</label>
              <ul>
                {notes.map((note, index) => (
                  <li key={index}>{t(note)}</li>
                ))}
              </ul>
              <label>{t('WithDraw.wantToLeave')}</label>
            </div>
          </div>
          <form onSubmit={handleSubmit(onOpenConfirmPopup)}>
            <InputWrapper
              id='reason'
              label={t('WithDraw.reason')}
              required={true}
              error={errors?.reason && errors?.reason?.message}>
              <textarea
                {...register('reason', {
                  maxLength: ('ValidateMsg.maxlengthContentMessages'),
                  validate: {
                    validTrim: (v) => !!v?.trim() || ('ValidateMsg.contentMessagesReason'),
                  },
                })}
                placeholder={t('WithDraw.placeholder')}
                maxLength={500}
                className='form-control'
              />
            </InputWrapper>
            <button
              className='btn w-100 btn_submit'
              disabled={!isEmptyObject(errors)}
              type='submit'>
              {t('WithDraw.submit')}
            </button>
          </form>
        </div>
      </LayoutCreatorRight>
    </>
  );
};
export default WithDraw;
