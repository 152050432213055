import { HomeIcon, PurcharseIcon, SearchIcon, VideoIcon } from 'images';
import SettingIcon from 'images/SettingIcon';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import i18n from 'translate/i18n';
import { isAdmin } from '../../utils/utils';
import PopupChoiceTypePost from '../Popup/choiceTypePost';
import Storage from 'utils/storage';
import './index.scss';

const tabs = [
  {
    label: `${i18n.t('MenuSiderbar.home')}`,
    icon: <HomeIcon />,
    router: '/my-page',
    type: 'popup-post',
  },
  {
    label: `${i18n.t('FooterTabs.short')}`,
    icon: <VideoIcon />,
    router: '/timeline',
  },
  {
    label: `${i18n.t('Common.search')}`,
    icon: <SearchIcon />,
    router: '/search-type',
  },

  {
    label: `${i18n.t('FooterTabs.listLiveStream')}`,
    icon: <SettingIcon />,
    router: '/lives/list/live',
  },
  {
    label: `${i18n.t('FooterTabs.salesContent')}`,
    icon: <PurcharseIcon />,
    router: '/subscribe-list',
    router2: '/list-post-buyed',
  },
];

const FooterTabsFan = ({ disabled, classNameCustom, styleCustom }) => {
  const navigate = useNavigate();
  const [isVisiblePopupPost, setIsVisiblePopupPost] = useState(false);
  const { pathname } = useLocation();
  return (
    <>
      <PopupChoiceTypePost
        isVisible={isVisiblePopupPost}
        closeModal={(e) => setIsVisiblePopupPost(false)}
        title={`${i18n.t('FooterTabs.createPost')}`}
      />
      <div
        className={`tabs-footer d-flex fan bg-white justify-content-center ${classNameCustom}`}
        style={{ pointerEvents: disabled ? 'none' : 'auto', ...styleCustom }}
        id='footer_coverout'>
        {tabs.map((tab, index) => (
          <div
            style={{ opacity: disabled ? 0.5 : 1 }}
            key={index}
            className={`item d-flex flex-column justify-content-center align-items-center ${
              isAdmin && 'disabled'
            } ${
              tab?.router?.includes(pathname) || tab?.router2?.includes(pathname)
                ? 'active'
                : ''
            }`}
            onClick={() => {
              if (pathname === '/subscribe-list' && tab.router === '/subscribe-list') {
              } else {
                Storage.set('RESET_DATA_LOAD_PAGE', 'true');
                navigate(tab.router);
              }
            }}>
            {tab.icon}
            <div className='py-sm-1 py-0' style={{ whiteSpace: 'nowrap' }}>
              {tab.label}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default FooterTabsFan;
