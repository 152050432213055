import { LayoutFan, PopupConfirmFan } from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { requestLeave } from '../../store/actions/users';
import './user.scss';

const RequestLeave = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { handleSubmit } = useForm({ mode: 'all' });
  const { loading } = useSelector((state) => state.users);
  const [visiblePopup, setVisiblePopup] = useState(false);
  const { t } = useTranslation();
  const onSubmit = () => {
    setVisiblePopup(true);
  };

  return (
    <div>
      {visiblePopup && (
        <PopupConfirmFan
          isVisiblePopup={false}
          iconNote={true}
          colorSvg='#FF9F43'
          text={t('Popup.requestLeaveConfirm')}
          className='popup-content'>
          <div className='btn cancel' onClick={() => setVisiblePopup(false)}>
            {t('Common.buttonCancel')}
          </div>
          <div className='ok btn' onClick={() => dispatch(requestLeave())}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}

      <LayoutFan titleHeader={t('User.requestLeave.title')} loading={loading}>
        <div className='user-wrapper without-money'>
          <div className='box-user-wrapper'>
            <div className='list-content'>
              <div>
                {t('User.requestLeave.text1')}
                <ul>
                  <li>{t('User.requestLeave.text2')}</li>
                  <li>{t('User.requestLeave.text3')}</li>
                  <li>{t('User.requestLeave.text4')}</li>
                  <li>{t('User.requestLeave.text5')}</li>
                  <li>{t('User.requestLeave.text6')}</li>
                </ul>
              </div>
              <div className='confirm'>{t('User.requestLeave.text7')}</div>
            </div>
            {/* <div className='confirm'>{t('User.requestLeave.text8')}?</div> */}
            <form onSubmit={(e) => e.preventDefault()} className='fansite-form'>
              <InputWrapper id='fsite-submit' className={`button-submit`}>
                <button onClick={handleSubmit(onSubmit)}>{t('Common.buttonLeave')}</button>
              </InputWrapper>
              <InputWrapper id='fsite-submit' className={`button-cancel`}>
                <button onClick={() => navigate('/user/profile')}>
                  {t('Common.buttonCancel')}
                </button>
              </InputWrapper>
            </form>
          </div>
        </div>
      </LayoutFan>
    </div>
  );
};

export default RequestLeave;
