import { useMemo, useRef } from 'react';
import ReactPlayer from 'react-player';
import { decryptPath, funcThumbnailIMG, linkS3Video } from 'utils';
import { useState } from 'react';
import ProgressBarRunTimeShortVideo from './progressBarRunTime';
import { useEffect } from 'react';

const config = {
  attributes: {
    disablePictureInPicture: false,
    controlsList: 'nodownload',
    onContextMenu: (e) => e.preventDefault(),
  },
};

const VideoShortPlayer = ({
  item,
  wasIntersected,
  isIntersecting,
  updateList,
  indexPlay,
  index,
}) => {
  const { medias } = item;
  const refVideo = useRef();
  const [isShowLight, setIsShowLight] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (wasIntersected && isIntersecting) {
      if (index !== indexPlay) {
        refVideo.current?.seekTo(0);
        updateList(index);
      }
    }
  }, [wasIntersected, isIntersecting, index, indexPlay, updateList]);

  useEffect(() => {
    if (isIntersecting && item?.isPlay) {
      setIsShowLight(false);
    }
  }, [item?.isPlay, isIntersecting]);

  useEffect(() => {
    if (!isIntersecting) {
      setIsShowLight(true);
    }
  }, [isIntersecting]);

  const poster = useMemo(() => {
    const thumbnailTMP = item?.medias?.filter(
      (item) => item?.type === 4 && item?.public_flag === 1
    );
    if (isShowLight) {
      if (item?.isPlay && !item?.media_preview?.url) {
        return false;
      }
      if (thumbnailTMP?.length === 0) {
        return `${funcThumbnailIMG(
          decryptPath(item?.media_preview?.url)
        )}?width=32&height=auto`;
      }
      return `${decryptPath(item?.media_preview?.url)}?width=32&height=auto`;
    }
    return false;
  }, [item, isShowLight]);

  return (
    <>
      <ReactPlayer
        ref={(el) => (refVideo.current = el)}
        url={`${linkS3Video}${decryptPath(medias[0].url) + '#t=0.1'}`}
        light={poster}
        playing={item?.isPlay}
        controls={false}
        loop={true}
        config={config}
        muted={item?.isMute}
        playsinline={true}
        playsInline={true}
        width='100%'
        height='100%'
        // onLoadedData={() => {
        //   setIsLoadedVideo(false);
        // }}
        className='video-custom'
        progressInterval={100}
        onProgress={(e) => {
          if (wasIntersected && isIntersecting) {
            setProgress(e?.playedSeconds * 1000);
          }
        }}
      />
      <ProgressBarRunTimeShortVideo
        play={item?.isPlay}
        duration={item?.duration}
        progress={progress}
      />
    </>
  );
};

export default VideoShortPlayer;
