import { newPathUser } from 'constants/layout/constant';
import { ArrowLeftIcon } from 'images';
import { useLocation, useNavigate } from 'react-router-dom';
import Avatar from '../../images/Avatar.png';
import ImgCustom from '../ImgCustom';
import './backToPage.scss';
import { resetDataInfoOther, resetIDHomePage } from 'store/actions/usersNew';
import { useDispatch } from 'react-redux';
const BackToPage = ({
  title,
  uiMobile = false,
  avatar = '',
  exactLink,
  isShowLeftIcon = true,
  isAdmin = false,
  navigateCreator = false,
  accountId = '',
  className = '',
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const goBack = () => {
    // window.scrollTo(0, 0);
    if (location.pathname === '/lives/list/live') {
      if (location.state === null) {
        dispatch(resetIDHomePage());
        dispatch(resetDataInfoOther());
        return navigate(`${newPathUser}`);
      }
    }
    exactLink ? navigate(exactLink) : navigate(-1);
  };

  return (
    <div
      className={`back-to-page ${uiMobile && 'uiMobile'} ${uiMobile && 'fixed-title-fan'} ${
        avatar !== '' && 'has-avatar'
      } ${!isShowLeftIcon && 'no-margin'} ${className}`}>
      {avatar !== '' ? (
        <>
          <div className='left'>
            {isShowLeftIcon ? <ArrowLeftIcon onClick={goBack} /> : ''}
            <div className='title'>{title}</div>
          </div>
          <ImgCustom
            src={avatar.type || avatar || Avatar}
            alt=''
            className={`${isAdmin && 'avatar_admin'}`}
            style={{ cursor: 'pointer' }}
            onClick={() =>
              !isAdmin && navigateCreator && accountId && navigate(`${newPathUser}${accountId}`)
            }
          />
        </>
      ) : (
        <>
          {isShowLeftIcon ? <ArrowLeftIcon onClick={goBack} /> : ''}
          <div className={`title text-center`}>{title}</div>
        </>
      )}
    </div>
  );
};

export default BackToPage;
