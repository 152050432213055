import { PopupAlert, LayoutCreatorRight, PopupC90SelectUser, PopupLoading } from 'components';
import CreatorQuickViewImages from 'components/CreatorQuickViewImage';
import { InputWrapper } from 'components/Input/InputWrapper';
import { pushNotify } from 'components/Notification';
import ProfileAvatar from 'components/Profile-avatar';
import ProfileBackgroundImage from 'components/Profile-background-image';
import { getEnv } from 'configs/env';
import { CREATOR_STATUS_ENUM, CREATOR_STATUS_ENUM_COLOR, SNS_ENUM } from 'constants/shared';
import withCreatorProfilePage, { useCreatorProfile } from 'context/CreatorProfileProvider';
import withCreatorRole from 'hocs/withCreatorRole';
import useUserRole from 'hooks/useUserRole';
import { ArrowLeftIcon, ArrowRightIcon, CloseIcon, TwitterProfileIcon } from 'images';
import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { linkTwitter, unLinkTwitter } from 'store/actions/twitter';
import {
  editCreatorProfile,
  fanListTag,
  getUserInfo,
  getUserInfoMe,
  handleResetDataProfile,
  handleResetDataProfileUser,
} from 'store/actions/users';
import {
  acceptedImageTypes,
  getPresignedUrlS3,
  isEmptyObject,
  REGEX_HTTP,
  setFileNameS3,
  STORAGE,
  TEN_MB,
  uploadS3ByPresigned,
  ENV,
  customStyles,
  CustomOption,
  widthSreen,
  patternCharacterLatinhUnderLineAndDot,
  isProdEnableLiveStream,
} from 'utils';
import Storage from 'utils/storage';
import ButtonSubmit from '../../../../components/Button/ButtonSubmit';
import { PlusOutlined } from '@ant-design/icons';
import './index.scss';
import ListTags from './list-tags';
import NavbarUserProfile from 'components/NavbarEditProfile';
import { newPathUser } from 'constants/layout/constant';
import DragNDrop from 'components/DragNDrop';
import DeleteIcon from 'images/DeleteIcon';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import i18n from 'translate/i18n';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { isMobile } from 'react-device-detect';
import { getUserInfoOthers, resetDataInfoOther, resetIDHomePage } from 'store/actions/usersNew';

const CreatorProfile = () => {
  // const { register, errors, handleSubmit, setValue, setError, clearErrors, watch } =
  //   useCreatorProfile();
  const { t } = useTranslation();
  const {
    loading,
    data: userProfile,
    category,
    creatorUpdateProfileBasicFailed,
    creatorUpdateProfileBasicSuccess,
  } = useSelector((state) => state.users);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm({
    mode: 'all',
  });

  const [isVisiblePopupTags, setIsVisiblePopupTags] = useState(false);
  const [listChecked, setListChecked] = useState([]);
  const [dataCheckBox, setDataCheckBox] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUnlinkTwitter, setUnlinkTwitter] = useState(false);
  const [showPopupSuccess, setShowPopupSuccess] = useState();
  let dataS3Update = {};
  const [listUserSelected, setListUserSelected] = useState([]);
  const [isVisiblePopupUser, setIsVisiblePopupUser] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateToHomePage = () => navigate(`${newPathUser}${dataProfile?.account_id || ''}`);
  const isTokenTwitter = Storage.has(STORAGE.TOKEN_TWITTER);
  const isSecretTokenTwitter = Storage.has(STORAGE.SERECT_TOKEN_TWITTER);
  const { isIdol } = useUserRole();
  const ref = useRef();
  const [avatarTmp, setAvatarTmp] = useState();
  const [bgTmp, setBgTmp] = useState();
  const [serviceList, setServiceList] = useState([{ id: 0, url: '' }]);
  const [SNSOption, setSNSOption] = useState([]);
  const [flgListTag, setFlgListTag] = useState(false);
  const [focusSNS, setFocusSNS] = useState(false);
  const [errorBG, setErrorBG] = useState('');
  const [errorAvar, setErrorAvar] = useState('');
  const [dataProfile, setDataProfile] = useState('');
  const API = {
    getPoint: () => `${ENV}/sns`,
  };

  useEffect(() => {
    return () => {
      dispatch(handleResetDataProfile());
    }
  }, [dispatch]);

  const handleServiceChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...serviceList];
    list[index][name] = value;
    setServiceList(list);
  };

  const handleServiceRemove = (index) => {
    const newList = [...serviceList];
    newList.splice(index, 1);
    setServiceList(newList);
  };

  const handleServiceAdd = () => {
    setServiceList([...serviceList, { id: 0, url: '' }]);
  };

  // handle input change
  const handleInputChange = (val, index) => {
    const newData = [...serviceList].map((item, idx) => {
      if (idx !== index) return item;
      return {
        ...item,
        id: val.value,
      };
    });
    setServiceList(newData);
  };

  const formValidation = (formVal) => {
    const data = [...formVal];
    let valid = true;
    for (let index = 0; index < data.length; index++) {
      // if (data[index].id === 0) {
      //   data[index].snsSelect = `${t('ValidateMsg.idSNSRequired')}`;
      //   data[index].urlCheck = '';
      //   data[index].urlFormat = '';
      //   valid = false;
      // } else if (data[index].url === '') {
      //   data[index].urlCheck = `${t('ValidateMsg.urlSNSRequired')}`;
      //   data[index].urlFormat = '';
      //   data[index].snsSelect = '';
      //   valid = false;
      if (data[index].id !== 0) {
        if (
          !REGEX_HTTP.test(serviceList[index].url.toString().trim()) ||
          data[index].url === ''
        ) {
          data[index].urlFormat = `${t('ValidateMsg.invalidUrl')}`;
          data[index].urlCheck = '';
          data[index].snsSelect = '';
          valid = false;
        } else {
          data[index].urlCheck = '';
          data[index].urlFormat = '';
          data[index].snsSelect = '';
          valid = true;
        }
      }
    }
    setServiceList(data);
    return valid;
  };

  useEffect(() => {
    callAPISNS();
  }, []);

  const callAPISNS = async () => {
    await fetch(API.getPoint(), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      },
    })
      .then((rs) => rs.json())
      .then((data) => {
        setSNSOption(data.data);
      });
  };

  useEffect(() => {
    dispatch(getUserInfo(({ data }) => setDataProfile(data)));
  }, []);

  useEffect(() => {
    dispatch(fanListTag(i18n.language === 'jp' ? 'ja' : 'en'));
  }, []);

  useEffect(() => {
    creatorUpdateProfileBasicFailed && setIsLoading(false);
  }, [creatorUpdateProfileBasicFailed]);

  useEffect(() => {
    creatorUpdateProfileBasicSuccess && setShowPopupSuccess(true);
    setIsLoading(false);
  }, [creatorUpdateProfileBasicSuccess]);

  const CREATOR_STATUS_ENUM_NEW = {
    1: i18n.t('User.status1'),
    2: i18n.t('User.status2'),
    3: i18n.t('User.status3'),
    4: i18n.t('User.status4'),
    5: i18n.t('User.status5'),
    7: i18n.t('User.status7'),
  };

  useEffect(() => {
    if (userProfile) {
      setValue('avatar', userProfile.avatar);
      setValue('bg_img', userProfile.bg_img);
      setValue('account_name', userProfile.account_name);
      setValue('account_id', userProfile.account_id);
      setValue('description', userProfile.description);
      setValue('user_image1', userProfile?.user_image1);
      setValue('user_image2', userProfile?.user_image2);
      setValue('user_image3', userProfile?.user_image3);
      setValue('ng_words', userProfile?.ng_words || '');
      setValue('link_youtube', userProfile?.link_youtube || '');
      setValue('link_facebook', userProfile?.link_facebook || '');
      setValue('link_instagram', userProfile?.link_instagram || '');
      if (userProfile?.user_sns.length > 0) {
        setServiceList(userProfile?.user_sns);
      }
      setListChecked(userProfile?.user_tags.map((item) => item.tag_id));
      setListUserSelected(
        userProfile.user_distributions.map((item) => {
          return { ...item, id: item.fan_id, account_name: item?.account_name };
        })
      );
    }
  }, [userProfile]);

  const watchDescription = watch('description', '');
  const watchAvatar = watch('avatar', undefined);
  const watchCover = watch('bg_img', undefined);
  const watchImage1 = watch('user_image1', undefined);
  const watchImage2 = watch('user_image2', undefined);
  const watchImage3 = watch('user_image3', undefined);
  const watchListImage = watch('user_images', undefined);
  const accountIDtmp = watch('account_id');

  useEffect(() => {
    if (category?.length > 0) {
      if (dataProfile) {
        if (userProfile?.user_tags?.length > 0) {
          const newListChecked = category.map((item) => {
            userProfile?.user_tags.map((tag) => {
              if (tag.tag_id === item.id) {
                item = { ...item, checked: true };
              }
            });
            return item;
          });
          setDataCheckBox(newListChecked);
        } else {
          setDataCheckBox(category);
        }
      }
    }
  }, [category, dataProfile]);

  const customStyleReactSelect = {
    ...customStyles,
    dropdownIndicator: (base) => ({
      ...base,
      display: 'block',
    }),
    singleValue: (base) => ({
      ...base,
      fontWeight: 500,
      fontSize: 14,
      position: 'absolute',
      padding: '2px 20px 2px 0',
      color: '#1a1a1a',
    }),
    menu: (base, state) => ({
      ...base,
      zIndex: 201,
    }),
    control: (base, state) => {
      return {
        ...base,
        width: '100%',
        svg: {
          color: '#666565',
          transform: state.menuIsOpen ? 'rotate(180deg)' : '',
        },
        borderRadius: '6px',
        boxShadow: 'none',
        borderColor: state.isFocused ? '#1480FF' : '#E5E6EB',
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
      };
    },
    option: (base, state) => {
      return {
        ...base,
        position: 'relative',
        backgroundColor: state.isSelected ? '#FFDBDB' : 'transparent',
        color: '#1A1A1A',
        '&.content-right': {
          color: '#DC143C',
        },
        svg: {
          color: '#DC143C',
          display: state.isSelected ? 'block' : 'none',
        },
        fontWeight: state.isSelected ? 500 : 400,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 19px 12px 16px',
        gap: '5px',
      };
    },
  };

  const handleOnchangeTags = (e, pack) => {
    const { checked } = e.target;
    if (checked) {
      setListChecked([...listChecked, pack.id]);
      const newListChecked = dataCheckBox.map((item) => {
        if (pack?.id === item?.id) {
          item = { ...item, checked: true };
        }
        return item;
      });
      setDataCheckBox(newListChecked);
    } else {
      let indexCheck = listChecked.indexOf(pack.id);
      if (indexCheck !== -1) {
        listChecked.splice(indexCheck, 1);
        setListChecked(listChecked);
      }
      const newListChecked = dataCheckBox.map((item) => {
        if (pack?.id === item?.id) {
          item = { ...item, checked: false };
        }
        return item;
      });
      setDataCheckBox(newListChecked);
    }
  };

  const handleListSelectedUser = (e) => {
    setListUserSelected(e);
  };

  const handleRemoveUser = (e) => {
    setListUserSelected(listUserSelected.filter((item) => item.id !== e.id));
  };

  const handleBack = (status) => {
    dispatch(getUserInfoMe());
    dispatch(getUserInfoOthers(accountIDtmp));
    dispatch(resetIDHomePage());
    if (status === 3 || status === 4) {
      dispatch(handleResetDataProfileUser())
      navigate(`${newPathUser}${accountIDtmp}`, {
        state: { name: 'homepage' },
      });
      setShowPopupSuccess(false);
    } else {
      dispatch(handleResetDataProfileUser())
      navigate(`${newPathUser}${accountIDtmp}`, {
        state: { name: 'homepage' },
      });
      setShowPopupSuccess(false);
    }
  };


  const optionListSNS = useMemo(() => {
    return (
      SNSOption?.map((item) => ({
        value: item?.id,
        label: SNS_ENUM[item?.id],
      })) || []
    );
  }, [SNSOption]);

  const onSubmit = async (data) => {
    const errorRes = formValidation(serviceList);
    if (errorRes) {
      await setIsLoading(true);
      const avatarImage = avatarTmp || '';
      const coverImage = bgTmp || '';
      data = {
        ...data,
        user_distributions: listUserSelected.map(({ id }) => id),
        tag_ids: listChecked,
      };

      if (
        typeof watchImage1 !== 'string' &&
        watchImage1 !== undefined &&
        watchImage1 !== null
      ) {
        const resultImage1 = await getPresignedUrlS3(
          'image',
          'uploads/output/images/identifier',
          setFileNameS3(watchImage1.name)
        );
        if (resultImage1?.data) {
          dataS3Update = {
            ...dataS3Update,
            user_image1: resultImage1?.data?.file_path,
            user_image1_pre_signed: resultImage1?.data?.pre_signed,
            user_image1_file: watchImage1,
          };
        }
      }

      if (
        typeof watchImage2 !== 'string' &&
        watchImage2 !== undefined &&
        watchImage2 !== null
      ) {
        const resultImage2 = await getPresignedUrlS3(
          'image',
          'uploads/output/images/identifier',
          setFileNameS3(watchImage2.name)
        );
        if (resultImage2?.data) {
          dataS3Update = {
            ...dataS3Update,
            user_image2: resultImage2?.data?.file_path,
            user_image2_pre_signed: resultImage2?.data?.pre_signed,
            user_image2_file: watchImage2,
          };
        }
      }

      if (
        typeof watchImage3 !== 'string' &&
        watchImage3 !== undefined &&
        watchImage3 !== null
      ) {
        const resultImage3 = await getPresignedUrlS3(
          'image',
          'uploads/output/images/identifier',
          setFileNameS3(watchImage3.name)
        );
        if (resultImage3?.data) {
          dataS3Update = {
            ...dataS3Update,
            user_image3: resultImage3?.data?.file_path,
            user_image3_pre_signed: resultImage3?.data?.pre_signed,
            user_image3_file: watchImage3,
          };
        }
      }
      //if (watchAvatar) {
      if (avatarImage) {
        const resultAvatar = await getPresignedUrlS3(
          'image',
          'uploads/output/images/creator/avatar',
          setFileNameS3(avatarImage.name)
        );
        if (resultAvatar?.data) {
          dataS3Update = {
            ...dataS3Update,
            avatar: resultAvatar?.data?.file_path,
            avatar_pre_signed: resultAvatar?.data?.pre_signed,
            avatar_file: avatarImage,
          };
        }
      }
      //}

      //if (watchCover) {
      if (coverImage) {
        const resultCover = await getPresignedUrlS3(
          'image',
          'uploads/output/images/creator/cover',
          setFileNameS3(coverImage.name)
        );
        if (resultCover?.data) {
          dataS3Update = {
            ...dataS3Update,
            bg_img: resultCover?.data?.file_path,
            bg_img_pre_signed: resultCover?.data?.pre_signed,
            bg_img_file: coverImage,
          };
        }
      }
      //}
      // let newListImages = [];
      // if (watchListImage) {
      //   newListImages = await Promise.all(
      //     watchListImage.map(async (item) => {
      //       if (item !== undefined) {
      //         if (item?.url) {
      //           return item.url;
      //         } else {
      //           const resultListImage = await getPresignedUrlS3(
      //             'image',
      //             'uploads/output/images/creator',
      //             setFileNameS3(item?.name)
      //           );
      //           if (resultListImage.data) {
      //             await uploadS3ByPresigned(resultListImage?.data?.pre_signed, item);
      //             return resultListImage?.data?.file_path;
      //           }
      //         }
      //       }
      //     })
      //   );
      //   newListImages = newListImages.filter((item) => item !== undefined);
      //   data = {
      //     ...data,
      //     url: newListImages,
      //   };
      // }
      let newListImages = [
        watchImage1 === undefined
          ? null
          : dataS3Update.user_image1
          ? dataS3Update.user_image1
          : data.user_image1,
        watchImage2 === undefined
          ? null
          : dataS3Update.user_image2
          ? dataS3Update.user_image2
          : data.user_image2,
        watchImage3 === undefined
          ? null
          : dataS3Update.user_image3
          ? dataS3Update.user_image3
          : data.user_image3,
      ];
      newListImages = newListImages.filter((item) => item !== undefined);

      const {
        avatar,
        avatar_pre_signed,
        avatar_file,
        bg_img,
        bg_img_pre_signed,
        bg_img_file,
        user_image1,
        user_image1_pre_signed,
        user_image1_file,
        user_image2,
        user_image2_pre_signed,
        user_image2_file,
        user_image3,
        user_image3_pre_signed,
        user_image3_file,
      } = dataS3Update;

      data = {
        ...data,
        user_images: newListImages,
        sns: serviceList.filter((i) => i.id !== 0),
        avatar: avatarTmp === null ? null : avatar,
        bg_img: bgTmp === null ? null : bg_img,
      };

      if (avatar) {
        await uploadS3ByPresigned(avatar_pre_signed, avatar_file);
      }
      if (bg_img) {
        await uploadS3ByPresigned(bg_img_pre_signed, bg_img_file);
      }

      if (user_image1) {
        await uploadS3ByPresigned(user_image1_pre_signed, user_image1_file);
      }

      if (user_image2) {
        await uploadS3ByPresigned(user_image2_pre_signed, user_image2_file);
      }

      if (user_image3) {
        await uploadS3ByPresigned(user_image3_pre_signed, user_image3_file);
      }

      //delete data.user_images;
      delete dataS3Update.avatar_file;
      delete dataS3Update.avatar_pre_signed;
      delete dataS3Update.bg_img_file;
      delete dataS3Update.bg_img_pre_signed;
      delete dataS3Update.user_image2_file;
      delete dataS3Update.user_image1_pre_signed;
      delete dataS3Update.user_image2_file;
      delete dataS3Update.user_image2_pre_signed;
      delete dataS3Update.user_image3_file;
      delete dataS3Update.user_image3_pre_signed;

      dispatch(editCreatorProfile({ ...data, ...dataS3Update }));
    }
  };
  const handleLinkOrUnlinkTwitter = (link) => {
    setIsLoading(true);
    link
      ? dispatch(
          linkTwitter(
            {
              token: Storage.get(STORAGE.TOKEN_TWITTER),
              secret: Storage.get(STORAGE.SERECT_TOKEN_TWITTER),
            },
            null,
            () => {
              setIsLoading(false);
              pushNotify('success', t('ValidateMsg.linkTwitter'));
              dispatch(getUserInfo());
            },
            () => setIsLoading(false)
          )
        )
      : dispatch(
          unLinkTwitter(
            () => {
              setIsLoading(false);
              setUnlinkTwitter(true);
              pushNotify('success', t('ValidateMsg.unLinkTwitter'));
              dispatch(getUserInfo());
            },
            () => setIsLoading(false)
          )
        );
  };

  const handleEnter = (e) => {
    if (e.keyIdentifier === 'U+000A' || e.keyIdentifier === 'Enter' || e.keyCode === 13) {
      e.preventDefault();
      return false;
    }
  };

  return (
    <React.Fragment>
      {showPopupSuccess && (
        <PopupAlert
          header=''
          content={t('Popup.saved')}
          isVisiblePopup={() => setShowPopupSuccess(false)}>
          <button
            className='btn btn-danger'
            onClick={() => {
              handleBack(userProfile?.status);
            }}>
            {t('Common.Ok')}
          </button>
        </PopupAlert>
      )}
      {isVisiblePopupTags && (
        <div className='popup popup-select-tag'>
          <div className='popup-content'>
            <div className='popup-header'>
              {t('Popup.categoryFavorite')}
              <CloseIcon
                className='close-icon cursor-pointer'
                onClick={() => setIsVisiblePopupTags(false)}
              />
            </div>
            <div className='popup-body'>
              {dataCheckBox && dataCheckBox?.length > 0 && (
                <ListTags handleSelectTags={handleOnchangeTags} data={dataCheckBox} />
              )}
              {dataCheckBox?.length === 0 && (
                <span className='text-danger'>{t('Common.doesNotPackage')}</span>
              )}
            </div>
            <div className='popup-footer'>
              <button
                type='button'
                className='btn btn-outline-secondary'
                onClick={() => setIsVisiblePopupTags(false)}>
                {t('Common.buttonCancel')}
              </button>
              <button
                className='btn btn-submit btn-danger'
                type='button'
                disabled={listChecked.length < 0}
                onClick={() => setIsVisiblePopupTags(false)}>
                {t('Common.Ok')}
              </button>
            </div>
          </div>
        </div>
      )}
      {isLoading && <PopupLoading />}
      <LayoutCreatorRight
        titlePage={t('ProfileSetting.titleProfileSetting')}
        className='profile'>
        <div style={{ position: 'absolute', top: 0 }} ref={ref} />
        <div className='position-relative bg-white profile'>
          <ProfileBackgroundImage
            name='bg_img'
            maxSize={TEN_MB}
            isSelected={watchCover}
            setBgTmp={setBgTmp}
            defaultValue={userProfile?.bg_img || ''}
            setErrorBG={setErrorBG}
          />
          <div className={`${isMobile ? 'px-3' : 'px-4'}`}>
            <ProfileAvatar
              setAvatarTmp={setAvatarTmp}
              name='avatar'
              maxSize={TEN_MB}
              isSelected={watchAvatar}
              defaultValue={userProfile?.avatar || ''}
              setErrorAvar={setErrorAvar}
            />
            <div>
              <NavbarUserProfile
                defaultActive={1}
                resetPage={() => {
                  //setPage(1);
                  //loadingFunc();
                }}
                className='navbar-edit-profile'
              />
              {loading && !isLoading && !isUnlinkTwitter ? (
                <div style={{ padding: '0px' }}>
                  <ShimmerPostDetails className='shimmer-loading' />
                </div>
              ) : (
                <>
                  <form encType='multipart/form-data'>
                    <InputWrapper
                      id='fsite-account-name'
                      label={t('Common.nameJPNew')}
                      required={true}
                      error={errors?.account_name ? errors?.account_name?.message : ''}>
                      <input
                        maxLength={30}
                        className='fsite-input'
                        type='text'
                        name='account_name'
                        {...register('account_name', {
                          required: ('ValidateMsg.USERNAME_IS_REQUIRED'),
                          maxLength: {
                            value: 30,
                            message: ('ValidateMsg.C002_VALIDATE_ACCOUNT_MAXSIZE'),
                          },
                        })}
                        onKeyDown={(e) => handleEnter(e)}
                        // onBlur={(e) => {
                        //   setValue('account_name', e.target.value?.toString().trim());
                        //   if (!e.target.value?.toString().trim()) {
                        //     setError('account_name', {
                        //       type: 'required',
                        //       message: t('ValidateMsg.USERNAME_IS_REQUIRED'),
                        //     });
                        //   }
                        //   clearErrors('account_name');
                        // }}
                        placeholder={t('ProfileSetting.plahodleCharacters')}
                      />
                    </InputWrapper>
                    <InputWrapper
                      id='fsite-account-id'
                      label={t('Common.accountID')}
                      required={true}
                      error={errors?.account_id ? errors?.account_id?.message : ''}>
                      <input
                        type='text'
                        className='fsite-input'
                        name='account_id'
                        {...register('account_id', {
                          required: ('ValidateMsg.C002_VALIDATE_ACCOUNT_ID_REQUIRED'),
                          maxLength: {
                            value: 30,
                            message: ('ValidateMsg.C002_VALIDATE_ACCOUNT_ID_MAXSIZE'),
                          },
                          pattern: {
                            value: patternCharacterLatinhUnderLineAndDot,
                            message: ('ValidateMsg.C002_VALIDATE_ACCOUNT_ID_FORMAT'),
                          },
                        })}
                        onKeyDown={(e) => handleEnter(e)}
                      />
                    </InputWrapper>
                    <InputWrapper
                      id='fsite-account-description'
                      label={t('ProfileSetting.description')}
                      //note={t('ValidateMsg.maxLengthProfileDesc')}
                      countLength={watchDescription?.toString().trim()?.length}
                      maxLength={2000}
                      //required={true}
                      error={errors?.description ? errors?.description?.message : ''}>
                      <textarea
                        type='text'
                        name='description'
                        placeholder={t('ProfileSetting.mandatory')}
                        className='fsite-input'
                        maxLength={2000}
                        {...register('description', {
                          //required: t('ProfileSetting.selfIntroduction'),
                          maxLength: {
                            value: 2000,
                            message: ('ProfileSetting.pleaseEnterSelfIntroduction'),
                          },
                        })}
                        onBlur={(e) => {
                          setValue('description', e.target.value?.toString().trim());
                          // if (!e.target.value?.toString().trim()) {
                          //   setError('description', {
                          //     type: 'required',
                          //     message: t('ProfileSetting.selfIntroduction'),
                          //   });
                          // }
                        }}
                      />
                    </InputWrapper>
                    {/* <InputWrapper
                  id='fsite-creator-search-word'
                  label={t('ProfileSetting.creatorSearch')}
                  error={errors?.search_words ? errors?.search_word?.message : ''}>
                  <input
                    type='text'
                    name='search_words'
                    placeholder={t('ProfileSetting.creatorSearch')}
                    className='fsite-input'
                    maxLength={500}
                    {...register('search_words')}
                    onBlur={(e) => {
                      setValue('search_words', e.target.value?.toString().trim());
                    }}
                  />
                </InputWrapper> */}
                    <InputWrapper id='fsite-category' label={t('Search.tabGenre')}>
                      <ListTags
                        handleSelectTags={handleOnchangeTags}
                        data={dataCheckBox}
                        limit={isMobile && widthSreen < 767 ? 6 : 12}
                        flgListTag={flgListTag}
                      />
                      <div className='row'>
                        {dataCheckBox?.length === 0 && (
                          <span className='text-danger'>{t('Common.doesNotPackage')}</span>
                        )}
                      </div>
                      {dataCheckBox.length > 12 && (
                        <div className='text-start mt-3'>
                          <span
                            className='text-start text-hypelink-profile'
                            role='button'
                            onClick={() => setFlgListTag(!flgListTag)}>
                            {!flgListTag ? t('Common.seeNext') : t('Common.sidebar')}
                            <ArrowRightIcon
                              className={`${!flgListTag ? 'active-tag' : 'not-active-tag'}`}
                            />
                          </span>
                        </div>
                      )}
                    </InputWrapper>

                    <InputWrapper
                      id='fsite-main-images1'
                      label={t('ProfileSetting.imageSetting')}
                      error={
                        errors?.user_image1
                          ? errors?.user_image1.message
                          : errors?.user_image2
                          ? errors?.user_image2.message
                          : errors?.user_image3
                          ? errors?.user_image3.message
                          : ''
                      }>
                      <div className='row'>
                        <div className='col-sm-12 col-md-4'>
                          <DragNDrop
                            widthBlue={`${isMobile ? 378 : 212}`}
                            heightBlue={241}
                            is_required={false}
                            label={t('SignUp.supportImg')}
                            name='user_image1'
                            setValue={setValue}
                            register={register}
                            setError={setError}
                            clearErrors={clearErrors}
                            maxSize={TEN_MB}
                            isSelected={watchImage1}
                            defaultValue={userProfile?.user_image1}
                            accept={acceptedImageTypes.map((item) => item).join(',')}
                          />
                        </div>
                        <div className='col-sm-12 col-md-4'>
                          <DragNDrop
                            widthBlue={`${isMobile ? 378 : 212}`}
                            heightBlue={241}
                            is_required={false}
                            label={t('SignUp.supportImg')}
                            name='user_image2'
                            setValue={setValue}
                            register={register}
                            setError={setError}
                            clearErrors={clearErrors}
                            maxSize={TEN_MB}
                            isSelected={watchImage2}
                            defaultValue={userProfile?.user_image2}
                            accept={acceptedImageTypes.map((item) => item).join(',')}
                          />
                        </div>
                        <div className='col-sm-12 col-md-4'>
                          <DragNDrop
                            widthBlue={`${isMobile ? 378 : 212}`}
                            heightBlue={241}
                            is_required={false}
                            label={t('SignUp.supportImg')}
                            name='user_image3'
                            setValue={setValue}
                            register={register}
                            setError={setError}
                            clearErrors={clearErrors}
                            maxSize={TEN_MB}
                            isSelected={watchImage3}
                            defaultValue={userProfile?.user_image3}
                            accept={acceptedImageTypes.map((item) => item).join(',')}
                          />
                        </div>
                      </div>
                    </InputWrapper>
                    <InputWrapper id='fsite-status'>
                      <div className=''>
                        <p>{t('User.statusAccount')}</p>
                        <div
                          className={`detail-btn btn ${
                            CREATOR_STATUS_ENUM_COLOR[userProfile?.status]
                          }-status`}>
                          {CREATOR_STATUS_ENUM_NEW[userProfile?.status]}
                        </div>
                      </div>
                    </InputWrapper>

                    <div className='form-status'>
                      <div
                        className='label d-flex justify-content-center align-items-center'
                        style={{ cursor: 'pointer' }}>
                        {t('User.statusIntegrationTwitter')} -
                        {userProfile?.twitter_linked === 1 ? (
                          <div
                            className='btn-link-or-unlink-twitter'
                            onClick={() => handleLinkOrUnlinkTwitter(false)}>
                            &nbsp;
                            {t('User.buttonUnLinkTwitter')}
                            <TwitterProfileIcon className='icon-twitter' />
                          </div>
                        ) : (
                          <>
                            {isTokenTwitter && isSecretTokenTwitter ? (
                              <div
                                className='btn-link-or-unlink-twitter'
                                onClick={() => handleLinkOrUnlinkTwitter(true)}>
                                {t('User.buttonLinkTwitter')}
                                <TwitterProfileIcon className='icon-twitter' />
                              </div>
                            ) : (
                              <a
                                href={getEnv(
                                  isIdol
                                    ? 'REACT_APP_API_TWITTER_CREATOR'
                                    : 'REACT_APP_API_TWITTER_FAN'
                                )}
                                className={`twitter btn-link-or-unlink-twitter`}
                                target='popup'
                                onClick={() => {
                                  window.open(
                                    getEnv(
                                      isIdol
                                        ? 'REACT_APP_API_TWITTER_CREATOR'
                                        : 'REACT_APP_API_TWITTER_FAN'
                                    ),
                                    'popup',
                                    'width=600,height=600,scrollbars=no,resizable=no'
                                  );
                                }}>
                                {t('User.buttonLinkTwitter')}
                                <TwitterProfileIcon className='icon-twitter' />
                              </a>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div
                      className={`${
                        userProfile?.twitter_linked === 0
                          ? 'statustweetRed'
                          : 'statustweetGreen'
                      }`}>
                      {t(
                        userProfile?.twitter_linked === 0
                          ? t('User.noCooperate')
                          : t('User.cooperated')
                      )}
                    </div>
                    {+isProdEnableLiveStream === 1 && (
                      <InputWrapper
                        id='fsite-ngWords'
                        label={t('ProfileSetting.NGWordSetting')}
                        error={errors?.ng_words ? errors?.ng_words?.message : ''}>
                        <input
                          type='text'
                          className='fsite-input'
                          placeholder='NG1, NG2, NG3'
                          name='ng_words'
                          {...register('ng_words')}
                          onBlur={(e) => {
                            setValue('ng_words', e.target.value?.toString().trim());
                          }}
                          maxLength={500}
                        />
                      </InputWrapper>
                    )}

                    <div className='sns'>
                      <div className='form-field'>
                        <label htmlFor='service' className='mb-2'>
                          {t('User.snsLink')}
                          {/* <span className='fansite-form-input__required'>*</span> */}
                        </label>
                        {serviceList.map((item, index) => (
                          <>
                            <div key={index} className='services'>
                              <div
                                className={`${
                                  serviceList?.length === 1
                                    ? 'first-division1'
                                    : 'first-division'
                                }`}>
                                <div style={{ display: 'flex' }}>
                                  <div className='sns-select'>
                                    <Controller
                                      name='total_live_time2'
                                      control={control}
                                      render={({ field }) => (
                                        <Select
                                          value={
                                            optionListSNS?.find((e) => e.value === item.id) ??
                                            ''
                                          }
                                          options={optionListSNS}
                                          onChange={(val) => {
                                            handleInputChange(val, index);
                                          }}
                                          placeholder={t('User.selectSNS')}
                                          styles={customStyleReactSelect}
                                          isSearchable={false}
                                          components={{
                                            Option: CustomOption,
                                            DropdownIndicator: () => <ArrowLeftIcon />,
                                          }}
                                        />
                                      )}
                                    />
                                  </div>
                                  <input
                                    disabled={item.id === 0}
                                    placeholder={t('User.SNSplaceholder')}
                                    name='url'
                                    type='text'
                                    id='url'
                                    value={item.url}
                                    onKeyDown={(e) => handleEnter(e)}
                                    onChange={(e) => handleServiceChange(e, index)}
                                  />
                                </div>
                                <p className='fansite-form-input__error text-danger'>
                                  {item.snsSelect}
                                  {item.urlCheck}
                                  {item.urlFormat}
                                </p>
                                {serviceList.length - 1 === index && (
                                  <button
                                    type='button'
                                    onClick={handleServiceAdd}
                                    className='add-btn'>
                                    <PlusOutlined className='add-answer-icon_plus' />
                                    <span
                                      className='ms-2'
                                      style={{
                                        fontSize: '14px',
                                        color: '#1A1A1A',
                                        fontWeight: '400',
                                      }}>
                                      {t('User.addSNS')}
                                    </span>
                                  </button>
                                )}
                              </div>
                              <div className=''>
                                {serviceList.length > 0 && (
                                  <>
                                    <div
                                      className='btn-dele'
                                      onClick={() => {
                                        if (serviceList.length === 1) {
                                          setServiceList([{ id: 0, url: '' }]);
                                          return;
                                        } else {
                                          handleServiceRemove(index);
                                        }
                                      }}>
                                      <DeleteIcon />
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                    <br />
                    <div
                      className={`${isMobile ? 'ps-bottom-moblie' : 'px-4 ps-bottom'}`}>
                      <InputWrapper>
                        <div className='row wrapper__btn-comfirm'>
                          <div className='col-12 col-sm-6 col-md-6 mb-3 btn_keep'>
                            <ButtonSubmit
                              className={`btn bg-red w-100 font-weight-700 text-white app-btn ${
                                !isMobile && 'mr-top'
                              }`}
                              disabled={
                                isLoading ||
                                !isEmptyObject(errors) ||
                                errorBG !== '' ||
                                errorAvar !== ''
                              }
                              onClick={handleSubmit(!isVisiblePopupUser && onSubmit)}>
                              {t('Common.keep')}
                            </ButtonSubmit>
                          </div>
                          <div className='col-12 col-sm-6 col-md-6 mb-3 btn_return'>
                            <button
                              className={`btn w-100 font-weight-700 border app-btn bg-white bg-while ${
                                !isMobile && 'mr-top'
                              }`}
                              onClick={navigateToHomePage}>
                              {t('Common.return')}
                            </button>
                          </div>
                        </div>
                      </InputWrapper>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      </LayoutCreatorRight>
    </React.Fragment>
  );
};

export default withCreatorRole(withCreatorProfilePage(CreatorProfile));
