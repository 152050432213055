/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';

const initialState = {
  status: null,
  loading: false,
  error: null,
  paymentCards: [],
  idCardActive: null,
  pagination: {},
  idScrollBackCard: null,
  idScrollBackCardVendo: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_CARDS_CREDIT_SUCCESS:
      if (+action?.data?.pagination?.current_page === 1) {
        return {
          ...state,
          pagination: action?.data?.pagination,
          cards: action.data.data,
        };
      }
      return {
        ...state,
        cards: state.cards.concat(action.data.data),
        pagination: action?.data?.pagination,
      };
    case TYPES.GET_PAYMENT_CARDS_CREDIT_SUCCESS:
      if (+action?.data?.pagination?.current_page === 1) {
        return {
          ...state,
          pagination: action?.data?.pagination,
          paymentCards: action?.data.data,
        };
      }
      return {
        ...state,
        pagination: action?.data?.pagination,
        paymentCards: state.cards.concat(action.data.data),
      };
    case TYPES.CREATE_PAYMENT_CARD_CREDIT_SUCCESS:
      return {
        ...state,
        // pagination: action?.data?.pagination,
        // paymentCards: state.cards.concat(action.data.data),
      };
    case TYPES.SET_ID_CARD_ACTIVE_SUCCESS:
      return {
        ...state,
        idCardActive: action.data.id,
      };
    case TYPES.CHANGE_DEFAULT_CARD_SUCCESS:
      return {
        ...state,
        cards: state.cards?.map((e) => {
          if (e?.id === +action?.data?.id) {
            return {
              ...e,
              default: 1,
            };
          }
          return {
            ...e,
            default: 0,
          };
        }),
      };
    case 'SET_DATA_PAYMENT_CARD':
      return {
        ...state,
        paymentCards: action.data.data,
      };
    case 'CLEAR_SCROLL_ID_AND_DATA_CARDS':
      return {
        ...state,
        paymentCards: [],
        idScrollBackCard: null,
        pagination: {},
      };
    case 'CLEAR_SCROLL_ID_AND_DATA_CARDS_VENDO':
      return {
        ...state,
        cards: [],
        idScrollBackCardVendo: null,
        pagination: {},
      };
    case 'CLEAR_CREDIT_CARDS':
      return {
        ...state,
        //cards: [],
      };
    case 'SET_ID_SCROLL_BACK_CARD':
      return {
        ...state,
        idScrollBackCard: action.data?.data?.id || null,
      };
    case 'SET_ID_SCROLL_BACK_CARD_VENDO':
      return {
        ...state,
        idScrollBackCardVendo: action.data?.data?.id || null,
      };
    case 'CLEAR_ACTIVE_CARDS':
      return {
        ...state,
        idCardActive: null,
      };
    default:
      return state;
  }
};
