import { useTranslation } from 'react-i18next';
import './index.scss';
export default function UploadImageConfirmationModal({
  isOpen,
  onResolve,
  onReject,
  isSelected,
  defaultValue,
  preview,
  isPdf,
  isDoc
}) {
  const { t } = useTranslation();
  return isOpen ? (
    <div className='popup'>
      <div className='popup-content height-fit-content px-2 py-3'>
        <div className='content justify-content-center align-items-center flex-column'>
          <p className={`text-center my-2 button-upload`} role='button' onClick={onResolve}>
            {isDoc ? t('DragNDrop.docUpload') : isPdf ? t('DragNDrop.pdfUpload') : t(`UploadImageConfirmationModal.buttonUploadImg`)}
          </p>
          {((isSelected?.length === undefined && typeof isSelected === 'object' && preview) ||
            (preview)) && (
            <p className='text-center my-2 button-preview' role='button' onClick={onReject}>
              {t(`UploadImageConfirmationModal.buttonDeleteImg`)}
            </p>
          )}
          <p className={`text-center my-2 button-cancel`} role='button' onClick={() => onReject('dismiss')}>
            {t(`Common.buttonCancel`)}
          </p>
        </div>
      </div>
    </div>
  ) : null;
}
