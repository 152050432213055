import Icon from '@ant-design/icons';

const Top3Icon = (props) => (
  <Icon
    component={() => (
      <svg
        width='18'
        height='24'
        viewBox='0 0 18 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7.96735 17.8965L4.36988 16.6973L3.31529 16.6914L1.47062 21.9913C1.41183 22.1601 1.54862 22.3328 1.72641 22.3141L4.31218 22.0434C4.38441 22.0358 4.45623 22.0608 4.50816 22.1116L6.36699 23.9293C6.49477 24.0543 6.70918 24.0039 6.76791 23.8351L8.62468 18.5005L7.96735 17.8965Z'
          fill='#0083FD'
        />
        <path
          d='M8.62477 18.501L8.99996 17.4231L3.68949 15.6172L3.31543 16.6919C4.8234 17.7679 6.64927 18.4266 8.62477 18.501Z'
          fill='#0072FC'
        />
        <path
          d='M10.0325 17.8965L13.6299 16.6973L14.6845 16.6914L16.5293 21.9914C16.588 22.1602 16.4513 22.3328 16.2735 22.3142L13.6877 22.0434C13.6155 22.0359 13.5437 22.0608 13.4918 22.1116L11.6329 23.9294C11.5052 24.0543 11.2907 24.004 11.232 23.8352L9.37524 18.5005L10.0325 17.8965Z'
          fill='#0083FD'
        />
        <path
          d='M9.37519 18.501L9 17.4231L14.3105 15.6172L14.6845 16.6919C13.1766 17.7679 11.3507 18.4266 9.37519 18.501Z'
          fill='#0072FC'
        />
        <path
          d='M8.9999 16.6924C0.298967 16.6924 0.291748 8.41819 0.298966 8.27273C0.527669 3.66534 4.33575 0 8.9999 0C13.6641 0 17.4721 3.66534 17.7008 8.27273C17.7081 8.41819 17.7008 16.6924 8.9999 16.6924Z'
          fill='#FF9838'
        />
        <path
          d='M8.9999 16.5461C4.33574 16.5461 0.527805 12.8808 0.299055 8.27344C0.291836 8.41889 0.288086 8.56528 0.288086 8.71252C0.288086 13.5239 4.18851 17.4243 8.9999 17.4243C13.8113 17.4243 17.7117 13.5239 17.7117 8.71252C17.7117 8.56528 17.708 8.41889 17.7007 8.27344C17.472 12.8808 13.6641 16.5461 8.9999 16.5461Z'
          fill='#FF8709'
        />
        <path
          d='M15.8628 8.71072C15.8628 11.6495 12.7901 15.1519 8.99978 15.1519C5.20942 15.1519 2.13672 11.4568 2.13672 8.71072C2.13672 8.5632 2.14136 8.41681 2.15055 8.27159C2.37695 4.68584 5.35694 1.84766 8.99978 1.84766C12.6426 1.84766 15.6226 4.68584 15.849 8.27159C15.8582 8.41681 15.8628 8.5632 15.8628 8.71072Z'
          fill='#CE7430'
        />
        <path
          d='M8.99978 14.6974C5.35698 14.6974 2.37756 11.8591 2.15111 8.27344C2.14192 8.41866 2.13672 8.56495 2.13672 8.71252C2.13672 12.5029 5.20942 15.5756 8.99978 15.5756C12.7901 15.5756 15.8628 12.5029 15.8628 8.71252C15.8628 8.56495 15.8576 8.41866 15.8485 8.27344C15.622 11.8591 12.6426 14.6974 8.99978 14.6974Z'
          fill='#BC672E'
        />
        <path
          d='M9.00847 12.7899C7.53037 12.7899 6.58987 11.771 6.40883 10.562C6.39238 10.4521 6.38718 10.2161 6.38718 10.2161C6.51304 9.89539 6.82532 9.66833 7.19062 9.66833C7.6671 9.66833 8.0534 10.0546 8.0534 10.5311C8.0534 11.0577 8.48183 11.4862 9.00847 11.4862C9.53512 11.4862 9.9636 11.0577 9.9636 10.5311C9.9636 10.3728 9.92488 10.2233 9.8564 10.0917C9.8564 10.0917 9.79161 9.27814 9.05469 9.27814C8.31547 9.27814 8.26597 8.2737 8.26597 8.2737C8.4163 8.02034 8.69258 7.85052 9.00852 7.85052C9.53516 7.85052 9.96365 7.42208 9.96365 6.89544C9.96365 6.3688 9.53516 5.94036 9.00852 5.94036C8.48188 5.94036 8.05344 6.3688 8.05344 6.89544C8.05344 7.37197 7.66715 7.75822 7.19066 7.75822C6.71418 7.75822 6.32788 7.37197 6.32788 6.89544C6.32788 5.41738 7.53041 4.21484 9.00847 4.21484C10.4866 4.21484 11.6891 5.41738 11.6891 6.89544C11.6891 7.59631 11.4188 8.23512 10.9769 8.7133C11.3246 9.08952 11.5661 9.5652 11.6533 10.0923C11.6533 10.0923 11.6559 10.3816 11.6308 10.529C11.417 11.7862 10.4866 12.7899 9.00847 12.7899Z'
          fill='#FFAA5C'
        />
        <path
          d='M9.00849 8.69709C8.69255 8.69709 8.41706 8.52675 8.26678 8.27344C8.19038 8.40216 8.14575 8.55197 8.14575 8.71252C8.14575 9.18905 8.532 9.5753 9.00853 9.5753C9.37683 9.5753 9.69652 9.7852 9.8558 10.0913C9.92428 9.95972 9.96366 9.81061 9.96366 9.65227C9.96361 9.12553 9.53513 8.69709 9.00849 8.69709Z'
          fill='#EA9957'
        />
        <path
          d='M9.00852 12.331C7.72372 12.331 6.64757 11.4222 6.38797 10.2138C6.34968 10.3114 6.32788 10.4174 6.32788 10.5286C6.32788 12.0067 7.53041 13.2092 9.00852 13.2092C10.4866 13.2092 11.6892 12.0067 11.6892 10.5286C11.6892 10.3791 11.6762 10.2327 11.6526 10.0898C11.4422 11.3595 10.337 12.331 9.00852 12.331Z'
          fill='#EA9957'
        />
      </svg>
    )}
    {...props}
  />
);

export default Top3Icon;
