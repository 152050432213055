import Icon from '@ant-design/icons';

const CopyLinkIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={44}
        height={44}
        viewBox='0 0 44 44'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx={22} cy={22} r='21.5' fill='#DC143C' stroke='#EEEEEE' />
        <path
          d='M25.75 25.75H30.25V13.75H18.25V18.25'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M25.75 18.25H13.75V30.25H25.75V18.25Z'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default CopyLinkIcon;
