import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ImgCustom, PopupConfirmFan } from 'components';
import { deleteListLiveStream, setIdScrollListLive } from '../../../../store/actions/lives';
import { useDispatch, useSelector } from 'react-redux';
import { convertToCurrentTime, decryptPath } from 'utils/utils';
import { NO_IMAGE_LIVESTREAM, LOGO_THUMBNAIL_COCOFAN } from '../../../../utils/constant';
import { useTranslation } from 'react-i18next';
import { useCheckPasswordLivestream } from 'hooks/useCheckPasswordLive';
import { newPathUser } from 'constants/layout/constant';
import { StyledTimeline } from './styled';
import DeleteIcon from 'images/DeleteIcon';
import UserCountIcon from 'images/UserCountIcon';
import { NumberViews } from 'components/LiveStream/NumberViews';
import { useMemo } from 'react';
import { isSafari } from 'react-device-detect';

const Items = ({ id, liveKey, password, item, isDataFirst }) => {
  const { dataUser } = useSelector((state) => state.users);
  const listCheckPassword = window.localStorage.getItem('cpw_ls');
  const dispatch = useDispatch();
  const [isVisiblePopup, setIsVisiblePopup] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const date = convertToCurrentTime(item?.live_date, 'YYYY/MM/DD HH:mm');

  const checkHaveBackground = useMemo(() => {
    const deImg = decryptPath(item?.img_background);
    if (deImg && deImg !== 'NULL') {
      return true;
    }
    return false;
  }, [item?.img_background]);

  const imgBackground = useMemo(() => {
    return checkHaveBackground ? item?.img_background : LOGO_THUMBNAIL_COCOFAN;
  }, [item?.img_background, checkHaveBackground]);
  const handleDeleteLive = () => {
    dispatch(deleteListLiveStream(id));
    setIsVisiblePopup(false);
  };
  const goToLive = () => {
    item?.status === 3
      ? dispatch(
          setIdScrollListLive(`list-live-${id}`, () => {
            navigate(`${newPathUser}live/${id}/${liveKey}`);
          })
        )
      : dispatch(
          setIdScrollListLive(`list-live-${id}`, () => {
            navigate(`${newPathUser}live/review/${id}/${liveKey}`);
          })
        );
  };

  const { UISetPassword, handleSetPassword } = useCheckPasswordLivestream(
    () => goToLive(),
    id,
    false,
    password
  );
  const { UISetPassword: UIPasswordDelete, handleSetPassword: handlePasswordDelete } =
    useCheckPasswordLivestream(() => setIsVisiblePopup(true), id, false, password);

  return (
    <>
      {password === 1 && (
        <>
          {UISetPassword}
          {UIPasswordDelete}
        </>
      )}
      {isVisiblePopup && (
        <PopupConfirmFan
          isVisiblePopup={() => setIsVisiblePopup(false)}
          colorSvg='#FF9F43'
          text={t('Popup.deleteLiveStream')}>
          <div className='ok btn' onClick={handleDeleteLive}>
            {t('Common.buttonYes')}
          </div>
          <div className='btn cancel' onClick={() => setIsVisiblePopup(false)}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}
      {isDataFirst ? (
        <>
          <div
            id={`list-live-${id}`}
            className={`live-stream-item-crt-list ${isDataFirst ? 'full' : ''}`}>
            <div
              className={`thumb ${isDataFirst ? 'full' : ''}`}
              style={
                item?.img_background === 'NULL' || item?.img_background === null
                  ? { background: 'none' }
                  : {}
              }>
              <div
                style={{ height: '100%', width: '100%' }}
                onClick={() => {
                  password === 1 && listCheckPassword !== id?.toString()
                    ? handleSetPassword()
                    : goToLive();
                }}>
                <StyledTimeline.BlueImg
                  url={`${decryptPath(item.img_background, { isMini: true })}`}>
                  <div className='bg-img'></div>
                  {/* {isSafari ? (
                    <img
                      alt={`${liveKey}`}
                      src={
                        checkHaveBackground
                          ? `${decryptPath(item.img_background)}?width=183&height=auto`
                          : LOGO_THUMBNAIL_COCOFAN
                      }
                      className={`image--changed-with-js ${
                        checkHaveBackground ? '' : 'img-placeholder'
                      }`}
                    />
                  ) : (
                    <ImgCustom
                      screen='post_640_auto_pc'
                      alt={`${liveKey}`}
                      src={imgBackground}
                      className={`image--changed-with-js ${
                        checkHaveBackground ? '' : 'img-placeholder'
                      }`}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = NO_IMAGE_LIVESTREAM;
                      }}
                    />
                  )} */}
                  <ImgCustom
                    screen={`${checkHaveBackground && 'post_640_auto_pc'}`}
                    alt={`${liveKey}`}
                    src={imgBackground}
                    className={`image--changed-with-js ${
                      checkHaveBackground ? '' : 'img-placeholder'
                    }`}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = NO_IMAGE_LIVESTREAM;
                    }}
                  />
                </StyledTimeline.BlueImg>
              </div>
              {item?.status !== 3 && (
                <div
                  className='episodes-tags crt-dlt'
                  onClick={() =>
                    password === 1 && listCheckPassword !== id?.toString()
                      ? handlePasswordDelete()
                      : setIsVisiblePopup(true)
                  }>
                  <DeleteIcon color='#dc143c' />
                </div>
              )}
            </div>
            <div className='content full'>
              <div className='content-info'>
                <div className='content-info__left'>
                  <ImgCustom
                    screen='avatar_44_44'
                    isPlaceholderAvatar
                    alt={item?.live_title}
                    src={`${dataUser?.avatar}`}
                    onClick={() => {
                      dispatch(
                        setIdScrollListLive(`list-live-${id}`, () => {
                          navigate(`${newPathUser}${dataUser?.account_id}`);
                        })
                      );
                    }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = NO_IMAGE_LIVESTREAM;
                    }}
                  />
                </div>
                <div className='content-info__right'>
                  <div className='title full'>{item?.live_title}</div>
                  <div className='name full'>{dataUser?.account_name}</div>
                </div>
              </div>

              <div className='content-date'>
                <button
                  className='button-go-live-right'
                  onClick={() => {
                    password === 1 && listCheckPassword !== id?.toString()
                      ? handleSetPassword()
                      : goToLive();
                  }}>
                  {t('Creator.ListLiveStream.goLive')}
                </button>
              </div>
            </div>
            <div className='count-user-watch'>
              <span>
                <UserCountIcon />
              </span>
              <span className='count'>{<NumberViews id={liveKey} />}</span>
            </div>
            <div className='episodes-tags-live'>
              <span>{t('Common.live')}</span>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            id={`list-live-${id}`}
            className={`live-stream-item-crt-list ${isDataFirst ? 'full' : ''}`}>
            <div
              className='thumb'
              style={
                item?.img_background === 'NULL' || item?.img_background === null
                  ? { background: 'none' }
                  : {}
              }>
              <div
                style={{ height: '100%', width: '100%' }}
                onClick={() => {
                  password === 1 && listCheckPassword !== id?.toString()
                    ? handleSetPassword()
                    : goToLive();
                }}>
                <StyledTimeline.BlueImg
                  url={`${decryptPath(item.img_background, { isMini: true })}`}>
                  <div className='bg-img'></div>
                  {isSafari ? (
                    <img
                      screen={`${checkHaveBackground && 'post_640_auto_pc'}`}
                      alt={`${liveKey}`}
                      src={
                        checkHaveBackground
                          ? `${decryptPath(item.img_background)}`
                          : LOGO_THUMBNAIL_COCOFAN
                      }
                      className={`image--changed-with-js ${
                        checkHaveBackground ? '' : 'img-placeholder'
                      }`}
                    />
                  ) : (
                    <ImgCustom
                      screen={`${checkHaveBackground && 'post_640_auto_pc'}`}
                      alt={`${liveKey}`}
                      src={imgBackground}
                      className={`image--changed-with-js ${
                        checkHaveBackground ? '' : 'img-placeholder'
                      }`}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = NO_IMAGE_LIVESTREAM;
                      }}
                    />
                  )}
                </StyledTimeline.BlueImg>
              </div>
              {item?.status !== 3 && (
                <div
                  className='episodes-tags crt-dlt'
                  onClick={() =>
                    password === 1 && listCheckPassword !== id?.toString()
                      ? handlePasswordDelete()
                      : setIsVisiblePopup(true)
                  }>
                  <DeleteIcon color='#dc143c' />
                </div>
              )}
            </div>
            <div className='content'>
              <div className={`content-info ${item?.status === 3 ? 'live' : ''}`}>
                <div className='content-info__left'>
                  <ImgCustom
                    screen='avatar_44_44'
                    isPlaceholderAvatar
                    alt={item?.live_title}
                    src={`${dataUser?.avatar}`}
                    onClick={() => {
                      dispatch(
                        setIdScrollListLive(`list-live-${id}`, () => {
                          navigate(`${newPathUser}${dataUser?.account_id}`);
                        })
                      );
                    }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = NO_IMAGE_LIVESTREAM;
                    }}
                  />
                </div>
                <div className='content-info__right'>
                  <div className='title'>{item?.live_title}</div>
                  <div className='name'>{dataUser?.account_name}</div>
                </div>
              </div>
              {item?.status !== 3 && (
                <div className='content-date'>
                  {date}~{convertToCurrentTime(item?.period_time_2, 'HH:mm')}
                </div>
              )}
            </div>
            {item?.status === 3 && (
              <div className='layer-go-lives'>
                <div className='layer'></div>
                <div className='count-user-watch'>
                  <span>
                    <UserCountIcon />
                  </span>
                  <span className='count'>{<NumberViews id={liveKey} />}</span>
                </div>
                <div className='episodes-tags'>
                  <span>{t('Common.live')}</span>
                </div>
                <div className='go-live'>
                  <button
                    className='button-go-live'
                    onClick={() => {
                      password === 1 && listCheckPassword !== id?.toString()
                        ? handleSetPassword()
                        : goToLive();
                    }}>
                    {t('Creator.ListLiveStream.goLive')}
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
export default Items;
