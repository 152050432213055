import Icon from '@ant-design/icons';

const DangerCircleIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={20}
        height={20}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z'
          stroke={props?.color || '#BEBEBE'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.375 9.375H10.0001L10 13.75H10.625'
          stroke={props?.color || '#BEBEBE'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.84375 7.34375C10.2752 7.34375 10.625 6.99397 10.625 6.5625C10.625 6.13103 10.2752 5.78125 9.84375 5.78125C9.41228 5.78125 9.0625 6.13103 9.0625 6.5625C9.0625 6.99397 9.41228 7.34375 9.84375 7.34375Z'
          fill={props?.color || '#BEBEBE'}
        />
      </svg>
    )}
    {...props}
  />
);

export default DangerCircleIcon;
