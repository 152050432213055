import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ImgCustom } from '..';
import { onErrImage } from '../../utils/utils';
import './AvartaInfor.scss';
import { AVATAR_DEFAULT } from 'utils';
import { newPathUser } from 'constants/layout/constant';
const AvatarInforDetail = ({
  images,
  name = '',
  content1 = '',
  contentRight = '',
  className = '',
  id = '',
  link = false,
  deleteMessage = false,
  onClick,
  handleTimeline,
  handleBookmark,
  notDate= false,
  ...props
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={`avatar-infor ${className}`} {...props} >
      <div className='content-left'>
        <ImgCustom
          isPlaceholderAvatar
          screen='avatar_44_44'
          src={images ? `${images}` : AVATAR_DEFAULT}
          alt='avatar'
          onClick={(e) => {
            e.stopPropagation();
            handleTimeline && handleTimeline();
            handleBookmark && handleBookmark();
            navigate(`${newPathUser}${content1?.account_id}`, {
              state: { name: 'homepage' },
            });
          }}
          onError={(e) => onErrImage(e, true)}
        />
        <div
          className='wrap--text'
          onClick={(e) => {
            e.stopPropagation();
            handleTimeline && handleTimeline();
            handleBookmark && handleBookmark();
            navigate(`${newPathUser}${content1?.account_id}`, {
              state: { name: 'homepage' },
            });
          }}>
          <span className='name'>{name}</span>
        </div>
      </div>
      {link && (
        <div className='btn-wrapper'>
          <div className='link' onClick={() => navigate(`/message/${id}`)}>
            {contentRight}
          </div>
          {deleteMessage && (
            <div className='delete-message' onClick={() => {}}>
              {t('Common.buttonDelete')}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AvatarInforDetail;
