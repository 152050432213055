import Icon from '@ant-design/icons';

const IconVideoCall = (props) => (
  <Icon
    component={() => (
      <svg
        width={20}
        height={20}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M1.875 4.6875H11.875C12.538 4.6875 13.1739 4.95089 13.6428 5.41973C14.1116 5.88857 14.375 6.52446 14.375 7.1875V14.6875C14.375 14.8533 14.3092 15.0122 14.1919 15.1294C14.0747 15.2467 13.9158 15.3125 13.75 15.3125H3.75C3.08696 15.3125 2.45107 15.0491 1.98223 14.5803C1.51339 14.1114 1.25 13.4755 1.25 12.8125V5.3125C1.25 5.14674 1.31585 4.98777 1.43306 4.87056C1.55027 4.75335 1.70924 4.6875 1.875 4.6875V4.6875Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.375 8.75L18.75 6.25V13.75L14.375 11.25'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default IconVideoCall;
