const LiveRankingIcon = (props) => (
  <svg
    width='34'
    height='26'
    viewBox='0 0 34 26'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <rect width='34' height='26' rx='4' fill='#FFBF00' />
    <g clip-path='url(#clip0_4480_311999)'>
      <path
        d='M12.8705 21.4704H9.05871C8.794 21.4704 8.5293 21.2057 8.5293 20.941V17.0763C8.5293 16.7586 8.794 16.5469 9.05871 16.5469H12.7646V20.941C12.7646 21.0998 12.8175 21.3116 12.8705 21.4704Z'
        fill='white'
      />
      <path
        d='M20.1762 14.588V20.9409C20.1762 21.2057 19.9115 21.4704 19.6468 21.4704H14.3527C14.0879 21.4704 13.8232 21.2057 13.8232 20.9409V14.588C13.8232 14.2704 14.0879 14.0586 14.3527 14.0586H19.6468C19.9115 14.0586 20.1762 14.2704 20.1762 14.588Z'
        fill='white'
      />
      <path
        d='M25.4706 17.7638V20.9403C25.4706 21.205 25.2059 21.4697 24.9412 21.4697H21.1294C21.1823 21.3108 21.2353 21.0991 21.2353 20.9403V17.2344H24.9412C25.2059 17.2344 25.4706 17.4461 25.4706 17.7638Z'
        fill='white'
      />
      <path
        d='M21.2881 8.23474L19.541 9.92886L19.9646 12.3642C20.0175 12.5759 19.9116 12.7877 19.7528 12.8936C19.594 12.9994 19.3293 12.9994 19.1704 12.9465L16.9998 11.7818L14.8293 12.8936C14.6704 12.9994 14.4587 12.9994 14.2998 12.8406C14.141 12.7347 14.0351 12.523 14.0881 12.3112L14.5116 9.87592L12.7116 8.23474C12.6057 8.12886 12.5528 7.9171 12.6057 7.70533C12.6587 7.49357 12.8175 7.38768 13.0293 7.33474L15.4646 6.96415L16.5234 4.79357C16.6822 4.42298 17.3175 4.42298 17.4763 4.79357L18.5351 6.96415L20.9704 7.33474C21.1822 7.38768 21.341 7.49357 21.394 7.70533C21.4469 7.9171 21.394 8.12886 21.2881 8.23474Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_4480_311999'>
        <rect width='18' height='18' fill='white' transform='translate(8 4)' />
      </clipPath>
    </defs>
  </svg>
);
export default LiveRankingIcon;
