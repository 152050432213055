import { CloseIcon } from 'images';
import { useState } from 'react';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import { formatCoin } from '../../../../../utils';

const PopupConfirmWithdraw = ({
  closeModal,
  onSubmitButton,
  currentPoint = 0,
  adminSetting = 0,
  ...props
}) => {
  const [numberAmount, setNumberAmount] = useState();
  useLockBodyScroll();
  const handleShowPopup = (e) => {
    closeModal(false);
  };
  const { t } = useTranslation();
  const handleSubmit = () => {
    onSubmitButton(numberAmount);
  };

  return (
    <>
      <div className='popup popup-confirm-withdraw'>
        <div className='popup-content'>
          <div className='popup-header'>
            <div className='title'>{t('Sales.withdrawal_amount')}</div>
            <CloseIcon className='close-icon' onClick={handleShowPopup} />
          </div>
          <div className='popup-body'>
            <div className='popup-input-label'>{t('Sales.placeHolder_withdrawal_amount')}</div>
            <CurrencyFormat
              thousandSeparator={true}
              placeholder={t('Sales.placeHolder_withdrawal_amount')}
              className='fsite-input'
              onValueChange={(e) => setNumberAmount(e.floatValue)}
            />
            {+numberAmount > +currentPoint && (
              <div className='err'>{t('Sales.withdrawal_amount_exceeds')}</div>
            )}
            {+numberAmount < +adminSetting && (
              <div className='err'>
                {t('Sales.pointAdminSetting', { point: formatCoin(adminSetting) })}
              </div>
            )}
          </div>

          <div className='popup-footer'>
            <div className='group-button-footer'>
              <button
                disabled={
                  !numberAmount ||
                  +numberAmount > +currentPoint ||
                  +numberAmount < +adminSetting
                }
                className='btn ok'
                onClick={handleSubmit}>
                {t('Common.Ok')}
              </button>
              <button className='btn cancel' onClick={handleShowPopup}>
                {t('Common.buttonCancel')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupConfirmWithdraw;
