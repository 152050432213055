import Icon from '@ant-design/icons';

const DollarIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M8.97489 0.582045C4.37489 0.598712 0.649893 4.34038 0.66656 8.94038C0.683226 13.5404 4.42489 17.2654 9.02489 17.2487C13.6249 17.232 17.3499 13.4904 17.3332 8.89038C17.3166 4.29038 13.5749 0.573712 8.97489 0.582045ZM10.8832 8.99871C11.5332 9.22371 12.4082 9.70705 12.4082 11.1987C12.4082 12.482 11.3999 13.5154 10.1666 13.5154H9.62489V13.9987C9.62489 14.3404 9.34156 14.6237 8.99989 14.6237C8.65823 14.6237 8.37489 14.3404 8.37489 13.9987V13.5154H8.07489C6.70823 13.5154 5.59989 12.3654 5.59989 10.9487C5.59989 10.607 5.88323 10.3237 6.22489 10.3237C6.56656 10.3237 6.84989 10.607 6.84989 10.9487C6.84989 11.6737 7.39989 12.2654 8.07489 12.2654H8.37489V9.44871L7.11656 8.99871C6.46656 8.77371 5.59156 8.29038 5.59156 6.79871C5.59156 5.51538 6.59989 4.48204 7.83323 4.48204H8.37489V3.99871C8.37489 3.65705 8.65823 3.37371 8.99989 3.37371C9.34156 3.37371 9.62489 3.65705 9.62489 3.99871V4.48204H9.92489C11.2916 4.48204 12.3999 5.63204 12.3999 7.04871C12.3999 7.39038 12.1166 7.67371 11.7749 7.67371C11.4332 7.67371 11.1499 7.39038 11.1499 7.04871C11.1499 6.32371 10.5999 5.73204 9.92489 5.73204H9.62489V8.54871L10.8832 8.99871Z'
          fill='#1480FF'
        />
      </svg>
    )}
    {...props}
  />
);

export default DollarIcon;
