import { ImgCustom, PopupLoading, ReactCalendar } from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import useCalendar from 'hooks/useCalendar';
import useOutsideClick from 'hooks/useOutsideClick';
import { CalendarIcon, EyeIcon, HiddenIcon } from 'images';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getListCountry, logout, signUpCreatorByFan } from 'store/actions/users';
import { GENDER, getQueryParams, isEmptyObject, LOGO_COCO_FANS, patternCharacterLatinhNoSpace, STORAGE } from 'utils';
import storage from 'utils/storage';
import './index.scss';

const SignUpByFan = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
    setError,
  } = useForm({
    mode: 'all',
  });
  const { t, i18n } = useTranslation();
  const { loading } = useSelector((state) => state.users);
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [viewedPolicyTerm, setViewedPolicyTerm] = useState({
    policy: false,
    term: false,
    checked: false,
  });
  const location = useLocation();
  const {
    date,
    currentAge,
    toggleCalendar,
    onChangeDate,
    isVisibleCalendar,
    setIsVisibleCalendar,
  } = useCalendar();
  const password = useRef({});
  const datePickerRef = useRef({});
  useOutsideClick(datePickerRef, (e) => {
    const isCalendar =
      e.target.tagName === 'ABBR' || e.target.className?.includes('react-calendar__tile');
    if (e.target !== datePickerRef.current && isVisibleCalendar === true && !isCalendar) {
      setIsVisibleCalendar(false);
    }
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getListCountry());
  }, []);

  useEffect(() => {
    if (
      getQueryParams(location.search, 'account_name') &&
      getQueryParams(location.search, 'email') &&
      getQueryParams(location.search, 'gender') &&
      getQueryParams(location.search, 'birthday') &&
      getQueryParams(location.search, 'token')
    ) {
      setValue('username', getQueryParams(location.search, 'account_name'));
      setValue('email', getQueryParams(location.search, 'email'));
      setValue('gender', getQueryParams(location.search, 'gender'));
      setValue(
        'birthday',
        moment(getQueryParams(location.search, 'birthday')).format('YYYY/MM/DD')
      );
    } else {
      navigate('/*');
    }
  }, []);

  password.current = watch('password', '');

  const watchGender = watch('gender', 0);
  const watchEmail = watch('email');
  const watchPassword = watch('password');
  const watchAccountName = watch('username');
  const watchAccountId = watch('account_id');
  const watchPolicy = watch('allow_policy');

  const isDisabledButton =
    !watchPassword ||
    !watchEmail ||
    !(watchGender !== 0 || watchGender !== 1) ||
    !watchAccountName ||
    !watchAccountId ||
    !watchPolicy ||
    currentAge < 18 ||
    !isEmptyObject(errors);

  const onSubmit = async (data) => {
    setIsLoading(true);
    await dispatch(
      signUpCreatorByFan(
        { ...data, token: getQueryParams(location.search, 'token') },
        () => {
          setIsLoading(false);
          navigate(`/sign-up/success`);
        },
        (error) => {
          console.log(error);
          setIsLoading(false);
        }
      )
    );
  };

  const customOnChangeDate = (date) => {
    onChangeDate(date);
    setValue('birthday', moment(date).format('YYYY/MM/DD'));
  };

  const handleCheckPolicyTerm = (type) => {
    const viewPolicyTermState = { ...viewedPolicyTerm, [type]: true };
    if (viewPolicyTermState.term && viewPolicyTermState.policy && !viewedPolicyTerm.checked) {
      setValue('allow_policy', true);
    }
    const checked = viewedPolicyTerm.checked
      ? true
      : viewPolicyTermState.term && viewPolicyTermState.policy;
    setViewedPolicyTerm({ ...viewPolicyTermState, checked });
  };

  return (
    <div>
      {(loading || isLoading) && <PopupLoading className={'popup-loading'} />}
      <div className='fansite-sign-up-creator by-fan'>
        <div className='box-sign-up'>
          <div className='header'>
            <ImgCustom
              src={LOGO_COCO_FANS}
              height={32}
              width={94}
              alt='logo'
              className='logo-cocofans'
            />
          </div>
          <div className='fansite-form '>
            <div className='title'>
              {storage.has(STORAGE.TOKEN_TWITTER) && storage.has(STORAGE.SERECT_TOKEN_TWITTER)
                ? t('SignUp.titleTwitter')
                : t('SignUp.title')}
            </div>
            <InputWrapper
              id='fsite-account-name'
              label={t('Common.nameJP')}
              required={true}
              error={errors?.username ? errors?.username?.message : ''}>
              <input
                maxLength={30}
                {...register('username', {
                  required: t('ValidateMsg.USERNAME_IS_REQUIRED'),
                  maxLength: {
                    value: 30,
                    message: t('ValidateMsg.C002_VALIDATE_ACCOUNT_MAXSIZE'),
                  },
                })}
                onBlur={(e) => {
                  setValue('username', e.target.value.trim());
                  if (!e.target.value.trim()) {
                    setError('username', {
                      type: 'required',
                      message: t('ValidateMsg.USERNAME_IS_REQUIRED'),
                    });
                  }
                }}
                placeholder={t('ValidateMsg.USERNAME_IS_REQUIRED')}
              />
            </InputWrapper>

            <InputWrapper
              id='fsite-account_id'
              label={t('Common.idFan')}
              required={true}
              error={errors?.account_id ? errors?.account_id?.message : ''}>
             <input
                {...register('account_id', {
                  required: t('ValidateMsg.C002_VALIDATE_ACCOUNT_ID_REQUIRED'),
                  maxLength: {
                    value: 30,
                    message: t('ValidateMsg.C002_VALIDATE_ACCOUNT_ID_MAXSIZE'),
                  },
                  pattern: {
                    value: patternCharacterLatinhNoSpace,
                    message: t('ValidateMsg.C002_VALIDATE_ACCOUNT_ID_FORMAT'),
                  },
                })}
                onBlur={(e) => {
                  setValue('account_id', e.target.value.trim());
                  if (!e.target.value.trim()) {
                    setError('account_id', {
                      type: 'required',
                      message: t('ValidateMsg.C002_VALIDATE_ACCOUNT_ID_REQUIRED'),
                    });
                  }
                  // clearErrors('account_id');
                }}
                placeholder={t('ValidateMsg.C002_VALIDATE_ACCOUNT_ID_REQUIRED')}
              />
            </InputWrapper>

            <InputWrapper id='fsite-birthday' label={t('Common.birthday')} required={true}>
              <div ref={datePickerRef} className='birthday'>
                <CalendarIcon className={`${date ? '' : ''}`} />
                <input
                  placeholder='YYYY/MM/DD'
                  {...register('birthday')}
                  onClick={toggleCalendar}
                  value={date}
                  readOnly
                  role='button'
                />
                {isVisibleCalendar && (
                  <ReactCalendar onChange={(e) => customOnChangeDate(e)} />
                )}
                {currentAge < 18 && (
                  <div className='fansite-form-input__error text-danger mt-2'>
                    {t('ValidateMsg.birthday18Age')}
                  </div>
                )}
              </div>
            </InputWrapper>
            <InputWrapper
              id='fsite-gender'
              label={t('Common.gender')}
              required={true}
              error={errors?.gender ? errors?.gender?.message : ''}>
              <div className='gender'>
                <div className='gender_input_radio-wrap'>
                  {GENDER.map((item) => {
                    return (
                      <div className='gender_input_radio ml-2' key={item.value}>
                        <input
                          {...register('gender', {
                            required: t('ValidateMsg.required'),
                          })}
                          type='radio'
                          name='gender'
                          value={item.value}
                        />{' '}
                        {item.label}
                      </div>
                    );
                  })}
                </div>
              </div>
            </InputWrapper>

            <InputWrapper
              id='fsite-email'
              label={t('Common.email')}
              required={true}
              error={errors?.email ? errors?.email?.message : ''}>
              <input
                type='text'
                placeholder={t('ValidateMsg.emailRequired')}
                maxLength={255}
                disabled
                {...register('email')}
              />
            </InputWrapper>

            <InputWrapper
              id='fsite-password'
              label={t('Common.password')}
              required={true}
              error={errors?.password ? errors?.password?.message : ''}>
              <div className='password'>
                <input
                  {...register('password', {
                    required: t('ValidateMsg.passwordRequired'),
                    minLength: {
                      value: 8,
                      message: t('ValidateMsg.minPassword'),
                    },
                    pattern: {
                      value: /[\w@.!#$%&'*+/=?^_`{|}~-]+$/,
                      message: t('ValidateMsg.C002_VALIDATE_PASSWORD_FORMAT'),
                    },
                  })}
                  placeholder={t('ValidateMsg.passwordRequired')}
                  type={`${hiddenPassword ? 'password' : 'text'}`}
                />
                {hiddenPassword ? (
                  <HiddenIcon onClick={() => setHiddenPassword(false)} />
                ) : (
                  <EyeIcon onClick={() => setHiddenPassword(true)} />
                )}
              </div>
            </InputWrapper>

            <div className='agree-policy'>
              <input
                type='checkbox'
                id='allow_policy'
                className='form-check-input mt-0'
                name='allow_policy'
                {...register('allow_policy')}
                style={{
                  pointerEvents:
                    viewedPolicyTerm.policy && viewedPolicyTerm.term ? 'auto' : 'none',
                  cursor:
                    viewedPolicyTerm.policy && viewedPolicyTerm.term ? 'pointer' : 'auto',
                }}
              />
              <label className='ps-2'>
                {i18n.language === 'en' && t('Common.agreeTo')}
                <a
                  className='link'
                  target='_blank'
                  href='/terms-of-use'
                  onClick={() => handleCheckPolicyTerm('term')}>
                  {t('Common.termsOfUse')}
                </a>{' '}
                {i18n.language === 'jp' && '、'}
                {i18n.language === 'en' && ' and '}
                <a
                  className='link'
                  target='_blank'
                  href='/privacy-policy'
                  onClick={() => handleCheckPolicyTerm('policy')}>
                  {t('Common.privacyPolicy')}
                </a>
                {i18n.language === 'jp' && t('Common.agreeTo')}
              </label>
            </div>
            <label className='note-policy'>{t('Common.notePolicy')}</label>

            <InputWrapper
              id='fsite-submit'
              className={`button-submit ${isDisabledButton || isLoading ? 'disabled' : ''}`}>
              <button
                disabled={isDisabledButton || isLoading}
                onClick={handleSubmit(onSubmit, console.log)}>
                {t('SignUp.temporaryRegistration')}
              </button>
            </InputWrapper>
          </div>

          <div className='footer'>
            <ImgCustom
              src={LOGO_COCO_FANS}
              height={32}
              width={94}
              alt='logo'
              className='logo-cocofans'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpByFan;
