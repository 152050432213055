import { ImgCustom, VideoDetail } from 'components';
import { CloseIcon } from 'images';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  acceptedImageTypes,
  acceptedImgVideoTypes,
  failFileThan1Gb,
  failImgInvalid,
  failImgThan10,
  failImgThan10Mb,
  failShortVideo,
  failVideoInvalid,
  failVideoThan1,
  getDurationVideo,
  ONE_GB,
  TEN_MB,
} from 'utils';
import 'video-react/dist/video-react.css';
import { pushNotify } from '../../../../../components/Notification';
import { postDurationVideo } from '../../../../../store/actions/getDurationVideo';
import '../normal.scss';
const ListImgVideo = ({ short_video, list_img, type_post, showErr, setLoadingUploadFile }) => {
  const [videoUpload, setVideoUpload] = useState({ img: [], files: null });
  const [imgUpload, setImgUpload] = useState({ img: [], files: [] });
  const [typePost, setTypePost] = useState();
  const { t } = useTranslation();
  const durationVideoRef = useRef();
  const { state } = useLocation();
  const dispatch = useDispatch();
  //reset when change state
  useEffect(() => {
    setVideoUpload({ img: [], files: null });
    setImgUpload({ img: [], files: [] });
    setTypePost();
    short_video();
    list_img();
    type_post();
  }, [state]);
  useEffect(() => {
    if (!videoUpload.img.length && !imgUpload.img.length) {
      setTypePost();
      short_video();
      list_img();
      type_post();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoUpload, imgUpload]);

  // START IMG VIDEO
  const inputImage = () => {
    return (
      <div className='d-flex align-items-center gap-2'>
        <div
          className={`inputFile ${
            typePost === 'normal'
              ? imgUpload.img.length >= 10 && 'disableImgInput'
              : typePost === 'short' && 'disableImgInput'
          }`}>
          <div className='btn-upload'>{t('Creator.PostArticle.postShort.typeImage')}</div>
          <input
            type='file'
            multiple='multiple'
            accept={acceptedImageTypes.map((item) => item).join(',')}
            onChange={handleInputImg}
            onClick={(e) => (e.target.value = null)}
            disabled={imgUpload.img.length >= 10 || videoUpload.img.length > 0}
          />
        </div>
        {showErr && (
          <div className='error-message-list-img-video' style={{ color: 'red' }}>
            {t('Creator.PostArticle.postShort.noImgWhenOnlyFollow')}
          </div>
        )}
      </div>
    );
  };

  const handleInputImg = (e) => {
    let sizeImgBiggerTenMb = false;
    let checkImg = true;
    const checkLength = imgUpload.img.length + e.target.files.length;
    const newArrImg = [];
    for (var i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size > TEN_MB) sizeImgBiggerTenMb = true;
      if (!acceptedImageTypes.includes(e.target.files[i]['type'])) checkImg = false;
      newArrImg.push(URL.createObjectURL(e.target.files[i]));
    }
    if (e.target.files[0] && checkImg && !sizeImgBiggerTenMb && checkLength <= 10) {
      setImgUpload({
        files: [...imgUpload.files, ...newArrImg],
        img: [...imgUpload.img, ...e.target.files],
      });
      list_img([...imgUpload.img, ...e.target.files]);
      setTypePost('normal');
      type_post('normal');
    } else {
      sizeImgBiggerTenMb && failImgThan10Mb();
      checkLength > 10 && failImgThan10();
      !checkImg && failImgInvalid();
    }
  };

  const handleRemoveImg = (i) => {
    const { img, files } = imgUpload;
    const removeEleImg = img.splice(i, 1);
    const removeEleFiles = files.splice(i, 1);
    if (img.length === 0) {
      setImgUpload({ img: [], files: [] });
    } else {
      setImgUpload({
        files: files.filter((item) => item !== removeEleFiles[0]),
        img: img.filter((item) => item !== removeEleImg[0]),
      });
      list_img(img.filter((item) => item !== removeEleImg[0]));
    }
  };
  const inputVideo = () => {
    return (
      <div
        className={`inputFile ${
          typePost === 'normal'
            ? 'disableVideoInput'
            : (imgUpload.img.length >= 10 || videoUpload.img.length > 0) && 'disableVideoInput'
        }`}>
        <div className='btn-upload'>{t('Creator.PostArticle.postShort.typeShortVideo')}</div>
        <input
          type='file'
          accept={acceptedImgVideoTypes.map((item) => item).join(',')}
          onChange={handleInputVideo}
          disabled={
            typePost === 'normal'
              ? true
              : imgUpload.img.length >= 10 || videoUpload.img.length > 0
          }
          onClick={(e) => (e.target.value = null)}
          multiple='multiple'
        />
      </div>
    );
  };

  const handleInputVideo = async (e) => {
    let videoSelect = false;
    let imageSelect = false;
    const targetFiles = e.target.files;
    let targetFilesObject = [...targetFiles];
    if (
      targetFilesObject.length + imgUpload.length > 10 ||
      targetFilesObject.length + videoUpload.length > 10
    ) {
      return pushNotify('error', `${t('ValidateMsg.uploadThan10Img')}`);
    }
    /**
     * check img vs video
     */
    targetFilesObject.map((file) => {
      const isImage = file.type.includes('image');
      const isVideo = file.type.includes('video');
      const maxSize = isImage ? TEN_MB : ONE_GB;
      const fileType = isImage
        ? acceptedImageTypes
        : ['video/mp4', 'video/x-ms-wmv', 'video/quicktime'];
      if (+file.size > +maxSize) {
        return pushNotify(
          'error',
          isImage ? `${t('ValidateMsg.imgThan10Mb1')}` : `${t('ValidateMsg.videoThan1Gb1')}`
        );
      } else if (isImage && !fileType.includes(file.type)) {
        return pushNotify('error', `${t('ValidateMsg.typeImg1')}`);
      } else if (isVideo && !fileType.includes(file.type)) {
        return pushNotify('error', `${t('ValidateMsg.typeVideo1')}`);
      } else if (!isVideo && !isImage) {
        pushNotify('error', `${t('ValidateMsg.typeImg1')}`);
        return pushNotify('error', `${t('ValidateMsg.typeVideo1')}`);
      } else if (
        (imgUpload.img.length > 0 && isVideo) ||
        (videoUpload.img.length > 0 && isImage)
      ) {
        return pushNotify('error', `${t('ValidateMsg.ImgAndVideoRequired')}`);
      } else if (isImage) {
        return (imageSelect = true);
      } else {
        return (videoSelect = true);
      }
    });

    const checkLengthVideo = videoUpload.img.length + e.target.files.length;
    if (videoSelect === true && (checkLengthVideo > 1 || e.target.files.length > 1)) {
      return pushNotify('error', `${t('ValidateMsg.uploadThan1Video')}`);
    }

    if (imageSelect && videoSelect) {
      return pushNotify('error', `${t('ValidateMsg.ImgAndVideoRequired')}`);
    } else if (imageSelect && !videoSelect) {
      let sizeImgBiggerTenMb = false;
      let checkImg = true;
      const checkLength = imgUpload.img.length + e.target.files.length;
      const newArrImg = [];
      for (var i = 0; i < e.target.files.length; i++) {
        if (e.target.files[i].size > TEN_MB) sizeImgBiggerTenMb = true;
        if (!acceptedImageTypes.includes(e.target.files[i]['type'])) checkImg = false;
        newArrImg.push(URL.createObjectURL(e.target.files[i]));
      }
      if (e.target.files[0] && checkImg && !sizeImgBiggerTenMb && checkLength <= 10) {
        setImgUpload({
          files: [...imgUpload.files, ...newArrImg],
          img: [...imgUpload.img, ...e.target.files],
        });
        list_img([...imgUpload.img, ...e.target.files]);
        setTypePost('short');
        type_post('short');
      } else {
        sizeImgBiggerTenMb && failImgThan10Mb();
        checkLength > 10 && failImgThan10();
        !checkImg && failImgInvalid();
      }
    } else if (!imageSelect && videoSelect) {
      let sizeImgBiggerOneGB = false;
      sizeImgBiggerOneGB = e.target.files[0].size > ONE_GB ? true : false;
      const checkTypes = e.target.files[0]['type'].includes('video');
      const checkLength = videoUpload.img.length + e.target.files.length;
      if (e.target.files[0]['type'] === 'video/x-ms-wmv') {
        setLoadingUploadFile(true);
        dispatch(
          postDurationVideo(e.target.files[0], ({ data }) => {
            setLoadingUploadFile(false);
            if (checkTypes && !sizeImgBiggerOneGB && checkLength <= 1) {
              if (data <= 60) {
                setVideoUpload({
                  files: URL.createObjectURL(e.target.files[0]),
                  img: e.target.files,
                });
                setTypePost('short');
                type_post('short');
                short_video(e.target.files[0]);
              } else {
                failShortVideo();
              }
            } else {
              sizeImgBiggerOneGB && failFileThan1Gb();
              !checkTypes && failVideoInvalid();
              checkLength > 1 && failVideoThan1();
            }
          }),
          () => setLoadingUploadFile(false)
        );
      } else {
        getDurationVideo(e.target.files[0], (duration) => {
          if (duration <= 60) {
            if (checkTypes && !sizeImgBiggerOneGB && checkLength <= 1) {
              setVideoUpload({
                files: URL.createObjectURL(e.target.files[0]),
                img: e.target.files,
              });
              setTypePost('short');
              type_post('short');
              short_video(e.target.files[0]);
            } else {
              sizeImgBiggerOneGB && failFileThan1Gb();
              !checkTypes && failVideoInvalid();
              checkLength > 1 && failVideoThan1();
            }
          } else {
            failShortVideo();
          }
        });
      }
    }
  };
  // END IMG VIDEO

  return (
    <div className='box-img-video'>
      <div className='list-img-video'>
        <div
          className={`list-item scroll-bar-custom-horizontal list-imgs ${
            videoUpload.img.length > 0 && 'has-video'
          }`}>
          {imgUpload.files.length > 0 &&
            imgUpload.files.map((item, index) => {
              return (
                <>
                  <div
                    key={index}
                    className={`box-item ${
                      imgUpload.img.length > 1 ? 'box-img2' : 'box-img1'
                    } ${typePost}`}>
                    <ImgCustom src={item} />
                    <CloseIcon onClick={() => handleRemoveImg(index)} />
                  </div>
                </>
              );
            })}
        </div>

        <div className={`list-item list-videos`}>
          {videoUpload.img.length > 0 && (
            <div className={`box-item box-video1`}>
              <VideoDetail src={videoUpload.files} />
              <CloseIcon onClick={() => setVideoUpload({ img: [], files: null })} />
            </div>
          )}
        </div>

        <div className='box-btn-upload d-flex align-items-center gap-4'>
          {state?.type === 'short' ? inputVideo() : inputImage()}
        </div>
      </div>
      <pre
        ref={durationVideoRef}
        id='duration-video'
        style={{ opacity: 0, visibility: 'hidden', position: 'absolute' }}></pre>
    </div>
  );
};

export default ListImgVideo;
