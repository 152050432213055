import Icon from '@ant-design/icons';

const Top2Icon = (props) => (
  <Icon
    component={() => (
      <svg
        width='18'
        height='24'
        viewBox='0 0 18 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7.74837 17.9434L3.87977 16.6055L3.02623 16.6924L1.18155 21.9924C1.12277 22.1612 1.25955 22.3338 1.43735 22.3152L4.02312 22.0444C4.09535 22.0369 4.16716 22.0619 4.2191 22.1126L6.07793 23.9304C6.20571 24.0554 6.42012 24.005 6.47885 23.8362L8.33562 18.5016L7.74837 17.9434Z'
          fill='#98DB7C'
        />
        <path
          d='M8.33571 18.501L8.7109 17.4231L3.40043 15.6172L3.02637 16.6919C4.53434 17.7679 6.36021 18.4266 8.33571 18.501Z'
          fill='#82D361'
        />
        <path
          d='M9.67334 17.9434L13.5419 16.6055L14.3955 16.6924L16.2402 21.9924C16.2989 22.1612 16.1622 22.3338 15.9844 22.3152L13.3986 22.0444C13.3264 22.0369 13.2546 22.0619 13.2027 22.1126L11.3439 23.9304C11.2161 24.0554 11.0017 24.005 10.9429 23.8362L9.08618 18.5016L9.67334 17.9434Z'
          fill='#98DB7C'
        />
        <path
          d='M9.08612 18.501L8.71094 17.4231L14.0214 15.6172L14.3955 16.6919C12.8875 17.7679 11.0616 18.4266 9.08612 18.501Z'
          fill='#82D361'
        />
        <path
          d='M8.71084 16.8487C0.00990415 16.8487 0.00268508 8.41819 0.00990383 8.27273C0.238607 3.66534 4.04669 0 8.71084 0C13.3719 0 17.178 3.66047 17.4113 8.2635C17.4189 8.412 17.4113 16.8487 8.71084 16.8487Z'
          fill='#E0E0E0'
        />
        <path
          d='M8.71084 16.5462C4.04668 16.5462 0.238742 12.8808 0.00999224 8.27344C0.00277349 8.41889 -0.000976562 8.56528 -0.000976562 8.71252C-0.000976562 13.5239 3.89945 17.4243 8.71084 17.4243C13.5222 17.4243 17.4226 13.5239 17.4226 8.71252C17.4226 8.56523 17.4189 8.41889 17.4117 8.27344C17.1829 12.8808 13.375 16.5462 8.71084 16.5462Z'
          fill='#CECECE'
        />
        <path
          d='M8.71073 15.0284C1.84767 15.0284 1.85231 8.41681 1.86149 8.27159C2.08795 4.68584 5.06788 1.84766 8.71073 1.84766C12.3536 1.84766 15.3335 4.68584 15.56 8.27159C15.5691 8.41681 15.56 15.0284 8.71073 15.0284Z'
          fill='#9E9E9E'
        />
        <path
          d='M8.71072 14.6974C5.06792 14.6974 2.0885 11.8591 1.86205 8.27344C1.85286 8.41866 1.84766 8.565 1.84766 8.71252C1.84766 12.5029 4.92036 15.5756 8.71072 15.5756C12.5011 15.5756 15.5738 12.5029 15.5738 8.71252C15.5738 8.56495 15.5686 8.41866 15.5594 8.27344C15.3329 11.8591 12.3535 14.6974 8.71072 14.6974Z'
          fill='#898989'
        />
        <path
          d='M10.6865 12.79H6.75261C6.27613 12.79 5.90821 11.9605 5.94407 11.771C6.04358 11.2445 6.27782 10.7485 6.62755 10.3351L9.39547 7.06381C9.65446 6.75772 9.53891 6.42791 9.49728 6.33406C9.44671 6.22002 9.28944 5.95334 8.92307 5.94097C8.91247 5.94059 8.90146 5.94041 8.89044 5.94041C8.3758 5.94041 7.95716 6.35909 7.95716 6.87373C7.95716 7.35022 7.57086 7.73652 7.09438 7.73652C6.61789 7.73652 6.2316 7.35027 6.2316 6.87373C6.2316 5.40767 7.42433 4.21484 8.89044 4.21484C8.92077 4.21484 8.95096 4.21536 8.981 4.21639C9.89835 4.24733 10.7005 4.7908 11.0747 5.63459C11.4545 6.491 11.3158 7.46572 10.7127 8.17841L7.94483 11.4497C7.93466 11.4618 7.92467 11.474 7.91492 11.4863H10.6865C11.0024 11.4863 11.2787 11.6562 11.429 11.9095C11.5054 12.0382 11.1629 12.79 10.6865 12.79Z'
          fill='#E0E0E0'
        />
        <path
          d='M10.6865 12.3321H6.75267C6.38189 12.3321 6.06666 12.0978 5.94464 11.7695C5.90878 11.9591 5.88989 12.1525 5.88989 12.3475C5.88989 12.8241 6.27619 13.2103 6.75267 13.2103H10.6865C11.163 13.2103 11.5493 12.8241 11.5493 12.3475C11.5493 12.187 11.5046 12.0372 11.4283 11.9085C11.2779 12.1618 11.0025 12.3321 10.6865 12.3321Z'
          fill='#CECECE'
        />
      </svg>
    )}
    {...props}
  />
);

export default Top2Icon;
