import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ImgCustom } from '..';
import './MessageItemFan.scss';
import { isAdmin as IS_ADMIN } from 'utils';

const MessageItemFan = ({
  images,
  name = '',
  dateTime = '',
  className = '',
  userId = '',
  unreadCount = 0,
  accountId,
  handleDelete,
  isAdmin,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className={`message-item-fan ${className} ${isAdmin && 'avatar_admin'}`}>
      <div className='content-left' onClick={() => navigate(`/message/${userId}`)}>
        <ImgCustom className='avatar' src={images} alt='avatar' />
        <div className='content-left_info'>
          <p className='name'>{isAdmin ? `${t('Acount.MessageItemFan.name')}` : name}</p>
          <p className='time mb-0'>
            {t('Acount.MessageItemFan.time')}
            {moment.utc(dateTime).local().format('YYYY/MM/DD HH:mm')}
          </p>
        </div>
      </div>
      <div className='content-right'>
        {!!unreadCount && !IS_ADMIN && (
          <button className='btn btn-danger mb-1'>
            {t('Acount.MessageItemFan.unread')}：{unreadCount > 99 ? '99+' : unreadCount}
            {t('Common.piece')}
          </button>
        )}
        {!IS_ADMIN && (
          <button onClick={handleDelete} className='btn btn-cancel'>
            {t('Common.buttonDelete')}
          </button>
        )}
      </div>
    </div>
  );
};

export default MessageItemFan;
