import { LayoutFan, PopupMessageUpdateEmail } from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleChangeEmailConfirmStep1, handleChangeEmailStep2 } from 'store/actions/users';
import { patternEmail, patternNoFirstSpacing } from 'utils/utils';
import './user.scss';

const ChangeEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [checkEmail, setCheckEmail] = useState(false);
  const [isVisibleUpdateEmailPopup, setIsVisibleUpdateEmailPopup] = useState(false);
  const { changeEmailStep2, changeEmailConfim1Failed } = useSelector((state) => state.users);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: 'all' });
  const watchEmail = watch('email', '');
  const { loading } = useSelector((state) => state.users);
  const { t } = useTranslation();
  const isDisabledButton = !watchEmail || checkEmail;
  const location = useLocation();
  const token = location.pathname.split('/')?.[3];
  useEffect(() => {
    changeEmailStep2 && setIsVisibleUpdateEmailPopup(true);
  }, [changeEmailStep2]);

  useEffect(() => {
    token && dispatch(handleChangeEmailConfirmStep1(token));
  }, [token]);

  useEffect(() => {
    changeEmailConfim1Failed === true && navigate('/user/profile');
  }, [changeEmailConfim1Failed]);

  const onSubmit = (data) => {
    dispatch(handleChangeEmailStep2(data.email));
  };

  return (
    <div>
      {isVisibleUpdateEmailPopup && (
        <PopupMessageUpdateEmail text={t('Popup.changeMessToMail2')} className='update-email'>
          <div
            className='ok btn'
            style={{ marginTop: 14 }}
            onClick={() => {
              setIsVisibleUpdateEmailPopup(false);
              navigate('/user/profile');
            }}>
            {t('Common.Ok')}
          </div>
        </PopupMessageUpdateEmail>
      )}
      <LayoutFan titleHeader={t('User.changeEmail.title')} loading={loading}>
        <div className='user-wrapper change-email'>
          <div className='box-user-wrapper'>
            <form onSubmit={(e) => e.preventDefault()} className='fansite-form'>
              <InputWrapper
                label={t('Common.newEmail')}
                required={true}
                error={errors?.email ? errors?.email?.message : ''}>
                <input
                  {...register('email', {
                    required: t('ValidateMsg.newEmailRequired'),
                    validate: {
                      noFirstSpacing: (value) => {
                        return (
                          (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                          t('ValidateMsg.newEmailRequired')
                        );
                      },
                      email: (value) => {
                        return (
                          (value && patternEmail.test(value.toString().trim())) ||
                          t('ValidateMsg.EMAIL_FORMAT_INVALID')
                        );
                      },
                    },
                    onChange: (e) => {
                      const { value } = e.target;
                      !patternNoFirstSpacing.test(value.toString().trim()) ||
                      !patternEmail.test(value.toString().trim())
                        ? setCheckEmail(true)
                        : setCheckEmail(false);
                    },
                  })}
                  placeholder={t('ValidateMsg.newEmailRequired')}
                />
              </InputWrapper>
              <InputWrapper
                id='fsite-submit'
                className={`button-submit ${isDisabledButton ? 'disabled' : ''}`}>
                <button onClick={handleSubmit(onSubmit)} disabled={isDisabledButton}>
                  {t('Common.buttonChange')}
                </button>
              </InputWrapper>
              <InputWrapper id='fsite-submit' className={`button-cancel`}>
                <button onClick={() => navigate('/user/profile')} style={{ marginTop: 24 }}>
                  {t('Common.buttonCancel')}
                </button>
              </InputWrapper>
            </form>
          </div>
        </div>
      </LayoutFan>
    </div>
  );
};

export default ChangeEmail;
