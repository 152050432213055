import Icon from '@ant-design/icons';

const DeleteIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width='16'
        height='18'
        viewBox='0 0 16 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.4582 4C14.4582 3.655 14.1782 3.375 13.8332 3.375H2.1665C1.8215 3.375 1.5415 3.655 1.5415 4V15.25C1.5415 15.9683 1.8265 16.6575 2.33484 17.165C2.84234 17.6733 3.5315 17.9583 4.24984 17.9583H11.7498C12.4682 17.9583 13.1573 17.6733 13.6648 17.165C14.1732 16.6575 14.4582 15.9683 14.4582 15.25C14.4582 11.5 14.4582 4 14.4582 4ZM13.2082 4.625V15.25C13.2082 15.6367 13.0548 16.0075 12.7807 16.2808C12.5073 16.555 12.1365 16.7083 11.7498 16.7083C9.63151 16.7083 6.36817 16.7083 4.24984 16.7083C3.86317 16.7083 3.49234 16.555 3.219 16.2808C2.94484 16.0075 2.7915 15.6367 2.7915 15.25V4.625H13.2082Z'
          fill={props?.color || '#666565'}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.75 4.6224C12.095 4.6224 12.375 4.3424 12.375 3.9974C12.375 3.9974 12.375 3.16406 12.375 2.33073C12.375 1.72323 12.1333 1.1399 11.7042 0.709896C11.2742 0.280729 10.6908 0.0390625 10.0833 0.0390625C8.84333 0.0390625 7.15667 0.0390625 5.91667 0.0390625C5.30917 0.0390625 4.72583 0.280729 4.29583 0.709896C3.86667 1.1399 3.625 1.72323 3.625 2.33073V3.9974C3.625 4.3424 3.905 4.6224 4.25 4.6224H11.75ZM11.125 3.3724H4.875V2.33073C4.875 2.05406 4.985 1.7899 5.18 1.59406C5.37583 1.39906 5.64 1.28906 5.91667 1.28906H10.0833C10.36 1.28906 10.6242 1.39906 10.82 1.59406C11.015 1.7899 11.125 2.05406 11.125 2.33073V3.3724Z'
          fill={props?.color || '#666565'}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.6666 3.375H1.33325C0.988252 3.375 0.708252 3.655 0.708252 4C0.708252 4.345 0.988252 4.625 1.33325 4.625H14.6666C15.0116 4.625 15.2916 4.345 15.2916 4C15.2916 3.655 15.0116 3.375 14.6666 3.375Z'
          fill={props?.color || '#666565'}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.2915 7.75V13.5833C5.2915 13.9283 5.5715 14.2083 5.9165 14.2083C6.2615 14.2083 6.5415 13.9283 6.5415 13.5833V7.75C6.5415 7.405 6.2615 7.125 5.9165 7.125C5.5715 7.125 5.2915 7.405 5.2915 7.75Z'
          fill={props?.color || '#666565'}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.45825 7.75V13.5833C9.45825 13.9283 9.73825 14.2083 10.0833 14.2083C10.4283 14.2083 10.7083 13.9283 10.7083 13.5833V7.75C10.7083 7.405 10.4283 7.125 10.0833 7.125C9.73825 7.125 9.45825 7.405 9.45825 7.75Z'
          fill={props?.color || '#666565'}
        />
      </svg>
    )}
    {...props}
  />
);

export default DeleteIcon;
