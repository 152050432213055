/* eslint-disable react-hooks/exhaustive-deps */

import RouteSuspense from 'components/RouteSuspense';
import Banner from 'pages/affiliator/layout/banner';
import BannerDetail from 'pages/affiliator/layout/banner/detail';
import SaleManagement from 'pages/affiliator/layout/sales-management';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

export default function AffiliatorLayout() {
  return (
    <Routes>
      <Route
        path='/banners'
        element={
          <RouteSuspense>
            <Banner />
          </RouteSuspense>
        }
      />
      <Route
        path='/banners/:banner_id'
        element={
          <RouteSuspense>
            <BannerDetail />
          </RouteSuspense>
        }
      />
      <Route
        path='/sales-management'
        element={
          <RouteSuspense>
            <SaleManagement />
          </RouteSuspense>
        }
      />
    </Routes>
  );
}
