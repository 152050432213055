// import { InputWrapper } from 'components/Input/InputWrapper';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PopupGeneral } from '..';
import { CheckedIcon } from '../../images';
import { ENV } from '../../utils/constant';
import Storage from 'utils/storage';
import './index.scss';
import PlusLiveIcon from 'images/plusIconLive';
const PopupC90SelectUser = ({
  handleListSelectedUser,
  isDisabled = true,
  listUserSelected,
  classNameCustom = '',
  isOpen = () => {},
}) => {
  const [isVisiblePopUp, setIsVisiblePopUp] = useState(false);
  const [valueInput, setValueInput] = useState('');
  const tableSetting = useRef();
  const [dataSearch, setDataSearch] = useState([]);
  const [listSelected, setListSelected] = useState([]);
  const [loadding, setLoading] = useState(false);
  const [checkCallApi, setCheckCallApi] = useState(false);

  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(2);
  const limit = 10;

  const [msgDataNull, setMsgDataNull] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    listUserSelected && setListSelected(listUserSelected);
  }, [listUserSelected]);

  const scrollToBottom = () => {
    if (isVisiblePopUp) {
      const scroll = tableSetting.current.scrollHeight - tableSetting.current.clientHeight;
      tableSetting.current.scrollTo(0, scroll);
    }
  };

  useEffect(() => {
    isVisiblePopUp && scrollToBottom();
    isOpen(isVisiblePopUp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisiblePopUp, setListSelected]);

  const handleSubmit = async (page) => {
    setLoading(true);
    setCheckCallApi(true);
    const res = await fetch(
      `${ENV}/search-users?keyword=${valueInput}&limit=${limit}&page=${page}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
        },
      }
    );
    res
      .json()
      .then((data) => {
        const obj = {};
        if (data?.data?.length < limit) {
          setMaxPage(page);
        }
        if (data.data?.length > 0) {
          listSelected.forEach((item) => {
            obj[item?.id] = true;
          });
          const newArr = data.data.map((item) => {
            return { ...item, checked: obj[item?.id] };
          });
          setMaxPage((prev) => prev + 1);
          setDataSearch(dataSearch.concat(newArr));
        } else {
          setMaxPage(page);
        }

        setLoading(false);
        setMsgDataNull(`${t('Common.noData')}`);
      })
      .catch(() => setLoading(true));
  };

  const handleSelectUser = (item) => {
    const x = listSelected?.find((oldItem) => oldItem?.id === item?.id);
    const newListChecked = !x
      ? listSelected.concat(item)
      : listSelected.filter((y) => y.id !== item.id);
    listSelected > 0 &&
      setListSelected(
        !x ? listSelected.concat(item) : listSelected.filter((y) => y.id !== item.id)
      );
    handleListSelectedUser(newListChecked);
  };

  useEffect(() => {
    if (dataSearch.length > 0) {
      const obj = {};
      listSelected.forEach((item) => {
        obj[item?.id] = true;
      });
      const newArr = dataSearch.map((item) => {
        return { ...item, checked: obj[item?.id] };
      });
      setDataSearch(newArr);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listSelected]);

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      if (page + 1 <= maxPage) {
        setPage((prev) => prev + 1);
        handleSubmit(page + 1);
      }
    }
  };

  return (
    <>
      <PlusLiveIcon
        className={`btn-popup-select-user-live  ${isDisabled ? '' : 'notAllow'}`}
        onClick={() => {
          isDisabled && setIsVisiblePopUp(true);
        }}
      />
      {isVisiblePopUp && (
        <PopupGeneral
          className={`popup-ng-setting popup-select-user ${classNameCustom}`}
          generalClass='pp-up-ng-setting-views popup-select-user-view'
          closeModal={() => {
            setIsVisiblePopUp(false);
            setValueInput('');
            setDataSearch([]);
            setPage(1);
            setMaxPage(2);
            setMsgDataNull('');
          }}
          title={t('Common.search')}>
          <div
            className=''
            style={{ paddingLeft: '20px', paddingRight: '20px', display: 'flex' }}
            ref={tableSetting}>
            <div className='search-wrapper'>
              <input
                type='text'
                placeholder=''
                className='fsite-input'
                value={valueInput}
                // onKeyPress={(e) => {
                // e.preventDefault();
                // if (e.key === "Enter") {
                //   return valueInput ? handleSubmit() : null;
                // }
                // }}
                onChange={(e) => {
                  setValueInput(e.target.value);
                }}
              />
              {dataSearch && dataSearch.length > 0 ? (
                <div
                  onScroll={(e) => {
                    handleScroll(e);
                  }}
                  className='list-search scroll-bar-custom '>
                  {dataSearch.map((item, index) => (
                    <>
                      <div
                        key={item.id}
                        className={`item-search ${item.id} ${item?.checked && 'active'}`}
                        onClick={() => handleSelectUser(item)}>
                        {item.account_name}
                        <CheckedIcon
                          key={index}
                          className={`icon-checked ${item?.checked ? 'active' : 'hidden'}`}
                        />
                      </div>
                    </>
                  ))}
                </div>
              ) : (
                <>
                  {checkCallApi && !loadding && !dataSearch.length && msgDataNull !== '' && (
                    <div className='no-data' key={'no-data'} style={{ position: 'absolute' }}>
                      {msgDataNull}
                    </div>
                  )}
                </>
              )}
            </div>
            <button
              className={`btn text-white ${
                valueInput ? ' btn-submit danger ' : ''
              }  rounded-6px`}
              type='button'
              disabled={!valueInput}
              onClick={() => {
                setDataSearch([])
                setPage(1);
                setMaxPage(2);
                handleSubmit(1);
              }}
              style={{ width: 77, marginLeft: 9 }}>
              {loadding ? (
                <div
                  className='spinner-border m-auto'
                  role='status'
                  style={{ width: '15px', height: '15px', color: '#fff' }}></div>
              ) : (
                `${t('Common.search')}`
              )}
            </button>
          </div>
        </PopupGeneral>
      )}
    </>
  );
};

export default PopupC90SelectUser;
