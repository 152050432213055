import Icon from '@ant-design/icons';

const VisaIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={props?.width || 32}
        height={props?.height || 12}
        viewBox='0 0 48 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M23.7665 0.49256L20.5597 15.485H16.6808L19.888 0.49256H23.7665ZM40.0845 10.1734L42.1259 4.54339L43.3007 10.1734H40.0845ZM44.4135 15.485H48L44.8667 0.49256H41.5584C40.8128 0.49256 40.1843 0.924855 39.9061 1.59151L34.0863 15.485H38.1597L38.9683 13.246H43.9438L44.4135 15.485ZM34.2883 10.5905C34.3051 6.63372 28.8185 6.41454 28.8552 4.64658C28.867 4.10941 29.3796 3.53718 30.4998 3.39083C31.0552 3.31934 32.5877 3.26134 34.325 4.06187L35.0045 0.881356C34.0714 0.543815 32.8707 0.21875 31.3765 0.21875C27.5422 0.21875 24.8449 2.25546 24.8233 5.1743C24.7987 7.33275 26.7501 8.53623 28.2172 9.25515C29.7299 9.98992 30.2368 10.461 30.2293 11.1175C30.2189 12.1234 29.0232 12.5689 27.9097 12.5857C25.9587 12.6161 24.8277 12.0583 23.9263 11.6382L23.2222 14.9249C24.13 15.3403 25.8025 15.7018 27.5344 15.7204C31.6109 15.7204 34.2761 13.7076 34.2883 10.5905ZM18.2242 0.49256L11.9397 15.485H7.84033L4.7475 3.51998C4.56001 2.7842 4.39647 2.51376 3.82626 2.20286C2.89355 1.69638 1.35387 1.22261 0 0.927891L0.0917206 0.49256H6.69148C7.53213 0.49256 8.28848 1.05198 8.48102 2.02043L10.1148 10.6967L14.1494 0.49256H18.2242Z'
          fill='#1434CB'
        />
      </svg>
    )}
    {...props}
  />
);

export default VisaIcon;
