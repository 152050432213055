import styled, { css } from 'styled-components';

export const Styled = {
  // WrapperFixed: styled.div`
  //   position: fixed;
  //   ${({ top0 }) =>
  //     top0
  //       ? css`
  //           top: 0;
  //         `
  //       : css`
  //           top: 60px;
  //         `}
  // `,
  WrapperFixed: styled.div`
    margin-top: 24px;
    /* filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.08)); */
  `,
  // Wrapper: styled.div`
  //   position: relative;
  //   background-color: transparent;
  //   // box-shadow: 0px 40px 64px 16px rgba(0, 0, 0, 0.05);
  //   overflow: hidden;

  //   display: flex;
  //   flex-direction: column;
  //   // padding: 16px 0 0 16px;
  //   ${({ height100 }) =>
  //     height100
  //       ? css`
  //           height: calc(100vh);
  //         `
  //       : css`
  //           height: calc(100vh - 60px);
  //         `}
  // `,
  Wrapper: styled.div`
    position: relative;
    background-color: transparent;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 25vw !important;
  `,
  CocoFansRecommendWrapper: styled.div`
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 20px;
    background-color: #fff;
    padding: 20px 0 0 20px;
    max-width: 330px;
    @media (orientation: landscape) {
      //cmtout
      /* margin-top: 20px; */
    }
    ${({ height100 }) =>
      height100
        ? css`
            height: calc(100%);
            margin-bottom: 0;
          `
        : css`
            //height: 435px;
            /* height: 70%; */       //cmtout
            height: 100%;
          `}
  `,
  ListCocoFansRecommend: styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 56px);
    display: flex;
    flex-direction: column;
    gap: 15px;
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
    }

    .btn-follow {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 12px;
      width: 80px;
      height: 36px;
      border: 1px solid #b1b6bb;
      border-radius: 6px;
      background: #ffffff;
    }

    .btn-followOrUnFollow {
      font-weight: 700;
      font-size: 12px;
      line-height: 20px;
      padding: 6px 12px;
      border-radius: 6px;
      background: #DC143C;
      color: #fff;
      height: max-content;
      white-space: nowrap;
      border-radius: 6px;
      border: 1px solid #000;
      cursor: pointer;
      
      &.following {
        color: #000;
        background: #fff;
        border-radius: 4px;
        border: 1px solid #e5e6eb !important;
        font-weight: 700;
        border: none;
      }

      &.no-follow {
        color: #fff;
        border: 1px solid #e5e6eb;
        &:hover {
          background: rgba(255, 0, 0, 0.1) !important;
          border: 1px solid rgba(255, 0, 0, 0.1) !important;
          color: #DC143C;
        }
      }

      &.hover {
        background: #f1f1f1;
        //background: rgba(255, 0, 0, 0.1);
        //border: 1px solid rgba(255, 0, 0, 0.1);
        //color: #DC143C;
        font-weight: 700;
      }
    }

    /* .btn-followOrUnFollow {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 36px;
      border: 1px solid #e5e6eb;
      width: 160px;
      background-color: #fff;
      color: #000;
      &:hover {
        background-color: #f1f1f1;
      }
    }
    .btn-followOrUnFollow.active {
      width: 277px;
      color: #fff;
      background-color: #dc143c;
      border-color: #dc143c;
      &:hover {
        background-color: #CE1338;
        border-color: #CE1338;
      }
    } */

    button {
      height: 32.1px;
      padding-top: 0;
      padding-bottom: 0;
      font-weight: 600;
    }

    .item {
      display: flex;
      gap: 12px;
      align-items: center;
      padding-right: 20px;
      cursor: pointer;
      ${({ paddingBottomLastChild }) =>
        paddingBottomLastChild
          ? css`
              &:last-child {
                padding-bottom: 24px;
              }
            `
          : css``}
      img {
        min-height: 56px;
        min-width: 56px;
        max-height: 56px;
        max-width: 56px;
        border-radius: 50%;
      }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 12px;
        line-height: 22px;
        padding-right: 16px;
        overflow: hidden;
        flex: 1 1 100%;
      }
      .name {
        font-weight: 900;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .content {
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  `,
  PostContentRecommendWrapper: styled.div`
    position: relative;
    /* overflow: hidden;
    border-radius: 20px; */
    background-color: #fff;
    padding: 0px;
    max-width: 330px;
    border-radius: 20px;
    overflow: hidden;
    @media (orientation: landscape) {
      //height: calc(50% - 225px);
    }
    img {
      background-size: cover;
      background-position: center;
      width: 100%;
      height: 100%;
      max-height: 186px;
      cursor: pointer;
    }
  `,
  ListPostContentRecommend: styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 56px);
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
    }
    .item {
      //display: flex;
      gap: 6px;
      padding-right: 16px;
      flex-direction: column;
      padding-bottom: 20px;
      cursor: pointer;
      &:last-child {
        padding-bottom: 10px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 6px;
        /* background-color: #000; */
      }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 22px;
        padding-right: 16px;
        flex: 1 1 100%;
        overflow: hidden;
      }
      .name {
        font-weight: 900;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .content {
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  `,
  Title: styled.div`
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 20px;
    ${({ fontSize16 }) =>
      fontSize16
        ? css`
            font-size: 16px;
          `
        : css`
            font-size: 20px;
          `}
    @media screen and (max-width: 1279px) {
      font-size: 16px;
    }
  `,
  BlueImg: styled.div`
    background: url(${({ url }) => "'" + url + "'"});
    height: ${({ height }) => height}px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
    border-radius: 6px;
    .bg-img {
      border-radius: 6px;
      overflow: hidden;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 9;
      background-size: cover;
      min-height: 100%;
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(20px);
    }
  `,

  NoData: styled.div`
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f1afbb;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
  `,

  DonateWrapper: styled.div`
    position: relative;
    overflow: hidden;
    margin-bottom: 24px;
    height: calc(100%);
    margin-bottom: 0;
  `,
  ListDonate: styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 75px);
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
    }
    .item {
      display: flex;
      align-items: center;
      gap: 16px;
      & + .item {
        margin-top: 24px;
      }
      &:last-child {
        padding-bottom: 75px;
      }
      .avatar {
        min-height: 74px;
        min-width: 74px;
        max-height: 74px;
        max-width: 74px;
        border-radius: 50%;
      }
      .rank-icon {
        min-width: 40px;
        max-width: 40px;
        min-height: 40px;
        max-height: 40px;
        background-color: transparent;
      }
      .number-ranking {
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        min-width: 40px;
        max-width: 40px;
        text-align: center;
      }
      .name {
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex: 1 1 100%;
        padding-right: 16px;
      }
    }
  `,

  SelectDonate: styled.div`
    position: relative;
    width: max-content;
    margin-bottom: 16px;
    .anticon {
      position: absolute;
      right: 15.3px;
      top: 50%;
      transform: translateY(-50%);
      color: #b1b6bb;
      z-index: 1;
    }
    select {
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
    }
    .anticon {
      right: 10px;
      &.focus {
        svg {
          transform: rotate(90deg);
        }
      }
      &.no-focus {
        svg {
          transform: rotate(-90deg);
        }
      }
      svg {
        width: 20px;
        height: 11px;
      }
    }
  `,
};
