import { ImgCustom, PopupConfirmFan, PopupSharePost, ReadMoreLess } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { getEnv } from 'configs/env';
import { newPathUser } from 'constants/layout/constant';
import {
  ChatIcon,
  CloseIcon,
  HeartActiveIcon,
  HeartIcon,
  IconBookmark,
  LockedIcon1,
  MuteIconAct,
  ShareIcon,
  ThreePointIcon,
} from 'images';
import { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetActionCreatorHomePage } from 'store/actions/creator';
import { formatNumber, isAdmin, AVATAR_DEFAULT, setOverflowTablet, STORAGE } from 'utils';
import {
  postFollowUser,
  getListPostCreator,
  creatorDeleteArticle,
  resetCommentDetail,
  postLikeDetail,
  postBookMarkDetail,
  hiddenPostMypage,
  checkIsShortMypage,
  getPageShort,
} from 'store/actions/usersNew';
import './index.scss';
import { LazyAware1 } from './lazyAware1';
import { Card } from './ListImgTimeline/Card';
import MuteShortVideoIcon from 'images/muteShortVideo';
import PopupViewFullMess from './comment/popupCommemt';
import InfoBox from './infoBox';
import PlayTikTokIcon from 'images/PlayIconTiktok';
import { LayerWrapClickVideo, LockScreen, PlaceHolderItem } from './style';
import { ReadMoreNew } from 'pages/creator/layout/timeline/readmore';
import { isMobile, isSafari } from 'react-device-detect';
import VideoShortPlayer from './VideoPlayerShort';
import Storage from 'utils/storage';
import { logUserProfile } from 'store/actions/myPage';
import HiddenShortVideo from 'images/HiddenShortVideo';
import WaitApprovePostWhiteIcon from 'images/WaitApprovePostWhiteIcon';

export const TimelineWrapperContext = createContext();

const GuardFollowed = ({
  item,
  index,
  dataUser,
  dataInfoOther,
  toggleFollowAndPlay,
  handleFollowCreator,
  isAuth,
  renderItemVideo,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { listPostCreator } = useSelector((state) => state.usersNew);
  const [isFollowed, setIsFollowed] = useState(0);
  useEffect(() => {
    const findUser = listPostCreator.find((it) => it.id === item?.id);
    setIsFollowed(findUser?.is_followed);
  }, [listPostCreator]);
  return (
    <>
      {isFollowed === 0 &&
        item?.follow_flag === 1 &&
        dataUser?.id !== dataInfoOther?.id &&
        !isAdmin && (
          <div style={item?.medias?.length === 0 ? { paddingBottom: '35px' } : {}}>
            <LockScreen>
              <div className='text-follow'>{t('DetailCard.notYetFollow')}</div>
              {!isAdmin && (
                <button
                  onClick={(e) => {
                    if (!isAuth) {
                      dispatch(logUserProfile(item));
                    } else {
                      e.stopPropagation();
                      toggleFollowAndPlay(index, item?.user_id);
                      handleFollowCreator(item?.user_id);
                    }
                  }}>
                  {t('Common.follow')}
                </button>
              )}
            </LockScreen>
          </div>
        )}
      {renderItemVideo(item, index)}
    </>
  );
};
const TimelineShort = ({ item, close, indexIdx }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isShowOtherBox, setIsShowOtherBox] = useState({
    isShow: false,
    indexShow: -1,
  });
  const [listShort, setListShort] = useState([]);
  const [indexPlay, setIndexPlay] = useState(0);
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const [isShowPopup, setShowPopup] = useState({
    index: 0,
    status: false,
    dataItem: {},
  });
  const { loading } = useSelector((state) => state.creator);
  const { pagination, listPostCreator, dataInfoOther } = useSelector(
    (state) => state.usersNew
  );
  const { dataUser } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const listRefVideo = useRef([]);
  const [page, setPage] = useState(pagination?.current_page);
  const [firstLoading, setFirstLoading] = useState(false);
  const [flgRenderCMT, setFlgRenderCMT] = useState(false);
  const [hoverId, setHoverId] = useState(false);
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const widthScreen = window.innerWidth;
  
  useEffect(() => {
    if (popupCopyLink) {
      document.getElementById('root').style.overflow = 'hidden';
      document.getElementById('root').style.position = 'fixed';
      document.getElementById('root').style.width = '100%';
    } else {
      // document.getElementById('root').style.overflow = 'auto';
      setOverflowTablet();
      document.getElementById('root').style.position = 'revert';
    }
  }, [popupCopyLink]);

  // useEffect(() => {
  //   dispatch(resetActionCreatorHomePage());
  // }, []);

  useEffect(() => {
    if (!flgRenderCMT) {
      const arrayFilter = listPostCreator.filter(
        (i, index) => index >= indexIdx && i.id !== item?.id
      );
      const arrayFind = listPostCreator.find((i) => i.id === item?.id);
      const arrayNew = [arrayFind].concat(arrayFilter);
      if (arrayNew && arrayNew?.length > 0) {
        const listChange = arrayNew?.map((item, index) => {
          let newItem = item;
          if (listShort[index]) {
            newItem = listShort[index];
          }
          if (index === indexPlay) {
            return {
              ...newItem,
              isFullContent: false,
              isPlay:
                item?.is_followed === 0 &&
                item?.follow_flag === 1 &&
                dataUser?.id !== dataInfoOther?.id
                  ? false
                  : true,
              isMute: isSafari && isMobile ? true : false,
              //type: newItem?.medias && newItem?.medias[0]?.type,
              duration:
                newItem?.medias && newItem?.medias[0]?.type === 2
                  ? newItem?.medias[0]?.duration
                  : 0,
            };
          }
          return {
            ...newItem,
            isFullContent: false,
            isPlay: false,
            isMute: true,
            //type: newItem?.medias && newItem?.medias[0]?.type,
            duration:
              newItem?.medias && newItem?.medias[0]?.type === 2
                ? newItem?.medias[0]?.duration
                : 0,
          };
        });
        setListShort(listChange);
      }
    }
  }, [listPostCreator]);

  const updateList = useCallback(
    (indexItem) => {
      setIndexPlay(indexItem);
      if (indexItem === listShort?.length - 1) {
        setPage((prevPage) => prevPage + 1);
      }
      if (listShort && listShort?.length > 0) {
        const listChange = listShort?.map((item, index) => {
          if (index === indexItem) {
            return {
              ...item,
              isPlay:
                item?.is_followed === 0 &&
                item?.follow_flag === 1 &&
                dataUser?.id !== dataInfoOther?.id
                  ? false
                  : true,
              isMute: isSafari && isMobile ? true : false,
              type: item?.medias && item?.medias[0]?.type,
            };
          }
          return {
            ...item,
            isPlay: false,
            isMute: true,
            type: item?.medias && item?.medias[0]?.type,
          };
        });
        setListShort(listChange);
      }
    },
    [listShort, page]
  );

  const funCallApiListPostShort1 = useCallback(() => {
    page === 1 && setFirstLoading(true); // page = 1
    if (pagination?.current_page < pagination?.total_pages) {
      dispatch(
        getListPostCreator(
          {
            type: 4,
            page: page || 1,
            limit: widthScreen <= 575 ? 4 : 10,
            account_id: dataInfoOther?.id || '',
            id: dataInfoOther?.id,
          },
          true,
          () => setFirstLoading(false),
          () => setFirstLoading(false)
        )
      );
    }
  }, [page]);

  useEffect(() => {
    funCallApiListPostShort1();
  }, [funCallApiListPostShort1]);

  const fetchNext = () => {
    const pages = pagination?.total / pagination?.per_page;
    if (pages > page) {
      setPage(page + 1);
    }
  };

  const handleLikePost = (id) => {
    dispatch(postLikeDetail(id));
  };

  const handleFollowCreator = (id) => {
    dispatch(postFollowUser(id));
    setIsShowOtherBox({
      isShow: false,
      indexShow: -1,
    });
  };

  const handleHiddenPost = (dt) => {
    dispatch(hiddenPostMypage(dt?.id));
    setIsShowOtherBox({
      isShow: false,
      indexShow: -1,
    });
  };

  const handlePostBookMark = (post) => {
    dispatch(postBookMarkDetail(post?.id));
  };

  const toggleValueVideo = useCallback(
    (index, name = 'isPlay', value) => {
      const listClone = JSON.parse(JSON.stringify(listShort)) || [];
      if (listClone?.length > 0) {
        if (name === 'is_liked') {
          listClone[index][name] = value || !listClone[index][name];
          if (value === 1) {
            listClone[index]['number_like'] = listClone[index]['number_like'] + 1;
          } else {
            listClone[index]['number_like'] =
              listClone[index]['number_like'] > 0 ? listClone[index]['number_like'] - 1 : 0;
          }
        } else if (name === 'number_comment') {
          listClone[index]['number_comment'] =
            listClone[index]['number_comment'] + (value || 1);
        } else {
          listClone[index][name] = value || !listClone[index][name];
        }
      }
      setListShort(listClone);
    },
    [listShort]
  );

  const toggleFollowAllUser = useCallback(
    (index, userId, playValue = true, followValue = 1) => {
      const listClone = JSON.parse(JSON.stringify(listShort)) || [];
      if (listClone?.length > 0) {
        const newList = listClone?.map((item, indexItem) => {
          if (index === indexItem) {
            return {
              ...item,
              isPlay: playValue,
              is_followed: followValue,
            };
          } else if (item?.user_id === userId) {
            return {
              ...item,
              isPlay: false,
              is_followed: followValue,
            };
          }
          return item;
        });
        setListShort(newList);
      }
    },
    [listShort]
  );

  const toggleHiddenUser = useCallback(
    (index, userId, playValue = true, followValue = 1, status) => {
      const listClone = JSON.parse(JSON.stringify(listShort)) || [];
      if (listClone?.length > 0) {
        const newList = listClone?.map((item, indexItem) => {
          if (index === indexItem) {
            return {
              ...item,
              isPlay: playValue,
              is_hided: followValue,
              post_status: status
            };
          } else if (item?.user_id === userId) {
            return {
              ...item,
              isPlay: false,
              is_hided: followValue,
              post_status: status
            };
          }
          return item;
        });
        setListShort(newList);
      }
    },
    [listShort]
  );

  const toggleFollowAndPlay = useCallback(
    (index, userId) => {
      toggleFollowAllUser(index, userId, true, 1);
    },
    [toggleFollowAllUser]
  );

  useEffect(() => {
    if (listShort) {
      listRefVideo.current = listRefVideo.current.slice(0, listShort?.length);
    }
  }, [listShort]);

  const renderItemVideo = (item, index) => {
    const { medias } = item;

    const isMiniUrl =
      item?.is_followed === 0 && item?.follow_flag === 1 && item?.user_id !== dataUser?.id;
    if (medias) {
      if (medias[0]?.type === 2) {
        return (
          <LazyAware1
            key={index}
            render={({ ref, wasIntersected, isIntersecting }) => {
              return (
                <div className='page-list-short-video-item__video' ref={ref}>
                  {wasIntersected ? (
                    <>
                      <VideoShortPlayer
                        item={item}
                        index={index}
                        indexPlay={indexPlay}
                        wasIntersected={wasIntersected}
                        isIntersecting={isIntersecting}
                        updateList={updateList}
                        isSearch={false}
                      />
                      <LayerWrapClickVideo
                        onClick={() => {
                          toggleValueVideo(index);
                        }}
                      />
                      {renderIcon(item, index)}
                    </>
                  ) : (
                    <PlaceHolderItem />
                  )}
                </div>
              );
            }}
          />
        );
      } else {
        return (
          <LazyAware1
            key={index}
            render={({ ref, wasIntersected, isIntersecting }) => {
              if (wasIntersected && isIntersecting) {
                if (index !== indexPlay) {
                  updateList(index);
                }
              }
              return (
                <div className='page-list-short-video-item__video' ref={ref}>
                  {wasIntersected ? (
                    <>
                      <Card
                        toggleValueVideo={() => {
                          toggleValueVideo(index);
                        }}
                        data={medias || []}
                        key={item?.id}
                        isMiniUrl={isMiniUrl}
                        isIntersecting={isIntersecting}
                        isPlay={item?.isPlay}
                      />
                      {renderIcon(item, index)}
                    </>
                  ) : (
                    <PlaceHolderItem />
                  )}
                </div>
              );
            }}
          />
        );
      }
    }
  };

  const renderIcon = (item, index) => {
    const checkDisableIcon = item?.is_active === 0 || item?.is_active === 2 || item?.post_status === 'reject' || item?.post_status === 'reviewing';

    return (
      <>
        <div
          className='play-icon index10'
          onClick={(e) => {
            e.stopPropagation();
            toggleValueVideo(index);
          }}>
          {!item?.isPlay && <PlayTikTokIcon />}
        </div>
        <div
          className='mute-icon index10'
          onClick={() => {
            if (item?.type !== 1) {
              toggleValueVideo(index, 'isMute');
            }
          }}>
          {item?.isMute || item?.type === 1 ? <MuteShortVideoIcon /> : <MuteIconAct />}
        </div>
        {item?.post_status && item?.post_status !== 'public' && 
          <div style={{ color: '#DC143C', position:'absolute', top: `${isMobile ? '25px' : '20px'}`, left: `${isMobile ? '70px' : '16px'}`, zIndex:'10' }}>
            <label
              className={`${
                item?.post_status
              }-status`}
              htmlFor={`comment-item-${item?.id}`}>
                {t(`DetailCard.${item?.post_status}`)}
            </label>
          </div>
        }
        <div className='block-info index20'>
          <div
            className={`avatar ${item?.isFullContent ? 'full-content' : ''}`}
            onClick={() => {
              close();
              navigate(`${newPathUser}${item?.account_id}`);
            }}>
            {item?.avatar ? (
              <ImgCustom screen='avatar_44_44' src={item?.avatar} alt='avatar11' />
            ) : (
              <img src={AVATAR_DEFAULT} alt='AVATAR_DEFAULT' />
            )}
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span className='name'>{item?.account_name}</span>
              {!(item?.follow_flag === 1 && item?.is_followed === 0) && item?.user_id !== dataUser?.id && (
                <div
                  style={{
                    border: '1px solid #DC143C',
                    borderRadius: '20px',
                    alignSelf: 'flex-start',
                    padding: '0 5px',
                    marginLeft: '15px',
                    cursor: 'pointer',
                    fontSize: '12px',
                    fontWeight: '500',
                  }}
                  className={`btn-followOrUnFollow ${
                    item?.is_followed === 1 ? 'following' : 'no-follow'
                  } ${item?.is_followed === 1 && hoverId === item?.id ? 'hover' : ''}`}
                  onMouseOver={() => {
                    if (item?.is_followed === 1 && item?.id) {
                      !isMobile && setHoverId(item.id);
                    }
                  }}
                  onMouseDown={() => {
                    if (!isAuth) {
                      setIsShowOtherBox(false);
                      dispatch(logUserProfile(item));
                    } else {
                      handleFollowCreator(item?.user_id);
                      setIsShowOtherBox(false);
                      if (item?.follow_flag === 1) {
                        toggleFollowAllUser(index, item?.user_id, false, 0);
                      } else {
                        toggleFollowAllUser(
                          index,
                          item?.user_id,
                          true,
                          item?.is_followed === 1 ? 0 : 1
                        );
                      }
                    }
                  }}
                  onMouseLeave={() => {
                    if (item?.is_followed === 1) {
                      setHoverId(false);
                    }
                  }}>
                  {widthScreen > 991 ? (
                    item?.is_followed === 1
                    ? hoverId === item?.id
                      ? t('Creator.FollowList.unfollow')
                      : t('Creator.FollowList.following')
                    : t('Creator.FollowList.followUs')
                  ) : (
                    item?.is_followed === 1
                      ? t('Creator.FollowList.following')
                    : t('Creator.FollowList.followUs')
                  )}
                </div>
              )}
            </div>
            {/* {item?.is_active === 0 ? (
              <div className='ms-1 mb-1'>
                <WaitApprovePostWhiteIcon />
              </div>
            ) : item?.is_active === 2 ? (
              <div className='ms-1 mb-1'>
                <LockedIcon1 />
              </div>
            ) : (
              item?.is_hided === 1 && (
                <div className='ms-1 mb-1'>
                  <HiddenShortVideo />
                </div>
              )
            )} */}
          </div>
          <div className={`content-des ${item?.isFullContent ? 'full-content' : ''}`}>
            <ReadMoreNew
              isFullContent={item?.isFullContent}
              setIsFullContent={() => {
                toggleValueVideo(index, 'isFullContent');
              }}
              content={item?.content}
              charLimit={isMobile ? 32 : 48}
            />
            {/* {item?.isFullContent && (
              <div
                className='btn-read btn-read-less'
                onClick={() => {
                  toggleValueVideo(index, 'isFullContent');
                }}>
                {t('ReadMoreLess.lessText')}
              </div>
            )} */}
          </div>
        </div>
        <div
          className={`group-icon ${isAdmin && 'disabled'} ${
            item?.is_followed === 0 &&
            item?.follow_flag === 1 &&
            dataUser?.id !== dataInfoOther?.id
              ? 'index10'
              : 'index25'
          }`}>
          <div className='icon-like'>
            <div
              style={{
                cursor: checkDisableIcon && 'default',
                background: checkDisableIcon && '#eeeeee',
              }}
              className='bg-icon'
              onClick={() => {
                if (checkDisableIcon) {
                } else if (!isAuth) {
                  dispatch(logUserProfile(item));
                } else {
                  toggleValueVideo(index, 'is_liked', item?.is_liked === 1 ? 0 : 1);
                  handleLikePost(item?.id);
                }
              }}>
              {item?.is_liked === 1 ? (
                <>
                  <HeartActiveIcon className={`like-icon active`} />
                </>
              ) : (
                <>
                  <HeartIcon
                    className={`like-icon`}
                    style={{ color: checkDisableIcon && '#b1b6bb' }}
                  />
                </>
              )}
            </div>
            <p className='mb-0 cls-text' style={{ color: '#fff' }}>
              {formatNumber(item?.number_like)}
            </p>
          </div>
          <div className='icon-chat index10'>
            <div
              className='bg-icon'
              style={{
                background: checkDisableIcon && '#eeeeee',
                cursor: checkDisableIcon && 'default',
              }}>
              <ChatIcon
                style={{
                  cursor: checkDisableIcon && 'default',
                  color: checkDisableIcon && '#b1b6bb',
                }}
                className='chat-icon'
                onClick={() => {
                  if (checkDisableIcon) {
                  } else
                    setShowPopup({
                      ...isShowPopup,
                      status: true,
                      dataItem: item,
                      index: index,
                    });
                }}
              />
            </div>
            <p className='mb-0 cls-text' style={{ color: '#fff' }}>
              {formatNumber(item?.number_comment) || 0}
            </p>
          </div>
          <div className='bg-icon index10'>
            <div
              className='bg-icon'
              style={{
                background: checkDisableIcon && '#eeeeee',
                cursor: checkDisableIcon && 'default',
              }}>
              <ShareIcon
                style={{
                  cursor: checkDisableIcon && 'default',
                  color: checkDisableIcon && '#b1b6bb',
                }}
                onClick={() => {
                  if (checkDisableIcon) {
                  } else if (!isAuth) {
                    dispatch(logUserProfile(item));
                  } else {
                    setPopupCopyLink({
                      link: `${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${item?.id}`,
                      title: item?.title,
                      id: item?.id,
                    });
                  }
                }}
                className='like-icon'
              />
            </div>
          </div>
          <div
            style={{
              margin: '21px 0px',
              cursor: (item?.is_active === 0 || item?.is_active === 2) && 'default',
            }}
            className='bg-icon index10'
            onClick={() => {
              if (item?.is_active === 0 || item?.is_active === 2) {
              } else if (!isAuth) {
                dispatch(logUserProfile(item));
              } else {
                toggleValueVideo(index, 'is_bookmarked', item?.is_bookmarked === 1 ? 0 : 1);
                handlePostBookMark(item);
              }
            }}>
            <IconBookmark
              style={{
                color:
                  item?.is_bookmarked === 1
                    ? '#dc143c'
                    : checkDisableIcon
                    ? '#b1b6bb'
                    : 'inherit',
              }}
              fill={item?.is_bookmarked === 1 ? '#dc143c' : 'none'}
              className='cls-icon'
            />
          </div>
          <div
            id='three-icon-timeline'
            className='bg-icon index10'
            onClick={() =>
              setIsShowOtherBox({
                isShow: !isShowOtherBox.isShow,
                indexShow: index,
              })
            }>
            <ThreePointIcon style={{ color: '#1A1A1A' }} className='three-point-icon' />
          </div>
          {isShowOtherBox?.isShow && isShowOtherBox?.indexShow === index && (
            <InfoBox
              item={item}
              index={index}
              handleFollowCreator={handleFollowCreator}
              handleHiddenPost={handleHiddenPost}
              setIsShowOtherBox={setIsShowOtherBox}
              toggleValueVideo={toggleValueVideo}
              navigate={navigate}
              toggleFollowAllUser={toggleFollowAllUser}
              setIsVisiblePopDeletePost={setIsVisiblePopDeletePost}
              toggleHiddenUser={toggleHiddenUser}
              t={t}
            />
          )}
        </div>
      </>
    );
  };

  const [isVisiblePopUpDeletePost, setIsVisiblePopDeletePost] = useState(null);
  const [isVisiblePopUpDeletePostComplete, setIsVisiblePopDeletePostComplete] =
    useState(false);
  const deletePostTimeLine = () => {
    setIsVisiblePopDeletePost(null);
    const { id } = isVisiblePopUpDeletePost;
    dispatch(creatorDeleteArticle(id, () => setIsVisiblePopDeletePostComplete(true)));
  };

  return (
    <div>
      {isVisiblePopUpDeletePost && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePost(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.confirmDeletePost')}>
          <div className='ok btn' onClick={deletePostTimeLine}>
            {t('Common.buttonYes')}
          </div>
          <div className='btn cancel' onClick={() => setIsVisiblePopDeletePost(null)}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpDeletePostComplete && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePostComplete(e)}
          iconNote={true}
          text={t('Popup.deletePostSucess')}>
          <div
            className='ok btn'
            onClick={() => {
              setIsVisiblePopDeletePostComplete(false);
              close();
            }}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {isShowPopup?.status && (
        <PopupViewFullMess
          setFlgRenderCMT={setFlgRenderCMT}
          index={isShowPopup?.index}
          closePopupComment={close}
          toggleValueVideo={toggleValueVideo}
          item={isShowPopup?.dataItem}
          close={() => {
            dispatch(checkIsShortMypage({ ishow: false }));
            dispatch(resetCommentDetail());
            setShowPopup({
              ...isShowPopup,
              status: false,
              dataItem: {},
            });
          }}
        />
      )}
      {popupCopyLink && (
        <PopupSharePost
          isVisiblePopup={() => setPopupCopyLink(false)}
          data={popupCopyLink || {}}
        />
      )}
      <div className='popup-wrap-short-video-mypage'>
        <svg
          onClick={() => {
            //dispatch(resetPopupPostShortVideo());
            //funCallApiListPostShort1(1);
            dispatch(getPageShort(page));
            close();
          }}
          className='close-icon'
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          fill='none'
          viewBox='0 0 18 18'>
          <path
            stroke='currentColor'
            strokeLinecap='round'
            strokeWidth='1.5'
            d='M4.5 13.875l9.711-9.378M4.5 4.5l9.711 9.378'></path>
        </svg>
        <>
          {listShort?.length > 0 ? (
            <InfiniteLoad loading={loading && page > 1} data={listShort} fetchNext={fetchNext}>
              <div
                className='page-list-short-video1 c154'
                id='short-video-list-id1'
                style={{
                  width: `${isMobile ? '100vw' : '720px'}`,
                  height: 'calc(100vh - 86px)',
                }}>
                {listShort.map((item, index) => (
                  <div
                    key={index}
                    className={`page-list-short-video1-item ${isMobile ? 'isMobie' : ''}`}>
                    <GuardFollowed
                      {...{
                        item,
                        index,
                        dataUser,
                        dataInfoOther,
                        toggleFollowAndPlay,
                        handleFollowCreator,
                        isAuth,
                        renderItemVideo,
                      }}
                    />
                  </div>
                ))}
                {/* {listShort?.length - 1 > 0 && <PlaceHolderItem />} */}
              </div>
            </InfiniteLoad>
          ) : (
            <div className='empty-box'>
              <div>{t('MenuSiderbar.creator.listShortVideoNull')}</div>
            </div>
          )}
        </>
      </div>
    </div>
  );
};

export default TimelineShort;
