//components
import {
  AvatarInfoCreator,
  Checkbox,
  LayoutCreatorRight,
  PopupConfirmFan,
  PopupLoading,
} from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import { newPathUser } from 'constants/layout/constant';
//hocs
import withCreatorRole from 'hocs/withCreatorRole';
//hook
//img
import { ArrowLeftIcon } from 'images';
//react
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
//store
import { creatorPostTimeLineArticle } from 'store/actions/myPage';
//util
import {
  CustomOption,
  customStylesHeight32,
  getPresignedUrlS3,
  isEmptyObject,
  patternNoFirstSpacing,
  setFileNameS3,
  uploadS3ByPresigned,
} from 'utils';
//scss
import '../short.scss';
import ListImgVideo from './listImgVideo';
//data

const PostShort = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.myPage);
  const { data: dataProfile } = useSelector((state) => state.users);
  const [isVisiblePopUpConfirm1, setIsVisiblePopUpConfirm1] = useState(false);
  const [isVisiblePopUpConfirm2, setIsVisiblePopUpConfirm2] = useState(false);
  //img && video
  const [imgUpload, setImgUpload] = useState([]);
  const [videoUpload, setVideoUpload] = useState();
  const [typePost, setTypePost] = useState();
  const [dataGetPresignedUrlS3, setDataGetPresignedUrlS3] = useState({
    short_video: '',
    images: [],
  });
  const { t } = useTranslation();
  const [checkPostIn24h, setCheckPostIn24h] = useState(false);
  const [focusChoiceUseSee, setFocusChoiceUseSee] = useState(false);
  const [detailDataAfterPost, setDetailAfterPost] = useState();
  const { state } = useLocation();
  //reset when change state
  useEffect(() => {
    reset();
  }, [state]);

  //loading
  const [popupLoading, setPopupLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    clearErrors,
    control,
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: {
      follow_flag: { label: t('Creator.CreateLiveStream.everyone'), value: '0' },
    },
  });
  const watchContent = watch('content', '');
  const watchFollowFlag = watch('follow_flag', '');
  const disabledButton =
    popupLoading ||
    loading ||
    isVisiblePopUpConfirm1 ||
    isVisiblePopUpConfirm2 ||
    !isEmptyObject(errors) ||
    state?.type === 'short'
      ? !imgUpload?.length && !videoUpload
      : watchFollowFlag?.value === '1'
      ? imgUpload?.length
        ? false
        : true
      : !watchContent && !imgUpload?.length && !videoUpload;
  //START END CALENDAR

  //START SET HEIGHT TEXTAREA
  const handleTextarea = (e) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    } else if (e.target.scrollHeight < e.target.clientHeight && e.target.clientHeight > 25) {
      e.target.style.height = `${e.target.clientHeight - 25}px`;
    }
    var code = e.keyCode || e.charCode;
    if (code === 13 || e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight + 25}px`;
    }
    if (e.target.value === '') {
      e.target.style.height = '25px';
      typePost !== undefined && clearErrors('content');
    }
  };
  //END SET HEIGHT TEXTAREA

  const handleShowPopupConfirmSuccess = (e) => {
    setIsVisiblePopUpConfirm1(false);
    setIsVisiblePopUpConfirm2(true);
    setPopupLoading(false);
    setDetailAfterPost(e);
  };
  //ONSUBMIT
  const onSubmit = async (data) => {
    const newData = {
      ...data,
      in_24h_flag: checkPostIn24h ? '1' : '0',
      follow_flag: data.follow_flag.value,
      enable_comment: data?.enable_comment ? '1' : '0',
    };
    !newData.content && delete newData.content;
    setDataGetPresignedUrlS3((prev) => ({ ...prev, ...newData }));
    setIsVisiblePopUpConfirm1(true);
  };

  const handleConfirmSubmit = async () => {
    setIsVisiblePopUpConfirm1(false);
    setPopupLoading(true);
    let listLinkS3Img = [];
    let listLinkPreSignedImg = [];
    let short_video_pre_signed;
    let short_video;

    if (videoUpload) {
      getPresignedUrlS3(
        'video',
        `uploads/output/videos`,
        setFileNameS3(videoUpload.name),
        'post'
      )
        .then((resUrl) => {
          short_video_pre_signed = resUrl.data.pre_signed;
          short_video = resUrl.data.file_path;
        })
        .then(() => {
          uploadS3ByPresigned(short_video_pre_signed, videoUpload)
            .then(() => {
              const newData = {
                ...dataGetPresignedUrlS3,
                short_video: short_video,
              };
              dispatch(
                creatorPostTimeLineArticle(
                  newData,
                  (e) => handleShowPopupConfirmSuccess(e),
                  () => setPopupLoading(false)
                )
              );
            })
            .catch(() => setPopupLoading(false));
        })
        .catch((_error) => setPopupLoading(false));
    }

    if (imgUpload?.length > 0) {
      const promiseList = imgUpload.map((item) => {
        return getPresignedUrlS3(
          'image',
          `uploads/output/images`,
          setFileNameS3(item.name),
          'post'
        );
      });
      Promise.all(promiseList)
        .then((responses) => {
          responses.forEach((element) => {
            if (element.success) {
              listLinkS3Img.push(element.data.file_path);
              listLinkPreSignedImg.push(element.data.pre_signed);
            }
          });
          const promiseGenerate = listLinkPreSignedImg.map((m, i) => {
            return uploadS3ByPresigned(m, imgUpload[i]);
          });
          Promise.all(promiseGenerate).then(() => {
            const newData =
              typePost === 'normal'
                ? {
                    ...dataGetPresignedUrlS3,
                    images: listLinkS3Img,
                  }
                : {
                    ...dataGetPresignedUrlS3,
                    short_images: listLinkS3Img,
                  };
            dispatch(
              creatorPostTimeLineArticle(
                newData,
                (e) => handleShowPopupConfirmSuccess(e),
                () => setPopupLoading(false)
              )
            );
          });
        })
        .catch((_errors) => setPopupLoading(false));
    }

    !videoUpload &&
      !imgUpload?.length > 0 &&
      dispatch(
        creatorPostTimeLineArticle(
          dataGetPresignedUrlS3,
          (e) => handleShowPopupConfirmSuccess(e),
          () => setPopupLoading(false)
        )
      );
  };

  return (
    <>
      {(loading || popupLoading) && <PopupLoading className={'popup-loading'} />}
      {isVisiblePopUpConfirm1 && (
        <PopupConfirmFan
          isVisiblePopup={() => setIsVisiblePopUpConfirm1(false)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.confirmCreatePost')}>
          <div
            className='btn cancel'
            onClick={() => {
              setIsVisiblePopUpConfirm1(false);
              setPopupLoading(false);
            }}>
            {t('Common.buttonNo')}
          </div>
          <div className='ok btn' onClick={handleConfirmSubmit}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpConfirm2 && (
        <PopupConfirmFan
          isVisiblePopup={() => setIsVisiblePopUpConfirm2(false)}
          colorSvg='#28C76F'
          iconNote={false}
          text={t('Popup.postCompleted')}>
          <div
            className='ok btn'
            onClick={() => {
              setIsVisiblePopUpConfirm2(false);
              navigate(`${newPathUser}post-short/${detailDataAfterPost?.id}`);
            }}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      <LayoutCreatorRight
        titlePage={t(
          state?.type === 'short'
            ? 'Creator.PostArticle.postShort.titleType4'
            : 'Creator.PostArticle.postShort.title'
        )}>
        <div className='fansite-creator-post-short'>
          <AvatarInfoCreator
            name={dataProfile?.account_name || dataProfile?.name_japanese}
            content2={`${t('Common.id')}: ${dataProfile?.account_id}`}
            images={dataProfile?.avatar && dataProfile?.avatar}
          />

          <form onSubmit={(e) => e.preventDefault()} className='fansite-form'>
            <div className='follow-flag'>
              <label>{t('Creator.PostArticle.postShort.whoSeePost')}</label>
              <Controller
                name='follow_flag'
                control={control}
                render={({ field }) => (
                  <div>
                    <Select
                      {...field}
                      options={[
                        { value: '0', label: t('Creator.CreateLiveStream.everyone') },
                        { value: '1', label: t('Creator.PostArticle.postShort.userFollow') },
                      ]}
                      onFocus={() => setFocusChoiceUseSee(true)}
                      onBlur={() => setFocusChoiceUseSee(false)}
                      onClick={(e) => setFocusChoiceUseSee(false)}
                      onChange={(val) => {
                        field.onChange(val);
                        setFocusChoiceUseSee(false);
                      }}
                      noOptionsMessage={() => {
                        t('Common.listNull');
                      }}
                      defaultValue={{
                        label: t('Creator.CreateLiveStream.everyone'),
                        value: '0',
                      }}
                      isSearchable={false}
                      styles={customStylesHeight32}
                      components={{
                        Option: CustomOption,
                      }}
                      className='selected'
                    />
                    <ArrowLeftIcon className={`${focusChoiceUseSee ? 'focus' : 'no-focus'}`} />
                  </div>
                )}
              />
            </div>
            <InputWrapper
              id='fsite-content'
              label=''
              required={true}
              error={errors?.content ? errors?.content?.message : ''}
              className={`${imgUpload && 'has-img'} text-post`}>
              <textarea
                {...register('content', {
                  maxLength: {
                    value: 500,
                    message: t('ValidateMsg.CONTENT_IS_TOO_LONG'),
                  },
                  validate: {
                    noFirstSpacing: (value) => {
                      return value
                        ? !patternNoFirstSpacing.test(value.toString().trim())
                          ? t('ValidateMsg.itemRequired')
                          : clearErrors('content')
                        : clearErrors('content');
                    },
                  },
                  onChange: (e) => handleTextarea(e),
                })}
                placeholder={t('Creator.PostArticle.placeholderContent')}
                onKeyDown={(e) => handleTextarea(e)}
                maxLength={500}
              />
            </InputWrapper>
            <ListImgVideo
              short_video={(e) => setVideoUpload(e)}
              list_img={(e) => setImgUpload(e)}
              type_post={(e) => setTypePost(e)}
              showErr={state?.type !== 'short' && !imgUpload && watchFollowFlag?.value === '1'}
              setLoadingUploadFile={(e) => setPopupLoading(e)}
            />

            <InputWrapper
              id='fsite-answer'
              label={t('Creator.PostArticle.canReply')}
              className='form-item-switch'>
              <label className='switch'>
                <input type='checkbox' {...register('enable_comment')} defaultChecked />
                <span className='slider round'></span>
              </label>
            </InputWrapper>
            {state?.type === 'short' && (
              <InputWrapper className='box-category'>
                <Checkbox
                  type='checkbox'
                  name={`in_24h_flag`}
                  onChange={(e) => setCheckPostIn24h(e.target.checked)}
                  text={t('Creator.PostArticle.postShort.24h')}
                  value={1}
                />
              </InputWrapper>
            )}
            <InputWrapper
              id='fsite-submit'
              className={`button-submit ${disabledButton ? 'disabled' : ''}`}>
              <button onClick={handleSubmit(onSubmit)} disabled={disabledButton}>
                {t('Creator.PostArticle.postShort.buttonPost')}
              </button>
            </InputWrapper>
          </form>
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(PostShort);
