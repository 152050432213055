import React, { useState, useEffect } from 'react';

// import { create } from 'hooks/useFireStore';
// import { serverTimestamp } from 'firebase/firestore';

import { serverTimestamp } from 'firebase/database';

import { InputWrapper } from 'components/Input/InputWrapper';
import { SendChatIcon } from 'images';
import { useParams } from 'react-router-dom';
import { CURRENT_USER_KEY } from 'constants/shared';
import Storage from 'utils/storage';
import { insertData } from 'hooks/useRealTimeDB';
import { v4 as uuidv4 } from 'uuid';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { EffectStyled } from './EffectStyled';
import IconEffect from './effectIcon.png';
import { SendEffectCreator } from 'components/Popup/sendEffectCreator';
import CoinIconV2 from 'images/CoinIconV2';
import EffectIcon from 'images/livestreams/EffectIcon';
import { useCallback } from 'react';
import { logUserProfile } from 'store/actions/myPage';
import { useDispatch } from 'react-redux';
export const InputChat = (props) => {
  const { liveKey } = useParams();
  const { allowComment, denyCharacter, id, creator, isAuth, dataStamps, setDataStamps } =
    props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const [valueChat, setValueChat] = useState('');
  const [openEffect, setOpenEffect] = useState(false);
  let user = Storage.get(CURRENT_USER_KEY);
  const handleInput = (e) => {
    setValueChat(e.target.value?.trimStart());
  };

  //* HANDLE SEND MESSAGE CHAT

  // const collectionName = 'lives-message';
  window.addEventListener('storage', () => {
    user = JSON.parse(window.localStorage.getItem(CURRENT_USER_KEY));
  });

  const generateId = (total) => {
    let result = '';
    const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const length = total;
    const charactersLength = characters.length;
    for (let i = 0; i < length; i += 1) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const handleSendChat = (type = 0) => {
    if (user && liveKey) {
      const params = {
        key: liveKey,
        type: type, // 0 la message binh thuong ko gui kem point,
        name: user?.data?.account_name,
        avatar: user?.data.avatar || 'null',
        id: id,
        creator: creator !== undefined ? 1 : 0,
        message: valueChat.trim(),
        is_block: creator !== undefined ? 0 : allowComment ? 0 : 1,
        icon: 'null',
        createdAt: serverTimestamp(),
      };

      const dateNow = Date.now();
      const uid = uuidv4();
      let key =
        'u' +
        user?.data?.id +
        'x' +
        dateNow +
        '-gen' +
        generateId(10) +
        '-rgen' +
        generateId(10) +
        uid +
        '-ml' +
        moment().milliseconds();
      insertData(`/chat/${liveKey}/${key}`, params);

      // create(collectionName, {
      //   key: liveKey,
      //   type: type, // 0 la message binh thuong ko gui kem point,
      //   name: user?.data?.account_name,
      //   avatar: user?.data?.avatar,
      //   message: valueChat.trim(),
      //   createdAt: serverTimestamp(),
      // });
      setValueChat('');
      return;
    }
  };

  const enterFunction = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        if (!allowComment) {
          return setValueChat('');
        } else {
          return valueChat ? handleSendChat('0') : null;
        }
      }
    },
    [allowComment, valueChat]
  );

  useEffect(() => {
    document.addEventListener('keydown', enterFunction);
    return () => {
      document.removeEventListener('keydown', enterFunction);
    };
  }, [enterFunction]);
  //* END HANDLE SEND MESSAGE CHAT
  return (
    <>
      <div className={`form-chat ${i18n.language === 'en' && 'en'}`}>
        <InputWrapper id='fsite-comment' className='form-item-live'>
          <input
            className='text'
            type='text'
            placeholder={t('LiveStream.Chat.InputChat.enterComment') + ' ...'}
            //readOnly={!allowComment}
            value={valueChat}
            onChange={handleInput}
            maxLength={500}
          />
          {props?.isGift && (
            <CoinIconV2
              onClick={() => {
                if (isAuth === 1) {
                  props?.isGift?.setIsVisibleSendCoinPopup();
                } else {
                  // props?.isGift?.setIsUserLogin();
                  dispatch(logUserProfile({
                    pathRedirect: `lives/${liveKey}`
                  }));
                }
              }}
              className='icon-send-coin-live'
            />
          )}

          <SendChatIcon
            className={`${valueChat ? 'active' : ''} button-send`}
            onClick={(event) => {
              event.preventDefault();
              if (isAuth) {
                if (!allowComment) {
                  return setValueChat('');
                } else {
                  return valueChat ? handleSendChat('0') : null;
                }
              } else {
                setValueChat('');
                // props?.isGift?.setIsUserLogin();
                dispatch(logUserProfile({
                  pathRedirect: `lives/${liveKey}`
                }));
              }
            }}
          />
          {creator === 1 && (
            <EffectStyled className='send_effect_creator'>
              <EffectIcon
                src={IconEffect}
                alt='send effect'
                onClick={() => setOpenEffect(true)}
              />
            </EffectStyled>
          )}
        </InputWrapper>
        {creator === 1 && openEffect && (
          <SendEffectCreator isVisiblePopup={() => setOpenEffect(false)} />
        )}
      </div>
    </>
  );
};
