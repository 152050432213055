import { LayoutCenter } from 'components';
import { useTranslation } from 'react-i18next';
import { POLICY, POLICY_JP } from '../constant';
import './styles.scss';
import { Link } from 'react-router-dom';
import { newPathUser } from 'constants/layout/constant';
import { STORAGE } from 'utils';
import Storage from 'utils/storage';

const PrivacyScreen = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      {i18n.language === 'en' ? (
        <LayoutCenter
          titlePage={POLICY.title_page}
          notLogin={true}
          isShowLeftIcon={false}
          className={'title-page-creator page-legal'}
          uiMobile={true}>
          <div className={`container-fluid w-100 bg-white policy-page creator`}>
            <div>{POLICY?.desc || ''}</div>
            <div>
              <div>
                <div className='title-chapter'>
                  1. Collected user information and collection method
                </div>
                <div className='text'>
                  In this policy, "user information" means information related to user
                  identification, action history on communication services, and other
                  information generated or accumulated in relation to users or users' devices,
                  which is shall mean what we collect.
                </div>
                <div className='text'>
                  User information collected by the Company in this service is as follows,
                  depending on the method of collection.
                </div>
                <div>
                  <div className={`content`}>
                    Information provided by users Information provided by users in order to use
                    this service or through the use of this service is as follows.
                    <div className='sub-content'>
                      Information related to profile such as name, date of birth, gender,
                      occupation, etc. Contact information such as e-mail address, phone
                      number, address, etc.
                    </div>
                    <div className='sub-content'>
                      Information related to payment methods such as credit card information,
                      bank account information, and electronic money information
                    </div>
                    <div className='sub-content'>
                      Information entered or sent by users through input forms or other methods
                      specified by the Company
                    </div>
                  </div>
                  <div className={`content`}>
                    By allowing users to link with other services when using this service, the
                    information provided by the other service Users may not link with other
                    services such as social networking services when using this service. If you
                    allow it, we will collect the following information from the external
                    service based on the content you agreed to at the time of permission.
                    <div className='sub-content'>
                      ID used by the user for the external service
                    </div>
                    <div className='sub-content'>
                      Other information that the user has permitted to disclose to the partner
                      through the privacy settings of the relevant external service
                    </div>
                  </div>
                  <div className={`content`}>
                    Information collected by the Company when users use the Service The Company
                    may collect information regarding access to and use of the Service. This
                    includes the following information:
                    <div className='sub-content'>Referrer</div>
                    <div className='sub-content'>IP address</div>
                    <div className='sub-content'>Information about server access logs</div>
                    <div className='sub-content'>
                      Cookies, ADID, IDFA and other identifiers
                    </div>
                  </div>
                  <div className={`content`}>
                    Information collected by the Company based on the user's individual consent
                    when the user uses this service
                  </div>
                  <div className={`content nodot`}>
                    {'\n'}
                    {'\n'}
                    We will collect the following information from the terminal in use if the
                    user individually agrees by the method specified in 3-1.
                    <div className='sub-content'>location information</div>
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>2. Purpose of use</div>
                <div className='text'>
                  The specific purposes of use of user information related to the provision of
                  this service are as follows.
                </div>
                <div>
                  <div className={`content nodot`}>
                    (1) To provide, maintain, protect, and improve the Service, such as
                    acceptance of registration for the Service, identity verification, user
                    authentication, recording of user settings, and payment calculation of
                    usage fees;
                  </div>
                  <div className={`content nodot`}>
                    (2) To improve the quality of this service
                  </div>

                  <div className={`content nodot`}>
                    (3) For user traffic measurement and behavior measurement
                  </div>
                  <div className={`content nodot`}>
                    (4) To distribute, display and measure the effectiveness of advertisements
                  </div>
                  <div className={`content nodot`}>
                    (5) To provide guidance and respond to inquiries regarding the Service;
                  </div>
                  <div className={`content nodot`}>
                    (6) for market research, analysis, marketing and research;
                  </div>
                  <div className={`content nodot`}>
                    (7) To create statistical data processed into a form that cannot identify
                    individuals
                  </div>
                  <div className={`content nodot`}>
                    (8) To respond to acts that violate our terms, policies, etc. (hereinafter
                    referred to as "terms, etc.") regarding this service
                  </div>
                  <div className={`content nodot`}>
                    (9) To notify you of changes to the terms of service, etc.
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>
                  3. Method of notification/publication or obtaining consent, method of
                  requesting suspension of use
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content nodot`}>
                    3-1 User consent shall be obtained before the following user information is
                    collected.
                    <div className='sub-content'>location information.</div>
                  </div>
                  <div className={`content nodot`}>
                    3-2 The User may request suspension of collection or use of all or part of
                    the User Information by making the prescribed settings for the Service. and
                    stop using it. Depending on the item of user information, the collection or
                    use thereof is a prerequisite for this service, so we will stop collecting
                    or using it only if you withdraw from this service by the method prescribed
                    by our company.
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>
                  4. Existence of external transmission, third party provision, information
                  collection module
                </div>
                <div className='text'>
                  This service uses Google Analytics to understand the usage status of this
                  service. Google Analytics uses cookies to collect user information. Please
                  refer to the Google Analytics site for the terms of use and privacy policy of
                  Google Analytics. We are not responsible for any damage caused by using the
                  Google Analytics service.
                </div>
                <div></div>
              </div>

              <div>
                <div className='title-chapter'>5. Provision to Third Parties</div>
                <div className='text'></div>
                <div>
                  <div className={`content nodot`}>
                    5-1 We will not provide personal information of user information to third
                    parties (including those located outside of Japan) without the prior
                    consent of the user. However, this does not apply in the following cases
                    where it is necessary to provide personal information to third parties
                    (including those outside Japan).
                    <div className='sub-content nodot'>
                      (1) When the Company entrusts all or part of the handling of personal
                      information to the extent necessary to achieve the purpose of use.
                    </div>
                    <div className='sub-content nodot'>
                      (2) When personal information is provided along with business succession
                      due to merger or other reasons.
                    </div>
                    <div className='sub-content nodot'>
                      (3) When personal information is provided to business partners or
                      information collection module providers in accordance with the provisions
                      of paragraph 4.
                    </div>
                    <div className='sub-content nodot'>
                      (4) When it is necessary to cooperate with national institutions, local
                      governments, or persons entrusted by them in carrying out the affairs
                      stipulated by laws and regulations, and the execution of said affairs
                      with the consent of the user If there is a risk of interfering with.
                    </div>
                    <div className='sub-content nodot'>
                      (5) Other cases permitted by the Act on the Protection of Personal
                      Information (hereinafter referred to as the "Personal Information
                      Protection Act") and other laws and regulations.
                    </div>
                  </div>
                  <div className={`content nodot`}>
                    5-2 Notwithstanding paragraph 1, for the purpose of improving the quality
                    of the Service, market research, analysis, marketing and research, the
                    Company shall irreversibly and appropriately process user information so
                    that individual users cannot be identified. may be provided to third
                    parties who have transactions with the Company by means of data
                    transmission, magnetic media, on paper, orally, etc., and users shall agree
                    in advance to such third party provision.
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>6. Disclosure of Personal Information</div>
                <div className='text'>
                  When a user requests disclosure of personal information based on the
                  provisions of the Personal Information Protection Law, we will disclose it to
                  the user without delay after confirming that the request is from the user
                  himself/herself. (If the personal information does not exist, we will notify
                  you to that effect.) However, this does not apply if the Company is not
                  obligated to disclose it under the Personal Information Protection Law or
                  other laws and regulations. Please note that we charge a fee (1,000 yen per
                  case) for the disclosure of personal information.
                </div>
                <div></div>
              </div>

              <div>
                <div className='title-chapter'>
                  7. Correction and suspension of use of personal information
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content nodot`}>
                    7-1 If the user requests (1) correction of the content based on the
                    provisions of the Personal Information Protection Law for the reason that
                    the personal information is not true, and (2) the scope of the purpose of
                    use announced in advance If the user himself/herself requests suspension of
                    use based on the provisions of the Personal Information Protection Act on
                    the grounds that the information is being handled beyond the limits or that
                    the information has been collected by deception or other wrongful means.
                    After confirming the request, we will conduct the necessary investigation
                    without delay, and based on the results, we will correct the content of the
                    personal information or stop using it, and notify the user to that effect.
                    In addition, when we decide not to correct or suspend use, we will notify
                    the user to that effect.
                  </div>
                  <div className={`content nodot`}>
                    7-2 If the user requests the deletion of the user's personal information,
                    and if the company determines that it is necessary to comply with the
                    request, the company will confirm that the request is from the user
                    himself/herself. will delete personal information and notify the user to
                    that effect.
                  </div>
                  <div className={`content nodot`}>
                    7-3 The provisions of 7-1 and 7-2 do not apply if the Company is not
                    obligated to correct, etc. or suspend use, etc., under the Personal
                    Information Protection Act or other laws and regulations
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>8. Contact point for inquiries</div>
                <div className='content nodot'>
                  If you have any comments, questions, complaints, or other inquiries regarding
                  the handling of user information, please contact the contact described at the
                  following URL. URL:{' '}
                  {!!Storage.get(STORAGE.USER_ACCESS_TOKEN) ? (
                    <Link to={`${newPathUser}help`} target='_blank' className='link_route'>
                      https://share-gram.com/help
                    </Link>
                  ) : (
                    <Link to={`/sign-in`} target='_blank' className='link_route'>
                      https://share-gram.com/help
                    </Link>
                  )}
                </div>
              </div>

              <div>
                <div className='title-chapter'>
                  9. Procedures for changing the privacy policy
                </div>
                <div className='text'>
                  We will change this policy as necessary. However, in the event that this
                  policy is amended in a manner that requires the consent of the user under
                  laws and regulations, this policy after the amendment shall apply only to
                  users who have consented to the amendment in the manner prescribed by the
                  Company. In addition, when changing this policy, the Company will notify the
                  user of the effective date and content of this policy after the change by
                  displaying it on the company's website or other appropriate methods.
                </div>
              </div>
            </div>
            <div className='end-text'>{POLICY?.time_created}</div>
            {/* <div className='end-text'>{POLICY?.time_updated}</div> */}
          </div>
        </LayoutCenter>
      ) : (
        <LayoutCenter
          titlePage={POLICY_JP.title_page}
          notLogin={true}
          isShowLeftIcon={false}
          className={'title-page-creator page-legal'}
          uiMobile={true}>
          <div className={`container-fluid w-100 bg-white policy-page creator`}>
            <div>{POLICY_JP?.desc || ''}</div>
            <div>
              <div>
                <div className='title-chapter'>1. 収集する利用者情報及び収集方法</div>
                <div className='text'>
                  本ポリシーにおいて、「利用者情報」とは、ユーザーの識別に係る情報、通信サービス上の行動履歴、その他ユーザーまたはユーザーの端末に関連して生成または蓄積された情報であって、本ポリシーに基づき当社が収集するものを意味するものとします。
                </div>
                <div className='text'>
                  本サービスにおいて当社が収集する利用者情報は、その収集方法に応じて、以下のようなものとなります。
                </div>
                <div>
                  <div className={`content`}>
                    ユーザーからご提供いただく情報本サービスを利用するために、または本サービスの利用を通じてユーザーからご提供いただく情報は以下のとおりです。
                    <div className='sub-content'>
                      氏名、生年月日、性別、職業等プロフィールに関する情報
                    </div>
                    <div className='sub-content'>
                      メールアドレス、電話番号、住所等連絡先に関する情報
                    </div>
                    <div className='sub-content'>
                      クレジットカード情報、銀行口座情報、電子マネー情報等決済手段に関する情報
                    </div>
                    <div className='sub-content'>
                      入力フォームその他当社が定める方法を通じてユーザーが入力または送信する情報
                    </div>
                  </div>
                  <div className={`content`}>
                    ユーザーが本サービスの利用において、他のサービスと連携を許可することにより、当該他のサービスからご提供いただく情報ユーザーが、本サービスを利用するにあたり、ソーシャルネットワーキングサービス等の他のサービスとの連携を許可した場合には、その許可の際にご同意いただいた内容に基づき、以下の情報を当該外部サービスから収集します。
                    <div className='sub-content'>当該外部サービスでユーザーが利用するID</div>
                    <div className='sub-content'>
                      その他当該外部サービスのプライバシー設定によりユーザーが連携先に開示を認めた情報
                    </div>
                  </div>
                  <div className={`content`}>
                    ユーザーが本サービスを利用するにあたって、当社が収集する情報当社は、本サービスへのアクセス状況やそのご利用方法に関する情報を収集することがあります。これには以下の情報が含まれます。
                    <div className='sub-content'>リファラ</div>
                    <div className='sub-content'>IPアドレス</div>
                    <div className='sub-content'>サーバーアクセスログに関する情報</div>
                    <div className='sub-content'>Cookie、ADID、IDFAその他の識別子</div>
                  </div>
                  <div className={`content`}>
                    ユーザーが本サービスを利用するにあたって、当社がユーザーの個別同意に基づいて収集する情報
                    当社は、ユーザーが3-1に定める方法により個別に同意した場合、当社は以下の情報を利用中の端末から収集します。
                    <div className='sub-content'>位置情報</div>
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>2. 利用目的</div>
                <div className='text'>
                  本サービスのサービス提供にかかわる利用者情報の具体的な利用目的は以下のとおりです。
                </div>
                <div>
                  <div className={`content nodot`}>
                    (1)
                    本サービスに関する登録の受付、本人確認、ユーザー認証、ユーザー設定の記録、利用料金の決済計算等本サービスの提供、維持、保護及び改善のため
                  </div>
                  <div className={`content nodot`}>(2) 本サービスの品質向上のため</div>

                  <div className={`content nodot`}>
                    (3) ユーザーのトラフィック測定及び行動測定のため
                  </div>
                  <div className={`content nodot`}>(4) 広告の配信、表示及び効果測定のため</div>
                  <div className={`content nodot`}>
                    (5) 本サービスに関するご案内、お問い合わせ等への対応のため
                  </div>
                  <div className={`content nodot`}>
                    (6) 市場調査、分析、マーケティング及び調査研究のため
                  </div>
                  <div className={`content nodot`}>
                    (7) 個人を識別できない形式に加工した統計データを作成するため
                  </div>
                  <div className={`content nodot`}>
                    (8)
                    本サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
                  </div>
                  <div className={`content nodot`}>
                    (9) 本サービスに関する規約等の変更などを通知するため
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>
                  3. 通知・公表または同意取得の方法、利用中止要請の方法
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content nodot`}>
                    3-1
                    以下の利用者情報については、その収集が行われる前にユーザーの同意を得るものとします。
                    <div className='sub-content'>位置情報</div>
                  </div>
                  <div className={`content nodot`}>
                    3-2
                    ユーザーは、本サービスの所定の設定を行うことにより、利用者情報の全部または一部についてその収集又は利用の停止を求めることができ、この場合、当社は速やかに、当社の定めるところに従い、その利用を停止します。なお利用者情報の項目によっては、その収集または利用が本サービスの前提となるため、当社所定の方法により本サービスを退会した場合に限り、当社はその収集又は利用を停止します。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>
                  4. 外部送信、第三者提供、情報収集モジュールの有無
                </div>
                <div className='text'>
                  本サービスでは、本サービスの利用状況を把握するためにGoogle
                  Analyticsを利用しています。Google
                  Analyticsは、クッキーを利用して利用者の情報を収集します。Google
                  Analyticsの利用規約及びプライバシーポリシーに関する説明については、Google
                  Analyticsのサイトをご覧ください。なお、Google
                  Analyticsのサービス利用による損害については、当社は責任を負わないものとします
                </div>
                <div></div>
              </div>

              <div>
                <div className='title-chapter'>5. 第三者提供</div>
                <div className='text'></div>
                <div>
                  <div className={`content nodot`}>
                    5-1
                    当社は、利用者情報のうち、個人情報については、あらかじめユーザーの同意を得ないで、第三者（日本国外にある者を含みます。）に提供しません。但し、次に掲げる必要があり第三者（日本国外にある者を含みます。）に提供する場合はこの限りではありません。
                    <div className='sub-content nodot'>
                      (1)
                      当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
                    </div>
                    <div className='sub-content nodot'>
                      (2) 合併その他の事由による事業の承継に伴って個人情報が提供される場合
                    </div>
                    <div className='sub-content nodot'>
                      (3)
                      第4項の定めに従って、提携先または情報収集モジュール提供者へ個人情報が提供される場合
                    </div>
                    <div className='sub-content nodot'>
                      (4)
                      国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合
                    </div>
                    <div className='sub-content nodot'>
                      (5)
                      その他、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）その他の法令で認められる場合
                    </div>
                  </div>
                  <div className={`content nodot`}>
                    5-2
                    当社は、第1項にかかわらず、本サービスの品質向上、市場調査、分析、マーケティング及び調査研究を目的とし、ユーザー個人を特定できないよう不可逆的かつ適切な加工を行った利用者情報を、当社と取引のある第三者に対し、データ送信、磁気媒体、紙面及び口頭等の方法により提供する場合があり、ユーザーはかかる第三者提供につき予め同意するものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>6. 個人情報の開示</div>
                <div className='text'>
                  当社は、ユーザーから、個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、手数料（1件あたり1,000円）を頂戴しておりますので、あらかじめ御了承ください。
                </div>
              </div>

              <div>
                <div className='title-chapter'>7. 個人情報の訂正及び利用停止等</div>
                <div className='text'></div>
                <div>
                  <div className={`content nodot`}>
                    7-1
                    当社は、ユーザーから、(1)個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び(2)あらかじめ公表された利用目的の範囲を超えて取扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、ユーザーご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をユーザーに通知します。なお、訂正または利用停止を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします。
                  </div>
                  <div className={`content nodot`}>
                    7-2当社は、ユーザーから、ユーザーの個人情報について消去を求められた場合、当社が当該請求に応じる必要があると判断した場合は、ユーザーご本人からのご請求であることを確認の上で、個人情報の消去を行い、その旨をユーザーに通知します。
                  </div>
                  <div className={`content nodot`}>
                    7-3個人情報保護法その他の法令により、当社が訂正等または利用停止等の義務を負わない場合は、7-1および7-2の規定は適用されません。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>8. お問い合わせ窓口</div>
                <div className='content nodot'>
                  ご意見、ご質問、苦情のお申出その他利用者情報の取扱いに関するお問い合わせは、下記のURLに記載する窓口までお願いいたします。
                  URL:
                  {!!Storage.get(STORAGE.USER_ACCESS_TOKEN) ? (
                    <Link to={`${newPathUser}help`} target='_blank' className='link_route'>
                      https://share-gram.com/help
                    </Link>
                  ) : (
                    <Link to={`/sign-in`} target='_blank' className='link_route'>
                      https://share-gram.com/help
                    </Link>
                  )}
                </div>
              </div>

              <div>
                <div className='title-chapter'>9. プライバシーポリシーの変更手続</div>
                <div className='text'>
                  当社は、必要に応じて、本ポリシーを変更します。但し、法令上ユーザーの同意が必要となるような本ポリシーの変更を行う場合、変更後の本ポリシーは、当社所定の方法で変更に同意したユーザーに対してのみ適用されるものとします。なお、当社は、本ポリシーを変更する場合には、変更後の本ポリシーの施行時期及び内容を当社のウェブサイト上での表示その他の適切な方法により周知し、またはユーザーに通知します。
                </div>
              </div>
            </div>
            <div className='end-text'>{POLICY_JP?.time_created}</div>
            {/* <div className='end-text'>{POLICY_JP?.time_updated}</div> */}
          </div>
        </LayoutCenter>
      )}
    </>
  );
};

export default PrivacyScreen;
