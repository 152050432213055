import { LayoutCreatorRight } from 'components';
import { InputWrapper } from 'components/FormLogin';
import PopupConfirm from 'components/Popup/confirmSuccess';
import PopupConfirmDelete from 'components/PopupConfirmDelete';
import PreviewMultipleImages from 'components/PreviewMultipleImages';
import { newPathUser } from 'constants/layout/constant';
import withCreatorRole from 'hocs/withCreatorRole';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { getListPackage } from 'store/actions/lives';
import { createMessage, resetIDMess } from 'store/actions/message';
import { getListSurvey } from 'store/actions/survey';
import { creatorGetListFan } from 'store/actions/users';
import i18n from 'translate/i18n';
import { getPresignedUrlS3, setFileNameS3, uploadS3ByPresigned } from 'utils/upload';
import { toast } from 'react-toastify';
import {
  acceptedImageTypes,
  checkLinkPostSale,
  CustomDropdownIndicator,
  CustomOption,
  customStylesReselect,
  ONE_GB,
  sortListPackagePointAndDate,
  TEN_MB,
} from 'utils/utils';
import './index.scss';
import { useMemo } from 'react';
import LoadingIconV2 from 'images/LoadingIconV2';
import { getInfoMetaPost } from 'store/actions/postMeta';
import MetaTagViewer from 'components/MetaTagViewer/MetaTagViewer';

const CreateMessage = () => {
  const dispatch = useDispatch();
  const { dataUser: dataProfile } = useSelector((state) => state.users);
  const userRef = useRef({});
  const { loadingListContent = [], dataUser } = useSelector((state) => state.users);
  const { packageData } = useSelector((state) => state.lives);
  const navigate = useNavigate();
  const [listPackage, setListPackage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const [confirmFail, setConfirmFail] = useState(false);
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [surveySelected, setSurveySelected] = useState(null);
  const [listFan, setListFan] = useState([]);
  const [paramsSearch, setParamsSearch] = useState({
    pageFan: null,
    keyword: '',
    maxPageFan: 2,
  });
  const [isSearchFirst, setIsSearchFirst] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [listPackageChecked, setListPackageChecked] = useState([]);
  const [allPackageChecked, setAllPackageChecked] = useState(false);

  const [listSurvey, setListSurvey] = useState([]);
  const [surveyIdChecked, setSurveyIdChecked] = useState([]);

  const [listImage, setListImage] = useState([]);
  const [listVideo, setListVideo] = useState([]);
  const [countSubmit, setCountSubmit] = useState(1);
  const [notYetSearch, setNotYetSearch] = useState(true);
  const [loadingSurvey, setLoadingSurvey] = useState(false);

  const LIMIT_PAGE = 20;

  const isType7 = useMemo(() => {
    return dataProfile?.status === 7;
  }, [dataProfile?.status]);

  const { t } = useTranslation();
  const {
    register,
    control,
    watch,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    reset,
    formState: { isSubmitted, errors, dirtyFields },
  } = useForm({
    mode: 'all',
    defaultValues: {},
  });
  const type = watch('type');
  const userIds = watch('user_ids');

  const [idPostMeta, setIdPostMeta] = useState(null);
  const [postDetailMeta, setPostDetailMeta] = useState(null);

  useEffect(() => {
    if (dataUser?.status === 1) {
      dispatch(getListPackage());
    }
  }, [dataUser?.status]);

  useEffect(() => {
    const data = sortListPackagePointAndDate(packageData);
    setListPackage(data);
  }, [packageData]);

  useEffect(() => {
    if (type === 1) {
      if (userIds && userIds?.length === 0) {
        setError('user_ids', {
          type: 'required',
          message: t('ValidateMsg.C101_VALIDATE_TYPE_MESSAGE'),
        });
      }
    } else {
      clearErrors('user_ids');
    }
  }, [type, userIds]);

  const handleListSurvey = ({ success, data }) => {
    if (success) {
      setListSurvey(data || []);
    }
    setLoadingSurvey(false);
  };

  const optionSurvey = useMemo(
    () => [
      {
        value: 0,
        label: i18n.t('Creator.Message.none'),
      },
      { value: 1, label: i18n.t('Creator.Message.yes') },
    ],
    [i18n.language]
  );

  const optionDestinationType = useMemo(() => {
    return [
      {
        value: 2,
        label: i18n.t('Creator.Message.subscription'),
      },
      {
        value: 3,
        label: i18n.t('Common.follow'),
      },
      {
        value: 4,
        label: i18n.t('Creator.Message.follower'),
      },
    ];
  }, [i18n.language]);

  const handleTextarea = (e) => {
    setValue('content', e.target.value, { shouldValidate: true, shouldTouch: true });
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    const idPost = checkLinkPostSale(e.target.value);
    if (idPost) {
      setIdPostMeta(idPost);
    } else {
      setIdPostMeta(null);
      setPostDetailMeta(null);
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    if (e.target.value === '') {
      e.target.style.height = '143px';
    }
  };

  useEffect(() => {
    if (idPostMeta) {
      dispatch(
        getInfoMetaPost(idPostMeta, (dataMeta) => {
          setPostDetailMeta(dataMeta);
        })
      );
    } else {
      setPostDetailMeta(null);
    }
  }, [idPostMeta, dispatch]);

  useEffect(() => {
    if (paramsSearch?.pageFan) {
      dispatch(
        creatorGetListFan(
          { keyword: paramsSearch?.keyword, page: paramsSearch?.pageFan, limit: LIMIT_PAGE },
          getListFan
        )
      );
    }
  }, [paramsSearch?.pageFan, paramsSearch?.keyword]);

  const getListFan = useCallback(
    (data, page) => {
      setSearchValue('');
      setNotYetSearch(false);
      const listUser = data?.data?.map((user) => ({
        label: user.account_name,
        value: user.id,
      }));
      if (listUser?.length === LIMIT_PAGE) {
        setParamsSearch((prev) => ({
          ...prev,
          maxPageFan: prev.maxPageFan + 1,
        }));
      }
      if (page !== 1 && listFan?.length > 0) {
        setListFan(listFan?.concat(listUser));
      } else {
        setListFan(listUser || []);
      }
      userRef.current.focus();
    },
    [listFan]
  );

  const handleCheckAllPackage = () => {
    if (allPackageChecked) {
      setAllPackageChecked(false);
      setListPackageChecked([]);
      setValue('package_ids', [], { shouldDirty: true });
    } else {
      const listIdPackage = listPackage?.map((packageItem) => packageItem?.id);
      setAllPackageChecked(true);
      setValue('package_ids', listIdPackage, { shouldDirty: true });
      setListPackageChecked(listIdPackage);
    }
  };

  const handleCheckPackage = (id) => {
    const indexExist = listPackageChecked.indexOf(id);
    if (indexExist === -1) {
      if (listPackageChecked.length === listPackage.length - 1) {
        setAllPackageChecked(true);
      }
      setListPackageChecked([...listPackageChecked, id]);
      setValue('package_ids', [...listPackageChecked, id], { shouldDirty: true });
    } else {
      setAllPackageChecked(false);
      const listCheck = [...listPackageChecked];
      listCheck?.splice(indexExist, 1);
      setValue('package_ids', listCheck, { shouldDirty: true });
      setListPackageChecked(listCheck);
    }
  };

  const handleCheckSurvey = (id) => {
    const indexExist = surveyIdChecked.indexOf(id);
    if (indexExist === -1) {
      setSurveyIdChecked([...surveyIdChecked, id]);
    } else {
      const listCheck = [...surveyIdChecked];
      listCheck?.splice(indexExist, 1);
      setSurveyIdChecked(listCheck);
    }
  };

  const renderPackageItem = (packageItem) => {
    const isChecked = listPackageChecked.includes(packageItem?.id);
    return (
      <div className='form-group d-flex align-items-center mb-2' key={packageItem?.id}>
        <input
          type='checkbox'
          id={packageItem?.id}
          className='form-check-input mt-0'
          {...register('package_ids')}
          onChange={() => handleCheckPackage(packageItem?.id)}
          checked={isChecked || allPackageChecked}
        />
        <label htmlFor={packageItem?.id} className='px-2'>
          {packageItem?.title}
        </label>
      </div>
    );
  };

  const renderSurveyItem = (surveyItem) => {
    const isChecked = surveyIdChecked.includes(surveyItem?.id);
    return (
      <div
        className='form-group d-flex'
        key={surveyItem?.id}
        style={{ width: '100%', wordBreak: 'break-all' }}>
        <input
          type='checkbox'
          id={surveyItem?.id}
          className='form-check-input mt-1'
          name={surveyItem?.id}
          // {...register("check_survey", {
          //  validate: (value) => (!surveySelected || value.length) ? true : "Please tell me if this is too hard."
          // })}
          onChange={() => handleCheckSurvey(surveyItem?.id)}
          checked={isChecked}
        />
        <label htmlFor={surveyItem?.id} className='px-2'>
          {surveyItem?.title}
        </label>
      </div>
    );
  };

  const handleCheckSubmit = (data) => {
    if (!data?.content?.trim()) {
      setError('content', {
        type: 'required',
        message: t('ValidateMsg.contentMessagesRequired'),
      });
    }
    if (data.type === 1 && !data.user_ids?.length) {
      setError('user_ids', {
        type: 'required',
        message: t('ValidateMsg.C101_VALIDATE_TYPE_MESSAGE'),
      });
    }
    if (data.type === 2 && !listPackageChecked?.length) {
      return true;
    }
    setShowPopupConfirm(true);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    let listLinkS3Img = [];
    let listLinkPreSignedImg = [];
    let main_video_pre_signed = '';
    let main_video = '';

    if (listImage.length) {
      const promiseList = listImage.map((item) => {
        return getPresignedUrlS3('image', `uploads/output/images`, setFileNameS3(item.name));
      });

      await Promise.all(promiseList)
        .then((responses) => {
          responses.forEach((element) => {
            if (element.success) {
              listLinkS3Img.push(element.data.file_path);
              listLinkPreSignedImg.push(element.data.pre_signed);
            }
          });
        })
        .catch((_errors) => {
          console.log(_errors);
        });

      const promiseGenerate = listLinkPreSignedImg.map((m, i) => {
        return uploadS3ByPresigned(m, listImage[i].file);
      });
      await Promise.all(promiseGenerate).then(() => {});
    }

    if (listVideo.length) {
      await getPresignedUrlS3(
        'video',
        `uploads/output/videos`,
        setFileNameS3(listVideo[0].name)
      )
        .then((resUrl) => {
          main_video_pre_signed = resUrl.data.pre_signed;
          main_video = resUrl.data.file_path;
        })
        .catch((_error) => {
          console.log(_error);
        });
      await uploadS3ByPresigned(main_video_pre_signed, listVideo[0].file);
    }

    const user_ids = data.user_ids?.map((user) => user.value);
    const params = {
      content: data.content,
      type: data.type,
    };
    if (allPackageChecked) {
      params.all_package = allPackageChecked;
    }
    if (listPackageChecked.length) {
      params.package_ids = listPackageChecked;
    }
    if (user_ids) {
      params.user_ids = user_ids;
    }
    if (listLinkS3Img.length) {
      params.images = listLinkS3Img;
    }
    if (surveySelected && surveyIdChecked?.length) {
      params.survey_ids = surveyIdChecked;
    }
    if (main_video) {
      params.main_video = main_video;
    }

    dispatch(createMessage(params, handleConfirmSuccess));
  };

  const handleConfirmSuccess = ({ success }) => {
    if (!success) {
      setConfirmFail(true);
    } else {
      dispatch(resetIDMess());
      setPostDetailMeta(null);
      setIdPostMeta(null);
      setConfirmSuccess(true);
    }
    setShowPopupConfirm(false);
    setLoading(false);
  };

  const handleSuccess = () => {
    setCountSubmit(countSubmit + 1);
    setConfirmSuccess(false);
    setConfirmFail(false);
    setListFan([]);
    reset({
      type: null,
      user_ids: [],
      select_survey: null,
      content: '',
    });
    setSurveySelected(null);
    setSearchValue('');
    setListPackageChecked([]);
    setAllPackageChecked(false);
    setSurveyIdChecked([]);
    setListImage([]);
    setListVideo([]);
    userRef.current.value = null;
    setNotYetSearch(true);
    setParamsSearch({
      pageFan: null,
      keyword: '',
      maxPageFan: 2,
    });
    setIsSearchFirst(false);
  };

  const handleOnchangeSendQuestionation = (value) => {
    setSurveySelected(value);
    if (value === 1) {
      setLoadingSurvey(true);
      dispatch(getListSurvey({ chk_expired: 1 }, handleListSurvey));
    } else {
      setListSurvey([]);
    }
  };

  return (
    <>
      <LayoutCreatorRight
        titlePage={t('Creator.Message.composeNewMessage')}
        className='create-message-layout'>
        {showPopupConfirm && (
          <PopupConfirmDelete
            title={t('Popup.confirmSendMess')}
            setShowPopup={setShowPopupConfirm}
            handleOnSubmit={handleSubmit(onSubmit)}
          />
        )}
        {confirmSuccess && (
          <PopupConfirm title={t('Popup.createMessSuccess')} closeModal={handleSuccess} />
        )}
        {confirmFail && (
          <PopupConfirm title={t('Popup.sendMessFail')} isWarning closeModal={handleSuccess} />
        )}
        {loading && (
          <div className='layer-spinner'>
            <LoadingIconV2 className='spinner-icon' />
          </div>
        )}
        <>
          <div className='create-message-page'>
            <form onSubmit={(e) => e.preventDefault()}>
              <InputWrapper id='type' error={errors?.type ? errors?.type?.message : ''}>
                <Controller
                  name='type'
                  control={control}
                  rules={{ required: 'ValidateMsg.C101_VALIDATE_TYPE_MESSAGE' }}
                  render={({ field }) => (
                    <Select
                      key={countSubmit}
                      ref={field.ref}
                      options={optionDestinationType}
                      isLoading={loadingListContent}
                      value={optionDestinationType?.find((e) => e.value === field.value)}
                      isSearchable={false}
                      isClearable={false}
                      className='select'
                      placeholder={t('Creator.Message.destinationType')}
                      width='100%'
                      styles={customStylesReselect}
                      components={{
                        Option: CustomOption,
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                      onChange={(val) => {
                        if (dataUser?.status !== 1 && val.value === 2) 
                          toast.error(t('Creator.Message.featureNotAvailable'), {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 3000,
                            hideProgressBar: true,
                            theme: 'colored',
                            className: 'display-linebreak',
                            enableMultiContainer: true,
                            icon: ({ theme, type }) => (
                              <svg
                                width={18}
                                height={18}
                                viewBox='0 0 18 18'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                  d='M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z'
                                  stroke='#DC143C'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                                <path
                                  d='M11.25 6.75L6.75 11.25'
                                  stroke='#DC143C'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                                <path
                                  d='M11.25 11.25L6.75 6.75'
                                  stroke='#DC143C'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            ),
                          });
                        field.onChange(val.value);
                      }}
                    />
                  )}
                />
              </InputWrapper>
              {/* <InputWrapper
                label={t('Creator.Message.selectDestination')}
                id='user_ids'
                error={errors?.user_ids ? errors?.user_ids?.message : ''}>
                <Controller
                  name='user_ids'
                  control={control}
                  render={({ field }) => (
                    <Select
                      key={countSubmit}
                      ref={userRef}
                      closeMenuOnSelect={false}
                      options={listFan}
                      isLoading={false}
                      isSearchable={true}
                      isClearable={true}
                      isMulti={true}
                      className='select'
                      hideSelectedOptions={false}
                      backspaceRemovesValue={false}
                      noOptionsMessage={() => {
                        if (isSearchFirst) {
                          return t('Messages.noCreator');
                        }
                        return null;
                      }}
                      value={field.value}
                      onChange={(val) => {
                        field.onChange(val);
                      }}
                      placeholder={t('Creator.Message.placeholderSelectCreator')}
                      loadingMessage='...'
                      styles={{
                        ...customStylesReselect,
                        valueContainer: (base) => ({
                          ...base,
                          paddingTop: 0,
                          paddingBottom: 0,
                          height: 44,
                          minHeight: 44,
                          overflow: 'auto',
                          '::-webkit-scrollbar': {
                            width: '3px',
                            height: '0px',
                          },
                          '::-webkit-scrollbar-track': {
                            background: 'transparent',
                          },
                          '::-webkit-scrollbar-thumb': {
                            background: '#d9d9d9',
                          },
                        }),
                      }}
                      isDisabled={type !== 1}
                      components={{
                        Option: CustomOption,
                        DropdownIndicator: CustomDropdownIndicator,
                      }}
                      openMenuOnFocus={!!listFan.length || !notYetSearch}
                      openMenuOnClick={!!listFan.length || !notYetSearch}
                      captureMenuScroll={(e) => {}}
                      onMenuScrollToBottom={() => {
                        setParamsSearch((prev) => {
                          if (prev.maxPageFan === prev.pageFan) {
                            return prev;
                          }
                          return {
                            ...prev,
                            pageFan: prev.pageFan + 1,
                          };
                        });
                      }}
                      inputValue={searchValue}
                      onInputChange={(value, action) => {
                        if (action.action !== 'input-blur' && action.action !== 'menu-close') {
                          setSearchValue(value);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          e.preventDefault();
                        }
                      }}
                      notYetSearch={notYetSearch}
                    />
                  )}
                />
              </InputWrapper> */}
              <InputWrapper
                label={t('Creator.Message.pleaseCheckToSend')}
                error={errors?.package_ids ? errors?.package_ids?.message : ''}>
                <div className='w-100 option-sub-checkbox'>
                  <div className='form-group d-flex align-items-center mb-2'>
                    <input
                      disabled={((dataUser?.status !== 1 && type === 2) || type !== 2)}
                      type='checkbox'
                      id='all_plans'
                      className='form-check-input checkbox-package mt-0'
                      {...register('package_ids')}
                      onChange={handleCheckAllPackage}
                      checked={allPackageChecked}
                    />
                    <label
                      htmlFor='all_plans'
                      className={`all-plan px-2 ${type !== 2 && 'disabled'}`}>
                      {t('Common.allPlan2')}
                    </label>
                  </div>
                  {type === 2 && dataUser?.status === 1 && (
                    <>
                      {listPackage.map(renderPackageItem)}
                      {(dirtyFields?.package_ids || isSubmitted) &&
                        !listPackageChecked?.length && (
                          <div className='error-package'>
                            {t(`ValidateMsg.C101_VALIDATE_PACKAGE`)}
                          </div>
                        )}
                    </>
                  )}
                </div>
              </InputWrapper>
              <InputWrapper label={t('Creator.Message.selectSurveyToSend')}>
                <Controller
                  name='select_survey'
                  control={control}
                  render={({ field }) => (
                    <div
                      onClick={() => {
                        if (dataUser?.status !== 1)
                          toast.error(t('Creator.Message.featureNotAvailable'), {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 3000,
                            hideProgressBar: true,
                            theme: 'colored',
                            className: 'display-linebreak',
                            enableMultiContainer: true,
                            icon: ({ theme, type }) => (
                              <svg
                                width={18}
                                height={18}
                                viewBox='0 0 18 18'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                  d='M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z'
                                  stroke='#DC143C'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                                <path
                                  d='M11.25 6.75L6.75 11.25'
                                  stroke='#DC143C'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                                <path
                                  d='M11.25 11.25L6.75 6.75'
                                  stroke='#DC143C'
                                  strokeWidth='1.5'
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </svg>
                            ),
                          });
                      }}>
                      <Select
                        isDisabled={dataUser?.status !== 1}
                        key={countSubmit}
                        options={optionSurvey}
                        value={optionSurvey?.find((e) => e.value === surveySelected)}
                        defaultValue={optionSurvey[0]}
                        isLoading={loadingListContent}
                        isSearchable={false}
                        isClearable={false}
                        className='select'
                        placeholder=''
                        width='100%'
                        styles={customStylesReselect}
                        components={{
                          Option: CustomOption,
                          DropdownIndicator: CustomDropdownIndicator,
                        }}
                        onChange={(e) => {
                          handleOnchangeSendQuestionation(e.value);
                        }}
                      />
                    </div>
                  )}
                />
                {surveySelected === 1 && !loadingSurvey && (
                  <p
                    className='link-navigate-survey'
                    onClick={() =>
                      navigate(`${newPathUser}create-surveys`, {
                        state: { back: 'message' },
                      })
                    }>
                    {t('Creator.Message.navigateCreateSurvey')}
                  </p>
                )}
              </InputWrapper>
              {surveySelected === 1 && !loadingSurvey && (
                <InputWrapper id='select_survey_options'>
                  <div className='group-options-survey'>
                    {listSurvey?.length > 0 ? (
                      <div className='option-survey-checkbox'>
                        {listSurvey?.map(renderSurveyItem)}
                      </div>
                    ) : (
                      <div className='no-survey'>{t('Creator.Message.noSurveys')}</div>
                    )}
                  </div>
                </InputWrapper>
              )}
              <PreviewMultipleImages
                key={countSubmit}
                id='fileUpload'
                maxSizeImage={TEN_MB}
                maxSizeVideo={ONE_GB}
                isApprove={dataUser?.status === 1}
                description={t('Creator.Message.attachVideosOrPhotos')}
                acceptImage={acceptedImageTypes}
                acceptVideo={['video/mp4', 'video/x-ms-wmv', 'video/quicktime']}
                handleSelectImage={(listImageSelect, video) => {
                  setListImage(listImageSelect);
                  setListVideo(video);
                }}
              />
              <InputWrapper
                id='fsite-content'
                label={t('Creator.Message.text')}
                error={errors?.content ? errors?.content?.message : ''}>
                <div
                  className={`wrapper-content-ogp ${
                    postDetailMeta?.title ? 'have-meta' : ''
                  }`}>
                  <textarea
                    {...register('content', {
                      required: 'ValidateMsg.contentMessagesRequired',
                      maxLength: 'ValidateMsg.maxlengthContentMessages',
                      onChange: (e) => handleTextarea(e),
                    })}
                    placeholder={t('Creator.Message.placeholderTextarea')}
                    onKeyDown={(e) => handleTextarea(e)}
                    onChange={(e) => handleTextarea(e)}
                    maxLength={500}
                    className='form-control content-ogp'
                    rows={6}
                  />
                  {postDetailMeta?.title && (
                    <MetaTagViewer
                      title={postDetailMeta?.title}
                      url={postDetailMeta?.url}
                      thumbnail={postDetailMeta?.thumbnail}
                    />
                  )}
                </div>
              </InputWrapper>
              <InputWrapper id='fsite-submit' className='button-submit'>
                <button
                  disabled={type === 2 && dataUser?.status !== 1}
                  className='btn btn-danger btn-lg w-100'
                  onClick={handleSubmit(handleCheckSubmit)}>
                  {t('Common.send')}
                </button>
              </InputWrapper>
            </form>
          </div>
        </>
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(CreateMessage);
