import { CloseIcon } from 'images';
import useLockBodyScroll from 'hooks/useLockBodyScroll';

const PopupGeneral = ({
  closeModal,
  title,
  className,
  generalClass = '',
  noTitle = false,
  hiddenCloseIcon = false,
  ...props
}) => {
  const handleShowPopup = (e) => {
    closeModal(null);
  };
  useLockBodyScroll();

  return (
    <div className={`popup popup-general ${className}`}>
      <div className='popup-content' style={{ zIndex: 101 }}>
        {!hiddenCloseIcon && <CloseIcon className='close-icon' onClick={handleShowPopup} />}
        {!noTitle && (
          <div
            className='title'
            style={props.colorTitle !== undefined ? { color: props.colorTitle } : {}}>
            {title}
          </div>
        )}
        <div className={`box-general ${generalClass} ${noTitle && 'no-title'}`}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default PopupGeneral;
