import Icon from '@ant-design/icons';

const ReportIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M4.29102 1.66602V18.3327'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.29102 3.33398H13.6244C15.8744 3.33398 16.3744 4.58398 14.791 6.16732L13.791 7.16732C13.1243 7.83398 13.1243 8.91732 13.791 9.50065L14.791 10.5007C16.3744 12.084 15.791 13.334 13.6244 13.334H4.29102'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default ReportIcon;
