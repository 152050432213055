import Icon from '@ant-design/icons';

const HiddenV2Icon = (props) => (
  <Icon
    component={() => (
      <svg
        width={20}
        height={20}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M10 4.375C3.75 4.375 1.25 10.0006 1.25 10.0006C1.25 10.0006 3.75 15.625 10 15.625C16.25 15.625 18.75 10.0006 18.75 10.0006C18.75 10.0006 16.25 4.375 10 4.375Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10 13.125C11.7259 13.125 13.125 11.7259 13.125 10C13.125 8.27411 11.7259 6.875 10 6.875C8.27411 6.875 6.875 8.27411 6.875 10C6.875 11.7259 8.27411 13.125 10 13.125Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default HiddenV2Icon;
