import Icon from '@ant-design/icons';

const UpThumbnailDisableIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={44}
        height={44}
        viewBox='0 0 44 44'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx={22} cy={22} r='21.5' fill='#BEBEBE' stroke='#EEEEEE' />
        <path
          d='M12.6255 20.121C12.7912 20.121 12.9502 20.0551 13.0674 19.9379C13.1846 19.8207 13.2505 19.6617 13.2505 19.496V14.951C13.251 14.499 13.4308 14.0656 13.7504 13.746C14.07 13.4264 14.5034 13.2466 14.9554 13.2461H19.5004C19.6661 13.2461 19.8251 13.1802 19.9423 13.063C20.0595 12.9458 20.1253 12.7868 20.1253 12.6211C20.1253 12.4553 20.0595 12.2964 19.9423 12.1791C19.8251 12.0619 19.6661 11.9961 19.5004 11.9961H14.9554C14.172 11.9969 13.4209 12.3085 12.8669 12.8625C12.3129 13.4165 12.0013 14.1676 12.0005 14.951V19.496C12.0005 19.6617 12.0663 19.8207 12.1835 19.9379C12.3008 20.0551 12.4597 20.121 12.6255 20.121Z'
          fill='white'
        />
        <path
          d='M29.0454 11.9961H24.5005C24.3347 11.9961 24.1758 12.0619 24.0585 12.1791C23.9413 12.2964 23.8755 12.4553 23.8755 12.6211C23.8755 12.7868 23.9413 12.9458 24.0585 13.063C24.1758 13.1802 24.3347 13.2461 24.5005 13.2461H29.0454C29.4974 13.2466 29.9308 13.4264 30.2504 13.746C30.5701 14.0656 30.7499 14.499 30.7504 14.951V19.496C30.7504 19.6617 30.8162 19.8207 30.9334 19.9379C31.0506 20.0551 31.2096 20.121 31.3754 20.121C31.5411 20.121 31.7001 20.0551 31.8173 19.9379C31.9345 19.8207 32.0003 19.6617 32.0003 19.496V14.951C31.9995 14.1676 31.6879 13.4165 31.1339 12.8625C30.58 12.3085 29.8288 11.9969 29.0454 11.9961Z'
          fill='white'
        />
        <path
          d='M19.5004 30.746H14.9554C14.5034 30.7455 14.07 30.5657 13.7504 30.2461C13.4308 29.9264 13.251 29.493 13.2505 29.041V24.4961C13.2505 24.3303 13.1846 24.1714 13.0674 24.0541C12.9502 23.9369 12.7912 23.8711 12.6255 23.8711C12.4597 23.8711 12.3008 23.9369 12.1835 24.0541C12.0663 24.1714 12.0005 24.3303 12.0005 24.4961V29.041C12.0013 29.8245 12.3129 30.5756 12.8669 31.1296C13.4209 31.6835 14.172 31.9951 14.9554 31.996H19.5004C19.6661 31.996 19.8251 31.9301 19.9423 31.8129C20.0595 31.6957 20.1253 31.5367 20.1253 31.371C20.1253 31.2052 20.0595 31.0462 19.9423 30.929C19.8251 30.8118 19.6661 30.746 19.5004 30.746Z'
          fill='white'
        />
        <path
          d='M31.3754 23.8711C31.2096 23.8711 31.0506 23.9369 30.9334 24.0541C30.8162 24.1714 30.7504 24.3303 30.7504 24.4961V29.041C30.7499 29.493 30.5701 29.9264 30.2504 30.2461C29.9308 30.5657 29.4974 30.7455 29.0454 30.746H24.5005C24.3347 30.746 24.1758 30.8118 24.0585 30.929C23.9413 31.0462 23.8755 31.2052 23.8755 31.371C23.8755 31.5367 23.9413 31.6957 24.0585 31.8129C24.1758 31.9301 24.3347 31.996 24.5005 31.996H29.0454C29.8288 31.9951 30.58 31.6835 31.1339 31.1296C31.6879 30.5756 31.9995 29.8245 32.0003 29.041V24.4961C32.0003 24.3303 31.9345 24.1714 31.8173 24.0541C31.7001 23.9369 31.5411 23.8711 31.3754 23.8711Z'
          fill='white'
        />
        <path
          d='M26.2508 21.996C26.2508 24.3432 24.3481 26.2459 22.0009 26.2459C19.6537 26.2459 17.751 24.3432 17.751 21.996C17.751 19.6488 19.6537 17.7461 22.0009 17.7461C24.3481 17.7461 26.2508 19.6488 26.2508 21.996Z'
          stroke='white'
          strokeWidth='1.5'
        />
      </svg>
    )}
    {...props}
  />
);

export default UpThumbnailDisableIcon;
