import { PopupConfirmFan, PopupGeneral, PopupLoading, ReadMoreLess } from 'components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import LimitedTicketIconEN from 'images/LimitedTicketIconEN.png';
import LimitedTicketIconJP from 'images/LimitedTicketIconJP.png';
import PremiumTicketIconEN from 'images/PremiumTicketIconEN.png';
import PremiumTicketIconJP from 'images/PremiumTicketIconJP.png';
import SuccessRegisterIcon from 'images/SuccessRegisterIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
  getListPackageAnotherCreator,
  postUnSubsriptionPackage,
  setIdScrollBackSellPackage,
} from '../../store/actions/sellPost';
import { formatCoin, isAuth } from '../../utils/utils';
import PopupSellPackageF017 from '../Popup/sellPackageF017';
import './index.scss';
import { useNavigate } from 'react-router';
import { newPathUser } from 'constants/layout/constant';
import { resetDataInfoOther, resetIDHomePage } from 'store/actions/usersNew';
import { logUserProfile } from 'store/actions/myPage';

const Plan = ({ item, buyPackageSuccess, creator_id, setIsOpen, setIdPackage }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checkedInput, setCheckedInput] = useState(false);
  const [showModalUnSubscription, setShowModalUnSubscription] = useState(false);
  const [id, setId] = useState();
  const [openPopupBuyPackage, setOpenPopupBuyPackage] = useState(false);
  const [stopPackageSuccess, setStopPackageSuccess] = useState(false);

  const { loadingBuyPost } = useSelector((state) => state.sellPost);
  const { t, i18n } = useTranslation();
  const toggleModal = ({ fan_buy_package, id }) => {
    setId(id);
    setIdPackage(id);

    if (fan_buy_package === 1) {
      setShowModalUnSubscription(!showModalUnSubscription);
    } else {
      setOpenPopupBuyPackage(true);
    }
  };
  const handleUnSubscription = () => {
    dispatch(postUnSubsriptionPackage(id, () => setStopPackageSuccess(true)));
    setShowModalUnSubscription(false);
    setId(null);
  };

  useEffect(() => {
    setCheckedInput(false);
  }, []);
  return (
    <>
      {showModalUnSubscription && (
        <PopupConfirmFan
          isVisiblePopup={(e) => {
            setShowModalUnSubscription(e);
            setId();
          }}
          colorSvg='#FFBF00'
          fillColor={true}
          iconNote={true}
          text={t('Popup.messageLeave')}
          className='unsub-modal'>
          <div className='ok btn' onClick={handleUnSubscription}>
            {t('Common.Ok')}
          </div>
          <div
            className='btn cancel'
            style={{ fontWeight: 500 }}
            onClick={() => {
              setShowModalUnSubscription(false);
              setId();
            }}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}
      {loadingBuyPost && id === item.id && <PopupLoading className={'popup-loading'} />}
      {openPopupBuyPackage && id === item.id && (
        <PopupSellPackageF017
          setCheckedInput={setCheckedInput}
          setIsOpen={setIsOpen}
          creator_id={creator_id}
          id={id}
          openPopup={openPopupBuyPackage}
          isVisiblePopup={(e) => {
            setOpenPopupBuyPackage(e);
          }}
          buyPackageSuccess={(e) => buyPackageSuccess(e)}
          item={item}
        />
      )}
      <div
        id={`item-sell-${item?.id}`}
        className={`plan-item-subscription px-3 py-4 mb-4 w-100 ${
          item?.fan_buy_package && 'selled'
        } ${!!item?.ticket_id ? 'premium' : ''} ${
          !item?.fan_buy_package && item?.limited_flag && item?.number_paid >= item?.limited
            ? 'limited'
            : ''
        }`}>
        {!!item?.ticket_id && item?.ticket_status === 1 &&
          !(
            !item?.fan_buy_package &&
            item?.limited_flag &&
            item?.number_paid >= item?.limited
          ) && (
            <div className='premium-label 1'>
              {i18n?.language === 'en' ? (
                <img src={PremiumTicketIconEN} alt='' />
              ) : (
                <img src={PremiumTicketIconJP} alt='' />
              )}
            </div>
          )}
        {!!(
          !item?.fan_buy_package &&
          item?.limited_flag &&
          item?.number_paid >= item?.limited
        ) && (
          <div className='limited-label'>
            {i18n?.language === 'en' ? (
              <img src={LimitedTicketIconEN} alt='' />
            ) : (
              <img src={LimitedTicketIconJP} alt='' />
            )}
          </div>
        )}
        <p
          className={`plan-item-subscription__title text-center ${
            item?.ticket_id ? 'premium' : ''
          }`}>
          {item?.title}
        </p>
        <p className='plan-item-subscription__plan-price'>
          {t('Common.oneMonth')}/ {formatCoin(item?.price)}
          {t('Common.pt')}
        </p>
        <div className='plan-subscription p-3 display-linebreak'>
          <ReadMoreLess content={item?.description ? item?.description : ''} />
        </div>
        <div className={`agree-policy ${i18n.language === 'jp' ? 'jp' : ''}`}>
          <input
            type='checkbox'
            id='agreePolicy'
            disabled={
              !item?.fan_buy_package &&
              item?.limited_flag &&
              item?.number_paid >= item?.limited
            }
            className='policy-checkbox form-check-input mt-0'
            name='agreePolicy'
            checked={checkedInput}
            onChange={(e) => {
              if (!isAuth) {
                dispatch(logUserProfile());
              } else {
                setCheckedInput(e.target.checked);
              }
            }}
          />
          <label className='policy-label'>{t('plan.agree')}</label>
          <a className={`link `} target='_blank' href='/terms-of-use'>
            {t('plan.term')}
          </a>
        </div>
        <div className='plan-item-subscription__footer'>
          <button
            className={`plan-item-subscription__button btn btn-edit1`}
            onClick={() => {
              dispatch(resetIDHomePage());
              dispatch(resetDataInfoOther());
              dispatch(
                setIdScrollBackSellPackage(
                  `item-sell-${item?.id}`,
                  navigate(
                    `${newPathUser}${item.account_id}?post=post-normal?sub=${item?.title}`,
                    {
                      state: { packageId: item?.id, packageName: item?.title },
                    }
                  )
                )
              );
            }}>
            {t('Creator.Home.listofPosts')} {`(${t('Creator.Home.numberPost')}: ${item?.post_count || 0})`} 
          </button>
          <button
            className={`plan-item-subscription__button btn btn-submit ${
              !checkedInput ? 'not-checked' : ''
            } ${item?.fan_buy_package && 'subscribed'} ${
              !item?.fan_buy_package &&
              item?.limited_flag &&
              item?.number_paid >= item?.limited
                ? 'not-checked'
                : ''
            }`}
            onClick={() => {
              if (
                !item?.fan_buy_package &&
                item?.limited_flag &&
                item?.number_paid >= item?.limited
              )
                return;
              if (checkedInput) {
                toggleModal(item);
              }
            }}>
            {item?.fan_buy_package ? t('plan.withdrawal') : t('Common.membership')}
          </button>
        </div>
        {stopPackageSuccess && (
          <PopupGeneral
            isVisible={stopPackageSuccess}
            closeModal={() => {
              setStopPackageSuccess(false);
              dispatch(getListPackageAnotherCreator(creator_id));
            }}
            title={t('Plan.title')}
            className='popup-success-subscribe'>
            <SuccessRegisterIcon />
            <p className='text-center success-message py-3'>
              {t('Plan.leaveSub.successText', { title: item.title })}
            </p>
            <div className='button-wrapper'>
              <div
                className='ok btn'
                onClick={() => {
                  setStopPackageSuccess(false);
                  setCheckedInput(false);
                  dispatch(getListPackageAnotherCreator(creator_id));
                }}>
                {t('Common.Ok')}
              </div>
            </div>
          </PopupGeneral>
        )}
      </div>
    </>
  );
};

export default Plan;
