import { ListChat } from 'components/LiveStream/ListChat';
import { ContentChat } from './styled';
import { InputChat } from 'components/LiveStream/Chat/InputChat';
import { useMemo } from 'react';
import { DBRealTime } from '../../../../../firebase/config';
import { onValue, ref, query, orderByChild, equalTo } from 'firebase/database';
import { useState } from 'react';
import { useEffect } from 'react';
import { QueueList } from 'utils';
import { updateData } from 'hooks/useRealTimeDB';

export const ListContentChat = ({ checkHorizontalScreen, liveKey, denyCharacter }) => {
  const [stampsQueue, setStampsQueue] = useState([]);
  const [showStamps, setShowStamps] = useState({ show: false, stampId: '', stampImg: '' });
  const [dataStamps, setDataStamps] = useState([]);
  let q = new QueueList();

  useEffect(() => {
    if (stampsQueue.length > 0) {
      stampsQueue.forEach((obj, _i) => {
        // console.log("msgFrom", obj.stampId);
        q.enqueue(obj);
      });
      let interval = null;
      if (!q.isEmpty) {
        let objPeek = q.peek();

        // setShowStamps({show: true, stampId: objPeek?.stampGiff, stampImg:objPeek?.stampGiff  });
        if (!dataStamps.length) {
          setShowStamps({
            show: true,
            stampId: objPeek?.stampGiff,
            stampImg: objPeek?.stampGiff,
          });
          setDataStamps([...dataStamps, objPeek]);
        }
        interval = setInterval(() => {
          let objDeque = q.dequeue();
          // console.log("QQ: ", objDeque);
          if (objDeque !== undefined) {
            setShowStamps({
              show: true,
              stampId: objDeque?.stampGiff,
              stampImg: objDeque?.stampGiff,
            });
            setDataStamps([...dataStamps, objDeque]);

            updateData(`/stamps/${liveKey}/${objDeque?.key}`, { status: 0 }).then(() => {});
          } else {
            // setShowStamps({show: false, stampId: '', stampImg: '' });
            // setDataStamps([]);
          }
        }, 30);
      }
      return () => clearInterval(interval);

      // for (let [key, value] of Object.entries(stampsQueue)) {
      //   q.enqueue(value);
      //   console.log(key, value);
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stampsQueue]);

  useMemo(() => {
    const messageRef = query(
      ref(DBRealTime, `/stamps/${liveKey}`),
      orderByChild('status'),
      equalTo(1)
    );

    onValue(messageRef, (snapshotData) => {
      const newData = [];
      snapshotData.forEach((ss) => {
        newData.push({ ...ss.val(), key: ss.key });
      });
      if (newData.length > 0) {
        return setStampsQueue(newData);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ContentChat
      className={`group-chat-box ${
        checkHorizontalScreen === 'landscape' && 'group-chat-box-landscape'
      }`}>
      <ContentChat className='content-chat'>
        <ListChat denyCharacter={denyCharacter} keyId={''} views='creator' />
        <InputChat
          allowComment={true}
          denyCharacter={[]}
          creator={1}
          id={0}
          isAuth={true}
          dataStamps={dataStamps}
          setDataStamps={setDataStamps}
        />
      </ContentChat>
    </ContentChat>
  );
};
