// import { useEffect, useState } from 'react';
// import { isIOS, isMobile } from 'react-device-detect';
// import InfiniteScroll from 'react-infinite-scroll-component';
// import { widthSreen } from '../../utils';
// import Loading from './Loading';

// const InfiniteLoad = (props) => {
//   const [checkScroll, setCheckScroll] = useState(false);
//   const [size, setSize] = useState(0);

//   const isLandscape = () => window.matchMedia('(orientation:landscape)').matches,
//     [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait'),
//     onWindowResize = () => {
//       clearTimeout(window.resizeLag);
//       window.resizeLag = setTimeout(() => {
//         delete window.resizeLag;
//         setSize(Number(window.innerWidth));
//         setOrientation(isLandscape() ? 'landscape' : 'portrait');
//       }, 200);
//     };

//   useEffect(
//     () => (
//       onWindowResize(),
//       window.addEventListener('resize', onWindowResize),
//       () => window.removeEventListener('resize', onWindowResize)
//     ),
//     []
//   );

//   return (
//     <>
//       {orientation === 'portrait' && size < 1024 && isIOS ? ( //DOC
//         <InfiniteScroll
//           dataLength={props.data.length || 0}
//           next={props.fetchNext}
//           hasMore={true}
//           loader={props.loading && checkScroll ? <Loading /> : null}
//           height={props.setHeight ? props.setHeight : ''}
//           onScroll={() => {
//             setCheckScroll(true);
//             !props.loading && setCheckScroll(false);
//           }}
//           {...props}>
//           <div>1{props.children}</div>
//         </InfiniteScroll>
//       ) : (
//         //ngang
//         <InfiniteScroll
//           dataLength={props.data.length || 0}
//           next={props.fetchNext}
//           hasMore={true}
//           loader={props.loading && checkScroll ? <Loading /> : null}
//           height={props.setHeight ? props.setHeight : ''}
//           onScroll={() => {
//             setCheckScroll(true);
//             !props.loading && setCheckScroll(false);
//           }}
//           scrollableTarget={'root'}
//           {...props}>
//           <div>2{props.children}</div>
//         </InfiniteScroll>
//       )}
//     </>
//   );
// };

// export default InfiniteLoad;

import { useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import InfiniteScroll from 'react-infinite-scroll-component';
import { widthSreen } from '../../utils';
import Loading from './Loading';

const InfiniteLoad = (props) => {
  const [checkScroll, setCheckScroll] = useState(false);
  return (
    <>
      {widthSreen < 1024 && isMobile && isIOS ? (
        <InfiniteScroll
          dataLength={props.data.length || 0}
          next={props.fetchNext}
          hasMore={true}
          loader={props.loading && checkScroll ? <Loading /> : null}
          height={props.setHeight ? props.setHeight : ''}
          onScroll={() => {
            setCheckScroll(true);
            !props.loading && setCheckScroll(false);
          }}
          {...props}>
          {props.children}
        </InfiniteScroll>
      ) : (
        <InfiniteScroll
          dataLength={props.data.length || 0}
          next={props.fetchNext}
          hasMore={true}
          loader={props.loading && checkScroll ? <Loading /> : null}
          height={props.setHeight ? props.setHeight : ''}
          onScroll={() => {
            setCheckScroll(true);
            !props.loading && setCheckScroll(false);
          }}
          scrollableTarget={
            props?.scrollableTargetCustom ? props?.scrollableTargetCustom : 'root'
          }
          {...props}>
          {props.children}
        </InfiniteScroll>
      )}
    </>
  );
};

export default InfiniteLoad;

