import Icon from '@ant-design/icons';

const EarthIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={18}
        height={18}
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M15.8808 11.4781C15.8913 11.454 15.9 11.4292 15.907 11.4038C16.4479 9.84793 16.4476 8.15502 15.9063 6.5993C15.8996 6.57514 15.8912 6.55147 15.8813 6.52843C15.3705 5.11314 14.4363 3.88948 13.2057 3.02381C11.975 2.15814 10.5076 1.69246 9.00295 1.69008L9 1.6875L8.99705 1.69008C7.49256 1.69245 6.02529 2.15804 4.79468 3.02356C3.56408 3.88908 2.62984 5.11254 2.11895 6.52763C2.10874 6.55126 2.10017 6.57556 2.09331 6.60037C1.55191 8.15704 1.55224 9.85094 2.09423 11.4074C2.10078 11.4306 2.1088 11.4533 2.11827 11.4755C2.62886 12.891 3.56305 14.115 4.7938 14.9809C6.02456 15.8468 7.49214 16.3126 8.99698 16.315L9 16.3176L9.00303 16.315C10.5074 16.3126 11.9745 15.8471 13.2051 14.9817C14.4356 14.1163 15.3698 12.893 15.8808 11.4781ZM9 3.20588C9.81644 4.05188 10.4301 5.07227 10.7945 6.19004H7.20545C7.56995 5.07227 8.18356 4.05188 9 3.20588ZM7.20548 11.815H10.7945C10.43 12.9328 9.81641 13.9532 9 14.7992C8.18359 13.9532 7.56998 12.9328 7.20548 11.815ZM6.91098 10.69C6.69634 9.57528 6.69634 8.4298 6.91098 7.31504H11.089C11.3037 8.4298 11.3037 9.57528 11.089 10.69H6.91098ZM12.2364 7.31504H14.9536C15.2655 8.41843 15.2655 9.58666 14.9536 10.69H12.2364C12.4212 9.57263 12.4212 8.43245 12.2364 7.31504ZM14.5105 6.19004H11.9818C11.645 5.00471 11.0674 3.90158 10.285 2.9496C11.1855 3.14257 12.0319 3.53344 12.7627 4.0939C13.4936 4.65436 14.0906 5.37036 14.5105 6.19004H14.5105ZM10.2849 15.0555C11.0673 14.1035 11.645 13.0004 11.9818 11.815H14.5105C14.0906 12.6347 13.4935 13.3507 12.7627 13.9112C12.0318 14.4717 11.1855 14.8625 10.2849 15.0555Z'
          fill='white'
        />
      </svg>
    )}
    {...props}
  />
);

export default EarthIcon;
