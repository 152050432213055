/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Chart from '../generalChart/chart';
import Table from '../generalChart/table';
import ChartCircle from '../generalChart/chartCircle';
import { getChartSales } from 'store/actions/chartRepot';
import { useTranslation } from 'react-i18next';
const Subscriptions = ({ date }) => {
  const { from_date, to_date } = date;
  const dispatch = useDispatch();
  const { chartSales } = useSelector((state) => state.chartReport);
  const [dataPieChart, setDataPieChart] = useState();
  const [genderChartCircle, setGenderChartCircle] = useState();
  const [clearActive, setClearActive] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(
      getChartSales(
        { from_date, to_date },
        () => {
          setDataPieChart();
          setGenderChartCircle();
          setClearActive(true);
        },
        () => {
          setDataPieChart();
          setGenderChartCircle();
          setClearActive(true);
        }
      )
    );
  }, [from_date, to_date]);

  return (
    <>
      {chartSales.length > 0 && (
        <>
          <Chart
            data={chartSales || []}
            titleChart={t('Report.content_buyer_report')}
            labelLeftChart={t('Report.number_of_purchasers')}
          />
          <Table
            columns={chartSales}
            itemClick={(e) => {
              setDataPieChart(e);
              setClearActive(false);
            }}
            clearActive={clearActive}
            genderChart={(e) => setGenderChartCircle(e)}
            totalTableField={t('Report.purchaser_total')}
          />
          {dataPieChart && (
            <ChartCircle
              data={dataPieChart}
              genderChart={genderChartCircle}
              titleChart='Report.mumber_of_content'
            />
          )}
        </>
      )}
    </>
  );
};
export default Subscriptions;
