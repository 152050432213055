import { newPathUser } from 'constants/layout/constant';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { CustomDropdownIndicator, CustomOption } from 'utils';
import { customStyleSelect, listSortTime } from './constant';
import './index.scss';
import { useGetIdFromRecommendUser } from 'hooks/v2/useGetIdFromRecommendUser';

export const OptionSearchRanking = ({
  timeUpdateData,
  option,
  type,
  setDefaultData,
  isLoading,
}) => {
  const { idFromRecommendUser, clearIdScrollBackRecommend } = useGetIdFromRecommendUser();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onChangeSelectTime = (data) => {
    if (data?.value !== option) {
      clearIdScrollBackRecommend();
      setDefaultData();
      navigate(`${newPathUser}ranking?type=${type}&option=${data?.value}`);
    }
  };

  return (
    <div className='ranking-option-search'>
      <div className='ranking-option-search-info'>
        <div className='ranking-option-search-info__select'>
          <Select
            options={listSortTime(t)}
            isSearchable={false}
            isClearable={false}
            defaultValue={listSortTime(t)[0]}
            onChange={onChangeSelectTime}
            className='select-sort-time'
            value={listSortTime(t)?.find((e) => e.value === option)}
            styles={customStyleSelect}
            isDisabled={isLoading}
            components={{
              Option: CustomOption,
              DropdownIndicator: CustomDropdownIndicator,
            }}
          />
        </div>
        <div className='ranking-option-search-info__time'>{timeUpdateData}</div>
      </div>
      <div className='ranking-option-search-type'>
        <div
          className={`tab-item ${+type === 1 ? 'active' : ''} ${
            isMobile ? '' : 'hover-color'
          }`}
          onClick={() => {
            if (+type !== 1 && !isLoading) {
              clearIdScrollBackRecommend();
              setDefaultData();
              navigate(`${newPathUser}ranking?type=${1}&option=${option}`);
            }
          }}>
          {t('Ranking.numberLike')}
        </div>
        <div
          className={`tab-item ${+type === 2 ? 'active' : ''} ${
            isMobile ? '' : 'hover-color'
          }`}
          onClick={() => {
            if (+type !== 2 && !isLoading) {
              clearIdScrollBackRecommend();
              setDefaultData();
              navigate(`${newPathUser}ranking?type=${2}&option=${option}`);
            }
          }}>
          {t('Ranking.saleContent')}
        </div>
      </div>
    </div>
  );
};

export default OptionSearchRanking;
