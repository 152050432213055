import Icon from '@ant-design/icons';

const FileIconV2 = (props) => (
  <Icon
    component={() => (
      <svg
        width='22'
        height='25'
        viewBox='0 0 22 25'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M14.8899 6.94556L5.57597 16.416C5.26763 16.7563 5.10195 17.2021 5.11326 17.6612C5.12456 18.1203 5.31197 18.5575 5.6367 18.8822C5.96142 19.2069 6.39858 19.3943 6.85766 19.4056C7.31675 19.4169 7.7626 19.2513 8.10291 18.9429L19.2058 7.68351C19.8225 7.0029 20.1538 6.11119 20.1312 5.19302C20.1086 4.27485 19.7338 3.40053 19.0843 2.75109C18.4349 2.10164 17.5606 1.72682 16.6424 1.70421C15.7242 1.6816 14.8325 2.01295 14.1519 2.62964L3.04904 13.889C2.04376 14.8943 1.479 16.2578 1.479 17.6794C1.479 19.1011 2.04376 20.4646 3.04904 21.4698C4.05432 22.4751 5.41777 23.0399 6.83944 23.0399C8.26112 23.0399 9.62457 22.4751 10.6298 21.4698L19.8095 12.3125'
          stroke={props?.color || '#666565'}
          strokeWidth='2.03897'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default FileIconV2;
