import { HeaderListIdolV2 } from 'components';
import FooterListIdolV2 from 'components/FooterV2/FooterListIdolV2';
import PopupRegisterTWSuccessFan from 'components/Popup/registerTwSuccess';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import './signInWrapper.scss';

const SignUpTwSuccess = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
     <>
     <div className='block-div-signin' style={{ backgroundColor: 'transparent' }}>
       <HeaderListIdolV2 />
       <div className='container-div'>
       <PopupRegisterTWSuccessFan>
          <div
            className={`btn go-login w-100`}
            onClick={() => navigate('/sign-in')}
            style={{ cursor: 'pointer' }}>
            {t('Common.goToLogin')}
          </div>
        </PopupRegisterTWSuccessFan>
       </div>
       <FooterListIdolV2 />
     </div>
   </>
  );
};

export default SignUpTwSuccess;
