import { ImgCustom } from 'components';
import { newPathUser } from 'constants/layout/constant';
import {
  ChatIcon,
  CloseIcon,
  FlowUnFlowIcon,
  HeartActiveIcon,
  HeartIcon,
  IconBookmark,
  MuteIconAct,
  ShareIcon,
  ThreePointIcon,
  UnFlowIcon,
} from 'images';
import PlayTikTokIcon from 'images/PlayIconTiktok';
import MuteShortVideoIcon from 'images/muteShortVideo';
import { useNavigate } from 'react-router-dom';
import { ReadMoreNew } from './readmore';
import { isMobile } from 'react-device-detect';
import { formatNumber, isAdmin } from 'utils';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { getEnv } from 'configs/env';
import InfoBox from './infoBox';
import { useDispatch } from 'react-redux';
import { bookmarkPost, likePost } from 'store/actions/usersNew';
import { STORAGE } from 'utils';
import Storage from 'utils/storage';
import { logUserProfile } from 'store/actions/myPage';
import MarkDownRead from 'components/MarkDownRead';
const OptionsItem = ({
  item,
  index,
  toggleValueVideo,
  setShowPopup,
  handleFollowCreator,
  toggleFollowAllUser,
  setPopupCopyLink,
  isShowPopup,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const [isShowOtherBox, setIsShowOtherBox] = useState(false);
  const [hoverId, setHoverId] = useState(false);
  const [isBookMark, setIsBookMark] = useState(item?.is_bookmarked || 0);
  const widthScreen = window.innerWidth;

  const handleLikePost = (id) => {
    dispatch(likePost(id));
  };

  const handlePostBookMark = (post) => {
    dispatch(bookmarkPost(post?.id));
  };

  return (
    <>
      <div
        className='play-icon index10'
        onClick={(e) => {
          e.stopPropagation();
          toggleValueVideo(index);
        }}>
        {!item?.isPlay && <PlayTikTokIcon />}
      </div>
      {widthScreen < 1024 && !isShowPopup?.status && (
        <CloseIcon
          className='close-icon'
          onClick={() => {
            navigate(-1);
          }}
        />
      )}
      <div
        className='mute-icon index10000'
        onClick={() => {
          if (item?.type !== 1) {
            toggleValueVideo(index, 'isMute');
          }
        }}>
        {item?.isMute || item?.type === 1 ? <MuteShortVideoIcon /> : <MuteIconAct />}
      </div>
      <div className='block-info index20'>
        <div
          className={`avatar ${item?.isFullContent ? 'full-content' : ''}`}
          onClick={() => navigate(`${newPathUser}${item?.account_id}`)}>
          <ImgCustom
            isPlaceholderAvatar
            screen='avatar_44_44'
            src={item?.avatar}
            alt='avatar'
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className='name'>{item?.account_name}</span>
            {!(item?.follow_flag === 1 && item?.is_followed === 0) && (
              <div
                style={{
                  border: '1px solid #DC143C',
                  borderRadius: '20px',
                  alignSelf: 'flex-start',
                  padding: '0 5px',
                  marginLeft: '15px',
                  cursor: 'pointer',
                  fontSize: '12px',
                  fontWeight: '500',
                }}
                className={`btn-followOrUnFollow ${
                  item?.is_followed === 1 ? 'following' : 'no-follow'
                } ${item?.is_followed === 1 && hoverId === item?.id ? 'hover' : ''}`}
                onMouseOver={() => {
                  if (item?.is_followed === 1 && item?.id) {
                    !isMobile && setHoverId(item.id);
                  }
                }}
                onMouseDown={() => {
                  if (!isAuth) {
                    setIsShowOtherBox(false);
                    dispatch(logUserProfile(item));
                  } else {
                    handleFollowCreator(item?.user_id);
                    setIsShowOtherBox(false);
                    if (item?.follow_flag === 1) {
                      toggleFollowAllUser(index, item?.user_id, false, 0);
                    } else {
                      toggleFollowAllUser(
                        index,
                        item?.user_id,
                        true,
                        item?.is_followed === 1 ? 0 : 1
                      );
                    }
                  }
                }}
                onMouseLeave={() => {
                  if (item?.is_followed === 1) {
                    setHoverId(false);
                  }
                }}>
                {widthScreen > 991 ? (
                  item?.is_followed === 1
                  ? hoverId === item?.id
                    ? t('Creator.FollowList.unfollow')
                    : t('Creator.FollowList.following')
                  : t('Creator.FollowList.followUs')
                ) : (
                  item?.is_followed === 1
                    ? t('Creator.FollowList.following')
                  : t('Creator.FollowList.followUs')
                )}
              </div>
            )}
          </div>
        </div>
        <div className={`content-des ${item?.isFullContent ? 'full-content' : ''}`}>
          {/* <ReadMoreNew
            isFullContent={item?.isFullContent}
            setIsFullContent={() => {
              toggleValueVideo(index, 'isFullContent');
            }}
            content={item?.content}
            charLimit={isMobile ? 36 : 48}
          /> */}
          <MarkDownRead
            maxHeight={150}
            charLimit={isMobile ? 36 : 48}
            content={item?.content}
          />
          {/* {item?.isFullContent && (
            <div
              className='btn-read btn-read-less'
              onClick={() => {
                toggleValueVideo(index, 'isFullContent');
              }}>
              {t('ReadMoreLess.lessText')}
            </div>
          )} */}
        </div>
      </div>
      <div
        className={`group-icon ${isAdmin && 'disabled'} ${
          item?.is_followed === 0 && item?.follow_flag === 1 ? 'index10' : 'index25'
        }`}>
        <div className='icon-like'>
          <div
            className='bg-icon'
            onClick={() => {
              if (!isAuth) {
                dispatch(logUserProfile(item));
              } else {
                toggleValueVideo(index, 'is_liked', item?.is_liked === 1 ? 0 : 1);
                handleLikePost(item?.id);
              }
            }}>
            {item?.is_liked === 1 ? (
              <>
                <HeartActiveIcon className={`like-icon active`} />
              </>
            ) : (
              <>
                <HeartIcon className={`like-icon`} />
              </>
            )}
          </div>
          <p className='mb-0 cls-text' style={{ color: '#fff' }}>
            {formatNumber(item?.number_like)}
          </p>
        </div>
        <div
          className='icon-chat index10'
          onClick={() => {
            setShowPopup({
              status: true,
              dataItem: item,
              index: index,
            });
          }}>
          <div className='bg-icon'>
            <ChatIcon className='chat-icon' />
          </div>
          <p className='mb-0 cls-text' style={{ color: '#fff' }}>
            {formatNumber(item?.number_comment) || 0}
          </p>
        </div>
        <div className='bg-icon index10'>
          <ShareIcon
            onClick={() => {
              if (!isAuth) {
                dispatch(logUserProfile(item));
              } else {
                setPopupCopyLink({
                  link: `${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${item?.id}`,
                  title: item?.title,
                  id: item?.id,
                });
              }
            }}
            className='like-icon'
          />
        </div>
        <div
          style={{ margin: '21px 0px' }}
          className='bg-icon index10'
          onClick={() => {
            if (!isAuth) {
              dispatch(logUserProfile(item));
            } else {
              setIsBookMark(isBookMark === 1 ? 0 : 1);
              handlePostBookMark(item);
            }
          }}>
          <IconBookmark
            style={{
              color: isBookMark === 1 ? '#dc143c' : 'inherit',
            }}
            fill={isBookMark === 1 ? '#dc143c' : 'none'}
            className='cls-icon'
          />
        </div>
        <div
          className='bg-icon index10'
          onClick={() => {
            setIsShowOtherBox(true);
          }}>
          <ThreePointIcon style={{ color: '#1A1A1A' }} className='three-point-icon' />
        </div>
        {isShowOtherBox && (
          <InfoBox
            item={item}
            index={index}
            handleFollowCreator={handleFollowCreator}
            setIsShowOtherBox={setIsShowOtherBox}
            toggleValueVideo={toggleValueVideo}
            navigate={navigate}
            toggleFollowAllUser={toggleFollowAllUser}
            t={t}
          />
        )}
      </div>
    </>
  );
};

export default OptionsItem;
