import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useContext, useMemo, useState } from 'react';
import { LivestreamFanContext } from '../liveStream';
import { useSelector } from 'react-redux';
import EmojiBubble from 'components/LiveStream/Animation/Bubble';
import AutoExpire from 'components/LiveStream/Animation/AutoExpire';
import { WrapperLive } from 'pages/creator/layout/livestream/components/styled';
import { CopyValueView, GroupFeatureFan } from './styled';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { AgoraOBSPlayer } from 'components/LiveStream/V5/Videos/AgoraOBSPlayer';
import {
  LOGO_SHARE_GRAM,
  LOGO_THUMBNAIL_COCOFAN,
  NO_IMAGE_LIVESTREAM,
  decryptPath,
} from 'utils';
import { useParams } from 'react-router-dom';
import { EffectCreator } from 'components/LiveStream/V5/Videos/EffectCreator';
import { ImgCustom } from '../../../components';
import MaxScreenLiveIcon from 'images/livestreams/MaxScreenLiveIcon';
import UserCountIcon from 'images/UserCountIcon';
import { NumberViews } from 'components/LiveStream/NumberViews';
import FullScreenIOS, { isIOSSafari } from 'components/LiveStream/V5/Videos/FullscreenIOS';
import styles from 'components/LiveStream/V5/Videos/ios.module.css';
import ExitLiveIcon from 'images/livestreams/ExitIcon';
import ExitFullScreenIcon from 'images/livestreams/ExitFullScreenIcon';
import { BlueImgLive } from 'components/LiveStream/V5/Videos/styled';
import CopyLinkIcon from 'images/livestreams/CopyLinkIcon';
import { isMobile } from 'react-device-detect';

const useCopy = () => {
  const [copiedText, setCopiedText] = useState(null);
  const copy = async (text) => {
    if (!navigator?.clipboard) {
      return false;
    }
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      setCopiedText(null);
      return false;
    }
  };
  return [copiedText, copy];
};
export const Live = (props) => {
  const { pauseLive, tokenAgora, findChannel, isAuth = true } = props;
  const { userInfo } = useSelector((state) => state.lives);

  const handle = useFullScreenHandle();
  const { liveKey } = useParams();

  const {
    remainPoint,
    allowView,
    userQuit,
    checkHorizontalScreen,
    allowComment,
    creatorIsFollow,
    messageAllow,
    emojiQueue,
    handleEventFanQuitLive,
  } = useContext(LivestreamFanContext);
  // const { pauseLive } = useContext(ControlAgoraContext);
  const [value, copy] = useCopy();
  const [copySuccess, setCopySuccess] = useState(false);

  useEffect(() => {
    if (copySuccess) {
      const time = setTimeout(() => setCopySuccess(false), 1500);
      return () => {
        clearTimeout(time);
      };
    }
  }, [copySuccess]);
  const { t } = useTranslation();

  const backGroundBlur = useMemo(() => {
    const deImg = decryptPath(userInfo?.livestream?.img_background);
    if (deImg && deImg !== 'NULL') {
      return deImg;
    }
    return LOGO_THUMBNAIL_COCOFAN;
  }, [userInfo?.livestream?.img_background]);

  const stylePause = {
    position: 'absolute',
    top: '45%',
    color: '#fff',
    background: 'rgba(40, 39, 39, 0.6)',
    borderRadius: '12px',
    width: '204px',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    fontWeight: '400',
    zIndex: 5,
  };
  const UIMobieIOS = Boolean(isMobile && isIOSSafari(window)) ? (
    <>
      <FullScreenIOS classNames={styles} isOpen={props?.openIOS}>
        {props?.openIOS ? (
          <div
            style={{
              zIndex: 250,
              position: 'fixed',
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
            }}>
            <AgoraOBSPlayer
              allowView={allowView}
              allowComment={allowComment}
              creatorIsFollow={creatorIsFollow}
              messageAllow={messageAllow}
              remainPoint={remainPoint || 0}
              host={false}
              liveKey={liveKey}
              isAuth={userInfo?.is_auth}
              type=''
              view='fan'
              showButton=''
              eventUserQuit={userQuit}
              isMobileScreenHorizontal={''}
              checkHorizontalScreen={checkHorizontalScreen}
              token={decodeURIComponent(tokenAgora)}
              ViewAgoraFan={findChannel?.ViewLive}
            />
            <div
              style={{
                position: 'fixed',
                bottom: 24,
                right: 24,
                zIndex: 55,
                cursor: 'pointer',
              }}
              onClick={() => props.setOpenIOS(false)}>
              <ExitFullScreenIcon className='exit-full-screen' />
            </div>
            {pauseLive ? (
              <div className='loading-video-live'>
                {/* <Loading /> */}
                <p style={stylePause}>{t('LiveStream.V5.liveStopped')}</p>
                <ImgCustom
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'contain',
                    ...(props?.openIOS ? { position: 'absolute', top: 0, left: 0, zIndex: 1 } : {}),
                  }}
                  src={userInfo?.livestream?.img_background}
                  type=''
                  alt=''
                  onError={(e) => (e.target.src = NO_IMAGE_LIVESTREAM)}
                />
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          <>
            <AgoraOBSPlayer
              allowView={allowView}
              allowComment={allowComment}
              creatorIsFollow={creatorIsFollow}
              messageAllow={messageAllow}
              remainPoint={remainPoint || 0}
              host={false}
              liveKey={liveKey}
              isAuth={userInfo?.is_auth}
              type=''
              view='fan'
              showButton=''
              eventUserQuit={userQuit}
              isMobileScreenHorizontal={''}
              checkHorizontalScreen={checkHorizontalScreen}
              token={decodeURIComponent(tokenAgora)}
              ViewAgoraFan={findChannel?.ViewLive}
            />
          </>
        )}
        {pauseLive && (
          <div className='loading-video-live'>
            <BlueImgLive url={`${backGroundBlur}`}>
              <div className='bg-img'></div>
              <p className='text-pause-live'>{t('LiveStream.V5.liveStopped')}</p>
            </BlueImgLive>
          </div>
        )}
      </FullScreenIOS>
    </>
  ) : (
    <FullScreen handle={handle} className={'current-view'}>
      <AgoraOBSPlayer
        allowView={allowView}
        allowComment={allowComment}
        creatorIsFollow={creatorIsFollow}
        messageAllow={messageAllow}
        remainPoint={remainPoint || 0}
        host={false}
        liveKey={liveKey}
        isAuth={userInfo?.is_auth}
        type=''
        view='fan'
        showButton=''
        eventUserQuit={userQuit}
        isMobileScreenHorizontal={''}
        checkHorizontalScreen={checkHorizontalScreen}
        token={decodeURIComponent(tokenAgora)}
        ViewAgoraFan={findChannel?.ViewLive}
      />
      {pauseLive && (
        <div className='loading-video-live'>
          <BlueImgLive url={`${backGroundBlur}`}>
            <div className='bg-img'></div>
            <p className='text-pause-live'>{t('LiveStream.V5.liveStopped')}</p>
          </BlueImgLive>
        </div>
      )}
      {handle.active && (
        <div
          style={{
            position: 'fixed',
            bottom: 24,
            right: 24,
            zIndex: 999,
            cursor: 'pointer',
          }}
          onClick={handle.exit}>
          <ExitFullScreenIcon className='exit-full-screen' />
        </div>
      )}
    </FullScreen>
  );

  return (
    <WrapperLive h='100%' view='fan'>
      <EffectCreator />
      {allowView !== null && tokenAgora && UIMobieIOS}
      {isAuth && !props?.openIOS && (
        <GroupFeatureFan>
          <ExitLiveIcon onClick={handleEventFanQuitLive} />
          <CopyLinkIcon
            onClick={() => {
              copy(window.location.href);
              setCopySuccess(true);
            }}
          />
          {copySuccess && (
            <CopyValueView className='copy-success watch-live'>
              {t('LiveStream.V5.messageCopyLink')}
            </CopyValueView>
          )}
          <MaxScreenLiveIcon
            onClick={() => {
              isIOSSafari(window) ? props?.setOpenIOS(true) : handle.enter();
            }}
          />
        </GroupFeatureFan>
      )}

      <div className='count-user-watch'>
        <span>
          <UserCountIcon />
        </span>
        <span className='count'>{<NumberViews id={liveKey} />}</span>
      </div>
      <div className='logo-coco'>
        <img src={LOGO_SHARE_GRAM} height={32} width={143} alt='logo' />
      </div>
      {emojiQueue !== undefined && emojiQueue.length > 0 && (
        <div className='dancing-icon' style={{ display: 'flex', justifyContent: 'center' }}>
          {emojiQueue.map(({ id, label, symbol, size, left, one, two, color }) => (
            <AutoExpire key={id}>
              <EmojiBubble
                id={id}
                color={color}
                label={label}
                symbol={symbol}
                size={size}
                left={left}
                one={one}
                two={two}
              />
            </AutoExpire>
          ))}
        </div>
      )}
    </WrapperLive>
  );
};
