const IconFieldChanged = (props) => (
  <svg
    {...props}
    width={20}
    height={20}
    viewBox='0 0 20 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_11585_612270)'>
      <path
        d='M16.25 7.5C16.0014 7.5 15.7629 7.40123 15.5871 7.22541C15.4113 7.0496 15.3125 6.81114 15.3125 6.5625C15.3125 6.06522 15.115 5.58831 14.7633 5.23667C14.4117 4.88504 13.9348 4.6875 13.4375 4.6875C13.1889 4.6875 12.9504 4.58873 12.7746 4.41291C12.5988 4.2371 12.5 3.99864 12.5 3.75C12.5 3.50136 12.5988 3.2629 12.7746 3.08709C12.9504 2.91127 13.1889 2.8125 13.4375 2.8125C13.9348 2.8125 14.4117 2.61496 14.7633 2.26333C15.115 1.91169 15.3125 1.43478 15.3125 0.9375C15.3125 0.68886 15.4113 0.450403 15.5871 0.274587C15.7629 0.098772 16.0014 0 16.25 0C16.4986 0 16.7371 0.098772 16.9129 0.274587C17.0887 0.450403 17.1875 0.68886 17.1875 0.9375C17.1875 1.43478 17.385 1.91169 17.7367 2.26333C18.0883 2.61496 18.5652 2.8125 19.0625 2.8125C19.3111 2.8125 19.5496 2.91127 19.7254 3.08709C19.9012 3.2629 20 3.50136 20 3.75C20 3.99864 19.9012 4.2371 19.7254 4.41291C19.5496 4.58873 19.3111 4.6875 19.0625 4.6875C18.5652 4.6875 18.0883 4.88504 17.7367 5.23667C17.385 5.58831 17.1875 6.06522 17.1875 6.5625C17.1875 6.81114 17.0887 7.0496 16.9129 7.22541C16.7371 7.40123 16.4986 7.5 16.25 7.5Z'
        fill='white'
      />
      <path
        d='M7.5 20C7.16848 20 6.85054 19.8683 6.61612 19.6339C6.3817 19.3995 6.25 19.0815 6.25 18.75C6.25 17.4239 5.72322 16.1521 4.78553 15.2145C3.84785 14.2768 2.57608 13.75 1.25 13.75C0.918479 13.75 0.600537 13.6183 0.366117 13.3839C0.131696 13.1495 0 12.8315 0 12.5C0 12.1685 0.131696 11.8505 0.366117 11.6161C0.600537 11.3817 0.918479 11.25 1.25 11.25C2.57608 11.25 3.84785 10.7232 4.78553 9.78553C5.72322 8.84785 6.25 7.57608 6.25 6.25C6.25 5.91848 6.3817 5.60054 6.61612 5.36612C6.85054 5.1317 7.16848 5 7.5 5C7.83152 5 8.14946 5.1317 8.38388 5.36612C8.6183 5.60054 8.75 5.91848 8.75 6.25C8.75 7.57608 9.27678 8.84785 10.2145 9.78553C11.1521 10.7232 12.4239 11.25 13.75 11.25C14.0815 11.25 14.3995 11.3817 14.6339 11.6161C14.8683 11.8505 15 12.1685 15 12.5C15 12.8315 14.8683 13.1495 14.6339 13.3839C14.3995 13.6183 14.0815 13.75 13.75 13.75C12.4239 13.75 11.1521 14.2768 10.2145 15.2145C9.27678 16.1521 8.75 17.4239 8.75 18.75C8.75 19.0815 8.6183 19.3995 8.38388 19.6339C8.14946 19.8683 7.83152 20 7.5 20Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_11585_612270'>
        <rect width={20} height={20} fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconFieldChanged;
