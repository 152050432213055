import styled from 'styled-components';

export const StyledPostDetail = {
  Wrapper: styled.div`
    position: relative;
    .content {
      margin: 0;
      padding: 0 16px 16px 16px;
    }
  `,
  WrapperBlur: styled.div`
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    z-index: 12;
  `,
  VideoWrapper: styled.div`
    position: relative;
    /* background-color: #000; */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    video {
      width: 100%;
      position: relative;
      aspect-ratio: 2/3;
    }
  `,
  TimelineWrapper: styled.div`
    position: relative;
    /* background-color: #000; */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    img {
      width: 100%;
      position: relative;
      aspect-ratio: 2/3;
    }
  `,
};
