import Icon from '@ant-design/icons';

const IconArrowLeftPaginate = (props) => (
  <Icon
    component={() => (
      <svg
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M11.2761 14.25L6.40319 9L11.2761 3.75'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default IconArrowLeftPaginate;
