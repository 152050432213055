import {
  AvatarInfoCreator,
  ImgCustom,
  LayoutCreatorRight,
  ListComment,
  ListImages,
  ListImgTimelinePostDetail,
  PopupConfirmFan,
  PopupLoading,
  PopupSharePost,
  Textarea,
  VideoCustom,
  ViewFullImage,
} from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import { newPathUser } from 'constants/layout/constant';
import withCreatorRole from 'hocs/withCreatorRole';
import useRedirect from 'hooks/useRedirect';
import { SendChatIcon } from 'images';
import Avatar from 'images/Avatar.png';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  creatorGetPostDetail,
  deleteCommentDetail,
  deleteReplyCommentDetail,
  postCommentDetail,
  postFollowCommentDetail,
  postFollowReplyCommentDetail,
  postLikeDetail,
} from 'store/actions/myPage';
import { IMG_NO_WATER_MARK, isAdmin, linkS3Video, decryptPath } from 'utils';
import GroupLikeChatShared from '../components/GroupLikeChatShared';
import '../detail.scss';
import { Styled } from './styles';
import { updateLikeReplyComment, updateLikeCommentDetail } from 'store/actions/usersNew';

const PostShortDetail = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { detailPost, loading, loadingAction, error, statusPostComment } = useSelector(
    (state) => state.myPage
  );
  const dataProfile = useSelector((state) => state.users.data);
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const [popupDeleteComment, setPopupDeleteComment] = useState(null);
  const [checkSendChat, setCheckSendChat] = useState(null);
  const [valueComment, setValueComment] = useState('');
  const { id } = useParams();
  const [dataViewFull, setDataViewFull] = useState({});

  const handleSharePost = (dataPC) => {
    setPopupCopyLink(dataPC);
  };

  const handleTextarea = (e, id, height) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    setValueComment(e.target.value);
    setCheckSendChat(id);
    if (e.target.value?.toString().trim() === '') {
      e.target.style.height = height;
      e.target.style.padding = '9px  30px  9px 12px';
      setCheckSendChat(null);
    }
  };

  // ESC FUNC
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setCheckSendChat(null);
      setValueComment('');
      const textareaId = document.getElementById('textarea-post-comment');
      if (textareaId) textareaId.style.height = '38px';
    }
  }, []);

  useRedirect(error);
  // START FUNC ESC
  useEffect(() => {
    if (statusPostComment) {
      setCheckSendChat(null);
      setValueComment('');
      const textareaId = document.getElementById('textarea-post-comment');
      if (textareaId) textareaId.style.height = '38px';
    }
    document.addEventListener('keydown', escFunction);
    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction, statusPostComment]);

  useEffect(() => {
    dispatch(creatorGetPostDetail(id, false));
  }, []);

  //API LIKE POST
  const handleLikePost = (id) => {
    dispatch(postLikeDetail(id));
  };

  //API LIKE COMMENT
  const handleLikeComment = (data) => {
    const { commentId, type, dataPost } = data;
    type === 'comment'
      ? dispatch(updateLikeCommentDetail(commentId, dataPost))
      : dispatch(updateLikeReplyComment(commentId, dataPost));
  };

  // API POST/EDIT COMMENT
  const handlePostComment = (id, parent_id) => {
    const data = {
      parent_id: parent_id,
      content: valueComment,
    };
    checkSendChat && dispatch(postCommentDetail(id, data));
  };

  // DELETE
  const handleDeleteComment = () => {
    const { id, type, idParent } = popupDeleteComment;
    type === 'comment'
      ? dispatch(deleteCommentDetail(id))
      : dispatch(deleteReplyCommentDetail(id, idParent));
    setPopupDeleteComment(false);
  };

  // FOLLOW USER IN COMMENT
  const handleFollowUserComment = (data) => {
    const { id, type } = data;
    type === 'comment'
      ? dispatch(postFollowCommentDetail(id))
      : dispatch(postFollowReplyCommentDetail(id));
  };

  const listImgFunc = (data, item, type) =>
    type === 3 ? (
      <ListImages
        images={data}
        id_post={detailPost?.id}
        className='custom-creator'
        is_idol={true}
        onClickImage={setDataViewFull}
        isPostDetail
      />
    ) : (
      <div className='list-img-timeline'>
        <ListImgTimelinePostDetail data={data} key={item?.id} />
      </div>
    );
  return (
    <>
      {popupCopyLink && (
        <PopupSharePost
          isVisiblePopup={() => setPopupCopyLink(false)}
          data={popupCopyLink || {}}
        />
      )}
      {popupDeleteComment && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupDeleteComment(false)}
          colorSvg='#FF9F43'
          text={t('Popup.confirmDeleteComment')}
          className='popup-delete'>
          <div className='btn cancel' onClick={() => setPopupDeleteComment(false)}>
            {t('Common.buttonCancel')}
          </div>
          <div className='ok btn' onClick={handleDeleteComment}>
            {t('Common.buttonDelete')}
          </div>
        </PopupConfirmFan>
      )}
      {dataViewFull?.image?.url && (
        <ViewFullImage dataViewFull={dataViewFull} close={() => setDataViewFull({})} />
      )}
      {loading && <PopupLoading className={'popup-loading'} />}
      {detailPost && (
        <LayoutCreatorRight
          titlePage={t(
            `Creator.PostArticle.postShort.${
              detailPost?.type === 3 ? 'titleDetailNormal' : 'titleDetailShort'
            }`
          )}>
          <div className='fansite-creator-post-detail creator-post-normal-detail creator-post-short-detail'>
            <div className='account block d-flex justify-content-between'>
              <AvatarInfoCreator
                name={detailPost?.user?.account_name}
                content2={`${t('Common.id')}: ${detailPost?.user?.account_id}`}
                className='account__creator'
                type={`${
                  (dataProfile?.account_id || dataProfile?.id) ===
                    (detailPost?.user?.account_id || detailPost?.user?.id) && 'edit'
                }`}
                images={detailPost?.user?.avatar || detailPost?.user?.avatar}
                link={`${newPathUser}post/update/${detailPost?.id}`}
              />
            </div>

            <div className='content block'>
              <div className='text display-linebreak' style={{ display: 'inline-block' }}>
                {detailPost?.content}
              </div>
              <Styled.Wrapper>
                {detailPost?.user_follow_status === 0 &&
                  detailPost?.follow_flag === 1 &&
                  dataProfile?.account_id !== detailPost?.user?.account_id &&
                  !isAdmin && (
                    <Styled.WrapperBlur>{t('DetailCard.notYetFollow')}</Styled.WrapperBlur>
                  )}
                {detailPost?.medias?.length > 0 && (
                  <>
                    {detailPost?.medias?.[0].type === 1 ? (
                      <>
                        {(() => {
                          const data = detailPost?.medias
                            ?.filter((item) => item?.type === 1)
                            .filter((item) => item?.public_flag === 1);
                          return (
                            data.length > 0 && (
                              <>{listImgFunc(data, detailPost, detailPost?.type)}</>
                            )
                          );
                        })()}
                      </>
                    ) : (
                      <>
                        {detailPost.medias[0].public_flag === 1 ? (
                          <VideoCustom
                            src={`${linkS3Video}${
                              decryptPath(detailPost.medias[0].url) + '#t=0.1'
                            }`}
                            handleNavigate={() => {}}
                            isDetailPost
                          />
                        ) : (
                          <ImgCustom
                            src={IMG_NO_WATER_MARK}
                            className='video-detail trailer_video img_no_water_mark'
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </Styled.Wrapper>
            </div>

            {isAdmin ? (
              <>
                <GroupLikeChatShared detailPost={detailPost} loadingAction={loadingAction} />
                {detailPost?.enable_comment === 1 && (
                  <ListComment
                    dataPost={detailPost}
                    handleDeleteComment={(e) => {
                      setPopupDeleteComment(e);
                    }}
                    handleLikeComment={handleLikeComment}
                    dataProfile={dataProfile}
                    handleFollowUserComment={handleFollowUserComment}
                  />
                )}
              </>
            ) : (
              <>
                {(dataProfile?.user_type === 5
                  ? dataProfile?.account_id === detailPost?.user?.account_id
                  : false) && (
                  <>
                    <GroupLikeChatShared
                      detailPost={detailPost}
                      loadingAction={loadingAction}
                      handleLikePost={(e) => handleLikePost(e)}
                      handleSharePost={(e) => handleSharePost(e)}
                    />
                    {detailPost?.enable_comment === 1 && (
                      <>
                        <div className={`comment block`}>
                          <ImgCustom
                            src={dataProfile?.avatar ? `${dataProfile.avatar}` : Avatar}
                          />
                          <form onSubmit={(e) => e.preventDefault()}>
                            <InputWrapper id='fsite-comment' className='form-item'>
                              <Textarea
                                onChange={(e) => handleTextarea(e, detailPost?.id, '38px')}
                                placeholder={t('comment.formAddRepComment.placeholder')}
                                onKeyDown={(e) => handleTextarea(e, detailPost?.id, '38px')}
                                value={valueComment}
                                maxLength='500'
                                id='textarea-post-comment'
                                overflowHidden
                              />
                              <SendChatIcon
                                className={`${valueComment !== '' && 'active'}`}
                                onClick={() =>
                                  valueComment !== '' && handlePostComment(detailPost?.id, 0)
                                }
                              />
                            </InputWrapper>
                          </form>
                        </div>
                        <ListComment
                          dataPost={detailPost}
                          handleDeleteComment={(e) => {
                            setPopupDeleteComment(e);
                          }}
                          handleLikeComment={handleLikeComment}
                          dataProfile={dataProfile}
                          handleFollowUserComment={handleFollowUserComment}
                        />
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </LayoutCreatorRight>
      )}
    </>
  );
};

export default withCreatorRole(PostShortDetail);
