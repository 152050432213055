import React from 'react';
import './index.scss';

const TableCustom = ({ children, className, ...props }) => {
  return (
    <div className={`table-container scroll-bar-custom-horizontal ${className}`} {...props}>
      <table className='table table-bordered'>{children}</table>
    </div>
  );
};

export default TableCustom;
