import React from 'react';

const EmojiWrapper = ({ className, label, color, id }) => {
  return (
    <span
      id={id}
      role='img'
      aria-label={label ? label : ''}
      aria-hidden={label ? 'false' : 'true'}
      className={className}>
      <svg
        width='32'
        height='32'
        viewBox='0 0 32 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M5.50039 7.50048C8.99958 4 14.9997 6.50002 16.4997 9.50002C18.9997 5.00002 26.9997 3.5 28.5004 10.5005C30.3096 18.9403 21.9996 24 16.4996 28C10.4996 23.5 -1.36305 14.3665 5.50039 7.50048Z'
          fill={`${color}`}
          stroke={`${color}`}
        />
      </svg>
    </span>
  );
};

export default EmojiWrapper;
