import Icon from '@ant-design/icons';

const MessagesList = (props) => (
  <Icon
    component={() => (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M3.54912 13.8279C2.6173 12.2571 2.29097 10.4001 2.6314 8.60571C2.97184 6.8113 3.95563 5.20288 5.39803 4.08249C6.84044 2.9621 8.64224 2.4068 10.4651 2.52087C12.2879 2.63494 14.0065 3.41053 15.2979 4.702C16.5894 5.99347 17.365 7.71198 17.4791 9.53484C17.5932 11.3577 17.0379 13.1595 15.9175 14.6019C14.7971 16.0443 13.1887 17.0281 11.3943 17.3686C9.59988 17.709 7.74291 17.3827 6.17207 16.4509L6.17209 16.4508L3.58173 17.1909C3.47456 17.2215 3.36115 17.2229 3.25325 17.195C3.14536 17.167 3.0469 17.1107 2.96809 17.0319C2.88927 16.9531 2.83297 16.8546 2.80501 16.7467C2.77705 16.6388 2.77846 16.5254 2.80908 16.4182L3.54918 13.8279L3.54912 13.8279Z'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.5 8.75H12.5'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.5 11.25H12.5'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default MessagesList;
