import useLockBodyScroll from '../../hooks/useLockBodyScroll';

export const PopupDenyFanView = ({
  closeModal,
  title,
  className,
  generalClass = '',
  ...props
}) => {
  useLockBodyScroll();
  return (
    <div className={`popup popup-general ${className}`}>
      <div
        style={
          className !== undefined
            ? {
                height: '300px',
                zIndex: 101,
                opacity: '95%',
                borderRadius: '19px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 16,
              }
            : {
                height: '220px',
                zIndex: 101,
                opacity: '95%',
                borderRadius: '19px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }
        }
        className='popup-content'>
        {props.children}
      </div>
    </div>
  );
};
