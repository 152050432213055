import { yupResolver } from '@hookform/resolvers/yup';
import { LayoutCreatorRight } from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import { getEnv } from 'configs/env';
import { newPathUser } from 'constants/layout/constant';
import LoadingIconV2 from 'images/LoadingIconV2';
import JCBIcon from 'images/payment/JCBIcon';
import MasterCardIcon from 'images/payment/MasterCardIcon';
import VisaIcon from 'images/payment/VisaIcon';
import * as jose from 'jose';
import { useCallback, useEffect, useMemo, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { clearScrollIdAndData, createCard, getCard, updateCard } from 'store/actions/card';
import { convertPhone, decodeToken, isEmptyObject, patternSplitCard } from 'utils';
import { validJCBCard, validMasterCard, validVisaCard } from 'utils/regex';
import './index.scss';
import { schema } from './schema';
import { getUserInfo } from 'store/actions/users';

export const CreateAndUpdateCard = ({ isEdit }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state: stateCardEdit } = useLocation();

  const { data: user, loading } = useSelector((state) => state.users);

  const privateKey = getEnv('REACT_APP_PAYMENT_SECRET');
  const secret = new TextEncoder().encode(privateKey);

  const [prevCardNum, setPrevCardNum] = useState('');
  const [isLoadingGetCard, setIsLoadingCard] = useState(isEdit ? true : false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm({ mode: 'all', defaultValues: {}, resolver: yupResolver(schema(t)) });

  const isUpdate = useMemo(() => {
    return +id;
  }, [id]);

  const valueCard = useMemo(() => {
    return {
      MASTER_CARD: {
        type: 'MASTER_CARD',
        icon: MasterCardIcon,
      },
      VISA: {
        type: 'VISA',
        icon: VisaIcon,
      },
      JCB: {
        type: 'JCB',
        icon: JCBIcon,
      },
    };
  }, []);

  const disableSubmit = (errors) => {
    return !isEmptyObject(errors);
  };

  const watchCardNumber = watch('cardNumber');
  const watchMethod = watch('method');

  const handleGetCardSuccess = useCallback(
    async (rs) => {
      const dataCard = await decodeToken(rs?.data?.data);
      if (dataCard) {
        setValue('nameCard', dataCard?.card_holder_name);
        setValue('method', dataCard?.card_type);
        setValue('cardNumber', dataCard?.card_number?.replace(patternSplitCard, '$1 '));
        setPrevCardNum(dataCard?.card_number);
        setValue(
          'dateExpiry',
          `${dataCard?.card_expired_month}${dataCard?.card_expired_year}`
        );
        setValue('securityCode', dataCard?.card_cvv);
        setValue('surName', dataCard?.first_name);
        setValue('givenName', dataCard?.last_name);
        setValue('phone', convertPhone(dataCard?.phone));
      }

      setIsLoadingCard(false);
    },
    [setValue]
  );

  const getCardByID = useCallback(() => {
    if (id) {
      if (stateCardEdit?.data) {
        handleGetCardSuccess({
          data: stateCardEdit,
        });
      } else {
        dispatch(getCard(id, handleGetCardSuccess));
      }
    }
  }, [dispatch, handleGetCardSuccess, id, stateCardEdit]);

  useEffect(() => {
    getCardByID();
  }, [getCardByID]);

  useEffect(() => {
    if (!isUpdate) {
      dispatch(getUserInfo());
    }
  }, [dispatch, isUpdate]);

  useEffect(() => {
    if (user && !isUpdate) {
      const { email, first_name, last_name, phone } = user;
      setValue('surName', last_name);
      setValue('givenName', first_name);
      setValue('mail', email);
      setValue('phone', convertPhone(phone));
    }
  }, [setValue, user, isUpdate]);

  useEffect(() => {
    if (watchCardNumber) {
      const cardNum = watchCardNumber?.replace(/\s/g, '');
      if (validMasterCard.test(cardNum)) {
        setValue('method', 'MASTER_CARD');
      } else if (validVisaCard.test(cardNum)) {
        setValue('method', 'VISA');
      } else if (validJCBCard.test(cardNum)) {
        setValue('method', 'JCB');
      } else {
        setValue('method', null);
      }
    } else {
      setValue('method', null);
    }
  }, [setValue, watchCardNumber]);

  const handleOnChangeCard = (val, field) => {
    const nameCleaned = val.replace(/\s/g, '');
    const valSpace = val.split('');
    if (valSpace[valSpace.length - 1] === ' ' || nameCleaned?.length <= prevCardNum?.length) {
      if (valSpace[valSpace.length - 1] === ' ') {
        var valSpaceN = valSpace.slice(0, -1);
        val = valSpaceN.join('');
      }
      setPrevCardNum(val.replace(/\s/g, ''));
      field.onChange(val);
      return;
    } else {
      if (nameCleaned?.length <= 16) {
        const textConvert = val.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
        setPrevCardNum(val.replace(/\s/g, ''));
        field.onChange(textConvert);
      }
    }
  };

  const IconType = useMemo(() => {
    if (watchMethod) {
      return valueCard[watchMethod]?.icon;
    }
    return null;
  }, [valueCard, watchMethod]);

  const handleSubmitSuccess = (data) => {
    if (data) {
      navigate(`${newPathUser}card-management`);
    }
    setIsLoadingSubmit(false);
  };
  const handleCheckSubmit = async (data) => {
    const dataSign = {
      card_holder_name: data?.nameCard,
      card_number: data?.cardNumber?.replace(/\s/g, ''),
      card_expired_month: data?.dateExpiry?.slice(0, 2),
      card_expired_year: data?.dateExpiry?.slice(2, 6),
      card_cvv: data?.securityCode,
      card_type: data?.method,
      first_name: data.givenName,
      last_name: data.surName,
      phone: ('' + data?.phone).replace(/\D/g, ''),
    };
    const jwt = await new jose.SignJWT(dataSign)
      .setProtectedHeader({ alg: 'HS256' })
      .sign(secret);

    if (jwt) {
      setIsLoadingSubmit(true);
      if (isUpdate) {
        dispatch(
          updateCard(
            id,
            {
              data: jwt,
            },
            () => dispatch(clearScrollIdAndData(handleSubmitSuccess))
          )
        );
      } else {
        dispatch(
          createCard(
            {
              data: jwt,
            },
            () => dispatch(clearScrollIdAndData(handleSubmitSuccess))
          )
        );
      }
    }
  };

  return (
    <LayoutCreatorRight
      titlePage={`${
        isUpdate
          ? t('Creator.cardManagement.titleUpdateCard')
          : t('Creator.cardManagement.titleAddCard')
      } `}
      className='create-and-update-card-layout'>
      {isLoadingSubmit && (
        <div className='layer-spinner'>
          <LoadingIconV2 className='spinner-icon' />
        </div>
      )}
      {isLoadingGetCard || loading ? (
        <div className='shimmer-loading'>
          <ShimmerPostDetails hasImage imageType='circular' title />
        </div>
      ) : (
        <form className='form-card' onSubmit={(e) => e.preventDefault()}>
          <div className='block-item'>
            <InputWrapper
              id='fsite-content'
              label={t('Creator.cardManagement.nameHolder')}
              className='item'
              required
              error={errors?.nameCard ? errors?.nameCard?.message : ''}>
              <input
                {...register('nameCard', {
                  required: t('ValidateMsg.contentMessagesRequired'),
                })}
                autocomplete='off'
                placeholder={t('Creator.cardManagement.placeholderNameHolder')}
                maxLength={500}
                className='item-input'
              />
            </InputWrapper>
            <div className='item psr'>
              <InputWrapper
                id='fsite-content'
                label={t('Creator.cardManagement.cardNumber')}
                required
                error={errors?.cardNumber ? errors?.cardNumber?.message : ''}>
                <Controller
                  name='cardNumber'
                  control={control}
                  rules={{ required: t('ValidateMsg.C101_VALIDATE_TYPE_MESSAGE') }}
                  render={({ field }) => (
                    <input
                      type='text'
                      placeholder='1234  5678  9101  1121'
                      className='item-input'
                      value={field?.value}
                      {...register('cardNumber', {
                        required: t('ValidateMsg.CARD_NUMBER_REQUIRED'),
                      })}
                      onChange={(e) => {
                        var val = e.target.value;
                        if (/^[0-9]*$/.test(val?.replace(/\s/g, ''))) {
                          const textConvert = val
                            .replace(/\W/gi, '')
                            .replace(/(.{4})/g, '$1 ');
                          handleOnChangeCard(textConvert, field);
                        }
                      }}
                    />
                  )}
                />
              </InputWrapper>
              {IconType && (
                <span className={`icon-card ${watchMethod === 'JCB' ? 'jcbCard' : ''}`}>
                  <IconType height={16} />
                </span>
              )}
            </div>
          </div>
          <div className='block-item'>
            <InputWrapper
              id='fsite-content'
              className='item'
              required
              label={t('Creator.cardManagement.dateExpiry')}
              error={errors?.dateExpiry ? errors?.dateExpiry?.message : ''}>
              <Controller
                name='dateExpiry'
                control={control}
                rules={{ required: t('ValidateMsg.C101_VALIDATE_TYPE_MESSAGE') }}
                render={({ field }) => (
                  <CurrencyFormat
                    {...register('dateExpiry', {
                      required: t('ValidateMsg.contentMessagesRequired'),
                    })}
                    value={field?.value}
                    onValueChange={(value) => {
                      setValue('dateExpiry', value?.value);
                    }}
                    format='##/####'
                    placeholder='MM/YYYY'
                    mask={['M', 'M', 'Y', 'Y', 'Y', 'Y']}
                    className='item-input'
                  />
                )}
              />
            </InputWrapper>
            <InputWrapper
              id='fsite-content'
              className='item'
              required
              notification={true}
              notificationContent={t('Creator.cardManagement.infoCCV')}
              label={t('Creator.cardManagement.securityCode')}
              error={errors?.securityCode ? errors?.securityCode?.message : ''}>
              <Controller
                name='securityCode'
                control={control}
                rules={{ required: t('ValidateMsg.C101_VALIDATE_TYPE_MESSAGE') }}
                render={({ field }) => (
                  <CurrencyFormat
                    {...register('securityCode', {
                      required: t('ValidateMsg.contentMessagesRequired'),
                    })}
                    value={field?.value}
                    onValueChange={(value) => {
                      setValue('securityCode', value?.value);
                    }}
                    format='####'
                    placeholder='456'
                    className='item-input'
                  />
                )}
              />
            </InputWrapper>
          </div>
          <div className='block-item'>
            <InputWrapper
              id='fsite-content'
              label={t('Creator.cardManagement.surName')}
              className='item'
              required
              error={errors?.surName ? errors?.surName?.message : ''}>
              <input
                {...register('surName', {
                  required: t('ValidateMsg.contentMessagesRequired'),
                })}
                placeholder='Yamada'
                maxLength={500}
                className='item-input'
              />
            </InputWrapper>
            <InputWrapper
              id='fsite-content'
              className='item'
              required
              label={t('Creator.cardManagement.givenName')}
              error={errors?.givenName ? errors?.givenName?.message : ''}>
              <input
                {...register('givenName', {
                  required: t('ValidateMsg.contentMessagesRequired'),
                })}
                placeholder='Tarou'
                maxLength={500}
                className='item-input'
              />
            </InputWrapper>
          </div>
          <div className='block-item block-item_50 mb-8'>
            <InputWrapper
              id='fsite-content'
              className='item'
              required
              label={t('Creator.cardManagement.phone')}
              error={errors?.phone ? errors?.phone?.message : ''}>
              <input
                {...register('phone', {
                  required: t('ValidateMsg.contentMessagesRequired'),
                  validate: (value) => {
                    const cleaned = ('' + value).replace(/\D/g, '');
                    const match10 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
                    const match11 = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
                    const match12 = cleaned.match(/^(\d{4})(\d{4})(\d{4})$/);

                    if (value) {
                      if (
                        (!match10 && !match11 && !match12) ||
                        (match12 && match12[1] !== '0120') ||
                        cleaned.substring(0, 1) !== '0'
                      ) {
                        return t('ValidateMsg.C002_VALIDATE_PHONE_FORMAT');
                      }
                    }
                  },
                  onChange: (e) => {
                    const value = e.target.value;
                    const cleaned = ('' + value).replace(/\D/g, '');
                    const match10 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);

                    if (match10) {
                      e.target.value = match10[1] + '-' + match10[2] + '-' + match10[3];
                    }

                    const match11 = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
                    if (match11) {
                      e.target.value = match11[1] + '-' + match11[2] + '-' + match11[3];
                    }

                    const match12 = cleaned.match(/^(\d{4})(\d{4})(\d{4})$/);
                    if (match12) {
                      if (match12[1] === '0120') {
                        e.target.value = '0120-' + match12[2] + '-' + match12[3];
                      } else {
                        e.target.value = match12[1] + '-' + match12[2] + '-' + match12[3];
                      }
                    }
                  },
                })}
                placeholder='XX-XXXX-XXXX・0X-XXXX-XXXX・0120-XXXX-XXXX'
                maxLength={14}
                className='item-input'
              />
            </InputWrapper>
            <div className='holder'></div>
          </div>
          <div className='block-item sub-col'>
            <button
              disabled={disableSubmit(errors)}
              className='btn btn-submit'
              onClick={handleSubmit(handleCheckSubmit)}>
              {isUpdate
                ? t('Creator.cardManagement.update')
                : t('Creator.cardManagement.addition')}
            </button>
            <button
              className='btn btn-cancel'
              onClick={() => {
                navigate(-1);
              }}>
              {t('Common.return')}
            </button>
          </div>
        </form>
      )}
    </LayoutCreatorRight>
  );
};
