import {
  ImgCustom,
  LayoutFan,
  PopupDeleteMessage,
  SendCoinPopup,
  ViewFullImage,
} from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import PopupConfirmDelete from 'components/PopupConfirmDelete';
import { newPathUser } from 'constants/layout/constant';
import { ArrowIcon, CoinIcon, FileIcon, SendChatIcon } from 'images';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  userDeleteMessage,
  userGetMessageDetail,
  userSendMessage,
} from 'store/actions/message';
import { getUserInfo } from 'store/actions/users';
import { AVATAR_DEFAULT, LOGO_COCO_FANS } from 'utils/constant';
import { formatCoin, decryptPath, linkS3Video, isAdmin as IS_ADMIN } from 'utils/utils';
import ButtonSubmit from '../../components/Button/ButtonSubmit';
import { ADMIN_TYPE, LIST_POINT_ICON_DONATE } from '../../utils/constant';
import './message.scss';

const MessageDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [dataMessages, setDataMessage] = useState({});
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [messageDelete, setMessageDelete] = useState({});
  const [loading, setLoading] = useState(false);
  const [isVisibleSendCoinPopup, setIsVisibleSendCoinPopup] = useState(false);
  const { data: dataProfile } = useSelector((state) => state.users);
  const isAdmin = ADMIN_TYPE.includes(dataMessages?.to_user?.user_type);
  const [dataViewFull, setDataViewFull] = useState({});

  useEffect(() => {
    setLoading(true);
    dispatch(
      userGetMessageDetail(
        {
          id,
          page,
          limit: 30,
        },
        handleSetDataMessage
      )
    );
  }, [page]);

  const fetchNext = () => {
    setPage(page + 1);
  };

  const handleSetDataMessage = ({ success, ...data }) => {
    if (success) {
      if (page === 1) {
        setDataMessage(data);
        // window.scrollTo(0, document.body.scrollHeight);
      } else {
        setDataMessage({
          ...data,
          data: [...dataMessages?.data, ...data.data],
        });
      }
    }
    setLoading(false);
  };

  const handleSendCoin = (coin, iconChoice) => {
    setIsVisibleSendCoinPopup(false);
    const newMess = {
      ...dataMessages?.data?.[0],
      message: {
        content: coin?.point,
        type: 5,
      },
      id: dataMessages?.data?.[0]?.id + 1,
      from_user_id: dataProfile?.id,
      point: {
        icon: iconChoice?.name,
        point: coin?.point,
      },
    };
    setDataMessage({
      ...dataMessages,
      data: [newMess, ...dataMessages?.data],
    });
    dispatch(getUserInfo());
    // window.scrollTo(0, document.body.scrollHeight);
  };

  const { register, handleSubmit, watch, reset } = useForm();
  const watchText = watch('text', '');

  const handleKeyDown = (e) => {
    var code = e.keyCode || e.charCode;

    if ([8, 13, 46].includes(code) && e.target.offsetHeight < 150) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    if (e.target.value === '') {
      e.target.style.height = '82px';
    }
    // window.scrollTo(0, document.body.scrollHeight);
  };

  const handleSendMessageSuccess = ({ data: newMessage, success }) => {
    if (success) {
      dispatch(
        userGetMessageDetail(
          {
            id,
            page: 1,
            limit: 30,
          },
          handleSetDataMessage
        )
      );
      reset();
    }
    setLoading(false);
    // window.scrollTo(0, document.body.scrollHeight);
  };

  const handleDeleteMessageSuccess = ({ success }) => {
    if (success) {
      const newMessage = [...dataMessages?.data];
      newMessage?.splice(messageDelete?.index, 1);
      setDataMessage({
        ...dataMessages,
        data: newMessage,
      });
    }
    setLoading(false);
    setMessageDelete({});
    setShowPopupConfirm(false);
  };

  const onSubmit = (data) => {
    setLoading(true);
    dispatch(
      userSendMessage(
        {
          to_user_id: id,
          type: 1,
          content: data.text,
        },
        handleSendMessageSuccess
      )
    );
  };

  const handleDeleteMessage = () => {
    setLoading(true);
    dispatch(userDeleteMessage(messageDelete?.id, handleDeleteMessageSuccess));
  };

  const renderDeleteMess = (id, index) => {
    return (
      <PopupDeleteMessage
        id={id}
        index={index}
        setShowPopupConfirm={setShowPopupConfirm}
        setMessageDelete={setMessageDelete}
      />
    );
  };

  const avatar = isAdmin
    ? LOGO_COCO_FANS
    : dataMessages?.to_user?.avatar
    ? `${dataMessages?.to_user?.avatar}`
    : AVATAR_DEFAULT;

  const renderAvatarFan = () => {
    return (
      <ImgCustom
        src={avatar}
        alt='avatar-fan'
        className='avatar-user'
        onClick={() => {
          !isAdmin && navigate(`${newPathUser}${dataMessages?.to_user?.account_id}`);
        }}
      />
    );
  };

  const renderTime = (time) => (
    <div className='time'>{moment.utc(time).local().format('YYYY/MM/DD, HH:mm')}</div>
  );

  const renderMessage = (messageItem, isUser, index) => {
    const { message, medias, survey, point } = messageItem;
    let messageView;
    switch (message?.type) {
      case 1:
        messageView = (
          <>
            {isUser ? renderAvatarFan() : renderDeleteMess(messageItem?.id, index)}
            <div className='type-text'>
              <div className='text'>{message?.content}</div>
              {renderTime(message?.updated_at)}
            </div>
          </>
        );
        break;
      case 2:
        messageView = (
          <>
            {isUser && renderAvatarFan()}
            <div className='type-img' key={messageItem?.id}>
              {medias?.map(({ id, url, objectUrl }, index) => (
                <ImgCustom
                  src={url ? url : objectUrl}
                  alt='img'
                  key={id}
                  avatarErr={false}
                  onClick={() =>
                    setDataViewFull({
                      image: medias[index],
                      listImage: [
                        ...medias.slice(index, medias.length),
                        ...medias.slice(0, index),
                      ],
                    })
                  }
                />
              ))}
              {renderTime(message?.updated_at)}
            </div>
          </>
        );
        break;
      case 3:
        messageView = (
          <>
            {isUser && renderAvatarFan()}
            <div className='type-video'>
              <video controls>
                <source
                  src={
                    medias[0]?.url
                      ? linkS3Video + decryptPath(medias[0]?.url) + '#t=0.001'
                      : medias[0]?.objectUrl + '#t=0.001'
                  }
                  type='video/mp4'
                />
                {t('Common.messageNotSupportVideo')}
              </video>
              {renderTime(message?.updated_at)}
            </div>
          </>
        );
        break;
      case 4:
        messageView = (
          <>
            {isUser && renderAvatarFan()}
            <div className='type-file'>
              <div
                onClick={() =>
                  navigate(`/survey/${survey?.id}`, {
                    state: { avatar: avatar, name: dataMessages?.to_user?.account_name },
                  })
                }
                className='file'>
                <FileIcon className='file-icon' />
                <span className='name'>{survey?.title}</span>
                <ArrowIcon className='arrow-icon' />
              </div>
              {renderTime(message?.updated_at)}
            </div>
          </>
        );
        break;
      case 5:
        messageView = (
          <>
            {isUser && renderAvatarFan()}
            <div className='type-coin'>
              <div className='coin'>
                {(() => {
                  const srcImg = LIST_POINT_ICON_DONATE.find((x) => {
                    return x.name === point?.icon;
                  });
                  if (srcImg) return <img src={srcImg?.icon} alt='' />;
                })()}

                <span className='number'>{formatCoin(point?.point)}</span>
              </div>
              {renderTime(message?.updated_at)}
            </div>
          </>
        );
        break;
      default:
        messageView = null;
    }
    return messageView;
  };

  return (
    <LayoutFan
      titleHeader={isAdmin ? t('Common.isAdmin') : dataMessages?.to_user?.account_name}
      avatarHeader={avatar}
      isAdmin={isAdmin}
      navigateCreator={true}
      accountId={dataMessages?.to_user?.account_id}
      page={'message-detail'}
      loading={loading}>
      {dataViewFull?.image?.url && (
        <ViewFullImage dataViewFull={dataViewFull} close={() => setDataViewFull({})} />
      )}
      {isVisibleSendCoinPopup && (
        <SendCoinPopup
          valueCoin={handleSendCoin}
          isVisiblePopup={() => setIsVisibleSendCoinPopup(false)}
          currentCoin={dataProfile?.point || 0}
          creatorId={id}
          transactionType={1}
        />
      )}
      {showPopupConfirm && (
        <PopupConfirmDelete
          title={t('Popup.confirmDelete1Mess')}
          setShowPopup={setShowPopupConfirm}
          handleOnSubmit={handleDeleteMessage}
        />
      )}
      <div className={`fan-message-detail ${isAdmin && 'avatar_admin'}`}>
        <div className='content scroll-bar-custom' id='scrollableDiv'>
          <InfiniteLoad
            loading={loading}
            data={dataMessages?.data || []}
            fetchNext={fetchNext}
            style={{ display: 'flex', flexDirection: 'column-reverse', overflow: 'none' }}
            inverse={true}
            scrollableTarget='scrollableDiv'>
            {dataMessages?.data?.map((item, index) => (
              <div key={item.id} className='item'>
                {item?.from_user_id !== dataMessages?.to_user?.id ? (
                  <div key={item.id} className='from-me'>
                    {renderMessage(item, false, index)}
                  </div>
                ) : (
                  <div key={item.id} className='from-user'>
                    {renderMessage(item, true)}
                  </div>
                )}
              </div>
            ))}
          </InfiniteLoad>
        </div>

        <div className='post-content'>
          <form onSubmit={(e) => e.preventDefault()} className='fansite-form'>
            <div className='left'>
              <InputWrapper id='fsite-text'>
                <textarea
                  key={dataMessages?.data?.length}
                  {...register('text', {
                    required: t('Messages.placeholderMessages'),
                    onChange: (e) => handleKeyDown(e),
                  })}
                  maxLength={500}
                  onKeyDown={(e) => handleKeyDown(e)}
                  placeholder={t('Messages.placeholderMessages')}
                  readOnly={IS_ADMIN}
                  className={`${IS_ADMIN ? 'admin' : ''}`}
                />
              </InputWrapper>
            </div>
            <div className='content-right'>
              {!isAdmin && !IS_ADMIN && (
                <div className='box-icon-plus'>
                  <CoinIcon
                    className='icon-plus'
                    onClick={() => setIsVisibleSendCoinPopup(!isVisibleSendCoinPopup)}
                  />
                </div>
              )}
              <InputWrapper
                id='fsite-submit'
                className={`button-submit ${
                  !watchText?.trim() || IS_ADMIN ? 'disabled' : ''
                }`}>
                <ButtonSubmit
                  type='button'
                  onClick={!IS_ADMIN && handleSubmit(onSubmit)}
                  disabled={!watchText?.trim() || IS_ADMIN}>
                  <SendChatIcon className='icon-send-message' />
                </ButtonSubmit>
              </InputWrapper>
            </div>
          </form>
        </div>
      </div>
    </LayoutFan>
  );
};

export default MessageDetail;
