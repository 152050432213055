import { LayoutCreatorRight, PopupLoading } from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import PopupConfirm from 'components/Popup/confirmSuccess';
import { newPathUser } from 'constants/layout/constant';
import withCreatorRole from 'hocs/withCreatorRole';
import React, { useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { creatorPackage } from 'store/actions/users';
import { isEmptyObject, isProdTrialMode, maxLengthInputTypeNumber, patternNoFirstSpacing } from 'utils/utils';
import './index.scss';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { clearIdScrollBackPackageSubAndData } from 'store/actions/lives';

const SubscriptionCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [valueCoin, setValueCoin] = useState(0);
  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: {
      price: 0,
    },
  });
  const watchName = watch('title', '');
  const watchPrice = watch('price', '');
  const watchLimited = watch('limited', '');
  const watchLimitedFlag = watch('limited_flag', '');

  const validateLimited = watchLimitedFlag
    ? !watchLimited || Number(watchLimited) <= 0
    : false;
  const isDisabledButton =
    !watchName ||
    watchName.length > 30 ||
    validateLimited ||
    !watchPrice < 0 ||
    (!valueCoin && valueCoin !== 0) ||
    !isEmptyObject(errors);

  // setHeight textarea
  const handleTextarea = (e) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    if (e.target.value === '') {
      e.target.style.height = '178px';
      e.target.style.padding = '9px  30px  9px 12px';
    }
  };

  const handleConfirmSuccess = () => {
    setConfirmSuccess(false);
    navigate(`${newPathUser}profile/subscription`);
  };
  
  const handleSubmitSuccess = ({ success }) => {
    dispatch(clearIdScrollBackPackageSubAndData());
    setIsLoading(false);
    success && setConfirmSuccess(true);
  }
  
  const handleSubmitFail = () => {
    setIsLoading(false);
  }
  //onSubmit
  const onSubmit = (data) => {
    setIsLoading(true)
    const newData = {
      ...data,
      limited_flag: +data.limited_flag,
      limited: data.limited_flag ? data.limited : null,
      price: +watchPrice,
    };
    dispatch(creatorPackage(newData, handleSubmitSuccess, handleSubmitFail));
  };

  return (
    <>
      {confirmSuccess && (
        <PopupConfirm
          title={t('Popup.createPackageSuccess')}
          closeModal={handleConfirmSuccess}
        />
      )}
      {isLoading && <PopupLoading />}
      <LayoutCreatorRight
        titlePage={t('Creator.Home.createSubscription')}
        link={`${newPathUser}profile/subscription`}
        className='subcription-post-create-page'
        >
          <>
            <div className='fansite-creator-subcription-post'>
              <form
                onSubmit={(e) => e.preventDefault()}
                className='fansite-form'
                autoComplete='off'>
                <InputWrapper
                  className='fsite-name'
                  label={t('Creator.Home.subscriptionName')}
                  required={true}
                  notification={true}
                  notificationContent={t('Creator.Home.upTo30Characters')}
                  error={errors?.title ? errors?.title?.message : ''}>
                  <input
                    maxLength={30}
                    {...register('title', {
                      required: ('Creator.Home.pleaseEnterASubscriptionName'),
                      validate: {
                        noFirstSpacing: (value) =>
                          (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                          ('Creator.Home.pleaseEnterASubscriptionName'),
                      },
                    })}
                    placeholder={t('Creator.Home.enterPlanName')}
                  />
                </InputWrapper>

                <InputWrapper
                  className='fsite-price'
                  label={t('Creator.Home.subscriptionAmount')}
                  required={true}
                  error={errors?.price ? errors?.price?.message : ''}
                  notification={true}
                  notificationContent={t('Creator.Home.youCanSetBetween0PtAnd10000Pt')}>
                  <div className='input-price'>
                    <CurrencyFormat
                      {...register('price', {
                        required: ('Creator.Home.pleaseEnterTheAmount'),
                        max: {
                          value: 999999,
                          message: (
                            'Creator.Home.pleaseSetTheAmountWithinTheRangeOf0PtTo100000Pt'
                          ),
                        },
                        min: {
                          value: 0,
                          message: (
                            'Creator.Home.pleaseEnterASubscriptionAmountGreaterThanOrEqualTo0'
                          ),
                        },
                      })}
                      disabled={+isProdTrialMode === 1}
                      decimalSeparator={false}
                      decimalScale={0}
                      value={valueCoin}
                      maxLength={7}
                      className='currency-format'
                      placeholder=''
                      thousandSeparator
                      onValueChange={(values) => {
                        const { value, floatValue } = values;
                        setValue(
                          'price',
                          floatValue >= 0
                            ? floatValue
                            : value === '-' || value === ''
                            ? ''
                            : -1
                        );
                        setValueCoin(value);
                      }}
                    />
                  </div>
                </InputWrapper>

                <InputWrapper
                  id='fsite-content'
                  label={t('Creator.Home.subscriptionDescription')}
                  className={`text-post`}>
                  <textarea
                    {...register('description')}
                    placeholder={t('Creator.Home.upTo1024Characters')}
                    maxLength={1024}
                    onKeyDown={(e) => handleTextarea(e)}
                    onChange={(e) => handleTextarea(e)}
                  />
                </InputWrapper>
                <InputWrapper
                  id='fsite-answer'
                  label={t('Creator.Home.setPlanCapacity')}
                  className='form-item-switch'>
                  <label className='switch'>
                    <input
                      type='checkbox'
                      {...register('limited_flag')}
                      onChange={(e) => {
                        setValue('limited_flag', e.target.checked);
                        if (!e.target.checked) {
                          setValue('limited', '');
                          clearErrors(['limited']);
                        }
                      }}
                    />
                    <span className='slider round'></span>
                  </label>
                </InputWrapper>

                <InputWrapper
                  className='fsite-member'
                  label={t('Creator.Home.capacity')}
                  notification={true}
                  notificationContent={t('Creator.Home.halfWidthDigit')}
                  error={errors?.limited ? errors?.limited?.message : ''}>
                  <div className='input-member'>
                    <input
                      maxLength={9}
                      disabled={!watchLimitedFlag}
                      type='number'
                      {...register('limited')}
                      onInput={(e) => maxLengthInputTypeNumber(e)}
                      onChange={(e) => {
                        const value = e.target.value.trim();
                        setValue('limited', value);
                        if (value !== '0' && value && value > 0) {
                          clearErrors(['limited']);
                        }
                      }}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setValue('limited', value);
                        if (value === '0' || !value || value < 0) {
                          setError('limited', {
                            type: 'required',
                            message: t('Creator.Home.pleaseEnterTheNumberOfPeopleFrom0'),
                          });
                        }
                      }}
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                </InputWrapper>

                <div className='mt-5'>
                  <InputWrapper
                    id='fsite-submit'
                    className={`button-submit ${isDisabledButton ? 'disabled' : ''}`}>
                    <button
                      type='button'
                      onClick={handleSubmit(onSubmit)}
                      disabled={isDisabledButton}>
                      {t('Creator.Home.publishSubscription')}
                    </button>
                  </InputWrapper>
                </div>
              </form>
            </div>
          </>
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(SubscriptionCreate);
