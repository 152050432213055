import { InputWrapper } from 'components/Input/InputWrapper';
import { SendChatIcon } from 'images';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { editReplyCommentDetail } from 'store/actions/usersNew';
import './index.scss';
import { useTranslation } from 'react-i18next';
import Textarea from 'rc-textarea';
import { checkLinkPostSale } from 'utils';
import { getInfoMetaPost } from 'store/actions/postMeta';
import MetaTagViewer from 'components/MetaTagViewer/MetaTagViewer';

const FormEditReplyComment = ({ item_rep, handleCancelEdit }) => {
  const dispatch = useDispatch();
  const [valueEditComment, setValueEditComment] = useState();
  const { t } = useTranslation();
  const [idPostMeta, setIdPostMeta] = useState(null);
  const [postDetailMeta, setPostDetailMeta] = useState(null);

  useEffect(() => {
    if (item_rep) {
      setValueEditComment(item_rep.content);
    }
  }, [item_rep]);

  // setHeight textarea
  const handleTextarea = (e) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    setValueEditComment(e.target.value);
    if (e.target.value?.toString().trim() === '') {
      e.target.style.height = '38px';
      e.target.style.padding = '9px  30px  9px 12px';
    }
  };

  useEffect(() => {
    const isValidContent = valueEditComment?.trim() !== '';
    if (isValidContent) {
      const idPost = checkLinkPostSale(valueEditComment);
      if (idPost) {
        setIdPostMeta(idPost);
      } else {
        setIdPostMeta(null);
        setPostDetailMeta(null);
      }
    } else {
      setPostDetailMeta(null);
      setIdPostMeta(null);
    }
  }, [valueEditComment]);

  useEffect(() => {
    if (idPostMeta) {
      dispatch(
        getInfoMetaPost(idPostMeta, (dataMeta) => {
          if (dataMeta?.title) {
            setPostDetailMeta(dataMeta);
          } else {
            setPostDetailMeta(null);
          }
        })
      );
    } else {
      setPostDetailMeta(null);
    }
  }, [idPostMeta, dispatch]);

  // api post reply comment
  const handleEditComment = (comment_id) => {
    const data = {
      content: valueEditComment?.toString(),
    };
    if (postDetailMeta) {
      dispatch(editReplyCommentDetail(comment_id, data, postDetailMeta));
    } else {
      dispatch(editReplyCommentDetail(comment_id, data));
    }
    setValueEditComment('');
  };

  return (
    <div className='comment form-edit-comment'>
      <form className={`form-reply-comment ${postDetailMeta?.title ? 'haveMeta' : ''}`} onSubmit={(e) => e.preventDefault()}>
        <InputWrapper
          id='fsite-comment'
          className={`form-item form-input-comment-meta ${
            postDetailMeta?.title ? 'haveMeta' : ''
          }`}>
          <Textarea
            placeholder={t('comment.formEditComment.placeholder')}
            onChange={handleTextarea}
            onKeyDown={handleTextarea}
            defaultValue={item_rep.content}
            maxLength='500'
            id='textarea-edit-rep-comment'
            autoSize
            style={{ background: '#fff' }}
          />
          {postDetailMeta?.title && (
            <MetaTagViewer
              title={postDetailMeta?.title}
              url={postDetailMeta?.url}
              thumbnail={postDetailMeta?.thumbnail}
            />
          )}
          <SendChatIcon
            // style={{ transform: "rotate(45deg)"}}
            className={`${valueEditComment && 'active'} icon-send-rep-comment`}
            onClick={() =>
              (valueEditComment.length > 0 || valueEditComment) &&
              handleEditComment(item_rep?.id)
            }
          />
        </InputWrapper>
      </form>
      <div
        className='out-edit'
        onClick={(e) => {
          handleCancelEdit(e);
        }}>
        {t('comment.formEditReplyComment.buttonESC')} <span>{t('Common.buttonCancel')}</span>
      </div>
    </div>
  );
};

export default FormEditReplyComment;
