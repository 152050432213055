import styled, { css, keyframes } from 'styled-components';
const animation = keyframes`
0% {
  width: 0;
}
100% {
  width: 100%;
}
`;
export const StyledTimeline = {
  Card: styled.div`
    ${({ isFanText = false }) =>
      isFanText &&
      css`
        overflow: hidden;
        .content {
          overflow: auto;
          position: absolute;
          z-index: 5;
          color: #fff;
          left: 75px;
          top: 15px;
          margin: 0;
          width: calc(100% - 140px);
          padding: 2px 10px;
          height: 42px;
          .title {
            margin-bottom: 0;
            font-size: 14px;
          }
          .text {
            color: #fff;
            text-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.4);
          }
          ::-webkit-scrollbar-thumb {
            background: #ffffff;
            border-radius: 20px;
          }
          ::-webkit-scrollbar {
            width: 6px;
          }
          ::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 20px;
            box-shadow: inset 0px 0px 0px 1px #c8c8cd;
          }
        }
      `}
  `,

  BlueImg: styled.div`
    border-radius: 10px;
    background: url(${({ url }) => url});
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    .bg-img {
      border-radius: 10px 10px 0px 0px;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 9;
      background-size: cover;
      min-height: 100%;
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(20px);
    }
    .cover-img {
      left: 50%;
      top: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      z-index: 10;
      max-width: 100%;
      max-height: 100%;
      height: auto;
    }
  `,

  Global: styled.div`
    position: relative;
    @media screen and (max-width: 575px) {
      overflow: hidden;
    }
    @media screen and (max-width: 768px) {
      overflow: hidden;
    }
    .slideshow {
      margin: 0 auto;
      overflow: hidden;
      max-width: 500px;
      width: 500px;
    }
    .slideshowSlider {
      white-space: nowrap;
      transition: ease 1000ms;
      img {
        /* background: #000; */
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    video {
      width: 100%;
      height: 100%;
      aspect-ratio: 2/3;
      /* background: #000; */
      object-fit: contain;
    }
    .slide {
      display: inline-block;
      &.normal {
        min-width: 500px;
        min-height: 375px;
        //max-height: 375px;
        img {
          aspect-ratio: 4/3;
        }
      }
      &.short {
        min-width: 500px;
        min-height: 750px;
        //max-height: 750px;
        img {
          aspect-ratio: 2/3;
        }
      }
      @media screen and (max-width: 575px) {
        &.normal {
          min-width: 300px;
          min-height: 257px;
          max-width: 100%;
        }
        &.short {
          min-width: 340px;
          min-height: 510px;
          max-width: 100%;
          img {
            aspect-ratio: 2/3;
          }
        }
      }

      @media screen and (max-width: 375px) {
        &.normal {
          min-width: 100%;
        }
        &.short {
          min-width: 100%;
        }
      }

      width: 100%;
      position: relative;
    }
  `,
  Loading: styled.div`
    position: absolute;
    z-index: 50;
    height: 3px;
    top: 3px;
    width: calc(100% - 64px);
    right: 32px;
    box-shadow: inset 0px 0px 0px 1px #c8c8cd;
    overflow: hidden;
    background: rgba(40, 39, 39, 0.3);
    border-radius: 20px;
    .progress-bar {
      display: flex;
      height: 100%;
      width: 0;
      background: #ff0000;
      ${({ play }) =>
        play
          ? css`
              animation: ${animation} 15s linear 0s infinite normal;
            `
          : ''};
    }
  `,
  Block: styled.div`
    ${({ isShort = false }) =>
      isShort &&
      css`
        background: linear-gradient(
          180deg,
          rgba(40, 39, 39, 0.5) 0%,
          rgba(40, 39, 39, 0) 100%
        );
        position: absolute;
        z-index: 5;
        top: 0;
        margin: 0;
        height: 80px;
        width: 100%;
      `}
  `,
  Count: styled.div`
    position: absolute;
    color: #fff;
    left: 10px;
    top: 20px;
    background: rgb(112 112 112 / 50%);
    border-radius: 4px;
    padding: 2px 12px;
    z-index: 10;
  `,
  ButtonPlay: styled.button`
    cursor: pointer;
    position: absolute;
    z-index: 3;
    width: 68px;
    height: 48px;
    padding: 2px;
    border: none;
    border-radius: 14px;
    background: rgba(0, 0, 0, 0.66);
    inset: 0;
    margin: auto;
    color: white;
    ${({ isShow = true }) =>
      isShow
        ? css`
            display: block;
          `
        : css`
            display: none;
          `};
  `,

  ButtonManyNumber: styled.button`
    cursor: pointer;
    position: absolute;
    //z-index: 100;
    border: none;
    inset: 0;
    margin: auto;
    color: white;
    background: #1a1a1a;
    opacity: 0.4;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 44px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
  `,

  LineDot: styled.div`
    position: absolute;
    z-index: 5;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 16px;
    display: flex;
    gap: 7px;
    .dot_i {
      cursor: pointer;
      width: 6px;
      height: 2px;
      background-color: #b1b6bb;
      border-radius: 10px;
      &.active {
        background-color: #ff0000;
      }
    }
  `,
  Button: styled.button`
    cursor: pointer;
    position: absolute;
    z-index: 3;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    border: none;
    background: #ff0000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    // Position
    top: 50%;
    ${({ right = false }) =>
      right
        ? css`
            right: 20px;
          `
        : css`
            left: 20px;
          `}
  `,
  LockScreen: styled.div`
    position: absolute;
    inset: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    z-index: 17;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    .text-follow {
      color: #000000;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }

    button {
      background-color: #dc143c;
      color: #fff;
      border: none;
      cursor: pointer;
      padding: 11px 24px;
      border-radius: 8px;
      font-size: 12px;
      &:hover {
        background-color: #ce1338 !important;
      }
    }
    @media screen and (max-width: 575px) {
      width: 92%;
    }
  `,
};
