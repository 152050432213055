import {
  ENV,
  callApi,
  callApiWithOutShowError,
  configParamsDELETE,
  configParamsGet,
  configParamsPUT,
  configParamsPostJSON,
  updateStatus,
} from 'utils';
import { TYPES } from '.';

const getCards = ({ page = 1, limit = 20 }, callback) => {
  let url = `${ENV}/credit-cards?page=${page}&limit=${limit}`;
  return (dispatch) => {
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_CARDS_CREDIT_SUCCESS, {
            data: response.data,
            pagination: response?.pagination,
          })
        );
        callback && callback(response);
      },
      () => {
        callback && callback({});
      }
    );
  };
};

const getCardsPayment = ({ page = 1, limit = 20 }, callback) => {
  let url = `${ENV}/credit-cards?page=${page}&limit=${limit}`;
  return (dispatch) => {
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_PAYMENT_CARDS_CREDIT_SUCCESS, {
            data: response.data,
            pagination: response?.pagination,
          })
        );
        callback && callback(response);
      },
      () => {
        callback && callback({});
      }
    );
  };
};

const getCard = (id, callback) => {
  let url = `${ENV}/credit-cards/${id}/edit`;
  return () => {
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        callback && callback(response);
      },
      (error) => {
        callback && callback(error);
      }
    );
  };
};

const checkCanUpdate = (id, typeCard, callback) => {
  let url = `${ENV}/credit-cards/${id}/edit`;
  return () => {
    callApiWithOutShowError(
      url,
      configParamsGet,
      null,
      async (rs) => {
        callback &&
          callback({
            id,
            data: rs?.data?.data,
          });
      },
      (error) => {
        callback &&
          callback({
            messageError: error?.errors?.message,
            typeCard,
          });
      }
    );
  };
};

const createCard = (params, callback) => {
  let url = `${ENV}/credit-cards`;
  return () => {
    callApi(
      url,
      configParamsPostJSON(params),
      null,
      async () => {
        callback && callback(true);
      },
      () => {
        callback && callback(false);
      }
    );
  };
};

const updateCard = (id, params, callback) => {
  let url = `${ENV}/credit-cards/${id}`;
  return () => {
    callApi(
      url,
      configParamsPUT(params),
      null,
      async () => {
        callback && callback(true);
      },
      () => {
        callback && callback(false);
      }
    );
  };
};

const changeDefaultCard = (id, callback) => {
  let url = `${ENV}/credit-cards/${id}/default`;
  return (dispatch) => {
    callApi(
      url,
      configParamsPUT(),
      null,
      async () => {
        await dispatch(
          updateStatus(TYPES.CHANGE_DEFAULT_CARD_SUCCESS, {
            id,
          })
        );
        callback && callback(id);
      },
      () => {
        callback && callback();
      }
    );
  };
};

const deleteCard = (id, callback) => {
  let url = `${ENV}/credit-cards/${id}`;
  return () => {
    callApi(
      url,
      configParamsDELETE,
      null,
      async () => {
        callback && callback(id);
      },
      () => {
        callback && callback();
      }
    );
  };
};

const setIdCardActive = (id, callback) => {
  return (dispatch) => {
    dispatch(
      updateStatus(TYPES.SET_ID_CARD_ACTIVE_SUCCESS, {
        id,
      })
    );
    callback && callback();
  };
};

const clearCards = () => {
  return (dispatch) => {
    dispatch(updateStatus('CLEAR_CREDIT_CARDS'));
  };
};

const clearActiveCard = () => {
  return (dispatch) => {
    dispatch(updateStatus('CLEAR_ACTIVE_CARDS'));
  };
};

const setDataPaymentCard = (data, cb) => {
  return (dispatch) => {
    dispatch(
      updateStatus('SET_DATA_PAYMENT_CARD', {
        data,
      })
    );
    cb && cb();
  };
};

const setIdScrollBackCard = (id, cb) => {
  return (dispatch) => {
    dispatch(
      updateStatus('SET_ID_SCROLL_BACK_CARD', {
        data: {
          id,
        },
      })
    );
    cb && cb();
  };
};

const setIdScrollBackCardVendo = (id, cb) => {
  return (dispatch) => {
    dispatch(
      updateStatus('SET_ID_SCROLL_BACK_CARD_VENDO', {
        data: {
          id,
        },
      })
    );
    cb && cb();
  };
};

const clearScrollIdAndData = (cb) => {
  return (dispatch) => {
    dispatch(updateStatus('CLEAR_SCROLL_ID_AND_DATA_CARDS'));
    cb && cb(true);
  };
};

const clearScrollIdAndDataVendo = (cb) => {
  return (dispatch) => {
    dispatch(updateStatus('CLEAR_SCROLL_ID_AND_DATA_CARDS_VENDO'));
    cb && cb(true);
  };
};

export {
  getCards,
  getCard,
  createCard,
  updateCard,
  deleteCard,
  changeDefaultCard,
  checkCanUpdate,
  clearCards,
  setIdCardActive,
  clearActiveCard,
  setIdScrollBackCard,
  getCardsPayment,
  setDataPaymentCard,
  clearScrollIdAndData,
  setIdScrollBackCardVendo,
  clearScrollIdAndDataVendo
};
