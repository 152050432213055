import Icon from '@ant-design/icons';

const PlayIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={47}
        height={56}
        viewBox='0 0 47 56'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M44.708 24.3739L6.46631 1.003C5.82217 0.609112 5.08472 0.393954 4.32983 0.379667C3.57494 0.36538 2.82987 0.552481 2.17129 0.921717C1.51271 1.29095 0.964398 1.82899 0.582773 2.48047C0.201148 3.13195 -8.13313e-06 3.87334 2.46633e-10 4.62837V51.3742C0.000614336 52.129 0.202159 52.87 0.583928 53.5211C0.965698 54.1722 1.51393 54.71 2.1723 55.0791C2.83066 55.4482 3.57543 55.6354 4.33008 55.6214C5.08473 55.6074 5.82206 55.3928 6.46631 54.9996L44.708 31.6287C45.3292 31.2488 45.8425 30.7158 46.1986 30.0806C46.5547 29.4454 46.7417 28.7295 46.7417 28.0013C46.7417 27.2731 46.5547 26.5571 46.1986 25.922C45.8425 25.2868 45.3292 24.7537 44.708 24.3739V24.3739Z'
          fill='white'
        />
      </svg>
    )}
    {...props}
  />
);

export default PlayIcon;
