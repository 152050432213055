import { ImgCustom } from 'components';
import { HeartActiveIcon, HeartIcon } from 'images';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { convertToCurrentTime } from 'utils';

export const CommentHideItemChild = ({
  item,
  paginationSub,
  handleGetSubComment,
  handleRedirect,
  handleLikeItem,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLoadMoreComment = () => {
    handleGetSubComment(paginationSub?.current_page + 1);
  };

  return (
    <div className='wrap-item-child-hide-comment'>
      <div className='comment-hide-item-child'>
        <div className='comment-hide-item-child-left'>
          <div
            className='avatar'
            onClick={() => {
              handleRedirect(`hide-comment-${item?.id}`, () => {
                navigate(`/${item?.account_id}`);
              });
            }}>
            <ImgCustom
              isPlaceholderAvatar
              className='img-avatar'
              screen='avatar_44_44'
              src={item?.avatar}
            />
          </div>
          <div className='info-user'>
            <div className='info-user-name-edit'>
              <div className='info-user-name'>{item?.account_name}</div>
              <div className='info-user-time-edit'>
                {convertToCurrentTime(item?.created_at, 'YYYY/MM/DD HH:mm')}
              </div>
            </div>
            <div className='info-user-content'>{item?.content}</div>
            <div className='interactive'>
              <div className='interactive-item'>
                {item?.is_liked === 1 ? (
                  <HeartActiveIcon
                    className={`like-icon active`}
                    onClick={() => handleLikeItem(item?.id)}
                  />
                ) : (
                  <HeartIcon
                    className={`like-icon`}
                    onClick={() => handleLikeItem(item?.id)}
                  />
                )}
                <span className='count'>{item?.number_like}</span>
              </div>
            </div>
            {paginationSub?.current_page < paginationSub?.total_pages && (
              <div className='view-more' onClick={handleLoadMoreComment}>
                {t('comment.listRepComment.viewMore')}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
