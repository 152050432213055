import { ImgCustom, LayoutCreatorRight } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import Plan from 'components/plan';
import { newPathUser } from 'constants/layout/constant';
import { usePopupPaymentSub } from 'hooks/v2/usePopupPaymentSub';
import NoSubscribe from 'images/NoSubscribe.svg';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { getListPackageAnotherCreator } from 'store/actions/sellPost';
import './index.scss';
import { sortListPackagePointAndDate } from 'utils';

const PlanList = () => {
  const { creator_id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [buyPackageSuccess, setBuyPackageSuccess] = useState(false);
  const {
    loading,
    listPackageAnotherCreator,
    pagination,
    loadingListPackageMore,
    idScrollBackSellPackage,
  } = useSelector((state) => state.sellPost);
  const { dataUser } = useSelector((state) => state.users);

  const { setIsOpen, setIdPackage, PopupPaymentBySub } = usePopupPaymentSub({
    urlRedirect: '',
    onClickSuccess: () => {
      dispatch(getListPackageAnotherCreator(creator_id, 99999));
    },
  });

  const fetchNext = () => {
    const pages = pagination?.total / pagination?.per_page;
    if (pages > page) {
      setPage(page + 1);
    }
  };

  const listPackage = useMemo(() => {
    if (listPackageAnotherCreator && !!listPackageAnotherCreator?.length) {
      return sortListPackagePointAndDate(listPackageAnotherCreator);
    }
    return [];
  }, [listPackageAnotherCreator]);

  useEffect(() => {
    if (creator_id && dataUser) {
      if (Number(creator_id) === dataUser?.id) {
        navigate(`${newPathUser}profile/subscription`);
      }
    }
  }, [dataUser, creator_id, navigate]);

  useEffect(() => {
    if (idScrollBackSellPackage) {
      const idPost = document.getElementById(idScrollBackSellPackage);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    } else {
      const top = document.getElementById('app_cocofans_0');
      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
    }
  }, [idScrollBackSellPackage]);

  useEffect(() => {
    if (!idScrollBackSellPackage) {
      dispatch(getListPackageAnotherCreator(creator_id, 99999, page));
    }
  }, [page, idScrollBackSellPackage, dispatch, creator_id]);

  return (
    <>
      <PopupPaymentBySub />
      <LayoutCreatorRight className='plan_list_page-header' titlePage={t('Plan.title')}>
        <div className='plan_list_page'>
          {loading && !listPackage.length && (
            <div className={'shimmer-loading'}>
              <ShimmerPostDetails />
            </div>
          )}
          <div className='d-flex justify-content-center flex-column align-items-center'>
            {!buyPackageSuccess && (
              <>
                {(!listPackage && !loading) ||
                  (listPackage?.length <= 0 && !loading && (
                    <div className='no-data'>
                      <div className='no-data_icon'>
                        <ImgCustom
                          src={NoSubscribe}
                          alt='empty'
                          style={{ backgroundColor: 'transparent' }}
                        />
                      </div>
                      <div className='no-data__text'>
                        {t('Creator.Home.noSubscriptionList')}
                      </div>
                    </div>
                  ))}

                {listPackage.length > 0 && (
                  <InfiniteLoad
                    data={listPackage}
                    fetchNext={fetchNext}
                    className='scroll'
                    loading={loadingListPackageMore}>
                    {listPackage.length > 0 &&
                      listPackage.map((item, index) => (
                        <Plan
                          key={index}
                          item={item}
                          setIsOpen={setIsOpen}
                          setIdPackage={setIdPackage}
                          creator_id={creator_id}
                          buyPackageSuccess={(e) => {
                            setBuyPackageSuccess(e);
                            dispatch(getListPackageAnotherCreator(creator_id, 99999));
                          }}
                        />
                      ))}
                  </InfiniteLoad>
                )}
              </>
            )}
          </div>
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default PlanList;
