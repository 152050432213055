import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { isIPad13, isMobile, isTablet } from 'react-device-detect';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import withCreatorRole from 'hocs/withCreatorRole';
import withCreatorProfilePage from 'context/CreatorProfileProvider';

import useLockBodyScroll from '../../../../../hooks/useLockBodyScroll';
import './index.scss';

import BackHeader from '../../back-header';
import { InputWrapper } from 'components/Input/InputWrapper';
import DragNDrop from 'components/DragNDrop';
import {
  acceptedImageTypes,
  acceptedFilePDFTypes,
  isEmptyObject,
  TEN_MB,
  getPresignedUrlS3,
  setFileNameS3,
  uploadS3ByPresigned,
} from 'utils';

import { newPathUser } from 'constants/layout/constant';
import { HeaderListIdolV2, LayoutCreatorRight, PopupConfirmFan } from 'components';
import { createContracts, getContract, updateContracts } from 'store/actions/contract';
import LoadingIconV2 from 'images/LoadingIconV2';
import { pushNotify } from 'components/Notification';
import { getUserInfo } from 'store/actions/users';
import { isEmpty, isObject, isString, omit, pickBy } from 'lodash';
import { CloseIcon } from 'images';
import { getIDKYC } from 'store/actions/usersNew';

const PopupContracts = ({ title, close, contractId, isShowProfile }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const regPaternRomanji = /^[A-Za-z ]+$/u;
  const [isVisiblePopUpConfirm, setIsVisiblePopUpConfirm] = useState(false);
  const [contract, setContract] = useState({});
  const [contractOrigin, setContractOrigin] = useState();

  const [popupLoading, setPopupLoading] = useState(false);

  const {
    dataUser: userProfile,
  } = useSelector((state) => state.users);

  useLockBodyScroll();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    reset,
  } = useForm({
    mode: 'all',
  });
  
  useEffect(() => {
    if(!contractId) {
      const top = document.getElementById('app_cocofans_0');
      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
    }
  }, []);

  useEffect(() => {
    if (isMobile && isShowProfile) {
      dispatch(getUserInfo());
    }
  }, [dispatch, isShowProfile]);

  useEffect(() => {
    let paramId = contractId;
    if (isShowProfile) {
      paramId = id || contractId;
    }
    if (paramId) {
      setPopupLoading(true)
      dispatch(getContract(paramId, receiveContract));
    }
  }, [id, contractId, isShowProfile]);

  const onSubmit = (data) => {
    setContract(data);
    setIsVisiblePopUpConfirm(true);
  }

  const receiveContract = (response) => {
    setPopupLoading(false);
    if (response.success) {
      const { data } = response;
      setContractOrigin(data);
      setValue('avatar', data?.avatar);
      setValue('avatar_identify', data?.avatar_identify);
      setValue('contract_file', data?.contract_file);
      setValue('first_name', data?.first_name);
      setValue('first_name_romaji', data?.first_name_romaji);
      setValue('identify_img_back', data?.identify_img_back);
      setValue('identify_img_front', data?.identify_img_front);
      setValue('last_name', data?.last_name);
      setValue('last_name_romaji', data?.last_name_romaji);

    }
  }
  const isUpdate = () => {
    return +id || contractId;
  }

  const uploadPdf = async () => {
    const { contract_file } = contract;
    if (!contract_file) return;
    if (isString(contract_file)) return contract_file;
    const { file_path, pre_signed } = await getPresignedUrlS3(
      'contract',
      `uploads/output/contract/${userProfile?.id}`,
      setFileNameS3(contract_file.name),
      'post'
    ).then(({ data }) => data);
    if (pre_signed && file_path) {
      await uploadS3ByPresigned(pre_signed, contract_file);
      return file_path
    }
  }

  const imagesIdentity = async () => {
    const contractClone = { ...contract };
    const images = omit(contractClone, ['contract_file'])
    const cleaned = pickBy(images, isObject);
    if (isEmpty(cleaned)) return;

    for (const property in cleaned) {
      const file = cleaned[property];
      const { file_path, pre_signed } = await getPresignedUrlS3(
        'image',
        `uploads/output/images`,
        setFileNameS3(file.name),
        'post'
      ).then(({ data }) => data);
      if (pre_signed && file_path) {
        await uploadS3ByPresigned(pre_signed, file) 
        cleaned[property] = file_path;
      }
    }
    return cleaned;
  }
  const trapSpacesForRequiredFields =  (value) => !!value.trim();

  const handleSubmitContract = () => {
    window.localStorage.removeItem('dataKYC')
    dispatch(getIDKYC({ id: null, page: 1 }));
    setIsVisiblePopUpConfirm(false);
    setPopupLoading(true);
    
    Promise.all([
      uploadPdf(),
      imagesIdentity(),
    ]).then(([
      contract_file,
      result
    ]) => {
      const payload = {
        ...contract,
        contract_file,
        ...result,
      }
      if (isUpdate()) {
        return dispatch(updateContracts(isUpdate(), payload, receiveContracts))
      }
      dispatch(createContracts(payload, receiveContracts))
    })
  }
  const receiveContracts = (response) => {
    setPopupLoading(false);

    if (response.errors) {
      return pushNotify('error', response.errors);
    }
    if (isShowProfile) {
      if (isTablet) {
        reset();
        close(true);
      }

      if (isMobile) return navigate(`${newPathUser}profile/registration-of-performer`);

      reset();
      close(true);
    } else {
      reset();
      close(true);
    }
  };

  const handleClosePopup = () => {
    if (isShowProfile) {
      if (isTablet) return close(false);

      if (isMobile) return navigate(`${newPathUser}profile/registration-of-performer`);
  
      close(false)
    } else {
      close(false)
    }
    
  }

  const disableSubmit = (errors) => {
    const formErrors = omit(errors, ['identify_img_back']);
    return !isEmptyObject(formErrors);
  }

  return (
    <LayoutCreatorRight
      noHeader={!isMobile}
      link={`${newPathUser}profile/registration-of-performer`}
      titlePage={t('Contract.title')} className='contracts'
    >

    <div className={`popup create-contracts ${isShowProfile ? "sm-w": ""}`}>
      {popupLoading && (
        <div className='layer-spinner'>
          <LoadingIconV2 className='spinner-icon' />
        </div>
      )}
      {isVisiblePopUpConfirm && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopUpConfirm(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={isUpdate() ? t('Popup.confirmUpdateContract') : t('Popup.confirmCreateContract')}
        >
          <div className='ok btn' onClick={() => handleSubmitContract()}>
            {t('Common.buttonYes')}
          </div>
          <div className='btn cancel' onClick={() => setIsVisiblePopUpConfirm(null)}>
            {t('Common.buttonNo')}

          </div>
        </PopupConfirmFan>
      )}

      <div className='popup-content contracts'>
        { (!isMobile || !isShowProfile) && 
        <>
          <div className='title'>
              {title}
            </div>
          <CloseIcon className='close-icon' onClick={() => handleClosePopup()} />
        </>
        }
        <div className='content'>
          <div className="content-wrapper">
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <label className="group-title">{t('Contract.popupTitle')}</label>
              <div className="group-name">
                <div className='box-field'>
                  <InputWrapper
                    id='fsite-last_name'
                    label={t('Contract.lastName')}
                    className='custom-input'
                    required={true}
                    error={
                      errors?.last_name ? errors?.last_name?.message : ''
                    }>
                    <input
                      {...register('last_name', {
                        validate: {
                          noFirstSpacing: (value) =>
                          trapSpacesForRequiredFields(value) ||
                          t('ValidateMsg.LAST_NAME_REQUIRED'),
                        },
                        maxLength: {
                          value: 20,
                          message: t(
                            'ValidateMsg.LAST_NAME_MAX_20'
                          ),
                        },
                        // pattern: {
                        //   value: /^[ぁ-んァ-ン一-龥ー]+$/u,
                        //   message: t(
                        //     'ValidateMsg.CONTRACT_JAPANESE_FORMAT'
                        //   ),
                        // },
                      })}        
                      placeholder={t('Contract.lastNamePlace')}
                    />
                  </InputWrapper>
                </div>
                <div className='box-field'>
                  <InputWrapper
                    id='fsite-first_name'
                    label={t('Contract.firstName')}
                    className='custom-input'
                    required={true}
                    error={
                      errors?.first_name ? errors?.first_name?.message : ''
                    }>
                    <input
                      {...register('first_name', {
                        validate: {
                          noFirstSpacing: (value) =>
                          trapSpacesForRequiredFields(value) ||
                          t('ValidateMsg.FIRST_NAME_REQUIRED'),
                        },
                        maxLength: {
                          value: 20,
                          message: t(
                            'ValidateMsg.FIRST_NAME_MAX_20'
                          ),
                        },
                        // pattern: {
                        //   value: /^[ぁ-んァ-ン一-龥ー]+$/u,
                        //   message: t(
                        //     'ValidateMsg.CONTRACT_JAPANESE_FORMAT'
                        //   ),
                        // },
                      })}        
                      placeholder={t('Contract.firstNamePlace')}
                    />
                  </InputWrapper>
                </div>
                <div className='box-field'>
                  <InputWrapper
                    id='fsite-last_name_romanji'
                    label={t('Contract.lastNameRomaji')}
                    className='custom-input'
                    required={true}
                    error={
                      errors?.last_name_romaji ? errors?.last_name_romaji?.message : ''
                    }>
                    <input
                      {...register('last_name_romaji', {
                        maxLength: {
                          value: 20,
                          message: t(
                            'ValidateMsg.LAST_NAME_MAX_20'
                          ),
                        },
                        pattern: {
                          value: regPaternRomanji,
                          message: t(
                            'ValidateMsg.CONTRACT_LAST_NAME_ROMAJI_FORMAT'
                          ),
                        },
                        validate: {
                          noFirstSpacing: (value) =>
                          trapSpacesForRequiredFields(value) ||
                          t('ValidateMsg.LAST_NAME_REQUIRED'),
                        },
                      })}        
                      placeholder={t('Contract.lastNameRomanjiPlace')}
                    />
                  </InputWrapper>
                </div>
                <div className='box-field'>
                  <InputWrapper
                    id='fsite-first_name_romanji'
                    label={t('Contract.firstNameRomaji')}
                    className='custom-input'
                    required={true}
                    error={
                      errors?.first_name_romaji ? errors?.first_name_romaji?.message : ''
                    }>
                    <input
                      {...register('first_name_romaji', {
                        maxLength: {
                          value: 20,
                          message: t(
                            'ValidateMsg.FIRST_NAME_MAX_20'
                          ),
                        },
                        pattern: {
                          value: regPaternRomanji,
                          message: t(
                            'ValidateMsg.CONTRACT_FIRST_NAME_ROMAJI_FORMAT'
                          ),
                        },
                        validate: {
                          noFirstSpacing: (value) =>
                          trapSpacesForRequiredFields(value) ||
                          t('ValidateMsg.FIRST_NAME_REQUIRED'),
                        },
                      })}        
                      placeholder={t('Contract.firstNameRomanjiPlace')}
                    />
                  </InputWrapper>
                </div>
              </div>
              <InputWrapper
                className="group-title"
                label={t('Contract.uploadLicense')}
                status={contractOrigin?.contract_file}
                required
                error={
                  errors?.contract_file ? errors?.contract_file?.message : ''
                }
              >
                <DragNDrop
                  widthBlue={`${isMobile ? 378 : 327}`}
                  heightBlue={241}
                  label={t('SignUp.supportPDF')}
                  name='contract_file'
                  setValue={setValue}
                  setError={setError}
                  clearErrors={clearErrors}
                  maxSize={TEN_MB}
                  isPdf={true}
                  isUpload={true}
                  accept={acceptedFilePDFTypes.map((item) => item).join(',')}
                  {...register('contract_file', {
                    required: 'ValidateMsg.required',
                  })}        
                />
              </InputWrapper>
              <div className="identify">
                <div className="identify-card-group">
                <InputWrapper
                  required={true}
                  className="group-title"
                  label={t('Contract.avatar')}
                  status={contractOrigin?.avatar}
                  error={
                    errors?.avatar ? errors?.avatar?.message : ''
                  }
                >
                  <DragNDrop
                    widthBlue={`${isMobile ? 378 : 327}`}
                    heightBlue={241}
                    label={t('SignUp.supportImg')}
                    name='avatar'
                    setValue={setValue}
                    setError={setError}
                    clearErrors={clearErrors}
                    maxSize={TEN_MB}
                    isUpload={true}
                    accept={acceptedImageTypes.map((item) => item).join(',')}
                    {...register('avatar', {
                      required: t('ValidateMsg.required'),
                    })}        
                  />
                </InputWrapper>
                </div>
                
                <div className="identify-card-group">
                  <InputWrapper
                    required={true}
                    className="group-title"
                    label={t('Contract.avatarIdentify')}
                    status={contractOrigin?.avatar_identify}
                    error={
                      errors?.avatar_identify ? errors?.avatar_identify?.message : ''
                    }
                  >
                    <DragNDrop
                      widthBlue={`${isMobile ? 378 : 327}`}
                      heightBlue={241}
                      label={t('SignUp.supportImg')}
                      name='avatar_identify'
                      setValue={setValue}
                      setError={setError}
                      clearErrors={clearErrors}
                      maxSize={TEN_MB}
                      isUpload={true}
                      accept={acceptedImageTypes.map((item) => item).join(',')}
                      {...register('avatar_identify', {
                        required: t('ValidateMsg.required'),
                      })}  
                    />
                  </InputWrapper>                  
                </div>
                <div className="identify-card-group">
                  <InputWrapper
                    required={true}
                    className="group-title"
                    label={t('Contract.identityFront')}
                    error={
                      errors?.identify_img_front ? errors?.identify_img_front?.message : ''
                    }
                    status={contractOrigin?.identify_img_front}
                  >
                    <DragNDrop
                      widthBlue={`${isMobile ? 378 : 327}`}
                      heightBlue={241}
                      label={t('SignUp.supportImg')}
                      name='identify_img_front'
                      setValue={setValue}
                      setError={setError}
                      clearErrors={clearErrors}
                      maxSize={TEN_MB}
                      isUpload={true}
                      accept={acceptedImageTypes.map((item) => item).join(',')}
                      {...register('identify_img_front', {
                        required: t('ValidateMsg.required'),
                      })}
                    />
                  </InputWrapper>
                </div>
                <div className="identify-card-group">
                  <InputWrapper
                    required={false}
                    className="group-title"
                    label={t('Contract.identityBack')}
                    status={contractOrigin?.identify_img_back}
                    error={
                      errors?.identify_img_back ? errors?.identify_img_back?.message : ''
                    }
                  >
                    <DragNDrop
                      widthBlue={`${isMobile ? 378 : 327}`}
                      heightBlue={241}
                      label={t('SignUp.supportImg')}
                      name='identify_img_back'
                      setValue={setValue}
                      setError={setError}
                      clearErrors={clearErrors}
                      maxSize={TEN_MB}
                      isUpload={true}
                      accept={acceptedImageTypes.map((item) => item).join(',')}
                      {...register('identify_img_back')}
                    />
                  </InputWrapper>
                </div>
              </div>
              <div className="notes">
                <p>{t('Contract.itemRequired')}</p>
                <p>{t('Contract.matchPhoto')}</p>
                <p>{t('Contract.matchNumber')}</p>
                <p>{t('Contract.pictureAllows')}</p>
                <p>{t('Contract.uploadNotes')}</p>
              </div>
              { isMobile && 
                <div className="button-actions">
                  <button type="submit" disabled={disableSubmit(errors)} className="btn bg-red w-100 font-weight-700 text-white app-btn m-0">
                   { isUpdate() ? t('Contract.update') : t('Contract.create') }
                  </button>
                  <button type="button" onClick={() => handleClosePopup()} className="btn w-100 font-weight-700 border app-btn bg-white m-0 mt-2">
                    {t('Common.buttonCancel')}
                  </button>
                </div>
              }
              {
                !isMobile &&
                <div className='box-button'>
                  <button
                    disabled={disableSubmit(errors)}
                    className='btn btn-bg-red text-white'
                    type="submit"
                  >
                   { isUpdate() ? t('Contract.update') : t('Contract.create') }
                  </button>
                  <button className='btn border bg-while' type='button' onClick={() => handleClosePopup()}>
                    {t('Common.buttonCancel')}
                  </button>
                </div>
              }
            </form>
          </div>
        </div>
        {!isMobile && <div className="button-actions-relative"></div>}
      </div>
    </div>
    </LayoutCreatorRight>
  );
};
export default withCreatorRole(withCreatorProfilePage(PopupContracts));
