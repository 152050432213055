import Icon from '@ant-design/icons';

const ArrowLeftIconV2 = (props) => (
  <Icon
    component={() => (
      <svg
        width={25}
        height={24}
        viewBox='0 0 25 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M4.73611 12.2734L19.4634 12.2734'
          stroke='#282727'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.6758 18.299L4.73578 12.275L10.6758 6.25'
          stroke='#282727'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default ArrowLeftIconV2;
