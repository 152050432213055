import { ClockIcon, CloseIcon } from 'images';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.scss';
import ClockIconV2 from 'images/ClockIconV2';
import { useClickOutSize } from 'hooks/v2/clickOutSide';
const CustomSelectTime = ({
  timePost,
  checkDisabled,
  classNameCustom,
  initTime,
  classTextBox = '',
  classIcon = '',
  disabledInput = false,
  hiddenDefaulvalue = false,
  closeIcon = false,
  showIconV2 = false,
  colorDefaultIcon = '#666565',
  scrollToBottom = false,
  handleOnClick,
  ...props
}) => {
  const [refOutSide] = useClickOutSize(() => {
    setIsOpenCustomTime(false);
  });
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [isOpenCustomTime, setIsOpenCustomTime] = useState(false);
  const [valueTime, setValueTime] = useState(hiddenDefaulvalue ? '' : '00:00');
  const { t } = useTranslation();
  const bottomRef = useRef(null);

  useEffect(() => {
    setValueTime(initTime);
  }, [initTime]);

  useEffect(() => {
    disabledInput || (!checkDisabled && setIsOpenCustomTime(false));
  }, [checkDisabled, disabledInput]);

  useEffect(() => {
    if (!scrollToBottom) return;

    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [isOpenCustomTime, scrollToBottom]);

  const handleCustomTime = () => {
    setIsOpenCustomTime(false);
    const time = `${hour < 10 ? `0${hour}` : `${hour}`}:${
      minute < 10 ? `0${minute}` : `${minute}`
    }`;
    setValueTime(time);
    timePost(time);
  };

  const handleTimeClick = () => {
    if (!disabledInput && checkDisabled) {
      setIsOpenCustomTime(!isOpenCustomTime);
    }
  };

  return (
    <div className='custom-select-time-wrapper'>
      <div className='time'>
        <input
          {...props}
          type='text'
          className={`csp ${classTextBox}`}
          readOnly
          value={valueTime ? valueTime : ''}
          disabled={disabledInput ? disabledInput : !checkDisabled}
          onClick={() => {
            checkDisabled && setIsOpenCustomTime(!isOpenCustomTime);
            handleOnClick && handleOnClick();
          }}
        />
        {closeIcon && valueTime && (
          <CloseIcon
            className='close-icon'
            onClick={() => {
              setValueTime();
              timePost();
            }}
          />
        )}
        {showIconV2 ? (
          <span
            className='time-icon'
            onClick={(e) => {
              handleTimeClick();
              handleOnClick && handleOnClick();
            }}>
            <ClockIconV2
              color={isOpenCustomTime ? '#DC143C' : colorDefaultIcon}
              className={`anticon ${classIcon}`}
              style={{ cursor: 'pointer' }}
            />
          </span>
        ) : (
          <ClockIcon
            className={`anticon ${classIcon}`}
            onClick={(e) => {
              !disabledInput && checkDisabled && setIsOpenCustomTime(!isOpenCustomTime);
            }}
            style={{ cursor: 'pointer' }}
          />
        )}
      </div>
      {isOpenCustomTime && (
        <div className={`custom-time-post ${classNameCustom}`} ref={refOutSide}>
          <div className='d-flex'>
            <div className='hour'>
              {new Array(24).fill(1).map((v, i) => (
                <div className='event-time' key={i}>
                  <div
                    className={`item item-hour ${hour === i && 'active'}`}
                    onClick={() => {
                      setHour(i);
                    }}>
                    {`${i}`.padStart(2, 0)}
                  </div>
                </div>
              ))}
            </div>
            <div className='minute'>
              {new Array(60).fill(1).map((v, i) => (
                <div className='event-time' key={i}>
                  <div
                    className={`item item-minute ${minute === i && 'active'}`}
                    onClick={() => {
                      setMinute(i);
                    }}>
                    {`${i}`.padStart(2, 0)}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='box-btn' ref={bottomRef}>
            <div className={`btn`} onClick={handleCustomTime}>
              {t('Common.Ok')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSelectTime;
