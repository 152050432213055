import {
  AccountSidebar,
  HeaderListIdolV2,
  LayoutCreatorRight,
  MenuSidebarCustom,
  PopupFanDetail,
} from 'components';
import { newPathUser } from 'constants/layout/constant';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  resetIDNoti,
  resetIDNotiAdmin,
  userGetBadgeNotification,
  userGetDetailNotification,
  getTabNoti,
  getIDNoti
} from 'store/actions/notification';
import Management from './managements/Managements';
import './styles.scss';
import UserNotices from './user-notices/UserNotices';
import { STORAGE } from 'utils';
import Storage from 'utils/storage';
import { resetDataInfoOther, resetIDHomePage } from 'store/actions/usersNew';

const TAB_USER = 1;
const TAB_MANAGEMENT = 2;
export default function Notification() {
  const navigate = useNavigate();
  const location = useLocation();
  const { idNoti2, idNoti1, tabNoti, initPageNoti } = useSelector((state) => state.notification);
  const [tab, setTab] = useState(tabNoti ? tabNoti : (idNoti1?.tab || idNoti2?.tab || location?.state?.tab || 1));
  const itemsPerPage = 30;

  const dispatch = useDispatch();
  const [isVisibleViewFanDetail, setIsVisibleViewFanDetail] = useState(false);
  const [idFanDetail, setIdFanDetail] = useState(null);
  const [firstLoading, setFirstLoading] = useState(false);

  const [unreadNumber, setUnreadNumber] = useState(null);
  const { dataUser } = useSelector((state) => state.users);

  const { t } = useTranslation();

  const listTab = [
    { id: 1, label: t('Creator.NotificationList.userNotice'), type: 'activity' },
    { id: 2, label: t('Creator.NotificationList.noticeFromManagement'), type: 'admin' },
  ];

  const [notifiRead, setNotifiRead] = useState([]);
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  useEffect(() => {
    if (isAuth) {
      dispatch(userGetBadgeNotification());
    }
  }, [tab]);

  const handleSetDataUnread = ({ success, data }) => success && setUnreadNumber(data);

  const handleNavigate = (notify) => {
    const { content_type, post_id, user_id, admin_id, title, created_by_admin, user } = notify;
    switch (true) {
      case tab === TAB_MANAGEMENT && created_by_admin === 1:
        dispatch(getIDNoti({ id: notify?.id, tab: 1, page: initPageNoti }));
        navigate(`${newPathUser}user/profile/notification/${notify.id}`, {
          state: { tab: TAB_MANAGEMENT },
        });
        break;
      case tab === TAB_MANAGEMENT && ['20', '21'].includes(title):
        dispatch(getIDNoti({ id: notify?.id, tab: 1, page: initPageNoti }));
        navigate(`${newPathUser}purchase`, {
          state: { tab: TAB_MANAGEMENT },
        });
        break;
      case tab === TAB_MANAGEMENT && !['15', '16', '14'].includes(title):
        dispatch(getIDNoti({ id: notify?.id, tab: 1, page: initPageNoti }));
        navigate(`${newPathUser}message/${admin_id}`, {
          state: { tab: TAB_MANAGEMENT },
        });
        break;
      case [1, 3, 9, 19].includes(content_type):
        const prefix = notify.post_type === 2 ? 'post-sale' : 'post';
        dispatch(getIDNoti({ id: notify?.id, tab: 1, page: initPageNoti }));
        navigate(`${newPathUser}${prefix}/${post_id}`, {
          state: { tab: TAB_USER, name: 'notification' },
        });
        break;
      case [7, 2].includes(content_type):
        dispatch(getIDNoti({ id: notify?.id, tab: 1, page: initPageNoti }));
        dispatch(resetIDHomePage());
        dispatch(resetDataInfoOther())
        navigate(`${newPathUser}${user?.account_id}`, {
          state: { tab: TAB_USER, name: 'homepage' },
        });
        break;
      case [8].includes(content_type):
        dispatch(getIDNoti({ id: notify?.id, tab: 1, page: initPageNoti }));
        navigate(`${newPathUser}message/${user_id}`, {
          state: { tab: TAB_USER },
        });
        break;
      case [20].includes(content_type):
        dispatch(getIDNoti({ id: notify?.id, tab: 1, page: initPageNoti }));
        navigate(`${newPathUser}purchase`, {
          state: { tab: TAB_USER },
        });
        break;
      default:
        break;
    }
  };

  const onSeen = async (notify) => {
    dispatch(
      userGetDetailNotification(notify?.id, () => {
        setNotifiRead([...notifiRead, notify?.id]);
        isAuth && dispatch(userGetBadgeNotification());
        handleNavigate(notify);
      })
    );
  };

  const handleClickNotify = (notify) => {
    !notify?.is_read ? onSeen(notify) : handleNavigate(notify);
  };

  const RenderTab = () => {
    switch (tab) {
      case 1:
        return (
          <UserNotices
            setFirstLoading={setFirstLoading}
            handleClickNotify={handleClickNotify}
            itemsPerPage={itemsPerPage}
            notifiRead={notifiRead}
          />
        );
      case 2:
        return (
          <Management
            setFirstLoading={setFirstLoading}
            handleClickNotify={handleClickNotify}
            itemsPerPage={itemsPerPage}
            notifiRead={notifiRead}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <LayoutCreatorRight
      noButtonBack
      className='notification-layout'
      titlePage={
        tab === TAB_MANAGEMENT
          ? t('Creator.NotificationList.noticeFromManagement')
          : t('Creator.NotificationList.userNotice')
      }
      link={`${newPathUser}`}>
      {isVisibleViewFanDetail && (
        <PopupFanDetail
          closeModal={() => setIsVisibleViewFanDetail(false)}
          isVisible={isVisibleViewFanDetail}
          id={idFanDetail}
        />
      )}
      <div className='creator-notification-page'>
        <div className='tab-container'>
          {listTab.map((v, i) => (
            <div
              key={i}
              disabled={firstLoading}
              style={{ cursor: `${firstLoading ? 'default' : 'pointer'}`, pointerEvents: `${tab === i + 1 ? 'none' : 'auto'}`}}
              className={`item ${tab === i + 1 ? ` active ` : ``}`}
              onClick={() => {
                if(!firstLoading) {
                  if (tab !== i + 1) {
                    setTab(i + 1);
                    dispatch(getTabNoti(i + 1));
                    window.scrollTo(0, 0);
                  }
                  dispatch(resetIDNoti());
                  dispatch(resetIDNotiAdmin());
                }
              }}>
              {v.label}
            </div>
          ))}
        </div>
        <div className='body'>{RenderTab()}</div>
      </div>
    </LayoutCreatorRight>
  );
}
