import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { InputWrapper } from 'components/Input/InputWrapper';
import { STORAGE, isAgency, patternNoFirstSpacing } from 'utils';
import { CloseIcon } from 'images';
import { PopupConfirmFan } from 'components';
import { useDispatch } from 'react-redux';
import { deletePostByAdmin, rejectPostByAdmin } from 'store/actions/admin';
import { creatorGetPostDetail } from 'store/actions/usersNew';
import { pushNotify } from 'components/Notification';
import Storage from 'utils/storage';

const FormDeletePost = ({ title, subTitle, handleClosePopup, type, id, typePost, loadingApprovePostAdmin, setLoadingApprovePostAdmin }) => {
  const [confirm, setConfirm] = useState(false);

  const { t } = useTranslation();

  const titleConfirm =
    type === 'reject'
      ? t('Popup.AdminManagementPost.are_you_sure_you_want_to_reject_this_content_confirm')
      : t('Popup.AdminManagementPost.are_you_sure_you_want_to_remove_this_content_confirm');

  const {
    register,
    watch,
    formState: { errors },
  } = useForm({ mode: 'all' });

  const handleTextarea = (e) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
  };

  const reasonContent = watch('reason', '');

  const token = localStorage.getItem(STORAGE.USER_ACCESS_TOKEN);
  const dispatch = useDispatch();
  const agencyToken = Storage.get(STORAGE.AGENCY_TOKEN);
  const adminTokenAccess = Storage.get(STORAGE.USER_ACCESS_TOKEN);
  const handleRejectSuccess = () => {
    dispatch(creatorGetPostDetail(id, typePost, false, isAgency ? agencyToken : adminTokenAccess));
    setConfirm(false);
    setLoadingApprovePostAdmin(false);
    handleClosePopup();
    pushNotify(
      'success',
      t('Popup.AdminManagementPost.rejection_has_been_successfully_completed')
    );
  };

  const handleDeleteSuccess = () => {
    dispatch(creatorGetPostDetail(id, typePost, false, isAgency ? agencyToken : adminTokenAccess));
    setConfirm(false);
    setLoadingApprovePostAdmin(false);
    handleClosePopup();
    pushNotify(
      'success',
      t('Popup.AdminManagementPost.removal_has_been_successfully_completed')
    );
  };

  const handleRejectFail = () => {
    setConfirm(false);
    setLoadingApprovePostAdmin(false);
    handleClosePopup();
    dispatch(creatorGetPostDetail(id, typePost, false, isAgency ? agencyToken : adminTokenAccess));
  };

  const handleDeleteFail = () => {
    setConfirm(false);
    setLoadingApprovePostAdmin(false);
    handleClosePopup();
    dispatch(creatorGetPostDetail(id, typePost, false, isAgency ? agencyToken : adminTokenAccess));
  };

  const cs_admin_id = Number(localStorage.getItem('CS_ADMIN_ID'));

  return (
    <>
      {confirm ? (
        <PopupConfirmFan
          isVisiblePopup={false}
          iconNote={true}
          colorSvg='#FFBF00'
          fillColor={true}
          text={titleConfirm}
          className='popup-content sell-package-page popup-post'>
          <div
            className='ok btn'
            onClick={() => {
              if (!loadingApprovePostAdmin) {
                setLoadingApprovePostAdmin(true);
                if (type === 'reject') {
                  dispatch(
                    rejectPostByAdmin(
                      { id, reasonContent, token, cs_admin_id },
                      handleRejectSuccess,
                      handleRejectFail
                    )
                  );
                } else {
                  dispatch(
                    deletePostByAdmin(
                      { id, reasonContent, token, cs_admin_id },
                      handleDeleteSuccess,
                      handleDeleteFail
                    )
                  );
                }
              }
            }}>
            {t('Common.Ok')}
          </div>
          <div className='btn cancel' onClick={() => handleClosePopup()}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      ) : (
        <div className={`popup post-sale-performer`}>
          <div className='popup-content sale-performer popup-admin-handle-post'>
            <>
              <div className='title'>{title}</div>
              <CloseIcon className='close-icon' onClick={() => handleClosePopup()} />
              <div
                className='content'
                style={{ padding: '24px', paddingBottom: '8px', display: 'block' }}>
                <p style={{ fontSize: '14px', fontWeight: '400' }}>{subTitle}</p>
                <>
                  <p style={{ fontSize: '14px', fontWeight: '400' }}>
                    {t('Creator.Message.text')}
                    <span className='fansite-form-input__required'>*</span>
                  </p>
                  <InputWrapper
                    id='fsite-content'
                    label=''
                    required={true}
                    className={`text-post`}
                    height={'110px'}
                    marginBottom={'0'}
                    border={'1px solid  #e5e6eb'}
                    borderRadius={'6px'}
                    overflow={'auto'}>
                    <textarea
                      {...register('reason', {
                        required: t('ValidateMsg.itemRequired'),
                        maxLength: {
                          value: 500,
                          message: t('ValidateMsg.CONTENT_IS_TOO_LONG'),
                        },
                        validate: {
                          noFirstSpacing: (value) =>
                            (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                            t('ValidateMsg.itemRequired'),
                        },
                        onChange: (e) => handleTextarea(e),
                      })}
                      style={{
                        width: '100%',
                        height: !reasonContent.length ? 'auto' : '80px',
                        padding: '10px 15px',
                        border: 'none',
                      }}
                      placeholder={t('Creator.Message.placeholderTextarea')}
                      maxLength={500}
                    />
                  </InputWrapper>
                  {errors && (
                    <p className='fansite-form-input__error text-danger'>
                      {errors?.reason ? errors?.reason?.message : ''}
                    </p>
                  )}
                </>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  borderTop: '1px solid #F0F0F0',
                  padding: '20px 15px',
                }}>
                <div className=''>
                  <button
                    disabled={!reasonContent.length}
                    onClick={() => {
                      setConfirm(true);
                    }}
                    className='btn btn-bg-red text-white'>
                    {t('Common.send')}
                  </button>
                </div>
                <div className=''>
                  <button
                    onClick={() => {
                      handleClosePopup();
                    }}
                    className='btn'
                    style={{
                      border: '1px solid #F0F0F0',
                      marginLeft: '10px',
                      minWidth: '119px',
                    }}>
                    {t('Common.buttonCancel')}
                  </button>
                </div>
              </div>
            </>
          </div>
        </div>
      )}
    </>
  );
};

export default FormDeletePost;
