import useUserRole from 'hooks/useUserRole';
import { useLayoutEffect } from 'react';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

const withCreatorRole = (WrappedComponent) => {
  const WithCreatorRole = (props) => {
    const { isFan } = useUserRole();
    useLayoutEffect(() => {
      if (isFan === true) {
        window.location.href = '/my-page';
      }
    }, []);
    if (isFan === true) {
      return null;
    }
    return <WrappedComponent {...props} />;
  };
  WithCreatorRole.displayName = `WithCreatorRole${getDisplayName(WrappedComponent)}`;
  return WithCreatorRole;
};

export default withCreatorRole;
