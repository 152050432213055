import { LayoutCreatorRight, LayoutFan, PopupLoading, ToggleButton } from 'components';
import useUserRole from 'hooks/useUserRole';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import '../styles.scss';

export default function SettingLanguage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { isIdol } = useUserRole();
  const [loading, setLoading] = useState(false);

  const handleChangeLange = () => {
    setLoading(true);
    i18n.changeLanguage(i18n.language === 'jp' ? 'en' : 'jp');
    setTimeout(() => {
      navigate(0);
    }, 500);
  };

  return (
    <>
      <LayoutCreatorRight titlePage={t('Notification.settingLanguage.title')}>
        {loading && <PopupLoading className={'popup-loading loading-reload-page'} />}
        <div className='notification-page' style={{ background: 'white' }}>
          <div className='setting-noti creator'>
            <div className='toggle'>
              <label>{t('Common.jp')}</label>
              <div>
                <ToggleButton
                  id='message_notification'
                  checked={i18n.language === 'jp'}
                  onChange={handleChangeLange}
                />
              </div>
            </div>
            <div className='toggle'>
              <label>{t('Common.en')}</label>
              <div>
                <ToggleButton
                  id='admin_notification'
                  checked={i18n.language === 'en'}
                  onChange={handleChangeLange}
                />
              </div>
            </div>
          </div>
        </div>
      </LayoutCreatorRight>
    </>
  );
}
