import { createContext } from 'react';
import { Card } from './Card';

export const TimelineContext = createContext();
TimelineContext.displayName = 'Store Timeline - short video';
export const ListImgTimelinePostDetail = ({ data = [], elementBookmark = () => {} }) => {
  return (
    <TimelineContext.Provider value={{ data, elementBookmark }}>
      <Card />
    </TimelineContext.Provider>
  );
};
