/* eslint-disable no-undef */
import { PlayIcon } from 'images';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import ReactHlsPlayer from 'react-hls-player';
import './index.scss';
import { decryptPath } from 'utils';
import useDisableContextMenu from 'hooks/useDisableContextMenu';
import useCheckFullVideo from 'hooks/v2/useCheckFullVideo';
import { StyledTimeline } from 'pages/timeline/elements/styled';
import styled, { css } from 'styled-components';

const WrapperHideIconVideo = styled.div`
  width: 100%;
  //height: 100%;
  position: relative;
  padding-top: 56.25%;
  video {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    aspect-ratio: 16 / 9;
  }
  video::-webkit-media-controls-overlay-play-button {
    ${({ isFull = false }) =>
      isFull
        ? css`
            display: block;
          `
        : css`
            display: none;
          `};
  }

  *::-webkit-media-controls-start-playback-button {
    ${({ isFull = false }) =>
      isFull
        ? css`
            display: block;
          `
        : css`
            display: none !important;
            -webkit-appearance: none;
          `};
  }
`;
function VideoCustomDetail({
  src,
  thumbnail,
  handleNavigate,
  isDetailPost = false,
  noPlayVideo = false,
  isLiveArchive = false,
  onLoadedData,
  ...props
}) {
  const ref = useRef();
  const buttonRef = useRef();
  const [showButton, setShowButton] = useState(true);
  const [showThumbnail, setShowThumbnail] = useState(true);
  useDisableContextMenu(ref);
  useLayoutEffect(() => {
    if (isLiveArchive) return;
    ref.current?.setAttribute('playsinline', '');
    if (ref.current && buttonRef.current) {
      ref.current.addEventListener('click', function () {
        if (!this.paused) this.pause();
        else {
          isDetailPost ? this.play() : handleNavigate();
        }
        isDetailPost && setShowButton(true);
      });
    }
  }, [ref, buttonRef]);

  const [isPlaying, setIsPlaying] = useState(false);
  const [controlCustom, setControlCustom] = useState(false);
  const [isFullScreen, setIsFullScreen] = useCheckFullVideo(ref);

  return (
      <WrapperHideIconVideo>
        {!isPlaying && controlCustom && (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '70%',
              zIndex: 32,
              top: 0,
            }}></div>
        )}
        <ReactHlsPlayer
          playerRef={ref}
          style={{ objectFit: 'contain', zIndex: 30 }}
          src={src}
          autoPlay={false}
          controls={isFullScreen ? true : controlCustom}
          className={props.className}
          controlsList='nodownload noremoteplayback noplaybackrate foobar'
          disablePictureInPicture
          preload='auto'
          playsInline={true}
          onLoadedData={onLoadedData}
          onPause={(e) => {
            if (ref.current.seeking) return;
            ref.current.pause();
            setControlCustom(true);
            setIsPlaying(false);
            setShowThumbnail(false);
          }}
          onPlay={() => {
            ref.current.play();
            setIsPlaying(true);
            setControlCustom(true);
            setShowThumbnail(false);
          }}
        />

        {showThumbnail && thumbnail && (
          <div className='video-thumbnail'>
            {!isLiveArchive ? (
              <img src={thumbnail} alt='thumbnail' className='thumbnail-img' />
            ) : (
              <div
                className='blue-img'
                style={{ backgroundImage: `url("${decryptPath(thumbnail)}")` }}
                url={thumbnail}
                height={360}
                width={640}
                onClick={() => {
                  if (setOpenIndex) {
                    setOpenIndex(index);
                  }
                }}>
                <div className='bg-img'></div>
                <img
                  className='change-bg image-item'
                  screen='image_post_detail_auto_360'
                  src={thumbnail}
                  alt=''
                />
              </div>
            )}
          </div>
        )}
        {!isFullScreen && (
          <StyledTimeline.ButtonPlay
            widthButton='56px'
            heightButton='56px'
            onClick={(e) => {
              e.stopPropagation();
              if (isPlaying) {
                setIsPlaying(false);
                setControlCustom(true);
                ref.current.pause();
              } else {
                setIsPlaying(true);
                setControlCustom(true);
                ref.current.play();
              }
            }}>
            {!isPlaying && (
              <PlayIcon
                style={{
                  filter: 'drop-shadow(0px 0px 0.5px #000)',
                }}
              />
            )}
          </StyledTimeline.ButtonPlay>
        )}
      </WrapperHideIconVideo>
  );
}

export default VideoCustomDetail;
