import { ImgCustom } from 'components';
import { InputWrapper } from 'components/FormLogin';
import { LazyAware } from 'components/v2/LazyAware';
import { newPathUser } from 'constants/layout/constant';
import { onValue, ref } from 'firebase/database';
import TopListIcon from 'images/TopListIcon';
import Top1Icon from 'images/rank/Top1Icon';
import Top2Icon from 'images/rank/Top2Icon';
import Top3Icon from 'images/rank/Top3Icon';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { setIdScrollBackRecommend } from 'store/actions/usersNew';
import { CustomOption, customStylesReselect, isAdmin, isAgency } from 'utils';
import { DBRealTimeTopList } from '../../firebase/config';
import { Styled } from './styles';

const SideBarTopList = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { typeTopList } = useSelector((state) => state.usersNew);
  const [listItem, setListItem] = useState([]);

  const optionDestinationType = useMemo(() => {
    return [
      {
        value: 'live',
        label: i18n.t('MenuSiderbar.duringDelivery'),
      },
      {
        value: 'month',
        label: i18n.t('Common.typeMonth'),
      },
    ];
  }, [i18n.language]);
  const [typeTop, setTypeTop] = useState(typeTopList || optionDestinationType[0]);

  useEffect(() => {
    if (typeTop?.value) {
      setListItem([]);
      const messageRef = ref(DBRealTimeTopList, `/top-users/livestreams/${typeTop?.value}`);
      onValue(
        messageRef,
        (snapshot) => {
          const newData = [];
          snapshot.forEach((ss) => {
            newData.push(ss.val());
          });
          if (newData.length > 0) {
            setListItem(newData.sort((a, b) => Number(a?.rank) - Number(b?.rank)));
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [typeTop?.value]);

  const renderIconRank = (index, rank) => {
    if (index === 0 && rank === 1) {
      return <Top1Icon />;
    } else if (index === 1 && rank === 2) {
      return <Top2Icon />;
    } else if (index === 2 && rank === 3) {
      return <Top3Icon />;
    }
    return (
      <>
        {i18n.language === 'en' && <span>{t('Acount.FanRankingItem.rankingItem')}&nbsp;</span>}
        {rank}
        {i18n.language !== 'en' && <span>&nbsp;{t('Acount.FanRankingItem.rankingItem')}</span>}
      </>
    );
  };
  return (
    <Styled.WrapperFixed>
      <Styled.Wrapper>
        <Styled.CocoFansRecommendWrapper>
          <Styled.Title>{t('SidebarRecommendPost.titleTopList')}</Styled.Title>
          <InputWrapper id='type'>
            <Select
              options={optionDestinationType}
              value={optionDestinationType?.find((e) => e.value === typeTop.value)}
              isSearchable={false}
              isClearable={false}
              placeholder={t('Creator.Message.destinationType')}
              width='100%'
              styles={customStylesReselect}
              components={{ Option: CustomOption }}
              onChange={(val) => {
                setTypeTop(val);
              }}
            />
          </InputWrapper>
          <Styled.ListCocoFansRecommend>
            {listItem?.map((item, index) => (
              <LazyAware
                key={item?.user_id}
                render={({ ref, wasIntersected }) => (
                  <div
                    ref={ref}
                    className='item'
                    key={item?.user_id}
                    onClick={() => {
                      if (!isAdmin && !isAgency) {
                        dispatch(
                          setIdScrollBackRecommend(`top-list`, pathname, typeTop, () => {
                            navigate(`${newPathUser}${item?.account_id}`);
                          })
                        );
                      }
                    }}>
                    {wasIntersected && (
                      <Styled.ItemList>
                        <div
                          className={`item-rank ${listItem?.length > 3 ? 'width-large' : ''}`}>
                          {renderIconRank(index, item?.rank)}
                        </div>
                        <ImgCustom
                          isPlaceholderAvatar
                          screen='recommend_top_56_56'
                          src={`${item?.avatar}`}
                        />
                        <div className='name'>{item?.account_name}</div>
                      </Styled.ItemList>
                    )}
                  </div>
                )}
              />
            ))}
            {!listItem?.length && (
              <>
                <Styled.NoData>
                  <div>
                    <TopListIcon />
                  </div>
                  <div className='text'>{t('SidebarRecommendPost.noDataListCreator')}</div>
                </Styled.NoData>
              </>
            )}
          </Styled.ListCocoFansRecommend>
        </Styled.CocoFansRecommendWrapper>
      </Styled.Wrapper>
    </Styled.WrapperFixed>
  );
};

export default SideBarTopList;
