import Icon from '@ant-design/icons';

const RePostIconV2 = (props) => (
  <Icon
    component={() => (
      <svg
        width={20}
        height={16}
        viewBox='0 0 20 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M18.4917 12.5588L16.1748 14.875L13.8594 12.5588'
          stroke='#BEBEBE'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.625 0.375C10.2108 0.375 9.875 0.710786 9.875 1.125C9.875 1.53921 10.2108 1.875 10.625 1.875V0.375ZM10.625 1.875H12.25V0.375H10.625V1.875ZM15.5 5.125V14.25H17V5.125H15.5ZM12.25 1.875C14.0449 1.875 15.5 3.33007 15.5 5.125H17C17 2.50165 14.8734 0.375 12.25 0.375V1.875Z'
          fill='#BEBEBE'
        />
        <path
          d='M1.50827 3.55147L3.82522 1.125L6.14062 3.55147'
          stroke='#BEBEBE'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.375 15.625C9.78921 15.625 10.125 15.2892 10.125 14.875C10.125 14.4608 9.78921 14.125 9.375 14.125L9.375 15.625ZM9.375 14.125L7.82353 14.125L7.82353 15.625L9.375 15.625L9.375 14.125ZM4.57353 10.875L4.57353 1.52941L3.07353 1.52941L3.07353 10.875L4.57353 10.875ZM7.82353 14.125C6.02861 14.125 4.57353 12.6699 4.57353 10.875L3.07353 10.875C3.07353 13.4984 5.20018 15.625 7.82353 15.625L7.82353 14.125Z'
          fill='#BEBEBE'
        />
      </svg>
    )}
    {...props}
  />
);

export default RePostIconV2;
