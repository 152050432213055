import {
  ImgCustom,
  LayoutCreatorRight,
  LayoutRightWithSidebar,
  PopupConfirmFan,
  PopupLoading,
} from 'components';
import SendCoinPopupLive from 'components/Popup/sendCoinLive';
import React, { useEffect } from 'react';
import { useState, useMemo } from 'react';
import { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AVATAR_DEFAULT, isAuth, messageValidate } from 'utils';
import { WrapperLives } from '../components/WrapperLives';
import { LivestreamFanContext } from '../liveStream';
import { Live } from '../ViewFan/Live';
import { Content } from '../ViewFan/Content';
import {
  Block,
  Button,
  GlobalWrapper,
  Wrapper,
} from 'pages/creator/layout/livestream/components/styled';
import { ListContentCard } from '../ViewFan/ListContentCard';
import { followUnFollowUserByType, createTokenAgoraGuest } from 'store/actions/lives';
import { useTranslation } from 'react-i18next';
import { getDetailCreator } from 'store/actions/creator';
import { useEffectUpdateTime } from 'components/LiveStream/V5/Videos/EffectCreator';
import { getEnv } from 'configs/env';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { useAgora } from 'components/LiveStream/V5/Videos/ObsAgora/useAgora';
import { MediaPlayer } from 'components/LiveStream/V5/Videos/ObsAgora/MediaAgora/MediaPlayer';
import { useParams } from 'react-router-dom';
import BorderImgLiveIcon from 'images/livestreams/BorderImgLiveIcon';
import { LiveStreamPageWatchContentStyledMobile } from '../components/styled';
import LoadingIconV2 from 'images/LoadingIconV2';
import { getUserInfoMe } from 'store/actions/users';

const appID = getEnv('REACT_APP_AGORA_APP_ID');
const client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
client.setClientRole('audience', {
  level: 1,
});

export const ViewFan = () => {
  const { userInfo, useInfoLoading } = useSelector((state) => state.lives);
  const { data: infoFan } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [creatorProfile, setCreatorProfile] = useState();
  const [isEnoughPoints, setIsEnoughPoints] = useState(null);
  const [pauseLive, setPauseLive] = useState(true);
  const [openIOS, setOpenIOS] = useState(false);
  const { join: joinAgora, remoteUsers: remoteAgora, leave } = useAgora(client, appID);
  const [tokenAgora, setTokenAgora] = useState(null);
  const { liveKey } = useParams();
  const findChannel = useMemo(() => {
    const checkList = remoteAgora.filter((item) => item?.hasAudio || item?.hasVideo);
    const result = checkList.pop();
    const obj = {
      ViewLive: (
        <MediaPlayer
          type={userInfo?.livestream?.obs_flag === 1 ? 'obs' : 'noobs'}
          videoTrack={result?.videoTrack}
          audioTrack={result?.audioTrack}
        />
      ),
      Result: result,
    };
    return obj;
  }, [remoteAgora, userInfo]);

  // USER_JOINED
  const handleUserJoined = (user, media, c) => {
    if (user?.uid === findChannel.Result?.uid) {
      if (user?.hasVideo) {
        setPauseLive(false);
      } else {
        setPauseLive(true);
      }
    }
  };

  const handleUserUnPublished = (user, media) => {
    if (user?.uid === findChannel.Result?.uid) {
      if (user?.hasVideo) {
        setPauseLive(false);
      } else {
        setPauseLive(true);
      }
    }
  };
  const handleUserPublished = (user, media) => {
    if (user?.hasVideo) {
      setPauseLive(false);
    } else {
      setPauseLive(true);
    }
  };
  const handleUserLeft = (user, reason) => {
    if (reason === 'Quit' && userInfo?.livestream?.obs_flag !== 1) {
      setPauseLive(true);
    }
    if (reason === 'ServerTimeOut') {
      window.location.reload();
    }
  };
  useEffect(() => {
    client.on('user-joined', handleUserJoined);
    client.on('user-unpublished', handleUserUnPublished);
    client.on('user-published', handleUserPublished);
    client.on('user-left', handleUserLeft);
    return () => {
      client.off('user-published', handleUserPublished);
      client.off('user-unpublished', handleUserUnPublished);
      client.off('user-joined', handleUserJoined);
      client.off('user-left', handleUserLeft);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, findChannel.Result]);

  useEffect(() => {
    return () => {
      leave()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (liveKey) {
      dispatch(
        createTokenAgoraGuest({
          // name: `${data?.user?.account_name}_${data?.live_key}`,
          name: liveKey,
          exSec: 300,
          action: (tk) => {
            setTokenAgora(encodeURIComponent(tk));
            joinAgora(liveKey, tk);
          },
        })
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [liveKey]);

  useEffect(() => {
    if (isAuth) {
      dispatch(getUserInfoMe());
    }
  }, [dispatch]);

  const {
    isVisibleSendCoinPopup,
    handleSendCoin,
    setIsVisibleSendCoinPopup,
    remainPoint,
    isFollowOrUnFollow,
    setIsFollowOrUnFollow,
    handleEventFanQuitLive,
    handleEventFanQuitWhenEnoughsPoint,
    handleNavigateAvatar,
    handleEventFanQuitWhenSubcriber,
  } = useContext(LivestreamFanContext);
  const [isUserLogin, setIsUserLogin] = useState(null);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    userInfo?.is_auth === 1 &&
      userInfo?.livestream?.user?.account_id &&
      dispatch(
        getDetailCreator(userInfo?.livestream?.user?.account_id, ({ data }) => {
          setCreatorProfile(data);
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.livestream?.user?.account_id]);

  const { showEffectUpdate } = useEffectUpdateTime();
  return (
    <>
      {(isLoading || useInfoLoading) && (
        <div className='layer-spinner'>
          <LoadingIconV2 className='spinner-icon' />
        </div>
      )}
      <LayoutRightWithSidebar
        titlePage={t('Creator.CreateLiveStream.livestream')}
        view='view-live'>
        {showEffectUpdate ? (
          <>
            <PopupLoading className={'popup-loading'} />
            <GlobalWrapper mh='calc(100% - 72px)' />
          </>
        ) : (
          <WrapperLives>
            {isVisibleSendCoinPopup && (
              <SendCoinPopupLive
                handleEventFanQuitWhenEnoughsPoint={handleEventFanQuitWhenEnoughsPoint}
                setIsEnoughPoints={setIsEnoughPoints}
                valueCoin={handleSendCoin}
                isVisiblePopup={() => setIsVisibleSendCoinPopup(false)}
                currentCoin={remainPoint || 0}
                liveId={userInfo?.livestream?.id}
                creatorId={userInfo?.livestream?.user_id}
              />
            )}
            {isEnoughPoints !== null && !isEnoughPoints && (
              <PopupConfirmFan
                isVisiblePopup={() => setIsEnoughPoints(null)}
                colorSvg='#FF9F43'
                text={t('Popup.NotEnoughPointsLive')}>
                <div
                  className='ok btn'
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    return handleEventFanQuitWhenEnoughsPoint();
                  }}>
                  OK
                </div>
                <div className='btn cancel' onClick={() => setIsEnoughPoints(null)}>
                  {t('Common.buttonCancel')}
                </div>
              </PopupConfirmFan>
            )}

            {/* //* USER NOT LOGIN CLICK FOLLOW, DONATE, LIKE --> SHOW POPUP */}
            {isUserLogin !== null && !isUserLogin && (
              <PopupConfirmFan
                className='creator-confirm-close-live'
                colorSvg='#FF9F43'
                text={messageValidate.F014_MESSAGE_GUESS_WHEN_ACTION_IN_LIVE}>
                <div
                  className={`btn ok`}
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setIsUserLogin(null);
                  }}>
                  OK
                </div>
              </PopupConfirmFan>
            )}
            {/* //* END USER NOT LOGIN CLICK FOLLOW, DONATE, LIKE SHOW POPUP */}
            <GlobalWrapper>
              <LiveStreamPageWatchContentStyledMobile>
                <div className={`live-stream-page__left `}>
                  <LayoutCreatorRight
                    cb={handleEventFanQuitLive}
                    className={`watch-create-live-header ${openIOS ? 'openIOS' : ''}`}
                    titlePage={t('Creator.CreateLiveStream.livestream')}>
                    <div className='live-stream-page-left-content'>
                      <div className='idol-info-live'>
                        <div className='idol-info-live__left'>
                          <div
                            className='idol-info-live-img'
                            onClick={() => {
                              handleNavigateAvatar(userInfo?.livestream?.user?.account_id);
                            }}>
                            <BorderImgLiveIcon className='border-img-icon' />
                            <ImgCustom
                              style={{ borderRadius: '50%' }}
                              isPlaceholderAvatar
                              src={
                                userInfo?.livestream?.user?.avatar === null
                                  ? AVATAR_DEFAULT
                                  : `${userInfo?.livestream?.user?.avatar}`
                              }
                              alt=''
                            />
                          </div>
                          <div className='idol-info-live-content'>
                            <div className='idol-info-live-content__title'>
                              {userInfo?.livestream?.live_title}
                            </div>
                            <div className='idol-info-live-content__name'>
                              {userInfo?.livestream?.user?.account_name}
                            </div>
                          </div>
                        </div>
                        <div className='idol-info-live__right'>
                          <Button
                            style={{
                              whiteSpace: 'nowrap',
                              background: `${isFollowOrUnFollow === 1 ? '#dc143c' : 'white'}`,
                              color: `${isFollowOrUnFollow === 1 ? 'white' : '#1a1a1a'}`,
                            }}
                            className='btn-follow-live'
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              if (userInfo?.is_auth === 1) {
                                // CALL API FOLLOW
                                if (
                                  isFollowOrUnFollow !== undefined &&
                                  isFollowOrUnFollow === 1
                                ) {
                                  dispatch(
                                    followUnFollowUserByType(
                                      userInfo?.livestream?.user_id,
                                      true
                                    )
                                  );
                                  setIsFollowOrUnFollow(0);
                                } else {
                                  dispatch(
                                    followUnFollowUserByType(
                                      userInfo?.livestream?.user_id,
                                      true
                                    )
                                  );
                                  setIsFollowOrUnFollow(1);
                                }
                              } else {
                                setIsUserLogin(false);
                              }
                            }}>
                            {isFollowOrUnFollow === 1
                              ? t('Common.unfollow')
                              : t('Common.follow')}
                          </Button>
                        </div>
                      </div>
                      <Wrapper>
                        <Live
                          openIOS={openIOS}
                          setOpenIOS={setOpenIOS}
                          pauseLive={pauseLive}
                          tokenAgora={tokenAgora}
                          findChannel={findChannel}
                        />
                      </Wrapper>
                      <Block w='-webkit-fill-available' bgc='1'>
                        <ListContentCard
                          handleEventFanQuitWhenSubcriber={handleEventFanQuitWhenSubcriber}
                        />
                      </Block>
                    </div>
                  </LayoutCreatorRight>
                </div>
                <div className={`live-stream-page__right`}>
                  <Content setIsUserLogin={setIsUserLogin} />
                </div>
              </LiveStreamPageWatchContentStyledMobile>
            </GlobalWrapper>
          </WrapperLives>
        )}
      </LayoutRightWithSidebar>
    </>
  );
};
