import { Header, ImgCustom, PopupLoading, ReactCalendar } from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import useCalendar from 'hooks/useCalendar';
import useOutsideClickCustom from 'hooks/useOutsideClickCustom';
import { ArrowLeftIcon, CalendarIcon, EyeIcon, HiddenIcon } from 'images';
import React, { useEffect, useRef, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { getInfoUserTwitter } from 'store/actions/twitter';
import { signUp } from 'store/actions/users';
import {
  customStyles,
  GENDER,
  isEmptyObject,
  LOGO_COCO_FANS,
  patternEmail,
  patternNoFirstSpacing,
  STORAGE,
  CustomOption,
} from 'utils';
import storage from 'utils/storage';
import { fanAffiliatorWithBanner } from '../../store/actions/affiliator';
import './signUpWrapper.scss';

const SignUp = () => {
  const navigate = useNavigate();
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [hiddenConfirmPassword, setHiddenConfirmPassword] = useState(true);
  const [viewedPolicyTerm, setViewedPolicyTerm] = useState({
    policy: false,
    term: false,
    checked: false,
  });
  const { t, i18n } = useTranslation();
  const { search } = useLocation();
  const [focusGender, setFocusGender] = useState(false);
  const password = useRef({});
  const confirmPassword = useRef({});
  const dispatch = useDispatch();
  const { date, currentAge, toggleCalendar, onChangeDate, isVisibleCalendar } = useCalendar();
  const { loading, signUpSuccess } = useSelector((state) => state.users);
  const checkTokenTwitter =
    storage.has(STORAGE.TOKEN_TWITTER) && storage.has(STORAGE.SERECT_TOKEN_TWITTER);
  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 18);

  const datePickerRef = useRef({});
  useOutsideClickCustom((e) => {
    if (isVisibleCalendar) {
      const { className } = e?.target;
      if (
        e?.target !== datePickerRef?.current &&
        className !== '' &&
        !className.includes('react-calendar')
      ) {
        toggleCalendar();
      }
    }
  });

  const {
    register,
    handleSubmit,
    watch,
    control,
    setValue,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: 'all', defaultValues: { is_affiliator: 0 } });
  password.current = watch('password');
  confirmPassword.current = watch('re_password');

  const watchFullName = watch('full_name');
  const watchGender = watch('gender');
  const watchEmail = watch('email');
  const watchPassword = watch('password');
  const watchConfirmPassword = watch('re_password');
  const watchPolicy = watch('allow_policy');
  // const watchAffiliator = watch('is_affiliator');

  useEffect(() => {
    reset();
    if (search) {
      const checkLinkAffiliator = search.split('?affiliator=')?.[1];
      checkLinkAffiliator &&
        dispatch(fanAffiliatorWithBanner({ affiliator: checkLinkAffiliator }));
    }
    if (checkTokenTwitter)
      dispatch(
        getInfoUserTwitter(
          {
            token: storage.get(STORAGE.TOKEN_TWITTER),
            secret: storage.get(STORAGE.SERECT_TOKEN_TWITTER),
          },
          ({ data }) => {
            setValue('email', data?.email);
          }
        )
      );
  }, []);

  useEffect(() => {
    signUpSuccess === true && navigate('/sign-up/success');
  }, [signUpSuccess]);

  const isDisabledButton =
    !watchFullName ||
    !watchPassword ||
    !watchConfirmPassword ||
    !watchEmail ||
    !watchGender ||
    currentAge < 18 ||
    !currentAge ||
    loading ||
    !watchPolicy ||
    // !watchAffiliator ||
    !isEmptyObject(errors);

  const onSubmit = (data) => {
    const affiliator_ref = search.includes('affiliator') ? search.split('=')?.[1] : '';
    const newData = {
      ...data,
      birthday: date,
      affiliator_ref: affiliator_ref,
    };
    dispatch(signUp(newData, checkTokenTwitter));
  };

  const customStyleReactSelect = {
    ...customStyles,
    dropdownIndicator: (base) => ({
      ...base,
      display: 'block',
    }),
  };

  const handleCheckPolicyTerm = (type) => {
    const viewPolicyTermState = { ...viewedPolicyTerm, [type]: true };

    if (viewPolicyTermState.term && viewPolicyTermState.policy && !viewedPolicyTerm.checked) {
      setValue('allow_policy', true);
    }

    const checked = viewedPolicyTerm.checked
      ? true
      : viewPolicyTermState.term && viewPolicyTermState.policy;

    setViewedPolicyTerm({ ...viewPolicyTermState, checked });
  };

  const WithDropdownIndicator = () => {
    return <ArrowLeftIcon className={`${focusGender ? 'focus' : 'no-focus'}`} />;
  };

  return (
    <div>
      {loading && <PopupLoading className={'popup-loading'} />}
      <div className='fansite-sign-up'>
        <div className='box-sign-up box-sign-up-fan'>
          <div className='header'>
            <ImgCustom
              src={LOGO_COCO_FANS}
              height={32}
              width={94}
              alt='logo'
              className='logo-cocofans'
            />
          </div>
          <form
            onSubmit={(e) => e.preventDefault()}
            className='fansite-form'
            autoComplete='off'>
            <div
              className='title display-linebreak'
              style={{
                fontSize:
                  storage.has(STORAGE.TOKEN_TWITTER) &&
                  storage.has(STORAGE.SERECT_TOKEN_TWITTER)
                    ? 20
                    : 24,
              }}>
              {storage.has(STORAGE.TOKEN_TWITTER) && storage.has(STORAGE.SERECT_TOKEN_TWITTER)
                ? t('SignUp.titleTwitter')
                : t('SignUp.title')}
            </div>
            <InputWrapper
              id='fsite-japan-name'
              label={t('Common.nameJP')}
              required={true}
              error={errors?.full_name ? errors?.full_name?.message : ''}>
              <input
                {...register('full_name', {
                  required: t('ValidateMsg.USERNAME_IS_REQUIRED'),
                  maxLength: {
                    value: 30,
                    message: t('ValidateMsg.C002_VALIDATE_ACCOUNT_MAXSIZE'),
                  },
                  validate: {
                    noFirstSpacing: (value) =>
                      (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                      t('ValidateMsg.USERNAME_IS_REQUIRED'),
                  },
                })}
                maxLength={30}
                placeholder={t('ValidateMsg.USERNAME_IS_REQUIRED')}
              />
            </InputWrapper>

            <InputWrapper
              id='fsite-birthday'
              label={t('Common.birthday')}
              required={true}
              error={errors?.birthday ? errors?.birthday?.message : ''}>
              <div className='birthday'>
                <CalendarIcon onClick={toggleCalendar} />
                <input
                  placeholder='YYYY/MM/DD'
                  {...register('birthday')}
                  value={date}
                  readOnly
                  style={{ cursor: 'pointer' }}
                  className='input-react-calendar'
                  onClick={toggleCalendar}
                />
                {isVisibleCalendar && (
                  <ReactCalendar onChange={onChangeDate} inputRef={datePickerRef} />
                )}
              </div>
            </InputWrapper>

            {+currentAge < 18 && (
              <div
                className='fansite-form-input__error text-danger'
                style={{ marginTop: -12 }}>
                {t('ValidateMsg.birthday18Age')}
              </div>
            )}

            <InputWrapper
              id='fsite-gender'
              label={t('Common.gender')}
              required={true}
              error={errors?.gender ? errors?.gender?.message : ''}>
              <div className='gender'>
                <Controller
                  name='gender'
                  control={control}
                  rules={{ required: t('ValidateMsg.genderRequired') }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      options={GENDER}
                      onFocus={() => setFocusGender(true)}
                      onBlur={() => setFocusGender(false)}
                      isSearchable={false}
                      onChange={(val) => {
                        field.onChange(val);
                        setFocusGender(false);
                      }}
                      placeholder={t('ValidateMsg.genderRequired')}
                      styles={customStyleReactSelect}
                      components={{
                        DropdownIndicator: WithDropdownIndicator,
                        Option: CustomOption,
                      }}
                    />
                  )}
                />
              </div>
            </InputWrapper>

            <InputWrapper
              id='fsite-email'
              label={t('Common.email')}
              required={true}
              error={errors?.email ? errors?.email?.message : ''}>
              <input
                type='text'
                placeholder={t('ValidateMsg.emailRequired')}
                autoComplete='false'
                {...register('email', {
                  required: t('ValidateMsg.emailRequired'),
                  validate: {
                    noFirstSpacing: (value) => {
                      return (
                        (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                        t('ValidateMsg.emailRequired')
                      );
                    },
                    email: (value) => {
                      return (
                        (value && patternEmail.test(value.toString().trim())) ||
                        t('ValidateMsg.EMAIL_FORMAT_INVALID')
                      );
                    },
                  },
                })}
                maxLength={255}
              />
            </InputWrapper>

            <InputWrapper
              id='fsite-password'
              label={t('Common.password')}
              required={true}
              error={errors?.password ? errors?.password?.message : ''}>
              <div className='password'>
                <input
                  {...register('password', {
                    required: t('ValidateMsg.passwordRequired'),
                    minLength: {
                      value: 8,
                      message: t('ValidateMsg.minPassword'),
                    },
                    validate: {
                      noFirstSpacing: (value) =>
                        (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                        t('ValidateMsg.passwordRequired'),
                      checkPass: (value) =>
                        value !== confirmPassword.current && confirmPassword.current
                          ? t('ValidateMsg.passwordNoMatchRePassword')
                          : null,
                    },
                    onChange: (e) => {
                      const { value } = e.target;
                      !(
                        !patternNoFirstSpacing.test(value.toString().trim()) ||
                        !value === confirmPassword.current
                      ) && clearErrors(['re_password', 'password']);
                    },
                  })}
                  autoComplete='false'
                  placeholder={t('ValidateMsg.passwordRequired')}
                  type={`${hiddenPassword ? 'password' : 'text'}`}
                />
                {hiddenPassword ? (
                  <HiddenIcon onClick={() => setHiddenPassword(false)} />
                ) : (
                  <EyeIcon onClick={() => setHiddenPassword(true)} />
                )}
              </div>
            </InputWrapper>

            <InputWrapper
              id='fsite-confirm-password'
              label={t('SignUp.rePassword')}
              required={true}
              error={errors?.re_password ? errors?.re_password?.message : ''}>
              <div className='confirm-password'>
                <input
                  {...register('re_password', {
                    required: t('ValidateMsg.rePasswordRequired'),
                    minLength: {
                      value: 8,
                      message: t('ValidateMsg.minPassword'),
                    },
                    validate: {
                      noFirstSpacing: (value) =>
                        (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                        t('ValidateMsg.rePasswordRequired'),
                      checkPass: (value) =>
                        value === password.current ||
                        t('ValidateMsg.passwordNoMatchRePassword'),
                    },
                    onChange: (e) => {
                      const { value } = e.target;
                      !(
                        !patternNoFirstSpacing.test(value.toString().trim()) ||
                        !value === password.current
                      ) && clearErrors(['re_password', 'password']);
                    },
                  })}
                  placeholder={t('ValidateMsg.rePasswordRequired')}
                  type={`${hiddenConfirmPassword ? 'password' : 'text'}`}
                />
                {hiddenConfirmPassword ? (
                  <HiddenIcon onClick={() => setHiddenConfirmPassword(false)} />
                ) : (
                  <EyeIcon onClick={() => setHiddenConfirmPassword(true)} />
                )}
              </div>
            </InputWrapper>

            {/* <InputWrapper id='fsite-type-account' className='is_affiliator'>
              <div className='box-radio-checkbox'>
                <label className='item'>
                  <input type='radio' value={0} {...register('is_affiliator')} />
                  <span className='checkmark radio'></span>
                  <span className='text'>{t('SignUp.becomeAFan')}</span>
                </label>
                <label className='item'>
                  <input type='radio' {...register('is_affiliator')} value={1} />
                  <span className='checkmark radio'></span>
                  <span className='text'>{t('SignUp.becomeAnAffilate')}</span>
                </label>
              </div>
            </InputWrapper>
            <label className='note-policy'>{t('SignUp.pleaseChoseOne')}</label> */}

            <div className='agree-policy'>
              <input
                type='checkbox'
                id='allow_policy'
                className='form-check-input mt-0'
                name='allow_policy'
                {...register('allow_policy')}
                style={{
                  pointerEvents:
                    viewedPolicyTerm.policy && viewedPolicyTerm.term ? 'auto' : 'none',
                  cursor:
                    viewedPolicyTerm.policy && viewedPolicyTerm.term ? 'pointer' : 'auto',
                }}
              />
              <label className='ps-2'>
                {i18n.language === 'en' && t('Common.agreeTo')}
                <a
                  className='link'
                  target='_blank'
                  href='/terms-of-use'
                  onClick={() => handleCheckPolicyTerm('term')}>
                  {t('Common.termsOfUse')}
                </a>{' '}
                {i18n.language === 'jp' && '、'}
                {i18n.language === 'en' && ' and '}
                <a
                  className='link'
                  target='_blank'
                  href='/privacy-policy'
                  onClick={() => handleCheckPolicyTerm('policy')}>
                  {t('Common.privacyPolicy')}
                </a>
                {i18n.language === 'jp' && t('Common.agreeTo')}
              </label>
            </div>
            <label className='note-policy'>{t('Common.notePolicy')}</label>

            <InputWrapper
              id='fsite-submit'
              className={`button-submit ${isDisabledButton ? 'disabled' : ''}`}>
              <button
                className='d-middle'
                onClick={handleSubmit(onSubmit)}
                disabled={isDisabledButton}>
                {t(checkTokenTwitter ? 'SignUp.buttonSignUp' : 'SignUp.buttonFan')}
              </button>
            </InputWrapper>
            <div className='text-center mb-3'>
              <span className='text'>
                {i18n.language === 'jp' && t('Common.alreadyAccount')}
                <span onClick={() => navigate('/sign-in')} className='link'>
                  {i18n.language === 'jp' ? t('Common.clickHere') : t('Common.clickHereC002')}
                </span>
                {i18n.language === 'en' && t('Common.alreadyAccountC002')}
              </span>
            </div>
          </form>

          <div className='footer'>
            <ImgCustom
              src={LOGO_COCO_FANS}
              height={32}
              width={94}
              alt='logo'
              className='logo-cocofans'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
