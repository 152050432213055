/* eslint-disable no-unused-expressions */
/* Call API with token */
import Storage from './storage';
import { pushNotify } from '../components/Notification';
import { STORAGE } from './constant';
import i18n from 'translate/i18n';

const SYSTEM_ERROR = 1;
const API_ERROR = 2;
export const TOKEN_TIMEOUT = 3;
export const SYSTEM_IS_MAINTAINING = 4;

class ApiError extends Error {
  constructor(error, type) {
    super();
    this.errors = error;
    this.type = type;
  }
}

async function validateResponse(response, v2) {
  if (!response.ok) {
    let error = {};
    let type = API_ERROR;
    const err = await response.json();
    console.log(err?.message + (v2 ? '_V2' : ''));
    if (err?.message === 'SYSTEM_MAINTAIN') {
      window.location.href = `/maintain`;
    } else {
      switch (err?.message) {
        case 'NOT_FOUND':
        case 'TICKET_NOT_FOUND':
        case 'PACKAGE_NOT_FOUND':
        case 'THE_SPECIFIED_URL_WAS_NOT_FOUND':
        case 'UNPUBLISHED_POST':
        case 'TOO_MANY_REQUESTS':
        case 'NOT_FOUND_LIVESTREAM':
          window.location.href = '/not-found';
          break;
        case 'EMAIL_HAS_BEEN_ACTIVATED':
          window.location.href = '/sign-in';
          break;
        default:
          break;
      }
    }

    switch (response.status) {
      case 400:
      case 401:
      case 404:
      case 500:
      case 422:
      case 403:
      case 429:
        // eslint-disable-next-line no-case-declarations
        type = API_ERROR; // TOKEN_TIMEOUT;
        if (err?.message === 'SYSTEM_MAINTAIN') {
          error = {
            message: 'SYSTEM_MAINTAIN',
            date_from: err.date_from,
            date_to: err.date_to,
            status: err.success,
          };
          break;
        } else {
          if (Array.isArray(err.errors || err?.message || err?.error?.message)) {
            const arrMessage =
              err?.errors?.join(`,,`) ||
              err?.error?.message.join(`,,`) ||
              err?.message?.join(`,,`);
            const newArrMessage = err?.message.map((item) => i18n.t(`ValidateMsg.${item}`));
            error = {
              messageId: err.success,
              message: newArrMessage.join(',,') || arrMessage,
              status: err.success,
            };
            break;
          } else if (typeof err.errors === 'object') {
            const arrMessage = Object.values(err.errors) || [];
            let arr =
              arrMessage?.map((item) => i18n.t(`ValidateMsg.${item}`)).join(',,') || '';
            error = {
              messageId: err.success,
              message: arr,
              status: err.success,
            };
            break;
          } else {
            error = {
              messageId: err.message,
              message: i18n.t(
                `${
                  err?.error?.message || err?.data?.email[0] || err?.message !== undefined
                    ? i18n.t(
                        `ValidateMsg.${
                          err?.error?.message ||
                          err?.data?.email[0] ||
                          err?.message + (v2 ? '_V2' : '')
                        }`
                      )
                    : 'message_not_defined'
                }`
              ),
              status: err.success || false,
            };
            break;
          }
        }
      case 503:
        type = SYSTEM_IS_MAINTAINING;
        error = {
          messageId: err.message,
          message: 'SYSTEM_IS_MAINTAINING',
          status: false,
        };
        break;
      default:
        type = SYSTEM_ERROR;
        error = {
          messageId: 'INTERNAL_ERROR',
          message: 'INTERNAL_ERROR',
          status: false,
          // message: response.statusText,
        };
        break;
    }
    if (error && type !== SYSTEM_ERROR) {
      throw new ApiError(error);
    } else {
      throw Error(error);
    }
  }
  if (response.status === 204) return {};
  return response.json();
}

function handleErrAnauthorized() {
  if (!Storage.has(STORAGE.ERR_UNAUTHORIZED)) {
    Storage.set(STORAGE.ERR_UNAUTHORIZED, true);
    pushNotify(
      'error',
      i18n.t('ValidateMsg.UNAUTHORIZED_OR_TOKEN_DOES_NOT_EXITS'),
      'Unauthorized'
    );
  }
  setTimeout(() => {
    Storage.remove(STORAGE.ERR_UNAUTHORIZED);
    Storage.remove(STORAGE.USER_ACCESS_TOKEN);
    Storage.remove(STORAGE.CURRENT_USER_KEY);
    Storage.remove(STORAGE.TOKEN_TWITTER);
    Storage.remove(STORAGE.SERECT_TOKEN_TWITTER);
    Storage.remove(STORAGE.IS_ADMIN);
    Storage.remove(STORAGE.USER_ID);
    Storage.remove(STORAGE.PARAMS);
    window.location.href = '/sign-in';
    clearTimeout();
  }, 1500);
}

function logError(error) {
  if (error instanceof ApiError) {
    if (Array.isArray(error?.error?.message)) {
      pushNotify(
        'error',
        i18n.t(`ValidateMsg.${error.errors.message}`) !== undefined
          ? i18n.t(`ValidateMsg.${error.errors.message}`)
          : i18n.t(`ValidateMsg.${error.errors.message}`),
        error?.errors?.success
      );
    } else {
      if (error?.errors?.messageId === 'TOO_SOME_SUBSCRIBERS') return;
      if (error?.errors?.messageId === 'PAYMENT_PACKAGE_NOT_ACTIVE') return;
      if (error?.errors?.messageId === 'YOU_BOUGHT_PACKAGE_OF_THIS_USER') return;
      if (
        error?.errors?.messageId === 'UNAUTHORIZED_OR_TOKEN_DOES_NOT_EXITS' ||
        error?.errors?.messageId === 'Unauthorized' ||
        error?.errors?.messageId === 'UNAUTHORIZED'
      ) {
        handleErrAnauthorized();
      } else {
        switch (error.errors?.message || error.errors?.messageId) {
          case 'SYSTEM_MAINTAIN':
            break;
          default:
            pushNotify('error', error?.errors?.message, error?.errors?.messageId);
            break;
        }
      }
    }
  }
  // throw error;
  return false;
}

const callApi = (
  url,
  config,
  onRequestFinish,
  onRequestSuccess,
  onRequestFailure,
  moreObj = {
    messV2: false,
    finally: () => {},
  }
) => {
  fetch(url, config)
    .then((res) => validateResponse(res, moreObj.messV2))
    .then((data) => {
      if (data) {
        onRequestFinish && onRequestFinish();
        onRequestSuccess && onRequestSuccess(data);
      }
    })
    .catch((err) => {
      logError(err);
      onRequestFinish && onRequestFinish();
      onRequestFailure && onRequestFailure(err);
    })
    .finally(moreObj.finally);
};

const callApiWithOutShowError = (
  url,
  config,
  onRequestFinish,
  onRequestSuccess,
  onRequestFailure,
  moreObj = {
    messV2: false,
    finally: () => {},
  }
) => {
  fetch(url, config)
    .then((res) => validateResponse(res, moreObj.messV2))
    .then((data) => {
      if (data) {
        onRequestFinish && onRequestFinish();
        onRequestSuccess && onRequestSuccess(data);
      }
    })
    .catch((err) => {
      onRequestFinish && onRequestFinish();
      onRequestFailure && onRequestFailure(err);
    })
    .finally(moreObj.finally);
};

const callApiNew = async (
  url,
  config,
  moreObj = {
    messV2: false,
    finally: () => {},
  }
) => {
  return fetch(url, config)
    .then((response) => {
      if (!response.ok) {
        let error = {};
        let type = API_ERROR;
        const err = response.json();
        console.log(err?.message + (moreObj.messV2 ? '_V2' : ''));
        if (err?.message === 'SYSTEM_MAINTAIN') {
          window.location.href = `/maintain`;
        } else {
          switch (err?.message) {
            case 'NOT_FOUND':
            case 'TICKET_NOT_FOUND':
            case 'PACKAGE_NOT_FOUND':
            case 'THE_SPECIFIED_URL_WAS_NOT_FOUND':
            case 'UNPUBLISHED_POST':
            case 'TOO_MANY_REQUESTS':
            case 'NOT_FOUND_LIVESTREAM':
              window.location.href = '/not-found';
              break;
            default:
              break;
          }
        }

        switch (response.status) {
          case 400:
          case 401:
          case 404:
          case 500:
          case 422:
          case 403:
          case 429:
            // eslint-disable-next-line no-case-declarations
            type = API_ERROR; // TOKEN_TIMEOUT;
            if (err?.message === 'SYSTEM_MAINTAIN') {
              error = {
                message: 'SYSTEM_MAINTAIN',
                date_from: err.date_from,
                date_to: err.date_to,
                status: err.success,
              };
              break;
            } else {
              if (Array.isArray(err.errors || err?.message || err?.error?.message)) {
                const arrMessage =
                  err?.errors?.join(`,,`) ||
                  err?.error?.message.join(`,,`) ||
                  err?.message?.join(`,,`);
                const newArrMessage = err?.message.map((item) =>
                  i18n.t(`ValidateMsg.${item}`)
                );
                error = {
                  messageId: err.success,
                  message: newArrMessage.join(',,') || arrMessage,
                  status: err.success,
                };
                break;
              } else {
                error = {
                  messageId: err.message,
                  message: i18n.t(
                    `${
                      err?.error?.message || err?.data?.email[0] || err?.message !== undefined
                        ? i18n.t(
                            `ValidateMsg.${
                              err?.error?.message ||
                              err?.data?.email[0] ||
                              err?.message + (moreObj.messV2 ? '_V2' : '')
                            }`
                          )
                        : 'message_not_defined'
                    }`
                  ),
                  status: err.success || false,
                };
                break;
              }
            }
          case 503:
            type = SYSTEM_IS_MAINTAINING;
            error = {
              messageId: err.message,
              message: 'SYSTEM_IS_MAINTAINING',
              status: false,
            };
            break;
          default:
            type = SYSTEM_ERROR;
            error = {
              messageId: 'INTERNAL_ERROR',
              message: 'INTERNAL_ERROR',
              status: false,
              // message: response.statusText,
            };
            break;
        }
        if (error && type !== SYSTEM_ERROR) {
          throw new ApiError(error);
        } else {
          throw Error(error);
        }
      }
      return response.json();
    })

    .catch((err) => {
      // logError(err);
      console.log('CALL CallApiNew: ' + err);
    });
};

// let prResolve = {}

// const clearPrResolve = () => {
//   prResolve = {};
//   // return prResolve;
// }

export { callApi, callApiNew, callApiWithOutShowError };
