import Icon from '@ant-design/icons';

const ProfileIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='12'
        height='16'
        fill='none'
        viewBox='0 0 12 16'>
        <path
          fill='currentColor'
          fillRule='evenodd'
          d='M9.97 4.468A3.956 3.956 0 016 8.438a3.956 3.956 0 01-3.97-3.97A3.955 3.955 0 016 .5a3.955 3.955 0 013.97 3.968zM6 15.5c-3.253 0-6-.529-6-2.569s2.764-2.55 6-2.55c3.254 0 6 .528 6 2.568C12 14.99 9.236 15.5 6 15.5z'
          clipRule='evenodd'></path>
      </svg>
    )}
    {...props}
  />
);

export default ProfileIcon;
