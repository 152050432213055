export default function SettingIcon(props) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M14.13 11.3401C14.52 9.70008 13.09 8.27003 11.45 8.66003C10.68 8.85003 10.05 9.48005 9.85998 10.2501C9.46998 11.8901 10.9 13.32 12.54 12.93C13.32 12.74 13.95 12.1101 14.13 11.3401Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M18.0099 17.19C19.7199 15.59 20.7899 13.32 20.7899 10.79C20.7899 5.92998 16.85 2 12 2C7.14995 2 3.20996 5.93998 3.20996 10.79C3.20996 13.33 4.28996 15.62 6.01996 17.22'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.00002 14.5498C7.08002 13.5698 6.51001 12.2498 6.51001 10.7898C6.51001 7.75979 8.97002 5.2998 12 5.2998C15.03 5.2998 17.49 7.75979 17.49 10.7898C17.49 12.2498 16.92 13.5598 16 14.5498'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.3 16.6598L8.85998 18.4498C7.71998 19.8798 8.72997 21.9898 10.56 21.9898H13.43C15.26 21.9898 16.28 19.8698 15.13 18.4498L13.69 16.6598C12.83 15.5698 11.17 15.5698 10.3 16.6598Z'
        stroke='currentColor'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
