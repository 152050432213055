import styled from 'styled-components';

export const StyledTimeLine = {
  Wrapper: styled.div`
    width: 100%;
    height: 418px;
    /* background-color: #000; */
    margin-top: 16px;
    position: relative;
    @media screen and (max-width: 575px) {
      height: 194px;
    }
    cursor: pointer;
    img,
    video {
      width: 100%;
      height: 100%;
      //aspect-ratio: 2/3;
      object-fit: contain;
    }
    /* .cls-imgCopy {
      position: absolute;
      top: 18.5px !important;
      left: 18.5px !important;
      color: #fff;
    } */
  `,
  BlueImg: styled.div`
    background: url(${({ url }) => "'" + url + "'"});
    height: ${({ height }) => height}px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    border-radius: 10px;
    .bg-img {
      //width: ${({ width }) => width}px;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 9;
      background-size: cover;
      min-height: 100%;
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(20px);
      &.br-10 {
        border-radius: 10px 10px 0px 0px;
      }
    }
    @media screen and (max-width: 575px) {
      .mobile-img {
        width: 343px !important;
        height: 193px !important;
      }
    }
  `,

  WrapperBlur1: styled.div`
    @media screen and (max-width: 575px) {
      width: calc(100% - 32px) !important;
      margin: 0 16px;
    }
    position: absolute;
    inset: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    z-index: 35;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    &.short-search-blur {
      border-radius: 20px;
    }

    &.hashtag-search-post {
      max-width: 100%;
      position: relative;
      inset: unset;
      top: 0;
      z-index: 100;
      left: 50%;
      bottom: 50%;

      transform: translate(-50%, -100%);
    }

    .text-follow {
      color: #000000;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      @media screen and (max-width: 575px) {
        font-size: 12px;
      }
      @media screen and (min-width: 1024px) and (max-width: 1024px) {
        font-size: 12px;
      }
    }

    button {
      background-color: #dc143c;
      color: #fff;
      border: none;
      cursor: pointer;
      padding: 11px 24px;
      border-radius: 8px;
      font-size: 12px;
      &:hover {
        background-color: #CE1338 !important;
      }
      &:disabled {
        cursor: default;
      }
    }
    /* @media screen and (max-width: 575px) {
      width: calc(100% - 32px);
    } */
  `,

  WrapperBlur: styled.div`
    position: absolute;
    inset: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    z-index: 34;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    background-image: url("../../../images/Blur_Right.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */

    &.short-search-blur {
      border-radius: 20px;
    }

    &.hashtag-search-post {
      max-width: 100%;
      position: relative;
      inset: unset;
      top: 0;
      z-index: 100;
      left: 50%;
      bottom: 50%;

      transform: translate(-50%, -100%);
    }

    .text-follow {
      color: #000000;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      @media screen and (max-width: 575px) {
        font-size: 12px;
      }
      @media screen and (min-width: 1024px) and (max-width: 1024px) {
        font-size: 12px;
      }
    }

    button {
      background-color: #dc143c;
      color: #fff;
      border: none;
      cursor: pointer;
      padding: 11px 24px;
      border-radius: 8px;
      font-size: 12px;
      &:hover {
        background-color: #CE1338 !important;
      }
      &:disabled {
        cursor: default;
      }
    }
    /* @media screen and (max-width: 575px) {
      width: calc(100% - 32px);
    } */
  `,
};

export const StyledDetailCard = {
  Wrapper: styled.div`
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 16px;
    /* background-color: #000; */
    /* .cls-imgCopy {
      position: absolute;
      top: 18.5px !important;
      left: 18.5px !important;
      color: #fff;
    } */
    img {
      width: 100%;
      aspect-ratio: 4/3;
      object-fit: contain;
    }
  `,
  WrapperBlur: styled.div`
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    top: 0;
    z-index: 12;
  `,
};
