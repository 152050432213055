import React, { useEffect, useRef, useState } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { convertToCurrentTime, decryptPath } from 'utils/utils';

import {
  clearDataLive,
  createTokenAgoraGuest,
  detailLive,
  updateChangeStatusToLive,
} from 'store/actions/lives';
import moment from 'moment';

import './index.scss';

import {
  ImgCustom,
  LayoutCreatorRight,
  PopupConfirmFan,
  PopupLoading,
  Tooltip,
} from 'components';
import { updateData } from 'hooks/useRealTimeDB';
import withCreatorRole from 'hocs/withCreatorRole';

import { getEnv } from 'configs/env';
import lodash from 'lodash';
import {
  LOGO_SHARE_GRAM,
  LOGO_THUMBNAIL_COCOFAN,
} from '../../../../utils/constant';
import { useTranslation } from 'react-i18next';
import { CopyValueReview } from 'pages/lives/ViewFan/styled';

import { MediaPlayer } from 'components/LiveStream/V5/Videos/ObsAgora/MediaAgora/MediaPlayer';
import { AgoraOBSPlayer } from 'components/LiveStream/V5/Videos/AgoraOBSPlayer';
import { useAgora } from 'components/LiveStream/V5/Videos/ObsAgora/useAgora';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import Webcam from './reviewLive';
import { useCheckPasswordLivestream } from 'hooks/useCheckPasswordLive';
import Storage from 'utils/storage';
import { newPathUser } from 'constants/layout/constant';
import CopyIcon from 'images/CopyIcon';
import BlurImage from 'components/BlurImg';

moment.locale('en');

export const useCopy = () => {
  const [copiedText, setCopiedText] = useState(null);
  const copy = async (text) => {
    if (!navigator?.clipboard) {
      return false;
    }
    try {
      await navigator.clipboard.writeText(text);
      setCopiedText(text);
      return true;
    } catch (error) {
      setCopiedText(null);
      return false;
    }
  };
  return [copiedText, copy];
};
const appID = getEnv('REACT_APP_AGORA_APP_ID');
const client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' });
const ReviewLivestream = () => {
  const listCheckPassword = window.localStorage.getItem('cpw_ls');
  const textRef = useRef(null);
  //* CODE LIVE
  let { id, liveKey } = useParams();
  // const url = `/home-page/lives/${liveKey}`;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [tokenAgora, setTokenAgora] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);

  const [uid, setUid] = useState(Storage.get('UID'));
  const uidStorge = useRef();
  const copyLinkFunc = () => {
    navigator.clipboard.writeText(`${getEnv('REACT_APP_FAN_URL')}/lives/${data.live_key}`);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1500);
  };
  const {
    join: joinAgora,
    remoteUsers: remoteAgora,
    leave,
  } = useAgora(client, appID);
  const data = useSelector((state) => state.lives.dataLiveStream);
  const findChannel = useMemo(() => {
    const checkList = remoteAgora.filter((item) => item?.hasAudio || item?.hasVideo);
    const result = checkList.pop();
    const obj = {
      ViewLive: (
        <MediaPlayer
          type={data?.obs_flag === 1 ? 'obs' : 'noobs'}
          videoTrack={result?.videoTrack}
          audioTrack={result?.audioTrack}
        />
      ),
      Result: result,
    };
    return obj;
  }, [remoteAgora, data]);
  const [copySuccessObs, setCopySuccessObs] = useState(false);

  useEffect(() => {
    if (copySuccessObs) {
      const time = setTimeout(() => setCopySuccessObs(false), 1500);
      return () => {
        clearTimeout(time);
      };
    }
  }, [copySuccessObs]);
  const [value, copy] = useCopy();

  const [popupValidateTime, setPopupValidateTime] = useState(false);
  const [popupValidateTimeExpired, setPopupValidateTimeExpired] = useState(false);
  const [popupConfirmLive, setPopupConfirmLive] = useState(false);

  // const livesCreateData = useSelector((state) => state.lives.createData);
  const loadingChangeStatus = useSelector((state) => state.lives.loadingChangeStatus);
  const loading = useSelector((state) => state.lives.loading);

  useEffect(() => {
    return () => {
      leave();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let listSetting = null;
  let ngWords_user = null;
  let listPackages = null;
  let listUsers = null;

  let listUsersDistribution = null;

  const [showMsg, setShowMsg] = useState([]);

  if (data !== null) {
    if (data?.ngWords?.length > 0) {
      listSetting = data?.ngWords[0]?.split(',');
      listSetting = lodash.without(listSetting, undefined, null, '', ' ');
    }
    if (data?.ngWords_user?.length > 0) {
      ngWords_user = data?.ngWords_user[0]?.split(',');
      ngWords_user = lodash.without(ngWords_user, undefined, null, '', ' ');
    }
    listPackages = data?.packages;
    listUsers = data?.user_live;
    listUsersDistribution = data?.user_distributions;
  }

  useEffect(() => {
    if (data?.status === 3) {
      const newObj = { status: data?.status };
      updateData(`/lives/${data?.live_key}/config`, newObj).then((_res) => {});
      window.location.href = (`${newPathUser}live/${data?.id}/${data?.live_key}`);
    }
    if (data !== null) {
      let msg1, msg2, msg3, msg4, msg5;
      if (data?.all_follow === 1) {
        msg1 = ` ${t('Creator.ReviewLiveStream.follower')} `;
      }
      if (data?.all_member === 1) {
        msg2 = ` ${t('Creator.ReviewLiveStream.everyone')} `;
      }

      if (listPackages !== null && listPackages !== undefined && listPackages?.length > 0) {
        msg3 = ` ${t('Common.subscription')} `;
      }

      if (listUsers !== null && listPackages !== undefined && listUsers?.length > 0) {
        msg4 = ` ${t('Creator.ReviewLiveStream.anyIndividual')} `;
      }

      if (
        listUsersDistribution !== null &&
        listUsersDistribution !== undefined &&
        listUsersDistribution?.length > 0
      ) {
        msg5 = ` ${t('Creator.ReviewLiveStream.anyDistribution')} `;
      }

      const val = lodash.union([msg1, msg2, msg3, msg4, msg5]);

      setShowMsg(val);
      // const dup = lodash.sortedUniq(showMsg);
      // setShowMsg(dup);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, i18n.language]);

  useEffect(() => {
    if (data && data?.live_token) {
      joinAgora(liveKey, data?.live_token);
    }
  }, [data]);

  useEffect(() => {
    return () => dispatch(clearDataLive());
  }, []);

  useEffect(() => {
    if (Storage.get('UID')) {
      setUid(Storage.get('UID'));
      uidStorge.current = Storage.get('UID');
    }
  }, [Storage.get('UID')]);

  useEffect(() => {
    if (liveKey) {
      dispatch(
        createTokenAgoraGuest({
          name: liveKey,
          exSec: 300,
          action: (tk) => {
            setTokenAgora(tk);
          },
        })
      );
    }
  }, [liveKey]);

  useEffect(() => {
    dispatch(detailLive(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formatDateLive = convertToCurrentTime(data?.live_date, 'YYYY/MM/DD');
  const formatDateLiveByHourMinute = convertToCurrentTime(data?.live_date, 'HH:mm');
  // usePromptReview(true, () => {
  //   client?.remoteAgora?.[0]?.videoTrack?.stop();
  //   remoteAgora?.[0]?.audioTrack?.stop();
  //   remoteAgora?.[0]?.videoTrack?.stop();
  //   leave();
  // });
  const handleOpenPopup = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const currentTime = moment().format('YYYY-MM-DD HH:mm');
    const liveDate = convertToCurrentTime(data.live_date, 'YYYY-MM-DD HH:mm');
    const hourLive = moment(data?.live_time, 'HH:mm').format('H');
    const minuteLive = moment(data?.live_time, 'HH:mm').format('mm');
    const timeExpired = moment(liveDate, 'YYYY-MM-DD HH:mm')
      .add(hourLive, 'hours')
      .add(minuteLive, 'minutes')
      .format('YYYY-MM-DD HH:mm');
    if (currentTime < liveDate) {
      setPopupValidateTime(true);
    } else if (timeExpired < currentTime) {
      setPopupValidateTimeExpired(true);
    } else {
      setPopupConfirmLive(true);
    }
    // currentTime < liveDate ? setPopupValidateTime(true) : setPopupConfirmLive(true);
  };
  const { UISetPassword, handleSetPassword } = useCheckPasswordLivestream(
    (e) => handleOpenPopup(e),
    data?.id,
    false,
    data?.is_password
  );

  // Start recording
  const startLive = async (uid) => {
    let tmp = {
      // cname: cname,
      uid: uid,
      clientRequest: {
        // token: token,
        // storageConfig: {
        //   secretKey: getEnv('REACT_APP_AWS_SECRET_ACCESS_KEY'),
        //   vendor: 1,
        //   region: 3,
        //   bucket: 'fansite-dev-video-output',
        //   accessKey: getEnv('REACT_APP_AWS_ACCESS_KEY_ID'),
        //   fileNamePrefix: ['uploads', 'output', 'recording', uid],
        // },
        recordingConfig: {
          maxIdleTime: 300,
          audioProfile: 2,
          channelType: 1,
          transcodingConfig: {
            bitrate: isMobile ? 500 : 2260,
            fps: 15,
            width: isMobile ? 360 : 1280,
            height: isMobile ? 640 : 720,
          },
        },
        recordingFileConfig: {
          avFileType: ['hls'],
        },
      },
    };

    const url = `${getEnv('REACT_APP_API_SERVER')}/livestreams/${id}/recording`;
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
      },
      body: JSON.stringify(tmp),
    });
    res
      .json()
      .then((rs) => {
        if (rs?.resourceId && rs?.sid) {
          // recordingStatus(rs.resourceId, rs.sid); //call recording status
          Storage.remove('UID');
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCallApi = async () => {
    dispatch(
      updateChangeStatusToLive(id, () => {
        if (data?.record_flag === 1) {
          startLive(uidStorge.current?.toString());
          // getResourceId(data?.live_key, uidStorge.current?.toString());
        }
      })
    );
    Storage.remove('UID');
    setPopupConfirmLive(false);
  };

  const handleRenderTimeLive = (live_time) => {
    return (
      <>
        {i18n.language === 'en' ? (
          <>
            {+live_time?.split(':')[0] > 0 && (
              <>
                {+live_time?.split(':')[0]}{' '}
                <span style={{ marginLeft: 1.5, marginRight: 1.5 }} />
                {t('Creator.CreateLiveStream.time')}
                {+live_time?.split(':')[0] > 1 && 's'}
              </>
            )}
          </>
        ) : (
          <>
            {+live_time?.split(':')[0] > 0 && (
              <>
                {+live_time?.split(':')[0]}
                <span style={{ marginLeft: 1.5, marginRight: 1.5 }} />
                {t('Creator.CreateLiveStream.time')}
              </>
            )}
          </>
        )}
        <span style={{ marginLeft: 1.5, marginRight: 1.5 }} />
        {+live_time?.split(':')[1] > 0 && (
          <>
            {+live_time?.split(':')[1]} {t('Creator.ReviewLiveStream.minutes')}
          </>
        )}
      </>
    );
  };

  const checkHaveBackground = useMemo(() => {
    const deImg = decryptPath(data?.img_background);

    if (deImg && deImg !== 'NULL') {
      return true;
    }
    return false;
  }, [data?.img_background]);

  return (
    <>
      {loading && <PopupLoading className={'popup-loading'} />}
      {loadingChangeStatus && <PopupLoading className={'popup-loading'} />}
      {popupValidateTime && (
        <PopupConfirmFan
          text={`${t('Popup.startLive1')} ${convertToCurrentTime(
            data.live_date,
            'YYYY-MM-DD HH:mm'
          )} ${t('Popup.startLive2')}`}
          className='popup-validate-time-live'>
          <div className={`btn ok`} onClick={() => setPopupValidateTime(false)}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}
      {popupValidateTimeExpired && (
        <PopupConfirmFan
          text={`${t('Popup.startExpired')}`}
          className='popup-validate-time-live'
          isDanger>
          <div
            className={`btn ok`}
            onClick={() => navigate(`${newPathUser}live/list-livestream`)}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}

      {popupConfirmLive && (
        <PopupConfirmFan text={t('Popup.confirmStartLive')} iconNote={true} colorSvg='#FF9F43'>
          <div className={`btn ok`} onClick={handleCallApi}>
            {t('Common.Ok')}
          </div>
          <div className={`btn cancel`} onClick={() => setPopupConfirmLive(false)}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}
      {data !== null ? (
        <LayoutCreatorRight
          titlePage={t('Creator.ReviewLiveStream.liveStreamingTest')}
          className='preview-live-stream-header'>
          <div className='review-livestream' style={{ overflow: 'hidden' }}>
            <div className='screen-live'>
              <div
                className='bg'
                style={{ height: '100%', width: '100%', borderRadius: '8px' }}>
                {/* Video  */}
                {data?.live_token && data?.obs_flag === 1 ? (
                  <>
                    <div className='logo-coco'>
                      <img src={LOGO_SHARE_GRAM} height={32} width={143} alt='logo' />
                    </div>
                    <AgoraOBSPlayer
                      host={true}
                      liveKey={liveKey}
                      type='review'
                      view='creator'
                      showButton='screen-review'
                      token={data?.live_token}
                      // ViewAgora={data?.obs_flag === 1 && ViewAgoraOBS}
                      ViewAgora={data?.obs_flag === 1 && findChannel?.ViewLive}
                    />
                  </>
                ) : data?.obs_flag !== 1 ? (
                  <>
                    <div className='logo-coco'>
                      <img src={LOGO_SHARE_GRAM} height={32} width={143} alt='logo' />
                    </div>
                    <Webcam className='live_webcam-noobs' />
                  </>
                ) : (
                  <div className='logo-coco'>
                    <img src={LOGO_SHARE_GRAM} height={32} width={143} alt='logo' />
                  </div>
                )}
              </div>
              {/* End CLASS BG */}
            </div>

            <div className='text' style={{ marginTop: 24 }}>
              {data?.live_title}
            </div>
            <div className='text text-description'>{data?.live_description}</div>

            <div className='screen-info'>
              <div className='img-background'>
                <BlurImage
                  style={{ borderRadius: '8px' }}
                  src={`${decryptPath(data?.img_background, { isMini: true })}`}>
                  <div className='bg-img'></div>
                  <ImgCustom
                    screen={checkHaveBackground ? 'post_775_720_pc' : null}
                    src={checkHaveBackground ? data?.img_background : LOGO_THUMBNAIL_COCOFAN}
                    className={`img_thumbnail cover-img ${
                      checkHaveBackground ? '' : 'img-placeholder'
                    }`}
                  />
                </BlurImage>
              </div>
              <div className='row'>
                <div className='dd-title-rv'>
                  {t('Creator.CreateLiveStream.destinationSettings')}:
                </div>
                <div className='dd-title-value value' style={{ wordSpacing: '3px' }}>
                  {showMsg?.length > 0
                    ? showMsg.filter((pack) => pack !== undefined).toString()
                    : ''}
                </div>
              </div>
              <div className='row'>
                <div className='dd-title-rv'>
                  {t('Creator.CreateLiveStream.deliveryTimeSetting')}:
                </div>
                <div className='dd-title-value value'>
                  {handleRenderTimeLive(data?.live_time)}
                </div>
              </div>
              <div className='row'>
                <div className='dd-title-rv'>
                  {t('Creator.CreateLiveStream.deliverySchedule')} :
                </div>
                <div className='dd-title-value value'>
                  {`${formatDateLive} ${formatDateLiveByHourMinute}`}
                </div>
              </div>

              <div className='row copy-link-creator'>
                <div className='dd-title-rv'>{t('Creator.ReviewLiveStream.deliveryURL')}</div>
                <div className='dd-title-value value link-copy deliveryURl'>
                  <Tooltip className='tooltip-no-data'>{`${getEnv(
                    'REACT_APP_FAN_URL'
                  )}/lives/${data.live_key}`}</Tooltip>
                  <span className='link' ref={textRef}>
                    {`${getEnv('REACT_APP_FAN_URL')}/lives/${data.live_key}`}
                  </span>
                  <CopyIcon className='icon-copy' onClick={copyLinkFunc} />
                  {copySuccess && (
                    <div className='copy-success'>{t('Affiliator.AF005.messageCopyLink')}</div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='dd-title-rv'>
                  {t('Creator.CreateLiveStream.recordingLive')}:
                </div>
                <div className='dd-title-value value'>
                  {data?.record_flag === 1 ? t('Common.buttonYes') : t('Common.buttonNo')}
                </div>
              </div>
              {/* Packages  */}
              <div className='row col-mobile'>
                <div className='dd-title-rv'>{t('Common.subscription')}:</div>
                <div className='dd-title-value value'>
                  <div className='box-table'>
                    <div className='table-setting'>
                      <div className='table-head'>
                        <div className='no d-middle'>No.</div>
                        <div className='text-setting d-middle'>{t('Common.subscription')}</div>
                      </div>
                      <div className='table-body'>
                        {listPackages &&
                        listPackages !== undefined &&
                        listPackages?.length > 0 ? (
                          listPackages.map((item, index) => (
                            <div className='row-item' key={index}>
                              <div className='no d-middle'>{index + 1}</div>
                              <div className='text-setting d-middle'>
                                <span>{item.title}</span>
                              </div>
                            </div>
                          ))
                        ) : (
                          <span className='text-no-data'>{t('Common.noData')}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* END PACKAGES */}
              {/* List Users */}
              <div className='row col-mobile'>
                <div className='dd-title-rv'>
                  {t('Creator.ReviewLiveStream.anyIndividual')}:
                </div>
                <div className='col-xl-10 col-sm-9 col-12 dd-title-value'>
                  <div className='box-table'>
                    <div className='table-setting'>
                      <div className='table-head'>
                        <div className='no d-middle'>No.</div>
                        <div className='text-setting d-middle'>
                          {t('Creator.ReviewLiveStream.anyIndividual')}
                        </div>
                      </div>
                      <div className='table-body'>
                        {listUsers !== null &&
                        listUsers !== undefined &&
                        listUsers?.length > 0 ? (
                          listUsers.map((item, index) => (
                            <div className='row-item' key={index}>
                              <div className='no d-middle'>{index + 1}</div>
                              <div className='text-setting d-middle'>
                                <span>{item?.account_name}</span>
                              </div>
                            </div>
                          ))
                        ) : (
                          <span className='text-no-data'>{t('Popup.messageNoData')}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* End List Users */}
              {/* End List Users Distributions */}

              <div className='row col-mobile'>
                <div className='dd-title-rv'>
                  {t('Creator.ReviewLiveStream.NGwordDisplay')}
                </div>
              </div>
              <div className='row'>
                <div className='row' style={{ marginTop: 0 }}></div>
                <div className='box-table'>
                  <div className='ng-word-row'>
                    <div className='ng-word-col'>
                      <div className='table-setting-last'>
                        <div className='table-head'>
                          <div className='no d-middle'>No.</div>
                          <div className='text-setting d-middle'>{t('Popup.NGword')}</div>
                        </div>
                        <div className='table-body'>
                          {listSetting !== null &&
                          listSetting !== undefined &&
                          listSetting?.length > 0 ? (
                            listSetting.map((item, index) => (
                              <div className='row-item' key={index}>
                                <div className='no d-middle'>{index + 1}</div>
                                <div className='text-setting d-middle'>
                                  <span>{item}</span>
                                </div>
                              </div>
                            ))
                          ) : (
                            <span className='text-no-data'>{t('Popup.messageNoData')}</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='ng-word-col ng-word-col2'>
                      <div className='table-setting-last'>
                        <div className='table-head'>
                          <div className='no d-middle'>No.</div>
                          <div className='text-setting d-middle'>{t('Popup.NGword')}</div>
                        </div>
                        <div className='table-body'>
                          {ngWords_user !== null &&
                          ngWords_user !== undefined &&
                          ngWords_user?.length > 0 ? (
                            ngWords_user.map((item, index) => (
                              <div className='row-item' key={index}>
                                <div className='no d-middle'>{index + 1}</div>
                                <div className='text-setting d-middle'>
                                  <span>{item}</span>
                                </div>
                              </div>
                            ))
                          ) : (
                            <span className='text-no-data'>{t('Popup.messageNoData')}</span>
                          )}
                        </div>
                      </div>
                      <p className='text-description'>
                        {t('Creator.ReviewLiveStream.NGwordsRegistered')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* OBS */}
              {data?.obs_flag === 1 && (
                <div
                  className='row'
                  style={{
                    display: isMobile ? 'block' : 'flex',
                  }}>
                  <div className='dd-title-rv'>{t('Creator.ReviewLiveStream.OBSInfor')}:</div>
                  <div
                    style={
                      isMobile
                        ? {
                            maxWidth: '-webkit-fill-available',
                          }
                        : null
                    }
                    className='dd-title-value value'>
                    <div className='value link-copy'>
                      <div style={isMobile ? { width: '-webkit-fill-available' } : {}}>
                        Information Url:
                      </div>
                      {data && data?.live_token && (
                        <>
                          <Tooltip
                            className={`tooltip-no-data ${
                              isMobile ? '' : 'tt_obs-hover'
                            }`}>{`${getEnv(
                            'REACT_APP_API_SERVER'
                          )}/livestreams/agora/setting?token=${encodeURIComponent(
                            data?.live_token
                          )}`}</Tooltip>
                          <div
                            style={
                              isMobile ? { width: 'calc(100% + 160px)' } : { width: '70%' }
                            }
                            className='link ms-2'
                            ref={textRef}>
                            {`${getEnv(
                              'REACT_APP_API_SERVER'
                            )}/livestreams/agora/setting?token=${encodeURIComponent(
                              data?.live_token
                            )}`}
                          </div>
                          <div style={{ position: 'relative' }}>
                            <CopyIcon
                              className='icon-copy'
                              onClick={() => {
                                copy(
                                  `${getEnv(
                                    'REACT_APP_API_SERVER'
                                  )}/livestreams/agora/setting?token=${encodeURIComponent(
                                    data?.live_token
                                  )}`
                                );
                                setCopySuccessObs(true);
                              }}
                            />
                            {copySuccessObs && (
                              <CopyValueReview className='copy-success'>
                                {t('LiveStream.V5.messageCopyLink')}
                              </CopyValueReview>
                            )}
                          </div>
                        </>
                      )}
                    </div>

                    <div className='value mb-2 mt-2'>
                      {t('Creator.ReviewLiveStream.OBSDesc')}
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className='d-flex align-items-center box-btn' style={{}}>
              <button
                className={`btn ${'button-submit'} d-middle`}
                style={{ width: '100%' }}
                onClick={(e) => {
                  if (data?.is_password === 1) {
                    if (listCheckPassword === id?.toString()) {
                      handleOpenPopup(e);
                    } else {
                      handleSetPassword(e);
                    }
                  }
                  if (data?.is_password === 0) {
                    handleOpenPopup(e);
                  }
                }}>
                {t('Creator.ReviewLiveStream.deliveryStart')}
              </button>
              <button
                className={`btn d-middle btn-edit`}
                style={{ width: '100%' }}
                onClick={() =>
                  navigate(`${newPathUser}live/create/${id}/${liveKey}`)
                }>
                {t('Common.buttonEdit')}
              </button>
            </div>
          </div>
          {data?.is_password === 1 && UISetPassword}
        </LayoutCreatorRight>
      ) : null}
    </>
  );
};

export default withCreatorRole(ReviewLivestream);
