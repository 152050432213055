import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isMobile, isTablet } from 'react-device-detect';

import { LayoutCreatorRight, PopupConfirmFan, PopupLoading } from 'components';
import NavbarUserProfile from 'components/NavbarEditProfile';
import ProfileAvatar from 'components/Profile-avatar';

import withCreatorRole from 'hocs/withCreatorRole';
import withCreatorProfilePage from 'context/CreatorProfileProvider';

import './index.scss';
import { newPathUser } from 'constants/layout/constant';
import ProfileBackgroundImage from 'components/Profile-background-image';
import PhosphorIconContract from 'images/profile-contract/PhosphorIconContract.png';
import { PlusOutlined } from '@ant-design/icons';

import { TEN_MB } from 'utils';

import { getUserInfo } from 'store/actions/users';

import { deleteContract, getContracts } from 'store/actions/contract';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { pushNotify } from 'components/Notification';
import PopupContracts from './popup/contracts';
import { useNavigate } from 'react-router-dom';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { getIDKYC } from 'store/actions/usersNew';

const ProfileContract = ({ isShowProfile = true }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ACTIVE_TAB = 3;

  const { data: userProfile } = useSelector((state) => state.users);
  const { idKYC } = useSelector((state) => state.usersNew);
  const { watch, setValue } = useForm({
    mode: 'all',
  });
  const dataStore = window.localStorage.getItem('dataKYC') || null;
  const tmp = dataStore ? JSON.parse(dataStore) : 1

  const [page, setPage] = useState(tmp?.pagination?.current_page || 1);
  const [loading, setLoading] = useState(false);
  const [loadingScroll, setLoadingScroll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const watchCover = watch('bg_img', undefined);
  const watchAvatar = watch('avatar', undefined);

  const [avatar, setAvatar] = useState('');
  const [errorBackGround, setErrorBackGround] = useState('');

  const [background, setBackground] = useState();
  const [errorAvatar, setErrorAvar] = useState('');

  const [contracts, setContracts] = useState({});
  const [contract, setContract] = useState({});
  const [contractId, setContractId] = useState(null);

  const [showPopupCreate, setShowPopupCreate] = useState(false);
  const [isPopUpConfirmDelete, setIsPopUpConfirmDelete] = useState(false);
  const [flgRender, setFlgRender] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    if(dataStore) {
      setContracts(JSON.parse(dataStore));
      setFlgRender(2);
    }
  }, []);

  useEffect(() => {
    if (idKYC && flgRender === 2) {
      const idPost = document.getElementById(idKYC?.id);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    } else {
      const top = document.getElementById('app_cocofans_0');
      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
    }
  }, [idKYC, flgRender]);

  useEffect(() => {
    if (isShowProfile) {
      dispatch(getUserInfo());
    }
  }, [dispatch, isShowProfile]);

  useEffect(() => {
    if (page === 1 && !dataStore) {
      setLoading(true);
    } else {//setLoadingScroll(true);
    }
    if(!dataStore) {
      dispatch(getContracts({ page }, receiveContracts));
    }
  }, [page]);

  useEffect(() => {
    if (userProfile) {
      setValue('avatar', userProfile.avatar);
      setValue('bg_img', userProfile.bg_img);
    }
  }, [userProfile]);

  const receiveContracts = ({ success, data, pagination }) => {
    if (success) {
      if (pagination?.current_page > 1) {
        setContracts({ listContracts: [...contracts?.listContracts, ...data], pagination });
      } else {
        setContracts({ listContracts: data, pagination });
      }
    }
    setLoadingScroll(false);
    setLoading(false);
  };

  const fetchNext = () => {
    if(dataStore) {
      window.localStorage.removeItem('dataKYC')
    }
    const pages = contracts?.pagination?.total / contracts?.pagination?.per_page;
    if (pages > page) {
      setLoadingScroll(true);
      setPage(page + 1);
    }
  };

  const hasContracts = () => {
    return contracts?.listContracts?.length > 0;
  };

  const handleOpenCreatePage = () => {
    if (isShowProfile) {
      if (isTablet) {
        setContractId(null);
        return setShowPopupCreate(true);
      }

      if (isMobile) {
        dispatch(getIDKYC({ id: null, page: 1 }));
        window.localStorage.setItem('dataKYC', JSON.stringify(contracts));
        return navigate(`${newPathUser}profile/registration-of-performer/create`);
      }

      setContractId(null);
      setShowPopupCreate(true);
    } else {
      setContractId(null);
      setShowPopupCreate(true);
    }
  };

  const handleClosePopupCreate = (success) => {
    if (success) {
      if (contractId) {
        pushNotify('success', `${t('ValidateMsg.UPDATE_CONTRACT_SUCCESS')}`);
        setContractId(null);
      } else {
        pushNotify('success', `${t('ValidateMsg.CREATE_CONTRACT_SUCCESS')}`);
      }
      setLoading(true);
      dispatch(getContracts({ page: 1 }, receiveContracts));
    }
    setContract(null);
    setShowPopupCreate(false);
  };

  const confirmDeleteContract = (contract) => {
    if(dataStore) {
      window.localStorage.removeItem('dataKYC')
      dispatch(getIDKYC({ id: null, page: 1 }));
    }
    setIsPopUpConfirmDelete(true);
    setContract(contract);
  };

  const handleDeleteContract = () => {
    if(dataStore) {
      window.localStorage.removeItem('dataKYC')
      dispatch(getIDKYC({ id: null, page: 1 }));
    }
    setIsPopUpConfirmDelete(false);
    setIsLoading(true);
    dispatch(deleteContract(contract, receiveDeleteContract));
  };

  const receiveDeleteContract = (response) => {
    if(dataStore) {
      window.localStorage.removeItem('dataKYC')
      dispatch(getIDKYC({ id: null, page: 1 }));
    }
    setIsLoading(false);
    setLoading(true);
    dispatch(getContracts({ page: 1 }, receiveContracts));

    if (response.errors) {
      return pushNotify('error', response.errors);
    }

    pushNotify('success', `${t('ValidateMsg.DELETE_CONTRACT_SUCCESS')}`);
  };

  const updateContract = (contract) => {
    if(dataStore) {
      window.localStorage.removeItem('dataKYC')
    }
    if (isShowProfile) {
      if (isTablet) {
        setContractId(contract.id);
        return setShowPopupCreate(true);
      }

      if (isMobile) {
        window.localStorage.setItem('dataKYC', JSON.stringify(contracts));
        dispatch(getIDKYC({ id: contract.id, page: page }));
        return navigate(`${newPathUser}profile/registration-of-performer/${contract.id}`);
      }

      setContractId(contract.id);
      setShowPopupCreate(true);
    } else {
      setContractId(contract.id);
      setShowPopupCreate(true);
    }
  };

  const Contracts = () => {
    return (
      <>
        <div className='contracts-add-new'>
          <button onClick={() => handleOpenCreatePage()} className='btn'>
            <PlusOutlined />
            <span className='ms-2'>{t('Creator.Survey.createNew')}</span>
          </button>
        </div>
        <div className='note-description mt-3'>{t('Contract.noteEditDelete')}</div>
        <div className='contracts-list'>
          {contracts.listContracts.map((item, index) => (
            <div className='contract-item' key={index} id={item?.id}>
              <label className='name'>{`${item.last_name_romaji} ${item.first_name_romaji}`}</label>
              <div className='btn-actions'>
                <button className='btn btn-edit' disabled={item?.is_disabled === 1} onClick={() => updateContract(item)}>
                  {t('Common.buttonEdit')}
                </button>
                <button className='btn btn-cancel' disabled={item?.is_disabled === 1} onClick={() => confirmDeleteContract(item)}>
                  {t('Common.buttonDelete')}
                </button>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const EmptyContracts = () => {
    return (
      <>
        <div className='contracts-container_empty'>
          <div className='contracts-container_empty-icon'>
            <img src={PhosphorIconContract} alt='no data' />
          </div>
          <div className='contracts-container_empty-text'>{t('Contract.noData')}</div>
          <div className='contracts-add-new'>
            <button
              onClick={() => {
                handleOpenCreatePage()
              }}
              className='btn d-flex align-items-center'>
              <PlusOutlined />
              <span className='ms-2'>{t('Creator.Survey.createNew')}</span>
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {isLoading && <PopupLoading />}
      <LayoutCreatorRight
        noFooter={!isShowProfile}
        link={`${newPathUser}`}
        titlePage={t('Contract.title')}
        className='profile'>
        {showPopupCreate && (
          <PopupContracts
            isShowProfile={isShowProfile}
            title={contractId ? t('Contract.updateContract') : t('Contract.createContract')}
            close={(e) => handleClosePopupCreate(e)}
            contractId={contractId}
          />
        )}
        {isPopUpConfirmDelete && (
          <PopupConfirmFan
            isVisiblePopup={(e) => setIsPopUpConfirmDelete(e)}
            colorSvg='#FF9F43'
            iconNote={true}
            text={t('Popup.confirmDeleteContract', {
              name: `${contract.last_name_romaji} ${contract.first_name_romaji}`,
            })}>
            <div className='ok btn' onClick={() => handleDeleteContract()}>
              {t('Common.buttonYes')}
            </div>
            <div className='btn cancel bg-while' onClick={() => setIsPopUpConfirmDelete(null)}>
              {t('Common.buttonNo')}
            </div>
          </PopupConfirmFan>
        )}
        <div style={{ position: 'absolute', top: 0 }} />
        {isShowProfile && (
          <ProfileBackgroundImage
            setBgTmp={setBackground}
            name='bg_img'
            maxSize={TEN_MB}
            isSelected={watchCover}
            defaultValue={userProfile?.bg_img || ''}
            setErrorBG={setErrorBackGround}
          />
        )}

        <div className={`${isMobile ? 'px-3' : 'px-4'} wrapper-contracts`}>
          {isShowProfile && (
            <>
              <ProfileAvatar
                setAvatarTmp={setAvatar}
                name='avatar'
                maxSize={TEN_MB}
                isSelected={watchAvatar}
                defaultValue={userProfile?.avatar || ''}
                setErrorAvar={setErrorAvar}
              />
              <NavbarUserProfile defaultActive={ACTIVE_TAB} className='navbar-edit-profile' />
            </>
          )}
          {loading ? (
            <div className='contracts-shimmer' style={{ padding: `0px'}` }}>
              <ShimmerPostDetails className='shimmer-loading' />
            </div>
          ) : (
            <div className='contracts-container'>
              {hasContracts() ? (
                <>
                  <InfiniteLoad
                    loading={loadingScroll}
                    data={contracts?.listSurvey || []}
                    fetchNext={fetchNext}>
                    <Contracts />
                  </InfiniteLoad>
                </>
              ) : (
                !loading && <EmptyContracts />
              )}
            </div>
          )}
        </div>
      </LayoutCreatorRight>
    </>
  );
};
export default withCreatorRole(withCreatorProfilePage(ProfileContract));
