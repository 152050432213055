import moment from 'moment';
import {
  getPresignedMultiVideoUrlS3,
  getPresignedMultiVideoUrlS3Complete,
  setFileNameS3,
  uploadS3ByPresignedMulti,
} from 'utils';

export const LEN_LIST_IMG = 10;
export const defaultDuration = '01:00';
const defaultDurationToSecond = 60;

export const getSecondsFromHHMMSS = (value) => {
  const [str1, str2, str3] = value.split(':');

  const val1 = Number(str1);
  const val2 = Number(str2);
  const val3 = Number(str3);

  if (!isNaN(val1) && isNaN(val2) && isNaN(val3)) {
    // seconds
    return val1;
  }

  if (!isNaN(val1) && !isNaN(val2) && isNaN(val3)) {
    // minutes * 60 + seconds
    return val1 * 60 + val2;
  }

  return 0;
};

export const toHHMMSS = (secs) => {
  if (secs >= 60) {
    const secNum = parseInt(secs.toString(), 10);
    const minutes = Math.floor(secNum / 60) % 60;
    const seconds = secNum % 60;

    return [minutes, seconds]
      .map((val) => (val < 10 ? `0${val}` : val))
      .filter((val, index) => val !== '00' || index > 0)
      .join(':');
  } else {
    if (secs < 10) {
      return `00:0${secs}`;
    }
    return `00:${secs}`;
  }
};

export const convertTimeToSecond = (time, defaultTime) => {
  const timeValid = moment(time, 'mm:ss').isValid();
  if (timeValid) {
    const minutes = moment(time, 'mm:ss').format('mm');
    const seconds = moment(time, 'mm:ss').format('ss');
    return +minutes * 60 + +seconds;
  } else {
    return defaultTime || defaultDurationToSecond;
  }
};

export const compareTimeValid = (time) => {
  const timeSecond = convertTimeToSecond(time, 0);
  if (timeSecond < 60) {
    return 'ValidateMsg.TIME_LEAST_1_MINUTE';
  } else if (timeSecond > 180) {
    return 'ValidateMsg.TIME_LONGER_3_MINUTES';
  } else {
    return null;
  }
};

export const Array10Item = Array.from(Array(LEN_LIST_IMG), (_, i) => i);

export const CHUNK_FILE = 200 * 1024 * 1024;

export const uploadVideoWithMulti = async (
  videoUpload,
  setPercentUpload,
  chunkSize = CHUNK_FILE
) => {
  const numChunks = Math.ceil(videoUpload.size / chunkSize);
  const fileParts = [];
  const listPercent = [];

  for (let i = 0; i < numChunks; i++) {
    const start = i * chunkSize;
    const end = Math.min(start + chunkSize, videoUpload.size);
    const chunk = videoUpload.slice(start, end);
    fileParts.push(chunk);
    listPercent.push(0);
  }
  setPercentUpload(listPercent);
  if (fileParts?.length > 0) {
    const presignedData = await getPresignedMultiVideoUrlS3(
      'video',
      `uploads/output/videos`,
      setFileNameS3(videoUpload.name),
      'post',
      fileParts.length
    );
    const presignedUrls = presignedData?.data.parts;
    const promiseUpload = presignedUrls?.map((m, i) => {
      return uploadS3ByPresignedMulti(m?.url, fileParts[i], setPercentUpload, listPercent, i);
    });
    return Promise.all(promiseUpload)
      .then(async (rs) => {
        let parts = [];
        rs?.forEach((element, index) => {
          if (element) {
            parts.push({ PartNumber: presignedUrls[index]['partNumber'], ETag: element });
          }
        });
        return parts;
      })
      .then(async (parts) => {
        const response = await getPresignedMultiVideoUrlS3Complete(
          presignedData?.data.upload_id,
          presignedData?.data.bucket,
          presignedData?.data.key,
          parts
        );
        return response;
      });
  }
};
