import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PopupLoading } from '..';
import useUserRole from '../../hooks/useUserRole';
import { logout } from '../../store/actions/users';
import ArrowBlockIcon from 'images/ArrowBlockIcon';
import { newPathUser } from 'constants/layout/constant';
import { useNavigate } from 'react-router-dom';

const PopupBlockUser = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(newPathUser)
    document.querySelector('#root').style.overflow = 'hidden';
    document.querySelector('#root').style.padding = '0';
  }, []);
  const dispatch = useDispatch();
  const { loading, data: dataProfile, dataUser } = useSelector((state) => state.users);
  const { isFan } = useUserRole();
  const { t } = useTranslation();
  return (
    <>
      {loading && <PopupLoading className={'popup-loading'} />}
      <div className={`popup-block ${!isFan && 'creator'}`} />
      <div className={`popup-block_content ${!isFan && 'creator'}`}>
        {isFan ? (
          <>
            <div>
              <ArrowBlockIcon />
              <span>{t('Popup.accountSuspended')}</span>
            </div>
            <button className='btn btn-logout' onClick={() => dispatch(logout())}>
              {t('Common.logOut')}
            </button>
          </>
        ) : (
          <>
            <div className='d-flex w-100 ' style={{ gap: "20px" }}>
              <ArrowBlockIcon />
              <div style={{ textAlign: 'center', color: '#000' }}>
                {dataUser?.status === 2 ? (
                  `${t('Popup.accountSuspended')}`
                ) : (
                  <>
                    {t('Popup.awaitingApproval')}
                    <br />
                    {t('Popup.littleBit')}
                  </>
                )}
              </div>
            </div>
            <div className='w-100 d-flex justify-content-end'>
              <button className='btn btn-logout' onClick={() => dispatch(logout())}>
                {t('Common.logOut')}
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PopupBlockUser;