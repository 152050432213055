import Icon from '@ant-design/icons';

const CalendarIconSelect = (props) => (
  <Icon
    component={() => (
      <svg
        width='14'
        height='16'
        viewBox='0 0 14 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M1.06177 6.26833H12.9444'
          stroke={props?.color || '#B1B6BB'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.96138 8.87771H9.96756'
          stroke={props?.color || '#B1B6BB'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.00307 8.87771H7.00925'
          stroke={props?.color || '#B1B6BB'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.03859 8.87771H4.04477'
          stroke={props?.color || '#B1B6BB'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.96138 11.4636H9.96756'
          stroke={props?.color || '#B1B6BB'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.00307 11.4636H7.00925'
          stroke={props?.color || '#B1B6BB'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.03859 11.4636H4.04477'
          stroke={props?.color || '#B1B6BB'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.69582 1.33594V3.52979'
          stroke={props?.color || '#B1B6BB'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M4.31033 1.33594V3.52979'
          stroke={props?.color || '#B1B6BB'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.82551 2.39062H4.18064C2.22285 2.39062 1 3.48125 1 5.48598V11.5191C1 13.5553 2.22285 14.6712 4.18064 14.6712H9.81933C11.7833 14.6712 13 13.5742 13 11.5695V5.48598C13.0062 3.48125 11.7895 2.39062 9.82551 2.39062Z'
          stroke={props?.color || '#B1B6BB'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default CalendarIconSelect;
