import { configParamsGet, configParamsPostJSON, ENV, updateStatus } from 'utils/constant';
import { callApi } from 'utils/request';
import { queryParamsTimezone, queryString } from '../../utils/utils';
import { TYPES } from './index';

const API = {
  getTopFans: (queries) => `${ENV}/top-users?${queryString(queries) + queryParamsTimezone()}`,
  getTransactions: (queries) =>
    `${ENV}/transactions?${queryString(queries) + queryParamsTimezone()}`,
  getRevenue: (queries) => `${ENV}/revenues?${queryString(queries) + queryParamsTimezone()}`,
  postUserWithdrawRequest: () => `${ENV}/withdraw-request`,
  getTransactionsCSV: (queries) =>
    `${ENV}/transactions?${queryString(queries) + queryParamsTimezone()}`,
  getSettingLimitPayment: () => `${ENV}/setting/limit-payment`,
};
const getTopFans = (queries) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_TOP_FANS_REQUEST));
    callApi(
      API.getTopFans(queries),
      configParamsGet,
      () => null,
      async (reponse) => {
        await dispatch(
          updateStatus(TYPES.GET_TOP_FANS_SUCCESS, {
            data: reponse,
          })
        );
      },
      async (error) => {
        await dispatch(updateStatus(TYPES.GET_TOP_FANS_FAILED, { error: error.errors }));
      }
    );
  };
};

const getTransactions = (queries) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_TRANSACTIONS_REQUEST));
    callApi(
      API.getTransactions(queries),
      configParamsGet,
      () => null,
      async (reponse) => {
        await dispatch(
          updateStatus(TYPES.GET_TRANSACTIONS_SUCCESS, {
            data: reponse,
          })
        );
      },
      async (error) => {
        await dispatch(updateStatus(TYPES.GET_TRANSACTIONS_FAILED, { error: error.errors }));
      }
    );
  };
};

const getRevenue = (queries) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_REVENUE_REQUEST));
    callApi(
      API.getRevenue(queries),
      configParamsGet,
      () => null,
      async (reponse) => {
        await dispatch(
          updateStatus(TYPES.GET_REVENUE_SUCCESS, {
            data: reponse.data,
          })
        );
      },
      async (error) => {
        await dispatch(updateStatus(TYPES.GET_REVENUE_FAILED, { error: error.errors }));
      }
    );
  };
};

const postUserWithdrawRequest = (data, handleFunc, handleFailed) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.POST_USER_WITHDRAW_REQUEST_REQUEST));
    callApi(
      API.postUserWithdrawRequest(),
      configParamsPostJSON(data),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.POST_USER_WITHDRAW_REQUEST_SUCCESS, {
            data: response,
          })
        );
        handleFunc && handleFunc(response);
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.POST_USER_WITHDRAW_REQUEST_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        handleFailed && handleFailed(error);
      }
    );
  };
};
const resetActionSaleConfirmation = () => (dispatch) => {
  dispatch(updateStatus('RESET_ACTION_SALE_CONFIRMATION'));
};

const getTransactionsCSV = (queries, handleSucces) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_TRANSACTIONS_CSV_REQUEST));
    callApi(
      API.getTransactionsCSV(queries),
      configParamsGet,
      () => null,
      async (reponse) => {
        await dispatch(
          updateStatus(TYPES.GET_TRANSACTIONS_CSV_SUCCESS, {
            data: reponse,
          })
        );
        handleSucces && handleSucces(reponse);
      },
      async (error) => {
        await dispatch(
          updateStatus(TYPES.GET_TRANSACTIONS_CSV_FAILED, { error: error.errors })
        );
      }
    );
  };
};

const getSettingLimitPayment = () => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_SETTING_LIMIT_PAYMENT_REQUEST));
    callApi(
      API.getSettingLimitPayment(),
      configParamsGet,
      () => null,
      async (reponse) => {
        await dispatch(
          updateStatus(TYPES.GET_SETTING_LIMIT_PAYMENT_SUCCESS, {
            data: reponse,
          })
        );
      },
      async (error) => {
        await dispatch(
          updateStatus(TYPES.GET_SETTING_LIMIT_PAYMENT_FAILED, { error: error.errors })
        );
      }
    );
  };
};

const getIDSale = (payload) => {
  return (dispatch) => {
    dispatch(updateStatus('SET_ID_SALE', payload));
  };
};

const resetIDSale = () => (dispatch) => {
  dispatch(updateStatus('RESET_ID_SALE'));
};

export {
  getRevenue,
  getTopFans,
  getTransactions,
  postUserWithdrawRequest,
  resetActionSaleConfirmation,
  getTransactionsCSV,
  getSettingLimitPayment,
  getIDSale,
  resetIDSale
};
