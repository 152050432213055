export default function UploadFileIcon(props) {
  return (
    <svg
      width='46'
      height='38'
      viewBox='0 0 46 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <rect x='11' y='17.5' width='22' height='20' fill='#DC143C' />
      <path
        d='M30.5633 0.464007C24.9745 0.347319 19.2076 3.55318 17.6293 10.7694C11.193 8.57692 5.38314 12.9067 5.98501 19.8281C4.28795 20.5134 2.83478 21.6908 1.81252 23.2089C0.790252 24.7269 0.24567 26.5162 0.248854 28.3464C0.248854 33.4377 4.35137 37.534 9.44267 37.534H20.6079L20.5956 27.0567L17.9793 28.0086C16.0755 28.8254 14.6752 26.4302 16.3027 24.9378L21.2589 20.1291C22.3889 18.999 23.7401 19.128 24.7411 20.1291L29.6973 24.944C31.3248 26.4302 29.9184 28.8254 28.0207 28.0086L25.4044 27.0567V37.534H36.5512C41.6425 37.534 45.7389 33.4377 45.7389 28.3464C45.7389 24.6001 43.5157 21.3942 40.3159 19.9632C46.5188 7.8645 38.7314 0.623686 30.5633 0.464007Z'
        fill='#F8D0D8'
      />
      <path
        d='M30.5632 0.460938C30.2562 0.460938 29.9122 0.467079 29.599 0.479362C22.4626 11.7306 33.4927 19.9602 40.3221 19.9602C46.525 7.86143 38.7314 0.626758 30.5632 0.460938Z'
        fill='#DC143C'
      />
    </svg>
  );
}
