import Icon from '@ant-design/icons';

const CameraIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={44}
        height={44}
        viewBox='0 0 44 44'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx={22} cy={22} r='21.5' fill='#DC143C' stroke='#EEEEEE' />
        <path
          d='M29.5 29.5H14.5C14.1022 29.5 13.7206 29.342 13.4393 29.0607C13.158 28.7794 13 28.3978 13 28V17.5C13 17.1022 13.158 16.7206 13.4393 16.4393C13.7206 16.158 14.1022 16 14.5 16H17.4995L18.9995 13.75H24.9995L26.4995 16H29.5C29.8978 16 30.2794 16.158 30.5607 16.4393C30.842 16.7206 31 17.1022 31 17.5V28C31 28.3978 30.842 28.7794 30.5607 29.0607C30.2794 29.342 29.8978 29.5 29.5 29.5Z'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M22 25.75C23.864 25.75 25.375 24.239 25.375 22.375C25.375 20.511 23.864 19 22 19C20.136 19 18.625 20.511 18.625 22.375C18.625 24.239 20.136 25.75 22 25.75Z'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default CameraIcon;
