import {
  configParamsDELETEByAdmin,
  configParamsPOSTByAdmin,
  configParamsPostJSON,
  configParamsPostNoBody,
  ENV,
  updateStatus,
} from 'utils/constant';
import { callApi } from 'utils/request';
import useUserRole from '../../hooks/useUserRole';
import { TYPES } from './index';

// eslint-disable-next-line react-hooks/rules-of-hooks
const { isIdol } = useUserRole();

const configParamsGetCustom = (token) => {
  return {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
};

const API = {
  checkAdminToken: () => `${ENV}/users`,
  loginFanByTokenAdmin: () => `${ENV}/user/admin-login`,
  logout: () => `${ENV}/${isIdol === true ? 'creator' : 'fan'}/logout`,
  deactivePostByAdmin: (id) => `${ENV}/posts/${id}/deactivate`,
  deletePostByAdmin: (id) => `${ENV}/posts/post/${id}`,
  approvePostAPI: (id) => `${ENV}/posts/${id}/approve`,
  rejectPostAPI: (id) => `${ENV}/posts/${id}/reject`,
};

const logout = () => {
  return () => {
    callApi(
      API.logout(),
      configParamsPostNoBody,
      null,
      () => {},
      (error) => {
        console.log(error);
      }
    );
  };
};

const checkAdminToken = (token, handleSuccess, handleFailed) => {
  return async (dispatch) => {
    dispatch(updateStatus(TYPES.CHECK_ADMIN_TOKEN_REQUEST));
    await callApi(
      API.checkAdminToken(),
      configParamsGetCustom(token),
      () => null,
      async () => {
        await dispatch(updateStatus(TYPES.CHECK_ADMIN_TOKEN_SUCCESS, {}));
        handleSuccess && handleSuccess();
      },
      async (error) => {
        await dispatch(updateStatus(TYPES.CHECK_ADMIN_TOKEN_FAILED, { error: error.errors }));
        handleFailed && handleFailed();
      }
    );
  };
};

const loginFanByTokenAdmin = (token, handleSuccess, handleFailed) => {
  return () => {
    callApi(
      API.loginFanByTokenAdmin(),
      configParamsPostJSON({}, token),
      null,
      async (response) => {
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleFailed && handleFailed(error);
      }
    );
  };
};

const deactivePostByAdmin = (data, handleSuccess, handleFailed) => {
  return () => {
    callApi(
      API.deactivePostByAdmin(data?.id),
      configParamsPOSTByAdmin({ admin_reason: data?.reasonContent }),
      null,
      async (response) => {
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleFailed && handleFailed(error);
      }
    );
  };
};

const deletePostByAdmin = (data, handleSuccess, handleFailed) => {
  return () => {
    callApi(
      API.deletePostByAdmin(data?.id),
      configParamsDELETEByAdmin({
        admin_reason: data?.reasonContent,
        cs_admin_id: data?.cs_admin_id,
      }),
      null,
      async (response) => {
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleFailed && handleFailed(error);
      }
    );
  };
};

const approvePostByAdmin = (id, handleSuccess, handleFailed) => {
  return () => {
    callApi(
      API.approvePostAPI(id),
      configParamsPOSTByAdmin(),
      null,
      async (response) => {
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleFailed && handleFailed(error);
      }
    );
  };
};

const rejectPostByAdmin = (data, handleSuccess, handleFailed) => {
  return () => {
    callApi(
      API.rejectPostAPI(data?.id),
      configParamsPOSTByAdmin({
        admin_reason: data?.reasonContent,
        cs_admin_id: data?.cs_admin_id,
      }),
      null,
      async (response) => {
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleFailed && handleFailed(error);
      }
    );
  };
};

export {
  checkAdminToken,
  loginFanByTokenAdmin,
  logout,
  deactivePostByAdmin,
  deletePostByAdmin,
  approvePostByAdmin,
  rejectPostByAdmin,
};
