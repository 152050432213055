import React, { useRef, useEffect, useLayoutEffect } from 'react';
import {
  CloseIcon,
  CommentIcon,
  Edit3Icon,
  FlagIcon,
  HeartActiveIcon,
  HeartIcon,
  IconBookmark,
  SendChatIcon,
  ShareIcon,
  ThreePointIcon,
  TrashIcon,
} from 'images';

import { convertToCurrentTime, formatNumber, isAdmin } from 'utils/utils';
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useUserRole from 'hooks/useUserRole';
import { useSelector } from 'react-redux';
import CommentPostTimeLine from 'components/comment/commentPostTimeLine';
import { InputWrapper } from 'components/Input/InputWrapper';
import Avatar from 'images/Avatar.png';
import { useDispatch } from 'react-redux';
import {
  creatorDeleteArticle,
  postBookMarkDetail,
  postCommentDetail,
  deleteCommentDetail,
  deleteReplyCommentDetail,
  postFollowCommentDetail,
  postFollowReplyCommentDetail,
  creatorGetPostDetail,
  updateLikeCommentDetail,
  updateLikeReplyComment,
  updatePostLikeDetail,
} from 'store/actions/usersNew';
import useRedirect from 'hooks/useRedirect';
import { getEnv } from 'configs/env';
import './PopupViewFullMess.scss';
import { newPathUser } from 'constants/layout/constant';
import { useNavigate } from 'react-router-dom';
import {
  ImgCustom,
  PopupConfirmFan,
  PopupSharePost,
  ReadMoreLess,
  Textarea,
} from 'components';

const PopupViewFullMess = ({ item, close }) => {
  // const { image, listImage, postInfo } = dataViewFull;
  const { t } = useTranslation();
  const widthScreen = window.innerWidth;
  const { isIdol } = useUserRole();
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const [popupDeleteComment, setPopupDeleteComment] = useState(null);

  const { detailPost, statusPostComment, error } = useSelector((state) => state.usersNew);
  const dataProfile = useSelector((state) => state.users.dataUser);

  const [disabledLike, setDisabledLike] = useState(false);
  const [currentLike, setCurrentLike] = useState({
    like: null,
    numberLike: null,
  });

  const [valueComment, setValueComment] = useState('');
  const [checkSendChat, setCheckSendChat] = useState(null);
  const [isVisiblePopup, setIsVisiblePopup] = useState(-1);
  const [flgRender, setFlgRender] = useState(false);
  const [widthContent, setWidthContent] = useState();
  const [isVisiblePopUpDeletePost, setIsVisiblePopDeletePost] = useState(null);
  const [isVisiblePopUpDeletePostComplete, setIsVisiblePopDeletePostComplete] =
    useState(false);

  const navigate = useNavigate();
  // useEffect(() => {
  //   setCurrentLike({
  //     like: detailPost?.user_follow_status,
  //     numberLike: detailPost?.number_like,
  //   });
  // }, [detailPost]);

  useEffect(() => {
    dispatch(creatorGetPostDetail(item?.id, false));
  }, []);

  const dispatch = useDispatch();

  useRedirect(error);

  const handleLikePost = (post) => {
    dispatch(updatePostLikeDetail(post));
  };

  const handlePostBookMark = (post) => {
    dispatch(postBookMarkDetail(post?.id));
  };

  const handleSharePost = (dataPC) => {
    setPopupCopyLink(dataPC);
  };

  const GroupIcon = (comment) => {
    return (
      <div
        className={`GroupIcon scroll-bar-custom-horizontal ${
          item?.user_follow_status === 0 && item?.follow_flag === 1 && 'disabled'
        } ${isAdmin && 'disabled'}`}
        style={{ padding: '0 16px' }}>
        <div className='d-middle-popup-all'>
          <div className='d-flex align-items-center justify-content-center'>
            {detailPost?.user_like_status === 1 ? (
              <>
                {/* đã like */}
                <HeartActiveIcon
                  className={`like-icon active`}
                  onClick={() => {
                    handleLikePost(item);
                  }}
                />
              </>
            ) : (
              <>
                {/* chưa like */}
                <HeartIcon
                  className={`like-icon`}
                  onClick={() => {
                    handleLikePost(item);
                  }}
                />
              </>
            )}
            <p className='mb-0 cls-text'>{formatNumber(detailPost?.number_like)}</p>
          </div>

          <div className='d-middle'>
            <label
              style={{ display: 'flex', alignItems: 'center' }}
              htmlFor={`comment-item-${item?.id}`}>
              <CommentIcon className='like-icon' />
              <p className='mb-0 cls-text'>{formatNumber(comment) || 0}</p>
            </label>
          </div>
          <ShareIcon
            onClick={() => {
              handleSharePost({
                link: `${getEnv('REACT_APP_FAN_URL')}/post/${item?.id}`,
                title: item?.title,
                id: item?.id,
              });
            }}
            className='like-icon'
          />
        </div>
        <IconBookmark
          style={{
            color: detailPost?.user_bookmark_status === 1 ? '#dc143c' : '#787880',
          }}
          fill={detailPost?.user_bookmark_status === 1 ? '#dc143c' : 'none'}
          onClick={() => handlePostBookMark(item)}
          className='cls-icon mx-0'
        />
      </div>
    );
  };

  const handleDeleteComment = () => {
    const { id, type, idParent } = popupDeleteComment;
    type === 'comment'
      ? dispatch(deleteCommentDetail(id))
      : dispatch(deleteReplyCommentDetail(id, idParent));
    setPopupDeleteComment(false);
  };

  const handleLikeComment = (data) => {
    const { commentId, type, dataPost } = data;
    type === 'comment'
      ? dispatch(updateLikeCommentDetail(commentId, dataPost))
      : dispatch(updateLikeReplyComment(commentId, dataPost));
  };

  const handleFollowUserComment = (data) => {
    const { id, type } = data;
    type === 'comment'
      ? dispatch(postFollowCommentDetail(id))
      : dispatch(postFollowReplyCommentDetail(id));
  };

  const handleTextarea = (e, id, height) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    setValueComment(e.target.value);
    setCheckSendChat(id);
    if (e.target.value?.toString().trim() === '') {
      e.target.style.height = height;
      e.target.style.padding = '9px  30px  9px 12px';
      setCheckSendChat(null);
    }
  };

  // ESC FUNC
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setCheckSendChat(null);
      setValueComment('');
      const textareaId = document.getElementById('textarea-post-comment-full');
      if (textareaId) textareaId.style.height = '38px';
    }
  }, []);

  useRedirect(error);
  // START FUNC ESC
  useEffect(() => {
    if (statusPostComment) {
      setCheckSendChat(null);
      setValueComment('');
      const textareaId = document.getElementById('textarea-post-comment-full');
      if (textareaId) textareaId.style.height = '38px';
    }
    document.addEventListener('keydown', escFunction);
    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction, statusPostComment]);

  const handlePostComment = (id, parent_id) => {
    const data = {
      parent_id: parent_id,
      content: valueComment,
    };
    checkSendChat && dispatch(postCommentDetail(id, data));
  };

  const navigateByLink = (postId, type) => {
    navigate(
      `${newPathUser}post${
        Boolean(type === 2)
          ? '-sale'
          : Boolean(type === 3 || type === 4)
          ? ''
          : ''
      }/update/${postId}`
    );
  };

  const deletePostTimeLine = () => {
    setIsVisiblePopDeletePost(null);
    const { id } = isVisiblePopUpDeletePost;
    dispatch(creatorDeleteArticle(id, () => setIsVisiblePopDeletePostComplete(true)));
    close();
  };

  useLayoutEffect(() => {
    const heightContent = document?.querySelector('.div-content-mess')?.offsetHeight;
    setWidthContent(heightContent);
  }, [flgRender]);

  return (
    <>
      {popupCopyLink && (
        <PopupSharePost
          isVisiblePopup={() => setPopupCopyLink(false)}
          data={popupCopyLink || {}}
        />
      )}
      {isVisiblePopUpDeletePost && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePost(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.confirmDeletePost')}>
          <div className='btn cancel' onClick={() => setIsVisiblePopDeletePost(null)}>
            {t('Common.buttonNo')}
          </div>
          <div className='ok btn' onClick={deletePostTimeLine}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpDeletePostComplete && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePostComplete(e)}
          iconNote={true}
          text={t('Popup.deletePostSucess')}>
          <div className='ok btn' onClick={() => setIsVisiblePopDeletePostComplete(false)}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {popupDeleteComment && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupDeleteComment(false)}
          colorSvg='#FF9F43'
          text={t('Popup.confirmDeleteComment')}
          className='popup-delete'>
          <div className='ok btn' onClick={handleDeleteComment}>
            {t('Common.buttonDelete')}
          </div>
          <div className='btn cancel' onClick={() => setPopupDeleteComment(false)}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}
      <div className='popup-wrap-timline-mess'>
        <div className='close-icon' style={{ cursor: 'pointer' }}>
          {/* <CloseIcon onClick={close} /> */}
          <svg
            onClick={close}
            xmlns='http://www.w3.org/2000/svg'
            width='18'
            height='18'
            fill='none'
            viewBox='0 0 18 18'>
            <path
              stroke='currentColor'
              strokeLinecap='round'
              strokeWidth='1.5'
              d='M4.5 13.875l9.711-9.378M4.5 4.5l9.711 9.378'></path>
          </svg>
        </div>
        <div className='card-item--detail left-content-comment-mess'>
          <div className='card-item--detail--header'>
            <div className='card-item--detail--header-title'>{t('Common.comment')}</div>
            <div className='card-item--detail--header-comment'>{item?.number_comment}</div>
          </div>
          <div className='card-item--detail-line'></div>
          <div className='comment-wrap-popup'>
            <div className='sroll-list'>
              <CommentPostTimeLine
                dataPost={detailPost}
                handleDeleteComment={(e) => {
                  setPopupDeleteComment(e);
                }}
                handleLikeComment={handleLikeComment}
                dataProfile={dataProfile}
                handleFollowUserComment={handleFollowUserComment}
                id={item?.id}
              />
            </div>
            <div className='div-list-cmt'>
              <div className={`comment block`}>
                <ImgCustom
                  screen='avartar_navbar_60_60'
                  src={dataProfile?.avatar ? `${dataProfile.avatar}` : Avatar}
                />
                <form onSubmit={(e) => e.preventDefault()}>
                  <InputWrapper id='fsite-comment' className='form-item'>
                    <Textarea
                      disabled={
                        item?.enable_comment === 0 ||
                        (item?.user_follow_status === 0 && item?.follow_flag === 1)
                      }
                      onChange={(e) => handleTextarea(e, item?.id, '38px')}
                      placeholder={t('comment.formAddRepComment.placeholder')}
                      onKeyDown={(e) => handleTextarea(e, item?.id, '38px')}
                      value={valueComment}
                      maxLength='500'
                      id='textarea-post-comment-full'
                      overflowHidden
                    />
                    <SendChatIcon
                      className={`${valueComment !== '' && 'active'}`}
                      onClick={() => valueComment !== '' && handlePostComment(item?.id, 0)}
                    />
                  </InputWrapper>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopupViewFullMess;
