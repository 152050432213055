import {
  Checkbox,
  ImgCustom,
  LayoutFan,
  PopupConfirmFan,
  PopupMessageUpdateEmail,
} from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import { pushNotify } from 'components/Notification';
import { getEnv } from 'configs/env';
import useCalendar from 'hooks/useCalendar';
import useOutsideClickCustom from 'hooks/useOutsideClickCustom';
import { ArrowLeftIcon, CalendarIcon, CameraIcon } from 'images';
import Avatar from 'images/Avatar.png';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import {
  editUserProfile,
  fanListTag,
  getUserInfo,
  handleChangeEmailStep1,
} from 'store/actions/users';
import {
  acceptedImageTypes,
  customStyles,
  failImgInvalid,
  GENDER,
  getPresignedUrlS3,
  isEmptyObject,
  linkS3,
  setFileNameS3,
  STORAGE,
  TEN_MB,
  uploadS3ByPresigned,
  CustomOption,
} from 'utils';
import Storage from 'utils/storage';
import useUserRole from '../../hooks/useUserRole';
import { linkTwitter, unLinkTwitter } from '../../store/actions/twitter';
import NavbarUserProfile from './components/navbar';
import PopupShowListPackage from './components/popup-show-list-package';
import './user.scss';

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { status, category, loading } = useSelector((state) => state.users);
  const dataProfile = useSelector((state) => state.users.data);
  const [focusGender, setFocusGender] = useState(false);
  const [isVisiblePopup, setIsVisiblePopup] = useState(false);
  const [isVisibleUpdateEmailPopup, setIsVisibleUpdateEmailPopup] = useState(false);
  const [imgUpload, setImgUpload] = useState({
    img: null,
    base64: Avatar,
  });
  const [failImg, setFailImg] = useState();
  const [checkSizeImg, setCheckSizeImg] = useState();
  const [listChecked, setListChecked] = useState([]);
  const [dataCheckBox, setDataCheckBox] = useState([]);
  const [popupLoading, setPopupLoading] = useState(false);
  const { t } = useTranslation();
  const { currentAge, toggleCalendar, isVisibleCalendar } = useCalendar();
  const datePickerRef = useRef({});
  const isTokenTwitter = Storage.has(STORAGE.TOKEN_TWITTER);
  const isSecretTokenTwitter = Storage.has(STORAGE.SERECT_TOKEN_TWITTER);
  const [isLoading, setIsLoading] = useState(false);
  const { isIdol } = useUserRole();

  useOutsideClickCustom((e) => {
    if (isVisibleCalendar) {
      const { className } = e?.target;
      if (
        e?.target !== datePickerRef?.current &&
        className !== '' &&
        !className.includes('react-calendar')
      ) {
        toggleCalendar();
      }
    }
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'all',
  });

  const isDisabledButton =
    loading || failImg || checkSizeImg > TEN_MB || !isEmptyObject(errors);

  useEffect(() => {
    dispatch(fanListTag());
  }, []);

  useEffect(() => {
    status === true && setIsVisiblePopup(true);
  }, [status]);

  useEffect(() => {
    if (!isEmptyObject(dataProfile) && dataProfile !== null) {
      const { birthday, full_name, name_romaji, description, id, gender, user_tag, email } =
        dataProfile;
      setImgUpload((prev) => ({
        ...prev,
        img: dataProfile?.avatar,
      }));
      setValue('birthday', birthday?.split('-')?.join('/'));
      setValue('full_name', full_name);
      setValue('name_romaji', name_romaji);
      setValue('id', id);
      setValue('description', description);
      setValue('gender', gender);
      setValue('tag_ids', user_tag);
      setValue('email', email);
      if (category?.length > 0) {
        const removeTagUnActive = category.filter((item) => item.active_flag === 1);
        if (user_tag?.length > 0) {
          const newListChecked = removeTagUnActive.map((item) => {
            user_tag.map((pack) => {
              if (+pack.tag_id === +item.id) {
                item = { ...item, checked: true };
              }
            });
            return item;
          });
          setDataCheckBox(newListChecked);
          setListChecked(user_tag);
        } else {
          setDataCheckBox(removeTagUnActive);
        }
      }
    }
  }, [category, dataProfile, setValue]);

  const handleUploadImg = (e) => {
    const sizeImg = e.target.files[0].size;
    const checkImg = acceptedImageTypes.includes(e.target.files[0]['type']);
    setFailImg(true);
    setCheckSizeImg(sizeImg);
    checkImg && setFailImg(false);
    if (e.target.files[0] && sizeImg < TEN_MB) {
      setImgUpload((prev) => ({ ...prev, img: e.target.files[0] }));
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImgUpload((prev) => ({ ...prev, base64: reader.result }));
      });
      reader.readAsDataURL(e.target.files[0]);
    } else {
      !checkImg && failImgInvalid();
    }
  };

  const handleKeyDown = (e) => {
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    if (e.target.value === '') {
      e.target.style.height = '100px';
    }
  };

  const handleOnchangePlanPost = (e, pack) => {
    const { checked } = e.target;
    if (checked) {
      setListChecked(
        listChecked.concat({
          tag_id: pack.id,
        })
      );
      const newData = dataCheckBox.map((item) => {
        if (item.id === pack.id) {
          return { ...item, checked: true };
        } else return item;
      });
      setDataCheckBox(newData);
    } else {
      const newData = dataCheckBox.map((item) => {
        if (item.id === pack.id) {
          return { ...item, checked: false };
        } else return item;
      });
      setDataCheckBox(newData);
      setListChecked(listChecked.filter((item) => item.tag_id !== pack.id));
    }
  };

  const onSubmit = async (data) => {
    setPopupLoading(true);
    let avatar_pre_signed;
    let newData = {
      description: data.description,
      tag_ids: listChecked.map((item) => item.tag_id),
      avatar: imgUpload.img,
    };
    const checkNewImg = typeof newData.avatar === 'object';
    const checkPostAvatarPromise =
      checkNewImg &&
      new Promise((resolve, reject) => {
        getPresignedUrlS3(
          'image',
          `${dataProfile.id}/avatar/`,
          setFileNameS3(imgUpload.img.name)
        )
          .then((resUrl) => {
            avatar_pre_signed = resUrl.data.pre_signed;
            newData = {
              ...newData,
              avatar_pre_signed: resUrl.data.pre_signed,
              avatar: resUrl.data.file_path,
            };
          })
          .then(() => {
            uploadS3ByPresigned(avatar_pre_signed, imgUpload.img)
              .then(() => {
                resolve();
              })
              .catch((e) => {
                setPopupLoading(false);
              });
          })
          .catch((_error) => {
            reject();
            setPopupLoading(false);
          });
      });
    if (newData?.avatar)
      await Promise.all([checkPostAvatarPromise])
        .then(() => {
          dispatch(editUserProfile(newData));
          setPopupLoading(false);
        })
        .catch((error) => {
          setPopupLoading(false);
        });
    else {
      dispatch(editUserProfile(newData));
      setPopupLoading(false);
    }
  };

  const customStyleReactSelect = {
    ...customStyles,
    dropdownIndicator: (base) => ({
      ...base,
      display: 'block',
    }),
    singleValue: (base) => ({
      ...base,
      color: '#b1b6bb',
      fontWeight: 500,
    }),
  };

  const WithDropdownIndicator = () => {
    return <ArrowLeftIcon className={`${focusGender ? 'focus' : 'no-focus'}`} />;
  };

  const handleChangeEmail = () => {
    dispatch(handleChangeEmailStep1());
    setIsVisibleUpdateEmailPopup(false);
  };

  const handleLinkOrUnlinkTwitter = (link) => {
    setIsLoading(true);
    link
      ? dispatch(
          linkTwitter(
            {
              token: Storage.get(STORAGE.TOKEN_TWITTER),
              secret: Storage.get(STORAGE.SERECT_TOKEN_TWITTER),
            },
            null,
            () => {
              setIsLoading(false);
              pushNotify('success', t('ValidateMsg.linkTwitter'));
              dispatch(getUserInfo());
            },
            () => setIsLoading(false)
          )
        )
      : dispatch(
          unLinkTwitter(
            () => {
              setIsLoading(false);
              pushNotify('success', t('ValidateMsg.unLinkTwitter'));
              dispatch(getUserInfo());
            },
            () => setIsLoading(false)
          )
        );
  };

  return (
    <>
      {isVisiblePopup && (
        <PopupConfirmFan text={t('Popup.settingConfirm')} colorSvg='#28c76f'>
          <div
            className='ok btn'
            onClick={() => {
              setIsVisiblePopup(false);
              navigate('/my-page');
            }}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisibleUpdateEmailPopup && (
        <PopupMessageUpdateEmail text={t('Popup.changeMessToMail')} className='update-email'>
          <div className='ok btn' style={{ marginTop: 14 }} onClick={handleChangeEmail}>
            {t('Common.Ok')}
          </div>
        </PopupMessageUpdateEmail>
      )}
      <LayoutFan titleHeader={t('User.title')} loading={loading || isLoading || popupLoading}>
        <div className='user-wrapper'>
          <div className='box-user-wrapper'>
            <NavbarUserProfile />
            <form onSubmit={(e) => e.preventDefault()} className='fansite-form form-profile'>
              <InputWrapper id='fsite-avatar' className='avatar'>
                <div className='box-file'>
                  <input
                    type='file'
                    accept='image/png, image/jpeg'
                    onChange={handleUploadImg}
                    onClick={(e) => (e.target.value = null)}
                  />
                  <ImgCustom
                    src={
                      typeof imgUpload.img === 'string' || imgUpload.file === 'string'
                        ? `${imgUpload?.img}`
                        : imgUpload.base64
                    }
                    alt=''
                  />
                  <div className='camera-icon'>
                    <CameraIcon />
                  </div>
                </div>
              </InputWrapper>

              {failImg && (
                <div
                  className='fansite-form-input__error text-danger'
                  style={{ marginTop: -12 }}>
                  {t('ValidateMsg.imgProfileFormat')}
                </div>
              )}
              {checkSizeImg > TEN_MB && (
                <div
                  className='fansite-form-input__error text-danger'
                  style={{ marginTop: -12 }}>
                  {t('ValidateMsg.imgProfileMaxSize')}
                </div>
              )}
              <div className='w-100 d-flex justify-content-end' style={{ marginBottom: 16 }}>
                <button
                  className='btn-update-email btn'
                  onClick={() =>
                    (window.location.href = `${getEnv(
                      'REACT_APP_IDOL_URL'
                    )}${`/sign-up-by-fan?account_name=${dataProfile?.account_name}&birthday=${
                      dataProfile?.birthday
                    }&gender=${dataProfile?.gender}&email=${
                      dataProfile?.email
                    }&token=${Storage.get(STORAGE.USER_ACCESS_TOKEN)}&is_link=${
                      dataProfile?.is_link
                    }`}`)
                  }>
                  {t(
                    dataProfile?.is_link === 1
                      ? 'User.navigateCreator'
                      : 'User.buttonCreateAccountCreator'
                  )}
                </button>
              </div>

              <InputWrapper id='fsite-email' label={t('Common.email')}>
                <input
                  type='text'
                  placeholder='Enter email address'
                  {...register('email')}
                  disabled
                />
              </InputWrapper>

              <div className='w-100 d-flex justify-content-end' style={{ marginBottom: 16 }}>
                <button
                  onClick={() => setIsVisibleUpdateEmailPopup(true)}
                  className='btn-update-email btn'>
                  {t('User.butonChangeEmail')}
                </button>
              </div>

              <InputWrapper label={t('Common.idFan')}>
                <input {...register('id')} disabled />
              </InputWrapper>

              <InputWrapper label={t('Common.nameJP')}>
                <input {...register('full_name')} disabled />
              </InputWrapper>

              <InputWrapper label={t('Common.birthday')}>
                <div className='birthday'>
                  <input
                    {...register('birthday')}
                    readOnly
                    style={{ cursor: 'pointer' }}
                    disabled
                  />
                  <CalendarIcon />
                </div>
              </InputWrapper>

              {+currentAge < 18 && (
                <div
                  className='fansite-form-input__error text-danger'
                  style={{ marginTop: -12 }}>
                  {t('ValidateMsg.birthday18Age')}
                </div>
              )}

              <InputWrapper
                label={t('Common.gender')}
                error={errors?.gender ? errors?.gender?.message : ''}>
                <div className='gender'>
                  <Controller
                    name='gender'
                    control={control}
                    rules={{ required: t('ValidateMsg.genderRequired') }}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={GENDER}
                        onFocus={() => setFocusGender(true)}
                        onBlur={() => setFocusGender(false)}
                        isSearchable={false}
                        onChange={(val) => {
                          field.onChange(val);
                          setFocusGender(false);
                        }}
                        value={GENDER.find((c) => c.value === field.value)}
                        placeholder={t('ValidateMsg.genderRequired')}
                        styles={customStyleReactSelect}
                        components={{
                          DropdownIndicator: WithDropdownIndicator,
                          Option: CustomOption,
                        }}
                        isDisabled
                      />
                    )}
                  />
                </div>
              </InputWrapper>

              <InputWrapper id='fsite-description' label={t('User.description')}>
                <textarea
                  {...register('description')}
                  onKeyDown={(e) => handleKeyDown(e)}
                  onChange={(e) => handleKeyDown(e)}
                  placeholder={t('ValidateMsg.maxLengthProfileDesc')}
                />
              </InputWrapper>

              <InputWrapper
                id='fsite-category'
                className='category'
                label={t('Popup.categoryFavorite')}>
                <div className='box-checkbox'>
                  {dataCheckBox.length > 0 ? (
                    <>
                      {dataCheckBox.slice(0, 6).map((item, index) => (
                        <Controller
                          name={`tag_ids[${index}]`}
                          control={control}
                          key={index}
                          render={() => {
                            return (
                              <Checkbox
                                text={item.tag}
                                checked={item.checked}
                                value={item.id}
                                onClick={(e) => handleOnchangePlanPost(e, item)}
                              />
                            );
                          }}
                        />
                      ))}
                    </>
                  ) : (
                    <span style={{ color: '#ff0000', whiteSpace: 'nowrap' }}>
                      {t('User.userListPackageNull')}
                    </span>
                  )}
                </div>
              </InputWrapper>

              {dataCheckBox?.length > 6 && (
                <PopupShowListPackage
                  allPackage={dataCheckBox}
                  listCheckedInit={listChecked}
                  handleSubmit={(newList, newData) => {
                    setListChecked(newList);
                    setDataCheckBox(newData);
                  }}
                />
              )}

              <div className='form-status'>
                <div className='label'>{t('User.statusAccount')}</div>
                {dataProfile?.status === 1 && (
                  <div className={`status status${dataProfile.status}`}>
                    {t('User.status1')}
                  </div>
                )}
                {dataProfile?.status === 2 && (
                  <div className={`status status${dataProfile.status}`}>
                    {t('User.status2')}{' '}
                  </div>
                )}
                {dataProfile?.status === 5 && (
                  <div className={`status status${dataProfile.status}`}>
                    {t('User.status5')}
                  </div>
                )}
              </div>
              {/* LINK OR UNLINK TWITTER */}

              {dataProfile?.social_account.length ? (
                <div
                  className='btn-link-or-unlink-twitter btn'
                  onClick={() => handleLinkOrUnlinkTwitter(false)}>
                  {t('User.buttonUnLinkTwitter')}
                </div>
              ) : (
                <>
                  {isTokenTwitter && isSecretTokenTwitter ? (
                    <div
                      className='btn-link-or-unlink-twitter btn'
                      onClick={() => handleLinkOrUnlinkTwitter(true)}>
                      {t('User.buttonLinkTwitter')}
                    </div>
                  ) : (
                    <a
                      href={getEnv(
                        isIdol ? 'REACT_APP_API_TWITTER_CREATOR' : 'REACT_APP_API_TWITTER_FAN'
                      )}
                      className={`btn twitter btn-link-or-unlink-twitter btn`}
                      target='popup'
                      onClick={() =>
                        window.open(
                          getEnv(
                            isIdol
                              ? 'REACT_APP_API_TWITTER_CREATOR'
                              : 'REACT_APP_API_TWITTER_FAN'
                          ),
                          'popup',
                          'width=600,height=600,scrollbars=no,resizable=no'
                        )
                      }>
                      {t('User.buttonLinkTwitter')}
                    </a>
                  )}
                </>
              )}

              <div className='form-status' style={{ marginBottom: 22 }}>
                <div className='label'>{t('User.statusIntegrationTwitter')}</div>
                <div className={`status status1`}>
                  {t(
                    dataProfile?.social_account.length === 0
                      ? t('User.noCooperate')
                      : t('User.cooperated')
                  )}
                </div>
              </div>

              <div className='withdraw-money-wrapper'>
                <div className='text'>{t('User.requiedMoney')}</div>
                <div
                  className='btn-withdraw-money btn'
                  onClick={() => navigate('/user/request-leave')}>
                  {t('Common.buttonRequest')}
                </div>
              </div>

              <InputWrapper
                id='fsite-submit'
                className={`button-submit ${isDisabledButton ? 'disabled' : ''}`}>
                <button onClick={handleSubmit(onSubmit)} disabled={isDisabledButton}>
                  {t('Common.buttonUpdate')}
                </button>
              </InputWrapper>
            </form>
          </div>
        </div>
      </LayoutFan>
    </>
  );
};

export default Profile;
