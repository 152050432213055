import Icon from '@ant-design/icons';

const MicroPhoneDisableIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={44}
        height={44}
        viewBox='0 0 44 44'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx={22} cy={22} r='21.5' fill='#BEBEBE' stroke='#EEEEEE' />
        <path
          d='M22 28.75V31.75'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.5 13.75L29.5 30.25'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M24.5225 24.7748C23.833 25.4035 22.9331 25.7513 22 25.75H22C21.0054 25.75 20.0516 25.3549 19.3483 24.6517C18.6451 23.9484 18.25 22.9946 18.25 22V17.875'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.5404 26.9946C25.62 27.8313 24.4863 28.3974 23.2645 28.6304C22.0428 28.8635 20.7803 28.7545 19.6166 28.3154C18.4528 27.8762 17.433 27.1242 16.6697 26.1421C15.9064 25.16 15.4293 23.9861 15.291 22.75'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M28.7086 22.75C28.629 23.4726 28.4324 24.1774 28.1265 24.8369'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18.8086 14.0298C19.1444 13.4854 19.614 13.0361 20.1726 12.7246C20.7313 12.413 21.3603 12.2497 21.9999 12.25H21.9999C22.4924 12.25 22.98 12.347 23.435 12.5355C23.89 12.7239 24.3034 13.0001 24.6516 13.3483C24.9998 13.6966 25.276 14.11 25.4645 14.5649C25.6529 15.0199 25.7499 15.5075 25.7499 16V21.6652'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default MicroPhoneDisableIcon;
