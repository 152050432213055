import Icon from '@ant-design/icons';

const BookmarkLabel = (props) => (
  <Icon
    component={() => (
      <svg
        width='30'
        height='30'
        viewBox='0 0 30 30'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx='15' cy='15' r='15' fill='white' />
        <path
          d='M19.999 22.5L14.9984 19.375L9.99902 22.5V8.75C9.99902 8.58424 10.0649 8.42527 10.1821 8.30806C10.2993 8.19085 10.4583 8.125 10.624 8.125H19.374C19.5398 8.125 19.6988 8.19085 19.816 8.30806C19.9332 8.42527 19.999 8.58424 19.999 8.75V22.5Z'
          fill='currentColor'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default BookmarkLabel;
