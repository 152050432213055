import { ReactCalendar, LayoutCreatorRight, PopupLoading } from 'components';
import React, { useEffect, useState } from 'react';
import '../post/styles.scss';
import { isMappable } from 'utils/utils';
import { useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { clearListSurvey, creatorGetDetailSurvey, creatorUpdateSurvey } from 'store/actions/survey';
import useCalendar from 'hooks/useCalendar';
import withCreatorRole from 'hocs/withCreatorRole';
import PopupConfirmDelete from 'components/PopupConfirmDelete';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { newPathUser } from 'constants/layout/constant';
import ClockIconV2 from 'images/ClockIconV2';
import CalendarIconV2 from 'images/CalendarIconV2';
import VerticalIcon from 'images/VerticalIcon';
import { PlusOutlined } from '@ant-design/icons';
import DeleteIcon from 'images/DeleteIcon';
import ICMoreIcon from 'images/IcMoreIcon';
import ICCloseIcon from 'images/IcCloseIcon';
import ICCopyUpIcon from 'images/IcCopyUp';
import { isMobile } from 'react-device-detect';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { useClickOutSize } from 'hooks/v2/clickOutSide';

const typeAnswer = {
  CHECKBOX: 1,
  OTHER: 2,
};

const EditSurveyScreen = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    getValues,
    register,
    unregister,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const watchTitle = watch('title', '');

  const loading = useSelector((state) => state.survey.loading);

  const { date, setDate, toggleCalendar, onChangeDate, isVisibleCalendar } = useCalendar();

  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [isOpenCustomTime, setIsOpenCustomTime] = useState(false);
  const [valueTime, setValueTime] = useState('');
  const [countAnswer, setCountAnswer] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isDone, setIsDone] = useState(false);

  const [refOutSide] = useClickOutSize(() => {
    setIsOpenCustomTime(false);
  });

  useEffect(() => {
    dispatch(
      creatorGetDetailSurvey(id, handleSetSurveyData, () => {
        setIsLoading(false);
      })
    );
  }, []);

  const handleSetSurveyData = ({ success, data }) => {
    setIsLoading(false);
    if (success) {
      setIsDone(+data?.is_done === 1);
      setValue('title', data?.title);
      setValue('description', data?.description);
      setValue('time', moment.utc(data?.end_date).local().format('HH:mm'));
      setDate(moment.utc(data?.end_date).local().format('YYYY/MM/DD'));
      setHour(Number(moment.utc(data?.end_date).local().format('HH')));
      setMinute(Number(moment.utc(data?.end_date).local().format('mm')));
      setValueTime(moment.utc(data?.end_date).local().format('HH:mm'));
      const questions = data?.survey_questions.map((ques) => {
        return {
          title: ques.title,
          answer: ques.survey_answers,
          hasOther: !!ques.survey_answers.find((ans) => ans.type === 2),
        };
      });
      setValue('questions', questions);
    }
  };

  const handleCustomTime = () => {
    setIsOpenCustomTime(false);
    const time = `${hour < 10 ? `0${hour}` : hour}:${minute < 10 ? `0${minute}` : minute}`;
    setValueTime(time);
    setValue('time', time);
  };

  const { fields, append, remove, swap, update } = useFieldArray({
    control,
    name: 'questions',
  });

  const watchQuestions = watch('questions');
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchQuestions[index],
    };
  });

  const onSubmit = async (data) => {
    const endDate = date + ' ' + data.time;
    const endDateUTC = moment.utc(moment(endDate).utc()).format('YYYY-MM-DD HH:mm');
    try {
      const arr = {
        title: data.title,
        description: data.description,
        end_date: endDateUTC,
        questions: data.questions.map((question) => {
          return {
            title: question.title,
            answers: question.answer.filter((j) => j !== null),
          };
        }),
      };

      dispatch(creatorUpdateSurvey(id, arr, handleSuccess));
    } catch (err) {
      console.log(err);
    }
  };

  const handleSuccess = ({ success }) => {
    if (success) {
      dispatch(clearListSurvey());
      navigate(`${newPathUser}surveys`);
    }
    setShowPopupConfirm(false);
  };

  const dragEnd = (result) => {
    const questions = [...fields];

    const [newQuestions] = questions.splice(result.source.index, 1);
    questions.splice(result.destination.index, 0, newQuestions);

    if (result.source.index === result.destination.index) return;
    swap(result.source.index, result.destination.index);
  };

  const dragEndAnswer = (result, array, index) => {
    const answers = [...array];

    const [newAnswers] = answers.splice(result.source.index, 1);
    answers.splice(result.destination.index, 0, newAnswers);

    setValue(`questions.${index}.answer`, answers);
  };

  const questionValid =
    isMappable(watchQuestions) && watchQuestions?.find((ques) => !ques?.title?.trim());
  const answerValid =
    isMappable(watchQuestions) &&
    watchQuestions?.find((ques) => {
      return ques?.answer.find((ans) => !ans?.title?.trim() && ans?.type === 1);
    });
  const isDisabledSubmit =
    isDone ||
    !watchTitle.trim() ||
    !date ||
    date === '' ||
    valueTime === '' ||
    !isMappable(watchQuestions) ||
    questionValid ||
    answerValid;

  return (
    <>
      <LayoutCreatorRight
        className='create-survey-layout'
        titlePage={t('Creator.Survey.questionnaireEditing')}
        link={`${newPathUser}surveys`}>
        {showPopupConfirm && (
          <PopupConfirmDelete
            title={t('Popup.confirmEditSurvey')}
            setShowPopup={setShowPopupConfirm}
            handleOnSubmit={handleSubmit(onSubmit)}
          />
        )}
        {loading && <PopupLoading className='popup-loading' />}
        {isLoading ? (
          <div style={{ padding: `${isMobile ? '16px' : '24px'}` }}>
            <ShimmerPostDetails className='shimmer-loading' />
          </div>
        ) : (
          <div className='container-fluid w-100 bg-white post-survey-page'>
            <form className='form' onSubmit={(e) => e.preventDefault()}>
              <div className='survey-box'>
                <div className='title mb-3'>
                  <label>{t('Creator.Post.enterTheSurveyTitle')}</label>
                  <span className='fansite-form-input__required'>*</span>
                  <div>
                    <input
                      maxLength={100}
                      placeholder={t('Creator.Post.enterTheSurveyTitle')}
                      {...register(`title`, {
                        onBlur: (e) => (e.target.value = e.target.value.trim()),
                      })}
                    />
                  </div>
                </div>
                <div className='title'>
                  <label>{t('Creator.Post.enterQuestionnaire')}</label>
                  <span className='fansite-form-input__required'>*</span>
                  <div>
                    <textarea
                      maxLength={500}
                      placeholder={t('Creator.Post.upTo1024Characters')}
                      {...register(`description`, {
                        onBlur: (e) => (e.target.value = e.target.value.trim()),
                      })}
                    />
                  </div>
                </div>
                {/* Date time */}
                <div className='w-100 mt-3 d-flex'>
                  <div
                    className='date'
                    onClick={() => {
                      if (isOpenCustomTime) {
                        setIsOpenCustomTime(false);
                      }
                    }}>
                    <label>{t('Creator.Post.answerDeadlineSetting')}</label>
                    <span className='fansite-form-input__required'>*</span>
                    {isVisibleCalendar ? (
                      <CalendarIconV2 className='crp' color='#DC143C' />
                    ) : (
                      <CalendarIconV2
                        className='crp'
                        onClick={() => {
                          toggleCalendar();
                          setValue('date', date);
                        }}
                      />
                    )}
                    <input
                      placeholder='YYYY/MM/DD'
                      {...register('date')}
                      onClick={() => {
                        toggleCalendar();
                        setValue('date', date);
                      }}
                      value={date}
                      readOnly
                      role='button'
                    />
                    {isVisibleCalendar && (
                      <ReactCalendar
                        minDate={new Date()}
                        onChange={onChangeDate}
                        toggleCalendar={toggleCalendar}
                      />
                    )}
                  </div>
                  <div className='time'>
                    <div
                      style={{ marginTop: '25px' }}
                      onClick={() => {
                        setIsOpenCustomTime(!isOpenCustomTime);
                        if (isVisibleCalendar) {
                          toggleCalendar();
                        }
                      }}>
                      <input
                        type='text'
                        placeholder='--:--'
                        {...register('time')}
                        readOnly
                        value={valueTime}
                        className='crp'
                      />
                      <div className='time-icon'>
                        {isOpenCustomTime ? (
                          <ClockIconV2 className='crp' color='#DC143C' />
                        ) : (
                          <ClockIconV2
                            className='crp'
                            onClick={() => {
                              setIsOpenCustomTime(!isOpenCustomTime);
                              if (isVisibleCalendar) {
                                toggleCalendar();
                              }
                            }}
                          />
                        )}
                      </div>
                    </div>
                    {isOpenCustomTime && (
                      <div className='custom-time-post' ref={refOutSide}>
                        <div className='d-flex'>
                          <div className='hour'>
                            {new Array(24).fill(1).map((v, i) => (
                              <div className='event-time' key={i}>
                                <div
                                  className={`item item-hour ${hour === i && 'active'}`}
                                  onClick={() => setHour(i)}>
                                  {`${i}`.padStart(2, 0)}
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className='minute'>
                            {new Array(60).fill(1).map((v, i) => (
                              <div className='event-time' key={i}>
                                <div
                                  className={`item item-minute ${minute === i && 'active'}`}
                                  onClick={() => setMinute(i)}>
                                  {`${i}`.padStart(2, 0)}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className='box-btn'>
                          <div className={`btn`} onClick={handleCustomTime}>
                            {t('Common.Ok')}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* List question */}
                <DragDropContext onDragEnd={(result) => dragEnd(result)}>
                  <Droppable droppableId='listQuestion'>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {controlledFields.map((field, index) => (
                          <Draggable
                            draggableId={`id-${index}`}
                            key={`id-${index}`}
                            index={index}>
                            {(provided) => (
                              <div
                                className='question-box'
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                key={index}>
                                <div className='text-center mb-2'>
                                  <ICMoreIcon alt='ic-more' draggable={false} />
                                </div>
                                <div className='d-flex justify-content-center'>
                                  <div className='question-title'>
                                    {t('Creator.Post.enterSurveyAnswer')}
                                  </div>
                                  <span className='fansite-form-input__required'>*</span>
                                </div>
                                <div className='question-header'>
                                  <input
                                    className='input-title'
                                    placeholder={t(
                                      'Creator.Post.enterTheQuestionnaireQuestionText'
                                    )}
                                    maxLength={100}
                                    {...register(`questions.${index}.title`, {
                                      required: true,
                                      onBlur: (e) => (e.target.value = e.target.value.trim()),
                                    })}
                                  />
                                  {errors?.questions && errors?.questions[index]?.title && (
                                    <label className='error-message mt-2 ms-4'>
                                      {t('Input.InputWrapper.must')}
                                    </label>
                                  )}
                                </div>
                                <hr className='hyphen' />
                                <div className='answer'>
                                  {/* List answer */}
                                  <label className='answer-title'>
                                    {t('Creator.Post.answer')}
                                  </label>
                                  <span className='fansite-form-input__required'>*</span>
                                  <DragDropContext
                                    onDragEnd={(result) =>
                                      dragEndAnswer(result, field?.answer, index)
                                    }>
                                    <Droppable droppableId='listAnswer'>
                                      {(answerProvided) => (
                                        <div
                                          className='list-answer'
                                          {...answerProvided?.droppableProps}
                                          ref={answerProvided?.innerRef}>
                                          {field?.answer
                                            // ?.sort((a, b) => a?.type?.localeCompare(b?.type)) Cho Other nằm cuối
                                            ?.map((v, i) => (
                                              <Draggable
                                                draggableId={`ans-${i}`}
                                                key={`ans-${i}`}
                                                index={i}>
                                                {(answerProvided) =>
                                                  v !== null && (
                                                    <div
                                                      {...answerProvided.draggableProps}
                                                      {...answerProvided.dragHandleProps}
                                                      ref={answerProvided.innerRef}
                                                      key={
                                                        v?.count ? 'new-' + v.count : v?.id
                                                      }>
                                                      <div className='list-answer-item d-flex my-2'>
                                                        <VerticalIcon className='list-answer-drag' />
                                                        {v?.type === typeAnswer.CHECKBOX ? (
                                                          <input
                                                            key={
                                                              v?.count
                                                                ? 'new-' + v.count
                                                                : v?.id
                                                            }
                                                            className='list-answer-input'
                                                            placeholder={`${t(
                                                              'Creator.Post.answer'
                                                            )}${i + 1}`}
                                                            maxLength={255}
                                                            {...register(
                                                              `questions.${index}.answer.${i}.title`,
                                                              {
                                                                required: true,
                                                              }
                                                            )}
                                                          />
                                                        ) : (
                                                          <input
                                                            className='list-answer-input'
                                                            disabled
                                                            key={v?.id}
                                                            maxLength={255}
                                                            value={t('Survey.others')}
                                                          />
                                                        )}
                                                        {/* Remove answer */}
                                                        <ICCloseIcon
                                                          style={{
                                                            display:
                                                              field?.answer.length === 1
                                                                ? 'none'
                                                                : '',
                                                          }}
                                                          onClick={() => {
                                                            if (field?.answer.length === 1) {
                                                              return false;
                                                            }
                                                            if (v?.type === typeAnswer.OTHER) {
                                                              unregister(
                                                                `questions.${index}.answer.${i}`
                                                              );
                                                              setValue(
                                                                `questions.${index}.answer.${i}`,
                                                                null
                                                              );
                                                              setValue(
                                                                `questions.${index}.hasOther`,
                                                                false
                                                              );
                                                              update(index, {
                                                                title: field?.title,
                                                                hasOther:
                                                                  getValues()?.questions[index]
                                                                    ?.hasOther,
                                                                answer: field.answer.filter(
                                                                  (j) => j !== null
                                                                ),
                                                              });
                                                            } else {
                                                              unregister(
                                                                `questions.${index}.answer.${i}`
                                                              );
                                                              update(index, {
                                                                title: field?.title,
                                                                hasOther:
                                                                  getValues()?.questions[index]
                                                                    ?.hasOther,
                                                                answer: field.answer.filter(
                                                                  (j) => j !== null
                                                                ),
                                                              });
                                                            }
                                                          }}
                                                          src='/images/icon/ic-close.svg'
                                                          alt='ic-close'
                                                        />
                                                      </div>
                                                      {errors?.questions &&
                                                        errors?.questions[index]?.answer &&
                                                        errors?.questions[index]?.answer[i]
                                                          ?.title && (
                                                          <label className='error-message mb-2 ms-4'>
                                                            {t(
                                                              'Creator.Survey.pleaseEnterYourSurveyResponses'
                                                            )}
                                                          </label>
                                                        )}
                                                    </div>
                                                  )
                                                }
                                              </Draggable>
                                            ))}
                                          {/* Add option or other */}
                                          <div
                                            className='add-answer add-answer_other'
                                            draggable={false}>
                                            {getValues()?.questions[index]?.hasOther ===
                                              false && (
                                              <button
                                                onClick={() => {
                                                  update(index, {
                                                    title: field?.title,
                                                    hasOther: true,
                                                    answer: [
                                                      ...field?.answer,
                                                      {
                                                        title: null,
                                                        type: typeAnswer.OTHER,
                                                      },
                                                    ],
                                                  });
                                                }}>
                                                <PlusOutlined className='add-answer-icon_plus' />
                                                {t('Creator.Survey.add')}
                                              </button>
                                            )}
                                          </div>
                                          <div className='add-answer add-answer_new'>
                                            <button
                                              onClick={() => {
                                                update(index, {
                                                  title: field?.title,
                                                  hasOther:
                                                    getValues()?.questions[index]?.hasOther,
                                                  answer: [
                                                    ...field?.answer,
                                                    {
                                                      title: '',
                                                      type: typeAnswer.CHECKBOX,
                                                      id: countAnswer + 1,
                                                    },
                                                  ],
                                                });
                                                setCountAnswer(countAnswer + 1);
                                              }}>
                                              <PlusOutlined className='add-answer-icon_plus' />
                                              {t('Creator.Survey.addOption')}
                                            </button>
                                          </div>
                                          {answerProvided.placeholder}
                                        </div>
                                      )}
                                    </Droppable>
                                  </DragDropContext>
                                </div>
                                <div className='icon'>
                                  <div className='me-3' onClick={() => append(field)}>
                                    <ICCopyUpIcon alt='ic-copy' />
                                  </div>
                                  <div
                                    onClick={() => remove(index)}
                                    style={{ position: 'relative', top: '-1px' }}>
                                    <DeleteIcon />
                                  </div>
                                </div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                <div className='question-add'>
                  <button
                    onClick={() => {
                      append({
                        title: '',
                        hasOther: false,
                        answer: [
                          {
                            title: '',
                            type: 1,
                            id: 0,
                          },
                        ],
                      });
                    }}>
                    <PlusOutlined className='question-add-icon_plus' />
                    <span className='question-add-text'>
                      {t('Creator.Survey.addNewSurvey')}
                    </span>
                  </button>
                </div>

                <div className='container-btn'>
                  <button
                    className={`btn button-red-new ${isDisabledSubmit ? 'disabled' : 'active'}`}
                    type='submit'
                    disabled={isDisabledSubmit}
                    onClick={() => setShowPopupConfirm(true)}>
                    {t('Creator.Post.keep')}
                  </button>
                  <button
                    className={`btn btn-back cancel`}
                    type='button'
                    onClick={() => {
                      navigate(`${newPathUser}surveys`);
                    }}>
                    {t('Common.buttonCancel')}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(EditSurveyScreen);
