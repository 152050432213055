import { newPathUser } from 'constants/layout/constant';
import { SearchIcon, TimelineIcon } from 'images';
import LetterIcon from 'images/LetterIcon';
import PaperWriteDownIcon from 'images/PaperWriteDownIcon';
import SettingIcon from 'images/SettingIcon';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import i18n from 'translate/i18n';
import { isAdmin } from '../../utils/utils';
import PopupChoiceTypePost from '../Popup/choiceTypePost';
import './index.scss';

const tabs = [
  {
    label: `${i18n.t('FooterTabs.timeline')}`,
    icon: <TimelineIcon />,
    router: `${newPathUser}timeline`,
  },
  {
    label: `${i18n.t('Common.search')}`,
    icon: <SearchIcon />,
    router: `${newPathUser}search`, //C030
  },
  {
    label: `${i18n.t('FooterTabs.createPost')}`,
    icon: <PaperWriteDownIcon />,
    router: `${newPathUser}post`, //popup
    type: 'popup-post',
  },
  {
    label: `${i18n.t('FooterTabs.listLiveStream')}`,
    icon: <SettingIcon />,
    router: `${newPathUser}live/list-livestream`, //C092
  },

  {
    label: `${i18n.t('FooterTabs.createMessage')}`,
    icon: <LetterIcon />,
    router: `${newPathUser}message/create`, //c012
  },
];

const FooterTabs = ({ disabled, classNameCustom }) => {
  const navigate = useNavigate();
  const [isVisiblePopupPost, setIsVisiblePopupPost] = useState(false);
  const { pathname } = useLocation();

  return (
    <>
      <PopupChoiceTypePost
        isVisible={isVisiblePopupPost}
        closeModal={() => setIsVisiblePopupPost(false)}
        title={`${i18n.t('FooterTabs.createPost')}`}
      />
      <div
        className={`tabs-footer creator bg-white d-flex justify-content-center ${classNameCustom}`}
        style={{ pointerEvents: disabled ? 'none' : 'auto' }}
        id='footer_coverout'>
        {tabs.map((tab, index) => (
          <div
            style={{ opacity: disabled ? 0.5 : 1 }}
            key={index}
            className={`item d-flex flex-column justify-content-center align-items-center ${
              isAdmin && 'disabled'
            } ${pathname?.includes(tab.router) ? 'active' : ''}`}
            onClick={() => {
              if (tab?.type === 'popup-post') {
                setIsVisiblePopupPost(true);
              } else tab.router && navigate(tab.router);
            }}>
            {tab.icon}
            <div className='py-sm-1 py-0' style={{ whiteSpace: 'nowrap' }}>
              {tab.label}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default FooterTabs;
