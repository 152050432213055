import {
  ArrowDownIcon,
  CheckedIcon,
  MenuIcon,
  CloseIcon,
  EarthIcon,
} from 'images';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AccountSidebar, ImgCustom, MenuSidebarCustom, PopupLoading } from '..';
import useOutsideClickCustom from '../../hooks/useOutsideClickCustom';
import Storage from 'utils/storage.js';
import { LOGO_COCO_FANS, STORAGE } from 'utils/constant';
import { newPathUser } from 'constants/layout/constant';
import PopupChoiceTypePost from '../Popup/choiceTypePost';
import './HeaderIdolV2.scss';
import { isAdmin, isAgency, setOverflowTablet } from 'utils';
import { useSelector } from 'react-redux';
import PopupBlockUserNoLogin from 'components/Popup/blockUserNoLogin';
import { isTablet } from 'react-device-detect';

const HeaderListIdolHome = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const [isVisiblePopupChangeLang, setIsVisiblePopupChangeLang] = useState(false);
  const [loading, setLoading] = useState(false);
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const [isVisiblePopupPost, setIsVisiblePopupPost] = useState(false);
  const [isVisibleMenu, setIsVisbleMenu] = useState(false);
  const { dataUser } = useSelector((state) => state.users);
  const { pathname } = useLocation();
  const [showPopupBlockUserNoLogin, setShowPopupBlockUserNoLogin] = useState(false);

  useEffect(() => {
    if (isVisibleMenu) {
      document.getElementById('root').style.overflow = 'hidden';
      document.getElementById('root').style.touchAction = 'none';
      document.getElementById('root').style.height = '100%';
      document.getElementById('root').style.width = '100vw';
    } else {
      document.getElementById('root').style.touchAction = 'auto';
      setOverflowTablet();
      document.getElementById('root').style.width = 'auto';
      document.getElementById('root').style.position = 'revert';
    }
  }, [isVisibleMenu]);

  useOutsideClickCustom(() => {
    isVisiblePopupChangeLang && setIsVisiblePopupChangeLang(false);
  });

  const handleChangeLange = (value) => {
    i18n.changeLanguage(value);
    return;
  };

  return (
    <>
      {showPopupBlockUserNoLogin && (
        <PopupBlockUserNoLogin
          handleCancel={() => setShowPopupBlockUserNoLogin(false)}
          handleOk={() => {
            if (pathname === '/sign-in') {
              setShowPopupBlockUserNoLogin(false);
              setIsVisbleMenu(false);
            } else navigate('/sign-in');
          }}
        />
      )}
      {loading && <PopupLoading className={'popup-loading loading-reload-page'} />}
      {isVisibleMenu && (
        <div className='box-modal'>
          <div className='menu-mobile'>
            <AccountSidebar
              setIsVisbleMenu={setIsVisbleMenu}
              isAdmin={isAdmin}
              isAgency={isAgency}
              user={dataUser}>
              <CloseIcon
                style={{ cursor: 'pointer', zIndex: 10 }}
                className='close-drawer'
                onClick={() => setIsVisbleMenu(false)}
              />
            </AccountSidebar>
            <MenuSidebarCustom
              setShowPopupBlockUserNoLogin={setShowPopupBlockUserNoLogin}
              showPopupBlockUserNoLogin={showPopupBlockUserNoLogin}
              setIsVisbleMenu={setIsVisbleMenu}
            />
          </div>
          <div className='gray-screen' onClick={() => setIsVisbleMenu(false)}></div>
        </div>
      )}
      <PopupChoiceTypePost
        isVisible={isVisiblePopupPost}
        closeModal={() => setIsVisiblePopupPost(false)}
        title={`${i18n.t('FooterTabs.createPost')}`}
      />
      <div style={{ position: 'relative', height: '60px' }} className={`header-fixed`}>
        <div
          style={{ boxShadow: 'none', background: 'transparent'}}
          className={`box-fansite-headerv2 ${
            isAuth ? 'header-list-idolv2' : 'header-list-idolv2-singin'
          }`}>
          <div className={`fansite-header`} style={{ backgroundColor: 'transparent'}}>
            <div className='nav__btn hide-greater-than-1024' style={{ background: '#DC143C', marginLeft: '8px', borderRadius: '2px', width: `${isTablet ? '34px': '24px'}`, height: `${isTablet ? '34px': '24px'}`, display: 'flex', justifyContent:'center', alignContent: 'center' }}>
              <MenuIcon
                style={{ color: '#fff' }}
                onClick={() => setIsVisbleMenu(true)}
              />
            </div>
            <div className='content-right' style={{ background: '#DC143C', marginRight: '10px', borderRadius: '2px'}}>
              <div className='search-lang-wrapper'>
                <div
                  className='language-wrapper-init'
                  style={{ padding: `${isTablet ? '8px': '4px 6px'}` }}
                  onClick={
                    isVisibleMenu
                      ? () => {}
                      : () => setIsVisiblePopupChangeLang(!isVisiblePopupChangeLang)
                  }>
                  <EarthIcon style={{ paddingRight: '3px' }} /> <ArrowDownIcon />
                  {isVisiblePopupChangeLang && (
                    <div className='popup-change-lang'>
                      <div className='lang-option' onMouseDown={() => handleChangeLange('jp')}>
                        <span className='lang'>日本語</span>
                        <CheckedIcon
                          className={`icon-checked ${i18n.language === 'jp' ? '' : 'd-none'}`}
                        />
                      </div>
                      <div className='lang-option' onMouseDown={() => handleChangeLange('en')}>
                        <span className='lang'>English</span>
                        <CheckedIcon
                          className={`icon-checked ${i18n.language === 'en' ? '' : 'd-none'}`}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderListIdolHome;
