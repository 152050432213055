import React, { Component } from 'react';
import PropTypes from 'prop-types';
import flvjs from 'flv.js';

export default class Reflv extends Component {
  static propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,

    url: PropTypes.string,
    /**
     * media type, 'flv' or 'mp4'
     */
    type: PropTypes.oneOf(['flv', 'mp4']).isRequired,

    isLive: PropTypes.bool,

    cors: PropTypes.bool,

    withCredentials: PropTypes.bool,

    hasAudio: PropTypes.bool,

    hasVideo: PropTypes.bool,

    duration: PropTypes.bool,

    filesize: PropTypes.number,

    segments: PropTypes.arrayOf(
      PropTypes.shape({
        duration: PropTypes.number.isRequired,

        filesize: PropTypes.number,
        url: PropTypes.string.isRequired,
      })
    ),

    config: PropTypes.object,
  };

  initFlv = ($video) => {
    if ($video) {
      if (flvjs.isSupported()) {
        let flvPlayer = flvjs.createPlayer({ ...this.props }, this.props.config);
        flvPlayer.attachMediaElement($video);
        flvPlayer.load();
        flvPlayer.play();
        this.flvPlayer = flvPlayer;
      }
    }
  };

  componentWillUnmount() {
    if (this.flvPlayer) {
      this.flvPlayer.unload();
      this.flvPlayer.detachMediaElement();
    }
  }

  render() {
    const { className, style } = this.props;
    return (
      <video
        className={className}
        controls={true}
        muted
        style={Object.assign(
          {
            width: '100%',
          },
          style
        )}
        ref={this.initFlv}
      />
    );
  }
}
