import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import 'components/LiveStream/Lives.scss';
import DATA_RANKING_FAN from './rankingFan.json';
import avatarDemo from 'images/Avatar.png';
import BoxGift from 'images/BoxGift.png';
import './lives.scss';
import { CURRENT_USER_KEY } from 'constants/shared';
import Storage from 'utils/storage';
import handleSendCoins from 'components/LiveStream/Chat/handleCoins';
import { onValue, ref } from 'firebase/database';
import { DBRealTime } from '../../firebase/config';
import { useSelector } from 'react-redux';
import { updateData } from 'hooks/useRealTimeDB';
import useDevice from 'hooks/useDevice';
import { createTokenAgoraGuest } from 'store/actions/lives';
import { useDispatch } from 'react-redux';
import { messageValidate } from 'utils/messageValidate';
import { useTranslation } from 'react-i18next';
import { MediaPlayer } from 'components/LiveStream/V5/Videos/ObsAgora/MediaAgora/MediaPlayer';
import { useAgora } from 'components/LiveStream/V5/Videos/ObsAgora/useAgora';
import { getEnv } from 'configs/env';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { createContext } from 'react';
import { STORAGE } from 'utils';
import { ViewFan } from './containers/ViewFan';
import { LayoutViewPC } from './ViewFan/styled';
import { newPathUser } from 'constants/layout/constant';
import { ViewGuestV2 } from './containers/ViewGuestV2';
import LocalStorage from 'utils/storage';
import PopupBlockUserNoLogin from 'components/Popup/blockUserNoLogin';
// const TIME_DISPLAY_STAMPS = 2500;
const appID = getEnv('REACT_APP_AGORA_APP_ID');
const client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
export const ControlAgoraContext = createContext();
export const LivestreamFanContext = createContext();
export const LiveStream = () => {
  const [isVisibleSendCoinPopup, setIsVisibleSendCoinPopup] = useState(false);
  const { t } = useTranslation();
  const { liveKey } = useParams();
  // View Agora OBS 26-09-22
  const { join: joinAgora, remoteUsers: remoteAgora, joinState } = useAgora(client, appID);
  // Control Agora OBS Live
  const [pauseLive, setPauseLive] = useState(false);
  //! CHECK DATA DB
  const [userQuit, setUserQuit] = useState(false);

  const handleEventFanQuitLive = () => {
    setUserQuit(!userQuit);
    if (userInfo?.is_auth === 1) {
      LocalStorage.set('from-live', 1);
      navigate(`${newPathUser}lives/list/live`);
    } else {
      LocalStorage.remove('from-live');
      navigate(`${newPathUser}lives/list/live`);
    }
  };

  const handleEventFanQuitWhenEnoughsPoint = () => {
    setUserQuit(!userQuit);
    return (window.location.href = `${newPathUser}purchase`);
  };

  const handleEventFanQuitWhenSubcriber = () => {
    setUserQuit(!userQuit);
    return (window.location.href = `${newPathUser}${userInfo?.livestream?.user_id}/plan-list`);
  };

  const handleNavigateAvatar = (id) => {
    setUserQuit(!userQuit);
    return (window.location.href = `${newPathUser}${id}`);
  };

  // TODO: 27-3-2022 READ CONFIG FIREBASE
  const [allowView, setAllowView] = useState(null);
  const [messageAllow, setMessageAllowView] = useState(null);

  const { isLogUser } = useSelector((state) => state.myPage);

  const [isUserLike, setIsUserLike] = useState(false);

  const [totalNumberLike, setTotalNumberLike] = useState(0);
  // const prevTotalNumberLike = usePreviousState(totalNumberLike);
  const arrColor = [
    '#FF0000',
    '#28C76F',
    '#FAAD14',
    '#A0D911',
    '#FA541C',
    '#2b75e5',
    '#f9ddc2',
    '#EA5455',
    '#fa6347',
  ];

  const [emojiQueue, setEmojiQueue] = useState([]);
  // const prevEmojiQueue  = usePreviousState(emojiQueue)
  const [isFirstLoad, setIsFirstLoad] = useState(null);
  // Token agora
  const [tokenAgora, setTokenAgora] = useState(null);
  const stateCtx = useSelector((state) => state.lives);
  const { userInfo, useInfoLoading } = useSelector((state) => state.lives);

  const randomNumber = (max, min) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const randomPosOrNeg = (max, min) => {
    let randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    randomNumber *= Math.floor(Math.random() * 2) === 1 ? 1 : -1;
    return randomNumber;
  };

  const [denyCharacter, setDenyCharacter] = useState([]);

  //! CHECK IF BUY PACKAGE SUCCESS RELOAD PAGE

  //! END IF BUY PACKAGE SUCCESS RELOAD PAGE
  const ViewAgoraOBS = (
    <>
      {/* {joinState && remoteAgora.length === 0 && 'Channel Unavailable'} */}
      <MediaPlayer
        type={userInfo?.livestream?.obs_flag === 1 ? 'obs' : 'noobs'}
        videoTrack={remoteAgora[remoteAgora.length - 1]?.videoTrack}
        audioTrack={remoteAgora[remoteAgora.length - 1]?.audioTrack}
      />
    </>
  );

  useEffect(() => {
    if (
      userInfo?.auth_id &&
      userInfo?.livestream?.user_id &&
      userInfo?.auth_id === userInfo?.livestream?.user_id
    ) {
      navigate(
        `${newPathUser}live/${userInfo?.livestream?.id}/${userInfo?.livestream?.live_key}`
      );
    }
  }, [userInfo]);

  useEffect(() => {
    if (userInfo) {
      setRemainPoint(userInfo.points);
      setIsFollowOrUnFollow(userInfo.is_followed);

      onValue(
        ref(DBRealTime, `/lives/${liveKey}/likes`),
        (snapshot) => {
          if (snapshot.val() !== null) {
            const list_user_likes =
              snapshot.val().lists !== null ? snapshot.val().lists : null;

            if (list_user_likes && Object.keys(list_user_likes).length > 0) {
              setTotalNumberLike(Object.keys(snapshot.val().lists).length);
              let lastValue = snapshot.val().lists[Object.keys(snapshot.val().lists).pop()];
              if (isFirstLoad !== null) {
              } else {
              }
              if (lastValue.status === 0) {
                setEmojiQueue([...emojiQueue, lastValue]);
              } else {
                setEmojiQueue([...[], {}]);
              }

              return setIsUserLike(false);
            } else {
              return setIsUserLike(false);
            }
          }
        }, //! END if (snapshot.val() !== null)
        {
          onlyOnce: false,
        }
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const [allowComment, setAllowComment] = useState(null);

  const [creatorIsFollow, setCreatorIsFollow] = useState(null);

  useMemo(() => {
    if (userInfo && userInfo?.is_auth === 1) {
      onValue(
        ref(DBRealTime, `/lives/${liveKey}/${stateCtx?.userInfo?.auth_id}`),
        (_snapshot) => {
          setCreatorIsFollow(true);
        },
        {
          onlyOnce: false,
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useMemo(() => {
    onValue(
      ref(DBRealTime, `/lives/${liveKey}/config`),
      (snapshot) => {
        if (snapshot.val() !== null && userInfo !== null) {
          const blockChat = snapshot.val().block_chat;
          if (blockChat !== undefined) {
            const valueBlockChat = Object.values(blockChat).find((obj) => {
              return obj.id === userInfo.auth_id;
            });

            if (valueBlockChat !== undefined) {
              if (valueBlockChat.comment === 1) {
                setAllowComment(1);
              } else {
                setAllowComment(0);
              }
            } else {
              setAllowComment(1);
            }
          } else {
            setAllowComment(1);
          }
          const listWordsC010 =
            snapshot.val().ngWords_user !== undefined ? snapshot.val().ngWords_user[0] : '';
          const listWordsLive =
            snapshot.val().ngWords !== undefined ? snapshot.val().ngWords[0] : '';
          const listWordsAgency =
            snapshot.val().ngWords_agency !== undefined
              ? snapshot.val().ngWords_agency[0]
              : '';
          const listWordsSys =
            snapshot.val().ngWords_sys !== undefined ? snapshot.val().ngWords_sys[0] : '';
          let abc =
            [listWordsAgency].toString() +
            ',' +
            [listWordsC010].toString() +
            ',' +
            [listWordsLive].toString() +
            ',' +
            [listWordsSys].toString();
          const alphaBet = abc.split(',').map((element) => {
            return element.trim();
          });
          setDenyCharacter(alphaBet);
        } //! END if (snapshot.val() !== null)
      },
      {
        onlyOnce: false,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowComment, userInfo]);

  useMemo(() => {
    onValue(
      ref(DBRealTime, `/lives/${liveKey}/config`),
      (snapshot) => {
        if (snapshot.val() !== null) {
          const allMember = snapshot.val()?.all_member;
          const packages = snapshot.val()?.packages;
          const allFollow = snapshot.val()?.all_follow;

          const userInList =
            snapshot.val().user_live !== undefined ? snapshot.val().user_live : [];
          const userDistributions =
            snapshot.val().user_distributions !== undefined
              ? snapshot.val().user_distributions
              : [];
          const userDonations =
            snapshot.val().user_donations !== undefined ? snapshot.val().user_donations : [];
          let dNew = [userInList, userDistributions, userDonations];
          const resultXListUserViewsLiveStream = [].concat(...dNew);
          if (!useInfoLoading && userInfo) {
            if (userInfo?.is_auth === 1) {
              if (allMember === 1) {
                setMessageAllowView({
                  package: null,
                  message: 'Allow_All_Member',
                  key: 'Allow_All_Member',
                });
                return setAllowView(true);
                // isAllow = true;
              } else {
                let checkPackageUserAllow = [];
                if (packages && packages?.length > 0) {
                  checkPackageUserAllow = userInfo?.packages.filter(function (o1) {
                    return packages?.some(function (o2) {
                      return parseInt(o1?.id) === parseInt(o2?.id);
                    });
                  });
                }
                const foundUser =
                  resultXListUserViewsLiveStream.length > 0
                    ? resultXListUserViewsLiveStream.find(
                        (elem) => elem.id === userInfo?.auth_id
                      )
                    : undefined;
                if (
                  (checkPackageUserAllow !== undefined && checkPackageUserAllow.length > 0) ||
                  foundUser !== undefined ||
                  (allFollow === 1 && userInfo?.is_followed === 1)
                ) {
                  setMessageAllowView({
                    package: null,
                    message: 'You_In_Package_Or_List_User_Or_List_Follow',
                    key: 'You_In_Package_Or_List_User_Or_List_Follow',
                  });
                  return setAllowView(true);
                } else {
                  if (
                    checkPackageUserAllow === undefined ||
                    checkPackageUserAllow?.length <= 0
                  ) {
                    if (packages?.length > 0) {
                      const getPackages = packages?.sort((a, b) =>
                        a.price > b.price ? 1 : -1
                      );
                      let packChoice = getPackages[0] || {};
                      getPackages?.every((item) => {
                        if (item?.limited_flag === 0) {
                          packChoice = item;
                          return false;
                        } else {
                          if (item?.limited > item?.number_paid) {
                            packChoice = item;
                            return false;
                          } else {
                            return true;
                          }
                        }
                      });
                      //* CREATOR CO CHON GOI THI FAN MOI DUOC YEU CAU CAN GOI
                      setMessageAllowView({
                        package: `${packChoice?.title}`,
                        message: `${packChoice?.price}`,
                        packageId: `${packChoice?.id}`,
                        description: `${packChoice?.description}`,
                      });
                    } else {
                      if (userInfo?.is_followed === 0 && allFollow === 1) {
                        setMessageAllowView({
                          package: null,
                          message: messageValidate.F014_MESSAGE_IF_USER_NOT_FOLLOW, // 'USER NOT FOLLOW OR NOT LIST',
                          key: 'F014_MESSAGE_IF_USER_NOT_FOLLOW', // 'USER NOT FOLLOW OR NOT LIST',
                        });
                      } else {
                        setMessageAllowView({
                          package: null,
                          message: messageValidate.F014_USER_NOT_IN_LIST_VIEW_LIVE, // 'USER NOT FOLLOW OR NOT LIST',
                          key: 'F014_USER_NOT_IN_LIST_VIEW_LIVE', // 'USER NOT FOLLOW OR NOT LIST',
                        });
                      }
                    }
                  } else {
                    setMessageAllowView({
                      package: null,
                      message: 'ライブの視聴ができません。', // 'USER NOT FOLLOW OR NOT LIST',
                    });

                    //  console.log("XXXXXXXX: ", messageAllow)
                  }
                  return setAllowView(false);
                }
              }
            } else {
              // USER CHUA DANG NHAP
              if (allMember === 1) {
                setMessageAllowView({ package: null, message: 'Allow_All_Member' });
                return setAllowView(true);
                // isAllow = true;
              } else {
                setMessageAllowView({
                  package: null,
                  message: messageValidate.F014_MESSAGE_IF_USER_NOT_ALLOW_ALL_MEMBER,
                  key: 'F014_MESSAGE_IF_USER_NOT_ALLOW_ALL_MEMBER',
                });
                return setAllowView(false);
              }
            }
          }
        } //! END if (snapshot.val() !== null)
      },
      {
        onlyOnce: false,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowView, userInfo]);

  const [remainPoint, setRemainPoint] = useState(userInfo?.points);
  const userStorage = JSON.parse(window.localStorage.getItem(CURRENT_USER_KEY));
  const [userDataStorage, setUserDataStorage] = useState(userStorage);
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);

  const handleSendCoin = (
    coin,
    remainCoin,
    isVisible,
    isSendFirebase,
    stampsIcon,
    stampsGift
  ) => {

    if (isSendFirebase) {
      setIsVisibleSendCoinPopup(isVisible);
      setRemainPoint(remainCoin);
      window.addEventListener('storage', () => {
        const user = JSON.parse(window.localStorage.getItem(CURRENT_USER_KEY));

        setUserDataStorage(user);
      });

      if (Storage.get(CURRENT_USER_KEY)) {
        setUserDataStorage(Storage.get(CURRENT_USER_KEY));
        handleSendCoins(
          1,
          liveKey,
          coin,
          userDataStorage || Storage.get(CURRENT_USER_KEY),
          stampsIcon,
          stampsGift,
          randomNumber(60, 10),
          randomNumber(70, 10)
        );
      }
    } else {
      console.log('LIVE-ERR-SC');
    }
  };

  //* ACTION LIKE / UNLIKE

  const [prvDoubleClick, setPrvDoubleClick] = useState(false);

  const handleSendHeart = async () => {
    setPrvDoubleClick(true);
    const params = {};
    const ids = Math.floor(Math.random() * 10000000000000000);
    const color = arrColor[Math.floor(Math.random() * arrColor.length)];

    params[totalNumberLike] = {
      id: userInfo?.auth_id || ids, // userInfo?.fan_id,
      color: color,
      status: 0,
      label: 'lb-' + ids,
      symbol: 'sys-' + ids,
      size: randomNumber(3, 2),
      left: randomNumber(60, 20),
      one: isDevice ? randomPosOrNeg(200, 0) : randomPosOrNeg(250, 0),
      two: isDevice ? randomPosOrNeg(200, 0) : randomPosOrNeg(250, 0),
    };

    updateData(`/lives/${liveKey}/likes/lists`, params).then(() => {
      setTimeout(() => {
        return updateData(`/lives/${liveKey}/likes/lists/${totalNumberLike}`, {
          status: 1,
        }).then(() => {
          setPrvDoubleClick(false);
        });
      }, 750);
    });
  };

  // //dan-edit start
  // //check horizontal
  const isDevice = useDevice();
  const [checkHorizontalScreen, setCheckHorizontalScreen] = useState('');

  const detect = () => {
    if (window.innerHeight > window.innerWidth) {
      if (isDevice) {
        setCheckHorizontalScreen('portrait');
      }
      // return "portrait";
    } else {
      if (isDevice) {
        setCheckHorizontalScreen('landscape');
      }
      //return "landscape";
    }
  };

  useEffect(() => {
    window.addEventListener('resize', detect);
    return () => window.removeEventListener('resize', detect);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isMobileScreenHorizontal, setIsMobileScreenHorizontal] = useState(false);
  const widthScreen = window.innerWidth;
  const heightScreen = window.innerHeight;

  useEffect(() => {
    if (window.innerHeight > window.innerWidth) {
      setIsMobileScreenHorizontal(false);
      if (isDevice) {
        setCheckHorizontalScreen('portrait');
      }
    } else {
      setIsMobileScreenHorizontal(true);
      if (isDevice) {
        setCheckHorizontalScreen('landscape');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widthScreen, heightScreen]);

  // //dan-edit end

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // console.log("allowView: ", allowView, ' - ALLOW COMMENT: ', allowComment);

  const [isFollowOrUnFollow, setIsFollowOrUnFollow] = useState(userInfo?.is_followed);
  // Get token agora
  useEffect(() => {
    if (liveKey) {
      dispatch(
        createTokenAgoraGuest({
          // name: `${data?.user?.account_name}_${data?.live_key}`,
          name: liveKey,
          exSec: 300,
          action: (tk) => {
            setTokenAgora(encodeURIComponent(tk));
            joinAgora(liveKey, tk);
          },
        })
      );
    }
  }, [liveKey]);

  useEffect(() => {
    if (userInfo?.livestream && +userInfo?.livestream?.status !== 3) {
      navigate('/not-found');
    }
  }, [userInfo]);
  return (
    <LivestreamFanContext.Provider
      value={{
        isVisibleSendCoinPopup,
        handleSendCoin,
        setIsVisibleSendCoinPopup,
        remainPoint,
        allowView,
        userQuit,
        checkHorizontalScreen,
        isFollowOrUnFollow,
        tokenAgora,
        allowComment,
        creatorIsFollow,
        messageAllow,
        liveKey,
        DATA_RANKING_FAN,
        avatarDemo,
        BoxGift,
        isMobileScreenHorizontal,
        setIsFollowOrUnFollow,
        emojiQueue,
        handleEventFanQuitLive,
        handleEventFanQuitWhenEnoughsPoint,
        handleEventFanQuitWhenSubcriber,
        handleNavigateAvatar,
        isUserLike,
        denyCharacter,
        totalNumberLike,
        setUserQuit,
        t,
        prvDoubleClick,
        handleSendHeart,
        ViewAgoraOBS,
      }}>
      <ControlAgoraContext.Provider value={{ pauseLive }}>
        {isLogUser && <PopupBlockUserNoLogin />}
        {isAuth ? (
          <>
            <LayoutViewPC>
              <ViewFan />
            </LayoutViewPC>
          </>
        ) : (
          <>
            <LayoutViewPC>
              <ViewGuestV2 />
            </LayoutViewPC>
          </>
        )}
      </ControlAgoraContext.Provider>
    </LivestreamFanContext.Provider>
  );
};
