import Icon from '@ant-design/icons';

const IconRanking = (props) => (
  <Icon
    component={() => (
      <svg
        width={20}
        height={20}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g id='ic_Active'>
          <path
            id='Vector'
            d='M4.375 4.375V8.67939C4.375 11.781 6.85583 14.3518 9.95735 14.3748C10.6996 14.3805 11.4356 14.2391 12.123 13.959C12.8104 13.6788 13.4355 13.2654 13.9624 12.7425C14.4892 12.2197 14.9074 11.5977 15.1927 10.9124C15.4781 10.2272 15.625 9.49227 15.625 8.75V4.375C15.625 4.20924 15.5592 4.05027 15.4419 3.93306C15.3247 3.81585 15.1658 3.75 15 3.75H5C4.83424 3.75 4.67527 3.81585 4.55806 3.93306C4.44085 4.05027 4.375 4.20924 4.375 4.375Z'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_2'
            d='M7.5 17.5H12.5'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_3'
            d='M10 14.375V17.5'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_4'
            d='M15.4863 10H16.2507C16.9138 10 17.5497 9.73661 18.0185 9.26777C18.4873 8.79893 18.7507 8.16304 18.7507 7.5V6.25C18.7507 6.08424 18.6849 5.92527 18.5677 5.80806C18.4505 5.69085 18.2915 5.625 18.1257 5.625H15.6257'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            id='Vector_5'
            d='M4.5286 10H3.74023C3.07719 10 2.44131 9.73661 1.97247 9.26777C1.50363 8.79893 1.24023 8.16304 1.24023 7.5V6.25C1.24023 6.08424 1.30608 5.92527 1.42329 5.80806C1.5405 5.69085 1.69947 5.625 1.86523 5.625H4.36523'
            stroke='currentColor'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </g>
      </svg>
    )}
    {...props}
  />
);

export default IconRanking;
