import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useUserRole from '../../hooks/useUserRole';
import { useNavigate } from 'react-router-dom';
import { DangerCircleIcon } from 'images';
import { logUserProfile } from 'store/actions/myPage';

const PopupBlockUserNoLogin = ({ handleCancel, handleOk, hideCancel = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isFan } = useUserRole();
  const { t } = useTranslation();
  return (
    <>
      <div className={`popup-block ${!isFan && 'creator'}`}>
        <div
          className={`popup-block_content ${!isFan && 'creator'}`}
          style={{ height: '160px' }}>
          <>
            <div className='d-flex w-100 ' style={{ gap: '20px' }}>
              <DangerCircleIcon color={'#FF9F43'} className='check-icon danger-icon' />
              <div style={{ textAlign: 'left', color: '#1a1a1a' }}>
                {`${t('ValidateMsg.F014_MESSAGE_IF_USER_NOT_LOGIN')}`}
              </div>
            </div>
            <div className='d-flex w-100 justify-content-end' style={{ gap: '10px' }}>
              <button
                className='btn btn-logout'
                onClick={() => (handleOk ? handleOk() : navigate('/sign-in'))}>
                {t('Common.goToLogin')}
              </button>
              {!hideCancel && (
                <button
                  className='btn cancel'
                  onClick={() => (handleCancel ? handleCancel() : dispatch(logUserProfile()))}
                  style={{
                    background: '#fff',
                    color: '#1a1a1a',
                    border: '1px solid #E5E6EB',
                  }}>
                  {t('Common.buttonCancel')}
                </button>
              )}
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default PopupBlockUserNoLogin;
