import Icon from '@ant-design/icons';

const MessageAddIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={16}
        height={16}
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M10.6673 1.33203H5.33398C2.66732 1.33203 1.33398 2.66536 1.33398 5.33203V13.9987C1.33398 14.3654 1.63398 14.6654 2.00065 14.6654H10.6673C13.334 14.6654 14.6673 13.332 14.6673 10.6654V5.33203C14.6673 2.66536 13.334 1.33203 10.6673 1.33203Z'
          stroke='#1480FF'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.66602 8H10.3327'
          stroke='#1480FF'
          strokeWidth='1.5'
          strokeMiterlimit={10}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8 10.3346V5.66797'
          stroke='#1480FF'
          strokeWidth='1.5'
          strokeMiterlimit={10}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default MessageAddIcon;
