import { TYPES } from '../actions';

const initialState = {
  loading: true,
  data: [],
  error: null,
  pagination: {},
  _data : []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_LIST_POST_TIMELINE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_LIST_POST_TIMELINE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, ...action?.data?.data?.data],
        pagination: action?.data?.data?.pagination,
      };
    case TYPES.GET_LIST_POST_TIMELINE_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: true,
      };

    case TYPES.GET_LIST_POST_TIMELINE_LANDING_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_LIST_POST_TIMELINE_LANDING_SUCCESS:
      return {
        ...state,
        loading: false,
        _data: [...state.data, ...action?.data?.data?.data],
        pagination: action?.data?.data?.pagination,
      };
    case TYPES.GET_LIST_POST_TIMELINE_LANDING_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: true,
      };
    default:
      return state;
  }
};
