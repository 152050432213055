import { LayoutFan } from 'components';
import { MessageQuestionIcon, SmsEditIcon } from 'images';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getListFeedBack } from 'store/actions/feedback';
import './helpScreen.scss';

const HelpScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { listFeeds, loading } = useSelector((state) => state.feedback);
  const dispatch = useDispatch();
  const [openContent, setOpenContent] = useState(null);
  const handleToggleContent = (question) => {
    if (openContent === question) {
      setOpenContent(null);
    } else {
      setOpenContent(question);
    }
  };

  useEffect(() => {
    dispatch(getListFeedBack());
  }, [dispatch]);

  return (
    <LayoutFan titleHeader={t('Help.title')} loading={loading}>
      <div className={`list-help`}>
        <div className='box-text'>
          <SmsEditIcon />
          <span className='text'>{t('Help.inquiry')}</span>
        </div>
        <div className='text1'>{t('Help.helpText')}</div>
        <div className='btn-help' onClick={() => navigate('/help/post')}>
          {t('Help.inquiry')}
        </div>
        <div className='box-text'>
          <MessageQuestionIcon />
          <span className='text'>{t('Help.frequetylQuestion')}</span>
        </div>
        {listFeeds.map((item, index) => {
          return (
            <>
              <div key={index} className='title' style={{ marginBottom: 34 }}>
                {item?.name}
              </div>
              {item?.question.map((q, index) => {
                return (
                  <div className='box-content' key={q.id}>
                    <div className='item'>
                      <div
                        className={`item-header ${q?.question !== openContent && 'no-active'}`}
                        onClick={() => handleToggleContent(q?.question)}>
                        <span className='sub-title'>{q?.question}</span>
                        <span className='icon'>
                          {openContent === q?.question ? (
                            <span className='icon-subtraction'></span>
                          ) : (
                            '+'
                          )}
                        </span>
                      </div>
                      {openContent === q?.question && (
                        <div className='item-content'>{q?.answer}</div>
                      )}
                    </div>
                  </div>
                );
              })}
            </>
          );
        })}
      </div>
    </LayoutFan>
  );
};

export default HelpScreen;
