import { ReactCalendar } from 'components';
import useCalendar from 'hooks/useCalendar';
import { CalendarIconSelect } from 'images';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { useState } from 'react';
import moment from 'moment';
const FORMAT = 'YYYY-MM-DD';

const DateRangePicker = ({ isBack, dateRangeNew, onChangeDate }) => {
  const { t } = useTranslation();
  const fromDate = moment().startOf('year').format(FORMAT);
  const toDate = dateRangeNew?.date_to;

  const [dateRange, setDateRange] = useState({
    date_from: fromDate,
    date_to: '',
  });

  //START REACT CALENDAR
  const {
    date: date_from,
    toggleCalendar: toggleCalendarDayFrom,
    onChangeDate: onChangeDateFrom,
    isVisibleCalendar: isVisibleCalendarFrom,
  } = useCalendar({ format: FORMAT });

  const {
    date: date_to,
    toggleCalendar: toggleCalendarDayTo,
    onChangeDate: onChangeDateTo,
    isVisibleCalendar: isVisibleCalendarTo,
  } = useCalendar({ format: FORMAT });

  const handleDateChange = (key, value) => {
    const date = { ...dateRange, [key]: moment(value).format(FORMAT) };
    setDateRange(date);
    onChangeDate(date);
  };

  return (
    <>
      <div className='date-wrapper pick-range-input'>
        <div className='pick-range-date date-from' onClick={toggleCalendarDayFrom}>
          <span className='label'>{t('Common.start')}</span>
          <span className='text-dark px-2'>{date_from ? date_from : fromDate}</span>
          <CalendarIconSelect className='calendar-icon-from' />
        </div>
        {isVisibleCalendarFrom && (
          <ReactCalendar
            defaultValue={fromDate}
            value={date_from ? moment(date_from, 'YYYY-MM-DD').toDate() : ''}
            onChange={(e) => {
              onChangeDateFrom(e);
              handleDateChange('date_from', e);
            }}
            maxDate={date_to ? new Date(date_to) : new Date()}
            className='react-calendar-AF004'
            toggleCalendar={toggleCalendarDayFrom}
          />
        )}
        <svg
          width='12'
          height='6'
          viewBox='0 0 12 6'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M11.6422 4.31563L9.07969 1.06563C9.03296 1.00629 8.97339 0.958321 8.90547 0.925306C8.83755 0.892291 8.76302 0.875092 8.6875 0.875H7.675C7.57031 0.875 7.5125 0.995312 7.57656 1.07812L9.83125 3.9375H0.375C0.30625 3.9375 0.25 3.99375 0.25 4.0625V5C0.25 5.06875 0.30625 5.125 0.375 5.125H11.2484C11.6672 5.125 11.9 4.64375 11.6422 4.31563Z'
            fill='#B1B6BB'
          />
        </svg>
        &nbsp;
        <div className='pick-range-date date-to' onClick={toggleCalendarDayTo}>
          <span className='label'>{t('Common.end')}</span>
          <span className='text-dark px-2'>{!isBack ? date_to : date_to ? date_to : toDate}</span>
          <CalendarIconSelect
            color={(isVisibleCalendarFrom || isVisibleCalendarTo) && '#DC143C'}
            className='calendar-icon'
          />
        </div>
        {isVisibleCalendarTo && (
          <ReactCalendar
            onChange={(e) => {
              onChangeDateTo(e);
              handleDateChange('date_to', e);
            }}
            value={date_to ? moment(date_to, 'YYYY-MM-DD').toDate() : ''}
            minDate={date_from ? new Date(date_from) : new Date(fromDate)}
            maxDate={new Date()}
            className='react-calendar-AF004'
            toggleCalendar={toggleCalendarDayTo}
          />
        )}
      </div>
    </>
  );
};
export default DateRangePicker;
