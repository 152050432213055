import React from 'react';
import './style.scss';
import { useTranslation } from 'react-i18next';

export const InputWrapper = (props) => {
  const { t } = useTranslation();
  return (
    <div
      className={
        'fansite-form-input__controls' + (props.className ? ' ' + props.className : '')
      }
    >
      {props.label && (
        <label
          onClick={props.customFocusHandler}
          htmlFor={props.id ? props.id : ''}
          className='form-label fansite-form-input__label'
        >
          {props.label}
          {props?.subLabel && <span className='fansite-form-input__sub-label'>{props?.subLabel}</span>}
          {props.required && (
            <span className='fansite-form-input__required'>*</span>
          )}
        </label>
      )}
      {props.children}
      {props.error && <p className='fansite-form-input__error text-danger'>{t(props.error)}</p>}
    </div>
  );
};
