import { useVisbilityStateHook } from 'hooks/useHideScreen';
import { isMobile } from 'react-device-detect';
import { useNavigate, useParams } from 'react-router-dom';

export const LivePending = () => {
  const navigate = useNavigate();
  const { liveKey } = useParams();
  useVisbilityStateHook(
    () => {},
    () => {
      if (isMobile) {
        navigate(`/lives/${liveKey}`);
      }
    }
  );

  return <div />;
};
