import { useMemo } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  white-space: normal;
  word-break: break-word;
  padding-right: 8px;
  max-height: ${({ isFullContent }) => (isFullContent ? '150px' : '50px')};
  overflow: ${({ isFullContent }) => (isFullContent ? 'auto' : 'hidden')};
  .btn-read {
    color: #1480ff;
    cursor: pointer;
    &.btn-read-less {
      position: absolute;
      right: 8px;
      bottom: 0;
    }
    &.btn-read-more {
      display: ${({ isShowMore }) => (isShowMore ? 'inline-block' : 'none')};
    }
  }
`;

export const ReadMoreNew = ({
  content = '',
  charLimit = 0,
  isFullContent,
  setIsFullContent,
}) => {
  const { t } = useTranslation();
  const newContent = useMemo(() => {
    if (content) {
      return content.slice(0, charLimit);
    }
    return '';
  }, [charLimit, content]);
  return (
    <Wrapper isFullContent={isFullContent} isShowMore={content?.length > charLimit}>
      {isFullContent ? (
        <>{content}</>
      ) : (
        <>
          {newContent}
          <span
            className='btn-read btn-read-more'
            onClick={() => {
              setIsFullContent();
            }}>
            {t('ReadMoreLess.moreText')}
          </span>
        </>
      )}
    </Wrapper>
  );
};
