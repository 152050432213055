import styled, { css, keyframes } from 'styled-components';
const animation = keyframes`
0% {
  width: 0;
}
100% {
  width: 100%;
}
`;

const fadeIn = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`;

const fadeOut = keyframes`
0% {
  opacity: 1;
}
100% {
  opacity: 0;
}
`;
export const StyledTimeline = {
  Card: styled.div`
    overflow: hidden;
    width: 100%;
    height: 100%;
    /* .content{
      Design thay đổi - Checked 27/11/2022
      position: absolute;
    } */
  `,
  Global: styled.div`
    position: relative;
    &.slideshow {
      margin: 0 auto;
      overflow: hidden;
      max-width: 100%;
      height: 100%;
      width: 100%;
      /* @media screen and (max-width: 575px), (max-height: 450px) {
        max-width: 100%;
        width: 100%;
      } */
    }
    .slideshowSlider {
      white-space: nowrap;
      transition: ease 1000ms;
      height: 100%;
      img {
        /* background: #000; */
        object-fit: contain;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        @media screen and (max-width: 991px) {
          border-radius: 0px;
        }
      }
    }
    .slide {
      display: inline-block;
      height: 100%;
      width: 100%;
      position: absolute;
      opacity: 0;
      &.hide {
        animation: ${fadeOut} 1s;
        img {
          display: none;
        }
      }
      &.show {
        opacity: 1;
        animation: ${fadeIn} 1s;
      }
      img {
        background-color: #1a1a1a;
      }
    }
  `,
  Loading: styled.div`
    position: absolute;
    z-index: 50;
    height: 4px;
    top: 3px;
    width: calc(100% - 64px);
    right: 32px;
    box-shadow: inset 0px 0px 0px 1px #c8c8cd;
    overflow: hidden;
    background: rgba(40, 39, 39, 0.3);
    border-radius: 20px;
    .progress-bar {
      display: flex;
      height: 100%;
      width: 0;
      background: #ff0000;
      ${({ play, time }) =>
        play
          ? css`
              animation: ${animation} ${time ? time : '15'}s linear 0s infinite normal;
            `
          : ''};
    }
  `,
  LoadingProcess: styled.div`
    position: absolute;
    z-index: 50;
    height: 4px;
    top: 8px;
    width: calc(100% - 64px);
    right: 32px;

    @media screen and (max-width: 991px) {
      width: calc(100% - 48px);
      right: 24px;
    }
    overflow: hidden;
    display: flex;
    flex-direction: row;
    border-radius: 20px;
    .slide-process-bar {
      /* background: rgba(40, 39, 39, 0.3);
    box-shadow: inset 0px 0px 0px 1px #c8c8cd; */
    }
    .progress-bar {
      display: flex;
      height: 100%;
      width: 0;
      background: #ff0000;
      ${({ play, time }) =>
        play
          ? css`
              animation: ${animation} ${time ? time : '15'}s linear 0s infinite normal;
            `
          : ''};
    }
  `,
  ProcessBar: styled.div`
    width: 100%;
    .back-ground-process {
      position: relative;
      height: 4px;
      background-color: rgba(255, 255, 255, 0.3);
    }
    .process-item-run {
      position: absolute;
      top: 0;
      left: 0;
    }
  `,

  Count: styled.div`
    position: absolute;
    color: #fff;
    left: 10px;
    top: 20px;
    background: rgb(112 112 112 / 50%);
    border-radius: 4px;
    padding: 2px 12px;
    z-index: 10;
  `,
  Button: styled.button`
    cursor: pointer;
    position: absolute;
    z-index: 3;
    width: 24px;
    height: 24px;
    border-radius: 100%;
    border: none;
    background: #ff0000;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    // Position
    top: 50%;
    transform: translateY(-50%);
    ${({ right = false }) =>
      right
        ? css`
            right: 20px;
          `
        : css`
            left: 20px;
          `}
    @media screen and (max-width: 575px) {
      width: 18px;
      height: 18px;
      ${({ right = false }) =>
        right
          ? css`
              right: 5px;
            `
          : css`
              left: 5px;
            `}
    }
  `,
  ButtonPlay: styled.button`
    cursor: pointer;
    position: absolute;
    z-index: 3;
    width: 89px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    width: 89px;
    height: 60px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    background: rgba(28, 27, 27, 0.5);
    color: white;
    ${({ isShow = false }) =>
      isShow
        ? css`
            display: inline-flex;
          `
        : css`
            display: none;
          `};
    @media screen and (max-width: 575px) {
      width: 42px;
      height: 28px;
      border-radius: 8px;
      svg {
        width: 12.5px;
        height: 14.5px;
      }
    }
  `,
  LineDot: styled.div`
    position: absolute;
    z-index: 5;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 16px;
    display: flex;
    gap: 7px;
    .dot_i {
      cursor: pointer;
      width: 6px;
      height: 2px;
      background-color: #b1b6bb;
      border-radius: 10px;
      &.active {
        background-color: #ff0000;
      }
    }
  `,
};
