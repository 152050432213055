import Icon from '@ant-design/icons';

const MessageEmailV2 = (props) => (
  <Icon
    component={() => (
      <svg
        width={16}
        height={16}
        viewBox='0 0 16 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M11.3333 13.6693H4.66659C2.66659 13.6693 1.33325 12.6693 1.33325 10.3359V5.66927C1.33325 3.33594 2.66659 2.33594 4.66659 2.33594H11.3333C13.3333 2.33594 14.6666 3.33594 14.6666 5.66927V10.3359C14.6666 12.6693 13.3333 13.6693 11.3333 13.6693Z'
          stroke='#8B94A0'
          strokeMiterlimit={10}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.3334 6L9.24674 7.66667C8.56008 8.21333 7.43341 8.21333 6.74674 7.66667L4.66675 6'
          stroke='#8B94A0'
          strokeMiterlimit={10}
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default MessageEmailV2;
