import Icon from '@ant-design/icons';

const MenuIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='24'
        height='24'
        fill='none'
        viewBox='0 0 24 24'>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeWidth='1.5'
          d='M3 7h18M3 12h18M3 17h18'></path>
      </svg>
    )}
    {...props}
  />
);

export default MenuIcon;
