export default function PerformerIcon(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <g id='Phosphor_Icon'>
        <path
          id='Vector'
          d='M10 14.0625C11.7259 14.0625 13.125 12.6634 13.125 10.9375C13.125 9.21161 11.7259 7.8125 10 7.8125C8.27411 7.8125 6.875 9.21161 6.875 10.9375C6.875 12.6634 8.27411 14.0625 10 14.0625Z'
          stroke={props?.color || '#1480FF'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_2'
          d='M15.3125 9.0625C16.0403 9.0619 16.7582 9.23102 17.4092 9.55645C18.0602 9.88188 18.6263 10.3546 19.0626 10.9372'
          stroke={props?.color || '#1480FF'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_3'
          d='M0.9375 10.9373C1.37378 10.3548 1.93992 9.88196 2.59093 9.5565C3.24194 9.23104 3.95989 9.0619 4.68772 9.0625'
          stroke={props?.color || '#1480FF'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_4'
          d='M5.50195 16.8749C5.91252 16.0317 6.5519 15.3208 7.34712 14.8235C8.14233 14.3262 9.06135 14.0625 9.99926 14.0625C10.9372 14.0625 11.8562 14.3262 12.6514 14.8235C13.4466 15.3208 14.086 16.0316 14.4966 16.8749'
          stroke={props?.color || '#1480FF'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_5'
          d='M4.68703 9.0625C4.21269 9.06241 3.74816 8.92738 3.34769 8.67316C2.94721 8.41895 2.62733 8.05605 2.42538 7.62684C2.22344 7.19763 2.14777 6.71982 2.20721 6.24921C2.26665 5.77861 2.45875 5.33462 2.76108 4.96911C3.06341 4.60359 3.46348 4.33163 3.91459 4.18498C4.36569 4.03833 4.84921 4.02304 5.30868 4.1409C5.76815 4.25876 6.18461 4.50491 6.50942 4.8506C6.83423 5.19629 7.054 5.62725 7.14305 6.09316'
           stroke={props?.color || '#1480FF'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          id='Vector_6'
          d='M12.8574 6.09362C12.9464 5.62764 13.1662 5.1966 13.491 4.85083C13.8158 4.50507 14.2323 4.25886 14.6918 4.14095C15.1514 4.02304 15.635 4.03831 16.0861 4.18496C16.5373 4.33162 16.9374 4.60361 17.2398 4.96917C17.5422 5.33473 17.7343 5.77878 17.7937 6.24945C17.8532 6.72012 17.7775 7.19798 17.5755 7.62724C17.3735 8.0565 17.0536 8.41943 16.653 8.67365C16.2525 8.92787 15.7879 9.06288 15.3134 9.06292'
           stroke={props?.color || '#1480FF'}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
    </svg>
  );
}
