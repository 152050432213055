/* eslint-disable import/no-anonymous-default-export */

import { TYPES } from '../actions';
const initialState = {
  status: null,
  loading: false,
  loadingRequestPoint: false,
  error: null,
  data: [],
  setting_system: {},
  post_payment_point: false,
  status_fan_request_point: null,
  code_payment: null,
  isLoadingPaymentVendo: false,
  paymentVendoSuccess: false,
  paymentVendoFail: false,
  isLoadingVerifyToken: false,
  messageErrorVendo: null,
  isCallWhenLimit: 0,
  flgCheckPoint: false,
  dataSend: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_PAYMENT_PACKAGES_REQUEST:
    case TYPES.UPDATE_STATUS_VENDO_PAYMENT_REQUEST:
      return {
        ...state,
        paymentVendoSuccess: false,
        paymentVendoFail: false,
        messageErrorVendo: null,
      };
    case TYPES.POST_PAYMENT_POINT_REQUEST:
    case TYPES.CHECK_POINT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.INPUT_VERIFY_PHONE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.UPDATE_INPUT_VERIFY_PHONE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.POST_PAYMENT_POINT_VENDO_REQUEST:
      return {
        ...state,
        isLoadingPaymentVendo: true,
      };
    case TYPES.VERIFY_PAYMENT_VENDO_REQUEST:
      return {
        ...state,
        isLoadingVerifyToken: true,
      };
    case TYPES.APPLY_VOUCHER_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.SETTING_SYSTEM_REQUEST:
    case TYPES.GET_SETTING_SYSTEM_TYPE_1_PURCHASE_REQUEST:
      return {
        ...state,
        loading: true,
        post_payment_point: false,
        status_fan_request_point: null,
      };
    case TYPES.GET_FAN_REQUEST_POINT_REQUEST:
      return {
        ...state,
        loading: true,
        loadingRequestPoint: true,
        code_payment: null,
      };
    case TYPES.GET_FAN_REQUEST_POINT_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingRequestPoint: false,
        status_fan_request_point: action.data?.statusCode,
        code_payment: action.data.data.code,
      };
    case TYPES.GET_REQUEST_POINT_VENDO_REQUEST:
      return {
        ...state,
        loading: true,
        code_payment: null,
      };
    case TYPES.CHECK_POINT_SUCCESS:
      return {
        ...state,
        loading: false,
        flgCheckPoint: action.data,
      };
    case TYPES.GET_REQUEST_POINT_VENDO_SUCCESS:
      return {
        ...state,
        loading: false,
        code_payment: action.data.data.code,
      };
    case TYPES.GET_PAYMENT_PACKAGES_SUCCESS:
      return {
        ...state,
        data: action?.data?.data,
        loading: false,
      };
    case TYPES.POST_PAYMENT_POINT_SUCCESS:
      return {
        ...state,
        loading: false,
        post_payment_point: true,
      };
    case TYPES.UPDATE_INPUT_VERIFY_PHONE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case TYPES.POST_PAYMENT_POINT_VENDO_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoadingPaymentVendo: false,
        paymentVendoSuccess: action?.data?.status,
      };
    case TYPES.INPUT_VERIFY_PHONE_SUCCESS:
      return {
        ...state,
        dataSend: action.data.data,
        loading: false,
      };
    case TYPES.VERIFY_PAYMENT_VENDO_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoadingVerifyToken: false,
        paymentVendoSuccess: true,
      };
    case TYPES.SETTING_SYSTEM_SUCCESS:
      return {
        ...state,
        loading: false,
        setting_system: JSON.parse(action?.data?.data?.value),
      };
    case TYPES.GET_SETTING_SYSTEM_TYPE_1_PURCHASE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case TYPES.CHECK_POINT_FAILED:
      return {
        ...state,
        loading: false,
      };
    case TYPES.UPDATE_INPUT_VERIFY_PHONE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case TYPES.INPUT_VERIFY_PHONE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case TYPES.GET_PAYMENT_PACKAGES_FAILED:
    case TYPES.POST_PAYMENT_POINT_FAILED:
    case TYPES.GET_FAN_REQUEST_POINT_FAILED:
      return {
        ...state,
        loading: false,
        loadingRequestPoint: false,
        paymentVendoFail: false,
        paymentVendoSuccess: false,
      };
    case TYPES.SETTING_SYSTEM_FAILED:
    case 'POST_PAYMENT_POINT_HIDE_LOADING':
      return {
        ...state,
        isLoadingPaymentVendo: false,
        paymentVendoSuccess: false,
        paymentVendoFail: false,
        messageErrorVendo: null,
      };
    case TYPES.POST_PAYMENT_POINT_VENDO_FAILED:
      return {
        ...state,
        isLoadingPaymentVendo: false,
        paymentVendoSuccess: false,
        paymentVendoFail: true,
        messageErrorVendo: action.data?.error.messageId || action.data?.error,
      };
    case TYPES.APPLY_VOUCHER_PAYMENT_FAILED:
      return {
        ...state,
        loading: false,
      };
    case TYPES.VERIFY_PAYMENT_VENDO_FAILED:
      return {
        ...state,
        isLoadingVerifyToken: false,
        isLoadingPaymentVendo: false,
        paymentVendoSuccess: false,
        paymentVendoFail: true,
        messageErrorVendo: action.data?.error,
      };
    case TYPES.GET_SETTING_SYSTEM_TYPE_1_PURCHASE_FAILED:
      return {
        ...state,
        status: action.type,
        error: action.data.error,
        loading: false,
        post_payment_point: false,
        status_fan_request_point: false,
        code_payment: null,
      };
    case TYPES.PAYMENT_UPDATE_STATUS_PAYMENT_FAIL:
      return {
        ...state,
        paymentVendoFail: true,
        messageErrorVendo: action.data?.messageError,
      };
    case TYPES.RESET_ALL_DATA_PAYMENT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
