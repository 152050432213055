import styled, { css, keyframes } from 'styled-components';

export const StyledDrag = {
  BlueImg: styled.div`
    background: url(${({ url }) => "'" + url + "'"});
    height: ${({ height }) => height}px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    position: relative;
    .bg-img {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 9;
      background-size: cover;
      min-height: 100%;
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(20px);
      border-radius: 8px;
      overflow: hidden;
    }
  `,
};
