import { customStylesReselect } from 'utils';

export const customStyleSelect = {
  ...customStylesReselect,
  control: (base, state) => {
    return {
      ...base,
      cursor: "pointer",
      width: '100%',
      height: 32,
      minHeight: 32,
      maxHeight: 32,
      svg: {
        color: '#666565',
        transform: state.menuIsOpen ? 'rotate(180deg)' : '',
      },
      borderRadius: '6px',
      boxShadow: 'none',
      borderColor: state.isFocused ? '#1480FF' : '#E5E6EB',
    };
  },
  valueContainer: (base) => ({
    ...base,
    paddingTop: 0,
    paddingBottom: 0,
    height: 28,
    minHeight: 28,
    maxHeight: 28,
    display: 'flex',
  }),
  input: (base) => ({
    ...base,
    height: 32,
    minHeight: 32,
    maxHeight: 32,
    padding: 0,
    margin: 0,
    position: 'relative',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    return {
      ...provided,
      opacity,
      fontWeight: 400,
      fontSize: 14,
      position: 'absolute',
      padding: '2px 2px 2px 0',
      color: '#1A1A1A',
    };
  },
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 28,
  }),
  menu: (base, state) => ({
    ...base,
    border: 'none !important',
    borderRadius: '8px',
    overflow: 'hidden',
    zIndex: 20,
    width: 213,
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.06)',
  }),
};

export const listSortTime = (t) => [
  {
    label: `${t('Ranking.realTime')}`,
    value: 'now',
  },
  {
    label: `${t('Ranking.day')}`,
    value: 'day',
  },
  {
    label: `${t('Ranking.week')}`,
    value: 'week',
  },
  {
    label: `${t('Ranking.month')}`,
    value: 'month',
  },
]
