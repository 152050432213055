import { configParamsGetNoToken, ENV, updateStatus } from 'utils/constant';
import { callApi } from 'utils/request';
import { TYPES } from '.';
// UI F002-C030
const API = {
  getListPost: `${ENV}/posts`,
  getSystemSettingMaintain: () => `${ENV}/setting/systems/2`,
  // getSystemSettingMaintain: () => `${ENV}/setting/system?type=2`,
};

const getSystemSettingMaintain = (handleSuccess) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_SYSTEM_SETTING_REQUEST));
    callApi(
      API.getSystemSettingMaintain(),
      configParamsGetNoToken,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_SYSTEM_SETTING_SUCCESS, {
            res: response.data,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_SYSTEM_SETTING_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

export { getSystemSettingMaintain };
