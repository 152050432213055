export default function Rank2(props) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_3083_238444)'>
        <path
          d='M10.7484 17.9395L6.87977 16.6016L6.02623 16.6885L4.18155 21.9885C4.12277 22.1573 4.25955 22.3299 4.43735 22.3113L7.02312 22.0405C7.09535 22.033 7.16716 22.058 7.2191 22.1087L9.07793 23.9265C9.20571 24.0515 9.42012 24.0011 9.47885 23.8323L11.3356 18.4977L10.7484 17.9395Z'
          fill='#98DB7C'
        />
        <path
          d='M11.3357 18.501L11.7109 17.4231L6.40043 15.6172L6.02637 16.6919C7.53434 17.7679 9.36021 18.4266 11.3357 18.501Z'
          fill='#82D361'
        />
        <path
          d='M12.6733 17.9395L16.5419 16.6016L17.3955 16.6885L19.2402 21.9885C19.2989 22.1573 19.1622 22.3299 18.9844 22.3113L16.3986 22.0405C16.3264 22.033 16.2546 22.058 16.2027 22.1087L14.3439 23.9265C14.2161 24.0515 14.0017 24.0011 13.9429 23.8323L12.0862 18.4977L12.6733 17.9395Z'
          fill='#98DB7C'
        />
        <path
          d='M12.0861 18.501L11.7109 17.4231L17.0214 15.6172L17.3955 16.6919C15.8875 17.7679 14.0616 18.4266 12.0861 18.501Z'
          fill='#82D361'
        />
        <path
          d='M11.7108 16.8487C3.0099 16.8487 3.00269 8.41819 3.0099 8.27273C3.23861 3.66534 7.04669 0 11.7108 0C16.3719 0 20.178 3.66047 20.4113 8.2635C20.4189 8.412 20.4113 16.8487 11.7108 16.8487Z'
          fill='#E0E0E0'
        />
        <path
          d='M11.7108 16.5462C7.04668 16.5462 3.23874 12.8808 3.00999 8.27344C3.00277 8.41889 2.99902 8.56528 2.99902 8.71252C2.99902 13.5239 6.89945 17.4243 11.7108 17.4243C16.5222 17.4243 20.4226 13.5239 20.4226 8.71252C20.4226 8.56523 20.4189 8.41889 20.4117 8.27344C20.1829 12.8808 16.375 16.5462 11.7108 16.5462Z'
          fill='#CECECE'
        />
        <path
          d='M11.7107 15.0323C4.84767 15.0323 4.85231 8.42072 4.86149 8.2755C5.08795 4.68975 8.06788 1.85156 11.7107 1.85156C15.3536 1.85156 18.3335 4.68975 18.56 8.2755C18.5691 8.42072 18.56 15.0323 11.7107 15.0323Z'
          fill='#9E9E9E'
        />
        <path
          d='M11.7107 14.6974C8.06792 14.6974 5.0885 11.8591 4.86205 8.27344C4.85286 8.41866 4.84766 8.565 4.84766 8.71252C4.84766 12.5029 7.92036 15.5756 11.7107 15.5756C15.5011 15.5756 18.5738 12.5029 18.5738 8.71252C18.5738 8.56495 18.5686 8.41866 18.5594 8.27344C18.3329 11.8591 15.3535 14.6974 11.7107 14.6974Z'
          fill='#898989'
        />
        <path
          d='M13.6865 12.7861H9.75261C9.27613 12.7861 8.90821 11.9566 8.94407 11.767C9.04358 11.2406 9.27782 10.7446 9.62755 10.3312L12.3955 7.05991C12.6545 6.75381 12.5389 6.424 12.4973 6.33016C12.4467 6.21611 12.2894 5.94944 11.9231 5.93706C11.9125 5.93669 11.9015 5.9365 11.8904 5.9365C11.3758 5.9365 10.9572 6.35519 10.9572 6.86983C10.9572 7.34631 10.5709 7.73261 10.0944 7.73261C9.61789 7.73261 9.2316 7.34636 9.2316 6.86983C9.2316 5.40377 10.4243 4.21094 11.8904 4.21094C11.9208 4.21094 11.951 4.21145 11.981 4.21248C12.8983 4.24342 13.7005 4.78689 14.0747 5.63069C14.4545 6.48709 14.3158 7.46181 13.7127 8.1745L10.9448 11.4458C10.9347 11.4579 10.9247 11.47 10.9149 11.4824H13.6865C14.0024 11.4824 14.2787 11.6522 14.429 11.9056C14.5054 12.0343 14.1629 12.7861 13.6865 12.7861Z'
          fill='#E0E0E0'
        />
        <path
          d='M13.6865 12.3282H9.75267C9.38189 12.3282 9.06666 12.0939 8.94464 11.7656C8.90878 11.9552 8.88989 12.1486 8.88989 12.3436C8.88989 12.8202 9.27619 13.2064 9.75267 13.2064H13.6865C14.163 13.2064 14.5493 12.8202 14.5493 12.3436C14.5493 12.1831 14.5046 12.0333 14.4283 11.9046C14.2779 12.1579 14.0025 12.3282 13.6865 12.3282Z'
          fill='#CECECE'
        />
      </g>
      <defs>
        <clipPath id='clip0_3083_238444'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
