import MetaTagViewer from 'components/MetaTagViewer/MetaTagViewer';
import { Markup } from 'interweave';
import { useMemo, memo } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getInfoMetaPost } from 'store/actions/postMeta';
import { getMetaCommentOPG } from 'store/actions/usersNew';
import styled, { css } from 'styled-components';
import { checkLinkPostSale, patternHyperLink } from 'utils';

const Wrapper = styled.div`
  a {
    color: #0d6efd;
    text-decoration: underline;
  }
  .btn-read {
    color: #1480ff;
    cursor: pointer;
  }
  .content-break-space {
    white-space: break-spaces;
    font-size: 14px;
  }
`;

const MarkDownReadStyled = styled.div`
  word-break: break-word;
  ${({ maxHeight }) =>
    maxHeight
      ? css`
          max-height: ${maxHeight}px;
          overflow: auto;
        `
      : ''};
`;

const MarkDownRead = ({
  className,
  content = '',
  charLimit = 0,
  maxHeight,
  postId,
  commentId,
  metaPost,
  parentId,
  cbGetMeta,
  isMeta,
  isPageNoti
}) => {
  const { t } = useTranslation();

  const [contentShow, setContentShow] = useState('');
  const [contentShowLess, setContentShowLess] = useState('');
  const [largeContent, setLargeContent] = useState(false);
  const [isReadLess, setIsReadLess] = useState(false);
  const [metaData, setMetaData] = useState(null);
  const dispatch = useDispatch();

  const contentHyperLink = useMemo(() => {
    return content?.replace(patternHyperLink, (match) => {
      if (isPageNoti) {
        return match;
      } else {
        return `<a href="${match}" target="_blank">${match}</a>`;
      }
    });
  }, [content]);

  const id = useMemo(() => {
    return checkLinkPostSale(contentHyperLink);
  }, [contentHyperLink]);

  const findAnchorIndices = useCallback(
    (indexFind) => {
      const anchorStartTag = '<a';
      const anchorEndTag = '</a>';
      let findIndexSlice = indexFind;
      if (indexFind > 0) {
        let startIndex = contentHyperLink.indexOf(anchorStartTag);
        let endIndex = contentHyperLink.indexOf(anchorEndTag);

        while (startIndex !== -1 && endIndex !== -1) {
          if (startIndex <= indexFind && indexFind <= endIndex + anchorEndTag.length) {
            findIndexSlice = endIndex + anchorEndTag.length;
          }

          startIndex = contentHyperLink.indexOf(
            anchorStartTag,
            endIndex + anchorEndTag.length
          );
          endIndex = contentHyperLink.indexOf(anchorEndTag, endIndex + anchorEndTag.length);
        }
      }

      return findIndexSlice;
    },
    [contentHyperLink]
  );

  useEffect(() => {
    if (contentHyperLink) {
      if (charLimit !== 0) {
        const indexLimit = findAnchorIndices(charLimit);
        let limit = indexLimit > charLimit ? indexLimit : charLimit;

        let rowSlice = contentHyperLink?.slice(0, limit);
        if (limit < contentHyperLink?.length) {
          setLargeContent(true);
          setIsReadLess(true);
          setContentShowLess(rowSlice);
        } else {
          setLargeContent(false);
        }
      } else {
        setLargeContent(false);
      }
    }
  }, [contentHyperLink, charLimit, findAnchorIndices]);

  useEffect(() => {
    if (!isReadLess) {
      setContentShow(contentHyperLink);
    } else {
      setContentShow(contentShowLess);
    }
  }, [isReadLess, contentShowLess, contentHyperLink]);

  useEffect(() => {
    if (id) {
      if (isMeta) {
        dispatch(
          getInfoMetaPost(id, (dataMeta) => {
            setMetaData(dataMeta);
          })
        );
      } else if (!metaPost) {
        setMetaData(null);
        if (cbGetMeta) {
          dispatch(
            getInfoMetaPost(id, (dataMeta) => {
              cbGetMeta(postId, dataMeta);
            })
          );
        } else if (postId && commentId) {
          dispatch(getMetaCommentOPG(id, postId, commentId, parentId));
        }
      }
    } else {
      setMetaData(null);
    }
  }, [id, dispatch, postId, commentId, metaPost, parentId, cbGetMeta, isMeta]);

  return (
    <Wrapper className={className}>
      <MarkDownReadStyled maxHeight={maxHeight}>
        <Markup content={contentShow}  className='content-break-space' />

        {(metaPost?.title || metaData?.title) && (
          <MetaTagViewer
            title={metaPost?.title || metaData?.title}
            url={metaPost?.url || metaData?.url}
            thumbnail={metaPost?.thumbnail || metaData?.thumbnail}
            postId={postId}
          />
        )}
      </MarkDownReadStyled>
      {largeContent && (
        <>
          {isReadLess && (
            <span
              className='btn-read btn-read-less'
              onClick={(e) => {
                e.stopPropagation();
                setIsReadLess(false);
              }}>
              {t('ReadMoreLess.moreText')}
            </span>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default memo(MarkDownRead);
