import { ImgCustom, PopupFanDetail, ReadMoreLess } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { newPathUser } from 'constants/layout/constant';
import { Edit2Icon, Edit3Icon, FlagIcon, ThreePointIcon, TrashIcon } from 'images';
import Avatar from 'images/Avatar.png';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getCommentDetail } from 'store/actions/myPage';
import { convertToCurrentTime, isAdmin, isAgency, linkS3 } from 'utils/utils';
import useUserRole from '../../hooks/useUserRole';
import FormAddRepComment from './formAddRepComment';
import FormEditComment from './formEditComment';
import './index.scss';
import ListRepComment from './listRepComment';
const ListComment = ({
  handleDeleteComment,
  handleLikeComment,
  dataProfile,
  dataPost,
  handleFollowUserComment,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    commentDetail,
    loadingCommentDetail,
    statusPostRepComment,
    statusEditComment,
    paginationComment,
  } = useSelector((state) => state.myPage);
  const [showFormReplyComment, setFormReplyComment] = useState(-1);
  const [showFormEditComment, setFormEditComment] = useState(null);
  const [isVisiblePopup, setIsVisiblePopup] = useState(-1);
  const { id } = useParams();
  const [isVisible, setIsVisible] = useState(false);
  const [idFan, seIdFan] = useState(false);
  const { isIdol } = useUserRole();

  // esc func
  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setFormEditComment(null);
      setFormReplyComment(null);
    }
  }, []);
  // start func esc
  useEffect(() => {
    if (statusPostRepComment) {
      setFormReplyComment(null);
      const textareaId = document.getElementById('textarea-post-rep-comment');
      if (textareaId) textareaId.style.height = '38px';
    }

    if (statusEditComment) {
      setFormEditComment(null);
      const textareaId = document.getElementById('textarea-edit-comment');
      if (textareaId) textareaId.style.height = '38px';
    }

    document.addEventListener('keydown', escFunction);
    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, [escFunction, statusPostRepComment, statusEditComment]);

  const [page, setPage] = useState(1);
  const itemsPerPage = 10;
  const fetchNext = () => {
    const pages = paginationComment?.total / paginationComment?.per_page;
    pages > page && setPage(page + 1);
  };

  //get list reply comment
  useEffect(() => {
    dispatch(getCommentDetail(id, page, itemsPerPage));
  }, [page]);

  return (
    <>
      {isVisible && (
        <PopupFanDetail
          closeModal={() => setIsVisible(false)}
          isVisible={isVisible}
          id={idFan}
        />
      )}
      {dataPost && commentDetail.length > 0 && (
        <div className='list_comment block'>
          <div className='list-comment'>
            <InfiniteLoad
              loading={loadingCommentDetail}
              data={commentDetail}
              fetchNext={fetchNext}>
              {commentDetail.map((item_comment) => {
                return (
                  <div className='comment-item-wrapper' key={item_comment?.id}>
                    <div className='comment-item' key={item_comment?.id}>
                      <div
                        className='avatar'
                        onClick={() => {
                          if (+dataProfile?.user_type !== 3 && !isAdmin) {
                            setIsVisible(true);
                            seIdFan(item_comment?.user?.id);
                          }
                        }}>
                        <ImgCustom
                          src={
                            item_comment?.user?.avatar
                              ? `${item_comment?.user?.avatar}`
                              : Avatar
                          }
                          alt=''
                        />
                      </div>
                      <div className='content'>
                        {showFormEditComment?.id === item_comment?.id ? (
                          <>
                            <FormEditComment
                              item_comment={item_comment}
                              handleCancelEdit={() => {
                                setFormEditComment(null);
                                setFormReplyComment(null);
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <div className='info-comment'>
                              <div className='left'>
                                <div className='name'>
                                  {item_comment?.user?.account_name ||
                                    item_comment?.user?.full_name}
                                </div>
                                <div className='d-flex w-100 justify-content-between align-items-end'>
                                  <div className='comment-content display-linebreak'>
                                    <ReadMoreLess
                                      content={item_comment?.content?.toString()}
                                    />
                                  </div>
                                  {item_comment.is_edited === 1 && (
                                    <Edit2Icon className='icon-edited' />
                                  )}
                                </div>
                              </div>
                              <div className='content-right'>
                                {!!dataPost?.enable_comment &&
                                (dataPost?.user_id === dataProfile?.id ||
                                  item_comment?.user?.id === dataProfile?.id) ? (
                                  <div className='box-popup'>
                                    <ThreePointIcon
                                      className='three-point-icon'
                                      onClick={() => {
                                        setIsVisiblePopup(
                                          isVisiblePopup === item_comment?.id
                                            ? -1
                                            : item_comment?.id
                                        );
                                      }}
                                      onBlur={() => setIsVisiblePopup(-1)}
                                    />
                                    {isVisiblePopup === item_comment?.id && (
                                      <div className='popup-edit-comment'>
                                        {item_comment?.user?.id === dataProfile?.id && (
                                          <div
                                            className='item item1'
                                            onMouseDown={() => {
                                              setFormEditComment(item_comment);
                                              setIsVisiblePopup(-1);
                                            }}>
                                            <Edit3Icon />
                                            <span className='text mt-3'>
                                              {t('comment.listComment.iconEdit')}
                                            </span>
                                          </div>
                                        )}
                                        <div
                                          className='item'
                                          onMouseDown={() => {
                                            setIsVisiblePopup(-1);
                                            handleDeleteComment({
                                              id: item_comment?.id,
                                              type: 'comment',
                                            });
                                          }}>
                                          <TrashIcon />
                                          <span className='text mt-3'>
                                            {t('Common.buttonDelete')}
                                          </span>
                                        </div>
                                        {item_comment?.user?.id !== dataProfile?.id &&
                                          isIdol && (
                                            <div
                                              className='item'
                                              onMouseDown={() => {
                                                setIsVisiblePopup(-1);
                                                navigate(`${newPathUser}report`, {
                                                  state: {
                                                    fan_id: item_comment?.user?.id,
                                                    fan_name:
                                                      item_comment?.user?.account_name ||
                                                      item_comment?.user?.full_name,
                                                  },
                                                });
                                              }}>
                                              <FlagIcon />
                                              <span className='text'>
                                                {t('comment.listComment.iconFlag')}
                                              </span>
                                            </div>
                                          )}
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  <div className='padding-time' />
                                )}
                                <div className='time'>
                                  {convertToCurrentTime(item_comment?.updated_at)}
                                </div>
                              </div>
                            </div>
                            <div
                              className={`like-reply ${
                                item_comment.total_rep_comment > 0 && '0'
                              }`}
                              style={{
                                pointerEvents: isAdmin || isAgency ? 'none' : 'pointer',
                                color: isAdmin || isAgency ? '#B1B6BB' : '#787880',
                              }}>
                              <span
                                className={`like ${
                                  item_comment?.status_like_comment === 1 && 'active'
                                }`}
                                onClick={() => {
                                  if (!isAdmin) {
                                    handleLikeComment({
                                      id: item_comment?.id,
                                      type: 'comment',
                                    });
                                  }
                                }}>{`${t('comment.listComment.textLike')} ${
                                item_comment?.number_like
                              }`}</span>

                              {dataProfile?.id !== item_comment?.user_id &&
                                dataProfile?.user_type !== item_comment?.user?.user_type && (
                                  <span
                                    className={`follow ${
                                      item_comment?.status_follow === 1 && 'active'
                                    }`}
                                    onClick={() => {
                                      if (!isAdmin) {
                                        handleFollowUserComment({
                                          id: item_comment?.user_id,
                                          type: 'comment',
                                        });
                                      }
                                    }}>
                                    {item_comment?.status_follow === 1
                                      ? `${t('Common.unfollow')}`
                                      : `${t('Common.follow')}`}
                                  </span>
                                )}

                              {!!dataPost?.enable_comment && (
                                <span
                                  className='reply-btn'
                                  onClick={() => {
                                    !isAdmin && setFormReplyComment(item_comment?.id);
                                  }}>
                                  {t('comment.listComment.buttonReply')}
                                </span>
                              )}
                            </div>
                          </>
                        )}

                        {/* form reply comment */}
                        {showFormReplyComment === item_comment?.id && (
                          <FormAddRepComment
                            item_comment={item_comment}
                            handleOnblur={() => setFormReplyComment(null)}
                          />
                        )}

                        {/* list reply comment */}
                        <ListRepComment
                          item_comment={item_comment}
                          showFormReplyComment={showFormReplyComment}
                          handleDeleteComment={handleDeleteComment}
                          handleLikeComment={handleLikeComment}
                          dataPost={dataPost}
                          handleFollowUserComment={handleFollowUserComment}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </InfiniteLoad>
          </div>
        </div>
      )}
    </>
  );
};

export default ListComment;
