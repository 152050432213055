import { ImgCustom } from 'components';
import { UploadFileIcon } from 'images';
import UploadImageIcon from 'images/UploadImageIcon';
import UploadMultiImageIcon from 'images/UploadMultiImage';
import UploadVideoIcon from 'images/UploadVideoIcon';
import ZipFileIconV2 from 'images/ZipFileIconV2';
import VideoCustomDetail from 'pages/creator/layout/recording/VideoCustomDetail';
import { memo, useEffect, useMemo, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import { BTN_REMOVE } from 'utils/constant';
import { getInfo } from 'react-mediainfo';
import {
  TEN_GB,
  TEN_MB,
  acceptedImageTypes,
  acceptedVideoTypes,
  decryptPath,
  failFileThan10Gb,
  failImgInvalid,
  failImgThan10,
  failImgThan10Mb,
  failVideoInvalid,
  failVideoLonger3Minutes,
  failVideoThan1,
  failZipThan1,
  getDurationVideo,
  linkS3Video,
} from 'utils/utils';
import {
  ControlBar,
  CurrentTimeDisplay,
  Player,
  TimeDivider,
  VolumeMenuButton,
} from 'video-react';
import 'video-react/dist/video-react.css';
import '../normal.scss';
import { BlueImg } from '../styled';
import moment from 'moment';
import {
  Array10Item,
  LEN_LIST_IMG,
  compareTimeValid,
  defaultDuration,
  getSecondsFromHHMMSS,
  toHHMMSS,
} from '../help';
import { InputSampleImg } from './inputSampleImg';

const ListImgVideo = ({
  main_video,
  trailer_video,
  thumbnail,
  list_img,
  file_zip,
  imgRecording,
  thumbnailR,
  setDurationVideo,
  setDurationVideoTrailer,
  errorTimeDurationInput,
  setErrorTimeDurationInput,
  isErrorDurationInput,
}) => {
  const list10null = Array.from(Array(LEN_LIST_IMG), (_, i) => null);
  const [videoUpload, setVideoUpload] = useState({ img: [], files: null });
  const [trailerUpload, setTrailerUpload] = useState({ img: [], files: null });
  const [thumbnailUpload, setThumbnailUpload] = useState({ img: [], files: null });
  const [listImg, setListImg] = useState({ img: [], files: list10null });
  const [fileZip, setFileZip] = useState({ img: [], files: null });
  const { t } = useTranslation();

  const [valueTimeDurationInput, setValueTimeDurationInput] = useState(defaultDuration);

  const handleOnChangeDurationInput = (data) => {
    setValueTimeDurationInput(data.formattedValue);
  };
  const handleOnBlurDurationInput = (event) => {
    const value = event.target.value;
    const timeValid = moment(valueTimeDurationInput, 'mm:ss').isValid();
    if (timeValid) {
      const seconds = Math.max(0, getSecondsFromHHMMSS(value));
      const time = toHHMMSS(seconds);
      setValueTimeDurationInput(time);
      const compareTime = compareTimeValid(time);
      setErrorTimeDurationInput(compareTime);
    } else {
      setValueTimeDurationInput(defaultDuration);
      const compareTime = compareTimeValid(defaultDuration);
      setErrorTimeDurationInput(compareTime);
    }
  };

  const getDurationFromInput = useMemo(() => {
    const timeValid = moment(valueTimeDurationInput, 'mm:ss').isValid();
    if (timeValid) {
      const minutes = moment(valueTimeDurationInput, 'mm:ss').format('mm');
      const seconds = moment(valueTimeDurationInput, 'mm:ss').format('ss');
      return +minutes * 60 + +seconds;
    } else {
      return 1 * 60;
    }
  }, [valueTimeDurationInput]);

  useEffect(() => {
    if (!trailerUpload?.files) {
      setDurationVideoTrailer(getDurationFromInput);
    }
  }, [getDurationFromInput, setDurationVideoTrailer, trailerUpload?.files]);

  // START IMG VIDEO
  useEffect(() => {
    if (listImg.img.length > 0) {
      list_img(listImg.img);
    }
  }, [listImg.img, list_img]);

  useEffect(() => {
    if (thumbnailUpload.img.length > 0) {
      const file = URL.createObjectURL(thumbnailUpload.img[0]);
      setThumbnailUpload((prev) => ({ ...prev, files: file }));
      thumbnail(thumbnailUpload.img[0]);
    }
  }, [thumbnail, thumbnailUpload.img]);

  useEffect(() => {
    if (videoUpload.img.length > 0) {
      const file = URL.createObjectURL(videoUpload.img[0]);
      setVideoUpload((prev) => ({ ...prev, files: file }));
      main_video(videoUpload.img[0]);
    }
  }, [main_video, videoUpload.img]);

  useEffect(() => {
    if (trailerUpload.img.length > 0) {
      const file = URL.createObjectURL(trailerUpload.img[0]);
      setTrailerUpload((prev) => ({ ...prev, files: file }));
      trailer_video(trailerUpload.img[0]);
    }
  }, [trailerUpload.img, trailer_video]);

  useEffect(() => {
    if (fileZip.img.length > 0) {
      const file = URL.createObjectURL(fileZip.img[0]);
      setFileZip((prev) => ({ ...prev, files: file }));
      file_zip(fileZip.img[0]);
    }
  }, [fileZip.img, file_zip]);

  const handleInputVideoOrZip = async (e) => {
    let sizeImgBiggerFiveGB = false;
    sizeImgBiggerFiveGB = e.target.files[0].size > TEN_GB ? true : false;

    const type = e.target.files[0]['type'];
    const typeFileVideo = type.includes('video');
    // const typeFileZip = type.includes('zip');
    const checkTypes = typeFileVideo;

    const checkLength = videoUpload.img.length + e.target.files.length;
    if (e.target.files[0] && checkTypes && !sizeImgBiggerFiveGB && checkLength <= 1) {
      if (typeFileVideo) {
        if (e.target.files[0]['type'] === 'video/x-ms-wmv') {
          const mediaInfoLib = await getInfo(e.target.files[0]);
          const durationVideo = +mediaInfoLib?.media?.track[0]?.Duration;
          if (durationVideo < 3 * 60) {
            setVideoUpload({ img: [], files: null });
            setDurationVideo(0);
            failVideoLonger3Minutes();
            return true;
          } else {
            setVideoUpload((prev) => ({ ...prev, img: e.target.files }));
            setDurationVideo(durationVideo);
          }
        } else {
          getDurationVideo(e.target.files[0], (duration) => {
            if (duration < 3 * 60) {
              setVideoUpload({ img: [], files: null });
              setDurationVideo(0);
              failVideoLonger3Minutes();
              return true;
            } else {
              setVideoUpload((prev) => ({ ...prev, img: e.target.files }));
              setDurationVideo(duration);
            }
          });
        }
      } else {
        setFileZip((prev) => ({ ...prev, img: e.target.files }));
      }
    } else {
      if (!checkTypes) failVideoInvalid();
      if (checkLength > 1) typeFileVideo ? failVideoThan1() : failZipThan1();
      sizeImgBiggerFiveGB && failFileThan10Gb();
    }
  };

  const handleInputTrailer = (e) => {
    let sizeImgBiggerFiveGB = false;
    sizeImgBiggerFiveGB = e.target.files[0].size > TEN_GB ? true : false;
    const checkTypes = e.target.files[0]['type'].includes('video');
    const checkLength = trailerUpload.img.length + e.target.files.length;
    if (e.target.files[0] && checkTypes && !sizeImgBiggerFiveGB && checkLength <= 1) {
      getDurationVideo(e.target.files[0], (duration) => {
        setDurationVideoTrailer(duration);
      });
      setTrailerUpload((prev) => ({ ...prev, img: e.target.files }));
    } else {
      sizeImgBiggerFiveGB && failFileThan10Gb();
      !checkTypes && failVideoInvalid();
      checkLength > 1 && failVideoThan1();
    }
  };

  const handleInputThumbnail = (e) => {
    let sizeImgBiggerTenMb = false;
    sizeImgBiggerTenMb = e.target.files[0].size > TEN_MB ? true : false;
    const checkTypes = acceptedImageTypes.includes(e.target.files[0]['type']);
    if (e.target.files[0] && checkTypes && !sizeImgBiggerTenMb) {
      setThumbnailUpload((prev) => ({ ...prev, img: e.target.files }));
    } else {
      sizeImgBiggerTenMb && failImgThan10Mb(e.target.files[0].size);
      !checkTypes && failImgInvalid(e.target.files[0].name);
    }
  };
  const handleInputListImg = (e, indexFile) => {
    let sizeImgBiggerTenMb = false;
    let checkImg = true;
    // const checkLength = listImg.img.length + e.target.files.length;
    for (var i = 0; i < e.target.files.length; i++) {
      if (e.target.files[i].size > TEN_MB) sizeImgBiggerTenMb = true;
      if (!acceptedImageTypes.includes(e.target.files[i]['type'])) checkImg = false;
    }
    if (e.target.files[0] && checkImg && !sizeImgBiggerTenMb) {
      const newArrImg = [];
      [...e.target.files]?.forEach((ele) => {
        newArrImg.push(URL.createObjectURL(ele));
      });

      let listImgClone = JSON.parse(JSON.stringify(listImg));
      listImgClone.files[indexFile] = newArrImg[0];
      listImgClone.img = [...listImg.img, { data: e.target.files[0], indexFile }];
      setListImg(listImgClone);
    } else {
      sizeImgBiggerTenMb && failImgThan10Mb();
      // checkLength > 10 && failImgThan10();
      !checkImg && failImgInvalid();
    }
  };

  const handleRemoveImg = (i) => {
    const { img, files } = listImg;
    img.length === 0 && setListImg({ img: [], files: [] });
    setListImg((prev) => ({
      ...prev,
      img: img.filter((item) => item?.indexFile !== i),
      files: files.map((item, index) => {
        if (index === i) {
          return null;
        }
        return item;
      }),
    }));
  };
  // END IMG VIDEO

  return (
    <>
      <div className='box-img-video'>
        <div className='item-wrap'>
          <div className='item-label'>{t('Creator.PostArticle.labelThumbnail')}</div>
          <div className={`main-thumbnail `}>
            {thumbnailUpload.img.length > 0 ? (
              <BlueImg url={`${decryptPath(thumbnailUpload.files)}`}>
                <div className='bg-img bd-8'></div>
                <ImgCustom src={thumbnailUpload.files} className='cover-img' />
              </BlueImg>
            ) : (
              <div className='upload-file-btn'>
                <UploadImageIcon className='icon' />
                <span className='text'>{t('ValidateMsg.checkThumbnailUploadMultiLine')}</span>
              </div>
            )}

            <input
              type='file'
              name='main_thumbnail'
              accept={acceptedImageTypes.map((item) => item).join(',')}
              onChange={handleInputThumbnail}
              onClick={(e) => (e.target.value = null)}
            />
            {thumbnailUpload.files && (
              <img
                className='zindex20 btn-remove'
                onClick={() => {
                  setThumbnailUpload({ img: [], files: null });
                  thumbnail();
                }}
                src={BTN_REMOVE}
                alt='remove'
              />
            )}
          </div>
        </div>
        <div className='item-wrap'>
          <div className='item-label'>{t('Creator.PostArticle.labelSampleVideo')}</div>
          <div className='trailer-video box-item'>
            {trailerUpload.img.length > 0 ? (
              <Player poster='' className='video'>
                <source src={trailerUpload.files} />
                <ControlBar>
                  <CurrentTimeDisplay order={4.1} />
                  <TimeDivider order={4.2} />
                  <VolumeMenuButton />
                </ControlBar>
              </Player>
            ) : (
              <div className='upload-file-btn'>
                <UploadVideoIcon className='icon' />
                <span className='text'>
                  {t('ValidateMsg.checkTrailerVideoUploadMultiLine')}
                </span>
              </div>
            )}
            <input
              type='file'
              name='trailer_video'
              onChange={handleInputTrailer}
              accept={acceptedVideoTypes.map((item) => item).join(',')}
              onClick={(e) => (e.target.value = null)}
            />

            {trailerUpload.files && (
              <img
                className='zindex20 btn-remove'
                onClick={() => {
                  setTrailerUpload({ img: [], files: null });
                  setDurationVideoTrailer(getDurationFromInput);
                  trailer_video();
                }}
                src={BTN_REMOVE}
                alt='remove'
              />
            )}
          </div>
        </div>
        <div className='list-img-box box-item'>
          <div className='item-label'>{t('Creator.PostArticle.labelMainVideo')}</div>
          {imgRecording?.length > 0 ? (
            <div className={`main-video`}>
              <VideoCustomDetail
                src={`${linkS3Video}${decryptPath(imgRecording) + '#t=0.1'}`}
                isLiveArchive
                className='record-content'
                isDetailPost
                handleNavigate={() => {}}
              />
            </div>
          ) : (
            <div
              className={`main-video box-item ${fileZip.img.length > 0 && 'file-zip-height'}`}>
              {videoUpload.img.length > 0 ? (
                <Player poster='' className='video'>
                  <source src={videoUpload.files} />
                  <ControlBar>
                    <CurrentTimeDisplay order={4.1} />
                    <TimeDivider order={4.2} />
                    <VolumeMenuButton />
                  </ControlBar>
                </Player>
              ) : fileZip.img.length > 0 ? (
                <div className='file-zip'>
                  <div className='file-zip-content'>
                    <ZipFileIconV2 className='file-zip-anticon' />
                    {fileZip.img.length > 0 && (
                      <div className='text name-file-zip'>{fileZip.img[0]?.name}</div>
                    )}
                  </div>
                </div>
              ) : (
                <div className='upload-file-btn'>
                  <UploadFileIcon className='icon' />
                  <span className='text'>{t('ValidateMsg.checkVideoMainVideoPost')}</span>
                </div>
              )}
              <input
                type='file'
                name='main_video'
                onChange={handleInputVideoOrZip}
                accept={acceptedVideoTypes}
                onClick={(e) => (e.target.value = null)}
              />
              {(videoUpload.files || fileZip.files) && (
                <img
                  className='zindex20 btn-remove'
                  onClick={() => {
                    setVideoUpload({ img: [], files: null });
                    main_video();
                    setFileZip({ img: [], files: null });
                    file_zip();
                  }}
                  src={BTN_REMOVE}
                  alt='remove'
                />
              )}
            </div>
          )}
        </div>
        <div className='setting-time'>
          <div className='setting-time_head'>
            <span className='setting-time-title'>
              {t('Creator.PostArticle.titleSettingTime')}
            </span>
            <span className='setting-time-input-time'>
              <CurrencyFormat
                className={`time-split-video ${isErrorDurationInput ? 'error' : ''}`}
                format='##:##'
                placeholder='mm:ss'
                value={valueTimeDurationInput}
                onValueChange={handleOnChangeDurationInput}
                onBlur={handleOnBlurDurationInput}
                disabled={!!trailerUpload?.files}
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault();
                }}
              />
            </span>
          </div>
          <div className='setting-time_info'>
            <div className='setting-time_info_item focus-red'>
              {t('Creator.PostArticle.infoSetting.info1')}
            </div>
            <div className='setting-time_info_item'>
              {t('Creator.PostArticle.infoSetting.info2')}
            </div>
            <div className='setting-time_info_item'>
              {t('Creator.PostArticle.infoSetting.info3')}
            </div>
            {isErrorDurationInput && (
              <div className='setting-time_info_item focus-red'>
                {t(errorTimeDurationInput)}
              </div>
            )}
          </div>
          <div className='setting-time_errors'></div>
        </div>
        <div className='list-img-sample'>
          <div className='item-label'>{t('Creator.PostArticle.labelSampleImg')}</div>
          <div className='list-img'>
            {Array10Item.map((indexFile) => {
              if (listImg?.files[indexFile]) {
                return (
                  <div className='img-des box-item' key={indexFile}>
                    <BlueImg url={`${decryptPath(listImg?.files[indexFile])}`}>
                      <div className='bg-img'></div>
                      <ImgCustom src={listImg?.files[indexFile]} className='cover-img' />
                      <img
                        className='zindex20 btn-remove'
                        onClick={() => handleRemoveImg(indexFile)}
                        src={BTN_REMOVE}
                        alt='remove'
                      />
                    </BlueImg>
                  </div>
                );
              }
              return (
                <InputSampleImg
                  key={indexFile}
                  indexFile={indexFile}
                  handleInputListImg={handleInputListImg}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(ListImgVideo);
