import { CircleCheckIcon } from 'images';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PopupWithdrawSuccess = ({ closeModal, ...props }) => {
  const navigate = useNavigate();
  useLockBodyScroll();
  const handleShowPopup = (e) => {
    closeModal(false);
    navigate(0);
  };
  const { t } = useTranslation();
  return (
    <div className='popup popup-withdraw-success'>
      <div className='popup-content'>
        <div className='content mb-5'>
          <CircleCheckIcon className='me-3 text-success font-24' />
          <b>{t('Sales.requested')}</b>
        </div>
        <div className='text-end'>
          <button className='btn ok' onClick={handleShowPopup}>
            {t('Common.Ok')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupWithdrawSuccess;
