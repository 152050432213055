import Icon from '@ant-design/icons';

const OutLiveIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={44}
        height={44}
        viewBox='0 0 44 44'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx={22} cy={22} r='21.5' fill='#DC143C' stroke='#EEEEEE' />
        <path
          d='M21.999 14.5V21.625'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M26.5002 15.086C27.9907 16.0561 29.1279 17.4819 29.7422 19.1508C30.3565 20.8198 30.415 22.6426 29.9091 24.3476C29.4031 26.0525 28.3598 27.5484 26.9345 28.6121C25.5093 29.6758 23.7784 30.2505 22 30.2505C20.2215 30.2505 18.4907 29.6758 17.0655 28.6121C15.6402 27.5483 14.5969 26.0525 14.0909 24.3475C13.5849 22.6426 13.6435 20.8198 14.2578 19.1508C14.8721 17.4818 16.0093 16.056 17.4999 15.0859'
          stroke='white'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default OutLiveIcon;
