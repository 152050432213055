import React, { useLayoutEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { newPathUser } from 'constants/layout/constant';
import './index.scss';
import { PopupConfirmFan } from 'components';
import { useHorizontalScroll } from 'hooks/useHorizontalScroll';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getIDKYC } from 'store/actions/usersNew';

const NavbarUserProfile = ({ defaultActive, resetPage, className }) => {
  const { dataUser } = useSelector((state) => state.users);
  const { t } = useTranslation();
  const [tabActive, setTabActive] = useState(0);
  const navigate = useNavigate();
  const [isVisiblePopup, setIsVisiblePopup] = useState(false);
  const refNabar = useRef(null);
  const scrollRef = useHorizontalScroll();
  const dispatch = useDispatch();
  useLayoutEffect(() => {
    defaultActive && setTabActive(defaultActive);
  }, [defaultActive]);

  const handleActiveTab = (tab) => {
    dispatch(getIDKYC({ id: null, page: 1 }));
    navigate(
      tab === 1
        ? `${newPathUser}profile-settings`
        : tab === 2
        ? `${newPathUser}profile/info`
        : `${newPathUser}profile/registration-of-performer`
    );
  };
  return (
    <>
      {isVisiblePopup && (
        <PopupConfirmFan
          isVisiblePopup={() => setIsVisiblePopup(false)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={'変更を保存せずにページを離れますか?'}>
          <div className='ok btn' onClick={() => handleActiveTab(refNabar.current)}>
            {t('Common.buttonYes')}
          </div>
          <div className='btn cancel' onClick={() => setIsVisiblePopup(false)}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}
      <div className={className} ref={scrollRef}>
        <div
          className={`tab-item ${tabActive === 1 ? 'active' : ''}`}
          onClick={() => {
            defaultActive !== 1 && handleActiveTab(1);
          }}>
          {t('User.tabProfile')}
        </div>
        <div
          className={`tab-item ${tabActive === 2 ? 'active' : ''}`}
          onClick={() => {
            defaultActive !== 2 && handleActiveTab(2);
          }}>
          {t('User.tabProfileInfo')}
        </div>
        {dataUser?.status === 1 && (
          <div
            className={`tab-item ${tabActive === 3 ? 'active' : ''}`}
            onClick={() => {
              defaultActive !== 3 && handleActiveTab(3);
            }}>
            {t('User.tabContract')}
          </div>
        )}
      </div>
    </>
  );
};

export default NavbarUserProfile;
