/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';

const initialState = {
  status: null,
  loading: false,
  error: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CHECK_ADMIN_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.CHECK_ADMIN_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case TYPES.CHECK_ADMIN_TOKEN_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};
