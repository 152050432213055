import { ClearInputIcon, TrashIcon } from 'images';
import CoinPackageBig from 'images/CoinPackageBig.svg';
import CoinPackageSmall from 'images/CoinPackageSmall.svg';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { StyledStep1TigerPay } from './styled';

const Step1Tiger = ({
  dataVendo,
  setStep,
  searchVoucher,
  setSearchVoucher,
  handleApplyVoucher,
  dataVoucher,
  setDataVoucher,
  priceVoucher,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <StyledStep1TigerPay>
      <div className='--item fixed-point-item'>
        <div className='--point'>
          <img
            src={dataVendo?.point < 100000 ? CoinPackageSmall : CoinPackageBig}
            alt='coin package'
            className='coin-package-img'
          />
          <CurrencyFormat
            className='point-number'
            thousandSeparator={true}
            value={dataVendo?.point}
            displayType='text'
          />
          <div className='point-text'>{t('Purchase.point')}</div>
        </div>

        <div className={`${Number(dataVendo?.price) > 1000000 ? 'long-price' : ''} --btn`}>
          ￥
          <CurrencyFormat
            thousandSeparator={true}
            value={Number(dataVendo?.price)}
            displayType='text'
          />
        </div>
      </div>
      <div className='block-info mt-24'>
        <div className='block-info-item'>
          <div className='block-info-item-first'>{t('Purchase.payment.vendo.total')}:</div>
          <CurrencyFormat
            className='block-info-item-last'
            thousandSeparator={true}
            value={dataVendo?.price}
            displayType='text'
            prefix='￥'
          />
        </div>
      </div>

      {/* <div className='hyphen-payment'></div> */}
      <div className='block-info'>
        {/* <div className='block-info-item'>
          <div className='block-info-item-first'>{t('Common.voucher')}:</div>
          <div className='block-info-item-last d-row'>
            <div className='search-voucher'>
              <input
                style={{
                  color: `${dataVoucher ? '#bebebe' : '#1A1A1A'}`,
                  background: `${dataVoucher ? '#EFEFEF' : '#FFF'}`,
                  fontWeight: '400',
                }}
                disabled={dataVoucher}
                className={`search-voucher-input ${searchVoucher?.trim() ? 'haveData' : ''}`}
                placeholder={t('Purchase.payment.vendo.enterVoucherCode')}
                value={searchVoucher}
                onChange={(e) => {
                  setSearchVoucher(e.target.value);
                }}
              />
              {!!searchVoucher?.trim() && (
                <ClearInputIcon
                  className='clear-icon-voucher'
                  onClick={() => {
                    setSearchVoucher('');
                  }}
                />
              )}
            </div>
            <button
              disabled={dataVoucher}
              onClick={handleApplyVoucher}
              className={`btn-apply-voucher ${dataVoucher ? 'disable' : ''}`}>
              {t('Common.apply')}
            </button>
          </div>
        </div> */}
        {/* 20/03 */}
        {dataVoucher && (
          <div className='block-info-item mt-4'>
            <div className='block-info-item-first'>{t('Common.lableVoucher')}:</div>
            <div className='block-info-item-last d-row'>
              <CurrencyFormat
                className='price-voucher'
                thousandSeparator={true}
                value={priceVoucher}
                displayType='text'
              />
              <div
                style={{
                  marginLeft: '-15px',
                  color: '#52C41A',
                  fontWeight: 'bold',
                  fontSize: '14px',
                }}>
                Pt
              </div>
              <TrashIcon
                className='delete-voucher-icon'
                color='#666565'
                onClick={() => {
                  setDataVoucher(null);
                }}
              />
            </div>
          </div>
        )}
      </div>

      <div className='hyphen-payment'></div>
      <div className='block-info'>
        <div className='block-info-item'>
          <div className='block-info-item-first'>
            {t('Purchase.payment.vendo.paymentInformation')}:
          </div>
          <div className='block-info-item-last'>
            Tiger Pay{' '}
            <span
              className='btn-change'
              onClick={() => {
                navigate(-1);
              }}>
              {t('Purchase.payment.vendo.change')}
            </span>
          </div>
        </div>
      </div>
      <div className='hyphen-payment mb-24'></div>
      <div className='block-item sub-col'>
        <button
          className='btn btn-submit'
          onClick={() => {
            setStep(2);
            // handleSubmitPayment();
          }}>
          {t('Common.confirmPay')}
        </button>
        <button
          className='btn btn-cancel'
          onClick={() => {
            navigate(-1);
          }}>
          {t('Common.buttonCancel')}
        </button>
      </div>
    </StyledStep1TigerPay>
  );
};

export default Step1Tiger;
