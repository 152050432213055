import Icon from '@ant-design/icons';

const VideoIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='18'
        height='14'
        fill='none'
        viewBox='0 0 18 14'
        {...props}>
        <path
          fillRule='evenodd'
          stroke={`currentColor`}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M12.58 9.948c.068 1.527-1.164 2.818-2.751 2.883-.117.005-5.816-.007-5.816-.007-1.58.12-2.962-1.015-3.087-2.536C.916 10.175.92 4.06.92 4.06.85 2.53 2.08 1.237 3.668 1.17c.119-.006 5.81.004 5.81.004 1.587-.118 2.974 1.025 3.097 2.554.008.11.006 6.22.006 6.22z'
          clipRule='evenodd'></path>
        <path
          stroke={`currentColor`}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M12.583 5.315l2.745-2.246a1.04 1.04 0 011.699.806L17.017 10c-.001.878-1.022 1.359-1.7.802l-2.734-2.246'></path>
      </svg>
    )}
    {...props}
  />
);

export default VideoIcon;
