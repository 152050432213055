import { LayoutCreatorRight, LayoutFan, PopupConfirmFan } from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import { newPathUser } from 'constants/layout/constant';
import { ArrowLeftIcon } from 'images';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { getReportCategories, postUserReport, resetStatus } from 'store/actions/feedback';
import {
  CustomDropdownIndicator,
  CustomOption,
  checkLinkPostSale,
  customStylesReselect,
  isEmptyObject,
  patternNoFirstSpacing,
  queryLanguage,
} from 'utils/utils';
import './index.scss';
import { getInfoMetaPost } from 'store/actions/postMeta';
import MetaTagViewer from 'components/MetaTagViewer/MetaTagViewer';
import { resetDataInfoOther, resetIDHomePage } from 'store/actions/usersNew';

const PostReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, listCategoryReport } = useSelector((state) => state.feedback);
  const location = useLocation();
  const { fan_id, account_id, post_id, fan_name } = location?.state || {};
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({ mode: 'all' });
  const watchCategory = watch('report_categorie_id', '');
  const watchContent = watch('content', '');
  const { t, i18n } = useTranslation();
  const isDisabledButton =
    !watchCategory ||
    !watchContent ||
    (!fan_id && !account_id && !post_id) ||
    !isEmptyObject(errors) ||
    !listCategoryReport.length > 0;
  const [focusCategory, setFocusCategory] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [idPostMeta, setIdPostMeta] = useState(null);
  const [postDetailMeta, setPostDetailMeta] = useState(null);

  useEffect(() => {
    const isValidContent = watchContent?.trim() !== '';
    if (isValidContent) {
      const idPost = checkLinkPostSale(watchContent);
      if (idPost) {
        setIdPostMeta(idPost);
      } else {
        setIdPostMeta(null);
        setPostDetailMeta(null);
      }
    } else {
      setPostDetailMeta(null);
      setIdPostMeta(null);
    }
  }, [watchContent]);

  useEffect(() => {
    if (idPostMeta) {
      dispatch(
        getInfoMetaPost(idPostMeta, (dataMeta) => {
          setPostDetailMeta(dataMeta);
        })
      );
    } else {
      setPostDetailMeta(null);
    }
  }, [idPostMeta, dispatch]);

  const optionListContent = useMemo(() => {
    return (
      listCategoryReport?.map((item) => ({
        ...item,
        value: item?.id,
        label: item?.name,
      })) || []
    );
  }, [listCategoryReport]);

  useEffect(() => {
    !fan_id && !account_id && !post_id && navigate('/*');
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetStatus());
    }
  }, []);

  useEffect(() => {
    dispatch(
      getReportCategories({
        active_flag: 1,
        language: i18n.language === 'jp' ? 'ja' : i18n.language,
      })
    );
  }, [i18n.language]);

  useEffect(() => {
    if (watchCategory) {
      setValue('report_categorie_id', watchCategory, {
        shouldTouch: true,
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [i18n.language]);
  const handleSendReportSuccess = ({ success }) => {
    success && setIsOpenModal(true);
  };

  const onSubmit = (data) => {
    if (!loading) {
      const newData = {
        ...data,
        type: fan_id ? 2 : 1,
        report_categorie_id: data.report_categorie_id?.id,
        report_id: fan_id || post_id,
      };
      dispatch(postUserReport(newData, handleSendReportSuccess));
    }
  };

  return (
    <>
      {isOpenModal && (
        <PopupConfirmFan text={t('Popup.reportSendSuccess')}>
          <div
            className='ok btn'
            onClick={() => {
              setIsOpenModal(false);
              dispatch(resetIDHomePage());
              dispatch(resetDataInfoOther());
              navigate(`${newPathUser}`);
            }}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}
      <LayoutCreatorRight
        className='post-report-page-header'
        titlePage={t('PostReport.title')}
        loading={loading}>
        <div className='post-report-page'>
          <form onSubmit={(e) => e.preventDefault()} className='fansite-form'>
            <div className='title'>{t('PostReport.pleaseDetailProblem')}</div>
            <InputWrapper id='fsite-id' label={t('PostReport.reportTo')}>
              <input disabled value={account_id || post_id} />
            </InputWrapper>

            <InputWrapper
              id='fsite-category'
              label={t('PostReport.category')}
              required={true}
              error={errors?.category ? errors?.category?.message : ''}>
              <div className='report-category'>
                <Controller
                  name='report_categorie_id'
                  control={control}
                  rules={{ required: t('ValidateMsg.categoryRequired') }}
                  render={({ field }) => (
                    <>
                      <Select
                        {...field}
                        value={optionListContent?.find((e) => e.value === field.value?.id)}
                        options={optionListContent}
                        onFocus={() => setFocusCategory(true)}
                        onBlur={() => setFocusCategory(false)}
                        onClick={(e) => setFocusCategory(false)}
                        onChange={(val) => {
                          field.onChange(val);
                          setFocusCategory(false);
                        }}
                        className='select-report'
                        placeholder={t('PostReport.category')}
                        styles={{
                          ...customStylesReselect,
                          menu: (base, state) => ({
                            ...base,
                            border: 'none !important',
                            borderRadius: '8px',
                            overflow: 'hidden',
                            zIndex: 9999,
                            width: 'auto',
                            boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.06)',
                          }),
                        }}
                        isSearchable={false}
                        components={{
                          Option: CustomOption,
                          DropdownIndicator: CustomDropdownIndicator,
                        }}
                      />
                    </>
                  )}
                />
              </div>
            </InputWrapper>
            <InputWrapper
              label={t('PostReport.problemDetail')}
              error={errors?.content ? errors?.content?.message : ''}>
              <div
                className={`wrapper-content-ogp ${postDetailMeta?.title ? 'have-meta' : ''}`}>
                <textarea
                  {...register('content', {
                    required: ('PostReport.pleaseDetailProblem'),
                    validate: {
                      noFirstSpacing: (value) =>
                        (value && patternNoFirstSpacing?.test(value.toString().trim())) ||
                        ('PostReport.pleaseDetailProblem'),
                    },
                    maxLength: {
                      value: 2000,
                      message: ('ValidateMsg.CONTENT_WITHIN_2000_CHARACTERS'),
                    },
                  })}
                  placeholder={t('PostReport.placeholderInputRear')}
                  className='content-ogp'
                />
                {postDetailMeta?.title && (
                  <MetaTagViewer
                    title={postDetailMeta?.title}
                    url={postDetailMeta?.url}
                    thumbnail={postDetailMeta?.thumbnail}
                  />
                )}
              </div>
            </InputWrapper>
            <div className='footer'>
              <InputWrapper
                id='fsite-submit'
                className={`btn button-submit ${
                  isDisabledButton || loading ? 'disabled' : ''
                }`}>
                <button onClick={handleSubmit(onSubmit)} disabled={isDisabledButton}>
                  {t('PostReport.buttonReport')}
                </button>
              </InputWrapper>
              <InputWrapper id='fsite-submit' className={`btn border button-cancel`}>
                <button onClick={() => navigate(-1)}>{t('PostReport.buttonCancel')}</button>
              </InputWrapper>
            </div>
          </form>
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default PostReport;
