import Icon from '@ant-design/icons';

const ICMoreIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width='21'
        height='20'
        viewBox='0 0 21 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g id='Iconly Normal'>
          <g id='More Square'>
            <path
              id='Stroke 11'
              d='M16.2986 13.3346L16.3123 13.3346'
              stroke='#A1A3A8'
              strokeWidth='4'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              id='Stroke 13'
              d='M10.2299 13.3346L10.2435 13.3346'
              stroke='#A1A3A8'
              strokeWidth='4'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              id='Stroke 15'
              d='M4.1624 13.3346H4.17602'
              stroke='#A1A3A8'
              strokeWidth='4'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              id='Stroke 16'
              d='M16.2986 6.66665L16.3123 6.66665'
              stroke='#A1A3A8'
              strokeWidth='4'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              id='Stroke 17'
              d='M10.2299 6.66665L10.2435 6.66665'
              stroke='#A1A3A8'
              strokeWidth='4'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              id='Stroke 18'
              d='M4.1624 6.66665L4.17603 6.66665'
              stroke='#A1A3A8'
              strokeWidth='4'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </g>
        </g>
      </svg>
    )}
    {...props}
  />
);

export default ICMoreIcon;
