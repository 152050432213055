import styled from 'styled-components';
export const StyledLayoutCreatorRightCustom = {
  Layout: styled.div`
    .content-container {
      min-height: unset;
    }
  `,
  Wrapper: styled.div`
    display: flex;
  `,
  Main: styled.div`
    flex: 1 1;
    position: relative;
    height: calc(100%);
    width: calc(100% - 272px);
    @media screen and(max-width:991px) {
      width: 100%;
    }
    .back-header-custom {
      position: sticky;
      z-index: 20;
      background-color: #fff;
      /* top: 0.5px; */
      top: 0px;
      height: 64.5px;
    }
    //c030
    .search-page.creator {
      position: relative;
      .tab-container,
      .form-search,
      .body .list-btn {
        position: sticky;
        z-index: 10;
        background-color: #fff;
      }
      .tab-container {
        top: 64px;
      }
      .body {
        position: relative;
        .form-search {
          top: 112px;
        }
        .tab-container {
          top: 180px;
        }
        &.tab-2 {
          .list-btn {
            top: 112px;
          }
          .form-search {
            top: 186px;
          }
        }
      }
    }
    //c157
    .navbar-type-post.c157_timeline {
      position: sticky;
      top: 64px;
      z-index: 20;
    }
  `,
  Sidebar: styled.div`
    width: 272px;
    @media screen and (max-width: 991px) {
      display: none;
    }
  `,
};
