import {
  LayoutCreatorRight,
  PopupConfirmFan,
  PopupGeneral,
} from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { newPathUser } from 'constants/layout/constant';
import { convertPoint2Price } from 'constants/shared';
import moment from 'moment';
import { useEffect, useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getPaymentPackages,
  getSettingSystem,
  getSettingSystemType1TimePurchase,
  updateStatusPaymentVendo,
} from 'store/actions/payment';
import { convertToCurrentTime, formatCoin } from '../../utils/utils';
import './styles.scss';
import CoinPackageBig from 'images/CoinPackageBig.svg';
import CoinPackageSmall from 'images/CoinPackageSmall.svg';
import { getUserPoints } from 'store/actions/users';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { CoinBadge } from 'images';
import { resetDataInfoOther, resetIDHomePage } from 'store/actions/usersNew';
import FailIcon from 'images/FailIcon';

const ListPurchaseScreen = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [inputPoint, setInputPoint] = useState();
  const [checkOnchangePoint, setCheckOnchangePoint] = useState(false);
  const [inputPrice, setInputPrice] = useState(0);
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    data: listPackages,
    setting_system,
    paymentVendoFail,
  } = useSelector((state) => state.payment);
  const { userPoints, loading: loadingPoints, dataUser } = useSelector((state) => state.users);

  const { min_point, max_point, point_to_jp, point_unit, tax } = setting_system;
  const loading = useSelector((state) => state.payment?.loading);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { t } = useTranslation();
  const handleInputPoint = (value) => {
    const newValue = +value >= 0 ? +value : 0;
    setInputPoint(newValue);
    newValue > 0
      ? setInputPrice(convertPoint2Price(+newValue, +tax / 100, point_to_jp))
      : setInputPrice(0);
    setCheckOnchangePoint(true);
  };

  useEffect(() => {
    const top = document.getElementById('app_cocofans_0');
    top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
  }, []);

  useEffect(() => {
    if (location.state?.point) {
      const newValue = +location.state?.point >= 0 ? +location.state?.point : 0;
      setInputPoint(newValue);
      newValue > 0
        ? setInputPrice(convertPoint2Price(+newValue, +tax / 100, point_to_jp))
        : setInputPrice(0);
    }
  }, [location.state, navigate, point_to_jp, tax]);

  const handleGetSettingType1Success = ({ data }) => {
    const today = moment().toDate();
    const startDatePayment = moment(convertToCurrentTime(data?.date_from)).toDate();
    const endDatePayment = moment(convertToCurrentTime(data?.date_to)).toDate();
    (data.value === '1' || data.value === 1) &&
      today >= startDatePayment &&
      today <= endDatePayment &&
      setIsOpenModal(true);
  };

  useEffect(() => {
    dispatch(getPaymentPackages({ limit: 999999, active_flag: 1 }));
    dispatch(getSettingSystem(5));
    dispatch(getSettingSystemType1TimePurchase(1, handleGetSettingType1Success));
    dispatch(getUserPoints());

    return () => {
      dispatch(updateStatusPaymentVendo());
    };
  }, []);

  useEffect(() => {
    if (!setting_system) {
      dispatch(getSettingSystem(5));
    }
  }, [location]);

  const currentPoints = dataUser?.points ?? 0;

  const getValuePoints = () => {
    if (dataUser?.points > 1) {
      return <>{`${formatCoin(currentPoints)} ${t('Purchase.points')}`}</>;
    } else {
      return <>{`${formatCoin(currentPoints)} ${t('Purchase.point')}`}</>;
    }
  };

  const getValueCurrency = (value) => {
    if (value > 1) {
      return <>{`${t('Purchase.points')}`}</>;
    } else {
      return <>{`${t('Purchase.point')}`}</>;
    }
  };

  const validatePointInput =
    inputPoint < min_point ||
    inputPoint > max_point ||
    !inputPrice ||
    !inputPoint > 0 ||
    inputPoint % point_unit > 0;

  return (
    <>
      {/* {(loading || loadingPoints) && <PopupLoading className={'popup-loading'} />} */}
      {isOpenModal && (
        <PopupConfirmFan text={t('Popup.noPurchasedThisTime')} colorSvg='#FF9F43' iconNote>
          <div
            className='ok btn'
            onClick={() => {
              setIsOpenModal(false);
              dispatch(resetIDHomePage());
              dispatch(resetDataInfoOther());
              navigate(`${newPathUser}`);
            }}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}
      {paymentVendoFail && !loading && !loadingPoints && (
        <PopupGeneral
          title={t('Purchase.payment.title')}
          className='popup-success-payment-fail-purchase'>
          <div className='success-icon'>
            <FailIcon />
          </div>
          <div className='text-content'>
            <div className='title-fail'>{t('Common.fail')}</div>
            {/* <div className='content'>
                  {messageErrorVendo == 'PAYMENT_PACKAGE_NOT_ACTIVE' ||
                  messageErrorVendo == 'PAYMENT_PACKAGE_NOT_ACTIVE'
                    ? t(`ValidateMsg.${messageErrorVendo}`)
                    : t(`${messageErrorVendo}`)}
                </div> */}
            <div className='content'>{t('Purchase.payment.vendo.paymentFail')}</div>
          </div>

          <div className='button-wrapper'>
            <div
              className='btn btn-ok'
              onClick={() => {
                dispatch(updateStatusPaymentVendo());
              }}>
              {t('Common.Ok')}
            </div>
            <div
              className='btn btn-cancel'
              onClick={() => {
                dispatch(updateStatusPaymentVendo());
                return navigate(`${newPathUser}timeline`);
              }}>
              {t('Purchase.payment.vendo.returnHome')}
            </div>
          </div>
        </PopupGeneral>
      )}
      <LayoutCreatorRight
        titlePage={t('Common.pointPurchase')}
        className='purchase-page-header'
        cb={() => {
          if (location.state?.pathname) {
            return navigate(location.state?.pathname);
          }
          return navigate(-1, {
            state: { ...location.state },
          });
        }}>
        <div className={`purchase-page`}>
          {loading || loadingPoints ? (
            <div className='shimmer-loading'>
              <ShimmerPostDetails />
            </div>
          ) : (
            <div className='--body'>
              <div className='--list'>
                <div className='top-content'>
                  <div className='current-point'>
                    <CoinBadge className='coin-badge' />
                    <div className='current-coin-title'>{t('Purchase.currentPoint')}</div>
                    <div className='current-coin-number'>{getValuePoints()}</div>
                  </div>
                  <div className=' custom input-point-container'>
                    <div className='input-point-title'>{t('Common.pointPurchase')}</div>
                    <div className='input-point' style={{ height: 44 }}>
                      <CurrencyFormat
                        value={inputPoint}
                        className='currency-format'
                        placeholder={t('ValidateMsg.numberPointRequired')}
                        thousandSeparator={true}
                        maxLength={11}
                        decimalSeparator={false}
                        decimalScale={0}
                        onValueChange={(values) => {
                          const { floatValue } = values;
                          // floatValue ? handleInputPoint(+floatValue) : setInputPrice(0);
                          if (floatValue) {
                            handleInputPoint(+floatValue);
                          } else {
                            setInputPrice(0);
                            setInputPoint();
                          }
                        }}
                      />
                      {!inputPoint && checkOnchangePoint && (
                        <p className='text-danger font-sm'>
                          {t('ValidateMsg.numberPointRequired')}
                        </p>
                      )}
                      {inputPoint > min_point &&
                        inputPoint < max_point &&
                        inputPoint &&
                        inputPoint % point_unit > 0 && (
                          <p className='text-danger font-sm'>
                            {t('ValidateMsg.pointUnitsPurchase', {
                              point: formatCoin(point_unit),
                            })}
                          </p>
                        )}
                      {inputPoint < min_point && inputPoint && (
                        <p className='text-danger font-sm'>
                          {t('ValidateMsg.minPointPurchase', { point: formatCoin(min_point) })}
                        </p>
                      )}
                      {inputPoint > max_point && (
                        <p className='text-danger font-sm'>
                          {t('ValidateMsg.maxPointPurchase', { point: formatCoin(max_point) })}
                        </p>
                      )}
                    </div>
                    <div className='--price'>
                      ￥
                      {inputPrice > 0 && inputPoint > 0 && (
                        <CurrencyFormat
                          thousandSeparator={true}
                          value={inputPrice}
                          className='currency-format'
                          displayType='text'
                        />
                      )}
                    </div>
                    <div
                      className={`--btn ${validatePointInput && '--disabled'}`}
                      onClick={() => {
                        if (!validatePointInput) {
                          navigate(`${newPathUser}payment`, {
                            state: { ...location.state, point: inputPoint, price: inputPrice },
                          });
                        }
                      }}>
                      {t('Common.pointPurchase')}
                    </div>
                  </div>
                </div>
                <div className='title'>{t('Purchase.listPointPurchase')}</div>
                <InfiniteLoad
                  scrollableTargetCustom={'posts-subscribe'}
                  loading={loading}
                  data={listPackages || []}
                  fetchNext={() => setPage(page + 1)}>
                  <div className='list-buyer-creator'>
                    {listPackages?.length > 0 &&
                      listPackages.map((item, index) => (
                        <div key={index} className='--item fixed-point-item'>
                          <div className='--point'>
                            <img
                              src={item?.value < 100000 ? CoinPackageSmall : CoinPackageBig}
                              alt='coin package'
                              className='coin-package-img'
                            />
                            <CurrencyFormat
                              className='point-number'
                              thousandSeparator={true}
                              value={item?.value}
                              displayType='text'
                            />
                            <div className='point-text'>{getValueCurrency(item?.value)}</div>
                          </div>

                          <div
                            className={`${item?.price > 1000000 ? 'long-price' : ''} --btn`}
                            onClick={() =>
                              navigate(`${newPathUser}payment`, {
                                state: {
                                  ...location.state,
                                  package_payment_id: item?.id,
                                  price: item?.price,
                                  point: item?.value,
                                },
                              })
                            }>
                            ￥
                            <CurrencyFormat
                              thousandSeparator={true}
                              value={item?.price}
                              displayType='text'
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </InfiniteLoad>
              </div>
            </div>
          )}
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default ListPurchaseScreen;
