import Icon from '@ant-design/icons';

const TwitterIcon = (props) => (
  <Icon
    component={() => (
      <svg width={44} height={44} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx={22} cy={22} r="21.5" fill="white" stroke="#E5E6EB" />
        <path d="M27.7226 12.7188H30.7549L24.1302 20.5347L31.9237 31.1704H25.8214L21.0419 24.7199L15.5731 31.1704H12.5389L19.6248 22.8104L12.1484 12.7188H18.4056L22.7259 18.6147L27.7226 12.7188ZM26.6584 29.2968H28.3386L17.4926 14.4939H15.6895L26.6584 29.2968Z" fill="#0F1419" />
      </svg>
    )}
    {...props}
  />
);

export default TwitterIcon;
