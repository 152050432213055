export default function ClockIcon(props) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M17.5 10C17.5 14.14 14.14 17.5 10 17.5C5.86 17.5 2.5 14.14 2.5 10C2.5 5.86 5.86 2.5 10 2.5C14.14 2.5 17.5 5.86 17.5 10Z'
        stroke='#B1B6BB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.5 12.5013L10.6005 11.0943C10.2696 10.8509 10 10.2653 10 9.78618V6.66797'
        stroke='#B1B6BB'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}

export const PickTimeIcon = ({ label, ...props }) => {
  return (
    <div className='d-flex align-items-center justify-content-end' {...props}>
      {label || ''} &nbsp; <ClockIcon />
    </div>
  );
};
