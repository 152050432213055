import Icon from '@ant-design/icons';

const HideCommentIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={20}
        height={20}
        viewBox='0 0 20 20'
        fill={ props.fill || 'none'}>
        <path
          d='M18.5173 5.83268C18.5173 7.77488 16.9429 9.34935 15.0007 9.34935C13.0584 9.34935 11.484 7.77488 11.484 5.83268C11.484 3.89048 13.0584 2.31602 15.0007 2.31602C16.9429 2.31602 18.5173 3.89048 18.5173 5.83268Z'
          stroke='currentColor'
          strokeWidth='1.3'
        />
        <path
          d='M5.83268 11.6667H12.4993M5.83268 8.33333H9.16602M18.3047 10.1805C18.3232 10.3956 18.3327 10.6134 18.3327 10.8333C18.3327 14.9755 14.9748 17.5 10.8327 17.5H4.99935C3.1584 17.5 1.66602 16.0076 1.66602 14.1667V10C1.66602 5.85786 4.19055 2.5 8.33268 2.5H9.99935C10.2193 2.5 10.437 2.50947 10.6522 2.52802M13.3327 5.83333H16.666'
          stroke='currentColor'
          strokeWidth='1.5'
          strokeLinecap='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default HideCommentIcon;
