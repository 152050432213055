import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import Loading from './Loading';

const InfiniteLoadingLive = (props) => {
    return (
        <>
            <InfiniteScroll
                style={props?.style}
                className={props.className}
                dataLength={props.data.length}
                next={props.fetchNext}
                hasMore={true}
                loader={props.loading ? <Loading /> : null}
                height={props.setHeight ? props.setHeight : 'auto'}
                {...props}
            >
                {props.children}
            </InfiniteScroll>
        </>
    )
}

export default InfiniteLoadingLive