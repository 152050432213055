import { configParamsGet, ENV, updateStatus } from 'utils/constant';
import { callApi } from 'utils/request';
import { queryParamsTimezone, queryString } from 'utils/utils';
import { TYPES } from './index';

const API = {
  getChartRevenues: (queries) =>
    `${ENV}/charts/revenues?${queryString(queries) + queryParamsTimezone()}`,
  getChartUserReactions: (queries) =>
    `${ENV}/charts/user-reactions?${queryString(queries) + queryParamsTimezone()}`,
  getChartUserSubscriptions: (queries) =>
    `${ENV}/charts/user-subscriptions?${queryString(queries) + queryParamsTimezone()}`,
  getChartSales: (queries) =>
    `${ENV}/charts/sales?${queryString(queries) + queryParamsTimezone()}`,
  getChartLivestreams: (queries) =>
    `${ENV}/charts/livestreams?${queryString(queries) + queryParamsTimezone()}`,
};

const getChartRevenues = (queries) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_CHART_REVENUES_REQUEST));
    callApi(
      API.getChartRevenues(queries),
      configParamsGet,
      () => null,
      async (reponse) => {
        await dispatch(
          updateStatus(TYPES.GET_CHART_REVENUES_SUCCESS, {
            data: reponse.data,
          })
        );
      },
      async (error) => {
        await dispatch(updateStatus(TYPES.GET_CHART_REVENUES_FAILED, { error: error.errors }));
      }
    );
  };
};
const getChartUserReactions = (queries) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_USER_REACTIONS_REQUEST));
    callApi(
      API.getChartUserReactions(queries),
      configParamsGet,
      () => null,
      async (reponse) => {
        await dispatch(
          updateStatus(TYPES.GET_USER_REACTIONS_SUCCESS, {
            data: reponse.data,
          })
        );
      },
      async (error) => {
        await dispatch(updateStatus(TYPES.GET_USER_REACTIONS_FAILED, { error: error.errors }));
      }
    );
  };
};
const getChartUserSubscriptions = (queries, handleSuccess, handleFailed) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_USER_SUBSCRIPTIONS_REQUEST));
    callApi(
      API.getChartUserSubscriptions(queries),
      configParamsGet,
      () => null,
      async (reponse) => {
        await dispatch(
          updateStatus(TYPES.GET_USER_SUBSCRIPTIONS_SUCCESS, {
            data: reponse.data,
          })
        );
        handleSuccess && handleSuccess(reponse.data);
      },
      async (error) => {
        await dispatch(
          updateStatus(TYPES.GET_USER_SUBSCRIPTIONS_FAILED, { error: error.errors })
        );
        handleFailed && handleFailed();
      }
    );
  };
};
const getChartSales = (queries, handleSuccess, handleFailed) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_CHART_SALES_REQUEST));
    callApi(
      API.getChartSales(queries),
      configParamsGet,
      () => null,
      async (reponse) => {
        await dispatch(
          updateStatus(TYPES.GET_CHART_SALES_SUCCESS, {
            data: reponse.data,
          })
        );
        handleSuccess && handleSuccess(reponse.data);
      },
      async (error) => {
        handleFailed && handleFailed();
        await dispatch(updateStatus(TYPES.GET_CHART_SALES_FAILED, { error: error.errors }));
      }
    );
  };
};
const getChartLivestreams = (queries, handleSuccess, handleFailed) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_CHART_LIVESTREAMS_REQUEST));
    callApi(
      API.getChartLivestreams(queries),
      configParamsGet,
      () => null,
      async (reponse) => {
        await dispatch(
          updateStatus(TYPES.GET_CHART_LIVESTREAMS_SUCCESS, {
            data: reponse.data,
          })
        );
        handleSuccess && handleSuccess(reponse.data);
      },
      async (error) => {
        handleFailed && handleFailed();
        await dispatch(
          updateStatus(TYPES.GET_CHART_LIVESTREAMS_FAILED, { error: error.errors })
        );
      }
    );
  };
};

const resetActionChartReport = () => (dispatch) => {
  dispatch(updateStatus('RESET_ACTION_CHART_REPORT'));
};

export {
  getChartRevenues,
  getChartLivestreams,
  getChartSales,
  getChartUserReactions,
  getChartUserSubscriptions,
  resetActionChartReport,
};
