import Icon from '@ant-design/icons';

const QuotedIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={20}
        height={20}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M7.5 16.8739H3.75C3.58424 16.8739 3.42527 16.8081 3.30806 16.6909C3.19085 16.5737 3.125 16.4147 3.125 16.2489V12.7578C3.125 12.6757 3.14117 12.5945 3.17258 12.5186C3.20398 12.4428 3.25002 12.3739 3.30806 12.3159L12.6831 2.94087C12.8003 2.82366 12.9592 2.75781 13.125 2.75781C13.2908 2.75781 13.4497 2.82366 13.5669 2.94087L17.0581 6.43199C17.1753 6.5492 17.2411 6.70817 17.2411 6.87393C17.2411 7.03969 17.1753 7.19866 17.0581 7.31587L7.5 16.8739Z'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M16.8743 16.8743H7.4993L3.16406 12.5391'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default QuotedIcon;
