import { queryParamsTimezone, queryString } from 'utils/utils';
import { configParamsGet, ENV, updateStatus } from 'utils/constant';
import { callApi } from 'utils/request';
import { TYPES } from './index';

const API = {
  creatorGetListDateLiveInMonth: (params) =>
    `${ENV}/calendar?${queryString(params) + queryParamsTimezone()}`,
  creatorGetListInDate: (params) =>
    `${ENV}/events?${queryString(params) + queryParamsTimezone()}`,
  fanGetListDateLiveInMonth: (params) =>
    `${ENV}/fan/calendar?${queryString(params) + queryParamsTimezone()}`,
  fanGetListInDate: (params) =>
    `${ENV}/fan/events?${queryString(params) + queryParamsTimezone()}`,
};

const creatorGetListDateLiveInMonth = (params) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_GET_LIST_DATE_LIVE_MONTH_REQUEST));
    callApi(
      API.creatorGetListDateLiveInMonth(params),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CREATOR_GET_LIST_DATE_LIVE_MONTH_SUCCESS, {
            data: response.data,
            params
          })
        );
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.CREATOR_GET_LIST_DATE_LIVE_MONTH_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const creatorGetListInDate = (params) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_GET_LIST_IN_DATE_REQUEST));
    callApi(
      API.creatorGetListInDate(params),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CREATOR_GET_LIST_IN_DATE_SUCCESS, {
            data: response.data,
            params
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.CREATOR_GET_LIST_IN_DATE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const fanGetListDateLiveInMonth = (params) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.FAN_GET_LIST_DATE_LIVE_MONTH_REQUEST));
    callApi(
      API.fanGetListDateLiveInMonth(params),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FAN_GET_LIST_DATE_LIVE_MONTH_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.FAN_GET_LIST_DATE_LIVE_MONTH_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const fanGetListInDate = (params) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.FAN_GET_LIST_IN_DATE_REQUEST));
    callApi(
      API.fanGetListInDate(params),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FAN_GET_LIST_IN_DATE_SUCCESS, {
            data: response.data,
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.FAN_GET_LIST_IN_DATE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const resetActionCalendar = () => (dispatch) => {
  dispatch(updateStatus('RESET_ACTION_CALENDAR'));
};

const setIdScrollBackCalender = (id, cb) => {
  return (dispatch) => {
    dispatch(
      updateStatus('SET_ID_SCROLL_BACK_CALENDER', {
        data: {
          id,
        },
      })
    );
    cb && cb();
  };
};

export {
  creatorGetListDateLiveInMonth,
  creatorGetListInDate,
  fanGetListDateLiveInMonth,
  fanGetListInDate,
  resetActionCalendar,
  setIdScrollBackCalender,
};
