import {
  CustomReSelect,
  PopupGeneral,
  PopupC90SelectUser,
  ImgCustom,
  PopupListPackageC092,
  PopupSharePost,
  LayoutCreatorRight,
} from 'components';
import { InputWrapper } from 'components/FormLogin';
import { NumberViews } from 'components/LiveStream/NumberViews';

import { RealTimeFanViews } from 'components/LiveStream/Videos/Creator/RealTimeFanViews';

import { isMobile } from 'react-device-detect';

import { AVATAR_DEFAULT, LOGO_SHARE_GRAM } from 'utils/constant';
import ClockIcon from 'images/ClockIcon';

import React, { useRef, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  creatorGetLiveByKey,
  setEmptyFanListAction,
  updateChangeStatusEndLive,
  updateTypeLiveStream,
} from 'store/actions/lives';
import './index.scss';

import moment from 'moment';

import { PopupConfirmFan } from 'components';

import { customStyles } from 'utils/utils';
import { pushNotify } from 'components/Notification';

import { updateData, getOne, deleteData } from 'hooks/useRealTimeDB';
import useDevice from 'hooks/useDevice';
import C092PopupFanRanking from 'components/Popup/C092FanRanking';

import { onValue, ref } from 'firebase/database';
import { DBRealTime } from '../../../../firebase/config';

import AutoExpire from 'components/LiveStream/Animation/AutoExpire';
import EmojiBubble from 'components/LiveStream/Animation/Bubble';
import PopupC90SelectDonations from 'components/Popup/C90SelectDonations';

import { T } from 'ramda';
import { useTranslation } from 'react-i18next';
import { GroupButton } from './components/GroupButton';
import { Wrapper, GlobalWrapper, WrapperLive, InformationOBS } from './components/styled';
import { useAgora } from 'components/LiveStream/V5/Videos/ObsAgora/useAgora';
import { getEnv } from 'configs/env';
import AgoraRTC from 'agora-rtc-sdk-ng';
import { MediaPlayer } from 'components/LiveStream/V5/Videos/ObsAgora/MediaAgora/MediaPlayer';
import { AgoraOBSPlayer } from 'components/LiveStream/V5/Videos/AgoraOBSPlayer';
import { CopyValue, LayoutViewPC } from 'pages/lives/ViewFan/styled';
import { useCallback } from 'react';
import { useOutsideHoverDom, useOutsideMultipleDom } from 'hooks/useOnMover';
import { useCopy } from '../review-livestream';
import { useMemo } from 'react';
import ButtonSubmit from '../../../../components/Button/ButtonSubmit';
import { EffectCreator } from 'components/LiveStream/V5/Videos/EffectCreator';
import { useCheckPasswordLivestream } from 'hooks/useCheckPasswordLive';
import { newPathUser } from 'constants/layout/constant';
import { getUserInfo } from 'store/actions/users';
import UserCountIcon from 'images/UserCountIcon';
import LocalStorage from 'utils/storage';
import {
  LiveStreamPageWatchContentStyled,
  LiveStreamPageWatchContentStyledMobile,
} from 'pages/lives/components/styled';
import withCreatorRole from 'hocs/withCreatorRole';
import CopyIcon from 'images/CopyIcon';
import BorderImgLiveIcon from 'images/livestreams/BorderImgLiveIcon';
import MarkDownRead from 'components/MarkDownRead';
import { resetDataInfoOther, resetIDHomePage } from 'store/actions/usersNew';
const selectHourOptions = [
  { value: '00', label: '00' },
  { value: '01', label: '01' },
  { value: '02', label: '02' },
  { value: '03', label: '03' },
  { value: '04', label: '04' },
  { value: '05', label: '05' },
  { value: '06', label: '06' },
  { value: '07', label: '07' },
  { value: '08', label: '08' },
  { value: '09', label: '09' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
];

const selectMinuteOptions = [
  { value: '00', label: '00' },
  { value: '15', label: '15' },
  { value: '30', label: '30' },
  { value: '45', label: '45' },
];

const widthVideoConfig = 375;
const heightVideoConfig = 667;
const appID = getEnv('REACT_APP_AGORA_APP_ID');
const client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
const CreatorLivestream = () => {
  useEffect(() => {
    document.location.href = '#app_cocofans_0';
  }, []);
  let { dataLiveStream, packageData, loadingCreate, createData, status, isShowAgoraObs } =
    useSelector((state) => state.lives);
  const listCheckPassword = window.localStorage.getItem('cpw_ls');

  let mediaQueryList = window.matchMedia('(orientation: portrait)');
  const { join: joinAgora, remoteUsers: remoteAgora, joinState } = useAgora(client, appID);
  const findChannel = useMemo(() => {
    const checkList = remoteAgora?.filter((item) => item?.hasAudio || item?.hasVideo);
    const result = checkList.pop();
    const obj = {
      ViewLive: (
        <MediaPlayer
          type={dataLiveStream?.obs_flag === 1 ? 'obs' : 'noobs'}
          videoTrack={result?.videoTrack}
          audioTrack={result?.audioTrack}
        />
      ),
      Result: result,
    };
    return obj;
  }, [remoteAgora, dataLiveStream]);
  const [stateViewMobile, setStateViewMobile] = useState(null);
  if (isMobile) {
    mediaQueryList = window.matchMedia('(orientation: portrait)');
    mediaQueryList.addEventListener('change', function (m) {});
  }

  useEffect(() => {
    if (isMobile) {
      if (mediaQueryList.matches) {
        setStateViewMobile(true);
      } else {
        setStateViewMobile(false);
      }
    }
  }, [stateViewMobile, mediaQueryList]);

  const [isVisibleCoinPopup, setIsVisibleCoinPopup] = useState(false);
  const [isVisibleFollowPopup, setIsVisibleFollowPopup] = useState(false);
  const [popupCopyLink, setPopupCopyLink] = useState(null);

  const [isVisibleDeliverySettingPopup, setIsVisibleDeliverySettingPopup] = useState(false);
  const [isVisibleDeliveryChangePopup, setIsVisibleDeliveryChangePopup] = useState(false);
  const [isVisibleNGWordsPopup, setIsVisibleNGWordsPopup] = useState(false);
  const [listNgSetting, setListNgSetting] = useState('');
  const [denyCharacter, setDenyCharacter] = useState([]);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { id, liveKey } = useParams();
  const navigate = useNavigate();

  const [canSelectUser, setCanSelectUser] = useState(false);
  const [canSelectPackages, setCanSelectPackages] = useState(false);
  const [listUserSelected, setListUserSelected] = useState([]);

  const [canSelectDistributions, setCanSelectDistributions] = useState(false);
  const [listUserSelectedDistributions, setListUserSelectedDistributions] = useState([]);

  const [canSelectDonations, setCanSelectDonations] = useState(false);
  const [isVisiblePopUpDonation, setIsVisiblePopUpDonation] = useState(false);
  const [listUserSelectedDonations, setListUserSelectedDonations] = useState([]);

  const [listPackageSelected, setListPackageSelected] = useState([]);

  // const [stopTime, setStopTime] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState('00:00');
  const [timePast, setTimePast] = useState('00:00');
  const [checkShowAlert, setCheckShowAlert] = useState(true);

  //* EVENT 3 PHUT TAT POPUP
  const [isThreeMinutesPopUp, setIsThreeMinutesPopUp] = useState(false);
  const [isConfirmThreeMinutes, setIsConfirmThreeMinutes] = useState(false);

  const [openIOS, setOpenIOS] = useState(false);

  // Token agora
  const [tokenAgora, setTokenAgora] = useState(null);
  const [isShowInformationOBS, setIsShowInformationOBS] = useState(false);
  //close all popup
  const handleClosePopup = (e) => {
    document.body.style.overflow = 'visible';
    setIsVisibleCoinPopup(e);
    setIsVisibleFollowPopup(e);
    setIsVisibleDeliverySettingPopup(e);
    setIsVisibleDeliveryChangePopup(e);
    setIsVisibleNGWordsPopup(e);
    setIsResetChange((prev) => prev + 1);
    setLoadAgain(false);

    setValue3('hour', selectHourOptions[0]);
    setValue3('minute', selectMinuteOptions[1]);
  };

  //useEffect get data livestream
  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(creatorGetLiveByKey(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //* SHOW DATA TOP DONATE

  const [pageTopDonate, setPageTopDonate] = useState(1);

  //* END SHOW DATA TOP DONATE

  //! UPDATE SUCCESS SAVE
  useEffect(() => {
    if (createData !== null) {
      updateData(`/lives/${createData?.live_key}/config`, createData).then((_res) => {
        handleClosePopup();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createData]);

  useEffect(() => {
    if (status !== undefined && status === 'CREATOR_UPDATE_LIVE_FAILED') {
      setCanSelectPackages(false);
      setValue2('all_follow', false);
      setCanSelectUser(false);
      setValue2('all_member', false);

      setCanSelectDistributions(false);
      setCanSelectDonations(false);

      return handleClosePopup();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const convertTime = (time) => {
    const hour =
      Math.floor(time / 60) < 9 ? '0' + Math.floor(time / 60) : Math.floor(time / 60);
    const minute = time % 60 < 9 ? '0' + (time % 60) : time % 60;
    return hour + ':' + minute;
  };
  useEffect(() => {
    if (dataLiveStream) {
      const timePass = dataLiveStream?.realtime?.elapsed_time;
      setTimePast(timePass);
      const minutes_left = dataLiveStream.realtime?.minutes_left;
      setTimeRemaining(convertTime(minutes_left));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLiveStream]);
  const MINUTE_MS = 15000 * 4; // 60s;
  useEffect(() => {
    // CHECK IF HIEN TAI CHI CON 3 PHUT
    let minuteEnd = parseInt(moment.duration(timeRemaining, 'HH:mm').asMinutes());
    if (minuteEnd > 0 && minuteEnd <= 3) {
      if (!isThreeMinutesPopUp && !isConfirmThreeMinutes) {
        handleClosePopup();
        setIsThreeMinutesPopUp(true);
      }
    }

    const interval = setInterval(() => {
      const timePlus = moment(timePast, 'HH:mm').add('1', 'minutes').format('HH:mm');
      setTimePast(timePlus);

      const dr = parseInt(moment.duration(timeRemaining, 'HH:mm').asMinutes()) - 1;
      if (dr <= 0) {
        setTimeRemaining('00:00');
        setCheckShowAlert(false);
        //* CALL API AUTO END LIVE
        getOne(`/logs/lives/${liveKey}`)
          .then((snapshot) => {
            let data = [];
            if (snapshot.exists()) {
              snapshot.forEach((ss) => {
                data.push(parseInt(ss.key));
              });

              dispatch(updateChangeStatusEndLive(id, liveKey, data));
            } else {
              dispatch(updateChangeStatusEndLive(id, liveKey, ''));
            }
          })
          .catch(() => {
            dispatch(updateChangeStatusEndLive(id, liveKey, ''));
          });
      } else {
        setTimeRemaining(convertTime(dr));
      }
    }, MINUTE_MS);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timePast, timeRemaining, id]);

  const [loadAgain, setLoadAgain] = useState(false);

  useEffect(() => {
    if (dataLiveStream && !loadAgain) {
      setLoadAgain(true);
      if (dataLiveStream?.packages?.length > 0) {
        const dataPackage = dataLiveStream.packages
          .filter((item) => item !== undefined)
          .map((obj) => obj.id);

        if (dataPackage?.length > 0) {
          setListPackageSelected(dataPackage);
          setCanSelectPackages(true);
          // setIsValidCheckBox(true);
        } else {
          // setIsValidCheckBox(false);
        }
      }

      if (dataLiveStream?.user_live?.length > 0) {
        setCanSelectUser(true);
        setListUserSelected(dataLiveStream.user_live);
      } else {
        setCanSelectUser(false);
        setListUserSelected([]);
      }

      if (dataLiveStream?.user_distributions?.length > 0) {
        setCanSelectDistributions(true);
        setListUserSelectedDistributions(dataLiveStream.user_distributions);
      } else {
        setCanSelectDistributions(false);
        setListUserSelectedDistributions([]);
      }
      if (dataLiveStream?.user_donations?.length > 0) {
        setCanSelectDonations(true);
        setListUserSelectedDonations(dataLiveStream.user_donations);
      } else {
        setCanSelectDonations(false);
        setListUserSelectedDonations([]);
      }
      if (dataLiveStream?.ngWords?.length > 0) {
        setListNgSetting(dataLiveStream?.ngWords[0]);
      }

      const listWordsC010 = dataLiveStream?.ngWords_user ? dataLiveStream.ngWords_user[0] : '';
      const listWordsLive = dataLiveStream?.ngWords ? dataLiveStream.ngWords[0] : '';
      const listWordsAgency = dataLiveStream?.ngWords_agency
        ? dataLiveStream.ngWords_agency[0]
        : '';
      const listWordsSys = dataLiveStream?.ngWords_sys ? dataLiveStream.ngWords_sys[0] : '';
      let listDeny =
        [listWordsAgency].toString() +
        ',' +
        [listWordsC010].toString() +
        ',' +
        [listWordsLive].toString() +
        ',' +
        [listWordsSys].toString();
      const alphaBet = listDeny.split(',').map((element) => {
        return element.trim();
      });
      setDenyCharacter(alphaBet);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLiveStream, loadAgain]);

  //START DELIVERY SETTING
  // Reset button Change delivery
  const [isResetChange, setIsResetChange] = useState(0);
  const resetButtonChangeDelivery = useCallback(() => {
    if (dataLiveStream && Object.keys(dataLiveStream).length > 0) {
      const { packages: cbx1, all_follow: cbx2, all_member: cbx3 } = dataLiveStream;
      // Re-check Packages
      // * Enable checkbox 1
      const isEnableCheckbox1 = (cbx1 || []).some((item) => item);
      setCanSelectPackages(isEnableCheckbox1);
      // * Checked packages
      const getChecked = cbx1?.map((item) => item.id);
      setListPackageSelected(getChecked);
      // Re-check Follower
      setValue2('all_follow', cbx2);
      // Re-check Everyone
      setValue2('all_member', cbx3);
    }
  }, [dataLiveStream]);
  useEffect(() => {
    isResetChange > 0 && resetButtonChangeDelivery();
  }, [resetButtonChangeDelivery, isResetChange]);
  //form delivery setting
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    control: control2,
    setValue: setValue2,
  } = useForm(); // useForm({ defaultValues: dataLiveStream });

  // call api listPackage
  const onGetListPackage = (event) => {
    const isCheck = event.target.checked;

    if (isCheck === true) {
      setCanSelectPackages(true);
    } else {
      setCanSelectPackages(false);
      setListPackageSelected([]);
    }
  };

  // handle listPackage
  const handleOnchangePackage = (event) => {
    let updatedList = [...listPackageSelected];
    const { checked } = event.target;
    if (checked) {
      updatedList = [...listPackageSelected, parseInt(event.target.value)];
    } else {
      updatedList.splice(listPackageSelected.indexOf(parseInt(event.target.value)), 1);
    }
    setListPackageSelected(updatedList);
  };

  const onAllowMember = (e) => {
    const isAllow = e.target.checked;
    setCanSelectUser(isAllow);
    if (isAllow === true) {
      // dispatch(getListFan());
    } else {
      // setValue2('allow_member', []);
      setListUserSelected([]);
      dispatch(setEmptyFanListAction());
    }
  };

  const onAllowMembeDistributions = (e) => {
    const isAllow = e.target.checked;
    setCanSelectDistributions(isAllow);

    if (isAllow === true) {
    } else {
      setListUserSelectedDistributions([]);
    }
  };

  const onAllowMembeDonations = (e) => {
    const isAllow = e.target.checked;
    setCanSelectDonations(isAllow);

    if (isAllow === true) {
    } else {
      setListUserSelectedDonations([]);
    }
  };

  //submit
  const onSubmitSetting = async (data) => {
    const {
      // packages_checkbox,
      all_follow,
      all_member,
      // allow_member,
      // allow_member_checkbox,
    } = data;

    const newData = {
      id,
      packages: canSelectPackages ? listPackageSelected : [],
      all_follow: all_follow ? 1 : 0,
      all_member: all_member ? 1 : 0,
      users: canSelectUser
        ? listUserSelected?.map(function (obj) {
            return obj.id;
          })
        : [],
      user_distributions: canSelectDistributions
        ? listUserSelectedDistributions?.map(function (obj) {
            return obj.id;
          })
        : [],
      user_donations: canSelectDonations
        ? listUserSelectedDonations?.map(function (obj) {
            return obj.id;
          })
        : [],
      live_key: liveKey,
    };

    if (!canSelectPackages) {
      delete newData.packages;
    }

    if (!canSelectUser) {
      delete newData.users;
    }

    if (!canSelectDistributions) {
      delete newData.user_distributions;
    }

    if (!canSelectDonations) {
      delete newData.user_donations;
    }

    //* UPDATE DATA
    dispatch(updateTypeLiveStream(newData, 'delivery'));
  };
  const {
    register: register3,
    handleSubmit: handleSubmit3,
    // watch: watch3,
    control: control3,
    setValue: setValue3,
    formState: { errors: error3 },
  } = useForm({
    defaultValues: { hour: selectHourOptions[0], minute: selectMinuteOptions[1] },
  });

  //* submitTIME
  const handleUpdateTimeLive = (value) => {
    updateData(`/lives/${liveKey}/update_time_live`, { value });
    const timer = setTimeout(() => {
      deleteData(`/lives/${liveKey}/update_time_live`);
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  };
  const onSubmitTime = ({ hour, minute }) => {
    if (hour.value === '00' && minute.value === '00') {
      pushNotify('error', t('Creator.Livestream.deliveryTimeOf15Minutes'), 'error_time');
      setIsVisibleDeliveryChangePopup(false);
      return;
    }

    const newData = { id, live_key: liveKey, live_time: `${hour.value}:${minute.value}` };
    dispatch(
      updateTypeLiveStream(newData, 'hour_minute', () => {
        if (dataLiveStream?.obs_flag === 1) {
          setTimeout(() => {
            setIsShowInformationOBS(true);
          }, 500);
        }
        handleUpdateTimeLive(true);
      })
    );
  };

  const handleClickOutside = (e) => {
    setCheckShowAlert(true);
  };
  const handleOutside = (e) => {
    setCheckShowAlert(false);
  };
  let refOut = useRef(null);
  // useOutsideClick(refOut, handleClickOutside);
  // Handle out live
  useOutsideHoverDom(
    document.getElementById('sidebar_creator'),
    handleClickOutside,
    handleOutside
  );
  useOutsideHoverDom(
    document.getElementById('back_creator_live'),
    handleClickOutside,
    handleOutside
  );
  useOutsideHoverDom(
    document.getElementById('footer_coverout'),
    handleClickOutside,
    handleOutside
  );
  useOutsideMultipleDom(
    document.querySelectorAll('.creator_post_live'),
    handleClickOutside,
    handleOutside
  );
  useOutsideMultipleDom(
    document.querySelectorAll('.fansite-creator-subcription-list'),
    handleClickOutside,
    handleOutside
  );
  const [passPasswordLive, setPassPasswordLive] = useState(0);
  const { UISetPassword, handleSetPassword } = useCheckPasswordLivestream(
    () => setPassPasswordLive(2),
    dataLiveStream?.id,
    true,
    dataLiveStream?.is_password
  );
  useEffect(() => {
    if (passPasswordLive === 0) {
      if (listCheckPassword === dataLiveStream?.id.toString()) {
        setPassPasswordLive(2);
      } else {
        dataLiveStream?.is_password === 1 && handleSetPassword();
        dataLiveStream?.is_password === 0 && setPassPasswordLive(2);
      }
    }
  }, [dataLiveStream, listCheckPassword]);
  //END MORE TIME LIVESTREAM;
  const isDevice = useDevice();
  const [checkHorizontalScreen, setCheckHorizontalScreen] = useState('');
  const [isMobileScreenHorizontal, setIsMobileScreenHorizontal] = useState(false);
  const widthScreen = window.innerWidth;
  const heightScreen = window.innerHeight;

  const detect = () => {
    if (isDevice) {
      window.innerHeight > window.innerWidth
        ? setCheckHorizontalScreen('portrait')
        : window.innerWidth - window.innerHeight > 200 &&
          setCheckHorizontalScreen('landscape');
    }
  };

  useEffect(() => {
    window.addEventListener('resize', detect);
    return () => window.removeEventListener('resize', detect);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (heightScreen > widthScreen) {
      setIsMobileScreenHorizontal(false);
      isDevice && setCheckHorizontalScreen('portrait');
    } else {
      if (widthScreen - heightScreen > 200) {
        setIsMobileScreenHorizontal(true);
        isDevice && setCheckHorizontalScreen('landscape');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widthScreen, heightScreen]);

  const handleListSelectedUser = (e) => {
    setListUserSelected(e);
  };

  const handleRemoveUser = (e) => {
    setListUserSelected(listUserSelected?.filter((item) => item.id !== e.id));
  };

  // distributions
  const handleListSelectedDistributions = (e) => {
    setListUserSelectedDistributions(e);
  };

  const handleRemoveUserDistributions = (e) => {
    setListUserSelectedDistributions(
      listUserSelectedDistributions?.filter((item) => item.id !== e.id)
    );
  };
  // end distributions

  // user_donations
  const handleListSelectedDonations = (e) => {
    setListUserSelectedDonations(e);
  };

  const handleRemoveUserDonations = (e) => {
    setListUserSelectedDonations(
      listUserSelectedDonations?.filter((item) => item.id !== e.id)
    );
  };
  // end user_donations

  const customStylesReselect = {
    ...customStyles,
    menuList: (base, state) => ({
      ...base,
      height: 140,
      overflow: 'auto',
    }),
    valueContainer: (base) => ({
      ...base,
      paddingTop: 0,
      paddingBottom: 0,
      height: 40,
      minHeight: 40,
    }),
  };

  const handleSharePost = (data) => {
    setPopupCopyLink(data);
  };

  const handleShowPopup = (e) => {
    setPopupCopyLink(e);
  };

  const [emojiQueue, setEmojiQueue] = useState([]);
  useEffect(() => {
    onValue(
      ref(DBRealTime, `/lives/${liveKey}/likes`),
      (snapshot) => {
        if (snapshot.val() !== null) {
          const list_user_likes = snapshot.val().lists !== null ? snapshot.val().lists : null;

          if (list_user_likes && Object.keys(list_user_likes)?.length > 0) {
            // let lastKey = Object.keys(snapshot.val().lists).pop();
            let lastValue = snapshot.val().lists[Object.keys(snapshot.val().lists).pop()];
            if (lastValue.status === 0) {
              const newValue = snapshot.val().lists.pop();
              // console.log("LAST: ", lastValue, ' -------: ', newValue);
              // setEmojiQueue([newValue, lastValue])
              setEmojiQueue([...emojiQueue, lastValue]);
            } else {
              setEmojiQueue([...[], {}]);
              //setEmojiQueue([])
            }
          }
        }
      }, //! END if (snapshot.val() !== null)
      {
        onlyOnce: false,
      }
    );
  }, []);
  // Token livestream
  // useEffect(() => {
  //   if (dataLiveStream) {
  //     dispatch(
  //       createTokenAgora({
  //         // name: `${data?.user?.account_name}_${data?.live_key}`,
  //         name: dataLiveStream?.live_key,
  //         exSec: dataLiveStream?.realtime?.minutes_left * 60,
  //         action: (tk) => {
  //           joinAgora(dataLiveStream?.live_key, tk);
  //           setTokenAgora(encodeURIComponent(tk));
  //         },
  //       })
  //     );
  //   }
  // }, [dataLiveStream]);
  useEffect(() => {
    if (dataLiveStream && dataLiveStream?.live_token) {
      joinAgora(dataLiveStream?.live_key, dataLiveStream?.live_token);
    }
  }, [dataLiveStream]);
  const [copySuccessObs, setCopySuccessObs] = useState(false);
  useEffect(() => {
    if (copySuccessObs) {
      const time = setTimeout(() => setCopySuccessObs(false), 1500);
      return () => {
        clearTimeout(time);
      };
    }
  }, [copySuccessObs]);
  const [value, copy] = useCopy();

  const handleRedirect = () => {
    LocalStorage.set('from-live', 1);
    navigate(`${newPathUser}live/list-livestream`);
  };
  return passPasswordLive === 0 ? (
    UISetPassword
  ) : (
    <div className='live-stream-wrapper-page'>
      {/* POPUP CON 3 PHUT HET GIO LIVE  */}
      {popupCopyLink && (
        <PopupSharePost isVisiblePopup={handleShowPopup} data={popupCopyLink || {}} />
      )}
      {isThreeMinutesPopUp && (
        <PopupConfirmFan
          className='creator-confirm-close-live'
          colorSvg='#FF9F43'
          text={t('Popup.notifi3MinuteEndLive')}>
          <div
            className={`btn ok`}
            onClick={(event) => {
              //* CLOSE POPUP AND OPEN POPUP ADD NEW TIME
              event.preventDefault();
              event.stopPropagation();
              setIsConfirmThreeMinutes(true);
              setIsThreeMinutesPopUp(false);
              handleClosePopup();
              setIsVisibleDeliveryChangePopup(true);
            }}>
            {t('Common.buttonYes')}
          </div>
          <div
            className='btn cancel'
            onClick={(event) => {
              //* CLOSE POPUP UP
              event.preventDefault();
              event.stopPropagation();
              setIsConfirmThreeMinutes(true);
              setIsThreeMinutesPopUp(false);
              handleClosePopup();
            }}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}

      {/* General coin */}
      {isVisibleCoinPopup && (
        <PopupGeneral
          generalClass={`pp-up-fan-views popup-with-scroll ${isMobile ? 'is-mobile' : ''}`}
          title={t('Popup.ranking')}
          className={`popup-fan-ranking ${isMobile ? 'is-mobile' : ''}`}
          closeModal={handleClosePopup}>
          <C092PopupFanRanking isMobile={isMobile} liveKey={liveKey} page={pageTopDonate} />
        </PopupGeneral>
      )}
      {/* General follow */}
      {isVisibleFollowPopup && (
        <PopupGeneral
          generalClass='pp-up-fan-views'
          closeModal={handleClosePopup}
          className={`follow ${isMobile ? 'is-mobile' : ''}`}
          title={t('Popup.audience')}>
          <RealTimeFanViews liveKey={liveKey} isMobile={isMobile} />
        </PopupGeneral>
      )}
      {/* General Delivery setting */}
      {isVisibleDeliverySettingPopup && (
        <PopupGeneral
          closeModal={handleClosePopup}
          className='delivery-setting'
          title={t('Popup.changeDelivery')}>
          <form className='fansite-form change-delivery' onSubmit={(e) => e.preventDefault()}>
            <InputWrapper
              id='fsite-folk-can-view'
              label={t('Creator.CreateLiveStream.destinationSettings')}
              className='category'>
              <div className='box-checkbox box-checkbox-list'>
                <label className='item'>
                  <input
                    name='packages_checkbox'
                    type='checkbox'
                    onChange={onGetListPackage}
                    checked={canSelectPackages}
                  />
                  <span className='checkmark'></span>
                  <span className='text'>{t('Common.subscription')}</span>
                </label>

                <label className='item'>
                  <Controller
                    name='all_follow'
                    control={control2}
                    // rules={{ required: 'Please select hour' }}
                    render={({ field }) => (
                      <>
                        <input
                          field={field}
                          type='checkbox'
                          {...register2('all_follow')}
                          defaultChecked={dataLiveStream?.all_follow === 1 ? true : false}
                        />
                        <span className='checkmark'></span>
                        <span className='text'>{t('homePageHeader.follower')}</span>
                      </>
                    )}
                  />
                </label>
                <label className='item'>
                  <Controller
                    name='all_member'
                    control={control2}
                    // rules={{ required: 'Please select hour' }}
                    render={({ field }) => (
                      <>
                        <input
                          field={field}
                          type='checkbox'
                          {...register2('all_member')}
                          defaultChecked={dataLiveStream?.all_member === 1 ? true : false}
                        />
                        <span className='checkmark'></span>
                        <span className='text'>{t('Creator.CreateLiveStream.everyone')}</span>
                      </>
                    )}
                  />
                </label>
              </div>
              <div
                className={`subscribers-area w-100 mt-2 rounded-6 position-relative ${
                  canSelectPackages ? 'white' : ''
                }`}>
                <div className='box-checkbox d-flex flex-wrap'>
                  {canSelectPackages &&
                    packageData?.map((pack, index) => (
                      <>
                        <label className='item' key={index}>
                          <input
                            type='checkbox'
                            id={pack.id}
                            // name={`packages[${index}]`}
                            value={pack.id}
                            name={pack.id}
                            onChange={handleOnchangePackage}
                            defaultChecked={
                              listPackageSelected.includes(pack.id) ? true : false
                            }
                            // checked={pack.is_checked}
                          />
                          <span className='checkmark'></span>
                          <span className='text'>{pack.title}</span>
                        </label>
                      </>
                    ))}
                </div>
              </div>
            </InputWrapper>

            <InputWrapper id='fsite-any' className='any'>
              <div className='box-checkbox'>
                <label className='item'>
                  <input
                    type='checkbox'
                    name='allow_member_checkbox'
                    defaultChecked={canSelectUser ? true : false}
                    onChange={onAllowMember}
                  />
                  <span className='checkmark'></span>
                  <span className='text'> {t('Creator.CreateLiveStream.anyInvidual')}</span>
                </label>
              </div>
            </InputWrapper>
            <div className={`list-user-selected-wrapper ${canSelectUser && 'approve'}`}>
              <div className='list-user'>
                {listUserSelected?.length > 0 ? (
                  listUserSelected?.map((item) => (
                    <div
                      key={item.id}
                      className='item'
                      onClick={() => {
                        if (canSelectUser) {
                          handleRemoveUser(item);
                        }
                      }}>
                      {item.account_name || item.full_name}
                      <span>x</span>
                    </div>
                  ))
                ) : (
                  <span className='d-middle' style={{ color: '#b1b6bb', fontWeight: 400 }}>
                    {t('ProfileSetting.anyInvidual')}
                  </span>
                )}
              </div>
              <PopupC90SelectUser
                isDisabled={canSelectUser}
                handleListSelectedUser={handleListSelectedUser}
                listUserSelected={listUserSelected}
                classNameCustom='custom'
              />
            </div>
            <InputWrapper className='any'>
              <div className='box-checkbox'>
                <label className='item'>
                  <input
                    type='checkbox'
                    id='user_donations'
                    name='user_donations'
                    // defaultChecked={canSelectDonations ? true : false}
                    checked={canSelectDonations ? true : false}
                    onChange={onAllowMembeDonations}
                  />
                  <span className='checkmark'></span>
                  <span className='text'>{t('Creator.Livestream.payerList')}</span>
                </label>
              </div>
            </InputWrapper>
            <div
              className={`list-user-selected-wrapper mb-40 ${
                canSelectDonations && 'approve'
              } ${isVisiblePopUpDonation ? 'active' : ''}`}>
              <div className='list-user'>
                {listUserSelectedDonations?.length > 0 ? (
                  listUserSelectedDonations?.map((item) => (
                    <div
                      key={item.id}
                      className='item'
                      onClick={() => {
                        if (canSelectDonations) {
                          handleRemoveUserDonations(item);
                        }
                      }}>
                      {item.account_name || item.full_name}
                      <span>x</span>
                    </div>
                  ))
                ) : (
                  <span className='d-middle' style={{ color: '#b1b6bb', fontWeight: 400 }}>
                    {t('Creator.Livestream.selectFromThePayerList')}
                  </span>
                )}
              </div>
              <PopupC90SelectDonations
                isShow={canSelectDonations}
                setIsVisiblePopUpDonation={setIsVisiblePopUpDonation}
                handleListSelectedUser={handleListSelectedDonations}
                handleCloseCheckBox={(data) => {
                  setCanSelectDonations(data);
                }}
                listUserSelected={listUserSelectedDonations}
                liveKey={liveKey}
              />
            </div>
            {/* END user_donations */}
            <div className='hr-hyphen'></div>
            <InputWrapper
              id='fsite-submit'
              className={`button-submit-delivery-setting`}
              // className={`button-submit ${isDisabledBtnDeliverySetting ? 'disabled' : ''}`}
            >
              {loadingCreate ? (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}>
                  <span
                    className='spinner-border m-auto'
                    style={{ width: '2rem', height: '2rem' }}
                    role='status'></span>
                </button>
              ) : (
                <ButtonSubmit
                  onClick={handleSubmit2(onSubmitSetting)}
                  // disabled={isDisabledBtnDeliverySetting}
                >
                  {t('Common.Ok')}
                </ButtonSubmit>
              )}
              <button
                className='button-cancel-delivery-setting'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsConfirmThreeMinutes(true);
                  setIsThreeMinutesPopUp(false);
                  setIsVisibleDeliverySettingPopup(false);
                  handleClosePopup();
                }}>
                {t('Common.buttonCancel')}
              </button>
            </InputWrapper>
          </form>
        </PopupGeneral>
      )}

      {/* General Delivery change */}
      {isVisibleDeliveryChangePopup && (
        <PopupGeneral
          closeModal={handleClosePopup}
          className='delivery-change'
          title={t('Popup.deliveryExtension2')}>
          <form className='fansite-form extention-form' onSubmit={(e) => e.preventDefault()}>
            <InputWrapper
              id='fsite-hour'
              label={t('Creator.CreateLiveStream.deliveryTimeSetting')}
              className='input-time'
              error={error3?.hour ? error3?.hour?.message : ''}>
              <Controller
                name='hour'
                control={control3}
                // rules={{ required: 'Please select hour' }}
                render={({ field }) => (
                  <div className='select'>
                    <CustomReSelect
                      field={field}
                      className=''
                      valueOption={selectHourOptions}
                      multi={false}
                      indicatorContainerHidden={true}
                      isSearchable={false}
                      defaultValue={selectHourOptions[0]}
                      stylesCustoms={customStylesReselect}
                      inputMode='none'
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      onFocus={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                )}
              />
              <div className='time time-custom'>
                <span>{t('Common.time')}</span>
                <ClockIcon />
              </div>
            </InputWrapper>
            <InputWrapper
              id='fsite-minute'
              className='input-time mb-46'
              error={error3?.minute ? error3?.minute?.message : ''}>
              <Controller
                name='minute'
                control={control3}
                // rules={{ required: 'Please select minutes' }}
                render={({ field }) => (
                  <div className='select'>
                    <CustomReSelect
                      field={field}
                      valueOption={selectMinuteOptions}
                      multi={false}
                      indicatorContainerHidden={true}
                      defaultValue={selectMinuteOptions[1]}
                      isSearchable={false}
                      stylesCustoms={customStylesReselect}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      onFocus={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                )}
              />
              <div className='time'>
                <span>{T('Creator.Livestream.minutes')}</span>
                <ClockIcon />
              </div>
            </InputWrapper>
            <div className='hr-hyphen'></div>
            <InputWrapper
              id='fsite-submit'
              // className={`button-submit ${!watch3Hour || !watch3Minute ? 'disabled' : ''}`}>
              className={`button-submit-delivery-setting mb-4`}>
              {loadingCreate ? (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}>
                  <span
                    className='spinner-border m-auto'
                    style={{ width: '2rem', height: '2rem' }}
                    role='status'></span>
                </button>
              ) : (
                <button
                  onClick={handleSubmit3(onSubmitTime)}
                  // disabled={!watch3Hour || !watch3Minute}
                >
                  {t('Common.Ok')}
                </button>
              )}
              <button
                className='button-cancel-delivery-setting'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsConfirmThreeMinutes(true);
                  setIsThreeMinutesPopUp(false);
                  handleClosePopup();
                }}>
                {t('Common.buttonCancel')}
              </button>
            </InputWrapper>
          </form>
        </PopupGeneral>
      )}
      {isShowInformationOBS && isShowAgoraObs && (
        <PopupGeneral
          closeModal={() => setIsShowInformationOBS(false)}
          className='delivery-setting obs'
          title='OBSのURL更新'>
          <InformationOBS height='217px'>
            <div className='information_obs-title'>Information Url</div>
            <div className='wrapper_obs-link'>
              <div className='information_obs-link'>
                {`${getEnv(
                  'REACT_APP_API_SERVER'
                )}/livestreams/agora/setting?token=${encodeURIComponent(
                  dataLiveStream?.live_token
                )}`}
              </div>
              <div style={{ position: 'relative' }}>
                <CopyIcon
                  className='information_obs-copy'
                  onClick={() => {
                    copy(
                      `${getEnv(
                        'REACT_APP_API_SERVER'
                      )}/livestreams/agora/setting?token=${encodeURIComponent(
                        dataLiveStream?.live_token
                      )}`
                    );
                    setCopySuccessObs(true);
                  }}
                />
                {copySuccessObs && (
                  <CopyValue right='120px' className='copy-success'>
                    {t('LiveStream.V5.messageCopyLink')}
                  </CopyValue>
                )}
              </div>
            </div>
            <div className='information_obs-bot'>{t('LiveStream.V5.noteCopyObs')}</div>
            <div className='hr-hyphen-obs'></div>
            <div className='information_obs-wrap'>
              <button
                className='information_obs-button'
                onClick={() => setIsShowInformationOBS(false)}>
                OK
              </button>
            </div>
          </InformationOBS>
        </PopupGeneral>
      )}
      <GlobalWrapper className={`live`}>
        <LiveStreamPageWatchContentStyledMobile>
          <div className={`live-stream-page__left `}>
            <LayoutCreatorRight
              cb={handleRedirect}
              className={`create-live-header ${openIOS ? 'openIOS' : ''}`}
              titlePage={t('Creator.Livestream.titleLive')}>
              <div className='live-stream-page-left-content'>
                <div className='idol-info-live'>
                  <div className='idol-info-live__left'>
                    <div
                      className='idol-info-live-img'
                      onClick={() => {
                        dispatch(resetIDHomePage());
                        dispatch(resetDataInfoOther());
                        navigate(`${newPathUser}`);
                      }}>
                      <BorderImgLiveIcon className='border-img-icon' />
                      <ImgCustom
                        style={{ borderRadius: '50%' }}
                        isPlaceholderAvatar
                        src={
                          dataLiveStream?.user?.avatar
                            ? `${dataLiveStream?.user?.avatar}`
                            : AVATAR_DEFAULT
                        }
                        alt=''
                      />
                    </div>
                    <div className='idol-info-live-content'>
                      <div className='idol-info-live-content__title'>
                        {dataLiveStream?.live_title}
                      </div>
                      <div className='idol-info-live-content__name'>
                        {dataLiveStream?.user?.account_name}
                      </div>
                    </div>
                  </div>
                </div>
                <Wrapper>
                  <WrapperLive className={`screen-live ${!isDevice && 'screen-live-pc'}`}>
                    <EffectCreator />
                    {/**  VIDEO LIVESTREAM */}
                    {dataLiveStream?.live_token && (
                      <div className='bg-live'>
                        <AgoraOBSPlayer
                          id={id}
                          isOpenIOS={openIOS}
                          setIsOpenIOS={setOpenIOS}
                          host={true}
                          liveKey={liveKey}
                          allowComment={1}
                          type='lives'
                          view='creator'
                          showButton='screen-creator-live'
                          checkShowAlert={checkShowAlert}
                          setCheckShowAlert={setCheckShowAlert}
                          isMobileScreenHorizontal={isMobileScreenHorizontal}
                          checkHorizontalScreen={checkHorizontalScreen}
                          videoConfig={
                            mediaQueryList.matches
                              ? { width: widthVideoConfig, height: heightVideoConfig }
                              : { width: heightVideoConfig, height: widthVideoConfig }
                          }
                          token={dataLiveStream?.live_token}
                          ViewAgora={dataLiveStream?.obs_flag === 1 && findChannel?.ViewLive}
                          showAlert={dataLiveStream?.obs_flag === 0}
                        />
                        <div className='count-user-watch'>
                          <span>
                            <UserCountIcon />
                          </span>
                          <span className='count'>{<NumberViews id={liveKey} />}</span>
                        </div>
                        <div className='logo-coco'>
                          <img src={LOGO_SHARE_GRAM} height={32} width={143} alt='logo' />
                        </div>
                        {emojiQueue !== undefined && emojiQueue?.length > 0 && (
                          <div
                            className='dancing-icon'
                            style={{ display: 'flex', justifyContent: 'center' }}>
                            {emojiQueue?.map(
                              ({ id, label, symbol, size, left, one, two, color }) => (
                                <AutoExpire key={id}>
                                  <EmojiBubble
                                    id={id}
                                    color={color}
                                    label={label}
                                    symbol={symbol}
                                    size={size}
                                    left={left}
                                    one={one}
                                    two={two}
                                  />
                                </AutoExpire>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    )}
                    {/* END VIDEO LIVESTREAM */}
                  </WrapperLive>
                </Wrapper>
                <LayoutViewPC>
                  <PopupListPackageC092 isMobile={isMobile} />
                  <div className='live-content-description'>
                    <MarkDownRead
                      content={dataLiveStream?.live_description}
                      charLimit={70}
                      isMeta
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    />
                  </div>
                </LayoutViewPC>
              </div>
            </LayoutCreatorRight>
          </div>
          <div className={`live-stream-page__right`}>
            <GroupButton
              setIsVisibleFollowPopup={setIsVisibleFollowPopup}
              setPageTopDonate={setPageTopDonate}
              setIsVisibleCoinPopup={setIsVisibleCoinPopup}
              setIsVisibleDeliverySettingPopup={setIsVisibleDeliverySettingPopup}
              setIsVisibleDeliveryChangePopup={setIsVisibleDeliveryChangePopup}
              listNgSetting={listNgSetting}
              denyCharacter={denyCharacter}
              setListNgSetting={setListNgSetting}
              timePast={timePast}
              timeRemaining={timeRemaining}
              checkHorizontalScreen={checkHorizontalScreen}
              setIsResetChange={setIsResetChange}
            />
          </div>
        </LiveStreamPageWatchContentStyledMobile>
      </GlobalWrapper>
    </div>
  );
};

export default withCreatorRole(CreatorLivestream);
