import { ArrowLeftIconV2 } from 'images';
import { useNavigate, useLocation } from 'react-router-dom';
import { ImgCustom } from 'components';
import './index.scss';
import { newPathUser } from 'constants/layout/constant';
import { isAdmin as isAdminLocal, isAgency } from 'utils';

export default function BackHeader({
  label,
  onBack,
  redirectTo,
  routeTo,
  className,
  avatar,
  onClickAvatar,
  isAdmin = false,
  marginLeft,
  idBack = '',
  page,
  classNameHeader,
  rightContent,
  cb = null,
  noButtonBack = false,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const goBack = () => {
    if (routeTo) {
      return navigate(routeTo || `${newPathUser}`);
    }
    if (cb) {
      cb();
    } else {
      if (!redirectTo || redirectTo === '-1') {
        return navigate(-1);
      } else if (redirectTo === '-2') {
        return navigate(-2);
      } else if (redirectTo !== '') {
        return navigate(`${redirectTo}`);
      } else {
        navigate(`${newPathUser}`);
      }
    }
  };
  const isCheckNavigatePost = location.pathname.includes('/home-page/post');
  const isCheckNavigateMessage = location.pathname.includes('/home-page/message');

  return (
    <>
      <div
        className={`hide-greater-than-1024 back-to-page uiMobile ${className} ${classNameHeader} ${
          rightContent ? 'pr' : ''
        }`}>
        <ArrowLeftIconV2 onClick={onBack ? onBack : goBack} className='hidden-policy' />
        <div
          className={`title ${marginLeft} ${page}`}
          style={{ marginLeft: 0 }}>
          {label}
        </div>
      </div>
      <div className={`hide-less-than-1024 back-header d-flex w-100 bg-white ${className} ${classNameHeader}`}>
        <div className='row' style={{ width: '100%' }}>
          <div
            className={`col-12 d-flex align-items-center px-5 ${rightContent ? 'pr' : ''} ${
              noButtonBack ? 'no-button-back' : ''
            }`}>
            {((isAdminLocal || isAgency) && isCheckNavigatePost) ||
            (isAdminLocal && isCheckNavigateMessage)
              ? ''
              : !noButtonBack && (
                  <ArrowLeftIconV2
                    id={idBack}
                    onClick={isAdminLocal || isAgency ? () => {} : onBack ? onBack : goBack}
                    className={`${isAdminLocal || isAgency ? 'diasble-go-back' : ''}`}
                  />
                )}

            <p
              className='mb-0 ms-4 header-left-content'
              style={{
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: '100%',
              }}>
              {label}
            </p>
            {rightContent && <div className='header-right-content'>{rightContent}</div>}
          </div>
        </div>
        {avatar && (
          <ImgCustom
            src={avatar}
            className={`avatar-right ${isAdmin && 'avatar_admin'}`}
            alt='avatar'
            onClick={() => {
              onClickAvatar && onClickAvatar();
            }}
          />
        )}
      </div>
    </>
  );
}
