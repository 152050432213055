import Icon from '@ant-design/icons';

const ShareIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        fill='none'
        viewBox='0 0 20 20'>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M5 12.5a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM13.75 18.125a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM13.75 6.875a2.5 2.5 0 100-5 2.5 2.5 0 000 5zM11.648 5.727L7.104 8.648M7.104 11.352l4.544 2.921'></path>
      </svg>
    )}
    {...props}
  />
);

export default ShareIcon;
