import React, { useRef, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
const Video = styled.div`
  video {
    object-fit: contain !important;
    aspect-ratio: 4/3;
  }
`;
const MediaPlayer = (props) => {
  const container = useRef(null);
  useEffect(() => {
    if (!container.current) return;
    props.videoTrack?.play(container.current);
    return () => {
      props.videoTrack?.stop();
    };
  }, [container, props.videoTrack]);
  useEffect(() => {
    if (props.audioTrack) {
      props.audioTrack?.play();
    }
    return () => {
      props.audioTrack?.stop();
    };
  }, [props.audioTrack]);
  return (
    <Video
      typeobs={props?.type}
      ref={container}
      // className='video-player'
      className={`${isMobile ? 'sc-creator-views-mobile' : ''} sc-creator-views current-view`}
      style={{ width: '100%', height: '100%' }}></Video>
  );
};

export { MediaPlayer };
