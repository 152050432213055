import React from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { TableCustom } from 'components';
import { widthSreen } from 'utils/constant';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
const COLORS = ['#2A71C2', '#FF0000', '#91BC3A', '#744EA3', '#24A3C5', '#FC842A'];
const RADIAN = Math.PI / 180;
const ChartCircle = ({ data, genderChart, titleChart = '' }) => {
  const { t, i18n } = useTranslation();
  const AGE_ENUM = {
    // age_10_19: `10${t('Common.generation')}`,
    age_20_29: `20${t('Common.generation')}`,
    age_30_39: `30${t('Common.generation')}`,
    age_40_49: `40${t('Common.generation')}`,
    age_50_59: `50${t('Common.generation')}`,
    age_over_60: `60${t('Common.generation')}`,
  };
  const AGE_ENUM_ARR = [
    // { key: 'age_10_19', value: `10${t('Common.generation')}` },
    { key: 'age_20_29', value: `20${t('Common.generation')}` },
    { key: 'age_30_39', value: `30${t('Common.generation')}` },
    { key: 'age_40_49', value: `40${t('Common.generation')}` },
    { key: 'age_50_59', value: `50${t('Common.generation')}` },
    { key: 'age_over_60', value: `60${t('Common.generation')}` },
  ];
  const handleConvertData = () => {
    const newData = { ...data };
    let newArr = [];
    delete newData.month;
    delete newData.male;
    delete newData.female;
    delete newData.total;
    delete newData.year;
    for (const property in newData) {
      newArr.push({
        name: AGE_ENUM[property],
        value: genderChart === 'female' ? newData[property].female : newData[property].male,
      });
    }
    return newArr;
  };

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill='white'
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline='central'
        fontSize={10}
        fontWeight={500}>
        {handleConvertData()[index]?.value > 0 ? handleConvertData()[index]?.name : ''}
      </text>
    );
  };

  const monthsArray = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  return (
    <React.Fragment>
      <p className='report-chart-label'>
        {/* {+data.month} */}
        {i18n.language !== 'en'
          ? t(titleChart, { month: `${+data.month}` })
          : t(titleChart, { month: `${monthsArray[+data.month - 1]}` })}
        （{genderChart === 'female' ? `${t('Common.woman')}` : `${t('Common.men')}`}）
      </p>
      <ResponsiveContainer
        width='100%'
        height='100%'
        minWidth={isMobile ? 300 : 500}
        minHeight={300}>
        <PieChart
          width={`${isMobile ? '300px' : '100%'}`}
          height={'100%'}
          className='chart-circle'>
          <Pie
            data={handleConvertData()}
            cx={`${widthSreen > 575 ? '50%' : '40%'}`}
            width={isMobile ? 300 : 500}
            cy='50%'
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={130}
            dataKey='value'>
            {handleConvertData().map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index]} />
            ))}
          </Pie>
          <Legend
            align='center'
            verticalAlign='middle'
            layout='vertical'
            iconType='circle'
            wrapperStyle={{ right: widthSreen > 991 ? 130 : 0, top: 20 }}
          />
        </PieChart>
      </ResponsiveContainer>
      <div className='report-table-label'>{t('Report.breakdown')}</div>
      <TableCustom className='general subscription'>
        <thead>
          <tr>
            <th style={{ minWidth: 41, maxWidth: 41, width: 41 }}></th>
            {AGE_ENUM_ARR.map((item) => (
              <th key={item.value}>{item.value}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th
              style={{
                minWidth: 41,
                maxWidth: genderChart === 'female' && i18n.language === 'en' ? 60 : 41,
                width: genderChart === 'female' && i18n.language === 'en' ? 60 : 41,
              }}>
              {genderChart === 'female' ? `${t('Common.woman')}` : `${t('Common.men')}`}
            </th>
            {AGE_ENUM_ARR.map((item) => (
              <td key={item.value} style={{ color: '#868686' }}>
                {genderChart === 'female' ? data[item.key].female : data[item.key].male}
              </td>
            ))}
          </tr>
        </tbody>
      </TableCustom>
    </React.Fragment>
  );
};

export default ChartCircle;
