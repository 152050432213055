import Icon from '@ant-design/icons';

const DelGhimIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={20}
        height={20}
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6.875 13.125L3.75 16.25'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.75 3.125L16.25 16.875'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.32044 7.05357L7.18652 7.1875C7.18652 7.1875 5.01902 6.10375 2.70224 7.97269C2.63382 8.02777 2.57776 8.09662 2.5377 8.17477C2.49763 8.25293 2.47445 8.33864 2.46968 8.42634C2.4649 8.51404 2.47863 8.60176 2.50997 8.68381C2.54131 8.76585 2.58956 8.84039 2.65158 8.90257L11.081 17.332C11.1441 17.3953 11.2201 17.4443 11.3038 17.4757C11.3875 17.507 11.477 17.5201 11.5661 17.5139C11.6553 17.5078 11.7421 17.4825 11.8207 17.4399C11.8993 17.3973 11.9678 17.3384 12.0216 17.267C12.6156 16.4775 13.5166 14.9332 13.0153 13.3179'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.447 11.1756L17.6817 7.94087C17.7989 7.82366 17.8648 7.66469 17.8648 7.49893C17.8648 7.33317 17.7989 7.1742 17.6817 7.05699L12.9406 2.31587C12.8234 2.19866 12.6644 2.13281 12.4987 2.13281C12.3329 2.13281 12.1739 2.19866 12.0567 2.31587L9.08984 5.28275'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default DelGhimIcon;
