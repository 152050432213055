import { useWasIt } from 'hooks/v2/useWasIt';
import { useRef } from 'react';
import { useIntersection } from 'react-use';

export function LazyAwareSearchShort({ render, rootMargin = '1000px' }) {
  const ref = useRef(null);
  const intersection = useIntersection(ref, {
    root: document.querySelector('#short-video-list-search'),
    rootMargin: '80px',
    threshold: 1,
  });

  const isIntersecting = !!intersection?.isIntersecting;
  const wasIntersected = useWasIt(intersection?.isIntersecting, true);

  return <>{render({ ref, isIntersecting, wasIntersected })}</>;
}
