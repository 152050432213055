import { getEnv } from 'configs/env';
import { callApi, configParamsGet, updateStatus } from 'utils';
import { TYPES } from '.';

export const getDetailPostMeta = (id) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/post-meta/${id}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_DETAIL_POST_META_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_DETAIL_POST_META_SUCCESS, {
            data: {
              ...response.data,
              url: `${getEnv('REACT_APP_FAN_URL')}/post-sale/${id}`,
            },
          })
        );
      },
      (error) => {
        dispatch(updateStatus(TYPES.GET_DETAIL_POST_META_FAILED, { err: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

export const getInfoMetaPost = (id, cb) => {
  const url = `${getEnv('REACT_APP_API_SERVER')}/post-meta/${id}`;
  return () => {
    callApi(url, configParamsGet, null, async (response) => {
      cb &&
        cb({
          ...response.data,
          url: `${getEnv('REACT_APP_FAN_URL')}/post-sale/${id}`,
        });
    });
  };
};

export const clearPostMeta = () => {
  return (dispatch) => {
    dispatch(updateStatus('CLEAR_POST_META'));
  };
};
