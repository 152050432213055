import styled from 'styled-components';

const EffectStyled = styled.div`
  z-index: 150;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 54px;
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100%;
  cursor: pointer;
`;
const IconEffectStyled = styled.img`
  width: 14.5px;
  height: 14.5px;
`;
export { EffectStyled, IconEffectStyled };
