import { useEffect, useState } from 'react';

const useLoading = () => {
  const [loadingCustom, setLoadingCustom] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoadingCustom(false);
    }, 1500);
    clearTimeout();
  }, [loadingCustom]);
  return loadingCustom;
};

export default useLoading;
