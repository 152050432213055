import 'react-calendar/dist/Calendar.css';
import { useTranslation } from 'react-i18next';
import { LOGO_WARNING_STOP_AGE } from 'utils/constant';
import { HeaderListIdolV2, ImgCustom } from '../../components';
import './index.scss';

const ConfirmNoAge = ({ handleBack }) => {
  const { t } = useTranslation();

  return (
    <div className='block-div-signin'>
      <HeaderListIdolV2 />
      <div className='fansite-verify-age'>
        <div className='box-verify-age'>
          <div className='header-popup'></div>
          <div className='bg-white'>
            <div className='title'>{t('CheckAge.titleStop')}</div>
            <div className='header-popup'>
              <ImgCustom src={LOGO_WARNING_STOP_AGE} alt='logo' className='logo-cocofans' />
            </div>
            <div className='text display-linebreak text-stop'>
              {t('CheckAge.text2')}
            </div>
            <div className='btn-wrapper'>
              <div
                className='btn btn-yes'
                onClick={handleBack}>
                {t('Common.return')}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmNoAge;
