import { LayoutFan } from 'components';
import useLoading from 'hooks/useLoading';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { userGetDetailNotification } from 'store/actions/notification';
import { convertToCurrentTime, getTitleByTypeAdmin } from 'utils/utils';
import '../styles.scss';

export default function Index() {
  const dispatch = useDispatch();
  const { dataNotify, loading: isLoading } = useSelector((state) => state.notification);
  const { id } = useParams();
  const initLoading = useLoading();
  const { t } = useTranslation();
  useEffect(() => {
    dispatch(userGetDetailNotification(id));
  }, []);

  return (
    <LayoutFan title={t('Notification.title')} loading={isLoading || initLoading}>
      <div className='notification-page'>
        {dataNotify && (
          <div className='detail-noti'>
            <div className='date'>{convertToCurrentTime(dataNotify?.start_time)}</div>
            <div
              className='title my-2'
              dangerouslySetInnerHTML={{
                __html: getTitleByTypeAdmin(+dataNotify?.title, dataNotify?.title),
              }}
            />
            <div
              className='content'
              dangerouslySetInnerHTML={{
                __html: dataNotify?.content_type ? '' : dataNotify?.content,
              }}
            />
          </div>
        )}
      </div>
    </LayoutFan>
  );
}
