import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TableCustom } from 'components';
import { convertTextMonthEN, formatCoin } from 'utils/utils';
import './index.scss';
import { useDispatch } from 'react-redux';
import {
  getListRevenuePayementHistory,
  getListRevenuePayementHistoryMonth,
} from 'store/actions/affiliator';
const SalesSumary = () => {
  const dispatch = useDispatch();
  const year = new Date().getFullYear();
  const listYear = [year - 2, year - 1, year];
  const [yearSelected, setYearSelected] = useState(year);
  const [monthSelected, setMonthSelected] = useState();
  const [dataByYear, setDataByYear] = useState([]);
  const [dataByMonth, setDataByMonth] = useState([]);
  const { t, i18n } = useTranslation();
  useEffect(() => {
    dispatch(
      getListRevenuePayementHistory({ year: yearSelected }, ({ data }) => setDataByYear(data))
    );
  }, [yearSelected]);

  useEffect(() => {
    monthSelected &&
      dispatch(
        getListRevenuePayementHistoryMonth(
          { year: yearSelected, month: monthSelected },
          ({ data }) => setDataByMonth(data)
        )
      );
  }, [yearSelected, monthSelected]);

  return (
    <div className='sales-sumary'>
      <div className='list-year'>
        {listYear.map((item) => (
          <div
            className={`year ${yearSelected === item && 'active'}`}
            onClick={() => {
              setYearSelected(item);
              setMonthSelected();
            }}>
            {item}
            {t('Common.year')}
          </div>
        ))}
      </div>
      <TableCustom className='general table-af004'>
        <thead>
          <tr>
            <th></th>
            {new Array(12).fill(0).map((item, index) => (
              <th
                key={index}
                className={`${monthSelected === index + 1 && 'active'} `}
                onClick={() => setMonthSelected(index + 1)}>
                {i18n.language === 'jp' ? (
                  <>{+index + 1 + `${t('Common.month')}`}</>
                ) : (
                  convertTextMonthEN(index + 1)
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>{t('Affiliator.AF004.numberOfClicks')}</th>
            {dataByYear.map((item) => (
              <td key={item.month}>{formatCoin(item.click_banner || 0)} </td>
            ))}
          </tr>
          <tr>
            <th>{t('Affiliator.AF004.numberOfDealsClosed')}</th>
            {dataByYear.map((item) => (
              <td key={item.month}>{formatCoin(item.fans || 0)}</td>
            ))}
          </tr>
          <tr>
            <th>{t('Sales.amount_of_money')} </th>
            {dataByYear.map((item) => (
              <td key={item.month}>{formatCoin(item.profit_point || 0)}</td>
            ))}
          </tr>
        </tbody>
      </TableCustom>

      {monthSelected && (
        <TableCustom className='general table-af004 custom'>
          <thead>
            <tr>
              <th></th>
              {new Array(dataByMonth?.length).fill(0).map((item, index) => (
                <th key={index}>
                  {+index + 1} {i18n.language === 'jp' && t('Common.sun')}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>{t('Affiliator.AF004.numberOfClicks')}</th>
              {dataByMonth.map((item) => (
                <td key={item.day}>{formatCoin(item.click_banner || 0)}</td>
              ))}
            </tr>
            <tr>
              <th>{t('Affiliator.AF004.numberOfDealsClosed')} </th>
              {dataByMonth.map((item) => (
                <td key={item.day}>{formatCoin(item.fans || 0)}</td>
              ))}
            </tr>
            <tr>
              <th>{t('Sales.amount_of_money')}</th>
              {dataByMonth.map((item) => (
                <td key={item.day}>{formatCoin(item.profit_point || 0)}</td>
              ))}
            </tr>
          </tbody>
        </TableCustom>
      )}
    </div>
  );
};

export default SalesSumary;
