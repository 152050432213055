import {
  CustomSelectTime,
  ImgCustom,
  PopupLoading,
  PopupNgSetting,
  ReactCalendar,
  LayoutCreatorRight,
} from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import { pushNotify } from 'components/Notification';
import PopupConfirm from 'components/Popup/confirmSuccess';
import withCreatorRole from 'hocs/withCreatorRole';
import useCalendar from 'hooks/useCalendar';
import useOutsideClickCustom from 'hooks/useOutsideClickCustom';
import { updateData } from 'hooks/useRealTimeDB';
import useUploadImage from 'hooks/useUploadImage';
import { CloseIcon, EyeV2Icon, HiddenV2Icon } from 'images';
import CrossIcon from 'images/CrossIcon';
import IconImageCreatorCreateLiveV2 from 'images/IconImageCreatorCreateLiveV2.png';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Select, { components } from 'react-select';
import {
  clearDataLive,
  creatLiveStream,
  detailLive,
  duplicatePreLivestream,
  getListPackage,
  getListUserByKeyWord,
  resetListLiveStream,
  setEmptyPackageAction,
  updateLiveStream,
} from 'store/actions/lives';
import { getPresignedUrlS3, setLinkSaveDB, uploadS3ByPresigned } from 'utils/upload';
import { NO_IMAGE_LIVESTREAM, updateStatus } from '../../../../utils/constant';
import {
  convertToCurrentTime,
  patternNoFirstSpacing,
  TEN_MB,
  CustomOption,
  customStylesReselect,
  CustomDropdownIndicator,
  decryptPath,
  checkLinkPostSale,
  sortListPackagePointAndDate,
} from '../../../../utils/utils';
import './index.scss';
import { useNavigate } from 'react-router-dom';
import { TYPES } from 'store/actions';
import ButtonSubmit from '../../../../components/Button/ButtonSubmit';
import { newPathUser } from 'constants/layout/constant';
import lodash from 'lodash';
import CalendarIconV2 from 'images/CalendarIconV2';
import CopyIcon from 'images/CopyIcon';
import { isSafari } from 'react-device-detect';
import { BlueImg } from './styled';
import { getInfoMetaPost } from 'store/actions/postMeta';
import MetaTagViewer from 'components/MetaTagViewer/MetaTagViewer';

moment.locale('en');

export function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export const withDropdownIndicator = (WrappedComponent) => {
  const WithDropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <WrappedComponent {...props} />
        </components.DropdownIndicator>
      )
    );
  };
  WithDropdownIndicator.displayName = `WithDropdownIndicator${getDisplayName(
    WrappedComponent
  )}`;
  return WithDropdownIndicator;
};

const initialValues = {
  packages: [],
  users: [],
  date_live: '',
  hour_minute: '',
  total_live_time: '00:30',
  password: '',
};
const CreateLiveStream = () => {
  const { selectedFile, setSelectedFile, preview, destroyState, setPreview } =
    useUploadImage();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const refThumbnail = useRef();
  const { id, liveKey } = useParams();
  let {
    date,
    toggleCalendar,
    onChangeDate,
    onValueDate,
    isVisibleCalendar,
    setIsVisibleCalendar,
  } = useCalendar();
  useEffect(() => {
    dispatch(updateStatus(TYPES.GET_DETAIL_PRE_LIVESTREAM_REQUEST));
  }, []);
  const [canSelectUser, setCanSelectUser] = useState(false);
  const [canSelectPackage, setCanSelectPackage] = useState(false);

  const [listUserSelected, setListUserSelected] = useState([]);
  // const [listNgSetting, setListNgSetting] = useState([]);

  const [listNgSetting, setListNgSetting] = useState('');

  const [allMemberState, setAllMemberState] = useState(false);
  const [allFollowState, setAllFollowState] = useState(false);

  const [checked, setChecked] = useState(false);

  const datePickerRef = useRef({});

  const [isFill, setIsFill] = useState(false);
  const [isHaveDataPre, setIsHaveDataPre] = useState(false);

  const [isSubmitEdit, setIsSubmitEdit] = useState(false);
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [recordingLive, setRecordingLive] = useState(false);
  const [disableButtonWhenClick, setDisableButtonWhenClick] = useState(false);

  const [optionsUser, setOptionsUser] = useState([]);
  const [inputUser, setInputUser] = useState('');

  const [idPostMeta, setIdPostMeta] = useState(null);
  const [postDetailMeta, setPostDetailMeta] = useState(null);

  const defaultValueSearch = {
    page: 1,
    keyword: '',
    maxPage: 2,
  };
  const [paramsSearchUser, setParamsSearchUser] = useState(defaultValueSearch);

  const LIMIT_PAGE = 20;

  const selectOptionTimeLiveCreate = useMemo(() => {
    return [
      {
        value: '00:30',
        label: `30${t('Creator.CreateLiveStream.minutes')}`,
      },
      { value: '01:00', label: `1 ${i18n.t('Creator.CreateLiveStream.time')}` },
      {
        value: '01:30',
        label: `1 ${i18n.t('Creator.CreateLiveStream.time')}${
          i18n.language === 'en' ? ' and ' : ''
        }30${i18n.t('Creator.CreateLiveStream.minutes')}`,
      },
      {
        value: '02:00',
        label: `2 ${i18n.t('Creator.CreateLiveStream.time')}${
          i18n.language === 'en' ? 's' : ''
        }`,
      },
    ];
  }, [i18n.language]);

  useOutsideClickCustom((e) => {
    if (isVisibleCalendar) {
      const { className } = e?.target;
      if (
        e?.target !== datePickerRef?.current &&
        className !== '' &&
        !className.includes('input-at-livestream-creator')
      ) {
        toggleCalendar();
      }
    }
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    clearErrors,
    getValues,
    setError,
    watch,
  } = useForm({
    mode: 'all',
    defaultValues: { total_live_time: '00:30' },
    // defaultValues: initialValues,
  });
  const watchTitle = watch('title');
  const watchDes = watch('description');
  const watchHourMinute = watch('hour_minute');
  const watchImage = watch('image');
  const watchTotalTimeLive = watch('total_live_time');

  const isDisabledSubmit = useMemo(() => {
    return !watchTitle || !watchDes || !watchTotalTimeLive;
  }, [watchTitle, watchDes, watchTotalTimeLive]);

  const dispatch = useDispatch();
  let liveStreamState = useSelector((state) => state.lives);

  const labelFileRef = useRef({});
  const [imageWidth, setImageWidth] = useState(0);

  let _URL = window.URL || window.webkitURL;
  const maxSize = TEN_MB;

  const onChangeFile = (e) => {
    var img;
    const files = e.target.files;

    if (!files || files?.length === 0) {
      setSelectedFile(undefined);
      setValue('image', null);
    } else {
      if (!files[0].type?.match(/jpg|jpeg|png/g)) {
        setError('image', {
          type: 'required',
          message: t('ValidateMsg.C090_VALIDATE_IMAGE'),
        });

        return;
      }
      if (files[0].size > maxSize) {
        setError('image', {
          type: 'required',
          message: t('ValidateMsg.imgProfileMaxSize'),
        });

        return;
      }
      setSelectedFile(files[0]);
      setValue('image', files[0]);
      clearErrors('image');
      setIsExsitingImgBackground(true);

      // Check Image Size
      img = new Image();
      let objectUrl = _URL.createObjectURL(files[0]);
      img.onload = function () {
        setImageWidth(img.width);
        _URL.revokeObjectURL(objectUrl);
      };
      img.src = objectUrl;
      // End check Image Size
    }
    e.target.value = '';
  };

  const onGetListPackage = (event) => {
    const isCheck = event.target.checked;

    setCanSelectPackage(isCheck);

    if (isCheck === true) {
      dispatch(getListPackage());
    } else {
      setValue('packages', []);
      dispatch(setEmptyPackageAction());
    }
  };

  useEffect(() => {
    dispatch(
      getListUserByKeyWord({
        keyword: paramsSearchUser.keyword,
        page: paramsSearchUser.page,
        limit: LIMIT_PAGE,
      })
    );
  }, [paramsSearchUser.keyword, paramsSearchUser.page]);

  const getEventCheckCheckBox = (event) => {
    if (event.target?.name === 'all_member') {
      setAllMemberState(event.target.checked);
    } else {
      setAllFollowState(event.target.checked);
    }
  };

  const onRemoveImage = () => {
    destroyState();

    setValue('image', null);
    setIsFill(false);
    setIsExsitingImgBackground(false);
  };

  const livesCreateDataSelector = useSelector((state) => state.lives.createData);
  const listUserDataSelector = useSelector((state) => state.lives.listUserData);
  const [livesCreateData, setLivesCreateData] = useState();
  const dataLiveStreamSelector = useSelector((state) => state.lives.dataLiveStream);
  const [dataLiveStream, setDataLiveStream] = useState();

  const [timePost, setTimePost] = useState(null);

  const year = moment().format('YYYY');
  const month = moment().format('MM');
  const dateCR = moment().format('DD');

  useEffect(() => {
    setDataLiveStream(dataLiveStreamSelector);
    setLivesCreateData(livesCreateDataSelector);
  }, [livesCreateDataSelector, dataLiveStreamSelector]);

  useEffect(() => {
    const top = document.getElementById('app_cocofans_0');
    top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
    if (state?.createLive) {
      setDataLiveStream();
      setLivesCreateData();
    }
    return () => dispatch(clearDataLive());
  }, []);

  useEffect(() => {
    if (id !== undefined) {
      dispatch(
        detailLive(id, {
          filter_words: false,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let { packageData, dataPreLivestream } = useSelector((state) => state.lives);
  const [listPackage, setListPackage] = useState([]);

  useEffect(() => {
    const data = sortListPackagePointAndDate(packageData);
    setListPackage(data);
  }, [packageData]);

  const [isExsitingImgBackground, setIsExsitingImgBackground] = useState(false);

  const handleFillPreLivestream = useCallback(() => {
    dispatch(
      duplicatePreLivestream((resp) => {
        if (resp) {
          setIsFill(true);
          setValue('title', resp?.live_title);
          resp?.live_title && clearErrors('title');
          setValue('description', resp?.live_description);
          resp?.live_description && clearErrors('description');
          clearErrors('image');
          const decryptImg = decryptPath(resp?.img_background);
          if (decryptImg && decryptImg !== 'NULL') {
            setIsExsitingImgBackground(true);
          }

          setPreview(`${decryptImg}`);
          setValue('image', decryptImg);

          const dataPackage = resp?.packages
            .filter((item) => item !== undefined)
            .map((obj) => obj.id);
          if (dataPackage?.length) {
            setCanSelectPackage(true);
            setListPackageSelected(dataPackage);
            dispatch(getListPackage());
          } else {
            setValue('packages', []);
            dispatch(setEmptyPackageAction());
          }
          if (resp?.user_live?.length > 0) {
            setCanSelectUser(true);
            setListUserSelected(
              resp?.user_live?.map((item) => ({
                label: item?.account_name || item?.full_name,
                value: item?.id,
              }))
            );
          }

          if (resp?.all_follow === 1) {
            setAllFollowState(true);
          }
          if (resp?.all_member === 1) {
            setAllMemberState(true);
          }

          if (resp?.record_flag === 1) {
            setRecordingLive(true);
          }

          setValue('total_live_time', resp?.live_time > '02:00' ? '02:00' : resp?.live_time);
          if (resp?.ngWords?.length > 0) {
            setListNgSetting(resp?.ngWords[0]);
          }
          setChecked(resp?.obs_flag === 1);
        } else {
          setIsFill(true);
          setIsHaveDataPre(true);
        }
      })
    );
  }, []);

  const [defaultValueTotalLiveTime, setDefaultValueTotalLiveTime] = useState(
    selectOptionTimeLiveCreate[0]
  );

  useEffect(() => {
    const listUser = listUserDataSelector?.map((item) => {
      return {
        label: item?.account_name || item?.full_name,
        value: item?.id,
      };
    });
    if (listUser?.length === LIMIT_PAGE) {
      setParamsSearchUser((prev) => ({
        ...prev,
        maxPage: prev.maxPage + 1,
      }));
    }
    if (paramsSearchUser.page !== 1 && optionsUser?.length > 0) {
      setOptionsUser(optionsUser?.concat(listUser));
    } else {
      setOptionsUser(listUser || []);
    }
  }, [listUserDataSelector]);

  const onAllowMember = (e) => {
    const isAllow = e.target.checked;
    setCanSelectUser(isAllow);

    if (isAllow === true) {
    } else {
      setValue('users', []);
    }
  };

  const onAllowRecordingLive = (e) => {
    const isAllow = e.target.checked;
    setRecordingLive(isAllow);
  };

  const [justLoad, setJustLoad] = useState(false);

  useEffect(() => {
    if (dataLiveStream && liveKey && !justLoad) {
      setJustLoad(true);
      dispatch(getListPackage());
      const dataPackage = dataLiveStream?.packages
        .filter((item) => item !== undefined)
        .map((obj) => obj.id);

      if (dataPackage?.length > 0) {
        setCanSelectPackage(true);
        setListPackageSelected(dataPackage);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLiveStream]);

  useEffect(() => {
    if (dataLiveStream && liveKey) {
      setChecked(dataLiveStream?.obs_flag === 1);
      setValue('title', dataLiveStream?.live_title);
      setValue('description', dataLiveStream?.live_description);
      const deImg = decryptPath(dataLiveStream?.img_background);
      if (deImg && deImg !== 'NULL') {
        setPreview(`${deImg}`);
        setValue('image', deImg);
      }

      if (dataLiveStream?.user_live?.length > 0) {
        dataLiveStream?.user_live.forEach((element) => {
          initialValues.users.push(element.id);
        });
      }

      if (dataLiveStream?.ngWords?.length > 0) {
        setListNgSetting(dataLiveStream?.ngWords[0]);
      }

      const formatDateLive = convertToCurrentTime(dataLiveStream?.live_date, 'YYYY-MM-DD');
      setValue('date_live', formatDateLive);

      onValueDate(formatDateLive);

      const formatDateLiveByHourMinute = convertToCurrentTime(
        dataLiveStream?.live_date,
        'HH:mm'
      );

      setTimePost(formatDateLiveByHourMinute);
      setValue('hour_minute', formatDateLiveByHourMinute);

      setValue('total_live_time', dataLiveStream?.live_time);

      const resultDefaultValueTotalLiveTime = selectOptionTimeLiveCreate.find(
        ({ value }) => value === dataLiveStream?.live_time
      );
      setDefaultValueTotalLiveTime(resultDefaultValueTotalLiveTime);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLiveStream]);

  useEffect(() => {
    if (dataLiveStream && liveKey) {
      if (dataLiveStream?.user_live?.length > 0) {
        setCanSelectUser(true);
        setListUserSelected(
          dataLiveStream?.user_live?.map((item) => ({
            label: item?.account_name || item?.full_name,
            value: item?.id,
          }))
        );
      }

      if (dataLiveStream?.all_follow === 1) {
        setAllFollowState(true);
      }

      if (dataLiveStream?.all_member === 1) {
        setAllMemberState(true);
      }

      if (dataLiveStream?.record_flag === 1) {
        setRecordingLive(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLiveStream]);

  useEffect(() => {
    if (livesCreateData) {
      if (livesCreateData?.live_key && livesCreateData?.live_key !== '') {
        const {
          created_at,
          live_description,
          updated_at,
          creator_id,
          slug_name,
          img_background,
          ...newObj
        } = livesCreateData;
        // eslint-disable-next-line react-hooks/rules-of-hooks
        updateData(`/lives/${livesCreateData?.live_key}/config`, newObj).then((_res) => {
          navigate(
            `${newPathUser}live/review/${livesCreateData?.id}/${livesCreateData?.live_key}`
          );
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [livesCreateData]);

  //!
  // status :
  // 1 : Pending
  // 2 : Chuan bi phat
  // 3 : Live
  // 4 : Pause
  // 5 : Ket Thuc

  // is_actived :
  // 1 : Active
  // 2 : Draf

  const addZero = (i) => {
    if (i < 10) {
      i = '0' + i;
    }
    return i;
  };

  const onSubmit = async (data) => {
    // setDisableButtonWhenClick(true);
    const gValuesImage = getValues('image');
    if (typeof gValuesImage !== 'string') {
      if (gValuesImage !== null || gValuesImage !== undefined) {
        if (!isFill && gValuesImage && !getValues('image')?.type?.match(/jpg|jpeg|png/g)) {
          pushNotify('error', t('ValidateMsg.C090_VALIDATE_IMAGE'), 'image');
          return;
        }
      }
    }

    const d = new Date();
    const currentDate = moment(d).format('YYYY-MM-DD');
    const currentTime = moment(d).format('HH:mm');
    data.date_live = date;
    if (data.date_live === undefined || data.date_live === '') {
      data.date_live = null;
    } else {
      data?.date_live?.split('/').join('-');
      const cv = `${data.date_live} ${timePost ? timePost : currentTime}:00`;
      data.date_live = moment(cv).utc().format('YYYY-MM-DD');
    }

    if (!timePost) {
      data.hour_minute = null;
    } else {
      data?.date_live?.split('/').join('-');
      const cv = `${data.date_live ? data.date_live : currentDate} ${timePost}:00`;
      data.hour_minute = moment(cv).utc().format('HH:mm');
    }

    if (timePost) {
      if (data.date_live) {
        if (new Date() > new Date(`${data.date_live} ${timePost}`)) {
          setDisableButtonWhenClick(false);
          pushNotify('error', t('Creator.CreateLiveStream.pleaseSelectDistributionSchedule'));
          return;
        }
      } else {
        const onlyYMD = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
        if (new Date() > new Date(`${onlyYMD} ${timePost}`)) {
          setDisableButtonWhenClick(false);
          pushNotify('error', t('Creator.CreateLiveStream.pleaseSelectDistributionSchedule'));
          return;
        }
      }
    } else {
      if (data.date_live) {
        const h = addZero(d.getHours());
        const m = addZero(d.getMinutes());
        const s = addZero(d.getSeconds());
        const newTime = `${h}:${m}:${s}`;
        if (new Date() > new Date(`${data.date_live} ${newTime}`)) {
          setDisableButtonWhenClick(false);
          pushNotify('error', t('Creator.CreateLiveStream.pleaseSelectDistributionSchedule'));
          return;
        }
      }
    }

    if (canSelectPackage) {
      data.packages = listPackageSelected;
    } else {
      delete data.packages;
    }
    const finalUsers = listUserSelected.map(function (obj) {
      return obj.value;
    });
    data.users = finalUsers;
    if (!data.users?.length) {
      if (!canSelectUser) {
        delete data.users;
      }
    } else {
    }

    if (!canSelectUser) {
      delete data.users;
    }

    let linkDB = '';
    if (selectedFile) {
      const backImg = setLinkSaveDB('images', 'livestream');
      let name = backImg.split('/');
      const path = `${name[0]}/${name[1]}/${name[2]}/${name[3]}`;
      await getPresignedUrlS3('image', path, name[4] || '').then((resUrl) => {
        linkDB = resUrl.data.file_path;
        uploadS3ByPresigned(resUrl?.data?.pre_signed, data?.image);
      });
    } else {
      linkDB = dataLiveStream?.img_background;
    }
    data.all_follow = allFollowState ? 1 : 0;
    data.all_member = allMemberState ? 1 : 0;
    data.status = 1;
    data.record_flag = recordingLive ? 1 : 0;
    let newData = {
      ...data,
      ng_words: listNgSetting?.toString(),
      live_time: data.total_live_time,
      total_hour_live: +data.total_live_time?.split(':')[0],
      total_minute_live: +data.total_live_time?.split(':')[1] || 0,
      img_background: linkDB || (isFill && dataPreLivestream?.img_background),
      imageWidth,
      obs_flag: checked ? 1 : 0,
    };
    if (typeof newData.image === 'string') {
      newData.image = null;
    }

    if (preview === undefined || preview === null) {
      newData.img_background = 'NULL';
    }
    if (dataLiveStream && liveKey !== undefined) {
      //! UPDATE LIVE
      newData.live_key = dataLiveStream?.live_key;
      setIsSubmitEdit(true);
      return dispatch(
        updateLiveStream(
          {
            ...newData,
            id,
          },
          '',
          () => {
            setDisableButtonWhenClick(false);
            dispatch(resetListLiveStream());
          }
        )
      );
    } else {
      return dispatch(
        creatLiveStream(newData, isFill ? false : true, () => {
          setDisableButtonWhenClick(false);
          dispatch(resetListLiveStream());
        })
      );
    }
  };

  const validateTotalHourLive = (value, key = '') => {
    const currentFormValues = getValues();
    if (
      value === 0 &&
      (currentFormValues[key] === 0 || currentFormValues[key] === undefined)
    ) {
      setError('total_live_time', {
        type: 'required',
        message: t('Creator.CreateLiveStream.totalLiveStream'),
      });
    } else {
      clearErrors('total_live_time');
    }
  };

  const [listPackageSelected, setListPackageSelected] = useState([]);

  // handle listPackage
  const handleOnchangePackage = (event) => {
    let updatedList = [...listPackageSelected];
    const { checked } = event.target;
    if (checked) {
      updatedList = [...listPackageSelected, parseInt(event.target.value)];
    } else {
      updatedList.splice(listPackageSelected.indexOf(parseInt(event.target.value)), 1);
    }
    setListPackageSelected(updatedList);
  };
  const optionsObs = [
    {
      value: false,
      label: t('Common.buttonNormalDelivery'),
    },
    {
      value: true,
      label: t('Creator.CreateLiveStream.streamingUsingOBS'),
    },
  ];

  const setSearchValue = lodash.debounce(function (keyword) {
    setParamsSearchUser({
      maxPage: 2,
      page: 1,
      keyword,
    });
  }, 500);

  const previewDecode = useMemo(() => {
    return decryptPath(preview);
  }, [preview]);

  const handleEnter = (e) => {
    if (e.keyIdentifier === 'U+000A' || e.keyIdentifier === 'Enter' || e.keyCode === 13) {
      e.preventDefault();
      return false;
    }
  };

  useEffect(() => {
    const isValidContent = watchDes?.trim() !== '';
    if (isValidContent) {
      const idPost = checkLinkPostSale(watchDes);
      if (idPost) {
        setIdPostMeta(idPost);
      } else {
        setIdPostMeta(null);
        setPostDetailMeta(null);
      }
    } else {
      setPostDetailMeta(null);
      setIdPostMeta(null);
    }
  }, [watchDes]);

  useEffect(() => {
    if (idPostMeta) {
      dispatch(
        getInfoMetaPost(idPostMeta, (dataMeta) => {
          setPostDetailMeta(dataMeta);
        })
      );
    } else {
      setPostDetailMeta(null);
    }
  }, [idPostMeta, dispatch]);

  return (
    <>
      {liveStreamState.loadingCreate && <PopupLoading className={'popup-loading'} />}
      <LayoutCreatorRight
        classNameHeader={`${liveKey ? 'show-back-to-page' : ''} `}
        titlePage={
          id && liveKey ? t('Common.editLiveStreaming') : t('Common.liveStreamingList')
        }
        className={'creator-live-list-page'}>
        <div className='container-fluid bg-white creator-create-live'>
          {!liveKey && (
            <div className='create-live-list-page-header'>
              <button
                className='create-live-list-page-header_btn'
                type='button'
                onClick={() => {
                  handleFillPreLivestream();
                }}>
                <CopyIcon color='white' alt='ic-copy' />
                {t('Creator.CreateLiveStream.duplication')}
              </button>
              <div className='create-live-list-page-header_text'>
                {t('Creator.CreateLiveStream.noteDuplicateLive')}
              </div>
            </div>
          )}
          <div className='card-body border-0'>
            <div className='card-block'>
              <form>
                <InputWrapper
                  className='card-block-item'
                  id='fsite-live-stream-title'
                  label={t('Creator.CreateLiveStream.enterTitle')}
                  required
                  error={errors?.title ? errors?.title?.message : ''}>
                  <input
                    type='text'
                    onKeyDown={(e) => handleEnter(e)}
                    placeholder={t('Creator.CreateLiveStream.enterTitle')}
                    className='card-block-item_input'
                    maxLength={50}
                    defaultValue={dataLiveStream?.live_title}
                    //onKeyPress={handleChangeWhiteSpace}
                    // onChange={(e)=> handleChangeWhiteSpace(e)}
                    name='title'
                    {...register('title', {
                      required: 'ValidateMsg.TITLE_REQUIRED',
                      validate: {
                        noFirstSpacing: (value) =>
                          (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                          'ValidateMsg.TITLE_REQUIRED',
                      },
                    })}
                  />
                </InputWrapper>
                <InputWrapper
                  className='card-block-item'
                  id='fsite-live-stream-description'
                  label={t('Creator.CreateLiveStream.inputDescription')}
                  required
                  error={errors?.description ? errors?.description?.message : ''}>
                  <div
                    className={`wrapper-content-ogp ${
                      postDetailMeta?.title ? 'have-meta' : ''
                    }`}>
                    <input
                      type='text'
                      onKeyDown={(e) => handleEnter(e)}
                      placeholder={t('Creator.CreateLiveStream.inputDescription')}
                      className='card-block-item_input'
                      name='description'
                      maxLength={500}
                      defaultValue={dataLiveStream?.live_description}
                      {...register('description', {
                        required: 'ValidateMsg.DESCRIPTION_REQUIRED',
                        validate: {
                          noFirstSpacing: (value) =>
                            (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                            'ValidateMsg.DESCRIPTION_REQUIRED',
                        },
                      })}
                      onBlur={(e) => {
                        setValue('description', e.target.value.trim());
                        if (!e.target.value.trim()) {
                          setError('description', {
                            type: 'required',
                            message: 'ValidateMsg.DESCRIPTION_REQUIRED',
                          });
                        }
                      }}
                    />
                    {postDetailMeta?.title && (
                      <MetaTagViewer
                        title={postDetailMeta?.title}
                        url={postDetailMeta?.url}
                        thumbnail={postDetailMeta?.thumbnail}
                      />
                    )}
                  </div>
                </InputWrapper>
                <InputWrapper id='fsite-thumbnail-img'>
                  {dataLiveStream && liveKey && (
                    <Controller
                      name='image'
                      control={control}
                      {...register(
                        'image',
                        !preview
                          ? {
                              required: false, // t('ValidateMsg.IMAGE_IS_REQUIRED'),
                            }
                          : { required: false }
                      )}
                      render={({ field }) => (
                        <>
                          {!dataLiveStream && !liveKey && (
                            <>
                              {!selectedFile && (
                                <label
                                  ref={refThumbnail}
                                  style={{ backgroundColor: '#F7F8FA', cursor: 'pointer' }}
                                  htmlFor='image'
                                  className='thumbnail border-thumbnail thumbnail-label rounded-6 d-flex text-center align-items-center justify-content-center'>
                                  <strong>
                                    <ImgCustom
                                      width={52}
                                      height={52}
                                      src={IconImageCreatorCreateLiveV2}
                                      alt=''
                                    />
                                    <p
                                      className='thumbnail-des'
                                      style={{
                                        marginTop: '20px',
                                        fontFamily: 'Noto Sans JP',
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        color: '#666565',
                                      }}>
                                      {t('Creator.CreateLiveStream.selectThumbnailImage')}{' '}
                                      <br />
                                      <span style={{ textAlign: 'center', fontSize: '12px' }}>
                                        {t('Creator.CreateLiveStream.theSizeIsNotRecommended')}
                                      </span>
                                    </p>
                                  </strong>
                                </label>
                              )}
                            </>
                          )}

                          <input
                            type='file'
                            id='image'
                            name='image'
                            className='d-none'
                            accept='image/png, image/jpeg , images/jpg'
                            // ref={field.ref}
                            ref={labelFileRef}
                            onChange={(e) => {
                              onChangeFile(e);
                            }}
                          />
                          {previewDecode && previewDecode !== 'NULL' && (
                            <div
                              className='thumbnail-container thumbnail rounded-6'
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                              }}>
                              <BlueImg
                                url={`${
                                  selectedFile
                                    ? previewDecode
                                    : `${previewDecode}?width=640&height=auto`
                                }`}>
                                <div className='bg-img'></div>
                                {selectedFile ? (
                                  <ImgCustom
                                    src={preview}
                                    id='rmn-edit-916'
                                    className='thumbnail-img cover-img'
                                    alt={preview}
                                    onError={(e) =>
                                      (e.target.onerror = null)(
                                        (e.target.src = NO_IMAGE_LIVESTREAM)
                                      )
                                    }
                                  />
                                ) : (
                                  <ImgCustom
                                    screen='post_775_720_pc'
                                    src={preview}
                                    id='rmn-edit-916'
                                    className='thumbnail-img cover-img'
                                    alt={preview}
                                    onError={(e) =>
                                      (e.target.onerror = null)(
                                        (e.target.src = NO_IMAGE_LIVESTREAM)
                                      )
                                    }
                                  />
                                )}

                                <CrossIcon
                                  className='thumbnail-cross-icon'
                                  onClick={onRemoveImage}
                                />
                              </BlueImg>
                            </div>
                          )}

                          {!preview && (
                            <label
                              style={{ backgroundColor: '#F7F8FA', cursor: 'pointer' }}
                              htmlFor='image'
                              className='thumbnail border-thumbnail thumbnail-label rounded-6 d-flex text-center align-items-center justify-content-center'>
                              <strong>
                                <ImgCustom
                                  width={52}
                                  height={52}
                                  src={IconImageCreatorCreateLiveV2}
                                  alt=''
                                />
                                <p
                                  className='thumbnail-des'
                                  style={{
                                    marginTop: '20px',
                                    fontFamily: 'Noto Sans JP',
                                    fontWeight: 400,
                                    fontSize: '12px',
                                    color: '#666565',
                                  }}>
                                  {t('Creator.CreateLiveStream.selectThumbnailImage')}
                                  <br />
                                  <span style={{ textAlign: 'center', fontSize: '12px' }}>
                                    {t('Creator.CreateLiveStream.theSizeIsNotRecommended')}
                                  </span>
                                </p>
                              </strong>
                            </label>
                          )}
                        </>
                      )}
                    />
                  )}

                  {!dataLiveStream && !liveKey && (
                    <Controller
                      name='image'
                      control={control}
                      {...register('image', {
                        required: false, // t('ValidateMsg.IMAGE_IS_REQUIRED'),
                      })}
                      render={({ field }) => (
                        <>
                          {!dataLiveStream && !liveKey && !isFill && (
                            <>
                              {!selectedFile && (
                                <label
                                  ref={refThumbnail}
                                  htmlFor='image'
                                  // style={`--valueHeight: ${heightThumbnail}`}
                                  className='thumbnail border-thumbnail thumbnail-label rounded-6 d-flex text-center align-items-center justify-content-center'>
                                  <strong>
                                    <ImgCustom
                                      width={52}
                                      height={52}
                                      src={IconImageCreatorCreateLiveV2}
                                      alt=''
                                    />
                                    <p
                                      className='thumbnail-des'
                                      style={{
                                        marginTop: '20px',
                                        lineHeight: '22px',
                                        fontFamily: 'Noto Sans JP',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        fontSize: '12px',
                                        color: '#666565',
                                      }}>
                                      {t('Creator.CreateLiveStream.selectThumbnailImage')}
                                      <br />
                                      <span style={{ textAlign: 'center', fontSize: '12px' }}>
                                        {t('Creator.CreateLiveStream.theSizeIsNotRecommended')}
                                      </span>
                                    </p>
                                  </strong>
                                </label>
                              )}
                            </>
                          )}

                          <input
                            type='file'
                            id='image'
                            name='image'
                            className='d-none'
                            accept='image/png, image/jpeg , images/jpg'
                            // ref={field.ref}
                            ref={labelFileRef}
                            onChange={(e) => {
                              onChangeFile(e);
                            }}
                          />
                          {(selectedFile ||
                            (decryptPath(dataLiveStream?.img_background) &&
                              decryptPath(dataLiveStream?.img_background) !== 'NULL') ||
                            isFill) && (
                            <>
                              {isExsitingImgBackground ? (
                                <div
                                  className='thumbnail-container thumbnail rounded-6'
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    backgroundColor: 'white',
                                    position: 'relative',
                                    width: '100%',
                                    aspectRatio: '1.33333',
                                  }}>
                                  <BlueImg
                                    url={`${
                                      selectedFile
                                        ? previewDecode
                                        : `${previewDecode}?width=32&height=auto`
                                    }`}>
                                    <div className='bg-img'></div>
                                    {isSafari ? (
                                      <img
                                        id='rmn1016'
                                        src={decryptPath(preview)}
                                        className='thumbnail-img cover-img'
                                        alt={preview}
                                      />
                                    ) : (
                                      <ImgCustom
                                        screen={`${selectedFile ? null : 'post_775_720_pc'}`}
                                        id='rmn1016'
                                        src={preview}
                                        className='thumbnail-img cover-img'
                                        alt={preview}
                                      />
                                    )}

                                    <CrossIcon
                                      className='thumbnail-cross-icon'
                                      onClick={onRemoveImage}
                                    />
                                  </BlueImg>
                                </div>
                              ) : (
                                <label
                                  // style={`--valueHeight: ${heightThumbnail}`}
                                  htmlFor='image'
                                  className='thumbnail border-thumbnail thumbnail-label rounded-6 d-flex text-center align-items-center justify-content-center'>
                                  <strong>
                                    <ImgCustom
                                      width={52}
                                      height={52}
                                      src={IconImageCreatorCreateLiveV2}
                                      alt=''
                                    />
                                    <p
                                      className='thumbnail-des'
                                      style={{
                                        marginTop: '20px',
                                        lineHeight: '22px',
                                        fontFamily: 'Noto Sans JP',
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        color: '#666565',
                                      }}>
                                      {t('Creator.CreateLiveStream.selectThumbnailImage')}{' '}
                                      <br />
                                      <span style={{ textAlign: 'center', fontSize: '12px' }}>
                                        {t('Creator.CreateLiveStream.theSizeIsNotRecommended')}
                                      </span>
                                    </p>
                                  </strong>
                                </label>
                              )}
                            </>
                          )}
                        </>
                      )}
                    />
                  )}
                </InputWrapper>
                <InputWrapper
                  className=''
                  id=''
                  label=''
                  error={
                    errors?.image ? errors?.image?.message || errors?.image.required : ''
                  }></InputWrapper>

                <InputWrapper
                  className='creator-cr-live card-block-item_format'
                  id='fsite-folk-can-view'
                  required
                  label={t('Creator.CreateLiveStream.destinationSettings')}>
                  <div className='d-flex w-md-25 w-100 justify-content-md-start justify-content-between mt-3'>
                    <div className='form-group d-flex align-items-center'>
                      {dataLiveStream && liveKey !== undefined && (
                        <input
                          type='checkbox'
                          id='packagesXXX'
                          className='form-check-input mt-0'
                          name='packagesXXX'
                          checked={canSelectPackage ? true : false}
                          onChange={(event) => {
                            const isCheck = event.target.checked;
                            setCanSelectPackage(isCheck);
                            if (!isCheck) {
                              setListPackageSelected([]);
                            } else {
                              dispatch(getListPackage());
                            }
                          }}
                        />
                      )}

                      {!dataLiveStream && !liveKey && (
                        <input
                          type='checkbox'
                          id='packages'
                          className='form-check-input mt-0'
                          name='packages'
                          checked={canSelectPackage}
                          onChange={onGetListPackage}
                        />
                      )}

                      <label
                        htmlFor={!dataLiveStream && !liveKey ? `packages` : 'packagesXXX'}
                        className='px-2'>
                        {t('Common.subscription')}
                      </label>
                    </div>
                    <div className='form-group d-flex align-items-center ms-4'>
                      <input
                        type='checkbox'
                        id='all_follow'
                        className='form-check-input mt-0'
                        name='all_follow'
                        onChange={getEventCheckCheckBox}
                        checked={allFollowState ? true : false}
                        // {...register('all_follow')}
                      />
                      <label htmlFor='all_follow' className='px-2'>
                        {t('Creator.CreateLiveStream.follower')}
                      </label>
                    </div>
                    <div className='form-group d-flex align-items-center ms-4'>
                      <input
                        type='checkbox'
                        id='all_member'
                        className='form-check-input mt-0'
                        name='all_member'
                        onChange={getEventCheckCheckBox}
                        checked={allMemberState ? true : false}
                        // {...register('all_member')}
                      />
                      <label htmlFor='all_member' className='px-2'>
                        {t('Creator.CreateLiveStream.everyone')}
                      </label>
                    </div>
                  </div>
                  {canSelectPackage && (
                    <div
                      className='subscribers-area-live w-100 mt-4 rounded-6'
                      style={{ overflow: 'hidden' }}>
                      <div className='row p-2 flex-wrap'>
                        {liveStreamState.loadingPackage ? (
                          <div
                            className='spinner-border text-danger m-auto text-center'
                            role='status'></div>
                        ) : null}
                        {!liveStreamState.loadingPackage &&
                          listPackage?.map((pack, index) => (
                            <div
                              className={`col-6 col-md-4 form-group d-flex align-items-center checkox-wrap`}
                              key={index}>
                              <input
                                type='checkbox'
                                id={index}
                                value={pack.id}
                                defaultChecked={
                                  listPackageSelected.includes(pack.id) ? true : false
                                }
                                className='form-check-input'
                                onChange={handleOnchangePackage}
                              />
                              <label htmlFor={index}>&nbsp;&nbsp;{pack.title}</label>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </InputWrapper>
                <InputWrapper
                  id='any-individual'
                  // error={
                  //   canSelectUser && errors?.description ? errors?.description?.message : '' }
                >
                  <>
                    <div className='form-group d-flex align-items-center mb-2'>
                      {dataLiveStream && liveKey ? (
                        <input
                          type='checkbox'
                          id='users'
                          className='form-check-input mt-0'
                          // defaultChecked={canSelectUser ? true : false}
                          checked={canSelectUser ? true : false}
                          onChange={onAllowMember}
                        />
                      ) : (
                        <input
                          type='checkbox'
                          id='users'
                          className='form-check-input mt-0'
                          checked={canSelectUser}
                          onChange={onAllowMember}
                        />
                      )}

                      <label htmlFor='users' className='px-2'>
                        {t('ProfileSetting.anyInvidual')}
                      </label>
                    </div>
                    <div
                      className={`list-user-selected-wrapper border-none ${
                        canSelectUser && 'approve'
                      }`}>
                      {canSelectUser ? (
                        <Controller
                          name='list_user'
                          control={control}
                          render={({ field }) => (
                            <Select
                              isMulti
                              hideSelectedOptions={false}
                              closeMenuOnSelect={false}
                              menuPlacement='bottom'
                              placeholder={t('ProfileSetting.enterToSearch')}
                              options={optionsUser}
                              defaultInputValue={listUserSelected}
                              value={listUserSelected}
                              inputValue={inputUser}
                              captureMenuScroll={(e) => {}}
                              onMenuScrollToBottom={() => {
                                setParamsSearchUser((prev) => {
                                  if (prev.maxPage === prev.page) {
                                    return prev;
                                  }
                                  return {
                                    ...prev,
                                    page: prev.page + 1,
                                  };
                                });
                              }}
                              onInputChange={(keyword, action) => {
                                if (action.action !== 'set-value') {
                                  setSearchValue(keyword);
                                }
                                setInputUser(keyword);
                              }}
                              ref={field.ref}
                              onChange={(val) => {
                                field.onChange(val);
                                setListUserSelected(val);
                              }}
                              styles={{
                                ...customStylesReselect,
                                menu: (base, state) => ({
                                  ...base,
                                  border: 'none !important',
                                  borderRadius: '8px',
                                  overflow: 'hidden',
                                  zIndex: 2,
                                  width: 'auto',
                                  boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.06)',
                                }),
                                valueContainer: (base) => ({
                                  ...base,
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                  height: 44,
                                  minHeight: 44,
                                  overflow: 'auto',
                                  '::-webkit-scrollbar': {
                                    width: '3px',
                                    height: '0px',
                                  },
                                  '::-webkit-scrollbar-track': {
                                    background: 'transparent',
                                  },
                                  '::-webkit-scrollbar-thumb': {
                                    background: '#d9d9d9',
                                  },
                                }),
                              }}
                              components={{
                                Option: CustomOption,
                                DropdownIndicator: CustomDropdownIndicator,
                              }}
                            />
                          )}
                        />
                      ) : (
                        <div className='list-user'>
                          <span className='d-middle' style={{ color: '#b1b6bb' }}>
                            {t('ProfileSetting.enterToSearch')}
                          </span>
                        </div>
                      )}
                      {/* <PopupC90SelectUser
                        isDisabled={canSelectUser}
                        handleListSelectedUser={handleListSelectedUser}
                        listUserSelected={listUserSelected}
                      /> */}
                    </div>
                  </>
                </InputWrapper>
                <InputWrapper
                  id='fsite-hours-setting'
                  label={t('Creator.CreateLiveStream.deliveryTimeSetting')}
                  error={errors?.total_live_time ? errors?.total_live_time?.message : ''}>
                  {dataLiveStream && liveKey && (
                    <Controller
                      name='total_live_time'
                      control={control}
                      // rules={{ required: 'Please select minutes' }}
                      render={({ field }) => (
                        <Select
                          menuPlacement='bottom'
                          options={selectOptionTimeLiveCreate}
                          defaultValue={selectOptionTimeLiveCreate.find(
                            ({ value }) => value === dataLiveStream?.live_time
                          )}
                          ref={field.ref}
                          onChange={(val) => {
                            validateTotalHourLive(val.value, 'total_hour_live');
                            field.onChange(val.value);
                          }}
                          // placeholder='00'
                          styles={customStylesReselect}
                          // components={{
                          //   DropdownIndicator: withDropdownIndicator(MinuteIndicator),
                          // }}
                          components={{
                            Option: CustomOption,
                            DropdownIndicator: CustomDropdownIndicator,
                          }}
                        />
                      )}
                    />
                  )}
                  {!dataLiveStream && !liveKey && (
                    <Controller
                      name='total_live_time'
                      control={control}
                      // rules={{ required: 'Please select minutes' }}
                      render={({ field }) => (
                        <Select
                          menuPlacement='bottom'
                          options={selectOptionTimeLiveCreate}
                          defaultValue={selectOptionTimeLiveCreate[0]}
                          ref={field.ref}
                          value={selectOptionTimeLiveCreate.find(
                            ({ value }) => value === field.value
                          )}
                          onChange={(val) => {
                            validateTotalHourLive(val.value, 'total_hour_live');
                            field.onChange(val.value);
                          }}
                          styles={customStylesReselect}
                          components={{
                            Option: CustomOption,
                            DropdownIndicator: CustomDropdownIndicator,
                          }}
                        />
                      )}
                    />
                  )}
                </InputWrapper>
                <InputWrapper
                  id='fsite-schedule'
                  label={t('Creator.CreateLiveStream.deliverySchedule')}
                  error={
                    errors?.date_live
                      ? errors?.date_live?.message
                      : errors?.hour_minute
                      ? errors?.hour_minute?.message
                      : ''
                  }>
                  <div className='row'>
                    <div className='col-7 col-sm-9' style={{ position: 'relative' }}>
                      <div className='date'>
                        <CalendarIconV2
                          className='creator-lvstream-create'
                          onClick={() => {
                            toggleCalendar();
                          }}
                          color={isVisibleCalendar ? '#DC143C' : '#666565'}
                        />
                        {isVisibleCalendar && (
                          <ReactCalendar
                            customPosition={'cal-creator-live'}
                            defaultValue={moment().toDate()}
                            value={date ? moment(date, 'YYYY/MM/DD').toDate() : ''}
                            onChange={onChangeDate}
                            inputRef={datePickerRef}
                            toggleCalendar={toggleCalendar}
                          />
                        )}
                        {date && (
                          <CloseIcon
                            className='close-icon'
                            onClick={() => {
                              onChangeDate(null);
                              onValueDate(null);
                              setIsVisibleCalendar(false);
                            }}
                          />
                        )}
                        <input
                          placeholder='YYYY/MM/DD'
                          {...register('date_live')}
                          name='date_live'
                          value={date ? date : ''}
                          readOnly
                          style={{ cursor: 'pointer', border: '1px solid #E5E6EB' }}
                          className='fsite-input input-at-livestream-creator'
                          onClick={toggleCalendar}
                        />
                      </div>
                    </div>
                    <div
                      className='col-5 col-sm-3 card-item-time'
                      style={{ position: 'relative' }}>
                      <Controller
                        render={({ field }) => (
                          <>
                            <CustomSelectTime
                              placeholder='--:--'
                              showIconV2
                              style={{ border: '1px solid #E5E6EB' }}
                              classTextBox='fsite-input input-at-livestream-creator'
                              classIcon='creator-lvstream-create time-icon'
                              initTime={dataLiveStream && liveKey ? timePost : ''}
                              hiddenDefaulvalue
                              timePost={(e) => {
                                field.onChange(e);
                                setTimePost(e);
                              }}
                              checkDisabled={true}
                              closeIcon
                            />
                          </>
                        )}
                        name='hour_minute'
                        control={control}
                        // rules={{ required: 'Please select hours and minutes' }}
                      />
                    </div>
                  </div>
                </InputWrapper>
                <div className='form-group d-flex align-items-center mb-3'>
                  <input
                    type='checkbox'
                    id='record_flag'
                    className='form-check-input mt-0'
                    checked={recordingLive ? true : false}
                    onChange={onAllowRecordingLive}
                  />
                  <label htmlFor='record_flag' className='px-2'>
                    {t('Creator.CreateLiveStream.recordingLive')}
                  </label>
                </div>
                <PopupNgSetting
                  dataSetting={listNgSetting}
                  handleAddNgSetting={(e) => setListNgSetting(e)}
                />

                {/* <LiveObs /> */}
                <div className='form-group align-items-center mb-4 mt-3'>
                  {/* <input
                  type='checkbox'
                  id='obs'
                  className='form-check-input mt-0'
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                /> */}

                  <div className='px-2 mb-2'>
                    {t('Common.buttonDistributionSoftwareSetting')}
                  </div>
                  <Controller
                    name='checked_obs'
                    control={control}
                    render={({ field }) => (
                      <Select
                        menuPlacement='bottom'
                        options={optionsObs}
                        defaultValue={optionsObs[0]}
                        ref={field.ref}
                        value={optionsObs.find(({ value }) => value === checked)}
                        isSearchable={false}
                        onChange={(val) => {
                          setChecked(val.value);
                          field.onChange(val.value);
                        }}
                        styles={{
                          ...customStylesReselect,
                          menu: (base, state) => ({
                            ...base,
                            border: 'none !important',
                            borderRadius: '8px',
                            overflow: 'hidden',
                            zIndex: 2,
                            width: 'auto',
                            boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.06)',
                          }),
                        }}
                        components={{
                          Option: CustomOption,
                          DropdownIndicator: CustomDropdownIndicator,
                        }}
                      />
                    )}
                  />

                  {/* <label htmlFor='obs' className='px-2'>
                  {t('Creator.CreateLiveStream.streamingUsingOBS')}
                </label> */}
                </div>
                <InputWrapper
                  // label={
                  //   !liveKey || (liveKey && dataLiveStream?.is_password === 1)
                  //     ? t('Common.password')
                  //     : ''
                  // }
                  className='card-block-item'
                  label={t('Common.password')}>
                  <div
                    style={{
                      position: 'relative',
                    }}>
                    {!liveKey ? (
                      <>
                        <input
                          {...register('password', {})}
                          autoComplete='new-password'
                          onKeyDown={(e) => handleEnter(e)}
                          className='fsite-input card-block-item_input'
                          placeholder={t('Creator.CreateLiveStream.placeholderPass')}
                          type={`${hiddenPassword ? 'password' : 'text'}`}
                          maxLength={255}
                        />
                        <div
                          style={{
                            position: 'absolute',
                            right: '12px',
                            top: '8px',
                          }}>
                          {hiddenPassword ? (
                            <EyeV2Icon onClick={() => setHiddenPassword(false)} />
                          ) : (
                            <HiddenV2Icon onClick={() => setHiddenPassword(true)} />
                          )}
                        </div>
                      </>
                    ) : (
                      <input
                        className='fsite-input'
                        type='password'
                        onKeyDown={(e) => handleEnter(e)}
                        disabled
                        value={dataLiveStream?.is_password === 1 ? '********' : ''}
                      />
                    )}
                  </div>
                  <div className='text-next'>
                    {t('Creator.CreateLiveStream.textUnderPass')}
                  </div>
                </InputWrapper>
                <InputWrapper id='fsite-submit'>
                  <ButtonSubmit
                    className={`w-100 btn text-white fw-bold btn_submit ${
                      isDisabledSubmit === true ? 'button-form-disabled-bg' : ''
                    }`}
                    disabled={isDisabledSubmit || disableButtonWhenClick}
                    type='submit'
                    onClick={handleSubmit(onSubmit)}>
                    {t('Creator.CreateLiveStream.next')}
                  </ButtonSubmit>
                </InputWrapper>
              </form>
            </div>
          </div>
        </div>
      </LayoutCreatorRight>
      {isHaveDataPre && (
        <PopupConfirm
          closeModal={setIsHaveDataPre}
          title={t('Popup.noSettingLive')}
          isWarning
        />
      )}
    </>
  );
};

export default withCreatorRole(CreateLiveStream);
