/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';

const initialState = {
  status: null,
  loading: false,
  error: null,
  bankAccount: {},
};
export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_INFOR_BANK_ACCOUNT_REQUEST:
    case TYPES.PUT_INFOR_BANK_ACCOUNT_REQUEST:
      return {
        ...state,
        loading: true,
        status: null,
      };
    case TYPES.GET_INFOR_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        bankAccount: action?.data?.data,
      };
    case TYPES.PUT_INFOR_BANK_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        status: true,
      };
    case TYPES.GET_INFOR_BANK_ACCOUNT_FAILED:
    case TYPES.PUT_INFOR_BANK_ACCOUNT_FAILED:
      return {
        ...state,
        loading: false,
        status: action.type,
      };

    case 'RESET_ACTION_BANK_ACCOUNT':
      return {
        ...state,
        loading: false,
        status: null,
      };

    default:
      return state;
  }
};
