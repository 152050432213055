export default function Rank1(props) {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g clip-path='url(#clip0_3083_7707)'>
        <path
          d='M7.2668 16.6484L6.31533 16.6948L4.47062 21.9948C4.41183 22.1636 4.54862 22.3362 4.72641 22.3176L7.31218 22.0468C7.38441 22.0393 7.45623 22.0643 7.50816 22.115L9.36699 23.9328C9.49477 24.0578 9.70918 24.0074 9.76791 23.8386L11.6247 18.504L11.0772 17.9726L7.2668 16.6484Z'
          fill='#FF4755'
        />
        <path
          d='M11.6248 18.501L12 17.4231L6.68949 15.6172L6.31543 16.6919C7.8234 17.7679 9.64927 18.4266 11.6248 18.501Z'
          fill='#FC2B3A'
        />
        <path
          d='M16.7331 16.6484L17.6845 16.6948L19.5293 21.9948C19.588 22.1636 19.4513 22.3362 19.2735 22.3176L16.6877 22.0468C16.6155 22.0393 16.5437 22.0643 16.4918 22.115L14.6329 23.9328C14.5052 24.0578 14.2907 24.0074 14.232 23.8386L12.3752 18.504L12.9227 17.9726L16.7331 16.6484Z'
          fill='#FF4755'
        />
        <path
          d='M12.3752 18.501L12 17.4231L17.3105 15.6172L17.6845 16.6919C16.1766 17.7679 14.3507 18.4266 12.3752 18.501Z'
          fill='#FC2B3A'
        />
        <path
          d='M11.9999 16.8804C3.28812 16.8804 3.29177 8.41814 3.29899 8.27269C3.52769 3.66534 7.33577 0 11.9999 0C16.6641 0 20.4722 3.66534 20.7009 8.27273C20.7081 8.41819 20.7117 16.8804 11.9999 16.8804Z'
          fill='#FFE27A'
        />
        <path
          d='M11.9999 16.5462C7.33574 16.5462 3.5278 12.8808 3.29905 8.27344C3.29184 8.41889 3.28809 8.56528 3.28809 8.71252C3.28809 13.5239 7.18851 17.4243 11.9999 17.4243C16.8113 17.4243 20.7117 13.5239 20.7117 8.71252C20.7117 8.56523 20.708 8.41889 20.7007 8.27344C20.472 12.8808 16.6641 16.5462 11.9999 16.5462Z'
          fill='#F9CF58'
        />
        <path
          d='M12 15.0074C5.15075 15.0074 5.14156 8.27228 5.15075 8.12706C5.3772 4.54131 8.35714 1.70312 12 1.70312C15.6428 1.70312 18.6228 4.54131 18.8492 8.12706C18.8584 8.27223 18.8492 15.0074 12 15.0074Z'
          fill='#FFBA57'
        />
        <path
          d='M11.9998 14.6974C8.35698 14.6974 5.37756 11.8591 5.15111 8.27344C5.14192 8.41866 5.13672 8.565 5.13672 8.71252C5.13672 12.5029 8.20942 15.5756 11.9998 15.5756C15.7901 15.5756 18.8628 12.5029 18.8628 8.71252C18.8628 8.56495 18.8576 8.41866 18.8485 8.27344C18.622 11.8591 15.6426 14.6974 11.9998 14.6974Z'
          fill='#FFAC3E'
        />
        <path
          d='M12.3914 12.7861C11.9149 12.7861 11.5286 12.3998 11.5286 11.9233V11.467V7.08808C11.5286 7.08808 11.0124 7.60581 10.3947 7.29517C9.91309 7.05297 9.91309 6.42958 9.91309 6.42958L10.9768 4.63398C11.1322 4.37177 11.4144 4.21094 11.7191 4.21094H12.3914C12.8679 4.21094 13.2541 4.59719 13.2541 5.07372V11.467V11.9233C13.2541 12.3998 12.8679 12.7861 12.3914 12.7861Z'
          fill='#FFE27A'
        />
        <path
          d='M11.41 6.41419C11.181 6.80077 10.6941 6.9684 10.288 6.77597C10.1237 6.69811 9.99695 6.57713 9.91272 6.43425C9.67314 6.83954 9.80284 7.36093 10.2017 7.60725C10.6131 7.86132 11.1628 7.70963 11.4092 7.29361L11.5285 7.0921V6.44658C11.5285 6.3818 11.443 6.35841 11.41 6.41419Z'
          fill='#F9CF58'
        />
        <path
          d='M12.3913 12.3315C11.9149 12.3315 11.5286 11.9453 11.5286 11.4688V12.347C11.5286 12.8235 11.9149 13.2097 12.3913 13.2097C12.8678 13.2097 13.2541 12.8235 13.2541 12.347V11.4688C13.2541 11.9453 12.8678 12.3315 12.3913 12.3315Z'
          fill='#F9CF58'
        />
      </g>
      <defs>
        <clipPath id='clip0_3083_7707'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
