export default function ProfileCameraIcon(props) {
  return (
    <svg width={52} height={52} viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_2989_85797)">
        <circle cx={26} cy={24} r={18} fill="#F7F8FA" />
        <path d="M33.5 17.25H30.9008L29.6235 15.334C29.555 15.2312 29.4622 15.147 29.3533 15.0887C29.2445 15.0305 29.1229 15 28.9995 15H22.9995C22.876 15 22.7544 15.0305 22.6456 15.0887C22.5367 15.147 22.4439 15.2312 22.3754 15.334L21.0981 17.25H18.5C17.9035 17.2507 17.3316 17.488 16.9098 17.9098C16.488 18.3316 16.2507 18.9035 16.25 19.5V30C16.2507 30.5965 16.488 31.1684 16.9098 31.5902C17.3316 32.012 17.9035 32.2493 18.5 32.25H33.5C34.0965 32.2493 34.6684 32.012 35.0902 31.5902C35.512 31.1684 35.7493 30.5965 35.75 30V19.5C35.7493 18.9035 35.512 18.3316 35.0902 17.9098C34.6684 17.488 34.0965 17.2507 33.5 17.25ZM29.375 24.375C29.375 25.0425 29.1771 25.695 28.8062 26.25C28.4354 26.8051 27.9083 27.2376 27.2916 27.4931C26.6749 27.7485 25.9963 27.8154 25.3416 27.6852C24.6869 27.5549 24.0855 27.2335 23.6135 26.7615C23.1415 26.2895 22.8201 25.6881 22.6898 25.0334C22.5596 24.3787 22.6265 23.7001 22.8819 23.0834C23.1374 22.4667 23.5699 21.9396 24.125 21.5688C24.68 21.1979 25.3325 21 26 21C26.8948 21.001 27.7527 21.3569 28.3854 21.9896C29.0181 22.6223 29.374 23.4802 29.375 24.375Z" fill="#DC143C" />
      </g>
      <defs>
        <filter id="filter0_d_2989_85797" x={0} y={0} width={52} height={52} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={4} />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2989_85797" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2989_85797" result="shape" />
        </filter>
      </defs>
    </svg>

  );
}
