import { PopupFanDetail, ImgCustom, LayoutCreatorRight, PopupLoading } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import withCreatorRole from 'hocs/withCreatorRole';
import NoFollow from 'images/NoFollow.svg';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearScrollBackFollower,
  followOrUnFollowUser,
  setIdScrollBackFollowing,
  userFollowing,
} from 'store/actions/users';
import { useTranslation } from 'react-i18next';
import './index.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { newPathUser } from 'constants/layout/constant';
import DefaultAvatar from 'images/DefaultAvatar.svg';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { useLayoutEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { getUserInfoOthers, resetIDHomePage } from 'store/actions/usersNew';
import { useGetIdFromRecommendUser } from 'hooks/v2/useGetIdFromRecommendUser';

const FollowPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { idFromRecommendUser, clearIdScrollBackRecommend } = useGetIdFromRecommendUser();
  const [isVisible, setIsVisible] = useState(false);
  const [id, setId] = useState(null);
  const [idFan, seIdFan] = useState(false);

  const location = useLocation();
  const itemsPerPage = 20;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [hoverId, setHoverId] = useState(false);
  const { account_id } = useParams();

  const {
    userListFollowing = [],
    loading,
    paginationFollowing: pagination,
    dataUser,
    idScrollBackFollowing,
    isLoadingFollow,
  } = useSelector((state) => state.users);
  const [page, setPage] = useState(
    pagination?.current_page ? +pagination?.current_page + 1 : 1
  );
  const { dataInfoOther } = useSelector((state) => state.usersNew);

  const fetchNext = () => {
    const pages = pagination?.total / pagination?.per_page;
    if (pages > page) {
      if (idScrollBackFollowing || idFromRecommendUser) {
        dispatch(setIdScrollBackFollowing());
        clearIdScrollBackRecommend();
      } else {
        setPage(page + 1);
      }
    } else if (pages + 1 > page) {
      if (idScrollBackFollowing || idFromRecommendUser) {
        dispatch(setIdScrollBackFollowing());
        clearIdScrollBackRecommend();
      }
    }
  };

  useEffect(() => {
    if (!idScrollBackFollowing && !idFromRecommendUser) {
      dispatch(getUserInfoOthers(account_id));
    }
  }, [account_id, dispatch, idScrollBackFollowing, idFromRecommendUser]);

  useLayoutEffect(() => {
    if (
      (location.state?.IDUSER ||
        dataUser?.account_id ||
        location?.pathname?.split('/').pop()) &&
      !idScrollBackFollowing &&
      !idFromRecommendUser
    ) {
      setIsLoading(true);
      dispatch(
        userFollowing(
          location?.pathname?.split('/').pop() ||
            location.state?.IDUSER ||
            dataUser?.account_id,
          page,
          itemsPerPage,
          () => setIsLoading(false)
        )
      );
    }
  }, [
    page,
    idScrollBackFollowing,
    location.state?.IDUSER,
    location?.pathname,
    dataUser?.account_id,
    idFromRecommendUser,
    dispatch,
  ]);

  useEffect(() => {
    if (idScrollBackFollowing) {
      const idPost = document.getElementById(idScrollBackFollowing);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
    return () => {
      clearIdScrollBackRecommend();
    };
  }, [idScrollBackFollowing]);

  const handleFollowOrUnfollow = (id, flg) => {
    setId(id);
    dispatch(followOrUnFollowUser(id, flg));
  };

  return (
    <>
      {isLoadingFollow && <PopupLoading className={'popup-loading'} />}
      {isVisible && (
        <PopupFanDetail
          closeModal={() => setIsVisible(false)}
          isVisible={isVisible}
          id={idFan}
        />
      )}
      <LayoutCreatorRight
        titlePage={t('Follow.title')}
        className='follow-header'
        classNameHeader='container-header'>
        <div className='follow-follower-page'>
          <div className='menu'>
            <div className={`menu-item active`} onClick={() => {}}>
              {t('Follow.menu_name')}
            </div>
            <div
              className={`menu-item`}
              disable={isLoading}
              onClick={() => {
                if (!isLoading) {
                  dispatch(clearScrollBackFollower());
                  clearIdScrollBackRecommend();
                  dispatch(
                    setIdScrollBackFollowing(`id-menu`, () =>
                      navigate(`${newPathUser}follower-list/${account_id}`, {
                        state: {
                          IDUSER: account_id,
                        },
                      })
                    )
                  );
                }
              }}>
              {t('Follower.menu_name')}
            </div>
          </div>
        </div>
        <div className='tab-content'>
          <div className='follow-page-creator'>
            <InfiniteLoad
              loading={loading}
              data={userListFollowing}
              fetchNext={fetchNext}
              className='div_block'>
              <div className='list-follow-creator'>
                {isLoading && !userListFollowing.length ? (
                  <div className='shimmer-loading'>
                    <ShimmerPostDetails hasImage imageType='circular' title />
                  </div>
                ) : (
                  <>
                    {userListFollowing.length > 0 ? (
                      userListFollowing.map((item) => (
                        <div
                          id={`following-${item?.id}`}
                          className={`account-follow account ${
                            id === item?.id && dataInfoOther?.id === dataUser?.id && 'hidden'
                          }`}
                          key={item?.id}>
                          <div
                            className={`content-left`}
                            onClick={() => {
                              if (id !== item?.id) {
                                if (item?.user_type === 3) {
                                  setIsVisible(true);
                                  seIdFan(item.id);
                                } else {
                                  dispatch(resetIDHomePage());
                                  dispatch(
                                    setIdScrollBackFollowing(`following-${item?.id}`, () => {
                                      navigate(`${newPathUser}${item?.account_id}`);
                                    })
                                  );
                                }
                              }
                            }}>
                            {item?.avatar ? (
                              <ImgCustom
                                screen={item?.avatar ? 'recommend_top_56_56' : ''}
                                src={item?.avatar ? `${item?.avatar}` : DefaultAvatar}
                                alt='avatar'
                              />
                            ) : (
                              <img src={DefaultAvatar} alt={item?.id} />
                            )}
                            <div>
                              <span className='name'>
                                {item?.account_name ||
                                  item?.full_name ||
                                  t('Creator.accountNoName')}
                              </span>

                              <span className='list-sub'>
                                {item?.packages?.map((x) => (
                                  <div className='item-list-sub' key={x?.id}>
                                    {x.title}
                                  </div>
                                ))}
                              </span>
                            </div>
                          </div>
                          {item?.account_id !== dataUser?.account_id && (
                            <div
                              className={`btn-followOrUnFollow ${
                                item?.is_followed === 1 ? 'following' : 'no-follow'
                              } ${item?.id === id ? 'loading-spin' : ''}`}
                              onClick={() =>
                                handleFollowOrUnfollow(
                                  item.id,
                                  dataInfoOther?.id === dataUser?.id
                                )
                              }
                              onMouseOver={() => {
                                if (item?.is_followed === 1 && item?.id) {
                                  !isMobile && setHoverId(item.id);
                                }
                              }}
                              onMouseLeave={() => {
                                if (item?.is_followed === 1) {
                                  setHoverId(false);
                                }
                              }}>
                              {item?.is_followed === 1
                                ? hoverId === item?.id
                                  ? t('Creator.FollowList.unfollow')
                                  : t('Creator.FollowList.following')
                                : t('Creator.FollowList.followUs')}
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      <div className='no-data'>
                        <img src={NoFollow} alt='No Follow' width={100} height={100} />
                        {t('Follow.listNull')}
                      </div>
                    )}
                  </>
                )}
              </div>
            </InfiniteLoad>
          </div>
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(FollowPage);
