import { getEnv } from 'configs/env';
import {
  configParamsGet,
  configParamsPostFormData,
  configParamsPUT,
  ENV,
  updateStatus,
} from '../../utils/constant';
import { callApi } from '../../utils/request';
import { TYPES } from './index';

const API = {
  creatorGetTicketDetail: (id) => `${ENV}/tickets/${id}`,
  creatorPostPremiumTicket: () => `${ENV}/tickets`,
  creatorEditPremiumTicket: (id) => `${ENV}/tickets/${id}`,
};

const creatorGetTicketDetail = (id, handleSetInitial) => {
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_GET_DETAIL_PREMIUM_TICKET_REQUEST));
    callApi(
      API.creatorGetTicketDetail(id),
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CREATOR_GET_DETAIL_PREMIUM_TICKET_SUCCESS, {
            data: response,
          })
        );
        handleSetInitial && handleSetInitial(response);
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.CREATOR_GET_DETAIL_PREMIUM_TICKET_FAILED, { error: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const creatorPostPremiumTicket = (data, handleSuccess) => {
  const { condition_month, package_id, expired_month } = data;
  const formData = new FormData();
  formData.append('package_id', +package_id);
  formData.append('condition_month', +condition_month);
  // formData.append('expired_month', expired_month);
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_POST_PREMIUM_TICKET_REQUEST));
    callApi(
      API.creatorPostPremiumTicket(),
      configParamsPostFormData(formData),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CREATOR_POST_PREMIUM_TICKET_SUCCESS, {
            data: response.data,
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.CREATOR_POST_PREMIUM_TICKET_FAILED, { err: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
        window.location.href = `/not-found`;
      }
    );
  };
};

const creatorEditPremiumTicket = (id, data, handleSuccess, handleConfirm) => {
  const params = {
    ...data,
    ticket_id: +id,
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.CREATOR_POST_PREMIUM_TICKET_REQUEST));
    callApi(
      API.creatorEditPremiumTicket(id),
      configParamsPUT(params),
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.CREATOR_POST_PREMIUM_TICKET_SUCCESS, {
            data: response.data,
          })
        );
        handleSuccess && handleSuccess(response);
        handleConfirm && handleConfirm();
      },
      (error) => {
        dispatch(
          updateStatus(TYPES.CREATOR_POST_PREMIUM_TICKET_FAILED, { err: error.errors })
        );
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

export { creatorGetTicketDetail, creatorPostPremiumTicket, creatorEditPremiumTicket };
