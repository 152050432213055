import { TYPES } from 'store/actions';
import { ENV, callApi, configParamsGet, queryString, updateStatus } from 'utils';
import Storage from '../../utils/storage';

const getDataRanking = (params, handleSuccess, handleFailed) => {
  const url = `${ENV}/top-posts?${queryString(params)}`;
  return (dispatch) => {
    dispatch(updateStatus(TYPES.GET_DATA_RANKING_PAGE_REQUEST));
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.GET_DATA_RANKING_PAGE_SUCCESS, {
            data: {
              list: response?.data,
              page: params?.page,
              pagination: response?.pagination,
            },
          })
        );
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleFailed && handleFailed();
        dispatch(updateStatus(TYPES.GET_DATA_RANKING_PAGE_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const followUserRanking = (id, handleSuccess, handleFailed) => {
  const url = `${ENV}/users/${id}/following`;
  const configParams = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${Storage.get('USER_ACCESS_TOKEN')}`,
    },
  };
  return (dispatch) => {
    dispatch(updateStatus(TYPES.FOLLOW_USER_RANKING_REQUEST));
    callApi(
      url,
      configParams,
      null,
      async (response) => {
        await dispatch(
          updateStatus(TYPES.FOLLOW_USER_RANKING_SUCCESS, {
            data: {
              id,
            },
          })
        );
        handleSuccess && handleSuccess(id);
      },
      (error) => {
        handleFailed && handleFailed();
        dispatch(updateStatus(TYPES.FOLLOW_USER_RANKING_FAILED, { error: error.errors }));
        dispatch(updateStatus(TYPES.LIST_ERROR, { error: error.errors }));
      }
    );
  };
};

const setDataItemRankingFromId = (id, type, value) => {
  return (dispatch) => {
    if (type === 'follow') {
      dispatch(
        updateStatus(TYPES.FOLLOW_USER_RANKING_SUCCESS, {
          data: {
            id,
          },
        })
      );
    } else if (type === 'like') {
      dispatch(
        updateStatus(TYPES.SET_COUNT_LIKE_POST_RANKING_SUCCESS, {
          data: {
            id,
            is_liked: value,
            number_like: value === 1 ? 1 : -1
          },
        })
      );
    } else if (type === 'comment') {
      dispatch(
        updateStatus(TYPES.SET_COUNT_COMMENT_RANKING_SUCCESS, {
          data: {
            id,
            number_comment: value
          },
        })
      );
    }
  };
};

const byPackageFromRankingSUCCESS = (id) => {
  return (dispatch) => {
    dispatch(
      updateStatus('BY_PACKAGE_FROM_RANKING_SUCCESS', {
        data: {
          id,
        },
      })
    );
  };
};

const clearListRanking = () => {
  return (dispatch) => {
    dispatch(updateStatus('CLEAR_LIST_RANKING'));
  };
};

const setIdScrollBack = (id, cb) => {
  return (dispatch) => {
    dispatch(
      updateStatus('SET_ID_SCROLL_BACK_RANKING', {
        data: {
          id,
        },
      })
    );
    cb && cb();
  };
};

export {
  followUserRanking,
  getDataRanking,
  byPackageFromRankingSUCCESS,
  clearListRanking,
  setIdScrollBack,
  setDataItemRankingFromId,
};
