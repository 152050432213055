import { ArrowLeftIcon, CalendarIcon, CloseIcon, MenuIcon, SearchIcon } from 'images';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LOGO_COCO_FANS, STORAGE } from 'utils/constant';
import Storage from 'utils/storage';
import { ImgCustom, Tooltip2 } from '..';
import Avatar from '../../images/Avatar.png';
import AccountSidebar from '../Account/AccountSidebar';
import { isAdmin as IS_ADMIN, setOverflowTablet } from 'utils/utils';
import MenuSidebarCustom from '../MenuSidebarCustom';
import { newPathUser } from 'constants/layout/constant';

const Header = ({
  titleHeader,
  isAdmin = false,
  navigateCreator = false,
  accountId = '',
  avatarHeader,
  page,
}) => {
  const [isLogin, setIsLogin] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [isVisibleMenu, setIsVisbleMenu] = useState(false);
  const widthScreen = window.innerWidth;
  const user = useSelector((state) => state.users.data);
  const navigate = useNavigate();
  const location = useLocation();
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  useEffect(() => {
    widthScreen <= 991 && setIsMobileScreen(true);
    if (Storage.get(STORAGE.USER_ACCESS_TOKEN)) setIsLogin(true);
  }, [widthScreen]);

  useEffect(() => {
    if (isVisibleMenu) {
      document.getElementById('root').style.overflow = 'hidden';
    } else {
      setOverflowTablet();
    }
  }, [isVisibleMenu]);

  const goBack = () => {
    //window.scrollTo(0, 0);
    navigate(-1);
  };

  const width = document?.querySelector('.layout-content .layout-left')?.offsetWidth;
  const widthContentRight = document?.querySelector('.content-right.has-avatar')?.offsetWidth;

  return (
    <div
      style={{ height: 60, position: 'relative', zIndex: 24, background: 'white' }}
      className={`${isLogin ? 'd-flex' : 'd-none'}`}>
      <div className='box-fansite-header'>
        <div
          className={`fansite-header ${isLogin && 'loggined '} ${
            isMobileScreen && 'mobileScreen'
          }`}>
          {isLogin && (
            <>
              {isMobileScreen ? (
                <>
                  <div className='left'>
                    <MenuIcon onClick={() => setIsVisbleMenu(true)} />
                  </div>
                  <div className='center'>
                    <div className={`content-right ${avatarHeader && 'has-avatar'}`}>
                      {avatarHeader ? (
                        <>
                          <div className='infor-creator'>
                            <div
                              className='name'
                              data-width={widthContentRight}
                              style={{ maxWidth: `calc(${widthContentRight}px - 80px)` }}>
                              {titleHeader}
                            </div>
                            <ImgCustom
                              src={avatarHeader?.type || avatarHeader || Avatar}
                              alt=''
                              className={`${isAdmin && 'avatar_admin'}`}
                              style={{ cursor: !IS_ADMIN && !isAdmin ? 'pointer' : 'auto' }}
                              onClick={() =>
                                !isAdmin &&
                                navigateCreator &&
                                accountId &&
                                !IS_ADMIN &&
                                navigate(`${newPathUser}${accountId}`)
                              }
                            />
                          </div>
                        </>
                      ) : (
                        <div className={`title ${page ? page : ''}`}>{titleHeader}</div>
                      )}
                    </div>
                  </div>
                  <div className='content-right'>
                    {!IS_ADMIN && (
                      <SearchIcon
                        onClick={() => {
                          navigate('/search-type');
                        }}
                      />
                    )}

                    {!IS_ADMIN ? (
                      <CalendarIcon onClick={() => navigate('/calendar')} />
                    ) : (
                      <div style={{ pointerEvents: 'none', cursor: 'auto' }}>
                        <CalendarIcon onClick={() => navigate('/calendar')} />
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  {/* pc */}
                  <div className='left'>
                    <Link to={isAuth ? `${newPathUser}` : '/'} reloadDocument>
                      <ImgCustom
                        src={LOGO_COCO_FANS}
                        height={32}
                        width={94}
                        alt='logo'
                        className='logo-cocofans'
                      />
                    </Link>
                    {!IS_ADMIN && (
                      <SearchIcon
                        style={{ marginLeft: 27 }}
                        onClick={() => navigate('/search-type')}
                      />
                    )}
                  </div>
                  <div className={`content-right ${avatarHeader && 'has-avatar'}`}>
                    {avatarHeader ? (
                      <>
                        <div className='infor-creator' style={{ width: width }}>
                          <ArrowLeftIcon
                            onClick={goBack}
                            className={`back-icon ${
                              location.pathname === '/my-page' && 'd-none'
                            }`}
                          />
                          <div className='name'>{titleHeader}</div>
                          <ImgCustom
                            src={avatarHeader?.type || avatarHeader || Avatar}
                            alt=''
                            className={`${isAdmin && 'avatar_admin'}`}
                            style={{ cursor: !IS_ADMIN && !isAdmin ? 'pointer' : 'auto' }}
                            onClick={() =>
                              !isAdmin &&
                              navigateCreator &&
                              accountId &&
                              !IS_ADMIN &&
                              navigate(`${newPathUser}${accountId}`)
                            }
                          />
                        </div>
                        <div className='space'>null</div>
                      </>
                    ) : (
                      <>
                        <div className='title'>
                          <ArrowLeftIcon
                            onClick={goBack}
                            className={`back-icon ${
                              location.pathname === '/my-page' && 'd-none'
                            }`}
                          />
                          {page === 'F017' ? (
                            <Tooltip2
                              className={'title-header'}
                              text={titleHeader}
                              style={{ width: width }}>
                              {titleHeader}
                            </Tooltip2>
                          ) : (
                            <span className='title-text'>{titleHeader}</span>
                          )}
                        </div>
                        <div className='space'>null</div>
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {isVisibleMenu && (
          <div className='box-modal'>
            <div className='menu-mobile'>
              <AccountSidebar user={user}>
                <CloseIcon
                  style={{ cursor: 'pointer', zIndex: 10 }}
                  className='close-drawer'
                  onClick={() => setIsVisbleMenu(false)}
                />
              </AccountSidebar>
              <MenuSidebarCustom />
            </div>
            <div className='gray-screen' onClick={() => setIsVisbleMenu(false)}></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
