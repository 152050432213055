import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PopupLoading } from '..';
import useUserRole from '../../hooks/useUserRole';
import { newPathUser } from 'constants/layout/constant';
import { useNavigate } from 'react-router-dom';
import { isTablet } from 'react-device-detect';

const PopupDownloadVideo = ({ loadVideo }) => {
  const navigate = useNavigate();
  useEffect(() => {
    document.querySelector('#root').style.overflow = 'hidden';
    document.querySelector('#root').style.padding = '0';
  }, []);
  const { isFan } = useUserRole();
  const { t } = useTranslation();
  return (
    <>
      {loadVideo && <PopupLoading className={'popup-loading-down'} />}
      <div className={`popup-block ${!isFan && 'creator'}`} />
      <div
        style={{ background: 'transparent', width: `${isTablet ? '580px' : 'auto'}`, top: "58%"}}
        className={`popup-block_content`}>
        <>
          <div style={{}} className='download-video'>
            <svg
              width={20}
              height={20}
              viewBox='0 0 20 20'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <rect width={20} height={20} fill='white' fillOpacity='0.01' />
              <g clipPath='url(#clip0_12505_638517)'>
                <path
                  d='M10 2C5.58214 2 2 5.58214 2 10C2 14.4179 5.58214 18 10 18C14.4179 18 18 14.4179 18 10C18 5.58214 14.4179 2 10 2ZM10 16.6429C6.33214 16.6429 3.35714 13.6679 3.35714 10C3.35714 6.33214 6.33214 3.35714 10 3.35714C13.6679 3.35714 16.6429 6.33214 16.6429 10C16.6429 13.6679 13.6679 16.6429 10 16.6429Z'
                  fill='white'
                />
                <path
                  d='M9.14258 13.1429C9.14258 13.3702 9.23288 13.5882 9.39363 13.7489C9.55438 13.9097 9.77239 14 9.99972 14C10.227 14 10.4451 13.9097 10.6058 13.7489C10.7666 13.5882 10.8569 13.3702 10.8569 13.1429C10.8569 12.9155 10.7666 12.6975 10.6058 12.5368C10.4451 12.376 10.227 12.2857 9.99972 12.2857C9.77239 12.2857 9.55438 12.376 9.39363 12.5368C9.23288 12.6975 9.14258 12.9155 9.14258 13.1429ZM9.57115 11.1429H10.4283C10.5069 11.1429 10.5711 11.0786 10.5711 11V6.14286C10.5711 6.06429 10.5069 6 10.4283 6H9.57115C9.49258 6 9.42829 6.06429 9.42829 6.14286V11C9.42829 11.0786 9.49258 11.1429 9.57115 11.1429Z'
                  fill='white'
                />
              </g>
              <defs>
                <clipPath id='clip0_12505_638517'>
                  <rect width={16} height={16} fill='white' transform='translate(2 2)' />
                </clipPath>
              </defs>
            </svg>
            <span>{t('Popup.textDownload')}</span>
          </div>
        </>
      </div>
    </>
  );
};

export default PopupDownloadVideo;
