import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import {
  ControlBar,
  CurrentTimeDisplay,
  Player,
  TimeDivider,
  VolumeMenuButton,
} from 'video-react';
import 'video-react/dist/video-react.css';
import { linkS3Video, decryptPath, isAdmin } from '../../utils/utils';

import './index.scss';
import { useSelector } from 'react-redux';
import FieldChanged from 'components/FieldChanged/FieldChanged';
function VideoDetail(
  { link, src, background_video, customVideo = false, handleRef, ...props },
  ref
) {
  const videoRef = useRef();
  useEffect(() => {
    customVideo && videoRef && handleRef(videoRef);
  }, [customVideo, handleRef, videoRef]);

  useImperativeHandle(ref, () => ({
    pauseVideo() {
      videoRef.current.pause();
    },
  }));

  const { detailPost } = useSelector((state) => state.usersNew);

  return (
    <div className={`${props.className}`}>
      <Player {...props} playsInline poster={background_video && `${background_video}`} ref={videoRef}>
        {isAdmin &&
          (detailPost?.medias_changed?.video === 1 ||
            detailPost?.medias_changed?.thumbnail === 1) && <FieldChanged top='10px' />}
        <source src={link ? `${linkS3Video}${decryptPath(link) + '#t=0.1'}` : src} />
        {/* <ControlBar>
          <CurrentTimeDisplay order={4.1} />
          <TimeDivider order={4.2} />
          <VolumeMenuButton />
        </ControlBar> */}
      </Player>
    </div>
  );
}

export default forwardRef(VideoDetail);
