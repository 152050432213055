import Icon from '@ant-design/icons';

const MuteIconAct = (props) => (
  <Icon
    component={() => (
      <svg
        width={88}
        height={88}
        viewBox='0 0 88 88'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g filter='url(#filter0_d_2021_73925)'>
          <rect x={24} y={20} width={40} height={40} rx={20} fill='white' fillOpacity='0.3' />
          <path
            d='M46.1497 31.9384C46.0446 31.8871 45.9272 31.8663 45.8109 31.8783C45.6946 31.8904 45.584 31.9349 45.4917 32.0068L40.0352 36.25H36.5C36.1686 36.2503 35.8508 36.3821 35.6165 36.6164C35.3821 36.8508 35.2503 37.1685 35.25 37.5V42.5C35.2503 42.8314 35.3821 43.1491 35.6165 43.3835C35.8508 43.6178 36.1686 43.7496 36.5 43.75H40.0352L45.4917 47.9931C45.584 48.065 45.6946 48.1095 45.811 48.1216C45.9273 48.1337 46.0448 48.1128 46.1498 48.0614C46.2549 48.01 46.3435 47.9301 46.4054 47.8309C46.4673 47.7316 46.5001 47.617 46.5 47.5V32.5C46.5001 32.3829 46.4673 32.2683 46.4053 32.169C46.3434 32.0697 46.2548 31.9898 46.1497 31.9384V31.9384Z'
            fill='white'
          />
          <path
            d='M49 37.5C48.8342 37.5 48.6753 37.5659 48.5581 37.6831C48.4409 37.8003 48.375 37.9592 48.375 38.125V41.875C48.375 42.0408 48.4408 42.1997 48.5581 42.3169C48.6753 42.4342 48.8342 42.5 49 42.5C49.1658 42.5 49.3247 42.4342 49.4419 42.3169C49.5592 42.1997 49.625 42.0408 49.625 41.875V38.125C49.625 37.9592 49.5591 37.8003 49.4419 37.6831C49.3247 37.5659 49.1658 37.5 49 37.5V37.5Z'
            fill='white'
          />
          <path
            d='M51.5 36.25C51.3342 36.25 51.1753 36.3159 51.0581 36.4331C50.9409 36.5503 50.875 36.7092 50.875 36.875V43.125C50.875 43.2908 50.9408 43.4497 51.0581 43.5669C51.1753 43.6842 51.3342 43.75 51.5 43.75C51.6658 43.75 51.8247 43.6842 51.9419 43.5669C52.0592 43.4497 52.125 43.2908 52.125 43.125V36.875C52.125 36.7092 52.0591 36.5503 51.9419 36.4331C51.8247 36.3159 51.6658 36.25 51.5 36.25V36.25Z'
            fill='white'
          />
        </g>
        <defs>
          <filter
            id='filter0_d_2021_73925'
            x={0}
            y={0}
            width={88}
            height={88}
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'>
            <feFlood floodOpacity={0} result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy={4} />
            <feGaussianBlur stdDeviation={12} />
            <feComposite in2='hardAlpha' operator='out' />
            <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0' />
            <feBlend
              mode='multiply'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_2021_73925'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_2021_73925'
              result='shape'
            />
          </filter>
        </defs>
      </svg>
    )}
    {...props}
  />
);

export default MuteIconAct;
