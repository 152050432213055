import { useTranslation } from 'react-i18next';
import { LayoutCenter } from '../../../components';
import { LAW, LAW_EN } from '../constant';
import './styles.scss';

const LawScreen = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  return (
    <>
    {i18n.language === 'en' ? (
      <LayoutCenter
        titlePage={t('Common.law')}
        notLogin={true}
        isShowLeftIcon={false}
        uiMobile={true}
        classNameBack='white_space-normal'
        className={'title-page-creator page-legal'}>
        <div className={`container-fluid w-100 bg-white law-page creator`}>
          {LAW_EN.map((item, index) => (
            <div>
              <div className='title bold'>{item?.title}</div>
              <div className={`value ${item.boldValue ? 'bold' : ''}`}>
                {typeof item?.value === 'string'
                  ? item?.value
                  : item?.value.map((x) => (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>{x.title}</div>
                        <div>{x.value}</div>
                      </div>
                    ))}
              </div>
            </div>
          ))}
        </div>
      </LayoutCenter>
    ) : (
      <LayoutCenter
        titlePage={t('Common.law')}
        notLogin={true}
        isShowLeftIcon={false}
        uiMobile={true}
        classNameBack='white_space-normal'
        className={'title-page-creator page-legal'}>
        <div className={`container-fluid w-100 bg-white law-page creator`}>
          {LAW.map((item, index) => (
            <div>
              <div className='title bold'>{item?.title}</div>
              <div className={`value ${item.boldValue ? 'bold' : ''}`}>
                {typeof item?.value === 'string'
                  ? item?.value
                  : item?.value.map((x) => (
                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>{x.title}</div>
                        <div>{x.value}</div>
                      </div>
                    ))}
              </div>
            </div>
          ))}
        </div>
      </LayoutCenter>
      )}
    </>
  );
};

export default LawScreen;
