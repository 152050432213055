export default function UploadImageIconV2(props) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        d='M16.875 3.75H3.125C2.77982 3.75 2.5 4.02982 2.5 4.375V15.625C2.5 15.9702 2.77982 16.25 3.125 16.25H16.875C17.2202 16.25 17.5 15.9702 17.5 15.625V4.375C17.5 4.02982 17.2202 3.75 16.875 3.75Z'
        stroke='#1A1A1A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M2.5 13.1239L6.43305 9.19087C6.49108 9.13283 6.55998 9.0868 6.63581 9.05539C6.71164 9.02398 6.79291 9.00781 6.87499 9.00781C6.95706 9.00781 7.03834 9.02398 7.11417 9.05539C7.18999 9.0868 7.25889 9.13283 7.31693 9.19087L10.808 12.682C10.8661 12.74 10.935 12.7861 11.0108 12.8175C11.0866 12.8489 11.1679 12.865 11.25 12.865C11.3321 12.865 11.4133 12.8489 11.4892 12.8175C11.565 12.7861 11.6339 12.74 11.6919 12.682L13.308 11.0659C13.3661 11.0078 13.435 10.9618 13.5108 10.9304C13.5866 10.899 13.6679 10.8828 13.75 10.8828C13.8321 10.8828 13.9133 10.899 13.9892 10.9304C14.065 10.9618 14.1339 11.0078 14.1919 11.0659L17.5 14.3739'
        stroke='#1A1A1A'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.1875 8.59375C12.619 8.59375 12.9688 8.24397 12.9688 7.8125C12.9688 7.38103 12.619 7.03125 12.1875 7.03125C11.756 7.03125 11.4062 7.38103 11.4062 7.8125C11.4062 8.24397 11.756 8.59375 12.1875 8.59375Z'
        fill={props?.color || '#1A1A1A'}
      />
    </svg>
  );
}
