import styled, { css } from 'styled-components';

export const ListIMG = {
  BlurImg: styled.div`
    background: url(${({ url }) => "'" + url + "'"});
    height: ${({ height }) => height}px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    /* Add the blur effect */
    filter: blur(20px);
    -webkit-filter: blur(20px);
  `,
  BlueImg: styled.div`
  background: url(${({ url }) => "'" + url + "'"});
  height: ${({ height }) => height}px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  border-radius: 8px;
  @media screen and (max-width: 575px) {
    border-radius: revert;
    height: 130px;
  }
  .bg-img {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 9;
    background-size: cover;
    min-height: 100%;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    &.br-8 {
      border-radius: 8px;
      @media screen and (max-width: 575px) {
        border-radius: revert;
      }
    }
  }
`,
};