export default function UploadImageIcon(props) {
  return (
    <svg
      width='52'
      height='52'
      viewBox='0 0 52 52'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
      >
      <mask
        id='mask0_1442_185834'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='52'
        height='52'>
        <rect width='52' height='52' rx='8' fill='#FFECF3' />
      </mask>
      <g mask='url(#mask0_1442_185834)'>
        <rect width='52' height='52' rx='8' fill='#FCECEF' />
        <rect
          x='12.5758'
          y='26'
          width='52'
          height='52'
          rx='8'
          transform='rotate(44.9205 12.5758 26)'
          fill='#F8D0D8'
        />
        <rect
          x='26.5731'
          y='18.5703'
          width='52'
          height='52'
          rx='8'
          transform='rotate(26.0777 26.5731 18.5703)'
          fill='#DC143C'
        />
        <rect x='9.28546' y='9.28906' width='9' height='9' rx='4.5' fill='#DC143C' />
      </g>
    </svg>
  );
}
