import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import './index.scss';

import { newPathUser } from 'constants/layout/constant';
import { STATUS_ACCEPT, STORAGE } from 'utils';
import Storage from 'utils/storage';
import { logUserProfile } from 'store/actions/myPage';
import {
  fanFollowCreator,
  resetIDTimeline,
} from 'store/actions/usersNew';
import { useState } from 'react';
import { getEnv } from 'configs/env';
import { PopupSharePost } from 'components';
import { setDataItemFollowUser } from 'store/actions/users';
import { FlowUnFlowIcon, UnFlowIcon } from 'images';

const FooterHompage = () => {
  const { t } = useTranslation();
  const { idSearchUser } = useSelector((state) => state.users);
  const {  dataInfoOther } = useSelector((state) => state.usersNew);
  const { pathname } = useLocation();
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const [popupCopyLink, setPopupCopyLink] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSharePost = (data) => {
    setPopupCopyLink(data);
  };

  const handleFollowCreator = () => {
    dataInfoOther &&
      dispatch(
        fanFollowCreator(dataInfoOther?.id, (data) => {
          if (idSearchUser) {
            dispatch(setDataItemFollowUser(dataInfoOther?.id, data));
          }
        })
      );
  };

  return (
    <>
    {popupCopyLink && (
      <PopupSharePost
        isVisiblePopup={() => setPopupCopyLink()}
        data={popupCopyLink || {}}
      />
    )}
    <div className='hide-greater-than-1024 footer-mobile1'>
      <ul className='list-menu'>
        <li
          className={`menu-item ${
            pathname === `${newPathUser}timeline` ? 'default-click' : ''
          }`}
          key={'HomePage'}
          onClick={(e) => {
            navigate(`${newPathUser}message/${dataInfoOther?.id}`);
          }}>
          <div className='block-div'>
            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.54912 13.8295C2.6173 12.2587 2.29097 10.4017 2.6314 8.6073C2.97184 6.81288 3.95563 5.20447 5.39803 4.08408C6.84044 2.96369 8.64224 2.40839 10.4651 2.52246C12.2879 2.63653 14.0065 3.41212 15.2979 4.70359C16.5894 5.99506 17.365 7.71357 17.4791 9.53643C17.5932 11.3593 17.0379 13.1611 15.9175 14.6035C14.7971 16.0459 13.1887 17.0297 11.3943 17.3701C9.59988 17.7106 7.74291 17.3843 6.17207 16.4525L6.17209 16.4524L3.58173 17.1925C3.47456 17.2231 3.36115 17.2245 3.25325 17.1965C3.14536 17.1686 3.0469 17.1123 2.96809 17.0335C2.88927 16.9547 2.83297 16.8562 2.80501 16.7483C2.77705 16.6404 2.77846 16.527 2.80908 16.4198L3.54918 13.8295L3.54912 13.8295Z" stroke="#DC143C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M10 10.7812C10.4315 10.7812 10.7812 10.4315 10.7812 10C10.7812 9.56853 10.4315 9.21875 10 9.21875C9.56853 9.21875 9.21875 9.56853 9.21875 10C9.21875 10.4315 9.56853 10.7812 10 10.7812Z" fill="#DC143C" />
              <path d="M13.75 10.7812C14.1815 10.7812 14.5312 10.4315 14.5312 10C14.5312 9.56853 14.1815 9.21875 13.75 9.21875C13.3185 9.21875 12.9688 9.56853 12.9688 10C12.9688 10.4315 13.3185 10.7812 13.75 10.7812Z" fill="#DC143C" />
              <path d="M6.25 10.7812C6.68147 10.7812 7.03125 10.4315 7.03125 10C7.03125 9.56853 6.68147 9.21875 6.25 9.21875C5.81853 9.21875 5.46875 9.56853 5.46875 10C5.46875 10.4315 5.81853 10.7812 6.25 10.7812Z" fill="#DC143C" />
            </svg>
            {t('MenuSiderbar.creator.message')}
          </div>
        </li>
        <li
          className={`menu-item ${
            pathname === `${newPathUser}timeline` ? 'default-click' : ''
          }`}
          key={'HomePage'}
          onClick={() => {
            if (!isAuth) {
              dispatch(logUserProfile());
            } else {
              handleFollowCreator();
            }
          }}>
          <div className='block-div'>
            {dataInfoOther?.is_followed === 0 ||
            !dataInfoOther?.is_followed ? (
              <svg
                width={21}
                height={20}
                viewBox='0 0 21 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M15.625 10.625H19.375'
                  stroke='#DC143C'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M17.5 8.75V12.5'
                  stroke='#DC143C'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M8.4375 12.5C11.0263 12.5 13.125 10.4013 13.125 7.8125C13.125 5.22367 11.0263 3.125 8.4375 3.125C5.84867 3.125 3.75 5.22367 3.75 7.8125C3.75 10.4013 5.84867 12.5 8.4375 12.5Z'
                  stroke='#DC143C'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M1.73535 15.6248C2.55671 14.6463 3.58238 13.8594 4.7403 13.3196C5.89822 12.7797 7.16031 12.5 8.43789 12.5C9.71547 12.5 10.9776 12.7798 12.1355 13.3196C13.2934 13.8595 14.319 14.6463 15.1404 15.6249'
                  stroke='#DC143C'
                  strokeWidth='1.5'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
            ) : (
              <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.625 10.625H19.375" stroke="#DC143C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.4375 12.5C11.0263 12.5 13.125 10.4013 13.125 7.8125C13.125 5.22367 11.0263 3.125 8.4375 3.125C5.84867 3.125 3.75 5.22367 3.75 7.8125C3.75 10.4013 5.84867 12.5 8.4375 12.5Z" stroke="#DC143C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1.73535 15.6248C2.55671 14.6463 3.58238 13.8594 4.7403 13.3196C5.89822 12.7797 7.16031 12.5 8.43789 12.5C9.71547 12.5 10.9776 12.7798 12.1355 13.3196C13.2934 13.8595 14.319 14.6463 15.1404 15.6249" stroke="#DC143C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            )}
            {dataInfoOther?.is_followed === 0 || !dataInfoOther?.is_followed
            ? t('comment.listComment.flow')
            : t('Creator.FollowList.following')}
          </div>
        </li>
        <li
          className={`menu-item ${
            pathname === `${newPathUser}timeline` ? 'default-click' : ''
          }`}
          key={'HomePage'}
          onClick={(e) => {
            if (!isAuth) {
              dispatch(logUserProfile());
            } else {
              handleSharePost({
                link: `${getEnv('REACT_APP_FAN_URL')}${newPathUser}${
                  dataInfoOther?.account_id
                }`,
              });
              e.preventDefault();
            }
          }}>
          <div className='block-div'>
            <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.5 12.5C6.88071 12.5 8 11.3807 8 10C8 8.61929 6.88071 7.5 5.5 7.5C4.11929 7.5 3 8.61929 3 10C3 11.3807 4.11929 12.5 5.5 12.5Z" stroke="#DC143C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M14.25 18.125C15.6307 18.125 16.75 17.0057 16.75 15.625C16.75 14.2443 15.6307 13.125 14.25 13.125C12.8693 13.125 11.75 14.2443 11.75 15.625C11.75 17.0057 12.8693 18.125 14.25 18.125Z" stroke="#DC143C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M14.25 6.875C15.6307 6.875 16.75 5.75571 16.75 4.375C16.75 2.99429 15.6307 1.875 14.25 1.875C12.8693 1.875 11.75 2.99429 11.75 4.375C11.75 5.75571 12.8693 6.875 14.25 6.875Z" stroke="#DC143C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M12.1462 5.72656L7.60156 8.6481" stroke="#DC143C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M7.60156 11.3516L12.1462 14.2731" stroke="#DC143C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            {t('Popup.share')}
          </div>
        </li>
      </ul>
    </div>
    </>
  );
};
export default FooterHompage;
