import Icon from '@ant-design/icons';

const BorderImgLiveIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width={70}
        height={75}
        viewBox='0 0 70 75'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle
          cx={35}
          cy={35}
          r='33.75'
          stroke='url(#paint0_linear_4698_392986)'
          strokeWidth='2.5'
        />
        <rect
          x={16}
          y={55}
          width={38}
          height={20}
          rx={10}
          fill='url(#paint1_linear_4698_392986)'
        />
        <path d='M23 69.94V61H25.148V68.14H28.628V69.94H23Z' fill='white' />
        <path d='M30.1719 69.94V61H32.3199V69.94H30.1719Z' fill='white' />
        <path
          d='M35.9032 69.94L33.2272 61H35.4952L36.5152 65.08C36.7792 66.04 36.9592 66.952 37.2112 67.936H37.2712C37.5352 66.952 37.7152 66.04 37.9672 65.08L38.9752 61H41.1592L38.4832 69.94H35.9032Z'
          fill='white'
        />
        <path
          d='M42.0781 69.94V61H47.7421V62.788H44.2261V64.444H47.2141V66.244H44.2261V68.14H47.8741V69.94H42.0781Z'
          fill='white'
        />
        <defs>
          <linearGradient
            id='paint0_linear_4698_392986'
            x1='5.83333'
            y1='3.40278'
            x2='63.1944'
            y2='64.6528'
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='#DC143C' />
            <stop offset={1} stopColor='#E9367C' />
          </linearGradient>
          <linearGradient
            id='paint1_linear_4698_392986'
            x1={16}
            y1={65}
            x2={54}
            y2={65}
            gradientUnits='userSpaceOnUse'>
            <stop stopColor='#DC143C' />
            <stop offset={1} stopColor='#E9367C' />
          </linearGradient>
        </defs>
      </svg>
    )}
    {...props}
  />
);

export default BorderImgLiveIcon;
