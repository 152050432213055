import { VideoPlayerTest } from 'components/LiveStream/V5/Videos/VideoPlayerTest';
import React from 'react'
import { useParams } from 'react-router-dom'

export const TestVideo = () => {

const { id } = useParams();

const mediaQueryList = window.matchMedia("(orientation: portrait)");

  return (
    <>
        <div className = 'ddtestabcd' style={{width: '100%', height: '100%'}}>
            {id === 'host' && (
                <VideoPlayerTest
                    host={true}
                    liveKey={'duc-123456-dd'}
                    allowComment={1}
                    type='lives'
                    view='creator'
                    showButton='screen-creator-live'
                    isMobileScreenHorizontal={''}
                    checkHorizontalScreen={''}
                    videoConfig = {mediaQueryList.matches ?
                        {width : 375, height: 667} : 
                        {width : 667, height: 375}
                    }
                />
            )}

            {id !== 'host' && (
                <VideoPlayerTest
                  allowView={true}
                  allowComment={1}
                  creatorIsFollow={0}
                  messageAllow={{}}
                  remainPoint={100}
                  host={false}
                  liveKey={'duc-123456-dd'}
                  type=''
                  view='fan'
                  showButton=''
                  eventUserQuit={{}}
                  isMobileScreenHorizontal={true}
                  checkHorizontalScreen={'portrait' || 'landscape'}
                />
            )}
            
        </div>
    </>
  )
}
