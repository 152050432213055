/* eslint-disable react-hooks/exhaustive-deps */
import { CustomReSelect, LayoutFan, PopupConfirmFan } from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import { ArrowLeftIcon } from 'images';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { customStyles, isEmptyObject, patternNoFirstSpacing } from 'utils/utils';
import { getListCategoryFeedBack, PostFeedBack } from '../../store/actions/feedback';
import './helpScreen.scss';

const PostHelp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    listCategoryFeedsBack = [],
    loading,
    status,
  } = useSelector((state) => state.feedback);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({ mode: 'all' });

  const watchQuestion = watch('question_content', '');
  const watchCategory = watch('category_content', '');
  const [focusCategory, setFocusCategory] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const optionListContent = listCategoryFeedsBack.map((item) => ({
    ...item,
    value: item?.id,
    label: item?.name,
  }));
  const { t } = useTranslation();

  const disabledButton = !watchQuestion || !watchCategory || !isEmptyObject(errors);
  const onSubmit = (data) => {
    const newData = {
      ...data,
      category_title: t('Creator.Post.category'),
      question_title: t('Help.inquiry'),
    };
    dispatch(PostFeedBack(newData));
  };

  useEffect(() => {
    dispatch(getListCategoryFeedBack());
  }, []);

  useEffect(() => {
    status === true && setIsOpenModal(true);
  }, [status]);

  const WithDropdownIndicator = () => {
    return <ArrowLeftIcon className={`${focusCategory ? 'focus' : 'no-focus'}`} />;
  };

  return (
    <>
      {isOpenModal && (
        <PopupConfirmFan text={t('Popup.weWillContact')} colorSvg='#28c76f'>
          <div
            className='ok btn'
            onClick={() => {
              setIsOpenModal(false);
              navigate('/help');
            }}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}
      <LayoutFan titleHeader={t('Help.form.title')} loading={loading}>
        <div className='post-help'>
          <form onSubmit={(e) => e.preventDefault()} className='fansite-form'>
            <InputWrapper
              id='fsite-category'
              label={t('Help.form.category')}
              required={true}
              error={errors?.category_content ? errors?.category_content?.message : ''}>
              <div
                className='category'
                onBlur={() => setFocusCategory(false)}
                onClick={() => setFocusCategory(!focusCategory)}>
                <Controller
                  name='category_content'
                  control={control}
                  rules={{ required: t('ValidateMsg.categoryRequired') }}
                  error={errors?.type ? errors?.type?.message : ''}
                  render={({ field }) => (
                    <CustomReSelect
                      field={field}
                      valueOption={[...optionListContent] || []}
                      isMulti={false}
                      placeholder={t('ValidateMsg.categoryRequired')}
                      isSearchable={false}
                      onChange={(val) => {
                        field.onChange(val);
                      }}
                      noOptionsMessage={() => t('Common.listNull')}
                      stylesCustoms={{
                        ...customStyles,
                        menuList: (base, state) => ({
                          ...base,
                          height: 200,
                          overflow: 'auto',
                        }),
                        dropdownIndicator: (base) => ({
                          ...base,
                          display: 'block',
                        }),
                      }}
                      customComponent={{
                        DropdownIndicator: WithDropdownIndicator,
                      }}
                    />
                  )}
                />
                <ArrowLeftIcon className={`${focusCategory ? 'focus' : 'no-focus'}`} />
              </div>
            </InputWrapper>
            <InputWrapper
              id='fsite-text'
              label={t('Help.inquiry')}
              required={true}
              error={errors?.question_content ? errors?.question_content?.message : ''}>
              <textarea
                {...register('question_content', {
                  required: t('ValidateMsg.inquiryRequired'),
                  validate: {
                    noFirstSpacing: (value) =>
                      (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                      t('ValidateMsg.inquiryRequired'),
                  },
                })}
                placeholder={t('Help.form.placholderQuestion')}
              />
            </InputWrapper>

            <InputWrapper
              id='fsite-submit'
              className={`button-submit ${disabledButton ? 'disabled' : ''}`}>
              <button onClick={handleSubmit(onSubmit)} disabled={disabledButton}>
                {t('Common.send')}
              </button>
            </InputWrapper>
          </form>
        </div>
      </LayoutFan>
    </>
  );
};

export default PostHelp;
