import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { setIdScrollBackRecommend } from 'store/actions/usersNew';

export const useGetIdFromRecommendUser = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { idScrollBackRecommend, pathUrlScrollBack } = useSelector((state) => state.usersNew);

  const idFromRecommendUser = useMemo(() => {
    if (pathUrlScrollBack === pathname) {
      return idScrollBackRecommend;
    }
    return null;
  }, [idScrollBackRecommend, pathUrlScrollBack, pathname]);

  const clearIdScrollBackRecommend = () => {
    dispatch(setIdScrollBackRecommend());
  };

  return {
    idFromRecommendUser,
    idScrollBackRecommend,
    pathUrlScrollBack,
    clearIdScrollBackRecommend,
  };
};
