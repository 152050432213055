import { useMemo, useState } from 'react';
import { Styled } from './styled';
import { ImgCustom, PopupConfirmFan, PopupLoading } from 'components';
import PopupViewFullIMG from 'components/Account/PopupViewFullIMG';
import FormDeletePost from './FormDeletePost';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { approvePostByAdmin } from 'store/actions/admin';
import { useLocation } from 'react-router-dom';
import { creatorGetPostDetail } from 'store/actions/usersNew';
import { STORAGE, decryptPath, isAdmin, isAgency, linkS3 } from 'utils';
import { pushNotify } from 'components/Notification';
import FieldChanged from 'components/FieldChanged/FieldChanged';
import Storage from 'utils/storage';

const STATUS_POST = {
  NOT_APPROVAL: 'NOT_APPROVAL',
  PUBLIC: 'PUBLIC',
  APPROVED_AGAIN: 'APPROVED_AGAIN',
  REMOVE: 'REMOVE',
  REMOVE_BY_CREATOR: 'REMOVE_BY_CREATOR',
  REJECT: 'REJECT',
};

const SidebarActionPost = () => {
  const { t } = useTranslation();
  const [isShowPopup, setIsShopPopup] = useState(false);
  const [isShowFormDelete, setIsShopFormDelete] = useState(false);
  const [loadingApprovePostAdmin, setLoadingApprovePostAdmin] = useState(false)
  const [content, setContent] = useState({
    title: '',
    subTitle: '',
    type: '',
  });
  const [showPopupFull, setShowPopupFull] = useState({
    status: false,
    idx: 0,
    dataItem: [],
    iSMany: 1,
  });
  const { detailPost } = useSelector((state) => state.usersNew);
  const user = useSelector((state) => state.users.dataUser);

  const permission_approve_post = user?.permissions?.admin_approve_post;

  const { pathname } = useLocation();

  const lastSlashIndex = pathname.lastIndexOf('/');

  const id = lastSlashIndex !== -1 && pathname.substring(lastSlashIndex + 1);

  const dispatch = useDispatch();

  const status = detailPost?.status;

  const typePost = pathname.match(/\/([^\/]+)\//)[1] === 'post' ? 1 : 2;

  const colorStatus = useMemo(() => {
    switch (status) {
      case STATUS_POST.NOT_APPROVAL:
        return {
          color: '#DC143C',
          text: t('Popup.AdminManagementPost.not_approved'),
        };
      case STATUS_POST.PUBLIC:
      case STATUS_POST.APPROVED_AGAIN:
        return {
          color: '#52C41A',
          text: t('Popup.AdminManagementPost.published'),
        };
      case STATUS_POST.REJECT:
        return {
          color: '#FFBF00',
          text: t('Popup.AdminManagementPost.rejected'),
        };
      case STATUS_POST.REMOVE:
        return {
          color: '#DC143C',
          text: t('Popup.AdminManagementPost.removed'),
        };
      case STATUS_POST.REMOVE_BY_CREATOR:
        return {
          color: '#DC143C',
          text: t('Popup.AdminManagementPost.removed_by_creator'),
        };
      default:
        break;
    }
  }, [detailPost, status, t]);

  const dataKYC1 = detailPost?.post_contracts;

  const disableActions = permission_approve_post === 0 ? true : false;

  const renderDataKYC = () => {
    if (dataKYC1?.length > 0) {
      return dataKYC1.map((item, index) => {
        const listImage = [
          { url: item?.avatar },
          { url: item?.avatar_identify },
          { url: item?.identify_img_front },
          { url: item?.identify_img_back },
        ];

        const dataItem = listImage.filter((item) => item?.url?.length > 0);

        const listIdChanged = Array.isArray(detailPost?.contracts_changed)
          ? detailPost?.contracts_changed
          : [];

        return (
          <Styled.ContentKYC key={index} className='content'>
            {isAdmin && listIdChanged?.includes(item?.contract_id) && (
              <FieldChanged borderRadius='5px' bottom='15px' top='auto' />
            )}
            <Styled.Field>
              {t('Popup.AdminManagementPost.performer_information')}
              <Styled.Content fw={500}>
                {item?.last_name} {item?.first_name}
              </Styled.Content>
            </Styled.Field>
            <Styled.Field>
              {t('Popup.AdminManagementPost.performer_authorization_form_or_contract')}
              <Styled.Content color='#1480FF' fw={400} cursor='pointer'>
                {item?.contract_file ? (
                  <a
                    href={`${linkS3}${item?.contract_file}`}
                    download={`${linkS3}${item?.contract_file}`}>
                    {item?.contract_file.split('/').pop()}
                  </a>
                ) : (
                  <p className='pdf-empty'>---</p>
                )}
              </Styled.Content>
            </Styled.Field>
            <Styled.Field>
              {t('Popup.AdminManagementPost.image')}
              <Styled.Content color='#1480FF' fw={400}>
                <ImgCustom
                  screen='home_avartar_100_100'
                  style={{
                    objectFit: 'cover',
                    height: '45px',
                    width: '45px !important',
                    cursor: 'pointer',
                  }}
                  src={item?.avatar}
                  className='w-100 avatar-home'
                  onClick={() => {
                    setShowPopupFull({
                      ...showPopupFull,
                      status: true,
                      dataItem: dataItem,
                      idx: 0,
                      iSMany: 2,
                    });
                  }}
                />

                {dataItem.length > 0 ? (
                  <div
                    className='enable'
                    onClick={() =>
                      setShowPopupFull({
                        ...showPopupFull,
                        status: true,
                        dataItem: dataItem,
                        idx: 0,
                        iSMany: 2,
                      })
                    }>
                    {dataItem.length > 0 ? `+${dataItem.length}` : ''}
                  </div>
                ) : (
                  <></>
                )}
              </Styled.Content>
            </Styled.Field>
          </Styled.ContentKYC>
        );
      });
    } else
      return (
        <Styled.Field className='no-data'>
          {t('Popup.AdminManagementPost.no_performer_information')}
        </Styled.Field>
      );
  };

  const ButtonApprove = () => (
    <Styled.Button disabled={disableActions} onClick={() => setIsShopPopup(true)}>
      {t('Popup.AdminManagementPost.approve')}
    </Styled.Button>
  );

  const ButtonReject = () => (
    <Styled.Button
      disabled={disableActions}
      onClick={() => {
        setIsShopFormDelete(true);
        setContent({
          title: t('Popup.AdminManagementPost.reason'),
          subTitle: t(
            'Popup.AdminManagementPost.are_you_sure_you_want_to_reject_this_content'
          ),
          type: 'reject',
        });
      }}>
      {t('Popup.AdminManagementPost.reject')}
    </Styled.Button>
  );

  const ButtonDelete = (width, mgbt) => (
    <Styled.Button
      disabled={disableActions}
      onClick={() => {
        setIsShopFormDelete(true);
        setContent({
          title: t('Popup.AdminManagementPost.reason'),
          subTitle: t(
            'Popup.AdminManagementPost.are_you_sure_you_want_to_remove_this_content'
          ),
          type: 'delete',
        });
      }}
      width={width ?? '100%'}
      mgbt={mgbt ?? '0'}>
      {t('Popup.AdminManagementPost.remove')}
    </Styled.Button>
  );
  const agencyToken = Storage.get(STORAGE.AGENCY_TOKEN);
  const adminTokenAccess = Storage.get(STORAGE.USER_ACCESS_TOKEN);
  const handleApproveSuccess = () => {
    dispatch(creatorGetPostDetail(id, typePost, false, isAgency ? agencyToken : adminTokenAccess));
    setIsShopPopup(false);
    setLoadingApprovePostAdmin(false);
    pushNotify(
      'success',
      t('Popup.AdminManagementPost.approval_has_been_successfully_completed')
    );
  };

  const handleApproveFail = () => {
    dispatch(creatorGetPostDetail(id, typePost, false, isAgency ? agencyToken : adminTokenAccess));
    setLoadingApprovePostAdmin(false);
    setIsShopPopup(false);
  };

  return (
    <Styled.WrapperFixed>
      {isShowFormDelete && (
        <FormDeletePost
          loadingApprovePostAdmin={loadingApprovePostAdmin}
          setLoadingApprovePostAdmin={setLoadingApprovePostAdmin}
          title={content.title}
          subTitle={content.subTitle}
          type={content.type}
          handleClosePopup={() => setIsShopFormDelete(false)}
          id={id}
          typePost={typePost}
        />
      )}
      {loadingApprovePostAdmin && <PopupLoading className={'popup-loading'} />}
      {isShowPopup && (
        <PopupConfirmFan
          isVisiblePopup={false}
          iconNote={true}
          colorSvg='#FFBF00'
          fillColor={true}
          text={t('Popup.AdminManagementPost.are_you_sure_you_want_to_approve_this_content')}
          className='popup-content sell-package-page popup-post'>
          <div
            className='ok btn'
            onClick={() => {
              if (!loadingApprovePostAdmin) {
                setLoadingApprovePostAdmin(true)
                dispatch(approvePostByAdmin(id, handleApproveSuccess, handleApproveFail));
              }
            }}>
            OK
          </div>
          <div className='btn cancel' onClick={() => setIsShopPopup(false)}>
            Cancel
          </div>
        </PopupConfirmFan>
      )}

      {showPopupFull.status && (
        <PopupViewFullIMG
          className='popup-post-detail-show-media'
          idx={showPopupFull.idx}
          item={showPopupFull}
          close={() => {
            setShowPopupFull({
              ...showPopupFull,
              status: false,
              dataItem: {},
            });
          }}
        />
      )}

      <Styled.Wrapper mgbt={'25px'}>
        <Styled.Title>{t('Popup.AdminManagementPost.action')}</Styled.Title>
        <Styled.Field>
          {t('Popup.AdminManagementPost.post_status')}
          <Styled.Content color={colorStatus?.color}>{colorStatus?.text}</Styled.Content>
        </Styled.Field>
        {!isAgency && (
          <>
            {status === STATUS_POST.NOT_APPROVAL && (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {ButtonApprove()}
                  {ButtonReject()}
                </div>
                {ButtonDelete()}
              </>
            )}

            {(status === STATUS_POST.PUBLIC || status === STATUS_POST.APPROVED_AGAIN) && (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {ButtonReject()}
                  {ButtonDelete('144px', '0')}
                </div>
              </>
            )}

            {status === STATUS_POST.REJECT && (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {ButtonDelete('144px', '0')}
                </div>
              </>
            )}

            {status === STATUS_POST.REMOVE_BY_CREATOR && <></>}
          </>
        )}
      </Styled.Wrapper>
      {detailPost?.is_subagent_post === 0 && 
        <Styled.Wrapper height='434px' paddingRight='0'>
          <Styled.Title>{t('Popup.AdminManagementPost.performer_information')}</Styled.Title>

          <div style={{ overflow: 'scroll', height: '100%' }}>{renderDataKYC()}</div>
        </Styled.Wrapper>
      }
    </Styled.WrapperFixed>
  );
};

export default SidebarActionPost;
