import {
  configParamsDELETE,
  configParamsGet,
  configParamsPostJSON,
  configParamsPUT,
  ENV,
  updateStatus,
} from '../../utils/constant';
import { callApi } from '../../utils/request';

const getContracts = ({ page = 1, limit = 20, chk_expired = 0 }, callback) => {
  let url = `${ENV}/contracts?page=${page}&limit=${limit}&chk_expired=${chk_expired}`;
  return () => {
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        callback && callback(response);
      },
      () => {
        callback && callback({});
      }
    );
  };
};
const createContracts = (params, handleSuccess) => {
  let url = `${ENV}/contracts`;
  return () => {
    callApi(
      url,
      configParamsPostJSON(params),
      null,
      async (response) => {
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleSuccess && handleSuccess(error);
      }
    );
  };
};

const updateContracts = (id, params, handleSuccess) => {
  let url = `${ENV}/contracts/${id}`;
  return () => {
    callApi(
      url,
      configParamsPUT(params),
      null,
      async (response) => {
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleSuccess && handleSuccess(error);
      }
    );
  };
};

const deleteContract = (params, handleSuccess) => {
  let url = `${ENV}/contracts/${params.id}`;
  return () => {
    callApi(
      url,
      configParamsDELETE,
      null,
      async (response) => {
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleSuccess && handleSuccess(error);
      }
    );
  };
};

const getContract = (id, handleSuccess) => {
  let url = `${ENV}/contracts/${id}`;
  return () => {
    callApi(
      url,
      configParamsGet,
      null,
      async (response) => {
        handleSuccess && handleSuccess(response);
      },
      (error) => {
        handleSuccess && handleSuccess(error);
      }
    );
  };
};

export {
  getContracts,
  createContracts,
  deleteContract,
  getContract,
  updateContracts
};
