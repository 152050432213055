import { listMonth, listYear } from 'constants/FilterDate';
import { CheckedIcon } from 'images';
import React, { useEffect, useMemo, useState } from 'react';
import Select, { components } from 'react-select';
import { WrapperFilter } from './styled';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { CustomDropdownIndicator } from 'utils';
const CustomOption = ({ children, ...props }) => {
  return (
    <components.Option {...props}>
      {children}
      <CheckedIcon />
    </components.Option>
  );
};
const customStylesYear = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#282727' : '#282727',
    backgroundColor: state.isSelected ? '#FFDBDB' : '#ffffff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    svg: {
      color: 'red',
      display: state.isSelected ? 'block' : 'none',
    },
    fontWeight: state.isSelected ? 500 : 400,
  }),
  control: (base, state) => ({
    width: 115,
    height: 32,
    display: 'flex',
    border: '1px solid #E5E6EB',
    borderRadius: '6px',
    fontSize: '12px',
    marginRight: '8px',
    svg: {
      color: '#666565',
      transform: state.menuIsOpen ? 'rotate(180deg)' : '',
    },
  }),
  menu: (provided) => ({
    ...provided,
    maxWidth: '343px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.08)',
    borderRadius: '6px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    return { ...provided, opacity, color: '#282727', fontWeight: 500 };
  },
};

const customStylesMonth = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? '#282727' : '#282727',
    backgroundColor: state.isSelected ? '#FFDBDB' : '#ffffff',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    svg: {
      color: 'red',
      display: state.isSelected ? 'block' : 'none',
    },
    fontWeight: state.isSelected ? 500 : 400,
  }),
  control: (base, state) => ({
    width: 100,
    height: 32,
    display: 'flex',
    border: '1px solid #E5E6EB',
    borderRadius: '6px',
    fontSize: '12px',
    marginRight: '8px',
    svg: {
      color: '#666565',
      transform: state.menuIsOpen ? 'rotate(180deg)' : '',
    },
  }),
  menu: (provided) => ({
    ...provided,
    maxWidth: '343px',
    background: '#FFFFFF',
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.08)',
    borderRadius: '6px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    return { ...provided, opacity, color: '#282727', fontWeight: 500 };
  },
};
function SelectDateGroup({ handleSelect }) {
  const [filterDateFrom, setFilterDateFrom] = useState({
    year: new Date().getFullYear(),
    month: '',
  });
  const { t, i18n } = useTranslation();

  const handleChangeDate = (key, value) => {
    setFilterDateFrom({ ...filterDateFrom, [key]: value });
    handleSelect({ ...filterDateFrom, [key]: value });
  };

  // const year = listYear.map((item) => ({
  //   value: item,
  //   label: `${item} ${t('Common.year')}`,
  // }));
  // const month = listMonth.map((item) => ({
  //   value: item,
  //   label: `${item} ${t('Common.month')}`,
  // }));


  const year = useMemo(() => {
    return listYear.map((item) => ({
      value: item,
      label: `${item} ${t('Common.year')}`,
    }));
  }, [listYear, i18n.language]);

  const month = useMemo(() => {
    return listMonth.map((item) => ({
      value: item,
      label: `${item} ${t('Common.month')}`,
    }));
  }, [listMonth, i18n.language]);

  const defaultValueYear = useMemo(() => {
    return {
      value: new Date().getFullYear(),
      label: `${new Date().getFullYear()} ${t('Common.year')}`,
    };
  }, [i18n.language]);

  const defaultValueMonth = useMemo(() => {
    return {
      value: '',
      label: `${t('Common.all')}`,
    };
  }, [i18n.language]);

  const OptionsSelect = useMemo(
    () => [{ label: t('Creator.all'), value: '' }],
    [i18n.language]
  );

  const defaultValueYearChange = useMemo(() => {
    const filterValue = [...year]?.filter((e) => e?.value === filterDateFrom['year']);
    if (filterValue && filterValue?.length > 0) {
      return filterValue[0]
    }
    return ''
  }, [filterDateFrom, year]);

  const defaultValueMonthChange = useMemo(() => {
    const filterValue = [OptionsSelect[0], ...month]?.filter((e) => e?.value === filterDateFrom['month']);
    if (filterValue && filterValue?.length > 0) {
      return filterValue[0]
    }
    return ''
  }, [OptionsSelect, filterDateFrom, month]);

  return (
    <WrapperFilter>
      <WrapperFilter>
        <Select
          isSearchable={false}
          isClearable={false}
          defaultValue={defaultValueYear}
          value={defaultValueYearChange}
          onChange={(e) => handleChangeDate('year', e.value)}
          options={[...year]}
          placeholder={`${t('Common.year')}`}
          styles={customStylesYear}
          components={{ Option: CustomOption, DropdownIndicator: CustomDropdownIndicator }}
        />
        <Select
          isSearchable={false}
          isClearable={false}
          value={defaultValueMonthChange}
          onChange={(e) => handleChangeDate('month', e.value)}
          options={[OptionsSelect[0], ...month]}
          defaultValue={defaultValueMonth}
          placeholder={`${t('Common.month')}`}
          styles={customStylesMonth}
          components={{ Option: CustomOption, DropdownIndicator: CustomDropdownIndicator }}
          className='select-date-group month'
        />
      </WrapperFilter>
    </WrapperFilter>
  );
}
export { SelectDateGroup };
export default SelectDateGroup;
