/* eslint-disable import/no-anonymous-default-export */
import { TYPES } from '../actions';

const initialState = {
  status: null,
  loading: false,
  loadingFollow: false,
  error: null,
  pagination: {},
  listRanking: [],
  idScrollBackRanking: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.GET_DATA_RANKING_PAGE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_DATA_RANKING_PAGE_SUCCESS:
      if (action?.data?.data?.page === 1) {
        return {
          ...state,
          loading: false,
          pagination: action.data?.data?.pagination,
          listRanking: action.data?.data?.list,
        };
      }
      return {
        ...state,
        loading: false,
        pagination: action.data?.data?.pagination,
        listRanking: [...state?.listRanking, ...action.data?.data?.list],
      };
    case TYPES.GET_DATA_RANKING_PAGE_FAILED:
      return {
        ...state,
        loading: false,
      };
    case TYPES.FOLLOW_USER_RANKING_REQUEST:
      return {
        ...state,
        loadingFollow: true,
      };
    case TYPES.FOLLOW_USER_RANKING_SUCCESS:
      state.listRanking = state?.listRanking?.map((item) => {
        if (+item?.user_id === +action.data?.data?.id) {
          return {
            ...item,
            is_followed: item?.is_followed === 1 ? 0 : 1,
          };
        }
        return item;
      });
      return {
        ...state,
        loadingFollow: false,
        listRanking: state?.listRanking,
      };
    case TYPES.FOLLOW_USER_RANKING_FAILED:
      return {
        ...state,
        loadingFollow: false,
      };
    case TYPES.SET_COUNT_LIKE_POST_RANKING_SUCCESS:
      state.listRanking = state?.listRanking?.map((item) => {
        if (+item?.id === +action.data?.data?.id) {
          return {
            ...item,
            is_liked: +action.data?.data?.is_liked,
            number_like: item?.number_like + Number(action.data?.data?.number_like)
          };
        }
        return item;
      });
      return {
        ...state,
        loadingFollow: false,
        listRanking: state?.listRanking,
      };
    case TYPES.SET_COUNT_COMMENT_RANKING_SUCCESS:
      state.listRanking = state?.listRanking?.map((item) => {
        if (+item?.id === +action.data?.data?.id) {
          return {
            ...item,
            number_comment: +action.data?.data?.number_comment,
          };
        }
        return item;
      });
      return {
        ...state,
        loadingFollow: false,
        listRanking: state?.listRanking,
      };
    case 'BY_PACKAGE_FROM_RANKING_SUCCESS':
      state.listRanking = state?.listRanking?.map((item) => {
        if (+item?.id === +action.data?.data?.id) {
          return {
            ...item,
            is_media_display: 1,
          };
        }
        return item;
      });
      return {
        ...state,
        listRanking: state?.listRanking,
      };
    case 'SET_ID_SCROLL_BACK_RANKING':
      return {
        ...state,
        idScrollBackRanking: action.data?.data?.id || null,
      };
    case 'CLEAR_LIST_RANKING':
      return {
        ...state,
        listRanking: [],
        idScrollBackRanking: null,
        pagination: {},
      };
    default:
      return state;
  }
};
