import Icon from '@ant-design/icons';

const SmsEditIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        fill='none'
        viewBox='0 0 20 20'>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          strokeWidth='1.5'
          d='M10 17.083H5.833c-2.5 0-4.167-1.25-4.167-4.166V7.082c0-2.917 1.667-4.167 4.167-4.167h8.334c2.5 0 4.166 1.25 4.166 4.167v2.5'></path>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeMiterlimit='10'
          strokeWidth='1.5'
          d='M14.167 7.5l-2.608 2.083c-.859.684-2.267.684-3.126 0L5.833 7.5M16.009 12.309l-2.95 2.95a1.027 1.027 0 00-.25.491l-.159 1.125c-.058.409.225.692.633.634l1.126-.159a.99.99 0 00.491-.25l2.95-2.95c.508-.508.75-1.1 0-1.85-.742-.741-1.333-.5-1.841.009zM15.584 12.733c.25.9.95 1.6 1.85 1.85'></path>
      </svg>
    )}
    {...props}
  />
);

export default SmsEditIcon;
