import classNames from 'classnames';
import {
  ImgCustom,
  LayoutCreatorRight,
  PopupDeleteMessage,
  PopupFanDetail,
  SendCoinPopup,
} from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import { pushNotify } from 'components/Notification';
import PopupConfirmDelete from 'components/PopupConfirmDelete';
import { newPathUser } from 'constants/layout/constant';
import { ArrowIcon, CloseIcon } from 'images';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  deleteMessage,
  userDeleteMessage,
  userGetMessageDetail,
  userGetMessageDetailToAdmin,
  userSendMessage,
} from 'store/actions/message';
import { getListSurvey } from 'store/actions/survey';
import {
  blockOrUnblockFanMessage,
  resetActionGetProfileAnotherFan,
} from 'store/actions/users';
import {
  ADMIN_TYPE,
  AVATAR_DEFAULT,
  LIST_POINT_ICON_DONATE,
  LOGO_COCO_FANS,
} from 'utils/constant';
import { getPresignedUrlS3, setFileNameS3, uploadS3ByPresigned } from 'utils/upload';
import {
  acceptedImageTypes,
  acceptedImgVideoTypes,
  formatCoin,
  isAdmin as isAdminLocalStorage,
  linkS3Video,
  ONE_GB,
  TEN_MB,
  TYPE_VIDEOS,
  userIdToAdmin,
  decryptPath,
  isProdEnableLiveStream,
  funcThumbnailIMG,
  checkLinkPostSale,
} from 'utils/utils';
import './index.scss';
import DeleteIcon from 'images/DeleteIcon';
import BlockIcon from 'images/BlockIcon';
import SendChatIconV2 from 'images/SendChatIconV2';
import CoinIconV2 from 'images/CoinIconV2';
import FileIconV2 from 'images/FileIconV2';
import AddSurveyIconV2 from 'images/AddSurveyIconV2';
import AddPhotoIconV2 from 'images/AddPhotoIconV2';
import CloseIconV2 from 'images/CloseIconV2';
import { useMemo } from 'react';
import ViewFullImageLoading from 'components/Account/ViewFullImageLoading';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import MarkDownRead from 'components/MarkDownRead';
import { getInfoMetaPost } from 'store/actions/postMeta';
import MetaTagViewer from 'components/MetaTagViewer/MetaTagViewer';
import { resetIDHomePage } from 'store/actions/usersNew';

const Index = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const listMessageRef = useRef();

  const [page, setPage] = useState(1);
  const [dataMessages, setDataMessage] = useState({});
  const [listSurvey, setListSurvey] = useState([]);
  const [surveyIdChecked, setSurveyIdChecked] = useState(null);
  const [isVisiblePopupSurvey, setIsVisiblePopupSurvey] = useState(false);
  const [isVisiblePopupCoin, setIsVisiblePopupCoin] = useState(false);
  const [isVisiblePopup, setIsVisiblePopup] = useState(false);
  const [isVisiblePopupBlock, setIsVisiblePopupBlock] = useState(false);

  const [isVisibleViewFanDetail, setIsVisibleViewFanDetail] = useState(false);
  const [showPopupConfirm, setShowPopupConfirm] = useState(false);
  const [showPopupConfirmDelete, setShowPopupConfirmDelete] = useState(false);
  const [messageDelete, setMessageDelete] = useState({});
  const [statusBlock, setStatusBlock] = useState();
  const [dataViewFull, setDataViewFull] = useState({});

  const [videoUpload, setVideoUpload] = useState([]);
  const [videoPreview, setVideoPreview] = useState();
  const [imgUpload, setImgUpload] = useState([]);

  const [listHoverShow, setListHoverShow] = useState([]);

  const [loadingGetSurveys, setLoadingGetSurveys] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const { dataUser: dataProfile } = useSelector((state) => state.users);

  const [idPostMeta, setIdPostMeta] = useState(null);
  const [postDetailMeta, setPostDetailMeta] = useState(null);

  const isApproved = useMemo(() => {
    return dataProfile?.status === 1;
    // return false;
  }, [dataProfile?.status]);

  const isAdmin = ADMIN_TYPE.includes(dataMessages?.to_user?.user_type);
  const avatar = useMemo(() => {
    return isAdmin
      ? LOGO_COCO_FANS
      : dataMessages?.to_user?.avatar
      ? `${dataMessages?.to_user?.avatar}`
      : AVATAR_DEFAULT;
  }, [isAdmin, dataMessages?.to_user?.avatar]);

  const nameShow = useMemo(() => {
    if (isAdmin) {
      if (i18n.language === 'en') {
        return 'ADMIN';
      }
      return 'アドミン';
    }
    return dataMessages?.to_user?.full_name || dataMessages?.to_user?.account_name;
  }, [i18n.language, isAdmin, dataMessages?.to_user]);

  useEffect(() => {
    return () => {
      dispatch(resetActionGetProfileAnotherFan());
      setDataMessage({});
    };
  }, []);

  useEffect(() => {
    setStatusBlock(dataMessages?.to_user?.block_status);
    if (dataMessages) {
      setListHoverShow(Array(dataMessages?.length)?.fill(false));
    }
  }, [dataMessages]);

  useEffect(() => {
    setLoadingData(true);
    dispatch(
      isAdminLocalStorage
        ? userGetMessageDetailToAdmin(
            {
              id: userIdToAdmin,
              userId: id,
              page,
              limit: 30,
            },
            handleSetDataMessage,
            true
          )
        : userGetMessageDetail(
            {
              id,
              page,
              limit: 30,
            },
            handleSetDataMessage,
            true
          )
    );
  }, [page]);

  useEffect(() => {
    listMessageRef?.current?.addEventListener('scroll', scrollLoadMoreMessage);
    return () => listMessageRef?.current?.removeEventListener('scroll', scrollLoadMoreMessage);
  }, [page, loading, dataMessages]);

  const scrollLoadMoreMessage = (e) => {
    const el = e.target;
    if (
      el.clientHeight - el.scrollTop > el.scrollHeight - 2 &&
      !loading &&
      page !== dataMessages?.pagination?.total_pages
    ) {
      setLoading(true);
      setPage(page + 1);
    }
  };

  const handleListSurvey = ({ success, data }) => {
    if (success) {
      setListSurvey(data || []);
    }
    setLoadingGetSurveys(false);
  };

  const handleSetDataMessage = ({ success, ...data }, isSetLoading = true) => {
    if (isSetLoading) {
      setLoadingData(true);
    }
    if (success) {
      page === 1
        ? setDataMessage(data)
        : setDataMessage({
            ...data,
            data: [...dataMessages.data, ...data.data],
          });
    }
    setLoadingData(false);
  };

  const { register, handleSubmit, watch, reset } = useForm();
  const watchText = watch('text', '');
  const isDisabledButton =
    !watchText?.trim() && videoUpload?.length === 0 && imgUpload?.length === 0;

  const handleSendText = () =>
    dispatch(
      userSendMessage(
        {
          to_user_id: id,
          type: 1,
          content: watchText,
        },
        handleSendMessageSuccess
      )
    );

  const handleChangeImgVideo = (e) => {
    setLoading(true);
    setIsVisiblePopup(false);
    const videoSelect = [];
    const imageSelect = [];
    const targetFiles = e.target.files;
    setVideoPreview(URL.createObjectURL(e.target.files[0]));
    let targetFilesObject = [...targetFiles];

    if (
      targetFilesObject.length + imgUpload.length > 10 ||
      targetFilesObject.length + videoUpload.length > 10
    ) {
      setLoading(false);
      return pushNotify('error', `${t('ValidateMsg.uploadThan10Img')}`);
    }

    targetFilesObject.map((file) => {
      const isImage = file.type.includes('image');
      const isVideo = file.type.includes('video');

      const currentFile = {
        type: file.type,
        file,
        url: URL.createObjectURL(file),
        name: file.name,
      };

      const maxSize = isImage ? TEN_MB : ONE_GB;
      const fileType = isImage ? acceptedImageTypes : TYPE_VIDEOS;
      if (+file.size > +maxSize) {
        return pushNotify(
          'error',
          isImage ? `${t('ValidateMsg.imgThan10Mb1')}` : `${t('ValidateMsg.videoThan1Gb1')}`
        );
      } else if (isImage && !fileType.includes(file.type)) {
        return pushNotify('error', `${t('ValidateMsg.typeImg1')}`);
      } else if (isVideo && !fileType.includes(file.type)) {
        return pushNotify('error', `${t('ValidateMsg.typeVideo1')}`);
      } else if (!isVideo && !isImage) {
        pushNotify('error', `${t('ValidateMsg.typeImg1')}`);
        return pushNotify('error', `${t('ValidateMsg.typeVideo1')}`);
      } else if ((imgUpload.length > 0 && isVideo) || (videoUpload.length > 0 && isImage)) {
        return pushNotify('error', `${t('ValidateMsg.ImgAndVideoRequired')}`);
      } else if (isImage) {
        return imageSelect.push(currentFile);
      } else {
        return videoSelect.push(currentFile);
      }
    });

    if (videoSelect.length > 1) {
      setLoading(false);
      return pushNotify('error', `${t('ValidateMsg.uploadThan1Video')}`);
    }

    if (imageSelect.length && videoSelect.length) {
      setLoading(false);
      return pushNotify('error', `${t('ValidateMsg.ImgAndVideoRequired')}`);
    } else {
      imageSelect.length && setImgUpload([...imgUpload, ...imageSelect]);
      videoSelect.length && setVideoUpload(videoSelect);
    }
    setLoading(false);
  };

  const handleUploadImg = async () => {
    setLoading(true);
    setIsVisiblePopup(false);

    let listLinkS3Img = [];
    let listLinkPreSignedImg = [];

    let listLinkS3Video = [];
    let listLinkPreSignedVideo = [];

    if (imgUpload.length) {
      const listObjectUrl = [];
      const promiseList = imgUpload.map((item) => {
        const objectUrl = URL.createObjectURL(item.file);
        listObjectUrl.push(objectUrl);
        return getPresignedUrlS3('image', `uploads/output/images`, setFileNameS3(item.name));
      });

      await Promise.all(promiseList)
        .then((responses) => {
          responses.forEach((element) => {
            if (element.success) {
              listLinkS3Img.push(element.data.file_path);
              listLinkPreSignedImg.push(element.data.pre_signed);
            }
          });
          const promiseGenerate = listLinkPreSignedImg.map((m, i) => {
            return uploadS3ByPresigned(m, imgUpload[i].file);
          });
          Promise.all(promiseGenerate).then(() => {
            dispatch(
              userSendMessage(
                {
                  to_user_id: id,
                  type: 2,
                  images: listLinkS3Img,
                },
                (data) => {
                  watchText?.trim()
                    ? handleSendText()
                    : setTimeout(() => {
                        handleSendMessageSuccess(data, listObjectUrl);
                      }, 1000);
                }
              )
            );
          });
        })
        .catch((_errors) => {
          setLoading(false);
        });
    }

    if (videoUpload.length) {
      const objectUrl = URL.createObjectURL(videoUpload[0].file);
      const promiseList = videoUpload.map((video) => {
        return getPresignedUrlS3('video', `uploads/output/videos`, setFileNameS3(video.name));
      });

      await Promise.all(promiseList)
        .then((responses) => {
          responses.forEach((element) => {
            if (element.success) {
              listLinkS3Video.push(element.data.file_path);
              listLinkPreSignedVideo.push(element.data.pre_signed);
            }
          });
          const promiseGenerate = listLinkPreSignedVideo.map((m, i) => {
            return uploadS3ByPresigned(m, videoUpload[i].file);
          });
          Promise.all(promiseGenerate).then(() => {
            for (const videoLink of listLinkS3Video) {
              dispatch(
                userSendMessage(
                  {
                    to_user_id: id,
                    type: 3,
                    main_video: videoLink,
                  },
                  (data) => {
                    watchText?.trim()
                      ? handleSendText()
                      : setTimeout(() => {
                          handleSendMessageSuccess(data, objectUrl);
                        }, 1000);
                  }
                )
              );
            }
          });
        })
        .catch((_errors) => {
          setLoading(false);
        });
    }
  };

  const handleBlock = () => {
    setIsVisiblePopupBlock(false);
    setLoading(true);
    dispatch(blockOrUnblockFanMessage(id, handleBlockSuccess));
  };

  const handleDelete = () => {
    setLoading(true);
    dispatch(
      deleteMessage(id, () => {
        setShowPopupConfirmDelete(false);
        navigate(`${newPathUser}message`);
      })
    );
    setLoading(false);
  };

  const handleBlockSuccess = ({ data: dataBlock, success }) => {
    if (success && dataBlock) {
      setStatusBlock(+dataBlock?.is_blocked);
      reset();
    }
    setLoading(false);
  };

  const handleSendMessageSuccess = ({ success }, objectUrl) => {
    setLoading(true);
    if (success) {
      reset();
      setSurveyIdChecked(null);
      setVideoUpload([]);
      setImgUpload([]);
      dispatch(
        isAdminLocalStorage
          ? userGetMessageDetailToAdmin(
              {
                id: userIdToAdmin,
                userId: id,
                page,
                limit: 30,
              },
              handleSetDataMessage,
              true
            )
          : userGetMessageDetail(
              {
                id,
                page,
                limit: 30,
              },
              handleSetDataMessage,
              true
            )
      );
    }
    listMessageRef.current.scrollTo(0, 0);
    setLoading(false);
  };

  const handleDeleteMessageSuccess = ({ success }) => {
    if (success) {
      const newMessage = [...dataMessages?.data];
      newMessage?.splice(messageDelete?.index, 1);
      setDataMessage({
        ...dataMessages,
        data: newMessage,
      });
    }
    setLoading(false);
    setMessageDelete({});
    setShowPopupConfirm(false);
  };

  const sendSurvey = () => {
    setIsVisiblePopupSurvey(false);
    setLoading(true);
    dispatch(
      userSendMessage(
        {
          to_user_id: id,
          type: 4,
          survey_id: surveyIdChecked,
        },
        handleSendMessageSuccess
      )
    );
  };

  const onSubmit = () => {
    setLoading(true);
    videoUpload?.length > 0 || imgUpload?.length > 0
      ? handleUploadImg()
      : watchText?.trim() && handleSendText();
  };

  useEffect(() => {
    const isValidContent = watchText.trim() !== '';
    if (isValidContent) {
      const idPost = checkLinkPostSale(watchText);
      if (idPost) {
        setIdPostMeta(idPost);
      } else {
        setIdPostMeta(null);
      }
    } else {
      setPostDetailMeta(null);
      setIdPostMeta(null);
    }
  }, [watchText]);

  useEffect(() => {
    if (idPostMeta) {
      dispatch(
        getInfoMetaPost(idPostMeta, (dataMeta) => {
          setPostDetailMeta(dataMeta);
        })
      );
    } else {
      setPostDetailMeta(null);
    }
  }, [idPostMeta, dispatch]);

  const handleCheckSurvey = (id) => setSurveyIdChecked(id);

  const renderSurveyItem = (surveyItem) => {
    const isChecked = surveyIdChecked === surveyItem?.id;
    return (
      <div
        className='item-survey form-group d-flex align-items-start mb-2'
        key={surveyItem?.id}>
        <input
          type='checkbox'
          id={surveyItem?.id}
          className='form-check-input'
          name={surveyItem?.id}
          onChange={() => handleCheckSurvey(surveyItem?.id)}
          checked={isChecked}
        />
        <label htmlFor={surveyItem?.id} className='px-2 survey-name'>
          {surveyItem?.title}
        </label>
      </div>
    );
  };

  const handleDeleteMessage = () => {
    setLoading(true);
    dispatch(userDeleteMessage(messageDelete?.id, handleDeleteMessageSuccess));
  };

  const renderDeleteMess = (id, index) => (
    <PopupDeleteMessage
      id={id}
      index={index}
      setShowPopupConfirm={setShowPopupConfirm}
      setMessageDelete={setMessageDelete}
    />
  );

  const renderAvatarFan = (item) => {
    return (
      <ImgCustom
        onClick={() => {
          if (!isAdmin) {
            dispatch(resetIDHomePage());
            navigate(`${newPathUser}${dataMessages?.to_user?.account_id}`);
            !isAdminLocalStorage && setIsVisibleViewFanDetail(true)
          }
        }}
        src={avatar}
        alt='avatar-fan'
        style={{ cursor: `${isAdmin ? 'default' : 'pointer'}`}}
        className='avatar-user'
        avatarErr={false}
      />
    );
  };

  const changeHoverItem = (index, value) => {
    const cloneListHover = JSON.parse(JSON.stringify(listHoverShow));
    cloneListHover[index] = value || !cloneListHover[index];
    setListHoverShow(cloneListHover);
  };

  const renderTime = (time) => (
    <div className='time'>{moment.utc(time).local().format('YYYY/MM/DD HH:mm')}</div>
  );


  const renderMessage = (messageItem, isUser, index) => {
    const { point, message, medias, survey } = messageItem;
    let messageView;
    switch (messageItem?.message?.type) {
      case 1:
        messageView = (
          <>
            {isUser && renderAvatarFan(messageItem)}
            <div className='type-text'>
              <div
                className='text-icon'
                onMouseEnter={() => {
                  !isAdminLocalStorage && changeHoverItem(index, true);
                }}
                onMouseLeave={() => !isAdminLocalStorage && changeHoverItem(index, false)}>
                <div className='text'>
                  {!isUser && listHoverShow[index] && renderDeleteMess(messageItem?.id, index)}
                  <MarkDownRead isMeta content={message?.content} />
                </div>
              </div>
              {renderTime(message?.updated_at)}
            </div>
          </>
        );
        break;
      case 2:
        messageView = (
          <>
            {isUser && renderAvatarFan(messageItem)}
            <div className='type-img' key={messageItem?.id}>
              <div
                className='text-icon-img'
                onMouseEnter={() => {
                  !isAdminLocalStorage && changeHoverItem(index, true);
                }}
                onMouseLeave={() => !isAdminLocalStorage && changeHoverItem(index, false)}>
                {!isUser && listHoverShow[index] && renderDeleteMess(messageItem?.id, index)}
                <div className='list-img'>
                  {medias?.map(({ id, url, objectUrl }, index) => (
                    <ImgCustom
                      src={url ? url : objectUrl}
                      alt='fail'
                      key={id}
                      screen='post_240_auto_pc'
                      avatarErr={false}
                      onClick={() =>
                        setDataViewFull({
                          image: medias[index],
                          listImage: [
                            ...medias.slice(index, medias.length),
                            ...medias.slice(0, index),
                          ],
                        })
                      }
                    />
                  ))}
                </div>
              </div>
              {renderTime(message?.updated_at)}
            </div>
          </>
        );
        break;
      case 3:
        messageView = (
          <>
            <div
              className='type-video'
              onMouseEnter={() => {
                !isAdminLocalStorage && changeHoverItem(index, true);
              }}
              onMouseLeave={() => !isAdminLocalStorage && changeHoverItem(index, false)}>
              {isUser
                ? renderAvatarFan(messageItem)
                : listHoverShow[index] && renderDeleteMess(messageItem?.id, index)}
              <video
                disablePictureInPicture
                controlsList='nodownload noremoteplayback noplaybackrate foobar'
                controls
                preload='none'
                poster={`${funcThumbnailIMG(decryptPath(medias[0]?.url)) + '#t=0.1'}`}
                src={
                  medias[0]?.url
                    ? linkS3Video + decryptPath(medias[0]?.url) + '#t=0.1'
                    : medias[0]?.objectUrl + '#t=0.1'
                }></video>
              {renderTime(message?.updated_at)}
            </div>
          </>
        );
        break;
      case 4:
        messageView = (
          <>
            {isUser && renderAvatarFan(messageItem)}
            <div className={`type-file ${!survey ? 'delete' : ''}`}>
              <div
                className='text-icon'
                onMouseEnter={() => {
                  !isAdminLocalStorage && changeHoverItem(index, true);
                }}
                onMouseLeave={() => !isAdminLocalStorage && changeHoverItem(index, false)}>
                {!isUser && listHoverShow[index] && renderDeleteMess(messageItem?.id, index)}
                <div
                  onClick={() => {
                    if (survey) {
                      if (isUser) {
                        navigate(`${newPathUser}survey/${survey?.id}`, {
                          state: {
                            avatar: avatar,
                            title: messageItem?.survey?.title,
                            id: messageItem?.from_user_id,
                          },
                        });
                      } else {
                        navigate(`${newPathUser}surveys/${survey?.id}`);
                      }
                    }
                  }}
                  className='file'>
                  <AddSurveyIconV2 className='file-icon' />
                  <span className='name'>
                    {survey ? survey?.title : t('Creator.Message.deletedSurvey')}
                  </span>
                  <ArrowIcon className='arrow-icon' />
                </div>
              </div>

              {renderTime(message?.updated_at)}
            </div>
          </>
        );
        break;
      case 5:
        messageView = (
          <>
            {isUser && renderAvatarFan(messageItem)}
            <div className='type-coin'>
              <div
                className='text-icon'
                onMouseEnter={() => {
                  !isAdminLocalStorage && changeHoverItem(index, true);
                }}
                onMouseLeave={() => !isAdminLocalStorage && changeHoverItem(index, false)}>
                <div className='coin'>
                  {!isUser && listHoverShow[index] && renderDeleteMess(messageItem?.id, index)}
                  {(() => {
                    const srcImg = LIST_POINT_ICON_DONATE.find((x) => {
                      return x.name === point?.icon;
                    });
                    if (srcImg) return <img src={srcImg?.icon} alt='' />;
                  })()}
                  <span className='number'>{formatCoin(point?.point)}</span>
                </div>
              </div>

              {renderTime(message?.updated_at)}
            </div>
          </>
        );
        break;
      default:
        messageView = null;
    }

    return messageView;
  };

  const handleSendCoin = (coin, iconChoice) => {
    setIsVisiblePopupCoin(false);
    const newMess = {
      ...dataMessages?.data?.[0],
      message: {
        content: coin?.point,
        type: 5,
      },
      id: dataMessages?.data?.[0]?.id + 1,
      from_user_id: dataProfile?.id,
      point: {
        icon: iconChoice?.name,
        point: coin?.point,
      },
    };
    setDataMessage({
      ...dataMessages,
      data: [newMess, ...dataMessages?.data],
    });
    // dispatch(getUserInfo());
    // window.scrollTo(0, document.body.scrollHeight);
  };

  return (
    <>
      {dataViewFull?.image?.url && (
        <ViewFullImageLoading
          isDefaultScreen='post_775_720_pc'
          dataViewFull={dataViewFull}
          close={() => setDataViewFull({})}
        />
      )}
      {isVisibleViewFanDetail && (
        <PopupFanDetail
          closeModal={() => setIsVisibleViewFanDetail(false)}
          isVisible={isVisibleViewFanDetail}
          id={id}
        />
      )}
      {showPopupConfirmDelete && (
        <PopupConfirmDelete
          title={t('Popup.confirmDeteleFullMess', {
            name: nameShow,
          })}
          setShowPopup={setShowPopupConfirmDelete}
          handleOnSubmit={handleDelete}
        />
      )}
      {isVisiblePopupBlock && (
        <PopupConfirmDelete
          title={
            statusBlock === 1
              ? t('Popup.confirmUnBlockMessageUser', {
                  name: nameShow,
                })
              : t('Popup.confirmBlockMessageUser', {
                  name: nameShow,
                })
          }
          setShowPopup={setIsVisiblePopupBlock}
          handleOnSubmit={handleBlock}
        />
      )}
      {showPopupConfirm && (
        <PopupConfirmDelete
          title={t('Popup.confirmDelete1Mess')}
          setShowPopup={setShowPopupConfirm}
          handleOnSubmit={handleDeleteMessage}
        />
      )}
      {isVisiblePopupSurvey && (
        <div className='popup-survey'>
          <div className='popup-survey-container'>
            <div className='popup-survey-container_header'>
              <span>{t('Creator.Message.questionnaireList')}</span>
              <CloseIcon
                className='popup-survey-container_header_close-icon'
                onClick={() => setIsVisiblePopupSurvey(false)}
              />
            </div>
            {!loadingGetSurveys && (
              <>
                {listSurvey.length > 0 ? (
                  <div className='popup-survey-container_list'>
                    {listSurvey.map(renderSurveyItem)}
                  </div>
                ) : (
                  <p className='text-center pt-5 pb-5'>{t('Creator.Message.noSurveys')}</p>
                )}
              </>
            )}

            <div className='popup-survey-container_submit'>
              <button
                disabled={!listSurvey.length || !surveyIdChecked}
                className='btn btn-submit btn-lg'
                onClick={sendSurvey}>
                {t('Common.send')}
              </button>
              <button
                className='btn btn-cancel btn-lg'
                onClick={() => setIsVisiblePopupSurvey(false)}>
                {t('Common.buttonCancel')}
              </button>
            </div>
          </div>
        </div>
      )}
      {isVisiblePopupCoin && (
        <SendCoinPopup
          valueCoin={handleSendCoin}
          isVisiblePopup={() => setIsVisiblePopupCoin(false)}
          currentCoin={dataProfile?.points || 0}
          creatorId={id}
          transactionType={1}
        />
      )}
      <LayoutCreatorRight
        titlePage={`${t('Creator.Message.title')}`}
        className='page-message-detail'
        classContainer={'bg-grey'}
        isAdmin={isAdmin}
        noFooter={true}
        cb={() => {
          if (location?.state?.tab === 2) {
            navigate(`${newPathUser}profile/notification`, { state: { tab: 2 } });
          } else {
            if (location?.state?.tab === 1) {
              navigate(`${newPathUser}profile/notification`, { state: { tab: 1 } });
            } else {
              navigate(-1, { state: { tab: 1 } });
            }
          }
        }}>
        {loadingData && (
          <div className='shimmer-loading'>
            <ShimmerPostDetails hasImage imageType='circular' title />
          </div>
        )}
        {!loadingData && (
          <>
            <div className={classNames('creator-block-message')}>
              {dataMessages?.to_user?.id && (
                <>
                  <div
                    style={{ cursor: `${!isAdmin ? 'pointer' : 'default'}`}}
                    className={`creator-block-message-left ${isAdmin && 'isAdmin'}`}
                    onClick={
                      !isAdminLocalStorage
                        ? () => {
                            if (!isAdmin) {
                              dispatch(resetIDHomePage());
                              navigate(`${newPathUser}${dataMessages?.to_user?.account_id}`);
                            }
                          }
                        : null
                    }>
                    <ImgCustom
                      isPlaceholderAvatar
                      screen={!isAdmin && 'recommend_top_56_56'}
                      src={avatar}
                      className={`avatar-right'}`}
                      alt='avatar'
                      avatarErr={false}
                    />
                    <span className={`creator-block-message-left__text`}>{nameShow}</span>
                  </div>
                  <div className='creator-block-message-right'>
                    <div className='creator-block-message-right__hyphen'></div>
                    {!isAdmin && (
                      <div className='creator-block-message-right__block'>
                        <BlockIcon
                          color={statusBlock === 1 && '#dc143c'}
                          onClick={() => {
                            if (!isAdminLocalStorage) {
                              setIsVisiblePopupBlock(true);
                            }
                          }}
                        />
                      </div>
                    )}
                    <div className='creator-block-message-right__delete'>
                      <DeleteIcon
                        onClick={
                          !isAdminLocalStorage ? () => setShowPopupConfirmDelete(true) : null
                        }
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              className={classNames('creator-message-detail', {
                'admin-chat': isAdmin,
                avatar_admin: isAdmin,
              })}>
              <div
                className='content'
                ref={listMessageRef}
                onScroll={scrollLoadMoreMessage}>
                {dataMessages?.data?.map((item, index) => (
                  <div key={item.id} className='item'>
                    {item?.from_user_id !== dataMessages?.to_user?.id ? (
                      <div key={item.id} className='from-me'>
                        {renderMessage(item, false, index)}
                      </div>
                    ) : (
                      <div key={item.id} className={`from-user`}>
                        {renderMessage(item, true)}
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className='post-content'>
                <form
                  onSubmit={!isAdminLocalStorage ? (e) => e.preventDefault() : null}
                  className='fansite-form'>
                  {(videoUpload.length > 0 || imgUpload.length > 0) && (
                    <div className='img-video-wrapper'>
                      {imgUpload?.length > 0 && (
                        <div className='list-img-preview scroll-bar-custom'>
                          {imgUpload.map((img) => (
                            <div key={img} className='img-preview'>
                              <ImgCustom src={img.url} />
                              <CloseIconV2
                                onClick={
                                  !isAdminLocalStorage
                                    ? () => {
                                        imgUpload.length === 0 && setImgUpload([]);
                                        setImgUpload(imgUpload.filter((item) => img !== item));
                                      }
                                    : null
                                }
                                className='remove-icon'
                              />
                            </div>
                          ))}
                        </div>
                      )}
                      {videoUpload?.length > 0 && (
                        <div className='video-preview'>
                          <video src={videoPreview} controls />
                          <CloseIconV2
                            onClick={!isAdminLocalStorage ? () => setVideoUpload([]) : null}
                            className='remove-icon'
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <div className='input-wrapper'>
                    <div className='left'>
                      <InputWrapper
                        id='fsite-text'
                        className={`${
                          videoUpload.length > 0 || imgUpload.length > 0 ? 'have-img' : ''
                        }`}>
                        <textarea
                          {...register('text')}
                          maxLength={500}
                          disabled={isAdminLocalStorage}
                          placeholder={`${t('Creator.Message.pleaseEnter')}...`}
                        />
                        {postDetailMeta?.title && (
                          <MetaTagViewer
                            className='meta-item'
                            title={postDetailMeta?.title}
                            url={postDetailMeta?.url}
                            thumbnail={postDetailMeta?.thumbnail}
                          />
                        )}
                        {!isAdmin && (
                          <CoinIconV2
                            onClick={
                              !isAdminLocalStorage ? () => setIsVisiblePopupCoin(true) : null
                            }
                            className='icon-send-coin'
                          />
                        )}
                        <button
                          onClick={
                            !isAdminLocalStorage && !loading ? handleSubmit(onSubmit) : null
                          }
                          disabled={isDisabledButton || loading}>
                          <SendChatIconV2
                            color={(isDisabledButton || loading) && '#dc143c4d'}
                            className='icon-send-message'
                          />
                        </button>
                      </InputWrapper>
                    </div>

                    <div
                      className={`content-right ${
                        videoUpload.length > 0 || imgUpload.length > 0 ? 'has-video-img' : ''
                      }`}>
                      <div className='box-icon-plus'>
                        <FileIconV2
                          color={isApproved ? (isVisiblePopup ? '#dc143c' : '') : '#bebebe'}
                          className={`icon-plus ${isApproved ? '' : 'disable'} `}
                          onClick={
                            !isAdminLocalStorage
                              ? () => {
                                  if (!isApproved) {
                                    toast.error(t('Creator.Message.featureNotAvailable'), {
                                      position: toast.POSITION.TOP_CENTER,
                                      autoClose: 3000,
                                      hideProgressBar: true,
                                      theme: 'colored',
                                      className: 'display-linebreak',
                                      enableMultiContainer: true,
                                      icon: ({ theme, type }) => (
                                        <svg
                                          width={18}
                                          height={18}
                                          viewBox='0 0 18 18'
                                          fill='none'
                                          xmlns='http://www.w3.org/2000/svg'>
                                          <path
                                            d='M9 15.75C12.7279 15.75 15.75 12.7279 15.75 9C15.75 5.27208 12.7279 2.25 9 2.25C5.27208 2.25 2.25 5.27208 2.25 9C2.25 12.7279 5.27208 15.75 9 15.75Z'
                                            stroke='#DC143C'
                                            strokeWidth='1.5'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                          />
                                          <path
                                            d='M11.25 6.75L6.75 11.25'
                                            stroke='#DC143C'
                                            strokeWidth='1.5'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                          />
                                          <path
                                            d='M11.25 11.25L6.75 6.75'
                                            stroke='#DC143C'
                                            strokeWidth='1.5'
                                            strokeLinecap='round'
                                            strokeLinejoin='round'
                                          />
                                        </svg>
                                      ),
                                    });
                                  } else if (dataProfile?.status !== 7) {
                                    setIsVisiblePopup(!isVisiblePopup);
                                  }
                                }
                              : null
                          }
                          onBlur={
                            !isAdminLocalStorage
                              ? () => {
                                  setTimeout(() => {
                                    setIsVisiblePopup(false);
                                  }, 200);
                                }
                              : null
                          }
                        />
                        <div
                          className={`pop-up ${isAdmin && 'isAdmin'}`}
                          style={{ visibility: isVisiblePopup ? '' : 'hidden' }}>
                          <label
                            className={`item item1 ${
                              (videoUpload?.length > 0 ||
                                imgUpload?.length >= 10 ||
                                !isApproved) &&
                              'disabled'
                            }`}
                            htmlFor='send-image'>
                            <AddPhotoIconV2 className='icon-add' />
                            <span className='text'>{t('Creator.Message.photoAndVideo')}</span>
                          </label>
                          <input
                            id='send-image'
                            type='file'
                            accept={acceptedImgVideoTypes.map((item) => item).join(',')}
                            onChange={handleChangeImgVideo}
                            multiple
                            onClick={(e) => (e.target.value = null)}
                            disabled={
                              (isAdminLocalStorage && videoUpload?.length > 0) ||
                              (isAdminLocalStorage && imgUpload?.length >= 10)
                            }
                          />
                          {!isAdmin && (
                            <div
                              className={`item ${isAdmin ? 'disabled-survey' : ''}`}
                              onClick={() => {
                                setIsVisiblePopup(!isVisiblePopup);
                                if (!isAdmin) {
                                  if (!isVisiblePopupSurvey) {
                                    setLoadingGetSurveys(true);
                                    dispatch(
                                      getListSurvey({ chk_expired: 1 }, handleListSurvey)
                                    );
                                  }
                                  setIsVisiblePopupSurvey(!isVisiblePopupSurvey);
                                }
                              }}>
                              <AddSurveyIconV2 className='icon-add' />
                              <span className='text'>
                                {t('Creator.Message.questionnaire')}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </>
        )}
      </LayoutCreatorRight>
    </>
  );
};

export default Index;
