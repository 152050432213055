import {
  ImgCustom,
  LayoutCreatorRight,
  PopupLoading,
  PopupConfirmFan,
  VideoDetail,
  MenuSidebarCustom,
  AccountSidebar,
  HeaderListIdolV2,
} from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { DeleteRecoreIcon, NoLiveArchive } from 'images';
import { createContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getListRecordingCreator,
  resetActionCreatorHomePage,
  deleteRecording,
  setIdScrollBackRecording,
} from 'store/actions/creator';
import { decryptPath, widthSreen, convertToCurrentTime } from 'utils';
import './index.scss';
import PopupRecordingDetail from 'components/Popup/recordingDetail';
import { LOGO_THUMBNAIL_COCOFAN } from 'utils/constant';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import dayjs from 'dayjs';
import PopupConfirm from 'components/Popup/confirmSuccess';
import { useFixedPopup } from 'hooks/v2/useFixedPopup';

export const TimelineWrapperContext1 = createContext();

const Recording = () => {
  const { t } = useTranslation();
  const {
    paginationRecording: pagination,
    loading,
    listRecording,
    idScrollBackRecording,
    loadingDel
  } = useSelector((state) => state.creator);
  const dispatch = useDispatch();
  const [page, setPage] = useState(
    idScrollBackRecording && pagination?.current_page ? +pagination?.current_page + 1 : 1
  );
  const widthC154 = document?.querySelector('.page-list-short-video.c154')?.offsetWidth;
  const [widthItem, setWidthItem] = useState();
  const [firstLoading, setFirstLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisiblePopupDelSuccess, setIsVisiblePopupDelSuccess] = useState(false);
  const [isVisibleLoading, setIsVisibleLoading] = useState(false);
  const [isVisibleDel, setIsVisibleDel] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [liveKey, setLiveKey] = useState();
  const [listRecord, setListRecord] = useState([]);
  useFixedPopup(isVisible);
  useEffect(() => {
    widthSreen >= 768
      ? setWidthItem((widthC154 - 72) / 2)
      : setWidthItem((widthC154 - 39) / 2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widthSreen, widthC154]);

  useEffect(() => {
    const top = document.getElementById('app_cocofans_0');
    top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
  }, []);
  
  useEffect(() => {
    dispatch(resetActionCreatorHomePage());
  }, []);

  const itemsPerPage = 10;
  useEffect(() => {
    if (!idScrollBackRecording) {
      dispatch(
        getListRecordingCreator({
          page: page,
          limit: itemsPerPage,
        })
      );
    }
  }, [dispatch, idScrollBackRecording, page]);

  useEffect(() => {
    if (idScrollBackRecording && !!listRecord?.length) {
      const idPost = document.getElementById(idScrollBackRecording);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idScrollBackRecording, listRecord]);

  useEffect(() => {
    const tempRecording = [...listRecording];

    const groupedData = tempRecording.reduce((result, item) => {
      const liveDate = item.live_date.split(' ')[0]; // Extract only the date part

      const monthYear = dayjs(liveDate).format('YYYY/MM');
      if (!result[monthYear]) {
        result[monthYear] = [];
      }
      result[monthYear].push(item);
      return result;
    }, {});

    // Convert the groupedData object into an array of arrays
    const groupedArray = Object.entries(groupedData).map(([monthYear, items]) => {
      return { monthYear, items };
    });

    setListRecord(groupedArray);
  }, [listRecording]);

  const GroupIcon = (item) => {
    return (
      <div
        className='GroupIcon scroll-bar-custom-horizontal'
        onClick={() => {
          setLiveKey(item?.id);
          setIsVisible(true);
        }}
        style={{
          pointerEvents: 'auto',
        }}>
        <div className='wrap--text'>
          <p className='mb-0 cls-title fw-bold'>{item?.live_title} </p>
          <div className='bottom-content'>
            <p className='live-date'>
              {convertToCurrentTime(item?.live_date, 'YYYY/MM/DD HH:mm')}
            </p>
            <p className='live-time'>{item?.duration} </p>
          </div>
        </div>
      </div>
    );
  };

  const fetchNext = () => {
    const pages = pagination?.total / pagination?.per_page;
    if (pages > page) {
      if (idScrollBackRecording) {
        dispatch(setIdScrollBackRecording());
      } else {
        setPage(page + 1);
      }
    } else if (pages + 1 > page) {
      if (idScrollBackRecording) {
        dispatch(setIdScrollBackRecording());
      }
    }
  };

  const handleDelete = async (liveKey, popupLiveKey) => {
    setFirstLoading(true);
    setIsVisibleLoading(true);
    dispatch(
      deleteRecording(
        liveKey ? liveKey : popupLiveKey,
        // getListRecordingCreator(
        //   {
        //     page: 1,
        //     limit: itemsPerPage,
        //   },
        //   () => setFirstLoading(false),
        //   () => setFirstLoading(false)
        // )
        null,
        ({ success }) => {
          if (success) {
            setDeleteId(null);
            setIsVisibleLoading(false);
            setIsVisibleDel(false);
            setIsVisiblePopupDelSuccess(true);
            setIsVisible(false);
          }
        },
        () => {
          setDeleteId(null);
          setIsVisibleLoading(false);
          setIsVisibleDel(false);
          setIsVisible(false);
        }
      )
    );
  };

  return (
    <>
      {loadingDel && <PopupLoading className={'popup-loading'} />}
      {isVisible && (
        <PopupRecordingDetail
          closeModal={() => setIsVisible(false)}
          isVisible={isVisible}
          setIsVisibleDel={setIsVisibleDel}
          liveKey={liveKey}
        />
      )}
      {isVisiblePopupDelSuccess && (
        <PopupConfirm
          title={t(`ValidateMsg.DELETE_RECORDINGLIVE_SUCCESS`)}
          closeModal={() => {
            setIsVisiblePopupDelSuccess(false);
          }}
        />
      )}
      {isVisibleDel && (
        <PopupConfirmFan
          text={t('Popup.confirmDeleteRecordingLive')}
          iconNote={true}
          colorSvg='#FF9F43'
          className='popup-confirm-delete-recording'>
          <div className={`btn ok`} onClick={() => handleDelete(deleteId, liveKey)}>
            {t('Common.buttonYes')}
          </div>
          <div
            className={`btn cancel`}
            onClick={() => {
              setDeleteId(null);
              setIsVisibleDel(false);
            }}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}
      <LayoutCreatorRight
        noButtonBack
        className='live-archived-header'
        titlePage={t('MenuSiderbar.creator.recording')}>
        {loading && !listRecord?.length && (
          <div style={{ padding: '16px 24px' }}>
            <ShimmerPostDetails />
          </div>
        )}
        <div id='list-live-archive-id'>
          {listRecord?.length > 0 ? (
            <InfiniteLoad
              loading={loading && !!listRecording.length}
              data={listRecording}
              fetchNext={fetchNext}>
              {listRecord.map((itemParent, index) => (
                <>
                  <div className='time-title'>{itemParent?.monthYear}</div>
                  <div className='page-list-recording c154'>
                    {itemParent?.items?.length > 0 &&
                      itemParent?.items?.map((item, index) => (
                        <div
                          id={`item-recording-${item?.id}`}
                          className='item'
                          key={index}
                          style={{ width: widthItem }}>
                          <div className='delete-recording'>
                            <DeleteRecoreIcon
                              onClick={() => {
                                setDeleteId(item?.id);
                                setIsVisibleDel(true);
                              }}
                            />
                          </div>

                          <div
                            className='thumbnail-video'
                            onClick={() => {
                              setLiveKey(item?.id);
                              setIsVisible(true);
                            }}>
                            {!item?.img_background ||
                            decryptPath(item?.img_background) === 'NULL' ? (
                              <img
                                src={LOGO_THUMBNAIL_COCOFAN}
                                alt=''
                                className='no-background-img'
                              />
                            ) : (
                              <>
                                <div
                                  className='blue-img'
                                  style={{
                                    borderRadius: '10px',
                                    backgroundImage: `url("${decryptPath(
                                      item?.img_background
                                    )}")`,
                                  }}
                                  url={item?.img_background}
                                  height={360}
                                  width={640}>
                                  <div className='bg-img'></div>
                                  <ImgCustom
                                    src={item?.img_background}
                                    className='recording-background'
                                  />
                                </div>
                              </>
                            )}
                          </div>

                          {GroupIcon(item)}
                        </div>
                      ))}
                  </div>
                </>
              ))}
            </InfiniteLoad>
          ) : (
            !loading && (
              <div className='custom_box empty-box'>
                <NoLiveArchive />
                <div>{t('MenuSiderbar.creator.listRecordingNull')}</div>
              </div>
            )
          )}
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default Recording;
