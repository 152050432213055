import { CloseIcon, SendChatIcon } from 'images';
import { useEffect, useState } from 'react';
import { ImgCustom, PopupConfirmFan } from '..';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import { useDispatch, useSelector } from 'react-redux';
import {
  effect_13,
  effect_14,
  effect_16,
  effect_19,
  effect_21,
  effect_3,
  effect_4,
  effect_6,
  effect_7,
  effect_8,
} from 'images';
import { useRef } from 'react';
import {
  fanDonateCreatorLive,
  listEffectCreator,
  resetStatusFanDonate,
} from 'store/actions/lives';

import { CURRENT_USER_KEY } from 'constants/shared';

import Storage from 'utils/storage';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { DBRealTime } from '../../firebase/config';
import { deleteData, getOne, insertData, updateData } from 'hooks/useRealTimeDB';
import { isMobile } from 'react-device-detect';

export const effectShow = {
  effect_13,
  effect_14,
  effect_16,
  effect_19,
  effect_21,
  effect_3,
  effect_4,
  effect_6,
  effect_7,
  effect_8,
};
const SendEffectCreator = ({ isVisiblePopup, ...props }) => {
  const [active, setActive] = useState(null);
  useLockBodyScroll();
  const [stampGift, setStampGift] = useState('');
  const { t } = useTranslation();
  const { liveKey } = useParams();

  const dispatch = useDispatch();

  let { loading, dataListEffectCreator } = useSelector((state) => state.lives);

  useEffect(() => {
    dispatch(listEffectCreator());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSendEffect = (stamp) => {
    isVisiblePopup();
    updateData(`/lives/${liveKey}/effect-creator`, { stamp });
    const timer = setTimeout(() => {
      deleteData(`/lives/${liveKey}/effect-creator`);
    }, 100);

    return () => {
      clearTimeout(timer);
    };
  };
  return (
    <>
      <div className='popup popup-send-coin popup_creator-effect'>
        <div className='popup-content'>
          <CloseIcon className='close-icon' onClick={isVisiblePopup} />
          <div className='box-coin crt-effect_title'>
            {t('Creator.CreateLiveStream.titleStamp')}
          </div>
          <div className={`box-coin ${isMobile ? 'is-mobile' : ''}`}>
            {loading ? (
              <div
                className='spinner-border m-auto'
                role='status'
                style={{ color: '#F90F00', width: '20px', height: '20px' }}></div>
            ) : (
              <>
                {dataListEffectCreator?.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className={`item ${item.id === active && 'active'}`}
                      onClick={() => {
                        setActive(item.id);
                        setStampGift(item.gift);
                      }}>
                      <img
                        src={effectShow[item?.gift]}
                        alt=''
                        style={{
                          width: '50px',
                          height: '50px',
                        }}
                      />
                    </div>
                  );
                })}
              </>
            )}
          </div>
          <div className='box-btn'>
            <div
              className={`btn-submit ${active && 'active'}`}
              onClick={(event) => {
                // event.preventDefault();
                // event.stopPropagation();
                handleSendEffect(stampGift);
              }}>
              <SendChatIcon />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { SendEffectCreator };
