import { useEffect, useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getDetailRecording,
  resetActionDataPopupRecor,
  updateDetailRecording,
} from 'store/actions/creator';
import {
  CustomDropdownIndicator,
  CustomOption,
  ONE_GB,
  TEN_MB,
  TYPE_VIDEOS,
  acceptedImageTypes,
  acceptedImgVideoTypes,
  checkLinkPostSale,
  customStyles,
  customStylesReselect,
  decryptPath,
  failFileThan1Gb,
  failImgInvalid,
  failImgThan10,
  failImgThan10Mb,
  failImgThan4,
  failMoreThan1Video,
  failMoreThan1Video1Img,
  formatCoin,
  formatNumber,
  funcThumbnailIMG,
  getDurationVideo,
  patternNoFirstSpacing,
} from 'utils/utils';
import {
  Checkbox,
  ImgCustom,
  PopupConfirmFan,
  PopupGeneral,
  PopupLoading,
  ReadMoreLess,
  ToggleButton,
  VideoDetail,
} from 'components';
import './index.scss';
import { PlayIcon, ProfileIcon } from 'images';
import {
  AVATAR_DEFAULT,
  BTN_REMOVE,
  convertToCurrentTime,
  getPresignedUrlS3,
  linkS3Video,
  setFileNameS3,
  uploadS3ByPresigned,
} from 'utils';
import LoadingIconV2 from 'images/LoadingIconV2';
import {
  creatorGetPostDetail,
  getListPostCreator,
  postRetweet,
  resetCommentDetail,
  resetIDHomePage,
  updateDetailRecordingV2,
} from 'store/actions/usersNew';
import UploadImageIconV2 from 'images/UploadImageIconV2';
import { InputWrapper } from 'components/Input/InputWrapper';
import { Controller, useForm } from 'react-hook-form';
import { StyledTimeline } from './styled';
import Select from 'react-select';
import CloseIconV2 from 'images/CloseIconV2';
import { isMobile, isTablet } from 'react-device-detect';
import { newPathUser } from 'constants/layout/constant';
import { getEnv } from 'configs/env';
import { isSafari } from '@firebase/util';
import { getInfoMetaPost } from 'store/actions/postMeta';
import MetaTagViewer from 'components/MetaTagViewer/MetaTagViewer';
import UploadImageIcon from 'images/UploadImageIcon';
import { BlueImg4 } from 'pages/creator/layout/post-article/post-content-sale/elements/styled';
import PerformerIcon from 'images/PerformerIcon';
import { useFixedPopup } from 'hooks/v2/useFixedPopup';
import PerformerPopup from 'pages/creator/layout/post-article/post-content/performerPopup';
export const TYPE_NORMAL = 'normal';
export const TYPE_SHORT = 'short';
const QuotePostMyPage = ({
  classNameCustom = '',
  closeModal,
  item,
  isVisible,
  setIsVisibleDel,
  setPage,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { dataUser } = useSelector((state) => state.users);
  const { detailPost, loading, dataInfoOther } = useSelector((state) => state.usersNew);
  const [videoUpload, setVideoUpload] = useState({ img: [], files: null });
  const [checkListImg, setCheckListImg] = useState({ img: [], files: [] });
  const [typePost, setTypePost] = useState(TYPE_NORMAL);
  const [checkPostIn24h, setCheckPostIn24h] = useState(false);
  const [durationVideo, setDurationVideo] = useState(0);
  const [imgUpload, setImgUpload] = useState({ img: [], files: [] });
  const [popupLoading, setPopupLoading] = useState(false);
  const [validImgFirstTime, setValidFirstTime] = useState(false);
  const [imgUpload1, setImgUpload1] = useState([]);
  const [videoUpload1, setVideoUpload1] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const [thumbnail, setThumbnail] = useState('');
  const [percentThumbnail, setPercentThumbnail] = useState([]);
  const refVideo = useRef();
  const [showButton, setShowButton] = useState(true);
  const [thumbnailUpload, setThumbnailUpload] = useState({ img: [], files: null });

  const [isShowPerformerPopup, setIsShowPerformerPopup] = useState(false);
  const [listPerformer, setListPerformer] = useState([]);

  const [isVisiblePopUpDeletePostComplete, setIsVisiblePopDeletePostComplete] =
    useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    clearErrors,
    control,
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: {
      follow_flag: { label: t('Creator.CreateLiveStream.everyone'), value: '0' },
    },
  });

  const [idPostMeta, setIdPostMeta] = useState(null);
  const [postDetailMeta, setPostDetailMeta] = useState(null);

  useFixedPopup(isShowPerformerPopup);

  useEffect(() => {
    dispatch(creatorGetPostDetail(item?.id, false));
  }, []);

  const isApproved = useMemo(() => {
    return dataUser?.status === 1;
  }, [dataUser?.status]);

  const watchContent = watch('content', '');
  const watchFollowFlag = watch('follow_flag', '');

  useEffect(() => {
    const isValidContent = watchContent?.trim() !== '';
    if (isValidContent) {
      const idPost = checkLinkPostSale(watchContent);
      if (idPost) {
        setIdPostMeta(idPost);
      } else {
        setIdPostMeta(null);
        setPostDetailMeta(null);
      }
    } else {
      setPostDetailMeta(null);
      setIdPostMeta(null);
    }
  }, [watchContent]);

  useEffect(() => {
    if (idPostMeta) {
      dispatch(
        getInfoMetaPost(idPostMeta, (dataMeta) => {
          setPostDetailMeta(dataMeta);
        })
      );
    } else {
      setPostDetailMeta(null);
    }
  }, [idPostMeta, dispatch]);

  const isHaveImgOrVideo = useMemo(() => {
    return imgUpload?.files?.length > 0 || videoUpload?.files ? true : false;
  }, [imgUpload, videoUpload]);

  useEffect(() => {
    if (isHaveImgOrVideo) {
      setValidFirstTime(true);
    }
  }, [isHaveImgOrVideo]);

  const check3Quote = (item) => {
    if (
      item?.medias?.length === 0 &&
      item?.share_type === 2 &&
      item?.share_post?.medias?.length > 0 &&
      item?.share_post?.share_post
    ) {
      return true;
    }
    return false;
  };

  const detailPostTMP = check3Quote(item) ? detailPost?.share_post : detailPost;
  const thumbnailTMP = detailPostTMP?.medias?.filter(
    (item) => item?.type === 4 && item?.public_flag === 1
  );

  const isShowErrorPackages = useMemo(() => {
    return watchFollowFlag?.value === '1' && !(imgUpload1?.length > 0 || videoUpload1);
  }, [watchFollowFlag?.value, imgUpload1, videoUpload1]);

  useEffect(() => {
    const { img } = imgUpload;
    if (img.length > 0) {
      const newArrImg = [];
      img.forEach((ele) => {
        newArrImg.push(URL.createObjectURL(ele));
      });
      setImgUpload((prev) => ({ ...prev, files: newArrImg }));
      setImgUpload1(img);
    } else {
      setImgUpload1([]);
    }

    if (videoUpload.img.length > 0) {
      const file = URL.createObjectURL(videoUpload.img[0]);
      setVideoUpload((prev) => ({ ...prev, files: file }));
      setVideoUpload1(videoUpload.img[0]);
    } else {
      setVideoUpload1(null);
    }
  }, [videoUpload.img, imgUpload.img]);

  const disabledButton = useMemo(() => {
    return (isShowErrorPackages || (isHaveImgOrVideo && !listPerformer?.length) );
  }, [isShowErrorPackages, isHaveImgOrVideo, listPerformer]);

  const optionSend = [
    { value: '0', label: t('Creator.CreateLiveStream.everyone') },
    {
      value: '1',
      label: t('Creator.PostArticle.postShort.userFollow'),
    },
  ];

  const refUploadVideo = useRef(null);

  const lengthImages = useMemo(() => {
    if (videoUpload?.img?.length > 0) {
      return 9;
    }
    return 10;
  }, [videoUpload?.img]);

  const handleInputImg = (e) => {
    let sizeImgBiggerTenMb = false;
    let sizeVideoBiggerOneGB = false;
    let checkImg = true;
    let lengthVideoUpload = 0;
    let checkVideos = true;
    let listImages = [];
    let video = null;
    const checkLength = imgUpload.img.length + e.target.files.length;
    for (var i = 0; i < e.target.files.length; i++) {
      if (acceptedImageTypes.includes(e.target.files[i]['type'])) {
        if (e.target.files[i].size > TEN_MB) {
          sizeImgBiggerTenMb = true;
        }
        listImages.push(e.target.files[i]);
      } else if (TYPE_VIDEOS.includes(e.target.files[i]['type'])) {
        video = e.target.files[i];
        if (e.target.files[i].size > ONE_GB) {
          sizeVideoBiggerOneGB = true;
        }
        lengthVideoUpload += 1;
      } else {
        checkImg = false;
      }
    }
    if (lengthVideoUpload > 1 || (videoUpload.img.length > 0 && lengthVideoUpload > 0)) {
      checkVideos = false;
    }

    if (lengthVideoUpload >= 1 && listImages.length >= 1) {
    }

    if (
      e.target.files[0] &&
      checkImg &&
      !sizeImgBiggerTenMb &&
      !sizeVideoBiggerOneGB &&
      checkLength <= lengthImages &&
      checkVideos
    ) {
      setImgUpload((prev) => ({ ...prev, img: [...imgUpload.img, ...listImages] }));
      if (video) {
        getDurationVideo(video, (duration) => {
          setVideoUpload({ files: URL.createObjectURL(video), img: [video] });
          setDurationVideo(duration);
        });
        setVideoUpload({ files: URL.createObjectURL(video), img: [video] });
      }
      setTypePost(TYPE_NORMAL);
    } else {
      !checkVideos && failMoreThan1Video();
      sizeImgBiggerTenMb && failImgThan10Mb();
      sizeVideoBiggerOneGB && failFileThan1Gb();
      checkLength > lengthImages && failImgThan10();
      !checkImg && failImgInvalid();
    }
  };

  const inputImage = () => {
    const len = typeof videoUpload.img === 'string' ? 1 : videoUpload.img?.length;
    const isDisable = len + checkListImg.img.length >= 10 || typePost === TYPE_SHORT;
    return (
      <div
        className={`inputFile ${isDisable && 'disableImgInput'}`}
        onClick={() => refUploadVideo?.current?.click()}>
        <div className='btn-upload'>
          <UploadImageIconV2 />
          <span>{t('Creator.PostArticle.imageUpload')}</span>
        </div>
        <input
          ref={refUploadVideo}
          style={{ display: 'none' }}
          type='file'
          multiple='multiple'
          accept={acceptedImgVideoTypes.map((item) => item).join(',')}
          onChange={handleInputImg}
          onClick={(e) => (e.target.value = null)}
          disabled={isDisable}
        />
      </div>
    );
  };

  // setHeight textarea
  const handleTextarea = (e) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    // if (e.target.value === '') {
    //   e.target.style.height = '86px';
    // }
  };

  const handleShowPopupConfirmFail = () => {
    setPopupLoading(false);
  };

  const handleRemoveImg = (i) => {
    const { img } = imgUpload;
    const removeEle = img.splice(i, 1);
    img.length === 0 && setImgUpload({ img: [], files: [] });
    setImgUpload((prev) => ({
      ...prev,
      img: img.filter((item) => item !== removeEle[0]),
    }));
  };

  const onSubmit = async (data) => {
    setPopupLoading(true);
    let listLinkS3Img = [];
    let listLinkPreSignedImg = [];
    let main_video_pre_signed;
    let main_video = null;
    let thumbnail;
    let newData1 = {
      ...data,
      share_type: 2,
      title: '',
      in_24h_flag: checkPostIn24h ? '1' : '0',
      follow_flag: data?.follow_flag?.value || 0,
      contract_ids: isHaveImgOrVideo ? listPerformer?.map((e) => e.id) : [],
    };

    if (thumbnailUpload?.files) {
      await getPresignedUrlS3(
        'image',
        `uploads/output/images`,
        setFileNameS3(thumbnailUpload?.img[0]?.name),
        'post'
      ).then(async (resUrl) => {
        thumbnail = resUrl.data.file_path;
        setThumbnail(resUrl.data.file_path);
        await uploadS3ByPresigned(
          resUrl.data.pre_signed,
          thumbnailUpload?.img[0],
          setPercentThumbnail,
          percentThumbnail,
          0
        );
      });
    }

    if (videoUpload1) {
      getPresignedUrlS3(
        'video',
        `uploads/output/videos`,
        setFileNameS3(videoUpload1.name),
        'post'
      )
        .then((resUrl) => {
          main_video_pre_signed = resUrl.data.pre_signed;
          main_video = resUrl.data.file_path;
        })
        .then(() => {
          let newData3 = {
            ...newData1,
            duration: +durationVideo * 1000,
            thumbnail,
            main_video,
          };
          uploadS3ByPresigned(main_video_pre_signed, videoUpload1)
            .then(() => {
              if (!imgUpload1?.length || imgUpload1?.length <= 0) {
                dispatch(
                  postRetweet(item?.id, newData3, () => {
                    setPopupLoading(false);
                    setIsVisiblePopDeletePostComplete(true);
                  })
                );
              }
            })
            .catch(() => handleShowPopupConfirmFail());
        })
        .catch((_error) => handleShowPopupConfirmFail());
    }

    if (imgUpload1?.length > 0) {
      const promiseList = imgUpload1.map((item) => {
        return getPresignedUrlS3(
          'image',
          `uploads/output/images`,
          setFileNameS3(item.name),
          'post'
        );
      });
      Promise.all(promiseList)
        .then((responses) => {
          responses.forEach((element) => {
            if (element.success) {
              listLinkS3Img.push(element.data.file_path);
              listLinkPreSignedImg.push(element.data.pre_signed);
            }
          });
          const promiseGenerate = listLinkPreSignedImg.map((m, i) => {
            return uploadS3ByPresigned(m, imgUpload1[i]);
          });
          Promise.all(promiseGenerate).then(() => {
            let newData = {
              ...newData1,
              thumbnail,
              duration: +durationVideo * 1000,
              main_video,
            };
            newData = { ...newData, images: listLinkS3Img };
            dispatch(
              postRetweet(item?.id, newData, () => {
                setPopupLoading(false);
                setIsVisiblePopDeletePostComplete(true);
              })
            );
          });
        })
        .catch((_errors) => handleShowPopupConfirmFail());
    }

    !videoUpload1 &&
      !imgUpload1.length > 0 &&
      dispatch(
        postRetweet(item?.id, newData1, () => {
          setPopupLoading(false);
          setIsVisiblePopDeletePostComplete(true);
        })
      );
  };

  const handleInputThumbnail = (e) => {
    let sizeImgBiggerTenMb = false;
    sizeImgBiggerTenMb = e.target.files[0].size > TEN_MB ? true : false;
    const checkTypes = acceptedImageTypes.includes(e.target.files[0]['type']);
    if (e.target.files[0] && checkTypes && !sizeImgBiggerTenMb) {
      const file = URL.createObjectURL(e.target.files[0]);
      setThumbnailUpload((prev) => ({ ...prev, img: e.target.files, files: file }));
    } else {
      sizeImgBiggerTenMb && failImgThan10Mb(e.target.files[0].size);
      !checkTypes && failImgInvalid(e.target.files[0].name);
    }
  };

  return (
    <>
      {(loading || popupLoading) && (
        <div className='layer-spinner'>
          <LoadingIconV2 className='spinner-icon' />
        </div>
      )}
      {isVisiblePopUpDeletePostComplete && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopDeletePostComplete(e)}
          text={t('Common.quoteSusses')}>
          <div
            className='ok btn'
            onClick={() => {
              const payload = {
                type: 1,
                page: 1,
                limit: 10,
                id: dataUser?.id,
              };
              setPage && setPage(1);
              dispatch(resetIDHomePage());
              dispatch(getListPostCreator(payload, false));
              navigate(`${newPathUser}${dataUser?.account_id}`, {
                state: { dataUserID: dataUser?.id },
              });
              setIsVisiblePopDeletePostComplete(false);
              closeModal(false);
            }}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}
      {isShowPerformerPopup && (
        <PerformerPopup
          listPerformer={listPerformer}
          setListPerformer={setListPerformer}
          title={t('Contract.createContract')}
          handleClosePopup={() => setIsShowPerformerPopup(false)}
        />
      )}
      <>
        <PopupGeneral
          className={`popup-quote-post ${classNameCustom}`}
          generalClass='popup-fan-detail-view1'
          closeModal={() => {
            closeModal(false);
            dispatch(resetActionDataPopupRecor());
            if (['post'].includes(location.pathname.split('/')[1]) === false) {
              dispatch(resetCommentDetail());
            }
          }}
          title={t('Common.QuotePost')}>
          <div className='d-flex block-img'>
            {dataUser?.avatar ? (
              <ImgCustom
                style={{
                  minWidth: 60,
                  maxWidth: 60,
                  minHeight: 60,
                  maxHeight: 60,
                  borderRadius: '50%',
                  objectFit: 'cover',
                }}
                src={`${dataUser?.avatar}`}
                alt=''
                className='cls-img'
              />
            ) : (
              <img src={AVATAR_DEFAULT} alt='AVATAR_DEFAULT' />
            )}

            <div className='wrap--text'>
              <p className='mb-0 cls-title' style={{ fontWeight: '700' }}>
                {dataUser?.account_name || 'no account name'}
              </p>
              <p
                className='cls-title-2nd cls-date'
                style={{ marginTop: '0px', fontSize: '12px' }}>
                {dataUser?.account_id}
              </p>
            </div>
          </div>
          <form onSubmit={(e) => e.preventDefault()} className='fansite-form'>
            <div className='text-are'>
              <InputWrapper
                id='fsite-content'
                label=''
                required={true}
                error={errors?.content ? errors?.content?.message : ''}
                className={`text-post`}>
                <textarea
                  {...register('content', {
                    maxLength: {
                      value: 500,
                      message: t('ValidateMsg.CONTENT_IS_TOO_LONG'),
                    },
                    validate: {
                      noFirstSpacing: (value) => {
                        return value
                          ? !patternNoFirstSpacing.test(value.toString().trim())
                            ? t('ValidateMsg.itemRequired')
                            : clearErrors('content')
                          : clearErrors('content');
                      },
                    },
                    onChange: (e) => handleTextarea(e),
                  })}
                  placeholder={t('Creator.PostArticle.placeholderContent')}
                  onKeyDown={(e) => handleTextarea(e)}
                  maxLength={500}
                />
                {postDetailMeta?.title && (
                  <MetaTagViewer
                    title={postDetailMeta?.title}
                    url={postDetailMeta?.url}
                    thumbnail={postDetailMeta?.thumbnail}
                  />
                )}
              </InputWrapper>
            </div>
            <div className='box-img-video'>
              <div className='list-img-video'>
                <div
                  className={`list-item scroll-bar-custom-horizontal list-imgs ${
                    videoUpload.img.length > 0 && 'has-video'
                  }`}>
                  <>
                    {videoUpload.img.length > 0 && typePost === TYPE_NORMAL && (
                      <div
                        className={`box-item box-video1 ${
                          imgUpload.files.length > 0 ? '' : 'self-video'
                        }`}>
                        <VideoDetail src={videoUpload.files} className='video-item' />
                        <CloseIconV2
                          onClick={() => {
                            setVideoUpload({ img: [], files: null });
                          }}
                        />
                      </div>
                    )}
                    {imgUpload.files.length > 0 &&
                      imgUpload.files.map((item, index) => {
                        return (
                          <>
                            <div
                              key={index}
                              className={`box-item ${
                                imgUpload.img.length > 1 ? 'box-img2' : 'box-img1'
                              }`}>
                              <ImgCustom src={item} />
                              <CloseIconV2 onClick={() => handleRemoveImg(index)} />
                            </div>
                          </>
                        );
                      })}
                  </>
                </div>
              </div>
            </div>
            <div className='content-div-info mt-4'>
              <div
                className={`block-info-text`}
                style={{ borderRadius: `${item?.medias?.length === 0 && '8px'}` }}>
                <div className='d-flex'>
                  {item?.avatar ? (
                    <ImgCustom
                      screen='avatar_44_44'
                      style={{
                        minWidth: 44,
                        maxWidth: 44,
                        minHeight: 44,
                        maxHeight: 44,
                        borderRadius: '50%',
                        objectFit: 'cover',
                      }}
                      src={`${item?.avatar}`}
                      alt=''
                      className='cls-img'
                    />
                  ) : (
                    <img src={AVATAR_DEFAULT} alt='AVATAR_DEFAULT' />
                  )}
                  <div className='wrap--text' style={{ marginTop: '0px' }}>
                    <p
                      className='mb-0 cls-title'
                      style={{ fontSize: '14px', fontWeight: '700' }}>
                      {item?.account_name || 'no account name'}
                    </p>
                    <p
                      className='cls-title-2nd cls-date fw-normal'
                      style={{ fontSize: '12px' }}>
                      {convertToCurrentTime(item?.created_at, 'YYYY/MM/DD HH:mm')}
                    </p>
                  </div>
                </div>
                <div className='remore-text'>
                  <ReadMoreLess content={item?.content} charLimit={130} />
                </div>
                {((item?.medias?.length === 0 && item?.share_type === 2) ||
                  (item?.share_type === 2 &&
                    item?.medias?.length > 0 &&
                    item?.share_post)) && (
                  <div
                    className='mt-3 block-img1 44'
                    style={{
                      whiteSpace: 'pre-wrap',
                      margin: '0',
                      fontWeight: '400',
                    }}>
                    {`${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${item?.id}`}
                  </div>
                )}
                {(!(item?.share_type === 2 && item?.share_post && item?.medias?.length > 0) ||
                  check3Quote(item)) && (
                  <div className='mt-3'>
                    <>
                      {/* case 1 img or video */}
                      {detailPostTMP?.medias?.filter((i) => i.type !== 4)?.length === 1 && (
                        <div
                          style={{
                            display: 'flex',
                            aspectRatio: '16/9',
                            width: '100%',
                            position: 'relative',
                            //padding: '0 16px',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          {detailPostTMP.medias[0]?.type === 1 ? ( // img
                            <StyledTimeline.BlueImg2
                              url={`${decryptPath(detailPostTMP?.medias[0]?.url, {
                                isMini: true,
                              })}`}
                              height={`${isMobile ? 293 : 418}`}
                              width={`${isMobile ? 372 : 640}`}>
                              <div className='bg-img'></div>
                              <ImgCustom
                                screen='post_640_auto_pc'
                                style={{
                                  height: '100%',
                                  width: '100%',
                                  margin: 'auto',
                                  top: '0',
                                  left: '0',
                                  right: '0',
                                  bottom: '0',
                                  objectFit: 'contain',
                                  position: 'absolute',
                                  zIndex: '10',
                                  background: 'transparent',
                                }}
                                src={detailPostTMP.medias[0].url}
                                alt=''
                              />
                            </StyledTimeline.BlueImg2>
                          ) : (
                            // video
                            <>
                              <video
                                style={{ objectFit: 'contain', background: '#000' }}
                                width='100%'
                                height='100%'
                                preload='none'
                                poster={`${
                                  thumbnailTMP?.length === 0
                                    ? funcThumbnailIMG(
                                        decryptPath(detailPostTMP?.medias[0]?.url)
                                      )
                                    : decryptPath(detailPostTMP?.medias[0]?.url) + '#t=0.1'
                                }`}
                                onPlay={() => {
                                  refVideo.current.play();
                                  setShowButton(false);
                                }}
                                onPause={() => {
                                  refVideo.current.pause();
                                  setShowButton(true);
                                }}
                                disablePictureInPicture
                                controlsList='nodownload noremoteplayback noplaybackrate foobar'
                                src={`${linkS3Video}${
                                  decryptPath(
                                    detailPostTMP?.medias?.filter((i) => i.type === 2)[0]?.url
                                  ) + '#t=0.1'
                                }`}
                                ref={refVideo}
                                controls={
                                  isMobile && !isTablet && isSafari ? false : true
                                }></video>
                            </>
                          )}
                          {showButton && (
                            <div
                              style={{
                                width: '100%',
                                height: '77%',
                                position: 'absolute',
                                top: '0',
                                left: '0',
                              }}>
                              <StyledTimeline.ButtonPlay1
                                onClick={(e) => {
                                  e.stopPropagation();
                                  refVideo.current.play();
                                  setShowButton(false);
                                }}>
                                <PlayIcon />
                              </StyledTimeline.ButtonPlay1>
                            </div>
                          )}
                        </div>
                      )}
                      {/* case 2 img or video // case 3 img or video */}
                      {detailPostTMP?.medias?.filter((i) => i.type !== 4)?.length === 2 && (
                        <div
                          style={{
                            display: 'flex',
                            height: '100',
                            width: '100%',
                            //padding: '0 16px',
                            gap: '2px',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}>
                          <div
                            className='position-relative w-100'
                            style={{
                              width: `${isMobile ? '171px' : '319px'}`,
                              height: `${isMobile ? '171px' : '319px'}`,
                            }}>
                            {detailPostTMP?.medias[0]?.type === 1 ? (
                              <StyledTimeline.BlueImg1
                                url={`${decryptPath(detailPostTMP?.medias[0]?.url, {
                                  isMini: true,
                                })}`}
                                height={`${isMobile ? 171 : 319}`}
                                width={`${isMobile ? 185 : 319}`}>
                                <div className='bg-img'></div>
                                <ImgCustom
                                  screen='post_319_auto_pc'
                                  src={detailPostTMP?.medias[0]?.url}
                                  alt=''
                                  style={{
                                    objectFit: 'contain',
                                    position: 'absolute',
                                    zIndex: '10',
                                    background: 'transparent',
                                    width: `${isMobile ? '100%' : '319px'}`,
                                    height: `${isMobile ? '171px' : '319px'}`,
                                  }}
                                />
                              </StyledTimeline.BlueImg1>
                            ) : (
                              <>
                                <video
                                  style={{ objectFit: 'contain', background: '#000' }}
                                  width='100%'
                                  height='100%'
                                  preload='none'
                                  poster={`${
                                    thumbnailTMP?.length === 0
                                      ? funcThumbnailIMG(
                                          decryptPath(detailPostTMP?.medias[0]?.url)
                                        )
                                      : decryptPath(detailPostTMP?.medias[0]?.url) + '#t=0.1'
                                  }`}
                                  onPlay={() => {
                                    refVideo.current.play();
                                    setShowButton(false);
                                  }}
                                  onPause={() => {
                                    refVideo.current.pause();
                                    setShowButton(true);
                                  }}
                                  disablePictureInPicture
                                  controlsList='nodownload noremoteplayback noplaybackrate foobar'
                                  src={`${linkS3Video}${
                                    decryptPath(
                                      detailPostTMP?.medias?.filter((i) => i.type === 2)[0]
                                        ?.url
                                    ) + '#t=0.1'
                                  }`}
                                  ref={refVideo}
                                  controls={
                                    isMobile && !isTablet && isSafari ? false : true
                                  }></video>
                              </>
                            )}
                            {showButton && (
                              <div
                                style={{
                                  width: '100%',
                                  height: '77%',
                                  position: 'absolute',
                                  top: '0',
                                  left: '0',
                                }}>
                                <StyledTimeline.ButtonPlay1
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    refVideo.current.play();
                                    setShowButton(false);
                                  }}>
                                  <PlayIcon />
                                </StyledTimeline.ButtonPlay1>
                              </div>
                            )}
                          </div>
                          <div
                            className='position-relative w-100'
                            style={{
                              width: `${isMobile ? '171px' : '319px'}`,
                              height: 'auto',
                            }}>
                            <StyledTimeline.BlueImg1
                              url={`${decryptPath(
                                detailPostTMP?.medias[`${thumbnailTMP?.length > 0 ? 2 : 1}`]
                                  .url,
                                {
                                  isMini: true,
                                }
                              )}`}
                              height={`${isMobile ? 171 : 319}`}
                              width={`${isMobile ? 185 : 319}`}>
                              <div className='bg-img'></div>
                              <ImgCustom
                                screen='post_319_auto_pc'
                                src={
                                  detailPostTMP?.medias[`${thumbnailTMP?.length > 0 ? 2 : 1}`]
                                    ?.url
                                }
                                alt=''
                                style={{
                                  objectFit: 'contain',
                                  position: 'absolute',
                                  zIndex: '10',
                                  background: 'transparent',
                                  width: `${isMobile ? '100%' : '319px'}`,
                                  height: `${isMobile ? '171px' : '319px'}`,
                                }}
                              />
                            </StyledTimeline.BlueImg1>
                          </div>
                        </div>
                      )}
                      {/* case 3 img or video // case 3 img or video */}
                      {detailPostTMP?.medias?.filter((i) => i.type !== 4)?.length === 3 && (
                        <div
                          style={{
                            display: 'flex',
                            height: `${isMobile ? '343px' : '640px'}`,
                            width: '100%',
                            //padding: '0 16px',
                            gap: '2px',
                          }}>
                          <div className='position-relative w-100'>
                            {detailPostTMP?.medias[0]?.type === 1 ? (
                              <StyledTimeline.BlueImg1
                                url={`${decryptPath(detailPostTMP?.medias[0]?.url, {
                                  isMini: true,
                                })}`}
                                height={`${isMobile ? 343 : 640}`}
                                width={`${isMobile ? 185 : 319}`}>
                                <div className='bg-img'></div>
                                <ImgCustom
                                  style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'contain',
                                    position: 'absolute',
                                    zIndex: '10',
                                    background: 'transparent',
                                  }}
                                  screen='post_319_auto_pc'
                                  src={detailPostTMP?.medias[0]?.url}
                                  alt=''
                                />
                              </StyledTimeline.BlueImg1>
                            ) : (
                              <div className='d-flex align-items-center h-100'>
                                <video
                                  style={{ objectFit: 'contain', background: '#000' }}
                                  width='100%'
                                  height='100%'
                                  preload='none'
                                  poster={`${
                                    thumbnailTMP?.length === 0
                                      ? funcThumbnailIMG(
                                          decryptPath(detailPostTMP?.medias[0]?.url)
                                        )
                                      : decryptPath(detailPostTMP?.medias[0]?.url) + '#t=0.1'
                                  }`}
                                  onPlay={() => {
                                    refVideo.current.play();
                                    setShowButton(false);
                                  }}
                                  onPause={() => {
                                    refVideo.current.pause();
                                    setShowButton(true);
                                  }}
                                  disablePictureInPicture
                                  controlsList='nodownload noremoteplayback noplaybackrate foobar'
                                  src={`${linkS3Video}${
                                    decryptPath(
                                      detailPostTMP?.medias?.filter((i) => i.type === 2)[0]
                                        ?.url
                                    ) + '#t=0.1'
                                  }`}
                                  ref={refVideo}
                                  controls={
                                    isMobile && !isTablet && isSafari ? false : true
                                  }></video>
                              </div>
                            )}
                            {showButton && (
                              <div
                                style={{
                                  width: '100%',
                                  height: '77%',
                                  position: 'absolute',
                                  top: '0',
                                  left: '0',
                                }}>
                                <StyledTimeline.ButtonPlay1
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    refVideo.current.play();
                                    setShowButton(false);
                                  }}>
                                  <PlayIcon />
                                </StyledTimeline.ButtonPlay1>
                              </div>
                            )}
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '2px',
                              width: '100%',
                            }}>
                            <div
                              className='position-relative w-100'
                              style={{ height: '319px' }}>
                              <StyledTimeline.BlueImg1
                                url={`${decryptPath(
                                  detailPostTMP?.medias[`${thumbnailTMP?.length > 0 ? 2 : 1}`]
                                    .url,
                                  {
                                    isMini: true,
                                  }
                                )}`}
                                height={`${isMobile ? 171 : 319}`}
                                width={`${isMobile ? 185 : 319}`}>
                                <div className='bg-img'></div>
                                <ImgCustom
                                  screen='post_319_auto_pc'
                                  style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'contain',
                                    position: 'absolute',
                                    zIndex: '10',
                                    background: 'transparent',
                                  }}
                                  src={
                                    detailPostTMP?.medias[thumbnailTMP?.length > 0 ? 2 : 1]
                                      ?.url
                                  }
                                  alt=''
                                />
                              </StyledTimeline.BlueImg1>
                            </div>
                            <div className='position-relative w-100'>
                              <StyledTimeline.BlueImg1
                                url={`${decryptPath(
                                  detailPostTMP?.medias[thumbnailTMP?.length > 0 ? 3 : 2].url,
                                  {
                                    isMini: true,
                                  }
                                )}`}
                                height={`${isMobile ? 171 : 319}`}
                                width={`${isMobile ? 185 : 319}`}>
                                <div className='bg-img'></div>
                                <ImgCustom
                                  screen='post_319_auto_pc'
                                  style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'contain',
                                    position: 'absolute',
                                    zIndex: '10',
                                    background: 'transparent',
                                  }}
                                  src={
                                    detailPostTMP?.medias[thumbnailTMP?.length > 0 ? 3 : 2]
                                      ?.url
                                  }
                                  alt=''
                                />
                              </StyledTimeline.BlueImg1>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* case >= 4 img or video */}
                      {detailPostTMP?.medias?.filter((i) => i.type !== 4)?.length >= 4 && (
                        <div
                          style={{
                            display: 'flex',
                            height: `${isMobile ? '343px' : '640px'}`,
                            width: '100%',
                            //padding: '0 16px',
                            gap: '2px',
                          }}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '2px',
                              width: '100%',
                            }}>
                            <div className='position-relative w-100'>
                              {detailPostTMP?.medias[0]?.type === 1 ? (
                                <StyledTimeline.BlueImg1
                                  url={`${decryptPath(detailPostTMP?.medias[0]?.url, {
                                    isMini: true,
                                  })}`}
                                  height={`${isMobile ? 171 : 319}`}
                                  width={`${isMobile ? 185 : 319}`}>
                                  <div className='bg-img'></div>
                                  <ImgCustom
                                    screen='post_319_auto_pc'
                                    style={{
                                      height: '100%',
                                      width: '100%',
                                      objectFit: 'contain',
                                      position: 'absolute',
                                      zIndex: '10',
                                      background: 'transparent',
                                    }}
                                    src={detailPostTMP?.medias[0]?.url}
                                    alt=''
                                  />
                                </StyledTimeline.BlueImg1>
                              ) : (
                                <div style={{ height: `${isMobile ? '171px' : '319px'}` }}>
                                  <video
                                    style={{ objectFit: 'contain', background: '#000' }}
                                    width='100%'
                                    height='100%'
                                    preload='none'
                                    poster={`${
                                      thumbnailTMP?.length === 0
                                        ? funcThumbnailIMG(
                                            decryptPath(detailPostTMP?.medias[0]?.url)
                                          )
                                        : decryptPath(detailPostTMP?.medias[0]?.url) + '#t=0.1'
                                    }`}
                                    onPlay={() => {
                                      refVideo.current.play();
                                      setShowButton(false);
                                    }}
                                    onPause={() => {
                                      refVideo.current.pause();
                                      setShowButton(true);
                                    }}
                                    disablePictureInPicture
                                    controlsList='nodownload noremoteplayback noplaybackrate foobar'
                                    src={`${linkS3Video}${
                                      decryptPath(
                                        detailPostTMP?.medias?.filter((i) => i.type === 2)[0]
                                          ?.url
                                      ) + '#t=0.1'
                                    }`}
                                    ref={refVideo}
                                    controls={
                                      isMobile && !isTablet && isSafari ? false : true
                                    }></video>
                                </div>
                              )}
                              {showButton && (
                                <div
                                  style={{
                                    width: '100%',
                                    height: '77%',
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                  }}>
                                  <StyledTimeline.ButtonPlay1
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      refVideo.current.play();
                                      setShowButton(false);
                                    }}>
                                    <PlayIcon />
                                  </StyledTimeline.ButtonPlay1>
                                </div>
                              )}
                            </div>
                            <div className='position-relative w-100'>
                              <StyledTimeline.BlueImg1
                                url={`${decryptPath(
                                  detailPostTMP?.medias[thumbnailTMP?.length > 0 ? 3 : 2].url,
                                  {
                                    isMini: true,
                                  }
                                )}`}
                                height={`${isMobile ? 171 : 319}`}
                                width={`${isMobile ? 185 : 319}`}>
                                <div className='bg-img'></div>
                                <ImgCustom
                                  screen='post_319_auto_pc'
                                  style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'contain',
                                    position: 'absolute',
                                    zIndex: '10',
                                    background: 'transparent',
                                  }}
                                  src={
                                    detailPostTMP?.medias[thumbnailTMP?.length > 0 ? 3 : 2]
                                      ?.url
                                  }
                                  alt=''
                                />
                              </StyledTimeline.BlueImg1>
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '2px',
                              width: '100%',
                            }}>
                            <div className='position-relative w-100'>
                              <StyledTimeline.BlueImg1
                                url={`${decryptPath(
                                  detailPostTMP?.medias[thumbnailTMP?.length > 0 ? 2 : 1].url,
                                  {
                                    isMini: true,
                                  }
                                )}`}
                                height={`${isMobile ? 271 : 319}`}
                                width={`${isMobile ? 185 : 319}`}>
                                <div className='bg-img'></div>
                                <ImgCustom
                                  screen='post_319_auto_pc'
                                  style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'contain',
                                    position: 'absolute',
                                    zIndex: '10',
                                    background: 'transparent',
                                  }}
                                  src={
                                    detailPostTMP?.medias[thumbnailTMP?.length > 0 ? 2 : 1]
                                      ?.url
                                  }
                                  alt=''
                                />
                              </StyledTimeline.BlueImg1>
                            </div>
                            <div className='position-relative w-100 h-100'>
                              <StyledTimeline.BlueImg1
                                url={`${decryptPath(
                                  detailPostTMP?.medias[thumbnailTMP?.length > 0 ? 4 : 3].url,
                                  {
                                    isMini: true,
                                  }
                                )}`}
                                height={`${isMobile ? 171 : 319}`}
                                width={`${isMobile ? 185 : 319}`}>
                                <div className='bg-img'></div>
                                <ImgCustom
                                  screen='post_319_auto_pc'
                                  style={{
                                    height: '100%',
                                    width: '100%',
                                    objectFit: 'contain',
                                    position: 'absolute',
                                    zIndex: '10',
                                    background: 'transparent',
                                  }}
                                  src={
                                    detailPostTMP?.medias[thumbnailTMP?.length > 0 ? 4 : 3]
                                      ?.url
                                  }
                                  alt=''
                                />
                                {detailPostTMP?.medias?.length > 4 && (
                                  <>
                                    <div className='div-blue-img'></div>
                                    <div className='number-img'>
                                      +{Number(detailPostTMP?.medias?.length) - 3}
                                    </div>
                                  </>
                                )}
                              </StyledTimeline.BlueImg1>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  </div>
                )}
              </div>
              {isApproved && (
                <div className='box-btn-upload d-flex align-items-center gap-4 mt-3'>
                  {inputImage()}
                </div>
              )}
              {isShowErrorPackages && (
                <div className='text-err'>
                  {t('Creator.PostArticle.postShort.noImgWhenOnlyFollow')}
                </div>
              )}
              <div className='mt-3'>
                <InputWrapper label={t('Creator.PostArticle.postShort.whoSeePost')}>
                  <Controller
                    name='follow_flag'
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={optionSend}
                        value={optionSend?.find((e) => e?.value === field?.value?.value)}
                        onChange={(val) => {
                          field.onChange(val);
                        }}
                        noOptionsMessage={() => {
                          t('Common.listNull');
                        }}
                        isDisabled={dataUser?.status !== 1}
                        defaultValue={optionSend[0]}
                        isSearchable={false}
                        styles={customStylesReselect}
                        components={{
                          Option: CustomOption,
                          DropdownIndicator: CustomDropdownIndicator,
                        }}
                      />
                    )}
                  />
                </InputWrapper>
              </div>

              {videoUpload1 && (
                <div className='item-wrap mb-3'>
                  <div className='item-label'>{t('Creator.PostArticle.labelThumbnail1')}</div>
                  <div className={`main-thumbnail mt-1`}>
                    {thumbnailUpload.img.length > 0 ? (
                      <>
                        <BlueImg4 url={`${decryptPath(thumbnailUpload.files)}`}></BlueImg4>
                        <ImgCustom src={thumbnailUpload.files} className='cover-img' />
                      </>
                    ) : (
                      <div className='upload-file-btn'>
                        <UploadImageIcon className='icon' />
                        <span className='text'>
                          {t('ValidateMsg.checkThumbnailUploadMultiLine')}
                        </span>
                      </div>
                    )}

                    <input
                      type='file'
                      name='main_thumbnail'
                      accept={acceptedImageTypes.map((item) => item).join(',')}
                      onChange={handleInputThumbnail}
                      onClick={(e) => (e.target.value = null)}
                    />
                    {thumbnailUpload.files && (
                      <img
                        className='zindex20 btn-remove'
                        onClick={() => {
                          setThumbnailUpload({ img: [], files: null });
                        }}
                        src={BTN_REMOVE}
                        alt='remove'
                      />
                    )}
                  </div>
                </div>
              )}
              {isApproved && (
                <div>
                  <InputWrapper className='box-category'>
                    <Checkbox
                      type='checkbox'
                      name={`in_24h_flag`}
                      onChange={(e) => setCheckPostIn24h(e.target.checked)}
                      text={t('Creator.PostArticle.postIn24h')}
                      value={1}
                    />
                  </InputWrapper>
                </div>
              )}
              {isHaveImgOrVideo && (
                <div className='performer-kyc'>
                  <InputWrapper
                    label={t('Creator.PostArticle.postContent.PerformerInformation')}
                    required></InputWrapper>
                  {listPerformer?.length > 0 && (
                    <div className='list-performer'>
                      {listPerformer?.map((item) => {
                        return (
                          <div
                            key={item?.id}
                            id={item?.id}
                            className='item-performer'>{`${item.last_name_romaji} ${item.first_name_romaji}`}</div>
                        );
                      })}
                    </div>
                  )}
                  <div className='btn-performer' onClick={() => setIsShowPerformerPopup(true)}>
                    <PerformerIcon className='performer-icon' />
                    {t('Creator.PostArticle.postContent.PerformerInformation')}
                  </div>
                </div>
              )}
              <div className={`pt-2`}>
                <InputWrapper>
                  <div className='row'>
                    <div className='col-12 col-sm-6 col-md-6 mb-3'>
                      <button
                        className='btn bg-red w-100 font-weight-700 text-white app-btn'
                        type='submit'
                        disabled={disabledButton}
                        onClick={handleSubmit(onSubmit)}>
                        {t('Common.post')}
                      </button>
                    </div>
                    <div className='col-12 col-sm-6 col-md-6 mb-3'>
                      <button
                        className='btn w-100 font-weight-700 border app-btn bg-white cancel'
                        onClick={() => {
                          closeModal(false);
                          dispatch(resetActionDataPopupRecor());
                          if (['post'].includes(location.pathname.split('/')[1]) === false) {
                            dispatch(resetCommentDetail());
                          }
                        }}>
                        {t('Common.buttonCancel')}
                      </button>
                    </div>
                  </div>
                </InputWrapper>
              </div>
            </div>
          </form>
        </PopupGeneral>
      </>
    </>
  );
};

export default QuotePostMyPage;
