import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { HeaderListIdolV2 } from 'components';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import SuccessRegisterIcon from 'images/SuccessRegisterIcon';

import './index.scss';

const WithDrawSuccess = () => {
  const navigate = useNavigate();
  useLockBodyScroll();
  const { t } = useTranslation();

  return (
    <div className='' style={{ backgroundColor: 'transparent' }}>
    <HeaderListIdolV2 />
    <div className='with-draw-container__success'>
    <div className='popup confirm' style={{ backgroundColor: "transparent"}}>
        <div className='popup-content'>
          <div className='content'>
            <SuccessRegisterIcon />
            <div className='text success-text'>
              {t('WithDraw.successfully')}
            </div>
            <div
              className={`btn go-top w-100`}
              onClick={() => navigate('/')}
              style={{ cursor: 'pointer' }}>
              {t('SignIn.login')}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default WithDrawSuccess;
