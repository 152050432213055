import { Textarea } from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import MetaTagViewer from 'components/MetaTagViewer/MetaTagViewer';
import { SendChatIcon } from 'images';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { logUserProfile } from 'store/actions/myPage';
import { getInfoMetaPost } from 'store/actions/postMeta';
import { postCommentDetail } from 'store/actions/usersNew';
import { checkLinkPostSale, isAuth } from 'utils';

const CommentForm = ({ item, index, item2, setTrickReloadComment= () => {}}) => {
  const { t } = useTranslation();
  const { dataUser } = useSelector((state) => state.users);
  const [valueComment, setValueComment] = useState('');
  const [idPostMeta, setIdPostMeta] = useState(null);
  const [checkSendChat, setCheckSendChat] = useState(null);
  const [postDetailMeta, setPostDetailMeta] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (idPostMeta) {
      dispatch(
        getInfoMetaPost(idPostMeta, (dataMeta) => {
          if (dataMeta?.title) {
            setPostDetailMeta(dataMeta);
          } else {
            setPostDetailMeta(null);
          }
        })
      );
    }
  }, [idPostMeta, dispatch]);

  const disableArea = (item) => {
    return (
      (item?.is_followed === 0 && item?.follow_flag === 1) || 
      (!item?.is_media_display && item?.type === 1) ||
      item?.enable_comment === 0 || item?.post_status === 'reject'
    );
  };

  const handlePostComment = (id, parent_id) => {
    const data = {
      parent_id: parent_id,
      content: valueComment,
    };
    checkSendChat &&
      dispatch(
        postCommentDetail(
          id,
          data,
          (data) => {
            setIdPostMeta(null);
            setValueComment('');
            setCheckSendChat(null);
            setPostDetailMeta(null);
            setTrickReloadComment({
              id: id,
              data: data.data,
            });
          },
          postDetailMeta,
          false
        )
      );

    const textareaId = document.getElementById(`comment-item-${id}`);
    if (textareaId) textareaId.style.height = '44px';
  };

  const handleTextarea = (e, id, height) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    const { value } = e.target;
    const idPost = checkLinkPostSale(value);
    if (idPost) {
      setIdPostMeta(idPost);
    } else {
      setIdPostMeta(null);
      setPostDetailMeta(null);
    }
    setValueComment(value);
    setCheckSendChat(id);
    if (e.target.value?.toString().trim() === '') {
      e.target.style.height = height;
      e.target.style.padding = '9px  30px  9px 12px';
      setCheckSendChat(null);
    }
  };

  const checkItem = (item2) => {
    return item2?.share_type === 2 && item2?.share_post?.share_type === 1
      ? item2
      : item2?.share_type === 1 && item2?.share_post?.share_type === 2
      ? item2?.share_post
      : item2?.share_type === 2 && item2?.share_post?.share_type === 2
      ? item2
      : item2?.share_type === 2
      ? item2
      : item2?.share_type === 1
      ? item2?.share_post
      : item2;
  };

  const funcDisableCMT = (item) => {
    const itemCheck = checkItem(item);
    if (item?.is_active === 0) return true;
    if (item?.post_status === 'reject' || item?.post_status === 'reviewing') return true;
    if (
      (itemCheck?.is_followed === 0 &&
        itemCheck?.follow_flag === 1 &&
        itemCheck?.user_id !== dataUser?.id) ||
      (itemCheck?.is_media_display === 0 && itemCheck?.type === 1) ||
      itemCheck?.enable_comment === 0 || itemCheck?.post_status === 'reject'
    ) {
      return true;
    }
    return false;
  };

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <InputWrapper
        id='fsite-comment'
        className={`form-item form-input-comment-meta ${disableArea(item) ? 'disable' : ''} ${
          postDetailMeta?.title ? 'haveMeta' : ''
        }`}>
        <Textarea
          disabled={funcDisableCMT(item2)}
          onChange={(e) => handleTextarea(e, item?.id, '44px')}
          placeholder={t('comment.formAddRepComment.placeholder')}
          onKeyDown={(e) => handleTextarea(e, item?.id, '44px')}
          value={valueComment}
          maxLength='500'
          id={`comment-item-${item?.id}`}
          overflowHidden
          className='input-comment'
          style={{
            background: `${funcDisableCMT(item2) ? '#efefef' : '#fff'}`,
          }}
        />
        {postDetailMeta?.title && (
          <MetaTagViewer
            title={postDetailMeta?.title}
            url={postDetailMeta?.url}
            thumbnail={postDetailMeta?.thumbnail}
          />
        )}
        <SendChatIcon
          style={{
            outline: 'none',
            cursor: `${item?.is_followed === 0 && item?.follow_flag === 1 && 'default'}`,
          }}
          className={`${valueComment && 'active'}`}
          onClick={() => {
            if (!isAuth) {
              dispatch(logUserProfile(item));
            } else {
              valueComment &&
                handlePostComment(item2?.share_type === 2 ? item2?.id : item?.id, 0, index);
                setValueComment('')
            }
          }}
        />
      </InputWrapper>
    </form>
  );
};

export default CommentForm;
