import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { resetActionDataPopupRecor } from 'store/actions/creator';
import './index.scss';
import PopupGeneral from './general';
import { Controller, useForm } from 'react-hook-form';
import { InputWrapper } from 'components/Input/InputWrapper';
import Select from 'react-select';
import { ArrowLeftIcon } from 'images';
import { CustomOption, customStyles } from 'utils';
import { isMobile } from 'react-device-detect';

const PopupPhone = ({ setIsPhone, handalSubmit, setPhone, setCountryCode }) => {
  const dispatch = useDispatch();
  const { listCountries } = useSelector((state) => state.users);
  const { t, i18n } = useTranslation();
  const userState = useSelector((state) => state.users);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'all',
  });

  const optionListContent = useMemo(() => {
    return (
      listCountries?.map((item) => ({
        ...item,
        value: item?.id,
        label: '(' + item?.country_code_number + ')',
      })) || []
    );
  }, [listCountries]);

  const customStylesReSelct = {
    ...customStyles,
    menu: (provided) => ({
      ...provided,
      maxWidth: '100%',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      return { ...provided, opacity, color: '#282727', fontWeight: 500 };
    },
  };
  const [focusNation, setFocusNation] = useState(false);
  const onSubmit = async (data) => {
    handalSubmit(data);
  };

  return (
    <>
      <>
        <PopupGeneral
          className={`popup-quote-post phone `}
          generalClass='popup-fan-detail-view1'
          closeModal={() => {
            setIsPhone(false);
            dispatch(resetActionDataPopupRecor());
          }}
          title={t('Common.verifyCode')}>
          <div className='d-flex block-img' style={{ padding: '20px 24px 0px 24px' }}>
            {t('Common.inputYourCode')}
          </div>
          <form onSubmit={(e) => e.preventDefault()} className='fansite-form1'>
            <div className='row' style={{ padding: '0 24px 24px 24px' }}>
              <div className='mt-3 col-4' >
                <InputWrapper
                  id='fsite-nation'
                  error={errors?.country_id ? errors?.country_id?.message : ''}>
                  <div className='gender'>
                    <Controller
                      name='country_id'
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          ref={field.ref}
                          value={optionListContent?.find((e) => e.value === field.value)}
                          defaultValue={i18n.language === 'jp' ? optionListContent[0] : optionListContent[110]}
                          options={optionListContent}
                          isLoading={userState.listCountryLoading}
                          onFocus={() => setFocusNation(true)}
                          onBlur={() => setFocusNation(false)}
                          onClick={() => setFocusNation(false)}
                          onChange={(val) => {
                            field.onChange(val?.id);
                            setFocusNation(false);
                            setValue('country_code_number', val.label);
                            setCountryCode(val.label);
                          }}
                          className='nation-zindex'
                          maxMenuHeight={100}
                          styles={customStylesReSelct}
                          components={{
                            Option: CustomOption,
                          }}
                          noOptionsMessage={() => t('Common.searchNoResult')}
                        />
                      )}
                    />
                    <ArrowLeftIcon className={`${focusNation ? 'focus' : 'no-focus'}`} />
                  </div>
                </InputWrapper>
              </div>
              <div className='mt-3 col-8' style={{ paddingLeft: '0' }}>
                <InputWrapper
                  required={true}
                  id='fsite-phone-number'
                  error={errors?.phone ? errors?.phone?.message : ''}>
                  <input
                    type='text'
                    className='fsite-input'
                    style={{ height: "46px" }}
                    placeholder={t('Common.enterPhone')}
                    maxLength={14}
                    {...register('phone', {
                      required: t('ValidateMsg.PHONE_IS_REQUIRED'),
                      validate: (value) => {
                        const cleaned = ('' + value).replace(/\D/g, '');
                        const match10 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
                        const match11 = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
                        const match12 = cleaned.match(/^(\d{4})(\d{4})(\d{4})$/);

                        if (value) {
                          if (
                            (!match10 && !match11 && !match12) ||
                            (match12 && match12[1] !== '0120') ||
                            cleaned.substring(0, 1) !== '0'
                          ) {
                            return 'ValidateMsg.C002_VALIDATE_PHONE_FORMAT';
                          }
                        }
                      },
                      onChange: (e) => {
                        const value = e.target.value;
                        const cleaned = ('' + value).replace(/\D/g, '');
                        const match10 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);

                        if (match10) {
                          e.target.value = match10[1] + '-' + match10[2] + '-' + match10[3];
                        }

                        const match11 = cleaned.match(/^(\d{3})(\d{4})(\d{4})$/);
                        if (match11) {
                          e.target.value = match11[1] + '-' + match11[2] + '-' + match11[3];
                        }

                        const match12 = cleaned.match(/^(\d{4})(\d{4})(\d{4})$/);
                        if (match12) {
                          if (match12[1] === '0120') {
                            e.target.value = '0120-' + match12[2] + '-' + match12[3];
                          } else {
                            e.target.value = match12[1] + '-' + match12[2] + '-' + match12[3];
                          }
                        }
                        setPhone(e.target.value);
                      },
                    })}
                  />
                </InputWrapper>
              </div>
            </div>
            <div className='' style={{ padding: '0 24px' }}>
              <InputWrapper>
                <div className='row' style={{ justifyContent: 'end' }}>
                  <div className={`${isMobile ? 'col-4 col-sm-4 col-md-4 mb-3' : 'col-3 col-sm-3 col-md-3 mb-3'}`} style={{ padding: 0}}>
                    <button
                      className='btn bg-red w-100 font-weight-700 text-white app-btn'
                      type='submit'
                      onClick={handleSubmit(onSubmit)}>
                      {t('Common.getCode')}
                    </button>
                  </div>
                  <div className={`${isMobile ? 'col-4 col-sm-4 col-md-4 mb-3' : 'col-3 col-sm-3 col-md-3 mb-3'}`}>
                    <button
                      className='btn w-100 font-weight-700 border app-btn bg-white cancel'
                      onClick={() => {
                        setIsPhone(false);
                      }}>
                      {t('Common.buttonCancel')}
                    </button>
                  </div>
                </div>
              </InputWrapper>
            </div>
          </form>
        </PopupGeneral>
      </>
    </>
  );
};

export default PopupPhone;
