import { PopupPaymentSuccess } from 'components';
import React from 'react';
import '../styles.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PaymentSuccess = ({ point }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div>
      <div className='fansite-payment-success'>
        <PopupPaymentSuccess point={point}>
          <div
            className='btn go-login'
            onClick={() => {
              navigate('/my-page');
            }}>
            {t('Popup.gotoMyPage')}
          </div>
        </PopupPaymentSuccess>
      </div>
    </div>
  );
};

export default PaymentSuccess;
