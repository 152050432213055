const year = new Date().getFullYear();
const listYear = [];
for (let index = 0; index < 50; index++) {
  listYear.push(year - index);
}
const listMonth = [];
for (let index = 1; index < 13; index++) {
  listMonth.push(index);
}

export { listYear, listMonth };
