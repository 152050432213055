import Icon from '@ant-design/icons';

const NextYearIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width='22'
        height='40'
        viewBox='0 0 22 40'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <mask id='path-1-inside-1_3785_224355' fill='white'>
          <path d='M11 15L16 20L11 25L9.93933 23.9393L13.8787 20L9.93933 16.0607L11 15Z' />
        </mask>
        <path
          d='M11 15L16 20L11 25L9.93933 23.9393L13.8787 20L9.93933 16.0607L11 15Z'
          fill='white'
        />
        <path
          d='M16 20L17.0607 21.0607L18.1213 20L17.0607 18.9393L16 20ZM11 15L12.0607 13.9393L11 12.8787L9.93933 13.9393L11 15ZM11 25L9.93933 26.0607L11 27.1213L12.0607 26.0607L11 25ZM9.93933 16.0607L8.87867 15L7.81801 16.0607L8.87867 17.1213L9.93933 16.0607ZM9.93933 23.9393L8.87867 22.8787L7.81801 23.9393L8.87867 25L9.93933 23.9393ZM13.8787 20L14.9393 21.0607L16 20L14.9393 18.9393L13.8787 20ZM17.0607 18.9393L12.0607 13.9393L9.93933 16.0607L14.9393 21.0607L17.0607 18.9393ZM12.0607 26.0607L17.0607 21.0607L14.9393 18.9393L9.93933 23.9393L12.0607 26.0607ZM9.93933 13.9393L8.87867 15L11 17.1213L12.0607 16.0607L9.93933 13.9393ZM8.87867 25L9.93933 26.0607L12.0607 23.9393L11 22.8787L8.87867 25ZM12.818 18.9393L8.87867 22.8787L11 25L14.9393 21.0607L12.818 18.9393ZM8.87867 17.1213L12.818 21.0607L14.9393 18.9393L11 15L8.87867 17.1213Z'
          fill='black'
          fill-opacity='0.25'
          mask='url(#path-1-inside-1_3785_224355)'
        />
        <mask id='path-3-inside-2_3785_224355' fill='white'>
          <path d='M4.93926 15L9.93926 20L4.93926 25L3.8786 23.9393L7.81794 20L3.8786 16.0607L4.93926 15Z' />
        </mask>
        <path
          d='M4.93926 15L9.93926 20L4.93926 25L3.8786 23.9393L7.81794 20L3.8786 16.0607L4.93926 15Z'
          fill='white'
        />
        <path
          d='M9.93926 20L10.9999 21.0607L12.0606 20L10.9999 18.9393L9.93926 20ZM4.93926 15L5.99992 13.9393L4.93926 12.8787L3.8786 13.9393L4.93926 15ZM4.93926 25L3.8786 26.0607L4.93926 27.1213L5.99992 26.0607L4.93926 25ZM3.8786 16.0607L2.81794 15L1.75728 16.0607L2.81794 17.1213L3.8786 16.0607ZM3.8786 23.9393L2.81794 22.8787L1.75728 23.9393L2.81794 25L3.8786 23.9393ZM7.81794 20L8.8786 21.0607L9.93926 20L8.8786 18.9393L7.81794 20ZM10.9999 18.9393L5.99992 13.9393L3.8786 16.0607L8.8786 21.0607L10.9999 18.9393ZM5.99992 26.0607L10.9999 21.0607L8.8786 18.9393L3.8786 23.9393L5.99992 26.0607ZM3.8786 13.9393L2.81794 15L4.93926 17.1213L5.99992 16.0607L3.8786 13.9393ZM2.81794 25L3.8786 26.0607L5.99992 23.9393L4.93926 22.8787L2.81794 25ZM6.75728 18.9393L2.81794 22.8787L4.93926 25L8.8786 21.0607L6.75728 18.9393ZM2.81794 17.1213L6.75728 21.0607L8.8786 18.9393L4.93926 15L2.81794 17.1213Z'
          fill='black'
          fill-opacity='0.25'
          mask='url(#path-3-inside-2_3785_224355)'
        />
      </svg>
    )}
    {...props}
  />
);

export default NextYearIcon;
