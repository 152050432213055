import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .jtf-center {
    display: flex;
    justify-content: center;
  }

  .img-cocofan {
    width: 94px;
    height: 32px;
    }
`;

export const Message = styled.div`
  width: 375px;
  height: 218px;
  background: #ffffff;
  box-shadow: 0px 4px 24px rgb(0 0 0 / 8%);
  display: grid;
`;

export const ContentTitle = styled.div`
  padding-top: 32px;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #ff0000;
`;

export const ContentMess = styled.div`
  padding: 0 20px;
  display: grid;
  margin-bottom: 22px;

  .select-mess {
    display: flex;
    justify-content: center;
    line-height: 0.5;
  }
`;

export const BtnMess = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 32px;
  min-width: 120px;
  height: 44px;
  background: #ffffff;
  border: 1px solid #ff0000;
  border-radius: 6px;
  margin: 0 auto;
  color: #ff0000;
  font-family: 'Noto Sans JP';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.002em;
  flex: none;
  order: 1;
  flex-grow: 0;

  .link-to-top {
    padding: 8px 17px;

    &:hover {
      color: #ff0000;
    }
  }
`;

export const LogoCoco = styled.div`
  margin-top: 8px;
  width: 375px;
  height: 60px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
`;