import moment from 'moment';
import { currentTimeToUTC } from 'utils';

export const handleTimeEdit = (
  isCheckTimeSell,
  date1,
  date2,
  timePost,
  timePostInit,
  timeRelease,
  timeReleaseInit,
  detailPost
) => {
  let publicTime;
  let unPublicTime;
  if (isCheckTimeSell) {
    if (!!date1) {
      publicTime = currentTimeToUTC(new Date(`${date1 + ' ' + timePost}`));
    } else {
      publicTime = currentTimeToUTC(
        new Date(
          `${
            moment
              .utc(detailPost?.public_time || detailPost?.created_at, 'YYYY-MM-DD HH:mm:ss')
              .local()
              .format('YYYY-MM-DD') +
            ' ' +
            timePost
          }`
        )
      );
    }
    if (!!date2) {
      unPublicTime = currentTimeToUTC(new Date(`${date2 + ' ' + timeRelease}`));
    } else {
      unPublicTime = currentTimeToUTC(
        new Date(
          `${
            moment
              .utc(detailPost?.unpublic_time, 'YYYY-MM-DD HH:mm:ss')
              .local()
              .format('YYYY-MM-DD') +
            ' ' +
            timeRelease
          }`
        )
      );
    }
  } else {
    publicTime = undefined;
    unPublicTime = undefined;
  }

  return { publicTime, unPublicTime };
};
