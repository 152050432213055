/* eslint-disable react-hooks/exhaustive-deps */
import {
  AccountSidebar,
  Checkbox,
  CustomSelectTime,
  HeaderListIdolV2,
  LayoutCreatorRight,
  MenuSidebarCustom,
  PopupConfirmFan,
  ReactCalendar,
} from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import { pushNotify } from 'components/Notification';
import { newPathUser } from 'constants/layout/constant';
import withCreatorRole from 'hocs/withCreatorRole';
import useCalendar from 'hooks/useCalendar';
import useOutsideClickCustom from 'hooks/useOutsideClickCustom';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { creatorPostArticle } from 'store/actions/myPage';
import { creatorGetListPackage } from 'store/actions/users';
import {
  acceptIntegerOfInputText,
  checkLinkPostSale,
  convertFullToHalf,
  currentTimeToUTC,
  formatCoin,
  getPresignedUrlS3,
  isProdTrialMode,
  isValidDate,
  patternKatakanaHiraganaFullWidth,
  patternNoFirstSpacing,
  patternOnlyNumber,
  patternRequiredStringAndNumber,
  patterTextJp,
  setFileNameS3,
  sortListPackagePointAndDate,
  textValidateRequired2,
  uploadS3ByPresigned,
} from 'utils';
import 'video-react/dist/video-react.css';
import '../content.scss';
import ListImgVideo from './listImgVideo';
import { useMemo } from 'react';
import { useCallback } from 'react';
import CalendarIconV2 from 'images/CalendarIconV2';
import LoadingIconV2 from 'images/LoadingIconV2';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { isMobile } from 'react-device-detect';
import { useLayoutEffect } from 'react';
import PerformerIcon from 'images/PerformerIcon';
import { useFixedPopup } from 'hooks/v2/useFixedPopup';
import PerformerPopup from './performerPopup';
import MetaTagViewer from 'components/MetaTagViewer/MetaTagViewer';
import { getInfoMetaPost } from 'store/actions/postMeta';
import { CHUNK_FILE, uploadVideoWithMulti } from '../help';
import ProcessingStatusBar from '../components/ProcessingStatusBar';
import { regexInteger } from 'utils/regex';
import { CloseIcon } from 'images';
// import EditorWithHyperLink from 'components/RickEditor';

export const listTab = [
  { id: 1, label: 'Common.post', isTabSale: false, path: 'post-normal/create' },
  {
    id: 2,
    label: 'Creator.PostArticle.titleArticle',
    isTabSale: true,
    path: 'post-content/create',
  },
];

const PostContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { detailPost } = useSelector((state) => state.myPage);
  const { loading: loadingListPackage, dataUser: dataProfile } = useSelector(
    (state) => state.users
  );
  const [valueCoin, setValueCoin] = useState();
  const [valueCoinConvert, setValueCoinConvert] = useState();
  const [valueChange, setValueChange] = useState();
  const [handleOnBlurPrice, setHandleOnBlurPrice] = useState(false);
  const { t } = useTranslation();
  const [isCheckPackagePaid, setIsCheckPackagePaid] = useState(false);
  const [isCheckOnlyCreator, setCheckIsOnlyCreator] = useState(false);
  const [listChecked, setListChecked] = useState([]);
  const [valueListCheck, setValueListCheck] = useState(false);
  const [dataCheckBox, setDataCheckBox] = useState([]);

  const [isChoicePrice, setIsChoicePrice] = useState(true);
  const [isChoicePremium, setIsChoicePremium] = useState(false);

  const [isCheckCondition, setIsCheckCondition] = useState(false);
  const [valuePremiumTicket, setValuePremiumTicket] = useState(false);

  const [timeStartDiscount, setTimeStartDiscount] = useState(null);
  const [timeEndDiscount, setTimeEndDiscount] = useState(null);
  const [isTimeDiscountValid, setIsTimeDiscountValid] = useState(true);

  const [isCheckTimeSell, setIsCheckTimeSell] = useState(false);
  const [isFailTime, setIsFailTime] = useState(false);
  const [timePost, setTimePost] = useState(null);
  const [timePostCheck, setTimePostCheck] = useState(null);
  const [timeRelease, setTimeRelease] = useState(null);
  const [timeReleaseCheck, setTimeReleaseCheck] = useState(null);
  const today = Date.now();
  const [checkValueNumberPart, setCheckValueNumberPart] = useState(false);
  const [creatorListPackage, setCreatorListPackage] = useState([]);
  const [durationVideo, setDurationVideo] = useState(0);
  const [durationVideoTrailer, setDurationVideoTrailer] = useState(0);

  //loading
  const [popupLoading, setPopupLoading] = useState(false);
  const [isVisiblePopUpConfirm1, setIsVisiblePopUpConfirm1] = useState(false);
  const [isVisiblePopUpConfirm2, setIsVisiblePopUpConfirm2] = useState(false);
  const [loading, setLoading] = useState(false);

  //settting get file m3u8
  const location = useLocation();
  const { live_title, recording, thumbnailRecord } = location?.state || {};
  const [imgRecording, setImgRecording] = useState('');
  const [thumbnailRecor, setThumbnailRecor] = useState('');
  // const [isValueEmptyContent, setIsValueEmptyContent] = useState(false);

  // STATE VIDEO - IMG
  const [videoUpload, setVideoUpload] = useState();
  const [trailerUpload, setTrailerUpload] = useState();
  const [thumbnailUpload, setThumbnailUpload] = useState();
  const [listImgUpload, setListImgUpload] = useState();
  const [fileZipUpload, setFileZipUpload] = useState();
  const [datagetPresignedUrlS3, setDatagetPresignedUrlS3] = useState({
    thumbnail: '',
    main_video: '',
    trailer: '',
    images: [],
    zip: '',
  });

  const [errorTimeDurationInput, setErrorTimeDurationInput] = useState(null);
  const [isShowPerformerPopup, setIsShowPerformerPopup] = useState(false);

  const [listPerformer, setListPerformer] = useState([]);
  const [idPostMeta, setIdPostMeta] = useState(null);
  const [postDetailMeta, setPostDetailMeta] = useState(null);

  const [percentUpload, setPercentUpload] = useState([]);
  const [percentImg, setPercentImg] = useState([]);
  const [percentTrailer, setPercentTrailer] = useState([]);
  const [percentThumbnail, setPercentThumbnail] = useState([]);
  const [openProgress, setOpenProgress] = useState(false);

  useFixedPopup(isShowPerformerPopup);

  const isErrorDurationInput = useMemo(() => {
    if (!trailerUpload && errorTimeDurationInput) {
      return true;
    }
    return false;
  }, [trailerUpload, errorTimeDurationInput]);

  const datePickerRef1 = useRef();
  const datePickerRef2 = useRef();
  const datePickerRefStartDiscount = useRef();
  const datePickerRefEndDiscount = useRef();

  const isApproved = useMemo(() => {
    return dataProfile?.status === 1;
  }, [dataProfile?.status]);

  useOutsideClickCustom((e) => {
    if (isVisibleCalendar1) {
      const { className } = e?.target;
      if (
        e?.target !== datePickerRef1?.current &&
        className !== '' &&
        !className.includes('react-calendar')
      ) {
        toggleCalendar1();
      }
    }
  });
  useOutsideClickCustom((e) => {
    if (isVisibleCalendar2) {
      const { className } = e?.target;
      if (
        e?.target !== datePickerRef2?.current &&
        className !== '' &&
        !className.includes('react-calendar')
      ) {
        toggleCalendar2();
      }
    }
  });

  const {
    date: date1,
    toggleCalendar: toggleCalendar1,
    onChangeDate: onChangeDate1,
    isVisibleCalendar: isVisibleCalendar1,
  } = useCalendar();
  const {
    date: date2,
    toggleCalendar: toggleCalendar2,
    onChangeDate: onChangeDate2,
    isVisibleCalendar: isVisibleCalendar2,
  } = useCalendar();
  const {
    date: dateStartDiscount,
    toggleCalendar: toggleCalendarStartDiscount,
    onChangeDate: onChangeDateStartDiscount,
    isVisibleCalendar: isVisibleCalendarStartDiscount,
  } = useCalendar();
  const {
    date: dateEndDiscount,
    toggleCalendar: toggleCalendarEndDiscount,
    onChangeDate: onChangeDateEndDiscount,
    isVisibleCalendar: isVisibleCalendarEndDiscount,
  } = useCalendar();
  const {
    register,
    handleSubmit,
    watch,
    control,
    clearErrors,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({ mode: 'all' });

  //GET PACKAGE OF CREATOR
  useEffect(() => {
    setValue('title', live_title);
    setImgRecording(recording);
    setThumbnailRecor(thumbnailRecord);
  }, []);

  useLayoutEffect(() => {
    if (isApproved) {
      setLoading(true);
      dispatch(
        creatorGetListPackage('', '', ({ data }) => {
          if (data) {
            setCreatorListPackage(data);
            const dataSort = sortListPackagePointAndDate(data);
            setDataCheckBox(dataSort);
          }
          setLoading(false);
        })
      );
    }
  }, [isApproved]);

  // useEffect(() => {
  //   if (+isProdTrialMode === 1) {
  //     setValueCoin(0);
  //   }
  // }, [isProdTrialMode]);

  const top = document.getElementById('app_cocofans_0');
  useEffect(() => {
    top && top.scrollIntoView({ top: 0, left: 0, behavior: 'smooth', block: 'start' });
  }, [top]);

  const watchContent = watch('content', '');
  const watchTitle = watch('title', '');
  const watchDiscount = watch('discount', '');
  const watchNumberPart = watch('number_part', '');
  const watchNumberTicket = watch('number_premium_ticket', '');

  const showErrorDiscount = (itemCheck) => {
    if (
      isChoicePrice &&
      (dateStartDiscount ||
        timeStartDiscount ||
        timeEndDiscount ||
        dateEndDiscount ||
        watchDiscount)
    ) {
      if (itemCheck) {
        return false;
      } else {
        if (
          !dateStartDiscount ||
          !timeStartDiscount ||
          !timeEndDiscount ||
          !dateEndDiscount ||
          !watchDiscount
        ) {
          return true;
        }
        return false;
      }
    }
    return false;
  };

  const checkValidInteger = (value) => {
    if (value) {
      return regexInteger.test(+value);
    }
    return true;
  };

  const checkValidIntegerJP = (value) => {
    if (value) {
      return /^[^.,]+$/.test(+value);
    }
    return true;
  };

  const checkNumberPart = !watchNumberPart
    ? false
    : !patternRequiredStringAndNumber.test(watchNumberPart) ||
      !patternNoFirstSpacing.test(watchNumberPart) ||
      watchNumberPart?.length > 10;

  const checkNumberTicket = isCheckCondition
    ? !watchNumberTicket
      ? true
      : !patternNoFirstSpacing.test(watchNumberTicket) ||
        watchNumberTicket?.length > 10 ||
        +watchNumberTicket < 0
    : false;

  const isDisabledButton =
    (!videoUpload && !fileZipUpload && !recording) ||
    isErrorDurationInput ||
    !watchTitle ||
    !listPerformer?.length ||
    // isValueEmptyContent ||
    !watchContent ||
    // (+isProdTrialMode !== 1 && isChoicePrice && !valueCoin) ||
    (isChoicePremium && !watchNumberTicket) ||
    (isChoicePremium && watchNumberTicket && watchNumberTicket == 0) ||
    (!isChoicePrice && !isChoicePremium) ||
    !isFailTime ||
    !valueListCheck ||
    !thumbnailUpload ||
    popupLoading ||
    loading ||
    isVisiblePopUpConfirm1 ||
    isVisiblePopUpConfirm2 ||
    checkNumberPart ||
    checkNumberTicket ||
    errors?.discount ||
    (isChoicePrice && (valueCoin === null || valueCoin === undefined)) ||
    (isChoicePremium && errors?.number_premium_ticket) ||
    !isTimeDiscountValid ||
    !checkValidInteger(valueCoin) ||
    !checkValidIntegerJP(valueCoin) ||
    showErrorDiscount();
  // setHeight textarea
  const handleTextarea = (e) => {
    if (e.target.scrollHeight > e.target.clientHeight) {
      e.target.style.height = e.target.scrollHeight + 'px';
    }
    var code = e.keyCode || e.charCode;
    if (code === 13) {
      e.target.style.height = 'inherit';
      e.target.style.height = `${e.target.scrollHeight}px`;
    }
    // if (e.target.value === '') {
    //   e.target.style.height = '86px';
    // }
  };

  //START HANDLE VIDEO - IMG
  const handleInputVideo = useCallback((e) => setVideoUpload(e), []);
  const handleInputTrailer = useCallback((e) => setTrailerUpload(e), []);
  const handleInputThumbnail = useCallback((e) => setThumbnailUpload(e), []);
  const handleInputListImg = useCallback((e) => setListImgUpload(e), []);
  const handleInputFileZip = useCallback((e) => setFileZipUpload(e), []);

  //END HANDLE VIDEO - IMG

  //START PACKAGE PAID
  const handleOnChangePackagePaid = (e) => {
    const { value } = e.target;
    if (+value !== 1 || value !== '1') {
      setIsCheckPackagePaid(false);
      setValue('all_package', 0);
    }
    if (+value === 1 || value === '1') {
      setIsCheckPackagePaid(true);
      setValue('paid_package_radio', 1);
    } else {
      setIsCheckPackagePaid(false);
      setValue('paid_package_radio', 2);
    }
  };
  const handleOnchangePlanPost = (e, pack) => {
    const { checked } = e.target;
    if (checked) {
      setListChecked(
        listChecked.concat({
          id: pack.id,
          title: pack.title,
          checked: true,
        })
      );
      const newData = dataCheckBox.map((item) => {
        if (item.id === pack.id) {
          return { ...item, checked: true };
        } else return item;
      });
      setDataCheckBox(newData);
    } else {
      const newData = dataCheckBox.map((item) => {
        if (item.id === pack.id) {
          return { ...item, checked: false };
        } else return item;
      });
      setDataCheckBox(newData);
      setListChecked(listChecked.filter((item) => item.id !== pack.id));
    }
  };
  const handleAllPlanPost = (e) => {
    const { checked } = e.target;
    if (checked) {
      const newData = dataCheckBox.map((item) => {
        return { ...item, checked: true };
      });
      setDataCheckBox(newData);
      setListChecked(newData);
    } else {
      const newData = dataCheckBox.map((item) => {
        return { ...item, checked: false };
      });
      setDataCheckBox(newData);
      setListChecked([]);
    }
  };
  //END PACKAGE PAID
  //START TIME SELL
  const handleOnChangeTimeSell = (e) => {
    const { value } = e.target;
    if (+value === 1 || value === '1') {
      setIsCheckTimeSell(true);
      setValue('time_sell_radio', 1);
    } else {
      setIsCheckTimeSell(false);
      setValue('time_sell_radio', 2);
    }
  };
  //END TIME SELL

  //START CONDITION
  const handleOnChangeCondition = (e) => {
    const { value } = e.target;
    if (+value === 1 || value === '1') {
      setIsCheckCondition(true);
      setValue('condition_radio', 1);
    } else {
      setIsCheckCondition(false);
      setValue('condition_radio', 2);
    }
  };
  //END CONDITION

  //START SUBMIT
  //CHECK SUBMIT
  useEffect(() => {
    //check package
    if (isCheckPackagePaid) {
      listChecked.length > 0 ? setValueListCheck(true) : setValueListCheck(false);
    }
    !isCheckPackagePaid && setValueListCheck(true);

    // check premium ticket
    isCheckCondition && watchNumberTicket
      ? setValuePremiumTicket(true)
      : setValuePremiumTicket(false);

    !isCheckCondition && setValuePremiumTicket(false);
  }, [isCheckCondition, isCheckPackagePaid, listChecked.length, getValues, watchNumberTicket]);

  useEffect(() => {
    if (isCheckTimeSell) {
      const publicTime = new Date(`${date1} ${timePost}`);
      const unPublicTime = new Date(`${date2} ${timeRelease}`);
      if (isValidDate(publicTime) === true) {
        if (isValidDate(unPublicTime) === true) {
          if (publicTime > unPublicTime) {
            setIsFailTime(false);
            pushNotify('error', t('ValidateMsg.END_DAY_AFTER_OR_EQUAL_NOW'));
          } else if (publicTime < today) {
            setIsFailTime(false);
            pushNotify('error', t('ValidateMsg.END_DAY_AFTER_DATE_NOW'));
          } else setIsFailTime(true);
        } else {
          setIsFailTime(false);
        }
      } else {
        setIsFailTime(false);
      }
    } else setIsFailTime(true);
  }, [date1, date2, isCheckTimeSell, timePost, timeRelease]);

  useEffect(() => {
    if (timeStartDiscount && timeEndDiscount && dateStartDiscount && dateEndDiscount) {
      const start = new Date(`${dateStartDiscount} ${timeStartDiscount}`);
      const end = new Date(`${dateEndDiscount} ${timeEndDiscount}`);
      if (start > end) {
        setIsTimeDiscountValid(false);
        pushNotify('error', t('ValidateMsg.DISCOUNT_END_AFTER_OR_EQUAL_START_DAY'));
      } else {
        setIsTimeDiscountValid(true);
      }
    }
  }, [
    timeStartDiscount,
    timeEndDiscount,
    dateStartDiscount,
    dateEndDiscount,
  ]);

  const handleShowPopupComfirmSuccess = (data) => {
    setPopupLoading(false);
    navigate(`${newPathUser}post-sale/${data?.id}`);
  };

  const handleShowPopupComfirmFail = (data) => {
    setPopupLoading(false);
    setOpenProgress(false);
    setPercentThumbnail([]);
    setPercentTrailer([]);
    setPercentImg([]);
    setPercentUpload([]);
  };

  const pcVideo = useMemo(() => {
    let percent = 0;
    if (videoUpload) {
      if (percentUpload?.length) {
        percentUpload.forEach((element) => {
          percent += Number(element);
        });
        percent /= percentUpload?.length;
      }
    }
    return Math.floor(percent);
  }, [percentUpload, videoUpload]);

  const pcImg = useMemo(() => {
    let percent = 0;
    if (listImgUpload?.length) {
      if (percentImg?.length) {
        percentImg.forEach((element) => {
          percent += Number(element);
        });
        percent /= percentImg?.length;
      }
    }
    return Math.floor(percent);
  }, [listImgUpload, percentImg]);

  const pcTrailer = useMemo(() => {
    let percent = 0;
    if (trailerUpload) {
      if (percentTrailer?.length) {
        percentTrailer.forEach((element) => {
          percent += Number(element);
        });
        percent /= percentTrailer?.length;
      }
    }
    return Math.floor(percent);
  }, [percentTrailer, trailerUpload]);

  const pcThumbnail = useMemo(() => {
    let percent = 0;
    if (thumbnailUpload) {
      if (percentThumbnail?.length) {
        percentThumbnail.forEach((element) => {
          percent += Number(element);
        });
        percent /= percentThumbnail?.length;
      }
    }
    return Math.floor(percent);
  }, [percentThumbnail, thumbnailUpload]);

  const countItemUpload = useMemo(() => {
    const countImg = listImgUpload?.length ? 1 : 0;
    const countTrailer = trailerUpload ? 1 : 0;
    const countVideo = videoUpload ? 1 : 0;
    const countThumbnail = thumbnailUpload ? 1 : 0;
    return countImg + countTrailer + countVideo + countThumbnail;
  }, [trailerUpload, listImgUpload, videoUpload, thumbnailUpload]);

  const pcUpload = useMemo(() => {
    return Math.floor((pcVideo + pcImg + pcTrailer + pcThumbnail) / countItemUpload);
  }, [pcVideo, pcImg, pcTrailer, pcThumbnail, countItemUpload]);

  const onSubmit = async (data) => {
    // setPopupLoading(true);
    setOpenProgress(true);
    const hours = new Date().getHours();
    const minutes = new Date().getMinutes();
    let useDateDiscount = false;
    if (isChoicePrice && dateStartDiscount) {
      useDateDiscount = true;
    }
    const newData = {
      ...data,
      creator_only: isCheckOnlyCreator ? 1 : 0,
      discount: useDateDiscount ? data?.discount : null,
      discount_start: useDateDiscount
        ? currentTimeToUTC(new Date(`${dateStartDiscount} ${timeStartDiscount}`))
        : null,
      discount_end: useDateDiscount
        ? currentTimeToUTC(new Date(`${dateEndDiscount} ${timeEndDiscount}`))
        : null,
      package_ids: listChecked,
      public_time: isCheckTimeSell
        ? currentTimeToUTC(new Date(`${date1} ${timePost}`))
        : currentTimeToUTC(new Date(`${date1} ${hours + ':' + minutes}`)),
      unpublic_time: isCheckTimeSell
        ? currentTimeToUTC(new Date(`${date2} ${timeRelease}`))
        : currentTimeToUTC(new Date(`${date2} ${hours + ':' + minutes}`)),
      price: isChoicePrice ? valueCoin : null,
      number_premium_ticket: isChoicePremium ? data?.number_premium_ticket : null,
      all_package:
        creatorListPackage.length > 0
          ? dataCheckBox.length === listChecked.length
            ? 1
            : 0
          : 0,
    };
    setDatagetPresignedUrlS3((prev) => ({ ...prev, ...newData }));
    const newDataUpload = {
      contract_ids: listPerformer?.map((e) => e.id),
      duration_trailer: +durationVideoTrailer * 1000,
      ...datagetPresignedUrlS3,
      ...newData,
    };
    handleConfirmSubmit(newDataUpload);
  };

  const handleConfirmSubmit = async (newDataUpload) => {
    let newData = newDataUpload;
    let main_video_pre_signed;
    let thumbnail_pre_signed;
    let trailer_pre_signed;
    let compressed_file_pre_signed;
    let listLinkS3Img = [];
    let listLinkPreSignedImg = [];

    let checkPostVideoPromise = null;
    if (recording?.length > 0) {
      newData = {
        ...newData,
        main_video: recording,
      };
    } else if (videoUpload) {
      if (videoUpload?.size > CHUNK_FILE) {
        checkPostVideoPromise = new Promise((resolve, reject) => {
          uploadVideoWithMulti(videoUpload, setPercentUpload)
            .then((resUrl) => {
              newData = {
                ...newData,
                duration: +durationVideo * 1000,
                main_video: resUrl.data.file_path,
              };
              resolve();
            })
            .catch(() => {
              reject();
            });
        });
      } else {
        checkPostVideoPromise = new Promise((resolve, reject) => {
          getPresignedUrlS3(
            'video',
            `uploads/output/videos`,
            setFileNameS3(videoUpload.name),
            'post'
          )
            .then((resUrl) => {
              main_video_pre_signed = resUrl.data.pre_signed;
              newData = {
                ...newData,
                duration: +durationVideo * 1000,
                main_video: resUrl.data.file_path,
              };
            })
            .then(() => {
              uploadS3ByPresigned(
                main_video_pre_signed,
                videoUpload,
                setPercentUpload,
                percentUpload,
                0
              )
                .then(() => {
                  resolve();
                })
                .catch((e) => {
                  console.log(e);
                });
            })
            .catch((_error) => {
              reject();
              setPopupLoading(false);
              console.log(_error);
            });
        });
      }
    }

    const checkPostTrailerPromise =
      trailerUpload &&
      new Promise((resolve, reject) => {
        getPresignedUrlS3(
          'video',
          `uploads/output/videos`,
          setFileNameS3(trailerUpload.name),
          'post'
        )
          .then((resUrl) => {
            trailer_pre_signed = resUrl.data.pre_signed;
            newData = {
              ...newData,
              trailer: resUrl.data.file_path,
            };
          })
          .then(() => {
            uploadS3ByPresigned(
              trailer_pre_signed,
              trailerUpload,
              setPercentTrailer,
              percentTrailer,
              0
            )
              .then(() => {
                resolve();
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((_error) => {
            setPopupLoading(false);
            console.log(_error);
            reject();
          });
      });

    const checkPostThumbnailPromise =
      thumbnailUpload &&
      new Promise((resolve, reject) => {
        getPresignedUrlS3(
          'image',
          `uploads/output/images`,
          setFileNameS3(thumbnailUpload.name),
          'post'
        )
          .then((resUrl) => {
            thumbnail_pre_signed = resUrl.data.pre_signed;
            newData = {
              ...newData,
              thumbnail: resUrl.data.file_path,
            };
          })
          .then(() => {
            uploadS3ByPresigned(
              thumbnail_pre_signed,
              thumbnailUpload,
              setPercentThumbnail,
              percentThumbnail,
              0
            )
              .then(() => {
                resolve();
              })
              .catch((e) => {
                console.log(e);
              });
          })
          .catch((_error) => {
            setPopupLoading(false);
            reject();
            console.log(_error);
          });
      });

    const checkPostListImgPromise =
      listImgUpload &&
      new Promise((resolve, reject) => {
        const promiseList = listImgUpload
          .sort((a, b) => a.indexFile - b.indexFile)
          .map((item) => {
            return getPresignedUrlS3(
              'image',
              `uploads/output/images`,
              setFileNameS3(item?.data.name),
              'post'
            );
          });
        Promise.all(promiseList)
          .then((responses) => {
            responses.forEach((element) => {
              if (element.success) {
                listLinkS3Img.push(element.data.file_path);
                listLinkPreSignedImg.push(element.data.pre_signed);
              }
            });
            const promiseGenerate = listLinkPreSignedImg.map((m, i) => {
              return uploadS3ByPresigned(
                m,
                listImgUpload[i].data,
                setPercentImg,
                percentImg,
                i
              );
            });
            Promise.all(promiseGenerate).then(() => {
              newData = {
                ...newData,
                images: listLinkS3Img,
              };
              resolve();
            });
          })
          .catch((_errors) => {
            reject();
            setPopupLoading(false);
            console.log(_errors);
          });
      });

    await Promise.all([
      checkPostVideoPromise,
      checkPostTrailerPromise,
      checkPostThumbnailPromise,
      checkPostListImgPromise,
    ])
      .then(() => {
        dispatch(
          creatorPostArticle(
            newData,
            2,
            handleShowPopupComfirmSuccess,
            handleShowPopupComfirmFail
          )
        );
      })

      .catch((error) => {
        console.log(error);
        setPopupLoading(false);
      });
  };
  //END SUBMIT

  const isTabSale = useMemo(() => {
    if (location?.pathname) {
      return location?.pathname?.split('/').includes('post-content');
    }
    return false;
  }, [location?.pathname]);

  const contentValue = watch('content', '');
  useEffect(() => {
    const isValidContent = contentValue.trim() !== '';
    if (isValidContent) {
      const idPost = checkLinkPostSale(contentValue);
      if (idPost) {
        setIdPostMeta(idPost);
      } else {
        setIdPostMeta(null);
        setPostDetailMeta(null);
      }
    } else {
      setPostDetailMeta(null);
      setIdPostMeta(null);
    }
  }, [contentValue]);

  useEffect(() => {
    if (idPostMeta) {
      dispatch(
        getInfoMetaPost(idPostMeta, (dataMeta) => {
          setPostDetailMeta(dataMeta);
        })
      );
    } else {
      setPostDetailMeta(null);
    }
  }, [idPostMeta, dispatch]);

  const handleChoiceMethod = (method) => {
    if (method === 1) {
      setIsChoicePrice((prev) => !prev);
    } else {
      setIsChoicePremium((prev) => !prev);
    }
    // if (method === 2) {
    //   setTimeStartDiscount(null);
    //   setTimeEndDiscount(null);
    //   setValue('discount', null);
    //   onChangeDateStartDiscount(null);
    //   onChangeDateEndDiscount(null);
    //   setValue('start_time_discount', null);
    //   setValue('end_time_discount', null);
    // }
  };

  return (
    <>
      {openProgress && (
        <div className='layer-spinner'>
          <ProcessingStatusBar percentage={pcUpload} />
        </div>
      )}
      {popupLoading && (
        <div className='layer-spinner'>
          <LoadingIconV2 className='spinner-icon' />
        </div>
      )}
      {isShowPerformerPopup && (
        <PerformerPopup
          listPerformer={listPerformer}
          setListPerformer={setListPerformer}
          title={t('Contract.createContract')}
          handleClosePopup={() => setIsShowPerformerPopup(false)}
        />
      )}

      {isVisiblePopUpConfirm1 && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopUpConfirm1(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.confirmCreatePost')}>
          <div className='ok btn' onClick={handleConfirmSubmit}>
            {t('Common.buttonYes')}
          </div>
          <div className='btn cancel' onClick={() => setIsVisiblePopUpConfirm1(false)}>
            {t('Common.buttonNo')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisiblePopUpConfirm2 && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopUpConfirm2(e)}
          colorSvg='#28C76F'
          iconNote={false}
          text={t('Popup.postCompleted')}>
          <div
            className='ok btn'
            onClick={() => {
              setIsVisiblePopUpConfirm2(false);
              navigate(`${newPathUser}post-sale/${detailPost?.id}`);
            }}>
            {t('Common.buttonYes')}
          </div>
        </PopupConfirmFan>
      )}

      <LayoutCreatorRight
        noButtonBack
        classContainer='post-content-page-article'
        titlePage={t('Creator.PostArticle.titleArticle')}
        className='post-content-header'>
        <div className='fansite-creator-post-content'>
          <div className='tab-container'>
            {listTab.map((v, i) => (
              <div
                style={{ pointerEvents: `${loading || loadingListPackage ? 'none' : 'auto'}` }}
                key={v.id}
                className={`item ${v?.isTabSale === isTabSale ? ` active ` : ``}`}
                onClick={() => {
                  if (v?.isTabSale !== isTabSale) {
                    navigate(`${newPathUser}${v.path}`);
                  }
                }}>
                {t(v.label)}
              </div>
            ))}
          </div>
          {loading || loadingListPackage ? (
            <div style={{ padding: `${isMobile ? '16px' : '16px 24px'}` }}>
              <ShimmerPostDetails className='shimmer-loading' />
            </div>
          ) : (
            <form onSubmit={(e) => e.preventDefault()} className='fansite-form'>
              <ListImgVideo
                main_video={handleInputVideo}
                trailer_video={handleInputTrailer}
                thumbnail={handleInputThumbnail}
                list_img={handleInputListImg}
                file_zip={handleInputFileZip}
                imgRecording={imgRecording}
                thumbnailR={thumbnailRecor}
                setDurationVideo={setDurationVideo}
                setDurationVideoTrailer={setDurationVideoTrailer}
                errorTimeDurationInput={errorTimeDurationInput}
                setErrorTimeDurationInput={setErrorTimeDurationInput}
                isErrorDurationInput={isErrorDurationInput}
              />
              <InputWrapper
                id='fsite-title'
                label={t('Creator.PostArticle.postContent.inputTitle')}
                required
                error={errors?.title ? errors?.title?.message : ''}>
                <textarea
                  {...register('title', {
                    required: 'ValidateMsg.itemRequired',
                    maxLength: {
                      value: 100,
                      message: 'ValidateMsg.TITLE_IS_TOO_LONG',
                    },
                    validate: {
                      noFirstSpacing: (value) =>
                        (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                        'ValidateMsg.itemRequired',
                    },
                    onChange: (e) => handleTextarea(e),
                  })}
                  onKeyDown={(e) => handleTextarea(e)}
                  placeholder={t('Creator.PostArticle.postContent.placeholderInputTitle')}
                  maxLength={100}
                />
              </InputWrapper>
              <InputWrapper
                id='fsite-content'
                label={t('Creator.PostArticle.postContent.content')}
                required={true}
                error={errors?.content ? errors?.content?.message : ''}>
                <div
                  className={`wrapper-content-ogp ${
                    postDetailMeta?.title ? 'have-meta' : ''
                  }`}>
                  <textarea
                    {...register('content', {
                      required: 'ValidateMsg.itemRequired',
                      maxLength: {
                        value: 500,
                        message: 'ValidateMsg.CONTENT_IS_TOO_LONG',
                      },
                      validate: {
                        noFirstSpacing: (value) =>
                          (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                          'ValidateMsg.itemRequired',
                      },
                      onChange: (e) => handleTextarea(e),
                    })}
                    placeholder={t('Creator.PostArticle.postContent.placeholderContent')}
                    onKeyDown={(e) => handleTextarea(e)}
                    maxLength={500}
                    className='area-l-height'
                  />
                  {postDetailMeta?.title && (
                    <MetaTagViewer
                      title={postDetailMeta?.title}
                      url={postDetailMeta?.url}
                      thumbnail={postDetailMeta?.thumbnail}
                    />
                  )}
                </div>
              </InputWrapper>
              <InputWrapper
                id='fsite-price'
                className='group-method'
                label={t('Creator.PostArticle.postContent.paymentMethod')}
                required>
                <div className='point group-haft'>
                  <Checkbox
                    type='checkbox'
                    onChange={() => {
                      handleChoiceMethod(1);
                    }}
                    checked={isChoicePrice}
                    text={t('Common.point')}
                    required
                    value={1}
                    direction='horizontal'
                    className='label-checkbox'
                  />
                  <div
                    className={`item-haft input-price  ${
                      isChoicePrice &&
                      (valueCoin === null ||
                        !checkValidInteger(valueCoin)) &&
                      'add-error-message'
                    }`}
                    after-content={t('Creator.PostArticle.postContent.afterContentPrice')}>
                    <input
                      {...register('price', {
                        maxLength: {
                          value: 7,
                          message: 'ValidateMsg.min6Character',
                        },
                        validate: {
                          inValid: (value) => {
                            const newValue = convertFullToHalf(value);
                            if (
                              newValue &&
                              (patterTextJp.test(value.toString().trim()) ||
                                !patternKatakanaHiraganaFullWidth.test(
                                  value.toString().trim()
                                )) &&
                              !patternOnlyNumber.test(newValue.toString().trim())
                            ) {
                              return 'Creator.PostArticle.postContent.formatPrice';
                            }
                          },
                        },
                        onChange: (e) => {
                          const { value } = e.target;
                          if (value?.length <= 6) {
                            const newValue = convertFullToHalf(value?.toString().trim());
                            setValueChange(newValue);
                            if (+newValue < 0) {
                              setValueCoin(newValue);
                              return e.preventDefault();
                            } else if (
                              patterTextJp.test(newValue) ||
                              !patternOnlyNumber.test(newValue) ||
                              patternKatakanaHiraganaFullWidth.test(value)
                            ) {
                              setValueCoin(newValue);
                              return e.preventDefault();
                            } else if (!newValue) {
                              setValueCoin(null);
                              return e.preventDefault();
                            } else {
                              setValueCoin(+newValue);
                            }
                          }
                        },
                      })}
                      disabled={!isChoicePrice}
                      placeholder={t('Creator.PostArticle.postContent.placeholderPrice')}
                      value={handleOnBlurPrice ? valueCoinConvert : valueCoin}
                      onBlur={() => {
                        setHandleOnBlurPrice(true);
                        if (valueCoin && regexInteger.test(+valueCoin)) {
                          setValueCoinConvert(formatCoin(valueCoin));
                        } else {
                          setValueCoinConvert(valueCoin);
                        }
                        if (!valueCoin && !valueChange && valueCoin !== 0) setValueCoin(null);
                      }}
                      onFocus={() => setHandleOnBlurPrice(false)}
                      onKeyDown={(e) => {
                        const newValue = convertFullToHalf(e.key);
                        !acceptIntegerOfInputText.includes(newValue) && e.preventDefault();
                      }}
                      type='text'
                      maxLength='20'
                    />
                    {isChoicePrice &&
                      (!checkValidInteger(valueCoin) || !checkValidIntegerJP(valueCoin)) && (
                        <div
                          className='error-message-custom'
                          style={{
                            color: '#ff0000',
                          }}>
                          {t('ValidateMsg.POINT_INTEGER_REQUIRED')}
                        </div>
                      )}
                    {isChoicePrice &&
                      checkValidInteger(valueCoin) &&
                      (valueCoin === null) && (
                        <div
                          className='error-message-custom'
                          style={{
                            color: '#ff0000',
                          }}>
                          {valueCoin === null
                            ? t('ValidateMsg.itemRequired')
                            : t('ValidateMsg.minPriceSales')}
                        </div>
                      )}
                  </div>
                </div>
                <div className='premium-ticker group-haft'>
                  <Checkbox
                    type='checkbox'
                    onChange={() => {
                      handleChoiceMethod(2);
                      //setValue('number_premium_ticket', null);
                      clearErrors(['number_premium_ticket'])
                    }}
                    checked={isChoicePremium}
                    text={t('Creator.PostArticle.postContent.premiumTicket1')}
                    required
                    value={2}
                    direction='horizontal'
                    className='label-checkbox mt-24'
                  />
                  <InputWrapper
                    id='fsite-price'
                    className='d-flex justify-content-right flex-column'
                    error={
                      watchNumberTicket
                        ? errors?.number_premium_ticket
                          ? errors?.number_premium_ticket?.message
                          : ''
                        : ''
                    }>
                    <div
                      className={`item-haft input-condition ${
                        isCheckCondition &&
                        !valuePremiumTicket &&
                        'add-error-message add-error-message-custom'
                      }`}
                      after-content={t(
                        'Creator.PostArticle.postContent.afterContentCondition'
                      )}>
                      <input
                        maxLength='4'
                        type='text'
                        placeholder={t('Creator.PostArticle.postContent.placeholderPrice')}
                        disabled={!isChoicePremium}
                        {...register('number_premium_ticket', {
                          maxLength: {
                            value: 5,
                            message: 'ValidateMsg.min4Character',
                          },
                          pattern: {
                            value: /^[^.,]+$/,
                            message: 'ValidateMsg.PREMIUM_INTEGER_REQUIRED',
                          },
                          validate: valuePremiumTicket
                            ? {
                                noFirstSpacing: (value) =>
                                  (value &&
                                    patternNoFirstSpacing.test(value.toString().trim())) ||
                                  'ValidateMsg.min10Character',
                                checkInteger: (value) =>
                                  (value && !/^[^.,]+$/.test(+value)) ||
                                  'ValidateMsg.PREMIUM_INTEGER_REQUIRED',
                              }
                            : {},
                          onChange: (e) => {
                            if (e.target.value && isChoicePremium) {
                              setValuePremiumTicket(true);
                            } else {
                              setValuePremiumTicket(false);
                            }
                          },
                        })}
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) => {
                          return (
                            !acceptIntegerOfInputText.includes(e.key) && e.preventDefault()
                          );
                        }}
                      />
                      {isChoicePremium && watchNumberTicket && watchNumberTicket == 0 && (
                        <div
                          className='error-message-custom'
                          style={{
                            color: '#ff0000',
                          }}>
                          {t('ValidateMsg.minNumberTicket0')}
                        </div>
                      )}
                      {isChoicePremium && !watchNumberTicket && textValidateRequired2()}
                    </div>
                  </InputWrapper>
                </div>
              </InputWrapper>
              <InputWrapper
                className={`discount-group group-haft ${
                  showErrorDiscount(dateStartDiscount) ? 'error-start-discount' : ''
                }`}
                label={t('Creator.PostArticle.postContent.discountLabel')}>
                <div className='discount item-haft mb-12'>
                  <div className='mb-4'>{t('Creator.PostArticle.postContent.discount')}</div>
                  <input
                    type='number'
                    placeholder='10%'
                    disabled={!isChoicePrice}
                    {...register('discount', {
                      pattern: {
                        value: /^[^.,]+$/,
                        message: 'ValidateMsg.DISCOUNT_INTEGER_REQUIRED',
                      },
                      validate: {
                        validDataDiscount: (value) => {
                          if (!!value) {
                            return (
                              (+value >= 1 && +value < 100) || 'ValidateMsg.DISCOUNT_INVALID'
                            );
                          } else {
                            return true;
                          }
                        },
                        checkInteger: (value) => {
                          if (!!value) {
                            return (
                              regexInteger.test(+value) ||
                              'ValidateMsg.DISCOUNT_INTEGER_REQUIRED'
                            );
                          } else {
                            return true;
                          }
                        },
                        // noFirstSpacing: (value) =>
                        //   (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                        //   'ValidateMsg.DISCOUNT_INVALID',
                        // validDataDiscount: (value) =>
                        //   (value && +value >= 1 && +value < 100) ||
                        //   'ValidateMsg.DISCOUNT_INVALID',
                        // checkInteger: (value) =>
                        //   (value && regexInteger.test(+value)) ||
                        //   'ValidateMsg.DISCOUNT_INTEGER_REQUIRED',
                      },
                    })}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={(e) => {
                      return !acceptIntegerOfInputText.includes(e.key) && e.preventDefault();
                    }}
                  />
                  {isChoicePrice && errors?.discount && (
                    <div className='text-error'>{t(errors?.discount?.message)}</div>
                  )}
                  {!errors?.discount?.message &&
                    showErrorDiscount(watchDiscount) &&
                    textValidateRequired2({
                      width: '100%',
                      'white-space': 'pre-wrap',
                      position: 'relative',
                      'margin-top': '4px',
                    })}
                </div>
                <div
                  className={`group-time item-haft ${
                    showErrorDiscount(dateStartDiscount) || showErrorDiscount(timeStartDiscount) ? 'error-start-discount' : ''
                  }`}>
                  <div className='left'>
                    <div className='mb-4'>
                      {t('Creator.PostArticle.postContent.startDate')}
                    </div>
                    <div>
                      <InputWrapper
                        id='fsite-start-time'
                        className={`item-time ${!isChoicePrice ? 'disabled' : ''} `}>
                        <div className='start-time'>
                          <CalendarIconV2
                            onClick={() => {
                              if (isVisibleCalendarStartDiscount) {
                                toggleCalendarEndDiscount();
                              }
                              toggleCalendarStartDiscount();
                            }}
                            color={isVisibleCalendarStartDiscount && '#DC143C'}
                          />
                          {dateStartDiscount && (
                            <CloseIcon
                              className='abc'
                              style={{ right: '35px' }}
                              onClick={() => {
                                onChangeDateStartDiscount(null);
                                setValue('start_time_discount', null);
                                setTimeStartDiscount(null);
                              }}
                            />
                          )}
                          <input
                            placeholder='YYYY/MM/DD'
                            {...register('start_time_discount')}
                            value={dateStartDiscount}
                            readOnly
                            disabled={!isChoicePrice}
                            onClick={() => {
                              if (isVisibleCalendarStartDiscount) {
                                toggleCalendarEndDiscount();
                              }
                              toggleCalendarStartDiscount();
                            }}
                          />
                          {isVisibleCalendarStartDiscount && (
                            <ReactCalendar
                              toggleCalendar={toggleCalendarStartDiscount}
                              minDate={new Date()}
                              onChange={(e) => {
                                onChangeDateStartDiscount(e);
                              }}
                              inputRef={datePickerRefStartDiscount}
                              className='react-calendar-C081_1'
                            />
                          )}
                          {showErrorDiscount(dateStartDiscount) &&
                            textValidateRequired2({
                              width: '100%',
                              'white-space': 'pre-wrap',
                            })}
                        </div>
                        <div style={{ position: 'relative' }}>
                          <CustomSelectTime
                            placeholder='--:--'
                            timePost={(e) => {
                              setTimeStartDiscount(e);
                            }}
                            disabledInput={!isChoicePrice}
                            handleOnClick={() => {
                              if (isVisibleCalendarStartDiscount) {
                                toggleCalendarStartDiscount();
                              }
                              if (isVisibleCalendar2) {
                                toggleCalendarEndDiscount();
                              }
                            }}
                            initTime={timeStartDiscount}
                            checkDisabled={true}
                            showIconV2
                            colorDefaultIcon='#BEBEBE'
                          />
                          {showErrorDiscount(timeStartDiscount) &&
                            textValidateRequired2({
                              width: '100%',
                              'white-space': 'pre-wrap',
                            })}
                        </div>
                      </InputWrapper>
                    </div>
                  </div>
                  <div className='right'>
                    <div className='mb-4'>{t('Creator.PostArticle.postContent.endDate')}</div>
                    <div>
                      <InputWrapper
                        id='fsite-start-time'
                        className={`item-time ${!isChoicePrice ? 'disabled' : ''}`}>
                        <div className='start-time'>
                          <CalendarIconV2
                            onClick={() => {
                              if (isVisibleCalendarStartDiscount) {
                                toggleCalendarStartDiscount();
                              }
                              toggleCalendarEndDiscount();
                            }}
                            color={isVisibleCalendarEndDiscount && '#DC143C'}
                          />
                          {dateEndDiscount && (
                            <CloseIcon
                              style={{ right: '35px' }}
                              onClick={() => {
                                onChangeDateEndDiscount(null);
                                setValue('end_time_discount', null);
                                setTimeEndDiscount(null);
                              }}
                            />
                          )}
                          <input
                            placeholder='YYYY/MM/DD'
                            {...register('end_time_discount')}
                            value={dateEndDiscount}
                            disabled={!isChoicePrice}
                            readOnly
                            onClick={() => {
                              if (isVisibleCalendarStartDiscount) {
                                toggleCalendarStartDiscount();
                              }
                              toggleCalendarEndDiscount();
                            }}
                          />
                          {isVisibleCalendarEndDiscount && (
                            <ReactCalendar
                              toggleCalendar={toggleCalendarEndDiscount}
                              minDate={new Date()}
                              onChange={(e) => {
                                onChangeDateEndDiscount(e);
                              }}
                              inputRef={datePickerRefEndDiscount}
                              className='react-calendar-C081_1'
                            />
                          )}
                          {showErrorDiscount(dateEndDiscount) &&
                            textValidateRequired2({
                              width: '100%',
                              'white-space': 'pre-wrap',
                            })}
                        </div>
                        <div style={{ position: 'relative' }}>
                          <CustomSelectTime
                            placeholder='--:--'
                            timePost={(e) => {
                              setTimeEndDiscount(e);
                            }}
                            disabledInput={!isChoicePrice}
                            handleOnClick={() => {
                              if (isVisibleCalendarEndDiscount) {
                                toggleCalendarEndDiscount();
                              }
                              if (isVisibleCalendarStartDiscount) {
                                toggleCalendarStartDiscount();
                              }
                            }}
                            initTime={timeEndDiscount}
                            checkDisabled={true}
                            showIconV2
                            colorDefaultIcon='#BEBEBE'
                          />
                          {showErrorDiscount(timeEndDiscount) &&
                            textValidateRequired2({
                              width: '100%',
                              'white-space': 'pre-wrap',
                            })}
                        </div>
                      </InputWrapper>
                    </div>
                  </div>
                </div>
              </InputWrapper>
              <InputWrapper
                id='fsite-type-sell'
                className='group-creator-only mt-16 mb-16'
                label={t('Creator.PostArticle.postContent.onlyCreator')}>
                <div className='box-radio-checkbox mb-8'>
                  <Checkbox
                    type='checkbox'
                    onChange={() => {
                      setCheckIsOnlyCreator((prev) => !prev);
                    }}
                    disabled={isCheckPackagePaid}
                    checked={isCheckOnlyCreator}
                    text={t('Creator.PostArticle.postContent.setting')}
                    direction='horizontal'
                  />
                </div>
                <div className='text-creator-info'>
                  {t('Creator.PostArticle.postContent.onlyCreatorInfo')}
                </div>
              </InputWrapper>

              <InputWrapper
                id='fsite-type-sell'
                className='group-plan mt-16 mb-16'
                label={t('Creator.PostArticle.postContent.includePlan')}>
                <div className='box-radio-checkbox'>
                  <Checkbox
                    register={{ ...register('paid_package_radio') }}
                    type='radio'
                    onChange={handleOnChangePackagePaid}
                    checked={!isCheckPackagePaid}
                    text={t('Common.buttonNo')}
                    value={2}
                    direction='horizontal'
                    disabled={isCheckOnlyCreator}
                  />
                  <Checkbox
                    register={{ ...register('paid_package_radio') }}
                    type='radio'
                    onChange={handleOnChangePackagePaid}
                    checked={isCheckPackagePaid}
                    text={t('Common.allPlan2')}
                    value={1}
                    direction='horizontal'
                    disabled={isCheckOnlyCreator}
                  />
                </div>
              </InputWrapper>
              {isCheckPackagePaid && (
                <InputWrapper
                  id='fsite-category'
                  className={`box-category ${
                    isCheckPackagePaid && !listChecked.length > 0 && 'add-error-message'
                  }`}>
                  <div className='box-checkbox scroll-bar-custom list-package'>
                    {dataCheckBox.length > 0 ? (
                      <>
                        {/* check all */}
                        <Checkbox
                          register={{ ...register('all_package') }}
                          type='checkbox'
                          onChange={(e) => handleAllPlanPost(e)}
                          checked={listChecked?.length === dataCheckBox?.length}
                          text={t('Common.allPlan')}
                        />
                        {dataCheckBox.map((item, index) => (
                          <Controller
                            name={`package_ids[${index}]`}
                            control={control}
                            key={index}
                            render={() => {
                              return (
                                <Checkbox
                                  id={item.id}
                                  type='checkbox'
                                  register={{ ...register(`package_ids[${index}]`) }}
                                  onChange={(e) => handleOnchangePlanPost(e, item)}
                                  text={item.title}
                                  checked={item.checked}
                                />
                              );
                            }}
                          />
                        ))}
                      </>
                    ) : (
                      <span style={{ color: '#ff0000' }}>{t('User.listPackageNull')}</span>
                    )}
                  </div>
                  {isCheckPackagePaid && !listChecked.length > 0 && (
                    <div
                      className='error-message-custom'
                      style={{
                        color: '#ff0000',
                      }}>
                      {t('ValidateMsg.selectPlanRequired')}
                    </div>
                  )}
                </InputWrapper>
              )}
              <InputWrapper
                id='fsite-setting'
                className='fsite-setting'
                label={t('Creator.PostArticle.postContent.productNumberSetting')}
                error={
                  watchNumberPart
                    ? errors?.number_part
                      ? errors?.number_part?.message
                      : ''
                    : ''
                }>
                <div className='input-setting'>
                  <input
                    {...register('number_part', {
                      maxLength: {
                        value: 10,
                        message: 'ValidateMsg.min10Character',
                      },
                      validate: checkValueNumberPart
                        ? {
                            noFirstSpacing: (value) =>
                              (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                              'ValidateMsg.min10Character',
                            requiredStringAndNumb: (value) =>
                              (value &&
                                patternRequiredStringAndNumber.test(
                                  value.toString().trim()
                                )) ||
                              'ValidateMsg.enterHaftWidthCharacter',
                          }
                        : {},
                      onChange: (e) => {
                        e.target.value
                          ? setCheckValueNumberPart(true)
                          : setCheckValueNumberPart(false);
                      },
                    })}
                    placeholder={t('Creator.PostArticle.postContent.placeholderPart')}
                  />
                </div>
              </InputWrapper>

              <InputWrapper
                className='group-list-performer'
                label={t('Creator.PostArticle.postContent.listPerformer')}
                required></InputWrapper>
              {listPerformer?.length > 0 && (
                <div className='list-performer'>
                  {listPerformer?.map((item) => {
                    return (
                      <div
                        key={item?.id}
                        id={item?.id}
                        className='item-performer'>{`${item.last_name_romaji} ${item.first_name_romaji}`}</div>
                    );
                  })}
                </div>
              )}
              <div className='btn-performer' onClick={() => setIsShowPerformerPopup(true)}>
                <PerformerIcon className='performer-icon' />
                {t('Creator.PostArticle.postContent.PerformerInformation')}
              </div>
              <InputWrapper
                id='fsite-time-sell'
                className='mt-16 mb-16'
                label={t('Creator.PostArticle.postContent.settingSalesPeriod')}>
                <div className='box-radio-checkbox'>
                  <Checkbox
                    register={{ ...register('time_sell_radio') }}
                    type='radio'
                    onChange={handleOnChangeTimeSell}
                    checked={!isCheckTimeSell}
                    text={t('Creator.PostArticle.notSet')}
                    value={2}
                    direction='horizontal'
                  />
                  <Checkbox
                    register={{ ...register('time_sell_radio') }}
                    type='radio'
                    onChange={handleOnChangeTimeSell}
                    checked={isCheckTimeSell}
                    text={t('Common.buttonUpdate')}
                    value={1}
                    direction='horizontal'
                  />
                </div>
              </InputWrapper>
              {isCheckTimeSell && (
                <div className='d-flex align-items-center d-sm-block '>
                  <div
                    className={`box-time-sell  ${
                      isCheckTimeSell &&
                      (!date1 || !date2 || !timePostCheck || !timeReleaseCheck) &&
                      'add-error-message add-error-message-date'
                    }`}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        flex: 1,
                      }}>
                      <InputWrapper
                        id='fsite-start-time'
                        className={`${
                          isCheckTimeSell && (!date1 || (date1 && !timePostCheck))
                            ? 'have-error'
                            : ''
                        }`}
                        required={true}
                        error={errors?.startTime ? errors?.startTime?.message : ''}>
                        <div className='start-time'>
                          <CalendarIconV2
                            onClick={() => {
                              if (isVisibleCalendar2) {
                                toggleCalendar2();
                              }
                              toggleCalendar1();
                            }}
                            color={isVisibleCalendar1 && '#DC143C'}
                          />
                          <input
                            placeholder='YYYY/MM/DD'
                            {...register('public_time')}
                            value={date1}
                            readOnly
                            onClick={() => {
                              if (isVisibleCalendar2) {
                                toggleCalendar2();
                              }
                              toggleCalendar1();
                            }}
                          />
                          {isVisibleCalendar1 && (
                            <ReactCalendar
                              minDate={new Date()}
                              onChange={(e) => {
                                onChangeDate1(e);
                              }}
                              inputRef={datePickerRef1}
                              className='react-calendar-C081_1'
                            />
                          )}
                          {isCheckTimeSell && !date1 && textValidateRequired2()}
                        </div>
                        <div style={{ position: 'relative' }}>
                          <CustomSelectTime
                            placeholder='--:--'
                            timePost={(e) => {
                              setTimePost(e);
                              setTimePostCheck(e);
                            }}
                            handleOnClick={() => {
                              if (isVisibleCalendar1) {
                                toggleCalendar1();
                              }
                              if (isVisibleCalendar2) {
                                toggleCalendar2();
                              }
                            }}
                            checkDisabled={true}
                            showIconV2
                            colorDefaultIcon='#BEBEBE'
                          />
                        </div>
                        {isCheckTimeSell &&
                          date1 &&
                          !timePostCheck &&
                          textValidateRequired2({
                            left: 'unset',
                            right: '0',
                          })}
                        <span>〜</span>
                      </InputWrapper>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                        flex: 1,
                      }}>
                      <InputWrapper
                        id='fsite-end-time'
                        required={true}
                        error={errors?.endTime ? errors?.endTime?.message : ''}>
                        <div className='end-time'>
                          <CalendarIconV2
                            onClick={() => {
                              if (isVisibleCalendar1) {
                                toggleCalendar1();
                              }
                              toggleCalendar2();
                            }}
                            color={isVisibleCalendar2 && '#DC143C'}
                          />
                          <input
                            placeholder='YYYY/MM/DD'
                            {...register('end_date')}
                            value={date2}
                            readOnly
                            onClick={() => {
                              if (isVisibleCalendar1) {
                                toggleCalendar1();
                              }
                              toggleCalendar2();
                            }}
                          />
                          {isVisibleCalendar2 && (
                            <ReactCalendar
                              minDate={new Date()}
                              onChange={(e) => {
                                onChangeDate2(e);
                              }}
                              customPosition='bottom-right'
                              inputRef={datePickerRef2}
                              className='react-calendar-C081_1'
                            />
                          )}
                          {isCheckTimeSell && !date2 && textValidateRequired2()}
                        </div>
                        <div style={{ position: 'relative' }}>
                          <CustomSelectTime
                            timePost={(e) => {
                              setTimeRelease(e);
                              setTimeReleaseCheck(e);
                            }}
                            handleOnClick={() => {
                              if (isVisibleCalendar1) {
                                toggleCalendar1();
                              }
                              if (isVisibleCalendar2) {
                                toggleCalendar2();
                              }
                            }}
                            placeholder='--:--'
                            checkDisabled={true}
                            classNameCustom='top'
                            showIconV2
                            colorDefaultIcon='#BEBEBE'
                          />
                        </div>
                        {isCheckTimeSell &&
                          date2 &&
                          !timeReleaseCheck &&
                          textValidateRequired2({
                            left: 'unset',
                            right: '0',
                          })}
                      </InputWrapper>
                    </div>
                  </div>
                </div>
              )}
              <InputWrapper id='fsite-submit' className={`button-submit`}>
                <div className='fansite-creator-post-content__footer'>
                  <button
                    onClick={handleSubmit(onSubmit)}
                    className={`btn btn-submit ${isDisabledButton ? 'disabled' : ''}`}
                    disabled={isDisabledButton}>
                    {t('Common.buttonSave')}
                  </button>
                  <button onClick={() => navigate(-1)} className='btn btn-cancel'>
                    {t('Common.buttonCancel')}
                  </button>
                </div>
              </InputWrapper>
            </form>
          )}
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default withCreatorRole(PostContent);
