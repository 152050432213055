import { propsStyled } from 'pages/creator/layout/livestream/components/props';
import styled, { css } from 'styled-components';

const LayoutViewMobile = styled.div`
  display: block;
  height: 100%;
  @media screen and (max-width: 575px), (max-height: 450px) {
    display: block;
    .sendCoin {
      width: 222px;
      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 106px;
      }
    }
    .comment {
      .text {
        overflow-wrap: anywhere;
      }
    }
  }
`;
const LayoutViewPC = styled.div`
  display: block;
  height: 100%;
  @media screen and (min-width: 576px) and (min-height: 450px) {
    display: block;
    .sendCoin {
      width: 222px;
      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 106px;
      }
    }
    .comment {
      .text {
        overflow-wrap: anywhere;
      }
    }
  }

  @media screen and (min-width: 1024px) {
    .subscribe-btn {
      width: 564px;
      max-width: calc(100% - 370px);
    }
  }
`;

const styleInput = css`
  outline: none;
  height: 36px;
  border-radius: 22.5px;
  width: 208px;
  padding: 0 32px 0 12px;
`;
const GiftBox = styled.img``;
const BlockFan = styled.div`
  position: relative;
  .box {
    padding: 10px 10px 47px;
  }
  .form-item-live {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: #f2f3f9;
    border-radius: 0px 0px 20px 20px;
    padding: 8px 10px;
    margin-bottom: 0px;
    .text {
      position: relative;
      width: calc(100% - 40px);
      height: 44px;
      background: #ffffff;
      border: 1px solid #e5e6eb;
      border-radius: 8px;
      padding: 8px 36px 8px 12px;
      &::placeholder {
        color: #bebebe;
      }
      &:focus {
        outline: none;
        border: 1px solid #e5e6eb;
      }
    }
    .button-send {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 22px;
      left: unset;
      svg {
        color: #bebebe;
      }
      &.active {
        svg {
          color: #dc143c;
        }
      }
    }
    .icon-send-coin-live {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 58px;
      left: unset;
    }
  }
  ${propsStyled.PropsDiv};
  input {
    ${({ ipfan }) => ipfan && styleInput}
  }
  .anticon {
    ${({ posicon }) =>
      posicon &&
      css`
        position: absolute;
        left: 180px;
        top: 9px;
        color: #b1b6bb;
      `}
    &.active {
      color: #ff0000;
    }
  }
  ${GiftBox} {
    position: absolute;
    right: 6px;
    bottom: 0px;
  }
  cursor: pointer;
`;
const ContentChatFan = styled.div`
  position: relative;
  ${propsStyled.PropsDiv};
  height: 100%;
  .box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 24px 10px 56px;
    .comment {
      margin-top: 0;
    }
    .text {
      overflow-wrap: anywhere;
      color: ${propsStyled.color[2]} !important;
    }
    .sendCoin {
      .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 116px;
      }
      .text {
        color: ${propsStyled.color[1]} !important;
      }
      .coin {
        margin-left: -10px;
      }
    }
  }
`;

const WrapperAutoExpireStampsView = styled.div`
  position: absolute;
  top: 107px;
  left: 10px;
  width: 100%;
`;

const FormChatFan = styled.div`
  position: relative;
  ${propsStyled.PropsDiv};
`;
const GroupFeatureFan = styled.div`
  position: absolute;
  z-index: 99;
  bottom: -64px;
  display: flex;
  gap: 16px;
  right: 0px;
  @media screen and (max-width: 1023px) {
    right: unset;
    left: 50%;
    bottom: -60px;
    transform: translateX(-50%);
  }
  button {
    background: #fff;
    border: 1px solid #eeeeee;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    padding: 0;
    img {
      width: 44px;
      height: 44px;
    }
  }
`;

const CopyValueReview = styled.div`
  position: absolute;
  background: #1a1a1a;
  border-radius: 8px;
  color: #eeeeee;
  width: max-content;
  padding: 8px 10px;
  font-weight: 400;
  font-size: 12px;
  bottom: -40px;
  top: 30px;
  right: 0;
  &.watch-live {
    top: -44px;
    left: 50%;
    transform: translateX(-50%);
  }
  ${({ review }) => review && `right: 250px;`}
  ${({ right }) => right && `right: ${right}`}
  @media screen and (max-width: 575px) {
    ${({ review }) => review && `right: 16px;`}
  }
`;
const CopyValue = styled.div`
  position: absolute;
  background: #1a1a1a;
  border-radius: 8px;
  color: #eeeeee;
  width: max-content;
  padding: 8px 10px;
  font-weight: 400;
  font-size: 12px;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  &::after {
    content: '';
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    width: 7px;
    height: 6px;
    background: #282727;
    position: absolute;
    top: 1px;
    left: 50%;
    transform: translate(-50%, -100%);
  }
  &.watch-live {
    top: -44px;
    left: 50%;
    transform: translateX(-50%);
  }
  ${({ review }) => review && `right: 250px;`}
  ${({ right }) => right && `right: ${right}`}
  @media screen and (max-width: 575px) {
    ${({ review }) => review && `right: 16px;`}
  }
`;

const CopyValueView = styled.div`
  position: absolute;
  background: #1a1a1a;
  border-radius: 8px;
  color: #eeeeee;
  width: max-content;
  padding: 8px 10px;
  font-weight: 400;
  font-size: 12px;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  &::after {
    content: '';
    -webkit-clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    width: 7px;
    height: 6px;
    background: #282727;
    position: absolute;
    top: 1px;
    left: 50%;
    transform: translate(-50%, -100%);
  }
  &.watch-live {
    left: 50%;
    transform: translateX(-50%);
  }
  ${({ review }) => review && `right: 250px;`}
  ${({ right }) => right && `right: ${right}`}
  @media screen and (max-width: 575px) {
    ${({ review }) => review && `right: 16px;`}
  }
`;

export {
  BlockFan,
  ContentChatFan,
  FormChatFan,
  GiftBox,
  LayoutViewMobile,
  LayoutViewPC,
  GroupFeatureFan,
  CopyValue,
  WrapperAutoExpireStampsView,
  CopyValueReview,
  CopyValueView,
};
