import { PlayIcon } from 'images';
import { useEffect, useRef, useState } from 'react';
// import ReactPlayer from 'react-player';
import styled, { css } from 'styled-components';
import { decryptPath, funcThumbnailIMG, isAdmin, linkS3Video } from 'utils';
import { StyledTimeline } from '../styled';
import { useSelector } from 'react-redux';
import FieldChanged from 'components/FieldChanged/FieldChanged';
import useCheckFullVideo from 'hooks/v2/useCheckFullVideo';
const WrapperHideIconVideo = styled.div`
  width: 100%;
  //height: 100%;
  position: relative;
  padding-top: 56.25%;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  video::-webkit-media-controls-overlay-play-button {
    ${({ isFull = false }) =>
      isFull
        ? css`
            display: block;
          `
        : css`
            display: none;
          `};
  }

  *::-webkit-media-controls-start-playback-button {
    ${({ isFull = false }) =>
      isFull
        ? css`
            display: block;
          `
        : css`
            display: none !important;
            -webkit-appearance: none;
          `};
  }
`;
export const VideoTag = ({ isMini, item, data }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [controlCustom, setControlCustom] = useState(false);
  const videoRef = useRef();
  const [isFullScreen, setIsFullScreen] = useCheckFullVideo(videoRef);
  const containerRef = useRef();
  const { detailPost } = useSelector((state) => state.usersNew);
  const handleIntersection = (entries) => {
    const [entry] = entries;
    if (entry?.isIntersecting) {
    } else {
      videoRef?.current?.pause();
      setIsPlaying(false);
    }
  };
  const [thumbnail, setThumbnail] = useState(null);

  useEffect(() => {
    if (detailPost) {
      setThumbnail(
        detailPost?.medias?.filter((item) => item?.type === 4 && item?.public_flag === 1)
      );
    }
  }, [detailPost]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 1,
    });
    if (containerRef?.current) {
      observer?.observe(containerRef.current);
    }
    return () => {
      observer?.disconnect();
    };
  }, []);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      ref={containerRef}>
      <WrapperHideIconVideo isFull={isFullScreen}>
        {isAdmin &&
          (detailPost?.medias_changed?.video === 1 ||
            detailPost?.medias_changed?.thumbnail === 1) && <FieldChanged top='10px' />}
        <video
          style={{ objectFit: 'contain', background: '#000' }}
          width='100%'
          height='100%'
          preload='none'
          poster={`${
            thumbnail?.length === 0
              ? isMini
                ? funcThumbnailIMG(decryptPath(item?.url, true))
                : funcThumbnailIMG(decryptPath(item?.url))
              : isMini
              ? decryptPath(detailPost?.medias[0]?.url, true) + '#t=0.1'
              : decryptPath(detailPost?.medias[0]?.url) + '#t=0.1'
          }`}
          onPause={(e) => {
            if (videoRef.current.seeking) return;
            videoRef.current.pause();
            setIsPlaying(false);
            setControlCustom(true);
          }}
          onPlay={(e) => {
            setIsPlaying(true);
            setControlCustom(true);
          }}
          disablePictureInPicture
          controlsList='nodownload noremoteplayback noplaybackrate foobar'
          src={linkS3Video + decryptPath(item?.url) + '#t=0.1'}
          ref={videoRef}
          controls={isFullScreen ? true : controlCustom}>
          Your browser does not support the video tag.
        </video>
        {!isFullScreen && (
          <StyledTimeline.ButtonPlay
            widthButton='56px'
            heightButton='56px'
            bgButton='none'
            onClick={(e) => {
              e.stopPropagation();
              if (isPlaying) {
                setControlCustom(true);
                setIsPlaying(false);
                videoRef.current.pause();
              } else {
                setControlCustom(true);
                setIsPlaying(true);
                videoRef.current.play();
              }
            }}>
            {!isPlaying && (
              <PlayIcon
                style={{
                  filter: 'drop-shadow(0px 0px 0.5px #000)',
                }}
              />
            )}
          </StyledTimeline.ButtonPlay>
        )}
      </WrapperHideIconVideo>
    </div>
  );
};
