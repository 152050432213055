import { decryptPath, isAdmin } from 'utils';
import { ImgCustom } from 'components';
import { BlueImg } from '../../styled';
import FieldChanged from 'components/FieldChanged/FieldChanged';

const ListImagesNew = ({ images, onClickImage, dataChanged }) => {
  const handleClickImage = async (image, index) => {
    const newListImg = [...images.slice(index, images?.length), ...images.slice(0, index)];
    onClickImage({
      image,
      listImage: newListImg,
      initPageActive: index,
    });
  };

  const listIdChanged = Array.isArray(dataChanged) ? dataChanged : [];
  return (
    <>
      {images?.map((item, index) => {
        return (
          <div
            key={item?.id}
            className='list-img-detail-item'
            onClick={() => handleClickImage(item, index)}>
            <BlueImg url={`${decryptPath(item?.url, { isMini: true })}`}>
              <div className='bg-img'></div>
              {isAdmin && listIdChanged?.includes(item?.id) && <FieldChanged />}
              <ImgCustom
                screen='post_212_auto_pc'
                src={item?.url}
                className='cover-img'
                style={{ marginTop: 0 }}
                avatarErr={false}
              />
            </BlueImg>
          </div>
        );
      })}
    </>
  );
};

export default ListImagesNew;
