import Icon from '@ant-design/icons';

const NoCardsIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={100}
        height={100}
        viewBox='0 0 100 100'
        fill='none'>
        <path
          d='M87.5 21.875H12.5C10.7741 21.875 9.375 23.2741 9.375 25V75C9.375 76.7259 10.7741 78.125 12.5 78.125H87.5C89.2259 78.125 90.625 76.7259 90.625 75V25C90.625 23.2741 89.2259 21.875 87.5 21.875Z'
          stroke='#FCECEF'
          strokeWidth='3.4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M65.623 65.625H78.123'
          stroke='#FCECEF'
          strokeWidth='3.4'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M46.873 65.625H53.123'
          stroke='#FCECEF'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.37305 37.8359H90.623'
          stroke='#FCECEF'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default NoCardsIcon;
