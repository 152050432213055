import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { resetActionDataPopupRecor } from 'store/actions/creator';
import './index.scss';
import PopupGeneral from './general';
import { useForm } from 'react-hook-form';
import { InputWrapper } from 'components/Input/InputWrapper';
import { inputVerifyPhone, sendVerifyPhone } from 'store/actions/payment';
import { useEffect, useRef } from 'react';
import { acceptIntegerOfInputText, convertFullToHalf } from 'utils';
import { isMobile } from 'react-device-detect';

export const TYPE_NORMAL = 'normal';
export const TYPE_SHORT = 'short';
const PopupPhoneVerify = ({
  classNameCustom = '',
  setIsPopup,
  handleSubmitPayment,
  setCounter,
  counter,
  phone,
  countryCode,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { dataSend, loading } = useSelector((state) => state.payment);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',
  });

  const timerIdRef = useRef(null);

  const startCountdown = (time) => {
    if (timerIdRef.current) {
      clearInterval(timerIdRef.current);
    }

    if (time) setCounter(time);
    const tick = () => {
      setCounter(prevCounter => {
        if (prevCounter <= 0) {
          clearInterval(timerIdRef.current);
          return 0;
        }
        return prevCounter - 1;
      });
    };
    timerIdRef.current = setInterval(tick, 1000);
  };

  useEffect(() => {
    startCountdown();
    return () => clearInterval(timerIdRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateCode = () => {
    if (counter) return;
    getCode();
  };

  const getCode = () => {
    const dataNew = {
      country_code_number: countryCode?.replace(/\(|\)/g, ''),
      phone: phone,
    };
    dispatch(
      sendVerifyPhone({
        data: dataNew,
        onSuccess: (res) => {
          startCountdown(120);
          setValue('code', '');
        },
      })
    );
  };

  const onSubmit = async (data) => {
    const dataNew = {
      country_code_number: dataSend?.country_code,
      phone: dataSend?.phone_number,
      phone_verify_id: dataSend?.id,
      code: data.code,
    };
    dispatch(
      inputVerifyPhone({
        data: dataNew,
        onSuccess: (res) => {
          if (res?.success) {
            handleSubmitPayment();
          } else {
            setIsPopup(false);
          }
        },
      })
    );
  };

  return (
    <>
      <>
        <PopupGeneral
          className={`popup-quote-post phone ${classNameCustom}`}
          generalClass='popup-fan-detail-view1'
          closeModal={() => {
            setIsPopup(false);
            dispatch(resetActionDataPopupRecor());
          }}
          title={t('Common.verifyCode')}>
          <div className='d-flex block-img' style={{ padding: '20px 24px 0px 24px' }}>
            {t('Common.inputCode')}
          </div>
          <form onSubmit={(e) => e.preventDefault()} className='fansite-form1'>
            <div className='row' style={{ padding: '0 24px' }}>
              <div className='mt-3'>
                <InputWrapper
                  id='fsite-code'
                  required={true}
                  error={errors?.code ? errors?.code?.message : ''}>
                  <input
                    maxLength='6'
                    type='text'
                    placeholder={t('Common.codeNumber')}
                    {...register('code', {
                      minLength: {
                        value: 6,
                        message: 'ValidateMsg.min6Character',
                      },
                      required: t('ValidateMsg.required'),
                      pattern: {
                        value: /^[^.,]+$/,
                        message: 'ValidateMsg.PREMIUM_INTEGER_REQUIRED',
                      },
                    })}
                    onKeyDown={(e) => {
                      const newValue = convertFullToHalf(e.key);
                      !acceptIntegerOfInputText.includes(newValue) && e.preventDefault();
                    }}
                    className='form-control block-div-signin__active'
                  />
                </InputWrapper>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                padding: '0 24px 24px 24px',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '14px'
              }}>
              {t('Common.textPhone')}
            </div>
            <div className='' style={{ padding: '0 24px' }}>
              <InputWrapper>
                <div className='row' style={{ justifyContent: 'end' }}>
                <div className={`${isMobile ? 'col-7 col-sm-7 col-md-7 mb-3' : 'col-5 col-sm-5 col-md-5 mb-3'}`} style={{ padding: '0'}}>
                    <button
                      style={{ cursor: `${counter ? 'default' : 'pointer'}`}}
                      className='btn w-100 font-weight-700 border app-btn bg-white cancel'
                      type='submit'
                      onClick={() => generateCode()}>
                      <div className={`generate`}>
                        {t('Common.getNewCode')} &nbsp;
                        <span>{counter ? `(${counter}s)` : ''}</span>
                      </div>
                    </button>
                  </div>
                  <div className={`${isMobile ? 'col-4 col-sm-4 col-md-4 mb-3' : 'col-3 col-sm-3 col-md-3 mb-3'}`}>
                    <button
                      disabled={loading}
                      className='btn bg-red w-100 font-weight-700 text-white app-btn'
                      onClick={handleSubmit(onSubmit)}>
                      {t('Input.InputWrapper.Submitted')}
                    </button>
                  </div>
                </div>
              </InputWrapper>
            </div>
          </form>
        </PopupGeneral>
      </>
    </>
  );
};

export default PopupPhoneVerify;
