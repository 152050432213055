import { isMobile } from 'react-device-detect';
import VideoShortPlayer from '.';
import { LazyAware } from '../lazyAware';
import { LayerWrapClickVideo, PlaceHolderItem } from '../style';
import OptionsItem from '../optionItem';

const ItemVideo = ({
  item,
  index,
  indexPlay,
  updateList,
  toggleValueVideo,
  setShowPopup,
  handleFollowCreator,
  toggleFollowAllUser,
  setPopupCopyLink,
  isShowPopup,
}) => {
  return (
    <LazyAware
      key={item?.id}
      render={({ ref, wasIntersected, isIntersecting }) => {
        if (isIntersecting) {
          window.scrollTo(0, 0);
        }
        return (
          <>
            <div
              className={`page-list-short-video-item__video ${isMobile ? 'isMobie' : ''}`}
              ref={ref}>
              {wasIntersected ? (
                <>
                  <VideoShortPlayer
                    item={item}
                    index={index}
                    indexPlay={indexPlay}
                    wasIntersected={wasIntersected}
                    isIntersecting={isIntersecting}
                    updateList={updateList}
                  />
                  <LayerWrapClickVideo
                    onClick={() => {
                      toggleValueVideo(index);
                    }}
                  />
                  <OptionsItem
                    item={item}
                    index={index}
                    toggleValueVideo={toggleValueVideo}
                    setShowPopup={setShowPopup}
                    handleFollowCreator={handleFollowCreator}
                    toggleFollowAllUser={toggleFollowAllUser}
                    setPopupCopyLink={setPopupCopyLink}
                    isShowPopup={isShowPopup}
                  />
                </>
              ) : (
                <PlaceHolderItem />
              )}
            </div>
          </>
        );
      }}
    />
  );
};

export default ItemVideo;
