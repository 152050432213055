import Icon from '@ant-design/icons';

const IconSearch = ({isActive}) => {
  return (
    <Icon
      component={() => (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='25'
          height='24'
          viewBox='0 0 25 24'
          fill='none'>
          <path
            d='M11.5 18.75C15.8492 18.75 19.375 15.2242 19.375 10.875C19.375 6.52576 15.8492 3 11.5 3C7.15076 3 3.625 6.52576 3.625 10.875C3.625 15.2242 7.15076 18.75 11.5 18.75Z'
            stroke={isActive ? '#DC143C' : '#BEBEBE'}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M17.0684 16.4453L21.6247 21.0016'
            stroke={isActive ? '#DC143C' : '#BEBEBE'}
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>
      )}
    />
  );
};

export default IconSearch;
