import {
  AccountSidebar,
  HeaderListIdolV2,
  LayoutCreatorRight,
  MenuSidebarCustom,
  PopupConfirmFan,
  PopupLoading,
} from 'components';
import { InputWrapper } from 'components/Input/InputWrapper';
import { CloseIcon, EyeV2Icon, HiddenV2Icon, MenuIcon } from 'images';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { STORAGE } from 'utils/constant';
import Storage from 'utils/storage';
import { changePassword, getUserInfo } from '../../store/actions/users';
import {
  patternNoFirstSpacing,
} from '../../utils/utils';
import './user.scss';
import ProfileBackgroundImage from 'components/Profile-background-image';
import ProfileAvatar from 'components/Profile-avatar';
import NavbarUserProfile from 'components/NavbarEditProfile';
import { newPathUser } from 'constants/layout/constant';
import { getPresignedUrlS3, setFileNameS3 } from 'utils';
import withCreatorRole from 'hocs/withCreatorRole';
import withCreatorProfilePage, { useCreatorProfile } from 'context/CreatorProfileProvider';
import { useForm } from 'react-hook-form';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { logout } from 'store/actions/users';
import LoadingIconV2 from 'images/LoadingIconV2';
import { isMobile } from 'react-device-detect';

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = useForm({
    mode: 'all',
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [hiddenOldPassword, setHiddenOldPassword] = useState(true);
  const [hiddenPassword, setHiddenPassword] = useState(true);
  const [hiddenConfirmPassword, setHiddenConfirmPassword] = useState(true);

  const [checkOldPass, setCheckOldPass] = useState(false);
  const [checkConfirmPass, setCheckConfirmPass] = useState(false);

  const [isVisiblePopup, setIsVisiblePopup] = useState(false);
  const [avatarTmp, setAvatarTmp] = useState();
  const [bgTmp, setBgTmp] = useState();
  const [errorBG, setErrorBG] = useState('');
  const [errorAvar, setErrorAvar] = useState('');
  const confirmPassword = useRef({});
  const password = useRef({});
  const navigateToHomePage = () => navigate(`${newPathUser}`);
  const { status, data: userProfile, dataUser , loading} = useSelector((state) => state.users);
  const [isLoading, setIsLoading] = useState(false);
  // const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   dispatch(getUserInfo((_response) => setLoading(false)));
  // }, []);

  // useEffect(() => {
  //   if (userProfile) {
  //     setValue('avatar', userProfile.avatar);
  //     setValue('bg_img', userProfile.bg_img);
  //   }
  // }, [userProfile]);

  let dataS3Update = {};
  const watchOldPassword = watch('old_password', '');
  const watchPassword = watch('new_password', '');
  const watchConfirmPassword = watch('new_password_confirmation', '');

  const watchCover = watch('bg_img', undefined);
  const watchAvatar = watch('avatar', undefined);

  confirmPassword.current = watch('new_password_confirmation', '');
  password.current = watch('new_password', '');
  useEffect(() => {
    status && setIsVisiblePopup(true);
  }, [status]);
  const isDisabledButton =
    !watchConfirmPassword ||
    !watchOldPassword ||
    !watchPassword ||
    watchConfirmPassword.length < 8 ||
    watchOldPassword.length < 8 ||
    watchPassword.length < 8 ||
    // watchConfirmPassword.length > 16 ||
    // watchOldPassword.length > 16 ||
    // watchPassword.length > 16 ||
    checkOldPass ||
    checkConfirmPass;

  const onSubmit = async (data) => {
    setIsLoading(true);
    const avatarImage = avatarTmp || '';
    const coverImage = bgTmp || '';

    //if (watchAvatar) {
    if (avatarImage) {
      const resultAvatar = await getPresignedUrlS3(
        'image',
        'uploads/output/images/creator/avatar',
        setFileNameS3(avatarImage.name)
      );
      if (resultAvatar?.data) {
        dataS3Update = {
          ...dataS3Update,
          avatar: resultAvatar?.data?.file_path,
          avatar_pre_signed: resultAvatar?.data?.pre_signed,
          avatar_file: avatarImage,
        };
      }
    }
    //}

    //if (watchCover) {
    if (coverImage) {
      const resultCover = await getPresignedUrlS3(
        'image',
        'uploads/output/images/creator/cover',
        setFileNameS3(coverImage.name)
      );
      if (resultCover?.data) {
        dataS3Update = {
          ...dataS3Update,
          bg_img: resultCover?.data?.file_path,
          bg_img_pre_signed: resultCover?.data?.pre_signed,
          bg_img_file: coverImage,
        };
      }
    }
    //}
    data = {
      ...data,
      avatar: avatarTmp === null ? null : dataS3Update.avatar,
      bg_img: bgTmp === null ? null : dataS3Update.bg_img,
    };

    dispatch(changePassword({ ...data, ...dataS3Update }, () => setIsLoading(false)));
  };

  return (
    <div>
      {isVisiblePopup && (
        <PopupConfirmFan text={t('Common.changPasswordSuccess')} colorSvg='#28c76f'>
          <div
            className='ok btn'
            onClick={() => {
              Storage.remove(STORAGE.CURRENT_USER_KEY);
              Storage.remove(STORAGE.USER_ACCESS_TOKEN);
              setIsVisiblePopup(false);
              navigate('/sign-in');
            }}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}
      <LayoutCreatorRight
        noButtonBack
        titlePage={t('User.tabChangePass')}
        loading={loading}
        className='passwork-info'>
        {isLoading && <PopupLoading />}
        <div className='user-wrapper'>
          <div className='box-user-wrapper form-change-password'>
            {loading && !isLoading ? (
              <div style={{ padding: `${isMobile ? '16px' : '24px'}` }}>
                <ShimmerPostDetails className='shimmer-loading' />
              </div>
            ) : (
              <form onSubmit={(e) => e.preventDefault()} className='fansite-form'>
                <InputWrapper
                  id='fsite-old-password'
                  label={t('User.oldPassuword')}
                  required={true}
                  error={errors?.old_password ? errors?.old_password?.message : ''}>
                  <div className='old-password'>
                    <input
                      {...register('old_password', {
                        required: ('ValidateMsg.oldPasswordRequired'),
                        minLength: {
                          value: 8,
                          message: ('ValidateMsg.minPassword'),
                        },
                        validate: {
                          noFirstSpacing: (value) =>
                            (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                            ('ValidateMsg.oldPasswordRequired'),
                        },
                        onChange: (e) => {
                          const { value } = e.target;
                          !patternNoFirstSpacing.test(value.toString().trim())
                            ? setCheckOldPass(true)
                            : setCheckOldPass(false);
                        },
                      })}
                      onKeyDown={(e) => {
                        if (e.key === ' ') {
                          e.preventDefault();
                        }
                      }}
                      placeholder={t('ValidateMsg.placeholderCurrentPassword')}
                      type={`${hiddenOldPassword ? 'password' : 'text'}`}
                    />
                    {hiddenOldPassword ? (
                      <EyeV2Icon onClick={() => setHiddenOldPassword(false)} />
                    ) : (
                      <HiddenV2Icon onClick={() => setHiddenOldPassword(true)} />
                    )}
                  </div>
                </InputWrapper>
                <InputWrapper
                  id='fsite-password'
                  label={t('Common.newPassword')}
                  notification={true}
                  notificationContent={t('ValidateMsg.minPassword')}
                  required={true}
                  error={errors?.new_password ? errors?.new_password?.message : ''}>
                  <div className='password'>
                    <input
                      {...register('new_password', {
                        required: ('ValidateMsg.newPasswordRequired'),
                        minLength: {
                          value: 8,
                          message: ('ValidateMsg.minPassword'),
                        },
                        validate: {
                          noFirstSpacing: (value) =>
                            (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                            ('ValidateMsg.newPasswordRequired'),
                          checkPass: (value) =>
                            value !== confirmPassword.current && confirmPassword.current
                              ? ('ValidateMsg.passwordNewNoMatchRePasswordNew')
                              : null,
                        },
                        onChange: (e) => {
                          const { value } = e.target;
                          if (
                            !patternNoFirstSpacing.test(value.toString().trim()) ||
                            !value === confirmPassword.current
                          ) {
                            setCheckConfirmPass(true);
                          } else {
                            setCheckConfirmPass(false);
                            clearErrors(['new_password', 'new_password_confirmation']);
                          }
                        },
                      })}
                      onKeyDown={(e) => {
                        if (e.key === ' ') {
                          e.preventDefault();
                        }
                      }}
                      placeholder={t('ValidateMsg.newPasswordRequired')}
                      type={`${hiddenPassword ? 'password' : 'text'}`}
                    />
                    {hiddenPassword ? (
                      <EyeV2Icon onClick={() => setHiddenPassword(false)} />
                    ) : (
                      <HiddenV2Icon onClick={() => setHiddenPassword(true)} />
                    )}
                  </div>
                </InputWrapper>

                <InputWrapper
                  id='fsite-confirm-password'
                  label={t('User.reNewPassword')}
                  notification={true}
                  notificationContent={t('ValidateMsg.minPassword')}
                  required={true}
                  error={
                    errors?.new_password_confirmation
                      ? errors?.new_password_confirmation?.message
                      : ''
                  }>
                  <div className='confirm-password'>
                    <input
                      {...register('new_password_confirmation', {
                        required: ('ValidateMsg.reNewPasswordRequired'),
                        minLength: {
                          value: 8,
                          message: ('ValidateMsg.minPassword'),
                        },
                        validate: {
                          noFirstSpacing: (value) =>
                            (value && patternNoFirstSpacing.test(value.toString().trim())) ||
                            ('ValidateMsg.reNewPasswordRequired'),
                          checkPass: (value) =>
                            value !== password.current && password.current
                              ? ('ValidateMsg.passwordNewNoMatchRePasswordNew')
                              : null,
                        },
                        onChange: (e) => {
                          const { value } = e.target;
                          if (
                            !patternNoFirstSpacing.test(value.toString().trim()) ||
                            !value === password.current
                          ) {
                            setCheckConfirmPass(true);
                          } else {
                            setCheckConfirmPass(false);
                            clearErrors(['new_password', 'new_password_confirmation']);
                          }
                        },
                      })}
                      onKeyDown={(e) => {
                        if (e.key === ' ') {
                          e.preventDefault();
                        }
                      }}
                      placeholder={t('ValidateMsg.reNewPasswordRequired')}
                      type={`${hiddenConfirmPassword ? 'password' : 'text'}`}
                    />
                    {hiddenConfirmPassword ? (
                      <EyeV2Icon onClick={() => setHiddenConfirmPassword(false)} />
                    ) : (
                      <HiddenV2Icon onClick={() => setHiddenConfirmPassword(true)} />
                    )}
                  </div>
                </InputWrapper>
                <div className='flat-btn'>
                  <label className='reset-password'>{t('SignIn.forgotPassword')}</label>
                  &nbsp;
                  <label
                    className='here'
                    onClick={() => {
                      dispatch(logout('/forgot-password'));
                    }}>
                    {t('SignIn.clickHere')}
                  </label>
                </div>
                <InputWrapper>
                  {/* id='fsite-submit'
               className={`button-submit ${isDisabledButton ? 'disabled' : ''}`}>
                 <button onClick={handleSubmit(onSubmit)} disabled={isDisabledButton}>
                 {t('Common.buttonUpdate')}
               </button> */}
                  <div className='row' style={{ marginTop: '24px' }}>
                    <div className='col-12 col-sm-12 col-md-12 mb-3'>
                      <button
                        onClick={handleSubmit(onSubmit)}
                        className='btn bg-red w-100 font-weight-700 text-white app-btn button-red-new'
                        disabled={isDisabledButton || errorBG !== '' || errorAvar !== ''}>
                        {t('Common.buttonUpdate')}
                      </button>
                    </div>
                    {/* <div className='col-12 col-sm-6 col-md-6 mb-3'>
                      <button
                        disabled={userProfile?.status === 3 || userProfile?.status === 4}
                        className='btn w-100 font-weight-700 border app-btn bg-white cancel'
                        onClick={navigateToHomePage}>
                        {t('Common.return')}
                      </button>
                    </div> */}
                  </div>
                </InputWrapper>
              </form>
            )}
          </div>
        </div>
      </LayoutCreatorRight>
    </div>
  );
};

export default withCreatorRole(withCreatorProfilePage(ChangePassword));
