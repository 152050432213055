import { CloseIcon, CopyLinkIcon } from 'images';
import { useState } from 'react';
import { useRef } from 'react';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import { useNavigate } from 'react-router-dom';
import { pushNotify } from 'components/Notification';
import { useTranslation } from 'react-i18next';

const PopupCopyLink = ({
  link,
  isVisiblePopup,
  router,
  hiddenCopy = true,
  notification = false,
}) => {
  useLockBodyScroll();
  const navigate = useNavigate();
  const textRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const { t } = useTranslation();
  const copyLinkFunc = () => {
    navigator.clipboard.writeText(link);
    setCopySuccess(true);
    notification && pushNotify('success', t('Popup.copiedLink'));
    setTimeout(() => {
      hiddenCopy && setCopySuccess(false);
      router && navigate(router);
    }, 1500);
  };
  const handleShowPopup = (e) => {
    e.preventDefault();
    isVisiblePopup(null);
  };

  return (
    <div className='popup popup-copy-link'>
      <div className='popup-content'>
        <div className='title'>{t('Popup.copyLink')}</div>
        <CloseIcon className='close-icon' onClick={handleShowPopup} />
        <div className='content'>
          <span className='text' ref={textRef}>
            {link}
          </span>
          <CopyLinkIcon onClick={copyLinkFunc} />
          {copySuccess && <div className='copy-success'>{t('Popup.copiedLink')}</div>}
        </div>
      </div>
    </div>
  );
};

export default PopupCopyLink;
