import {
  ImgCustom,
  LayoutCreatorRight,
  NavbarF025,
} from 'components';
import { InputWrapper } from 'components/FormLogin';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { ArrowLeftIcon, FileZipUploadIcon } from 'images';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { getListOfPurchasedPostCreators } from 'store/actions/sellPost';
import {
  convertToCurrentTime,
  CustomOption,
  customStyles,
  decryptPath,
} from 'utils/utils';
import './index.scss';
import { creatorGetListMyBuyer, getIDSearchBuyed } from 'store/actions/users';
import { newPathUser } from 'constants/layout/constant';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { useMemo } from 'react';
import NoPostBuyedIcon from 'images/NoPostBuyed';
import { AVATAR_DEFAULT } from 'utils';

const customStylesReselect = {
  ...customStyles,

  singleValue: (base) => ({
    ...base,
    fontWeight: 500,
    fontSize: 14,
    position: 'absolute',
    padding: '2px 28px 2px 0',
    color: '#1A1A1A',
  }),
  dropdownIndicator: (base) => ({
    ...base,
    display: 'block',
    padding: '0px 4px',
  }),
  control: (base, state) => ({
    ...base,
    display: 'flex',
    width: '260px',
    svg: {
      color: '#666565',
      transform: state.menuIsOpen ? 'rotate(180deg)' : '',
    },
    borderColor: state.isFocused ? '#1480FF' : '#E5E6EB',
  }),
  menu: (base, state) => ({
    ...base,
    border: 'none !important',
    borderRadius: '8px',
    overflow: 'hidden',
    zIndex: 2,
    width: 260,
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.06)',
  }),
  option: (base, state) => {
    return {
      ...base,
      position: 'relative',
      backgroundColor: state.isSelected ? '#FFDBDB' : 'transparent',
      color: '#1A1A1A',

      '&.content-right': {
        color: '#DC143C',
      },
      svg: {
        color: '#DC143C',
        display: state.isSelected ? 'block' : 'none',
        position: 'absolute',
        right: -12,
        alignItems: 'center',
        margin: 'auto',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        marginLeft: '4px',
      },
      fontWeight: state.isSelected ? 500 : 400,
      padding: '12px 24px 12px 16px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      display: 'block',
    };
  },
};

const ListPostBuyed = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [pageOptions, setPageOptions] = useState(1);
  const [focusSelect, setFocusSelect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingFirst, setIsLoadingFirst] = useState(false);
  const sortParams = searchParams.get('creatorId');
  const { t, i18n } = useTranslation();

  const { list_creator = [], listCreatorPagination } = useSelector((state) => state.sellPost);
  const widthScreen = window.innerWidth;
  const itemsPerPage = widthScreen <= 575 ? 6 : 12;

  const optionListContent = list_creator.map((item) => ({
    ...item,
    value: item.user_id,
    label: item.account_name,
  }));

  const {
    listBuyer,
    listBuyerPagination,
    idBuyed,
    initPageBuyed
  } = useSelector((state) => state.users);
  const [creatorId, setCreatorId] = useState(Number(sortParams) || idBuyed?.selectPost || null);
  const [page, setPage] = useState(idBuyed && initPageBuyed ? +initPageBuyed : 1);

  useEffect(() => {
    dispatch(getListOfPurchasedPostCreators(pageOptions, 20));
  }, [pageOptions]);

  // api list page
  const fetchNext = () => {
    const pages = listBuyerPagination?.total / listBuyerPagination?.per_page;
    if (pages > page) {
      funCallApi(page + 1);
      setPage(page + 1);
    }
  };

  const fetchOptionsNext = () => {
    const pages = listCreatorPagination?.total / listCreatorPagination?.per_page;
    if (pages > pageOptions) {
      setPageOptions(pageOptions + 1);
    }
  };

  useEffect(() => {
    if (idBuyed) {
      const idPost = document.getElementById(idBuyed.id);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idBuyed]);

  const funCallApi = useCallback(
    (page) => {
      setIsLoading(true);
      dispatch(creatorGetListMyBuyer(page, itemsPerPage, creatorId, () => setIsLoading(false)));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, creatorId]
  );

  const funCallApiSelect = useCallback(
    (page, value) => {
      setIsLoadingFirst(true);
      dispatch(creatorGetListMyBuyer(page, itemsPerPage, value, () => setIsLoadingFirst(false)));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, creatorId]
  );

  useEffect(() => {
    if (initPageBuyed < 1) {
      const top = document.getElementById('app_cocofans_0');
      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
      setIsLoadingFirst(true);
      dispatch(creatorGetListMyBuyer(page, itemsPerPage, creatorId, () => setIsLoadingFirst(false)));
    }
  }, [page, creatorId]);

  const onChangeSelect = (value) => {
    navigate(`/list-post-buyed?creatorId=${value?.value}`);
    setCreatorId(value.value);
    setPage(1);
    funCallApiSelect(1, value.value);
  };

  const OptionsSelect = useMemo(
    () => [{ label: t('Creator.all'), value: '' }],
    [i18n.language]
  );

  return (
    <>
      <LayoutCreatorRight
        noButtonBack
        titlePage={t('Navbar.F025.tab2')}
        className='sub-purchase-list'>
        <NavbarF025
          defaultActive={2}
          resetPage={() => {
            setPage(1);
            // loadingFunc();
          }}
          className='sub-purchase-navbar'
        />
        <div className='page-post-buyed' id={'posts-subscribe'}>
          <form className='fansite-form' onSubmit={(e) => e.preventDefault()}>
            <InputWrapper id='fsite-select'>
              <div
                className='select'
                onBlur={() => setFocusSelect(false)}
                onClick={() => setFocusSelect(!focusSelect)}>
                {/* <div className='select-label'> {t('PostBuyed.package')}</div> */}
                <Select
                  options={[OptionsSelect[0], ...optionListContent]}
                  value={optionListContent?.find((e) => e.value === creatorId)}
                  isSearchable={false}
                  isClearable={false}
                  defaultValue={!sortParams && !idBuyed?.selectPost && OptionsSelect[0]}
                  onChange={onChangeSelect}
                  placeholder={t('Common.all')}
                  styles={customStylesReselect}
                  className='react-select-container'
                  classNamePrefix='react-select'
                  components={{
                    Option: CustomOption,
                    DropdownIndicator: () => <ArrowLeftIcon />,
                  }}
                  captureMenuScroll={(e) => {}}
                  onMenuScrollToBottom={fetchOptionsNext}
                />
              </div>
            </InputWrapper>
          </form>
          {isLoadingFirst ? (
            <div className='shimmer-loading'>
              <ShimmerPostDetails />
            </div>
          ) : (
            <>
              {!!listBuyer?.length ? (
                <InfiniteLoad
                  loading={isLoading && listBuyer.length > 0}
                  data={listBuyer || []}
                  fetchNext={fetchNext}>
                  <div className='list-post-buyed'>
                    {listBuyer.map((item, index) => {
                      const splitTime = convertToCurrentTime(item?.purchased_at).split(' ');
                      const time = splitTime?.[1] || '';
                      const date = splitTime?.[0]?.split('-').join('/');
                      return (
                        <div
                          className='item'
                          onClick={() => {
                            dispatch(getIDSearchBuyed({id: item?.id, tab: 2 , selectPost: creatorId, page: page }))
                            navigate(`${newPathUser}post-sale/${item.id}`, {
                              state: { previousPath: 'list-post-buyed' },
                            });
                          }}
                          key={index} id={item?.id}>
                          {item?.media_preview?.type === 5 && (
                            <div className='file-zip'>
                              <FileZipUploadIcon />
                              <span className='file-name'>
                                {item?.media_preview?.file_name}
                              </span>
                            </div>
                          )}

                          <div className='infor'>
                            <div className='user-infor'>
                              {decryptPath(item?.avatar) === 'NULL' ||
                              !decryptPath(item?.avatar) ? (
                                <img
                                  className='avatar-mini'
                                  src={AVATAR_DEFAULT}
                                  alt='avatar-default'></img>
                              ) : (
                                <ImgCustom
                                  className='avatar-mini'
                                  screen={item?.avatar ? 'avatar_mini_28_28' : ''}
                                  src={`${item?.avatar}`}
                                  alt='avatar'
                                />
                              )}
                              <div className='name'>{item?.account_name}</div>
                            </div>
                            <div className='title'>{item?.title}</div>
                            <div className='content'>{item?.content}</div>
                          </div>

                          {item?.media_preview?.type === 4 && (
                            <div
                              className='blue-img'
                              style={{
                                backgroundImage: `url("${decryptPath(
                                  item?.media_preview?.url, { isMini: true }
                                )}")`,
                              }}
                              url={item?.media_preview?.url}
                              height={223}
                              width={223}>
                              <div className='bg-img'></div>

                              <ImgCustom
                                screen={
                                  item?.media_preview?.url ? 'image_preview_223_auto' : ''
                                }
                                src={`${item?.media_preview?.url}`}
                                alt='image preview'
                                className='img-preview'
                                style={{
                                  objectFit: 'contain',
                                  position: 'absolute',
                                  zIndex: '10',
                                  background: 'transparent',
                                }}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </InfiniteLoad>
              ) : (
                <div className='no-data'>
                  <NoPostBuyedIcon className='icon-no-data' />
                  <div>{t('PostBuyed.listNull')}</div>
                </div>
              )}
            </>
          )}
        </div>
      </LayoutCreatorRight>
    </>
  );
};
export default ListPostBuyed;
