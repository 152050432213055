import Icon from '@ant-design/icons';

const ViewPostIcon = (props) => (
  <Icon
    component={() => (
      <svg
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M15.6256 17.5H4.375C4.20924 17.5 4.05027 17.4342 3.93306 17.3169C3.81585 17.1997 3.75 17.0408 3.75 16.875V3.125C3.75 2.95924 3.81585 2.80027 3.93306 2.68306C4.05027 2.56585 4.20924 2.5 4.375 2.5H11.8756L16.2506 6.875V16.875C16.2506 16.9571 16.2344 17.0383 16.203 17.1142C16.1716 17.19 16.1256 17.2589 16.0676 17.3169C16.0095 17.375 15.9406 17.421 15.8648 17.4524C15.789 17.4838 15.7077 17.5 15.6256 17.5Z'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.875 2.5V6.875H16.2506'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.2344 13.1094L12.4844 14.3594'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.6875 13.75C10.8956 13.75 11.875 12.7706 11.875 11.5625C11.875 10.3544 10.8956 9.375 9.6875 9.375C8.47938 9.375 7.5 10.3544 7.5 11.5625C7.5 12.7706 8.47938 13.75 9.6875 13.75Z'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default ViewPostIcon;
