/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { orderByChild } from 'firebase/database';
import { ref, startAfter, query, get, limitToFirst /* equalTo */ } from 'firebase/database';
import { DBRealTime } from '../../../../firebase/config';
import { updateData } from 'hooks/useRealTimeDB';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import avatarDemo from 'images/Avatar.png';
import './RealTimeFanViews.scss';
import { linkS3 } from 'utils/utils';
import { useDispatch } from 'react-redux';
import { followUnFollowUserByType } from 'store/actions/lives';
import { useTranslation } from 'react-i18next';
import { ImgCustom, Tooltip2 } from '../../..';
import useUserRole from '../../../../hooks/useUserRole';
import { Block, HoverTooltip } from 'pages/creator/layout/livestream/components/styled';
import { AVATAR_DEFAULT } from 'utils';

// import Storage from 'utils/storage';

export const RealTimeFanViews = (props) => {
  const { liveKey, show = true, isMobile = false } = props;
  const baseRef = ref(DBRealTime, `/lives/${liveKey}`);
  const [lastValue, setLastValue] = useState(null);
  const dispatch = useDispatch();
  const order = [orderByChild('user_id')];
  const pageSize = 10;
  const [dataVal, setDataVal] = useState([]);
  const { t } = useTranslation();
  const { isIdol } = useUserRole();
  const callData = () => {
    let ref = null;
    if (lastValue !== null) {
      ref = query(baseRef, ...order, startAfter(lastValue), limitToFirst(pageSize));
    } else {
      ref = query(baseRef, ...order, limitToFirst(pageSize));
    }

    get(ref).then((snap) => {
      const keys = [];
      const data = [];
      snap.forEach((ss) => {
        if (ss.val().key && ss.val().user_id && ss.val().name) {
          data.push(ss.val());
          keys.push(ss.key);
        }
      });

      if (data.length) {
        const last = data.length - 1;
        setLastValue(data[last].user_id);
      }

      setDataVal([...dataVal, ...data]);
    });
  };

  useEffect(() => {
    callData();
  }, []);

  const fetchNext = () => {
    callData();
  };

  // const [textFollow, setTextFollow] = useState('フォロー');
  // const [textBlockUser, setTextBlockUser] = useState('コメント可能');
  const updateSetComment = (item, commentValue) => {
    const params = {};
    params[item?.user_id] = { id: item?.user_id, comment: commentValue };
    let comment = commentValue;
    //* set VALUE ko cho comment
    updateData(`lives/${liveKey}/${item.user_id}`, { comment });
    return updateData(`lives/${liveKey}/config/block_chat/`, params);
  };
  return (
    <>
      {!dataVal.length && (
        <p className='' style={{ fontWeight: 'normal', padding: '50px', textAlign: 'center' }}>
          {t('Common.noData')}
        </p>
      )}
      <InfiniteLoad
        className={`real-fan-view wrapper-live-view ${isMobile ? 'isMobile' : ''}`}
        loading={false}
        setHeight='auto'
        data={dataVal}
        fetchNext={fetchNext}>
        {/* <div className='wrapper-live-view'> */}
        {dataVal.map((item, index) => {
          return (
            <div className={`item ${isMobile ? 'mobile' : 'isMobile'}`} key={index}>
              {isMobile ? (
                <>
                  <div
                    className={`info-user ${isMobile ? 'mobile live' : ''} ${
                      !show ? 'full' : ''
                    }`}>
                    <ImgCustom
                      isPlaceholderAvatar
                      src={(item.avatar === 'null' || !item?.avatar)  ? AVATAR_DEFAULT : `${item.avatar}`}
                      alt=''
                      className='avatar'
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
                      <div className='name creator-live-name-length'>
                        <Tooltip2
                          text={item.name}
                          className={`ranking-name-tooltip-wrapper ${
                            isIdol ? 'creator' : 'fan'
                          } ${!show ? 'full' : ''}`}>
                          {item.name}
                        </Tooltip2>
                      </div>
                      {show && (
                        <div className='box-follow-block mobile'>
                          <Block totip>
                            <div
                              id={`follow-${item?.user_id}`}
                              className={`btn ${
                                item.creator_follow === 1 ? 'popup-realtime-user-active' : ''
                              } `}
                              style={{
                                width: 'auto',
                                marginLeft: '0px',
                                borderRadius: '8px',
                              }}
                              onClick={(event) => {
                                event.preventDefault();

                                //const getItemStorage = Storage.get('CREATOR_LIST_SUBSCRIBER');
                                const btnID = document.getElementById(
                                  `follow-${item?.user_id}`
                                );
                                const btnIDHover = document.getElementById(
                                  `follow-hover-${item?.user_id}`
                                );
                                if (
                                  event.target.classList.contains('popup-realtime-user-active')
                                ) {
                                  dispatch(followUnFollowUserByType(item?.user_id));
                                  event.currentTarget.classList.remove(
                                    'popup-realtime-user-active'
                                  );

                                  btnID.innerText = `${t('Common.follow')}`;
                                  btnIDHover.innerText = `${t('Common.follow')}`;

                                  let creator_follow = 0;
                                  return updateData(`lives/${liveKey}/${item.user_id}`, {
                                    creator_follow,
                                  });
                                } else {
                                  event.currentTarget.classList.add(
                                    'popup-realtime-user-active'
                                  );
                                  dispatch(followUnFollowUserByType(item?.user_id));
                                  // setTextFollow('フォロー');
                                  btnID.innerText = `${t('Common.unfollow')}`;
                                  btnIDHover.innerText = `${t('Common.unfollow')}`;
                                  let creator_follow = 1;
                                  return updateData(`lives/${liveKey}/${item.user_id}`, {
                                    creator_follow,
                                  });
                                }
                              }}>
                              {item.creator_follow === 1
                                ? `${t('Common.unfollow')}`
                                : `${t('Common.follow')}`}
                            </div>
                            <HoverTooltip id={`follow-hover-${item?.user_id}`}>
                              {item.creator_follow === 1
                                ? `${t('Common.unfollow')}`
                                : `${t('Common.follow')}`}
                            </HoverTooltip>
                          </Block>
                          <Block totip>
                            <div
                              id={`comment-${item?.user_id}`}
                              className={`btn ${
                                item?.comment === 1 ? '' : 'popup-realtime-user-active'
                              } `}
                              style={{
                                marginLeft: '0px',
                                borderRadius: '8px',
                                width: 'auto',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                              }}
                              onClick={(event) => {
                                event.preventDefault();
                                const btnID = document.getElementById(
                                  `comment-${item?.user_id}`
                                );
                                const btnIDHover = document.getElementById(
                                  `comment-hover-${item?.user_id}`
                                );

                                if (
                                  event.target.classList.contains('popup-realtime-user-active')
                                ) {
                                  event.currentTarget.classList.remove(
                                    'popup-realtime-user-active'
                                  );
                                  updateSetComment(item, 1);
                                  btnID.innerText = `${t('LiveStream.Videos.noComment')}`;
                                  btnIDHover.innerText = `${t('LiveStream.Videos.noComment')}`;
                                } else {
                                  event.currentTarget.classList.add(
                                    'popup-realtime-user-active'
                                  );
                                  updateSetComment(item, 0);
                                  btnID.innerText = `${t('LiveStream.Videos.commentable')}`;
                                  btnIDHover.innerText = `${t(
                                    'LiveStream.Videos.commentable'
                                  )}`;
                                }
                              }}>
                              {item?.comment === 1
                                ? `${t('LiveStream.Videos.noComment')}`
                                : `${t('LiveStream.Videos.commentable')}`}
                            </div>
                            <HoverTooltip id={`comment-hover-${item?.user_id}`}>
                              {item?.comment === 1
                                ? `${t('LiveStream.Videos.noComment')}`
                                : `${t('LiveStream.Videos.commentable')}`}
                            </HoverTooltip>
                          </Block>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className={`info-user ${!show ? 'full' : ''}`}>
                    <ImgCustom
                      isPlaceholderAvatar
                      src={(item.avatar === 'null' || !item?.avatar) ? AVATAR_DEFAULT : `${item.avatar}`}
                      alt=''
                      className='avatar'
                    />
                    <span className='name creator-live-name-length'>
                      <Tooltip2
                        text={item.name}
                        className={`ranking-name-tooltip-wrapper ${
                          isIdol ? 'creator' : 'fan'
                        } ${!show ? 'full' : ''}`}>
                        {item.name}
                      </Tooltip2>
                    </span>
                  </div>
                  {show && (
                    <div className='box-follow-block '>
                      <Block totip>
                        <div
                          id={`follow-${item?.user_id}`}
                          className={`btn ${
                            item.creator_follow === 1 ? 'popup-realtime-user-active' : ''
                          } `}
                          style={{ width: '105px', marginLeft: '0px' }}
                          onClick={(event) => {
                            event.preventDefault();

                            //const getItemStorage = Storage.get('CREATOR_LIST_SUBSCRIBER');
                            const btnID = document.getElementById(`follow-${item?.user_id}`);
                            const btnIDHover = document.getElementById(
                              `follow-hover-${item?.user_id}`
                            );
                            if (
                              event.target.classList.contains('popup-realtime-user-active')
                            ) {
                              dispatch(followUnFollowUserByType(item?.user_id));
                              event.currentTarget.classList.remove(
                                'popup-realtime-user-active'
                              );
                              btnID.innerText = `${t('Common.follow')}`;
                              btnIDHover.innerText = `${t('Common.follow')}`;

                              let creator_follow = 0;
                              return updateData(`lives/${liveKey}/${item.user_id}`, {
                                creator_follow,
                              });
                            } else {
                              event.currentTarget.classList.add('popup-realtime-user-active');
                              dispatch(followUnFollowUserByType(item?.user_id));
                              // setTextFollow('フォロー');
                              btnID.innerText = `${t('Common.unfollow')}`;
                              btnIDHover.innerText = `${t('Common.unfollow')}`;
                              let creator_follow = 1;
                              return updateData(`lives/${liveKey}/${item.user_id}`, {
                                creator_follow,
                              });
                            }
                          }}>
                          {item.creator_follow === 1
                            ? `${t('Common.unfollow')}`
                            : `${t('Common.follow')}`}
                        </div>
                        <HoverTooltip id={`follow-hover-${item?.user_id}`}>
                          {item.creator_follow === 1
                            ? `${t('Common.unfollow')}`
                            : `${t('Common.follow')}`}
                        </HoverTooltip>
                      </Block>
                      <Block totip>
                        <div
                          id={`comment-${item?.user_id}`}
                          className={`btn ${
                            item?.comment === 1 ? '' : 'popup-realtime-user-active'
                          } `}
                          style={{
                            marginLeft: '0px',
                            width: '105px',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            const btnID = document.getElementById(`comment-${item?.user_id}`);
                            const btnIDHover = document.getElementById(
                              `comment-hover-${item?.user_id}`
                            );

                            if (
                              event.target.classList.contains('popup-realtime-user-active')
                            ) {
                              event.currentTarget.classList.remove(
                                'popup-realtime-user-active'
                              );
                              updateSetComment(item, 1);
                              btnID.innerText = `${t('LiveStream.Videos.noComment')}`;
                              btnIDHover.innerText = `${t('LiveStream.Videos.noComment')}`;
                            } else {
                              event.currentTarget.classList.add('popup-realtime-user-active');
                              updateSetComment(item, 0);
                              btnID.innerText = `${t('LiveStream.Videos.commentable')}`;
                              btnIDHover.innerText = `${t('LiveStream.Videos.commentable')}`;
                            }
                          }}>
                          {item?.comment === 1
                            ? `${t('LiveStream.Videos.noComment')}`
                            : `${t('LiveStream.Videos.commentable')}`}
                        </div>
                        <HoverTooltip id={`comment-hover-${item?.user_id}`}>
                          {item?.comment === 1
                            ? `${t('LiveStream.Videos.noComment')}`
                            : `${t('LiveStream.Videos.commentable')}`}
                        </HoverTooltip>
                      </Block>
                    </div>
                  )}
                </>
              )}
            </div>
          );
        })}
        {/* </div> */}
      </InfiniteLoad>
    </>
  );
};
