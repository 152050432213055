import { ChevronLeft, ChevronRight, StartNormalIcon } from 'images';
import ImgDemo from 'images/imgUploadDemo.jpg';
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { ButtonBookmark, ImgCustom } from '..';
import { postBookMarkF017 } from '../../store/actions/creator';
import { postBookMark, postBookMarkDetail } from '../../store/actions/myPage';
import { linkS3, linkS3Video, onErrImage, decryptPath, linkS3VideoM3U8 } from '../../utils/utils';
import ReactHlsPlayer from 'react-hls-player';
import './ListImages.scss';
import { newPathUser } from 'constants/layout/constant';
import useDisableContextMenu from 'hooks/useDisableContextMenu';

const ListImages = ({
  images = [],
  number_bookmark = 0,
  id_post,
  bookmark_status,
  post_type,
  is_idol,
  className,
  isMyPage = false,
  isPostDetail = false,
  onClickImage,
  noScroll = false,
  type,
  noClick = false,
  show_img_demo = false,
  isFanF017 = false,
  isF006 = false,
  removePostInListBookmark,
  show_1_img = false,
  play_video = true,
  timelineElement = () => {},
  isCreator,
  setDataFetch,
  dataFetch,
  postData,
}) => {
  const [pageActive, setPageActive] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();
  const refVideo = useRef();

  useDisableContextMenu(ref);
  useDisableContextMenu(refVideo);
  const { t } = useTranslation();

  useEffect(() => {
    ref.current?.setAttribute('playsinline', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const NextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ChevronRight />
      </div>
    );
  };
  const PrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <ChevronLeft />
      </div>
    );
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    draggable: noScroll,
    swipe: noScroll,
    afterChange: (current) => setPageActive(current),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const handlePostBookMark = () => {
    if (isFanF017) {
      dispatch(postBookMarkF017(id_post));
    } else {
      if (isMyPage) {
        dispatch(
          postBookMark(id_post, (res) => {
            const newData = dataFetch?.dataTimeline.map((item) => {
              if (Number(item?.id) !== Number(id_post)) return item;
              return {
                ...item,
                number_bookmark: !!res?.data?.bookmark_status
                  ? item?.number_bookmark + 1
                  : item?.number_bookmark - 1,
                user_bookmark_status: !!res?.data?.bookmark_status ? 1 : 0,
              };
            });
            setDataFetch({
              ...dataFetch,
              dataTimeline: newData,
            });
          })
        );
      } else if (isF006) {
        dispatch(postBookMark(id_post, removePostInListBookmark));
      } else {
        dispatch(postBookMarkDetail(id_post));
      }
    }
  };

  const handleClickImage = async (image, listImage = [], type = '', e) => {
    if (!noClick) {
      await id_post;
      if (!isPostDetail) {
        is_idol
          ? navigate(`${newPathUser}post${type}/${id_post}`)
          : navigate(`${newPathUser}post-normal/${id_post}`);
        e?.preventDefault();
      } else {
        const indexImg = listImage.indexOf(image);
        const newListImg = [
          ...listImage.slice(indexImg, listImage?.length),
          ...listImage.slice(0, indexImg),
        ];
        onClickImage({ image, listImage: newListImg, postInfo: postData });
        if (e) {
          e.preventDefault();
          e.target.pause();
        }
      }
    }
  };

  let newImages = images.reduce((newImages, item) => {
    if (post_type === 2) {
      if (item.type === 4) {
        newImages.push({
          id: item.id,
          type: item.type,
          url: item.url,
          post_id: item.post_id,
        });
      }
    } else {
      if (item.type === 1 || item.type === 2) {
        newImages.push({
          id: item.id,
          type: item.type,
          url: item.url,
          post_id: item.post_id,
        });
      }
    }

    return newImages;
  }, []);

  return newImages?.length === 0 ? (
    <>
      {/* {!is_idol && !isCreator && ( */}
      <ButtonBookmark
        bookmark_status={bookmark_status}
        onClick={(e) => {
          e.stopPropagation();
          handlePostBookMark();
        }}
        number_bookmark={number_bookmark}
      />
      {/* )} */}
      {show_img_demo && (
        <ImgCustom
          src={ImgDemo}
          alt=''
          className='img-demo'
          onClick={() => handleClickImage()}
        />
      )}
      {timelineElement()}
    </>
  ) : (
    <Slider {...settings} className={`list-images ${className} ${isMyPage && 'my-page-view'}`}>
      {newImages.slice(0, show_1_img ? 1 : newImages.length).map((item, index) => (
        <div className='list-images-item' key={index}>
          <div
            className={`pagination ${
              show_1_img ? 'hidden' : newImages.length <= 1 && 'hidden'
            }`}>
            <span>
              {pageActive + 1}/{newImages.length}
            </span>
            <div className='gray-screen'></div>
          </div>
          {!is_idol && (
            <ButtonBookmark
              bookmark_status={bookmark_status}
              onClick={handlePostBookMark}
              number_bookmark={number_bookmark}
            />
          )}
          {item.type === 1 || item.type === 4 ? (
            <ImgCustom
              onClick={() => handleClickImage(item, newImages, type)}
              src={`${item.url}` || ImgDemo}
              alt=''
              onError={(e) => onErrImage(e, false)}
              style={{ cursor: 'pointer' }}
            />
          ) : item.type === 2 && item?.url?.indexOf('.m3u8') === -1 ? (
            <div
              className='post-video'
              style={{ cursor: 'pointer' }}
              onClick={(e) =>
                isMyPage ? handleClickImage() : handleClickImage(item, newImages, type)
              }>
              <div className='cheat-ui'></div>
              <video width='100%' height='100%' controls={play_video} ref={refVideo}>
                <source
                  src={`${linkS3Video}${decryptPath(item.url)}#t=0.001`}
                  type='video/mp4'
                />
                {t('messageNotSupportVideo')}
              </video>
            </div>
          ) : (
            item.type === 2 &&
            item?.url?.indexOf('.m3u8') !== -1 && (
              <div
                className='post-video'
                style={{ cursor: 'pointer' }}
                onClick={(e) =>
                  isMyPage ? handleClickImage() : handleClickImage(item, newImages, type)
                }>
                <div className='cheat-ui'></div>
                <ReactHlsPlayer
                  playerRef={ref}
                  src={`${linkS3VideoM3U8}${decryptPath(item.url) + '#t=0.1'}`}
                  autoPlay={false}
                  controls={true}
                  width='100%'
                  height='100%'
                  playsInline={true}
                />
              </div>
            )
          )}
        </div>
      ))}
    </Slider>
  );
};

export default ListImages;
