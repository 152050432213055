export default function IdentifyCardCameraIcon(props) {
  return (
    <svg
      width={52}
      height={52}
      viewBox='0 0 52 52'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <mask
        id='mask0_2989_202555'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x={0}
        y={0}
        width={52}
        height={52}>
        <rect width={52} height={52} rx={8} fill='#FFECF3' />
      </mask>
      <g mask='url(#mask0_2989_202555)'>
        <rect width={52} height={52} rx={8} fill='#FCECEF' />
        <rect
          x='12.5762'
          y={26}
          width={52}
          height={52}
          rx={8}
          transform='rotate(44.9205 12.5762 26)'
          fill='#F8D0D8'
        />
        <rect
          x='26.5723'
          y='18.5703'
          width={52}
          height={52}
          rx={8}
          transform='rotate(26.0776 26.5723 18.5703)'
          fill='#DC143C'
        />
        <rect x='9.28516' y='9.28906' width={9} height={9} rx='4.5' fill='#DC143C' />
      </g>
    </svg>
  );
}
