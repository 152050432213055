import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ImgCustom } from '..';
import { LOGO_COCO_FANS } from '../../utils/constant';
import {
  MessageEmailV2,
  VisaV2Icon,
  JcbV2Icon,
  MasterCardV2Icon,
  FacebooklV2,
  TwitterV2,
  InstagramV2,
} from 'images';
import './FooterVender.scss';
const FooterVender = () => {
  const { t } = useTranslation();
  return (
    <div className='block-div-vender'>
      <div className='footer-list-idolv2'>
        <div className='content-left'>
          <div className='contact-us'>{t('Footer.FooterV2.contactUs')}</div>
          <div className='text'>{t('Footer.FooterV2.discounts')}</div>
        </div>
        <div className='content-right'>
          <div className='text-right'>
            <div style={{ marginTop: '-2px' }}>
              <MessageEmailV2 />
            </div>
            <input
              className='input-mail'
              placeholder={t('Footer.FooterV2.enterYourEmail')}></input>
          </div>
          <div className='button'>
            <button className='btn button-submit button-red-new'>{t('Footer.FooterV2.subscribe')}</button>
          </div>
        </div>
      </div>
      <div className='block-center row'>
        <div className='col-md-12' style={{ marginBottom: '16px', paddingLeft: '0px' }}>
          <Link to='/' reloadDocument>
            <ImgCustom
              src={LOGO_COCO_FANS}
              height={32}
              width={94}
              alt='logo'
              className='logo-cocofans d-none d-lg-block'
            />
          </Link>
        </div>
        <div className='col-md-7 content-left'>
          <div className='text-big'>{t('Footer.FooterV2.bladeINC')}</div>
          <div className='text'>
            Lilia Angelica Nishijima Vargas | 5348 Vegas Dr, Las Vegas, NV USA 89108 <br />{' '}
            424-571-0772 | info@sharegram.com
          </div>
        </div>
        <div className='col-md-5 content-right'>
          <div className='row'>
            <div className='col-md-7 text'>
              <div className='text'>{t('Footer.FooterV2.weCccept')}</div>
              <div className='block-card'>
                <VisaV2Icon />
                <MasterCardV2Icon />
                <JcbV2Icon />
              </div>
            </div>
            <div className='col-md-5 text block-follow'>
              <div className='text'>{t('Footer.FooterV2.followUs')}</div>
              <div className='block-card'>
                <TwitterV2 />
                <FacebooklV2 />
                <InstagramV2 />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterVender;
