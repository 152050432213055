import { InputWrapper } from 'components/Input/InputWrapper';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateLiveStream, updateTypeLiveStream } from 'store/actions/lives';
import { pushNotify } from 'components/Notification';
import { PopupConfirmFan, PopupGeneral } from '..';
// import { CloseIcon } from '../../images';
import lodash from 'lodash';
import { useTranslation } from 'react-i18next';
import DeleteIcon from 'images/DeleteIcon';

const PopupNgSetting = ({
  dataSetting,
  handleAddNgSetting,
  showScreen,
  liveKey,
  newStyle,
  id,
  isShowButtonCancel = false,
}) => {
  const [isVisiblePopUp, setIsVisiblePopUp] = useState(false);
  const [isVisiblePopUpConfirm, setIsVisiblePopUpConfirm] = useState(false);
  const [isVisiblePopUpDelete, setIsVisiblePopUpDelete] = useState(false);
  const [valueInput, setValueInput] = useState('');
  const [idNGWord, setIdNGWord] = useState('');
  let [listSetting, setListSetting] = useState(dataSetting);
  const tableSetting = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const refTable = useRef();
  useEffect(() => {
    dataSetting && setListSetting(dataSetting);
  }, [dataSetting]);

  useEffect(() => {
    if (dataSetting !== '' || dataSetting !== null) {
      setValueInput(dataSetting);
    }
  }, [dataSetting]);

  const scrollToBottom = () => {
    if (isVisiblePopUp) {
      const scroll = tableSetting.current.scrollHeight - tableSetting.current.clientHeight;
      tableSetting.current.scrollTo(0, scroll);
    }
  };

  useEffect(() => {
    // isVisiblePopUp && scrollToBottom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisiblePopUp, listSetting]);

  // const handleRemoveItem = (e) => {
  //   console.log(e);
  //   const newArr = listSetting.filter((item) => item.id !== e.id);
  //   setListSetting(newArr);
  // };

  // const handleAddSetting = () => {
  //   if (valueInput) {
  //     setListSetting(valueInput);
  //     // setValueInput('');
  //   }
  // };
  const hasDuplicates = (arr) => {
    //const listNG = [listSetting, ...arr]
    return lodash.uniq(arr).length !== arr.length;
  };

  const handleNGWord = () => {
    if (showScreen === 'C092') {
      const newValueInput = valueInput.toString();
      const results = newValueInput?.split(',').map((element) => {
        return element.trim();
      });
      const dVal = lodash.without(results, undefined, null, '', '');
      if (!hasDuplicates(dVal)) {
        setIsVisiblePopUpConfirm(true);
      } else {
        pushNotify('error', t('ValidateMsg.registerNG'), 'errdup');
        let uniqueChars = [...new Set(dVal)];
        setValueInput(uniqueChars);
        // setValueInput(lodash.uniq(results));
      }
    } else {
      setIsVisiblePopUpConfirm(true);
    }
  };

  const handleDeleteNGWord = (id) => {
    setIdNGWord(id);
    setIsVisiblePopUpDelete(true);
  };

  const deleteNGWord = () => {
    // let newArr = listSetting

    // console.log("idNGWord: ", idNGWord, "-listSetting: ", listSetting);

    const value = typeof listSetting === 'string' ? listSetting.split(',') : listSetting;

    const index = value.indexOf(idNGWord);
    if (index > -1) {
      value.splice(index, 1);

      setListSetting(value);
      handleAddNgSetting(value);
      setIsVisiblePopUpDelete(false);
    }
  };

  const handleSubmit = () => {
    if (showScreen !== 'C092') {
      const results = valueInput?.split(',').map((element) => {
        return element.trim();
      });

      let dataListSetting = null;

      if (listSetting.length > 0) {
        dataListSetting =
          typeof listSetting === 'string'
            ? listSetting.split(',').concat(results)
            : listSetting.concat(results);

        // console.log('-listSetting length: ', listSetting, results, dataListSetting);
      } else {
        // console.log('NOT-listSetting: ', listSetting);
        dataListSetting = results; // [listSetting, ...results];
      }

      let vals = lodash.without(dataListSetting, undefined, null, '', '');
      const valueSend = lodash.uniq(vals);

      setListSetting(valueSend);
      handleAddNgSetting(valueSend);
    } else {
      const newValueInput = valueInput.toString();
      const results = newValueInput?.split(',').map((element) => {
        return element.trim();
      });

      const dataListSetting = results;
      setListSetting(dataListSetting);
      handleAddNgSetting(dataListSetting);
    }
    // if (valueInput) {
    //   // const newArr = listSetting.concat({ id: listSetting.length + 1, name: valueInput });
    //   const newArr = valueInput;
    //   setListSetting(newArr);
    //   handleAddNgSetting(newArr);
    // } else {
    //   handleAddNgSetting(listSetting);
    // }

    // if (typeof valueInput === 'string') {
    //   const results = valueInput?.split(',').map(element => {
    //     return element.trim();
    //   });
    //   dataListSetting = [listSetting, ...results];
    // } else {
    //    dataListSetting = [listSetting, ...valueInput];
    // }

    setIsVisiblePopUp(false);
    setIsVisiblePopUpConfirm(false);
    // window.scrollTo(0, document.body.scrollHeight);

    // setValueInput('');

    //* CHECK IF SCREEN C092 CALL API UPDATE NGWORDS
    if (showScreen !== undefined && showScreen === 'C092') {
      // console.log("DDSDSDSD: ", valueInput);
      const newData = {
        id,
        live_key: liveKey,
        ng_words: valueInput.toString(),
      };
      return dispatch(updateTypeLiveStream(newData, 'ng_word'));
    }
  };

  const widthTable = refTable?.current?.clientWidth;

  return (
    <>
      {(showScreen === undefined || showScreen !== 'C092') && (
        <>
          <button
            className={`btn text-white d-middle rounded-6px`}
            style={{
              minWidth: 151,
              height: 44,
              marginBottom: 26,
              marginTop: 24,
              background: '#DC143C',
              fontWeight: '500',
              fontSize: 16,
            }}
            type='button'
            onClick={() => {
              setValueInput('');
              setIsVisiblePopUp(true);
            }}>
            {t('Popup.wordSetting')}
          </button>
          <div className='table-ng-words'>
            <table className='table' ref={refTable}>
              <thead>
                <tr style={{ color: '#1A1A1A' }}>
                  <th>{t('Creator.ReviewLiveStream.no')}.</th>
                  <th>{t('Popup.NGword')}</th>
                  <th className='action'></th>
                </tr>
              </thead>
              <tbody>
                {typeof listSetting === 'string' ? (
                  listSetting && listSetting?.length > 0 ? (
                    listSetting?.split(',')?.map((item, index) => (
                      <tr key={index}>
                        <td className='no' style={{ color: '#1A1A1A' }}>
                          {index + 1}
                        </td>
                        <td
                          className='td-text'
                          style={{
                            maxWidth: `calc(${widthTable}px - 93px)`,
                            color: '#1A1A1A',
                          }}>
                          <span>{item}</span>
                        </td>
                        <td className='action'>
                          <span
                            onClick={() => handleDeleteNGWord(item)}
                            className='del-ngword'>
                            <DeleteIcon color='#1A1A1A' />
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className='tr-empty'>
                      <td colSpan={3} className='text-no-data'>
                        {t('Popup.messageNoData')}
                      </td>
                    </tr>
                  )
                ) : listSetting && listSetting?.length > 0 ? (
                  listSetting?.map((item, index) => (
                    <tr key={index}>
                      <td className='no'>{index + 1}</td>
                      <td
                        className='td-text'
                        style={{ maxWidth: `calc(${widthTable}px - 100px)` }}>
                        <span>{item}</span>
                      </td>
                      <td className='action'>
                        <span onClick={() => handleDeleteNGWord(item)} className='del-ngword'>
                          <DeleteIcon color='#1A1A1A' />
                        </span>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr className='tr-empty'>
                    <td colSpan={3} className='text-no-data'>
                      {t('Popup.messageNoData')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </>
      )}

      {showScreen !== undefined && showScreen === 'C092' && (
        <div
          className='btn'
          style={newStyle}
          onClick={() => {
            setIsVisiblePopUp(true);
          }}>
          {t('Popup.setting')}
        </div>
      )}

      {isVisiblePopUpConfirm && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopUpConfirm(e)}
          colorSvg='#FFBF00'
          iconNote={true}
          fillColor='#FFBF00'
          text={t('Popup.commentContaining')}
          textColor='#000000'>
          <div
            className='ok btn'
            style={{ background: '#DC143C' }}
            onClick={handleSubmit}>
            {t('Common.Ok')}
          </div>
          <div
            className='btn cancel'
            style={{ fontWeight: 500 }}
            onClick={() => setIsVisiblePopUpConfirm(false)}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}

      {isVisiblePopUpDelete && (
        <PopupConfirmFan
          isVisiblePopup={(e) => setIsVisiblePopUpConfirm(e)}
          colorSvg='#FF9F43'
          iconNote={true}
          text={t('Popup.messageDeleteNGWord')}>
          <div
            className='ok btn'
            style={{ background: '#dc143c' }}
            onClick={deleteNGWord}>
            {t('Common.Ok')}
          </div>
          <div
            className='btn cancel'
            style={{ fontWeight: 500 }}
            onClick={() => setIsVisiblePopUpDelete(false)}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}

      {isVisiblePopUp && (
        <PopupGeneral
          className='popup-ng-setting'
          generalClass='pp-up-ng-setting-views'
          closeModal={(e) => setIsVisiblePopUp(e)}
          title={t('Popup.wordSetting')}
          colorTitle='#000000'>
          <div
            className=''
            style={{ paddingLeft: '20px', paddingRight: '20px' }}
            ref={tableSetting}>
            <InputWrapper
              id='ng-words'
              label={t('Popup.NGword')}
              error={''}
              notification={true}
              notificationContent={t('Popup.messageSeparate')}
              className='inputWrapper-ngword'>
              <input
                type='text'
                placeholder=''
                className='fsite-input'
                maxLength={500}
                //defaultValue={( showScreen === 'C092' ? dataSetting : '')}
                defaultValue={showScreen === 'C092' ? valueInput : ''}
                value={showScreen === 'C092' ? valueInput : null}
                onChange={(e) => {
                  setValueInput(e.target.value);
                }}
              />
            </InputWrapper>
          </div>

          <div className='w-100 d-flex justify-content-end box-btn-submit'>
            <button
              disabled={showScreen !== 'C092' ? !valueInput : false}
              className='btn btn-submit text-white danger rounded-6px'
              style={{ fontSize: '14px', fontWeight: '500', margin: '16px 0px' }}
              type='button'
              onClick={handleNGWord}>
              {t('Popup.wordSetting')}
            </button>
            {isShowButtonCancel && (
              <button
                className='button-cancel-word'
                onClick={(e) => {
                  setIsVisiblePopUp(false);
                }}>
                {t('Common.buttonCancel')}
              </button>
            )}
          </div>
        </PopupGeneral>
      )}
    </>
  );
};

export default PopupNgSetting;
