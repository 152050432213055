import Icon from '@ant-design/icons';

const LoadingIconV2 = (props) => (
  <Icon
    component={() => (
      <svg
        width='48'
        height='48'
        viewBox='0 0 48 48'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className={props.className}>
        <path
          opacity='0.4'
          d='M24 48C19.2533 48 14.6131 46.5924 10.6663 43.9553C6.71954 41.3181 3.64341 37.5698 1.8269 33.1844C0.0103989 28.799 -0.464881 23.9734 0.461164 19.3178C1.38721 14.6623 3.67299 10.3859 7.02945 7.02945C10.3859 3.67299 14.6623 1.38721 19.3178 0.461164C23.9734 -0.464881 28.799 0.0103989 33.1844 1.8269C37.5698 3.64341 41.3181 6.71954 43.9553 10.6663C46.5924 14.6131 48 19.2533 48 24C47.9929 30.363 45.462 36.4634 40.9627 40.9627C36.4634 45.462 30.363 47.9929 24 48ZM24 4.00001C20.0444 4.00001 16.1776 5.17299 12.8886 7.37062C9.59962 9.56825 7.03617 12.6918 5.52242 16.3463C4.00867 20.0009 3.6126 24.0222 4.38431 27.9018C5.15601 31.7814 7.06082 35.3451 9.85787 38.1421C12.6549 40.9392 16.2186 42.844 20.0982 43.6157C23.9778 44.3874 27.9992 43.9914 31.6537 42.4776C35.3082 40.9639 38.4318 38.4004 40.6294 35.1114C42.827 31.8224 44 27.9556 44 24C43.9939 18.6975 41.8848 13.614 38.1354 9.8646C34.386 6.11519 29.3025 4.0061 24 4.00001Z'
          fill='#FBA7A0'
        />
        <path
          d='M48 24H44C43.9939 18.6975 41.8848 13.614 38.1354 9.86459C34.386 6.11518 29.3025 4.00609 24 4V0C30.3629 0.00741045 36.4631 2.53835 40.9624 7.03762C45.4616 11.5369 47.9926 17.6371 48 24Z'
          fill='#DC143C'
        />
      </svg>
    )}
  />
);

export default LoadingIconV2;
