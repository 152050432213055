import { DangerCircleIcon } from 'images';
import './index.scss';
const Tooltip = ({ children, className, text = '', ...props }) => {
  return (
    <div className={`tooltip ${className}`} {...props}>
      {text ? text : <DangerCircleIcon />}
      <span className='tooltiptext'>{children}</span>
    </div>
  );
};

export default Tooltip;
