import RouteSuspense from '../components/RouteSuspense';
import CreatorHomePage from '../pages/creator/layout/home-page';
import PostContentDetail from '../pages/creator/layout/post-article/post-content-detail';
import PostContentSaleDetail from 'pages/creator/layout/post-article/post-content-sale';
import ListMessage from 'pages/creator/layout/message';
import Message from 'pages/creator/layout/message/detail';
//fan
import PageNotFound from '../pages/not-found';

export const ADMIN_ROUTES = [
  {
    path: '/',
    element: <CreatorHomePage />,
  },
  {
    path: '/:account_id',
    element: (
      <RouteSuspense>
        <CreatorHomePage />
      </RouteSuspense>
    ),
  },
  {
    path: '/post/:id',
    element: (
      <RouteSuspense>
        <PostContentDetail />
      </RouteSuspense>
    ),
  },
  {
    path: '/post-sale/:id',
    element: (
      <RouteSuspense>
        <PostContentSaleDetail />
      </RouteSuspense>
    ),
  },
  // {
  //   path: '/post-content/:id',
  //   element: (
  //     <RouteSuspense>
  //       <PostContentDetail />
  //     </RouteSuspense>
  //   ),
  // },
  // {
  //   path: '/post-short/:id',
  //   element: (
  //     <RouteSuspense>
  //       <PostShortDetail />
  //     </RouteSuspense>
  //   ),
  // },
  {
    path: '/message',
    element: (
      <RouteSuspense>
        <ListMessage />
      </RouteSuspense>
    ),
  },
  {
    path: '/message/:id',
    element: (
      <RouteSuspense>
        <Message />
      </RouteSuspense>
    ),
  },
  {
    path: '*',
    element: (
      <RouteSuspense>
        <PageNotFound />
      </RouteSuspense>
    ),
  },
];

export const ADMIN_FAN_ROUTES = [
  {
    path: '/message',
    element: (
      <RouteSuspense>
        <ListMessage />
      </RouteSuspense>
    ),
  },
  {
    path: '/message/:id',
    element: (
      <RouteSuspense>
        <Message />
      </RouteSuspense>
    ),
  },
  {
    path: '*',
    element: (
      <RouteSuspense>
        <PageNotFound />
      </RouteSuspense>
    ),
  },
];
