import Icon from '@ant-design/icons';

const ThreePointIconV3 = (props) => (
  <Icon
    component={() => (
      <svg
        width={13}
        height={3}
        viewBox='0 0 13 3'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx='1.5' cy='1.5' r='1.5' fill='#BEBEBE' />
        <circle cx='6.5' cy='1.5' r='1.5' fill='#BEBEBE' />
        <circle cx='11.5' cy='1.5' r='1.5' fill='#BEBEBE' />
      </svg>
    )}
    {...props}
  />
);

export default ThreePointIconV3;
