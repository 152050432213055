import { createContext } from 'react';
import { Card } from './Card';

export const TimelineContext = createContext();
TimelineContext.displayName = 'Store Timeline - short video';
export const TimelinePost = ({
  data = [],
  setShowPopup,
  isShowPopup,
  item,
  flgPause,
  page, 
  itemParent
}) => {
  return (
    <TimelineContext.Provider
      value={{ data, setShowPopup, isShowPopup, item, flgPause, page, itemParent }}>
      <Card />
    </TimelineContext.Provider>
  );
};
