import React, { useState } from 'react';
import { TableCustom } from 'components';
import { convertTextMonthEN, formatCoin } from 'utils/utils';
import { useTranslation } from 'react-i18next';
const Table = ({
  columns,
  itemClick,
  genderChart,
  totalTableField = '',
  clearActive = false,
}) => {
  const [activeClick, setActiveClick] = useState();
  const { t, i18n } = useTranslation();
  const handleClickDate = (item, type) => {
    setActiveClick(`${item.month}-${type}`);
    itemClick(item);
    genderChart(type);
  };

  const handleSetClassNameMonth = (item, type) => `${item.month}-${type}`;

  return (
    <TableCustom style={{ marginTop: 32 }} className='general able-click'>
      <thead>
        <tr>
          <th></th>
          {columns.map((item, index) => (
            <th key={index}>
              {i18n.language === 'jp' ? (
                <>{+item.month + `${t('Common.month')}`}</>
              ) : (
                convertTextMonthEN(+item.month)
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>{t('Common.men')}</th>
          {columns.map((item) => (
            <td
              key={item.month}
              onClick={() => item.male > 0 && handleClickDate(item, 'male')}
              className={`${
                !clearActive &&
                handleSetClassNameMonth(item, 'male') === activeClick &&
                'active'
              }`}>
              {formatCoin(item.male)}
            </td>
          ))}
        </tr>
        <tr>
          <th>{t('Common.woman')} </th>
          {columns.map((item) => (
            <td
              key={item.month}
              className={`${
                !clearActive &&
                handleSetClassNameMonth(item, 'female') === activeClick &&
                'active'
              }`}
              onClick={() => item.female > 0 && handleClickDate(item, 'female')}>
              {formatCoin(item.female)}
            </td>
          ))}
        </tr>
        <tr className='disabled'>
          <th>{totalTableField} </th>
          {columns.map((item) => (
            <td key={item.month}>{formatCoin(item.total)}</td>
          ))}
        </tr>
      </tbody>
    </TableCustom>
  );
};

export default Table;
