import { PopupGeneral, ImgCustom } from 'components';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ControlBar,
  CurrentTimeDisplay,
  Player,
  TimeDivider,
  VolumeMenuButton,
} from 'video-react';
import 'video-react/dist/video-react.css';
import { IMG_NO_WATER_MARK, decryptPath, linkS3Video } from '../../utils/utils';

const PopupShowVideo = ({ item, thumbnail, classNameCustom, water_mark = true, cb }) => {
  const [isVisiblePopUp, setIsVisiblePopUp] = useState(false);
  const url = item?.[0]?.url;
  const widthScreen = window.innerWidth;
  const heightScreen = window.innerHeight;
  const [width, setWidth] = useState(window.innerWidth * 0.5);
  const [height, setHeight] = useState(window.innerHeight * 0.5);
  const ref = useRef();
  const { t } = useTranslation();

  useEffect(() => {
    if (widthScreen < 991) {
      setWidth(widthScreen * 0.85);
      setHeight(heightScreen * 0.5);
    }
  }, [heightScreen, widthScreen]);

  useEffect(() => {
    if (isVisiblePopUp) {
      const checkVideo = document.querySelector('.video-react-video');
      checkVideo && checkVideo.pause();
    }
  }, [isVisiblePopUp]);

  return (
    <>
      <div
        className='btn-open-trailer'
        onClick={() => {
          cb && cb();
          setIsVisiblePopUp(true);
        }}>
        {t('Popup.messageSampleVideo')}
      </div>
      {isVisiblePopUp && (
        <PopupGeneral
          //style={{ maxHeight: '540px' }}
          title=''
          className={`popup-show-video ${classNameCustom}`}
          closeModal={() => setIsVisiblePopUp(false)}>
          {water_mark ? (
            <Player
              playsInline
              poster={`${decryptPath(thumbnail)}` || ''}
              ref={ref}
              fluid={false}
              width={width}
              height={height}
              //className='video-detail-custom'
              >
              <source src={`${linkS3Video}${decryptPath(url) + '#t=0.1'}`} />
              {/* <ControlBar>
                <CurrentTimeDisplay order={4.1} />
                <TimeDivider order={4.2} />
                <VolumeMenuButton />
              </ControlBar> */}
            </Player>
          ) : (
            <ImgCustom
              src={IMG_NO_WATER_MARK}
              style={{
                marginTop: 0,
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                maxHeight: '520px',
              }}
            />
          )}
        </PopupGeneral>
      )}
    </>
  );
};

export default PopupShowVideo;
