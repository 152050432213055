/* eslint-disable no-unused-vars */

import {
  AvatarInfor,
  ImgCustom,
  LayoutCreatorRight,
  PopupConfirmFan,
  PopupLoading,
  PopupLockPostNormal,
  PopupSharePost,
  ReadMoreLess,
  Textarea,
  ToggleButton,
} from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { getEnv } from 'configs/env.js';
import { newPathUser } from 'constants/layout/constant';
import {
  AvatarDefault,
  BookmarkIcon,
  CancelRetweetIcon,
  CancelSussRetweetIcon,
  ChatIcon,
  FlowUnFlowIcon,
  HeartActiveIcon,
  HeartIcon,
  NoBookmarkList,
  QuotedIcon,
  RePostIcon,
  RePostIconV2,
  ReportIcon,
  SendChatIcon,
  ShareIcon,
  ThreePointIcon,
  UnFlowIcon,
} from 'images';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import { postBookMark } from 'store/actions/myPage';
import {
  getListBookmark,
  likePost,
  postCommentDetail,
  postRetweet,
  resetDetailPost,
  updatePostLikeDetail,
  postBookMark,
  fanFollowCreator,
  postNormalPaymentBuyPackageSuccess,
  getIDFavor,
  resetIDHomePage,
  resetDataInfoOther
} from 'store/actions/usersNew';
import {
  fanGetListBookmark,
  followOrUnFollowUser,
  getUserInfo,
  resetIDFavorite,
  resetIDLike,
  searchTimeline,
} from 'store/actions/users';
import { convertToCurrentTime, renderTimeAfter24h } from 'utils/utils';
import '../index.scss';
import { TimelinePost } from './elements';
import { InputWrapper } from 'components/Input/InputWrapper';
import ListTopFirstComment from './elements/comments/listTopFirstComment';
import { deleteCommentDetail, likeCommentDetail } from 'store/actions/usersNew';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import { StyledTimeline } from './elements/styled';
import { useClickOutSize } from 'hooks/v2/clickOutSide';
import { useLayoutEffect } from 'react';
import MarkDownRead from 'components/MarkDownRead';
import { usePopupPaymentSub } from 'hooks/v2/usePopupPaymentSub';
import { useCallback } from 'react';
import QuotePostMyPage from 'components/Popup/quotePostMyPage';
import { isMobile } from 'react-device-detect';
import CommentForm from './elements/commentForm';
import { CommentPostTimeLineShowAll } from 'components/comment/commentListShowAll';
import { ContextTimeline, ProviderTimeline } from 'pages/timeline/contextTimeline';
import { useFixedPopup } from 'hooks/v2/useFixedPopup';
import AvatarInforDetail from 'components/Account/AvatarInforDetail';
import { AVATAR_DEFAULT } from 'utils';
import Blur_img from 'images/Blur_Right.png';

const CountComment = () => {
  const { countNumberView } = useContext(ContextTimeline);
  return <span className='number'>{countNumberView.countNumberComment}</span>;
};
const RenderPostItem = ({
  item,
  index,
  refOutSideThree,
  setIsVisiblePopup,
  isVisiblePopup,
  dataUser,
  listImgFunc,
  setIsOpen,
  setIdPackage,
  checkDisabledAction,
  handleLikePost,
  handleNavigate,
  setIsVisiblePopupGhim,
  isVisiblePopupGhim,
  handleRetweet,
  widthScreen,
  setPopupDeleteComment,
  handleLikeComment,
  handleFollowCommentUser,
  setTrickReloadComment,
  trickReloadComment,
  handleSharePost,
  setDataGetListBookmark,
  dataGetListBookmark,
  setItemPostPopup,
  setIsVisibleQuote,
  page,
  resetDataWhenBySuccessByZero,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  if (item?.type === 2) {
    const itemAfter = item?.medias?.filter((media) => media.type === 4);
  }
  const { fanListBookmark } = useSelector((state) => state.users);
  const [isFollowed, setIsFollowed] = useState(0);
  const [hoverId, setHoverId] = useState(false);
  useEffect(() => {
    const findUser = fanListBookmark.find((it) => it.user_id === item?.user_id);
    setIsFollowed(findUser?.is_followed);
  }, [fanListBookmark]);
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  const handleBookmark = () => {
    dispatch(getIDFavor({ id: item?.id, page: page, tab: 1 }));
    dispatch(resetIDHomePage());
    dispatch(resetDataInfoOther());
  };

  return (
    <ProviderTimeline>
      <div style={{ position: 'relative' }}>
        <div className='d-flex justify-content-between user-infor'>
          <div className={`avatar-infor `}>
            <div className='content-left'>
              <ImgCustom
                isPlaceholderAvatar
                screen='avatar_44_44'
                src={item?.avatar ? `${item?.avatar}` : AVATAR_DEFAULT}
                alt='avatar'
                onClick={(e) => {
                  e.stopPropagation();
                  handleBookmark && handleBookmark();
                  navigate(`${newPathUser}${item?.account_id}`, {
                    state: { name: 'homepage' },
                  });
                }}
              />
              <div style={{ display: 'flex' }}>
                <div
                  className='wrap--text'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleBookmark && handleBookmark();
                    navigate(`${newPathUser}${item?.account_id}`, {
                      state: { name: 'homepage' },
                    });
                  }}>
                  <span style={{ marginTop: '-5px' }} className='name'>
                    {item?.account_name}
                  </span>
                </div>
                <div
                  style={{
                    border: '1px solid #DC143C',
                    borderRadius: '20px',
                    alignSelf: 'flex-start',
                    padding: '0 5px',
                    cursor: 'pointer',
                    fontSize: '12px',
                    fontWeight: '500'
                  }}
                  className={`btn-followOrUnFollow ${
                    item?.is_followed === 1 ? 'following' : 'no-follow'
                  } ${item?.is_followed === 1 && hoverId === item?.id ? 'hover' : ''}`}
                  onMouseOver={() => {
                    if (item?.is_followed === 1 && item?.id) {
                      !isMobile && setHoverId(item.id);
                    }
                  }}
                  onMouseLeave={() => {
                    if (item?.is_followed === 1) {
                      setHoverId(false);
                    }
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(fanFollowCreator(item.user_id));
                  }}>
                  {widthScreen > 991 ? (
                    item?.is_followed === 1
                    ? hoverId === item?.id
                      ? t('Creator.FollowList.unfollow')
                      : t('Creator.FollowList.following')
                    : t('Creator.FollowList.followUs')
                  ) : (
                    item?.is_followed === 1
                      ? t('Creator.FollowList.following')
                    : t('Creator.FollowList.followUs')
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className='box-popup bookmark-box-popup'>
            <span
              className='btn-three-point'
              ref={refOutSideThree}
              onClick={() => {
                setIsVisiblePopup(isVisiblePopup === item?.id ? -1 : item?.id);
              }}>
              <ThreePointIcon style={{ color: '#666565' }} className='three-point-icon' />
            </span>
            {isVisiblePopup === item?.id && (
              <div className='popup-edit-comment'>
                <div
                  className='item'
                  onMouseDown={() => {
                    setIsVisiblePopup(-1);

                    navigate(`${newPathUser}post-report`, {
                      state: {
                        fan_id: item?.user_id,
                        fan_name: item?.account_name,
                        post_id: item?.id,
                      },
                    });
                  }}>
                  <ReportIcon className='share-icon' />
                  <span className='text'>{t('comment.listComment.iconFlag')}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {
          item?.medias?.length > 0 && (
            <>
              {item?.type === 1 && (
                <>
                  {item?.medias?.[0].type === 1 && (
                    <>
                      {(() => {
                        const data = item?.medias
                          ?.filter((item) => item?.type === 1)
                          .filter((item) => item.public_flag === 1);
                        return data.length === 0;
                      })()}
                    </>
                  )}
                </>
              )}
            </>
          )
          // bookmarkFunc(item)
        }
      </div>
      {/* <div className={`content `}>
        <div className='text'>
          <MarkDownRead
            isMeta
            charLimit={widthScreen > 575 ? 150 : 30}
            content={item?.type === 2 ? item?.title : item?.content}
          />
        </div>
      </div> */}
      <div
        style={{
          position: 'relative',
          //minHeight: !item?.is_media_display && item?.type === 1 ? 400 : 'unset',
          margin: `${
            item?.is_followed === 0 &&
            item?.follow_flag === 1 &&
            item?.user_id !== dataUser?.id
              ? '0 16px'
              : '0'
          }`,
        }}
        className='mobile-timeline1 bookmark-lock-package'>
        {item?.is_followed === 0 &&
        item?.follow_flag === 1 &&
        item?.user_id !== dataUser?.id ? (
          <div style={item?.medias?.length === 0 ? { paddingBottom: '35px' } : {}}>
            <StyledTimeline.LockScreen style={{backgroundImage: `${isFirefox ? `url(${Blur_img})` : 'transparent'}`}}>
              <div className='text-follow 2'>{t('DetailCard.notYetFollow')}</div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(fanFollowCreator(item.user_id));
                }}>
                {t('Common.follow')}
              </button>
            </StyledTimeline.LockScreen>
          </div>
        ) : (
          ''
        )}
        {item?.is_media_display === 0 && (
          <div>
            <PopupLockPostNormal
              isFavoriteListPost
              post_id={item.id}
              detailPost={item}
              in_my_page
              point={dataUser?.points}
              setIsOpen={setIsOpen}
              setIdPackage={setIdPackage}
              resetDataWhenBySuccessByZero={resetDataWhenBySuccessByZero}
            />
          </div>
        )}
        <>
          {item?.medias?.length > 0 && (
            <>
              {item?.type === 1 ? (
                <>
                  {item?.medias?.[0].type === 1 ? (
                    <>
                      {(() => {
                        const data = item?.medias
                          ?.filter((item) => item?.type === 1)
                          .filter((item) => item.public_flag === 1);
                        return data.length > 0 && listImgFunc(data, item, item);
                      })()}
                    </>
                  ) : (
                    listImgFunc(item?.medias, item, item)
                  )}
                </>
              ) : (
                listImgFunc(item?.medias, item, item)
              )}
            </>
          )}
        </>
      </div>
      <div className={`content `} style={{ margin: '0' }}>
        <div className='text'>
          <MarkDownRead
            isMeta
            charLimit={widthScreen > 575 ? 150 : 30}
            content={item?.type === 2 ? item?.title : item?.content}
          />
        </div>
      </div>
      <div className='d-flex content1 al-center mt-2' style={{ marginLeft: '16px' }}>
        <p
          className='cls-title-2nd cls-date me-1'
          style={{
            color: '#666565',
            marginTop: '0px',
            marginBottom: '0px',
            fontSize: '12px',
          }}>
          {convertToCurrentTime(item?.public_time || item?.created_at, 'YYYY/MM/DD HH:mm')}
        </p>
        {item?.unpublic_time && (
          <>
            <p
              className='cls-title-2nd cls-date ms-1 mb-left'
              style={{
                color: '#DC143C',
                marginTop: '0px',
                border: '1px solid',
                padding: '0px 5px',
                marginBottom: '0',
                marginRight: '3px',
                width: 'fit-content',
              }}>
              <span className='me-1'>{t('Common.publicDay')}</span>
              {convertToCurrentTime(item?.unpublic_time, 'YYYY/MM/DD HH:mm')}
            </p>
          </>
        )}
        {item?.in_24h_flag === 1 && (
          <>
            <p
              className='cls-title-2nd cls-date ms-1 mb-left'
              style={{
                color: '#DC143C',
                marginTop: '0px',
                border: '1px solid',
                padding: '0px 5px',
                marginBottom: '0',
                marginRight: '3px',
                width: 'fit-content',
              }}>
              <span className='me-1'>{t('Common.publicDay')}</span>
              {convertToCurrentTime(renderTimeAfter24h(item?.public_time), 'YYYY/MM/DD HH:mm')}
            </p>
          </>
        )}
      </div>
      {item?.share_post && item?.medias?.length > 0 && (
        <div
          className='block-quote-no-img mt-2'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            dispatch(getIDFavor({ id: item?.id, page: page, tab: 1 }));
            navigate(`${newPathUser}post/${item?.share_post?.id}`);
          }}>
          <div style={{ padding: '16px 0px 0px 16px' }}>
            <AvatarInforDetail
              images={item?.share_post?.avatar}
              name={item?.share_post?.account_name}
              content1={item?.share_post}
              // onClick={(e) => {
              //   e.stopPropagation();
              //   navigate(`${newPathUser}${item?.share_post?.account_id}`);
              // }}
            />
          </div>
          <div style={{ padding: '0 16px' }}>
            <MarkDownRead
              isMeta
              charLimit={isMobile ? 90 : 160}
              content={item?.share_post?.content}
            />
          </div>
          <div
            className='d-flex align-items-center mt-2'
            style={{ fontSize: '12px', paddingLeft: '16px' }}>
            <p
              className='cls-title-2nd cls-date'
              style={{
                color: '#666565',
                marginTop: '0px',
                fontSize: '12px',
                marginBottom: '0',
              }}>
              {convertToCurrentTime(
                item?.share_post?.public_time || item?.share_post?.created_at,
                'YYYY/MM/DD HH:mm'
              )}
            </p>
            &nbsp;
            {item?.share_post?.unpublic_time && (
              <>
                <p
                  className='cls-title-2nd cls-date ms-1'
                  style={{
                    color: '#DC143C',
                    marginTop: '0px',
                    border: '1px solid',
                    padding: '0px 5px',
                  }}>
                  <span className='me-1'>{t('Common.publicDay')}</span>
                  {convertToCurrentTime(item?.share_post?.unpublic_time, 'YYYY/MM/DD HH:mm')}
                </p>
              </>
            )}
            {item?.share_post?.in_24h_flag === 1 && (
              <>
                <p
                  className='cls-title-2nd cls-date ms-1'
                  style={{
                    color: '#DC143C',
                    marginTop: '0px',
                    border: '1px solid',
                    padding: '0px 5px',
                  }}>
                  <span className='me-1'>{t('Common.publicDay')}</span>
                  {convertToCurrentTime(
                    renderTimeAfter24h(item?.share_post?.public_time),
                    'YYYY/MM/DD HH:mm'
                  )}
                </p>
              </>
            )}
          </div>
          <div className='mt-3 block-img1' style={{ padding: '0 16px', fontWeight: '500' }}>
            {`${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${item?.share_post?.id}`}
          </div>
        </div>
      )}
      {(item?.share_type === 1 || item?.share_type === 2) && !item?.share_post && (
        <div
          className={`block-repost-no-img mt-2`}
          style={{
            borderRadius: '8px',
            margin: `${item?.medias?.length > 0 ? '16px' : '0 16px'}`,
          }}>
          {t('Common.unavailable')}
        </div>
      )}
      {item?.share_post && item?.medias?.length === 0 && (
        <>
          <div
            className={`block-quote ${item?.medias?.length === 0 && 'mt-2'} ${
              item?.share_post?.medias?.length === 0 && 'mb-2'
            }`}
            onClick={() => {
              dispatch(getIDFavor({ id: item?.id, page: page, tab: 1 }));
              navigate(`${newPathUser}post/${item?.share_post?.id}`);
            }}
            style={{
              cursor: 'pointer',
              borderRadius: `${item?.share_post?.share_post && '8px'}`,
            }}>
            <div style={{ padding: '16px 0px 0px 16px' }}>
              <AvatarInforDetail
                images={item?.share_post?.avatar}
                name={item?.share_post?.account_name}
                content1={item?.share_post}
                // onClick={() => navigate(`${newPathUser}${item?.share_post?.account_id}`)}
              />
            </div>
            {!item?.share_post?.share_post && (
              <div
                className='style-mb-package-homepage'
                style={{
                  position: 'relative',
                  margin: '0',
                }}>
                {item?.share_post?.is_followed === 0 &&
                  item?.share_post?.follow_flag === 1 &&
                  item?.share_post?.user_id !== dataUser?.id && (
                    <StyledTimeline.LockScreen style={{backgroundImage: `${isFirefox ? `url(${Blur_img})` : 'transparent'}`}}>
                      <div className='text-follow 1'>{t('DetailCard.notYetFollow')}</div>
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(followOrUnFollowUser(item?.share_post?.user_id));
                        }}>
                        {t('Common.follow')}
                      </button>
                    </StyledTimeline.LockScreen>
                  )}
                {item?.share_post?.is_media_display === 0 && (
                  <div>
                    <PopupLockPostNormal
                      is_class={true}
                      isFavoriteListPost
                      post_id={item?.share_post?.id}
                      detailPost={item?.share_post}
                      point={dataUser?.points}
                      setIsOpen={setIsOpen}
                      setIdPackage={setIdPackage}
                      resetDataWhenBySuccessByZero={resetDataWhenBySuccessByZero}
                    />
                  </div>
                )}
                <div style={{ margin: '0 -16px' }}>
                  {listImgFunc(item?.share_post?.medias, item?.share_post, item)}
                </div>
              </div>
            )}
            <div style={{ padding: '0px 16px 0px 16px' }}>
              <MarkDownRead
                isMeta
                charLimit={isMobile ? 90 : 160}
                content={item?.share_post?.content}
              />
            </div>
            <div
              className='d-flex align-items-center mt-2'
              style={{ fontSize: '12px', paddingLeft: '16px' }}>
              <p
                className='cls-title-2nd cls-date'
                style={{ color: '#666565', marginTop: '0px', fontSize: '12px' }}>
                {convertToCurrentTime(
                  item?.share_post?.public_time || item?.share_post?.created_at,
                  'YYYY/MM/DD HH:mm'
                )}
              </p>
              &nbsp;
              {item?.share_post?.unpublic_time && (
                <>
                  <p
                    className='cls-title-2nd cls-date ms-1'
                    style={{
                      color: '#DC143C',
                      marginTop: '0px',
                      border: '1px solid',
                      padding: '0px 5px',
                    }}>
                    <span className='me-1'>{t('Common.publicDay')}</span>
                    {convertToCurrentTime(item?.share_post?.unpublic_time, 'YYYY/MM/DD HH:mm')}
                  </p>
                </>
              )}
              {item?.share_post?.in_24h_flag === 1 && (
                <>
                  <p
                    className='cls-title-2nd cls-date ms-1'
                    style={{
                      color: '#DC143C',
                      marginTop: '0px',
                      border: '1px solid',
                      padding: '0px 5px',
                    }}>
                    <span className='me-1'>{t('Common.publicDay')}</span>
                    {convertToCurrentTime(
                      renderTimeAfter24h(item?.share_post?.public_time),
                      'YYYY/MM/DD HH:mm'
                    )}
                  </p>
                </>
              )}
            </div>
            {(item?.share_post?.medias?.length === 0 || item?.share_post?.share_post) && (
              <div
                className='mt-2 block-img1 2'
                style={{
                  whiteSpace: 'pre-wrap',
                  margin: '0',
                  fontWeight: '500',
                  padding: '0 16px 16px 16px',
                }}>
                {`${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${item?.share_post?.id}`}
              </div>
            )}
          </div>
        </>
      )}

      <div className={`interactive ${checkDisabledAction(item) && 'disabled'}`}>
        <div className='left'>
          <div className={`item ${checkDisabledAction(item) && 'disabled'}`}>
            {item?.is_liked === 1 ? (
              <>
                {/* đã like */}
                <HeartActiveIcon
                  className={`like-icon active`}
                  onClick={() => !checkDisabledAction(item) && handleLikePost(item)}
                  disabled={checkDisabledAction(item)}
                />
              </>
            ) : (
              <>
                {/* chưa like */}
                <HeartIcon
                  className={`like-icon`}
                  onClick={() => !checkDisabledAction(item) && handleLikePost(item)}
                  disabled={checkDisabledAction(item)}
                />
              </>
            )}
            <span className='number'>{item?.number_like}</span>
          </div>
          <div className={`item ${checkDisabledAction(item) && 'disabled'}`}>
            <ChatIcon
              className='chat-icon'
              onClick={() => handleNavigate(item?.type, item?.id)}
            />
            {/* <span className='number'>{item?.number_comment}</span> */}
            <CountComment />
          </div>
          {item?.type === 1 && (
            <div
              className={`item d-middle div-ghim1`}
              style={{ pointerEvents: `${item?.pin_share_enable === 0 && 'none'}` }}>
              {checkDisabledAction(item) ? (
                <RePostIconV2 />
              ) : (
                <>
                  {item?.is_share === 1 ? (
                    <CancelSussRetweetIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setIsVisiblePopupGhim(isVisiblePopupGhim === item?.id ? -1 : item?.id);
                      }}
                      onBlur={() => setIsVisiblePopupGhim(-1)}
                    />
                  ) : (
                    <RePostIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setIsVisiblePopupGhim(isVisiblePopupGhim === item?.id ? -1 : item?.id);
                      }}
                      onBlur={() => setIsVisiblePopupGhim(-1)}
                    />
                  )}
                  {isVisiblePopupGhim === item?.id && (
                    <div className='popup-ghim1'>
                      <div
                        className='item1'
                        onMouseDown={() => {
                          handleRetweet(item, item?.id, {
                            share_type: 1,
                          });
                          setIsVisiblePopupGhim(-1);
                        }}>
                        <CancelRetweetIcon />
                        <span className='text'>
                          {item?.is_share === 0 ? t('Common.repost') : t('Common.Unrepost')}
                        </span>
                      </div>
                      <div
                        className='item1'
                        onMouseDown={() => {
                          setItemPostPopup(item);
                          setIsVisibleQuote(true);
                          setIsVisiblePopupGhim(-1);
                        }}>
                        <QuotedIcon />
                        <span className='text'>{t('Common.QuotePost')}</span>
                      </div>
                    </div>
                  )}
                </>
              )}
              <span className={`number ms-1 ${checkDisabledAction(item) && 'disabled'}`}>
                {item?.number_share ?? 0}
              </span>
            </div>
          )}
          <div className={`item ${checkDisabledAction(item) && 'disabled'}`}>
            <ShareIcon
              className={`share-icon ${checkDisabledAction(item) && 'disabled'}`}
              onClick={() => {
                if (item?.type === 2) {
                  !checkDisabledAction(item) &&
                    handleSharePost({
                      link: `${getEnv('REACT_APP_FAN_URL')}${newPathUser}post-sale/${
                        item?.id
                      }`,
                      title: item.title,
                    });
                } else {
                  !checkDisabledAction(item) &&
                    handleSharePost({
                      link: `${getEnv('REACT_APP_FAN_URL')}${newPathUser}post/${item?.id}`,
                      title: item.title,
                    });
                }
              }}
              disabled={checkDisabledAction(item)}
            />
          </div>
        </div>
        <BookmarkIcon
          onClick={() => {
            dispatch(postBookMark(item));
            // dispatch(
            //   postBookMark(item?.id, ({ data }) =>
            //     setDataGetListBookmark(
            //       dataGetListBookmark?.filter((item) => +item?.id !== +data?.post_id)
            //     )
            //   )
            // );
          }}
        />
      </div>
      <div className='comment-wrap'>
        <CommentPostTimeLineShowAll
          defaultCount={item?.number_comment}
          dataPost={item}
          handleDeleteComment={(e) => {
            setPopupDeleteComment(e);
          }}
          handleLikeComment={handleLikeComment}
          handleFollowUserComment={handleFollowCommentUser}
          setTrickReloadComment={setTrickReloadComment}
          trickReloadComment={trickReloadComment}
          id={item?.id}
          dataProfile={dataUser}
          typeFollow='fav'
          pages='bookmark'
        />
      </div>
      <div className={`comment block`} style={{ marginBottom: '16px' }}>
        <ImgCustom
          isPlaceholderAvatar
          screen='avatar_44_44'
          src={dataUser?.avatar ? `${dataUser?.avatar}` : AvatarDefault}
        />
        <CommentForm
          item={item}
          setDataGetListBookmark={setDataGetListBookmark}
          dataGetListBookmark={dataGetListBookmark}
          setTrickReloadComment={setTrickReloadComment}
        />
      </div>
    </ProviderTimeline>
  );
};
const ListPost = ({ isSearchPage, keyword }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { fanListBookmarkPagination, dataUser, fanListBookmark, isLoadingFollow } =
    useSelector((state) => state.users);

  const {
    loading,
    dataBookmark,
    listBookmarkPagination,
    isLoadingFollowCreate,
    idFavoriteTimline,
    initPageFavor,
  } = useSelector((state) => state.usersNew);
  const [postIdClickBy, setPostIdClickBy] = useState(null);

  const { idPackage, setIsOpen, setIdPackage, PopupPaymentBySub } = usePopupPaymentSub({
    urlRedirect: '',
    onClickSuccess: () => {
      dispatch(postNormalPaymentBuyPackageSuccess(idPackage));
    },
  });

  const [page, setPage] = useState(idFavoriteTimline && initPageFavor ? +initPageFavor : 1);
  const widthScreen = window.innerWidth;
  const itemsPerPage = 10;

  const [popupDeleteComment, setPopupDeleteComment] = useState(null);
  const [isVisiblePopup, setIsVisiblePopup] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);

  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const [dataGetListBookmark, setDataGetListBookmark] = useState([]);
  const [isVisiblePopupGhim, setIsVisiblePopupGhim] = useState(-1);
  const [isVisibleQuote, setIsVisibleQuote] = useState(false);
  const [itemPostPopup, setItemPostPopup] = useState();
  const [trickReloadComment, setTrickReloadComment] = useState(null);
  useFixedPopup(isVisibleQuote);
  const { t } = useTranslation();
  // api list page
  const fetchNext = () => {
    const pages = listBookmarkPagination?.total / listBookmarkPagination?.per_page;
    if (pages > page) {
      setPage(page + 1);
      funCallApi(page + 1);
    }
  };

  useEffect(() => {
    dispatch(resetDetailPost());
  }, []);

  useEffect(() => {
    if (idFavoriteTimline) {
      const idPost = document.getElementById(idFavoriteTimline.id);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idFavoriteTimline]);

  const funCallApi = useCallback(
    (page) => {
      setIsLoading(true);
      dispatch(
        getListBookmark(page, itemsPerPage, ({ data }) => {
          setDataGetListBookmark([...dataGetListBookmark, ...data]);
          setIsLoading(false);
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page]
  );

  useLayoutEffect(() => {
    if (initPageFavor < 1) {
      const top = document.getElementById('app_cocofans_0');
      top && top.scrollIntoView({ top: 0, left: 0, block: 'start' });
      setIsLoading(true);
      dispatch(
        getListBookmark(page, itemsPerPage, ({ data }) => {
          setDataGetListBookmark([...dataGetListBookmark, ...data]);
          setIsLoading(false);
        })
      );
    }
  }, [page]);

  const resetDataWhenBySuccess = useCallback(() => {
    const newListPostTimeLine = dataGetListBookmark?.map((item) => {
      if (item?.share_type === 1 || (item?.share_type === 2 && item?.medias?.length === 0)) {
        if (+item?.share_post?.packages[0]?.id === +idPackage) {
          const newItem = { ...item };
          if (newItem.share_post.is_media_display === 1) {
            newItem.share_post.is_media_display = 0;
          } else {
            newItem.share_post.is_media_display = 1;
          }
          return newItem;
        } else return item;
      } else {
        if (+item?.packages[0]?.id === +idPackage) {
          const newItem = { ...item };
          if (newItem.is_media_display === 1) {
            newItem.is_media_display = 0;
          } else {
            newItem.is_media_display = 1;
          }
          return newItem;
        } else return item;
      }
    });
    setDataGetListBookmark(newListPostTimeLine);
  }, [idPackage, dataGetListBookmark]);

  const resetDataWhenBySuccessByZero = useCallback(() => {
    const newListPostTimeLine = dataGetListBookmark?.map((item) => {
      if (item?.share_type === 1 || (item?.share_type === 2 && item?.medias?.length === 0)) {
        if (+item?.share_post?.packages[0]?.id === +idPackage) {
          const newItem = { ...item };
          if (newItem.share_post.is_media_display === 1) {
            newItem.share_post.is_media_display = 0;
          } else {
            newItem.share_post.is_media_display = 1;
          }
          return newItem;
        } else return item;
      } else {
        if (+item?.packages[0]?.id === +idPackage) {
          const newItem = { ...item };
          if (newItem.is_media_display === 1) {
            newItem.is_media_display = 0;
          } else {
            newItem.is_media_display = 1;
          }
          return newItem;
        } else return item;
      }
    });
    setDataGetListBookmark(newListPostTimeLine);
  }, [idPackage, dataGetListBookmark]);

  const handleNavigate = (type, post_id) => {
    dispatch(getIDFavor({ id: post_id, page: page, tab: 1 }));
    if (type === 2) {
      navigate(`${newPathUser}post-sale/${post_id}`);
    } else {
      navigate(`${newPathUser}post/${post_id}`);
    }
  };

  const handleFollowCommentUser = (id, onRequest = () => {}, onFinally = () => {}) => {
    dispatch(
      followOrUnFollowUser(id.id, undefined, (onRequest = () => {}), (onFinally = () => {}))
    );
  };

  const handleLikeComment = (id, cb = () => {}) => {
    cb();
    dispatch(likeCommentDetail(id.commentId));
    setDataGetListBookmark(
      dataGetListBookmark.map((item) => {
        if (+item?.latest_comment?.id === +id) {
          const newItem = { ...item };
          if (newItem.latest_comment.is_liked === 1) {
            newItem.latest_comment.is_liked = 0;
            --newItem.latest_comment.number_like;
          } else {
            newItem.latest_comment.is_liked = 1;
            ++newItem.latest_comment.number_like;
          }
          return newItem;
        } else return item;
      })
    );
  };

  const handleLikePost = (post) => {
    dispatch(updatePostLikeDetail(post));
    //dispatch(likePost(post?.id));
    //handleLikePostSuccess(post);
  };

  const handleDeleteComment = () => {
    const { id, type, idParent, cb = () => {}, postId } = popupDeleteComment;
    dispatch(
      deleteCommentDetail(id, postId, (data) => {
        cb();
        setDataGetListBookmark(
          dataGetListBookmark.map((item) => {
            if (+item?.latest_comment?.id === +id) {
              const newItem = { ...item };
              newItem.latest_comment = null;
              newItem.number_comment = newItem.number_comment - data?.data?.count;

              return newItem;
            } else return item;
          })
        );
      })
    );
    setPopupDeleteComment(false);
  };

  const checkDisabledAction = (item) => {
    if (
      (item?.is_followed === 0 && item?.follow_flag === 1 && item?.user_id !== dataUser?.id) ||
      (!item?.is_media_display && item?.type === 1 && item?.user_id !== dataUser?.id)
    ) {
      return true;
    }
    return false;
  };

  const handleSharePost = (data) => {
    setPopupCopyLink(data);
  };

  const [valueComments, setValueComments] = useState(() =>
    Array.from({ length: 10000 }, () => '')
  );

  const [checkSendChat, setCheckSendChat] = useState(null);

  const handleRetweet = (item, id, data) => {
    dispatch(postRetweet(id, data));
    const isShare = item?.is_share ? 0 : 1;
    const numberShare = isShare ? item?.number_share + 1 : item?.number_share - 1;
    setDataGetListBookmark(
      dataGetListBookmark.map((item) => {
        if (+item.id === +id) {
          return {
            ...item,
            number_share: numberShare,
            is_share: isShare,
          };
        }
        return item;
      })
    );
  };

  const listImgFunc = (data, item, itemParent) => {
    if (item?.type === 2) {
      const itemAfter = item?.medias?.filter((media) => media.type === 4);

      return <TimelinePost data={itemAfter} item={item} page={page} itemParent={itemParent} />;
    } else {
      return <TimelinePost data={data} item={item} page={page} itemParent={itemParent} />;
    }
  };

  const [refOutSideThree] = useClickOutSize(() => {
    setIsVisiblePopup(-1);
  });

  return (
    <>
      {popupDeleteComment && (
        <PopupConfirmFan
          isVisiblePopup={() => setPopupDeleteComment(false)}
          colorSvg='#FF9F43'
          text={t('Popup.confirmDeleteComment')}
          className='popup-delete'>
          <div className='ok btn' onClick={handleDeleteComment}>
            {t('Common.buttonDelete')}
          </div>
          <div className='btn cancel' onClick={() => setPopupDeleteComment(false)}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}
      {isVisibleQuote && (
        <QuotePostMyPage
          closeModal={() => setIsVisibleQuote(false)}
          isVisibleQuote={isVisibleQuote}
          item={itemPostPopup}
        />
      )}
      {popupCopyLink && (
        <PopupSharePost
          isVisiblePopup={() => setPopupCopyLink(false)}
          data={popupCopyLink || {}}
        />
      )}
      <LayoutCreatorRight
        noButtonBack
        titlePage={t('Favorite.title')}
        noHeader={isSearchPage}
        className={`${
          isSearchPage ? 'search-list-post' : ''
        } like-list-bookmark-layout favorite-list-post`}>
        <PopupPaymentBySub />
        <div className={`fan-favorite-wrapper`}>
          <ul className='header header-bookmarks'>
            <li className='active'>{t('Favorite.bookmarkList')}</li>
            <li
              onClick={() => {
                dispatch(resetIDLike());
                navigate(`${newPathUser}like-list`);
              }}
              className={`${isLoading && !dataBookmark.length && 'disable-like'}`}>
              {t('Favorite.likeList')}
            </li>
          </ul>

          <div className='bookmark-navigation-checkbox'>
            <div className='navigate-label'>
              {/* <span className='first-label'>{t('Favorite.tabLeft')}</span>
              <span className='active-label'>{t('Favorite.tabRight')}</span> */}
            </div>
            <div className='checkbox-container'>
              <span className='checkbox-label'>{t('Common.changeDisplay')}</span>

              <ToggleButton
                disabled={isLoading && !dataBookmark.length}
                id='navigation-checkbox'
                defaultChecked={false}
                onChange={() => {
                  dispatch(resetIDFavorite());
                  navigate(`${newPathUser}favorite/view-on-timeline`);
                }}
              />
            </div>
          </div>
          {isLoading && !dataBookmark.length ? (
            <div className='shimmer-loading'>
              <ShimmerPostDetails hasImage imageType='circular' title />
            </div>
          ) : (
            <>
              {isLoadingFollowCreate && <PopupLoading className={'popup-loading'} />}
              {dataBookmark?.length > 0 ? (
                <div
                  className={`fan-list-post my-page scroll-bar-custom  ${
                    dataBookmark.length === 0 && 'list-data-null'
                  }`}>
                  <InfiniteLoad loading={loading} data={dataBookmark} fetchNext={fetchNext}>
                    {dataBookmark?.length > 0 &&
                      dataBookmark.map((item, index) => (
                        <div
                          key={index}
                          id={item?.id}
                          className={`my-page-item ${
                            !item?.unlock_package && item?.type === 1 && 'popup_lock_post'
                          }`}>
                          {/* {renderPostItem(item, index)} */}
                          <RenderPostItem 
                           {...({
                            item,
                            index,
                            refOutSideThree,
                            setIsVisiblePopup,
                            isVisiblePopup,
                            dataUser,
                            listImgFunc,
                            setIsOpen,
                            setIdPackage,
                            checkDisabledAction,
                            handleLikePost,
                            handleNavigate,
                            setIsVisiblePopupGhim,
                            isVisiblePopupGhim,
                            handleRetweet,
                            widthScreen,
                            setPopupDeleteComment,
                            handleLikeComment,
                            handleFollowCommentUser,
                            setTrickReloadComment,
                            trickReloadComment,
                            handleSharePost,
                            setDataGetListBookmark,
                            dataGetListBookmark,
                            setItemPostPopup,
                            setIsVisibleQuote,
                            page,
                            resetDataWhenBySuccessByZero
                           })}
                          />
                        </div>
                      ))}
                  </InfiniteLoad>
                </div>
              ) : (
                <span className='no-data'>
                  <NoBookmarkList />
                  {t('Favorite.listNull')}
                </span>
              )}
            </>
          )}
        </div>
      </LayoutCreatorRight>
    </>
  );
};

export default ListPost;
