import { LockIcon } from 'images';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { postNormalBuyPackage } from 'store/actions/usersNew';
import { formatCoin, isAdmin, isAgency } from 'utils/utils';
import { PopupConfirmFan, PopupLoading } from '..';
import './index.scss';
import { newPathUser } from 'constants/layout/constant';
import Storage from 'utils/storage';
import PopupConfirmError from './confirmErrrorBuy';
import { logUserProfile } from 'store/actions/myPage';
import { STORAGE } from 'utils';
import Blur_img from 'images/Blur_Right.png';

const PopupLockPostNormal = ({
  detailPost,
  in_my_page = false,
  post_id,
  point,
  is_des = false,
  is_3_column = false,
  one_column = false,
  isSearchPage = false,
  isFavoriteListPost = false,
  handleByPackageSuccess,
  setIsOpen,
  setIdPackage,
  setIdPostBySuccess,
  idDetail,
  resetDataWhenBySuccessByZero,
  is_class = false
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loadingBuyPost } = useSelector((state) => state.sellPost);
  const { dataUser } = useSelector((state) => state.users);
  const [packageItem, setPackageItem] = useState();
  const [isCannotBuy, setIsCannotBuy] = useState(false);
  const [isVisibleConfirm, setIsVisibleConfirm] = useState(false);
  const [successSellPackagePopup, setSuccessSellPackagePopup] = useState(false);
  const [notEnoughPointPopup, setNotEnoughPointPopup] = useState(false);
  const [failSellPackagePopup, setFailSellPackagePopup] = useState(false);
  const minPricePackage = detailPost?.packages.sort((a, b) => a.price - b.price)[0];
  const [checkPostId, setCheckPostId] = useState();
  const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  const { t } = useTranslation();
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  const handleBySub = () => {
    if (minPricePackage.price === 0) {
      setIdPackage && setIdPackage(null);
      setIsOpen && setIsOpen(false);
      dispatch(
        postNormalBuyPackage(
          packageItem?.id,
          () => {
            resetDataWhenBySuccessByZero && resetDataWhenBySuccessByZero(packageItem?.id)
            if (handleByPackageSuccess) {
              handleByPackageSuccess(idDetail || post_id);
            } else {
              setSuccessSellPackagePopup(true);
            }
          },
          (error) => {
            if (error.messageId === 'YOU_BOUGHT_PACKAGE_OF_THIS_USER') {
              setIsCannotBuy(true);
            }
            error.messageId === 'TOO_SOME_SUBSCRIBERS' && setFailSellPackagePopup(true);
          }
        ),
        packageItem
      );
    } else {
      setIdPostBySuccess && setIdPostBySuccess(detailPost?.id)
      setIsOpen && setIsOpen(true);
    }
  };
  return (
    <>
      {loadingBuyPost && checkPostId === post_id && (
        <PopupLoading className={'popup-loading'} />
      )}

      {isCannotBuy && (
        <PopupConfirmError
          title={t('ValidateMsg.YOU_BOUGHT_PACKAGE_OF_THIS_USER')}
          isWarning={true}
          closeModal={() => {
            setIsCannotBuy(false);
          }}
        />
      )}

      {successSellPackagePopup && checkPostId === post_id && (
        <PopupConfirmFan
          isVisiblePopup={false}
          colorSvg='#28C76F'
          iconNote={false}
          text={t('Popup.messageSubscriptionSuccess')}
          className={`${in_my_page ? 'in_my_page' : ''}`}
          lockScroll={!in_my_page}>
          <div
            className='ok btn'
            onClick={() => {
              setSuccessSellPackagePopup(false);
            }}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}

      {failSellPackagePopup && checkPostId === post_id && (
        <PopupConfirmFan
          isVisiblePopup={false}
          iconNote={true}
          colorSvg='#FF9F43'
          text={t('ValidateMsg.TOO_SOME_SUBSCRIBERS')}
          className={`${in_my_page ? 'in_my_page' : ''}`}
          lockScroll={!in_my_page}>
          <div
            className='btn ok'
            onClick={(e) => {
              e.stopPropagation();
              setFailSellPackagePopup(false);
            }}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}

      {notEnoughPointPopup && checkPostId === post_id && (
        <PopupConfirmFan
          isVisiblePopup={false}
          iconNote={true}
          colorSvg='#FF9F43'
          text={t('Popup.NotEnoughPoints')}
          className={`${in_my_page ? 'in_my_page' : ''}`}
          lockScroll={!in_my_page}>
          <div
            className='btn cancel'
            onClick={(e) => {
              e.stopPropagation();
              setNotEnoughPointPopup(false);
            }}>
            {t('Common.buttonCancel')}
          </div>
          <div className='ok btn' onClick={() => navigate(`${newPathUser}purchase`)}>
            {t('Common.Ok')}
          </div>
        </PopupConfirmFan>
      )}

      {isVisibleConfirm && checkPostId === post_id && (
        <PopupConfirmFan
          isVisiblePopup={false}
          iconNote={true}
          colorSvg='#FF9F43'
          text={t('Popup.subscribeJoin')}
          className={`${in_my_page ? 'in_my_page' : ''}`}
          lockScroll={!in_my_page}
          // content={
          //   <div
          //     className='content-text'
          //     // style={{
          //     //   lineHeight: '24px',
          //     //   fontWeight: 400,
          //     //   fontSize: 16,
          //     //   margin: '10px 0 23px auto',
          //     //   width: '100%',
          //     //   marginLeft: '38px',
          //     // }}
          //   >
          //     {t('Popup.yourPoint')}{' '}
          //     <span style={{ fontWeight: 700, color: '#ff0000', whiteSpace: 'nowrap' }}>
          //       {formatCoin(dataUser?.points ?? point) || 0} {t('Common.pt')}
          //     </span>
          //   </div>
          // }
        >
          <div
            className='ok btn'
            onClick={(e) => {
              e.stopPropagation();
              setIsVisibleConfirm(false);
              handleBySub();
            }}>
            {t('Common.Ok')}
          </div>
          <div
            className='btn cancel'
            onClick={(e) => {
              e.stopPropagation();
              setIsVisibleConfirm(false);
            }}>
            {t('Common.buttonCancel')}
          </div>
        </PopupConfirmFan>
      )}
      {!detailPost?.unlock_package && detailPost?.type === 1 && !isAdmin && (
        <div style={{backgroundImage: `${isFirefox ? `url(${Blur_img})` : 'transparent'}`}} className={`lock-content-common ${is_class && 'lock-content-common-new'} ${in_my_page && 'in_my_page'}`}>
          <div
            className={`lock-content-inner ${one_column && 'mobile_one_colmn'} ${
              is_3_column && 'mobile_3_column'
            }`}
            style={isSearchPage ? {} : { padding: `${is_des ? '10px 10px' : '24px 32px'}` }}>
            <LockIcon />
            {detailPost?.packages &&
              detailPost?.packages.map(
                (item, index) =>
                  index < 1 && (
                    <>
                      <div className='_list-package'>
                        <div className='package-item text-center' key={index}>
                          <div className={`${is_des ? '_packageNew' : '_package'}`}>
                            {item?.title}
                          </div>
                          <div
                            className='bold _price'
                            style={{ marginBottom: `${is_des ? '0px' : '16px'}` }}>
                            {t('Common.oneMonth')}/ {formatCoin(item?.price)}
                            {t('Common.pt')}
                          </div>
                          {!is_des && (
                            <div
                              className={`${
                                (one_column || isFavoriteListPost) && 'hidden_des'
                              } _package display-linebreak package-description`}>
                              {item?.description}
                            </div>
                          )}
                        </div>
                      </div>
                      <button
                        disabled={isAgency}
                        className='btn btn-lg w-100 btn-membership'
                        onClick={(e) => {
                            if (!isAuth) {
                              e.stopPropagation();
                              dispatch(logUserProfile(detailPost));
                            } else {
                              e.stopPropagation();
                              setIdPackage && setIdPackage(item?.id);
                              setIsVisibleConfirm(true);
                              setPackageItem(item);
                              setCheckPostId(detailPost.id);
                            }
                          // Storage.set('ID_HIDDEN_LOCK_POST', detailPost.id);
                        }}>
                        {t('Common.membership')}
                      </button>
                    </>
                  )
              )}
          </div>
        </div>
      )}
    </>
  );
};

export default PopupLockPostNormal;
