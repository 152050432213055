/* eslint-disable import/no-anonymous-default-export */

import { TYPES } from '../actions';
const initialState = {
  status: null,
  loading: false,
  error: null,
  data: null,
  dataDetail: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.CREATOR_GET_DETAIL_PREMIUM_TICKET_REQUEST:
    case TYPES.CREATOR_POST_PREMIUM_TICKET_REQUEST:
    case TYPES.CREATOR_EDIT_PREMIUM_TICKET_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.CREATOR_GET_DETAIL_PREMIUM_TICKET_SUCCESS:
      return {
        ...state,
        dataDetail: action.data.data.data,
        loading: false,
      };
    case TYPES.CREATOR_POST_PREMIUM_TICKET_SUCCESS:
      return {
        ...state,
        dataDetail: action.data.data,
        loading: false,
      };
    case TYPES.CREATOR_EDIT_PREMIUM_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case TYPES.CREATOR_POST_PREMIUM_TICKET_FAILED:
    case TYPES.CREATOR_EDIT_PREMIUM_TICKET_FAILED:
    case TYPES.CREATOR_GET_DETAIL_PREMIUM_TICKET_FAILED:
      return {
        ...state,
        status: false,
        error: action.data.error,
        loading: false,
      };

    default:
      return state;
  }
};
