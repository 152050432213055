import { LayoutCenter } from 'components';
import { useTranslation } from 'react-i18next';
import { TERMS, TERMS_JP } from '../constant';
import './styles.scss';
import { Link } from 'react-router-dom';
import { getEnv } from 'configs/env';

const TermScreen = () => {
  const { i18n } = useTranslation();
  return (
    <>
      {i18n.language === 'en' ? (
        <LayoutCenter
          titlePage={'Sharegram Terms of Use'}
          notLogin={true}
          isShowLeftIcon={false}
          className={'title-page-creator page-legal'}
          uiMobile={true}>
          <div className={`container-fluid w-100 bg-white term-page creator`}>
            {/* <div className='title-page'>{TERMS?.title_page || ''}</div> */}
            <div>
              These Terms of Use (hereinafter referred to as "these Terms") set forth the
              conditions for the provision of this service and the rights and obligations
              between DRAGON BLADE INC (hereinafter referred to as "our company") and you, the
              user. In order to use this service, you need to read the entire text of these
              Terms and agree to these Terms.
            </div>
            <div>
              <div>
                <div className='title-chapter'>Article 1 (Application)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. These Terms aim to define the conditions for the provision of this
                    service and the rights and obligations between our company and the user
                    regarding the use of this service, and apply to all relationships related
                    to the use of this service between the user and our company.
                  </div>
                  <div className={`content`}>
                    2. The rules related to the use of this service that our company posts on
                    this website {'('}
                    <Link to='/terms-of-use' target='_blank' className='link_route'>
                      {getEnv('REACT_APP_FAN_URL')}/terms-of-use
                    </Link>
                    {')'} shall constitute part of these Terms.
                  </div>
                  <div className={`content`}>
                    3. In the event of a discrepancy between the contents of these Terms and
                    the rules in the previous paragraph or any other descriptions of this
                    service outside of these Terms, the provisions of these Terms shall take
                    precedence.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 2 (Definitions)</div>
                <div className='text'>
                  In these Terms, the following terms shall have the meanings set forth below:
                </div>
                <div>
                  <div className={`content`}>
                    (1) "The Service" refers to the creator and fan interaction support related
                    service named "Sharegram" provided by our company (including the service
                    after any changes to the name or content of the service, regardless of the
                    reason).
                  </div>
                  <div className={`content`}>
                    (2) "The Service Use Contract" refers to the contract for the use of the
                    Service concluded between our company and the user, with these Terms as the
                    contract conditions.
                  </div>
                  <div className={`content`}>
                    (3) "Intellectual Property Rights" refers to copyrights, patent rights,
                    utility model rights, design rights, trademark rights, and other
                    intellectual property rights (including the rights to acquire them and the
                    rights to apply for registration, etc.).
                  </div>
                  <div className={`content`}>
                    (4) "Posted Data" refers to the content (including but not limited to text,
                    images, videos, and other data) that users post or otherwise transmit using
                    the Service.
                  </div>

                  <div className={`content`}>
                    (5) "This Website" refers to the website operated by our company, the
                    domain of which is " ", (including the website after any changes to the
                    domain or content of our company's website, regardless of the reason).
                  </div>
                  <div className={`content`}>
                    (6) "User" refers to an individual who has registered as a user of the
                    Service under Article 3 (Registration). Users consist of creators and
                    general users.
                  </div>
                  <div className={`content`}>
                    (7) "Creator" refers to those among individuals who have registered as
                    users of the Service under Article 3 (Registration), who provide
                    distribution content through the Service.
                  </div>
                  <div className={`content`}>
                    (8) "General User" refers to all users, excluding creators.
                  </div>
                  <div className={`content`}>
                    (9) "Distribution Content" refers to all content such as videos, audio
                    (music and other sounds, etc.), images, photos, language (comments,
                    scripts, etc.), trade names, trademarks, service marks, logos, interactive
                    features, software, indicators, materials, etc., that are distributed by
                    creators through the Service.
                  </div>
                  <div className={`content`}>
                    (10) "Fan Club" refers to the interaction site between creators and general
                    users that creators open through the Service.
                  </div>
                  <div className={`content`}>
                    (11) "Points" refers to the payment method provided by our company, used as
                    consideration for the viewing or use of distribution content by general
                    users.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 3 (User Registration)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Those who wish to use the Service (hereinafter referred to as
                    "Registration Applicants") can apply for registration to use the Service by
                    agreeing to comply with these Terms, and providing certain information such
                    as identity and age (hereinafter referred to as "Registration Items") to
                    our company in the manner specified by our company. Please note that a
                    photo ID issued by a public institution is required when applying for
                    registration.
                  </div>
                  <div className={`content`}>
                    2. Our company will judge whether or not to register the Registration
                    Applicant who has applied for registration based on Article 1 according to
                    our company's standards, and if our company approves the registration, we
                    will notify the Registration Applicant to that effect. The registration of
                    the Registration Applicant as a user is considered complete when our
                    company has made this notification in writing (including email).
                  </div>
                  <div className={`content`}>
                    3. Upon completion of the registration set forth in the preceding
                    paragraph, the Service Use Contract is established between the user and our
                    company, and the user can use the Service in accordance with these Terms.
                  </div>
                  <div className={`content`}>
                    4. Our company may refuse registration and re-registration if the
                    Registration Applicant falls under any of the following items, and we are
                    not obligated to disclose any reasons.
                    <div className={`sub-content`}>
                      (1) If there is any false information, errors, or omissions in all or
                      part of the Registration Items provided to our company
                    </div>
                    <div className={`sub-content`}>
                      (2) If the person is a minor, an adult ward, a person under curatorship,
                      or a person under assistance, and has not obtained the consent of a legal
                      representative, guardian, curator, or assistant
                    </div>
                    <div className={`sub-content`}>
                      (3) If the person is an antisocial force (meaning a gang, gang member,
                      right-wing group, antisocial force, or anyone similar. The same shall
                      apply hereinafter.), or if our company determines that the person has any
                      interaction or involvement with antisocial forces, such as through
                      funding or other means to maintain, operate, or manage antisocial forces
                    </div>
                    <div className={`sub-content`}>
                      (4) If our company determines that the person has violated a contract
                      with our company in the past or is related to such a person
                    </div>
                    <div className={`sub-content`}>
                      (5) If the person has been subject to the measures set forth in Article
                      21
                    </div>
                    <div className={`sub-content`}>
                      (6) If our company determines that registration is not appropriate for
                      any other reason
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 4 (Change of Registration Items)</div>
                <div className='text'>
                  If there are any changes to the Registration Items, the user shall promptly
                  notify our company of such changes in the manner specified by our company.
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 5 (Password and User ID Management)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Users shall appropriately manage and store their passwords and user IDs
                    for the Service at their own responsibility, and shall not allow third
                    parties to use them, nor lend, transfer, change the name of, buy or sell
                    them.
                  </div>
                  <div className={`content`}>
                    2. Users shall be responsible for any damages caused by inadequate
                    management of passwords or user IDs, errors in use, or use by third
                    parties.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 6 (Functions of the Service)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Our company provides creators with all or part of the following
                    functions in the Service:
                    <div className={`sub-content`}>(1) Fan club site opening function</div>
                    <div className={`sub-content`}>
                      (2) Distribution content distribution function
                    </div>
                    <div className={`sub-content`}>
                      (3) Other functions that our company deems useful in the Service
                    </div>
                  </div>
                  <div className={`content`}>
                    2. Our company provides general users with all or part of the following
                    functions in the Service:
                    <div className={`sub-content`}>
                      (1) Distribution content viewing function
                    </div>
                    <div className={`sub-content`}>
                      (2) Function to post questions, comments, likes, and other posted data on
                      the Service
                    </div>
                    <div className={`sub-content`}>
                      (3) Other functions that our company deems useful in the Service
                    </div>
                  </div>
                  <div className={`content`}>
                    3. Our company may add or change the functions of the Service, and unless
                    it has a significant impact on users, we will not give prior notice.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 7 (Fan Club Use Contract)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. When a general user applies for membership in a fan club on this
                    website, and the creator notifies the general user of their acceptance, a
                    Fan Club Use Contract is established between the creator and the general
                    user.
                  </div>
                  <div className={`content`}>
                    2. By entering into a Fan Club Use Contract, the general user agrees to the
                    obligation to pay the fan club membership fee set for each fan club.
                  </div>
                  <div className={`content`}>
                    3. The fee structure and payment method for each fan club shall be
                    separately defined on this website.
                  </div>
                  <div className={`content`}>
                    4. Creators may change all or part of the content or fee structure of the
                    fan club, or terminate all or part of the fan club, in accordance with the
                    method separately defined by our company.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 8 (Period of Fan Club Use Contract, etc.)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. The period of the Fan Club Use Contract is until the same day of the
                    following month from the day the general user joined the fan club, and
                    thereafter it is an automatic renewal contract on a monthly basis. However,
                    if there is no calendar day corresponding to the joining date in a month,
                    the contract period will be until the day before the end of the month. (For
                    example, if you apply on August 31, the contract end date is September 29).
                    If a member of the fan club (hereinafter referred to as a "Fan Club
                    Member") applies for withdrawal, the Fan Club Use Contract will end on the
                    day of the withdrawal application.
                  </div>
                  <div className={`content`}>
                    2. Even if you withdraw from the fan club during the period of the Fan Club
                    Use Contract, the fan club membership fee will not be calculated on a daily
                    basis, and the Fan Club Member cannot request a refund of the current
                    month's fan club membership fee.
                  </div>
                  <div className={`content`}>
                    3. If the payment of the fan club membership fee is delayed (including
                    cases where the payment procedure was not made due to a lack of points,
                    etc.), the Fan Club Member will be deemed to have automatically withdrawn
                    from the fan club.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 9 (Termination of Fan Club Use Contract, etc. by Our Company)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. If our company cancels the user registration of a creator or a general
                    user, all contracts between the creator and the Fan Club Member or between
                    the general user and each registered creator will end at that point.
                  </div>
                  <div className={`content`}>
                    2. Even in the case of the preceding paragraph, the fan club membership fee
                    will not be calculated on a daily basis, and the Fan Club Member cannot
                    request a refund of the current month's fan club membership fee.
                  </div>
                  <div className={`content`}>
                    3. Even in the case of termination of the Fan Club Use Contract under this
                    Article, our company is exempted as long as it is a measure based on these
                    Terms, and in this case, the user cannot make any claim for damages against
                    our company.
                  </div>
                  <div className={`content`}>
                    4. If our company cancels the user registration of a specific creator, our
                    company may delete all fan club sites (including distribution content)
                    opened by the creator. Even in this case, our company is exempted as long
                    as it is a measure based on these Terms, and the creator cannot make any
                    claim for damages against our company.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 10 (Individual Content Distribution Contract)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. In addition to the members-only distribution content in the Fan Club Use
                    Contract, creators can set prices, distribution dates and times, and other
                    conditions on the fan club site by a separately defined method, and
                    distribute content (hereinafter referred to as "Individual Content
                    Distribution").
                  </div>
                  <div className={`content`}>
                    2. Registered users can apply to view or use Individual Content
                    Distribution by a method separately defined by our company, and by doing
                    so, an Individual Content Distribution Contract is established between the
                    user and the creator.
                  </div>
                  <div className={`content`}>
                    3. Creators may change all or part of the content or fee structure of
                    Individual Content Distribution, or terminate all or part of the
                    distribution of the content, in accordance with the method separately
                    defined by our company.
                  </div>
                  <div className={`content`}>
                    4. The provisions of Article 9 shall apply mutatis mutandis to the
                    termination of the Individual Content Distribution Contract by our
                    company's judgment.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 11 (Purchase and Handling of Points)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. General users can purchase points by credit card payment, bank account
                    transfer, or other methods specified by our company as a payment method for
                    the service fee, and use them in the Service at a rate of 1 point = 1 yen
                    equivalent. Other details and usage conditions of points shall be
                    separately defined by our company and shall be in accordance with what is
                    displayed in the Service.
                  </div>
                  <div className={`content`}>
                    2. The upper limit of points that a general user can hold is 100,000
                    points.
                  </div>
                  <div className={`content`}>
                    3. General users can use the points they receive for a period of 180 days
                    from the date of issue. Users can use the general free points only during
                    the 180 days from the date of acquisition or the number of days specified
                    by our company before the grant. Unused points that have passed the
                    expiration date will be lost and cannot be used thereafter.
                  </div>
                  <div className={`content`}>
                    4. Our company will not refund or exchange points, regardless of the
                    reason. However, this does not apply if it is necessary under the law. In
                    this case, the method of refunding points shall be determined by our
                    company in accordance with the law and shall be in accordance with what is
                    separately notified.
                  </div>
                  <div className={`content`}>
                    5. Our company will notify the general user of the points acquired by the
                    general user, the points used by the general user, and the balance of
                    points (hereinafter referred to as "Point Amount") by a method separately
                    defined by our company. If a general user has any doubts about the Point
                    Amount, they must immediately contact our company and explain the content.
                    The final decision on the Point Amount shall be made by our company, and
                    the general user shall follow it.
                  </div>
                  <div className={`content`}>
                    6. The use of points shall be done by the general user themselves, and
                    cannot be done by a third party other than the general user themselves.
                    Even in the case of unauthorized use by a third party, our company is not
                    obligated to return the used points and is not responsible for any damages
                    incurred by the general user.
                  </div>
                  <div className={`content`}>
                    7. If taxes or incidental expenses are incurred in connection with the
                    acquisition or use of points, the general user shall bear these costs.
                  </div>
                  <div className={`content`}>
                    8. Regardless of the reason, if a general user withdraws or the user
                    registration is canceled, all points held by the general user will be
                    invalidated and cannot be used thereafter.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 12 (Campaigns)</div>
                <div className='text'>
                  Our company may grant points free of charge to general users who participate
                  in various campaigns in the Service or perform other actions separately
                  designated by our company.
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 13 (Payment and Payment Application)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Creators shall grant our company the authority to receive fan club
                    membership fees and individual content distribution contract usage fees
                    (hereinafter referred to as "Membership Fees, etc.") from general users on
                    their behalf, and the authority to redelegate such receipt operations to a
                    third party designated by our company (hereinafter, together with our
                    company, referred to as "Agent Recipients"). When the Agent Recipient
                    receives the Membership Fees, etc. from the general user, the payment
                    obligation of the Membership Fees, etc. that the general user owes under
                    this agreement shall be extinguished.
                  </div>
                  <div className={`content`}>
                    2. General users shall not pay the Membership Fees, etc. directly to the
                    creator. General users shall not be able to refuse a claim from our company
                    by paying the Membership Fees, etc. directly to the creator.
                  </div>
                  <div className={`content`}>
                    3. Our company shall pay the creator by transferring the amount to the
                    account designated by the creator, deducting the transfer fee, if the
                    Membership Fees, etc. paid by the general user to the creator during the
                    calculation period separately defined by our company reach the amount
                    separately defined by our company. The creator agrees that our company will
                    send the above amount in dollars and that the creator will bear the
                    exchange fee.
                  </div>
                  <div className={`content`}>
                    4. If a creator cancels their user registration themselves or if their
                    registration is canceled under the provisions of Article 9, our company may
                    consider that the creator has waived all Membership Fees, etc. that can be
                    claimed from the creator.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 14 (Fees for using this service)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Creators shall pay to the Company a usage fee separately determined by
                    the Company as compensation for using the Service.
                  </div>
                  <div className={`content`}>
                    2. The Company may deduct the usage fee set forth in the preceding
                    paragraph from the membership fees, etc. paid to creators pursuant to the
                    provisions of Paragraph 3 of the preceding article, in lieu of receiving
                    the usage fee.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 15 (User's Responsibility)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. If a user defames someone else's reputation, infringes on privacy
                    rights, discloses a third party's personal information without permission,
                    commits an act that violates copyright law, or otherwise infringes on the
                    rights of a third party, the user must resolve this at their own
                    responsibility and expense, and our company assumes no responsibility
                    whatsoever.
                  </div>
                  <div className={`content`}>
                    2. Our company assumes no responsibility for the storage, preservation,
                    backup, etc. of data accumulated and stored in the Service by the user
                    using the Service, except in cases attributable to our company's fault.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 16 (Performers of distributed content)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. If a third party other than the creator (hereinafter referred to as a
                    "performer") appears in the distributed content, the creator shall
                    separately notify the Company of the content before the distributed content
                    is distributed through this service. In accordance with the method
                    specified by the Company, the performer's attribute information such as
                    name and age, an ID card with a face photo of the performer issued by a
                    public institution, and the ID card with a face photo and the performer's
                    face are arranged side by side. Submit a consent form, etc. (hereinafter
                    referred to as "performer information, etc.") for appearing in, publicly
                    distributing, downloading, etc. distributed content that should be obtained
                    in accordance with the requirements of the law applicable to the employee,
                    the creator, and the performer. shall be done.
                  </div>
                  <div className={`content`}>
                    2. Creators shall obtain prior consent from performers for their performer
                    information to be provided to the Company.
                  </div>
                  <div className={`content`}>
                    3. If requested by the Company, creators shall submit information or
                    materials requested by the Company in addition to performer information
                    without delay.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 17 (Our Authority and Responsibility)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. We may confirm whether the content to be distributed through the Service
                    by the creator is legal and does not violate these Terms before it is
                    distributed, with the consent of the creator not being required, and the
                    creator agrees to this.
                  </div>
                  <div className={`content`}>
                    2. We will monitor in real time whether the content of the live broadcast
                    by the creator is legal and does not violate these Terms.
                  </div>
                  <div className={`content`}>
                    3. If we can determine that the content distributed in the previous two
                    paragraphs is illegal or violates these Terms, we may take the measures set
                    forth in Article 20, and the creator agrees to such measures.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 18 (Prohibited Actions by Users)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Users must not engage in any of the following actions or actions that we
                    determine to fall under any of the following when using the Service:
                    <div className={`sub-content`}>
                      (1) Actions that violate laws or are related to criminal activities
                    </div>
                    <div className={`sub-content`}>
                      (2) Fraudulent or threatening actions against us, other users of the
                      Service, or any other third party
                    </div>
                    <div className={`sub-content`}>
                      (3) Posting of content that includes minors
                    </div>
                    <div className={`sub-content`}>
                      (4) Actions against public order and morals
                    </div>
                    <div className={`sub-content`}>
                      (5) Actions that infringe on the intellectual property rights, portrait
                      rights, privacy rights, honor, or other rights or interests of us, other
                      users of the Service, or any other third party
                    </div>
                    <div className={`sub-content`}>
                      (6) Sending information to us or other users of the Service through the
                      Service that falls under or is determined by us to fall under any of the
                      following:
                      <div className={`sub-content`}>
                        ・ Information containing excessively violent or cruel expressions
                      </div>
                      <div className={`sub-content`}>
                        ・ Information containing computer viruses or other harmful computer
                        programs
                      </div>
                      <div className={`sub-content`}>
                        ・ Information containing expressions that defame the honor or credit
                        of us, other users of the Service, or any other third party
                      </div>
                      <div className={`sub-content`}>
                        ・ Information containing expressions that promote discrimination
                      </div>
                      <div className={`sub-content`}>
                        ・ Information containing expressions that encourage suicide or
                        self-harm
                      </div>
                      <div className={`sub-content`}>
                        ・ Information containing expressions that encourage human trafficking,
                        sexual abuse, or child exploitation
                      </div>
                      <div className={`sub-content`}>
                        ・ Information containing expressions that encourage inappropriate use
                        of drugs and excessive drinking
                      </div>
                      <div className={`sub-content`}>
                        ・ Information containing antisocial expressions
                      </div>
                      <div className={`sub-content`}>
                        ・ Information requesting the spread of information to third parties,
                        such as chain mail
                      </div>
                      <div className={`sub-content`}>
                        ・ Information containing expressions that cause discomfort to others
                      </div>
                    </div>
                    <div className={`sub-content`}>
                      (7) Actions that place an excessive load on the network or system, etc.
                      of the Service
                    </div>
                    <div className={`sub-content`}>
                      (8) Reverse engineering or other analysis of the software or other
                      systems provided by us
                    </div>
                    <div className={`sub-content`}>
                      (9) Actions that may interfere with the operation of the Service
                    </div>
                    <div className={`sub-content`}>
                      (10) Unauthorized access to our network or system, etc.
                    </div>
                    <div className={`sub-content`}>(11) Impersonating a third party</div>
                    <div className={`sub-content`}>
                      (12) Using the ID or password of another user of the Service
                    </div>
                    <div className={`sub-content`}>
                      (13) Advertising, advertising, solicitation, or business activities on
                      the Service without our prior consent
                    </div>
                    <div className={`sub-content`}>
                      (14) Collection of information from other users of the Service
                    </div>
                    <div className={`sub-content`}>
                      (15) Actions that cause disadvantage, damage, or discomfort to us, other
                      users of the Service, or any other third party
                    </div>
                    <div className={`sub-content`}>
                      (16) Providing benefits to antisocial forces, etc.
                    </div>
                    <div className={`sub-content`}>
                      (17) Actions for the purpose of meeting with an unfamiliar person of the
                      opposite sex
                    </div>
                    <div className={`sub-content`}>
                      (18) Actions that directly or indirectly induce or facilitate any of the
                      actions in the preceding items
                    </div>
                    <div className={`sub-content`}>
                      (19) Attempting any of the actions in the preceding items
                    </div>
                    <div className={`sub-content`}>
                      (20) Any other actions that we deem inappropriate
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 19 (Suspension of the Service)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. We may suspend or interrupt the provision of all or part of the Service
                    without prior notice to the user in any of the following cases:
                    <div className={`sub-content`}>
                      (1) When it is necessary to urgently carry out inspection or maintenance
                      work on the computer system related to the Service
                    </div>
                    <div className={`sub-content`}>
                      (2) When the operation of the Service becomes impossible due to computer
                      failure, communication line failure, operational errors, excessive
                      concentration of access, unauthorized access, hacking, etc.
                    </div>
                    <div className={`sub-content`}>
                      (3) When the operation of the Service becomes impossible due to force
                      majeure such as earthquakes, lightning strikes, fires, wind and water
                      damage, power outages, natural disasters, etc.
                    </div>
                    <div className={`sub-content`}>
                      (4) In any other case where we deem it necessary to suspend or interrupt
                      the Service
                    </div>
                  </div>
                  <div className={`content`}>
                    2. We may change the content of the Service or terminate the provision of
                    the Service at our discretion.
                  </div>
                  <div className={`content`}>
                    3. In the event that we terminate the provision of the Service, we will
                    notify the user in advance by a method determined by us.
                  </div>
                  <div className={`content`}>
                    4. We shall not be liable for any damages suffered by the user due to the
                    suspension of the Service as set forth in this Article, regardless of the
                    reason.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 20 (Ownership of Rights)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. All intellectual property rights related to this website and the Service
                    belong to us or those who have granted us a license, and the license to use
                    the Service under these Terms does not imply a license to use the
                    intellectual property rights of us or those who have granted us a license
                    related to this website or the Service.
                  </div>
                  <div className={`content`}>
                    2. Users represent and warrant to us that they have the lawful rights to
                    post or otherwise transmit the posted data or distributed content
                    (hereinafter referred to as "Posted Data, etc.") and that the Posted Data,
                    etc. does not infringe the rights of any third party.
                  </div>
                  <div className={`content`}>
                    3. Users grant us a worldwide, non-exclusive, royalty-free, sublicensable
                    and transferable license to use, reproduce, distribute, create derivative
                    works from, display and perform the Posted Data, etc. Users also grant a
                    non-exclusive license to other users to use, reproduce, distribute, create
                    derivative works from, display and perform the Posted Data, etc. that the
                    user has posted or otherwise transmitted through the Service.
                  </div>
                  <div className={`content`}>
                    4. Users agree not to exercise their moral rights against us and those who
                    have inherited or been granted rights from us.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 21 (Registration Cancellation, etc.)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. We may, without prior notice or demand, delete or hide posted data,
                    temporarily suspend the use of the Service for the user, or cancel the
                    registration of the user, if the user falls under any of the following
                    items:
                    <div className='sub-content'>
                      (1) If the user violates any provision of these Terms
                    </div>
                    <div className='sub-content'>
                      (2) If it is found that there are false facts in the registration
                      information
                    </div>
                    <div className='sub-content'>
                      (3) If the user becomes insolvent or insolvent, or if the user has filed
                      for the commencement of bankruptcy proceedings, civil rehabilitation
                      proceedings, corporate reorganization proceedings, special liquidation
                      proceedings, or any similar proceedings
                    </div>
                    <div className='sub-content'>
                      (4) If there is no response to inquiries or other communications from us
                      for more than 30 days
                    </div>
                    <div className='sub-content'>
                      (5) If the user falls under any of the items in Article 3, Paragraph 4
                    </div>
                    <div className='sub-content'>
                      (6) If we determine that it is not appropriate to continue using the
                      Service or to continue registration as a user
                    </div>
                  </div>
                  <div className={`content`}>
                    2. If the user falls under any of the items in the preceding paragraph, the
                    user shall lose the benefit of the term for all debts owed to us and shall
                    immediately pay all debts to us.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 22 (Withdrawal, etc.)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Users can withdraw from the Service by completing the procedures
                    specified by us, and if they are users, they can cancel their registration
                    as users.
                  </div>
                  <div className={`content`}>
                    2. If the user has any debts to us at the time of withdrawal, the user
                    shall naturally lose the benefit of the term for all debts owed to us and
                    shall immediately pay all debts to us.
                  </div>
                  <div className={`content`}>
                    3. The handling of user information after withdrawal shall be in accordance
                    with the provisions of Article 15.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 23 (Post-contract termination procedures)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Regardless of the reason, we may delete all data related to the Service
                    after the termination of the contract.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 24 (Deletion Request Procedures)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Users may submit a request to us for the deletion of posted data, etc.,
                    on the grounds of copyright infringement, other legal violations, or
                    violations of these Terms of Service, through the application form (inquiry
                    URL) or the "Report" button on each post.
                  </div>
                  <div className={`content`}>
                    2. If we determine that the deletion request in the preceding paragraph is
                    reasonable, we will delete the relevant posted data, etc., within seven
                    days from the date of the deletion request. In such a case, we will report
                    to the requester about the measures we have taken within one month from the
                    date of the deletion request.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 25 (Disclaimer and Exemption from Liability)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. We do not guarantee, either explicitly or implicitly, that the Service
                    is suitable for a user's specific purpose, has the expected functionality,
                    commercial value, accuracy, or usefulness, that the use of the Service by
                    the user complies with laws or internal rules of industry groups applicable
                    to the user, that it can be used continuously, or that it will not have any
                    defects.
                  </div>
                  <div className={`content`}>
                    2. We shall only be liable for damages suffered by users in relation to the
                    Service within the scope of actual and direct ordinary damages (excluding
                    special damages, lost profits, indirect damages, and attorney's fees), and
                    the amount of damages compensation shall be limited to 10,000 yen.
                  </div>
                  <div className={`content`}>
                    3. We do not guarantee that the Service is compatible with all information
                    terminals, and users acknowledge in advance that defects in the operation
                    of the Service may occur due to version upgrades of the OS of the
                    information terminal used for the Service. We do not guarantee that such
                    defects will be resolved by program modifications that we may perform in
                    the event of such defects.
                  </div>
                  <div className={`content`}>
                    4. Transactions, communications, disputes, etc. that arise between users
                    and other users or third parties in connection with the Service or this
                    website shall be resolved by the users at their own responsibility.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 26 (Use of External Services)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. When using the Service, if users utilize external services such as
                    Facebook, Twitter, etc., they shall comply not only with these Terms but
                    also with the terms of use and other conditions stipulated by such external
                    services (including the conditions after any changes to such terms of use,
                    etc.).
                  </div>
                  <div className={`content`}>
                    2. External services are the responsibility of the companies or individuals
                    providing those services, and we do not make any guarantees regarding the
                    absence of errors, bugs, defects, or security vulnerabilities in such
                    external services, non-infringement of third-party rights, possession of
                    the nature and commercial value expected by users, or compliance with
                    applicable laws or internal rules, etc. for users.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 27 (Confidentiality)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Users shall treat any non-public information disclosed to them by the
                    Company in relation to the Service as confidential, unless the Company has
                    given prior written consent to disclose such information.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 28 (Handling of User Information)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. The handling of user information by the Company shall be governed by the
                    Company's separate Privacy Policy{' ('}
                    <Link to='/privacy-policy' target='_blank' className='link_route'>
                      {getEnv('REACT_APP_FAN_URL')}/privacy-policy
                    </Link>
                    {')'}, and users agree that the Company may handle user information in
                    accordance with this Privacy Policy.
                  </div>
                  <div className={`content`}>
                    2. The Company may use and disclose information and data provided by users
                    as statistical information in a form that does not identify individuals, at
                    the Company's discretion, and users shall not object to this.
                  </div>
                  <div className={`content`}>
                    3. Users must obtain consent from the individual concerned when personal
                    information is included in the posted data. Users shall handle such
                    personal information with the care of a good manager at their own
                    responsibility, and the Company will cooperate as much as possible.
                  </div>
                  <div className={`content`}>
                    4. Users shall handle any claims, disputes, etc. arising from the inclusion
                    of personal information in the posted data at their own responsibility and
                    expense, and the Company shall not be responsible for any of these matters.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 29 (Changes to these Terms and Conditions)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. The Company may change these Terms and Conditions as it deems necessary.
                    When changing these Terms and Conditions, the Company will announce the
                    effective date and content of the revised Terms and Conditions by posting
                    them on this website or by other appropriate means, or by notifying the
                    user. However, if the changes involve content that requires the user's
                    consent under the law, the Company will obtain the user's consent in the
                    manner specified by the Company.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 30 (Communication/Notification)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Inquiries about the Service and other communications or notifications
                    from the user to the Company, as well as notifications of changes to these
                    Terms and other communications or notifications from the Company to the
                    user, shall be made in the manner specified by the Company.
                  </div>
                  <div className={`content`}>
                    2. If the Company contacts or notifies the user at the email address or
                    other contact information included in the registered information, the user
                    is deemed to have received such communication or notification.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 31 (End of Use of This Website)</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Users can terminate the use of the Service at any time by deleting their
                    Service account or other methods specified by the Company. Users who have
                    terminated the use of the Service will not be able to use the Service from
                    the point of termination.
                  </div>
                  <div className={`content`}>
                    2. Users agree in advance that if they lose the right to use the Service
                    due to accidental account deletion or for any other reason, they will not
                    be able to use their account, provided content, user content, or other
                    information accumulated in the Service.
                  </div>
                  <div className={`content`}>
                    3. Even after the termination of the use of the Service, users are not
                    exempt from all obligations and debts (including damages, but not limited
                    to these) under this usage agreement to the Company and third parties.
                  </div>
                  <div className={`content`}>
                    4. Even after a user has terminated the use of the Service, the Company may
                    retain and use the information provided by the user.
                  </div>
                  <div className={`content`}>
                    5. If a user terminates the use of the Service, the Company may, at its
                    discretion, delete the user's account without prior notice, and the user
                    agrees that all content remaining in the website at the time of termination
                    may be lost.
                  </div>
                  <div className={`content`}>
                    6. In the event of a user's death, the user's account, user information,
                    and other information accumulated on the website will be deleted, and the
                    right to use the Service and the website will not be subject to
                    inheritance.
                  </div>
                  <div className={`content`}>
                    7. The Company may, at its discretion, delete accounts that have not been
                    accessed for more than six months without prior notice.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 32 (Transfer of Status under the Service Use Agreement, etc.)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. Users may not assign, transfer, create a security interest in, or
                    otherwise dispose of their status under the use agreement or rights or
                    obligations under these Terms to a third party without the prior written
                    consent of the Company.
                  </div>
                  <div className={`content`}>
                    2. If the Company transfers its business related to the Service to another
                    company, it may transfer its status under the use agreement, rights and
                    obligations under these Terms, and user registration information and other
                    customer information to the transferee of the business transfer, and the
                    user is deemed to have consented in advance to such transfer in this
                    clause. The business transfer mentioned in this clause includes not only
                    ordinary business transfers but also any case where the business is
                    transferred, such as a company split.
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>Article 33 (Severability)</div>
                <div className='text'>
                  Even if any provision or part of these Terms is deemed invalid or
                  unenforceable under the Consumer Contract Act or other laws, the remaining
                  provisions of these Terms and the remaining part of the provision deemed
                  partially invalid or unenforceable shall continue to be in full force and
                  effect.
                </div>
                <div></div>
              </div>
              <div>
                <div className='title-chapter'>
                  Article 34 (Governing Law and Jurisdiction)
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. The laws of the State of Nevada, United States of America, shall govern
                    these Terms and the Service Use Agreement.
                  </div>
                  <div className={`content`}>
                    2. For all disputes arising out of or in connection with these Terms or the
                    Service Use Agreement, the state courts located in the State of Nevada,
                    United States of America, shall have exclusive jurisdiction as the court of
                    first instance.
                  </div>
                </div>
              </div>
            </div>

            <div className='end-text'>
              <div>[Established on 19 June 2023]</div>
              <div>[Revised 29 September 2023]</div>
            </div>
          </div>
        </LayoutCenter>
      ) : (
        <LayoutCenter
          titlePage='Sharegram利用規約'
          notLogin={true}
          isShowLeftIcon={false}
          className={'title-page-creator page-legal'}
          uiMobile={true}>
          <div className={`container-fluid w-100 bg-white term-page creator`}>
            {/* <div className='title-page'>{TERMS_JP?.title_page || ''}</div> */}
            <div>
              本利用規約（以下「本規約」といいます）には、本サービスの提供条件及びDRAGON BLADE
              INC（以下「当社」といいます）とユーザーの皆様との間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。
            </div>
            <div>
              <div>
                <div className='title-chapter'>第1条（適用）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    本規約は、本サービスの提供条件及び本サービスの利用に関する当社とユーザーとの間の権利義務関係を定めることを目的とし、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されます。
                  </div>
                  <div className={`content`}>
                    2. 当社が本ウェブサイト上で掲載する本サービス利用に関するルール{' ('}
                    <Link to='/terms-of-use' target='_blank' className='link_route'>
                      {getEnv('REACT_APP_FAN_URL')}/terms-of-use
                    </Link>
                    {') '}
                    は、本規約の一部を構成するものとします。
                  </div>
                  <div className={`content`}>
                    3.
                    本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>第2条（定義）</div>
                <div className='text'>
                  本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。
                </div>
                <div>
                  <div className={`content`}>
                    (1)
                    「本サービス」とは、当社が提供する【Sharegram】という名称のクリエイター及びファンの交流支援関連サービス（理由の如何を問わずサービスの名称または内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。
                  </div>
                  <div className={`content`}>
                    (2)
                    「サービス利用契約」とは、本規約を契約条件として当社とユーザーの間で締結される、本サービスの利用契約を意味します。
                  </div>
                  <div className={`content`}>
                    (3)
                    「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。
                  </div>
                  <div className={`content`}>
                    (4)
                    「投稿データ」とは、ユーザーが本サービスを利用して投稿その他送信するコンテンツ（文章、画像、動画その他のデータを含みますがこれらに限りません。）を意味します。
                  </div>
                  <div className={`content`}>
                    (5) 「本ウェブサイト」とは、そのドメインが 「share-gram.com」
                    である、当社が運営するウェブサイト（理由の如何を問わず、当社のウェブサイトのドメインまたは内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。
                  </div>
                  <div className={`content`}>
                    (6)
                    「ユーザー」とは、第3条（登録）に基づいて本サービスの利用者としての登録がなされた個人を意味します。ユーザーはクリエイター及び一般ユーザーから構成されます。
                  </div>
                  <div className={`content`}>
                    (7)
                    「クリエイター」とは、第3条（登録）に基づいて本サービスの利用者としての登録がなされた個人のうち、本サービスを通じて配信コンテンツを提供する者を意味します。
                  </div>
                  <div className={`content`}>
                    (8)
                    「一般ユーザー」とは、ユーザーのうち、クリエイターを除くすべての者を意味します。
                  </div>
                  <div className={`content`}>
                    (9)
                    「配信コンテンツ」とは、本サービスを通じてクリエイターによって配信される動画、オーディオ（音楽やその他のサウンドなど）、画像、写真、言語（コメントや脚本など）、商号、商標、サービスマーク、ロゴ、インタラクティブ機能、ソフトウェア、指標、素材等の一切のコンテンツを意味します。
                  </div>
                  <div className={`content`}>
                    (10)
                    「ファンクラブ」とは、クリエイターが本サービスを通じて開設する、クリエイター及び一般ユーザーとの交流サイトを意味します。
                  </div>
                  <div className={`content`}>
                    (11)
                    「ポイント」とは、当社が提供する、一般ユーザーによる配信コンテンツの視聴　ないし利用の対価として用いられる決済手段をいいます。
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>第3条（ユーザー登録）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    本サービスの利用を希望する者（以下「登録希望者」といいます）は、本規約を遵守することに同意し、かつ当社の定める身元や年齢等の一定の情報（以下「登録事項」といいます）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。なお、登録申請の際には公的機関が発行した顔写真付きの身分証明書が必要となります。
                  </div>
                  <div className={`content`}>
                    2.
                    当社は、当社の基準に従って、第１項に基づいて登録申請を行った登録希望者（以下「登録申請者」といいます）の登録の可否を判断し、当社が登録を認める場合にはその旨を登録申請者に通知します。登録申請者のユーザーとしての登録は、当社が本項の通知を書面（Eメール等を含みます）により行ったことをもって完了したものとします。
                  </div>
                  <div className={`content`}>
                    3.
                    前項に定める登録の完了時に、サービス利用契約がユーザーと当社の間に成立し、ユーザーは本サービスを本規約に従い利用することができるようになります。
                  </div>
                  <div className={`content`}>
                    4.
                    当社は、登録申請者が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。
                    <div className={`sub-content`}>
                      (1)
                      当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合
                    </div>
                    <div className={`sub-content`}>
                      (2)
                      未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合
                    </div>
                    <div className={`sub-content`}>
                      (3)
                      反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合
                    </div>
                    <div className={`sub-content`}>
                      (4)
                      過去当社との契約に違反した者またはその関係者であると当社が判断した場合
                    </div>
                    <div className={`sub-content`}>
                      (5) 第21条に定める措置を受けたことがある場合
                    </div>
                    <div className={`sub-content`}>
                      (6) その他、登録を適当でないと当社が判断した場合
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>第4条（登録事項の変更）</div>
                <div className='text'>
                  ユーザーは、登録事項に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。
                </div>
              </div>
              <div>
                <div className='title-chapter'>第5条（パスワード及びユーザーIDの管理）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    ユーザーは、自己の責任において、本サービスに関するパスワード及びユーザーIDを適切に管理及び保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。
                  </div>
                  <div className={`content`}>
                    2.
                    パスワードまたはユーザーIDの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任はユーザーが負うものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第6条（本サービスの機能）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    当社は、クリエイターに対し、本サービスにおいて、以下の機能の全部又は一部を提供します。
                    <div className={`sub-content`}>(1) ファンクラブサイト開設機能</div>
                    <div className={`sub-content`}>(2) 配信コンテンツ配信機能</div>
                    <div className={`sub-content`}>
                      (3) その他当社が本サービスにおいて有用と判断する機能
                    </div>
                  </div>
                  <div className={`content`}>
                    2.
                    当社は、一般ユーザーに対して、本サービスにおいて、以下の機能の全部又は一部を提供します。
                    <div className={`sub-content`}>(1) 配信コンテンツの視聴機能</div>
                    <div className={`sub-content`}>
                      (2)
                      本サービス上に質問、コメント、LIKE及び「いいね」等の投稿データを投稿する機能
                    </div>
                    <div className={`sub-content`}>
                      (3) その他当社が本サービスにおいて有用と判断する機能
                    </div>
                  </div>
                  <div className={`content`}>
                    3.
                    当社は、本サービスの機能を追加変更する場合があるものとし、ユーザーに重大な影響がない限り、事前通知をしないものとします。
                  </div>
                </div>
              </div>
              <div>
                <div className='title-chapter'>第7条（ファンクラブ利用契約）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    一般ユーザーが本ウェブサイト上でファンクラブへの入会を申し込み、クリエイターが一般ユーザーに対しこれを承諾する旨の通知をした時点で、クリエイターと一般ユーザーとの間にファンクラブ利用契約が成立します。
                  </div>
                  <div className={`content`}>
                    2.
                    一般ユーザーは、ファンクラブ利用契約を締結することにより、ファンクラブ毎に設定されたファンクラブ会費の支払い義務が発生することに同意するものとします。
                  </div>
                  <div className={`content`}>
                    3.
                    各ファンクラブの料金体系及び支払方法は、別途本ウェブサイト上において定めるものとします。
                  </div>
                  <div className={`content`}>
                    4.
                    クリエイターは、別途当社が定める方法に従い、ファンクラブの内容若しくは料金体系等の全部又は一部の変更を行い、又はファンクラブの全部若しくは一部を終了させることができるものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第8条（ファンクラブ利用契約の期間等）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    ファンクラブ利用契約の期間は、一般ユーザーが当該ファンクラブに入会した日の翌月同日までとし、それ以降は１か月単位での自動更新契約とします。ただし、加入日に該当する暦日がない月の場合、月末日の前日までが契約期間となります。（例：
                    8月31日申し込みの場合、9月29日が契約最終日です）。なお、当該ファンクラブの会員（以下「ファンクラブ会員」といいます）が退会申請を行った場合、当該ファンクラブ利用契約は、退会申請を行った日を以って終了します。
                  </div>
                  <div className={`content`}>
                    2.
                    ファンクラブ利用契約の期間の途中で当該ファンクラブを退会した場合であっても、ファンクラブ会費は日割計算とはならず、ファンクラブ会員は、当月分のファンクラブ会費の返還を請求することはできません。
                  </div>
                  <div className={`content`}>
                    3.
                    ファンクラブ会費の支払を遅滞した場合（ポイントの残高不足等により決済手続がなされなかった場合を含む）、当該ファンクラブ会員は、自動的に、当該ファンクラブを退会したものとみなされます。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>
                  第9条（当社によるファンクラブ利用契約等の終了）
                </div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    当社がクリエイター又は一般ユーザーのユーザー登録を抹消した場合、当該時点において、当該クリエイターと当該ファンクラブ会員との間の契約又は当該一般ユーザーと各登録クリエイターとの間の契約は全て終了します。
                  </div>
                  <div className={`content`}>
                    2.
                    前項の場合もファンクラブ会費は日割計算とはならず、ファンクラブ会員は、当月分のファンクラブ会費の返還を請求することはできません。
                  </div>
                  <div className={`content`}>
                    3.
                    本条によるファンクラブ利用契約の終了の場合であっても、本規約に基づく措置である限りにおいて当社は免責され、この場合、ユーザーは、当社に対して一切の損害賠償請求を行うことはできません。
                  </div>
                  <div className={`content`}>
                    4.
                    当社が特定のクリエイターのユーザー登録を抹消した場合、当社は、当該クリエイターが開設した全てのファンクラブサイト（配信コンテンツを含む）を削除することができるものとします。この場合でも、本規約に基づく措置である限りにおいて当社は免責され、当該クリエイターは、当社に対して一切の損害賠償請求を行うことはできません。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第10条（個別コンテンツ配信契約）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    クリエイターは、ファンクラブ利用契約における会員限定の配信コンテンツとは別に、別途定める方法により、ファンクラブサイト上にて価格・配信日時・その他の条件を設定のうえ、コンテンツ配信（以下「個別コンテンツ配信」といいます）を行うことができます。
                  </div>
                  <div className={`content`}>
                    2.
                    登録ユーザーは、当社が別途定める方法により、個別コンテンツ配信の視聴ないし利用を申込むことができ、これにより、当該クリエイターとの間で個別コンテンツ配信契約が成立します。
                  </div>
                  <div className={`content`}>
                    3.
                    クリエイターは、別途当社が定める方法に従い、個別コンテンツ配信の内容若しくは料金体系等の全部又は一部の変更を行い、又は当該コンテンツの全部若しくは一部の配信を終了させることができるものとします。
                  </div>
                  <div className={`content`}>
                    4.
                    当社判断による個別コンテンツ配信契約の終了については第９条の規定を準用するものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第11条（ポイントの購入及び取扱い）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    一般ユーザーは、本サービスの利用料金の支払い方法として、クレジットカード決済、銀行口座振替、その他当社の定める方法によりポイントを購入し、本サービスの利用に際して1ポイント＝1円相当額として利用できるものとします。その他、ポイントの内容および利用条件については、当社が別途定め、本サービスにおいて表示するところに従うものとします。
                  </div>
                  <div className={`content`}>
                    2.
                    一般ユーザーが保有することのできるポイントの上限は100,000ポイントとします。
                  </div>
                  <div className={`content`}>
                    3.
                    一般ユーザーは、有償ポイントの発行を受けた日から180日間のみ同ポイントを利用することができます。ユーザーは、一般無償ポイントを獲得した日から180日間もしくは付与前に別途当社が定めた日数の期間中にのみ同ポイントを利用することができます。有効期限を過ぎた未使用のポイントは消滅し、その後利用することはできません。
                  </div>
                  <div className={`content`}>
                    4.
                    当社は、理由のいかんを問わず、ポイントの払戻し又は換金を行いません。ただし、法令上必要な場合はこの限りではありません。この場合、ポイントの払戻し方法は、法令に従って当社が定め、別途通知するところによるものとします。
                  </div>
                  <div className={`content`}>
                    5.
                    当社は、別途当社が定める方法により、一般ユーザーが獲得したポイント、一般ユーザーが使用したポイントおよびポイントの残高（以下「ポイント額」といいます）を、一般ユーザーに告知します。一般ユーザーは、ポイント額に疑義のある場合には、直ちに当社に連絡し、その内容を説明するものとします。ポイント額に関する最終的な決定は当社が行うものとし、一般ユーザーはこれに従うものとします。
                  </div>
                  <div className={`content`}>
                    6.
                    ポイントの使用は、一般ユーザー本人が行うものとし、当該一般ユーザー本人以外の第三者が行うことはできません。第三者による不正使用であった場合でも、当社は、使用されたポイントを返還する義務を負わず、一般ユーザーに生じた損害について一切責任を負いません。
                  </div>
                  <div className={`content`}>
                    7.
                    ポイントの取得、ポイントの利用にともない、税金や付帯費用が発生する場合には、一般ユーザーがこれらを負担するものとします。
                  </div>
                  <div className={`content`}>
                    8.
                    理由のいかんを問わず、一般ユーザーが退会した場合又は一般ユーザー登録が抹消された場合には、当該一般ユーザーが保有するポイントは全て失効し、以後利用することはできないものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第12条（キャンペーン）</div>
                <div className='text'>
                  当社は、本サービスにおける各種キャンペーンへの参加その他当社が別途指定する行為を行った一般ユーザーに対し、ポイントを無償で付与することができるものとします。
                </div>
              </div>

              <div>
                <div className='title-chapter'>第13条（決済及び支払申請）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    クリエイターは、当社に対し、一般ユーザーよりファンクラブ会費及び個別コンテンツ配信契約利用料（以下「会費等」といいます）を代理受領する権限並びにかかる受領業務を当社の指定する第三者（以下、当社とあわせて「代理受領者」といいます。）に再委託する権限を付与するものとします。代理受領者が一般ユーザーから会費等を受領することにより、本規約に基づき一般ユーザーが負う会費等の支払債務は消滅するものとします。
                  </div>
                  <div className={`content`}>
                    2.
                    一般ユーザーは、会費等を直接にクリエイターに支払ってはなりません。一般ユーザーは、会費等を直接にクリエイターに支払ったことをもって、当社からの請求を拒絶することができないものとします。
                  </div>
                  <div className={`content`}>
                    3.
                    当社は、クリエイターに対し、別途当社が定める計算期間中において一般ユーザーが当該クリエイターに支払った会費等が別途当社の定める金額に達した場合には、振込手数料を控除した上で当該クリエイターの指定する口座に振り込む方法によって支払うものとします。なお、クリエイターは、当社が上記金員をドルで送金すること及びクリエイターが為替手数料を負担することを同意するものとします。
                  </div>
                  <div className={`content`}>
                    4.
                    クリエイターが、自らユーザー登録を抹消した場合又は第9条の規定に基づき登録を抹消された場合、当社は、当該クリエイターに対して請求できる会費等の一切を放棄したものとみなすことができるものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第14条（本サービスの利用手数料）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    クリエイターは、当社に対し、本サービスの利用の対価として、別途当社が定める利用手数料を当社に対して支払うものとします。
                  </div>
                  <div className={`content`}>
                    2.
                    当社は、前条第３項の規定に基づきクリエイターに対して支払う会費等から前項に定める利用手数料を差し引くことにより、利用手数料の受領に代えることができるものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第15条（ユーザーの責任）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    ユーザーは、他人の名誉を毀損した場合、プライバシー権を侵害した場合、許諾なく第三者の個人情報を開示した場合、著作権法に違反する行為を行った場合そのほか第三者の権利を侵害した場合には、自己の責任と費用においてこれを解決しなければならず、当社は一切の責任を負いません。
                  </div>
                  <div className={`content`}>
                    2.
                    当社は、ユーザーが本サービスを利用して本サービス内に蓄積、保存したデータ等の保管、保存、バックアップ等に関して、当社の責めに帰すべき事由による場合を除き、一切責任を負わないものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第16条（配信コンテンツの出演者）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    クリエイターは、配信コンテンツに当該クリエイター以外の第三者（以下「出演者」といいます）が出演する場合には、当社に対し、配信コンテンツが本サービスを通じ配信される前に、別途当社が定める方法で、当該出演者の氏名・年齢等の属性情報、公的機関が発行した当該出演者の顔写真付きの身分証明書及び当該顔写真付き身分証と当該出演者の顔とを並べた社員、当該クリエイター及び当該出演者に適用される法の要求に従って取得されるべき配信コンテンツへの出演・公衆配布・ダウンロード等に対する同意書等（以下「出演者情報等」といいます）を提出するものとします。
                  </div>
                  <div className={`content`}>
                    2.
                    クリエイターは、出演者に対し、当社に対して当該出演者の出演者情報が提供されることについて、事前に同意を得るものとします。{' '}
                  </div>
                  <div className={`content`}>
                    3.
                    クリエイターは、当社が要求する場合には、出演者情報の他に当社が要求する情報ないし資料等を遅滞なく提出するものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第17条（当社の権限及び責任）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    当社は、クリエイターの同意なく、配信コンテンツが本サービスを通じ配信される前に、当該コンテンツが適法かつ本規約に違反するものではないかを確認するものとし、クリエイターはそれに同意するものとします。
                  </div>
                  <div className={`content`}>
                    2.
                    当社は、クリエイターがリアルタイムの配信を行う場合には、当該配信コンテンツが適法かつ本規約に違反するものではないかリアルタイムでモニタリングするものとします。
                  </div>
                  <div className={`content`}>
                    3.
                    当社は、前２項の配信コンテンツが違法な意思本規約に違反するものであると判断できる場合、第２１条に定める措置を講ずることができるものとし、クリエイターは当該措置に同意するものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第18条（禁止事項）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。
                    <div className={`sub-content`}>
                      (1) 法令に違反する行為または犯罪行為に関連する行為
                    </div>
                    <div className={`sub-content`}>
                      (2)
                      当社、本サービスの他の利用者またはその他の第三者に対する詐欺または脅迫行為
                    </div>
                    <div className={`sub-content`}>
                      (3) 未成年者の身体が含まれる配信コンテンツの投稿
                    </div>
                    <div className={`sub-content`}>(4) 公序良俗に反する行為</div>
                    <div className={`sub-content`}>
                      (5)
                      当社、本サービスの他の利用者またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為
                    </div>
                    <div className={`sub-content`}>
                      (6)
                      本サービスを通じ、以下に該当し、または該当すると当社が判断する情報を当社または本サービスの他の利用者に送信すること
                      <div className={`sub-content dot`}>
                        過度に暴力的または残虐な表現を含む情報
                      </div>
                      <div className={`sub-content dot`}>
                        コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報
                      </div>
                      <div className={`sub-content dot`}>
                        当社、本サービスの他の利用者またはその他の第三者の名誉または信用を毀損する表現を含む情報
                      </div>
                      <div className={`sub-content dot`}>差別を助長する表現を含む情報</div>
                      <div className={`sub-content dot`}>
                        自殺、自傷行為を助長する表現を含む情報
                      </div>
                      <div className={`sub-content dot`}>
                        人身売買、性的虐待、児童搾取を助長する表現を含む情報
                      </div>
                      <div className={`sub-content dot`}>
                        薬物の不適切な利用及び過度な飲酒を助長する表現を含む情報
                      </div>
                      <div className={`sub-content dot`}>反社会的な表現を含む情報</div>
                      <div className={`sub-content dot`}>
                        チェーンメール等の第三者への情報の拡散を求める情報
                      </div>
                      <div className={`sub-content dot`}>
                        他人に不快感を与える表現を含む情報
                      </div>
                    </div>
                    <div className={`sub-content`}>
                      (7) 本サービスのネットワークまたはシステム等に過度な負荷をかける行為
                    </div>
                    <div className={`sub-content`}>
                      (8)
                      当社が提供するソフトウェアその他のシステムに対するリバースエンジニアリングその他の解析行為
                    </div>
                    <div className={`sub-content`}>
                      (9) 本サービスの運営を妨害するおそれのある行為
                    </div>
                    <div className={`sub-content`}>
                      (10) 当社のネットワークまたはシステム等への不正アクセス
                    </div>
                    <div className={`sub-content`}>(11) 第三者に成りすます行為</div>
                    <div className={`sub-content`}>
                      (12) 本サービスの他の利用者のIDまたはパスワードを利用する行為
                    </div>
                    <div className={`sub-content`}>
                      (13)
                      当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為
                    </div>
                    <div className={`sub-content`}>
                      (14) 本サービスの他の利用者の情報の収集
                    </div>
                    <div className={`sub-content`}>
                      (15)
                      当社、本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為
                    </div>
                    <div className={`sub-content`}>(16) 反社会的勢力等への利益供与</div>
                    <div className={`sub-content`}>
                      (17) 面識のない異性との出会いを目的とした行為
                    </div>
                    <div className={`sub-content`}>
                      (18) 前各号の行為を直接または間接に惹起し、または容易にする行為
                    </div>
                    <div className={`sub-content`}>(19) 前各号の行為を試みること</div>
                    <div className={`sub-content`}>
                      (20) その他、当社が不適切と判断する行為
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第19条（本サービスの停止等）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    当社は、以下のいずれかに該当する場合には、ユーザーに事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。
                    <div className={`sub-content`}>
                      (1)
                      本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合
                    </div>
                    <div className={`sub-content`}>
                      (2)
                      コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合
                    </div>
                    <div className={`sub-content`}>
                      (3)
                      地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
                    </div>
                    <div className={`sub-content`}>
                      (4) その他、当社が停止または中断を必要と判断した場合
                    </div>
                  </div>
                  <div className={`content`}>
                    2.
                    当社は、当社の都合により、本サービスの内容を変更し、又は本サービスの提供を終了することができるものとします。
                  </div>
                  <div className={`content`}>
                    3.
                    当社が本サービスの提供を終了する場合、当社は当社所定の方法で、ユーザーに事前に通知するものとします。
                  </div>
                  <div className={`content`}>
                    4.
                    当社は、本条に定める本サービスの停止等によってユーザーが被った一切の損害について、その理由の如何を問わず賠償する責任を負いません。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第20条（権利帰属）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    本ウェブサイト及び本サービスに関する知的財産権は全て当社または当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、本ウェブサイトまたは本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。
                  </div>
                  <div className={`content`}>
                    2.
                    ユーザーは、投稿データないし配信コンテンツ（以下「投稿データ等」といいます）について、自らが投稿その他送信することについての適法な権利を有していること、及び投稿データ等が第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。
                  </div>
                  <div className={`content`}>
                    3.
                    ユーザーは、投稿データ等について、当社に対し、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与します。また、他のユーザーに対しても、本サービスを利用してユーザーが投稿その他送信した投稿データ等の使用、複製、配布、派生著作物を作成、表示及び実行することについての非独占的なライセンスを付与します。
                  </div>
                  <div className={`content`}>
                    4.
                    ユーザーは、当社及び当社から権利を承継しまたは許諾された者に対して著作者人格権を行使しないことに同意するものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第21条（登録抹消等）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    当社は、ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、投稿データ等を削除もしくは非表示にし、当該ユーザーについて本サービスの利用を一時的に停止し、または当該ユーザーの登録を抹消することができます。
                    <div className={`sub-content`}>
                      (1) 本規約のいずれかの条項に違反した場合
                    </div>
                    <div className={`sub-content`}>
                      (2) 登録事項に虚偽の事実があることが判明した場合
                    </div>
                    <div className={`sub-content`}>
                      (3)
                      支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
                    </div>
                    <div className={`sub-content`}>
                      (4)
                      当社からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない場合
                    </div>
                    <div className={`sub-content`}>(5) 第3条第4項各号に該当する場合</div>
                    <div className={`sub-content`}>
                      (6)
                      その他、当社が本サービスの利用またはユーザーとしての登録の継続を適当でないと判断した場合
                    </div>
                  </div>
                  <div className={`content`}>
                    2.
                    前項各号のいずれかの事由に該当した場合、ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第22条（退会等）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    ユーザーは、当社所定の手続の完了により、本サービスから退会することができるものとし、ユーザーの場合は、自己のユーザーとしての登録を抹消することができます。
                  </div>
                  <div className={`content`}>
                    2.
                    退会にあたり、当社に対して負っている債務が有る場合は、ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
                  </div>
                  <div className={`content`}>
                    3. 退会後の利用者情報の取扱いについては、第15条の規定に従うものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第23条（契約終了後の処理）</div>
                <div className='text'>
                  当社は、理由の如何を問わず契約終了後、本サービスに係る全てのデータを削除できるものとします。
                </div>
              </div>

              <div>
                <div className='title-chapter'>第24条（削除申請手続）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    ユーザーは、申請フォーム（問い合わせURL）又は各投稿の「通報ボタン」を通じて、著作権侵害その他法令違反ないし本規約違反を理由として当社に対して投稿データ等の削除申請を行うことができます。
                  </div>
                  <div className={`content`}>
                    2.
                    当社は、前項の削除申請が合理的なものであると判断できる場合、当該削除申請があった日から７日以内に当該投稿データ等を削除するものとします。また、その場合、当社は削除申請があった日から１か月以内に当該削除申請者に対し、当社が講じた措置について報告するものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第25条（保証の否認及び免責）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    当社は、本サービスがユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、ユーザーによる本サービスの利用がユーザーに適用のある法令または業界団体の内部規則等に適合すること、継続的に利用できること、及び不具合が生じないことについて、明示又は黙示を問わず何ら保証するものではありません。
                  </div>
                  <div className={`content`}>
                    2.
                    当社は、本サービスに関してユーザーが被った損害につき、現実かつ直接に発生した通常の損害（特別損害、逸失利益、間接損害及び弁護士費用を除く。）の範囲内でのみ損害賠償責任を負うものとし、かつ損害賠償の額は1万円を上限とするものとします。
                  </div>
                  <div className={`content`}>
                    3.
                    当社は、本サービスが全ての情報端末に対応していることを保証するものではなく、本サービスの利用に供する情報端末のOSのバージョンアップ等に伴い、本サービスの動作に不具合が生じる可能性があることにつき、ユーザーはあらかじめ了承するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。
                  </div>
                  <div className={`content`}>
                    4.
                    本サービスまたは本ウェブサイトに関連してユーザーと他のユーザーまたは第三者との間において生じた取引、連絡、紛争等については、ユーザーが自己の責任によって解決するものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第26条（外部サービスの利用）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    ユーザーは、本サービスの利用にあたり、Facebook、Twitterその他の外部サービス等を利用する場合には、本規約のほか、当該外部サービスの定める利用規約その他の条件(当該利用規約等が変更された場合には変更後の条件を含みます。)に従うものとします。
                  </div>
                  <div className={`content`}>
                    2.
                    外部サービスについては、当該外部サービスを提供する企業又は個人が責任を負うものとし、当社は、当該外部サービスにつき、エラー、バグ、不具合又はセキュリティ上の欠陥が存しないこと、第三者の権利を侵害しないこと、ユーザーが期待する性質及び商品的価値を有すること、並びにユーザーに適用のある法令又は内部規則等に適合することについて、如何なる保証も行うものではありません。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第27条（秘密保持）</div>
                <div className='text'>
                  ユーザーは、本サービスに関連して当社がユーザーに対して秘密に取扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取扱うものとします。
                </div>
              </div>

              <div>
                <div className='title-chapter'>第28条（利用者情報の取扱い）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    当社によるユーザーの利用者情報の取扱いについては、別途当社プライバシーポリシー
                    {' ('}
                    <Link to='/privacy-policy' target='_blank' className='link_route'>
                      {getEnv('REACT_APP_FAN_URL')}/privacy-policy
                    </Link>
                    {') '}
                    の定めによるものとし、ユーザーはこのプライバシーポリシーに従って当社がユーザーの利用者情報を取扱うことについて同意するものとします。
                  </div>
                  <div className={`content`}>
                    2.
                    当社は、ユーザーが当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用及び公開することができるものとし、ユーザーはこれに異議を唱えないものとします。
                  </div>
                  <div className={`content`}>
                    3.
                    ユーザーは、投稿データに個人情報が含まれる場合、当該個人情報に係る個人に対して投稿データに含まれることについての同意を得なければならないものとします。ユーザーは、当該個人情報について自己の責任で善良なる管理者の注意をもって取り扱うものとし、当社はそれにできるかぎり協力するものとします。
                  </div>
                  <div className={`content`}>
                    4.
                    ユーザーは、投稿データに個人情報が含まれていたことに起因するクレーム、紛争等に対して、自己の責任と費用を持って対応するものとし、当社はこれに一切責任を負わないものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第29条（本規約等の変更）</div>
                <div className='text'>
                  当社は、当社が必要と認めた場合は、本規約を変更できるものとします。本契約を変更する場合、変更後の本規約の施行時期及び内容を本ウェブサイト上での掲示その他の適切な方法により周知し、またはユーザーに通知します。但し、法令上ユーザーの同意が必要となるような内容の変更の場合は、当社所定の方法でユーザーの同意を得るものとします。
                </div>
              </div>

              <div>
                <div className='title-chapter'>第30条（連絡／通知）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    本サービスに関する問い合わせその他ユーザーから当社に対する連絡または通知、及び本規約の変更に関する通知その他当社からユーザーに対する連絡または通知は、当社の定める方法で行うものとします。
                  </div>
                  <div className={`content`}>
                    2.
                    当社が登録事項に含まれるメールアドレスその他の連絡先に連絡または通知を行った場合、ユーザーは当該連絡または通知を受領したものとみなします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第31条（本ウェブサイトの利用終了）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    ユーザーは、本サービスのアカウントの削除その他当社所定の方法により、いつでも本サービスの利用を終了することができます。本サービスの利用を終了したユーザーは、当該終了時点から本サービスを利用することができなくなります。
                  </div>
                  <div className={`content`}>
                    2.
                    誤ってアカウントを削除した場合その他理由の如何を問わず、ユーザーが本サービスを利用する権利を失った場合、ユーザーは、アカウント、提供コンテンツユーザーコンテンツその他本サービスに蓄積した情報を利用することができなくなることを、あらかじめ承諾するものとします。
                  </div>
                  <div className={`content`}>
                    3.
                    ユーザーは、本サービス利用終了後も、当社及び第三者に対する本利用契約上の一切の義務及び債務(損害賠償を含みますが、これに限りません。)を免れるものではありません。
                  </div>
                  <div className={`content`}>
                    4.
                    当社は、ユーザーが本サービスの利用を終了した後も、当該ユーザーが当社に提供した情報を保有・利用することができるものとします。
                  </div>
                  <div className={`content`}>
                    5.
                    ユーザーが本サービスの利用を終了した場合、当社は、当社の裁量により、事前に通知することなく当該ユーザーのアカウントを削除することができるものとし、ユーザーは、終了時に残存している本ウェブサイト内の一切のコンテンツが消滅する場合があることに同意するものとします。
                  </div>
                  <div className={`content`}>
                    6.
                    ユーザーが死亡した場合、当該ユーザーのアカウント、ユーザー情報その他本ウェブサイトに蓄積した情報は削除されるものとし、本サービス及び本ウェブサイトの利用権は相続の対象とはなりません。
                  </div>
                  <div className={`content`}>
                    7.
                    当社は、当社の裁量により、あらかじめ通知することなく、最終のアクセスから6か月以上経過しているアカウントを削除することができるものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第32条（サービス利用契約上の地位の譲渡等）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1.
                    ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
                  </div>
                  <div className={`content`}>
                    2.
                    当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びにユーザーの登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
                  </div>
                </div>
              </div>

              <div>
                <div className='title-chapter'>第33条（分離可能性）</div>
                <div className='text'>
                  本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
                </div>
              </div>

              <div>
                <div className='title-chapter'>第34条（準拠法及び管轄裁判所）</div>
                <div className='text'></div>
                <div>
                  <div className={`content`}>
                    1. 本規約及びサービス利用契約の準拠法はアメリカ合衆国ネヴァダ州法とします。
                  </div>
                  <div className={`content`}>
                    2.
                    本規約またはサービス利用契約に起因し、または関連する一切の紛争については、アメリカ合衆国ネヴァダ州地方裁判所を第一審の専属的合意管轄裁判所とします。
                  </div>
                </div>
              </div>
            </div>

            <div className='end-text'>
              <div>【2023年06月19日制定】</div>
              <div>【2023年09月29日改定】</div>
            </div>
          </div>
        </LayoutCenter>
      )}
    </>
  );
};

export default TermScreen;
