import { ImgCustom, PopupFanDetail, PopupLoading } from 'components';
import MarkDownRead from 'components/MarkDownRead';
import Loading from 'components/Paging/Loading';
import { newPathUser } from 'constants/layout/constant';
import { useClickOutSize } from 'hooks/v2/clickOutSide';
import {
  CommentIcon,
  Edit2Icon,
  Edit3Icon,
  FlagIcon,
  FlowUnFlowIcon,
  HeartIcon,
  ThreePointIcon,
  ThreePointIconV3,
  TrashIcon,
  UnFlowIcon,
} from 'images';
import { ContextTimeline } from 'pages/timeline/contextTimeline';
import { forwardRef, useCallback, useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getEditCommentDetail,
  getListCommentDetail,
  logUserProfile,
} from 'store/actions/myPage';
import styled from 'styled-components';
import { AVATAR_DEFAULT, STORAGE } from 'utils';
import Storage from 'utils/storage';
import { convertToCurrentTime, decryptPath, isAdmin, isAgency } from 'utils/utils';
import useUserRole from '../../hooks/useUserRole';
import { FormEditComment } from './commentListShowAll.edit';
import { FormAddRepComment } from './commentListShowAll.formAddRep';
import ListRepComment from './commentListShowAll.listRep';
import './index.scss';
import {
  postFollowCommentDetailNoApi,
  postFollowUserNoApi,
  followOrUnFollowUserNoApi,
  resetIDHomePage,
  resetDataInfoOther,
  resetgetIDCMTTimeline,
  resetgetIDCMTHomepage,
  resetgetIDCMTBookmark,
  updateHiddenLastComment,
} from 'store/actions/usersNew';
import HideCommentIcon from 'images/HideCommentIcon';
import { useMemo } from 'react';

const WrapperList = styled.div``;

function updateArrayWithNewData(originData, newData) {
  const idToUpdate = newData.id;
  const indexToUpdate = originData.findIndex((item) => item.id === idToUpdate);

  if (indexToUpdate !== -1) {
    originData[indexToUpdate] = newData;
  } else {
    originData.push(newData);
  }

  return originData;
}

function deleteItemById(dataArray, idToDelete) {
  const updatedArray = dataArray.filter((item) => item.id !== idToDelete);
  return updatedArray;
}
function hideItemById(dataArray, idToHide) {
  const updatedArray = dataArray?.map((item) => {
    if (item.id === idToHide) {
      return {
        ...item,
        is_hidden: 1,
      };
    }
    return item;
  });
  return updatedArray;
}

function FollowCommentUser({
  handleFollowUserComment,
  isAuth,
  setIsVisiblePopup,
  updateCommentFollow,
  item_comment,
  setIsLoadingFollow,
  callbackFollow,
  type = 'default',
  updateCommentFollowSub,
  setFlg,
}) {
  const { listPostCreator, listPostTimeLine, detailPost, searchPostTimeline } = useSelector(
    (state) => state.usersNew
  );
  const { fanListBookmark } = useSelector((state) => state.users);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isFollowed, setIsFollowed] = useState(false);
  // typeFollow = timeline
  useEffect(() => {
    if (type === 'timeline') {
      const findUser = listPostTimeLine.find((item) => item.user_id === item_comment?.user_id);
      if (findUser) {
        setIsFollowed(findUser?.is_followed);
      } else {
        setIsFollowed(item_comment?.is_followed);
      }
    }
  }, []);
  // typeFollow = detailHome
  useEffect(() => {
    if (type === 'detailHome') {
      const findUser = listPostCreator.find((item) => item.user_id === item_comment?.user_id);
      if (findUser) {
        setIsFollowed(findUser?.is_followed);
      } else {
        setIsFollowed(item_comment?.is_followed);
      }
    }
  }, []);
  // typeFollow = detailPost || postDetail
  useEffect(() => {
    if (type === 'detailPostSale' || type === 'postDetail') {
      setIsFollowed(item_comment?.is_followed);
    }
  }, []);
  // typeFollow = fav
  useEffect(() => {
    if (type === 'fav') {
      const findUser = fanListBookmark.find((item) => item.user_id === item_comment?.user_id);
      if (findUser) {
        setIsFollowed(findUser?.is_followed);
      } else {
        setIsFollowed(item_comment?.is_followed);
      }
    }
  }, []);
  // typeFollow = searchShort
  useEffect(() => {
    if (type === 'searchShort') {
      const findUser = searchPostTimeline.find(
        (item) => item.user_id === item_comment?.user_id
      );
      if (findUser) {
        setIsFollowed(findUser?.is_followed);
      } else {
        setIsFollowed(item_comment?.is_followed);
      }
    }
  }, []);
  return (
    <div
      className='item'
      onMouseDown={() => {
        if (!isAuth) {
          setIsVisiblePopup(-1);
          dispatch(logUserProfile());
        } else {
          setFlg(true);
          updateCommentFollowSub(item_comment?.user_id);
          updateCommentFollow(item_comment?.user_id);
          setTimeout(() => {
            switch (type) {
              case 'timeline':
              case 'detailHome':
              case 'detailPostSale':
              case 'postDetail':
              case 'default':
                dispatch(
                  postFollowCommentDetailNoApi(
                    {
                      is_followed: isFollowed === 1 ? 0 : 1,
                    },
                    item_comment?.user_id
                  )
                );
                callbackFollow && callbackFollow(item_comment?.is_followed === 1 ? 0 : 1);
                break;
              // case 'fav':
              case 'searchShort':
                dispatch(
                  followOrUnFollowUserNoApi(
                    {
                      is_followed: isFollowed === 1 ? 0 : 1,
                    },
                    item_comment?.user_id
                  )
                );
                callbackFollow && callbackFollow(item_comment?.is_followed === 1 ? 0 : 1);
                break;
              default:
                break;
            }
          }, 50);
          handleFollowUserComment(
            {
              id: item_comment?.user_id,
              type: 'comment',
            },
            () => {
              setIsLoadingFollow(true);
            },
            () => {
              setIsLoadingFollow(false);
            }
          );
          setIsVisiblePopup(-1);
        }
      }}>
      {(
        type === 'default'
          ? !item_comment?.is_followed || item_comment?.is_followed !== 1
          : isFollowed !== 1
      ) ? (
        <FlowUnFlowIcon />
      ) : (
        <UnFlowIcon />
      )}
      <span className='text'>
        {(
          type === 'default'
            ? !item_comment?.is_followed || item_comment?.is_followed !== 1
            : isFollowed !== 1
        )
          ? t('comment.listComment.flow')
          : t('comment.listComment.unflow')}
      </span>
    </div>
  );
}
export const CommentPostTimeLineShowAll = forwardRef(
  (
    {
      handleDeleteComment,
      handleHideComment = () => {},
      handleLikeComment,
      dataPost,
      handleFollowUserComment,
      id,
      addCountComment,
      idScrollable = 'sroll-list-comment',
      isPopupSaleContent = false,
      isEnableComment = true,
      setFlgRender1,
      handleClose,
      setTrickReloadComment,
      trickReloadComment,
      dataProfile,
      defaultFetch = 1,
      setCountComment = () => {},
      defaultCount = 0,
      callbackFollow,
      typeFollow = 'timeline',
      handleTimeline,
      pages = 'timeline',
      renderFolowCMT = false,
      isPopup = false,
    },
    ref
  ) => {
    const { setCountNumberView } = useContext(ContextTimeline);
    useEffect(() => {
      setCountNumberView((prev) => ({
        ...prev,
        countNumberComment: dataPost?.number_comment || 0,
      }));
    }, []);
    useEffect(() => {
      setCountComment(defaultCount);
    }, [defaultCount]);
    const { renderLikeCMTTimeline, renderLikeCMTHomepage, renderLikeCMTBookmark } =
      useSelector((state) => state.usersNew);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [commentDetail, setCommentDetail] = useState([]);
    const [paginationComment, setPaginationComment] = useState({});
    const [statusPostRepComment] = useState(null);
    const [statusEditComment, setStatusEditComment] = useState(null);
    const [showFormReplyComment, setFormReplyComment] = useState(-1);
    const [showFormEditComment, setFormEditComment] = useState(null);
    const [isVisiblePopup, setIsVisiblePopup] = useState(-1);
    const [isVisible, setIsVisible] = useState(false);
    const [idFan, seIdFan] = useState(false);
    const { isIdol } = useUserRole();
    const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
    const [visibleComments, setVisibleComments] = useState(defaultFetch);
    const [page, setPage] = useState(1);
    const [trickReply, setTrickReply] = useState(null);
    const [loadingMess, setLoadingMess] = useState(false);
    const [isLoadingFollow, setIsLoadingFollow] = useState(false);
    const [count, setCount] = useState(1);

    const [replyCommentDetail1, setReplyCommentDetail1] = useState([]);
    const [flg, setFlg] = useState(false);
    const [flgSee, setFlgSee] = useState(false);
    const [flgSeeData, setFlgSeeData] = useState(false);
    const { dataInfoOther } = useSelector((state) => state.usersNew);
    // const [trickCountReply, setTrickCountReply] = useState({})
    // esc func
    const escFunction = useCallback((event) => {
      if (event.keyCode === 27) {
        setFormEditComment(null);
        setFormReplyComment(null);
      }
    }, []);
    // start func esc

    // useEffect(() => {
    //   if (paginationComment?.current_page === 1) setPage(1);
    // }, [paginationComment]);
    useEffect(() => {
      if (statusPostRepComment) {
        setFormReplyComment(null);
        const textareaId = document.getElementById('textarea-post-rep-comment');
        if (textareaId) textareaId.style.height = '38px';
      }

      if (statusEditComment) {
        setFormEditComment(null);
        const textareaId = document.getElementById('textarea-edit-comment');
        if (textareaId) textareaId.style.height = '38px';
      }

      document.addEventListener('keydown', escFunction);
      return () => {
        document.removeEventListener('keydown', escFunction);
      };
    }, [escFunction, statusPostRepComment, statusEditComment]);

    const itemsPerPage = 10;

    const { search } = useLocation();
    const token = search?.split('&');
    const tokenAgency = localStorage.getItem(STORAGE.AGENCY_TOKEN)?.replace(/"/g, '') || false;

    const getCommentList = () => {
      const adminToken = isAgency
        ? tokenAgency
        : isAdmin
        ? token[1]?.split('=')[1] ?? token[0]?.split('=')[1]
        : null;
      setLoadingMess(true);
      const onSuccess = (res) => {
        if (res?.data?.length === 0) {
          setFlgSeeData(true);
        }
        setLoadingMess(false);
        setCommentDetail(res?.data || []);
        setPaginationComment(res?.pagination || {});
        setTrickReloadComment(null);
        setStatusEditComment(null);
      };
      const onFailed = () => {};
      dispatch(
        getListCommentDetail({
          id,
          page: 1,
          limit: 10,
          onSuccess,
          onFailed,
          adminToken,
        })
      );
    };

    const getCommentListMore = ({ nextPage }) => {
      const adminToken = isAgency
        ? tokenAgency
        : isAdmin
        ? token[1]?.split('=')[1] ?? token[0]?.split('=')[1]
        : null;
      setLoadingMess(true);
      setFlgSee(true);
      const onSuccess = (res) => {
        setLoadingMess(false);
        const uniqueComments = {};
        const uniqueCommentArray = [];
        [...commentDetail, ...(res?.data || [])].forEach((comment) => {
          if (!uniqueComments[comment.id]) {
            uniqueComments[comment.id] = true;
            uniqueCommentArray.push(comment);
          }
        });
        if (res?.data?.length === 0) {
          setFlgSeeData(true);
        }
        setCommentDetail(uniqueCommentArray);
        setPaginationComment(res?.pagination || {});
        setTrickReloadComment(null);
        setStatusEditComment(null);
      };
      const onFailed = () => {};
      dispatch(
        getListCommentDetail({
          id,
          page: nextPage,
          limit: itemsPerPage,
          onSuccess,
          onFailed,
          adminToken,
        })
      );
    };

    useEffect(() => {
      if (renderFolowCMT?.id) {
        const updateFollow = (idUser, list) => {
          for (let i = 0; i < list.length; i++) {
            // Check if the idUser matches the provided idUser
            if (list[i].user_id === idUser) {
              // Toggle the value of is_followed (1 to 0 or 0 to 1)
              list[i].is_followed = list[i].is_followed === 1 ? 0 : 1;
            }
          }
          return list;
        };
        const updatedComments = updateFollow(renderFolowCMT?.id, commentDetail);
        setCommentDetail(updatedComments);
      }
    }, [renderFolowCMT?.flg]);

    useEffect(() => {
      // getCommentList();
      // Get first
      if (count === 1) {
        if (defaultFetch === 1) {
          if (dataPost?.latest_comment) {
            const dataCommentFirst = (data) => ({
              id: data?.id,
              parent_id: data?.parent_id,
              content: data?.content,
              number_like: data?.number_like,
              number_comment: data?.number_comment,
              is_edited: data?.is_edited,
              is_hidden: data?.is_hidden,
              post_id: data?.post_id,
              user_id: data?.user_id,
              created_at: data?.created_at,
              updated_at: data?.updated_at,
              deleted_at: data?.deleted_at,
              is_liked: data?.is_liked,
              is_followed: data?.is_followed,
              avatar: data?.avatar,
              account_id: data?.account_id,
              account_name: data?.account_name,
            });
            setCount(2);
            if (Object.keys(dataPost?.latest_comment || {}).length !== 0) {
              setCommentDetail([dataCommentFirst(dataPost?.latest_comment) || {}]);
            } else {
              setCommentDetail([]);
            }
            const trickPagination = {
              total_pages: 1,
              current_page: 0,
            };
            if(dataPost?.isReadmore) {
              setPaginationComment(dataPost?.number_comment >= 1 ? trickPagination : {});
            } else {
              setPaginationComment(dataPost?.number_comment > 1 ? trickPagination : {});
            }
            // setPaginationComment(
            //   Boolean(
            //     dataPost?.number_comment > 1 &&
            //       dataPost?.number_comment - dataPost?.latest_comment?.number_comment > 1
            //   )
            //     ? trickPagination
            //     : {}
            // );
          }
        } else {
          getCommentList();
        }
      }

      setLoadingMess(false);
      setTrickReloadComment(null);
      setStatusEditComment(null);
    }, []);

    //render readtime update status like timeline, homepage, bookmark
    useEffect(() => {
      if (count === 2) {
        if (dataPost?.latest_comment) {
          const dataCommentFirst = (data) => ({
            id: data?.id,
            parent_id: data?.parent_id,
            content: data?.content,
            number_like: data?.number_like,
            number_comment: data?.number_comment,
            is_hidden: data?.is_hidden,
            is_edited: data?.is_edited,
            post_id: data?.post_id,
            user_id: data?.user_id,
            created_at: data?.created_at,
            updated_at: data?.updated_at,
            deleted_at: data?.deleted_at,
            is_liked: data?.is_liked,
            is_followed: data?.is_followed,
            avatar: data?.avatar,
            account_id: data?.account_id,
            account_name: data?.account_name,
          });

          if (
            ((renderLikeCMTTimeline?.id && pages === 'timeline') ||
              (renderLikeCMTHomepage?.id && pages === 'homepage') ||
              (renderLikeCMTBookmark?.id && pages === 'bookmark')) &&
            (!renderLikeCMTTimeline?.flgDel && !renderLikeCMTHomepage?.flgDel && !renderLikeCMTBookmark?.flgDel)
          ) {
            setCommentDetail([dataCommentFirst(dataPost?.latest_comment) || {}]);
          }
        }
      }
    }, [
      renderLikeCMTTimeline,
      renderLikeCMTHomepage,
      renderLikeCMTBookmark,
      dataPost?.latest_comment,
      count,
    ]);

    //render update data cmt after delete post detail
    useEffect(() => {
      if (count === 2) {
        const dataCommentFirst = (data) => ({
          id: data?.id,
          parent_id: data?.parent_id,
          content: data?.content,
          number_like: data?.number_like,
          number_comment: data?.number_comment,
          is_hidden: data?.is_hidden,
          is_edited: data?.is_edited,
          post_id: data?.post_id,
          user_id: data?.user_id,
          created_at: data?.created_at,
          updated_at: data?.updated_at,
          deleted_at: data?.deleted_at,
          is_liked: data?.is_liked,
          is_followed: data?.is_followed,
          avatar: data?.avatar,
          account_id: data?.account_id,
          account_name: data?.account_name,
        });

        if (
          ((renderLikeCMTTimeline?.id && pages === 'timeline') ||
            (renderLikeCMTHomepage?.id && pages === 'homepage') ||
            (renderLikeCMTBookmark?.id && pages === 'bookmark')) &&
          (renderLikeCMTTimeline?.flgDel || renderLikeCMTHomepage?.flgDel || renderLikeCMTBookmark?.flgDel)
        ) {
          if (Object.keys(dataPost?.latest_comment || {}).length !== 0) {
            setCommentDetail([dataCommentFirst(dataPost?.latest_comment) || {}]);
          } else {
            setCommentDetail([]);
          }
          setCountNumberView((prev) => ({
            ...prev,
            countNumberComment: dataPost?.number_comment || 0,
          }));
          const trickPagination = {
            total_pages: 1,
            current_page: 0,
          };
          setPaginationComment(dataPost?.number_comment >= 1 ? trickPagination : {});
        }
      }
    }, [
      renderLikeCMTTimeline,
      renderLikeCMTHomepage,
      renderLikeCMTBookmark,
      dataPost?.latest_comment,
      count,
    ]);

    const loadMoreComments = () => {
      const cacuPage = Math.ceil((commentDetail.length + 1) / itemsPerPage);
      setVisibleComments((prev) => commentDetail.length + 10);
      setPage(cacuPage);
      getCommentListMore({ nextPage: cacuPage });
    };
    //  Trick reset comment
    useEffect(() => {
      if (`${trickReloadComment?.id}` === `${id}`) {
        const newComment = trickReloadComment?.data || [];
        setCommentDetail((prev) => [newComment, ...prev]);
        setCountNumberView((prev) => ({
          ...prev,
          countNumberComment: trickReloadComment?.data?.post?.number_comment,
        }));
        setCountComment((prev) => prev + 1);
        setVisibleComments((prev) => prev + 1);
        setTrickReloadComment(null);
      }
    }, [trickReloadComment?.id, commentDetail]);
    const checkDisabledAction = (item) => {
      if (item?.is_active === 0) return true;
      if (isPopupSaleContent) return false;
      if (isAdmin || isAgency) return true;
      if (
        (item?.is_followed === 0 && item?.follow_flag === 1 && dataPost?.is_my_post === 0) ||
        (item?.is_media_display === 0 && dataPost?.is_my_post === 0)
      ) {
        return true;
      }
      return false;
    };

    const [refOutSideThree] = useClickOutSize(() => {
      setIsVisiblePopup(-1);
    });
    const updateCommentLikes = (id) => {
      if (pages === 'timeline') {
        dispatch(resetgetIDCMTTimeline());
      }
      if (pages === 'homepage') {
        dispatch(resetgetIDCMTHomepage());
      }
      if (pages === 'bookmark') {
        dispatch(resetgetIDCMTBookmark());
      }
      const updatedComments = commentDetail.map((comment) => {
        if (comment.id === id) {
          comment.is_liked = comment.is_liked === 0 ? 1 : 0;
          comment.number_like =
            comment.is_liked === 1 ? comment.number_like + 1 : comment.number_like - 1;
        }
        return comment;
      });
      setCommentDetail(updatedComments);
    };
    const updateCommentFollow = (id, cb = () => {}) => {
      const updateFollow = (idUser, list) => {
        for (let i = 0; i < list.length; i++) {
          // Check if the idUser matches the provided idUser
          if (list[i].user_id === idUser) {
            // Toggle the value of is_followed (1 to 0 or 0 to 1)
            list[i].is_followed = list[i].is_followed === 1 ? 0 : 1;
            cb(list[i].is_followed === 1 ? 0 : 1);
          }
        }
        return list;
      };
      const updatedComments = updateFollow(id, commentDetail);
      setCommentDetail(updatedComments);
    };

    const updateCommentFollowSub = (id, cb = () => {}) => {
      const updateFollow = (idUser, list) => {
        for (let i = 0; i < list.length; i++) {
          // Check if the idUser matches the provided idUser
          if (list[i].user_id === idUser) {
            // Toggle the value of is_followed (1 to 0 or 0 to 1)
            list[i].is_followed = list[i].is_followed === 1 ? 0 : 1;
            cb(list[i].is_followed === 1 ? 0 : 1);
          }
        }
        return list;
      };
      const updatedComments = updateFollow(id, replyCommentDetail1);
      setReplyCommentDetail1(updatedComments);
    };

    const handleUpdateComment = (newData) => {
      const newArray = updateArrayWithNewData(commentDetail, newData);

      setCommentDetail(newArray);
    };

    const isCreator = useMemo(() => {
      return +dataPost?.user_id === +dataProfile?.id;
    }, [dataPost?.user_id, dataProfile?.id]);

    const checkIsShowComment = useCallback(
      (item_comment) => {
        if (+item_comment?.is_hidden === 1) {
          if (item_comment?.user_id === dataProfile?.id) {
            return true;
          }
          return false;
        }
        return true;
      },
      [dataProfile?.id]
    );
    return (
      <>
        {isVisible && (
          <PopupFanDetail
            closeModal={() => setIsVisible(false)}
            isVisible={isVisible}
            id={idFan}
          />
        )}
        {dataPost && commentDetail.length > 0 && (
          <div className='list_comment_detail block'>
            {ref && <div ref={ref} />}
            <div className='list-comment'>
              <WrapperList>
                {commentDetail.slice(0, visibleComments).map((item_comment) => {
                  return (
                    checkIsShowComment(item_comment) && (
                      <div key={item_comment.id} className='comment-item-wrapper'>
                        <div className='comment-item'>
                          <div
                            className='avatar'
                            onClick={
                              !isAdmin
                                ? () => {
                                    handleTimeline && handleTimeline();
                                    handleClose && handleClose();
                                    dispatch(resetIDHomePage());
                                    dispatch(resetDataInfoOther());
                                    navigate(`${newPathUser}${item_comment?.account_id}`);
                                  }
                                : null
                            }>
                            {!item_comment?.avatar ||
                            decryptPath(item_comment?.avatar) === 'NULL' ? (
                              <img src={AVATAR_DEFAULT} alt='avt'></img>
                            ) : (
                              <ImgCustom
                                isPlaceholderAvatar
                                screen='avatar_44_44'
                                src={`${item_comment?.avatar}`}
                              />
                            )}
                          </div>
                          <div className='content'>
                            {showFormEditComment?.id === item_comment?.id ? (
                              <>
                                <FormEditComment
                                  item_comment={showFormEditComment}
                                  handleUpdateComment={handleUpdateComment}
                                  handleCancelEdit={() => {
                                    setFormEditComment(null);
                                    setFormReplyComment(null);
                                    setIsVisiblePopup(-1);
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <div className='info-comment'>
                                  <div className='left'>
                                    <div className='dp-flex'>
                                      <div
                                        className='name custom_name'
                                        style={{ maxWidth: `${!isMobile && '166px'}` }}
                                        onClick={
                                          !isAdmin
                                            ? () => {
                                                handleTimeline && handleTimeline();
                                                handleClose && handleClose();
                                                dispatch(resetIDHomePage());
                                                dispatch(resetDataInfoOther());
                                                navigate(
                                                  `${newPathUser}${item_comment?.account_id}`
                                                );
                                              }
                                            : null
                                        }>
                                        {item_comment?.account_name}
                                      </div>
                                      <div className='time'>
                                        {convertToCurrentTime(
                                          item_comment?.created_at,
                                          'YYYY/MM/DD HH:mm'
                                        )}
                                      </div>
                                      {item_comment.is_edited === 1 && (
                                        <Edit2Icon className='icon-edited mb-1' />
                                      )}
                                      {/* {item_comment?.user_id === dataProfile?.id && ( */}
                                      <div
                                        className={`box-popup ${
                                          (isAdmin || isAgency) && 'disabled'
                                        }`}>
                                        {checkDisabledAction(dataPost) ? (
                                          <ThreePointIconV3 />
                                        ) : (
                                          <span
                                            className={`btn-three-point ${
                                              (isAdmin || isAgency) && 'disabled'
                                            }`}
                                            ref={refOutSideThree}
                                            onClick={() => {
                                              !checkDisabledAction(dataPost) &&
                                                setIsVisiblePopup(
                                                  isVisiblePopup === item_comment?.id
                                                    ? -1
                                                    : item_comment?.id
                                                );
                                            }}>
                                            {!isAdmin && (
                                              <ThreePointIcon
                                                className='three-point-icon'
                                                onBlur={() => setIsVisiblePopup(-1)}
                                              />
                                            )}
                                          </span>
                                        )}
                                        {isVisiblePopup === item_comment?.id && (
                                          <div
                                            className='popup-edit-comment'
                                            style={{ top: '25px' }}>
                                            {item_comment?.user_id !== dataProfile?.id && (
                                              <FollowCommentUser
                                                {...{
                                                  handleFollowUserComment,
                                                  isAuth,
                                                  setIsVisiblePopup,
                                                  updateCommentFollow,
                                                  item_comment,
                                                  setIsLoadingFollow,
                                                  updateCommentFollowSub,
                                                  setFlg,
                                                }}
                                                callbackFollow={callbackFollow}
                                                type={typeFollow}
                                                dataPost={dataPost}
                                              />
                                            )}
                                            {isCreator &&
                                              item_comment?.user_id !== dataProfile?.id && (
                                                <div
                                                  className='item'
                                                  onMouseDown={() => {
                                                    if (!isAuth) {
                                                      setIsVisiblePopup(-1);
                                                      dispatch(logUserProfile());
                                                    } else {
                                                      setIsVisiblePopup(-1);
                                                      handleHideComment({
                                                        id: item_comment?.id,
                                                        cb: () => {
                                                          const newData = hideItemById(
                                                            commentDetail,
                                                            item_comment?.id
                                                          );
                                                          setCommentDetail(newData);
                                                          dispatch(updateHiddenLastComment(id, item_comment?.id, true))
                                                        },
                                                      });
                                                    }
                                                  }}>
                                                  <HideCommentIcon color='#1A1A1A' />
                                                  <span className='text'>
                                                    {t('Common.hide')}
                                                  </span>
                                                </div>
                                              )}
                                            {item_comment?.user_id !== dataProfile?.id &&
                                              isIdol && (
                                                <div
                                                  className='item'
                                                  onMouseDown={() => {
                                                    if (!isAuth) {
                                                      setIsVisiblePopup(-1);
                                                      dispatch(logUserProfile());
                                                    } else {
                                                      setIsVisiblePopup(-1);
                                                      navigate(`${newPathUser}post-report`, {
                                                        state: {
                                                          fan_id: item_comment?.user_id,
                                                          fan_name: item_comment?.account_name,
                                                          account_id: item_comment?.account_id,
                                                        },
                                                      });
                                                    }
                                                  }}>
                                                  <FlagIcon />
                                                  <span className='text'>
                                                    {t('comment.listComment.iconFlag')}
                                                  </span>
                                                </div>
                                              )}
                                            {item_comment?.user_id === dataProfile?.id && (
                                              <div
                                                className='item item1'
                                                onMouseDown={() => {
                                                  if (!isAuth) {
                                                    dispatch(logUserProfile());
                                                  } else {
                                                    setStatusEditComment(true);
                                                    dispatch(
                                                      getEditCommentDetail({
                                                        id: item_comment?.id,
                                                        onSuccess: ({ data }) => {
                                                          setFormEditComment(data);
                                                        },
                                                      })
                                                    );
                                                  }
                                                  //setIsVisiblePopup(-1);
                                                }}>
                                                <Edit3Icon color='#282727' />
                                                <span className='text'>
                                                  {t('comment.listComment.iconEdit')}
                                                </span>
                                              </div>
                                            )}
                                            {(item_comment?.user_id === dataProfile?.id ||
                                              dataPost?.user_id === dataProfile?.id) && (
                                              <div
                                                className='item'
                                                onMouseDown={() => {
                                                  if (!isAuth) {
                                                    setIsVisiblePopup(-1);
                                                    dispatch(logUserProfile());
                                                  } else {
                                                    setIsVisiblePopup(-1);
                                                    handleDeleteComment({
                                                      id: item_comment?.id,
                                                      type: 'comment',
                                                      postId: id,
                                                      totalComment:
                                                        item_comment?.number_comment + 1,
                                                      cb: () => {
                                                        const newData = deleteItemById(
                                                          commentDetail,
                                                          item_comment?.id
                                                        );
                                                        setCommentDetail(newData);
                                                        setCountComment(
                                                          (prev) =>
                                                            prev -
                                                            (item_comment?.number_comment + 1)
                                                        );
                                                        setCountNumberView((prev) => ({
                                                          ...prev,
                                                          countNumberComment:
                                                            prev.countNumberComment -
                                                            (item_comment?.number_comment + 1),
                                                        }));
                                                      },
                                                    });
                                                  }
                                                }}>
                                                <TrashIcon />
                                                <span className='text'>
                                                  {t('Common.buttonDelete')}
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                      {/* )} */}
                                    </div>

                                    <div
                                      className='d-flex w-100 justify-content-between align-items-end'
                                      // style={{ maxWidth: 'calc(100% - 32px)' }}>
                                      style={{ maxWidth: '100%' }}>
                                      <div
                                        className='comment-content display-linebreak font-size-12 introduce readmore-top-cmt'
                                        style={{
                                          whiteSpace: 'pre-wrap',
                                          margin: '0',
                                          maxWidth: '550px',
                                          width: '100%',
                                        }}>
                                        <MarkDownRead
                                          isMeta
                                          content={item_comment?.content?.toString() || ''}
                                          charLimit={isMobile ? 90 : 160}
                                        />
                                      </div>
                                      {/* {item_comment.is_edited === 1 && (
                                      <Edit3Icon className='icon-edited' />
                                    )} */}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  // className={`like-reply ${item_comment.total_rep_comment > 0 && '0'}`}
                                  className={`like-reply1 4444`}
                                  style={{
                                    pointerEvents: isAdmin || isAgency ? 'none' : 'pointer',
                                    color: isAdmin || isAgency ? '#B1B6BB' : '#787880',
                                    opacity: (isAdmin || isAgency) && 0.4,
                                    cursor: `${
                                      checkDisabledAction(dataPost) ||
                                      dataPost?.enable_comment === 0
                                        ? 'default'
                                        : 'pointer'
                                    }`,
                                  }}>
                                  <span
                                    className={`like `}
                                    onClick={() => {
                                      if (!isAuth) {
                                        dispatch(logUserProfile(dataPost));
                                      } else {
                                        if (!isAdmin) {
                                          if (
                                            !checkDisabledAction(dataPost) &&
                                            dataPost?.enable_comment !== 0
                                          ) {
                                            handleLikeComment(
                                              {
                                                dataPost: item_comment,
                                                commentId: item_comment?.id,
                                                type: 'comment',
                                              },
                                              () => {
                                                updateCommentLikes(item_comment?.id);
                                              }
                                            );
                                          }
                                        }
                                      }
                                    }}>
                                    <HeartIcon
                                      style={{
                                        color:
                                          item_comment?.is_liked === 1
                                            ? '#dc143c'
                                            : checkDisabledAction(dataPost) ||
                                              dataPost?.enable_comment === 0
                                            ? '#BEBEBE'
                                            : '#787880',
                                        cursor: `${
                                          checkDisabledAction(dataPost) ||
                                          dataPost?.enable_comment === 0
                                            ? 'default'
                                            : 'pointer'
                                        }`,
                                      }}
                                      fill={item_comment?.is_liked === 1 ? '#dc143c' : 'none'}
                                      className='cls-icon1 me-2'
                                    />
                                    <p
                                      style={{
                                        color: `${
                                          checkDisabledAction(dataPost) ||
                                          dataPost?.enable_comment === 0
                                            ? '#BEBEBE'
                                            : '#787880'
                                        }`,
                                        cursor: `${
                                          checkDisabledAction(dataPost) ||
                                          dataPost?.enable_comment === 0
                                            ? 'default'
                                            : 'pointer'
                                        }`,
                                        marginBottom: '0px',
                                      }}>
                                      {item_comment?.number_like}
                                    </p>
                                  </span>
                                  <span className={`comment-icon `}>
                                    <CommentIcon
                                      style={{
                                        color: `${
                                          checkDisabledAction(dataPost) ||
                                          dataPost?.enable_comment === 0
                                            ? '#BEBEBE'
                                            : '#787880'
                                        }`,
                                        cursor: `${
                                          checkDisabledAction(dataPost) ||
                                          dataPost?.enable_comment === 0
                                            ? 'default'
                                            : 'pointer'
                                        }`,
                                      }}
                                      className='cls-icon creator_post_live'
                                      onClick={() => {
                                        if (!isAuth) {
                                          dispatch(logUserProfile(dataPost));
                                        } else {
                                          !isAdmin &&
                                            !checkDisabledAction(dataPost) &&
                                            dataPost?.enable_comment !== 0 &&
                                            setFormReplyComment(item_comment?.id);
                                        }
                                      }}
                                    />
                                  </span>
                                </div>
                              </>
                            )}

                            {/* form reply comment */}
                            {showFormReplyComment === item_comment?.id && (
                              <FormAddRepComment
                                addCountComment={addCountComment}
                                item_comment={item_comment}
                                isEnableComment={isEnableComment}
                                setFlgRender1={setFlgRender1}
                                handleOnblur={() => setFormReplyComment(null)}
                                setTrickReply={setTrickReply}
                              />
                            )}

                            {/* list reply comment */}
                            <ListRepComment
                              isPopupSaleContent={isPopupSaleContent}
                              item_comment={item_comment}
                              showFormReplyComment={showFormReplyComment}
                              handleDeleteComment={handleDeleteComment}
                              handleHideComment={handleHideComment}
                              handleLikeComment={handleLikeComment}
                              dataPost={dataPost}
                              isEnableComment={isEnableComment}
                              handleFollowUserComment={handleFollowUserComment}
                              setTrickReply={setTrickReply}
                              trickReply={trickReply}
                              setCountComment={setCountComment}
                              updateCommentFollow={updateCommentFollow}
                              isShowRepComment={{
                                [item_comment?.id]: item_comment?.number_comment > 0,
                              }}
                              commentDetail={commentDetail}
                              setCommentDetail={setCommentDetail}
                              setReplyCommentDetail1={setReplyCommentDetail1}
                              replyCommentDetail1={replyCommentDetail1}
                              flg={flg}
                            />
                          </div>
                        </div>
                      </div>
                    )
                  );
                })}

                {loadingMess && <Loading className='popup-loading' />}
              </WrapperList>
            </div>
          </div>
        )}
        {/* {!isPopup && !flgSee && defaultCount > 0 && commentDetail?.length === 0 && (
          <button className='btn-view-all 2' onClick={loadMoreComments}>
            {t('comment.listRepComment.viewMore')}
          </button>
        )} */}

        {flgSeeData &&
          isPopup &&
          !flgSee &&
          defaultCount > 0 &&
          commentDetail?.length === 0 && (
            <button className='btn-view-all' onClick={loadMoreComments}>
              {t('comment.listRepComment.viewMore')}
            </button>
          )}

        {loadingMess && defaultCount > 0 && commentDetail?.length === 0 && (
          <Loading className='popup-loading' />
        )}
        {paginationComment?.total_pages > paginationComment?.current_page && (
          <button className='btn-view-all' onClick={loadMoreComments}>
            {t('comment.listRepComment.viewMore')}
          </button>
        )}
        {isLoadingFollow && <PopupLoading className={'popup-loading'} />}
      </>
    );
  }
);
