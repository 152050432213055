export default function LockIcon(props) {
  return (
    <svg
      width={24}
      height={28}
      viewBox='0 0 24 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.3635 7.86069V9.90514C21.6599 10.622 23.3332 12.7009 23.3332 15.1839V21.7664C23.3332 24.8404 20.7847 27.3327 17.6428 27.3327H6.35824C3.21498 27.3327 0.666504 24.8404 0.666504 21.7664V15.1839C0.666504 12.7009 2.3411 10.622 4.63622 9.90514V7.86069C4.64977 3.88573 7.94206 0.666016 11.9795 0.666016C16.0712 0.666016 19.3635 3.88573 19.3635 7.86069ZM12.0066 2.98474C14.757 2.98474 16.9925 5.17097 16.9925 7.86069V9.61762H7.00721V7.83419C7.02076 5.15772 9.25626 2.98474 12.0066 2.98474ZM13.1853 19.9393C13.1853 20.5885 12.6569 21.1052 11.9931 21.1052C11.3427 21.1052 10.8143 20.5885 10.8143 19.9393V16.9978C10.8143 16.3618 11.3427 15.8451 11.9931 15.8451C12.6569 15.8451 13.1853 16.3618 13.1853 16.9978V19.9393Z'
        fill='#1480FF'
      />
    </svg>
  );
}
