import { useEffect, useCallback } from 'react';

const useDisableContextMenu = (ref) => {
  const handleClick = useCallback(e => {
    e.preventDefault();
  }, [])

  useEffect(() => {
    const element = ref?.current;
    try {
      if (element && !element.props) {
        element?.addEventListener('contextmenu', handleClick);
      }
    } catch(e) {}
   
    return () => 
      element && !element.props &&
      element?.removeEventListener('contextmenu', handleClick);
  }, [handleClick, ref]);

  useEffect(() => {
    const videos = document.querySelectorAll('video');
    videos?.forEach(video => {
      video?.addEventListener('contextmenu', handleClick)
    })
    return () => {
      videos?.forEach(video => {
        return video?.removeEventListener('contextmenu', handleClick)
      })
    }
  }, []);
};

export default useDisableContextMenu;
