import React from 'react';
import { convertToCurrentTime, decryptPath } from 'utils/utils';
import ImageUserDemo from 'images/images-livestream.jpeg';
import { useNavigate } from 'react-router-dom';

import { NumberViews } from 'components/LiveStream/NumberViews';
import { useTranslation } from 'react-i18next';
import { LOGO_THUMBNAIL_COCOFAN, NO_IMAGE_LIVESTREAM } from 'utils';
import { ImgCustom } from '../../../components';
import UserCountIcon from 'images/UserCountIcon';
import { StyledTimeline } from './styled';
import { newPathUser } from 'constants/layout/constant';
import { useMemo } from 'react';
import { isSafari } from 'react-device-detect';
import { useDispatch } from 'react-redux';
import { setIdScrollLiveDelivery } from 'store/actions/lives';

const Items = ({ liveKey, item, isItemLive, isDataFirst }) => {
  const date = convertToCurrentTime(item?.live_date, 'YYYY/MM/DD HH:mm');
  const url = `/lives/${liveKey}`;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const checkHaveBackground = useMemo(() => {
    const deImg = decryptPath(item?.img_background);
    if (deImg && deImg !== 'NULL') {
      return true;
    }
    return false;
  }, [item?.img_background]);

  const imgBackground = useMemo(() => {
    return checkHaveBackground ? item?.img_background : LOGO_THUMBNAIL_COCOFAN;
  }, [item?.img_background, checkHaveBackground]);

  return (
    <>
      <div id={`live-${item?.id}`} className={`live-stream-item ${isDataFirst ? 'full' : ''}`}>
        {isItemLive ? (
          <>
            {isDataFirst ? (
              <>
                <div
                  onClick={() =>
                    dispatch(
                      setIdScrollLiveDelivery(`live-${item?.id}`, () => {
                        navigate(`${url}`);
                      })
                    )
                  }
                  className={`thumb ${isDataFirst ? 'full' : ''}`}
                  style={
                    item.img_background === 'NULL' || item.img_background === null
                      ? { background: '#EEEEEE' }
                      : {}
                  }>
                  <StyledTimeline.BlueImg
                    url={`${decryptPath(item.img_background, { isMini: true })}`}>
                    <div className='bg-img'></div>
                    {isSafari ? (
                      <img
                        alt={item.live_title}
                        isLogoPlaceholder
                        src={
                          checkHaveBackground
                            ? `${decryptPath(item.img_background)}?width=640&height=auto`
                            : LOGO_THUMBNAIL_COCOFAN
                        }
                        className={`${checkHaveBackground ? '' : 'img-placeholder'}`}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = LOGO_THUMBNAIL_COCOFAN;
                        }}
                      />
                    ) : (
                      <ImgCustom
                        screen={`${checkHaveBackground && 'post_640_auto_pc'}`}
                        alt={item.live_title}
                        isLogoPlaceholder
                        src={imgBackground}
                        className={`${checkHaveBackground ? '' : 'img-placeholder'}`}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = LOGO_THUMBNAIL_COCOFAN;
                        }}
                      />
                    )}
                  </StyledTimeline.BlueImg>
                  <div className='episodes-tags'>
                    <span>{t('Common.live')}</span>
                  </div>
                  <div className='watch-tags'>
                    <span>{t('Creator.ListLiveStream.goLive')}</span>
                  </div>
                  <div className='count-user-watch'>
                    <span>
                      <UserCountIcon />
                    </span>
                    <span className='count'>{<NumberViews id={liveKey} />}</span>
                  </div>
                </div>
                <div className='content-first'>
                  <div className='content-info'>
                    <div className='content-info__left'>
                      <ImgCustom
                        screen='avatar_44_44'
                        isPlaceholderAvatar
                        alt={item.live_title}
                        src={`${item?.avatar}` || ImageUserDemo}
                        onClick={() =>
                          dispatch(
                            setIdScrollLiveDelivery(`live-${item?.id}`, () => {
                              navigate(`${newPathUser}${item?.account_id}`);
                            })
                          )
                        }
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = NO_IMAGE_LIVESTREAM;
                        }}
                      />
                    </div>
                    <div className='content-info__right'>
                      <div className='title'>{item?.live_title}</div>
                      <div className='name'>{item?.account_name}</div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className='thumb'
                  onClick={() =>
                    dispatch(
                      setIdScrollLiveDelivery(`live-${item?.id}`, () => {
                        navigate(`${url}`);
                      })
                    )
                  }
                  style={
                    item.img_background === 'NULL' || item.img_background === null
                      ? { background: '#EEEEEE' }
                      : {}
                  }>
                  <StyledTimeline.BlueImg
                    url={`${decryptPath(item.img_background, { isMini: true })}`}>
                    <div className='bg-img'></div>
                    {isSafari ? (
                      <img
                        alt={item.live_title}
                        isLogoPlaceholder
                        src={
                          checkHaveBackground
                            ? `${decryptPath(item.img_background)}?width=183&height=auto`
                            : LOGO_THUMBNAIL_COCOFAN
                        }
                        className={`${checkHaveBackground ? '' : 'img-placeholder'}`}
                      />
                    ) : (
                      <ImgCustom
                        screen={`${checkHaveBackground && 'post_640_auto_pc'}`}
                        alt={item.live_title}
                        isLogoPlaceholder
                        src={imgBackground}
                        className={`${checkHaveBackground ? '' : 'img-placeholder'}`}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = LOGO_THUMBNAIL_COCOFAN;
                        }}
                      />
                    )}
                  </StyledTimeline.BlueImg>
                  <div className='episodes-tags'>
                    <span>{t('Common.live')}</span>
                  </div>
                  <div className='count-user-watch'>
                    <span>
                      <UserCountIcon />
                    </span>
                    <span className='count'>{<NumberViews id={liveKey} />}</span>
                  </div>
                  {isItemLive && (
                    <div className='episodes-tags'>
                      <span>{t('Common.live')}</span>
                    </div>
                  )}
                </div>
                <div className='content'>
                  <div className={`content-info ${!isDataFirst ? 'full-name' : ''}`}>
                    <div className='content-info__left'>
                      {isSafari ? (
                        <img
                          isPlaceholderAvatar
                          alt={item.live_title}
                          src={
                            checkHaveBackground
                              ? `${decryptPath(item.img_background)}?width=183&height=auto`
                              : LOGO_THUMBNAIL_COCOFAN
                          }
                          onClick={() =>
                            dispatch(
                              setIdScrollLiveDelivery(`live-${item?.id}`, () => {
                                navigate(`${newPathUser}${item?.account_id}`);
                              })
                            )
                          }
                        />
                      ) : (
                        <ImgCustom
                          screen='avatar_44_44'
                          isPlaceholderAvatar
                          alt={item.live_title}
                          src={`${item?.avatar}` || ImageUserDemo}
                          onClick={() =>
                            dispatch(
                              setIdScrollLiveDelivery(`live-${item?.id}`, () => {
                                navigate(`${newPathUser}${item?.account_id}`);
                              })
                            )
                          }
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = LOGO_THUMBNAIL_COCOFAN;
                          }}
                        />
                      )}
                    </div>
                    <div className='content-info__right'>
                      <div className='title'>{item?.live_title}</div>
                      <div className='name'>{item?.account_name}</div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {!isItemLive && (
              <>
                <div
                  className='thumb'
                  style={
                    item.img_background === 'NULL' || item.img_background === null
                      ? { background: '#EEEEEE', cursor: 'default' }
                      : { cursor: 'default' }
                  }>
                  <StyledTimeline.BlueImg
                    url={`${decryptPath(item.img_background, { isMini: true })}`}>
                    <div className='bg-img'></div>
                    <ImgCustom
                      screen={`${checkHaveBackground && 'post_640_auto_pc'}`}
                      alt={item.live_title}
                      isLogoPlaceholder
                      src={imgBackground}
                      className={`${checkHaveBackground ? '' : 'img-placeholder'}`}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = LOGO_THUMBNAIL_COCOFAN;
                      }}
                    />
                  </StyledTimeline.BlueImg>
                </div>
                <div className='content'>
                  <div className='content-info'>
                    <div className='content-info__left'>
                      <ImgCustom
                        screen='avatar_44_44'
                        isPlaceholderAvatar
                        alt={item.live_title}
                        src={`${item?.avatar}` || ImageUserDemo}
                        onClick={() =>
                          dispatch(
                            setIdScrollLiveDelivery(`live-${item?.id}`, () => {
                              navigate(`${newPathUser}${item?.account_id}`);
                            })
                          )
                        }
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = NO_IMAGE_LIVESTREAM;
                        }}
                      />
                    </div>
                    <div className='content-info__right'>
                      <div className='title'>{item?.live_title}</div>
                      <div className='name'>{item?.account_name}</div>
                    </div>
                  </div>
                  <div className='content-date'>
                    {date}~{convertToCurrentTime(item?.period_time_2, 'HH:mm')}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
export default Items;
