//import avatarDemo from 'images/Avatar.png';

import { useEffect, useState } from 'react';
import { ImgCustom, PopupGeneral, Tooltip2 } from '..';
import { formatCoin, itemPerPages } from '../../utils/utils';
import { ProfileIcon } from 'images';
import { NumberViews } from 'components/LiveStream/NumberViews';
import InfiniteLoadingLive from 'components/Paging/InfiniteLoadingLive';

import { useDispatch, useSelector } from 'react-redux';
import { getTopDonate, resetDataTopDonate } from 'store/actions/lives';

import { AVATAR_DEFAULT } from 'utils/constant';
import Loading from '../Paging/Loading';

import lodash from 'lodash';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import CoinRankIcon from 'images/rank/CoinRank';
import Top1Icon from 'images/rank/Top1Icon';
import Top2Icon from 'images/rank/Top2Icon';
import Top3Icon from 'images/rank/Top3Icon';

export const WrapperItemLiveRankFan = styled.div`
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  overflow: hidden;
  .item {
    .info-user {
      .ranking {
        font-weight: 400;
        font-size: 14px;
        color: #282727;
      }
      img.img-custom.avatar {
        min-height: 56px !important;
        min-width: 56px !important;
        max-height: 56px !important;
        max-width: 56px !important;
      }
      .name .text {
        font-weight: 700;
        font-size: 14px;
        color: #282727;
      }
    }
    .box-coin {
      font-weight: 500;
      font-size: 14px;
      .number-coin {
        color: #282727 !important;
      }
      .text-coin {
        color: #666565;
      }
    }
  }
`;

const PopupFanRanking = ({ data, liveKey, isMobile = false, ...props }) => {
  let { loadingTopDonate, dataTopDonate } = useSelector((state) => state.lives);

  const [isVisiblePopUp, setIsVisiblePopUp] = useState(false);
  const { t } = useTranslation();

  const [pageTopDonate, setPageTopDonate] = useState(1);
  const itemPerPage = itemPerPages;
  const dispatch = useDispatch();
  const fetchNext = () => {
    setPageTopDonate(pageTopDonate + 1);
  };

  useEffect(() => {
    // dispatch(getTopDonate(liveKey));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (pageTopDonate > 1) {
      dispatch(getTopDonate(liveKey, pageTopDonate, itemPerPage));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageTopDonate]);

  if (dataTopDonate.length > 0) {
    //const result = lodash.reverse(lodash.uniqBy(lodash.reverse([...dataTopDonate]), 'user_id'));
    dataTopDonate = lodash.uniqBy(dataTopDonate, 'user_id');
  }

  return (
    <>
      <div
        className='number-fan'
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          setPageTopDonate(1);
          dispatch(resetDataTopDonate());
          dispatch(getTopDonate(liveKey, 1, itemPerPage));
          setIsVisiblePopUp(true);
        }}
        {...props}>
        <ProfileIcon className='profile-icon' />
        <span className='number'>
          <NumberViews id={liveKey} />
        </span>
      </div>

      {isVisiblePopUp && (
        <PopupGeneral
          title={t('Popup.ranking')}
          generalClass='pp-up-fan-views'
          className={`popup-fan-ranking`}
          closeModal={() => {
            setIsVisiblePopUp(false);
            document.body.style.overflow = 'visible';
          }}>
          <InfiniteLoadingLive
            style={{
              maxHeight: `${isMobile ? '505px' : '706px'}`,
            }}
            loading={loadingTopDonate}
            data={dataTopDonate}
            fetchNext={fetchNext}>
            {loadingTopDonate ? <Loading /> : null}
            {!loadingTopDonate && !dataTopDonate.length && (
              <p
                className=''
                style={{
                  fontWeight: 'normal',
                  padding: '50px',
                  textAlign: 'center',
                  color: '#282727',
                }}>
                {t('Popup.noRanking')}
              </p>
            )}
            <WrapperItemLiveRankFan>
              {dataTopDonate.length > 0 &&
                dataTopDonate.map((item, index) => (
                  <div className='item' key={index}>
                    <div className='info-user'>
                      <span className='ranking'>
                        {item?.rank === 1 && <Top1Icon />}
                        {item?.rank === 2 && <Top2Icon />}
                        {item?.rank === 3 && <Top3Icon />}
                        {item?.rank > 3 && item?.rank}
                      </span>
                      <ImgCustom
                        src={item?.avatar === null ? AVATAR_DEFAULT : `${item?.avatar}`}
                        alt=''
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = AVATAR_DEFAULT;
                        }}
                        className='avatar'
                      />
                      <span className='name'>
                        <Tooltip2
                          text={item?.account_name}
                          className={`ranking-name-tooltip-wrapper`}>
                          {item?.account_name}
                        </Tooltip2>
                      </span>
                    </div>
                    <div className='box-coin'>
                      <CoinRankIcon />
                      <span className='number-coin'>{formatCoin(item?.points)}</span>
                      <span className='text-coin'>{t('Common.point')}</span>
                    </div>
                  </div>
                ))}
            </WrapperItemLiveRankFan>
          </InfiniteLoadingLive>
        </PopupGeneral>
      )}
    </>
  );
};

export default PopupFanRanking;
