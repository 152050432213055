import styled, { css, keyframes } from 'styled-components';

const animation = keyframes`
0% {
  width: 0;
}
100% {
  width: 100%;
}
`;

export const LoadingProgress = styled.div`
  position: absolute;
  z-index: 50;
  height: 4px;
  top: 8px;
  width: calc(100% - 64px);
  right: 32px;
  box-shadow: inset 0px 0px 0px 1px #c8c8cd;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 20px;
  .progress-bar {
    display: flex;
    height: 100%;
    width: ${({ progress }) => progress}%;
    background: white;
    transition: width 0s;
  }
`;

export const LayerProgress = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 125px;
  z-index: 10000;
  border-radius: 20px 20px 0px 0px;
  @media screen and (max-width: 768px) {
    border-radius: 0px;
  }
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
`;

export const LayerWrapClickVideo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  border-radius: 20px;
`;

export const LockScreen = styled.div`
  position: absolute;
  inset: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border-radius: 20px;
  overflow: hidden;
  .screen-blur {
    position: absolute;
    display: block;
    margin: auto;
    width: 100%;
    height: 100%;
    z-index: 17;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(20px);
    @media screen and (max-width: 991px) {
      border-radius: 0px;
    }
  }
  .text-follow {
    color: #000000;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    z-index: 50;
  }

  button {
    background-color: #dc143c;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 11px 24px;
    border-radius: 8px;
    font-size: 12px;
    z-index: 50;
    &:hover {
      background-color: #CE1338 !important;
    }
  }
  @media screen and (max-width: 575px) {
    border-radius: 0px;
  }
`;

export const PlaceHolderItem = styled.div`
  width: 100%;
  height: 125px;
  z-index: 5;
  border-radius: 20px 20px 0px 0px;
  @media screen and (max-width: 1023px) {
    border-radius: 0px;
  }
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
`;
