/* eslint-disable react-hooks/exhaustive-deps */
import { getDisplayName } from 'pages/creator/layout/create-livestream';
import { createContext, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

export const CreatorProfileContext = createContext({});

const initialFormState = {
  tag_id: [],
  images: [],
};

export function CreatorProfileProvider({ children }) {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    getValues,
    reset,
  } = useForm({
    mode: 'all',
    defaultValues: initialFormState,
  });
  const userState = useSelector((state) => state.users);

  useEffect(() => {
    if (userState.data) {
      const { username, description, text, user_images, search_words, tag_id } =
        userState.data;
      reset({
        username,
        description,
        text,
        // images: JSON.parse(images),
        user_images,
        search_words,
        tag_id,
      });
    }
  }, [userState.data]);

  const state = {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    errors,
    setValue,
    setError,
    clearErrors,
    getValues,
  };
  return (
    <CreatorProfileContext.Provider value={state}>{children}</CreatorProfileContext.Provider>
  );
}

export const useCreatorProfile = () => useContext(CreatorProfileContext);

export default function withCreatorProfilePage(WrappedComponent) {
  const WithCreatorProfilePage = (props) => {
    return (
      <CreatorProfileProvider>
        <WrappedComponent {...props} />
      </CreatorProfileProvider>
    );
  };
  WithCreatorProfilePage.displayName = `WithCreatorProfileProvider${getDisplayName(
    WrappedComponent
  )}`;
  return WithCreatorProfilePage;
}
