import {
  ImgCustom,
  LayoutCreatorRight,
  NavbarF025,
} from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import { newPathUser } from 'constants/layout/constant';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fanGetListPackages, getIDSubcribe } from 'store/actions/users';
import { isMappable } from 'utils/utils';
import '../styles.scss';
import DefaultAvatar from 'images/DefaultAvatar.svg';
import { ShimmerPostDetails } from 'react-shimmer-effects';
import NoSubscribedListIcon from 'images/NoSubscribedList';
import { resetIDHomePage } from 'store/actions/usersNew';

export default function Index() {
  const {
    fanListPackages,
    loading,
    fanListPackagesPagination,
    idSubscribe,
    initPageSubscribe
  } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isHoverAvatar, setIsHoverAvatar] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(idSubscribe && initPageSubscribe ? +initPageSubscribe : 1);

  const fetchNext = () => {
    const pages = fanListPackagesPagination?.total / fanListPackagesPagination?.per_page;
    if (pages > page) {
      funCallApi(page + 1);
      setPage(page + 1);
    }
  };

  const funCallApi = useCallback(
    (page) => {
      setIsLoading(true);
      dispatch(fanGetListPackages(page, 10, () => setIsLoading(false)));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page]
  );

  useEffect(() => {
    if (idSubscribe) {
      const idPost = document.getElementById(idSubscribe.id);
      if (idPost) {
        idPost.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [idSubscribe]);

  useLayoutEffect(() => {
    if (initPageSubscribe < 1) {
      setIsLoading(true);
      dispatch(fanGetListPackages(page, 10, () => setIsLoading(false)));
    }
  }, [page]);

  return (
    <>
      <LayoutCreatorRight
        noButtonBack
        titlePage={t('Navbar.F025.tab1')}
        className='sub-purchase-list'>
        <NavbarF025 defaultActive={1} resetPage={() => {}} className='sub-purchase-navbar' />
        <div className='list-page fan-subscriber'>
          <div className='body'>
            <InfiniteLoad
              loading={loading && !!fanListPackages.length}
              data={fanListPackages}
              fetchNext={fetchNext}>
              {isLoading && !fanListPackages.length ? (
                <div className='shimmer-loading'>
                  <ShimmerPostDetails />
                </div>
              ) : (
                <>
                  {isMappable(fanListPackages) ? (
                    <div className='list'>
                      {fanListPackages?.length > 0 &&
                        fanListPackages.map((item, index) => (
                          <div
                            className='package_item'
                            key={index}
                            id={item?.id}
                            onClick={() =>
                              {
                                if (!isHoverAvatar) {
                                  dispatch(resetIDHomePage());
                                  navigate(`${newPathUser}${item?.account_id}`, {
                                    state: { defaultTab: 1 },
                                  })
                                  dispatch(getIDSubcribe({id: item?.id, tab : 1, page: page }))
                                }
                              }
                            }>
                            <div className='name_plan text'>
                              {item?.packages?.map((x) => (
                                <div key={x?.id} className='package_name'>
                                  {x?.title}
                                </div>
                              ))}
                            </div>
                            <div className='user-info'>
                              <ImgCustom
                                isPlaceholderAvatar
                                src={item?.avatar ? `${item?.avatar}` : DefaultAvatar}
                                onClick={() =>  {
                                  dispatch(resetIDHomePage());
                                  dispatch(getIDSubcribe({id: item?.id, tab : 1, page: page }))
                                  navigate(`${newPathUser}${item?.account_id}`)
                                }}
                                onMouseEnter={() => setIsHoverAvatar(true)}
                                onMouseLeave={() => setIsHoverAvatar()}
                                screen={item?.avatar ? 'recommend_top_56_56' : ''}
                                className='user-avatar'
                              />
                              <div className='subscriber-content'>
                                <div className='name_creator text'>
                                  <div>{item?.account_name}</div>
                                  {/* <div className='second-name'>{item?.account_name}</div> */}
                                </div>
                                <div className='name_number_ticket text'>
                                  {t('Subscribed.premiumTicket')} {item?.tickets_count}
                                  {i18n.language === 'en' ? ' ' : ''}
                                  {t('Common.sheet')}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div className='no-result'>
                      <NoSubscribedListIcon />
                      {t('Subscribed.listNull')}
                    </div>
                  )}
                </>
              )}
            </InfiniteLoad>
          </div>
        </div>
      </LayoutCreatorRight>
    </>
  );
}
