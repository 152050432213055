import {
  PaginationTable,
  PopupCopyLink,
  PopupLoading,
  LayoutAffiliator,
  TableNoBorderRadius,
} from 'components';
import { pushNotify } from 'components/Notification';
import { CopyLinkIcon } from 'images';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getListBannerAffiliator } from 'store/actions/affiliator';
import './index.scss';
const Banner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { listBanner = [], pagination, loading } = useSelector((state) => state.affiliator);
  const { data: userProfile } = useSelector((state) => state.users);
  const [popupCopyLink, setPopupCopyLink] = useState(null);
  const textRef = useRef(null);
  const [page, setPage] = useState(1);
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(getListBannerAffiliator({ page: page, limit: 20 }));
  }, [page]);

  const copyLinkFunc = () => {
    navigator.clipboard.writeText(userProfile?.referral_url);
    pushNotify('success', t('Popup.copiedLink'));
  };

  const renderTable = () => {
    return listBanner?.length > 0 ? (
      listBanner.map((item, id) => (
        <tr key={id}>
          <td className='td-title'>{item.title}</td>
          <td className='td-date'>

            {moment.utc(item.start_time).local().format('YYYY/MM/DD')}
          </td>
          <td className='td-date'>{moment.utc(item.end_time).local().format('YYYY/MM/DD')}</td>
          <td className={`tooltip td-link`}>
            <span className='text'>{item?.banner_url}</span>
            <span className='tooltiptext'>{item?.banner_url}</span>
          </td>
          <td className='td-button'>
            <button
              className='btn__action-table'
              onClick={() => navigate(`/affiliator/banners/${item?.id}`)}>
              {t('Affiliator.AF005.detail')}
            </button>
          </td>
        </tr>
      ))
    ) : (
      <div
        style={{
          width: '100%',
          height: `50vh`,
          color: '#B1B6BB',
        }}
        className='d-flex justify-content-center align-items-center'>
        {t('Common.noData')}
      </div>
    );
  };

  return (
    <>
      {popupCopyLink && (
        <PopupCopyLink
          isVisiblePopup={() => setPopupCopyLink(false)}
          notification={true}
          link={userProfile.referral_url}
        />
      )}
      <LayoutAffiliator titlePage={t('Affiliator.AF005.bannerScreen')}>
        <div className={`view-affiliator--home-page`}>
          {loading && <PopupLoading className={'popup-loading'} />}
          <div className='content-box-affiliator'>
            <h1 className='affiliator-referen_url-title'>
              {t('Affiliator.AF005.pleaseUseIt')}
            </h1>
            <div className='affiliator-referen_url' ref={textRef}>
              {userProfile?.referral_url}
              <CopyLinkIcon className='icon_copy_affiliator' onClick={copyLinkFunc} />
            </div>
            <TableNoBorderRadius
              pagination={() => (
                <div className={`pagination-bar ${listBanner?.length === 0 && 'd-none'}`}>
                  <PaginationTable
                    itemsPerPage={20}
                    total={pagination.total}
                    currentPageTable={(e) => setPage(e)}
                  />
                </div>
              )}>
              <thead>
                <tr>
                  <th className='td-title'>{t('Affiliator.AF005.bannerTitle')}</th>
                  <th className='td-date'>{t('Affiliator.AF005.publicationStartDate')}</th>
                  <th className='td-date'>{t('Affiliator.AF005.postingAndDate')}</th>
                  <th className='td-link'>{t('Affiliator.AF005.referralURL')}</th>
                  <th className='td-button'>{t('Affiliator.AF005.action')}</th>
                </tr>
              </thead>
              <tbody>{renderTable()}</tbody>
            </TableNoBorderRadius>
          </div>
        </div>
      </LayoutAffiliator>
    </>
  );
};

export default Banner;
