import Icon from '@ant-design/icons';

const CloseIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='18'
        height='18'
        fill='none'
        viewBox='0 0 18 18'>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeWidth='1.5'
          d='M4.5 13.875l9.711-9.378M4.5 4.5l9.711 9.378'></path>
      </svg>
    )}
    {...props}
  />
);

export default CloseIcon;
