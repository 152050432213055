import Icon from '@ant-design/icons';

const ArrowIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='10.5'
        height='5.5'
        fill='none'
        viewBox='0 0 12 8'>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M11.25 1.375L6 6.625.75 1.375'></path>
      </svg>
    )}
    {...props}
  />
);

export default ArrowIcon;
