import Icon from '@ant-design/icons';

const IconArrowRightPaginate = (props) => (
  <Icon
    component={() => (
      <svg
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M6.78467 3.75L11.6576 9L6.78467 14.25'
          stroke='#1A1A1A'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    )}
    {...props}
  />
);

export default IconArrowRightPaginate;
