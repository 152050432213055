import React from 'react';
import { useParams } from 'react-router-dom';
import Reflv from 'components/Obs/Reflv';

// 'https://50.112.193.176:8888' // 'http://localhost:8888';

export const SERVER_LIVE_STREAM = 'https://fansite-dev-streamsv.bappartners.com'; 

export const Demo = () => {
  const params = useParams();
  const urlLive = SERVER_LIVE_STREAM + '/live/' + params.id + '.flv';

  return (
    <>
      <h2 style={{textAlign:'center'}}> Connect OBS </h2>
      <h3 style={{textAlign:'center'}}> StreamKey :  {params.id || null}</h3>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
            <div style={{width: '710px'}}>
            <Reflv style={{height: 'auto'}} type='flv' url={urlLive} /> 
            </div>
             
        </div>
    </>
  );
};
