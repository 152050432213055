import { Outlet } from 'react-router-dom';
// import { STORAGE } from 'utils/constant';
// import Storage from 'utils/storage';

const SpecialRoutes = () => {
  //const isAuth = Storage.has(STORAGE.USER_ACCESS_TOKEN);
  return <> <Outlet /> </>
};

export default SpecialRoutes;
