import {
  AvatarInfor,
  ButtonBookmark,
  ListImages,
  ReadMoreLess,
  ViewFullImage,
} from 'components';
import useUserRole from 'hooks/useUserRole';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { postBookMarkDetail } from 'store/actions/myPage';
import { convertToCurrentTime, linkS3Video, decryptPath, linkS3VideoM3U8 } from 'utils';
import VideoCustomDetail from 'pages/creator/layout/recording/VideoCustomDetail';

const ContentNormal = ({ detailPost = {} }) => {
  const dispatch = useDispatch();
  const [dataViewFull, setDataViewFull] = useState({});
  const { isIdol } = useUserRole();
  const { t } = useTranslation();
  const handlePostBookMark = () => {
    dispatch(postBookMarkDetail(detailPost?.id));
  };

  return (
    <>
      {dataViewFull?.image?.url && (
        <ViewFullImage dataViewFull={dataViewFull} close={() => setDataViewFull({})} />
      )}
      {detailPost?.medias?.length === 0 && (
        <ButtonBookmark post={detailPost} onClick={handlePostBookMark} />
      )}

      <AvatarInfor
        className='avatar-info-detail'
        images={detailPost?.user?.avatar}
        name={detailPost?.user?.account_name || detailPost?.user?.username}
        content1={`${t('Common.id')}: ${detailPost?.user?.account_id}`}
      />
      <div className='content'>
        <div className='post-meta mb-3'>
          {detailPost?.packages?.length > 0 && (
            <div className='post-meta-item'>
              <span className='text-gray'>{t('PostDetail.browserTarget')}</span>
              {detailPost.all_package ? t('Common.allPlan') : detailPost.package_names}
            </div>
          )}
          <div className='post-meta-item'>
            <span className='text-gray'>{t('PostDetail.publicationPeriod')}</span>
            {convertToCurrentTime(
              detailPost?.public_time || detailPost.created_at,
              'YYYY/MM/DD HH:mm:ss'
            )}
            {detailPost?.unpublic_time &&
              ' ～' + convertToCurrentTime(detailPost?.unpublic_time, 'YYYY/MM/DD HH:mm:ss')}
          </div>
        </div>
        <div className='title display-linebreak'>
          <ReadMoreLess content={detailPost?.title} />
        </div>
        <div className='text'>
          <ReadMoreLess content={detailPost?.content} />
        </div>
      </div>
      <div className='px-16'>
        {detailPost?.medias?.length > 0 && (
          <>
            {detailPost?.medias?.[0].type === 1 ? (
              <>
                {(() => {
                  const data = detailPost?.medias
                    ?.filter((item) => item?.type === 1)
                    .filter((item) => item.public_flag === 1);
                  return (
                    data.length > 0 && (
                      <ListImages
                        images={data}
                        number_bookmark={detailPost?.number_bookmark}
                        id_post={detailPost?.id}
                        bookmark_status={detailPost?.user_bookmark_status}
                        post_type={detailPost?.type}
                        is_idol={isIdol}
                        isPostDetail
                        onClickImage={setDataViewFull}
                      />
                    )
                  );
                })()}
              </>
            ) : detailPost.medias[0].url?.indexOf('.m3u8') !== -1 ? (
              <div className='' style={{ position: 'relative' }}>
                <VideoCustomDetail
                  className='video-detail video-custom img-content-recod'
                  src={`${linkS3VideoM3U8}${decryptPath(detailPost.medias[0].url) + '#t=0.1'}`}
                  isDetailPost
                  handleNavigate={() => {}}
                />
                <ButtonBookmark post={detailPost} onClick={handlePostBookMark} />
              </div>
            ) : (
              <>
                <div className='' style={{ position: 'relative' }}>
                  <video className='video-detail video-custom' controls>
                    <source
                      src={`${linkS3Video}${decryptPath(detailPost.medias[0].url)}#t=0.001`}
                      type='video/mp4'
                    />
                  </video>
                  <ButtonBookmark post={detailPost} onClick={handlePostBookMark} />
                </div>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default ContentNormal;
