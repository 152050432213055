import { LayoutFan, UserItem } from 'components';
import InfiniteLoad from 'components/Paging/InfiniteLoad';
import useLoading from 'hooks/useLoading';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { userFollowing } from 'store/actions/users';
import { isMappable } from 'utils/utils';
import '../styles.scss';

export default function Index() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const isLoading = useLoading();
  const itemsPerPage = 20;
  const { t } = useTranslation();
  const { userListFollowing = [], loading, pagination } = useSelector((state) => state.users);
  const fetchNext = () => {
    const pages = pagination?.total / pagination?.per_page;
    pages > page && setPage(page + 1);
  };

  useEffect(() => {
    dispatch(userFollowing(page, itemsPerPage));
  }, [page]);

  return (
    <LayoutFan titleHeader={t('Follow.title')} loading={isLoading}>
      <div className='list-page'>
        <div className='body'>
          {isMappable(userListFollowing) ? (
            <div className='list'>
              <InfiniteLoad loading={loading} data={userListFollowing} fetchNext={fetchNext}>
                {userListFollowing.map((item, index) => (
                  <UserItem key={index} item={item} isSubscribe={true} />
                ))}
              </InfiniteLoad>
            </div>
          ) : (
            <div className='no-result'>{t('Follow.listNull')}</div>
          )}
        </div>
      </div>
    </LayoutFan>
  );
}
