import Icon from '@ant-design/icons';

const StartNormalIcon = (props) => (
  <Icon
    component={() => (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='16'
        height='16'
        fill='none'
        viewBox='0 0 16 16'>
        <path
          stroke='currentColor'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5'
          d='M8.736 2.785L9.954 5.22c.12.24.35.405.618.443l2.725.392c.673.098.941.914.454 1.381L11.78 9.33a.802.802 0 00-.237.717l.465 2.673c.115.662-.59 1.166-1.191.853L8.382 12.31a.832.832 0 00-.764 0l-2.436 1.263c-.602.313-1.306-.191-1.19-.853l.464-2.673a.802.802 0 00-.236-.717L2.25 7.435c-.488-.467-.22-1.283.453-1.38l2.725-.393a.82.82 0 00.618-.443l1.218-2.434a.826.826 0 011.472 0z'
          clipRule='evenodd'></path>
      </svg>
    )}
    {...props}
  />
);

export default StartNormalIcon;
