export const TERMS = {
  title_page: 'Sharegram Terms of Service',
  desc: `These terms of use (hereinafter referred to as "this agreement") stipulate the conditions for providing this service and the rights and obligations between DRAGON BLADE inc (hereinafter referred to as "our company") and users. When using this service, it is necessary to read the entire text of this agreement and agree to this agreement.`,
  chapter: [
    {
      title: `(Apply)`,
      content: [
        'The purpose of this agreement is to stipulate the terms of provision of this service and the rights and obligations between the Company and the user regarding the use of this service, and it applies to all relationships between the user and the Company regarding the use of this service. will be',
        'In the event that the content of these Terms differs from the rules in the preceding paragraph or other explanations of the Service outside of these Terms, the provisions of these Terms shall take precedence.',
      ],
    },
    {
      title: `(Definition)`,
      content: [
        'The following terms used in these Terms shall have the respective meanings set forth below.',
        '"This service" means a service related to support for interaction between creators and fans named [Sharegram] provided by the Company (the name or content of the service may change for any reason).',
        '"Service Agreement" means the agreement for the use of the Service concluded between the Company and the User on the terms of this Agreement.',
        '"Intellectual property rights" means copyrights, patent rights, utility model rights, design rights, trademark rights and other intellectual property rights (the right to acquire those rights or apply for registration etc. for those rights includes.).',
        '"Posted data" means content (including but not limited to text, images, videos and other data) posted or otherwise transmitted by users using this service.',
        '"This website" means the website operated by the Company whose domain is "share-gram.com" (if the domain or content of the website is changed for any reason, (including the website after such change).',
        '"User" means an individual who has been registered as a user of the Service pursuant to Article 3 (Registration). Users consist of creators and general users.',
        '"Creator" means an individual who is registered as a user of this service based on Article 3 (Registration) and who provides distributed content through this service.',
        '“General Users” means all Users, excluding Creators.',
        '"Distributed Content" means videos, audio (such as music and other sounds), images, photographs, languages (such as comments and scripts), trade names, trademarks, service marks, logos and interactive features distributed by Creators through the Service. , software, indicators, materials, or any other content.',
        '“Fan Club” means a website for creators and general users to interact with, established by creators through the Service.',
        '"Points" refers to payment methods provided by the Company that are used as compensation for viewing or using distributed content by general users.',
      ],
    },
    {
      title: `(User registration)`,
      content: [
        ' Persons who wish to use this service (hereinafter referred to as "Registration Applicants") must agree to comply with these Terms and provide certain information specified by the Company (hereinafter referred to as "Registration Matters") to the Company. By providing it to the Company in the manner specified in , you can apply to the Company for registration of use of this service.',
        `In accordance with our company's standards, we will determine whether or not to register the registration applicant (hereinafter referred to as "registration applicant") who applied for registration based on paragraph 1, and if we approve the registration, The registration applicant will be notified to that effect. The registration of the applicant for registration as a user shall be deemed to have been completed when the Company has given notice under this paragraph.`,
        'Upon completion of the registration stipulated in the preceding paragraph, a service usage agreement will be established between the User and the Company, and the User will be able to use the Service in accordance with these Terms.',
        'We may refuse registration or re-registration if the registration applicant falls under any of the following items, and we are not obligated to disclose the reason.',
        'If all or part of the registration information provided to the Company is false, erroneous, or omitted.',
        'If you are a minor, an adult ward, a person under curatorship, or a person under assistance, and you have not obtained the consent of a legal representative, guardian, curator, or assistant.',
        'Being an anti-social force, etc. (meaning an organized crime group, a member of an organized crime group, a right-wing organization, an anti-social force, or any other person equivalent thereto; the same shall apply hereinafter), or maintaining or operating an anti-social force, etc. through funding or other means Or if the Company determines that there is some sort of interaction or involvement with anti-social forces, etc., such as cooperating with or participating in management',
        'If the Company determines that the person has violated a contract with the Company in the past or is related to such person',
        'If you have received the measures specified in Article 19',
        'In addition, when the Company determines that registration is not appropriate',
      ],
    },
    {
      title: `(Change of Registered Matters)`,
      text: 'If there is any change in the registered items, the user shall notify the Company of the change without delay by the method specified by the Company.',
    },
    {
      title: `(Management of passwords and user IDs)`,
      content: [
        'Users shall, at their own responsibility, properly manage and store passwords and user IDs related to this service, and shall not allow third parties to use them, or lend, transfer, change names, sell, etc. will do.',
        'Users shall be responsible for damages caused by insufficient management of passwords or user IDs, errors in use, use by third parties, etc.',
      ],
    },
    {
      title: `(Functions of this service)`,
      content: [
        'The Company provides Creators with all or part of the following functions in the Service.',
        'Fan club site opening function',
        'Distribution content distribution function',
        'Other functions that the Company deems useful in the Service',
        ' The Company provides general users with all or part of the following functions in the Service.',
        'Viewing function of distributed content',
        'A function to post posted data such as questions, comments, LIKEs and "Likes" on this service',
        'Other functions that the Company deems useful in the Service',
        'The Company may add or change the functions of the Service, and shall not give advance notice unless there is a serious impact on the User.',
      ],
    },
    {
      title: `(Fan club usage contract)`,
      content: [
        'A general user applies for membership in the fan club on this website, and at the time when the creator notifies the general user of their acceptance, a fan club usage contract will be established between the creator and the general user.',
        'By concluding a fan club usage contract, general users agree that they will be obligated to pay the fan club membership fee set for each fan club.',
        'The fee structure and payment method for each fan club shall be separately determined on this website.',
        'Creators shall be able to change all or part of the fan club content or fee structure, etc, or terminate all or part of the fan club in accordance with the method separately specified by the Company.',
      ],
    },
    {
      title: `(Period of fan club usage contract, etc.)`,
      content: [
        'The term of the fan club usage contract shall be until the same day of the month following the day when the general user joins the fan club, and after that, the contract shall be automatically renewed on a monthly basis. However, if there is no calendar day corresponding to the subscription date, the contract period will be until the day before the last day of the month. (Example: If you applied on August 31st, the contract will end on September 29th). In addition, if the member of the fan club (hereinafter referred to as "fan club member") applies for withdrawal, the fan club usage contract will be terminated on the date of application for withdrawal.',
        'Even if you withdraw from the fan club in the middle of the fan club usage contract period, the fan club membership fee will not be calculated on a daily basis, and the fan club member will request a refund of the fan club membership fee for the current month. You can not.',
        'If the payment of the fan club membership fee is delayed (including cases where payment procedures are not completed due to an insufficient balance of points, etc.), the fan club member will automatically be deemed to have withdrawn from the fan club.',
      ],
    },
    {
      title: `(Termination of fan club usage contract, etc. by the Company)`,
      content: [
        'If the Company cancels the user registration of a creator or general user, all contracts between the creator and the fan club member or between the general user and each registered creator will be terminated at that time.',
        'In the case of the preceding paragraph, the fan club membership fee will not be calculated on a per diem basis, and the fan club member will not be able to claim the refund of the fan club membership fee for the current month',
        'Even in the event of the termination of the fan club usage contract under this section, the Company will be exempted from liability as long as the measures are based on this agreement, and in this case, the user cannot claim any damages against the Company.',
        'If the Company cancels the user registration of a specific creator, the Company may delete all fan club sites (including distributed content) established by the creator. Even in this case, as long as the measures are based on these Terms, the Company will be exempted from liability, and the creator cannot claim any damages against the Company.',
      ],
    },
    {
      title: `(Individual content distribution contract)`,
      content: [
        `Separately from the member-only distribution content in the fan club usage contract, the creator sets the price, distribution date and time, and other conditions on the fan club site by a method stipulated separately, and distributes the content (hereinafter referred to as "individual content distribution"). can be done.`,
        'A Registered User may apply to view or use Individual Content Delivery by a method separately determined by the Company, and an Individual Content Delivery Agreement will be established with the relevant Creator.',
        `Creators may change all or part of the contents of individual content distribution or fee structure, etc., or terminate distribution of all or part of such content in accordance with the method separately determined by the Company.`,
        `The provisions of Article 9 shall apply mutatis mutandis to the termination of the Individual Content Distribution Agreement at the Company's discretion.`,
      ],
    },
    {
      title: `(Purchase and handling of points)`,
      content: [
        'General Users shall be able to purchase points by credit card payment, bank account transfer, or any other method specified by the Company as a method of payment for the usage fee of the Service, and use the Service at the rate of 1 point = 1 yen equivalent. increase. In addition, the content and terms of use of points shall be determined separately by the Company and shall comply with those displayed in the Service.',
        'The maximum number of points that a general user can possess is 100,000 points.',
        'General users can use paid points only for 180 days from the date of issuance of paid points. The User may use General Free Points only for 180 days from the date of acquisition or during the number of days separately determined by the Company prior to grant. Unused points that have passed the expiration date will be forfeited and cannot be used thereafter.',
        'The Company will not refund or redeem points for any reason. However, this shall not apply if required by W. In this case, the point refund method shall be determined by the Company in accordance with laws and regulations and shall be notified separately.',
        'The Company will notify the General User of the points earned by the General User, the points used by the General User, and the point balance (hereinafter referred to as the "Point Amount") by a method separately determined by the Company. If a General User has any doubts about the amount of points, the General User shall immediately contact the Company and explain the matter. The final decision on the amount of points shall be made by the Company, and general users shall comply with this.',
        'Points must be used by the general user himself/herself, and cannot be used by a third party other than the general user himself/herself. Even in the case of unauthorized use by a third party, the Company shall not be obliged to return the points used, and shall not be held responsible for any damage caused to general users.',
        'General users shall be responsible for any taxes or incidental expenses that accrue with the acquisition and use of points.',
        'Regardless of the reason, if a general user withdraws from the membership or the general user registration is cancelled, all points held by the general user will be invalidated and cannot be used thereafter.',
      ],
    },
    {
      title: `(Campaign)`,
      text: 'The Company shall be able to grant points free of charge to General Users who have participated in various campaigns in the Service or otherwise performed acts separately designated by the Company.',
    },
    {
      title: `(Settlement and payment application)`,
      content: [
        'Creators have the authority to receive fan club membership fees and individual content distribution contract usage fees (hereinafter referred to as “membership fees, etc.”) from general users on behalf of the Company, and a third party designated by the Company (hereinafter referred to as “ (together with the Company, collectively referred to as the “Substitute Recipient”) shall be authorized to subcontract. When the Substitute Recipient receives Membership Fees from General Users, payment obligations for Membership Fees, etc. borne by General Users under these Terms shall be extinguished.',
        'General users must not pay membership fees directly to creators. General users may not refuse the request from the Company by paying the membership fee directly to the creator.',
        'If the membership fee, etc. paid by a general user to the creator reaches the amount separately determined by the Company during the calculation period separately determined by the Company, the Company will notify the creator of the amount specified by the creator after deducting the transfer fee. Payment shall be made by bank transfer.',
        'In the event that a creator cancels his or her user registration or cancels registration pursuant to the provisions of Article 9, the Company shall be deemed to have waived all membership fees, etc. that may be charged to the creator. Increase.',
      ],
    },
    {
      title: `(Fees for using this service)`,
      content: [
        'The creator shall pay the Company an amount equivalent to 30% of the membership fee, etc. received by the substitute recipient pursuant to the provisions of paragraph 1 of the preceding article and consumption tax (rounded up to the nearest whole number) as consideration for the use of the Service. shall pay for',
        'The Company may deduct the usage fee stipulated in the preceding paragraph from the membership fee, etc. paid to creators based on the provisions of paragraph 3 of the preceding article, instead of receiving the usage fee.',
      ],
    },
    {
      title: `(User Responsibility)`,
      content: [
        'If the user defames another person, violates the right to privacy, discloses personal information of a third party without permission, commits an act that violates the copyright law, or otherwise infringes the rights of a third party. If you do so, you must resolve this at your own responsibility and expense, and the Company will not assume any responsibility.',
        'The Company shall not take any responsibility for the storage, preservation, backup, etc. of the data accumulated or saved in the Service by the User using the Service, except for reasons attributable to the Company.',
      ],
    },
    {
      title: `(Prohibited matter)`,
      text: 'When using this service, users must not engage in any act that falls under any of the following items or any act that the Company determines to fall under any of the items below.',
      content: [
        'An act that violates laws or acts related to a criminal act',
        'Fraudulent or threatening behavior against the Company, other users of the Service, or other third parties',
        'Acts against public order and morals',
        'Acts that infringe on intellectual property rights, portrait rights, privacy rights, honor, or other rights or interests of the Company, other users of the Service, or other third parties',
        {
          title:
            'Transmitting information that falls under the following or that the Company determines to fall under the following through the Service to the Company or other users of the Service:',
          subcontent: [
            'Information containing excessively violent or cruel expressions',
            'Information containing computer viruses or other harmful computer programs',
            'Information that includes expressions that defame the honor or credibility of the Company, other users of the Service, or other third parties',
            'Information containing excessively obscene expressions',
            'Information containing expressions that promote discrimination',
            'Information that includes expressions that encourage suicide or self-harm',
            'Information that includes expressions that encourage the inappropriate use of drugs',
            'Information containing anti-social expressions',
            'Information requesting the dissemination of information to third parties such as chain mail',
            'Information that contains expressions that make others feel uncomfortable',
          ],
        },
        'Acts that place an excessive load on the network or system of this service',
        'Reverse engineering or other analysis of software or other systems provided by the Company',
        'Acts that may interfere with the operation of this service',
        'Unauthorized access to our network or system, etc.',
      ],
      text2: 'Acts of impersonating a third party',
      content2: [
        'Acts of using the ID or password of another user of this service',
        'Publicity, advertisement, solicitation, or sales activities on the Service that are not approved in advance by the Company',
        'Collection of information of other users of this service',
      ],
      text3:
        'Actions that cause disadvantage, damage, or discomfort to the Company, other users of the Service, or other third parties',
      content3: ['Providing benefits to anti-social forces, etc'],
      text4: 'Acts aimed at meeting strangers of the opposite sex',
      content4: [
        'Acts that directly or indirectly cause or facilitate the acts of the preceding items',
        'Trying the actions in the preceding items',
      ],
      text5: 'Other acts that the Company deems inappropriate',
    },
    {
      title: `(Suspension of the Service, etc.)`,
      content: [
        'In any of the following cases, the Company may suspend or suspend the provision of all or part of the Service without prior notice to the User.',
        'In case of urgent inspection or maintenance of the computer system related to this service',
        ' When it becomes impossible to operate this service due to computer or communication line failure, erroneous operation, excessive concentration of access, unauthorized access, hacking, etc.',
        'If the service cannot be operated due to force majeure such as an earthquake, lightning strike, fire, wind and flood damage, power outage, natural disaster, etc.',
        'In addition, when the Company determines that suspension or interruption is necessary.',
        'The Company reserves the right to change the content of the Service or terminate the provision of the Service at its own discretion.',
        'If the Company terminates the provision of this service, the Company shall notify the user in advance in the manner prescribed by the Company.',
        'The Company shall not be liable for any damages incurred by the User due to the suspension of the Service as stipulated in this article, regardless of the reason.',
      ],
    },
    {
      title: `(Attribution of rights)`,
      content: [
        'All intellectual property rights relating to this website and this service belong to our company or those who have granted licenses to this company. It does not imply any license to use the intellectual property rights of those who license us.',
        'Users must confirm that they have the legal rights to post or otherwise transmit posted data or distributed content (hereinafter referred to as "posted data, etc."), and that posted data, etc. is owned by a third party. You represent and warrant to us that you do not infringe any rights.',
        'The User grants to the Company a worldwide, non-exclusive, free-of-charge, sublicensable and transferable license for use, reproduction, distribution, creation of derivative works, display and execution of Posted Data, etc. . In addition, non-exclusive license for other users to use, reproduce, distribute, create derivative works, display and execute posted data etc. posted or transmitted by users using this service Grant',
        `The user agrees not to exercise the author's moral rights against the Company and those who have inherited or have been granted rights from the Company.`,
      ],
    },
    {
      title: `(Cancellation of registration, etc.)`,
      content: [
        'If the user falls under any of the following items, the Company may delete or hide posted data, etc. without prior notice or demand, and temporarily suspend the use of this service by the user. or cancel the registration of the user.',
        'If you violate any of the provisions of these Terms',
        'If it turns out that there is a false fact in the registered items',
        'When payment is suspended or becomes insolvent, or when there is a petition for the commencement of bankruptcy proceedings, civil rehabilitation proceedings, corporate reorganization proceedings, special liquidation, or similar proceedings.',
        'When there is no response for 30 days or more to inquiries from the Company or other communications requesting an answer.',
        'When it falls under each item of Article 3, Paragraph 4',
        'In addition, when the Company determines that the use of this service or the continuation of registration as a user is not appropriate.',
        `If any of the items in the preceding paragraph apply, the User will naturally lose the benefit of time for all debts owed to the Company, and must immediately pay all debts to the Company. it won't work.`,
      ],
    },
    {
      title: `(Withdrawal, etc.)`,
      content: [
        'The User may withdraw from the Service by completing the procedures prescribed by the Company, and the User may cancel his or her registration as a User.',
        'When withdrawing from membership, if the user has any debts owed to the Company, the user will naturally lose the benefit of time for all debts owed to the Company, and immediately pay all debts to the Company. must be done.',
        'The handling of user information after withdrawal shall comply with the provisions of Article 15.',
      ],
    },
    {
      title: `(Processing after termination of contract)`,
      text: 'The Company shall be able to delete all data related to the Service after termination of the contract for any reason.',
    },
    {
      title: `(Disclaimer of Warranty and Disclaimer)`,
      content: [
        `The Company shall ensure that the Service conforms to the User's specific purpose, has the expected functions, commercial value, accuracy, and usefulness, and that the User's use of the Service is subject to applicable laws and regulations or industry associations. We make no guarantees, express or implied, regarding compliance with internal rules, continuous use, or no defects.`,
        `The Company shall be liable for damages incurred by the User in connection with the Service only within the scope of actual and directly normal damages (excluding special damages, lost profits, indirect damages and attorneys' fees). , and the amount of damages shall be up to 10,000 yen.`,
        'The Company does not guarantee that the Service is compatible with all information terminals, and there is a possibility that malfunctions may occur in the operation of the Service due to upgrades, etc. of the OS of the information terminals used to use the Service. The user shall acknowledge in advance that there is The Company does not guarantee that the problem will be resolved by modifying the program, etc. that the Company makes in the event of such a problem.',
        'The user shall be responsible for resolving any transactions, communications, disputes, etc. that arise between the user and other users or third parties in relation to this service or this website.',
      ],
    },
    {
      title: `(Use of external services)`,
      content: [
        'In using this service, if the user uses Facebook, Twitter, or other external services, etc., in addition to this agreement, the terms of use and other conditions stipulated by the external service (if the terms of use, etc. are changed, includes the changed conditions.)',
        'For external services, the company or individual providing the external service shall be responsible, and the Company shall confirm that the external service does not have any errors, bugs, malfunctions or security flaws, and the rights of third parties. does not infringe, has the properties and commercial value expected by users, and complies with applicable laws and regulations or internal rules.',
      ],
    },
    {
      title: `(Confidentiality)`,
      text: 'The User shall handle confidentially any non-public information disclosed by the Company in connection with the Service with the Company requesting the User to keep it confidential, except with the prior written consent of the Company.',
    },
    {
      title: `(Handling of User Information)`,
      content: [
        `The Company's handling of the user's user information shall be in accordance with the separate provisions of the Company's privacy policy (https://share-gram.com/privacy-policy), and the user shall be handled by the Company in accordance with this privacy policy. You agree that.`,
        'The Company may, at its discretion, use and disclose the information, data, etc. provided by the User to the Company as statistical information in a form that cannot identify an individual, and the User may not object to this. Shall not.',
        'If personal information is included in posted data, the user must obtain the consent of the individual to whom the personal information pertains to be included in the posted data. Users shall handle such personal information at their own responsibility and with the care of a good manager, and the Company shall cooperate as much as possible.',
        'Users shall respond to complaints, disputes, etc. arising from the inclusion of personal information in posted data at their own responsibility and expense, and the Company shall not be held responsible for this.',
      ],
    },
    {
      title: `(Changes to these Terms, etc.)`,
      text: `The Company reserves the right to change these Terms if the Company deems it necessary. In the event of any change to this Agreement, we will notify the user of the effective date and content of this Agreement after the change by posting it on this website or other appropriate means. However, in the case of changes to the content that require the user's consent by law, the user's consent shall be obtained in the manner prescribed by the Company.`,
    },
    {
      title: `(Contact/notification)`,
      content: [
        'Inquiries regarding this service and other communications or notices from users to the Company, and notices concerning changes to these Terms and other communications or notices from the Company to users shall be made in accordance with the methods stipulated by the Company.',
        'If the Company communicates or notifies to the e-mail address or other contact information included in the registered items, the User shall be deemed to have received such communication or notification.',
      ],
    },
    {
      title: `(End of use of this website)`,
      content: [
        'The user may terminate the use of this service at any time by deleting the account of this service or by other methods prescribed by the Company. A user who terminates the use of this service will not be able to use this service from the time of said termination.',
        'If the User loses the right to use the Service due to accidental deletion of the account or for any other reason, the User may not use the account, provided content, user content, or other information accumulated in the Service. You agree in advance that it will disappear.',
        'Even after termination of use of the Service, the User shall not be exempt from all obligations and obligations (including, but not limited to, compensation for damages) to the Company and third parties under the Terms of Use.',
        'The Company shall be able to retain and use the information provided by the User to the Company even after the User has finished using the Service.',
        `If the user terminates the use of this service, the Company may, at its discretion, delete the user's account without prior notice, and the user may delete the remaining Website at the time of termination. You agree that any content within may disappear.`,
        `If a user dies, the user's account, user information and other information accumulated on this website will be deleted, and the right to use this service and this website will not be inherited.`,
        'We reserve the right, at our discretion, to delete accounts that have been accessed for more than 6 months without prior notice.',
      ],
    },
    {
      title: `(Transfer, etc. of Status in Service Use Contract)`,
      content: [
        'Users may not assign, transfer, set collateral, or otherwise dispose of their status under the terms of use or rights or obligations under these Terms of Use to a third party without prior written consent of the Company.',
        'In the event that the Company transfers the business related to the Service to another company, the transferee of the business transfer shall transfer the status under the Service Agreement, the rights and obligations under the Terms, the registered items of the User and other customer information to the transferee of the business. and the User shall agree in advance in this section to such transfer. In addition, the business transfer stipulated in this section shall include not only ordinary business transfer but also company splits and other cases where business is transferred.',
      ],
    },
    {
      title: `(Separability)`,
      text: 'Even if any provision or part of these Terms is determined to be invalid or unenforceable under the Consumer Contract Act or other laws and regulations, the remaining provisions and parts of these Terms are invalid or unenforceable. The remainder of the provisions so determined shall continue in full force and effect.',
    },
    {
      title: `(Governing Law and Court of Jurisdiction)`,
      content: [
        'The governing law of this agreement and the service use contract shall be the law of the State of Nevada, USA.',
        'For all disputes arising out of or related to this Agreement or the Service Use Agreement, the Nevada District Court of the United States shall be the exclusive jurisdictional court of first instance.',
      ],
    },
  ],
  starttext: '[Established on 7 april 2023]',
  endtext: '[Established on 7 april 2023]',
};

export const POLICY = {
  title_page: 'Privacy Policy',
  desc: `DRAGON BLADE inc (hereinafter referred to as "our company") handles user information, including personal information about users, in the service provided by our company (hereinafter referred to as "this service") as follows. We will establish a privacy policy (hereinafter referred to as "this policy").`,
  chapter: [
    {
      title: `1. Collected user information and collection method`,
      text: `In this policy, "user information" means information related to user identification, action history on communication services, and other information generated or accumulated in relation to users or users' devices, which is shall mean what we collect.\nUser information collected by the Company in this service is as follows, depending on the method of collection.`,
      content: [
        {
          title: `Information provided by users Information provided by users in order to use this service or through the use of this service is as follows.`,
          subcontent: [
            'Information related to profile such as name, date of birth, gender, occupation, etc. Contact information such as e-mail address, phone number, address, etc.',
            'Information related to payment methods such as credit card information, bank account information, and electronic money information',
            'Information entered or sent by users through input forms or other methods specified by the Company',
          ],
        },
        {
          title: `By allowing users to link with other services when using this service, the information provided by the other service Users may not link with other services such as social networking services when using this service. If you allow it, we will collect the following information from the external service based on the content you agreed to at the time of permission.`,
          subcontent: [
            'ID used by the user for the external service',
            'Other information that the user has permitted to disclose to the partner through the privacy settings of the relevant external service',
          ],
        },
        {
          title: `Information collected by the Company when users use the Service The Company may collect information regarding access to and use of the Service. This includes the following information:`,
          subcontent: [
            'Referrer',
            'IP address',
            'Information about server access logs',
            'Cookies, ADID, IDFA and other identifiers',
          ],
        },
        {
          title: `Information collected by the Company based on the user's individual consent when the user uses this service
          \n
          We will collect the following information from the terminal in use if the user individually agrees by the method specified in 3-1.`,
          subcontent: ['location information'],
        },
      ],
    },
    {
      title: `2. Purpose of use`,
      nodot: true,
      text: 'The specific purposes of use of user information related to the provision of this service are as follows.',
      content: [
        '(1) To provide, maintain, protect, and improve the Service, such as acceptance of registration for the Service, identity verification, user authentication, recording of user settings, and payment calculation of usage fees;',
        '(2) To improve the quality of this service',
        '(3) For user traffic measurement and behavior measurement',
        '(4) To distribute, display and measure the effectiveness of advertisements',
        '(5) To provide guidance and respond to inquiries regarding the Service;',
        '(6) for market research, analysis, marketing and research;',
        '(7) To create statistical data processed into a form that cannot identify individuals',
        '(8) To respond to acts that violate our terms, policies, etc. (hereinafter referred to as "terms, etc.") regarding this service',
        '(9) To notify you of changes to the terms of service, etc.',
      ],
    },
    {
      title: `3. Method of notification/publication or obtaining consent, method of requesting suspension of use`,
      nodot: true,
      content: [
        {
          title:
            '3-1 User consent shall be obtained before the following user information is collected.',
          subcontent: ['location information.'],
        },
        '3-2 The User may request suspension of collection or use of all or part of the User Information by making the prescribed settings for the Service. and stop using it. Depending on the item of user information, the collection or use thereof is a prerequisite for this service, so we will stop collecting or using it only if you withdraw from this service by the method prescribed by our company.',
      ],
    },
    {
      title: `4. Existence of external transmission, third party provision, information collection module`,
      text: 'This service uses Google Analytics to understand the usage status of this service. Google Analytics uses cookies to collect user information. Please refer to the Google Analytics site for the terms of use and privacy policy of Google Analytics. We are not responsible for any damage caused by using the Google Analytics service.',
    },
    {
      title: `5. Provision to Third Parties`,
      nodot: true,
      content: [
        {
          title:
            '5-1 We will not provide personal information of user information to third parties (including those located outside of Japan) without the prior consent of the user. However, this does not apply in the following cases where it is necessary to provide personal information to third parties (including those outside Japan).',
          nodot: true,
          subcontent: [
            '(1) When the Company entrusts all or part of the handling of personal information to the extent necessary to achieve the purpose of use.',
            '(2) When personal information is provided along with business succession due to merger or other reasons.',
            '(3) When personal information is provided to business partners or information collection module providers in accordance with the provisions of paragraph 4.',
            '(4) When it is necessary to cooperate with national institutions, local governments, or persons entrusted by them in carrying out the affairs stipulated by laws and regulations, and the execution of said affairs with the consent of the user If there is a risk of interfering with.',
            '(5) Other cases permitted by the Act on the Protection of Personal Information (hereinafter referred to as the "Personal Information Protection Act") and other laws and regulations.',
          ],
        },
        '5-2 Notwithstanding paragraph 1, for the purpose of improving the quality of the Service, market research, analysis, marketing and research, the Company shall irreversibly and appropriately process user information so that individual users cannot be identified. may be provided to third parties who have transactions with the Company by means of data transmission, magnetic media, on paper, orally, etc., and users shall agree in advance to such third party provision.',
      ],
    },
    {
      title: `6. Disclosure of Personal Information`,
      text: 'When a user requests disclosure of personal information based on the provisions of the Personal Information Protection Law, we will disclose it to the user without delay after confirming that the request is from the user himself/herself. (If the personal information does not exist, we will notify you to that effect.) However, this does not apply if the Company is not obligated to disclose it under the Personal Information Protection Law or other laws and regulations. Please note that we charge a fee (1,000 yen per case) for the disclosure of personal information.',
    },
    {
      title: `7. Correction and suspension of use of personal information`,
      nodot: true,
      content: [
        `7-1 If the user requests (1) correction of the content based on the provisions of the Personal Information Protection Law for the reason that the personal information is not true, and (2) the scope of the purpose of use announced in advance If the user himself/herself requests suspension of use based on the provisions of the Personal Information Protection Act on the grounds that the information is being handled beyond the limits or that the information has been collected by deception or other wrongful means. After confirming the request, we will conduct the necessary investigation without delay, and based on the results, we will correct the content of the personal information or stop using it, and notify the user to that effect. In addition, when we decide not to correct or suspend use, we will notify the user to that effect.`,
        `7-2 If the user requests the deletion of the user's personal information, and if the company determines that it is necessary to comply with the request, the company will confirm that the request is from the user himself/herself. will delete personal information and notify the user to that effect.`,
        '7-3 The provisions of 7-1 and 7-2 do not apply if the Company is not obligated to correct, etc. or suspend use, etc., under the Personal Information Protection Act or other laws and regulations',
      ],
    },
    {
      title: `8. Contact point for inquiries`,
      nodot: true,
      content: [
        'If you have any comments, questions, complaints, or other inquiries regarding the handling of user information, please contact the contact described at the following URL. URL: https://share-gram.com/home-page/help/post',
      ],
    },
    {
      title: `9. Procedures for changing the privacy policy`,
      text: `We will change this policy as necessary. However, in the event that this policy is amended in a manner that requires the consent of the user under laws and regulations, this policy after the amendment shall apply only to users who have consented to the amendment in the manner prescribed by the Company. In addition, when changing this policy, the Company will notify the user of the effective date and content of this policy after the change by displaying it on the company's website or other appropriate methods.`,
    },
    {
      title: `10. Content management policy on the page`,
      content: [
        {
          title: `Enter into a written agreement with third-party users that upload or generate content for the merchants
          website by verified users/content providers. This must include:`,
          subcontent: [
            'Written consent of people depicted.',
            'Identity and age verification of all people depicted.',
          ],
        },
        {
          title: `Only permit content uploads from verified content providers.`,
        },
        {
          title: `Have a process in place to verify the age and identity of users/content providers. This can be a verification video
          or at least a picture of the user holding a valid government issued ID where details are clearly visible. These
          documents must be stored and must be made available upon request from your payment service provider.
          Failure to provide these can lead to fines and assessments from the Schemes.`,
        },
        {
          title: `Review all uploaded content prior to publication to ensure it is not illegal or otherwise in violation of
          Mastercard standards. The content can be AI or manually reviewed.`,
        },
        {
          title: `Media including children is strictly prohibited. This applies to content of both mainstream and adult nature.`,
        },
        {
          title: `Be able to fully control live streaming. This must include:`,
          subcontent: ['Real-time monitoring.', 'Content removal.'],
        },
        {
          title: `Provide a content removal procedure for both copyright and also consensual reasons. This procedure should include:`,
          subcontent: [
            'Complaint process for reporting and review and if warranted, removal of content.',
            'Deletion of content within a 7 day limit.',
            'A monthly report to the aquirer that includes flagged content, complaints and action taken.',
          ],
        },
        {
          title: `To have policies in place that prohibit their site from being used for:`,
          subcontent: ['Human trafficking.', 'Sex trafficking.', 'Sexual abuse.'],
        },
        {
          title: `Provide Mastercard with with credentials allowing access to content behind a paywall or restricted content
          (upon request).`,
          subcontent: [],
        },
      ],
    },
  ],
  time_created: '[Established on 2 June 2023]',
  time_updated: '[Revised on 2 October 2023]',
};

export const TERMS_JP = {
  title_page: 'Sharegram利用規約',
  desc: `本利用規約（以下「本規約」といいます）には、本サービスの提供条件及び DRAGON BLADE inc（以下「当社」といいます）とユーザーの皆様との間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。`,
  chapter: [
    {
      title: `（適用）`,
      content: [
        ' 本規約は、本サービスの提供条件及び本サービスの利用に関する当社とユーザーとの間の権利義務関係を定めることを目的とし、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されます。',
        ' 本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。',
      ],
    },
    {
      title: `（定義）`,
      content: [
        '本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。',
        '「本サービス」とは、当社が提供する【Sharegram】という名称のクリエイター及びファンの交流支援関連サービス（理由の如何を問わずサービスの名称または内容が変更',
        '「サービス利用契約」とは、本規約を契約条件として当社とユーザーの間で締結される、本サービスの利用契約を意味します。',
        '「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。',
        '「投稿データ」とは、ユーザーが本サービスを利用して投稿その他送信するコンテンツ（文章、画像、動画その他のデータを含みますがこれらに限りません。）を意味します。',
        '「本ウェブサイト」とは、そのドメインが「share-gram.com」である、当社が運営するウェブサイト（理由の如何を問わず、当社のウェブサイトのドメインまたは内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。',
        '「ユーザー」とは、第3条（登録）に基づいて本サービスの利用者としての登録がなされた個人を意味します。ユーザーはクリエイター及び一般ユーザーから構成されます。',
        '「クリエイター」とは、第3条（登録）に基づいて本サービスの利用者としての登録がなされた個人のうち、本サービスを通じて配信コンテンツを提供する者を意味します。',
        '「一般ユーザー」とは、ユーザーのうち、クリエイターを除くすべての者を意味します。',
        '「配信コンテンツ」とは、本サービスを通じてクリエイターによって配信される動画、オーディオ（音楽やその他のサウンドなど）、画像、写真、言語（コメントや脚本など）、商号、商標、サービスマーク、ロゴ、インタラクティブ機能、ソフトウェア、指標、素材等の一切のコンテンツを意味します。',
        '「ファンクラブ」とは、クリエイターが本サービスを通じて開設する、クリエイター及び一般ユーザーとの交流サイトを意味します。',
        '「ポイント」とは、当社が提供する、一般ユーザーによる配信コンテンツの視聴　ないし利用の対価として用いられる決済手段をいいます。',
      ],
    },
    {
      title: `（ユーザー登録）`,
      content: [
        ' 本サービスの利用を希望する者（以下「登録希望者」といいます）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録事項」といいます）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。',
        '当社は、当社の基準に従って、第１項に基づいて登録申請を行った登録希望者（以下「登録申請者」といいます）の登録の可否を判断し、当社が登録を認める場合にはその旨を登録申請者に通知します。登録申請者のユーザーとしての登録は、当社が本項の通知を行ったことをもって完了したものとします。',
        '前項に定める登録の完了時に、サービス利用契約がユーザーと当社の間に成立し、ユーザーは本サービスを本規約に従い利用することができるようになります。',
        '当社は、登録申請者が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。',
        '当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合',
        '未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合',
        '反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合',
        '過去当社との契約に違反した者またはその関係者であると当社が判断した場合',
        '第19条に定める措置を受けたことがある場合',
        'その他、登録を適当でないと当社が判断した場合',
      ],
    },
    {
      title: `（登録事項の変更）`,
      text: 'ユーザーは、登録事項に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。',
    },
    {
      title: `（パスワード及びユーザーIDの管理）`,
      content: [
        'ユーザーは、自己の責任において、本サービスに関するパスワード及びユーザーIDを適切に管理及び保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。',
        'パスワードまたはユーザーIDの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任はユーザーが負うものとします。',
      ],
    },
    {
      title: `（本サービスの機能）`,
      content: [
        '当社は、クリエイターに対し、本サービスにおいて、以下の機能の全部又は一部を提供します。',
        'ファンクラブサイト開設機能',
        '配信コンテンツ配信機能',
        'その他当社が本サービスにおいて有用と判断する機能',
        '当社は、一般ユーザーに対して、本サービスにおいて、以下の機能の全部又は一部を提供します。',
        '配信コンテンツの視聴機能',
        '本サービス上に質問、コメント、LIKE及び「いいね」等の投稿データを投稿する機能',
        'その他当社が本サービスにおいて有用と判断する機能',
        '当社は、本サービスの機能を追加変更する場合があるものとし、ユーザーに重大な影響がない限り、事前通知をしないものとします。',
      ],
    },
    {
      title: `（ファンクラブ利用契約）`,
      content: [
        '一般ユーザーが本ウェブサイト上でファンクラブへの入会を申し込み、クリエイターが一般ユーザーに対しこれを承諾する旨の通知をした時点で、クリエイターと一般ユーザーとの間にファンクラブ利用契約が成立します。',
        '一般ユーザーは、ファンクラブ利用契約を締結することにより、ファンクラブ毎に設定されたファンクラブ会費の支払い義務が発生することに同意するものとします。',
        '各ファンクラブの料金体系及び支払方法は、別途本ウェブサイト上において定めるものとします。',
        'クリエイターは、別途当社が定める方法に従い、ファンクラブの内容若しくは料金体系等の全部又は一部の変更を行い、又はファンクラブの全部若しくは一部を終了させることができるものとします。',
      ],
    },
    {
      title: `（ファンクラブ利用契約の期間等）`,
      content: [
        'ファンクラブ利用契約の期間は、一般ユーザーが当該ファンクラブに入会した日の翌月同日までとし、それ以降は１か月単位での自動更新契約とします。ただし、加入日に該当する暦日がない月の場合、月末日の前日までが契約期間となります。（例： 8月31日申し込みの場合、9月29日が契約最終日です）。なお、当該ファンクラブの会員（以下「ファンクラブ会員」といいます）が退会申請を行った場合、当該ファンクラブ利用契約は、退会申請を行った日を以って終了します。',
        'ファンクラブ利用契約の期間の途中で当該ファンクラブを退会した場合であっても、ファンクラブ会費は日割計算とはならず、ファンクラブ会員は、当月分のファンクラブ会費の返還を請求することはできません。',
        'ファンクラブ会費の支払を遅滞した場合（ポイントの残高不足等により決済手続がなされなかった場合を含む）、当該ファンクラブ会員は、自動的に、当該ファンクラブを退会したものとみなされます。',
      ],
    },
    {
      title: `（当社によるファンクラブ利用契約等の終了）`,
      content: [
        '当社がクリエイター又は一般ユーザーのユーザー登録を抹消した場合、当該時点において、当該クリエイターと当該ファンクラブ会員との間の契約又は当該一般ユーザーと各登録クリエイターとの間の契約は全て終了します。',
        '前項の場合もファンクラブ会費は日割計算とはならず、ファンクラブ会員は、当月分のファンクラブ会費の返還を請求することはできません。',
        '本条によるファンクラブ利用契約の終了の場合であっても、本規約に基づく措置である限りにおいて当社は免責され、この場合、ユーザーは、当社に対して一切の損害賠償請求を行うことはできません。',
        '当社が特定のクリエイターのユーザー登録を抹消した場合、当社は、当該クリエイターが開設した全てのファンクラブサイト（配信コンテンツを含む）を削除することができるものとします。この場合でも、本規約に基づく措置である限りにおいて当社は免責され、当該クリエイターは、当社に対して一切の損害賠償請求を行うことはできません。',
      ],
    },
    {
      title: `（個別コンテンツ配信契約）`,
      content: [
        'クリエイターは、ファンクラブ利用契約における会員限定の配信コンテンツとは別に、別途定める方法により、ファンクラブサイト上にて価格・配信日時・その他の条件を設定のうえ、コンテンツ配信（以下「個別コンテンツ配信」といいます）を行うことができます。',
        '登録ユーザーは、当社が別途定める方法により、個別コンテンツ配信の視聴ないし利用を申込むことができ、これにより、当該クリエイターとの間で個別コンテンツ配信契約が成立します。',
        'クリエイターは、別途当社が定める方法に従い、個別コンテンツ配信の内容若しくは料金体系等の全部又は一部の変更を行い、又は当該コンテンツの全部若しくは一部の配信を終了させることができるものとします。',
        '当社判断による個別コンテンツ配信契約の終了については第９条の規定を準用するものとします。',
      ],
    },
    {
      title: `（ポイントの購入及び取扱い）`,
      content: [
        '一般ユーザーは、本サービスの利用料金の支払い方法として、クレジットカード決済、銀行口座振替、その他当社の定める方法によりポイントを購入し、本サービスの利用に際して1ポイント＝1円相当額として利用できるものとします。その他、ポイントの内容および利用条件については、当社が別途定め、本サービスにおいて表示するところに従うものとします。',
        '一般ユーザーが保有することのできるポイントの上限は100,000ポイントとします。',
        '一般ユーザーは、有償ポイントの発行を受けた日から180日間のみ同ポイントを利用することができます。ユーザーは、一般無償ポイントを獲得した日から180日間もしくは付与前に別途当社が定めた日数の期間中にのみ同ポイントを利用することができます。有効期限を過ぎた未使用のポイントは消滅し、その後利用することはできません。',
        '当社は、理由のいかんを問わず、ポイントの払戻し又は換金を行いません。ただし、法令上必要な場合はこの限りではありません。この場合、ポイントの払戻し方法は、法令に従って当社が定め、別途通知するところによるものとします。',
        '当社は、別途当社が定める方法により、一般ユーザーが獲得したポイント、一般ユーザーが使用したポイントおよびポイントの残高（以下「ポイント額」といいます）を、一般ユーザーに告知します。一般ユーザーは、ポイント額に疑義のある場合には、直ちに当社に連絡し、その内容を説明するものとします。ポイント額に関する最終的な決定は当社が行うものとし、一般ユーザーはこれに従うものとします。',
        'ポイントの使用は、一般ユーザー本人が行うものとし、当該一般ユーザー本人以外の第三者が行うことはできません。第三者による不正使用であった場合でも、当社は、使用されたポイントを返還する義務を負わず、一般ユーザーに生じた損害について一切責任を負いません。',
        'ポイントの取得、ポイントの利用にともない、税金や付帯費用が発生する場合には、一般ユーザーがこれらを負担するものとします。',
        '理由のいかんを問わず、一般ユーザーが退会した場合又は一般ユーザー登録が抹消された場合には、当該一般ユーザーが保有するポイントは全て失効し、以後利用することはできないものとします。',
      ],
    },
    {
      title: `（キャンペーン）`,
      text: '当社は、本サービスにおける各種キャンペーンへの参加その他当社が別途指定する行為を行った一般ユーザーに対し、ポイントを無償で付与することができるものとします。',
    },
    {
      title: `（決済及び支払申請）`,
      content: [
        'クリエイターは、当社に対し、一般ユーザーよりファンクラブ会費及び個別コンテンツ配信契約利用料（以下「会費等」といいます）を代理受領する権限並びにかかる受領業務を当社の指定する第三者（以下、当社とあわせて「代理受領者」といいます。）に再委託する権限を付与するものとします。代理受領者が一般ユーザーから会費等を受領することにより、本規約に基づき一般ユーザーが負う会費等の支払債務は消滅するものとします。',
        '一般ユーザーは、会費等を直接にクリエイターに支払ってはなりません。一般ユーザーは、会費等を直接にクリエイターに支払ったことをもって、当社からの請求を拒絶することができないものとします。',
        '当社は、クリエイターに対し、別途当社が定める計算期間中において一般ユーザーが当該クリエイターに支払った会費等が別途当社の定める金額に達した場合には、振込手数料を控除した上で当該クリエイターの指定する口座に振り込む方法によって支払うものとします。',
        'クリエイターが、自らユーザー登録を抹消した場合又は第9条の規定に基づき登録を抹消された場合、当社は、当該クリエイターに対して請求できる会費等の一切を放棄したものとみなすことができるものとします。',
      ],
    },
    {
      title: `（本サービスの利用手数料）`,
      content: [
        'クリエイターは、当社に対し、本サービスの利用の対価として、前条第１項の規定に基づき代理受領者が受領した会費等の３０％に相当する額及び消費税（小数点以下は切り上げます）を当社に対して支払うものとします。',
        '当社は、前条第３項の規定に基づきクリエイターに対して支払う会費等から前項に定める利用手数料を差し引くことにより、利用手数料の受領に代えることができるものとします。',
      ],
    },
    {
      title: `（ユーザーの責任）`,
      content: [
        'ユーザーは、他人の名誉を毀損した場合、プライバシー権を侵害した場合、許諾なく第三者の個人情報を開示した場合、著作権法に違反する行為を行った場合そのほか第三者の権利を侵害した場合には、自己の責任と費用においてこれを解決しなければならず、当社は一切の責任を負いません。',
        '当社は、ユーザーが本サービスを利用して本サービス内に蓄積、保存したデータ等の保管、保存、バックアップ等に関して、当社の責めに帰すべき事由による場合を除き、一切責任を負わないものとします。',
      ],
    },
    {
      title: `（禁止事項）`,
      text: 'ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。',
      content: [
        '法令に違反する行為または犯罪行為に関連する行為',
        '当社、本サービスの他の利用者またはその他の第三者に対する詐欺または脅迫行為',
        '公序良俗に反する行為',
        '当社、本サービスの他の利用者またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為',
        {
          title:
            '本サービスを通じ、以下に該当し、または該当すると当社が判断する情報を当社または本サービスの他の利用者に送信すること',
          subcontent: [
            '過度に暴力的または残虐な表現を含む情報',
            'コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報',
            '当社、本サービスの他の利用者またはその他の第三者の名誉または信用を毀損する表現を含む情報',
            '過度にわいせつな表現を含む情報',
            '差別を助長する表現を含む情報',
            '自殺、自傷行為を助長する表現を含む情報',
            '薬物の不適切な利用を助長する表現を含む情報',
            '反社会的な表現を含む情報',
            'チェーンメール等の第三者への情報の拡散を求める情報',
            '他人に不快感を与える表現を含む情報',
          ],
        },
        '本サービスのネットワークまたはシステム等に過度な負荷をかける行為',
        '当社が提供するソフトウェアその他のシステムに対するリバースエンジニアリングその他の解析行為',
        '本サービスの運営を妨害するおそれのある行為',
        '当社のネットワークまたはシステム等への不正アクセス',
      ],
      text2: '第三者に成りすます行為',
      content2: [
        '本サービスの他の利用者のIDまたはパスワードを利用する行為',
        '当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為',
        '本サービスの他の利用者の情報の収集',
      ],
      text3:
        '当社、本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為',
      content3: ['反社会的勢力等への利益供与'],
      text4: '面識のない異性との出会いを目的とした行為',
      content4: [
        '前各号の行為を直接または間接に惹起し、または容易にする行為',
        '前各号の行為を試みること',
      ],
      text5: 'その他、当社が不適切と判断する行為',
    },
    {
      title: `（本サービスの停止等）`,
      content: [
        '当社は、以下のいずれかに該当する場合には、ユーザーに事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。',
        '本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合',
        'コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合',
        '地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合',
        'その他、当社が停止または中断を必要と判断した場合',
        '当社は、当社の都合により、本サービスの内容を変更し、又は本サービスの提供を終了することができるものとします。',
        '当社が本サービスの提供を終了する場合、当社は当社所定の方法で、ユーザーに事前に通知するものとします。',
        '当社は、本条に定める本サービスの停止等によってユーザーが被った一切の損害について、その理由の如何を問わず賠償する責任を負いません。',
      ],
    },
    {
      title: `（権利帰属）`,
      content: [
        '本ウェブサイト及び本サービスに関する知的財産権は全て当社または当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、本ウェブサイトまたは本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。',
        'ユーザーは、投稿データないし配信コンテンツ（以下「投稿データ等」といいます）について、自らが投稿その他送信することについての適法な権利を有していること、及び投稿データ等が第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。',
        'ユーザーは、投稿データ等について、当社に対し、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与します。また、他のユーザーに対しても、本サービスを利用してユーザーが投稿その他送信した投稿データ等の使用、複製、配布、派生著作物を作成、表示及び実行することについての非独占的なライセンスを付与します。',
        'ユーザーは、当社及び当社から権利を承継しまたは許諾された者に対して著作者人格権を行使しないことに同意するものとします。',
      ],
    },
    {
      title: `（登録抹消等）`,
      content: [
        '当社は、ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、投稿データ等を削除もしくは非表示にし、当該ユーザーについて本サービスの利用を一時的に停止し、または当該ユーザーの登録を抹消することができます。',
        '本規約のいずれかの条項に違反した場合',
        '登録事項に虚偽の事実があることが判明した場合',
        '支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合',
        '当社からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない場合',
        '第3条第4項各号に該当する場合',
        'その他、当社が本サービスの利用またはユーザーとしての登録の継続を適当でないと判断した場合',
        '前項各号のいずれかの事由に該当した場合、ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。',
      ],
    },
    {
      title: `（退会等）`,
      content: [
        'ユーザーは、当社所定の手続の完了により、本サービスから退会することができるものとし、ユーザーの場合は、自己のユーザーとしての登録を抹消することができます。',
        '退会にあたり、当社に対して負っている債務が有る場合は、ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。',
        '退会後の利用者情報の取扱いについては、第15条の規定に従うものとします。',
      ],
    },
    {
      title: `（契約終了後の処理）`,
      text: '当社は、理由の如何を問わず契約終了後、本サービスに係る全てのデータを削除できるものとします。',
    },
    {
      title: `（保証の否認及び免責）`,
      content: [
        '当社は、本サービスがユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、ユーザーによる本サービスの利用がユーザーに適用のある法令または業界団体の内部規則等に適合すること、継続的に利用できること、及び不具合が生じないことについて、明示又は黙示を問わず何ら保証するものではありません。',
        '当社は、本サービスに関してユーザーが被った損害につき、現実かつ直接に発生した通常の損害（特別損害、逸失利益、間接損害及び弁護士費用を除く。）の範囲内でのみ損害賠償責任を負うものとし、かつ損害賠償の額は1万円を上限とするものとします。',
        '当社は、本サービスが全ての情報端末に対応していることを保証するものではなく、本サービスの利用に供する情報端末のOSのバージョンアップ等に伴い、本サービスの動作に不具合が生じる可能性があることにつき、ユーザーはあらかじめ了承するものとします。当社は、かかる不具合が生じた場合に当社が行うプログラムの修正等により、当該不具合が解消されることを保証するものではありません。',
        '本サービスまたは本ウェブサイトに関連してユーザーと他のユーザーまたは第三者との間において生じた取引、連絡、紛争等については、ユーザーが自己の責任によって解決するものとします。',
      ],
    },
    {
      title: `（外部サービスの利用）`,
      content: [
        'ユーザーは、本サービスの利用にあたり、Facebook、Twitterその他の外部サービス等を利用する場合には、本規約のほか、当該外部サービスの定める利用規約その他の条件(当該利用規約等が変更された場合には変更後の条件を含みます。)に従うものとします。',
        '外部サービスについては、当該外部サービスを提供する企業又は個人が責任を負うものとし、当社は、当該外部サービスにつき、エラー、バグ、不具合又はセキュリティ上の欠陥が存しないこと、第三者の権利を侵害しないこと、ユーザーが期待する性質及び商品的価値を有すること、並びにユーザーに適用のある法令又は内部規則等に適合することについて、如何なる保証も行うものではありません。',
      ],
    },
    {
      title: `（秘密保持）`,
      text: 'ユーザーは、本サービスに関連して当社がユーザーに対して秘密に取扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取扱うものとします。',
    },
    {
      title: `（利用者情報の取扱い）`,
      content: [
        '当社によるユーザーの利用者情報の取扱いについては、別途当社プライバシーポリシー（http://.......）の定めによるものとし、ユーザーはこのプライバシーポリシーに従って当社がユーザーの利用者情報を取扱うことについて同意するものとします。',
        '当社は、ユーザーが当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用及び公開することができるものとし、ユーザーはこれに異議を唱えないものとします。',
        'ユーザーは、投稿データに個人情報が含まれる場合、当該個人情報に係る個人に対して投稿データに含まれることについての同意を得なければならないものとします。ユーザーは、当該個人情報について自己の責任で善良なる管理者の注意をもって取り扱うものとし、当社はそれにできるかぎり協力するものとします。',
        'ユーザーは、投稿データに個人情報が含まれていたことに起因するクレーム、紛争等に対して、自己の責任と費用を持って対応するものとし、当社はこれに一切責任を負わないものとします。',
      ],
    },
    {
      title: `（本規約等の変更）`,
      text: '当社は、当社が必要と認めた場合は、本規約を変更できるものとします。本契約を変更する場合、変更後の本規約の施行時期及び内容を本ウェブサイト上での掲示その他の適切な方法により周知し、またはユーザーに通知します。但し、法令上ユーザーの同意が必要となるような内容の変更の場合は、当社所定の方法でユーザーの同意を得るものとします。',
    },
    {
      title: `（連絡／通知）`,
      content: [
        '本サービスに関する問い合わせその他ユーザーから当社に対する連絡または通知、及び本規約の変更に関する通知その他当社からユーザーに対する連絡または通知は、当社の定める方法で行うものとします。',
        '当社が登録事項に含まれるメールアドレスその他の連絡先に連絡または通知を行った場合、ユーザーは当該連絡または通知を受領したものとみなします。',
      ],
    },
    {
      title: `（本ウェブサイトの利用終了）`,
      content: [
        'ユーザーは、本サービスのアカウントの削除その他当社所定の方法により、いつでも本サービスの利用を終了することができます。本サービスの利用を終了したユーザーは、当該終了時点から本サービスを利用することができなくなります。',
        '誤ってアカウントを削除した場合その他理由の如何を問わず、ユーザーが本サービスを利用する権利を失った場合、ユーザーは、アカウント、提供コンテンツユーザーコンテンツその他本サービスに蓄積した情報を利用することができなくなることを、あらかじめ承諾するものとします。',
        'ユーザーは、本サービス利用終了後も、当社及び第三者に対する本利用契約上の一切の義務及び債務(損害賠償を含みますが、これに限りません。)を免れるものではありません。',
        '当社は、ユーザーが本サービスの利用を終了した後も、当該ユーザーが当社に提供した情報を保有・利用することができるものとします。',
        'ユーザーが本サービスの利用を終了した場合、当社は、当社の裁量により、事前に通知することなく当該ユーザーのアカウントを削除することができるものとし、ユーザーは、終了時に残存している本ウェブサイト内の一切のコンテンツが消滅する場合があることに同意するものとします。',
        'ユーザーが死亡した場合、当該ユーザーのアカウント、ユーザー情報その他本ウェブサイトに蓄積した情報は削除されるものとし、本サービス及び本ウェブサイトの利用権は相続の対象とはなりません。',
        '当社は、当社の裁量により、あらかじめ通知することなく、最終のアクセスから6か月以上経過しているアカウントを削除することができるものとします。',
      ],
    },
    {
      title: `（サービス利用契約上の地位の譲渡等）`,
      content: [
        'ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。',
        '当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びにユーザーの登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。',
      ],
    },
    {
      title: `（分離可能性）`,
      text: '本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。',
    },
    {
      title: `（準拠法及び管轄裁判所）`,
      content: [
        '本規約及びサービス利用契約の準拠法はアメリカ合衆国ネヴァダ州法とします。',
        '本規約またはサービス利用契約に起因し、または関連する一切の紛争については、アメリカ合衆国ネヴァダ州地方裁判所を第一審の専属的合意管轄裁判所とします。',
      ],
    },
  ],
  endtext: '【2023年06月02日改定】',
};

export const POLICY_JP = {
  title_page: 'プライバシーポリシー',
  desc: `DRAGON BLADE inc（以下「当社」といいます。）は、当社の提供するサービス（以下「本サービス」といいます。）における、ユーザーについての個人情報を含む利用者情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。`,
  chapter: [
    {
      title: `1.	収集する利用者情報及び収集方法`,
      text: `本ポリシーにおいて、「利用者情報」とは、ユーザーの識別に係る情報、通信サービス上の行動履歴、その他ユーザーまたはユーザーの端末に関連して生成または蓄積された情報であって、本ポリシーに基づき当社が収集するものを意味するものとします。
      本サービスにおいて当社が収集する利用者情報は、その収集方法に応じて、以下のようなものとなります。`,
      content: [
        {
          title: `ユーザーからご提供いただく情報本サービスを利用するために、または本サービスの利用を通じてユーザーからご提供いただく情報は以下のとおりです。`,
          subcontent: [
            '氏名、生年月日、性別、職業等プロフィールに関する情報',
            'メールアドレス、電話番号、住所等連絡先に関する情報',
            'クレジットカード情報、銀行口座情報、電子マネー情報等決済手段に関する情報',
            '入力フォームその他当社が定める方法を通じてユーザーが入力または送信する情報',
          ],
        },
        {
          title: `ユーザーが本サービスの利用において、他のサービスと連携を許可することにより、当該他のサービスからご提供いただく情報ユーザーが、本サービスを利用するにあたり、ソーシャルネットワーキングサービス等の他のサービスとの連携を許可した場合には、その許可の際にご同意いただいた内容に基づき、以下の情報を当該外部サービスから収集します。
         `,
          subcontent: [
            '当該外部サービスでユーザーが利用するID',
            'その他当該外部サービスのプライバシー設定によりユーザーが連携先に開示を認めた情報',
          ],
        },
        {
          title: `ユーザーが本サービスを利用するにあたって、当社が収集する情報当社は、本サービスへのアクセス状況やそのご利用方法に関する情報を収集することがあります。これには以下の情報が含まれます。`,
          subcontent: [
            'リファラ',
            'IPアドレス',
            'サーバーアクセスログに関する情報',
            'Cookie、ADID、IDFAその他の識別子',
          ],
        },
        {
          title: `ユーザーが本サービスを利用するにあたって、当社がユーザーの個別同意に基づいて収集する情報
         当社は、ユーザーが3-1に定める方法により個別に同意した場合、当社は以下の情報を利用中の端末から収集します。`,
          subcontent: ['位置情報'],
        },
      ],
    },
    {
      title: `2.	利用目的`,
      nodot: true,
      text: '本サービスのサービス提供にかかわる利用者情報の具体的な利用目的は以下のとおりです。',
      content: [
        '(1)	本サービスに関する登録の受付、本人確認、ユーザー認証、ユーザー設定の記録、利用料金の決済計算等本サービスの提供、維持、保護及び改善のため',
        '(2)	本サービスの品質向上のため',
        '(3)	ユーザーのトラフィック測定及び行動測定のため',
        '(4)	広告の配信、表示及び効果測定のため',
        '(5)	本サービスに関するご案内、お問い合わせ等への対応のため',
        '(6)	市場調査、分析、マーケティング及び調査研究のため',
        '(7)	個人を識別できない形式に加工した統計データを作成するため',
        '(8)	本サービスに関する当社の規約、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため',
        '(9)	本サービスに関する規約等の変更などを通知するため',
      ],
    },
    {
      title: `3.	通知・公表または同意取得の方法、利用中止要請の方法`,
      nodot: true,
      content: [
        {
          title:
            '3-1	以下の利用者情報については、その収集が行われる前にユーザーの同意を得るものとします。',
          subcontent: ['位置情報'],
        },
        '3-2	ユーザーは、本サービスの所定の設定を行うことにより、利用者情報の全部または一部についてその収集又は利用の停止を求めることができ、この場合、当社は速やかに、当社の定めるところに従い、その利用を停止します。なお利用者情報の項目によっては、その収集または利用が本サービスの前提となるため、当社所定の方法により本サービスを退会した場合に限り、当社はその収集又は利用を停止します。',
      ],
    },
    {
      title: `4.	外部送信、第三者提供、情報収集モジュールの有無`,
      text: '本サービスでは、本サービスの利用状況を把握するためにGoogle Analyticsを利用しています。Google Analyticsは、クッキーを利用して利用者の情報を収集します。Google Analyticsの利用規約及びプライバシーポリシーに関する説明については、Google Analyticsのサイトをご覧ください。なお、Google Analyticsのサービス利用による損害については、当社は責任を負わないものとします',
    },
    {
      title: `5.	第三者提供`,
      nodot: true,
      content: [
        {
          title:
            '5-1	当社は、利用者情報のうち、個人情報については、あらかじめユーザーの同意を得ないで、第三者（日本国外にある者を含みます。）に提供しません。但し、次に掲げる必要があり第三者（日本国外にある者を含みます。）に提供する場合はこの限りではありません。',
          nodot: true,
          subcontent: [
            '(1)	当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合',
            '(2)	合併その他の事由による事業の承継に伴って個人情報が提供される場合',
            '(3)	第4項の定めに従って、提携先または情報収集モジュール提供者へ個人情報が提供される場合',
            '(4)	国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合',
            '(5)	その他、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）その他の法令で認められる場合',
          ],
        },
        '5-2	当社は、第1項にかかわらず、本サービスの品質向上、市場調査、分析、マーケティング及び調査研究を目的とし、ユーザー個人を特定できないよう不可逆的かつ適切な加工を行った利用者情報を、当社と取引のある第三者に対し、データ送信、磁気媒体、紙面及び口頭等の方法により提供する場合があり、ユーザーはかかる第三者提供につき予め同意するものとします。',
      ],
    },
    {
      title: `6.	個人情報の開示`,
      text: '当社は、ユーザーから、個人情報保護法の定めに基づき個人情報の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該個人情報が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、手数料（1件あたり1,000円）を頂戴しておりますので、あらかじめ御了承ください。',
    },
    {
      title: `7.	個人情報の訂正及び利用停止等`,
      nodot: true,
      content: [
        `7-1	当社は、ユーザーから、(1)個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び(2)あらかじめ公表された利用目的の範囲を超えて取扱われているという理由または偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、ユーザーご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をユーザーに通知します。なお、訂正または利用停止を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします。`,
        '7-2当社は、ユーザーから、ユーザーの個人情報について消去を求められた場合、当社が当該請求に応じる必要があると判断した場合は、ユーザーご本人からのご請求であることを確認の上で、個人情報の消去を行い、その旨をユーザーに通知します。',
        '7-3個人情報保護法その他の法令により、当社が訂正等または利用停止等の義務を負わない場合は、7-1および7-2の規定は適用されません。',
      ],
    },
    {
      title: `8.	お問い合わせ窓口`,
      nodot: true,
      content: [
        'ご意見、ご質問、苦情のお申出その他利用者情報の取扱いに関するお問い合わせは、下記のURLに記載する窓口までお願いいたします。',
        'URL:https://share-gram.com/help/post',
      ],
    },
    {
      title: `9.	プライバシーポリシーの変更手続`,
      text: '当社は、必要に応じて、本ポリシーを変更します。但し、法令上ユーザーの同意が必要となるような本ポリシーの変更を行う場合、変更後の本ポリシーは、当社所定の方法で変更に同意したユーザーに対してのみ適用されるものとします。なお、当社は、本ポリシーを変更する場合には、変更後の本ポリシーの施行時期及び内容を当社のウェブサイト上での表示その他の適切な方法により周知し、またはユーザーに通知します。',
    },
    {
      title: `10. ページのコンテンツ管理ポリシー`,
      content: [
        {
          title: `マーチャント向けにコンテンツをアップロードまたは生成するサードパーティ ユーザーと書面による契約を締結する
          検証済みのユーザー/コンテンツ プロバイダーによる Web サイト。 これには以下が含まれている必要があります。`,
          subcontent: [
            '描かれている人々の書面による同意。',
            '描かれているすべての人物の身元と年齢の確認。',
          ],
        },
        {
          title: `確認済みのコンテンツ プロバイダーからのコンテンツのアップロードのみを許可します。`,
        },
        {
          title: `ユーザー/コンテンツ プロバイダーの年齢と身元を確認するプロセスを用意します。 これは検証ビデオになる可能性があります
          または少なくとも、政府発行の有効な ID を持っているユーザーの写真で、詳細がはっきりと見えるもの。 これらは
          文書は保存する必要があり、支払いサービスプロバイダーからの要求に応じて利用できるようにする必要があります。
          これらを提供しないと、スキームからの罰金や評価につながる可能性があります。`,
        },
        {
          title: `アップロードされたすべてのコンテンツを公開前に確認して、違法または違反していないことを確認します。
          マスターカード基準。 コンテンツは AI または手動でレビューできます。`,
        },
        {
          title: `子供を含むメディアは固く禁じられています。 これは、主流のコンテンツと成人向けコンテンツの両方に適用されます。`,
        },
        {
          title: `ライブ ストリーミングを完全に制御できる。 これには以下が含まれている必要があります。`,
          subcontent: ['リアルタイム監視。', 'コンテンツの削除。'],
        },
        {
          title: `著作権と同意の両方の理由から、コンテンツの削除手順を提供します。 この手順には、次のものが含まれている必要があります。`,
          subcontent: [
            '報告と審査、および正当な理由がある場合はコンテンツの削除のための苦情処理。',
            '7 日以内のコンテンツの削除。',
            'フラグが付けられたコンテンツ、苦情、および実行されたアクションを含む、取得者への月次レポート。',
          ],
        },
        {
          title: `サイトが次の目的で使用されることを禁止するポリシーを導入するには`,
          subcontent: ['人身売買。', '性的人身売買。', '性的虐待。'],
        },
        {
          title: `ペイウォールの背後にあるコンテンツまたは制限されたコンテンツへのアクセスを許可する資格情報を Mastercard に提供する
          （要求に応じて）。`,
        },
      ],
    },
  ],
  time_created: '【2023年06月02日制定】',
  time_updated: '【2023年10月02日改定】',
};

export const LAW = [
  {
    title: '事業者名',
    value: 'DRAGON BLADE inc',
  },
  {
    title: '代表者',
    value: 'Lilia Angelica Nishijima Vargas',
  },
  {
    title: '所在地',
    value: '5348 Vegas Dr,Las Vegas,NV USA 89108',
  },
  {
    title: '電話番号',
    value: '424-571-0772',
  },
  {
    title: 'お問い合わせ先',
    value: 'info@sharegram.com',
  },
  {
    title: '利用料金',
    value: '当社サイト上で表示する有償サービスの利用額',
  },
  {
    title: '利用料以外に発生する料金等',
    value:
      'サイトの閲覧、お問い合わせ等の際の電子メールの送受信時などの通信料等はお客様のご負担となります。詳細はお客様各自で各会社にお問い合わせください。',
  },
  {
    title: 'お支払い方法',
    value: 'クレジットカード',
  },
  {
    title: '支払時期',
    value:
      'ご利用のクレジットカードの締め日や契約内容により異なります。ご利用されるカード会社までお問い合わせください。',
  },
  {
    title: '引渡時期',
    value: '当社所定の手続き終了後、直ちにご利用いただけます。',
  },
  {
    title: '返品についての特約事項',
    value: '電子商品としての性質上、返品には応じられません。',
  },
  {
    title: '動作環境',
    value: 'OS・ブラウザについて',
  },
  {
    title: 'PCの場合',
    value: [
      { title: '■WindowsOSをご利用の方', value: '•	・Google Chrome（最新版）' },
      { title: '■MacOSをご利用の方', value: '•	・Safari（最新版）' },
    ],
  },
  {
    title: 'スマートフォンの場合',
    value: [
      {
        title: '■iPhone/iPod touch',
        value: '•	・それぞれ最新のOS、標準ブラウザ（Safari 最新版）',
      },
      { title: '■Android', value: '•	・最新のOS、Google Chrome（最新版）' },
    ],
  },
];

export const LAW_EN = [
  {
    title: 'Business name',
    value: 'DRAGON BLADE inc',
  },
  {
    title: 'Representative',
    value: 'Lilia Angelica Nishijima Vargas',
  },
  {
    title: 'Location',
    value: '5348 Vegas Dr,Las Vegas,NV USA 89108',
  },
  {
    title: 'Telephone number',
    value: '424-571-0772',
  },
  {
    title: 'Contact information',
    value: 'info@sharegram.com',
  },
  {
    title: 'Usage fee',
    value: 'Amount used for paid services displayed on our website',
  },
  {
    title: 'Charges other than the usage fee, etc.',
    value:
      'Customers are responsible for communication charges, etc., for sending and receiving e-mails when browsing the site, making inquiries, etc. Please contact each company for details.',
  },
  {
    title: 'Payment method',
    value: 'Credit card',
  },
  {
    title: 'Payment period',
    value:
      'It depends on the closing date of your credit card and contract details. Please contact your card company.',
  },
  {
    title: 'Delivery time',
    value:
      'You can use it immediately after completing the procedure prescribed by our company.',
  },
  {
    title: 'Special terms for returns',
    value: 'Due to the nature of electronic products, returns are not accepted.',
  },
  {
    title: 'Operating environment',
    value: 'OS/Browser',
  },
  {
    title: 'For PC',
    value: [
      { title: '■WindowsOS users', value: '•	Google Chrome (Latest edition)' },
      { title: '■MacOS users', value: '•	Safari (Latest edition）' },
    ],
  },
  {
    title: 'For smartphone',
    value: [
      {
        title: '■iPhone/iPod touch',
        value: '•	The latest OS and standard browser (Safari latest version)',
      },
      { title: '■Android', value: '•	Latest OS, Google Chrome (latest version)' },
    ],
  },
];

export const COMPLIANCE_NOTICE = {
  title: '18 U.S.C. SECTION 2257 COMPLIANCE NOTICE',
  title_page: 'Compliance Notice',
  desc: `All models, actors, actresses and other persons that appear in any visual depiction of actual sexually explicit conduct appearing or otherwise contained in this Website were over the age of eighteen years at the time of the creation of such depictions. With respect to all visual depictions displayed on this website, whether of actual sexually explicit conduct, simulated sexual content or otherwise, all persons in said visual depictions were at least 18 years of age when said visual depictions were created. The owners and operators of this Website are not the primary producer (as that term is defined in 18 USC section 2257) of any of the visual content contained in the Website. All pictures, graphics, videos or other visual content produced for, provided by and explicitly displayed under and/or with the Sharegram brand are in compliance with United States Code, Title 18, Section 2257, all models, actors, actresses and other persons who appear on in any visual depiction of actual sexually explicit conduct appearing or otherwise contained in or at the Sharegram site were over the age of eighteen years at the time of the creation of such depictions and they appear here exclusively in roles depicting them as adults. Please direct questions pertaining to content on this website to: `,
  link: 'info@sharegram.com',
  endtext1: 'Dragon Blade',
  endtext2: '5348 Vegas DR Las Vegas NV USA',
};

export const COMPLIANCE_NOTICE_JP = {
  title_page: 'Compliance Notice',
  title: '18 U.S.C. SECTION 2257 COMPLIANCE NOTICE',
  desc: `All models, actors, actresses and other persons that appear in any visual depiction of actual sexually explicit conduct appearing or otherwise contained in this Website were over the age of eighteen years at the time of the creation of such depictions. With respect to all visual depictions displayed on this website, whether of actual sexually explicit conduct, simulated sexual content or otherwise, all persons in said visual depictions were at least 18 years of age when said visual depictions were created. The owners and operators of this Website are not the primary producer (as that term is defined in 18 USC section 2257) of any of the visual content contained in the Website. All pictures, graphics, videos or other visual content produced for, provided by and explicitly displayed under and/or with the Sharegram brand are in compliance with United States Code, Title 18, Section 2257, all models, actors, actresses and other persons who appear on in any visual depiction of actual sexually explicit conduct appearing or otherwise contained in or at the Sharegram site were over the age of eighteen years at the time of the creation of such depictions and they appear here exclusively in roles depicting them as adults. Please direct questions pertaining to content on this website to: `,
  link: 'info@sharegram.com',
  endtext1: 'Dragon Blade',
  endtext2: '5348 Vegas DR Las Vegas NV USA',
};
