import { PopupGeneral } from 'components';
import { newPathUser } from 'constants/layout/constant';
import FailIcon from 'images/FailIcon';
import LoadingIconV2 from 'images/LoadingIconV2';
import SuccessRegisterIcon from 'images/SuccessRegisterIcon';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { cancelPaymentPonitVendo, updateStatusPaymentVendo } from 'store/actions/payment';
import { PAYMENT_CONST } from 'utils';
import Storage from 'utils/storage';
import './index.scss';
import PopupListCardSub from './listCard';
import { PopupCreateAndUpdate } from './popupCreateAndUpdate';
import { PopupPaymentCard } from './popupPayment';
import { clearActiveCard, clearCards } from 'store/actions/card';
import { resetDataInfoOther, resetIDHomePage } from 'store/actions/usersNew';
import { getUserInfoMe } from 'store/actions/users';

const PopupPaymentCardComponent = ({
  isShowPopupListCard,
  setIsShowPopupListCard,
  idPackage,
  isShowModalOTP,
  setIsShowModalOTP,
  urlRedirectPayment,
  isShowPopupCreateAndUpdateCard,
  setIsShowPopupCreateAndUpdateCard,
  isShowPopupPayment,
  setIsShowPopupPayment,
  onClickSuccess,
  itemSub,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    loadingRequestPoint,
    isLoadingPaymentVendo,
    paymentVendoSuccess,
    paymentVendoFail,
    messageErrorVendo,
    isLoadingVerifyToken,
  } = useSelector((state) => state.payment);

  const [codeBySub, setBySub] = useState(Storage.get(PAYMENT_CONST.CODE_SUB));

  useEffect(() => {
    setBySub(Storage.get(PAYMENT_CONST.CODE_SUB));
  }, [Storage.get(PAYMENT_CONST.CODE_SUB)]);

  const resetAll = (isClearCards) => {
    if (isClearCards) {
      dispatch(clearCards());
      dispatch(clearActiveCard());
    }
    dispatch(updateStatusPaymentVendo());
    Storage.remove(PAYMENT_CONST.VERIFY_VENDO);
    Storage.remove(PAYMENT_CONST.CODE_ROLLBACK);
    Storage.remove(PAYMENT_CONST.CREDIT_CARD_ID);
    Storage.remove(PAYMENT_CONST.URL_REDIRECT);
    Storage.remove(PAYMENT_CONST.CODE_SUB);
  };

  return (
    <div className='wrap-payment-card-sub'>
      {(loadingRequestPoint || isLoadingPaymentVendo || isLoadingVerifyToken) && (
        <div className='layer-spinner'>
          <LoadingIconV2 className='spinner-icon' />
        </div>
      )}
      {isShowPopupListCard && (
        <PopupListCardSub
          title={t('Plan.titleListCard')}
          resetAll={resetAll}
          setIsShowPopupPayment={setIsShowPopupPayment}
          setIsShowPopupListCard={setIsShowPopupListCard}
          setIsShowPopupCreateAndUpdateCard={setIsShowPopupCreateAndUpdateCard}
        />
      )}
      {isShowPopupCreateAndUpdateCard && (
        <PopupCreateAndUpdate
          resetAll={resetAll}
          title={t('Creator.cardManagement.titleAddCard')}
          setIsShowPopupListCard={setIsShowPopupListCard}
          setIsShowPopupCreateAndUpdateCard={setIsShowPopupCreateAndUpdateCard}
        />
      )}
      {isShowPopupPayment && (
        <PopupPaymentCard
          title={t('Plan.titlePayment')}
          urlRedirectPayment={urlRedirectPayment}
          item={itemSub}
          resetAll={resetAll}
          idPackage={idPackage}
          setIsShowPopupPayment={setIsShowPopupPayment}
          setIsShowPopupListCard={setIsShowPopupListCard}
          setIsShowPopupCreateAndUpdateCard={setIsShowPopupCreateAndUpdateCard}
        />
      )}
      {paymentVendoSuccess && (
        <PopupGeneral
          title={t('Purchase.payment.title')}
          className='popup-success-payment-tiger'>
          <div className='success-icon'>
            <SuccessRegisterIcon />
          </div>
          <div className='text-content'>
            <div className='title-success'>{t('Common.success')}</div>
            <div className='content'>{t('Purchase.payment.vendo.paymentSuccess')}</div>
          </div>

          <div className='button-wrapper'>
            <div
              className='btn btn-ok'
              onClick={() => {
                resetAll(true);
                dispatch(getUserInfoMe());
                dispatch(resetIDHomePage());
                dispatch(resetDataInfoOther());
                navigate(`${newPathUser}`);
              }}>
              {t('Purchase.payment.vendo.returnHome')}
            </div>
            <div
              className='btn btn-cancel'
              onClick={() => {
                let idPost = window.localStorage.getItem('idPost')
                if (idPost) {
                  window.localStorage.removeItem('idPost')
                  navigate(`${newPathUser}post-sale/${idPost}`);
                } else {
                  resetAll(true);
                  dispatch(getUserInfoMe());
                  setIsShowPopupPayment(false);
                  setIsShowPopupListCard(false);
                  onClickSuccess && onClickSuccess();
                }
              }}>
              {t('Common.Ok')}
            </div>
          </div>
        </PopupGeneral>
      )}
      {paymentVendoFail && (
        <PopupGeneral
          title={t('Purchase.payment.title')}
          className='popup-success-payment-tiger'>
          <div className='success-icon'>
            <FailIcon />
          </div>
          <div className='text-content'>
            <div className='title-fail'>{t('Common.fail')}</div>
            {/* <div className='content'>{t(`ValidateMsg.${messageErrorVendo}`)}</div> */}
            <div className='content'>{t('Purchase.payment.vendo.paymentFail')}</div>
          </div>

          <div className='button-wrapper'>
            <div
              className='btn btn-ok'
              onClick={() => {
                resetAll();
              }}>
              {t('Common.Ok')}
            </div>
            <div
              className='btn btn-cancel'
              onClick={() => {
                resetAll(true);
                dispatch(resetIDHomePage());
                dispatch(resetDataInfoOther());
                navigate(`${newPathUser}`);
              }}>
              {t('Purchase.payment.vendo.returnHome')}
            </div>
          </div>
        </PopupGeneral>
      )}
      {isShowModalOTP?.isShow && (
        <PopupGeneral
          title={t('Purchase.payment.title')}
          className='popup-success-payment-tiger'>
          <div className='success-icon'>
            <FailIcon />
          </div>
          <div className='text-content'>
            <div className='title-fail'>{t('Common.fail')}</div>
            <div className='content'></div>
            <div className='content'>{t('Purchase.payment.vendo.paymentOTPFail')}</div>
          </div>

          <div className='button-wrapper'>
            <div
              className='btn btn-ok'
              onClick={() => {
                if (isShowModalOTP?.urlOTP) {
                  window.location.href = isShowModalOTP?.urlOTP;
                }
              }}>
              {t('Common.buttonYes')}
            </div>
            <div
              className='btn btn-cancel'
              onClick={() => {
                resetAll(true);
                setIsShowModalOTP({
                  isShow: false,
                  urlOTP: null,
                });
                dispatch(cancelPaymentPonitVendo(codeBySub));
              }}>
              {t('Common.buttonNo')}
            </div>
          </div>
        </PopupGeneral>
      )}
    </div>
  );
};

export default memo(PopupPaymentCardComponent);
